import * as actionTypes from './actionTypes';
import { gql } from "apollo-boost";
import * as dataSet from './dataSet';
import * as actionCreators from './index';

/**
 * TYPE : GET
 * Getting Lead List
 */
// export const getLeadList = (client, value) => {

//     return function (dispatch) {
//         const query = gql`
//                 query getLeadList($list: ProjectListInput!) 
//                 {
//                     getLeadList(list: $list) {
//                         totalrecord
//                         totalpage
//                         data
//                     }
//                 }
//             `;
//         client.query({
//             query: query,
//             fetchPolicy: 'no-cache',
//             variables: value,
//         }).then(response => {
//             let return_data = dataSet.processGQLData(response.data.getLeadList);
//             dispatch(setResponseDetails(actionTypes.GET_LEAD_LIST, return_data));
//         }).catch((error) => {
//             // let err_data = errorMessageGraphQl(error);
//             var returnData = {
//                 'data': {},
//                 'error': false,
//                 'notify_msg': ""
//             }
//             dispatch(setResponseDetails(actionTypes.GET_LEAD_LIST, returnData));
//             //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
//         });
//     }
// }

/**
 * TYPE : GET
 * Getting Lead Filter List
 */
export const getProjectFilterList = (client, value) => {
    return function (dispatch) {
        const query = gql`
                query getProjectFilterList($filterdata: [JSON!]!,$list: ProjectListInput!, $filterid: Float!)                 
                {
                    getProjectFilterList(filterdata:$filterdata, list: $list, filterid: $filterid) {
                        totalrecord
                        totalpage
                        data
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: value,
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getProjectFilterList);
            dispatch(setResponseDetails(actionTypes.GET_PROJECT_FILTER_LIST, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            var returnData = {
                'data': { totalrecord: 0, totalpage: 1, data: [] },
                'error': "",
                'notify_msg': "",
                'is_loaded': true,
            }
            dispatch(setResponseDetails(actionTypes.GET_PROJECT_FILTER_LIST, returnData));
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const deleteProject = (client, request) => {
    return dispatch => {
        const query = gql`
            query deleteProject($projectid: [Float!]!)                 
            {
                deleteProject(projectid:$projectid) {
                    status
                    message
                }
            }
        `;
        client.query({
            query: query,
            variables: request['deleteid'],
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.deleteProject.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.deleteProject.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.deleteProject.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
            dispatch(actionCreators.getProjectFilterList(client, request['listval']))
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const addFavouriteProject = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation addFavouriteProject($status:Boolean!, $projectid:Float!) {
                 addFavouriteProject(status:$status,projectid:$projectid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: request['favorite']
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.addFavouriteProject.status == "true") {
                        // returnData = {
                        //     'data': {},
                        //     'error': false,
                        //     'notify_msg': response.data.addFavouriteProject.message
                        // }
                        // dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                        dispatch(actionCreators.getProjectFilterList(client, request['listval']))
                        dispatch(actionCreators.getProjectField(client, request['favorite'].projectid))
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.addFavouriteProject.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }

            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}
export const updateProjectListColumnOrder = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateProjectListColumnOrder($orderno:[Float!]!, $columnstatus:[Boolean!]!,$fieldid:[Float!]!) {
                 updateProjectListColumnOrder(orderno:$orderno,columnstatus:$columnstatus,fieldid:$fieldid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: request
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateProjectListColumnOrder.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateProjectListColumnOrder.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                        dispatch(actionCreators.getProjectListColumnOrder(client))
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateProjectListColumnOrder.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }


            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}
export const getProjectListColumnOrder = (client) => {
    return function (dispatch) {
        const query = gql`
                query getProjectListColumnOrder
                {
                    getProjectListColumnOrder {
                        id
                        field{
                            id
                            fieldkey
                            fieldlabel
                        }
                        userid
                        columnid
                        orderno
                        isactive
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getProjectListColumnOrder);
            console.log(return_data, 'responseresponse');
            dispatch(setResponseDetails(actionTypes.GET_PROJECT_LIST_ORDER, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            var returnData = {
                'data': {},
                'error': false,
                'notify_msg': ""
            }
            dispatch(setResponseDetails(actionTypes.GET_PROJECT_LIST_ORDER, returnData));
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}




/**
 * [addLeadLogComment saves comments of log]
 */
export const addProjectLogComment = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation addProjectLogComment($type:String!, $logid:Float!, $comment:String!,$mentionuser:[Float!], $mentiondetail: String,$projectid: Float!) {
                 addProjectLogComment(type:$type, logid:$logid, comment:$comment,mentionuser:$mentionuser, mentiondetail:$mentiondetail,projectid: $projectid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                type: request.type,
                logid: request.logid,
                comment: request.comment,
                mentionuser: request.mentionuser,
                mentiondetail: request.mentiondetail,
                projectid: request.projectid,

            }
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.addProjectLogComment.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.addProjectLogComment.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));

                    let logparam = { projectid: request.projectid, emailid: request.emailid, logtypeid: request.logtypeid, activitypeid: request.activitypeid };
                    dispatch(actionCreators.getProjectActivityLogList(client, logparam))
                    dispatch(actionCreators.getProjectLogListNew(client, logparam))
                    dispatch(actionCreators.getProjectField(client, request.projectid))
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.addProjectLogComment.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

/**
 * [updateProjectLogComment saves comments of log]
 */
export const updateProjectLogComment = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateProjectLogComment($type:String!, $commentid:Float!, $comment:String!,$mentionuser:[Float!], $mentiondetail: String!) {
                 updateProjectLogComment(type:$type, commentid:$commentid, comment:$comment,mentionuser:$mentionuser, mentiondetail:$mentiondetail) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                type: request.type,
                commentid: request.commentid,
                comment: request.comment,
                mentionuser: request.mentionuser,
                mentiondetail: request.mentiondetail,
            }
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.updateProjectLogComment.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.updateProjectLogComment.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));

                    let logparam = { projectid: request.projectid, emailid: request.emailid, logtypeid: request.logtypeid, activitypeid: request.activitypeid };
                    dispatch(actionCreators.getProjectActivityLogList(client, logparam))
                    dispatch(actionCreators.getProjectLogListNew(client, logparam))
                    dispatch(actionCreators.getProjectField(client, request.projectid))
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.updateProjectLogComment.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}
export const deleteProjectLogComment = (client, request) => {

    return dispatch => {
        const query = gql`
            query deleteProjectLogComment($commentid: Float!, $type: String!)                 
            {
                deleteProjectLogComment(commentid:$commentid, type:$type) {
                    status
                    message
                }
            }
        `;
        client.query({
            query: query,
            variables: { commentid: request.commentid, type: request.type },
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.deleteProjectLogComment.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.deleteProjectLogComment.message
                    }

                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    let logparam = { projectid: request.projectid, emailid: request.emailid, logtypeid: request.logtypeid, activitypeid: request.activitypeid };
                    dispatch(actionCreators.getProjectActivityLogList(client, logparam))
                    dispatch(actionCreators.getProjectLogListNew(client, logparam))
                    dispatch(actionCreators.getProjectField(client, request.projectid))
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.deleteProjectLogComment.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}


/**
 * TYPE : GET
 * Getting Lead Log List with filter
 */
export const getProjectLogListNew = (client, request) => {

    let paramtype = request.logtypeid;
    let pullarr = [1000, 1001];
    pullarr.map((arr) => {
        if (paramtype.indexOf(arr) != -1) {
            let ind = paramtype.indexOf(arr);
            paramtype.splice(ind, 1);
        }
    });

    return function (dispatch) {
        const query = gql`
                query getProjectLogListNew($projectid: Float!, $emailid: String!, $logtypeid:[Float!], $activitypeid:[Float!])                 
                {
                    getProjectLogListNew(projectid: $projectid,emailid:$emailid,logtypeid:$logtypeid,activitypeid:$activitypeid) {
                        logdata
                        status
                        message
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: { projectid: request.projectid, emailid: request.emailid, logtypeid: paramtype, activitypeid: request.activitypeid },
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getProjectLogListNew);
            dispatch(setResponseDetails(actionTypes.GET_PROJECT_LOG_LIST, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            var returnData = {
                'data': {},
                'error': false,
                'notify_msg': ""
            }
            dispatch(setResponseDetails(actionTypes.GET_PROJECT_LOG_LIST, returnData));
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

/**
 * TYPE : GET
 * Getting Lead Log List with filter
 */
export const getProjectActivityLogList = (client, request) => {
    return function (dispatch) {
        const query = gql`
                query getProjectActivityLogList($projectid: Float!, $emailid: String!, $logtypeid:[Float!], $activitypeid:[Float!], $filterchange:Boolean)                 
                {
                    getProjectActivityLogList(projectid: $projectid,emailid:$emailid,logtypeid:$logtypeid,activitypeid:$activitypeid,filterchange:$filterchange) {
                        status
                        message
                        pined
                        today
                        yesterday
                        thisweek
                        lastweek
                        thismonth
                        lastmonth
                        earlier
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: request,
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getProjectActivityLogList);
            dispatch(setResponseDetails(actionTypes.GET_PROJECT_LOG_SIDEBAR_LIST, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            var returnData = {
                'data': {},
                'error': false,
                'notify_msg': ""
            }
            dispatch(setResponseDetails(actionTypes.GET_PROJECT_LOG_LIST, returnData));
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}


/**
 * TYPE : GET
 * Getting Lead Log List
 */
export const getProjectLogList = (client, request) => {
    return function (dispatch) {
        const query = gql`
                query getProjectLogList($projectid: Float!, $emailid: String!)                 
                {
                    getProjectLogList(projectid: $projectid,emailid:$emailid) {
                        logdata
                        status
                        message
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: request,
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getProjectLogList);
            dispatch(setResponseDetails(actionTypes.GET_PROJECT_LOG_LIST, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            var returnData = {
                'data': {},
                'error': false,
                'notify_msg': ""
            }
            dispatch(setResponseDetails(actionTypes.GET_PROJECT_LOG_LIST, returnData));
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const saveProjectLog = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation saveProjectLog($mentionuser:[Float!], $mentiondetail: String, $propertyname:String!, $propertyid:Float!, $projectid:Float!, $text:String!, $oldvalue:String!, $newvalue:String!, $logtime:Float!, $logdate:Float!, $logtype:Float!, $fieldid:Float!) {
                 saveProjectLog(mentionuser:$mentionuser, mentiondetail:$mentiondetail, propertyname:$propertyname, propertyid:$propertyid, projectid:$projectid, text:$text, oldvalue:$oldvalue, newvalue:$newvalue, logtype:$logtype, logtime:$logtime, logdate:$logdate, fieldid:$fieldid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: request['save']
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.saveProjectLog.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.saveProjectLog.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                        dispatch(actionCreators.getProjectActivityLogList(client, request['getlog']))
                        dispatch(actionCreators.getProjectLogListNew(client, request['getlog']))
                        dispatch(actionCreators.getProjectField(client, request['getlog'].projectid))


                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.saveProjectLog.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const updateProjectLog = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateProjectLog($mentionuser:[Float!],$mentiondetail: String, $text:String!, $logtime:Float!, $logdate:Float!, $logid:Float!) {
                 updateProjectLog(mentionuser:$mentionuser, mentiondetail:$mentiondetail, text:$text, logtime:$logtime, logdate:$logdate, logid:$logid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: request['update']
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateProjectLog.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateProjectLog.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                        dispatch(actionCreators.getProjectActivityLogList(client, request['getlog']))
                        dispatch(actionCreators.getProjectLogListNew(client, request['getlog']))
                        dispatch(actionCreators.getProjectField(client, request['getlog'].projectid))


                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateProjectLog.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}
export const deleteProjectLogDetail = (client, request) => {

    return dispatch => {
        const query = gql`
            query deleteProjectLogDetail($logid: Float!)                 
            {
                deleteProjectLogDetail(logid:$logid) {
                    status
                    message
                }
            }
        `;
        client.query({
            query: query,
            variables: { logid: request.logid },
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.deleteProjectLogDetail.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.deleteProjectLogDetail.message
                    }

                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    let logparam = { projectid: request.projectid, emailid: request.emailid, logtypeid: request.logtypeid, activitypeid: request.activitypeid };
                    dispatch(actionCreators.getProjectActivityLogList(client, logparam))
                    dispatch(actionCreators.getProjectLogListNew(client, logparam))
                    dispatch(actionCreators.getProjectField(client, request.projectid))
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.deleteProjectLogDetail.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}


export const saveProjectDetail = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation saveProjectDetail($projectdata:[JSON!]!,$teamvisible:Boolean!) {
                 saveProjectDetail(projectdata:$projectdata,teamvisible:$teamvisible) {
                    id
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                projectdata: request.projectdata,
                teamvisible: request.teamvisible
            }
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.saveProjectDetail.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.saveProjectDetail.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    if (request.isFromTopBar && response.data.saveProjectDetail.id) {
                        console.log(response.data.saveProjectDetail.id);
                        let redirectURL = '/project/projects/' + response.data.saveProjectDetail.id;
                        window.location.href = redirectURL;
                    }
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.saveProjectDetail.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
            let val = {
                filterdata: [],
                list: {
                    limit: 10,
                    pageno: 1,
                    searchtext: "",
                    sorttype: "",
                    type: "list",
                    fieldId: 1,
                    columnname: '',
                    islimited: false,
                },
                filterid: 1
            }

            dispatch(actionCreators.getProjectFilterList(client, val))

            if (request.sidebarParams && request.sidebarParams !== "") {
                dispatch(actionCreators.getRecordRelatedData(client, request.sidebarParams))
            }
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const getProjectField = (client, projectid) => {
    return function (dispatch) {
        const query = gql`
                query getProjectField($projectid: Float!) 
                {
                    getProjectField(projectid: $projectid) {
                        id
                        is_default
                        fieldstatus
                        fieldkey
                        fieldtype{
                          id
                          name
                        }
                        fieldicon
                        fieldlabel
                        generatedfieldid
                        projectdetail{
                          id
                          values
                          fieldvalue
                          project{                          
                            teamvisible
                            }
                        }
                        options
                        leadfavourite
                        image
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                projectid: projectid
            },
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getProjectField);
            dispatch(setResponseDetails(actionTypes.PROJECT_FIELD_DATA, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const updateProjectDetail = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateProjectDetail($updatedfieldid:Float!,$projectdata:[JSON!]!,$projectid:Float!,$teamvisible:Boolean!) {
                 updateProjectDetail(updatedfieldid:$updatedfieldid,projectdata:$projectdata,projectid:$projectid,teamvisible:$teamvisible) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                projectdata: request.projectdata,
                projectid: request.projectid,
                updatedfieldid: request.updatedfieldid,
                teamvisible: request.teamvisible

            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateProjectDetail.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateProjectDetail.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateProjectDetail.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }

                let logparam = { projectid: request.projectid, emailid: '' };
                // dispatch(actionCreators.getProjectLogList(client, logparam))
                dispatch(actionCreators.getProjectField(client, request.projectid))

                let val = {
                    filterdata: [],
                    list: {
                        limit: 10,
                        pageno: 1,
                        searchtext: "",
                        sorttype: "",
                        type: "list",
                        fieldId: 1,
                        columnname: '',
                        islimited: false,
                    },
                    filterid: 1
                }
                if (!request.dropSubmit) {
                    dispatch(actionCreators.getProjectFilterList(client, val))
                }

            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const saveFilterDetailProject = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation saveFilterDetail($filterdata:[JSON!]!,$fitertype:String!,$filtername: String!,$recordtypeid: Float!) {
                 saveFilterDetail(filterdata:$filterdata,fitertype:$fitertype,filtername:$filtername,recordtypeid:$recordtypeid) {
                    id
                    fitertype
                    userid
                    filtername
                    filterquery
                    filteroption
                    isdefault
                    recordtypeid
                    isselected
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                filterdata: request.filterdata,
                fitertype: request.fitertype,
                filtername: request.filtername,
                recordtypeid: request.recordtypeid,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.saveFilterDetail.id) {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': "Filter saved successfully"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    }
                }
                dispatch(actionCreators.getFilterListProject(client, 5))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const getFilterListProject = (client, recordtypeid) => {
    return function (dispatch) {
        const query = gql`
                query getFilterList($recordtypeid: Float!) 
                {
                    getFilterList(recordtypeid: $recordtypeid) {
                        id
                        fitertype
                        userid
                        filtername
                        filterquery
                        filteroption
                        isdefault
                        recordtypeid
                        isselected
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                recordtypeid: recordtypeid
            },
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getFilterList);
            dispatch(setResponseDetails(actionTypes.GET_PROJECTFILTER_LIST, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}
export const deleteFilterProject = (client, filterid) => {
    return function (dispatch) {
        const query = gql`
                query deleteFilter($filterid: Float!) 
                {
                    deleteFilter(filterid: $filterid) {
                        status
                        message
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                filterid: filterid
            },
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.deleteFilter.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.deleteFilter.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.deleteFilter.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
            dispatch(actionCreators.getFilterListProject(client, 5))
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const updateFilterDefaultProject = (client, filterid) => {
    return dispatch => {
        const mutation = gql`
             mutation updateFilterDefault($type:String!, $fiterid:Float!,$recordtypeid:Float!) {
                 updateFilterDefault(type:$type,fiterid:$fiterid,recordtypeid:$recordtypeid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                type: "isdefault",
                fiterid: filterid,
                recordtypeid: 5
            },
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateFilterDefault.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateFilterDefault.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateFilterDefault.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
                dispatch(actionCreators.getFilterListProject(client, 5))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const updateFilterDetailProject = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateFilterDetail($filterdata: [JSON!]!, $fitertype:String!,$filtername:String!,$filterid:Float!) {
                 updateFilterDetail(filterdata:$filterdata,fitertype:$fitertype,filtername:$filtername,filterid:$filterid) {
                    id
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                filterdata: request.filterdata,
                fitertype: request.fitertype,
                filtername: request.filtername,
                filterid: request.filterid,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateFilterDetail.id) {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': "Filter updated successfully"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': "Filter couldn't get updated"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
                dispatch(actionCreators.getFilterListProject(client, 5))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const updateProjectStage = (client, req) => {
    return dispatch => {
        const mutation = gql`
             mutation updateProjectStage($updated_stage_id:Float!, $current_stage_id:Float!,$projectid:Float!) {
                 updateProjectStage(updated_stage_id:$updated_stage_id,current_stage_id:$current_stage_id,projectid:$projectid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                updated_stage_id: req.updated_stage_id,
                current_stage_id: req.current_stage_id,
                projectid: req.projectid,
            },
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateProjectStage.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateProjectStage.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                        dispatch(actionCreators.getRecordRelatedData(client, req.getRelatedReq))
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateProjectStage.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}
export const updateProjectStatus = (client, req) => {
    return dispatch => {
        const mutation = gql`
             mutation updateProjectStatus($updated_status_id:Float!, $current_status_id:Float!,$projectid:Float!) {
                 updateProjectStatus(updated_status_id:$updated_status_id,current_status_id:$current_status_id,projectid:$projectid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                updated_status_id: req.updated_status_id,
                current_status_id: req.current_status_id,
                projectid: req.projectid,
            },
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateProjectStatus.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateProjectStatus.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                        dispatch(actionCreators.getRecordRelatedData(client, req.getRelatedReq))
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateProjectStatus.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const addProjectLogEmoji = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation addProjectLogEmoji($logid:Float!,$emoji:String!, $emojidetail:String!,$logtype:String!) {
                addProjectLogEmoji(logid:$logid,emoji:$emoji,emojidetail:$emojidetail,logtype:$logtype) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                logid: request.logid,
                emoji: request.emoji,
                emojidetail: request.emojidetail,
                logtype: request.logtype,
            }
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.addProjectLogEmoji.status == "true") {
                    let logparam = { projectid: request.projectid, emailid: request.emailid, logtypeid: request.logtypeid, activitypeid: request.activitypeid };
                    dispatch(actionCreators.getProjectActivityLogList(client, logparam))
                    dispatch(actionCreators.getProjectLogListNew(client, logparam))
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.addProjectLogEmoji.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

const setResponseDetails = (newType, data) => {
    return {
        type: newType,
        val: data
    };
}

const notificationAll = (newType, data, status) => {
    return {
        type: newType,
        val: data,
        status: status,
    }
}

const errorMessageGraphQl = (err) => {
    let msg = 'Something Went Wrong!';
    if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        msg = err.graphQLErrors[0].message;
    } else if (err.networkError && err.networkError.result) {
        msg = err.networkError.result.errors[0].message;
    } else if (err.message) {
        msg = err.message;
    }

    return {
        notify_msg: msg,
        error: true
    }
}

export const clearNotification = (value) => {
    return function (dispatch) {
        dispatch(clearNotificationAll(actionTypes.NOTIFICATIONS, {}, false));
    }
}
const clearNotificationAll = (newType, data, status) => {
    return {
        type: actionTypes.NOTIFICATIONS,
        val: '',
        status: '',
    }
}
