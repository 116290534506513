import React, { Component } from "react";
import { Dialog, IconButton, withStyles, Button, Icon, Tooltip, Grid, FormControl, Select, InputLabel, MenuItem, DialogActions } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Autocomplete from '@material-ui/lab/Autocomplete';
import NumberFormat from 'react-number-format';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';

const TooltipNew = withStyles((theme) => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);
class CurrencyEditDialoge extends Component {
  state = {
    currencyData: {},
    symbol: '',
    thousandSeparator: '',
    decimalSeparator: '',
    decimalPlaces: '',
  };

  componentWillReceiveProps(props) {
    console.log("props", props);
    if (props.notification && props.notification.status === true) {
      this.props.handleClose();
      this.props.clearNotification();
    }
  }

  componentDidMount() {
    if (this.props.currencyData.detail) {
      this.setState({ currencyData: this.props.currencyData })
      if (this.props.currencyData.symbol === "") {
        this.setState({ symbol: "None" })
      } else {
        this.setState({ symbol: this.props.currencyData.symbol })
      }
      this.setState({ symbol: this.props.currencyData.symbol })
      this.setState({ thousandSeparator: this.props.currencyData.thousandsseparator, decimalPlaces: this.props.currencyData.decimalplaces, decimalSeparator: this.props.currencyData.decimalseparator })
    }
  }

  handleChange = event => {
    event.persist();
    this.setState({ [event.target.name]: event.target.value });
  };

  handleFormSubmit = () => {
    var updateData = {}
    updateData.id = this.state.currencyData.id;
    updateData.currencyid = this.state.currencyData.currencyid;
    updateData.symbol = this.state.symbol;
    updateData.thousandsseparator = this.state.thousandSeparator;
    updateData.decimalplaces = this.state.decimalPlaces;
    updateData.decimalseparator = this.state.decimalSeparator;
    this.props.updateCurrencyData(this.props.apolloClient.client, updateData);

  }

  render() {
    let { open, handleClose, handleMinimize, handleFull, fullScreen, minimizeScreen } = this.props;
    return (
      <Dialog onClose={handleClose} open={open} maxWidth="xs" fullWidth={true} className={`crm-custom-bg-primary custom_dialog ${minimizeScreen && fullScreen ? 'alignitemsend-fullscreen' : ''} ${minimizeScreen ? 'alignitemsend' : ''} ${fullScreen ? 'fullscreen' : ''}`} disableBackdropClick
      // disableEscapeKeyDown
      >
        <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">
          <h4 className="m-0 text-white">Edit {this.state.currencyData && this.state.currencyData.detail ? this.state.currencyData.detail.code : ''} Format</h4>
          <div className="d-flex">
            <TooltipNew title={minimizeScreen ? 'Normal Screen' : 'Minimize Screen'}>
              <IconButton onClick={handleMinimize}>
                <Icon className="text-white">{minimizeScreen ? 'add' : 'remove'}</Icon>
              </IconButton>
            </TooltipNew>
            <TooltipNew title={fullScreen ? 'Exit Full Screen' : 'Full Screen'}>
              <IconButton onClick={handleFull}>
                <Icon className="text-white">{fullScreen ? 'fullscreen_exit' : 'fullscreen'}</Icon>
              </IconButton>
            </TooltipNew>
            <TooltipNew title="Close">
              <IconButton onClick={handleClose}>
                <Icon className="text-white">clear</Icon>
              </IconButton>
            </TooltipNew>
          </div>
        </div>
        <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
          <div className="dialog-body">
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <FormControl className="w-100 input_bx">
                  <InputLabel>Symbol</InputLabel>
                  <Select
                    name="symbol"
                    value={this.state.symbol}
                    onChange={this.handleChange}
                  >
                    <MenuItem className="select-menu-item" value={'None'}>None</MenuItem>
                    {this.state.currencyData && this.state.currencyData.detail &&
                      <MenuItem className="select-menu-item" value={this.state.currencyData.detail.symbol}>{this.state.currencyData.detail.symbol}</MenuItem>
                    }
                    {this.state.currencyData && this.state.currencyData.detail &&
                      <MenuItem className="select-menu-item" value={this.state.currencyData.detail.code}>{this.state.currencyData.detail.code}</MenuItem>
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormControl className="w-100 input_bx">
                  <InputLabel>Thousands Separator</InputLabel>
                  <Select
                    name="thousandSeparator"
                    value={this.state.thousandSeparator}
                    onChange={this.handleChange}
                  >
                    <MenuItem className="select-menu-item" value={','}>Comma</MenuItem>
                    <MenuItem className="select-menu-item" value={'.'}>Period</MenuItem>
                    <MenuItem className="select-menu-item" value={' '}>Space</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormControl className="w-100 input_bx">
                  <InputLabel>Decimal Places</InputLabel>
                  <Select
                    name="decimalPlaces"
                    value={this.state.decimalPlaces}
                    onChange={this.handleChange}
                  >
                    <MenuItem className="select-menu-item" value={'0'}>0</MenuItem>
                    <MenuItem className="select-menu-item" value={'1'}>1</MenuItem>
                    <MenuItem className="select-menu-item" value={'2'}>2</MenuItem>
                    <MenuItem className="select-menu-item" value={'3'}>3</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormControl className="w-100 input_bx">
                  <InputLabel>Decimal Separator</InputLabel>
                  <Select
                    name="decimalSeparator"
                    value={this.state.decimalSeparator}
                    onChange={this.handleChange}
                  >
                    <MenuItem className="select-menu-item" value={','}>Comma</MenuItem>
                    <MenuItem className="select-menu-item" value={'.'}>Period</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={12} xs={12}>
                <InputLabel style={{ fontSize: '12px', marginBottom: '12px' }}>Preview: <NumberFormat
                  value={2456981}
                  displayType={'text'}
                  thousandSeparator={this.state.thousandSeparator}
                  prefix={this.state.symbol}
                  decimalScale={this.state.decimalPlaces}
                  decimalSeparator={this.state.decimalSeparator + " "}
                  fixedDecimalScale={this.state.decimalPlaces === '0' ? false : true} />
                </InputLabel>
              </Grid>
            </Grid>
          </div>
          <DialogActions className="dialog-action">
            <Button variant="contained" onClick={handleClose}>Cancel</Button>
            <Button variant="contained" type="submit" color="primary" className="btn_Primary" >Save</Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
});


const mapDispatchToProps = dispatch => {
  return {
    loadCurrencyList: (client) => dispatch(actionCreators.loadCurrencyList(client)),
    getCompanyCurrency: (client) => dispatch(actionCreators.getCompanyCurrency(client)),
    updateCurrencyData: (client, data) => dispatch(actionCreators.updateCurrencyData(client, data)),
    clearNotification: () => dispatch(actionCreators.clearNotification()),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(CurrencyEditDialoge));
