import React, { Component } from "react";
import { Typography, Checkbox } from "@material-ui/core";
import { Table } from 'antd';
import { connect } from "react-redux";
import * as actionCreators from '../../../store/actions/index';

class AutoNotifications extends Component {
    state = {
        selection: [],
        checkbox_auto_name1: '',
        checkbox_auto_name2: '',
        checkbox_auto_name3: '',
        checkbox_auto_name4: '',
        checkbox_auto_name5: '',
    };

    componentDidMount() {
        this.props.getFollowSetting(this.props.apolloClient.client);
        if (this.props.settingReducer.getnotificationsettingdata) {
            let data = this.props.settingReducer.getnotificationsettingdata.data;
            this.setnotification(data);
            this.setState({ selection: data });
        }
    }
    componentWillReceiveProps(props) {
        if (props.settingReducer.getfollowSettingdata) {
            let data = props.settingReducer.getfollowSettingdata.data;
            this.setnotification(data);
            this.setState({ selection: data });
        }
    }
    setnotification = (data) => {
        if (data && data.length > 0) {
            data.map((ndata) => {
                if (ndata) {
                    if (ndata.notification_type === "FOLLOW_01") {
                        this.setState({
                            checkbox_auto_name1: ndata.status,
                        });
                    }
                    if (ndata.notification_type === "FOLLOW_02") {
                        this.setState({
                            checkbox_auto_name2: ndata.status,
                        });
                    }
                    if (ndata.notification_type === "FOLLOW_03") {
                        this.setState({
                            checkbox_auto_name3: ndata.status,
                        });
                    }
                    if (ndata.notification_type === "FOLLOW_04") {
                        this.setState({
                            checkbox_auto_name4: ndata.status,
                        });
                    }
                    if (ndata.notification_type === "FOLLOW_05") {
                        this.setState({
                            checkbox_auto_name5: ndata.status,
                        });
                    }
                }
            });
        }
    }
    handleCheckBoxChange = (event, name) => {
        let submitData;
        if (name === 'checkbox_auto_name1') {
            submitData = [{
                notification_type: 'FOLLOW_01', status: event.target.checked,
            }]
        }
        if (name === 'checkbox_auto_name2') {
            submitData = [{
                notification_type: 'FOLLOW_02', status: event.target.checked,
            }]
        }
        if (name === 'checkbox_auto_name3') {
            submitData = [{
                notification_type: 'FOLLOW_03', status: event.target.checked,
            }]
        }
        if (name === 'checkbox_auto_name4') {
            submitData = [{
                notification_type: 'FOLLOW_04', status: event.target.checked,
            }]
        }
        if (name === 'checkbox_auto_name5') {
            submitData = [{
                notification_type: 'FOLLOW_05', status: event.target.checked,
            }]
        }
        let req = { settingdata: submitData };
        this.props.UpdateFollowSetting(this.props.apolloClient.client, req);
        this.setState({ [name]: event.target.checked });
    };
    render() {
        const columns = [];
        const rows = [];
        const general_rows = [];

        const general_columns = [

            {
                title: (<Typography className="font-size-18 p-10" variant="p" color="inherit">Automatically follow records when:</Typography>),
                dataIndex: "name",
                className: "align_left",
                width: 300
            },
        ];

        general_rows.push(
            {
                name: (
                    <div className="ml-10">
                        <Checkbox
                            checked={this.state.checkbox_auto_name1}
                            onChange={event => this.handleCheckBoxChange(event, 'checkbox_auto_name1')}
                            value="checkbox_auto_name1"
                            color="primary"
                        />
                        <label className="crm-custom-field-label font-weight-500">I create a record</label>
                    </div>
                ),
            },
            {
                name: (
                    <div className="ml-10">
                        <Checkbox
                            checked={this.state.checkbox_auto_name2}
                            onChange={event => this.handleCheckBoxChange(event, 'checkbox_auto_name2')}
                            value="checkbox_auto_name2"
                            color="primary"
                        />
                        <label className="crm-custom-field-label font-weight-500">I update a record</label>
                    </div>
                ),
            },
            {
                name: (
                    <div className="ml-10">
                        <Checkbox
                            checked={this.state.checkbox_auto_name3}
                            onChange={event => this.handleCheckBoxChange(event, 'checkbox_auto_name3')}
                            value="checkbox_auto_name3"
                            color="primary"
                        />
                        <label className="crm-custom-field-label font-weight-500">I log activities on a record</label>
                    </div>
                ),
            },
            {
                name: (
                    <div className="ml-10">
                        <Checkbox
                            checked={this.state.checkbox_auto_name4}
                            onChange={event => this.handleCheckBoxChange(event, 'checkbox_auto_name4')}
                            value="checkbox_auto_name4"
                            color="primary"
                        />
                        <label className="crm-custom-field-label font-weight-500">I assign someone to a record</label>
                    </div>
                ),
            },
            {
                name: (
                    <div className="ml-10">
                        <Checkbox
                            checked={this.state.checkbox_auto_name5}
                            onChange={event => this.handleCheckBoxChange(event, 'checkbox_auto_name5')}
                            value="checkbox_auto_name5"
                            color="primary"
                        />
                        <label className="crm-custom-field-label font-weight-500">Someone assigns a record to me</label>
                    </div>
                ),
            },
        )



        return (
            <React.Fragment>
                <div className="table_width antd-tables">
                    <Table
                        bordered={false}
                        columns={general_columns}
                        pagination={false}
                        dataSource={general_rows}
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        apolloClient: state.apolloClient,
        settingReducer: state.settingReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getFollowSetting: (client) => dispatch(actionCreators.getFollowSetting(client)),
        UpdateFollowSetting: (client, request) => dispatch(actionCreators.UpdateFollowSetting(client, request)),
    }
}
export default (connect(mapStateToProps, mapDispatchToProps)(AutoNotifications));
