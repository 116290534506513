import React, { Component } from "react";
import SettingSidebar from '../../../Layout/SettingSidebarMenu';
import { Avatar, Dialog, DialogTitle, DialogActions, Input, InputAdornment, InputLabel, withStyles, Box, IconButton, Card, Icon, Tab, Tabs, Typography, Button, Checkbox, Chip, Fab, FormControl, Grid, ListItemText, MenuItem, Select, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter } from "@material-ui/core";
import moment from 'moment';
import PropTypes from 'prop-types';
import GoalsDialog from "./GoalsDialog"
import LoadingState from "./LoadingState"


import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from '../../../../store/actions/index';
const IconSmall = withStyles(() => ({
  root: {
    fontSize: "1rem",
    color: "rgba(0, 0, 0, 0.6)",
  },
}))(Icon);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={2}>{children}</Box>
    </Typography>
  );
}
const names = [
  'All activity',
  'Email',
  'Meeting',
  'Phone call'

];
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}
function a11in1yProps(index) {
  return {
    id: `tabinside1-${index}`,
    'aria-controls': `tabpanelin1-${index}`,
  };
}
function a11in2yProps(index) {
  return {
    id: `tabinside2-${index}`,
    'aria-controls': `tabpanelin2-${index}`,
  };
}
function a11in3yProps(index) {
  return {
    id: `tabinside2-${index}`,
    'aria-controls': `tabpanelin2-${index}`,
  };
}
function a11in4yProps(index) {
  return {
    id: `tabinside2-${index}`,
    'aria-controls': `tabpanelin2-${index}`,
  };
}

class QuarterlyActivityGoals extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedUserList: "all-users",
      userlist: this.props.data.state.usersListArray,

      shouldOpenSaleRevenueGoalsDialog: false,
      FullGoalDialog: false,
      minimizeGoalDialog: false,
      value: 0,
      valueone: 0,
      valuetwo: 0,
      valuethree: 0,
      valuefour: 0,
      selectedActivityName: ['All activity'],
      selectedActivityIds: [],
      quarterList: [],

      activityList: [],
      selectedActivityList: [],
      goalList: [],
      selectedActivity: "0",
      usersListArray: [],
      teamList: [],
      date: moment(),
      previousdate: moment().subtract("1", "quarters"),
      nextdate: moment().add("1", "quarters"),
      datetype: 2,
      goaltype: this.props.goaltype,
      submitGoalData: [],
      activitytotal: [],

      isDeleteConfirmDialoOpen: false,
      goaldeleteid: "",
      goaldelete: [],
      deleteactivity: [],
      deletegoalmessage: "",
    }
  }

  componentDidMount = () => {
    var j = 0;
    let date = moment().subtract(12, "quarters");
    let quarterList = [];

    for (j = 0; j < 24; j++) {
      quarterList.push({ start: moment(date).add(j, "quarters"), end: moment(date).add(j + 1, "quarters") })
    }

    this.setState({ quarterList })

    // this.props.getPipelines(this.props.apolloClient.client);
    this.props.getActivityTypes(this.props.apolloClient.client);

    // let goalParams = {datetype: parseInt(this.state.datetype), goaltype: parseInt(this.state.goaltype), filterdata:[]};
    // this.props.getGoalList(this.props.apolloClient.client, goalParams);    
    this.getGoalList();
  }

  getGoalList = () => {
    let goalParams = { datetype: parseInt(this.state.datetype), goaltype: parseInt(this.state.goaltype), filterdata: this.state.selectedActivityIds };
    this.props.getGoalList(this.props.apolloClient.client, goalParams);
  }

  componentWillReceiveProps(props) {

    if (props.settingReducer.activity_quarter_goallist && props.settingReducer.activity_quarter_goallist.data && props.settingReducer.activity_quarter_goallist.is_loaded) {

      let goalslist = props.settingReducer.activity_quarter_goallist.data;
      let submitGoalData = [];
      let activitylist = this.state.activityList;
      let activitytotal = [];

      if (props.settingReducer.teamMemberList && props.settingReducer.teamMemberList.data && this.state.selectedUserList != 'all-users') {
        let teamMemberList = props.settingReducer.teamMemberList.data;

        let memberlist = [];

        teamMemberList.map((teamuser) => {
          memberlist.push(teamuser.user);
        })

        goalslist.map((goal, goalfilterkey) => {
          let userkey = this.findWithAttr(memberlist, 'id', goal.user.id);
          if (userkey == -1) {
            goalslist.splice(goalfilterkey, 1);
          }
        });

      }

      goalslist && goalslist.map((goaldata, goalkey) => {
        if (!submitGoalData[goalkey])
          submitGoalData[goalkey] = [];

        if (!submitGoalData[goalkey].activity)
          submitGoalData[goalkey].activity = [];

        if (!submitGoalData[goalkey].previousdate)
          submitGoalData[goalkey].previousdate = [];

        if (!submitGoalData[goalkey].currentdate)
          submitGoalData[goalkey].currentdate = [];

        if (!submitGoalData[goalkey].nextdate)
          submitGoalData[goalkey].nextdate = [];

        if (!submitGoalData[goalkey].alldate)
          submitGoalData[goalkey].alldate = [];

        if (!submitGoalData[goalkey].goalid)
          submitGoalData[goalkey].id = goaldata.id;

        goaldata.activity && goaldata.activity.map((pipedata, pipekey) => {

          if (!submitGoalData[goalkey].alldate[pipekey])
            submitGoalData[goalkey].alldate[pipekey] = [];

          let isavailable = this.findWithAttr(submitGoalData[goalkey].activity, 'id', pipedata.id);

          if (isavailable == -1) {

            submitGoalData[goalkey].activity.push(pipedata);
            submitGoalData[goalkey].alldate[pipekey] = goaldata.alldate[pipekey]
          }

          goaldata.alldate[pipekey].map((allval, allkey) => {

            if (!activitytotal[allkey])
              activitytotal[allkey] = {};

            if (!activitytotal[allkey][pipedata.id])
              activitytotal[allkey][pipedata.id] = {};


            let totalactivityvalue = (activitytotal[allkey][pipedata.id].totalValue) ? activitytotal[allkey][pipedata.id].totalValue : 0;
            if ((allval.activityid == parseInt(pipedata.id)) && (allval.activitykey == pipekey)) {
              totalactivityvalue += parseInt(allval.value);
            }
            activitytotal[allkey][pipedata.id] = {
              totalValue: parseInt(totalactivityvalue),
              activityid: pipedata.id,
              startdate: allval.startdate,
              enddate: allval.enddate,
            }
          })
        })
      })

      props.settingReducer.activity_quarter_goallist.is_loaded = false;

      this.setState({ goalList: goalslist, submitGoalData, activitytotal })
    }

    if (props.settingReducer.masterActivityTypesList && props.settingReducer.masterActivityTypesList.data) {
      let activitys = props.settingReducer.masterActivityTypesList.data;
      (activitys && activitys.length) && activitys.map((activity) => {
        if (names.indexOf(activity.name) == -1 && (activity.is_disable == false))
          names.push(activity.name);
      });
      let submitGoalData = this.state.submitGoalData;

      if (submitGoalData && submitGoalData.length > 0) {
        // submitGoalData.map((goaldata, goalkey)=>{

        //   goaldata.activity
        //   let activitykey = this.findWithAttr(this.state.activityList, 'id', pipedata.id);

        //   if(activitykey != -1)
        //     activitylist.splice(activitykey, 1);

        //   activitylist[goalkey] = activitys;
        //   console.log(goaldata,'goaldataasd as ads asd'); 
        // })
      }
      this.setState({ activityList: props.settingReducer.masterActivityTypesList.data })
    }

    if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
      const usersListArray = props.settingReducer.usersListArray.data;
      this.setState({ usersListArray: usersListArray })
    }

    if (props.goaltype) {
      this.setState({ goaltype: props.goaltype })
    }

    if (props.data.state.teamList) {
      this.setState({ teamList: props.data.state.teamList })
    }
  }

  handleGoalDialogClose = () => {
    this.setState({ shouldOpenSaleRevenueGoalsDialog: false, FullGoalDialog: false, minimizeGoalDialog: false });
  };
  handleGoalDialogMinimize = () => {
    this.setState(oldStateminimizeGoalDialog => ({ minimizeGoalDialog: !oldStateminimizeGoalDialog.minimizeGoalDialog }));
    this.setState({ FullGoalDialog: false })
  };
  handleGoalDialogFull = () => {
    this.setState(oldStateFullGoalDialog => ({ FullGoalDialog: !oldStateFullGoalDialog.FullGoalDialog }));
    this.setState({ minimizeGoalDialog: false })
  };


  nextQuarter = () => {
    let date = moment(this.state.date).add("1", "quarters")
    let previousdate = moment(date).subtract("1", "quarters");
    let nextdate = moment(date).add("1", "quarters");

    if (date < this.state.quarterList[this.state.quarterList.length - 1].start) {
      this.setState({ date, previousdate, nextdate })
    }
  }

  prevQuarter = () => {

    let date = moment(this.state.date).subtract("1", "quarters");
    let previousdate = moment(date).subtract("1", "quarters");
    let nextdate = moment(date).add("1", "quarters");
    if (date > this.state.quarterList[0].start) {
      this.setState({ date, previousdate, nextdate })
    }
  }

  handleDateChange = () => event => {
    this.setState({ date: moment(event.target.value, "DD-MM-YYYY") })
  }

  handleChange = event => {
    event.persist();
    if (event.target.type === 'checkbox') {
      this.setState({ [event.target.name]: event.target.checked });
    }
    else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };
  quaterFilterList = () => {
    return (<div color="primary" fontSizeSmall >
      <Icon className="text-middle" onClick={this.prevQuarter} color="primary" fontSizeSmall>keyboard_arrow_left</Icon>

      <Select displayEmpty
        value={this.state.date.format("DD-MM-YYYY")}
        onChange={this.handleDateChange()}
        inputProps={{ className: "text-primary font-weight-500" }}
        MenuProps={{ className: 'choose_field_menu' }}
        className="text-middle"
      >
        {this.state.quarterList.map(val => {
          return <MenuItem value={val.start.format("DD-MM-YYYY")} className="select-menu-item">{val.start.format("MMM YY")} - {val.end.format("MMM YYYY")}</MenuItem>
        })}
      </Select>
      <Icon className="text-middle" onClick={this.nextQuarter} color="primary" fontSizeSmall >keyboard_arrow_right</Icon>
    </div>)
  }


  handleActivityChange = (event, gkey, goaldata) => {

    let selectedActivityList = this.state.selectedActivityList;
    let submitGoalData = this.state.submitGoalData;
    let activitylist = this.state.activityList;

    if (!submitGoalData[gkey])
      submitGoalData[gkey] = [];

    if (!submitGoalData[gkey].activity)
      submitGoalData[gkey].activity = [];

    if (!submitGoalData[gkey].previousdate)
      submitGoalData[gkey].previousdate = [];

    if (!submitGoalData[gkey].currentdate)
      submitGoalData[gkey].currentdate = [];

    if (!submitGoalData[gkey].nextdate)
      submitGoalData[gkey].nextdate = [];

    if (!submitGoalData[gkey].alldate)
      submitGoalData[gkey].alldate = [];

    submitGoalData[gkey].id = goaldata.id;

    let pipekey = this.findWithAttr(this.state.activityList, 'id', event.target.value);
    let isavailable = this.findWithAttr(submitGoalData[gkey].activity, 'id', event.target.value);

    if (pipekey != -1 && isavailable == -1) {
      let newactivitykey = submitGoalData[gkey].activity.length;
      submitGoalData[gkey].activity[newactivitykey] = activitylist[pipekey];

      submitGoalData[gkey].previousdate[newactivitykey] = {}
      submitGoalData[gkey].currentdate[newactivitykey] = {}
      submitGoalData[gkey].nextdate[newactivitykey] = {}

      if (!submitGoalData[gkey].alldate[newactivitykey])
        submitGoalData[gkey].alldate[newactivitykey] = [];

      this.state.quarterList.map((quarter) => {
        submitGoalData[gkey].alldate[newactivitykey].push(
          {
            'value': 0,
            'activitykey': newactivitykey,
            'activityid': parseInt(activitylist[pipekey].id),
            'startdate': moment(quarter.start).startOf('quarters').format('YYYY-MM-DD'),
            'enddate': moment(quarter.start).endOf('quarters').format('YYYY-MM-DD')
          }
        )
      })

      this.setState({ submitGoalData })
    }

    let goalParams = [];
    
    goalParams['savegoal'] = {
      goalid: parseInt(goaldata.id),
      goaltype: parseInt(this.state.goaltype),
      datetype: parseInt(this.state.datetype),
      userid: parseInt(goaldata.user.id),
      teamid: (goaldata.user.teamlist && goaldata.user.teamlist.length) ? parseInt(goaldata.user.teamlist[0].team.id) : 0,
      activity: submitGoalData[gkey].activity,
      alldate: submitGoalData[gkey].alldate,
      previousdate: [],
      currentdate: [],
      nextdate: [],
      pipline: [],
    };
    goalParams['type'] = 'Activity';
    this.props.saveGoal(this.props.apolloClient.client, goalParams);
  }

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] == value) {
        return i;
      }
    }
    return -1;
  }

  handleChangegoaldata = (event, gkey, goaldetail, data, alldatakey) => {

    let selectedActivityList = this.state.selectedActivityList;
    let submitGoalData = this.state.submitGoalData;
    let activitylist = this.state.activityList;

    let current_data_key = submitGoalData[gkey].activity.length - 1;

    if (!submitGoalData[gkey])
      submitGoalData[gkey] = [];

    if (!submitGoalData[gkey].alldate)
      submitGoalData[gkey].alldate = [];

    if (!submitGoalData[gkey].goaldata)
      submitGoalData[gkey].goaldata = [];

    if (!submitGoalData[gkey].alldate[data.activitykey])
      submitGoalData[gkey].alldate[data.activitykey] = [];

    submitGoalData[gkey].alldate[data.activitykey][alldatakey].value = event.target.value;

    submitGoalData[gkey].goaldata = goaldetail;

    this.setState({ submitGoalData })

    let goalParams = {
      goalid: parseInt(goaldetail.id),
      goaltype: parseInt(goaldetail.goaltype),
      datetype: parseInt(goaldetail.datetype),
      userid: parseInt(goaldetail.user.id),
      teamid: goaldetail.user.teamlist && goaldetail.user.teamlist.length  ? parseInt(goaldetail.user.teamlist[0].team.id) : 0,
      activity: submitGoalData[gkey].activity,
      alldate: submitGoalData[gkey].alldate,
      previousdate: [],
      currentdate: [],
      nextdate: [],
      pipline: [],
    };


    setTimeout(() => {
      this.props.saveGoal(this.props.apolloClient.client, goalParams);
    }, 500);

  }

  handleDeleteactivityGoal = (deletegoal, activitydata) => {

    this.setState({
      deletegoalmessage: "Are you sure you want to delete " + activitydata.name + " activity from " + deletegoal.user.name + " goal ?",
      isDeleteConfirmDialoOpen: !this.state.isDeleteConfirmDialoOpen,
      goaldelete: deletegoal,
      deleteactivity: activitydata,
    });
  };

  confirmDeleteactivityGoal = () => {
    let goaldelete = this.state.goaldelete;
    let deleteactivity = this.state.deleteactivity;
    let submitGoalData = this.state.submitGoalData;

    let activitykey = this.findWithAttr(goaldelete.activity, 'id', deleteactivity.id);
    let submitgoaldatakey = this.findWithAttr(submitGoalData, 'id', goaldelete.id)

    if (submitgoaldatakey != -1) {
      let submitactivitykey = this.findWithAttr(submitGoalData[submitgoaldatakey].activity, 'id', deleteactivity.id);
      if (submitactivitykey != -1) {
        submitGoalData[submitgoaldatakey].alldate.splice(submitactivitykey, 1);
        submitGoalData[submitgoaldatakey].activity.splice(submitactivitykey, 1);
      }
      // console.log(submitGoalData[submitgoaldatakey],'submitGoalData');
      this.setState({ submitGoalData })
    }

    if (activitykey != -1) {
      goaldelete.alldate.splice(activitykey, 1);
      goaldelete.activity.splice(activitykey, 1);
    }

    let goalParams = [];
    
    goalParams['savegoal'] = {
      goalid: parseInt(goaldelete.id),
      goaltype: parseInt(goaldelete.goaltype),
      datetype: parseInt(goaldelete.datetype),
      userid: parseInt(goaldelete.user.id),
      teamid: (goaldelete.user.teamlist && goaldelete.user.teamlist.lenght > 0) ? parseInt(goaldelete.user.teamlist[0].team.id) : 0,
      activity: goaldelete.activity,
      alldate: goaldelete.alldate,
      pipline: [],
      previousdate: [],
      currentdate: [],
      nextdate: [],
    };
    goalParams['type'] = 'Activity data';
    this.props.saveGoal(this.props.apolloClient.client, goalParams);
    this.closegoalRemoveDialoge()
  }

  handleDeleteGoal = (deletegoal) => {
    this.setState({
      deletegoalmessage: "Are you sure you want to delete " + deletegoal.user.name + " goal ?",
      isDeleteConfirmDialoOpen: !this.state.isDeleteConfirmDialoOpen,
      goaldeleteid: parseInt(deletegoal.id),
    });
  };

  confirmDeleteGoal = () => {
    this.props.deleteGoal(this.props.apolloClient.client, { goalid: parseInt(this.state.goaldeleteid), datetype: this.state.datetype, goaltype: this.state.goaltype });

    this.closegoalRemoveDialoge()
  }

  setthisquarter = () => {
    let date = moment();
    let previousdate = moment(date).subtract("1", "quarters");
    let nextdate = moment(date).add("1", "quarters");
    this.setState({ date, previousdate, nextdate })
  }

  closegoalRemoveDialoge = () => {
    this.setState({ deletegoalmessage: "", isDeleteConfirmDialoOpen: false, goaldeleteid: "", goaldelete: [], deleteactivity: [], })
  }

  handleChangeSelectedList = (event) => {
    let usersListArray = this.state.usersListArray;
    if (event.target.value == 'all-users') {
      // let goalParams = {datetype: parseInt(this.state.datetype), goaltype: parseInt(this.state.goaltype)};
      // this.props.getGoalList(this.props.apolloClient.client, goalParams); 

      this.getGoalList();

      this.setState({ selectedUserList: event.target.value });
    } else {
      let teammembersParams = { teamid: parseInt(event.target.value) };
      // let goalParams = {datetype: parseInt(this.state.datetype), goaltype: parseInt(this.state.goaltype)};
      // this.props.getGoalList(this.props.apolloClient.client, goalParams); 
      this.getGoalList();

      setTimeout(() => {
        this.props.getTeamMember(this.props.apolloClient.client, teammembersParams);
      }, 100);

      this.setState({ selectedUserList: event.target.value });
    }
  }

  // handleOnchange = (event) => {
  //   let selectedActivities = [];
  //   let activityList = this.state.activityList;
  //   event.target.value.map((selected)=>{
  //     let selectedactivitykey = this.findWithAttr(activityList, 'name', selected);
  //     if(selectedactivitykey != -1){
  //       selectedActivities.push(parseInt(activityList[selectedactivitykey].id));
  //     }
  //   })
  //   if(selectedActivities.length > 0){
  //     let allkey = event.target.value.indexOf('All activity');
  //     if(allkey != -1)
  //       event.target.value.splice(allkey, 1);
  //   }

  //   let goalParams = {datetype: parseInt(this.state.datetype), goaltype: parseInt(this.state.goaltype), filterdata: selectedActivities };
  //   this.props.getGoalList(this.props.apolloClient.client, goalParams);

  //   this.setState({ selectedActivityName: event.target.value, selectedActivityIds: selectedActivities })
  // }
  handleOnchange = (event) => {
    let selectedActivities = [];
    let selectednames = [];
    
    if(event.target.value.indexOf('All activity') == (event.target.value.length - 1)){
      selectednames = ['All activity']
    }else{
      let activityList = this.state.activityList;
      
      event.target.value.map((selected) => {
        let selectedactivitykey = this.findWithAttr(activityList, 'name', selected);
        if (selectedactivitykey != -1) {
          selectedActivities.push(parseInt(activityList[selectedactivitykey].id));
        }
      })
      if (selectedActivities.length > 0) {
        let allkey = event.target.value.indexOf('All activity');
        if (allkey != -1)
          event.target.value.splice(allkey, 1);
      }
      selectednames = event.target.value;
    }
    
    
    let goalParams = {datetype: parseInt(this.state.datetype), goaltype: parseInt(this.state.goaltype), filterdata: selectedActivities };
    this.props.getGoalList(this.props.apolloClient.client, goalParams); 
    
    this.setState({ selectedActivityName: selectednames, selectedActivityIds: selectedActivities })
  }

  render() {
    const { teamList, deleteactivity, activitytotal, submitGoalData, selectedActivityList, activityList, goalList, selectedUserList, usersListArray, selectedActivity, shouldOpenSaleRevenueGoalsDialog, minimizeGoalDialog, FullGoalDialog, value, valueone, valuetwo, valuethree, valuefour } = this.state;

    return (
      <React.Fragment>
        <Grid container justify="space-between" className="bg-default">
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                <Button variant="contained" className="btn_Primary" color="primary" onClick={() => this.setState({ shouldOpenSaleRevenueGoalsDialog: true })} size="small" >Add User</Button>
              </Grid>
              <Grid item className="ml-30">
                <Select displayEmpty
                  name="selectedUserList"
                  value={selectedUserList}
                  onChange={this.handleChangeSelectedList}
                  className="w-100x"
                  MenuProps={{ className: 'choose_field_menu' }}
                >
                  <MenuItem value={"all-users"} className="select-menu-item">All Users({goalList.length})</MenuItem>
                  {
                    (teamList.length > 0) && teamList.map((team) => (
                      <MenuItem value={team.id} className="select-menu-item">{team.teamname}</MenuItem>
                    ))
                  }
                </Select>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <Grid item><lable className="text-lable font-size-14" onClick={this.setthisquarter}><Button>THIS QUARTER</Button></lable></Grid>
              <Grid item className="ml-16">{this.quaterFilterList()}</Grid>
              {
                // this.props.goaltype && parseInt(this.props.goaltype) == 3 &&
                <Grid className="ml-16">
                  <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    value={this.state.selectedActivityName}
                    onChange={(event) => this.handleOnchange(event)}
                    renderValue={selected => (
                      <div >
                        {selected.map(value => (
                          <Chip key={value} label={value} />
                        ))}
                      </div>
                    )}
                  >
                    {names.map((name, index) => {
                      return (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={this.state.selectedActivityName.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      )
                    }
                    )}
                  </Select>
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
        <TableContainer className="simple_table h-vh-auto">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="20%">Name</TableCell>
                <TableCell width="10%">Team</TableCell>
                <TableCell width="10%">Activity</TableCell>
                <TableCell align="right" width="20%">{moment(this.state.date).subtract("1", "quarters").format("MMM DD")} - {moment(this.state.date).format("MMM DD YYYY")}</TableCell>
                <TableCell className="text-primary" align="right" width="20%">{this.state.date.format("MMM DD")} - {moment(this.state.date).add("1", "quarters").format("MMM DD YYYY")}</TableCell>
                <TableCell align="right" width="20%">{moment(this.state.date).add("1", "quarters").format("MMM DD")} - {moment(this.state.date).add("2", "quarters").format("MMM DD YYYY")}</TableCell>
                <TableCell align="center" width="10%">ACTION</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(goalList && goalList.length > 0) ?
                goalList.map((goaldata, goalkey) => (
                  <TableRow>
                    <TableCell>
                      <Grid container spacing={1}>
                        {/* <Grid item>
                          <Avatar className={"avatar_circle color_"+ goaldata.user.name.charAt(0).toLowerCase(0)}>{goaldata.user.name.charAt(0).toUpperCase()}</Avatar>
                        </Grid> */}
                        <Grid item>
                          <h6>{goaldata.user.name}</h6>
                          <p>{goaldata.user.email}</p>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      {(goaldata.user.teamlist && goaldata.user.teamlist.length > 0) && goaldata.user.teamlist.map((team, key) => (
                        <>{((goaldata.user.teamlist.length - 1) <= key) ? team.team.teamname : team.team.teamname + ", "}</>
                      ))}
                    </TableCell>
                    <TableCell>
                      {goaldata.activity && goaldata.activity.map((activitydata, activitykey) => (
                        <>
                          <InputLabel className="pt-10 pb-5">{activitydata.name}</InputLabel>
                        </>
                      ))}
                      <FormControl className="w-100 input_bx">
                        <Select
                          className="pt-5"
                          name="selectedActivity"
                          value={"0"}
                          onChange={(event, gkey, goal) => this.handleActivityChange(event, goalkey, goaldata)}
                          MenuProps={{ className: 'choose_field_menu' }}
                        >
                          <MenuItem value="0">Add activity</MenuItem>
                          {activityList && activityList.map((activity) => (
                            (activity.is_disable == false) &&
                            <MenuItem value={activity.id}>{activity.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell align="center">
                      {
                        goaldata.activity && goaldata.activity.map((pipe, pipkey) => (
                          goaldata.alldate[pipkey].map((activitydatas, alldatakey) => (
                            (activitydatas && activitydatas.startdate == moment(this.state.previousdate).startOf('quarters').format('YYYY-MM-DD')) &&
                            <FormControl fullWidth>
                              {/* <InputLabel htmlFor="standard-adornment-amount">Amount</InputLabel> */}
                              <Input
                                id="standard-adornment-amount"
                                value={activitydatas.value && activitydatas.value}
                                type="number"
                                name={"previousdata"}
                                onChange={(event, gkey, goaldetail, previousdata, allkey) => this.handleChangegoaldata(event, goalkey, goaldata, activitydatas, alldatakey)}
                                startAdornment={this.state.goaltype === 1 && <InputAdornment position="start">$</InputAdornment>}
                              />
                            </FormControl>
                          ))
                        ))
                      }
                      <FormControl fullWidth>
                        {/* <InputLabel htmlFor="standard-adornment-amount">Amount</InputLabel> */}
                        <Input
                          id="standard-adornment-amount"
                          type="number"
                          value={"select activity"}
                          disabled={true}
                          name={"previousdata"}
                          onChange={(event, gkey, goaldetail, previousdata) => this.handleChangegoaldata(event, goalkey, goaldata)}
                          startAdornment={this.state.goaltype === 1 && <InputAdornment position="start">$</InputAdornment>}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell align="center">
                      {
                        goaldata.activity && goaldata.activity.map((pipe, pipkey) => (
                          goaldata.alldate[pipkey].map((activitydatas, alldatakey) => (
                            (activitydatas && activitydatas.startdate == moment(this.state.date).startOf('quarters').format('YYYY-MM-DD')) &&
                            <FormControl fullWidth>
                              {/* <InputLabel htmlFor="standard-adornment-amount">Amount</InputLabel> */}
                              <Input
                                id="standard-adornment-amount"
                                value={activitydatas.value && activitydatas.value}
                                type="number"
                                name={"previousdata"}
                                onChange={(event, gkey, goaldetail, previousdata, allkey) => this.handleChangegoaldata(event, goalkey, goaldata, activitydatas, alldatakey)}
                                startAdornment={this.state.goaltype === 1 && <InputAdornment position="start">$</InputAdornment>}
                              />
                            </FormControl>
                          ))
                        ))
                      }
                      <FormControl fullWidth>
                        {/* <InputLabel htmlFor="standard-adornment-amount">Amount</InputLabel> */}
                        <Input
                          id="standard-adornment-amount"
                          type="number"
                          value={"select activity"}
                          disabled={true}
                          name={"previousdata"}
                          onChange={(event, gkey, goaldetail, previousdata) => this.handleChangegoaldata(event, goalkey, goaldata)}
                          startAdornment={this.state.goaltype === 1 && <InputAdornment position="start">$</InputAdornment>}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell align="center">
                      {
                        goaldata.activity && goaldata.activity.map((pipe, pipkey) => (
                          goaldata.alldate[pipkey].map((activitydatas, alldatakey) => (
                            (activitydatas && activitydatas.startdate == moment(this.state.nextdate).startOf('quarters').format('YYYY-MM-DD')) &&
                            <FormControl fullWidth>
                              {/* <InputLabel htmlFor="standard-adornment-amount">Amount</InputLabel> */}
                              <Input
                                id="standard-adornment-amount"
                                value={activitydatas.value && activitydatas.value}
                                type="number"
                                name={"previousdata"}
                                onChange={(event, gkey, goaldetail, previousdata, allkey) => this.handleChangegoaldata(event, goalkey, goaldata, activitydatas, alldatakey)}
                                startAdornment={this.state.goaltype === 1 &&<InputAdornment position="start">$</InputAdornment>}
                              />
                            </FormControl>
                          ))
                        ))
                      }
                      <FormControl fullWidth>
                        {/* <InputLabel htmlFor="standard-adornment-amount">Amount</InputLabel> */}
                        <Input
                          id="standard-adornment-amount"
                          type="number"
                          value={"select activity"}
                          disabled={true}
                          name={"previousdata"}
                          onChange={(event, gkey, goaldetail, previousdata) => this.handleChangegoaldata(event, goalkey, goaldata)}
                          startAdornment={this.state.goaltype === 1 &&<InputAdornment position="start">$</InputAdornment>}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell align="center">
                      {
                        (goaldata.activity && goaldata.activity.length > 0) ?

                          goaldata.activity.map((activity) => (
                            <div className="amount_button cursor-pointer">
                              {/* <IconButton className="pt-10" onClick={(deletegoal) => this.handleDeleteGoal(goaldata)}> */}
                              <IconSmall className="mt-10 mb-5" onClick={(deletegoal, activitydata) => this.handleDeleteactivityGoal(goaldata, activity)}>clear</IconSmall>
                              {/* </IconButton> */}
                            </div>
                          ))
                          :
                          <div className="amount_button">
                            <IconButton className="pt-10" onClick={(deletegoal) => this.handleDeleteGoal(goaldata)}><IconSmall>delete</IconSmall></IconButton>
                          </div>

                      }
                    </TableCell>
                  </TableRow>
                ))
                :
                <TableRow>
                <TableCell colSpan="7">
                  <LoadingState
                    icon={"flag"}
                    title="No Goals Added Yet."
                    subtitle="Click ADD USER to get started setting goals"
                  />
                </TableCell>
              </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
        {(goalList && goalList.length > 0) &&
          <TableContainer className="simple_table">
            <Table>
              <TableFooter>
                <TableRow rowSpan={activityList.length}>
                  <TableCell width="25%" rowSpan={activityList.length + 1}>GOAL TOTAL</TableCell>
                </TableRow>
                {(activityList && activityList.length) && activityList.map((pipe, pipkey) => (
                  (pipe.is_disable == false) &&
                  <TableRow>
                    <TableCell width="10%"></TableCell>
                    <TableCell width="15%">
                      {
                        <div>{pipe.name}</div>
                      }
                    </TableCell>
                    <TableCell align="center" width="15%">
                      {
                        // (activityList && activityList.length) && activityList.map((pipe, pipkey)=>(
                        activitytotal && activitytotal.map((total, totalkey) => (
                          ((total && total[pipe.id]) && total[pipe.id].startdate == moment(this.state.previousdate).startOf('quarters').format('YYYY-MM-DD')) &&
                          <div>{total[pipe.id].totalValue}</div>
                        ))
                        // ))
                      }
                    </TableCell>
                    <TableCell align="center" width="15%">
                      {
                        // (activityList && activityList.length) && activityList.map((pipe, pipkey)=>(
                        activitytotal && activitytotal.map((total, totalkey) => (
                          ((total && total[pipe.id]) && total[pipe.id].startdate == moment(this.state.date).startOf('quarters').format('YYYY-MM-DD')) &&
                          <div>{total[pipe.id].totalValue}</div>
                        ))
                        // ))
                      }
                    </TableCell>
                    <TableCell align="center" width="15%">
                      {
                        // (activityList && activityList.length) && activityList.map((pipe, pipkey)=>(
                        activitytotal && activitytotal.map((total, totalkey) => (
                          ((total && total[pipe.id]) && total[pipe.id].startdate == moment(this.state.nextdate).startOf('quarters').format('YYYY-MM-DD')) &&
                          <div>{total[pipe.id].totalValue}</div>
                        ))
                        // ))
                      }
                    </TableCell>
                    <TableCell width="5%"></TableCell>
                  </TableRow>
                ))
                }
              </TableFooter>
            </Table>
          </TableContainer>
        }
        {shouldOpenSaleRevenueGoalsDialog && (
          <GoalsDialog
            handleClose={this.handleGoalDialogClose}
            open={shouldOpenSaleRevenueGoalsDialog}
            handleMinimize={this.handleGoalDialogMinimize}
            handleFull={this.handleGoalDialogFull}
            minimizeScreen={minimizeGoalDialog}
            fullScreen={FullGoalDialog}
            data={this}
          />
        )}
        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={this.state.isDeleteConfirmDialoOpen}
          onClose={this.closegoalRemoveDialoge}
          disableBackdropClick
          aria-labelledby="ar-remove-dialog-title"
        >
          <DialogTitle id="ar-remove-dialog-title">{this.state.deletegoalmessage}</DialogTitle>
          <DialogActions>
            <Button onClick={this.closegoalRemoveDialoge} color="primary">Cancel</Button>
            {
              (deleteactivity && deleteactivity.id) ?
                <Button type="Button" onClick={() => this.confirmDeleteactivityGoal()} className="btn_Primary"> Ok </Button>
                :
                <Button type="Button" onClick={() => this.confirmDeleteGoal()} className="btn_Primary"> Ok </Button>
            }
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
});

const mapDispatchToProps = dispatch => {
  return {
    saveGoal: (client, request) => dispatch(actionCreators.saveGoal(client, request)),
    deleteGoal: (client, request) => dispatch(actionCreators.deleteGoal(client, request)),

    getactivitys: (client) => dispatch(actionCreators.getPipelines(client)),
    getUserList: (client) => dispatch(actionCreators.getUserList(client)),
    getGoalList: (client, request) => dispatch(actionCreators.getGoalList(client, request)),
    getTeamMember: (client, request) => dispatch(actionCreators.getTeamMember(client, request)),
    getActivityTypes: (client) => dispatch(actionCreators.getActivityTypes(client)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuarterlyActivityGoals);