import React, { Component } from "react";
import {
  Dialog, IconButton, Icon, Tooltip, Grid,
  Menu, MenuItem, Fab, withStyles
} from "@material-ui/core";

const TooltipNew = withStyles((theme) => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);

class CreatelogDialog extends Component {
  state = {
    minimizeViewEmailDialog: false,
    FullViewEmailDialog: false
  }

  handleViewEmailDialogMinimize = () => {
    this.setState(oldStateminimizeViewEmailDialog => ({ minimizeViewEmailDialog: !oldStateminimizeViewEmailDialog.minimizeViewEmailDialog }));
    this.setState({ FullViewEmailDialog: false })
  };

  handleViewEmailDialogFull = () => {
    this.setState(oldStateFullViewEmailDialog => ({ FullViewEmailDialog: !oldStateFullViewEmailDialog.FullViewEmailDialog }));
    this.setState({ minimizeViewEmailDialog: false })
  };


  render() {
    var user_data = JSON.parse(localStorage.getItem('user_data_session'))
    let colorClass = user_data && user_data.name ? user_data.name.charAt(0).toLowerCase() : "";
    let { open, handleClose } = this.props;
    let { minimizeViewEmailDialog, FullViewEmailDialog } = this.state;

    console.log(this.props.logDatas, 'this.props.logDatas');

    return (
      <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth={true} className={`crm-custom-bg-primary custom_dialog ${minimizeViewEmailDialog && FullViewEmailDialog ? 'alignitemsend-fullscreen' : ''} ${minimizeViewEmailDialog ? 'alignitemsend' : ''} ${FullViewEmailDialog ? 'fullscreen' : ''}`} disableBackdropClick >
        <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">
          <h4 className="m-0 text-white">{this.props.logDatas.subject}</h4>
          <div className="d-flex">
            <TooltipNew title={minimizeViewEmailDialog ? 'Normal Screen' : 'Minimize Screen'}>
              <IconButton onClick={this.handleViewEmailDialogMinimize}>
                <Icon className="text-white">{minimizeViewEmailDialog ? 'add' : 'remove'}</Icon>
              </IconButton>
            </TooltipNew>
            <TooltipNew title={FullViewEmailDialog ? 'Exit Full Screen' : 'Full Screen'}>
              <IconButton onClick={this.handleViewEmailDialogFull}>
                <Icon className="text-white">{FullViewEmailDialog ? 'fullscreen_exit' : 'fullscreen'}</Icon>
              </IconButton>
            </TooltipNew>
            <TooltipNew title="Close">
              <IconButton onClick={handleClose}>
                <Icon className="text-white">clear</Icon>
              </IconButton>
            </TooltipNew>
          </div>
        </div>
        <div className="dialog-body">
          <div className="flex-auto cursor_poiter">
            <Grid container className="mb-10 cursor_poiter" spacing={1}>
              <Grid item>
                <Fab className="small-size overflow-hidden mr-8 mt-10" color="secondary" size="small">
                  <h5
                    className={"text-white m-0 font-weight-normal avatar_circle color_" + colorClass}>
                    {colorClass.toUpperCase()}
                  </h5>
                </Fab>
              </Grid>
              <Grid item className="mt-20">
                {(this.props.logDatas.email_data && this.props.logDatas.email_data.length > 0 &&
                  this.props.logDatas.email_data[0].email_type == 2) ?
                  <h5 className="mb-0">You to {" "}
                    {this.props.logDatas.email_data.map((data, i) => (
                      data.email_type !== 1 &&
                      (i > 1 ? "," : "") + data.email
                    ))}</h5>
                  :
                  <h5 className="mb-0">{this.props.logDatas.name} to You</h5>
                }

              </Grid>
            </Grid>
          </div>
          <div className="mt-30">
            <h6>{this.props.logDatas.subject}</h6>
            <div dangerouslySetInnerHTML={{ __html: this.props.logDatas.body_html }} />
          </div>
        </div>
        <div className="dialog-action">
          <div className="mt-20">
            {(this.props.logDatas.attachment && this.props.logDatas.attachment.length > 0) &&
              this.props.logDatas.attachment.map((attach) => (
                <>
                  <span onClick={this.handleDownloadClick} className="crm-email-log-attachment">
                    <IconButton size="small">
                      <Icon>attach_file</Icon>
                    </IconButton>
                    {attach.file_name}
                    <IconButton size="small">
                      <Icon>arrow_drop_down</Icon>
                    </IconButton>
                  </span>
                  <Menu
                    id="long-menu"
                    anchorEl={this.state.Downloadoptions}
                    open={this.state.showDownloadoptions}
                    onClose={this.handleCloseDownload}
                  >
                    <MenuItem onClick={this.handleCloseDownload}>
                      <a href={attach.s3_file_name} target="__blank" className="text-center">
                        Download
                        </a>
                    </MenuItem>
                    <MenuItem onClick={this.handleCloseDownload}>
                      <span className="font-size-13 mr-10 mb-5">Relate To Lead</span>
                    </MenuItem>
                  </Menu>
                </>
              ))
            }
          </div>
        </div>
      </Dialog>
    );
  }
}

export default CreatelogDialog;
