import React, { Component } from "react";
import { Chip, Switch, InputLabel, FormControl, Input, FormHelperText, RadioGroup, Radio, FormControlLabel, Divider, DialogContent, InputAdornment, Button, IconButton, Icon, MenuItem, Card, TextField, Dialog, DialogActions, DialogTitle, Drawer, Avatar } from "@material-ui/core";
import { default as Grid } from '@material-ui/core/Grid';
import DropdownDefault from "../Layout/DropdownDefault";
import { Link } from "react-router-dom";
import CreateNewOpportunityDialog from "./CreateNewOpportunityDialog";
import OpportunityProfileFrame from "./OpportunityProfileFrame";
import moment from 'moment';//moment
import 'moment-timezone';//timezone
import AddNewPipeline from '../Settings/AddNewPipeline'
import { FilterComponent } from './FilterComponent'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";


import * as actions from '../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import "../../styles/_opportunities.scss";

class ProgressionReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            switchView: true,
            drawerflag: false,
            searchText: '',
            deleteIds: [],
            currentPage: 0,
            pageSize: 10,
            column_name: "",
            sorttype: "",
            fieldId: 1,
            isDeleteConfirmDialoOpen: false,
            defaultCurrentpage: 1,
            totalCount: 0,
            opportunityListData: [],
            updateColumnOrder: true,
            defaultColumnOrder: ["title", "email", "owner", "phones", "company", "status", "visibility", "statusduration", "statusupdatedate", "lastcontacted", "interactions", "inactivedays"],
            column_ids: [],
            isOpenedPipelines: false,
            isOpenedFilters: false,
            isOpenedFiltersDefault: false,
            isOpenedRange: false,
            shouldOpenCreateOpportunityDialog: false,
            FullCreateOpportunityDialog: false,
            minimizeOpportunityDialog: false,
            defaulSortingData: [
                { columnName: 'name', direction: 'asc' },
                { columnName: 'visibility', direction: 'desc' },
                { columnName: 'company', direction: 'desc' },
                { columnName: 'title', direction: 'desc' },
                { columnName: 'email', direction: 'desc' },
                { columnName: 'owner', direction: 'desc' },
                { columnName: 'phones', direction: 'desc' },
                { columnName: 'status', direction: 'desc' },
                { columnName: 'statusduration', direction: 'desc' },
                { columnName: 'statusupdatedate', direction: 'desc' },
                { columnName: 'lastcontacted', direction: 'desc' },
                { columnName: 'interactions', direction: 'desc' },
                { columnName: 'inactivedays', direction: 'desc' },
            ],
            selection: [],
            leadLogs: [],
            filterObject: {},
            systemTagsList: [],
            systemCityListArray: [],
            systemStateListArray: [],
            systemZipcodeListArray: [],
            countryListArray: [],
            fieldDisplay: [],
            filterRangeArray: [],
            dropdownhide: [],
            filterSaveDialoge: false,
            fitertype: "1",
            filtername: "",
            recordtypeid: 4,
            filternameError: false,
            opportunityFilterListArray: [],
            displaySelectedFilterName: "All Opportunity",
            filterUpdateId: "",
            allowStateUpdateFilter: true,
            filterdata: [],
            activityquery: "any",
            tagsquery: "any",
            dateformat: 'MM/DD/YYYY',
            timeZone: 'America/New_York',
            usersListArray: [],
            AllOpportunityFilterKey: "",
            columnWidths: [
                { columnName: "name", width: 250 },
                { columnName: "title", width: 180 },
                { columnName: "email", width: 180 },
                { columnName: "company", width: 180 },
                { columnName: "owner", width: 180 },
                { columnName: "phones", width: 180 },
                { columnName: "status", width: 180 },
                { columnName: "visibility", width: 180 },
                { columnName: "statusduration", width: 120 },
                { columnName: "statusupdatedate", width: 180 },
                { columnName: "lastcontacted", width: 180 },
                { columnName: "interactions", width: 180 },
                { columnName: "inactivedays", width: 180 }
            ],
            isDataLoading: true,
            newSavedFilterName: "",
            /*START For Add Pipeline Dialog*/
            shouldOpenNewPipelineDialog: false,
            FullNewPipelineDialog: false,
            minimizePipelineDialog: false,
            isLoadingPipelineList: true,
            selectedPipelineId: 1,
            selectedPipelineName: "",
            isLoadingPipelineList: true,
            pipelineListArray: [],
            /*END For Add Pipeline Dialog*/
            stageArray: [],
            openTotal: 0,
            wonTotal: 0,
            lostTotal: 0,
            abandonedTotal: 0,
            valueTotal: 0,
            companyCurrencyData: [],
            userSettings: "",
        };
    }

    componentDidMount() {
        let userData = JSON.parse(localStorage.getItem('user_data_session'))
        let userprefparams = { userid: userData.id }
        let val1 = { typeId: 4 }
        this.props.getCompanyCurrency(this.props.apolloClient.client);
        this.props.getUserSetting(this.props.apolloClient.client, userprefparams);
        this.props.customFieldList(this.props.apolloClient.client, val1);
        this.props.getCityList(this.props.apolloClient.client);
        this.props.getFilterListOpportunity(this.props.apolloClient.client, 4);
        this.props.getStateList(this.props.apolloClient.client);
        this.props.getZipcodeList(this.props.apolloClient.client);
        this.props.getUserList(this.props.apolloClient.client);
        var pipelineId = this.state.selectedPipelineId;
        if (localStorage.getItem('selected_pipeline_id')) {
            pipelineId = localStorage.getItem('selected_pipeline_id')
        } else {
            localStorage.setItem('selected_pipeline_id', this.state.selectedPipelineId)
            pipelineId = localStorage.getItem('selected_pipeline_id')
        }
        let val = {
            filterdata: this.state.filterdata,
            list: {
                limit: this.state.pageSize,
                pageno: 1,
                searchtext: this.state.searchText,
                sorttype: this.state.sorttype,
                type: "list",
                fieldId: this.state.fieldId,
                columnname: this.state.column_name
            },
            piplineid: parseFloat(pipelineId),
            filterid: 1,
        }
        this.getListing(val)
        this.props.getPipelines(this.props.apolloClient.client);
        // let params_customfield = { typeId:1 }
        // this.props.customFieldList(this.props.apolloClient.client, params_customfield);

    }
    getListing = (val) => {
        this.props.getOpportunitiesFilterList(this.props.apolloClient.client, val);
        //this.props.getLeadListColumnOrder(this.props.apolloClient.client);
    }
    goBack = () => {
        window.history.back();
    }

    componentWillReceiveProps(props) {
        //storing user setting data in state
        if (props.settingReducer.userSettingArray.data && this.state.userSettings == "") {
            const settingData = props.settingReducer.userSettingArray.data;
            this.setState({
                userSettings: settingData,
            })
        }
        if (props.settingReducer.companyCurrencyData.data != null) {
            var cData = props.settingReducer.companyCurrencyData.data;
            this.setState({ companyCurrencyData: cData[0] });
        }
        if (props.opportunityReducer.opportunityList.data && props.opportunityReducer.opportunityList.is_loaded) {
            let oppoList = props.opportunityReducer.opportunityList.data;

            //Pipelines list array
            if (props.settingReducer.pipelineListArray.data && props.settingReducer.pipelineListArray.is_loaded) {
                var pipeData = props.settingReducer.pipelineListArray.data
                //Apply pipeline id to localstorage
                var selectedPipelineId = localStorage.getItem('selected_pipeline_id');
                if (!selectedPipelineId) {
                    localStorage.setItem('selected_pipeline_id', "1");
                } else {
                    selectedPipelineId = localStorage.getItem('selected_pipeline_id');
                }
                selectedPipelineId = localStorage.getItem('selected_pipeline_id');
                let allData = pipeData;
                if (props.settingReducer.userPermissionDetails.data) {
                    let udata = props.settingReducer.userPermissionDetails.data.data
                    if (udata.visibilityaccess.length > 0 &&
                        udata.teampermissionstatus === true &&
                        props.authReducer.userRole.id === 3) {
                        allData = [];
                        pipeData.map((val, k) => {
                            let dt = udata.visibilityaccess.find(function (e) { return e.piplineid === parseInt(val.id) });
                            if (dt && dt.isaccess) {
                                localStorage.setItem('selected_pipeline_id', val.id);
                                this.setState({ selectedPipelineId: val.id })
                                allData.push(val)
                            }
                        })
                    }
                }

                var key = this.findWithAttr(allData, 'id', selectedPipelineId);
                this.setState({
                    pipelineListArray: allData,
                    isLoadingPipelineList: false,
                    selectedPipelineId: selectedPipelineId,
                    selectedPipelineName: allData[key].name,
                    //isDataLoading: false,
                }, () => {
                    this.forceUpdate()
                    //props.settingReducer.pipelineListArray.is_loaded = false
                })
            }

        }
        if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
            const usersListArray = props.settingReducer.usersListArray.data;
            this.setState({ usersListArray: usersListArray })
        }
        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            let usersetting = props.settingReducer.userSettingArray.data;

            let format = usersetting.dateformat;
            let timezone = usersetting.timezone;

            this.setState({ dateformat: format, timeZone: timezone })
        }

        if (props.opportunityReducer.opportunityFilterListArray.data && this.state.allowStateUpdateFilter || props.opportunityReducer.opportunityFilterListArray.is_loaded) {
            const opportunityFilterListArray = props.opportunityReducer.opportunityFilterListArray.data;
            props.opportunityReducer.opportunityFilterListArray.is_loaded = false
            this.setState({ opportunityFilterListArray: opportunityFilterListArray, allowStateUpdateFilter: false })

            var AllfilterIdKey = this.findWithAttr(opportunityFilterListArray, 'id', "11");
            if (AllfilterIdKey != -1) {
                this.setState({ AllOpportunityFilterKey: AllfilterIdKey })
            }


            //Aplying default filter selected  STRAT

            let defaultFilter;
            //checkdefault filter  in localStorage
            var localStorageFilterId = localStorage.getItem('selected_opportunity_filter_id');

            if (localStorageFilterId) {
                var localId = this.findWithAttr(opportunityFilterListArray, 'id', localStorageFilterId);
                defaultFilter = opportunityFilterListArray[localId];
            } else {
                var defaultKeyStatus = this.findWithAttr(opportunityFilterListArray, 'id', "11");
                defaultFilter = opportunityFilterListArray[defaultKeyStatus];
                localStorage.setItem('selected_opportunity_filter_id', defaultFilter.id);
            }

            //my open oppoertunity
            if (defaultFilter.id == "12") {
                let udata = JSON.parse(localStorage.getItem('user_data_session'))
                defaultFilter.filteroption = [
                    {
                        fieldid: "46", name: "owner", type: "value", value: [udata.email]
                    },
                    {
                        fieldid: "45", name: "status", type: "value", value: ["Open"]
                    }
                ]
            }

            //Opportunities Im Following
            if (defaultFilter.id == "14") {
                defaultFilter.filteroption = [
                    {
                        fieldid: "0",
                        name: "followed",
                        type: "followed",
                        value: [true],
                        chktype: "follow",
                    }
                ]
            }

            //won opportunity
            if (defaultFilter.id == "15") {
                let udata = JSON.parse(localStorage.getItem('user_data_session'))
                defaultFilter.filteroption = [
                    {
                        fieldid: "45", name: "status", type: "value", value: ["Won"]
                    }
                ]
            }
            //Open opportunity
            if (defaultFilter.id == "13") {
                let udata = JSON.parse(localStorage.getItem('user_data_session'))
                defaultFilter.filteroption = [
                    {
                        fieldid: "45", name: "status", type: "value", value: ["Open"]
                    }
                ]
            }

            //updateFilter name to display and menudisplay array set
            this.setState({
                displaySelectedFilterName: this.state.newSavedFilterName ? this.state.newSavedFilterName : defaultFilter.filtername,
                fieldDisplay: [],
                dropdownhide: [],
            })

            if (defaultFilter.filteroption.length > 0) {
                let filterObject = this.state.filterObject;
                let filterRangeArray = this.state.filterRangeArray;
                let displayField = []
                let dropdownhideShow = []
                var promise = new Promise((resolve, reject) => {
                    defaultFilter.filteroption.map((val, k) => {
                        filterObject = {
                            ...filterObject,
                            [val.name]: val
                        }

                        if (val.name == "tags") {
                            this.setState({ tagsquery: val.query })
                        }
                        if (val.name == "activitytype") {
                            this.setState({ activityquery: val.query })
                        }
                        if (val.type === "range") {
                            filterRangeArray[val.name] = ["", ""];
                            filterRangeArray[val.name][0] = val.value[0];
                            filterRangeArray[val.name][1] = val.value[1];
                            this.setState({ filterRangeArray })
                            displayField.push(val.name)
                        }
                        if (val.type === "date") {
                            filterRangeArray[val.name] = ["", ""];
                            filterRangeArray[val.name][0] = val.value[0] == "" ? null : val.value[0];
                            filterRangeArray[val.name][1] = val.value[1] == "" ? null : val.value[1];
                            this.setState({ filterRangeArray })
                            if (val.value[0] != "" && val.value[1] != "") {
                                displayField.push(val.name)
                            } else if (val.value[0] != "" || val.value[1] != "") {
                                displayField.push(val.name)
                            }
                        }
                        if (val.name == "zipcode" || val.name == "city" || val.name == "state" || val.name == "country") {//hideshow ENABLE state/city/country/state
                            dropdownhideShow.push(val.name)
                        } else {
                            dropdownhideShow.push(val.name)
                        }
                    })
                    this.setState({
                        filterObject,
                        fieldDisplay: displayField,
                        dropdownhide: dropdownhideShow,
                        newSavedFilterName: "",
                    }, () => this.forceUpdate())
                    resolve(true)
                });

                promise.then(result => {
                    if (localStorage.getItem('selected_pipeline_id')) {
                        this.setState({
                            selectedPipelineId: localStorage.getItem('selected_pipeline_id'),
                        }, () => this.forceUpdate())
                    }
                    this.handleFilterSubmit();
                });
            }
            //Aplying default filter selected  END
        }

        if (props.opportunityReducer.leadLogList && props.opportunityReducer.leadLogList.data && props.opportunityReducer.leadLogList.data.logdata) {
            this.setState({ leadLogs: props.opportunityReducer.leadLogList.data.logdata })
        }

        if (props.settingReducer.opportunitiesCustomFieldList.data) {
            const customFields = props.settingReducer.opportunitiesCustomFieldList.data;
            this.setState({ fieldsList: customFields })
        }
        if (props.settingReducer.systemCityListArray && props.settingReducer.systemCityListArray.data && this.state.systemCityListArray.length == 0) {
            this.setState({ systemCityListArray: props.settingReducer.systemCityListArray.data })
        }
        if (props.settingReducer.systemZipcodeListArray && props.settingReducer.systemZipcodeListArray.data && this.state.systemZipcodeListArray.length == 0) {
            this.setState({ systemZipcodeListArray: props.settingReducer.systemZipcodeListArray.data })
        }
        if (props.settingReducer.systemStateListArray && props.settingReducer.systemStateListArray.data && this.state.systemStateListArray.length == 0) {
            this.setState({ systemStateListArray: props.settingReducer.systemStateListArray.data })
        }
        if (props.settingReducer.countryListGql.data && this.state.countryListArray.length == 0) {
            const countryies = props.settingReducer.countryListGql.data;
            this.setState({ countryListArray: countryies })
        }

        if (props.settingReducer.systemTagsList.data && this.state.systemTagsList.length == 0) {
            const tagsData = props.settingReducer.systemTagsList.data;
            this.setState({ systemTagsList: tagsData })
        }

        if (props.opportunityReducer.opportunityFieldData.data && props.opportunityReducer.opportunityFieldData.is_loaded) {
        }
        if (props.opportunityReducer.opportunityList.data && props.opportunityReducer.opportunityList.is_loaded) {
            let oppoList = props.opportunityReducer.opportunityList.data;
            //Grand Total values
            var valueTotal = 0
            var grandTotalWon = oppoList.data.filter(function (element) {
                if (element.status && element.status[0].id == "1") {
                    return element
                }
            });
            var grandTotalAbandoned = oppoList.data.filter(function (element) {
                if (element.status && element.status[0].id == "2") {
                    return element
                }
            });
            var grandTotalLost = oppoList.data.filter(function (element) {
                if (element.status && element.status[0].id == "3") {
                    return element
                }
            });
            var grandTotalOpen = oppoList.data.filter(function (element) {
                if (element.status && element.status[0].id == "4") {
                    return element
                }
            });

            oppoList.data.filter(function (element) {
                if (element.value && element.value.length > 0) {
                    valueTotal = valueTotal + parseInt(element.value[0].value)
                }
            });
            this.setState({
                openTotal: grandTotalOpen.length,
                wonTotal: grandTotalWon.length,
                lostTotal: grandTotalLost.length,
                abandonedTotal: grandTotalAbandoned.length,
                valueTotal: valueTotal,
            }, () => {
                this.forceUpdate()
            })


            //Pipelines list array
            if (props.settingReducer.pipelineListArray.data && props.settingReducer.pipelineListArray.is_loaded) {
                var pipeData = props.settingReducer.pipelineListArray.data
                //Apply pipeline id to localstorage
                var selectedPipelineId = localStorage.getItem('selected_pipeline_id');
                if (!selectedPipelineId) {
                    localStorage.setItem('selected_pipeline_id', "1");
                } else {
                    selectedPipelineId = localStorage.getItem('selected_pipeline_id');
                }
                selectedPipelineId = localStorage.getItem('selected_pipeline_id');
                var pipeKey = this.findWithAttr(pipeData, 'id', selectedPipelineId);

                this.setState({
                    stageArray: pipeData[pipeKey].stage
                }, () => {
                    this.forceUpdate()
                    var promise = new Promise((resolve, reject) => {
                        if (pipeKey !== -1) {
                            this.state.stageArray.map((v, k) => {
                                var stageTotal = 0
                                var elements = oppoList.data.filter(function (element) {
                                    if (element.stage[0].id == v.id) {
                                        return element
                                    }
                                });
                                var wonCount = elements.filter(function (element) {
                                    if (element.status && element.status[0].id == "1") {
                                        return element
                                    }
                                });
                                var abandonedCount = elements.filter(function (element) {
                                    if (element.status && element.status[0].id == "2") {
                                        return element
                                    }
                                });
                                var lostCount = elements.filter(function (element) {
                                    if (element.status && element.status[0].id == "3") {
                                        return element
                                    }
                                });
                                var openCount = elements.filter(function (element) {
                                    if (element.status && element.status[0].id == "4") {
                                        return element
                                    }
                                });
                                elements.filter(function (element) {
                                    if (element.value && element.value.length > 0) {
                                        stageTotal = stageTotal + parseInt(element.value[0].value)
                                    }
                                });
                                v.dataList = elements;
                                v.woncount = wonCount.length;
                                v.abandonedcount = abandonedCount.length;
                                v.lostcount = lostCount.length;
                                v.opencount = openCount.length;
                                v.stagetotal = stageTotal;
                            })
                        }
                        resolve(1)
                    })

                    promise.then(result => {
                        props.opportunityReducer.opportunityList.is_loaded = false;
                        this.setState({
                            isDataLoading: false,
                        }, () => {
                            this.forceUpdate()
                        })
                    });
                })

            }

        }


    }
    onColumnWidthsChange = (widths) => {
        console.log(widths, 'widthswidthswidths');

    }
    handleChange = (event) => {
        if (event.target.name === 'switchView') {
            let path = `/opportunities/opportunities-card`;
            this.props.history.push(path);
        }
        if (event.target.type === 'checkbox') {
            this.setState({ [event.target.name]: event.target.checked });
        } else {
            this.setState({ [event.target.name]: event.target.value });
        }
    }
    handleChangeColumnOrder = (val) => {

        let arr_ids = [];
        let arr_orders = [];
        let arr_status = [];
        let columns = [];

        val.map((col, key) => {

            if (col == 'statusupdatedate' || col == 'statusduration' || col == 'interactions' || col == 'inactivedays' || col == 'lastcontacted') {
                arr_ids.push(this.props.opportunityReducer.lead_column_order_extra[0][col])
                arr_orders.push(key + 1);
                arr_status.push(true)
                columns.push(col);
            }
            else {
                arr_ids.push(this.state.column_ids[col]);
                arr_orders.push(key + 1);
                arr_status.push(true)
                columns.push(col);
            }
        });

        this.setState({ updateColumnOrder: false, defaultColumnOrder: columns });
        let changeColumnOrderparams = {
            fieldid: arr_ids,
            columnstatus: arr_status,
            orderno: arr_orders
        };
        this.props.updateLeadListColumnOrder(this.props.apolloClient.client, changeColumnOrderparams);

    }

    handleFavorite = (opportunity, status) => {
        let setFavoriteParams = [];
        setFavoriteParams['favorite'] = { status: !status, opportunitiesid: opportunity };
        setFavoriteParams['listval'] = {
            filterdata: this.state.filterdata,
            list: {
                limit: this.state.pageSize,
                pageno: this.state.currentPage + 1,
                searchtext: this.state.searchText,
                sorttype: this.state.sorttype,
                type: "list",
                fieldId: this.state.fieldId,
                columnname: this.state.column_name
            },
            piplineid: parseFloat(this.state.selectedPipelineId),
            filterid: 1
        };


        this.props.addFavouriteOpportunities(this.props.apolloClient.client, setFavoriteParams);
    }

    handleCurrentPage = (page) => {
        this.setState({
            currentPage: page,
            selection: [],
        })

        let params_for_pagechange = {
            filterdata: this.state.filterdata,
            list: {
                limit: this.state.pageSize,
                pageno: page + 1,
                searchtext: this.state.searchText,
                sorttype: this.state.sorttype,
                type: "list",
                fieldId: this.state.fieldId,
                columnname: this.state.column_name
            },
            piplineid: parseFloat(this.state.selectedPipelineId),
            filterid: 1
        }
        this.getListing(params_for_pagechange);
    }
    handlePageSize = (size) => {

        this.setState({
            pageSize: size,
            currentPage: 0,
            selection: [],
        })

        let params_for_pagesize = {
            filterdata: this.state.filterdata,
            list: {
                limit: size,
                pageno: 1,
                searchtext: this.state.searchText,
                sorttype: this.state.sorttype,
                type: "list",
                fieldId: this.state.fieldId,
                columnname: this.state.column_name
            },
            piplineid: parseFloat(this.state.selectedPipelineId),
            filterid: 1
        }
        this.getListing(params_for_pagesize);
    }

    toggleLeadList = () => {
        this.setState(oldStateLeadList => ({ isOpenedPipelines: !oldStateLeadList.isOpenedPipelines }));
    }
    toggleFiltersDefault = () => {
        this.setState(oldStateLeadList => ({ isOpenedFiltersDefault: !oldStateLeadList.isOpenedFiltersDefault }));
    }
    toggleCustomFilters = () => {
        this.setState(oldStateCustomFilters => ({ isOpenedFilters: !oldStateCustomFilters.isOpenedFilters }));
    }
    toggleRange = (e) => {
        e.preventDefault();
        this.setState(oldStateRange => ({ isOpenedRange: !oldStateRange.isOpenedRange }));
    }
    changeDefaultSorting = (val) => {
        let column = val[0].columnName;
        let direction = val[0].direction;

        let data = this.state.defaulSortingData;
        data = data.filter(sort => sort.columnName !== val[0].columnName).map(sort => {
            sort.direction = "desc"
            return sort;
        })
        data.push(val[0]);
        this.setState({
            defaulSortingData: data,
            sorttype: direction
        }, () => this.forceUpdate)

        if (column == 'statusupdatedate' || column == 'statusduration' || column == 'interactions' || column == 'inactivedays' || column == 'lastcontacted') {
            let params_for_sort = {
                filterdata: this.state.filterdata,
                list: {
                    limit: this.state.pageSize,
                    pageno: this.state.currentPage + 1,
                    searchtext: this.state.searchText,
                    sorttype: direction,
                    type: "sort",
                    fieldId: 0,
                    columnname: column
                },
                piplineid: parseFloat(this.state.selectedPipelineId),
                filterid: 1
            }
            this.getListing(params_for_sort);
            this.setState({ fieldId: 0, column_name: column });
        } else if (this.state.column_ids[column]) {
            let params_for_sort = {
                filterdata: this.state.filterdata,
                list: {
                    limit: this.state.pageSize,
                    pageno: this.state.currentPage + 1,
                    searchtext: this.state.searchText,
                    sorttype: direction,
                    type: "sort",
                    fieldId: this.state.column_ids[column],
                    columnname: column
                },
                piplineid: parseFloat(this.state.selectedPipelineId),
                filterid: 1
            }
            this.getListing(params_for_sort);
            this.setState({ fieldId: this.state.column_ids[column], column_name: column });
        }

    }

    changeSearching1 = (e) => {
        let params_for_search = {
            filterdata: this.state.filterdata,
            list: {
                limit: this.state.pageSize,
                pageno: 1,
                searchtext: e.target.value,
                sorttype: this.state.sorttype,
                type: "search",
                fieldId: this.state.fieldId,
                columnname: this.state.column_name
            },
            piplineid: parseFloat(this.state.selectedPipelineId),
            filterid: 1
        }
        this.getListing(params_for_search);
        this.setState({ searchText: e.target.value });
    }


    handleOpportunityDialogClose = () => {
        this.setState({ shouldOpenCreateOpportunityDialog: false, FullCreateOpportunityDialog: false, minimizeOpportunityDialog: false });
    };
    handleOpportunityDialogMinimize = () => {
        this.setState(oldStateminimizeOpportunityDialog => ({ minimizeOpportunityDialog: !oldStateminimizeOpportunityDialog.minimizeOpportunityDialog }));
        this.setState({ FullCreateOpportunityDialog: false })
    };
    handleLeadDialogFull = () => {
        this.setState(oldStateFullCreateOpportunityDialog => ({ FullCreateOpportunityDialog: !oldStateFullCreateOpportunityDialog.FullCreateOpportunityDialog }));
        this.setState({ minimizeOpportunityDialog: false })

    };
    setSelection = (val) => {
        let selectedIds = [];

        if (this.props.opportunityReducer.opportunityList.data.data) {
            let list_data = this.props.opportunityReducer.opportunityList.data.data;
            val.map((key) => {
                selectedIds.push(parseFloat(list_data[key].opportunities_id));
            });
        }
        this.setState({
            selection: val,
            deleteIds: selectedIds
        });
    }
    handleDrawerRight = (opportunities_id) => {
        if (this.state.opportunities_id && (this.state.opportunities_id === opportunities_id)) { } else {
            this.setState({
                opportunities_id: opportunities_id,
                isDataLoading: true,
            }, () => {
                this.forceUpdate()
            })
        }

        setTimeout(() => {
            this.setState({
                drawerOpen: true,
            }, () => {
                this.forceUpdate()
            })
        }, 2000);

    };
    closeDrawerRight = (e) => {
        // e.preventDefault()
        this.setState({ drawerOpen: false });
    };
    handleDeleteOpportunities = () => {
        this.toggleDeleteDialog()
    }

    toggleDeleteDialog = () => {
        this.setState({ isDeleteConfirmDialoOpen: !this.state.isDeleteConfirmDialoOpen });
    };
    confirmDeleteCustomField = () => {
        let deleteOpportunitiesParams = [];
        deleteOpportunitiesParams['deleteid'] = {
            opportunitiesid: this.state.deleteIds,
            piplineid: parseFloat(this.state.selectedPipelineId)
        };
        deleteOpportunitiesParams['listval'] = {
            filterdata: this.state.filterdata,
            list: {
                limit: this.state.pageSize,
                pageno: 1,
                searchtext: this.state.searchText,
                sorttype: this.state.sorttype,
                type: "list",
                fieldId: this.state.fieldId,
                columnname: this.state.column_name
            },
            piplineid: parseFloat(this.state.selectedPipelineId),
            filterid: 1
        };

        this.props.deleteOpportunities(this.props.apolloClient.client, deleteOpportunitiesParams);
        this.setState({
            selection: [],
            deleteIds: []
        });
        this.toggleDeleteDialog()
    };

    handleFollowedCheckbox = (val, name) => {
        // console.log("name1", name);
        // console.log("val1", val);
        this.setState({
            filterObject: {
                ...this.state.filterObject,
                [name]: val
            }
        }, () => {
            setTimeout(() => {
                if (this.state.filterObject[name] == val) {
                    this.handleFilterSubmit()
                }
            }, 1500);
        })

    }
    handleChangeFilter = (val, name) => {
        // console.log("name", name);
        // console.log("val", val);
        this.setState({
            filterObject: {
                ...this.state.filterObject,
                [name]: val
            }
        }, () => {
            setTimeout(() => {
                if (this.state.filterObject[name] == val) {
                    this.handleFilterSubmit()
                }
            }, 1500);
        })
    };

    rangeClick = (key, id) => {
        var rangeData = this.state.filterRangeArray[key];
        if (this.state.filterRangeArray[key][0] == "" && this.state.filterRangeArray[key][1] == "") {
            rangeData = []
        }

        var value = { fieldid: id, name: key, type: "range", value: rangeData };
        this.setState({
            filterObject: {
                ...this.state.filterObject,
                [key]: value
            }
        }, () => {
            this.forceUpdate()
            setTimeout(() => {
                if (this.state.filterObject[key] == value) {
                    this.handleFilterSubmit()
                }
            }, 1500);
        })
    }
    rangeFilter = (rval, rname) => {
        let filterRangeArray = this.state.filterRangeArray;
        filterRangeArray[rname][rval.case] = rval.value;
        this.setState({ filterRangeArray })
    }

    rangeClickDate = (key, id) => {
        var rangeData = this.state.filterRangeArray[key];
        if (this.state.filterRangeArray[key][0] == "" && this.state.filterRangeArray[key][1] == "") {
            rangeData = []
        }

        var value = { fieldid: id, name: key, type: "date", value: rangeData };
        this.setState({
            filterObject: {
                ...this.state.filterObject,
                [key]: value
            }
        }, () => {
            this.forceUpdate()
            setTimeout(() => {
                if (this.state.filterObject[key] == value) {
                    this.handleFilterSubmit()
                }
            }, 1500);
        })
    }

    rangeFilterDate = (rval, rname) => {
        let filterRangeArray = this.state.filterRangeArray;
        filterRangeArray[rname][rval.case] = rval.value;
        this.setState({
            filterRangeArray
        }, () => {
            this.forceUpdate()
            setTimeout(() => {
                this.rangeClickDate(rname, rval.fieldid)
            }, 100);
        })
    }
    handleToggleFieldDisplay = (name) => {
        var exists = this.state.fieldDisplay.includes(name);
        var joined = [];
        let filterRangeArray = this.state.filterRangeArray;

        if (exists) {
            joined = this.state.fieldDisplay.filter((item) => item != name)
            if (filterRangeArray[name]) {
                //delete filterRangeArray[name];
            }
        } else {
            joined = this.state.fieldDisplay.concat(name);
            if (!filterRangeArray[name]) {
                filterRangeArray[name] = ["", ""];
            }
        }

        this.setState({ fieldDisplay: joined, filterRangeArray })
    }

    dropdownMenu = (fkey) => {
        var exists = this.state.dropdownhide.includes(fkey);
        var joined = [];
        if (exists) {
            joined = this.state.dropdownhide.filter((item) => item != fkey)
        } else {
            joined = this.state.dropdownhide.concat(fkey);
        }
        this.setState({ dropdownhide: joined })
    }

    handleFilterSubmit = () => {
        var allowToFilter = true
        var filterValuesArray = Object.keys(this.state.filterObject);
        var finalFilterData = filterValuesArray.map((k, i) => {
            let v = this.state.filterObject[k];

            if (v.value.length > 0) {
                var valuesArray = [];
                v.value.map((val, k) => {
                    if (v.type === "date") {
                        if (val == "Invalid Date") {
                            allowToFilter = false
                        } else if (val == "") {
                            valuesArray.push(val)
                        } else if (val == null) {
                            valuesArray.push("")
                        } else if (val != null) {
                            //val = moment(val).format("X");
                            val = moment(val).format(this.state.dateformat);
                            valuesArray.push(val)
                        }
                    } else if (v.fieldid == '46' && val.email) {
                        valuesArray.push(val.email)
                    } else if (v.fieldid != '46' && val.name) {
                        valuesArray.push(val.name)
                    } else if (val.optionvalue) {
                        valuesArray.push(val.optionvalue)
                    } else if (v.fieldid === "56" || v.type === "range") {//tag array and value
                        valuesArray.push(val);
                    } else {
                        valuesArray.push(val);
                    }
                })


                let checkBoxKey = -1
                if (this.state.fieldsList != undefined) {
                    checkBoxKey = this.findWithAttr(this.state.fieldsList, 'id', v.fieldid);
                }



                v.value = valuesArray;
                if (v.type == "followed") {//follow/not follow filter only value is true
                    if (v.value[0] === true) {
                        return v
                    }
                } else if (checkBoxKey != -1 && this.state.fieldsList[checkBoxKey].fieldtype.id == "3" && v.value[0] == "1") {
                    return v
                } else if (checkBoxKey != -1 && this.state.fieldsList[checkBoxKey].fieldtype.id != "3") {
                    return v
                } else if (checkBoxKey != -1 && this.state.fieldsList[checkBoxKey].fieldtype.id == "3") {
                } else {
                    return v
                }
            }
        })

        //filter null and undefined object 
        finalFilterData = finalFilterData.filter(function (element) {
            return element !== undefined || (element !== undefined && element.value.length != 0);
        });
        console.log("allowToFilter", allowToFilter);
        console.log("finalFilterData", finalFilterData);
        if (allowToFilter) {
            //saveFilter Data for save in future use.
            this.setState({
                filterdata: finalFilterData,
                isDataLoading: true,
            })

            let val = {
                filterdata: finalFilterData,
                list: {
                    limit: this.state.pageSize,
                    pageno: 1,
                    searchtext: this.state.searchText,
                    sorttype: this.state.sorttype,
                    type: "list",
                    fieldId: this.state.fieldId,
                    columnname: this.state.column_name
                },
                piplineid: parseFloat(this.state.selectedPipelineId),
                filterid: 1
            }
            this.getListing(val)
        }
    }

    handleSavefilter = () => {
        if (this.state.filtername == "") {
            this.setState({ filternameError: true })
            return false
        }
        if (this.state.filterdata && this.state.filterdata.length > 0) {
            let req = {
                filterdata: this.state.filterdata,
                fitertype: this.state.fitertype,
                filtername: this.state.filtername,
                recordtypeid: this.state.recordtypeid,
            }

            this.props.saveFilterDetailOpportunity(this.props.apolloClient.client, req);
            this.handleSavefilterDialogeClose()
            this.setState({
                displaySelectedFilterName: this.state.filtername,
                newSavedFilterName: this.state.filtername,
            })
        } else {
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
        }
        setTimeout(() => {
            this.setState({
                allowStateUpdateFilter: true,
            })
        }, 2000);
    }
    updateOpportunityFilter = (data) => {
        // console.log("data", data);
        this.setState({
            filterSaveDialoge: true,
            filterUpdateId: data.id,
            filtername: data.filtername,
            fitertype: data.fitertype.toString(),
            oldFilterOption: data.filteroption,
        })
    }
    handleUpdatefilter = () => {
        if (this.state.filtername == "") {
            this.setState({ filternameError: true })
            return false
        }
        let req = {
            filterdata: this.state.oldFilterOption,
            fitertype: this.state.fitertype,
            filtername: this.state.filtername,
            filterid: parseInt(this.state.filterUpdateId),
        }
        //return
        this.props.updateFilterDetailOpportunity(this.props.apolloClient.client, req);
        let updateDataInState = this.findWithAttr(this.state.opportunityFilterListArray, 'id', this.state.filterUpdateId);
        if (updateDataInState !== -1) {
            this.state.opportunityFilterListArray[updateDataInState].filtername = this.state.filtername;
            this.state.opportunityFilterListArray[updateDataInState].fitertype = this.state.fitertype;
        }
        this.handleSavefilterDialogeClose()
    }
    updateFilterOptions = () => {
        let key = this.findWithAttr(this.props.opportunityReducer.opportunityFilterListArray.data, 'id', localStorage.getItem('selected_opportunity_filter_id'));
        var data = ""
        if (key !== -1) {
            data = this.props.opportunityReducer.opportunityFilterListArray.data[key];
            if (this.state.filterdata && this.state.filterdata.length > 0) {
                let req = {
                    filterdata: this.state.filterdata,
                    fitertype: data.fitertype.toString(),
                    filtername: data.filtername,
                    filterid: parseInt(data.id),
                }
                this.props.updateFilterDetailOpportunity(this.props.apolloClient.client, req);
                this.handleSavefilterDialogeClose()
            } else {
                //dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
            }
            setTimeout(() => {
                this.setState({
                    allowStateUpdateFilter: true,
                })
            }, 1500);
        }
    }
    confirmDeleteFilter = () => {
        this.props.deleteFilterOpportunity(this.props.apolloClient.client, parseInt(this.state.filterDeleteId));
        let removeFilterObjKey = this.findWithAttr(this.props.opportunityReducer.opportunityFilterListArray.data, 'id', this.state.filterDeleteId);
        if (removeFilterObjKey !== -1) {
            if (!this.props.opportunityReducer.opportunityFilterListArray.data[removeFilterObjKey].isdefault == true) {
                this.props.opportunityReducer.opportunityFilterListArray.data.splice(removeFilterObjKey, 1);
            }
        }
        this.filterRemoveDialoge()
    }
    filterRemoveDialoge = () => {
        this.setState({ isDeleteFilter: false, filterDeleteId: "", allowStateUpdateFilter: true })
    }
    handleSavefilterDialoge = () => {
        this.setState({ filterSaveDialoge: true })
    }
    handleSavefilterDialogeClose = () => {
        this.setState({
            filterSaveDialoge: false,
            filternameError: false,
            fitertype: "1",
            filterUpdateId: "",
            oldFilterOption: "",
            filtername: "",
        })
    }

    updateFilterDefaultOpportunity = (data) => {
        this.props.updateFilterDefaultOpportunity(this.props.apolloClient.client, parseInt(data.id));
        setTimeout(() => {
            this.setState({
                allowStateUpdateFilter: true,
            })
        }, 2000);
    }

    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] == value) {
                return i;
            }
        }
        return -1;
    }

    handleFilterChange = (filterdata) => {

        //my open oppoertunity
        if (filterdata.id == "12") {
            let udata = JSON.parse(localStorage.getItem('user_data_session'))
            filterdata.filteroption = [
                {
                    fieldid: "46", name: "owner", type: "value", value: [udata.email]
                },
                {
                    fieldid: "45", name: "status", type: "value", value: ["Open"]
                }
            ]
        }

        //Opportunities Im Following
        if (filterdata.id == "14") {
            filterdata.filteroption = [
                {
                    fieldid: "0",
                    name: "followed",
                    type: "followed",
                    value: [true],
                    chktype: "follow",
                }
            ]
        }

        //won opportunity
        if (filterdata.id == "15") {
            let udata = JSON.parse(localStorage.getItem('user_data_session'))
            filterdata.filteroption = [
                {
                    fieldid: "45", name: "status", type: "value", value: ["Won"]
                }
            ]
        }
        //Open opportunity
        if (filterdata.id == "13") {
            let udata = JSON.parse(localStorage.getItem('user_data_session'))
            filterdata.filteroption = [
                {
                    fieldid: "45", name: "status", type: "value", value: ["Open"]
                }
            ]
        }
        this.setState({
            filterObject: {},
            filterRangeArray: [],
            fieldDisplay: [],
            dropdownhide: [],
        }, () => {
            this.forceUpdate()
            this.setState({ displaySelectedFilterName: filterdata.filtername, currentPage: 0 })
            if (filterdata.filteroption.length > 0) {
                let filterObject = this.state.filterObject;
                let filterRangeArray = this.state.filterRangeArray;
                let displayField = []
                let dropdownhideShow = []
                var promise = new Promise((resolve, reject) => {
                    filterdata.filteroption.map((val, k) => {
                        filterObject = {
                            ...filterObject,
                            [val.name]: val
                        }
                        if (val.name == "tags") {
                            this.setState({ tagsquery: val.query })
                        }
                        if (val.name == "activitytype") {
                            this.setState({ activityquery: val.query })
                        }
                        if (val.type === "range") {
                            filterRangeArray[val.name] = ["", ""];
                            filterRangeArray[val.name][0] = val.value[0];
                            filterRangeArray[val.name][1] = val.value[1];
                            this.setState({ filterRangeArray })
                            displayField.push(val.name)
                        }

                        if (val.type == "date") {
                            filterRangeArray[val.name] = ["", ""];
                            filterRangeArray[val.name][0] = val.value[0] == "" ? null : val.value[0];
                            filterRangeArray[val.name][1] = val.value[1] == "" ? null : val.value[1];
                            this.setState({ filterRangeArray })
                            if (val.value[0] != "" && val.value[1] != "") {
                                displayField.push(val.name)
                            } else if (val.value[0] != "" || val.value[1] != "") {
                                displayField.push(val.name)
                            }
                        }

                    })
                    this.setState({
                        filterObject,
                        fieldDisplay: displayField,
                        dropdownhide: dropdownhideShow,
                    }, () => this.forceUpdate())
                    resolve(true)
                });

                promise.then(result => {
                    this.handleFilterSubmit();
                    localStorage.setItem('selected_opportunity_filter_id', filterdata.id)
                });
            } else {
                let val = {
                    filterdata: [],
                    list: {
                        limit: this.state.pageSize,
                        pageno: 1,
                        searchtext: this.state.searchText,
                        sorttype: this.state.sorttype,
                        type: "list",
                        fieldId: this.state.fieldId,
                        columnname: this.state.column_name
                    },
                    piplineid: parseFloat(this.state.selectedPipelineId),
                    filterid: 1
                }
                localStorage.setItem('selected_opportunity_filter_id', filterdata.id)
                this.handleFilterSubmit();
            }
        })

    }

    handlechangeActivityQuery = (event) => {
        this.setState({
            activityquery: event.target.value
        }, () => {
            setTimeout(() => {
                if (this.state.filterObject['activitytype'] && this.state.filterObject['activitytype'].value.length > 0) {
                    this.state.filterObject['activitytype'].query = event.target.value;
                    this.handleFilterSubmit();
                }
            }, 1500);
        })
    }
    handlechangeTagsQuery = (event) => {
        this.setState({
            tagsquery: event.target.value
        }, () => {
            setTimeout(() => {
                if (this.state.filterObject['tags'] && this.state.filterObject['tags'].value.length > 0) {
                    this.state.filterObject['tags'].query = event.target.value;
                    this.handleFilterSubmit();
                }
            }, 1500);
        })
    }
    handleClearFilterData = () => {
        this.setState({
            fieldDisplay: [],
            filterRangeArray: [],
            dropdownhide: [],
            filterObject: {},
            filterdata: [],
            activityquery: "any",
            tagsquery: "any",
        }, () => {
            setTimeout(() => {
                // if(this.state.filterObject['tags'] && this.state.filterObject['tags'].value.length > 0){
                //     this.state.filterObject['tags'].query = event.target.value;
                //     this.handleFilterSubmit();
                // }
            }, 1500);
        })
    }

    handlePipeLineChange = (pipelineData) => {
        if (this.state.selectedPipelineId != pipelineData.id) {
            localStorage.setItem('selected_pipeline_id', pipelineData.id);
            this.setState({
                selectedPipelineId: pipelineData.id,
                isDataLoading: true,
            })
            let val = {
                filterdata: this.state.filterdata,
                list: {
                    limit: 10,
                    pageno: 1,
                    searchtext: '',
                    sorttype: '',
                    type: "list",
                    fieldId: 1,
                    columnname: ""
                },
                piplineid: parseFloat(pipelineData.id),
                filterid: 1
            }
            this.getListing(val)
            this.props.getPipelines(this.props.apolloClient.client);
            this.props.settingReducer.pipelineListArray.is_loaded = true
        }

    };

    handlePipelineADDDialogClose = () => {
        this.setState({ shouldOpenNewPipelineDialog: false, FullNewPipelineDialog: false, minimizePipelineDialog: false, editPiplineData: '', });
    };
    handlePipelineADDDialogMinimize = () => {
        this.setState(oldStateminimizePipelineDialog => ({ minimizePipelineDialog: !oldStateminimizePipelineDialog.minimizePipelineDialog }));
        this.setState({ FullNewPipelineDialog: false })
    };
    handlePipelineADDDialogFull = () => {
        this.setState(oldStateFullNewPipelineDialog => ({ FullNewPipelineDialog: !oldStateFullNewPipelineDialog.FullNewPipelineDialog }));
        this.setState({ minimizePipelineDialog: false })

    }
    render() {
        let { isOpenedFiltersDefault, isOpenedPipelines, isOpenedFilters, isOpenedCustomFilters, isOpenedRange } = this.state;
        var localStorageFilter = localStorage.getItem('selected_opportunity_filter_id');
        let updateSaveFilterBtnDisabled = (this.state.filterdata == undefined || !this.state.filterdata.length) ? ((this.state.oldFilterOption == undefined || !this.state.oldFilterOption.length) ? true : false) : false
        const stageArrayGlobal = this.state.stageArray
        let opportunityCardArray = []
        {
            stageArrayGlobal.length > 0 && stageArrayGlobal.map((item, key) => {
                if (item.dataList && item.dataList.length > 0) {
                    item.dataList.map((cardData, key1) => {
                        var objKey = this.findWithAttr(stageArrayGlobal, "id", parseInt(cardData.stage[0].id))
                        var stageClass = ""

                        var valueField = ""
                        var companyOwnerNames = ""
                        var currecySymbol = this.state.companyCurrencyData.symbol
                        var chipClass = ""
                        var bgClass = ""
                        var hrClass = ""
                        var roundClass = ""
                        if (cardData.value && cardData.value.length > 0) {
                            valueField = "Value : " + currecySymbol + cardData.value[0].value
                        }


                        if (cardData.company && cardData.company.length > 0) {
                            companyOwnerNames = cardData.company[0].value + " : "
                        }
                        if (cardData.owner && cardData.owner.length > 0) {
                            companyOwnerNames += cardData.owner[0].name
                        }

                        if (objKey != -1) {
                            if (objKey === 0) {
                                stageClass = "first";
                            } else if (objKey === 1) {
                                stageClass = "second";
                            } else if (objKey === 2) {
                                stageClass = "third";
                            } else if (objKey === 3) {
                                stageClass = "forth";
                            } else if (objKey === 4) {
                                stageClass = "fifth";
                            }
                        }
                        if (cardData.status && cardData.status.length > 0) {
                            console.log("cardData.status", cardData.status);
                            if (cardData.status[0].id == '1') {
                                chipClass = "chip_won";
                                bgClass = "won_bg";
                                hrClass = "won_hr";
                                roundClass = "won_round";
                            }
                            if (cardData.status[0].id == '2') {
                                chipClass = "chip_abandoned";
                                bgClass = "abandoned_bg";
                                hrClass = "abandoned_hr";
                                roundClass = "abandoned_round";
                            }
                            if (cardData.status[0].id == '3') {
                                chipClass = "chip_lost";
                                bgClass = "lost_bg";
                                hrClass = "lost_hr";
                                roundClass = "lost_round";
                            }
                        }



                        opportunityCardArray.push(<>
                            <div className={"deal_line " + stageClass}>
                                <hr className={hrClass} />
                                {stageClass != "first" && <div className={"rounded_icon " + roundClass} >
                                    <Icon>chevron_right</Icon>
                                </div>}
                                <div className={"deal_details " + bgClass}>
                                    <Link to={"/opportunities/opportunities/" + cardData.opportunities_id} className="deal_title">{cardData.name}</Link>
                                    <div claclassNamess="account_assignee">{companyOwnerNames}</div>
                                    <div className="monetary_value">{valueField}</div>
                                    {chipClass != "" && <Chip label={cardData.status[0].value} className={chipClass} />}
                                </div>
                            </div>
                        </>)
                    })
                }

            })
        }
console.log(this.state.stageArray,'this.state.stageArray');
        return (
            <div className="opportunities_card progression-report">
                <aside className="aside_left" style={{ overflowY: 'auto' }}>
                    <Card elevation={3}>
                        <h5 onClick={this.toggleLeadList} className="cursor_poiter d-flex justify-content-between">Pipelines <Icon className="vert-middle flex-end">{isOpenedPipelines ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon></h5>
                        {isOpenedPipelines &&
                            <div className="filter_menu">
                                <ul>
                                    {this.state.isLoadingPipelineList && <li className="text-center"><CircularProgress size={20} /></li>}
                                    {this.state.pipelineListArray.length > 0 && this.state.pipelineListArray.map((item, key) =>
                                        <li onClick={() => this.handlePipeLineChange(item)}><Link className={(this.state.selectedPipelineId == item.id) ? "pipline-selected-class" : ""}>{item.name}</Link></li>
                                    )}
                                    {
                                        this.props.authReducer.userRole.id !== 3 &&
                                        <li><Link onClick={() => this.setState({ shouldOpenNewPipelineDialog: true })}>+ Create New Pipeline</Link></li>
                                    }
                                </ul>
                            </div>
                        }
                        <Link to="#" onClick={() => this.goBack()}><h5 className="cursor_poiter">Back</h5></Link>
                        <h5 onClick={this.toggleFiltersDefault} className="cursor_poiter d-flex justify-content-between">Filters <div style={{ fontSize: '13px', margin: '3px 0px 0px 0px', fontWeight: '400' }}>({this.state.displaySelectedFilterName})</div><Icon className="vert-middle flex-end">{isOpenedFiltersDefault ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon></h5>
                        {isOpenedFiltersDefault &&
                            <div className="filter_menu_custom">
                                <h6 className="mt-8 font-size-14 mb-0 pl-16 pr-16">Private Filters </h6>
                                <ul>
                                    {this.state.opportunityFilterListArray.length > 0 && this.state.opportunityFilterListArray.map((item, key) => {
                                        let star_icon = item.isdefault ? "star" : "star_border";
                                        if (item.fitertype == 1 && item.id != localStorage.getItem('selected_opportunity_filter_id')) {
                                            return <li key={item.key}>
                                                <div onClick={() => this.handleFilterChange(item)}>{item.filtername}</div>
                                                <><IconButton className="text-secondary ml-4" size="small">
                                                    <Icon onClick={() => this.updateOpportunityFilter(item)} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{'settings'}</Icon>
                                                </IconButton>
                                                    <IconButton className="text-secondary ml-4" size="small">
                                                        <Icon onClick={() => this.setState({ isDeleteFilter: true, filterDeleteId: item.id })} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{'delete'}</Icon>
                                                    </IconButton>
                                                    <IconButton className="text-secondary ml-4" size="small">
                                                        <Icon onClick={() => this.updateFilterDefaultOpportunity(item)} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{star_icon}</Icon>
                                                    </IconButton>
                                                </>
                                            </li>
                                        }
                                    }
                                    )}
                                </ul>
                                <h6 className="mt-8 font-size-14 mb-0 mt-16 pl-16 pr-16">Public Filters</h6>
                                <ul>
                                    {this.state.opportunityFilterListArray.length > 0 && this.state.opportunityFilterListArray.map((items, key) => {
                                        let star_icon = items.isdefault ? "star" : "star_border";
                                        if (items.fitertype == 0 && items.id != localStorage.getItem('selected_opportunity_filter_id')) {
                                            return <li key={items.key}>
                                                <div onClick={() => this.handleFilterChange(items)}>{items.filtername}</div>
                                                {!["11", "12", "13", "14", "15"].includes(items.id) &&
                                                    <><IconButton className="text-secondary ml-4" size="small">
                                                        <Icon onClick={() => this.updateOpportunityFilter(items)} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{'settings'}</Icon>
                                                    </IconButton>
                                                        <IconButton className="text-secondary ml-4" size="small">
                                                            <Icon onClick={() => this.setState({ isDeleteFilter: true, filterDeleteId: items.id })} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{'delete'}</Icon>
                                                        </IconButton></>
                                                }
                                                <IconButton className="text-secondary ml-4" size="small">
                                                    <Icon onClick={() => this.updateFilterDefaultOpportunity(items)} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{star_icon}</Icon>
                                                </IconButton>
                                            </li>
                                        }
                                    }
                                    )}
                                </ul>

                                <><Dialog
                                    fullWidth={true}
                                    maxWidth={'xs'}
                                    open={this.state.isDeleteFilter}
                                    onClose={this.filterRemoveDialoge}
                                    disableBackdropClick

                                    aria-labelledby="ar-remove-dialog-title"
                                >
                                    <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to delete filter ?"}</DialogTitle>
                                    <DialogActions>
                                        <Button onClick={this.filterRemoveDialoge} color="primary">Cancel</Button>
                                        <Button type="Button" onClick={() => this.confirmDeleteFilter()} className="btn_Primary">
                                            Ok
                                    </Button>
                                    </DialogActions>
                                </Dialog></>
                            </div>
                        }
                    </Card>
                    <Card elevation={3} className={`pt-16 pb-16`} style={{ marginTop: '5px' }}>
                        <div className="ml-16 mr-16">
                            <Button fullWidth className="btn_Primary" size="small" onClick={this.toggleCustomFilters}><Icon>add</Icon> Add Custom Filters</Button>
                        </div>
                        {isOpenedFilters &&
                            <React.Fragment>
                                <div className="v_scroll position-relative ml-16 mt-16 pr-16">
                                    <Link className="text_link" onClick={() => this.handleFilterChange(this.state.opportunityFilterListArray[this.state.AllOpportunityFilterKey])}>Clear All</Link>
                                    {FilterComponent(this)}
                                </div>
                                <div className="fixed-bottom" style={{ position: 'initial' }}>
                                    <Button type="button" className="btn_Primary  mb-16 px-12" size="small" onClick={() => this.handleSavefilterDialoge()}>Save Filter</Button>
                                    {localStorageFilter && !["11", "12", "13", "14", "15"].includes(localStorageFilter) &&
                                        <Button type="button" className="btn_Primary  mb-16 px-12 ml-16" size="small" onClick={() => this.updateFilterOptions()}>Update Filter</Button>
                                    }
                                </div>
                            </React.Fragment>
                        }
                    </Card>
                </aside>
                <Card elevation={3} className="fixed_layout report_layout">
                    <Grid container justify="space-between" className="title-with-label pr-16 report_layout2">
                        <Grid item>
                            <Grid container justify="flex-start" alignItems="center" spacing={2}  >
                                <Grid item>
                                    <h3 className="ml-16">{this.state.selectedPipelineName}</h3>
                                </Grid>
                                <Grid item>
                                    <label className="text-lable d-block">Total Value</label>
                                    <div className="text-value">${this.state.valueTotal}</div>
                                </Grid>
                                <Grid item>
                                    <label className="text-lable d-block">Open</label>
                                    <div className="text-value">{this.state.openTotal}</div>
                                </Grid>
                                <Grid item>
                                    <label className="text-lable d-block">Won</label>
                                    <div className="text-value">{this.state.wonTotal}</div>
                                </Grid>
                                <Grid item>
                                    <label className="text-lable d-block">Lost</label>
                                    <div className="text-value">{this.state.lostTotal}</div>
                                </Grid>
                                <Grid item>
                                    <label className="text-lable d-block">Abandoned</label>
                                    <div className="text-value">{this.state.abandonedTotal}</div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className="progression_stag report_layout2">
                        <Grid container alignItems="center">
                            {this.state.stageArray.length > 0 && this.state.stageArray.map((item, key) => {
                                if (item) {
                                    return <Grid item className="stag_cell flex-basis">
                                        <div className="title" >{item.name}</div>
                                        <div className="total">${item.stagetotal}</div>
                                    </Grid>
                                }
                            }
                            )}
                        </Grid>
                        <Grid container alignItems="center" className="mt-8 ml-16 mr-16">
                            {
                                this.state.stageArray.length > 0 && this.state.stageArray.map((item, key) => {
                                    return (
                                        <Grid item className="flex-basis">
                                            <Grid container justify="flex-start" alignItems="center" spacing={1}>
                                                <Grid item>
                                                    <label className="text-lable d-block">Open</label>
                                                    <div className="text-value">{item.opencount}</div>
                                                </Grid>
                                                <Grid item>
                                                    <label className="text-lable d-block">Won</label>
                                                    <div className="text-value">{item.woncount}</div>
                                                </Grid>
                                                <Grid item>
                                                    <label className="text-lable d-block">Lost</label>
                                                    <div className="text-value">{item.lostcount}</div>
                                                </Grid>
                                                <Grid item>
                                                    <label className="text-lable d-block" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Abandoned</label>
                                                    <div className="text-value">{item.abandonedcount}</div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </div>
                    <div className="stag_body v_scroll report_layout2">
                        {opportunityCardArray}
                    </div>
                </Card>
                {this.state.shouldOpenNewPipelineDialog && (
                    <AddNewPipeline
                        handleClose={this.handlePipelineADDDialogClose}
                        open={this.state.shouldOpenNewPipelineDialog}
                        handleMinimize={this.handlePipelineADDDialogMinimize}
                        handleFull={this.handlePipelineADDDialogFull}
                        minimizeScreen={this.state.minimizePipelineDialog}
                        fullScreen={this.state.FullNewPipelineDialog}
                    />
                )}
                {this.state.isDataLoading &&
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        open={this.state.isDataLoading}
                        TransitionComponent={Fade}
                        message={<><CircularProgress size={20} style={{ color: 'white' }} /><span style={{ paddingLeft: '6px', color: 'white' }}>{" Loading..."}</span></>}
                        variant={'success'}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        opportunityReducer: state.opportunityReducer,
        settingReducer: state.settingReducer,
        apolloClient: state.apolloClient,
        authReducer: state.authReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
        //updateLeadListColumnOrder: (client, request) => { dispatch(actions.updateLeadListColumnOrder(client, request)) },
        addFavouriteOpportunities: (client, request) => { dispatch(actions.addFavouriteOpportunities(client, request)) },
        deleteOpportunities: (client, request) => { dispatch(actions.deleteOpportunities(client, request)) },
        getOpportunitiesFilterList: (client, request) => { dispatch(actions.getOpportunitiesFilterList(client, request)) },
        //getLeadListColumnOrder: (client) => { dispatch(actions.getLeadListColumnOrder(client)) },
        getCityList: (client) => { dispatch(actions.getCityList(client)) },
        getStateList: (client) => { dispatch(actions.getStateList(client)) },
        getZipcodeList: (client) => { dispatch(actions.getZipcodeList(client)) },
        saveFilterDetailOpportunity: (client, request) => { dispatch(actions.saveFilterDetailOpportunity(client, request)) },
        getFilterListOpportunity: (client, recordtypeid) => { dispatch(actions.getFilterListOpportunity(client, recordtypeid)) },
        updateFilterDefaultOpportunity: (client, filterid) => { dispatch(actions.updateFilterDefaultOpportunity(client, filterid)) },
        updateFilterDetailOpportunity: (client, req) => { dispatch(actions.updateFilterDetailOpportunity(client, req)) },
        deleteFilterOpportunity: (client, req) => { dispatch(actions.deleteFilterOpportunity(client, req)) },
        getUserSetting: (client, data) => dispatch(actions.getUserSetting(client, data)),
        getUserList: (client) => dispatch(actions.getUserList(client)),
        getPipelines: (client) => dispatch(actions.getPipelines(client)),
        customFieldList: (client, request) => { dispatch(actions.customFieldList(client, request)) },
        getCompanyCurrency: (client) => dispatch(actions.getCompanyCurrency(client)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ProgressionReport);
