import React, { Component } from "react";
import TableComponent from './TableComponent';
// import TaskCreationRule from './TaskCreationRule'
import { Icon, Switch, FormControlLabel, IconButton, withStyles, Button, Card, Grid, FormControl, InputLabel, Select, MenuItem, Checkbox, FormHelperText, FormLabel, Radio, RadioGroup, Snackbar, Fade, CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import FontAwesome from 'react-fontawesome'
// import CreateNewFieldTaskDialog from "./CreateNewFieldTaskDialog";
import * as actions from '../../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';
import { parse } from "date-fns";
import Server from "../../../Common/Server";
const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.5rem",
    }
}))(Icon);

class RelatedSettingLead extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mailboxid: "",
            setting_one: "",
            setting_two: "",
            setting_three: "",
            setting_four: "",
            setting_five: "",
            setting_six: "",
            setting_seven: "",
            setting_eight: "",
            allSettings: {},
            isDataLoading: true,
        };
    }
    componentDidMount() {
        this.props.getEmailSetting(this.props.apolloClient.client);
        let userdata = JSON.parse(localStorage.getItem('user_data_session'));
        const mailboxid = userdata.mailboxid ? userdata.mailboxid : "";
        this.setState({ mailboxid: mailboxid });
    }
    componentWillReceiveProps(props) {
        if (props.settingReducer.emailsettings.data) {
            const mian_data = props.settingReducer.emailsettings.data
            this.setState({
                isDataLoading: false,
                allSettings: mian_data,
                setting_one: mian_data.setting_one,
                setting_two: mian_data.setting_two,
                setting_three: mian_data.setting_three,
                setting_four: mian_data.setting_four,
                setting_five: mian_data.setting_five,
                setting_six: mian_data.setting_six,
                setting_seven: mian_data.setting_seven,
                setting_eight: mian_data.setting_eight,
            })
        }
        if (props.settingReducer.generatedmailboxid.data) {
            let mailboxid = props.settingReducer.generatedmailboxid.data.mailboxid;
            this.setState({ mailboxid });
        }
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value
        }, () => this.updateSettings())
    }
    updateSettings = () => {
        this.setState({ isDataLoading: true })
        let req = {
            setting_one: parseFloat(this.state.setting_one),
            setting_two: this.state.setting_two,
            setting_three: this.state.setting_three,
            setting_four: this.state.setting_four,
            setting_five: this.state.setting_five === "true" ? true : false,
            setting_six: this.state.setting_six,
            setting_seven: this.state.setting_seven,
            setting_eight: this.state.setting_eight,
        }
        this.props.updateEmailSetting(this.props.apolloClient.client, { setting: req });
    }
    generateMailbox = () => {
        this.props.generateMailBoxId(this.props.apolloClient.client);
    }
    render() {
        let userdata = JSON.parse(localStorage.getItem('user_data_session'));
        return (
            <>
                <div className="font-size-13 mb-20">Manage how IDrive CRM will interact with your email. </div>
                <Card className="signup-card">
                    <Grid item xs={7} style={{ padding: '20px' }}>
                        <FormControl className="w-100 input_bx">
                            <InputLabel className="font-size-13">Email Preferances</InputLabel>
                            <Select
                                className="font-size-13"
                                name="setting_one"
                                value={this.state.setting_one}
                                onChange={this.handleChange}>
                                {userdata.googlestatus &&
                                    <MenuItem key="1" value="1">Send and reply from IDrive CRM</MenuItem>
                                }
                                <MenuItem key="2" value="2">Email links open in Gmail</MenuItem>
                                <MenuItem key="3" value="3">Email links open in my default email client</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControlLabel className="font-size-12"
                            control={
                                <Checkbox
                                    color="primary"
                                    onChange={this.handleChange}
                                    checked={this.state.setting_two}
                                    name="setting_two" />
                            }
                            label={
                                <span style={{ fontSize: "13px" }}>
                                    Mark synced emails public by default
                                </span>
                            }
                        />
                        <FormHelperText className="ml-30 mt-0">
                            Changes to this setting do not affect the visibility of previously synced
                            emails.Admins can always see all synced emails.
                            </FormHelperText>
                        {this.state.setting_one === "1" || this.state.setting_one === 1 ?
                            <>
                                <FormControlLabel className="font-size-12"
                                    control={
                                        <Checkbox color="primary"
                                            onChange={this.handleChange}
                                            checked={this.state.setting_eight}
                                            name="setting_eight" />}
                                    label={<span style={{ fontSize: "13px" }}>
                                        Use Gmail Signature.</span>
                                    }
                                />
                                <FormHelperText className="ml-30 mt-0">
                                    Email sent from IDrive CRM will include your Gmail signature.
                                    </FormHelperText>
                            </>
                            :
                            <>
                                <FormControlLabel className="font-size-12"
                                    control={
                                        <Checkbox color="primary"
                                            onChange={this.handleChange}
                                            checked={this.state.setting_three}
                                            name="setting_three" />}
                                    label={
                                        <span style={{ fontSize: "13px" }}>
                                            Always include my IDrive CRM mailbox address in
                                            the BCC field.</span>}
                                />
                                <FormHelperText className="ml-30 mt-0">
                                    Required to sync emails if your Google Sync Settings
                                    do not allow Gmail access.</FormHelperText>
                            </>
                        }
                    </Grid>
                </Card>
                <h5 className="mt-20 mb-20">Admin Private Email Visibility (Account Owner)</h5>
                <Card className="signup-card">
                    <Grid item xs={7} style={{ padding: '20px' }}>
                        <FormControlLabel
                            control={
                                <Checkbox color="primary"
                                    onChange={this.handleChange}
                                    checked={this.state.setting_four}
                                    name="setting_four" />}
                            label={<span style={{ fontSize: "13px" }}>Allow Admins to see emails marked as private by any user</span>}
                        />
                        <FormHelperText className="ml-30 mt-0">All emails are visibile to admins. The account owner can choose to disable this so emails marked as private are not visibile to any admins.</FormHelperText>
                    </Grid>
                </Card>
                <h5 className="mt-20 mb-20">Individual Email Visibility Control (Admin)</h5>
                <Card className="signup-card">
                    <Grid item xs={7} style={{ padding: '20px' }}>
                        <FormControl>
                            <h5 className="font-size-14">Allow users within your company to choose the default visibility for their synced emails</h5>
                            <FormHelperText>Individual email visibility can always be changed by the owner of the email conversation.</FormHelperText>
                            <RadioGroup name="setting_five" value={this.state.setting_five.toString()} onChange={this.handleChange}>
                                <FormControlLabel value="true" control={<Radio color="primary" />} label={<span style={{ fontSize: "13px" }}>Yes, users may specify their own default visibility (private by default)</span>} />
                                <FormControlLabel value="false" control={<Radio color="primary" />} label={<span style={{ fontSize: "13px" }}>No (always private by default)</span>} />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Card>
                <h5 className="mt-20 mb-20">IDrive CRM Mailbox</h5>
                <Card className="signup-card">
                    <Grid item xs={12} style={{ padding: '20px' }}>
                        <FormControl>
                            <h5 className="font-size-14">IDrive CRM mailbox address:</h5>
                            <FormHelperText>Automatic email syncing requires your Google Sync Settings to allow Gmail access. If that access is disabled or you do not use Gmail as your email provider, include this email address in the BCC line of any email to sync it with IDrive CRM.</FormHelperText>
                            <br />
                            <span className="font-size-14 font-weight-500">
                                {this.state.mailboxid + Server.BCCINBOX} 
                                <a href="#" className="ml-20" onClick={this.generateMailbox} style={{ color: "rgba(0, 0, 0, 0.54)" }}> Generate new mailbox address</a></span>
                            <FormControlLabel
                                control={<Checkbox color="primary" onChange={this.handleChange} checked={this.state.setting_six} name="setting_six" />}
                                label={<span style={{ fontSize: "13px" }}>Add CC’d contacts to IDrive CRM</span>}
                            />
                            <FormHelperText className="ml-30 mt-0">All emails sent via web app or the mobile apps will be automatically tracked. You can choose which emails sent via Gmail or Inbox can be tracked.</FormHelperText>
                        </FormControl>
                    </Grid>
                </Card>
                <h5 className="mt-20 mb-20">Email Open Tracking</h5>
                <Card className="signup-card">
                    <Grid item xs={7} style={{ padding: '20px' }}>
                        <FormControlLabel
                            control={
                                <Checkbox color="primary"
                                    onChange={this.handleChange}
                                    checked={this.state.setting_seven}
                                    name="setting_seven" />}
                            label={<span style={{ fontSize: "13px" }}>
                                Track when emails are opened by default</span>}
                        />
                        <FormHelperText className="ml-30 mt-0">
                            All emails sent via web app or the mobile apps will be
                            automatically tracked. You can choose which emails sent via
                             Gmail or Inbox can be tracked.</FormHelperText>
                    </Grid>
                </Card>
                {this.state.isDataLoading &&
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        open={this.state.isDataLoading}
                        TransitionComponent={Fade}
                        message={<><CircularProgress size={20} style={{ color: 'white' }} /><span style={{ paddingLeft: '6px', color: 'white' }}>{" Loading..."}</span></>}
                        variant={'success'}
                    />
                }
            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        settingReducer: state.settingReducer,
        apolloClient: state.apolloClient
    };
};
const mapDispatchToProps = dispatch => {
    return {
        generateMailBoxId: (client) => { dispatch(actions.generateMailBoxId(client)) },
        getEmailSetting: (client) => { dispatch(actions.getEmailSetting(client)) },
        updateEmailSetting: (client, req) => { dispatch(actions.updateEmailSetting(client, req)) },

    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(RelatedSettingLead);
