import React, { Component } from "react";
import { Dialog, List, ListItem, DialogTitle, DialogActions, IconButton, Icon, Menu, withStyles, Grid, Fab, Select, MenuItem, TextField, Card, InputLabel, Button, Checkbox, Tooltip } from "@material-ui/core";
import * as actions from '../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import InboxCompose from "../CommonComposeMail/InboxCompose";
import FontAwesome from "react-fontawesome";
import _ from 'lodash'
import { Link } from "react-router-dom";
import SystemLog from "../CommonLogUI/SystemLog"
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';
import EmailTypeOne from "../CommonLogUI/EmailTypeOne";
import EmailTypeTwo from "../CommonLogUI/EmailTypeTwo";
import EmailTypeFour from "../CommonLogUI/EmailTypeFour";
import ViewHtmlDialog from "../CommonLogUI/ViewHtmlDialog";
import AddComment from "../CommonLogUI/AddComment";
import EditComment from "../CommonLogUI/EditComment";
import forEach from 'lodash/forEach';
import InboxComposeDialog from "../CommonComposeMail/InboxComposeDialog";
import NoteLog from "../CommonLogUI/NoteLog";
import CallLogs from "../CommonLogUI/CallLogs";
import RingCentralCallLogs from "../CommonLogUI/RingCentralCallLogs";
import CreatelogDialog from "../CommonLogUI/CreatelogDialog";
import FilterLogoCommon from "../CommonLogUI/FilterLogoCommon";
const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.3rem",
        color: 'rgba(0, 0, 0, 0.6)'
    }
}))(Icon);

const IconSmaller = withStyles(() => ({
    root: {
        fontSize: "1.0rem",
        color: 'rgba(0, 0, 0, 0.6)'
    }
}))(Icon);

const TooltipNew = withStyles((theme) => ({
    tooltip: {
        fontSize: 14,
    },
}))(Tooltip);


class FiltersMenu extends Component {
    state = {
        commentid: '',
        Commentoptions: '',
        showCommentoptions: false,
        activities: [],
        typeArray: [],
        activityArray: [],
        activityflag: true,
    };
    componentDidMount() {

        let typeArray1 = this.state.typeArray;
        let activityArray1 = this.state.activityArray;

        typeArray1 = [1, 2, 3, 4, 15, 20, 21, 5, 100, 1000, 1001];

        this.setState({ typeArray: typeArray1 });
        this.props.thisData.setState({ logtypeid: typeArray1 });
    }
    componentWillReceiveProps(props) {

        if (props.proVar && props.proVar.length > 0) {
            this.setState({ activities: props.proVar });
            if (this.state.activityflag) {
                let activityArray1 = this.state.activityArray;
                let activities = props.proVar;

                if (activities && activities.length > 0) {
                    activities.map((activity) => {
                        if (activityArray1.indexOf(parseFloat(activity.id)) == -1) {
                            activityArray1.push(parseFloat(activity.id));
                        }
                    })
                    this.setState({ activityArray: activityArray1, activityflag: false });
                    props.thisData.setState({ activitypeid: activityArray1 });
                }
            }
        }
    }

    handleClick = (event) => {
        this.setState({ Commentoptions: event.target, showCommentoptions: true });
    }

    handleCloseOptions = (event) => {
        this.setState({ Commentoptions: null, showCommentoptions: false });
    }

    handleMenuClick = (e, value, type) => {
        let typeArray1 = this.state.typeArray;
        let activityArray1 = this.state.activityArray;
        let hideEmail = false;
        var promise = new Promise((resolve, reject) => {
            if (e.target.checked == true) {
                if (type == 0) {
                    if (activityArray1.indexOf(value) == -1)
                        activityArray1.push(value);
                } else if (type == 1000) {
                    typeArray1 = [1, 2, 3, 4, 15, 20, 21, 5, 100, 1000, 1001];
                    let activities = this.state.activities;
                    if (activities && activities.length > 0) {
                        activities.map((activity) => {
                            if (activityArray1.indexOf(parseFloat(activity.id)) == -1) {
                                activityArray1.push(parseFloat(activity.id));
                            }
                        })
                    }
                } else if (type == 1001) {
                    let pusharr = [1, 2, 3, 4, 15, 20, 21, 1001];
                    pusharr.map((arr) => {
                        if (typeArray1.indexOf(arr) == -1) {
                            typeArray1.push(arr);
                        }
                    })
                } else {
                    if (typeArray1.indexOf(type) == -1)
                        typeArray1.push(type);
                        
                    if (activityArray1.indexOf(3) == -1)
                        activityArray1.push(3);
                }
            }
            if (e.target.checked == false) {
                if (type == 0) {
                    let key1 = activityArray1.indexOf(value);
                    activityArray1.splice(key1, 1);
                } else if (type == 1000) {
                    typeArray1 = [];
                    activityArray1 = [];
                } else if (type == 1001) {
                    let pullarr = [1, 2, 3, 4, 15, 20, 21, 1000, 1001];
                    pullarr.map((arr) => {
                        if (typeArray1.indexOf(arr) != -1) {
                            let ind = typeArray1.indexOf(arr);
                            typeArray1.splice(ind, 1);
                        }
                    })
                } else if (type == 100) {//email log
                    let key = typeArray1.indexOf(type);
                    typeArray1.splice(key, 1);

                    let key1 = activityArray1.indexOf(3);
                    activityArray1.splice(key1, 1);

                    hideEmail = true;
                } else {
                    let key = typeArray1.indexOf(type);
                    typeArray1.splice(key, 1);
                }
            }
            resolve(1)
        })

        promise.then(result => {
            this.setState({ typeArray: typeArray1, activityArray: activityArray1 });
            this.props.thisData.setState({ logtypeid: typeArray1, activitypeid: activityArray1 });
            this.props.thisData.handlefiltermain(typeArray1, activityArray1, hideEmail);
        });
    }

    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }

    render() {
        let { activities, activityArray, typeArray } = this.state;
        let def_typearr = [1, 2, 3, 4, 15, 20, 21, 5, 100];
        let flag_all = true;

        if (activities && activities.length > 0) {
            activities.map((act) => {
                if (activityArray.indexOf(parseFloat(act.id)) == -1)
                    flag_all = false;
            })
        } else {
            flag_all = false;
        }

        def_typearr.map((def) => {
            if (typeArray.indexOf(def) == -1)
                flag_all = false;
        })

        return (
            <>
                <Grid container justify="space-between">
                    <Grid item xs={10}></Grid>
                    <Grid item xs={2}>
                        <List className="p-0 pull-right mr-20 log_filter_list">
                            <ListItem
                                aria-label="More"
                                aria-owns={this.state.showCommentoptions ? "filters_menu" : undefined}
                                aria-haspopup="true"
                                onClick={this.handleClick}
                                className="font-size-14 cursor_poiter  pr-0"
                            >
                                Filters <Icon>arrow_drop_down</Icon>
                            </ListItem>
                            <Menu
                                className=""
                                id="long-menu"
                                anchorEl={this.state.Commentoptions}
                                open={this.state.showCommentoptions}
                                onClose={this.handleCloseOptions}
                                PaperProps={{
                                    style: {
                                        width: 200
                                    }
                                }}
                            >
                                <MenuItem className="filter_items">
                                    <Icon className="mr-16">storage</Icon> <TooltipNew title='All Activities'>
                                        <div className="text_ellipsis " style={{ maxWidth: '72px', fontSize: '14px', margin: '3px 0px 0px 0px', fontWeight: '400' }}>All Activities</div></TooltipNew>
                                    <Checkbox color="primary"
                                        disabled={false}
                                        className={"crm_log_checkbox_filter checkB_color"}
                                        name={'allActivities'}
                                        id={'filterCheck'}
                                        checked={flag_all}
                                        onChange={(event, value) => this.handleMenuClick(event, 1000, 1000)}
                                        value={this.state.filterCheck}
                                        color="primary"
                                    />
                                </MenuItem>
                                {(activities && activities.length > 0) && activities.map((activity) => (
                                    parseFloat(activity.id) !== 3 && (
                                        <MenuItem className="filter_items">
                                            {/* <FontAwesome className={activity.icon + " mr-16"} style={{ fontSize: '15px', padding: '5px' }} /> */}
                                            {activity.icon && activity.icon === "fa-paper-plane" && <FontAwesome className={activity.icon + " p-5 crm_activitytype_icon"} style={{ fontSize: '20px', padding: '5px 18px 5px 2px' }} />}
                                            {activity.icon && activity.icon !== "fa-paper-plane" && <FontAwesome className={activity.icon + " mr-16"} style={{ fontSize: '15px', padding: '5px' }} />}
                                            <TooltipNew title={activity.name}>
                                                <div className="text_ellipsis " style={{ maxWidth: '72px', fontSize: '14px', margin: '3px 0px 0px 0px', fontWeight: '400' }}>{activity.name}</div></TooltipNew>
                                            <Checkbox color="primary"
                                                className={"crm_log_checkbox_filter checkB_color"}
                                                disabled={false}
                                                name={activity.name}
                                                id={'filterCheck'}
                                                checked={(activityArray.indexOf(parseFloat(activity.id)) != -1) ? true : false}
                                                onChange={(event, value) => this.handleMenuClick(event, parseFloat(activity.id), 0)}
                                                value={this.state.filterCheck}
                                                color="primary"
                                            />
                                        </MenuItem>)
                                ))}
                                <MenuItem className="filter_items">
                                    <FontAwesome className={"fa-credit-card p-5 crm_activitytype_icon"} style={{ fontSize: '17px', padding: '5px 18px 5px 5px' }} />
                                    <TooltipNew title='Emails'>
                                        <div className="text_ellipsis " style={{ maxWidth: '72px', fontSize: '14px', margin: '3px 0px 0px 0px', fontWeight: '400' }}>Emails</div></TooltipNew>
                                    <Checkbox color="primary"
                                        disabled={false}
                                        className={"crm_log_checkbox_filter checkB_color"}
                                        name={'email'}
                                        id={'filterCheck'}
                                        checked={(typeArray.indexOf(100) != -1) ? true : false}
                                        onChange={(event, value) => this.handleMenuClick(event, 100, 100)}
                                        value={this.state.filterCheck}
                                        color="primary"
                                    />
                                </MenuItem>
                                <MenuItem className="filter_items">
                                    <Icon className="mr-16">subject</Icon><TooltipNew title='Notes'>
                                        <div className="text_ellipsis " style={{ maxWidth: '72px', fontSize: '14px', margin: '3px 0px 0px 0px', fontWeight: '400' }}>Notes</div></TooltipNew>
                                    <Checkbox color="primary"
                                        disabled={false}
                                        className={"crm_log_checkbox_filter checkB_color"}
                                        name={"note"}
                                        id={'filterCheck'}
                                        checked={(typeArray.indexOf(5) != -1) ? true : false}
                                        onChange={(event, value) => this.handleMenuClick(event, 5, 5)}
                                        value={this.state.filterCheck}
                                        color="primary"
                                    />
                                </MenuItem>
                                <MenuItem className="filter_items">
                                    <Icon className="mr-16">storage</Icon><TooltipNew title='System Updates'>
                                        <div className="text_ellipsis " style={{ maxWidth: '72px', fontSize: '14px', margin: '3px 0px 0px 0px', fontWeight: '400' }}>System Updates</div></TooltipNew>
                                    <Checkbox color="primary"
                                        disabled={false}
                                        className={"crm_log_checkbox_filter checkB_color"}
                                        name={'sysupdate'}
                                        id={'filterCheck'}
                                        checked={(typeArray.indexOf(1) != -1) ? true : false}
                                        onChange={(event, value) => this.handleMenuClick(event, 1001, 1001)}
                                        value={this.state.filterCheck}
                                        color="primary"
                                    />
                                </MenuItem>
                            </Menu>
                        </List>
                    </Grid>
                </Grid>
            </>
        )
    }
}

class ContactLogs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            viewHtmlData: '',
            shouldOpenViewEmailDialog: false,
            FullViewEmailDialog: false,
            minimizeViewEmailDialog: false,

            selectedDate: new Date(),
            isOpenedEmailIds: [],
            isOpenedReplayEmailLog: false,
            allLogs: [],
            logvaluetype: 'Phone Call',
            logtype: '',
            textvalue: '',
            dateformat: 'MM/DD/YYYY',
            timeZone: 'America/New_York',
            shouldOpenCreateLogDialog: false,
            FullCreateContactDialog: false,
            minimizeContactDialog: false,
            isDeleteLogConfirmDialoOpen: false,
            isEditLog: false,
            isEditComment: false,
            logid: '',
            commentid: '',
            Commentoptions: '',
            showCommentoptions: false,
            isOpenedCommentIds: [],
            logtypeid: [],
            activitypeid: [],
            activities: [],
            userid: "",
            showSelector: false,
            reactions: ["👍", "🔥", "😍", "🎉", "👏", "💯", "😎", "😂", "😭", "⛳️", "🙂", "🎯", "🍾", "😢", "❤️"],

            allSettings: "",
            emaildata: [],
            mainUserData: [],
            isSendNewMail: false,
            userSelectedEmail: "",
            userName: ""
        };

    }
    componentDidMount() {
        let val = { typeId: 2 }
        this.props.customFieldList(this.props.apolloClient.client, val);

        this.props.getEmailSetting(this.props.apolloClient.client);

        this.props.getActivityTypes(this.props.apolloClient.client);
        let userData = JSON.parse(localStorage.getItem('user_data_session'))
        this.setState({
            userid: userData.id
        })
    }
    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }
    componentWillReceiveProps(props) {
        if (props.contactReducer.contactFieldData.data && props.contactReducer.contactFieldData.data.length > 0 && props.contactReducer.contactFieldData.is_loaded) {
            var data = props.contactReducer.contactFieldData.data;
            var mainIntemplate = {};
            data.map((details, index) => {
                if (details.fieldkey == "name") {
                    mainIntemplate.fullname = details.contactdetail.length > 0 ? details.contactdetail[0].fieldvalue : null;
                    mainIntemplate.firstname = (details.contactdetail.length > 0 && details.contactdetail[0].values.firstname) ? details.contactdetail[0].values.firstname : "";
                    mainIntemplate.lastname = (details.contactdetail.length > 0 && details.contactdetail[0].values.lastname) ? details.contactdetail[0].values.lastname : "";
                    this.setState({ userName: details.contactdetail.length > 0 ? details.contactdetail[0].fieldvalue : null })
                }
                if (details.fieldkey == "company" || details.fieldkey == "contacttype" || details.fieldkey == "email" || details.fieldkey == "phones") {
                    mainIntemplate[details.fieldkey] = (details.contactdetail.length > 0 && details.contactdetail[0].values.length > 0) ? details.contactdetail[0].values[0].value : null
                }
                if (details.fieldkey == "title" || details.fieldkey == "address") {
                    mainIntemplate[details.fieldkey] = (details.contactdetail.length > 0 && details.contactdetail[0].fieldvalue) ? details.contactdetail[0].fieldvalue : null
                }
                if (details.fieldkey == "owner") {
                    mainIntemplate.owner = (details.contactdetail.length > 0 && details.contactdetail[0].values.length > 0) ? details.contactdetail[0].values[0].name : null
                }
                if (details.fieldkey == "email") {
                    this.setState({
                        userSelectedEmail: (details.contactdetail.length > 0 && details.contactdetail[0].values.length > 0) ? details.contactdetail[0].values[0].value : null
                    })
                }
            })
            this.setState({
                mainUserData: mainIntemplate
            })
        }

        if (props.settingReducer.emailsettings.data) {
            const mian_data = props.settingReducer.emailsettings.data
            this.setState({
                allSettings: mian_data,
            })
        }

        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            let usersetting = props.settingReducer.userSettingArray.data;

            let format = usersetting.dateformat;
            let timezone = usersetting.timezone;
            this.setState({ dateformat: format, timeZone: timezone })
        }
        if (props.settingReducer.masterActivityTypesList) {
            this.setState({ activities: props.settingReducer.masterActivityTypesList.data });
        }
        if (props.contactReducer.contactLogList && props.contactReducer.contactLogList.data && props.contactReducer.contactLogList.data.logdata && props.contactReducer.contactLogList.data.logdata.length > 0) {
            let logged_in_user_email = JSON.parse(localStorage.getItem("user_data_session")).email;
            let logData = [];
            let results = [];
            let resultList = [];
            props.contactReducer.contactLogList.data.logdata.map((data) => {
                if (data.logtype == 0 || data.logtype == 5) {
                    resultList.push({
                        str: moment.unix(data.logdate).tz(this.state.timeZone).format('MMM YYYY')
                    })
                } else {
                    resultList.push({
                        str: moment.unix(data.createddate).tz(this.state.timeZone).format('MMM YYYY')
                    })
                }
            })
            let name = '';
            let source = '';
            if (props.contactReducer.contactFieldData.data) {
                let fieldSource = props.contactReducer.contactFieldData.data;
                var sourcekey = this.findWithAttr(fieldSource, 'fieldkey', 'name');

                if (fieldSource[sourcekey].contactdetail && fieldSource[sourcekey].contactdetail[0].fieldvalue)
                    source = fieldSource[sourcekey].contactdetail[0].fieldvalue;
            }
            results['Pinned'] = [];
            let results_pined_ids = [];
            resultList.map((mont) => {
                results[mont.str] = [];

                props.contactReducer.contactLogList.data.logdata.map((data) => {

                    let pinned = data.ispined;
                    if (data.email_type) {
                        if (mont.str == moment.unix(data.createddate).tz(this.state.timeZone).format('MMM YYYY')) {
                            let log_user = JSON.parse(localStorage.getItem('user_data_session'));
                            let color_class = (log_user.name) ? log_user.name.charAt(0) : "";

                            if (data.email_type == 1) {
                                let date = moment(data.details.email_date_time).format("X");
                                logData.push(
                                    {
                                        logId: data.id,
                                        emojidetail: data.emojidetail && data.emojidetail !== null ? JSON.parse(data.emojidetail) : [],

                                        emailtype: 1,
                                        ispined: pinned,
                                        name: data.name,
                                        email: data.email,
                                        color: color_class,
                                        comments: data.__commentlist__,
                                        attachment: data.attachment,
                                        emaildetails: data.details,
                                        emailTo: data.emailTo,
                                        snippet: data.details.snippet,
                                        subject: data.details.subject,
                                        body_html: data.details.body_html,
                                        email_date_time: moment.unix(data.createddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " + moment.unix(data.createddate).tz(this.state.timeZone).format('hh:mm A'),
                                    }
                                );
                            }
                            else if (data.email_type == 2) {
                                logData.push(
                                    {
                                        logId: data.id,
                                        emailtype: 2,
                                        emojidetail: data.emojidetail && data.emojidetail !== null ? JSON.parse(data.emojidetail) : [],

                                        ispined: pinned,
                                        name: data.name,
                                        email: data.email,
                                        color: color_class,
                                        comments: data.__commentlist__,
                                        attachment: data.attachment,
                                        emaildetails: data.details,
                                        emailTo: data.emailTo,
                                        snippet: data.details.snippet,
                                        subject: data.details.subject,
                                        body_html: data.details.body_html,
                                        email_date_time: moment.unix(data.createddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " + moment.unix(data.createddate).tz(this.state.timeZone).format('hh:mm A'),
                                    }
                                );
                            }
                            else if (data.email_type == 4) {
                                logData.push(
                                    {
                                        logId: data.id,
                                        emojidetail: data.emojidetail && data.emojidetail !== null ? JSON.parse(data.emojidetail) : [],

                                        emailtype: 4,
                                        ispined: pinned,
                                        name: data.name,
                                        email: data.email,
                                        color: color_class,
                                        comments: data.__commentlist__,
                                        attachment: data.attachment,
                                        emaildetails: data.details,
                                        emailTo: data.emailTo,
                                        snippet: data.details.snippet,
                                        subject: data.details.subject,
                                        body_html: data.details.body_html,
                                        email_date_time: moment.unix(data.createddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " + moment.unix(data.createddate).tz(this.state.timeZone).format('hh:mm A'),
                                    }
                                );
                            }
                        }
                    }
                    else {
                        let template = data.template;
                        let color_class = (data.__user__.name) ? data.__user__.name.charAt(0) : "";
                        let text = (data.text) ? data.text : "";
                        let loguser = (data.__user__.email == logged_in_user_email) ? 'You' : data.__user__.name;
                        let template1 = (data.template) ? template.replace("{{user}}", loguser) : "";

                        let new_template = "";

                        let create_date = data.createddate;
                        let updateddate = data.updateddate;
                        if (mont.str == moment.unix(data.createddate).tz(this.state.timeZone).format('MMM YYYY') && data.logtype != 0 && data.logtype != 5) {
                            if (data.logtype == 1) // create time
                            {
                                new_template = template1.replace("{{source_type}}", "contact");
                            }
                            else if (data.logtype == 1000) {// RingCentral Call
                                updateddate = data.logdate;
                                new_template = "Ring Central Calls";
                            }
                            else if (data.logtype == 2) // name change
                            {
                                let old_template = template1.replace("{{source_type}}", "contact");
                                new_template = old_template.replace("{{source name=new_value}}", '"' + data.newvalue + '"');

                            }
                            else if (data.logtype == 3 && data.__assignuser__) // owner change
                            {
                                let logAssignUser = (data.__assignuser__.email == logged_in_user_email) ? 'You' : data.__assignuser__.name;
                                new_template = template1.replace("{{target}}", '"' + logAssignUser + '"');
                            }
                            else if (data.logtype == 4) // for status
                            {
                                let old_template = template1.replace("{{old_value}}", data.oldvalue);
                                new_template = old_template.replace("{{new_value}}", data.newvalue);
                            }
                            // else if (data.logtype == 5) // for note
                            // {
                            //     updateddate = data.logdate;
                            //     new_template = template1.replace("{{property_name}}", '"' + data.propertyname + '"');
                            // }
                            else if (data.logtype == 15) // for note
                            {
                                let old_template = template1.replace("{{old_value}}", data.oldvalue);
                                let old_template1 = old_template.replace("{{new_value}}", data.newvalue);
                                new_template = old_template1.replace("{{property_name}}", '"' + data.propertyname + '"');

                            }
                            else if (data.logtype == 20) // for task
                            {
                                if (data.__task__ != null) {
                                    let old_template = template1.replace("{{task}}", data.__task__.__taskdetail__[0].fieldvalue);
                                    new_template = old_template.replace("{{source}}", source);
                                }
                            }
                            else if (data.logtype == 21) // for task
                            {
                                if (data.__task__ != null) {
                                    let old_template = template1.replace("{{task}}", data.__task__.__taskdetail__[0].fieldvalue);
                                    let old_template1 = old_template.replace("{{source}}", source);
                                    new_template = old_template1.replace("{{target}}", loguser);
                                }
                            }
                            if (new_template != "") {
                                logData.push(
                                    {
                                        logId: data.id,
                                        emojidetail: data.emojidetail && data.emojidetail !== null ? JSON.parse(data.emojidetail) : [],
                                        mentionid: data.mentionid,
                                        mentiondetail: data.mentiondetail ? data.mentiondetail : "",
                                        ispined: pinned,
                                        creaetedDate: moment.unix(create_date).tz(this.state.timeZone).format(this.state.dateformat),
                                        emailtype: 0,
                                        activitytype: data.__activitytype__,
                                        comments: data.__commentlist__,
                                        user: data.__user__.name,
                                        useremail: data.__user__.email,
                                        propertyType: data.propertyname,
                                        color: color_class,
                                        template: new_template,
                                        text: text,
                                        logtype: data.logtype,
                                        time: (updateddate != null)
                                            ?
                                            moment.unix(updateddate).tz(this.state.timeZone).format('hh:mm A')
                                            :
                                            moment.unix(create_date).tz(this.state.timeZone).format('hh:mm A'),
                                        date: (updateddate != null)
                                            ?
                                            moment.unix(updateddate).tz(this.state.timeZone).format(this.state.dateformat)
                                            :
                                            moment.unix(create_date).tz(this.state.timeZone).format(this.state.dateformat)
                                    }
                                );
                            }
                        } else if (mont.str == moment.unix(data.logdate).tz(this.state.timeZone).format('MMM YYYY') && (data.logtype == 0 || data.logtype == 5)) {
                            if (data.logtype == 0) // Phone call
                            {
                                updateddate = data.logdate;
                                new_template = template1.replace("{{property_name}}", '"' + data.propertyname + '"');
                            } else if (data.logtype == 5) // for note
                            {
                                updateddate = data.logdate;
                                new_template = template1.replace("{{property_name}}", '"' + data.propertyname + '"');
                            }

                            if (new_template != "") {

                                logData.push({
                                    logId: data.id,
                                    emojidetail: data.emojidetail && data.emojidetail !== null ? JSON.parse(data.emojidetail) : [],
                                    mentionid: data.mentionid,
                                    mentiondetail: data.mentiondetail ? data.mentiondetail : "",
                                    creaetedDate: moment.unix(create_date).tz(this.state.timeZone).format(this.state.dateformat),
                                    emailtype: 0,
                                    comments: data.__commentlist__,
                                    activitytype: data.__activitytype__,
                                    user: data.__user__.name,
                                    useremail: data.__user__.email,
                                    propertyType: data.propertyname,
                                    color: color_class,
                                    template: new_template,
                                    ispined: pinned,
                                    text: text,
                                    logtype: data.logtype,
                                    time: (updateddate != null)
                                        ?
                                        moment.unix(updateddate).tz(this.state.timeZone).format('hh:mm A')
                                        :
                                        moment.unix(create_date).tz(this.state.timeZone).format('hh:mm A'),
                                    date: (updateddate != null)
                                        ?
                                        moment.unix(updateddate).tz(this.state.timeZone).format(this.state.dateformat)
                                        :
                                        moment.unix(create_date).tz(this.state.timeZone).format(this.state.dateformat)
                                });

                            }
                        }

                    }

                    if (logData.length > 0) {
                        if (logData[0].ispined && logData[0].ispined == true) {
                            if (results_pined_ids.indexOf(logData[0].logId) == -1) {
                                results['Pinned'].push(logData);
                                results_pined_ids.push(logData[0].logId);
                            }
                        } else {
                            results[mont.str].push(logData);
                        }
                        logData = []
                    }
                    // }
                });
            });

            let temp_arr1 = [];

            Object.keys(results).forEach(function (key) {
                let temp_arr2 = [];
                if (key === 'Pinned') {
                    temp_arr2['key'] = 'Pinned';
                    temp_arr2['log'] = results['Pinned'];
                    temp_arr1.unshift(temp_arr2);
                } else if (key !== 'Pinned') {
                    temp_arr2['key'] = key;
                    temp_arr2['log'] = results[key];
                    temp_arr1.push(temp_arr2);
                }
            })

            // this.setState({ LogResult: results, contact_id: props.contact_id});
            this.setState({ allLogs: temp_arr1, contact_id: props.contact_id });
        }
        else if (props.contactReducer.contactLogList && props.contactReducer.contactLogList.data && props.contactReducer.contactLogList.data.logdata && props.contactReducer.contactLogList.data.logdata.length <= 0) {
            this.setState({ allLogs: [], contact_id: props.contact_id });
        }

    }


    handleContactDialogClose = () => {
        this.setState({ shouldOpenCreateLogDialog: false, FullCreateContactDialog: false, minimizeContactDialog: false });
    };
    handleContactDialogMinimize = () => {
        this.setState(oldStateminimizeContactDialog => ({ minimizeContactDialog: !oldStateminimizeContactDialog.minimizeContactDialog }));
        this.setState({ FullCreateContactDialog: false })
    };
    handleContactDialogFull = () => {
        this.setState(oldStateFullCreateContactDialog => ({ FullCreateContactDialog: !oldStateFullCreateContactDialog.FullCreateContactDialog }));
        this.setState({ minimizeContactDialog: false })
    };
    handleViewEmailDialogClose = () => {
        this.setState({ shouldOpenViewEmailDialog: false, FullViewEmailDialog: false, minimizeViewEmailDialog: false });
    };

    handlechange = (event, logid) => {
        this.setState({ [event.target.name]: event.target.value, logid: logid })
    }
    handleDateChange = date => {
        this.setState({ selectedDate: date });
    };
    handleCancelAddLog = () => {
        this.setState({ textvalue: '' })
    }
    handleSaveEditLog = (data) => {
        let mention = [];
        Object.entries(data.raw.entityMap).map((entity) => {
            if (mention.indexOf(entity[1].data.mention.id) == -1) {
                mention.push(parseFloat(entity[1].data.mention.id))
            }
        });
        // let date = moment(this.state.selectedDate).format("X");

        let date_test = moment(this.state.selectedDate).format('YYYY-MM-DD HH:mm');
        console.log(date_test, 'inserted');

        var logdate = moment.tz(date_test, this.state.timeZone).format();
        console.log(logdate, this.state.timeZone);

        var date = moment.utc(logdate).format('X');
        console.log(date, 'utc');

        console.log(moment.unix(date).tz(this.state.timeZone).format('YYYY-MM-DD hh:mm A'), 'conv');

        // let textvalue = this.state.textvalue;
        let saveLogparams = [];

        saveLogparams['update'] = {
            text: data.textvalue,
            mentionuser: mention,
            mentiondetail: JSON.stringify(data.raw),
            logtime: parseFloat(date),
            logdate: parseFloat(date),
            logid: parseFloat(this.state.logid),
            // propertyid: parseFloat(0)
        };
        // saveLogparams['contactid'] = this.state.contact_id
        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        saveLogparams['getlog'] = { emailid: emailParam, contactid: parseFloat(this.props.contact_id), activitypeid: this.state.activitypeid, logtypeid: this.state.logtypeid };

        this.props.updateContactLog(this.props.apolloClient.client, saveLogparams);
        this.setState({ isEditLog: false })
    }

    //------delete log
    handleDeleteLog = (logid) => {
        this.setState({ logid: logid });
        this.toggleDeleteDialog()
    }
    toggleDeleteDialog = () => {
        this.setState({ isDeleteLogConfirmDialoOpen: !this.state.isDeleteLogConfirmDialoOpen });
    };
    confirmDeleteLog = () => {
        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        let deletelogParams = { logid: parseFloat(this.state.logid), contactid: parseFloat(this.state.contact_id), emailid: emailParam, activitypeid: this.state.activitypeid, logtypeid: this.state.logtypeid };
        this.props.deleteContactLogDetail(this.props.apolloClient.client, deletelogParams);
        this.toggleDeleteDialog()
    };


    //email menu functions
    handleEmailHtmlPopupMain = (logdata) => {
        this.setState({ shouldOpenViewEmailDialog: true, viewHtmlData: logdata });
    }

    //- comments-----------------
    toggleComment = (logid) => {
        let isOpenedCommentIds = this.state.isOpenedCommentIds;
        if (isOpenedCommentIds.indexOf(logid) == -1) {
            isOpenedCommentIds.push(logid);
        }
        else {
            let index = isOpenedCommentIds.indexOf(logid);
            isOpenedCommentIds.splice(index, 1);
        }
        this.setState(({ commentvalue: "", logid: logid, isOpenedCommentIds: isOpenedCommentIds }));
    }

    handleCancelComment = () => {
        this.setState({ commentvalue: '' });
    }

    handleSaveCommentLog = (type) => {
        const textvalue = this.state.commentState.textvalue
        const raw = this.state.commentState.raw
        let mention = [];
        Object.entries(raw.entityMap).map((entity) => {
            if (mention.indexOf(entity[1].data.mention.id) == -1) {
                mention.push(parseFloat(entity[1].data.mention.id))
            }
        });
        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        let commentParams = {
            type: type,
            comment: this.state.commentvalue,
            logid: parseFloat(this.state.logid),
            contactid: parseFloat(this.state.contact_id),
            emailid: emailParam, logtypeid: this.state.logtypeid,
            activitypeid: this.state.activitypeid,
            mentionuser: mention,
            mentiondetail: "",
        };
        this.props.addContactLogComment(this.props.apolloClient.client, commentParams);
        this.setState({ commentvalue: '' });
    }

    //-edit comment
    handleSaveEditComment = (type) => {
        const textvalue = this.state.commentState.textvalue
        const raw = this.state.commentState.raw
        let mention = [];
        Object.entries(raw.entityMap).map((entity) => {
            if (mention.indexOf(entity[1].data.mention.id) == -1) {
                mention.push(parseFloat(entity[1].data.mention.id))
            }
        });
        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        let commentParams = {
            type: type,
            commentid: parseFloat(this.state.commentid),
            comment: this.state.commentvalue,
            contactid: parseFloat(this.state.contact_id),
            emailid: emailParam,
            logtypeid: this.state.logtypeid,
            activitypeid: this.state.activitypeid,
            mentionuser: mention,
            mentiondetail: "",
        };
        this.setState({ commentvalue: '', isEditComment: false, commentid: '' });

        this.props.updateContactLogComment(this.props.apolloClient.client, commentParams);
    };

    handleEditCommentMain = (id, comment) => {
        this.setState({ isEditComment: true, commentvalue: comment, commentid: id });
    }

    handleCancelEditComment = () => {
        this.setState({ isEditComment: false, commentvalue: '' })
    }

    //-delete comment
    deleteComment = (cmt_id, type) => {
        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        let commentParams = { commentid: cmt_id, type: type, emailid: emailParam, contactid: parseFloat(this.state.contact_id), activitypeid: this.state.activitypeid, logtypeid: this.state.logtypeid };
        this.props.deleteContactLogComment(this.props.apolloClient.client, commentParams);
    }
    //--------------comments functions-----------

    //---- email functions
    toggleEmail = (id) => {
        let isOpenedEmailIds = this.state.isOpenedEmailIds;

        if (isOpenedEmailIds.indexOf(id) == -1) {
            isOpenedEmailIds.push(id);
        }
        else {
            let index = isOpenedEmailIds.indexOf(id);
            isOpenedEmailIds.splice(index, 1);
        }

        this.setState(({ logid: id, isOpenedEmailIds: isOpenedEmailIds }));
    }

    toggleReplayEmailLog = () => {
        this.setState({
            isOpenedReplayEmailLog: !this.state.isOpenedReplayEmailLog
        });
    }
    componentWillUnmount() {
        localStorage.removeItem('emailLogEmail');
    }
    //------------

    // -- get all months between two dates
    getMonths = (startDate, endDate) => {
        var resultList = [];
        var date = moment.unix(startDate).tz(this.state.timeZone)._d;
        var endDate = moment.unix(endDate).tz(this.state.timeZone)._d;

        var monthNameList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        while (date.getMonth() <= endDate.getMonth() + 1) {
            var stringDate = monthNameList[date.getMonth()] + " " + date.getFullYear();

            //get first and last day of month
            var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

            resultList.push({
                str: stringDate,
            });
            date.setMonth(date.getMonth() + 1);
        }
        resultList.reverse();
        return resultList;
    };
    handlefiltermain = (types, activities, hideEmail) => {
        if (types.length == 0 && activities.length == 0) {
            this.setState({ allLogs: [] });
        } else {
            let key1 = types.indexOf(100);
            if (types.length === 0 && activities.length === 0) {
                hideEmail = false;
            } else {
                hideEmail = (key1 !== -1) ? false : true;
            }
            let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
            this.setState({ logtypeid: types, activitypeid: activities });
            let getfilterLogParam = {
                contactid: this.state.contact_id,
                emailid: hideEmail ? "" : emailParam,
                logtypeid: types,
                activitypeid: activities
            }
            this.props.getContactLogListNew(this.props.apolloClient.client, getfilterLogParam);
        }
    }
    togglePin = (logId, pinned) => {
        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';

        let pinParams = {
            ispined: !pinned,
            logid: parseFloat(logId),
            recordid: parseFloat(this.state.contact_id),
            recordtypeid: 2,
            emailid: emailParam,
            logtypeid: this.state.logtypeid,
            activitypeid: this.state.activitypeid
        }

        this.props.addLogPined(this.props.apolloClient.client, pinParams);
    }
    handleAdd = (log) => this.setState({ showSelector: !this.state.showSelector, logid: log.logId })

    handleSelect = (emoji, data) => {

        const index = data.emojidetail.findIndex(obj => obj.emoji === emoji && obj.by === this.state.userid)
        var main_data = data.emojidetail;
        if (index > -1) {
            main_data = [...main_data.slice(0, index), ...main_data.slice(index + 1)]
        } else {
            main_data = [...main_data, { emoji, by: this.state.userid }]
        }
        this.setState({ showSelector: false })

        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';

        let req = {
            logid: data.emailtype !== 0 ? parseFloat(data.emaildetails.id) : parseFloat(data.logId),
            emoji: emoji,
            emojidetail: JSON.stringify(main_data),
            contactid: parseFloat(this.state.contact_id),
            activitypeid: this.state.activitypeid,
            emailid: emailParam,
            logtypeid: this.state.logtypeid,
            logtype: data.emailtype !== 0 ? "email" : "log"
        }
        this.props.addContactLogEmoji(this.props.apolloClient.client, req)
    }
    isOpenedClickEmailToggle = () => {
        this.setState({
            isSendNewMail: !this.state.isSendNewMail
        })
    }
    openNewMail = (log) => {
        this.setState({ isOpenedReplayEmailLog: true, logid: log.logId, emaildata: log });
    }
    getdata = (data) => {
        console.log("data ....", data)
        this.setState({ commentvalue: data.textvalue, commentState: data })
    }
    getEditCommentData = (data) => {
        console.log("data edit ....", data)
        this.setState({ commentvalue: data.textvalue, commentState: data })
    }
    saveId = (id, date) => {
        this.setState({ logid: id, selectedDate: date })
    }
    AddNewLog = (log) => {
        this.setState({
            logtype: log.logtype,
            logvaluetype: log.propertyType,
            shouldOpenCreateLogDialog: true
        })
    }
    handleSaveAddLog = (data) => {
        let saveLogparams = [];
        saveLogparams['save'] = {
            mentionuser: data.mentionuser,
            mentiondetail: data.mentiondetail,
            propertyname: data.propertyname,
            propertyid: data.propertyid,
            text: data.text,
            logtime: data.logtime,
            logdate: data.logdate,
            oldvalue: "",
            newvalue: "",
            logtype: data.logtype,
            fieldid: data.fieldid,
            contactid: this.props.contact_id
        };

        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        saveLogparams['getlog'] = { emailid: emailParam, contactid: parseFloat(this.props.contact_id), logtypeid: [], activitypeid: [] };
        this.props.saveContactLog(this.props.apolloClient.client, saveLogparams);
        this.setState({ shouldOpenCreateLogDialog: false });

    }
    render() {
        const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

        const { isOpenedReplayEmailLog, commentvalue, allLogs, logvaluetype, isOpenedComment, textvalue, selectedDate, shouldOpenCreateLogDialog, minimizeContactDialog, FullCreateContactDialog } = this.state
        let user_data = JSON.parse(localStorage.getItem('user_data_session'));
        let current_logUser = user_data.email;
        let loghtml = [];
        let logheader = [];
        let logbody = [];
        let allowUserReply = user_data.googlestatus === true && this.state.allSettings.setting_one && this.state.allSettings.setting_one === 1 ? true : false

        allLogs.map((logdata) => {
            logheader = [];
            logbody = [];
            logheader.push(
                <FilterLogoCommon headerKey={logdata.key} />
            )
            logdata['log'].map((log) => {
                log = log[0];
                if (log.emailtype != 0) {
                    if (log.emailtype == 1) {
                        logbody.push(
                            <EmailTypeOne
                                allowUserReply={allowUserReply}
                                commentvalue={commentvalue}
                                log={log}
                                logid={this.state.logid}
                                userName={this.state.userName}
                                lead_id={this.state.lead_id}
                                isOpenedEmailIds={this.state.isOpenedEmailIds}
                                showSelector={this.state.showSelector}
                                dateformat={this.state.dateformat}
                                timeZone={this.state.timeZone}
                                commentid={this.state.commentid}
                                isOpenedCommentIds={this.state.isOpenedCommentIds}
                                isEditComment={this.state.isEditComment}
                                thatAll={this}
                                isOpenedReplayEmailLog={isOpenedReplayEmailLog}
                                transferLink={"/contact/contact/" + this.state.contact_id}
                                mainUserData={this.state.mainUserData}
                                emaildata={this.state.emaildata}
                                LogDetails={{
                                    contactid: parseFloat(this.state.contact_id),
                                    emailid: localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '',
                                    logtypeid: this.state.logtypeid,
                                    activitypeid: this.state.activitypeid,
                                }}
                                LogComponent="Contact" />
                        )
                    }
                    else if (log.emailtype == 2) {
                        logbody.push(
                            <EmailTypeTwo
                                allowUserReply={allowUserReply}
                                commentvalue={commentvalue}
                                log={log}
                                logid={this.state.logid}
                                userName={this.state.userName}
                                lead_id={this.state.lead_id}
                                isOpenedEmailIds={this.state.isOpenedEmailIds}
                                showSelector={this.state.showSelector}
                                dateformat={this.state.dateformat}
                                timeZone={this.state.timeZone}
                                commentid={this.state.commentid}
                                isOpenedCommentIds={this.state.isOpenedCommentIds}
                                isEditComment={this.state.isEditComment}
                                thatAll={this}
                                isOpenedReplayEmailLog={isOpenedReplayEmailLog}
                                transferLink={"/contact/contact/" + this.state.contact_id}
                                mainUserData={this.state.mainUserData}
                                emaildata={this.state.emaildata}
                                LogDetails={{
                                    contactid: parseFloat(this.state.contact_id),
                                    emailid: localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '',
                                    logtypeid: this.state.logtypeid,
                                    activitypeid: this.state.activitypeid,
                                }}
                                LogComponent="Contact" />
                        )
                    }
                    else if (log.emailtype == 4) {
                        logbody.push(
                            <EmailTypeFour
                                allowUserReply={allowUserReply}
                                commentvalue={commentvalue}
                                log={log}
                                logid={this.state.logid}
                                userName={this.state.userName}
                                lead_id={this.state.lead_id}
                                isOpenedEmailIds={this.state.isOpenedEmailIds}
                                showSelector={this.state.showSelector}
                                dateformat={this.state.dateformat}
                                timeZone={this.state.timeZone}
                                commentid={this.state.commentid}
                                isOpenedCommentIds={this.state.isOpenedCommentIds}
                                isEditComment={this.state.isEditComment}
                                thatAll={this}
                                isOpenedReplayEmailLog={isOpenedReplayEmailLog}
                                transferLink={"/contact/contact/" + this.state.contact_id}
                                mainUserData={this.state.mainUserData}
                                emaildata={this.state.emaildata}
                                LogDetails={{
                                    contactid: parseFloat(this.state.contact_id),
                                    emailid: localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '',
                                    logtypeid: this.state.logtypeid,
                                    activitypeid: this.state.activitypeid,
                                }}
                                LogComponent="Contact" />
                        )
                    }
                } else {
                    if (log.logtype == 0) {
                        logbody.push(
                            <CallLogs
                                thatAll={this}
                                log={log}
                                commentvalue={this.state.commentvalue}
                                selectedDate={this.state.selectedDate}
                                logid={this.state.logid}
                                dateformat={this.state.dateformat}
                                showSelector={this.state.showSelector}
                                isOpenedCommentIds={this.state.isOpenedCommentIds}
                                isEditComment={this.state.isEditComment}
                                commentid={this.state.commentid}
                                timeZone={this.state.timeZone}
                            />
                        )
                    } else if (log.logtype == 1000) {
                        logbody.push(
                            <RingCentralCallLogs
                                thatAll={this}
                                log={log}
                                commentvalue={this.state.commentvalue}
                                selectedDate={this.state.selectedDate}
                                logid={this.state.logid}
                                dateformat={this.state.dateformat}
                                showSelector={this.state.showSelector}
                                isOpenedCommentIds={this.state.isOpenedCommentIds}
                                isEditComment={this.state.isEditComment}
                                commentid={this.state.commentid}
                                timeZone={this.state.timeZone}
                            />)
                    }
                    else if (log.logtype == 5) {
                        logbody.push(
                            <NoteLog
                                thatAll={this}
                                log={log}
                                commentvalue={this.state.commentvalue}
                                selectedDate={this.state.selectedDate}
                                logid={this.state.logid}
                                dateformat={this.state.dateformat}
                                showSelector={this.state.showSelector}
                                isOpenedCommentIds={this.state.isOpenedCommentIds}
                                isEditComment={this.state.isEditComment}
                                commentid={this.state.commentid}
                                timeZone={this.state.timeZone}
                            />
                        )
                    }
                    else {
                        logbody.push(
                            <SystemLog log={log} />
                        )
                    }
                }
            })
            if (logbody && logbody.length > 0) {
                loghtml.push(logheader);
                loghtml.push(logbody);
            }
        })


        return (
            <>
                <FiltersMenu proVar={this.state.activities} thisData={this} />
                <div>
                    {loghtml.map((month_log) => (
                        <div className="lead_upcoming">
                            <div className="box_portlet">
                                {month_log}
                            </div>
                        </div>
                    ))}
                </div>
                {shouldOpenCreateLogDialog && (
                    <CreatelogDialog
                        handleClose={this.handleContactDialogClose}
                        open={shouldOpenCreateLogDialog}
                        handleMinimize={this.handleContactDialogMinimize}
                        handleFull={this.handleContactDialogFull}
                        minimizeScreen={minimizeContactDialog}
                        fullScreen={FullCreateContactDialog}
                        handleSaveAddLog={this.handleSaveAddLog}
                        logvaluetype={this.state.logvaluetype}
                        logtype={this.state.logtype}
                    />
                )}
                <Dialog
                    fullWidth={true}
                    maxWidth={'xs'}
                    open={this.state.isDeleteLogConfirmDialoOpen}
                    onClose={this.toggleDeleteDialog}
                    disableBackdropClick

                    aria-labelledby="ar-remove-dialog-title"
                >
                    <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to remove selected log ?"}</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.toggleDeleteDialog} color="primary">
                            Cancel
                        </Button>
                        <Button type="Button" onClick={() => this.confirmDeleteLog()} className="btn_Primary">
                            Ok
                        </Button>

                    </DialogActions>
                </Dialog>
                {this.state.shouldOpenViewEmailDialog && (
                    <ViewHtmlDialog
                        handleClose={this.handleViewEmailDialogClose}
                        open={this.state.shouldOpenViewEmailDialog}
                        logDatas={this.state.viewHtmlData}
                    />
                )}
                {this.state.isSendNewMail &&
                    <InboxComposeDialog
                        handleClose={this.isOpenedClickEmailToggle}
                        open={this.state.isSendNewMail}
                        userSelectedEmail={this.state.userSelectedEmail}
                        mainUserData={this.state.mainUserData}
                        LogDetails={{
                            contactid: parseFloat(this.state.contact_id),
                            emailid: localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '',
                            logtypeid: this.state.logtypeid,
                            activitypeid: this.state.activitypeid,
                        }}
                        LogComponent="Contact"
                    />
                }
            </>
        );
    }
}

//export default ContactLogs;

const mapStateToProps = state => {
    return {
        contactReducer: state.contactReducer,
        apolloClient: state.apolloClient,
        settingReducer: state.settingReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
        addLogPined: (client, request) => dispatch(actions.addLogPined(client, request)),
        getContactLogListNew: (client, request) => dispatch(actions.getContactLogListNew(client, request)),
        // getLeadLogList: (client, request) => dispatch(actions.getLeadLogList(client, request)),
        getActivityTypes: (client) => { dispatch(actions.getActivityTypes(client)) },
        saveContactLog: (client, request) => dispatch(actions.saveContactLog(client, request)),
        deleteContactLogDetail: (client, request) => dispatch(actions.deleteContactLogDetail(client, request)),
        updateContactLog: (client, request) => dispatch(actions.updateContactLog(client, request)),
        addContactLogComment: (client, request) => dispatch(actions.addContactLogComment(client, request)),
        updateContactLogComment: (client, request) => dispatch(actions.updateContactLogComment(client, request)),
        deleteContactLogComment: (client, request) => dispatch(actions.deleteContactLogComment(client, request)),
        addContactLogEmoji: (client, request) => dispatch(actions.addContactLogEmoji(client, request)),
        getEmailSetting: (client) => dispatch(actions.getEmailSetting(client)),
        customFieldList: (client, request) => { dispatch(actions.customFieldList(client, request)) },

    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ContactLogs);
