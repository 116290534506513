import React, { Component } from "react";
import { connect } from 'react-redux';
import { Grid, Link, withStyles, CircularProgress, Container, Backdrop } from "@material-ui/core";
import * as actionCreators from '../../../store/actions/index';

const styles = (theme) => ({
  buttonProgress: {
    position: "absolute",
    top: "45%",
    left: "28%",
    marginTop: -12,
    marginLeft: -12
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

class CheckEmail extends Component {
  state = { loading: false, backdrop: false };
  componentWillReceiveProps() {
    this.setState({ loading: false, backdrop: false })
  }
  resendEmail = (e) => {
    e.preventDefault();
    this.setState({ loading: true, backdrop: true })
    console.log("fdsfdsfdsfsf===>", this.props.emailData.email)
    let send_data = { email: this.props.emailData.email }
    this.props.resendEmail(send_data);
  }
  render() {
    let { classes } = this.props;
    return (
      <Container maxWidth="sm" className="h-100vh">
        <Grid container justify="center" alignItems="center" className="h-100">
          <div className="center_bx h-auto">
            <div className="p-48 text-center">
              <h3 className="font-weight-bold mb-24">Please confirm your email</h3>
              <h5 className="font-weight-500">We just sent you a confirmation email</h5>
              <p className="subheading-1">
                Check your inbox and confirm your email address to complete your account registration. If you don't see an email check your spam folder or click <Link href="#" onClick={this.resendEmail} color="primary">here</Link> to resend
                {/* Check your inbox and confirm your email address to complete your account registration. If you don't see an email check your spam folder or click <Link href="#" onClick={this.resendEmail} color="primary">here</Link> to resend. */}
              </p>
              {this.state.loading && (
                <Backdrop
                  className={classes.backdrop}
                  open={this.state.backdrop}
                >
                  <CircularProgress size={60} color="#fff" />
                </Backdrop>
              )}
            </div>
          </div>
        </Grid>
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  emailData: state.authReducer.userData
});
const mapDispatchToProps = (dispatch) => ({
  resendEmail: (email) => dispatch(actionCreators.resendEmail(email))
})
export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(CheckEmail));
