import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function EmojiTemplate(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const reactions = ["👍", "🔥", "😍", "🎉", "👏", "💯", "😎", "😂", "😭", "⛳️", "🙂", "🎯", "🍾", "😢", "❤️"];
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const adEmoji = (emoji) => {
    props.handleSelect(emoji, props.logs)
    handleClose()
  }
  return (
    <>
      <div
        style={{ display: "inline-block" }}
        aria-owns={anchorEl ? "simple-menu" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        aria-describedby={"log_reaction"}
      >
        {props.menuButton}
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: props.isright ? 'top' : 'center',
          horizontal: props.isright ? 'right' : 'left',
        }}
      >
        <div className="emoji_popup">
          {reactions.map((emoji, id) => (
            <li key={id} onClick={() => adEmoji(emoji)}  >{emoji}</li>
          ))}
        </div>
      </Popover>
    </>
  );
}