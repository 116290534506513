import React, { Component } from "react";
import {
  Dialog, IconButton, Button, Icon,
  Fab, Grid, DialogActions,
  FormControl, MenuItem,
  TextField,
  InputLabel, Grow,
  Select, ListSubheader, withStyles,
  DialogTitle, Tooltip, Chip, makeStyles, Avatar,
} from "@material-ui/core";
import InputAdornment from '@material-ui/core/InputAdornment';
import DateFnsUtils from "@date-io/date-fns";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions/index"
import { MegadraftEditor, editorStateFromRaw, editorStateToJSON, MegadraftPlugin, insertDataBlock } from "megadraft-denistsuman";
import { convertFromHTML, EditorState, ContentState, Modifier, Entity, genKey, ContentBlock } from 'draft-js';
import LinkInput from "megadraft-denistsuman/lib/entity_inputs/LinkInput";
import icons from "megadraft-denistsuman/lib/icons"
import 'megadraft-denistsuman/dist/css/megadraft.css'
import FontAwesome from "react-fontawesome";
import { stateToHTML } from 'draft-js-export-html';
import clearFormatting from 'draft-js-clear-formatting';
import { split } from "apollo-boost";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ReactDOM from 'react-dom';
import FormatClearIcon from '@material-ui/icons/FormatClear';

const IconSmall = withStyles(() => ({
  root: {
    fontSize: "1.0rem",
  }
}))(Icon);
const TooltipNew = withStyles((theme) => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);

const StyledChip = withStyles({
  root: {
    margin: "3px",
    padding: "inherit",
  },
})(Chip);

const IconSmalladd = withStyles(() => ({
  root: {
    fontSize: "1.0rem",
    '&:hover': {
      fontSize: "1.2rem",
      margin: "3px 0px"
    }
  },
}))(Icon);
let messageeditstate = '';

class clearFormatIcon extends React.Component {
  render() {
    return (
      <>
        <div className="pt-12">
          <FontAwesome onClick={this.clearFormating} className={"fa fa-underline toolbar__button"} style={{ fontSize: '18px', paddingBottom: '15px', paddingTop: "0px" }} />
        </div>
      </>
    );
  }
}
class UnderlineIcon extends React.Component {
  render() {
    return (
      <>
        <div className="pt-12">
          <FontAwesome className={"fa fa-underline toolbar__button"} style={{ fontSize: '18px', paddingBottom: '15px', paddingTop: "0px" }} />
        </div>
      </>
    );
  }
}

class ClearFormattingIcon extends React.Component {
  render() {
    return (
      <>
        <div className="pt-12">
          {/* <FontAwesome className={"fas fa-remove-format toolbar__button"} style={{ fontSize: '18px', paddingBottom: '15px', paddingTop: "0px" }} /> */}
          <FormatClearIcon onClick={this.clearFormating} style={{ paddingBottom: '6px', width : '30px', height : '30px' }}/>
        </div>
      </>
    );
  }
}
class CustomSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      selecttemplate: "none",
      placeholder: "none",
      Template_list: [],
      current_template: [],
      subject_edit: '',
      message_edit: '',
      user_placeholder: [],
      sender_placeholder: []
    };
  }
  componentWillMount() {
    let recData = this.props.thisData.props.mainUserData;
    let sendData = JSON.parse(localStorage.getItem('user_data_session'))
    var senderFulladdress = null;
    if (sendData.address) {
      senderFulladdress = (sendData.address && sendData.address) + " " + (sendData.city && sendData.city) + " " + (sendData.state && sendData.state) + " " + (sendData.pincode && sendData.pincode) + " " + (sendData.country && sendData.country);
    }
    let userData = [
      { key: "rec#FirstName", label: "#FirstName", value: recData.firstname },
      { key: "rec#LastName", label: "#LastName", value: recData.lastname },
      { key: "rec#FullName", label: "#FullName", value: recData.fullname },
      { key: "rec#Email", label: "#Email", value: recData.email },
      { key: "rec#Phone", label: "#Phone", value: recData.phones },
      { key: "rec#CompanyName", label: "#CompanyName", value: recData.company },
      { key: "rec#Title", label: "#Title", value: recData.title },
      { key: "rec#Address", label: "#Address", value: recData.address },
      { key: "rec#ContactType", label: "#ContactType", value: recData.contacttype },
      { key: "rec#Owner", label: "#Owner", value: recData.owner },
    ];
    let senderData = [
      { key: "send#SenderName", label: "#SenderName", value: sendData.name },
      { key: "send#SenderEmail", label: "#SenderEmail", value: sendData.email },
      { key: "send#SenderPhone", label: "#SenderPhone", value: sendData.phoneno },
      { key: "send#SenderCompanyName", label: "#SenderCompanyName", value: sendData.companylist && sendData.companylist.length > 0 ? sendData.companylist[0].displayname : null },
      { key: "send#SenderAddress", label: "#SenderAddress", value: senderFulladdress },

    ];
    this.setState({ user_placeholder: userData, sender_placeholder: senderData })
  }
  componentWillReceiveProps(props) {
    if (props.thisData.props.settingReducer.emailtemplatelist.data) {
      let email_list = props.thisData.props.settingReducer.emailtemplatelist.data;
      this.setState({ Template_list: email_list })
    }
  }

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i][attr].split(/\s/).join('') === value.split(/\s/).join('')) {
        return i;
      }
    }
    return -1;
  }
  handleSelectPlaceholder = (event) => {
    let value = ""
    let type = event.target.value.split("#")[0];
    var senderKey = this.findWithAttr(this.state.sender_placeholder, 'key', event.target.value);
    var key = this.findWithAttr(this.state.user_placeholder, 'key', event.target.value);
    if (key !== -1 && this.state.user_placeholder[key].value) {
      value = this.state.user_placeholder[key].value;
    } else if (senderKey !== -1 && this.state.sender_placeholder[senderKey].value) {
      value = this.state.sender_placeholder[senderKey].value;
    } else {
      value = "#" + event.target.value.split("#")[1];
    }
    if (this.props.editorType == "Message") {
      let editorState = this.props.thisData.state.editorState;
      let currentContent = editorState.getCurrentContent();
      let selection = editorState.getSelection();
      let entityKey = Entity.create('LINK', 'MUTABLE', { url: '#' + type, value });
      let textWithEntity = Modifier.insertText(currentContent, selection, value, null, entityKey);
      let neweditorstate = EditorState.push(editorState, textWithEntity, 'insert-characters');

      currentContent = neweditorstate.getCurrentContent();
      selection = neweditorstate.getSelection();
      let entityKey1 = Entity.create('UNSTYLED', 'MUTABLE', " ");
      let textWithEntity1 = Modifier.insertText(currentContent, selection, " ", null, entityKey1);
      neweditorstate = EditorState.push(neweditorstate, textWithEntity1, 'insert-characters');

      messageeditstate = neweditorstate;
      this.props.thisData.onChange(neweditorstate);
    }
    else {
      //subject
      // let subjectmeta = event.target.value;
      let editorSubjectState = this.props.thisData.state.subject;
      let currentSubjectContent = editorSubjectState.getCurrentContent();
      let subjectSelection = editorSubjectState.getSelection();

      let entityKey = Entity.create('LINK', 'MUTABLE', { url: '#' + type, value });
      let textWithEntity = Modifier.insertText(currentSubjectContent, subjectSelection, value, null, entityKey);
      let newsubjecteditorstate = EditorState.push(editorSubjectState, textWithEntity, 'insert-characters');

      currentSubjectContent = newsubjecteditorstate.getCurrentContent();
      subjectSelection = newsubjecteditorstate.getSelection();
      let entityKey1 = Entity.create('UNSTYLED', 'MUTABLE', " ");
      let textWithEntity1 = Modifier.insertText(currentSubjectContent, subjectSelection, " ", null, entityKey1);
      newsubjecteditorstate = EditorState.push(newsubjecteditorstate, textWithEntity1, 'insert-characters');

      this.props.thisData.handleChangeSubject(newsubjecteditorstate);
    }
  }

  handleSelectTemplate = (event) => {
    const userData = [...this.state.user_placeholder]
    const sendData = [...this.state.sender_placeholder]
    let emailTemplatelist = this.state.Template_list;
    let key = this.findWithAttr(emailTemplatelist, 'id', event.target.value);
    if (key != -1) {
      // Mesaage Box with value 
      let selectedMessageTemplate = JSON.parse(JSON.parse(emailTemplatelist[key].message));
      let selectedMessageBlocks = selectedMessageTemplate.blocks;
      let editorState = this.props.thisData.state.editorState;
      (selectedMessageBlocks) && selectedMessageBlocks.map((block, i) => {
        var arr = block.text.split(' ');
        let finalString = '';
        arr.forEach(element => {
          if (element.indexOf('#') === 0) {
            var key = this.findWithAttr(userData, 'label', element);
            var senderKey = this.findWithAttr(sendData, 'label', element);
            console.log("element", element, key, senderKey)
            if (key !== -1) {
              let currentContent = editorState.getCurrentContent();
              let selection = editorState.getSelection();
              let entityKey = Entity.create('LINK', 'MUTABLE', { url: '#rec', value: userData[key].value ? userData[key].value : element });
              let textWithEntity = Modifier.insertText(currentContent, selection, userData[key].value ? userData[key].value : element, null, entityKey);
              editorState = EditorState.push(editorState, textWithEntity, 'insert-characters');
              currentContent = editorState.getCurrentContent();
              selection = editorState.getSelection();
              let entityKey1 = Entity.create('UNSTYLED', 'MUTABLE', " ");
              let textWithEntity1 = Modifier.insertText(currentContent, selection, " ", null, entityKey1);
              editorState = EditorState.push(editorState, textWithEntity1, 'insert-characters');
            }
            if (senderKey !== -1) {
              let currentContent = editorState.getCurrentContent();
              let selection = editorState.getSelection();
              let entityKey = Entity.create('LINK', 'MUTABLE', { url: '#send', value: sendData[senderKey].value ? sendData[senderKey].value : element });
              let textWithEntity = Modifier.insertText(currentContent, selection, sendData[senderKey].value ? sendData[senderKey].value : element, null, entityKey);
              editorState = EditorState.push(editorState, textWithEntity, 'insert-characters');
              currentContent = editorState.getCurrentContent();
              selection = editorState.getSelection();
              let entityKey1 = Entity.create('UNSTYLED', 'MUTABLE', " ");
              let textWithEntity1 = Modifier.insertText(currentContent, selection, " ", null, entityKey1);
              editorState = EditorState.push(editorState, textWithEntity1, 'insert-characters');
            }
          } else {
            let currentContent = editorState.getCurrentContent();
            let selection = editorState.getSelection();
            let entityKey1 = Entity.create('UNSTYLED', 'MUTABLE', element);
            let textWithEntity1 = Modifier.insertText(currentContent, selection, element, null, entityKey1);
            editorState = EditorState.push(editorState, textWithEntity1, 'insert-characters');
            currentContent = editorState.getCurrentContent();
            selection = editorState.getSelection();
            entityKey1 = Entity.create('UNSTYLED', 'MUTABLE', " ");
            textWithEntity1 = Modifier.insertText(currentContent, selection, " ", null, entityKey1);
            editorState = EditorState.push(editorState, textWithEntity1, 'insert-characters');
          }
        });
        selectedMessageBlocks[i].key = genKey();
        let currentContent = editorState.getCurrentContent();
        let selection = editorState.getSelection();
        let entityKey1 = Entity.create('UNSTYLED', 'MUTABLE', '\n');
        let textWithEntity1 = Modifier.insertText(currentContent, selection, '\n', null, entityKey1);
        editorState = EditorState.push(editorState, textWithEntity1, 'insert-characters');
      })
      this.props.thisData.onChange(editorState);
      //close message box

      //subject  with value 
      let selectedSubjectTemplate = JSON.parse(JSON.parse(emailTemplatelist[key].subject));
      let selectedSubjectBlocks = selectedSubjectTemplate.blocks;
      let editorSubjectState = this.props.thisData.state.subject;
      (selectedSubjectBlocks) && selectedSubjectBlocks.map((block, i) => {
        const arr = block.text.split(' ');
        arr.forEach(element => {
          if (element.indexOf('#') === 0) {
            var key = this.findWithAttr(userData, 'label', element);
            var senderKey = this.findWithAttr(sendData, 'label', element);
            if (key !== -1) {
              let currentContent = editorSubjectState.getCurrentContent();
              let selection = editorSubjectState.getSelection();
              let entityKey = Entity.create('LINK', 'MUTABLE', { url: '#rec', value: userData[key].value ? userData[key].value : element });
              let textWithEntity = Modifier.insertText(currentContent, selection, userData[key].value ? userData[key].value : element, null, entityKey);
              editorSubjectState = EditorState.push(editorSubjectState, textWithEntity, 'insert-characters');
              currentContent = editorSubjectState.getCurrentContent();
              selection = editorSubjectState.getSelection();
              let entityKey1 = Entity.create('UNSTYLED', 'MUTABLE', " ");
              let textWithEntity1 = Modifier.insertText(currentContent, selection, " ", null, entityKey1);
              editorSubjectState = EditorState.push(editorSubjectState, textWithEntity1, 'insert-characters');
            } else if (senderKey !== -1) {
              let currentContent = editorSubjectState.getCurrentContent();
              let selection = editorSubjectState.getSelection();
              let entityKey = Entity.create('LINK', 'MUTABLE', { url: '#send', value: sendData[senderKey].value ? sendData[senderKey].value : element });
              let textWithEntity = Modifier.insertText(currentContent, selection, sendData[senderKey].value ? sendData[senderKey].value : element, null, entityKey);
              editorSubjectState = EditorState.push(editorSubjectState, textWithEntity, 'insert-characters');
              currentContent = editorSubjectState.getCurrentContent();
              selection = editorSubjectState.getSelection();
              let entityKey1 = Entity.create('UNSTYLED', 'MUTABLE', " ");
              let textWithEntity1 = Modifier.insertText(currentContent, selection, " ", null, entityKey1);
              editorSubjectState = EditorState.push(editorSubjectState, textWithEntity1, 'insert-characters');
            }
          } else {
            let currentContent = editorSubjectState.getCurrentContent();
            let selection = editorSubjectState.getSelection();
            let entityKey1 = Entity.create('UNSTYLED', 'MUTABLE', element);
            let textWithEntity1 = Modifier.insertText(currentContent, selection, element, null, entityKey1);
            editorSubjectState = EditorState.push(editorSubjectState, textWithEntity1, 'insert-characters');
            currentContent = editorSubjectState.getCurrentContent();
            selection = editorSubjectState.getSelection();
            entityKey1 = Entity.create('UNSTYLED', 'MUTABLE', " ");
            textWithEntity1 = Modifier.insertText(currentContent, selection, " ", null, entityKey1);
            editorSubjectState = EditorState.push(editorSubjectState, textWithEntity1, 'insert-characters');
          }
        });
        this.props.thisData.handleChangeSubject(editorSubjectState);
      })
    }
    //close subject
  }
  togglesidbarCLick = () => {
    this.setState({ checked: !this.state.checked })
  }
  render() {
    let { checked, Template_list, user_placeholder, sender_placeholder } = this.state;

    return (
      <>
        <div className="sidebar__menu">
          <Grid container spacing={1}  >
            {checked ?
              <>
                <Grid item className="ml-10">
                  <Select
                    name="selecttemplate"
                    id="selecttemplate"
                    label="Templates"
                    className="font-size-13"
                    value={this.state.selecttemplate}
                    disableUnderline
                    onChange={this.handleSelectTemplate}
                  >
                    <MenuItem value="none">Templates</MenuItem>
                    <ListSubheader style={{ color: 'rgba(0, 0, 0, 0.54)', fontWeight: '500', paddingTop: '10px', paddingBottom: '10px', backgroundColor: '#fff' }}>My Template</ListSubheader>
                    {Template_list.map((template, index) => (
                      (template.isshared == false) &&
                      <MenuItem value={template.id} key={index}>
                        <span className="font-weight-500 font-size-13 ml-5 mr-5 p_value"> {template.name}</span>
                      </MenuItem>
                    ))}
                    <ListSubheader style={{ color: 'rgba(0, 0, 0, 0.54)', fontWeight: '500', paddingTop: '10px', paddingBottom: '10px', backgroundColor: '#fff' }}>Shared Template</ListSubheader>
                    {Template_list.map((template, index) => (
                      (template.isshared == true) &&
                      <MenuItem value={template.id} key={index}>
                        <span className="font-weight-500 font-size-13 ml-5 mr-5 p_value"> {template.name}</span>
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item >
                  <Select
                    name="placeholder"
                    id="placeholder"
                    label="Merge Fields"
                    className="font-size-13"
                    value={this.state.placeholder}
                    disableUnderline
                    onChange={this.handleSelectPlaceholder}
                  >
                    <MenuItem value="none">Merge Fields</MenuItem>
                    <ListSubheader style={{ color: 'rgba(0, 0, 0, 0.54)', fontWeight: '500', paddingTop: '10px', paddingBottom: '10px', backgroundColor: '#fff' }}>
                      Recipient Merge Fields
                      </ListSubheader>
                    {
                      user_placeholder.map((val, i) => (
                        <MenuItem className="wrapper-rec" key={i} value={val.key}>
                          <Grid container spacing={3}>
                            <Grid item xs={6} >
                              <span className="font-weight-500 font-size-13 ml-5 mr-5 crm-rec-hashplaceholder" >
                                {val.label} </span>
                            </Grid>
                            <Grid item xs={6} className="whiteSpaceInitial">
                              <span className="font-weight-500 font-size-13 ml-5 mr-5 p_value">
                                {val.value}</span>
                            </Grid>
                          </Grid>
                        </MenuItem>
                      ))}
                    <ListSubheader style={{ color: 'rgba(0, 0, 0, 0.54)', fontWeight: '500', paddingTop: '10px', paddingBottom: '10px', backgroundColor: '#fff' }}>Sender Merge Fields</ListSubheader>
                    {
                      sender_placeholder.map((val, i) => (
                        <MenuItem className="wrapper-send" key={i} value={val.key}>
                          <Grid container spacing={3}>
                            <Grid item xs={6} >
                              <span className="font-weight-500 font-size-13 ml-5 mr-5 crm-send-hashplaceholder">
                                {val.label}
                              </span>
                            </Grid>
                            <Grid item xs={6} className="whiteSpaceInitial">
                              <span className="font-weight-500 font-size-13 ml-5 mr-5 p_value">
                                {val.value}</span>
                            </Grid>
                          </Grid>
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
                <Grid item>
                  <IconButton className="p-0 mt-8 mr-4" onClick={this.togglesidbarCLick}><IconSmall >clear</IconSmall></IconButton>
                </Grid>
              </>
              :
              <Grid item>
                <IconButton className="p-0" style={{ marginTop: '-1px', color: "var(--primary)" }} onClick={this.togglesidbarCLick}><IconSmalladd >add</IconSmalladd></IconButton>
                {/* <IconButton className="toolbar__button mr-0" style={{ fontSize: '12px', backgroundColor: '#fff', color: '#3192e7', width: '13px' }} onClick={this.togglesidbarCLick}><IconSmall >add</IconSmall></IconButton> */}
              </Grid>
            }
          </Grid>
        </div>
        {/* <div className="sidebar__menu">
          <FontAwesome onClick={this.togglesidbarCLick} className={"fa fa-plus-circle toolbar__button"} style={{ fontSize: '30px', backgroundColor:'#fff' }} />
        </div> */}
      </>
    );
  }
}
const entityInputs = {
  LINK: LinkInput,
}

class InboxComposeDialog extends Component {
  constructor(props) {
    super(props);
    const myContent = {
      "entityMap": {},
      "blocks": [
        {
          "key": genKey(),
          "text": "",
          "type": "unstyled",
          "depth": 0,
          "inlineStyleRanges": [],
          "entityRanges": [],
          "data": {}
        }
      ]
    };
    const exampleState = {
      "blocks": [{
        "key": genKey(),
        "text": "",
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [],
        "entityRanges": [],
        "data": {}
      }],
      "entityMap": {}
    };
    const editorState = editorStateFromRaw(myContent);
    messageeditstate = editorState;
    let subjectEditorState = editorStateFromRaw(exampleState);
    this.state = {
      isshared: false,
      editorState: editorState,
      subject: subjectEditorState,
      toEmail: [],
      ccEmail: [],
      bccEmail: [],
      hasCC: false,
      hasBCC: false,
      messageid: "",
      threadid: "",
      attachment: [],
      usersListArray: [],
      mainEmail: "",
      isSubmitDisable: true,
      fullScreen: false,
      minimizeScreen: false,
      readOnlySubject: false,
      toEmailError: false,
    };
    this.ccEmail = React.createRef();
    this.bccEmail = React.createRef();
    this.toEmail = React.createRef();


  }
  componentWillMount() {
    this.props.getEmailTemplateList(this.props.apolloClient.client);
    this.props.getUserList(this.props.apolloClient.client)
    if (this.props.userSelectedEmail) {
      var to = [];
      to.push(this.props.userSelectedEmail)
      this.setState({ toEmail: to, })
    }
    if (this.props.emaildata) {
      var allEmailList = this.props.emaildata.emailTo
      console.log("data allEmailList", allEmailList)
      var toData = [...this.state.toEmail];
      var ccData = [...this.state.ccEmail];
      var bccData = [...this.state.bccEmail];
      allEmailList.map((data) => {
        if (data.email_type === 1) {
          console.log("from ", data.email)
        } else if (data.email_type === 2) {
          toData = [...toData, data.email];
        } else if (data.email_type === 3) {
          ccData = [...ccData, data.email];
        } else {
          bccData = [...bccData, data.email];
        }
      })
      let editorSubjectState = this.state.subject;
      let currentContent = editorSubjectState.getCurrentContent();
      let selection = editorSubjectState.getSelection();
      let entityKey1 = Entity.create('UNSTYLED', 'MUTABLE', "Re:" + this.props.emaildata.subject);
      let textWithEntity1 = Modifier.insertText(currentContent, selection, "Re:" + this.props.emaildata.subject, null, entityKey1);
      editorSubjectState = EditorState.push(editorSubjectState, textWithEntity1, 'insert-characters');
      this.handleChangeSubject(editorSubjectState)
      this.setState({
        toEmail: toData,
        ccEmail: ccData,
        bccEmail: bccData,
        hasCC: ccData.length > 0 ? true : false,
        hasBCC: bccData.length > 0 ? true : false,
        messageid: this.props.emaildata.emaildetails.message_id,
        threadid: this.props.emaildata.emaildetails.thread_id,
        readOnlySubject: true
      })
    }
  }
  componentWillReceiveProps(props) {
    if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
      const usersListArray = props.settingReducer.usersListArray.data;
      this.setState({ usersListArray: usersListArray })
    }
  }
  onChange = (editorState) => {
    messageeditstate = editorState;
    this.setState({
      editorState: editorState,
      isSubmitDisable:
        (this.state.toEmail.length > 0 && this.state.attachment.length > 0)
          || (this.state.subject.getCurrentContent().hasText() && editorState.getCurrentContent().hasText()) ?
          false : true
    })
  }

  handleChangeSubject = (subject) => {
    this.setState({
      subject: subject,
      isSubmitDisable: (this.state.toEmail.length > 0 && this.state.attachment.length > 0)
        || (subject.getCurrentContent().hasText() && this.state.editorState.getCurrentContent().hasText()) ?
        false : true
    })
  }

  getCustomSidebar = (props) => {
    return <CustomSidebar thisData={this} editorType="Message" />
  }
  getCustomSubjectSidebar = (props) => {
    return <CustomSidebar thisData={this} editorType="Subject" />
  }
  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }
  handleFormSubmit = () => {
    const { editorState, toEmail, attachment, bccEmail, ccEmail } = this.state;

    let html = stateToHTML(editorState.getCurrentContent());
    const subjectwithhtml = this.state.subject.getCurrentContent();
    const subjectText = subjectwithhtml.getPlainText('\u0001');
    let SendParams = [];
    SendParams['Send'] = {
      mail: {
        to: toEmail.join(),
        cc: ccEmail.join(),
        bcc: bccEmail.join(),
        subject: subjectText,
        message: html,
        threadid: this.state.threadid,
        messageid: this.state.messageid,
      },
      fileInput: attachment
    }
    SendParams['LogDetails'] = this.props.LogDetails
    SendParams['LogComponent'] = this.props.LogComponent

    console.log("SendParams", SendParams);
    
    this.props.sendGmail(this.props.apolloClient.client, SendParams);
    this.props.handleClose();
  }
  handleAttachmentSelection = (event) => {
    var allfiles = this.state.attachment;
    for (let i = 0; i < event.target.files.length; i++) {
      allfiles.push(event.target.files[i])
    }
    this.setState({
      attachment: allfiles, isSubmitDisable: allfiles.length > 0 ? false : true
    });
  };
  validateEmailList = (raw) => {
    var valid = true;
    var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    for (var i = 0; i < raw.length; i++) {
      if (raw[i] == " " || !regex.test(raw[i].replace(/\s/g, ""))) {
        valid = false;
      }
    }
    return valid;
  }
  handleChangeTag = (val, fname) => {
    if (this.validateEmailList(val)) {
      console.log("vallida")
      setTimeout(() => {
        this[fname].current.classList.remove("email_error")
      }, 0);
      this.setState({
        [fname]: val,
        isSubmitDisable: !(val.length > 0 && this.state.subject.getCurrentContent().hasText() && this.state.editorState.getCurrentContent().hasText())
      })
    } else {
      console.log("not validateEmailList", this[fname])
      setTimeout(() => {
        this[fname].current.focus();
        this[fname].current.classList.add("email_error")

      }, 0)
      this.setState({ isSubmitDisable: true });
    }
  };
  toggleCC = () => {
    setTimeout(() => {
      this.ccEmail.current.focus();
    }, 0)

    this.setState({ hasCC: !this.state.hasCC })
  }
  toggleBCC = () => {
    setTimeout(() => {
      this.bccEmail.current.focus();
    }, 0)
    this.setState({ hasBCC: !this.state.hasBCC })
  }

  deleteFile = (id) => {
    var array = [...this.state.attachment]; // make a separate copy of the array
    if (id !== -1) {
      array.splice(id, 1);
      this.setState({ attachment: array, isSubmitDisable: array.length > 0 ? false : true });
    }
  }
  handleMinimize = () => {
    this.setState({ minimizeScreen: !this.state.minimizeScreen, fullScreen: false });
  };
  handleFull = () => {
    this.setState({ fullScreen: !this.state.fullScreen, minimizeScreen: false });
  };
  handleDelete = (item, name) => {
    this.setState({
      [name]: this.state[name].filter(i => i !== item)
    });
  };
  handlePaste = (evt, name) => {
    evt.preventDefault();
    var paste = evt.clipboardData.getData("text");
    console.log("{() => ", paste, name)
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);
    if (emails) {
      this.setState({ [name]: [...this.state[name], ...emails] });
    }
  };
  handleChangeText = (e, fname) => {
    var that = this;
    if (e.target.value === "") {
      setTimeout(() => {
        that[fname].current.classList.remove("email_error")
      }, 0);
      that.setState({
        isSubmitDisable: that.state.subject.getCurrentContent().hasText() && that.state.editorState.getCurrentContent().hasText()
      })
    }
  }
  render() {
    let { open, handleClose, } = this.props;
    let { attachment, bccEmail, toEmail, ccEmail, minimizeScreen, fullScreen } = this.state;
    let onChangeFunction = this.onChange;
    return (
      <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth={true} className={`custom_dialog ${minimizeScreen && fullScreen ? 'alignitemsend-fullscreen' : ''} ${minimizeScreen ? 'alignitemsend' : ''} ${fullScreen ? 'fullscreen' : ''}`} disableBackdropClick >
        <div className="crm-custom-bg-primary">
          <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary ">
            <h4 className="m-0 text-white">Send Email</h4>
            <div className="d-flex">
              <TooltipNew title={minimizeScreen ? 'Normal Screen' : 'Minimize Screen'}>
                <IconButton onClick={this.handleMinimize}>
                  <Icon className="text-white">{minimizeScreen ? 'add' : 'remove'}</Icon>
                </IconButton>
              </TooltipNew>
              <TooltipNew title={fullScreen ? 'Exit Full Screen' : 'Full Screen'}>
                <IconButton onClick={this.handleFull}>
                  <Icon className="text-white">{fullScreen ? 'fullscreen_exit' : 'fullscreen'}</Icon>
                </IconButton>
              </TooltipNew>
              <TooltipNew title="Close">
                <IconButton onClick={handleClose}>
                  <Icon className="text-white">clear</Icon>
                </IconButton>
              </TooltipNew>
            </div>
          </div>
          <div className="crm_dialog_create_template">
            <div className={`${this.state.isDataLoading ? "dialog-body popupHeightCustom" : "dialog-body"}`}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Autocomplete
                    filterSelectedOptions
                    disableClearable
                    multiple
                    freeSolo
                    value={toEmail}
                    name={"toEmail"}
                    id="tagInputId"
                    options={this.state.usersListArray.map(option => option.email)}
                    onChange={(event, value) => this.handleChangeTag(value, "toEmail")}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <StyledChip variant="default"
                          key={index}
                          disabled={index === 0}
                          size="small"
                          label={option}
                          onDelete={() => (
                            index === 0 ? undefined :
                              this.handleDelete(option, "toEmail"))
                          } />
                      ))
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        autoFocus
                        ref={this.toEmail}
                        className="w-100 input_bx"
                        onChange={(e) => this.handleChangeText(e, "toEmail")}
                        onKeyDown={(e) => {
                          if (e.keyCode === 32 && e.target.value) {
                            this.handleChangeTag(toEmail.concat(e.target.value), "toEmail");
                          }
                          if (e.key === 'Backspace') {
                            e.stopPropagation();
                          }
                        }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (<React.Fragment>
                            <label className="compose_fieldName">TO</label>
                            {params.InputProps.startAdornment}</React.Fragment>
                          ),
                          endAdornment: (
                            <React.Fragment>
                              <label className="compose_ext_name"
                                style={{ display: this.state.hasCC ? "none" : "block" }}
                                onClick={() => this.toggleCC()}>CC</label>
                              <label className="compose_ext_name"
                                onClick={() => this.toggleBCC()}
                                style={{ display: this.state.hasBCC ? "none" : "block" }}
                              >BCC</label>
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}

                      />
                    )}
                  />

                </Grid>
                {this.state.hasCC &&
                  <Grid item xs={12}>
                    <Autocomplete
                      filterSelectedOptions
                      disableClearable
                      multiple
                      freeSolo
                      value={ccEmail}
                      name={"ccEmail"}
                      id="tagInputId2"
                      options={this.state.usersListArray.map(option => option.email)}
                      onChange={(event, value) => this.handleChangeTag(value, "ccEmail")}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <StyledChip variant="default"
                            key={index}
                            size="small"
                            label={option}

                            onDelete={() => this.handleDelete(option, "ccEmail")}
                          />
                        ))
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          autoFocus
                          className="w-100 input_bx"
                          ref={this.ccEmail}
                          onChange={(e) => this.handleChangeText(e, "ccEmail")}
                          onKeyDown={(e) => {
                            if (e.keyCode === 32 && e.target.value) {
                              this.handleChangeTag(ccEmail.concat(e.target.value), "ccEmail");
                            }
                          }}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <React.Fragment>
                                <label className="compose_fieldName">CC</label>
                                {params.InputProps.startAdornment}
                              </React.Fragment>
                            )
                          }}
                        />
                      )
                      }
                    />
                  </Grid>
                }
                {this.state.hasBCC &&
                  <Grid item xs={12}>
                    <Autocomplete
                      filterSelectedOptions
                      disableClearable
                      multiple
                      value={bccEmail}
                      name={"bccEmail"}
                      id="tagInputId3"
                      options={this.state.usersListArray.map(option => option.email)}
                      freeSolo
                      onChange={(event, value) => this.handleChangeTag(value, "bccEmail")}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <StyledChip variant="default"
                            key={index}
                            size="small"
                            label={option}
                            onDelete={() => this.handleDelete(option, "bccEmail")}
                          />
                        ))
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          autoFocus
                          ref={this.bccEmail}
                          className="w-100 input_bx"
                          onChange={(e) => this.handleChangeText(e, "bccEmail")}
                          onKeyDown={(e) => {
                            if (e.keyCode === 32 && e.target.value) {
                              this.handleChangeTag(bccEmail.concat(e.target.value), "bccEmail");
                            }
                          }}

                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <React.Fragment>
                                <label className="compose_fieldName">BCC</label>
                                {params.InputProps.startAdornment}
                              </React.Fragment>
                            )
                          }}
                        />
                      )}
                    />
                  </Grid>
                }
                <Grid item xs={12} className="subject-editor">
                  <MegadraftEditor
                    placeholder='Subject'
                    theme="white-thin"
                    editorState={this.state.subject}
                    onChange={this.handleChangeSubject}
                    actions={[]}
                    readOnly={this.state.readOnlySubject}
                    sidebarRendererFn={this.getCustomSubjectSidebar}
                  />
                </Grid>
                <Grid item xs={12} className="message-editor Scroll_upload">
                  <MegadraftEditor
                    placeholder='Message'
                    theme="white-thin"
                    editorState={this.state.editorState}
                    onChange={this.onChange}
                    actions={[
                      { type: "inline", label: "B", style: "BOLD", icon: icons.BoldIcon },
                      { type: "inline", label: "I", style: "ITALIC", icon: icons.ItalicIcon },
                      { type: "inline", label: "U", style: "UNDERLINE", icon: UnderlineIcon },
                      {
                        type: "custom", icon: ClearFormattingIcon, action() {
                          let options = {
                            inline: true,
                            entities: false,
                            lists: false,
                          }
                          const newEditorState = clearFormatting(messageeditstate, options)
                          messageeditstate = newEditorState;
                          onChangeFunction(newEditorState);
                        }, active() { }
                      },
                      { type: "separator" },
                      { type: "block", label: "UL", style: "unordered-list-item", icon: icons.ULIcon },
                      { type: "block", label: "OL", style: "ordered-list-item", icon: icons.OLIcon },
                      { type: "separator" },
                      { type: "entity", label: "Link", style: "link", entity: "LINK", icon: icons.LinkIcon },
                    ]}
                    entityInputs={entityInputs}
                    sidebarRendererFn={this.getCustomSidebar}
                  />
                  {attachment.map((att, i) => {
                    return <StyledChip
                      key={i}
                      label={att.name}
                      size="small"
                      onDelete={(e) => { this.deleteFile(e, att) }}
                    />
                  })}
                </Grid>
              </Grid>
              <DialogActions className="dialog-action">
                <Grid container spacing={2} justify="space-between" alignItems="center">
                  <Grid item>
                    <label htmlFor="attachment">
                      <Fab color="primary" size="small" onClick={() => this.attachment.click()} className="fab_primary" aria-label="attachment">
                        <Icon>attach_file</Icon>
                      </Fab>
                    </label>
                    <input style={{ display: 'none' }} type="file" multiple
                      onChange={this.handleAttachmentSelection}
                      ref={attachment => this.attachment = attachment} />
                  </Grid>
                  <Grid item>
                    <Button variant="contained" className="mr-12" onClick={handleClose}>Cancel</Button>
                    {
                      this.state.isSubmitDisable ?
                        <Button variant="contained" disabled color="default" >Send</Button>
                        :
                        <Button variant="contained" type="submit"
                          color={"primary"} className="btn_Primary" onClick={this.handleFormSubmit}>Send</Button>
                    }
                  </Grid>
                </Grid>
              </DialogActions>
            </div>

          </div>
        </div>
      </Dialog >
    );
  }
}
const mapStateToProps = (state) => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  leadReducer: state.leadReducer,
  contactReducer: state.contactReducer,
});
const mapDispatchToProps = (dispatch) => {
  return {
    getEmailTemplateList: (client, request) => { dispatch(actionCreators.getEmailTemplateList(client, request)) },
    sendGmail: (client, req) => dispatch(actionCreators.sendGmail(client, req)),
    getUserList: (client) => dispatch(actionCreators.getUserList(client)),
    saveEmailTemplate: (client, request) => { dispatch(actionCreators.saveEmailTemplate(client, request)); },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(InboxComposeDialog);