import React, { Component } from "react";
import { Dialog, List, ListItem, DialogTitle, DialogActions, IconButton, Icon, Menu, withStyles, Grid, Fab, Select, MenuItem, TextField, Card, InputLabel, Button, Checkbox, Tooltip } from "@material-ui/core";
import * as actions from '../../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import FontAwesome from "react-fontawesome";
import _ from 'lodash'

import { Link } from "react-router-dom";
import moment from 'moment';

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.3rem",
        color: 'rgba(0, 0, 0, 0.6)'
    }
}))(Icon);

const IconSmaller = withStyles(() => ({
    root: {
        fontSize: "1.0rem",
        color: 'rgba(0, 0, 0, 0.6)'
    }
}))(Icon);

class EmailTypeTwo extends Component {
    state = {
        openComment: false,
        Downloadoptions: "",
        showDownloadoptions: false,
        isOpenedEmailDetails:false,
    }

    toggleComment = (id) => {
        this.setState({ openComment: !this.state.openComment })
        this.props.thatAll.toggleComment(id)
    }
    handleDownloadClick = (event) => {
        this.setState({ Downloadoptions: event.target, showDownloadoptions: true });
    }
    handleCloseDownload = (event) => {
        this.setState({ Downloadoptions: null, showDownloadoptions: false });
    }

    OpenedEmailDetails = (id) => {
        this.setState({ isOpenedEmailDetails: !this.state.isOpenedEmailDetails })
    }

    render() {
        let { Downloadoptions, showDownloadoptions } = this.state;
        const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
        let user_data = JSON.parse(localStorage.getItem('user_data_session'));
        let current_logUser = user_data.email;
        let { log, allowUserReply, commentvalue, timeZone, isOpenedReplayEmailLog, emaildata, mainUserData, LogDetails, LogComponent, commentid, isEditComment, isOpenedCommentIds,
            userName, dateformat, logid, showSelector, transferLink, isOpenedEmailIds, thatAll } = this.props;
        return (
            <div className="box_body">
                <div className="lead_pl p-0">
                    <Grid container>
                        <Grid item md={1} className="text-center">
                            <Icon className="icon_left">mail</Icon>
                        </Grid>
                        <Grid item md={11}>
                            <Grid container justify="space-between">
                                <Grid onClick={(id) => this.OpenedEmailDetails(log.logId)} className="flex-auto cursor_poiter">
                                    <h5 className="mb-0">
                                        You to{" "}{log.email}
                                    </h5>
                                    <p className="mt-4 mb-4 font-size-13 text-hint">{log.email_date_time}</p>
                                </Grid>
                            </Grid>
                            {!this.state.isOpenedEmailDetails ?
                                <div className="mt-0 font-size-14">
                                    <span className="font-weight-500">{log.subject}</span> - {renderHTML(log.snippet)}
                                </div>
                                :
                                <div className="border_bx">
                                    <h6>{log.subject}</h6>
                                    <div dangerouslySetInnerHTML={{ __html: log.body_html }} />
                                </div>
                            }
                            <div className="mt-20">
                                {(log.attachment && log.attachment.length > 0) &&
                                    log.attachment.map((attach) => (
                                        <>
                                            <Button
                                            //  onClick={this.handleDownloadClick} 
                                             className="crm-email-log-attachment" variant="contained">
                                                <IconButton size="small">
                                                    <IconSmaller>attach_file</IconSmaller>
                                                </IconButton>
                                                {attach.file_name}
                                                
                                            </Button>
                                           
                                        </>
                                    ))
                                }
                            </div>
                        </Grid>
                    </Grid>
                </div>
            
            </div>
        );
    }
}

export default EmailTypeTwo