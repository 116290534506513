import React, { Component } from "react";
import { CircularProgress, Icon, withStyles } from "@material-ui/core";
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import { compose } from "redux";
import ListComponent from "../Common/ListComponent";

class ProfileRight extends Component {
  state = {
    attachment: null,
    activedatas: [],
    updatelistorder: true,
    company_id: "",
    isDataLoading: true,
    userSelectedEmail: '',
    Username: '',
  };

  componentWillMount() {
    let val = { recordtypeid: 3 }
    this.props.getRelatedMasterList(this.props.apolloClient.client, val);
    this.props.getUserIntegration(this.props.apolloClient.client);
    if (this.props.company_id) {
      this.setState({
        company_id: this.props.company_id,
      }, () => {
        this.forceUpdate()
      });
    }
  }
  componentWillReceiveProps(props) {
    if (props.company_id != this.state.company_id) {
      this.setState({
        company_id: parseInt(props.company_id),
      }, () => {
        this.forceUpdate()
      });
    }
    if (props.settingReducer.companyrelatedMaster.data) {
      const companyrelatedMaster = props.settingReducer.companyrelatedMaster.data;
      let activedatas = [];

      companyrelatedMaster.map((fieldData, index) => {
        if (fieldData.isactive === true) {
          activedatas.push({
            key: fieldData.id,
            alias: fieldData.alias,
            isactive: fieldData.isactive,
            orderid: fieldData.orderno,
          });
        }
      });
      if (this.state.updatelistorder == true) {
        this.setState({ activedatas: activedatas, isDataLoading: false }, () => this.forceUpdate());
      }
    }
    if (props.companyReducer.companyFieldData.data) {
      const companyDataForEdit = props.companyReducer.companyFieldData.data;
      companyDataForEdit.map((v, i) => {
        if (v.companydetail.length > 0 && v.companydetail[0].values.length > 0) {
          if (v.fieldkey == "email") {
            this.setState({
              userSelectedEmail: v.companydetail[0].values[0].value,
            })
          }
        }
        if (v.fieldkey == "name") {
          this.setState({
            Username: v.companydetail[0].fieldvalue,
          })
        }
        // }
      })
    }
    if (props.settingReducer.userItegration.data && props.settingReducer.userItegration.data !== this.state.itegrationDetails) {
      let data = props.settingReducer.userItegration.data;
      let hasRingCentralConnect = false;
      data.map((item) => {
        if (item.status && item.integrationid == 10) {
          hasRingCentralConnect = true;
          console.log('Contact details Page');
          var rcs = document.createElement("script");
          rcs.src = "https://ringcentral.github.io/ringcentral-embeddable/adapter.js?&disableGlip=true&disableConferenceCall=true&disableMessages=true&clientId=hPpoApgkSt2Hy1VZqUrrhQ";
          var rcs0 = document.getElementsByTagName("script")[0];
          rcs0.parentNode.insertBefore(rcs, rcs0);
          if (window.RCAdapter) {
            window.RCAdapter.setMinimized(false);
            window.RCAdapter.setClosed(false);
          }
          window.addEventListener('message', (e) => {
            const data = e.data;
            if (data) {
              switch (data.type) {
                case 'rc-call-start-notify':
                  console.log("web START calll", data.call);
                  break;
                case 'rc-call-end-notify':
                  console.log("web END call", data.call);
                  this.setState({ ringCentralData: data.call }, () => this.UpdateSettingRingCentral());
                  break;
                case 'rc-active-call-notify':
                  console.log("web Active Call", data.call);
                  break;
                default:
                  break;
              }
            }
          });
        }
      });
      this.setState({
        itegrationDetails: data,
        hasRingCentralConnect: hasRingCentralConnect,
      });
    }
  }

  UpdateSettingRingCentral = () => {
    let data = [];
    if (this.state.ringCentralData.direction === "Outbound") {
      data['data'] = {
        direction: this.state.ringCentralData.direction,
        telephonyStatus: this.state.ringCentralData.callStatus,
        fromphoneNumber: this.state.ringCentralData.fromNumber,
        fromName: this.state.Username,
        phoneNumber: this.state.ringCentralData.to,
        startTime: this.state.ringCentralData.startTime
      };
      console.log(data['data'], "jhsbdjbcjs");
      let req = {
        text: JSON.stringify(data['data']),
        sourcetype: 3,
        sourceid: parseInt(this.state.company_id),
      }
      console.log(req, 'UpdateSettingRingCentral');
      this.props.saveRingCentralData(this.props.apolloClient.client, req);
    }
  }

  render() {
    var flag = 0;
    if (window.location.pathname == "/opportunities/opportunities-card" || window.location.pathname == "/opportunities/opportunities-list") {
      flag = 1
    }
    return (
      <>
        {this.state.isDataLoading ?
          <CircularProgress size={25} disableShrink className="loaderPopupCenter" />
          :
          this.state.company_id && <ListComponent recordId={this.state.company_id} recordtype={"company"} activedata={this.state.activedatas} allData={this} />}

      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
    companyReducer : state.companyReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getRelatedMasterList: (client, request) => {
      dispatch(actions.getRelatedMasterList(client, request));
    },
    getUserIntegration: (client) => dispatch(actions.getUserIntegration(client)),
    saveRingCentralData: (client, value) => dispatch(actions.saveRingCentralData(client, value)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ProfileRight
);
