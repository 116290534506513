import React, { Component } from "react";
import SettingSidebar from '../../../Layout/SettingSidebarMenu'
import PropTypes from 'prop-types';
import { Grid, Tabs, Tab, Card, IconButton, Icon, withStyles, Typography, Button, DialogActions, Box } from '@material-ui/core';
import MemberList from "./MemberList";
import TeamAccess from "./TeamAccess";
import { Link } from "react-router-dom";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import * as actions from '../../../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.2rem",
        fontWeight: 500
    }
}))(Icon);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {children}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

class TeamDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            TabValue: 0,
            isEdit: false,
            team_name: "",
            team_main: "",
            isTeamManger: false,

        }
    }
    componentDidMount() {
        let getTeamParams = { teamid: parseFloat(this.props.match.params.teamId) };
        this.props.getTeamDetail(this.props.apolloClient.client, getTeamParams)
    }

    componentWillReceiveProps(props) {
        if (props.settingReducer.teamDetails.data) {
            const teamDetails = props.settingReducer.teamDetails.data;
            let session_data = JSON.parse(localStorage.getItem('user_data_session'));
            let manger = false;
            if (teamDetails.memberlist && teamDetails.memberlist.length > 0) {
                teamDetails.memberlist.map((val, i) => {
                    if (val.user && val.user.email === session_data.email) {
                        manger = val.teammanager
                    }
                })
            }
            this.setState({
                isTeamManger: manger,
                team_main: teamDetails.teamname,
                team_name: teamDetails.teamname
            })
        }
    }
    handleTabChange = (event, TabValue) => {
        this.setState((state) => ({ TabValue }));
    };

    handleClick(item) {
        this.setState(prevState => (
            { [item]: !prevState[item] }
        ))
    }
    handleEditName = () => {
        this.setState({ isEdit: !this.state.isEdit });
    }
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    handleDrawerToggle = () => {
        this.props.history.push('/settings/teams-permissions')
    }
    handleFormSubmit = () => {
        let req = {
            teamid: parseFloat(this.props.match.params.teamId),
            teamname: this.state.team_name
        }
        this.props.saveTeam(this.props.apolloClient.client, req)
        this.handleEditName()
    }
    handleCancle = () => {
        this.setState({ team_name: this.state.team_main, isEdit: false })
    }
    render() {
        let hasEditAccess = false;
        if ((this.props.authReducer.userRole.id !== 3) || (this.props.authReducer.userRole.id === 3 && this.state.isTeamManger)) {
            hasEditAccess = true
        } else {
            hasEditAccess = false
        }

        const { tabs, TabValue } = this.state;
        return (
            <React.Fragment>
                <SettingSidebar />
                <div className="h-100 right-16 settings_view v_scroll">

                    <div style={{ margin: '10px' }}>
                        <IconButton size="small" color="primary" onClick={this.handleDrawerToggle} >
                            <IconSmall>arrow_back</IconSmall>
                        </IconButton>
                        <div style={{ margin: '-22px 0 0 35px' }}>
                            <Link className="text-small text_link" style={{ color: '#2B41B2' }} to='/settings/teams-permissions/' >
                                Back to Team List</Link>
                        </div>
                        <Typography className="p-10" variant="h5" color="inherit" style={{ color: 'black' }}>
                            {
                                this.state.isEdit ?
                                    <ValidatorForm
                                        noValidate
                                        instantValidate={false}
                                        onSubmit={this.handleFormSubmit}
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            justify="flex-start"
                                            alignItems="center"
                                        >
                                            <Grid container item xs={5} >
                                                <TextValidator
                                                    required={true}
                                                    type="text"
                                                    name="team_name"
                                                    onChange={this.handleChange}
                                                    className="w-100 input_bx"
                                                    value={this.state.team_name}
                                                    errorMessages={["This field is required"]}
                                                    validators={["required"]} />
                                            </Grid>
                                            <Grid container item xs={2} >
                                                <DialogActions className="dialog-action">
                                                    <Button variant="contained" onClick={this.handleCancle}>
                                                        Cancel</Button>
                                                    <Button
                                                        // disabled={this.state.isDataLoading}
                                                        variant="contained"
                                                        type="submit"
                                                        color="primary"
                                                        className="btn_Primary"
                                                    >
                                                    Save
                                                   </Button>
                                                </DialogActions>
                                            </Grid>
                                        </Grid>
                                    </ValidatorForm>
                                    :
                                    <>
                                        {this.state.team_name}
                                        {
                                            hasEditAccess &&
                                            < IconButton className="ml-20" onClick={this.handleEditName}>
                                                <Icon>edit</Icon>
                                            </IconButton>
                                        }
                                    </>
                            }
                        </Typography>
                    </div>
                    <Tabs
                        value={TabValue}
                        onChange={this.handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        className="settings_tabs"
                        aria-label="scrollable auto tabs"
                    >
                        <Tab label="Team Members" {...a11yProps(0)} />
                        {
                            hasEditAccess &&
                            <Tab label="Access & Visibility" {...a11yProps(1)} />
                        }
                    </Tabs>
                    <div >
                        <TabPanel value={TabValue} index={0} className="h-100">
                            <MemberList isTeamManger={this.state.isTeamManger} teamid={this.props.match.params.teamId} userRole={this.props.authReducer.userRole.id} />
                        </TabPanel>
                        {
                            hasEditAccess &&
                            <TabPanel value={TabValue} index={1} className="h-100">
                                <TeamAccess isTeamManger={this.state.isTeamManger} teamid={this.props.match.params.teamId} userRole={this.props.authReducer.userRole.id} />
                            </TabPanel>
                        }
                    </div>
                </div>
            </React.Fragment >
        );
    }
}

const mapStateToProps = state => {
    return {
        settingReducer: state.settingReducer,
        apolloClient: state.apolloClient,
        authReducer: state.authReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        saveTeam: (client, request) => dispatch(actions.saveTeam(client, request)),
        getTeamDetail: (client, req) => dispatch(actions.getTeamDetail(client, req)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(TeamDetails);


