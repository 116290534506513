import React from 'react';
import { Button, Icon, TableContainer, Paper, Card, Table, TableCell, TableBody, TableRow, TableHead, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, CircularProgress, Fade, Snackbar, Grid, FormControlLabel, Checkbox, FormControl, Select, MenuItem } from '@material-ui/core';
import { Link } from "react-router-dom";
import * as actions from '../../../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';

class TeamAccess extends React.Component {
    state = {
        EntityTypeList: [],
        IntegrationList: [
            {
                id: 1,
                name: "Xero",
                icon: "/assets/images/intregation_logo/xero_logo.png",
                is_Access: true
            }
        ],
        ReportTypeList: [],
        isDataLoading: false
    };

    componentDidMount() {
        let getTeamParams = { teamid: parseFloat(this.props.teamid) };
        this.props.getTeamVisibility(this.props.apolloClient.client, getTeamParams)
        this.props.getTeamReportVisibility(this.props.apolloClient.client, getTeamParams)
    }
    componentWillReceiveProps(props) {
        if (props.settingReducer.teamAcessList.data && this.state.EntityTypeList !== props.settingReducer.teamAcessList.data) {
            const teamAcessList = props.settingReducer.teamAcessList.data;
            this.setState({ EntityTypeList: teamAcessList })
        }
        if (props.settingReducer.teamReportAceessList.data &&
            this.state.ReportTypeList !== props.settingReducer.teamReportAceessList.data) {
            const ReportTypeList = props.settingReducer.teamReportAceessList.data;
            this.setState({ ReportTypeList: ReportTypeList })
        }
    }
    handleEntityChange = (event, i, row) => {
        if (event.target !== undefined) {

            let a = event.target.name
            let d = event.target.type === 'checkbox' ? event.target.checked : event.target.value
            let data = [...this.state.EntityTypeList];
            if (a === "isaccess" && d === false) {
                if (data[i].sourcetype === 2 || data[i].sourcetype === 3) {
                    data.map((e, j) => {
                        if (e.sourcetype === 4) {
                            e.iscreate = false
                            e.isdelete = false
                            e.isedit = false
                            e.isaccess = false
                        }
                    })
                }
                data[i].iscreate = false
                data[i].isdelete = false
                data[i].isedit = false
                data[i][a] = d
            } else {
                if (data[i].sourcetype === 4 && d === true) {
                    data.map((e, j) => {
                        if (e.sourcetype === 2 || e.sourcetype === 3) {
                            e.isaccess = true
                        }
                    })
                }
                data[i][a] = d
                data[i].isaccess = true
            }
            this.setState({
                EntityTypeList: data
            }, () => this.updateSettings())
        }
    }
    updateSettings = () => {
        let visibilitydata = [];
        let reportvisibility = []
        this.state.EntityTypeList.map((row) => {
            visibilitydata.push({
                id: row.id,
                isaccess: row.isaccess,
                iscreate: row.iscreate,
                isedit: row.isedit,
                isdelete: row.isdelete,
                viewoption: row.viewoption
            })
        })
        this.state.ReportTypeList.map((row) => {
            reportvisibility.push({
                id: row.id,
                isaccess: row.isaccess,
                viewoption: row.viewoption
            })
        })
        let req = {
            teamid: parseFloat(this.props.teamid),
            visibilitydata: visibilitydata,
            reportvisibility: reportvisibility
        }
        this.props.updateTeamAccess(this.props.apolloClient.client, req)
    }
    handleReportChange = (event, i, row) => {
        if (event.target !== undefined) {
            let a = event.target.name
            let d = event.target.type === 'checkbox' ? event.target.checked : event.target.value
            let data = [...this.state.ReportTypeList];
            data[i][a] = d
            this.setState({
                ReportTypeList: data
            }, () => this.updateSettings())
        }
    }
    render() {
        return (
            <div className="p-10">
                <Card className="team_visibility signup-card mb-10 mt-10">
                    <TableContainer component={Paper}>
                        {this.state.EntityTypeList.length > 0 &&
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Entity Type</TableCell>
                                        <TableCell>Access</TableCell>
                                        <TableCell>Create</TableCell>
                                        <TableCell>Edit</TableCell>
                                        <TableCell>Delete</TableCell>
                                        <TableCell>Members of this Team can see</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.EntityTypeList.map((row, i) => (
                                        <TableRow key={i}>
                                            <TableCell component="th" scope="row" style={{ width: "30%" }}>
                                                <Icon className="font-size-22 material-icons mb--4 mr-4" >{row.icon}</Icon>
                                                {row.name}</TableCell>
                                            <TableCell style={{ width: "10%" }}>
                                                <Checkbox
                                                    name={"isaccess"}
                                                    checked={row.isaccess}
                                                    onChange={(e) => this.handleEntityChange(e, i, row)}
                                                    color="primary" />
                                            </TableCell >
                                            <TableCell style={{ width: "10%" }}>
                                                <Checkbox
                                                    name={"iscreate"}
                                                    checked={row.iscreate}
                                                    onChange={(e) => this.handleEntityChange(e, i, row)}
                                                    color="primary" />
                                            </TableCell>
                                            <TableCell style={{ width: "10%" }}>
                                                <Checkbox
                                                    name={"isedit"}
                                                    checked={row.isedit}
                                                    onChange={(e) => this.handleEntityChange(e, i, row)}
                                                    color="primary" />
                                            </TableCell>
                                            <TableCell style={{ width: "10%" }}>
                                                <Checkbox
                                                    name={"isdelete"}
                                                    onChange={(e) => this.handleEntityChange(e, i, row)}
                                                    checked={row.isdelete} color="primary" />
                                            </TableCell>
                                            <TableCell style={{ width: "30%" }}>
                                                {
                                                    row.isaccess === true ?
                                                        <Select
                                                            name="viewoption"
                                                            value={row.viewoption ? row.viewoption : 2}
                                                            fullWidth
                                                            onChange={(e) => this.handleEntityChange(e, i, row)}
                                                        >
                                                            <MenuItem className="select-menu-item" value={1}>Records owned by anyone</MenuItem>
                                                            <MenuItem className="select-menu-item" value={2}>Only records owned by members of this Team</MenuItem>
                                                            <MenuItem className="select-menu-item" value={3}>Only records owned by themselves</MenuItem>  </Select>

                                                        : "Disabled"
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        }
                    </TableContainer>
                </Card>
                {/* <Card className="signup-card mb-10 mt-10">
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Integration</TableCell>
                                    <TableCell>Access</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.IntegrationList.map((row, i) => (
                                    <TableRow key={i}>
                                        <TableCell component="th" scope="row" style={{ width: "30%" }}>
                                            <img height="30px" width="30px" className="mb--8 mr-4" src={row.icon} />
                                            {row.name}</TableCell>
                                        <TableCell style={{ width: "10%" }}>
                                            <Checkbox name={row.name} color="primary" />
                                        </TableCell>
                                        <TableCell style={{ width: "10%" }}>{""}</TableCell>
                                        <TableCell style={{ width: "10%" }}>{""}</TableCell>
                                        <TableCell style={{ width: "10%" }}>{""}</TableCell>
                                        <TableCell style={{ width: "30%" }}>{""}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card> */}
                <Card className="signup-card mb-10 mt-10">
                    <TableContainer component={Paper}>
                        {this.state.ReportTypeList.length > 0 &&
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Entity Type</TableCell>
                                        <TableCell>Access</TableCell>
                                        <TableCell>{""}</TableCell>
                                        <TableCell>{""}</TableCell>
                                        <TableCell>{""}</TableCell>
                                        <TableCell>Members of this Team can see</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.ReportTypeList.map((row, i) => (
                                        <TableRow key={i}>
                                            <TableCell component="th" scope="row" style={{ width: "30%" }}>
                                                {row.name}</TableCell>
                                            <TableCell style={{ width: "10%" }}>
                                                <Checkbox
                                                    name={"isaccess"}
                                                    checked={row.isaccess}
                                                    onChange={(e) => this.handleReportChange(e, i, row)}
                                                    color="primary" />
                                            </TableCell>
                                            <TableCell style={{ width: "10%" }}></TableCell>
                                            <TableCell style={{ width: "10%" }}></TableCell>
                                            <TableCell style={{ width: "10%" }}></TableCell>
                                            <TableCell style={{ width: "30%" }}>

                                                {row.reporttype !== 4 ?
                                                    row.isaccess === true ?
                                                        <Select
                                                            name="viewoption"
                                                            value={row.viewoption ? row.viewoption : 2}
                                                            fullWidth
                                                            onChange={(e) => this.handleReportChange(e, i, row)}
                                                        >
                                                            <MenuItem className="select-menu-item" value={1}>Reports for all users in the system</MenuItem>
                                                            <MenuItem className="select-menu-item" value={2}>Reports for all members of this team</MenuItem>
                                                            <MenuItem className="select-menu-item" value={3}>Individual reports only</MenuItem>  </Select>

                                                        : "Disabled"
                                                    : ""
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        }
                    </TableContainer>
                    {this.state.isDataLoading &&
                        <Snackbar
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            open={this.state.isDataLoading}
                            TransitionComponent={Fade}
                            message={<><CircularProgress size={20} style={{ color: 'white' }} /><span style={{ paddingLeft: '6px', color: 'white' }}>{" Loading..."}</span></>}
                            variant={'success'}
                        />
                    }
                </Card>
            </div >
        );
    }
}


const mapStateToProps = state => {
    return {
        settingReducer: state.settingReducer,
        apolloClient: state.apolloClient
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateTeamAccess: (client, req) => dispatch(actions.updateTeamAccess(client, req)),
        getTeamReportVisibility: (client, req) => dispatch(actions.getTeamReportVisibility(client, req)),
        getTeamVisibility: (client, req) => dispatch(actions.getTeamVisibility(client, req)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(TeamAccess);