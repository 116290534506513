import React, { Component } from "react";
import {
    Checkbox, IconButton, MenuItem, Grid, TableContainer,
    Card, Paper, Select, TextField, Link, FormControl,
    InputLabel, Button, NativeSelect, Snackbar, Icon,
    Fade, CircularProgress, CardContent, Typography, Table,
    TableBody, TableCell, TableHead, TableRow, withStyles, ListItem,
    ListItemText, ListItemIcon, ListItemSecondaryAction, List
} from "@material-ui/core";
import { connect } from "react-redux";
import * as actionCreators from '../../../store/actions/index';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ReactDragListView from "react-drag-listview";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import FontAwesome from 'react-fontawesome';
import RootRef from "@material-ui/core/RootRef";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import EditIcon from "@material-ui/icons/Edit";

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.2rem",
        fontWeight: 500
    }
}))(Icon);

const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
        background: "rgb(235,235,235)"
    })
});

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.map((value, index) => {
        result[index].order_no = index + 1;
    })
    return result;
};

class DocuSignIntegrations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selection: [],
            max_num_envelopes: 'No_Selection',
            data: [],
            rows: [
                { id: 1, name: "onleads", label: "Lead" },
                { id: 2, name: "onopportunities", label: "Opportunities" },
                { id: 3, name: "oncompanies", label: "Companies" },
                { id: 4, name: "onpeople", label: "Contact" },
                { id: 4, name: "onproject", label: "Project" },
            ],
            integrationId: 9,
            isDataLoading: true,
            onleads: "",
            onopportunities: "",
            oncompanies: "",
            onpeople: "",
            onproject: "",
            integrationData: "",
            numberEnvelopesStatus: false,
            DocuSingRoleData: [],
        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    componentDidMount() {
        let val = this.state.integrationId;
        this.props.getIntegrationDetail(this.props.apolloClient.client, val);
        this.props.getDocuSignRole(this.props.apolloClient.client);
    }

    componentWillReceiveProps(props) {
        if (props.settingReducer.getintegationDetails.data) {
            console.log('data', props.settingReducer.getintegationDetails.data);
            if (props.settingReducer.getintegationDetails.data !== this.state.integrationData) {
                const data = props.settingReducer.getintegationDetails.data;
                let maxnumberrecords = (data.maxnumberrecords) ? data.maxnumberrecords : 'No_Selection';
                this.setState({
                    isDataLoading: false,
                    integrationData: data,
                    refereshtoken: data.refereshtoken,
                    accesstoken: data.accesstoken,
                    onleads: data.onleads,
                    oncompanies: data.oncompanies,
                    onopportunities: data.onopportunities,
                    onpeople: data.onpeople,
                    onproject: data.onproject,
                    max_num_envelopes: maxnumberrecords,
                });
            }
        }
        if (props.settingReducer.getDocuSingRole.data) {
            const data = props.settingReducer.getDocuSingRole.data;
            this.setState({
                DocuSingRoleData: data
            });
        }
    }
    updateSettings = () => {
        this.setState({ isDataLoading: true });
        let envelopes = "";
        if (this.state.max_num_envelopes === 'No_Selection') {
            this.setState({ numberEnvelopesStatus: true });
            envelopes = 0;
        } else {
            this.setState({ numberEnvelopesStatus: false });
            envelopes = this.state.max_num_envelopes;
        }
        let val = {
            integration: {
                integrationid: this.state.integrationId,
                status: true,
                email: this.state.integrationData.email,
                sortby: "",
                domain: this.state.integrationData.domain,
                accesstoken: this.state.integrationData.accesstoken,
                refereshtoken: this.state.integrationData.refereshtoken,
                maxnumberrecords: envelopes !== "" ? parseFloat(envelopes) : null,
                oncompanies: this.state.oncompanies,
                onleads: this.state.onleads,
                onopportunities: this.state.onopportunities,
                onpeople: this.state.onpeople,
                onproject: this.state.onproject,
                ticketstatus: '',
            }
        }
        let data = [];
        data['data'] = val;
        console.log(data, 'Response of Docusing for Save intregation API');
        let id = this.state.integrationId;
        let req = { data, id };
        this.props.saveIntegrationSetting(this.props.apolloClient.client, req);
    }

    handleCheckBoxChange = (event) => {
        console.log("event.target.name", event.target.name, event.target.type === "checkbox" ?
            event.target.checked : event.target.value)
        this.setState({
            [event.target.name]: event.target.type === "checkbox" ?
                event.target.checked
                :
                event.target.value
        }, () => this.updateSettings())
    }

    dropdownHandleChange = (name, id, order_no) => {
        let submitData = [{ id: id, name: name, order_no: order_no, }]
        let req = { signrole: submitData };
        this.props.saveDocuSignRole(this.props.apolloClient.client, req);
    };

    handleDrawerToggle = () => {
        window.location.href = '/settings/integrations';
    };

    handleAddOption = () => {
        let submitData;
        let options = this.state.DocuSingRoleData;
        if (options.length < 10 && this.state.optionLength !== options.length + 1) {
            // setTimeout(() => {
            submitData = [{ id: 0, name: "Signer " + (options.length + 1), order_no: 0, }]
            let req = { signrole: submitData };
            this.props.saveDocuSignRole(this.props.apolloClient.client, req);
            // }, 2000)
            this.setState({ optionLength: options.length + 1 });
        }
    };

    deleteOption = (index, length) => {
        this.props.deleteDocuSign(this.props.apolloClient.client, parseFloat(index));
        let options = this.state.DocuSingRoleData;
        this.setState({ optionLength: options.length - 1 });
    }

    handleAZSorting = () => {
        let options = this.state.DocuSingRoleData;
        options = options.sort(function (a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
            else if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
            return 0;
        });
        console.log(options);
        this.setState({ DocuSingRoleData: options })
        let req = { signrole: options };
        this.props.saveDocuSignRole(this.props.apolloClient.client, req);
    };

    onDragEnd(result_destination, index) {
        let options = this.state.DocuSingRoleData;
        const items = reorder(options, index, result_destination);
        this.setState({ DocuSingRoleData: items });
        let req = { signrole: items };
        this.props.saveDocuSignRole(this.props.apolloClient.client, req);
    }

    render() {
        const { DocuSingRoleData } = this.state;
        return (
            <React.Fragment >
                <div className="v_scrollI">
                    <div style={{ margin: '10px' }}>
                        <IconButton size="small" color="primary" onClick={this.handleDrawerToggle}>
                            <Icon style={{ fontSize: '1.2rem', marginBottom: '-2px' }} >arrow_back</Icon>
                        </IconButton>
                        <div style={{ margin: '-22px 0 0 35px' }}>
                            <Link className="text-small text_link cursor_poiter" style={{ color: '#2B41B2' }} to='/settings/integrations' >
                                Back to Integrations
                            </Link>
                        </div>
                        <Typography className="p-10" variant="h5" color="inherit" style={{ color: 'black' }}>DocuSign Settings</Typography>
                        <Typography className="text-hint p-10" variant="p" color="inherit" style={{ color: 'black' }}>
                            Track documents and signatures with DocuSign
                        </Typography>
                    </div>
                    <div className="IntegrationBlock_contents" >
                        <div className="zendesk_Integrations_card">
                            <div style={{ margin: '40px 20px 20px 40px' }}>
                                <div className="mb-16">
                                    <div className="d-flex justify-content-between">
                                        <label className="text-lable">Add DocuSign Roles</label>
                                        {/* <label className="text-lable">Sort <Link to="#" onClick={this.handleAZSorting} className="text_link">A-Z</Link></label> */}
                                    </div>
                                    <ValidatorForm ref="form" >
                                        <div className="box_body" style={{ margin: '10px' }}>
                                            <DragDropContext onDragEnd={(result) => this.onDragEnd(result.destination.index, result.source.index)}>
                                                <Droppable droppableId="droppable">
                                                    {(provided, snapshot) => (
                                                        <RootRef rootRef={provided.innerRef}>
                                                            <List >
                                                                {DocuSingRoleData && DocuSingRoleData.map((item, index) => (
                                                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                                                        {(provided, snapshot) => (
                                                                            <ListItem
                                                                                ContainerComponent="li"
                                                                                ContainerProps={{ ref: provided.innerRef }}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                style={getItemStyle(
                                                                                    snapshot.isDragging,
                                                                                    provided.draggableProps.style
                                                                                )}
                                                                            >
                                                                                <div style={{ color: 'rgba(0, 0, 0, 0.54)', display: 'inline-flex', flexShrink: 0 }} >
                                                                                    <Icon>drag_handle</Icon>
                                                                                </div>
                                                                                <TextValidator
                                                                                    className="w-100 input_bx ml-10 mr-10"
                                                                                    onChange={(event) => this.dropdownHandleChange(event.target.value, item.id, item.order_no)}
                                                                                    placeholder="Enter option name"
                                                                                    type="text"
                                                                                    defaultValue={item.name}
                                                                                />
                                                                                {this.state.DocuSingRoleData.length > 1 &&
                                                                                    <ListItemSecondaryAction>
                                                                                        <IconButton onClick={() => this.deleteOption(item.id, DocuSingRoleData.length)} ><Icon>delete</Icon></IconButton>
                                                                                    </ListItemSecondaryAction>
                                                                                }
                                                                            </ListItem>
                                                                        )}
                                                                    </Draggable>
                                                                ))}
                                                                {provided.placeholder}
                                                            </List>
                                                        </RootRef>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                        </div>
                                    </ValidatorForm>
                                    <Button size="small" style={{ margin: '0 0 10px 20px' }} variant="contained" color="primary" onClick={this.handleAddOption}><Icon>add</Icon> Add Option</Button>
                                </div>
                                <Grid item style={{ padding: '0 0 45px 0' }}>
                                    <FormControl className="w-100 input_bx">
                                        <InputLabel>Choose the maximum number of DocuSign envelopes to show</InputLabel>
                                        <Select
                                            name="max_num_envelopes"
                                            value={this.state.max_num_envelopes}
                                            onChange={this.handleCheckBoxChange}
                                        >
                                            <MenuItem value="No_Selection" disabled >
                                                - Choose the maximum number of DocuSign envelopes to show -
                                            </MenuItem>
                                            <MenuItem key={'5'} value={'5'}>5</MenuItem>
                                            <MenuItem key={'10'} value={'10'}>10</MenuItem>
                                            <MenuItem key={'15'} value={'15'}>15</MenuItem>
                                            <MenuItem key={'20'} value={'20'}>20</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {this.state.numberEnvelopesStatus === true ? <p className="text-small" style={{ color: 'red', margin: '5px 0 0 880px' }} >Choose the maximum number of DocuSign envelopes to show is required.</p> : ""}
                                </Grid>
                            </div>
                        </div>
                    </div>
                    <div className="mt-20">
                        <Typography className="text-small-14 font-size-20 p-10" style={{ margin: '10px' }} variant="p" color="inherit">
                            Enable DocuSign for records
                        </Typography>
                        <div style={{ margin: '14px' }} className="mt-20">
                            <Card className="signup-card">
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Record Type</TableCell>
                                                <TableCell>Show on Record</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.rows.map((row, i) => (
                                                <TableRow key={i}>
                                                    <TableCell component="th" scope="row">{row.label}</TableCell>
                                                    <TableCell>
                                                        <div style={{ margin: "0 0px 0px 30px" }}>
                                                            <Checkbox
                                                                checked={this.state[row.name]}
                                                                name={row.name}
                                                                onChange={(event) => this.handleCheckBoxChange(event)}
                                                                color="primary"
                                                            />
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </div>
                    </div>
                </div >
                {/* </Grid> */}
            </React.Fragment >
        );
    }
}

const mapStateToProps = state => {
    return {
        apolloClient: state.apolloClient,
        settingReducer: state.settingReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getIntegrationDetail: (client, request) => dispatch(actionCreators.getIntegrationDetail(client, request)),
        getDocuSignRole: (client) => dispatch(actionCreators.getDocuSignRole(client)),
        saveIntegrationSetting: (client, req) => dispatch(actionCreators.saveIntegrationSetting(client, req)),
        saveDocuSignRole: (client, request) => dispatch(actionCreators.saveDocuSignRole(client, request)),
        UpdateIntegrationStatus: (client, request) => dispatch(actionCreators.UpdateIntegrationStatus(client, request)),
        deleteDocuSign: (client, request) => dispatch(actionCreators.deleteDocuSign(client, request)),
    }
}
export default (connect(mapStateToProps, mapDispatchToProps)(DocuSignIntegrations));
