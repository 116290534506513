import React, { Component } from "react";
import {
  Grid, IconButton, Icon, Card, Fab,
  Tooltip, withStyles, CircularProgress, Box,
  MenuItem, DialogActions, Button, Typography,
  DialogContent, FormControl, Select, Chip,
  DialogTitle, Dialog, TextField, Avatar, Link
} from "@material-ui/core";
import LensIcon from '@material-ui/icons/Lens';
import GetAppIcon from '@material-ui/icons/GetApp';
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { TextValidator, ValidatorForm, SelectValidator } from "react-material-ui-form-validator";
import DropdownDefault from "../Layout/DropdownDefault";
import { NavLink, withRouter } from "react-router-dom";
import moment from 'moment';

import DropboxChooser from 'react-dropbox-chooser';
import GooglePicker from 'react-google-picker';

import Autocomplete from "@material-ui/lab/Autocomplete";

const APP_KEY = 'dqsfbf3twmx7g7z';
// const CLIENTID = "161942936141-fsq8al02npjinbf8vm1rr1lop0takre0.apps.googleusercontent.com";
const CLIENTID = "965427486424-jsdger0lt68ncnd49jki1ojpjkaemndc.apps.googleusercontent.com";
const DEVLOPERKEY = "AIzaSyA8whoR2ER3oOfYroEKTPtmC5_cGqVkX3I";
const SCOPE = "https://www.googleapis.com/auth/drive.file";
const APPID = "crmlikecopper";

const IconSmall = withStyles(() => ({
  root: {
    fontSize: "1.5rem",
    color: 'rgba(0, 0, 0, 0.6)'
  }
}))(Icon);

const TooltipNew = withStyles((theme) => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);


class DocuSign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenedDocuSign: false,
      UserID: '',
      dateformat: 'MM/DD/YYYY',
      timeZone: 'America/New_York',
      hasMore: true,
      totalCount: 0,
      currentPage: 1,
      isDataLoading: true,
      isDsDataLoading: true,
      hasPagination: false,
      displayList: [],
      Domain: '',
      itegrationDetails: [],
      hasGoggleDriveConnect: false,
      hasDropBoxConnect: false,
      fileListArray: [],
      fileListArray2: [],
      type: "files",
      inputs: ['input-0'],
      DocuSingRoleData: [],
      CenterDisplayFileDetails: [],
      option_show_dropdown: '',
      ResponseFileID: [],
      File_Validation: false,
      SingerData: [],
      CreateData: [],
      DisplayDocuSingData: [],
      fileId: "",
    };
  }

  componentWillUnmount() {
    this.props.settingReducer.relatedDataFile = [];
    this.props.settingReducer.CreateDocuSign = [];
    this.props.settingReducer.getDocusingData = [];
  };

  componentDidMount() {
    let recortypedid = "";
    let userData = JSON.parse(localStorage.getItem('user_data_session'))
    if (this.props.recordtype == "lead") { recortypedid = 1 }
    if (this.props.recordtype == "contact") { recortypedid = 2 }
    if (this.props.recordtype == "company") { recortypedid = 3 }
    if (this.props.recordtype == "opportunities") { recortypedid = 4 }
    if (this.props.recordtype == "project") { recortypedid = 5 }

    if (this.props.recordId) {
      this.setState({
        recordId: this.props.recordId,
        recordtype: this.props.recordtype,
        recortypedid: recortypedid,
      }, () => {
        this.forceUpdate()
      });
      if (this.props.recordId) {
        var req = {
          id: this.props.recordId,
          type: this.state.type,
          recordtype: this.props.recordtype,
        }
        this.props.getRecordRelatedData(this.props.apolloClient.client, req);
      }
    }
    this.props.getUserIntegration(this.props.apolloClient.client);
    this.props.getDocuSignRole(this.props.apolloClient.client);

    let dataofEmail = {
      email: userData.email
    }
    this.props.getUserDetailByEmail(this.props.apolloClient.client, dataofEmail);

    if (this.props.recordId) {
      var dtaofDS = {
        id: this.props.recordId,
        type: this.props.recordtype === "opportunities" ? "opportunity" : this.props.recordtype,
        page: this.state.currentPage,
      }
      this.props.GetDocuSignData(dtaofDS);
    }
  };

  componentWillReceiveProps(props) {
    if (props.settingReducer.getuserdetailbyemail) {
      const user_Data = props.settingReducer.getuserdetailbyemail.data && props.settingReducer.getuserdetailbyemail.data;
      if (user_Data && user_Data.id) {
        this.setState({ UserID: user_Data.id, mainUSerName: user_Data.name });
      }
    }

    if (props.settingReducer.getDocusingData.data) {
      const DisplayDocuSingData = props.settingReducer.getDocusingData.data.data;
      const totalCount = props.settingReducer.getDocusingData.data.total_documents;

      if (DisplayDocuSingData && DisplayDocuSingData !== this.state.DisplayDocuSingData) {
        this.setState({
          totalCount: totalCount,
          isDsDataLoading: false
        });
        if (!this.state.hasPagination) {
          this.setState({
            DisplayDocuSingData: DisplayDocuSingData,
            hasPagination: true,
            hasMore: totalCount > DisplayDocuSingData.length ? true : false,
          })
        }
      }
      if (DisplayDocuSingData.length > 0) {
        this.setState({ isOpenedDocuSign: true });
      }else{
        this.setState({ isOpenedDocuSign: false });
      }
    }

    if (props.settingReducer.userItegration.data && props.settingReducer.userItegration.data !== this.state.itegrationDetails) {
      let data = props.settingReducer.userItegration.data;
      var hasGoggleDriveConnect = false;
      var hasDropBoxConnect = false;
      data.map((item) => {
        if (item.status && item.integrationid == 4) {
          hasGoggleDriveConnect = true;
        }
        if (item.status && item.integrationid == 3) {
          hasDropBoxConnect = true;
        }
      });
      this.setState({
        itegrationDetails: data,
        hasGoggleDriveConnect: hasGoggleDriveConnect,
        hasDropBoxConnect: hasDropBoxConnect,
      });
    }

    if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
      let usersetting = props.settingReducer.userSettingArray.data;

      let format = usersetting.dateformat;
      let timezone = usersetting.timezone;

      this.setState({ dateformat: format, timeZone: timezone })
    }

    if (props.recordId != this.state.recordId) {
      this.setState({
        recordId: props.recordId,
        recordtype: props.recordtype,
      }, () => {
        this.forceUpdate()
        var req = {
          id: props.recordId,
          type: this.state.type,
          recordtype: props.recordtype,
        }
        this.props.getRecordRelatedData(this.props.apolloClient.client, req);
      });
    }

    if (props.settingReducer.relatedDataFile.data) {
      let ArryData = props.settingReducer.relatedDataFile.data.data;
      let Displaylength = props.settingReducer.relatedDataFile.data.data.length;

      if (ArryData !== this.state.fileListArray) {
        this.setState({
          fileListArray: ArryData,
          fileListArray2: [],
          displayLength: Displaylength,
        });

        setTimeout(() => {
          this.setState({ isDataLoading: false });
        }, 5000);
      }
    }

    if (props.settingReducer.getDocuSingRole.data) {
      let data = props.settingReducer.getDocuSingRole.data;
      this.setState({ DocuSingRoleData: data });
    }

    if (props.settingReducer.CreateDocuSign.data) {
      const CreateData = props.settingReducer.CreateDocuSign.data;
      if (CreateData !== this.state.CreateData) {
        this.setState({
          CreateData: CreateData,
          sender_view_url: CreateData.sender_view_url,
        });
        setTimeout(() => {
          this.setState({ isDataLoading: false });
        }, 5000);
      }
    }

    if (props.Username &&
      props.userSelectedEmail &&
      props.recordtype !== "company" ||
      props.recordtype !== "project" ||
      props.recordtype !== "opportunities") {
      let SingerData = [{ title: props.Username }];
      this.setState({
        SingerData: props.userSelectedEmail ? SingerData : [],
        SingerName: props.Username,
        Username: props.Username,
        email: props.userSelectedEmail
      })
    }

    if (props.settingReducer.relatedDataContact.data &&
      props.recordtype === "company" ||
      props.recordtype === "project" ||
      props.recordtype === "opportunities") {
      let contactListArray = props.settingReducer.relatedDataContact.data && props.settingReducer.relatedDataContact.data.data &&
        props.settingReducer.relatedDataContact.data.data;
      if (contactListArray && contactListArray.length > 0) {
        let namekey = '';
        let emailkey = '';
        // console.log("name :", contactListArray[0].contact);
        contactListArray[0].contact.__contactdetail__.map((data, key1) => {
          if (data.__field__.id == "16") {//name
            namekey = data && data.fieldvalue;
          }
          if (data.__field__.id == "21") {//email
            emailkey = data && data.allvalues[0] && data.allvalues[0].value;
          }
        });

        let SingerData = emailkey ? [{ title: namekey }] : [];
        // console.log('namekey', namekey);
        console.log('emailkey :', emailkey);
        console.log('namekey :', namekey);
        this.setState({
          SingerData: SingerData,
          SingerName: namekey,
          email: emailkey,
          Image: contactListArray[0].contact.image
        }, () => this.forceUpdate());
      }
    }
  };

  toggledocuSign = () => {
    this.setState({ isOpenedDocuSign: !this.state.isOpenedDocuSign });
  };

  toggleAddDocuSign = () => {
    let data = this.state.DocuSingRoleData;
    let namevalue = this.state.SingerData;
    this.setState({
      inputs: ['input-0'],
      isDataLoading: false,
      option_show_dropdown: data && data.length > 0 && data[0].name,
      SubscriptionDialog: !this.state.SubscriptionDialog,
      CenterDisplayFileDetails: [],
      ResponseFileID: [],
      fileListArray2: this.state.fileListArray,
      emailSubject: '',
      sender_view_url: '',
      CreateData: [],
      SingerName: namevalue && namevalue.length && namevalue[0].title,
      SomthingWentRong: false,
    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleAttachmentSelection = (event) => {
    let files = event.target.files[0];
    if (event.target.files[0] == undefined) {
      return false
    }
    let data = {
      recordtype: this.state.recordtype,
      type: this.state.type,
      id: parseInt(this.state.recordId),
    }

    let req = {
      recordid: parseInt(this.state.recordId),
      recortypedid: this.state.recortypedid,
      fileInput: event.target.files[0],
      getParams: data,
    }

    this.setState({ isDataLoading: true });
    this.props.saveRelatedFiles(this.props.apolloClient.client, req);
    setTimeout(() => {
      this.AddFile(files, 'FromComputer');
    }, 10000);
  };

  handleDropBox = (files) => {
    // console.log('files :', files);
    let filename = [];
    let fileurl = [];

    files.map((file) => {
      filename = file.name;
      fileurl = file.link;

      let data = {
        recordtype: this.state.recordtype,
        type: this.state.type,
        id: parseInt(this.state.recordId),
      }

      let req = {
        recordid: parseInt(this.state.recordId),
        recortypedid: this.state.recortypedid,
        filename: filename,
        dropboxlink: fileurl,
        getParams: data,
      }
      this.setState({ isDataLoading: true });
      this.props.saveDropboxRelatedFiles(this.props.apolloClient.client, req);
    });
    setTimeout(() => {
      this.AddFile(files, "dropbox");
    }, 10000);
  };

  pickerCallback = (filedata) => {
    if (filedata.action === "picked") {
      // console.log('filedata :', filedata.docs);
      filedata.docs.map((file) => {
        let filename = file.name;
        let googlefilelink = file.url;
        let googlefileid = file.id;
        let data = {
          recordtype: this.state.recordtype,
          type: this.state.type,
          id: parseInt(this.state.recordId),
        }
        let req = {
          recordid: parseInt(this.state.recordId),
          recortypedid: this.state.recortypedid,
          filename: filename,
          googlefilelink: googlefilelink,
          googlefileid: googlefileid,
          getParams: data,
        }
        this.setState({ isDataLoading: true });
        this.props.saveGoogleRelatedFiles(this.props.apolloClient.client, req);
      });
      setTimeout(() => {
        this.AddFile(filedata.docs, "google");
      }, 10000);
    }
  };

  appendInput() {
    let data = this.state.DocuSingRoleData;
    var newInput = `input-${this.state.inputs.length}`;
    this.setState(prevState => ({
      inputs: prevState.inputs.concat([newInput]),
      option_show_dropdown: data[this.state.inputs.length].name
    }));
  };

  handleDropBoxChange = (event) => {
    this.setState({ option_show_dropdown: event.target.value });
  };

  handleChanegText = (o, value) => {
    this.setState({ SingerName: value });
  };

  handleSubmit = () => {
    console.log(this.state.SingerName, this.state.email);
    if (this.state.SingerName && this.state.SingerName.length > 0 && this.state.email) {
      console.log("SingerName :", this.state.SingerName, "email :", this.state.email);
      this.setState({ recipientError: false });
      if (this.state.emailSubject && this.state.emailSubject.length > 0) {
        this.setState({ EmailSubjectError: false });
        if (this.state.ResponseFileID && this.state.ResponseFileID.length > 0) {
          this.setState({ FileError: false });
          this.saveDetails();
        } else {
          this.setState({ FileError: true });
        }
      } else {
        this.setState({ EmailSubjectError: true });
      }
    } else {
      this.setState({ recipientError: true });
    }
  };

  AddFile = (data, key) => {
    if (key === 'FromComputer') {
      let CenterDisplayFileDetails = this.state.CenterDisplayFileDetails;
      let ResponseFileID = this.state.ResponseFileID;

      let found = this.state.fileListArray && this.state.fileListArray.find(
        function (element) { return element.filename === data.name });

      console.log('found', found);

      CenterDisplayFileDetails.push(found);
      ResponseFileID.push(found.id);

      this.setState({
        CenterDisplayFileDetails: CenterDisplayFileDetails,
        ResponseFileID: ResponseFileID,
        fileListArray2: []
      });
    }

    if (key === 'google') {
      data.map(item => {
        let CenterDisplayFileDetails = this.state.CenterDisplayFileDetails;
        let ResponseFileID = this.state.ResponseFileID;

        let found = this.state.fileListArray && this.state.fileListArray.find(
          function (element) { return element.filename === item.name });

        CenterDisplayFileDetails.push(found);
        ResponseFileID.push(found.id);

        this.setState({
          CenterDisplayFileDetails: CenterDisplayFileDetails,
          ResponseFileID: ResponseFileID,
          fileListArray2: []
        });
      })
    }

    if (key === 'dropbox') {
      data.map(item => {
        let CenterDisplayFileDetails = this.state.CenterDisplayFileDetails;
        let ResponseFileID = this.state.ResponseFileID;

        let found = this.state.fileListArray && this.state.fileListArray.find(
          function (element) { return element.filename === item.name });

        CenterDisplayFileDetails.push(found);
        ResponseFileID.push(found.id);

        this.setState({
          CenterDisplayFileDetails: CenterDisplayFileDetails,
          ResponseFileID: ResponseFileID,
          fileListArray2: []
        });
      })
    }


  };

  ChangeDataOfFile = (item, Index) => {
    let CenterDisplayFileDetails = this.state.CenterDisplayFileDetails;
    let ResponseFileID = this.state.ResponseFileID;

    CenterDisplayFileDetails.push(item);
    ResponseFileID.push(item.id);

    this.setState({
      CenterDisplayFileDetails: CenterDisplayFileDetails,
      fileListArray2: [],
      ResponseFileID: ResponseFileID
    });
  };

  handleDelete = (id, Index) => {
    let CenterDisplayFileDetails = this.state.CenterDisplayFileDetails;
    let ResponseFileID = this.state.ResponseFileID;

    let FindIndex = ResponseFileID && this.state.fileListArray.findIndex(
      function (element) { return element === id });

    CenterDisplayFileDetails.splice(Index, 1);
    ResponseFileID.splice(FindIndex, 1);

    this.setState({
      CenterDisplayFileDetails: CenterDisplayFileDetails,
      ResponseFileID: ResponseFileID
    });
  };

  saveDetails = () => {
    let thisvar = this;
    let dT = {
      id: this.state.recordId,
      type: this.props.recordtype === "opportunities" ? "opportunity" : this.props.recordtype,
      emailSubject: this.state.emailSubject,
      fileList: this.state.ResponseFileID,
      userId: this.state.UserID,
      signers: [{
        email: this.state.email,
        name: this.state.SingerName,
        role_name: this.state.DocuSingRoleData[0].name,
      }]
    }
    this.setState({
      isDataLoading: true,
    });
    this.props.SaveDocuSignRecipient(dT);
    setTimeout(() => {
      if (thisvar.state.sender_view_url) {
        this.toggleAddDocuSign();
        this.setState({ isDataLoading: false, SomthingWentRong: false });
      } else {
        this.setState({ isDataLoading: false, SomthingWentRong: true });
      }
    }, 15000);
  };


  componentDidUpdate = (prevProps) => {
    const main = this.props.settingReducer.getDocusingData.data;
    const premain = prevProps.settingReducer.getDocusingData.data;
    const maintotalCount = this.props.settingReducer.getDocusingData.data && this.props.settingReducer.getDocusingData.data.total_documents;
    const premaintotalCount = prevProps.settingReducer.getDocusingData.data && prevProps.settingReducer.getDocusingData.data.total_documents;
    // console.log('main:', main && main.data, maintotalCount);
    // console.log('premain:', premain && premain.data, premaintotalCount);
    if (premain && premain.data !== main.data && maintotalCount === premaintotalCount) {
      if (main.data !== this.state.DisplayDocuSingData) {
        const data_new = this.state.DisplayDocuSingData.concat(main.data);
        this.setState({
          hasMore: data_new.length === this.state.totalCount ? false : true,
          DisplayDocuSingData: data_new,
          isDsDataLoading: false
        });
      }
    } else if (premaintotalCount !== maintotalCount) {
      this.setState({
        DisplayDocuSingData: main && main.data,
        totalCount: maintotalCount,
        hasMore: main && main.data.length === maintotalCount ? false : true,
      });
    }
    // this.setState({ main: main, premain: premain });
  };

  fetchMoreData = (e) => {
    e.preventDefault();
    if (this.state.DisplayDocuSingData.length >= this.state.totalCount) {
      this.setState({ hasMore: false });
      return;
    }
    this.getNextPageData(this.state.currentPage + 1)
  };

  getNextPageData = (page) => {
    this.setState({ currentPage: page, isDsDataLoading: true })
    var req = {
      id: this.props.recordId,
      type: this.props.recordtype === "opportunities" ? "opportunity" : this.props.recordtype,
      page: page,
    }
    this.props.GetDocuSignData(req);
  };


  handleRemoveTask = (file_id, subject) => {
    this.setState({
      removeFilePopup: true,
      fileId: file_id,
      RemoveSubject: subject
    }, () => {
      this.forceUpdate()
    })

  };

  toggleRemoveFilePopup = () => {
    if (this.state.removeFilePopup) {
      this.setState({ fileId: "", }, () => { this.forceUpdate() })
    }
    this.setState({ removeFilePopup: !this.state.removeFilePopup, }, () => { this.forceUpdate() })
  };

  confirmremoveFilePopup = () => {
    if (this.state.fileId) {
      let Data = {
        id: this.props.recordId,
        type: this.props.recordtype === "opportunities" ? "opportunity" : this.props.recordtype,
        page: 1,
      }
      var req = {
        fileid: parseInt(this.state.fileId),
        Data: Data
      }
      this.props.RemoveDocuSignData(req);
      this.setState({
        fileId: "",
        removeFilePopup: false,
      }, () => {
        this.forceUpdate()
      })
    }
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  };


  changedata = (event) => {
    // console.log(event.target.value);
    this.setState({ SomthingWentRong: false });
  }


  render() {
    const {
      isOpenedDocuSign,
      SubscriptionDialog,
      hasGoggleDriveConnect,
      hasDropBoxConnect,
      DocuSingRoleData,
      option_show_dropdown,
      CenterDisplayFileDetails,
      fileListArray2,
      SingerData,
      DisplayDocuSingData
    } = this.state;

    let list = [];
    let displayFileList = [];
    let CenterFileList = [];
    let GoggleDrivePicker = [];
    let DropBoxPicker = [];

    if (fileListArray2) {
      fileListArray2.map((item, Index) => {
        displayFileList.push(
          <Grid
            className='d-flex'
            key={Index}
            onClick={() => this.ChangeDataOfFile(item, Index)}
          >
            <TooltipNew
              title={item.filename}
              placement="top">
              <Chip
                style={{
                  width: '120px',
                  margin: '2px'
                }}
                clickable
                icon={<Icon>attachment</Icon>}
                label={item.filename}
              />
            </TooltipNew>
          </Grid>
        )
      })
    }

    if (CenterDisplayFileDetails) {
      CenterDisplayFileDetails.map((item, index) => {
        CenterFileList.push(
          <Grid
            className='d-flex'
            key={index}  >
            <TooltipNew
              title={item.filename}
              placement="top">
              <Chip
                style={{ width: '120px' }}
                clickable
                icon={<Icon>attachment</Icon>}
                label={item.filename}
                onDelete={() => this.handleDelete(item.id, index)}
              />
            </TooltipNew>
          </Grid>
        )
      })
    }

    GoggleDrivePicker.push(
      <GooglePicker
        clientId={CLIENTID}
        developerKey={DEVLOPERKEY}
        scope={SCOPE}
        multiselect={true}
        navHidden={true}
        authImmediate={false}

        createPicker={(google, oauthToken) => {
          const picker = new window.google.picker.PickerBuilder()
            .addView(new google.picker.DocsView().setParent('root').setIncludeFolders(true))
            .enableFeature(google.picker.Feature.NAV_HIDDEN)
            .addView(google.picker.ViewId.FOLDERS)
            .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
            .setAppId(APPID)
            .setDeveloperKey(DEVLOPERKEY)
            .setOAuthToken(oauthToken)
            .setCallback((data) => {
              this.pickerCallback(data);
            })
            .build();
          picker.setVisible(true);
        }}
      >
        <div className="google"><MenuItem>Link to Google Drive</MenuItem></div>
      </GooglePicker>
    );

    DropBoxPicker.push(
      <DropboxChooser
        appKey={APP_KEY}
        success={files => this.handleDropBox(files)}
        multiselect={true}
        extensions={['.doc', '.docm', '.docx', '.dot', '.dotm', '.dotx', '.htm', '.html', '.msg', '.pdf', '.rtf', '.txt', '.wpd', '.xps', '.bmp', '.gif', '.jpg', '.jpeg', '.png', '.tif', '.tiff', '.pot', '.potx', '.pps', '.ppt', '.pptm', '.pptx'
          , '.csv', '.xls', '.xlsm', '.xlsx']}
      >
        <div className="dropbox">
          <MenuItem>
            <div>Link to Dropbox</div>
          </MenuItem>
        </div>
      </DropboxChooser>
    );

    if (DisplayDocuSingData && DisplayDocuSingData !== null) {
      DisplayDocuSingData.map((item) => {
        let Box_color = '';
        let Box_bgcolor = '';
        let colorClass = item.recipients && item.recipients[0].name.charAt(0).toUpperCase();
        let url = `https://appdemo.docusign.com/documents/details/${item.docusign_id}`;

        if (item.status === "completed") { Box_color = '#FFF'; Box_bgcolor = '#11C074' }
        if (item.status === "created") { Box_color = '#909799'; Box_bgcolor = '#FFF' }
        if (item.status === "sent") { Box_color = '#11C074'; Box_bgcolor = '#FFF' }

        list.push(
          <div className="box_portlet mt-12 crm-calendar-event" >
            <div className="box_body">
              <div className="lead_pl" style={{ paddingLeft: '55px' }}>
                <a target="_blank" href={url}>
                  <div className="icon_left">
                    <Fab
                      size="small"
                      aria-label="Task"
                      className={`wrap_icons_task_sidebar vert-middle bg-grey_sidebar`}
                    >
                      <GetAppIcon />
                    </Fab>
                  </div>
                </a>
                <Grid className="mt-10">
                  {this.state.dataloading === true ? <CircularProgress /> :
                    <>
                      <Grid container justify="space-between">
                        <Grid style={{ color: 'black', fontWeight: '300px' }}>
                          {item.subject}
                        </Grid>
                        <Box className="hover_hide font-size-10"
                          style={{
                            padding: "0 4px",
                            height: "20px",
                            background: `${Box_bgcolor}`,
                            color: `${Box_color}`,
                            border: `1px solid ${item.status === "completed" ? '#11C074' : Box_color}`,
                            fontSize: `${item.status === "completed" ? '11px' : '13px'}`,
                          }}>
                          {item.status.toUpperCase()}
                        </Box>
                        <Box className="Toggle_hover_hide" style={{ padding: "0 4px", height: "15px" }}>
                          <a href={url} target="_blank" >
                            <TooltipNew title="Open in DocuSign" placement="top" arrow>
                              <IconSmall style={{ fontSize: '18px' }}>
                                launch
                              </IconSmall>
                            </TooltipNew>
                          </a>
                          <TooltipNew title="Delete" placement="top" arrow>
                            <IconSmall style={{ fontSize: '18px', cursor: 'pointer' }} onClick={() => this.handleRemoveTask(item.id, item.subject)}>
                              delete
                            </IconSmall>
                          </TooltipNew>
                        </Box>
                      </Grid>
                      <a target="_blank" href={url}>
                        <Grid container justify="space-between" className="text-small" style={{ color: 'black' }} >
                          {item.status} {item.date_updated ? moment(item.date_updated, 'YYYY-MM-DD').format(this.state.dateformat) : " Created Date  -"}
                        </Grid>
                        <Grid container justify="space-between" style={{ color: 'black' }} className="text-small mt-4" >
                          Send by: {this.state.mainUSerName}
                        </Grid>
                        <Grid className='d-flex m-4'>
                          <Grid className='d-flex text-small'>
                            <Avatar
                              style={{ width: '24px', height: '24px' }}>
                              <span className="text-small" style={{ color: 'black' }} >
                                {colorClass.toUpperCase()}
                              </span>
                            </Avatar>
                            <IconButton aria-label="Pin" size="small" >
                              <LensIcon
                                style={{
                                  color: `${item.status === "completed" ? '#11C074' : '#FFF'}`,
                                  width: "10px",
                                  height: "10px",
                                  margin: '10px 0 0 -9px',
                                  border: `${item.status === "completed" ? '' : `1px solid ${Box_color}`}`,
                                  borderRadius: '50%'
                                }}
                              />
                            </IconButton>
                          </Grid>
                          <span className="text-small" style={{ marginTop: '2px', color: 'black' }} >
                            {item.recipients && item.recipients[0].name}
                          </span>
                        </Grid>
                      </a>
                    </>
                  }
                </Grid>
              </div>
            </div>
          </div>

        );
      })
    }


    return (
      <Card elevation={3} className="p-16 mt-16" style={{ overflow: 'initial' }}>
        <Grid container justify="space-between">
          <h5 className="flex-auto cursor_poiter mb-0 pt-4"
            onClick={this.toggledocuSign}>
            DocuSign ({this.state.totalCount})
            <Icon className="vert-middle">
              {isOpenedDocuSign ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
            </Icon>
          </h5>
          <IconButton aria-label="Add" size="small">
            <IconSmall onClick={this.toggleAddDocuSign}>add</IconSmall>
          </IconButton>

        </Grid>
        {isOpenedDocuSign &&
          <>
            {(DisplayDocuSingData.length == 0) ?
              <label className="text-hint">
                <IconButton className="mr-8" component="span">
                  <Icon>description</Icon>
                </IconButton> Send with DocuSign</label>
              :
              <>
                {list}
                {this.state.isDsDataLoading ?
                  <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: '10vh', backgroundColor: 'rgba(0,0,0,0)' }}
                  >
                    <Grid item xs={3}>
                      <CircularProgress disableShrink size={25} />
                    </Grid>
                  </Grid>
                  :
                  this.state.hasMore &&
                  <Grid container item justify="center" className='m-10'>
                    <a href="" className="text-small text_link" onClick={this.fetchMoreData}>
                      SHOW {this.state.totalCount - DisplayDocuSingData.length} MORE ENVELOPE
                           <Icon style={{ margin: "-8px 0px" }}>expand_more</Icon>
                    </a>
                  </Grid>}
              </>
            }

          </>
        }
        <Dialog
          maxWidth={'sm'}
          fullWidth={true}
          disableBackdropClick
          className="crm_dialog_setting"
          open={SubscriptionDialog}
          onClose={this.SubscriptionDialog}
          style={{ zIndex: '100' }}
        >
          <DialogTitle>
            <Typography
              className="p-10 font-weight-500"
              variant="h6"
              color="inherit">
              Choose Recipients and Assign Roles
            </Typography>
            <div
              className="hover_show"
              style={{ margin: '-65px 0 0 540px' }} >
              <IconButton
                aria-label="Delete"
                size="small"
                onClick={this.toggleAddDocuSign}
              >
                <IconSmall
                  style={{ fontSize: '18px' }}
                >
                  clear
                </IconSmall>
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            <Grid
              style={{
                margin: '15px',
                padding: '10px'
              }} >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {this.state.inputs.map((input, i) =>
                    <Autocomplete
                      key={i}
                      multiple
                      disableClearable
                      freeSolo
                      options={[]}
                      defaultValue={[
                        input === 'input-0' && SingerData && SingerData.length && SingerData[0].title
                      ]}
                      onChange={(option, value) => this.handleChanegText(value)}
                      renderTags={(value, getTagProps) =>
                        input === 'input-0' && SingerData && SingerData.length ? (
                          // value.map((option, index) => (
                          <TooltipNew title={this.state.email} placement="top">
                            <Chip
                              size="small"
                              avatar={
                                this.state.Image ?
                                  <Avatar alt="Natacha" src={this.state.Image} />
                                  :
                                  <Avatar className={`avatar_circle color_${SingerData && SingerData.length && SingerData[0].title.charAt(0)}`}>
                                    {SingerData && SingerData.length && SingerData[0].title.charAt(0).toUpperCase()}
                                  </Avatar>
                              }
                              label={SingerData && SingerData.length && SingerData[0].title}
                            // onDelete={this.handleSinger}
                            />
                          </TooltipNew>
                          //   ))
                        ) : ("")
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          autoFocus
                          className="w-100 input_bx"
                          value={this.state.AutocompleteValue}
                          onChange={this.changedata}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <React.Fragment>
                                <TextField
                                  style={{
                                    width: '100px',
                                    marginTop: '5px'
                                  }}
                                  select
                                  id="search-input"
                                  placeholder="search"
                                  defaultValue={option_show_dropdown}
                                  onChange={this.handleDropBoxChange}
                                >
                                  {DocuSingRoleData.map((iteam, i) => (
                                    <MenuItem value={iteam.name} >
                                      {iteam.name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                                {params.InputProps.startAdornment}</React.Fragment>
                            ),

                            endAdornment: (
                              <React.Fragment>
                                {input === 'input-0' && this.state.inputs.length < this.state.DocuSingRoleData.length &&
                                  this.state.recordtype !== "lead" && this.state.recordtype !== "project" &&
                                  <label
                                    onClick={() => this.appendInput()}
                                    className="compose_ext_name_lowerCase"
                                  >
                                    Add a Recipient
                                </label>
                                }
                                {params.InputProps.endAdornment}
                              </React.Fragment>

                            ),
                          }}
                        />
                      )}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid style={{ marginTop: '15px' }} >
                <TextField
                  type="text"
                  fullWidth
                  name="emailSubject"
                  placeholder='Subject'
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid container spacing={0}
                className="p-5"
                justify="space-between"
                style={{ margin: '15px 15px 15px 3px' }}
              >
                <Grid className="compose_ext_name_lowerCase" >
                  File
                  </Grid>
                <Grid style={{ margin: '0 0 0 -250px' }} >
                  {
                    CenterDisplayFileDetails && CenterDisplayFileDetails.map((item, index) => (
                      <Grid
                        className='d-flex'
                        key={index}  >
                        <TooltipNew
                          title={item.filename}
                          placement="top">
                          <Chip
                            style={{ width: '120px' }}
                            clickable
                            icon={<Icon>attachment</Icon>}
                            label={item.filename}
                            onDelete={() => this.handleDelete(item.id, index)}
                          />
                        </TooltipNew>
                      </Grid>
                    ))
                  }
                </Grid>
                <DropdownDefault
                  menuButton={
                    <Button size="small" color="primary" onClick={this.changedata} style={{ borderRadius: '10px' }} >Add File</Button>
                  }
                >
                  <Grid style={{ margin: '10px', width: '300px' }}>
                    {fileListArray2 && fileListArray2.map((item, Index) => (
                      <TooltipNew
                        title={item.filename}
                        placement="top">
                        <Chip
                          style={{
                            width: '120px',
                            height: '20px',
                            margin: '2px'
                          }}
                          onClick={() => this.ChangeDataOfFile(item, Index)}
                          clickable
                          icon={
                            <Icon
                              tyle={{
                                height: '20px',
                              }}>
                              attachment
                            </Icon>}
                          label={item.filename}
                        />
                      </TooltipNew>
                    ))}
                  </Grid>
                  <MenuItem onClick={() => this.fileInput.click()} >Upload from My Computer</MenuItem>
                  {hasGoggleDriveConnect === true ? GoggleDrivePicker : ""}
                  {hasDropBoxConnect === true ? DropBoxPicker : ""}
                </DropdownDefault>
                <input style={{ display: 'none' }} type="file"
                  accept=".doc, .docm, .docx, .dot, .dotm, .dotx, .htm, .html, .msg, .pdf, .rtf, .txt, .wpd, .xps, .bmp, .gif, .jpg, .jpeg, .png, .tif, .tiff, .pot, .potx, .pps, .ppt, .pptm, .pptx
,.csv, .xls, .xlsm, .xlsx"  onChange={this.handleAttachmentSelection}
                  ref={fileInput => this.fileInput = fileInput}
                />
              </Grid>
              {this.state.isDataLoading &&
                <Grid
                  container
                  spacing={0}
                  className="p-20"
                  direction="column"
                  alignItems="center"
                  justify="center"
                >
                  <Grid item xs={3}>
                    <CircularProgress disableShrink size={30} />
                  </Grid>
                </Grid>}
            </Grid>
            {this.state.recipientError ? <p style={{ color: 'red', margin: '0 25px ' }} >Please add a recipient</p> : ''}
            {this.state.EmailSubjectError ? <p style={{ color: 'red', margin: '0 25px ' }} >Please add a subject</p> : ''}
            {this.state.FileError ? <p style={{ color: 'red', margin: '0 25px ' }} >Please add a file</p> : ''}
            {this.state.SomthingWentRong ? <p style={{ color: 'red', margin: '0 25px ' }} >Something went wrong creating your envelope</p> : ''}
          </DialogContent>
          <DialogActions>
            <DialogActions className="dialog-action">
              <Button variant="contained" onClick={this.toggleAddDocuSign}>Cancel</Button>
              <Button variant="contained" onClick={this.handleSubmit} color="primary" className="btn_Primary" type="submit">
                Send to DocuSign
              </Button>
            </DialogActions>
          </DialogActions>
        </Dialog>
        <Dialog
          // fullWidth={true}
          maxWidth={'sm'}
          // style={{ maxWidth: '700px' }}
          open={this.state.removeFilePopup}
          onClose={this.toggleRemoveFilePopup}
          disableBackdropClick

          aria-labelledby="ar-remove-dialog-title"
        >
          <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to remove the association with " + this.state.RemoveSubject + "?"}</DialogTitle>
          <DialogContent>
            After removing, you will not be able to reassociate the envelope
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleRemoveFilePopup} color="primary">
              Cancel
              </Button>
            <Button type="Button" onClick={() => this.confirmremoveFilePopup()} className="btn_Primary">
              Ok
              </Button>
          </DialogActions>
        </Dialog>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
  taskReducer: state.taskReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    getRecordRelatedData: (client, req) => { dispatch(actionCreators.getRecordRelatedData(client, req)) },
    saveRelatedFiles: (client, req) => { dispatch(actionCreators.saveRelatedFiles(client, req)) },
    saveDropboxRelatedFiles: (client, req) => { dispatch(actionCreators.saveDropboxRelatedFiles(client, req)) },
    saveGoogleRelatedFiles: (client, req) => { dispatch(actionCreators.saveGoogleRelatedFiles(client, req)) },
    deleteRelatedFiles: (client, req) => { dispatch(actionCreators.deleteRelatedFiles(client, req)) },
    getUserIntegration: (client) => dispatch(actionCreators.getUserIntegration(client)),
    getDocuSignRole: (client) => dispatch(actionCreators.getDocuSignRole(client)),
    SaveDocuSignRecipient: (value) => dispatch(actionCreators.SaveDocuSignRecipient(value)),
    GetDocuSignData: (value) => dispatch(actionCreators.GetDocuSignData(value)),
    RemoveDocuSignData: (value) => dispatch(actionCreators.RemoveDocuSignData(value)),
    getUserDetailByEmail: (client, req) => { dispatch(actionCreators.getUserDetailByEmail(client, req)) },
  }
}


export default (connect(mapStateToProps, mapDispatchToProps)(withRouter(DocuSign)));	