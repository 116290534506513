
import React, { Component } from 'react';
import { Grid, IconButton, Typography } from "@material-ui/core";
import * as actions from "../../../store/actions/index";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { connect } from "react-redux";
import { compose } from 'redux';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import AllSearchLogs from './AllSearchLogs';
import LeadSearchLogs from './LeadSearchLogs';
import ContactSearchLogs from './ContactSearchLogs';
import CompaniesSearchLogs from './CompaniesSearchLogs';
import OpportunitiesSearchLogs from './OpportunitiesSearchLogs';
import TasksSearchLogs from './TasksSearchLogs';
import ProjectsSearchLogs from './ProjectsSearchLogs';

class MasterSerach extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 'Everything',
      serachText: '',
      masterSerachData: [],
      sortedData: [],
    };
    this.goBack = this.goBack.bind(this);
  }

  componentWillMount() {
    this.props.settingReducer.masterSerach = [];
    this.setState({ masterSerachData: [] });
  }

  componentDidMount() {
    if (this.props.match.params.phrase) {
      console.log("serachtext:" ,this.props.match.params.phrase);
      let req = {
        searchtext: this.props.match.params.phrase,
      }
      this.props.masterSearch(this.props.apolloClient.client, req);
      this.setState({ serachText: this.props.match.params.phrase });
    }
  }

  componentWillReceiveProps(props) {
    if (props.settingReducer.masterSerach && props.settingReducer.masterSerach.data) {
      if (props.settingReducer.masterSerach.data.type === props.match.params.phrase) {
        // console.log("data of serach result :::", props.settingReducer.masterSerach.data);
        const masterSerachData = props.settingReducer.masterSerach.data;
        if (masterSerachData !== this.state.masterSerachData) {
          const data = masterSerachData.data;
          this.setState({ masterSerachData: data });
        }
      }
      this.setState({ serachText: props.match.params.phrase });
    }
  }

  handleChange = (event) => {
    console.log(event.target.value);
    this.setState({ value: event.target.value });
  };

  goBack() {
    this.props.history.goBack();
  }

  render() {
    const { masterSerachData, serachText } = this.state;
    return (
      <>
        <Typography className="card-margin-leadstatus p-10 d-flex" variant="h6" color="inherit" style={{ margin: "20px 10px 10px 190px" }}>
          <IconButton style={{ marginTop: '-7px' }} onClick={this.goBack} >
            <ArrowBackIcon />
          </IconButton>
          Search
        </Typography>
        <div className="v_scroll">
          <Grid className="d-flex"  >
            <Grid style={{ margin: '16px 0 0 220px' }}>
              <FormControl component="fieldset">
                <RadioGroup aria-label="gender" name="gender1" value={this.state.value} onChange={this.handleChange}>
                  <FormControlLabel
                    value="Everything"
                    control={<Radio color="primary" />}
                    label={<span className="font-size-13">Everything</span>}
                  />
                  <FormControlLabel
                    value="Lead"
                    control={<Radio color="primary" />}
                    label={<span className="font-size-13">Lead</span>}
                  />
                  <FormControlLabel
                    value="Contact"
                    control={<Radio color="primary" />}
                    label={<span className="font-size-13">Contact</span>}
                  />
                  <FormControlLabel
                    value="Companies"
                    control={<Radio color="primary" />}
                    label={<span className="font-size-13">Companies</span>}
                  />
                  <FormControlLabel
                    value="Opportunities"
                    control={<Radio color="primary" />}
                    label={<span className="font-size-13">Opportunities</span>}
                  />
                  <FormControlLabel
                    value="Tasks"
                    control={<Radio color="primary" />}
                    label={<span className="font-size-13">Tasks</span>}
                  />
                  <FormControlLabel
                    value="Projects"
                    control={<Radio color="primary" />}
                    label={<span className="font-size-13">Projects</span>}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid style={{ marginBottom: '70px', marginLeft: '80px', maxWidth: '800px' }}>
              <Grid className="font-size-13 font-weight-500" container style={{ margin: '0 0 10px 20px' }}>
                Results for "{serachText}"
              </Grid>
              {this.state.value === "Everything" ?
                <AllSearchLogs
                  thisData={this}
                  serachText={serachText}
                  masterSerachData={masterSerachData}
                />
                : ""}
              {this.state.value === "Lead" ?
                <LeadSearchLogs
                  thisData={this}
                  serachText={serachText}
                  masterSerachData={masterSerachData}
                />
                : ""}
              {this.state.value === "Contact" ?
                <ContactSearchLogs
                  thisData={this}
                  serachText={serachText}
                  masterSerachData={masterSerachData}
                />
                : ""}
              {this.state.value === "Companies" ?
                <CompaniesSearchLogs
                  thisData={this}
                  serachText={serachText}
                  masterSerachData={masterSerachData}
                />
                : ""}
              {this.state.value === "Opportunities" ?
                <OpportunitiesSearchLogs
                  thisData={this}
                  serachText={serachText}
                  masterSerachData={masterSerachData}
                />
                : ""}
              {this.state.value === "Tasks" ?
                <TasksSearchLogs
                  thisData={this}
                  serachText={serachText}
                  masterSerachData={masterSerachData}
                />
                : ""}
              {this.state.value === "Projects" ?
                <ProjectsSearchLogs
                  thisData={this}
                  serachText={serachText}
                  masterSerachData={masterSerachData}
                />
                : ""}
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    masterSearch: (client, req) => { dispatch(actions.masterSearch(client, req)) },
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(MasterSerach);
