import React, { Component } from "react";
import { Checkbox, Typography, Card, Grid, Button } from "@material-ui/core";
import { Table } from 'antd';
import BusinessIcon from '@material-ui/icons/Business';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PersonIcon from '@material-ui/icons/Person';

class ImportOpportunities extends Component {
    state = {
       
    };
    
    goToUploadPage = (type) => {
        this.props.history.push('/settings/import-opportunities/' + type);
    }

    backToImport = () => {
        this.props.history.push('/settings/import-data/');
    }

    render() {
        return (
            <React.Fragment>
                <div className="v_scroll crm-custom-importdata-settings">
                    <div className="mb-40">
                        <Typography className=" p-10" variant="h5" color="inherit">Import Opportunities</Typography>
                        <Typography className="text-hint p-10" variant="p">
                            Import data into IDrive CRM from Google Contacts, CSV files, or Excel files.
                        </Typography>
                    </div>
                    <Card className="p-20 mb-10">
                        <Typography className="font-weight-500 p-10" variant="p" color="inherit">Step 1: Choose where you're importing from</Typography>
                        <Grid container className="mb-10 mt-30 import-buttons">
                            <Grid item md={2} className="text-center">
                                <div>
                                    <Button variant="outlined" className="types-buttons" onClick={(type) => this.goToUploadPage('CSV')} color="default" size="small">CSV</Button>
                                </div>
                            </Grid>
                            <Grid item md={2} className="text-center">
                                <div>
                                    <Button variant="outlined" className="types-buttons" onClick={(type) => this.goToUploadPage('Excel')} color="default" size="small">Excel</Button>
                                </div>
                            </Grid>
                            <Grid item md={2} className="text-center">
                                <div>
                                    <Button variant="outlined" className="types-buttons" onClick={(type) => this.goToUploadPage('Salesforce')} color="default" size="small">Salesforce</Button>
                                </div>
                            </Grid>
                            <Grid item md={2} className="text-center">
                                <div>
                                    <Button variant="outlined" className="types-buttons" onClick={(type) => this.goToUploadPage('Google Sheets')} color="default" size="small">Google Sheets</Button>
                                </div>
                            </Grid>
                            <Grid item md={2} className="text-center">
                                <div>
                                    <Button variant="outlined" className="types-buttons" onClick={(type) => this.goToUploadPage('Pipedrive')} color="default" size="small">Pipedrive</Button>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container className="mb-10 import-buttons">
                            <Grid item md={2} className="text-center">
                                <div>
                                    <Button variant="outlined" className="types-buttons" onClick={(type) => this.goToUploadPage('Base CRM')} color="default" size="small">Base CRM</Button>
                                </div>
                            </Grid>
                            <Grid item md={2} className="text-center">
                                <div>
                                    <Button variant="outlined" className="types-buttons" onClick={(type) => this.goToUploadPage('Salesforce IQ')} color="default" size="small">Salesforce IQ</Button>
                                </div>
                            </Grid>
                            <Grid item md={2} className="text-center">
                                <div>
                                    <Button variant="outlined" className="types-buttons" onClick={(type) => this.goToUploadPage('Insightly')} color="default" size="small">Insightly</Button>
                                </div>
                            </Grid>
                            <Grid item md={2} className="text-center">
                                <div>
                                    <Button variant="outlined" className="types-buttons" onClick={(type) => this.goToUploadPage('Nimble')} color="default" size="small">Nimble</Button>
                                </div>
                            </Grid>
                            <Grid item md={2} className="text-center">
                                <div>
                                    <Button variant="outlined" className="types-buttons" onClick={(type) => this.goToUploadPage('Other')} color="default" size="small">Other</Button>
                                </div>
                            </Grid>
                        </Grid>
                        
                        <Button onClick={this.backToImport} color="secondary" >Cancel</Button>
                    </Card>                   
                </div>
            </React.Fragment>
        );
    }
}

export default ImportOpportunities;
