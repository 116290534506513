import React, { Component } from "react";
import SettingSidebar from '../../Layout/SettingSidebarMenu';
import { InputLabel, MenuItem, Select, Card, Button, Paper, Icon, Switch, IconButton, Grid, Dialog, DialogTitle, DialogActions, } from "@material-ui/core";
import { Link } from "react-router-dom";

import * as actions from '../../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';
import moment, { now } from 'moment';



class WorkflowAutomation extends Component {
    state = {
        workflowCheckbox: true,
        opportunityCheckbox: true,
        companyCheckbox: true,
        personCheckbox: true,
        workflowList:[],
        userSettings:[],
        deleteid:"",
        // settingObject:[],
        timezoneList:[],
        selectedTimezone:"",

    };

    componentDidMount(){
        this.props.getAutomationList(this.props.apolloClient.client);
        this.props.getTimezoneList(this.props.apolloClient.client);
    }

    componentWillReceiveProps(props) {
        if(props.settingReducer.timezoneList.data && this.state.timezoneList.length == 0) {
            const data = props.settingReducer.timezoneList.data;
            this.setState({
                timezoneList:data,
            })
        }
        

        if (props.settingReducer.automationList && props.settingReducer.automationList.data) {
            this.setState({ workflowList: props.settingReducer.automationList.data })  
        }

        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            const settingData = props.settingReducer.userSettingArray.data;
            // console.log(settingData,'settingDatasettingDatasettingData');
            this.setState({
                userSettings: settingData,
                selectedTimezone:settingData.automationtimezone,
            })
        }
    }
    
    handleEditautomation = (event, id) => {
        this.props.history.push('/settings/edit-workflow-automation/'+id);
    }

    handleChange = event => {
        event.persist();
        if (event.target.type === 'checkbox') {
            this.setState({ [event.target.name]: event.target.checked });
        }
        else {
            this.setState({ [event.target.name]: event.target.value });
        }
    };

    handleStatusChange = (event, id) => {
        let updateStatusParams = { id: parseFloat(id), status: event.target.checked};
        this.props.updateAutomationStatus(this.props.apolloClient.client, updateStatusParams);
    }

    handleDeleteAutomation = (id) => {
        this.setState({deleteid: id});
        this.toggleDeleteDialog()
    }

    toggleDeleteDialog = () => {
        this.setState({ isDeleteConfirmDialoOpen: !this.state.isDeleteConfirmDialoOpen });
    };
    confirmDeleteAutomation = () => {
        let deleteParams = { automationid: parseFloat(this.state.deleteid) };
        this.props.deleteAutomation(this.props.apolloClient.client, deleteParams);
        this.setState({deleteid: ""});
        this.toggleDeleteDialog()
    };
    selectTimezone = (event) => {
        
        let params = { timezone: event.target.value.toString(), userid: parseFloat(JSON.parse(localStorage.getItem('user_data_session')).id) };
        this.props.updateAutomationTimezone(this.props.apolloClient.client, params);

        this.setState({selectedTimezone: event.target.value})
    }
    render() {
        let { timezoneList, selectedTimezone, workflowList, workflowCheckbox, opportunityCheckbox, companyCheckbox, personCheckbox } = this.state;
    
        return (
            <React.Fragment>
                <SettingSidebar />
                <div className="lead_content right-16 settings_view">
                    <Card elevation={3} className="crm-workflow-automation-list">
                        <div className="v_scroll">
                            <div className="d-flex justify-content-between m-16">
                                <h3>Workflow automation</h3>
                                
                                <Link to="/settings/add-new-workflow-automation/">
                                    <Button variant="contained" color="primary" size="small" >Add New Work Automation</Button>
                                </Link>
                            </div>
                            
                            <Paper variant="outlined" square className="paper_bx mb-20 p-20">
                                <Grid container spacing={3}>
                                    <Grid item xs={4} className="pt-0">
                                        <InputLabel htmlFor="assignedto" className="font-size-14 font-weight-500">Time Zone Used for Workflows</InputLabel>
                                        <Select
                                            
                                            name="selectedTimezone"
                                            value={selectedTimezone ? selectedTimezone : "-"}
                                            onChange={(event) => this.selectTimezone(event)}
                                            MenuProps={{ className: 'choose_field_menu' }}
                                            className="crm-worflow-condition-comparator"
                                        >
                                            <MenuItem className="select-menu-item" value={"-"}>--Select timezone--</MenuItem>
                                            {(timezoneList && timezoneList) && timezoneList.map((option) => (
                                                <MenuItem className="select-menu-item" value={option.name}>{option.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                </Grid>
                                
                            </Paper>
                            
                            { (workflowList && workflowList.length > 0) ?  
                                workflowList.map((automation)=>(
                                    <Paper variant="outlined" square className="paper_bx">
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item className="automation_icon">
                                                {(automation.triggerrecordid == 1) ? <Icon>supervised_user_circle</Icon> : (automation.triggerrecordid == 2) ? <Icon>person</Icon> : (automation.triggerrecordid == 3) ? <Icon>business</Icon>: (automation.triggerrecordid == 4) ? <Icon>attach_money</Icon>: (automation.triggerrecordid == 5) ? <Icon>business_center</Icon>: (automation.triggerrecordid == 6) ? <Icon>list</Icon> : ""}
                                            </Grid>
                                            <Grid item className="automation_details flex-auto">
                                                <h6>{automation.name}</h6>
                                                <p> Created on 
                                                    {
                                                    (automation.createddate != null) ?
                                                    " " + moment.unix(automation.createddate).format(this.state.userSettings.dateformat)+ " " + moment.unix(automation.createddate).format('hh:mm:ss a')+ " "
                                                    : " "
                                                    } 
                                                    by {automation.user.name}
                                                </p>
                                            </Grid>
                                            <Grid item className="justify-content-end">
                                                <Switch
                                                    checked={automation.isactive}
                                                    name="workflowCheckbox"
                                                    onChange={(event, id)=>this.handleStatusChange(event, automation.id)}
                                                    value="workflowCheckbox"
                                                    color="primary"
                                                />
                                                <IconButton aria-label="edit" onClick={(event, id)=>this.handleEditautomation(event, automation.id)} size="small">
                                                    <Icon>edit</Icon>
                                                </IconButton>
                                                <IconButton aria-label="delete" onClick={(id) => this.handleDeleteAutomation(automation.id)} size="small">
                                                    <Icon>delete</Icon>
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                ))
                            :
                            <Grid container spacing={3}>
                                <Grid item md={12} className="pt-0">
                                    <Paper variant="outlined" square className="paper_bx p-20 mt-20">
                                        <h5 className="text-hint font-weight-500">No Workflow Automation Rules</h5>
                                    </Paper>
                                </Grid>
                            </Grid>
                            }
                        </div>
                    </Card>
                </div>
                <Dialog
                    fullWidth={true}
                    maxWidth={'xs'}
                    open={this.state.isDeleteConfirmDialoOpen}
                    onClose={this.toggleDeleteDialog}
                    disableBackdropClick
                    // disableEscapeKeyDown
                    aria-labelledby="ar-remove-dialog-title"
                >
                    <DialogTitle id="ar-remove-dialog-title">{"Are you sure to remove workflow automation ?"}</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.toggleDeleteDialog} color="primary">
                            Cancel
                        </Button>
                        <Button type="Button" onClick={() => this.confirmDeleteAutomation()} className="btn_Primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}


const mapStateToProps = state => {
    return {
        settingReducer: state.settingReducer,
        apolloClient: state.apolloClient
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAutomationList: (client) => dispatch(actions.getAutomationList(client)),
        deleteAutomation: (client, request) => dispatch(actions.deleteAutomation(client, request)),
        updateAutomationStatus: (client, request) => dispatch(actions.updateAutomationStatus(client, request)),
        updateAutomationTimezone: (client, request) => dispatch(actions.updateAutomationTimezone(client, request)),
        
        getTimezoneList: (client) => dispatch(actions.getTimezoneList(client)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(WorkflowAutomation);

// import React, { Component } from "react";
// import SettingSidebar from '../../Layout/SettingSidebarMenu';
// import { Card, Button, Paper, Icon, Switch, IconButton, Grid, Dialog, DialogTitle, DialogActions, } from "@material-ui/core";
// import { Link } from "react-router-dom";

// import * as actions from '../../../store/actions/index';
// import { connect } from "react-redux";
// import { compose } from 'redux';
// import moment, { now } from 'moment';



// class WorkflowAutomation extends Component {
//     state = {
//         workflowCheckbox: true,
//         opportunityCheckbox: true,
//         companyCheckbox: true,
//         personCheckbox: true,
//         workflowList:[],
//         userSettings:[],
//         deleteid:"",
//     };

//     componentDidMount(){
//         this.props.getAutomationList(this.props.apolloClient.client);
//     }

//     componentWillReceiveProps(props) {
//         if (props.settingReducer.automationList && props.settingReducer.automationList.data) {
//             // console.log(props.settingReducer.automationList.data,' hgsdhjf shjd');
//             this.setState({ workflowList: props.settingReducer.automationList.data })  
//         }

//         if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
//             const settingData = props.settingReducer.userSettingArray.data;
            
//             this.setState({
//                 userSettings: settingData,
//             })

//             if(this.state.selectedTimezone == ""){
//                 this.setState({selectedTimezone:settingData.automationtimezone})
//             }
//         }
//     }
    
//     handleEditautomation = (event, id) => {

        
//         this.props.history.push('/settings/edit-workflow-automation/'+id);
//     }

//     handleChange = event => {
//         event.persist();
//         if (event.target.type === 'checkbox') {
//             this.setState({ [event.target.name]: event.target.checked });
//         }
//         else {
//             this.setState({ [event.target.name]: event.target.value });
//         }
//     };

//     handleStatusChange = (event, id) => {
//         console.log(event.target.checked,'event.target.checked');
//         let updateStatusParams = { id: parseFloat(id), status: event.target.checked};
//         this.props.updateAutomationStatus(this.props.apolloClient.client, updateStatusParams);
//     }

//     handleDeleteAutomation = (id) => {
//         this.setState({deleteid: id});
//         this.toggleDeleteDialog()
//     }

//     toggleDeleteDialog = () => {
//         this.setState({ isDeleteConfirmDialoOpen: !this.state.isDeleteConfirmDialoOpen });
//     };
//     confirmDeleteAutomation = () => {
//         let deleteParams = { automationid: parseFloat(this.state.deleteid) };
//         this.props.deleteAutomation(this.props.apolloClient.client, deleteParams);
//         this.setState({deleteid: ""});
//         this.toggleDeleteDialog()
//     };

//     render() {
//         let { workflowList, workflowCheckbox, opportunityCheckbox, companyCheckbox, personCheckbox } = this.state;
//         return (
//             <React.Fragment>
//                 <SettingSidebar />
//                 <div className="lead_content right-16 settings_view">
//                     <Card elevation={3} className="crm-workflow-automation-list">
//                         <div className="v_scroll">
//                             <div className="d-flex justify-content-between m-16 " >
//                                 <h3>Workflow automation</h3>
//                                 <Link to="/settings/add-new-workflow-automation/">
//                                     <Button variant="contained" color="primary" size="small" >Add new work automation</Button>
//                                 </Link>
//                             </div>
//                             { (workflowList && workflowList.length > 0) ?  
//                                 workflowList.map((automation)=>(
//                                     <Paper variant="outlined" square className="paper_bx">
//                                         <Grid container spacing={1} alignItems="center">
//                                             <Grid item className="automation_icon">
//                                                 {(automation.triggerrecordid == 1) ? <Icon>supervised_user_circle</Icon> : (automation.triggerrecordid == 2) ? <Icon>person</Icon> : (automation.triggerrecordid == 3) ? <Icon>business</Icon>: (automation.triggerrecordid == 4) ? <Icon>attach_money</Icon>: (automation.triggerrecordid == 5) ? <Icon>business_center</Icon>: (automation.triggerrecordid == 6) ? <Icon>list</Icon> : ""}
//                                             </Grid>
//                                             <Grid item className="automation_details flex-auto">
//                                                 <h6>{automation.name}</h6>
//                                                 <p> Created on 
//                                                     {
//                                                     (automation.createddate != null) ?
//                                                     " " + moment.unix(automation.createddate).format(this.state.userSettings.dateformat)+ " " + moment.unix(automation.createddate).format('hh:mm:ss a')+ " "
//                                                     : " "
//                                                     } 
//                                                     by {automation.user.name}
//                                                 </p>
//                                             </Grid>
//                                             <Grid item className="justify-content-end">
//                                                 <Switch
//                                                     checked={automation.isactive}
//                                                     name="workflowCheckbox"
//                                                     onChange={(event, id)=>this.handleStatusChange(event, automation.id)}
//                                                     value="workflowCheckbox"
//                                                     color="primary"
//                                                 />
//                                                 <IconButton aria-label="edit" onClick={(event, id)=>this.handleEditautomation(event, automation.id)} size="small">
//                                                     <Icon>edit</Icon>
//                                                 </IconButton>
//                                                 <IconButton aria-label="delete" onClick={(id) => this.handleDeleteAutomation(automation.id)} size="small">
//                                                     <Icon>delete</Icon>
//                                                 </IconButton>
//                                             </Grid>
//                                         </Grid>
//                                     </Paper>
//                                 ))
//                             :
//                                 <Paper variant="outlined" square className="paper_bx">
//                                     <h5 className="text-hint font-weight-500">No Workflow Automation Rules</h5>
//                                 </Paper>
//                             }
//                         </div>
                        
//                     </Card>
//                 </div>
//                 <Dialog
//                     fullWidth={true}
//                     maxWidth={'xs'}
//                     open={this.state.isDeleteConfirmDialoOpen}
//                     onClose={this.toggleDeleteDialog}
//                     disableBackdropClick
//                     disableEscapeKeyDown
//                     aria-labelledby="ar-remove-dialog-title"
//                 >
//                     <DialogTitle id="ar-remove-dialog-title">{"Are you sure to remove workflow automation ?"}</DialogTitle>
//                     <DialogActions>
//                         <Button onClick={this.toggleDeleteDialog} color="primary">
//                             Cancel
//                         </Button>
//                         <Button type="Button" onClick={() => this.confirmDeleteAutomation()} className="btn_Primary">
//                             Ok
//                         </Button>
//                     </DialogActions>
//                 </Dialog>
//             </React.Fragment>
//         );
//     }
// }


// const mapStateToProps = state => {
//     return {
//         settingReducer: state.settingReducer,
//         apolloClient: state.apolloClient
//     };
// };

// const mapDispatchToProps = dispatch => {
//     return {
//         getAutomationList: (client) => dispatch(actions.getAutomationList(client)),
//         deleteAutomation: (client, request) => dispatch(actions.deleteAutomation(client, request)),
//         updateAutomationStatus: (client, request) => dispatch(actions.updateAutomationStatus(client, request)),
        
//     };
// };

// export default compose(connect(mapStateToProps, mapDispatchToProps))(WorkflowAutomation);

