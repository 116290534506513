import React, { Component } from "react";
import { List, ListItem, Menu, ListItemText, LinearProgress, Fab, Grid, withStyles, Switch, InputLabel, FormControl, Input, FormHelperText, RadioGroup, Radio, FormControlLabel, Divider, DialogContent, InputAdornment, Button, IconButton, Icon, MenuItem, Card, TextField, Dialog, DialogActions, DialogTitle, Drawer, Avatar } from "@material-ui/core";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { NavLink, withRouter } from "react-router-dom";

class ProjectStatus extends Component {
    state = {
        options: [],
        isOpen: false,
        selectedIndex: 1,
        anchorEl: null,
        oldStatusId: "",
        projectid: "",
        statusOptions: "",
        getRelatedReq: "",
    };

    componentDidMount() {
        if(this.props.projectid){
            this.setState({
                projectid:this.props.projectid
            })
        }
        if(this.props.getRelatedReq){
            this.setState({
                getRelatedReq:this.props.getRelatedReq
            })
        }
        if(this.props.statusOptions){
            var array = []
            this.props.statusOptions.map((v,k)=>{
                array.push(v.name)
            })
            this.setState({
                options:array,
                statusOptions:this.props.statusOptions
            })
            if(this.props.oldStatusId){
                var key = this.findWithAttr(this.props.statusOptions, 'id', this.props.oldStatusId);
                console.log("this.props.statusOptions[key].name", this.props.statusOptions[key].name);
                var selectedKey = array.indexOf(this.props.statusOptions[key].name)
                console.log("selectedKey", selectedKey);
                this.setState({
                    oldStatusId:parseInt(this.props.oldStatusId),
                    selectedIndex:selectedKey,
                })
            }
        }
    }
    componentWillReceiveProps(props) {

    }

    handleClickListItem(event) {
        this.setState({
            isOpen: !this.state.isOpen,
            anchorEl: event.currentTarget,
        })
    }

    handleMenuItemClick = (event, index) => {
        var key = this.findWithAttr(this.state.statusOptions, 'name',this.state.options[index]);
        var newStatus = this.state.statusOptions[key]
        this.setState({
            selectedIndex: index,
        })
        var req = {
            updated_status_id:newStatus.id,
            current_status_id:parseInt(this.state.oldStatusId),
            projectid:parseInt(this.state.projectid),
            getRelatedReq:this.state.getRelatedReq,
        }
        this.props.updateProjectStatus(this.props.apolloClient.client,req);

        this.handleClose()
        this.setState({
            oldStatusId: newStatus.id,
        })
    }

    handleClose() {
        this.setState({ isOpen: false })
    }

    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] == value) {
                return i;
            }
        }
        return -1;
    }
    render() {

        return (
            <>
            <List className="dorpdown_menu">
                    <ListItem
                        aria-haspopup="true"
                        aria-controls="lock-menu"
                        onClick={(event)=>this.handleClickListItem(event)}
                    >
                        <ListItemText className="dorpdown_menu_list"
                            secondary={this.state.options[this.state.selectedIndex]}
                        />
                        <Icon>arrow_drop_down</Icon>
                    </ListItem>
                </List>
                <Menu 
                id = "lock-menu"
                anchorEl = { this.state.anchorEl } 
                keepMounted 
                open = { this.state.isOpen } 
                onClose = {() => this.handleClose() } >
                {
                    this.state.options.map((option, index) => (
                        <MenuItem
                                className="select-menu-item"
                                key={option}
                                selected={index === this.state.selectedIndex}
                                onClick={(event)=> this.handleMenuItemClick(event, index)}
                            >{option}
                            </MenuItem>
                    ))
                }
            </Menu>
            </>
        );
    }
}

const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
    notification: state.notification,
    projectReducer: state.projectReducer,
});

const mapDispatchToProps = dispatch => {
    return {
        updateProjectStatus: (client, req) => { dispatch(actionCreators.updateProjectStatus(client, req)) },
    }
}


export default (connect(mapStateToProps, mapDispatchToProps)(withRouter(ProjectStatus)));