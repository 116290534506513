import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Box, Typography, Card } from "@material-ui/core";
import SummaryDashboard from "./SummaryTab/SummaryDashboard"
import GoalsDashboard from "./GoalsTab/GoalsDashboard"


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={2}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}
export default function DashboardTab(props) {
    const [value, setValue] = React.useState(0);
    function handleChange(event, newValue) {
        setValue(newValue);
    }
    return (
        <div className="v_scroll ml-50 mt-16 tab_card">
            <Tabs
                style={{
                    borderBottom: "1px solid #504f4f14"
                }}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                scrollButtons="auto"
                className="tab_selected_color"
                variant="scrollable"
            >
                <Tab label="Summary" {...a11yProps(0)} />
                <Tab label="Goal" {...a11yProps(1)} />

                {/* <Tab label="Activity" {...a11yProps(2)} />
                <Tab label="Goals" {...a11yProps(3)} /> */}

            </Tabs>

            <TabPanel value={value} index={0} className="h-100">
                <SummaryDashboard />
            </TabPanel>
            <TabPanel value={value} index={1} className="h-100">
                <GoalsDashboard />
            </TabPanel>
            {/* <TabPanel value={value} index={2} className="h-100">
                
            </TabPanel>
            <TabPanel value={value} index={3} className="h-100">

            </TabPanel>
            <TabPanel value={value} index={4} className="h-100">Task</TabPanel> */}

        </div>
    );
}
