import React, { Component } from "react";
// import $ from "jquery";
import {
    Dialog,
    IconButton,
    Button,
    Icon,
    Tooltip,
    Grid,
    DialogActions,
    FormControl,
    MenuItem,
    TextField,
    Checkbox,
    InputLabel,
    FormControlLabel,
    Grow,
    Select,
    ListSubheader,
    withStyles,
    CircularProgress
} from "@material-ui/core";

import { SingleSelect } from "react-select-material-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import { Redirect, Route } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import {
    ValidatorForm,
    TextValidator,
    SelectValidator,
} from "react-material-ui-form-validator";

import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import { lastDayOfDecade } from "date-fns";
import InputAdornment from "@material-ui/core/InputAdornment";
import moment from 'moment';

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.0rem",
    }
}))(Icon);

const TooltipNew = withStyles((theme) => ({
    tooltip: {
      fontSize: 14,
    },
  }))(Tooltip);

class ImportDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            filecode:'',
            fullfilename:'',
            type:'',
            afterSubmit:false,
            isLoading: false,
            dateformat: 'MM/DD/YYYY',
            timeZone: 'America/New_York',
        };
    }

    componentDidMount(){
        if (this.props.fullfilename){
            let fullfilename = this.props.fullfilename;
            let cropname = fullfilename.split('.')[0];
            let filenamedata = cropname.split('-');
            let type = filenamedata[0];
            this.setState({ fullfilename: fullfilename, type: filenamedata[0], name: type+' import', filecode: filenamedata[1]})
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })        
    }

    handleFormSubmit = () => {
        if (this.props.selectedFields && this.props.selectedFields.length > 0){
            let oldnewvalue = {};
            
            oldnewvalue.old = this.props.oldColumnValues;
            oldnewvalue.new = this.props.newColumnValues;
            
            
            let reqParams = {
                matchingstatus: this.props.data.state.checkbox_matching,
                matchingcolumn: this.props.data.state.drop_matching,
                matchingtype: this.props.data.state.radio_matching,
                record: this.state.type,
                filename: this.state.name,
                filecode: this.state.filecode,
                fullfilename: this.state.fullfilename,
                columnmapdata: this.props.selectedFields,
                oldnewvalue: oldnewvalue
            }
           
            console.log(reqParams,'reqParamsreqParams');
            
            this.props.importExcelData(this.props.apolloClient.client, reqParams);

            // setTimeout(() => {
            //     this.backToImport();
            // }, 4000);
            this.setState({ afterSubmit: true, isLoading: true });
        }
    }

    componentWillReceiveProps(props){
        if (props.settingReducer.importExcelData.status){
            this.setState({ isLoading : false });
        }

        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            let usersetting = props.settingReducer.userSettingArray.data;

            let format = usersetting.dateformat;
            let timezone = usersetting.timezone;
            this.setState({ dateformat: format, timeZone: timezone })
        }
        
    }

    backToImport = () => {
        this.props.backToImport()
        // this.props.history.push('/settings/import-data/');
    }

    render() {
        let currentDate = new Date();
        let dateofimport = moment(currentDate).format(this.state.dateformat) +' '+ moment(currentDate).format('HH:mm');
        let importby = JSON.parse(localStorage.getItem('user_data_session')).name;
        
        let { open, handleClose, handleMinimize, handleFull, fullScreen, minimizeScreen } = this.props;
        let onChangeFunction = this.onChange;
        return (
            <Dialog
                onClose={handleClose}
                open={open}
                maxWidth="md"
                fullWidth={true}
                className={`crm-custom-bg-primary custom_dialog ${
                    minimizeScreen && fullScreen ? "alignitemsend-fullscreen" : ""
                    } ${minimizeScreen ? "alignitemsend" : ""} ${
                    fullScreen ? "fullscreen" : ""
                    } crm_dialog_create_template`}
                disableBackdropClick
                // disableEscapeKeyDown
            >
                <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">
                    <h4 className="m-0 text-white">Import Data</h4>
                    <div className="d-flex">
                        <TooltipNew
                            title={minimizeScreen ? "Normal Screen" : "Minimize Screen"}
                        >
                            <IconButton onClick={handleMinimize}>
                                <Icon className="text-white">
                                    {minimizeScreen ? "add" : "remove"}
                                </Icon>
                            </IconButton>
                        </TooltipNew>
                        <TooltipNew title={fullScreen ? "Exit Full Screen" : "Full Screen"}>
                            <IconButton onClick={handleFull}>
                                <Icon className="text-white">
                                    {fullScreen ? "fullscreen_exit" : "fullscreen"}
                                </Icon>
                            </IconButton>
                        </TooltipNew>
                        <TooltipNew title="Close">
                            <IconButton onClick={handleClose}>
                                <Icon className="text-white">clear</Icon>
                            </IconButton>
                        </TooltipNew>
                    </div>
                </div>
                {!this.state.afterSubmit ?
                    <>
                        <div className={`${this.state.isDataLoading ? "dialog-body popupHeightCustom" : "dialog-body"}`} style={{ overflow: "visible" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} className="mt-20 mb-10">
                                    <label className="font-weight-500">Import Tag</label><br />
                                    <span className="text-hint font-size-10">Records created during this import will receive the following tag</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <label className="font-weight-500" style={{ fontSize: '12px' }}>Name</label>
                                    <TextField
                                        type="text"
                                        className="w-100 input_bx"
                                        onChange={(event) => this.handleChange(event)}
                                        name={"name"}
                                        placeholder="Add Template Name"
                                        value={this.state.name}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <label className="font-weight-500" style={{ fontSize: '12px' }}>ID</label><br />
                                    <span>-{this.state.filecode}</span>
                                </Grid>
                            </Grid>
                        </div>
                        <DialogActions className="dialog-action">
                            <Button variant="contained" onClick={handleClose}>Cancel</Button>
                            <Button onClick={this.handleFormSubmit} variant="contained" type="submit" color={this.state.isSubmitDisable ? "default" : "primary"}>Submit Import</Button>
                        </DialogActions>
                    </>
                    :
                    <>
                        {this.state.isLoading ?
                            <div className="text-center mt-30 mb-30">
                                <CircularProgress />
                                <h6 className="mt-20">Uploading Data</h6>
                            </div>
                            :
                            <>
                                <div className={`${this.state.isDataLoading ? "dialog-body popupHeightCustom" : "dialog-body"}`} style={{ overflow: "visible" }}>
                                    <span className="text-hint">Your data is being imported. We will send you an email when the import is complete. During peak times this can take up to a few hours.</span>
                                    <Grid container spacing={0}>
                                        <Grid item xs={2} className="mt-20 text-hint">Overview</Grid>
                                        <Grid item xs={10} className="mt-20"></Grid>
                                        
                                        <Grid item xs={2} className="m-0"><label className="font-weight-500">Import Tag</label></Grid>
                                        <Grid item xs={10} className="m-0"><label className="text-hint" style={{ fontSize: '12px' }}>{this.state.name + '-' + this.state.filecode}</label></Grid>
                                        
                                        <Grid item xs={2} className="m-0"><label className="font-weight-500">Imported On</label></Grid>
                                        <Grid item xs={10} className="m-0"><label className="text-hint" style={{ fontSize: '12px' }}>{dateofimport}</label></Grid>
                                        
                                        <Grid item xs={2} className="m-0"><label className="font-weight-500">Imported By</label></Grid>
                                        <Grid item xs={10} className="m-0"><label className="text-hint" style={{ fontSize: '12px' }}>{importby}</label></Grid>
                                    </Grid>
                                    <div className="text-hint font-weight-500 mt-20"><Icon style={{fontSize:'12px'}}>autorenew</Icon>In Progress</div>
                                </div>
                                <DialogActions className="dialog-action">
                                    <Button variant="contained" color={"primary"} onClick={this.backToImport}>Ok</Button>                                    
                                </DialogActions>
                            </>
                        } 
                    </>
                }
            </Dialog>
        );
    }
}
const mapStateToProps = (state) => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
});
const mapDispatchToProps = (dispatch) => {
    return {
        importExcelData: (client, req) => { dispatch(actions.importExcelData(client, req)) },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ImportDialog);
