import { Icon } from '@material-ui/core';
import React from 'react';

export default function LoadingState(props) {
  return (
    <td colSpan={props.columnCount} className="isTableEmpty">
      {!props.loading &&
        <div className="list_empty_crm">
          <div className="noListIcon">
            <Icon>{props.icon}</Icon>
          </div>
          <div className="noListTitle">No {props.componetname} Added Yet</div>
          <div className="text">Add a {props.componetname}   by clicking the "Create New" button above</div>
        </div>
      }
    </td>
  );
}
