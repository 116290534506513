import React, { Component } from "react";
import {
  Fab, Grid, withStyles, Button, IconButton,
  Icon, MenuItem, Card, Dialog,
  DialogActions, DialogTitle
} from "@material-ui/core";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { withRouter } from "react-router-dom";
import moment from 'moment';
import DropdownDefault from "../Layout/DropdownDefault";

import DropboxChooser from 'react-dropbox-chooser';
import GooglePicker from 'react-google-picker';

const APP_KEY = 'dqsfbf3twmx7g7z';
// const CLIENTID = "161942936141-fsq8al02npjinbf8vm1rr1lop0takre0.apps.googleusercontent.com";
const CLIENTID = "965427486424-jsdger0lt68ncnd49jki1ojpjkaemndc.apps.googleusercontent.com";
const DEVLOPERKEY = "AIzaSyA8whoR2ER3oOfYroEKTPtmC5_cGqVkX3I";
const SCOPE = "https://www.googleapis.com/auth/drive.file";
const APPID = "crmlikecopper";

const IconSmall = withStyles(() => ({
  root: {
    fontSize: "1.5rem",
    color: 'rgba(0, 0, 0, 0.6)'
  }
}))(Icon);


class Task extends Component {
  state = {
    attachment: null,
    showAddFiles: false,
    file_id: "",
    fileListArray: [],
    removeFilePopup: false,
    shouldOpenCreateTaskDialog: false,
    type: "files",
    recordtype: "",
    dateformat: 'MM/DD/YYYY',
    timeZone: 'America/New_York',
    files: [],
    displayLength: 0,
    itegrationDetails: [],
    hasGoggleDriveConnect: false,
    hasDropBoxConnect: false,
  };

  componentWillUnmount() {
    this.props.settingReducer.relatedDataFile = [];
  }

  componentDidMount() {
    let recortypedid = "";
    if (this.props.recordtype == "lead") {
      recortypedid = 1
    }
    if (this.props.recordtype == "contact") {
      recortypedid = 2
    }
    if (this.props.recordtype == "company") {
      recortypedid = 3
    }
    if (this.props.recordtype == "opportunities") {
      recortypedid = 4
    }
    if (this.props.recordtype == "project") {
      recortypedid = 5
    }

    if (this.props.recordId) {
      this.setState({
        recordId: this.props.recordId,
        recordtype: this.props.recordtype,
        recortypedid: recortypedid,
      }, () => {
        this.forceUpdate()
      });
      if (this.props.recordId) {
        var req = {
          id: this.props.recordId,
          type: this.state.type,
          recordtype: this.props.recordtype,
        }
        this.props.getRecordRelatedData(this.props.apolloClient.client, req);
      }
    }
    this.props.getUserIntegration(this.props.apolloClient.client);
  }


  componentWillReceiveProps(props) {

    if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
      let usersetting = props.settingReducer.userSettingArray.data;

      let format = usersetting.dateformat;
      let timezone = usersetting.timezone;

      this.setState({ dateformat: format, timeZone: timezone })
    }

    if (props.recordId != this.state.recordId) {
      this.setState({
        recordId: props.recordId,
        recordtype: props.recordtype,
      }, () => {
        this.forceUpdate()
        var req = {
          id: props.recordId,
          type: this.state.type,
          recordtype: props.recordtype,
        }
        this.props.getRecordRelatedData(this.props.apolloClient.client, req);
      });
    }

    if (props.settingReducer.relatedDataFile.data) {
      props.settingReducer.relatedDataFile.is_loaded = false;
      let ArryData = props.settingReducer.relatedDataFile.data.data;
      let Displaylength = props.settingReducer.relatedDataFile.data.data.length;
      this.setState({
        fileListArray: ArryData,
        displayLength: Displaylength,
      });
      if (Displaylength > 0) {
        this.setState({ isOpenedFiles: true });
      } else {
        this.setState({ isOpenedFiles: false });
      }
    } else {
      this.setState({
        fileListArray: [],
        isOpenedFiles: false
      }, () => this.forceUpdate());
    }

    if (props.settingReducer.userItegration.data && props.settingReducer.userItegration.data !== this.state.itegrationDetails) {
      let data = props.settingReducer.userItegration.data;
      var hasGoggleDriveConnect = false;
      var hasDropBoxConnect = false;
      data.map((item) => {
        if (item.status && item.integrationid == 4) {
          hasGoggleDriveConnect = true;
        }
        if (item.status && item.integrationid == 3) {
          hasDropBoxConnect = true;
        }
      });
      this.setState({
        itegrationDetails: data,
        hasGoggleDriveConnect: hasGoggleDriveConnect,
        hasDropBoxConnect: hasDropBoxConnect,
      });
    }
  }

  toggleFiles = () => {
    this.setState(oldStateProject => ({ isOpenedFiles: !oldStateProject.isOpenedFiles }));
    this.setState({
      showAddFiles: true
    }, () => this.forceUpdate());

  };

  handleRemoveTask = (file_id) => {
    this.setState({
      removeFilePopup: true,
      fileId: file_id,
    }, () => {
      this.forceUpdate()
    })

  };

  toggleRemoveFilePopup = () => {
    if (this.state.removeFilePopup) {
      this.setState({
        fileId: "",
      }, () => {
        this.forceUpdate()
      })

    }
    this.setState({
      removeFilePopup: !this.state.removeFilePopup,
    }, () => {
      this.forceUpdate()
    })

  };

  confirmremoveFilePopup = () => {
    if (this.state.fileId) {
      var data = {
        recordtype: this.state.recordtype,
        type: this.state.type,
        id: parseInt(this.state.recordId),
      }

      var req = {
        fileid: parseInt(this.state.fileId),
        getParams: data,
      }
      this.props.deleteRelatedFiles(this.props.apolloClient.client, req);

      this.setState({
        fileId: "",
        removeFilePopup: false,
        displayLength: this.state.displayLength - 1
      }, () => {
        this.forceUpdate()
      })
    }

  };

  handleAttachmentSelection = (event) => {
    if (event.target.files[0] == undefined) {
      return false
    }
    let data = {
      recordtype: this.state.recordtype,
      type: this.state.type,
      id: parseInt(this.state.recordId),
    }

    let req = {
      recordid: parseInt(this.state.recordId),
      recortypedid: this.state.recortypedid,
      fileInput: event.target.files[0],
      getParams: data,
    }
    console.log(req, 'req');
    this.props.saveRelatedFiles(this.props.apolloClient.client, req);
  };

  handleDropBox = (files) => {
    console.log('files', files);
    let filename = [];
    let fileurl = [];

    files.map((file) => {
      filename = file.name;
      fileurl = file.link;

      let data = {
        recordtype: this.state.recordtype,
        type: this.state.type,
        id: parseInt(this.state.recordId),
      }

      let req = {
        recordid: parseInt(this.state.recordId),
        recortypedid: this.state.recortypedid,
        filename: filename,
        dropboxlink: fileurl,
        getParams: data,
      }
      this.props.saveDropboxRelatedFiles(this.props.apolloClient.client, req);
    });

  };

  pickerCallback = (filedata) => {
    console.log('filedata::', filedata);
    if (filedata.action === "picked") {
      filedata.docs.map((file) => {
        let filename = file.name;
        let googlefilelink = file.url;
        let googlefileid = file.id;
        let data = {
          recordtype: this.state.recordtype,
          type: this.state.type,
          id: parseInt(this.state.recordId),
        }
        let req = {
          recordid: parseInt(this.state.recordId),
          recortypedid: this.state.recortypedid,
          filename: filename,
          googlefilelink: googlefilelink,
          googlefileid: googlefileid,
          getParams: data,
        }
        this.props.saveGoogleRelatedFiles(this.props.apolloClient.client, req);
      });

    }
  };

  render() {
    let { isOpenedFiles, fileListArray, hasGoggleDriveConnect, hasDropBoxConnect } = this.state;
    let GoggleDrivePicker = [];
    let DropBoxPicker = [];

    GoggleDrivePicker.push(
      <GooglePicker
        clientId={CLIENTID}
        developerKey={DEVLOPERKEY}
        // scope={SCOPE}
        multiselect={true}
        navHidden={true}
        authImmediate={false}

        createPicker={(google, oauthToken) => {
          const picker = new window.google.picker.PickerBuilder()
            .addView(new google.picker.DocsView().setParent('root').setIncludeFolders(true))
            .enableFeature(google.picker.Feature.NAV_HIDDEN)
            .addView(google.picker.ViewId.FOLDERS)
            .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
            .setAppId(APPID)
            .setDeveloperKey(DEVLOPERKEY)
            .setOAuthToken(oauthToken)
            .setCallback((data) => {
              this.pickerCallback(data);
            })
            .build();
          picker.setVisible(true);
        }}
      >
        <div className="google"><MenuItem>Link to Google Drive</MenuItem></div>
      </GooglePicker>
    );

    DropBoxPicker.push(
      <DropboxChooser
        appKey={APP_KEY}
        success={files => this.handleDropBox(files)}
        multiselect={true} >
        <div className="dropbox">
          <MenuItem>
            <div>Link to Dropbox</div>
          </MenuItem>
        </div>
      </DropboxChooser>
    );

    /*STRAT project Display*/
    let displayFileList = [];
    {
      fileListArray.map((item, key) => {
        var date = moment.unix(item.createddate).tz(this.state.timeZone).format(this.state.dateformat)
        if (item) {
          var FileName = item.filename;
          let url = "";

          if (item.filekey) {
            url = item.filekey;
          } else if (item.dropboxfile) {
            url = item.dropboxfile;
          } else if (item.googlefile) { url = item.googlefile; }

          displayFileList.push(
            <div className="box_portlet mt-10 crm-calendar-event" key={key}>
              <div className="box_body px-10 py-20">
                <div className="lead_pl" style={{ paddingLeft: '50px' }}>
                  <div className="icon_left">
                    <Fab
                      size="small"
                      aria-label="Task"
                      className={`wrap_icons_task_sidebar vert-middle bg-grey_sidebar`}
                      style={{ marginTop : '-5px' }}
                    >
                      <Icon className="activity_icon_normal">attachment</Icon>
                    </Fab>
                  </div>
                  <a href={url} target="_blank" className={"cursor_poiter"}>
                    <Grid container justify="space-between">
                      <h6 className="m-0 text_ellipsis" style={{ width: '100%' }}>{FileName}</h6>
                    </Grid>
                  </a>
                  <span className="related-text">{"Added by"} {item.__user__.name} on {date}</span>
                  <div className="hover_show" style={{ top: '-21px', right: '-7px' }}>
                    <IconButton aria-label="Delete" size="small" onClick={() => this.handleRemoveTask(item.id)}>
                      <IconSmall style={{ fontSize: '18px' }}>delete</IconSmall>
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>

          )
        }

      })
    }
    /*END project Display*/

    return (

      <Card elevation={3} className="p-16 mt-16" style={{ overflow: 'initial' }}>
        <Grid container justify="space-between">
          <h5 className="flex-auto cursor_poiter mb-0 pt-4" onClick={this.toggleFiles}>Files ({this.state.displayLength}) <Icon className="vert-middle">{isOpenedFiles ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon></h5>
          <DropdownDefault
            menuButton={
              <IconButton
                aria-haspopup="true"
                aria-label="Add" size="small"
              >
                <Icon>add</Icon>
              </IconButton>
            }
          >
            <MenuItem onClick={() => this.fileInput.click()} >Upload from My Computer</MenuItem>
            {hasGoggleDriveConnect ?
              GoggleDrivePicker
              : ""}

            {hasDropBoxConnect ?
              DropBoxPicker
              : ""
            }
          </DropdownDefault>
          <input style={{ display: 'none' }} type="file" onChange={this.handleAttachmentSelection}
            ref={fileInput => this.fileInput = fileInput}
          />
        </Grid>
        {isOpenedFiles &&
          <>
            {(displayFileList.length == 0) &&
              <div className="mt-10">
                {this.state.hasGoggleDriveConnect === true || this.state.hasDropBoxConnect === true ?
                  <>
                    <DropdownDefault
                      menuButton={
                        <label htmlFor="attachment" className="text-hint">
                          <IconButton className="mr-8" component="span">
                            <Icon>attachment</Icon>
                          </IconButton>Add Attachment</label>
                      }
                    >
                      <MenuItem onClick={() => this.fileInput.click()} >Upload from My Computer</MenuItem>
                      {hasGoggleDriveConnect === true ?
                        GoggleDrivePicker
                        : ""}

                      {hasDropBoxConnect === true ?
                        DropBoxPicker
                        : ""
                      }
                    </DropdownDefault>
                    <input style={{ display: 'none' }} type="file" onChange={this.handleAttachmentSelection}
                      ref={fileInput => this.fileInput = fileInput}
                    />
                  </>
                  :
                  <label htmlFor="attachment" className="text-hint">
                    <IconButton className="mr-8" component="span">
                      <Icon>attachment</Icon>
                    </IconButton>Add Attachment</label>
                }
              </div>
            }
            {displayFileList}

          </>
        }

        <Dialog
          fullWidth={true}
          maxWidth={'xs'}
          open={this.state.removeFilePopup}
          onClose={this.toggleRemoveFilePopup}
          disableBackdropClick

          aria-labelledby="ar-remove-dialog-title"
        >
          <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to remove the Associated File?"}</DialogTitle>
          <DialogActions>
            <Button onClick={this.toggleRemoveFilePopup} color="primary">
              Cancel
              </Button>
            <Button type="Button" onClick={() => this.confirmremoveFilePopup()} className="btn_Primary">
              Ok
              </Button>
          </DialogActions>
        </Dialog>

      </Card>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
});

const mapDispatchToProps = dispatch => {
  return {
    getRecordRelatedData: (client, req) => { dispatch(actionCreators.getRecordRelatedData(client, req)) },
    saveRelatedFiles: (client, req) => { dispatch(actionCreators.saveRelatedFiles(client, req)) },
    saveDropboxRelatedFiles: (client, req) => { dispatch(actionCreators.saveDropboxRelatedFiles(client, req)) },
    saveGoogleRelatedFiles: (client, req) => { dispatch(actionCreators.saveGoogleRelatedFiles(client, req)) },
    deleteRelatedFiles: (client, req) => { dispatch(actionCreators.deleteRelatedFiles(client, req)) },
    getUserIntegration: (client) => dispatch(actionCreators.getUserIntegration(client)),
  }
}


export default (connect(mapStateToProps, mapDispatchToProps)(withRouter(Task)));
