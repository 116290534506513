
import React, { Component } from 'react';
import { InputLabel, Snackbar,Menu, CircularProgress, Box, Fab, Divider, DialogContent, Grid, Button, withStyles, ListItemText, IconButton, Icon, MenuItem, TextField, Dialog, DialogActions, DialogTitle, Avatar, Typography, List, Badge, Card } from "@material-ui/core";
import * as actions from "../../store/actions/index"
import { connect } from "react-redux";
import { compose } from 'redux';
import moment from 'moment';//moment
import 'moment-timezone';//timezone
import PropTypes from 'prop-types';
import TrackDataPopover from "./TrackDataPopover"
import DraftsIcon from '@material-ui/icons/Drafts';
import ViewHtmlDialog from "./ViewHtmlDialog";
import InboxCompose from "../CommonComposeMail/InboxCompose";
import { Link } from 'react-router-dom';
import { divProperties } from 'office-ui-fabric-react';
import FilterLogoCommon from "../CommonLogUI/FilterLogoCommon";
const IconSmall = withStyles(() => ({
  root: {
    fontSize: "1.3rem",
    color: 'rgba(0, 0, 0, 0.6)'
  }
}))(Icon);

const IconSmaller = withStyles(() => ({
  root: {
    fontSize: "1.0rem",
    color: 'rgba(0, 0, 0, 0.6)'
  }
}))(Icon);
class TrackedEmailList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateformat: 'MM/DD/YYYY',
      timeZone: 'America/New_York',
      allLogs: [],
      isOpenedEmailIds: [],
      TabValue: 0,
      isopenTrackdetails: false,
      logid: 0,
      isDataLoading: true,
      shouldOpenViewEmailDialog: false,
      emaildata: "",
      hasMore: false,
      totalPages: 0,
      currentPage: 1,
      limit: 10
    };
  }

  componentWillMount() {
    var request = {
      limit: this.state.limit,
      pageno: this.state.currentPage,
    }
    this.props.getEmailTrackingList(this.props.apolloClient.client, request)
  }
  componentWillReceiveProps(props) {
    let source = "";
    if (props.settingReducer.TrackedEmailList.data) {
      let mainData = props.settingReducer.TrackedEmailList.data
      let logdata = [];
      let totalpage = mainData.totalpage;
      let pined = mainData.pined;
      let today = mainData.today;
      let yesterday = mainData.yesterday;
      let thisweek = mainData.thisweek;
      let lastweek = mainData.lastweek;
      let thismonth = mainData.thismonth;
      let lastmonth = mainData.lastmonth;
      let earlier = mainData.earlier;

      logdata.push(this.createLogArray(source, pined, 'Pinned'));
      logdata.push(this.createLogArray(source, today, 'Today'));
      logdata.push(this.createLogArray(source, yesterday, 'Yesterday'));
      logdata.push(this.createLogArray(source, thisweek, 'This week'));
      logdata.push(this.createLogArray(source, lastweek, 'Last week'));
      logdata.push(this.createLogArray(source, thismonth, 'This month'));
      logdata.push(this.createLogArray(source, lastmonth, 'Last month'));
      logdata.push(this.createLogArray(source, earlier, 'Earlier'));

      if (this.state.currentPage === 1) {
        this.setState({
          allLogs: logdata,
          isDataLoading: false,
          hasMore: totalpage > this.state.currentPage ? true : false,
          totalPages: totalpage
        }, () => this.forceUpdate())
      } else {
        this.AddNewData(logdata)
        this.setState({
          hasMore: totalpage > this.state.currentPage ? true : false,
          // allLogs: [...this.state.allLogs, ...logdata],
          isDataLoading: false,
        }, () => this.forceUpdate())
      }
    } else {
      setTimeout(() => {
        this.setState({ isDataLoading: false, });
      }, 1500);
    }
    if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
      let usersetting = props.settingReducer.userSettingArray.data;
      this.setState({ dateformat: usersetting.dateformat, timeZone: usersetting.timezone })
    }
  }
  toggleEmail = (id) => {
    let isOpenedEmailIds = this.state.isOpenedEmailIds;
    if (isOpenedEmailIds.indexOf(id) == -1) {
      isOpenedEmailIds.push(id);
    } else {
      let index = isOpenedEmailIds.indexOf(id);
      isOpenedEmailIds.splice(index, 1);
    }
    this.setState(({ logid: id, isOpenedEmailIds: isOpenedEmailIds, isopenTrackdetails: false }));
  }
  handleOpenTrackDetails = (id) => {
    this.setState({ isopenTrackdetails: true, logid: id })
  }
  handleCloseTrackDetails = () => {
    this.setState({ isopenTrackdetails: false })
  }
  handleViewEmailDialogClose = () => {
    this.setState({ shouldOpenViewEmailDialog: false })
  }
  handleReplayMail = (log) => {
    this.setState({
      isOpenedReplayEmailLog: true, logid: log.logId,
      emaildata: log
    });
  }
  toggleReplayEmailLog = () => {
    this.setState({ isOpenedReplayEmailLog: false });
  }
  createLogArray = (source, partData, partKey) => {
    let logData = [];
    if (partData && partData.length > 0) {
      var user_data = JSON.parse(localStorage.getItem('user_data_session'))
      partData.map((data) => {
        let create_date = data.email_date_time
        logData.push({
          logId: data.id,
          thread_id: data.thread_id,
          trackingList: data.tracking_data,
          trackIcon: data.tracking_data.length > 0 ? <DraftsIcon /> : <Icon>mail</Icon>,
          trackClass: data.tracking_data.length > 0 ? "track_icon" : "track_icon_unread",
          track_count: data.tracking_data ? data.tracking_data.length > 9 ? "9+" : data.tracking_data.length : 0,
          email: data.email_data[0].email,
          email_data: data.email_data,
          body_html: data.body_html,
          subject: data.subject,
          attachment: data.attachment,
          snippet: data.snippet,
          colorClass: user_data && user_data.name ? user_data.name.charAt(0).toLowerCase() : "",
          time: moment(create_date).tz(this.state.timeZone).format('hh:mm A'),
          date: moment(create_date).tz(this.state.timeZone).format(this.state.dateformat)
        });
      });
    }
    return { key: partKey, datas: logData };
  }
  fetchMoreData = () => {
    // e.preventDefault();
    if (this.state.currentPage >= this.state.totalPages) {
      this.setState({ hasMore: false });
      return;
    }
    this.getNextPageData(this.state.currentPage + 1)
  };
  getNextPageData = (page) => {
    this.setState({
      currentPage: page, isDataLoading: true
    }, () => {
      this.forceUpdate()
      var request = {
        limit: this.state.limit,
        pageno: page,
      }
      this.props.getEmailTrackingList(this.props.apolloClient.client, request)
    })
  }
  AddNewData = (logdata) => {
    var okData = [...this.state.allLogs]
    for (var i = 0; i < logdata.length; i++) {
      if (logdata[i].key == okData[i].key) {
        okData[i].datas = [...okData[i].datas, ...logdata[i].datas]
      }
    }
    console.log(okData);
  }
  handleDownloadClick = (event) => {
    this.setState({ Downloadoptions: event.target, showDownloadoptions: true });
  }
  handleCloseDownload = (event) => {
    this.setState({ Downloadoptions: null, showDownloadoptions: false });
  }
  render() {
    let { Downloadoptions, showDownloadoptions } = this.state;

    let { isOpenedEmailIds, isDataLoading, allLogs, isOpenedReplayEmailLog, isopenTrackdetails, logid, emaildata } = this.state;
    const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
    let loghtml = [];
    let logbody = [];
    allLogs.map((logdata, index) => {
      logbody = [];
      if (logdata.datas.length > 0) {
        console.log(logdata.key)
        logbody.push(
          <FilterLogoCommon headerKey={logdata.key} />
        );
        logdata.datas.map((log, i) => {
          logbody.push(
            <div className="box_body" style={{ borderBottom: "1px solid #ebedf2" }} key={i}>
              {
                isopenTrackdetails && logid === log.logId &&
                <TrackDataPopover
                  tracking_data={log.trackingList}
                  handleCloseTrackDetails={this.handleCloseTrackDetails}
                  dateformat={this.state.dateformat}
                  timeZone={this.state.timeZone}
                  threadId={log.thread_id}
                />
              }
              <div className="lead_pl p-0">
                <Grid container>
                  <Grid item md={1}>
                    <Fab className="small-size overflow-hidden mr-8" color="secondary" size="small" style={{ height: "35px", width: "35px" }}>
                      <h5 style={{ fontSize: "16px" }}
                        className={"text-white m-0 font-weight-normal avatar_circle color_" + log.colorClass}>{log.colorClass.toUpperCase()}
                      </h5>
                    </Fab>
                    <div className={log.trackClass}
                      onMouseOver={() => { this.handleOpenTrackDetails(log.logId) }}>
                      {log.trackIcon}
                      <span className="track_count">{log.track_count}</span>
                    </div>
                  </Grid>
                  <Grid item md={11}>
                    <Grid container justify="space-between">
                      <Grid onClick={(id) => this.toggleEmail(log.logId)}
                        className="flex-auto cursor_poiter">
                        <h5 className="mb-0">
                          You {" "}to <Link className="text_link font-size-16" to={"ffff"}>{log.email}</Link>
                          {log.email_data.length > 2 && " and " + ((log.email_data.length) - 1) + " others"}
                        </h5>
                        <p className="mt-4 mb-4 font-size-13 text-hint">{log.date},{log.time}</p>
                      </Grid>
                      <div className="action_menu hover_show">
                        < IconButton aria-label="Reply All" size="small"
                          onClick={() => this.handleReplayMail(log)}>
                          <IconSmall>{log.email_data.length > 2 ? 'reply_all' : 'reply'}</IconSmall>
                        </IconButton>
                        <IconButton aria-label="View HTML Version" size="small" onClick=
                          {() => this.setState({ shouldOpenViewEmailDialog: true, viewHtmlData: log })}>
                          <IconSmall>launch</IconSmall>
                        </IconButton>
                        <IconButton aria-label="View HTML Version" size="small">
                          <IconSmall>delete</IconSmall>
                        </IconButton>
                        <IconButton aria-label="View HTML Version" size="small">
                          <IconSmall>lock</IconSmall>
                        </IconButton>
                      </div>
                    </Grid>
                    {(isOpenedEmailIds.indexOf(log.logId) == -1) ?
                      <div className="mt-0 font-size-14">
                        <span className="font-weight-500">{log.subject}</span> - {renderHTML(log.snippet)}
                      </div>
                      :
                      <div className="border_bx">
                        <h6>{log.subject}</h6>
                        <div dangerouslySetInnerHTML={{ __html: log.body_html }} />
                      </div>
                    }
                    <div className="mt-20">
                      {(log.attachment && log.attachment.length > 0) &&
                        log.attachment.map((attach) => (
                          <>
                            <Button onClick={this.handleDownloadClick} className="crm-email-log-attachment" variant="contained">
                              <IconButton size="small">
                                <IconSmaller>attach_file</IconSmaller>
                              </IconButton>
                              {attach.file_name}
                              <IconButton size="small">
                                <IconSmaller>arrow_drop_down</IconSmaller>
                              </IconButton>
                            </Button>
                            <Menu
                              id="long-menu"
                              anchorEl={Downloadoptions}
                              open={showDownloadoptions}
                              onClose={this.handleCloseDownload}
                            >
                              <MenuItem onClick={this.handleCloseDownload}>
                                <a href={attach.s3_file_name} target="__blank" className="text-center">
                                  Download
                                                  </a>
                              </MenuItem>
                              <MenuItem onClick={this.handleCloseDownload}>
                                <span className="font-size-13 mr-10 mb-5">Relate To Lead</span>
                              </MenuItem>
                            </Menu>
                          </>
                        ))
                      }
                    </div>
                    {isOpenedReplayEmailLog && logid === log.logId
                      &&
                      <InboxCompose
                        emaildata={emaildata}
                        handleClose={this.toggleReplayEmailLog}
                        LogDetails={""}
                        LogComponent={"Track"}
                      />
                    }
                  </Grid>
                </Grid>
              </div>
            </div >
          )
        })

        if (logbody && logbody.length > 0) {
          loghtml.push(logbody);
        }
      }
    })
    return (
      <div >
        <Typography className="card-margin-leadstatus p-10" variant="h5" color="inherit" style={{ marginLeft: "10%" }}>
          My Tracked Emails
           </Typography>

        <Card elevation={3} className="mt-16 tab_card" style={{ margin: "0 10%" }}>
          <div className="v_scroll position-relative h-100">
            <div className="sidebar_activity">
              {
                (loghtml.length == 0) ?
                  <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: '40vh' }}
                  >
                    <Grid item xs={3}><h3 className="text-hint">No Tracked Email found</h3></Grid>
                  </Grid>
                  :
                  <>
                    {loghtml.map((day_log, t) => (
                      <div className="lead_upcoming" key={t}>
                        <div className="box_portlet">
                          {day_log}
                        </div>
                      </div>
                    ))
                    }
                    {
                      isDataLoading ?
                        <Grid
                          container
                          spacing={0}
                          direction="column"
                          alignItems="center"
                          justify="center"
                          style={{ minHeight: '10vh', backgroundColor: 'rgba(0,0,0,0)' }}
                        >
                          <Grid item xs={3}>
                            <CircularProgress disableShrink size={25} />
                          </Grid>
                        </Grid>
                        :
                        this.state.hasMore &&
                        < Grid container item justify="center" className="m-20">
                          <Fab
                            variant="extended"
                            size="small"
                            color="primary"
                            onClick={this.fetchMoreData}
                            aria-label="add"
                            className="capitalize"
                          >
                            <Icon >expand_more</Icon>
                            See more</Fab>
                        </Grid>
                    }
                  </>
              }
            </div>
          </div>
        </Card>
        {this.state.shouldOpenViewEmailDialog && (
          <ViewHtmlDialog
            handleClose={this.handleViewEmailDialogClose}
            open={this.state.shouldOpenViewEmailDialog}
            logDatas={this.state.viewHtmlData}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getEmailTrackingList: (client, req) => { dispatch(actions.getEmailTrackingList(client, req)) },
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(TrackedEmailList);


// No Tracked Emails
// Copper can track when people open emails you send them. Install the Chrome Extension and check the track email box in the Gmail compose window to start tracking email opens. Learn more