import React, { Component } from "react";
import {  IconButton,TextField, Button, Card,Typography,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Paper,Menu
} from "@material-ui/core";
import "date-fns";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import EditIcon from "@material-ui/icons/Edit";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CurrencyEditDialoge from "./CurrencyEditDialoge"

class Currency extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currencyList:[],
            defaultCurrencyObj:{},
            anchorEl:null,
            companyCurrencyData:[],
            FullDialog: false,
            minimizeDialog: false,
            shouldOpenDialog: false,
        };
    }
    


    componentDidMount() {
        this.props.loadCurrencyList(this.props.apolloClient.client);
        this.props.getCompanyCurrency(this.props.apolloClient.client);
    }

    componentWillReceiveProps(props){
        if (props.settingReducer.companyCurrencyData.data != null) {
            var cData = props.settingReducer.companyCurrencyData.data;
            this.setState({defaultCurrencyObj:cData[0].detail,companyCurrencyData:cData[0]});
        }
        if (props.settingReducer.currencyList.data != null) {
            var dataList = props.settingReducer.currencyList.data
            this.setState({ currencyList: dataList});
        }
    }
    
    findWithAttr = (array, attr, value) => {
        for(var i = 0; i < array.length; i += 1) {
            if(array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }

    handleChange = event => {
        event.persist();
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeSearch = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleCurrencySelectChange = (event, values) => {
        if(values){
            this.state.companyCurrencyData.detail = values;
            this.setState({defaultCurrencyObj:this.state.companyCurrencyData.detail});
            var updateData = this.state.companyCurrencyData;
            updateData.currencyid = values.id;
            updateData.symbol = values.symbol;
            delete updateData.detail;
            this.props.updateCurrencyData(this.props.apolloClient.client,updateData);
        }
        this.setState({anchorEl :null});
    }

    handleClick = event => {
        this.setState({
           anchorEl :event.currentTarget
        })
        setTimeout(function() {
            document.getElementById("size-small-standard").focus();
        },200);
    };

    handleClose = () => {
        this.setState({
           anchorEl :null
        })
    };

    handleEditCurrencyDialogOpen = () => {
        this.setState({ shouldOpenDialog: false, FullDialog: false, minimizeDialog: false });
    };

    handleDialogMinimize = () => {
        this.setState(oldStateminimizeDialog => ({ minimizeDialog: !oldStateminimizeDialog.minimizeDialog }));
        this.setState({ FullDialog: false })
    };
    handleDialogFull = () => {
        this.setState(oldStateFullDialog => ({ FullDialog: !oldStateFullDialog.FullDialog }));
        this.setState({ minimizeDialog: false })

    };

    render() {
        const {anchorEl} = this.state;
        return (
            <div>
                <Typography className="card-margin-leadstatus" variant="h5" color="inherit">Currency Settings</Typography>
                <Card className="signup-card">
                    <TableContainer component={Paper}>
                      <Table  aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center" style={{width:'10%'}}>ISO Code</TableCell>
                            <TableCell align="center" style={{width:'45%'}}>Name</TableCell>
                            <TableCell align="center">Symbol</TableCell>
                            <TableCell align="center">Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                              {this.state.defaultCurrencyObj && <TableCell align="center" padding="none">{this.state.defaultCurrencyObj.code}</TableCell>}
                              <TableCell padding="none" align="center">
                              <div>
                                  {this.state.defaultCurrencyObj && <Button aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick}>
                                    {this.state.defaultCurrencyObj.name}
                                  </Button>}
                                  <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={this.handleClose}
                                  >
                                      <Autocomplete
                                        
                                        style={{width:'250px'}}
                                        id="size-small-standard"
                                        onChange={this.handleCurrencySelectChange}
                                        options={this.state.currencyList}
                                        getOptionLabel={option => option.code +' - '+ option.name}
                                        defaultValue={this.state.currencyList[this.state.selectedDefaultCurrency]}
                                        closeIcon={<span></span>}
                                        onClose={this.handleDropdownClose}
                                        renderInput={params => (
                                          <TextField
                                            {...params}
                                            variant="standard"
                                            fullWidth
                                            placeholder="Search"
                                          />
                                        )}
                                      />
                                  </Menu>
                                </div>
                                
                              </TableCell>
                              {this.state.defaultCurrencyObj && <TableCell align="center" padding="none">{this.state.companyCurrencyData.symbol}</TableCell>}
                              <TableCell align="center" padding="none">
                                <IconButton onClick={() => this.setState({ shouldOpenDialog: true })}><EditIcon/></IconButton>
                              </TableCell>
                            </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {this.state.shouldOpenDialog && (
                        <CurrencyEditDialoge
                            handleClose={this.handleEditCurrencyDialogOpen}
                            open={this.state.shouldOpenDialog}
                            handleMinimize={this.handleDialogMinimize}
                            handleFull={this.handleDialogFull}
                            minimizeScreen={this.state.minimizeDialog}
                            fullScreen={this.state.FullDialog}
                            currencyData = {this.state.companyCurrencyData}
                        />
                    )}
                </Card>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient
});


const mapDispatchToProps = dispatch => {
    return {
        loadCurrencyList: (client) => dispatch(actionCreators.loadCurrencyList(client)),
        getCompanyCurrency: (client) => dispatch(actionCreators.getCompanyCurrency(client)),
        updateCurrencyData: (client,data) => dispatch(actionCreators.updateCurrencyData(client,data)),
    }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Currency));
