import React, { Component } from "react";
import {
    Checkbox, IconButton, MenuItem, Grid, TableContainer, Card, Paper, Select,
    FormControl, InputLabel, Snackbar, Icon,
    Fade, CircularProgress, CardContent, Typography,
    Table, TableBody, TableCell,
    TableHead, TableRow, withStyles
} from "@material-ui/core";
import { connect } from "react-redux";
import * as actionCreators from '../../../store/actions/index';
import { Link } from "react-router-dom";

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.2rem",
        fontWeight: 500
    }
}))(Icon);
class QuickBookIntegrations extends Component {
    state = {
        selection: [],
        ticket_show_dropdown: '',
        integrationId: 8,
        isDataLoading: true,
        rows: [
            { id: 1, name: "onleads", label: "Lead" },
            { id: 2, name: "onopportunities", label: "Opportunities" },
            { id: 3, name: "oncompanies", label: "Companies" },
            { id: 4, name: "onpeople", label: "Contact" },
        ],
        data: "",
        onleads: "",
        onopportunities: "",
        oncompanies: "",
        onpeople: "",
        max_num_invoices: "",
        sort_invoices_by: ""

    };
    componentDidMount() {
        let val = this.state.integrationId;
        this.props.getIntegrationDetail(this.props.apolloClient.client, val);
    }
    componentWillReceiveProps(props) {
        if (props.settingReducer.getintegationDetails.data) {
            if (props.settingReducer.getintegationDetails.data !== this.state.data) {
                const data = props.settingReducer.getintegationDetails.data;
                this.setState({
                    isDataLoading: false,
                    data: data,
                    onleads: data.onleads,
                    oncompanies: data.oncompanies,
                    onopportunities: data.onopportunities,
                    onpeople: data.onpeople,
                    max_num_invoices: data.maxnumberrecords,
                    sort_invoices_by: data.sortby
                })
            }
        } else {
            setTimeout(() => {
                this.setState({ isDataLoading: false })
            }, 1000);
        }
    }

    updateSettings = () => {
        this.setState({ isDataLoading: true })
        let val = {
            integration: {
                integrationid: this.state.integrationId,
                oncompanies: this.state.oncompanies,
                onleads: this.state.onleads,
                onopportunities: this.state.onopportunities,
                onpeople: this.state.onpeople,
                onproject: false,
                maxnumberrecords: this.state.max_num_invoices !== "" ? parseFloat(this.state.max_num_invoices) : null,
                sortby: this.state.sort_invoices_by,
                status: true,
                email: this.state.data.email,
                domain: this.state.data.domain,
                accesstoken: this.state.data.accesstoken,
                refereshtoken: this.state.data.refereshtoken,
                ticketstatus: "",
            }
        }
        let data = [];
        data['data'] = val;
        let id = this.state.integrationId;
        let req = { data, id };
        this.props.saveIntegrationSetting(this.props.apolloClient.client, req);
    }
    handleCheckBoxChange = (event) => {
        console.log("event.target.name", event.target.name, event.target.type === "checkbox" ?
            event.target.checked : event.target.value)
        this.setState({
            [event.target.name]: event.target.type === "checkbox" ?
                event.target.checked
                :
                event.target.value
        }, () => this.updateSettings())
    }
    handleDrawerToggle = () => {
        window.location.href = '/settings/integrations';
    }

    render() {
        return (
            <React.Fragment >
                <div className="v_scrollI">
                    <div style={{ margin: '10px' }}>
                        <IconButton size="small" color="primary" onClick={this.handleDrawerToggle}>
                            <IconSmall>arrow_back</IconSmall>
                        </IconButton>
                        <div style={{ margin: '-22px 0 0 35px' }}>
                            <Link className="text-small text_link" style={{ color: '#2B41B2' }} to='/settings/integrations' >
                                Back to Integrations
                        </Link>
                        </div>
                        <Typography className="p-10" variant="h5" color="inherit" style={{ color: 'black' }}>QuickBook Integrations</Typography>
                        <Typography className="text-hint p-10" variant="p" color="inherit" style={{ color: 'black' }}>
                            Choose where you want to see QuickBooks invoices. For more information,  contact our &nbsp;
                            <span className="company_user_name" style={{ color: 'blue' }}>
                                <a target="_blank" href="http://idrivecrm.com/">
                                    support team
                                </a>
                            </span>
                        </Typography>
                    </div>
                    <div className="mt-20">
                        <Typography className="text-small-14 font-size-20 p-10" variant="p" color="inherit">
                            Enable QuickBooks Integration for records
                            </Typography>
                        <div style={{ margin: '14px' }} className="mt-20">
                            <Card className="signup-card">
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Record Type</TableCell>
                                                <TableCell>Show on Record</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.rows.map((row, i) => (
                                                <TableRow key={i}>
                                                    <TableCell component="th" scope="row">{row.label}</TableCell>
                                                    <TableCell>
                                                        <div style={{ margin: "0 0px 0px 30px" }}>
                                                            <Checkbox
                                                                checked={this.state[row.name]}
                                                                name={row.name}
                                                                onChange={(event) => this.handleCheckBoxChange(event)}
                                                                color="primary"
                                                            />
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </div>
                    </div>
                    <div className="IntegrationBlock_contents" >
                        <div className="zendesk_Integrations_card">
                            <CardContent>
                                <Grid item xs={5} style={{ padding: '20px' }}>
                                    <FormControl className="w-100 input_bx">
                                        <InputLabel>Choose the maximum number of invoices to show</InputLabel>
                                        <Select
                                            name="max_num_invoices"
                                            value={this.state.max_num_invoices}
                                            onChange={this.handleCheckBoxChange}
                                        >
                                            <MenuItem key={"5"} value={"5"}>5</MenuItem>
                                            <MenuItem key={"10"} value={"10"}>10</MenuItem>
                                            <MenuItem key={"15"} value={"15"}>15</MenuItem>
                                            <MenuItem key={"20"} value={"20"}>20</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={5} style={{ padding: '20px' }}>
                                    <FormControl className="w-100 input_bx">
                                        <InputLabel>Choose which field to sort invoices by</InputLabel>
                                        <Select
                                            name="sort_invoices_by"
                                            value={this.state.sort_invoices_by}
                                            onChange={this.handleCheckBoxChange}
                                        >
                                            <MenuItem key={"created_at"} value={"created_at"}>Created Date</MenuItem>
                                            <MenuItem key={"last_modified"} value={"last_modified"}>Last Modified Date</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </CardContent>
                        </div>
                    </div>
                </div>
                {/* </Grid> */}
                {this.state.isDataLoading &&
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        open={this.state.isDataLoading}
                        TransitionComponent={Fade}
                        message={<><CircularProgress size={20} style={{ color: 'white' }} /><span style={{ paddingLeft: '6px', color: 'white' }}>{" Loading..."}</span></>}
                        variant={'success'}
                    />
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        apolloClient: state.apolloClient,
        settingReducer: state.settingReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        saveIntegrationSetting: (client, req) => dispatch(actionCreators.saveIntegrationSetting(client, req)),
        getIntegrationDetail: (client, request) => dispatch(actionCreators.getIntegrationDetail(client, request)),
    }
}
export default (connect(mapStateToProps, mapDispatchToProps)(QuickBookIntegrations));
