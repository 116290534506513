import React, { Component } from "react";
import {
    Dialog, DialogActions, Table, withStyles,
    TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, Tooltip, IconButton, Icon, Grid, Select, MenuItem, Button
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from '../../../../store/actions/index';

const TooltipNew = withStyles((theme) => ({
    tooltip: {
        fontSize: 14,
    },
}))(Tooltip);

class SalesRevenueGoalDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userlist: 10,
            searchUsers: '',
            usersListArray: [],
            teamUsersListArray: [],
            selectedUserList: [],
            selectedUsers: "all-user",
            addedusers: [],
            goaltype: 0,
            datetype: 0,
            selectedPipeline: 0,
            teamList: [],
            goalList: [],
        };
    }

    componentWillMount() {
        this.props.getUserList(this.props.apolloClient.client);
    }

    componentWillReceiveProps(props) {
        if (props.settingReducer.goalList) {
            this.setState({ goallist: props.settingReducer.goalList });
        }

        if (props.data) {
            let datastate = props.data.state;

            
            if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0 && this.state.searchUsers == "") {
                let goallist = datastate.goalList;
                const usersListArray = props.settingReducer.usersListArray.data;

                goallist.map((goal) => {
                    let userkey = this.findWithAttr(usersListArray, 'id', goal.user.id);
                    if (userkey != -1) {
                        usersListArray.splice(userkey, 1);
                    }
                });
                
                

                this.setState({ usersListArray: usersListArray })
            }

            if( (props.settingReducer.user_filter_list_goal && props.settingReducer.user_filter_list_goal.data) || props.settingReducer.user_filter_list_goal.is_loaded){
                let goallist = datastate.goalList;
                const usersListArray = props.settingReducer.user_filter_list_goal.data;

                goallist.map((goal) => {
                    let userkey = this.findWithAttr(usersListArray, 'id', goal.user.id);
                    if (userkey != -1) {
                        usersListArray.splice(userkey, 1);
                    }
                });
                this.setState({ usersListArray: usersListArray })
                props.settingReducer.user_filter_list_goal.is_loaded = false;
            }

            if (props.settingReducer.teamMemberList && props.settingReducer.teamMemberList.data) {
                let goallist = datastate.goalList;
                const teamMemberList = props.settingReducer.teamMemberList.data;

                let memberlist = [];

                teamMemberList.map((teamuser) => {
                    memberlist.push(teamuser.user);
                })

                goallist.map((goal) => {
                    let userkey = this.findWithAttr(memberlist, 'id', goal.user.id);
                    if (userkey != -1) {
                        memberlist.splice(userkey, 1);
                    }
                });

                teamMemberList.map((teamuser, memberkey) => {
                    let newteamkey = this.findWithAttr(memberlist, 'id', teamuser.user.id);
                    if (newteamkey == -1) {
                        teamMemberList.splice(memberkey, 1);
                    }
                })

                this.setState({ teamUsersListArray: teamMemberList })
            }

            this.setState({ goaltype: datastate.goaltype, datetype: datastate.datetype, selectedPipeline: datastate.selectedPipeline, teamList: datastate.teamList, goalList: datastate.goalList })
        }
    }

    handleChange = event => {

        let selectedUsers = this.state.selectedUsers;

        if(selectedUsers == 'all-user'){
            let userfilterparam = {teamid: 0, name: event.target.value};
            this.props.userFilter(this.props.apolloClient.client, userfilterparam);
        }else{
            let userfilterparam = {teamid: parseFloat(selectedUsers), name: event.target.value};
            this.props.userFilter(this.props.apolloClient.client, userfilterparam);
        }
        
        
        // event.persist();
        this.setState({ [event.target.name]: event.target.value });
    };

    handleFormSubmit = () => {

        this.state.selectedUserList.map((selecteduser) => {

            let goalParams = {
                goalid: 0,
                goaltype: this.state.goaltype,
                datetype: this.state.datetype,
                userid: parseInt(selecteduser.id),
                teamid: (selecteduser.teamlist && selecteduser.teamlist.lenght > 0) ? parseInt(selecteduser.teamlist[0].team.id) : 0,
                pipline: [],
                previousdate: [],
                currentdate: [],
                nextdate: [],
                activity: [],
            };
            // console.log(goalParams,'goalParams');

            this.props.saveGoal(this.props.apolloClient.client, goalParams);
        })

        this.props.handleClose();
    };

    handleusercheck = (event) => {
        let userslist = this.state.usersListArray;
        let userkey = this.findWithAttr(userslist, 'id', event.target.value);
        let selectedUser = this.state.selectedUserList;
        let user = [];

        if (userkey != -1) {
            if (event.target.checked == true) {
                user = userslist[userkey];
                selectedUser.push(user);
            }
            if (event.target.checked == false) {
                selectedUser.splice(userkey, 1);
            }
        }

        this.setState({ selectedUserList: selectedUser })
    }

    handleChangeSelectedList = (event) => {
        let usersListArray = this.state.usersListArray;
        if (event.target.value == 'all-user') {
            this.setState({ selectedUsers: event.target.value });
        } else {
            let teammembersParams = { teamid: parseInt(event.target.value) };
            this.props.getTeamMember(this.props.apolloClient.client, teammembersParams);

            this.setState({ selectedUsers: event.target.value });
        }
    }

    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] == value) {
                return i;
            }
        }
        return -1;
    }

    render() {
        let { open, handleClose, handleMinimize, handleFull, fullScreen, minimizeScreen } = this.props;
        let { datetype, goaltype, teamUsersListArray, selectedUsers, addedusers, usersListArray, userlist, searchUsers, teamList } = this.state;
        let arrayofgoaltypes = ['Sales Revenue', 'Won Opportunities', 'Activities'];
        let arrayofdatetypes = ['Monthly', 'Quarterly', 'Yearly'];

        return (
            <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth={true} className={`crm-custom-bg-primary custom_dialog ${minimizeScreen && fullScreen ? 'alignitemsend-fullscreen' : ''} ${minimizeScreen ? 'alignitemsend' : ''} ${fullScreen ? 'fullscreen' : ''}`} disableBackdropClick
            //  disableEscapeKeyDown
             >
                <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">
                    <h4 className="p-8 m-0 text-white">Select The Users You'd Like to Create {arrayofdatetypes[datetype + -1]} {arrayofgoaltypes[goaltype - 1]} Goals For</h4>
                    <div className="d-flex">
                        <TooltipNew title={minimizeScreen ? 'Normal Screen' : 'Minimize Screen'}>
                            <IconButton onClick={handleMinimize}>
                                <Icon className="text-white">{minimizeScreen ? 'add' : 'remove'}</Icon>
                            </IconButton>
                        </TooltipNew>
                        <TooltipNew title={fullScreen ? 'Exit Full Screen' : 'Full Screen'}>
                            <IconButton onClick={handleFull}>
                                <Icon className="text-white">{fullScreen ? 'fullscreen_exit' : 'fullscreen'}</Icon>
                            </IconButton>
                        </TooltipNew>
                        <TooltipNew title="Close">
                            <IconButton onClick={handleClose}>
                                <Icon className="text-white">clear</Icon>
                            </IconButton>
                        </TooltipNew>
                    </div>
                </div>
                <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                    <div className="dialog-body">
                        <Grid container spacing={2}>
                            <Grid item sm={6} xs={12}>
                                <Select displayEmpty
                                    name="selectedUsers"
                                    value={selectedUsers}
                                    onChange={this.handleChangeSelectedList}
                                    fullWidth
                                    MenuProps={{ className: 'choose_field_menu' }}
                                >
                                    <MenuItem value={'all-user'} className="select-menu-item">All Users</MenuItem>
                                    {
                                        (teamList.length > 0) && teamList.map((team) => (
                                            <MenuItem value={team.id} className="select-menu-item">{team.teamname}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className="w-100 input_bx"
                                    onChange={this.handleChange}
                                    autoFocus
                                    type="text"
                                    name="searchUsers"
                                    placeholder="Search Users"
                                    value={searchUsers}
                                />
                            </Grid>
                        </Grid>
                        <TableContainer className="simple_table">
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="50"></TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Team</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        (selectedUsers == 'all-user') ?
                                            (usersListArray && usersListArray.length > 0) &&
                                            usersListArray.map((user) => (
                                                <>
                                                    {(this.findWithAttr(addedusers, 'id', user.id) == -1) &&
                                                        <TableRow>
                                                            <TableCell><Checkbox value={user.id} color="secondary" inputProps={{ 'aria-label': 'checkbox' }} onChange={this.handleusercheck} /></TableCell>
                                                            <TableCell>{user.name}</TableCell>
                                                            <TableCell>
                                                                {(user.teamlist && user.teamlist.length > 0) && user.teamlist.map((team, key) => (
                                                                    <>
                                                                        {((user.teamlist.length - 1) <= key) ? team.team.teamname : team.team.teamname + ", "}
                                                                    </>
                                                                ))}
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                </>
                                            ))
                                            :
                                            (teamUsersListArray && teamUsersListArray.length > 0) &&
                                            teamUsersListArray.map((teammember) => (
                                                <>
                                                    {
                                                        teammember.user &&
                                                        (this.findWithAttr(addedusers, 'id', teammember.user.id) == -1) &&
                                                        <TableRow>
                                                            <TableCell><Checkbox value={teammember.user.id} color="secondary" inputProps={{ 'aria-label': 'checkbox' }} onChange={this.handleusercheck} /></TableCell>
                                                            <TableCell>{teammember.user.name}</TableCell>
                                                            <TableCell>
                                                                {(teammember.user.teamlist && teammember.user.teamlist.length > 0) && teammember.user.teamlist.map((team, key) => (
                                                                    <>
                                                                        {((teammember.user.teamlist.length - 1) <= key) ? team.team.teamname : team.team.teamname + ", "}
                                                                    </>
                                                                ))}
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                </>
                                            ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <DialogActions className="dialog-action">
                        <Button variant="contained" onClick={handleClose}>Cancel</Button>
                        {this.state.selectedUserList.length > 0 ?
                            <Button variant="contained" type="submit" className="btn_Primary" color="primary">Save</Button>
                            :
                            <Button variant="contained" style={{ "cursor": "not-allowed" }} color="primary">Save</Button>
                        }
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        );
    }
}



const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
    notification: state.notification,
});

const mapDispatchToProps = dispatch => {
    return {
        getUserList: (client) => dispatch(actionCreators.getUserList(client)),
        saveGoal: (client, request) => dispatch(actionCreators.saveGoal(client, request)),
        userFilter: (client, request) => dispatch(actionCreators.userFilter(client, request)),
        getTeamMember: (client, request) => dispatch(actionCreators.getTeamMember(client, request)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesRevenueGoalDialog);
