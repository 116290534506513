import React, { Component } from "react";
import { Button, IconButton, Icon, withStyles, Card, DialogContent, Dialog, DialogActions, DialogTitle, Typography, MenuItem, InputLabel, Radio, FormControlLabel, RadioGroup, Badge, Drawer, Tooltip } from "@material-ui/core";
import Grid, { default as MaterialGrid } from '@material-ui/core/Grid';

import { connect } from "react-redux";
import * as actions from '../../store/actions/index';

import ReportSidebarMenu from "../Layout/ReportSidebarMenu";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import moment from 'moment';//moment
import 'moment-timezone';//timezone
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

class ActivitiesLeaderboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportTitile: "Activities Leaderboard",
            reportSubTitle: "Amount total along with the closing rate.",
            dateopen: false,
            dateformat: 'MM/DD/YYYY',
            selectedDate: {from: moment().startOf("month").format('YYYY-MM-DD'), to: moment().endOf('month').format('YYYY-MM-DD')},
            selectedDatelabel:"",
            definedRanges: [
                {label: "Today",
                startDate:moment().format('YYYY-MM-DD'),
                endDate:  moment().format('YYYY-MM-DD')},

                {label: "Yesterday",
                startDate:moment().subtract(1, 'days').format('YYYY-MM-DD'),
                endDate:  moment().subtract(1, 'days').format('YYYY-MM-DD')},

                {label: "This Week",
                startDate:moment().day(1),
                endDate:  moment().day(7)},

                {label: "This Month",
                startDate:moment().startOf("month"),
                endDate:  moment().endOf("month")},

                {label: "This Quarter",
                startDate:moment().startOf('quarter'),
                endDate:  moment().endOf('quarter')},

                {label: "This Year",
                startDate:moment().startOf('year'),
                endDate:  moment().endOf('year')},

                {label: "Last 7 days",
                startDate:moment().subtract(6, 'days').format('YYYY-MM-DD'),
                endDate:  moment().format('YYYY-MM-DD')},

                {label: "Last 30 days",
                startDate:moment().subtract(30, 'days').format('YYYY-MM-DD'),
                endDate:  moment().format('YYYY-MM-DD')},
            ],
            fromdate:moment().startOf("month").format('YYYY-MM-DD'),
            todate:  moment().endOf("month").format('YYYY-MM-DD'),    
            activityleaderboard: [],
        }
    }

    componentDidMount() {
        let activityparams = {
            fromdate: this.state.fromdate,
            todate: this.state.todate,
            memberid:0
        };
        this.props.activityLeaderboard(this.props.apolloClient.client, activityparams);
        console.log(moment().startOf("month").format('YYYY-MM-DD'),'jashfgsdjhfsd f');
        this.setState({ selectedDate: {from: moment().startOf("month").format('YYYY-MM-DD'), to: moment().endOf('month').format('YYYY-MM-DD')} })
    }

    componentWillReceiveProps(props) {
        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            let usersetting = props.settingReducer.userSettingArray.data;
            this.setState({ dateformat: usersetting.dateformat, timeZone: usersetting.timezone })
        }

        if(props.reportReducer.reportactivityleaderboard && props.reportReducer.reportactivityleaderboard.data){
            let activityleaderboard = props.reportReducer.reportactivityleaderboard.data.data
            this.setState({ activityleaderboard });
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    toggle = () => {
        this.setState({ dateopen: !this.state.dateopen });
    }

    setDateRange = (range) => {
        let startDate = moment(range.startDate).format('YYYY-MM-DD');
        let endDate = moment(range.endDate).format('YYYY-MM-DD');
        
        let activityparams = {
            fromdate: startDate,
            todate: endDate,
            memberid:0
        };
        this.props.activityLeaderboard(this.props.apolloClient.client, activityparams);

        if (range.label) {
            this.setState({ selectedDatelabel: range.label });
        } else {
            this.setState({ selectedDate: {from: startDate, to:endDate}, selectedDatelabel:"" });
        }

        this.setState({fromdate: startDate, todate: endDate})
    }
    closeDatepicker = () =>{
        this.setState({dateopen:false})
    }
    
    render() {

        let { activityleaderboard, reportTitile } = this.state;

        const options = {
            chart: {
                type: 'bar',
                marginTop: 50
            },
            title: {
                text: ''
            },
            xAxis: {
                allowDecimals: false,
                title: {
                    text: 'Activity Assigned to'
                },
                categories: activityleaderboard.categories
            },
            yAxis: {
                allowDecimals: false,
                min: 0,
                title: {
                    text: 'Count of Activites'
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        color: ( // theme
                            Highcharts.defaultOptions.title.style &&
                            Highcharts.defaultOptions.title.style.color
                        ) || 'gray'
                    }
                }
            },
            legend: {
                align: 'left',
                verticalAlign: 'top',
                floating: true,
            },
            plotOptions: {
                series: {
                    stacking: 'normal',
                    borderWidth: 0,
                }
            },
            formatter: function () {
                return this.total;
            },
            series: activityleaderboard.series,
            // series: [{
            //     name: 'Call',
            //     color: '#ff6e6e',
            //     data: [9, 20, 25, 6, 8]
            // }, {
            //     name: 'Email sent to contact',
            //     color: '#27c1c1',
            //     data: [6, 20, 10, 10, 5]
            // }, {
            //     name: 'Meeting',
            //     color: '#dc9df1',
            //     data: [10, 10, 6, 4, 0]
            // }]
        }
        
        return (
            <React.Fragment >
                <div>
                    {/* <ReportSidebarMenu /> */}
                    <div className="lead_content right-16 settings_view"
                        style={{ overflowY: "auto", overflowX: "hidden" }}>
                        <Card elevation={3}>
                            <div className="report_card">
                                <div className="report_header" onClick={this.closeDatepicker}>
                                    <h3>{reportTitile}</h3>
                                    {/* <Grid container direction="row" justify="space-between" alignItems="center">
                                    </Grid> */}
                                    {/* <Grid
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center"
                                    >
                                        <div className="cursor-pointer report_date_picker w-200" onClick={this.toggle}>
                                            <InputLabel className="mb-10" onClick={this.toggle} id="">Select Date Range</InputLabel>
                                            {this.state.selectedDate}
                                        </div>
                                        <DateRangePicker
                                            open={this.state.dateopen}
                                            toggle={this.toggle}
                                            onChange={(range) => this.setDateRange(range)}
                                        />
                                    </Grid> */}
                                </div>
                                <div className="report_content" onClick={this.closeDatepicker}>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={options}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
    notification: state.notification,
    reportReducer: state.reportReducer,
});
const mapDispatchToProps = dispatch => {
    return {
        // activityLeaderboard
        activityLeaderboard: (client, value) => dispatch(actions.activityLeaderboard(client, value)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesLeaderboard);