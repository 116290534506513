import React, { Component } from "react";
import { Card, Typography } from "@material-ui/core";
import GeneralNotification from './GeneralNotification';
import CommentsNotifications from './CommentsNotifications';
import AssignmentsNotification from './AssignmentsNotification';
import RemindersNotification from './RemindersNotification';
import SystemNotification from './SystemNotification';
import AutoNotifications from './AutoNotifications';


class Notifications extends Component {
    state = {
        selection: []
    };

    render() {
        return (
            <React.Fragment >
                <div className="v_scroll crm-custom-notification-settings">
                    <div className="mb-40">
                        <Typography className=" p-10" variant="h5" color="inherit">Notifications</Typography>
                        <Typography className="text-hint p-10" variant="p" color="inherit">
                            Customize the notifications IDrive CRM will send you
                    </Typography>
                    </div>
                    <div className="mt-20">
                        <Typography className="font-size-18 p-10" variant="p" color="inherit">General</Typography>
                    </div>
                    <Card className="">
                        <div className="crm-custom-notification-table" >
                            <GeneralNotification />
                        </div>
                    </Card>
                    <div className="mt-20">
                        <Typography className="font-size-18 p-10" variant="p" color="inherit">Comments and Reactions</Typography>
                    </div>
                    <Card className="">
                        <div className="crm-custom-notification-table" >
                            <CommentsNotifications />
                        </div>
                    </Card>
                    <div className="mt-20">
                        <Typography className="font-size-18 p-10" variant="p" color="inherit">Assignments</Typography>
                    </div>
                    <Card className="">
                        <div className="crm-custom-notification-table" >
                            <AssignmentsNotification />
                        </div>
                    </Card>
                    <div className="mt-20">
                        <Typography className="font-size-18 p-10" variant="p" color="inherit">Reminders</Typography>
                    </div>
                    <Card className="">
                        <div className="crm-custom-notification-table" >
                            <RemindersNotification />
                        </div>
                    </Card>
                    <div className="mt-20">
                        <Typography className="font-size-18 p-10" variant="p" color="inherit">System Updates</Typography>
                    </div>
                    <Card className="">
                        <div className="crm-custom-notification-table" >
                            <SystemNotification />
                        </div>
                    </Card>
                    <Card className="mt-20">
                        <div className="crm-custom-notification-table" >
                            <AutoNotifications />
                        </div>
                    </Card>
                </div>
            </React.Fragment>
        );
    }
}

export default Notifications;
