import React, { Component } from "react";
import { Dialog, List, ListItem, DialogTitle, DialogActions, IconButton, Icon, Menu, withStyles, Grid, Fab, Select, MenuItem, TextField, Card, InputLabel, Button, Checkbox, Tooltip } from "@material-ui/core";
import * as actions from '../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import _ from 'lodash'
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment, { now } from 'moment';
import forEach from 'lodash/forEach';
import NoteLog from "../CommonLogUI/NoteLog";
import CallLogs from "../CommonLogUI/CallLogs";
import AddComment from "../CommonLogUI/AddComment";
import EditComment from "../CommonLogUI/EditComment";
import CreatelogDialog from "../CommonLogUI/CreatelogDialog";
import FilterLogoCommon from "../CommonLogUI/FilterLogoCommon";

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.3rem",
        color: 'rgba(0, 0, 0, 0.6)'
    }
}))(Icon);

const IconSmaller = withStyles(() => ({
    root: {
        fontSize: "1.0rem",
        color: 'rgba(0, 0, 0, 0.6)'
    }
}))(Icon);


class NoteLogs extends Component {
    constructor(props) {
        super(props);
             this.state = {
            FullViewEmailDialog: false,
            minimizeViewEmailDialog: false,
            selectedDate: new Date(),
            isOpenedEmailIds: [],
            allLogs: [],
            logvaluetype: 'Phone Call',
            logtype: '',
            textvalue: '',
            dateformat: 'MM/DD/YYYY',
            timeZone: 'America/New_York',
            shouldOpenCreateLogDialog: false,
            FullCreateLeadDialog: false,
            minimizeLeadDialog: false,
            isDeleteLogConfirmDialoOpen: false,
            isEditLog: false,
            isEditComment: false,
            logid: '',
            commentid: '',
            Commentoptions: '',
            showCommentoptions: false,
            isOpenedCommentIds: [],
            logtypeid: [],
            activitypeid: [],
            activities: [],
            userid: "",
            showSelector: false,
            
        };

      
    }

    componentDidMount() {
        this.props.getActivityTypes(this.props.apolloClient.client);
        let userData = JSON.parse(localStorage.getItem('user_data_session'))
        this.setState({
            userid: userData.id
        })
        // this.setState({ logtypeid: [5], activitypeid: [] }, () => this.forceUpdate());

        // let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        // let getfilterLogParam = { leadid: this.props.lead_id, emailid: emailParam, logtypeid: [5], activitypeid: [] }
        // this.props.getLeadLogListNew(this.props.apolloClient.client, getfilterLogParam);
    }
    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }
    componentWillReceiveProps(props) {
    
        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            let usersetting = props.settingReducer.userSettingArray.data;

            let format = usersetting.dateformat;
            let timezone = usersetting.timezone;
            this.setState({ dateformat: format, timeZone: timezone })
        }
        if (props.settingReducer.masterActivityTypesList) {
            this.setState({ activities: props.settingReducer.masterActivityTypesList.data });
        }
        if (props.leadReducer.leadLogList && props.leadReducer.leadLogList.data && props.leadReducer.leadLogList.data.logdata && props.leadReducer.leadLogList.data.logdata.length > 0) {
            let logged_in_user_email = JSON.parse(localStorage.getItem("user_data_session")).email;
            let logData = [];
            let results = [];
            let smallData = props.leadReducer.leadLogList.data.logdata[props.leadReducer.leadLogList.data.logdata.length - 1]
            // let resultList = this.getMonths(smallData.createddate, props.leadReducer.leadLogList.data.logdata[0].createddate);
            let source = '';
            if (props.leadReducer.leadFieldData.data) {
                let fieldSource = props.leadReducer.leadFieldData.data;
                var sourcekey = this.findWithAttr(fieldSource, 'fieldkey', 'name');

                if (fieldSource[sourcekey].leaddetail && fieldSource[sourcekey].leaddetail[0].fieldvalue)
                    source = fieldSource[sourcekey].leaddetail[0].fieldvalue;
            }
            let resultList = [];
            props.leadReducer.leadLogList.data.logdata.map((data) => {
                resultList.push({
                    str: moment.unix(data.logdate).tz(this.state.timeZone).format('MMM YYYY')
                })
            })
            results['Pinned'] = [];
            let results_pined_ids = [];
            resultList.map((mont) => {
                results[mont.str] = [];
                props.leadReducer.leadLogList.data.logdata.map((data) => {
                    if (mont.str == moment.unix(data.logdate).tz(this.state.timeZone).format('MMM YYYY')) {
                        if (!data.email_type ) {
                            let template = data.template;
                            let pinned = data.ispined;
                            let color_class = (data.__user__.name) ? data.__user__.name.charAt(0) : "";
                            let text = (data.text) ? data.text : "";
                            let loguser = (data.__user__.email == logged_in_user_email) ? 'You' : data.__user__.name;
                            let template1 = (data.template) ? template.replace("{{user}}", loguser) : "";

                            let new_template = "";

                            let create_date = data.createddate;
                            let updateddate = data.updateddate;

                            if (data.logtype == 5) // for note
                            {
                                updateddate = data.logdate;
                                new_template = template1.replace("{{property_name}}", '"' + data.propertyname + '"');
                            }

                            if (new_template != "") {
                                logData.push({
                                    logId: data.id,
                                    emojidetail: data.emojidetail && data.emojidetail !== null ? JSON.parse(data.emojidetail) : [],
                                    mentionid: data.mentionid,
                                    mentiondetail: data.mentiondetail ? data.mentiondetail : "",
                                    creaetedDate: moment.unix(create_date).tz(this.state.timeZone).format(this.state.dateformat),
                                    emailtype: 0,
                                    comments: data.__commentlist__,
                                    activitytype: data.__activitytype__,
                                    user: data.__user__.name,
                                    useremail: data.__user__.email,
                                    propertyType: data.propertyname,
                                    color: color_class,
                                    template: new_template,
                                    ispined: pinned,
                                    text: text,
                                    logtype: data.logtype,
                                    time: (updateddate != null)
                                        ?
                                        moment.unix(updateddate).tz(this.state.timeZone).format('hh:mm a')
                                        :
                                        moment.unix(create_date).tz(this.state.timeZone).format('hh:mm a'),
                                    date: (updateddate != null)
                                        ?
                                        moment.unix(updateddate).tz(this.state.timeZone).format(this.state.dateformat)
                                        :
                                        moment.unix(create_date).tz(this.state.timeZone).format(this.state.dateformat)
                                });
                            }
                        }

                        if (logData.length > 0) {
                            if (logData[0].ispined && logData[0].ispined == true) {
                                if(results_pined_ids.indexOf(logData[0].logId) == -1){
                                    results['Pinned'].push(logData);
                                    results_pined_ids.push(logData[0].logId);
                                }
                            } else {
                                results[mont.str].push(logData);
                            }
                            logData = []
                        }

                    }
                });
            });
            let temp_arr1 = [];
            Object.keys(results).forEach(function (key) {
                let temp_arr2 = [];
                if (key === 'Pinned') {
                    temp_arr2['key'] = 'Pinned';
                    temp_arr2['log'] = results['Pinned'];
                    if (results['Pinned'] && results['Pinned'].length > 0) {
                        temp_arr1.push(temp_arr2);
                    }
                } else if (key !== 'Pinned') {
                    temp_arr2['key'] = key;
                    temp_arr2['log'] = results[key];
                    if (results[key] && results[key].length > 0) {
                        temp_arr1.push(temp_arr2);
                    }
                }
            })
            // this.setState({ LogResult: results, lead_id: props.lead_id});
            this.setState({ allLogs: temp_arr1, lead_id: props.lead_id });
        }
        else if (props.leadReducer.leadLogList && props.leadReducer.leadLogList.data && props.leadReducer.leadLogList.data.logdata && props.leadReducer.leadLogList.data.logdata.length == 0) {
            this.setState({ allLogs: [], lead_id: props.lead_id });
        }
    }

    handleLeadDialogClose = () => {
        this.setState({ shouldOpenCreateLogDialog: false, FullCreateLeadDialog: false, minimizeLeadDialog: false });
    };
    handleLeadDialogMinimize = () => {
        this.setState(oldStateminimizeLeadDialog => ({ minimizeLeadDialog: !oldStateminimizeLeadDialog.minimizeLeadDialog }));
        this.setState({ FullCreateLeadDialog: false })
    };
    handleLeadDialogFull = () => {
        this.setState(oldStateFullCreateLeadDialog => ({ FullCreateLeadDialog: !oldStateFullCreateLeadDialog.FullCreateLeadDialog }));
        this.setState({ minimizeLeadDialog: false })
    };
    handlechange = (event, logid) => {
        this.setState({ [event.target.name]: event.target.value, logid: logid })
    }
    handleDateChange = date => {
        this.setState({ selectedDate: date });
    };
    handleCancelAddLog = () => {
        this.setState({ textvalue: '' })
    }

    handleSaveEditLog = (data) => {
        let mention = [];
        Object.entries(data.raw.entityMap).map((entity) => {
          if (mention.indexOf(entity[1].data.mention.id) == -1) {
            mention.push(parseFloat(entity[1].data.mention.id))
          }
        });
        // let date = moment(this.state.selectedDate).format("X");
    
        let date_test = moment(this.state.selectedDate).format('YYYY-MM-DD HH:mm');
        console.log(date_test, 'inserted');
    
        var logdate = moment.tz(date_test, this.state.timeZone).format();
        console.log(logdate, this.state.timeZone);
    
        var date = moment.utc(logdate).format('X');
        console.log(date, 'utc');
    
        console.log(moment.unix(date).tz(this.state.timeZone).format('YYYY-MM-DD hh:mm A'), 'conv');
    
        // let textvalue = this.state.textvalue;
        let saveLogparams = [];
    
        saveLogparams['update'] = {
          text: data.textvalue,
          mentionuser: mention,
          mentiondetail: JSON.stringify(data.raw),
          logtime: parseFloat(date),
          logdate: parseFloat(date),
          logid: parseFloat(this.state.logid),
          // propertyid: parseFloat(0)
        };

        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        saveLogparams['getlog'] = { emailid: emailParam, leadid: parseFloat(this.props.lead_id), logtypeid: this.state.logtypeid, activitypeid: this.state.activitypeid };

        this.props.updateLeadLog(this.props.apolloClient.client, saveLogparams);
        this.setState({ isEditLog: false })
    }

 
    //------delete log
    handleDeleteLog = (logid) => {
        this.setState({ logid: logid });
        this.toggleDeleteDialog()
    }
    toggleDeleteDialog = () => {
        this.setState({ isDeleteLogConfirmDialoOpen: !this.state.isDeleteLogConfirmDialoOpen });
    };
    confirmDeleteLog = () => {
        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        let deletelogParams = { logid: parseFloat(this.state.logid), leadid: parseFloat(this.state.lead_id), emailid: emailParam, logtypeid: this.state.logtypeid, activitypeid: this.state.activitypeid };
        this.props.deleteLeadLogDetail(this.props.apolloClient.client, deletelogParams);
        this.toggleDeleteDialog()
    };

    //- comments-----------------
    toggleComment = (logid) => {
        let isOpenedCommentIds = this.state.isOpenedCommentIds;

        if (isOpenedCommentIds.indexOf(logid) == -1) {
            isOpenedCommentIds.push(logid);
        }
        else {
            let index = isOpenedCommentIds.indexOf(logid);
            isOpenedCommentIds.splice(index, 1);
        }

        this.setState(({ commentvalue: "", logid: logid, isOpenedCommentIds: isOpenedCommentIds }));
        // this.setState(({ isOpenedComment: !this.state.isOpenedComment, logid: logid }));
    }

    handleCancelComment = () => {
        this.setState({ commentvalue: '' });
    }

    handleSaveCommentLog = (type) => {
        const textvalue = this.state.commentState.textvalue
        const raw = this.state.commentState.raw
        let mention = [];
        Object.entries(raw.entityMap).map((entity) => {
          if (mention.indexOf(entity[1].data.mention.id) == -1) {
            mention.push(parseFloat(entity[1].data.mention.id))
          }
        });
    
        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        let commentParams = {
          type: type,
          comment: this.state.commentvalue,
          logid: parseFloat(this.state.logid),
          leadid: parseFloat(this.state.lead_id),
          emailid: emailParam, logtypeid: this.state.logtypeid,
          activitypeid: this.state.activitypeid,
          mentionuser: mention,
          mentiondetail: "",
        };
        this.props.addLeadLogComment(this.props.apolloClient.client, commentParams);
        this.setState({ commentvalue: '' });
      }
    
      //-edit comment
      handleSaveEditComment = (type) => {
        const textvalue = this.state.commentState.textvalue
        const raw = this.state.commentState.raw
        let mention = [];
        Object.entries(raw.entityMap).map((entity) => {
          if (mention.indexOf(entity[1].data.mention.id) == -1) {
            mention.push(parseFloat(entity[1].data.mention.id))
          }
        });
    
        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        let commentParams = {
          type: type,
          commentid: parseFloat(this.state.commentid),
          comment: this.state.commentvalue,
          leadid: parseFloat(this.state.lead_id),
          emailid: emailParam,
          logtypeid: this.state.logtypeid,
          activitypeid: this.state.activitypeid,
          mentionuser: mention,
          mentiondetail: "",
        };
        this.setState({ commentvalue: '', isEditComment: false, commentid: '' });
        this.props.updateLeadLogComment(this.props.apolloClient.client, commentParams);
      }
    

    handleEditCommentMain = (id, comment) => {
        this.setState({ isEditComment: true, commentvalue: comment, commentid: id });
    }

    handleCancelEditComment = () => {
        this.setState({ isEditComment: false, commentvalue: '' })
    }

    //-delete comment
    deleteComment = (cmt_id, type) => {
        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        let commentParams = { commentid: cmt_id, type: type, emailid: emailParam, leadid: parseFloat(this.state.lead_id), logtypeid: this.state.logtypeid, activitypeid: this.state.activitypeid };
        this.props.deleteLeadLogComment(this.props.apolloClient.client, commentParams);
    }
    //--------------comments functions-----------


    componentWillUnmount() {
        localStorage.removeItem('emailLogEmail');
    }
    //------------

    // -- get all months between two dates
    getMonths = (startDate, endDate) => {
        var resultList = [];
        var date = moment.unix(startDate).tz(this.state.timeZone)._d;
        var endDate = moment.unix(endDate).tz(this.state.timeZone)._d;
        var monthNameList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        if(date.getMonth() < endDate.getMonth()){
            while (date.getMonth() <= endDate.getMonth() + 1) {
                var stringDate = monthNameList[date.getMonth()] + " " + date.getFullYear();
             
                //get first and last day of month
                // var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                // var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    
                resultList.push({
                    str: stringDate,
                });
                date.setMonth(date.getMonth() + 1);
            }
            resultList.reverse();
        }else if(date.getMonth() == endDate.getMonth()){
            var stringDate = monthNameList[date.getMonth()] + " " + date.getFullYear();
            resultList.push({
                str: stringDate,
            });
        }

        return resultList;
    };
    handleAdd = (log) => this.setState({ showSelector: !this.state.showSelector, logid: log.logId })

    handleSelect = (emoji, data) => {
        const index = data.emojidetail.findIndex(obj => obj.emoji === emoji && obj.by === this.state.userid)
        var main_data = data.emojidetail;
        if (index > -1) {
            main_data = [...main_data.slice(0, index), ...main_data.slice(index + 1)]
        } else {
            main_data = [...main_data, { emoji, by: this.state.userid }]
        }
        this.setState({ showSelector: false })
        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';

        let req = {
            logid: data.emailtype !== 0 ? parseFloat(data.emaildetails.id) : parseFloat(data.logId),
            emoji: emoji,
            emojidetail: JSON.stringify(main_data),
            leadid: parseFloat(this.state.lead_id),
            activitypeid: this.state.activitypeid,
            emailid: emailParam,
            logtypeid: this.state.logtypeid,
            logtype: data.emailtype !== 0 ? "email" : "log"
        }
        this.props.addLeadLogEmoji(this.props.apolloClient.client, req)
    }
    getdata = (data) => {
        console.log("data ....", data)
        this.setState({ commentvalue: data.textvalue, commentState: data })
      }
      getEditCommentData = (data) => {
        console.log("data edit ....", data)
        this.setState({ commentvalue: data.textvalue, commentState: data })
      }
      saveId = (id, date) => {
        this.setState({ logid: id, selectedDate: date })
      }
      AddNewLog = (log) => {
        this.setState({
          logtype: log.logtype,
          logvaluetype: log.propertyType,
          shouldOpenCreateLogDialog: true
        })
      }
      handleSaveAddLog = (data) => {
        let saveLogparams = [];
        saveLogparams['save'] = {
            mentionuser: data.mentionuser,
            mentiondetail: data.mentiondetail,
            propertyname: data.propertyname,
            propertyid: data.propertyid,
            text: data.text,
            logtime: data.logtime,
            logdate: data.logdate,
            oldvalue: "",
            newvalue: "",
            logtype: data.logtype,
            fieldid: data.fieldid,
            leadid: this.props.lead_id
        };
        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        saveLogparams['getlog'] = { emailid: emailParam, leadid: parseFloat(this.props.lead_id), logtypeid: [], activitypeid: [] };

        this.props.saveLeadLog(this.props.apolloClient.client, saveLogparams);
        this.setState({ shouldOpenCreateLogDialog: false });
    };

    togglePin = (logId, pinned) => {
        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        let pinParams = {
            ispined: !pinned,
            logid: parseFloat(logId),
            recordid: parseFloat(this.state.lead_id),
            recordtypeid: 1,
            emailid: emailParam,
            logtypeid: this.state.logtypeid,
            activitypeid: this.state.activitypeid
        }
        this.props.addLogPined(this.props.apolloClient.client, pinParams);
    }

    render() {
        const { commentvalue, allLogs, logvaluetype, isOpenedComment, textvalue, selectedDate, shouldOpenCreateLogDialog, minimizeLeadDialog, FullCreateLeadDialog } = this.state
        let current_logUser = JSON.parse(localStorage.getItem('user_data_session')).email;
        let loghtml = [];
        let logheader = [];
        let logbody = [];

        allLogs.map((logdata) => {
            logheader = [];
            logbody = [];
            logheader.push(
                <FilterLogoCommon headerKey={logdata.key} />
                         )
            logdata['log'].map((log) => {
                log = log[0];
                if (log.logtype == 5) {
                    logbody.push(
                        <NoteLog
                        thatAll={this}
                        log={log}
                        commentvalue={this.state.commentvalue}
                        selectedDate={this.state.selectedDate}
                        logid={this.state.logid}
                        dateformat={this.state.dateformat}
                        showSelector={this.state.showSelector}
                        isOpenedCommentIds={this.state.isOpenedCommentIds}
                        isEditComment={this.state.isEditComment}
                        commentid={this.state.commentid}
                        timeZone={this.state.timeZone}
                      /> )
                }
            })
            if (logbody && logbody.length > 0) {
                loghtml.push(logheader);
                loghtml.push(logbody);
            }
        })
        return (
            <>
                {/* <FiltersMenu proVar={this.state.activities} thisData={this} /> */}
                {allLogs && allLogs.length > 0 ?
                    <div>
                        {loghtml.map((month_log) => (
                            <div className="lead_upcoming">
                                <div className="box_portlet">
                                    {month_log}
                                </div>
                            </div>
                        ))}
                    </div>
                    :
                    <div className="isLogTabEmpty">
                        <div className="tab_empty_crm">
                            <div className="notabIcon">
                                <Icon className="no_icon" >library_books</Icon>
                            </div>
                            <div className="notabTitle">No Note Logged Yet</div>
                        </div>
                    </div>
                }
                {
                    shouldOpenCreateLogDialog && (
                        <CreatelogDialog
                            handleClose={this.handleLeadDialogClose}
                            open={shouldOpenCreateLogDialog}
                            handleMinimize={this.handleLeadDialogMinimize}
                            handleFull={this.handleLeadDialogFull}
                            minimizeScreen={minimizeLeadDialog}
                            fullScreen={FullCreateLeadDialog}
                            handleSaveAddLog={this.handleSaveAddLog}
                            logvaluetype={this.state.logvaluetype}
                            logtype={this.state.logtype}
                        />
                    )
                }
                <Dialog
                    fullWidth={true}
                    maxWidth={'xs'}
                    open={this.state.isDeleteLogConfirmDialoOpen}
                    onClose={this.toggleDeleteDialog}
                    disableBackdropClick
                    
                    aria-labelledby="ar-remove-dialog-title"
                >
                    <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to remove selected log ?"}</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.toggleDeleteDialog} color="primary">
                            Cancel
                        </Button>
                        <Button type="Button" onClick={() => this.confirmDeleteLog()} className="btn_Primary">
                            Ok
                        </Button>

                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        leadReducer: state.leadReducer,
        apolloClient: state.apolloClient,
        settingReducer: state.settingReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
        addLogPined: (client, request) => dispatch(actions.addLogPined(client, request)),
        getLeadLogListNew: (client, request) => dispatch(actions.getLeadLogListNew(client, request)),
        getActivityTypes: (client) => { dispatch(actions.getActivityTypes(client)) },
        saveLeadLog: (client, request) => dispatch(actions.saveLeadLog(client, request)),
        deleteLeadLogDetail: (client, request) => dispatch(actions.deleteLeadLogDetail(client, request)),
        updateLeadLog: (client, request) => dispatch(actions.updateLeadLog(client, request)),
        addLeadLogComment: (client, request) => dispatch(actions.addLeadLogComment(client, request)),
        updateLeadLogComment: (client, request) => dispatch(actions.updateLeadLogComment(client, request)),
        deleteLeadLogComment: (client, request) => dispatch(actions.deleteLeadLogComment(client, request)),
        addLeadLogEmoji: (client, request) => dispatch(actions.addLeadLogEmoji(client, request)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(NoteLogs);