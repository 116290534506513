import React, { Component } from "react";
import { Radio, FormControlLabel, RadioGroup, Grid, Icon, Select, MenuItem, List, Fab, Button, DialogContent, ListItemText, ListItemAvatar, DialogTitle, Avatar, Card, ListItem } from "@material-ui/core";

import FontAwesome from "react-fontawesome";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../store/actions/index';

class MyOpenTasks extends Component {
  state = {
    tasklist: [],
    showMore: false,
    value_type: "true",
    limit: 5,
    totalPages: 0,
    currentPage: 1,
    isDataLoading: true,
    hasMore: false,
    pagesLoaded: [],
    memberid: 0
  };

  componentDidMount() {
    let val = {
      limit: parseFloat(this.state.limit),
      pageno: parseFloat(this.state.currentPage),
      ismytask: (this.state.value_type == "true"),
      memberid: 0
    }
    this.props.taskReportList(this.props.apolloClient.client, val);
  }

  componentWillReceiveProps(props) {
    if (props.reportReducer.opentaskreportdata && props.reportReducer.opentaskreportdata.data && props.reportReducer.opentaskreportdata.data) {
      let tasklist = props.reportReducer.opentaskreportdata.data.data.task;
      let totalpage = props.reportReducer.opentaskreportdata.data.data.totalpage;


      if (this.state.currentPage === 1) {
        this.setState({
          tasklist: tasklist,
          isDataLoading: false,
          hasMore: totalpage > this.state.currentPage ? true : false,
          totalPages: totalpage
        }, () => this.forceUpdate())
      } else {
        if (this.state.pagesLoaded.indexOf(this.state.currentPage) == -1) {
          this.AddNewData(tasklist)
        }
        this.setState({
          hasMore: totalpage > this.state.currentPage ? true : false,
          isDataLoading: false,
        }, () => this.forceUpdate())
      }

    }
    else {
      setTimeout(() => {
        this.setState({ isDataLoading: false, });
      }, 1500);
    }
  }


  handleTypeChange = (event) => {
    this.setState({
      value_type: event.target.value
    }, () => this.updateGraphSettings())
  }

  fetchMoreData = () => {
    // e.preventDefault();
    if (this.state.currentPage >= this.state.totalPages) {
      this.setState({ hasMore: false });
      return;
    }
    this.getNextPageData(this.state.currentPage + 1)
  };
  getNextPageData = (page) => {
    this.setState({
      currentPage: page, isDataLoading: true
    }, () => this.updateGraphSettings())
  }
  updateGraphSettings = () => {
    let val = {
      ismytask: (this.state.value_type == "true"),
      limit: parseFloat(this.state.limit),
      pageno: parseFloat(this.state.currentPage),
      memberid: parseFloat(this.state.memberid)
    }
    this.props.taskReportList(this.props.apolloClient.client, val);
  }
  render() {
    let { value_type, tasklist } = this.state
    return (
      <Card elevation={3} className="p-16 dashboard_card mb-10">
        <Grid container justify="space-between">
          <h3 className="text-primary">{"My Open Task"}</h3>
          <Link className="viewmore text_link" to="/task/task-list">View</Link>
        </Grid>
        <Grid container justify="space-between">
          <RadioGroup className="ml-16 mt-10" row aria-label="gender" name="value_type" value={value_type} onChange={this.handleTypeChange}>
            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="My Tasks" />
            <FormControlLabel value={"false"} control={<Radio color="primary" />} label="All Team members’ tasks" />
          </RadioGroup>
        </Grid>
        <List className="white_space_nowrap">
          {
            (tasklist.length > 0) ?
              tasklist.map((fieldData, index) => (
                <ListItem key={index}>
                  <ListItemAvatar>
                    <Fab
                      size="small"
                      aria-label="Task"
                      className={`wrap_icons_task vert-middle bg-success`}
                    >
                      <Icon className="hover_icon" >check_box_outline_blank</Icon>
                      <FontAwesome className={"activity_icon fa-check-circle"} />
                    </Fab>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <div className="font-weight-500">{fieldData.name}</div>
                    }
                    secondary={<a className="text_link">{fieldData.owner[0].name}</a>}
                  />
                </ListItem>
              ))
              :
              <div className="text-hint text-center font-weight-500" >No Tasks Found</div>
          }
        </List>
      </Card >
    );
  }
}

const mapStateToProps = state => {
  return {
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
    notification: state.notification,
    reportReducer: state.reportReducer,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    // getPipelines: (client) => dispatch(actions.getPipelines(client)),
    // getTagsList: (client) => dispatch(actions.getTagsList(client)),
    // getUserList: (client) => dispatch(actions.getUserList(client)),
    // getActivityTypes: (client) => dispatch(actions.getActivityTypes(client)),
    // customFieldList: (client, value) => dispatch(actions.customFieldList(client, value)),

    taskReportList: (client, value) => dispatch(actions.taskReportList(client, value)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(MyOpenTasks);
