import React, { Component } from "react";
import ContactProfileLeft from "./ContactProfileLeft";
import ContactProfileCenter from "./ContactProfileCenter";
import ContactProfileRight from "./ContactProfileRight";

class Contact extends Component {
  state = {
    contact_id: ""
  }
  componentWillMount() {
    this.setState({
      contact_id: parseInt(this.props.match.params.id)
    })
  }
  render() {
    return (
      <React.Fragment>
        <ContactProfileLeft contact_id={this.state.contact_id} />
        <ContactProfileCenter contact_id={this.state.contact_id} />
        <div className="lead_right">
          <ContactProfileRight contact_id={this.state.contact_id}/>
        </div>
      </React.Fragment>
    );
  }
}

export default Contact;
