import React, { Component } from "react";
import { Grid, IconButton, Icon, Card, Fab, Tooltip, withStyles, CircularProgress, Box } from "@material-ui/core";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { NavLink, withRouter } from "react-router-dom";
import moment from 'moment';

const IconSmall = withStyles(() => ({
  root: {
    fontSize: "1.5rem",
    color: 'rgba(0, 0, 0, 0.6)'
  }
}))(Icon);

const TooltipNew = withStyles((theme) => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);
class Zendesk extends Component {
  state = {
    isOpenedZendesk: false,
    dateformat: 'MM/DD/YYYY',
    timeZone: 'America/New_York',
    hasMore: true,
    totalCount: 0,
    currentPage: 1,
    isDataLoading: true,
    hasPagination: false,
    ZendeskTicketList: [],
    Domain: '',
  };
  componentWillUnmount() {
    this.props.settingReducer.getticketList = []
  }

  componentWillMount() {
    if (this.props.recordId) {
      var req = {
        id: this.props.recordId,
        type: this.props.recordtype === "opportunities" ? "opportunity" : this.props.recordtype,
        page: this.state.currentPage,
      }
      this.props.TicketZendeskList(req);
    }
  }
  componentWillReceiveProps(props) {

    if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
      let usersetting = props.settingReducer.userSettingArray.data;

      let format = usersetting.dateformat;
      let timezone = usersetting.timezone;

      this.setState({ dateformat: format, timeZone: timezone })
    }
    if (props.settingReducer.getticketList.data) {
      if (props.settingReducer.getticketList.data.data !== this.state.ZendeskTicketList) {
        var data = props.settingReducer.getticketList.data.data;
        let totalRecord = props.settingReducer.getticketList.data.count;
        let domain = props.settingReducer.getticketList.data.domain;
        this.setState({
          totalCount: totalRecord,
          Domain: domain,
          isDataLoading: false,
        });
        if (!this.state.hasPagination) {
          this.setState({
            ZendeskTicketList: data,
            Domain: domain,
            hasPagination: true,
            hasMore: totalRecord > data.length ? true : false,
          })
        }
      }
      if (props.settingReducer.getticketList.data.data.length > 0) {
        this.setState({ isOpenedZendesk: true });
      }else{
        this.setState({ isOpenedZendesk: false });
      }
    } else {
      setTimeout(() => {
        this.setState({ isDataLoading: false });
      }, 1500);
    }
  }
  componentDidUpdate = (prevProps) => {
    const main = this.props.settingReducer.getticketList.data;
    const premain = prevProps.settingReducer.getticketList.data
    if (premain && premain.data !== main.data) {
      if (main.data !== this.state.ZendeskTicketList) {
        const data_new = this.state.ZendeskTicketList.concat(main.data)
        this.setState({
          hasMore: data_new.length === this.state.totalCount ? false : true,
          ZendeskTicketList: data_new,
          isDataLoading: false
        });
      }
    }
  }

  fetchMoreData = (e) => {
    e.preventDefault();
    if (this.state.ZendeskTicketList.length >= this.state.totalCount) {
      this.setState({ hasMore: false });
      return;
    }
    this.getNextPageData(this.state.currentPage + 1)
  };
  getNextPageData = (page) => {
    this.setState({ currentPage: page, isDataLoading: true })
    var req = {
      id: this.props.recordId,
      type: this.props.recordtype === "opportunities" ? "opportunity" : this.props.recordtype,
      page: page,
    }
    this.props.TicketZendeskList(req);
  }

  toggleZendesk = () => {
    this.setState({ isOpenedZendesk: !this.state.isOpenedZendesk });
  }

  render() {
    const { isOpenedZendesk, ZendeskTicketList } = this.state;
    let list = [];
    let status = "";
    let color = '';
    let url = ``;
    if (ZendeskTicketList && ZendeskTicketList !== null) {
      ZendeskTicketList.map((item) => {
        if (item) {
          status = item.status.toUpperCase();
          url = `https://${this.state.Domain}.zendesk.com/agent/tickets/${item.id}`;

          if (status === "OPEN" || status === "HOLD") { color = '#B50814' }
          if (status === "NEW") { color = '#FFB648' }
          if (status === "PENDING") { color = '#3091EC' }
          if (status === "SOLVED" || status === "CLOSED") { color = 'grey' }
        }
        list.push(
          <div className="box_portlet mt-10 crm-calendar-event">
            <div className="box_body">
              <div className="lead_pl" style={{ paddingLeft: '50px' }}>
                <div className="icon_left">
                  <Fab
                    size="small"
                    aria-label="Task"
                    className={`wrap_icons_task_sidebar vert-middle bg-grey_sidebar`}
                  >
                    <img src="/assets/images/intregation_logo/zendesk-512.png" style={{ width: "60%" }} />
                  </Fab>
                </div>
                <a href={url} target="_blank" className="border_none">
                  <div style={{ marginTop: "10px" }}>
                    {this.state.dataloading === true ? <CircularProgress /> :
                      <>
                        <Grid container justify="space-between">
                          <h6 className="m-0 " >{item.subject}</h6>
                        </Grid>
                        <Grid className='d-flex' >
                          <h6 style={{ marginTop: "9px" }} >
                            {item.created_at ? moment(item.created_at, 'YYYY-MM-DD').format(this.state.dateformat) : "-"}
                          </h6>
                          <Box className="text-white font-size-13" style={{ padding: "0 5px", background: `${color}`, height: "20%", margin: '4px 0 0 7px' }}>{status}</Box>
                        </Grid>
                        <div style={{ width: '80%' }}>
                          <h6 className="m-0 text-warp" >{item.requester} | Assignee {item.assignee} </h6>
                        </div>
                      </>
                    }
                  </div>
                </a>
                <div className="hover_show" style={{ top: '-21px', right: '-7px' }}>
                  <a href={url} target="_blank">
                    <TooltipNew title="Open in Zendesk">
                      <IconButton aria-label="Delete" size="small" >
                        <IconSmall style={{ fontSize: '18px' }}>launch</IconSmall>
                      </IconButton>
                    </TooltipNew>
                  </a>
                </div>
              </div>
            </div>
          </div>
        );
      })
      // }
    }
    return (
      <Card elevation={3} className="p-16 mt-16" style={{ overflow: 'initial' }}>
        <Grid container justify="space-between">
          <h5 className="flex-auto cursor_poiter mb-0 pt-4"
            onClick={this.toggleZendesk}>
            Zendesk ({this.state.totalCount})
            <Icon className="vert-middle">
              {isOpenedZendesk ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
            </Icon>
          </h5>

        </Grid>
        {
          isOpenedZendesk &&
          <>
            {
              (ZendeskTicketList.length == 0) ?
                <label className="text-hint">
                  <IconButton className="mr-8" component="span">
                    <Icon>description</Icon>
                  </IconButton> No Zendesk Ticktes</label>
                :
                <>
                  {list}
                  {
                    this.state.isDataLoading ?
                      <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                        style={{ minHeight: '10vh', backgroundColor: 'rgba(0,0,0,0)' }}
                      >
                        <Grid item xs={3}>
                          <CircularProgress disableShrink size={25} />
                        </Grid>
                      </Grid>
                      :
                      this.state.hasMore &&
                      <Grid container item justify="center">
                        <a href="" className="text-small text_link"
                          onClick={this.fetchMoreData}
                        >
                          SHOW {this.state.totalCount - ZendeskTicketList.length} MORE TICKETS
                           <Icon style={{ margin: "-8px 0px" }}>expand_more</Icon>
                        </a>
                      </Grid>
                  }
                </>
            }

          </>
        }
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
});

const mapDispatchToProps = dispatch => {
  return {
    TicketZendeskList: (req) => { dispatch(actionCreators.TicketZendeskList(req)) },
  }
}
export default (connect(mapStateToProps, mapDispatchToProps)(withRouter(Zendesk)));