import React, { Component } from "react";
import { withStyles, Dialog, IconButton, Button, Icon, Tooltip, DialogActions, Select, MenuItem, Grid, FormControl, InputLabel, FormControlLabel, Checkbox, Paper } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import ReactDragListView from "react-drag-listview";
import { Link } from "react-router-dom";
import FontAwesome from 'react-fontawesome'
import SearchDropdown from "./SearchDropdown";

import * as actions from '../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';

const TooltipNew = withStyles((theme) => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);

class CreateNewFieldLeadDialog extends Component {
  state = {
    selectType: '',
    search: '',
    labelname: '',
    fieldkey: '',
    
    filtersCheckbox: false,
    leadCheckbox:false,
    contactCheckbox: false,
    taskCheckbox: false,
    companiesCheckbox: false,
    opportunitiesCheckbox: false,
    projectsCheckbox: false,
    
    currency: '',
    data: [],
    currencyList: [],
    customfieldtype:[],
    selectedDefaultCurrency:'',
    customFieldDetail:[]
  };

  componentDidMount(){
    this.props.loadCurrencyList(this.props.apolloClient.client);
    this.props.customFieldTypeList(this.props.apolloClient.client);
    
    if (this.props.fieldData) {

      
      let val = { fieldid: parseFloat(this.props.fieldData.id) }
      this.props.customFieldDetail(this.props.apolloClient.client, val);
    }
  }

  componentWillReceiveProps(props){
    if (props.settingReducer.fieldDetail.data && props.settingReducer.customFieldTypeList.data)
    {
      let customFieldDetail = props.settingReducer.fieldDetail.data;
      this.setState({ customFieldDetail: customFieldDetail});
      
      let type = [];
      props.settingReducer.customFieldTypeList.data.map((types, index)=>(
      
      
        //set type of field edited
        (parseFloat(props.settingReducer.fieldDetail.data.fieldtype) == parseFloat(types.id)) ?
          type = types
        :
          null

      ));   
      
      //set all the parameters for edited field from field detail 
      if (customFieldDetail.currencytype)
      {
        this.setState({ currency: customFieldDetail.currencytype });
      }
      
      if (customFieldDetail.options) {
        this.setState({ data: customFieldDetail.options });
      }

      (customFieldDetail.includeinfilter) ? this.setState({ filtersCheckbox: true }) : this.setState({ filtersCheckbox: false }) ;
      (customFieldDetail.inleads == 1) ? this.setState({ leadCheckbox: true }) : this.setState({ leadCheckbox: false });
      (customFieldDetail.incompany == 1) ? this.setState({ companiesCheckbox: true }) : this.setState({ companiesCheckbox: false });
      (customFieldDetail.inpeople == 1) ? this.setState({ contactCheckbox: true }) : this.setState({ contactCheckbox: false });
      (customFieldDetail.intasks == 1) ? this.setState({ taskCheckbox: true }) : this.setState({ taskCheckbox: false });
      (customFieldDetail.inopportunities == 1) ? this.setState({ opportunitiesCheckbox: true }) : this.setState({ opportunitiesCheckbox: false });
      (customFieldDetail.inprojects == 1) ? this.setState({ projectsCheckbox: true }) : this.setState({ projectsCheckbox: false });

      this.setState({ customfieldtype: type, labelname: customFieldDetail.fieldlabel, fieldkey: customFieldDetail.fieldkey, selectType: type.id});
   
   
      if (props.settingReducer.currencyList.data) {
        var dataList = props.settingReducer.currencyList.data
        //getting default option key to set in dropdown selected value for default lead status
       
        var key = this.findWithAttr(dataList, 'id', customFieldDetail.currencytype);
        this.setState({ currencyList: dataList, selectedDefaultCurrency: key });
      }
   
    }

    
  }

  handleChange = event => {
    event.persist();
    if (event.target.type === 'checkbox') {
      this.setState({ [event.target.name]: event.target.checked });
    }
    else {
      this.setState({ [event.target.name]: event.target.value }); 
    }
  };
  dropdownHandleChange = (index) => event => {
    var options = this.state.data

    options[index].optionvalue = event.target.value ;
    this.setState({ data: options })
    
  };

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  handleFormSubmit = () => {
    let options = [];
    let option_ids = [];
    this.state.data.map((data, index)=>{
      options.push(data.optionvalue);
      option_ids.push(data.id);
    });

    let leadscheck = (this.state.leadCheckbox) ? 1 : 0;
    let companycheck = (this.state.companiesCheckbox) ? 1 : 0;
    let contactcheck = (this.state.contactCheckbox) ? 1 : 0;
    let taskcheck = (this.state.taskCheckbox) ? 1 : 0;
    let opportunitiesCheck = (this.state.opportunitiesCheckbox) ? 1 : 0;
    let projectsCheck = (this.state.projectsCheckbox) ? 1 : 0;
    let param = [];
    param['fieldData'] = {
      field: {
        customeFieldId: parseFloat(this.props.fieldData.id),
        fieldlabel: this.state.labelname,
        fieldkey: this.state.fieldkey,
        inleads: leadscheck,
        inpeople: contactcheck,
        incompany: companycheck,
        inopportunities: opportunitiesCheck,
        inprojects: projectsCheck,
        intasks: taskcheck,
        optionid: option_ids,
        includeinfilter: this.state.filtersCheckbox,
        optionvalue: options,
        currencytype: "" + this.state.currency,
        pipelinesid: [0]
      }
    } 
    param['typeIdlead'] = { typeId: (this.props.fieldData.inleads) ? parseFloat(1) : 1 };
    param['typeIdcontact'] = { typeId: (this.props.fieldData.inpeople) ? parseFloat(2) : 2 };
    param['typeIdcompany'] = { typeId: (this.props.fieldData.incompany) ? parseFloat(2) : 3 };
    
   

    this.props.updateCustomFieldInput(this.props.apolloClient.client, param);
    this.props.allData.closePopUp();
  };

  handleAddOption = () => {
    var options = this.state.data
    options.push({ id: 0, optionvalue: "Option " + (options.length + 1) })
    this.setState({ data: options })
  }
  deleteOption = (index) => {
    let options = this.state.data.filter((val, ind) => index !== ind);
    this.setState({
      data: options
    })
  }
  handleAZSorting = () => {
    let options = this.state.data;
    options = options.sort(function (a, b) {
      if (a.optionvalue.toLowerCase() < b.optionvalue.toLowerCase()) return -1;
      else if (a.optionvalue.toLowerCase() > b.optionvalue.toLowerCase()) return 1;
      return 0;
    });

    this.setState({
      data: options
    })

  }

  setCurrency = (currency) => {
    this.setState({ currency: currency.id });
  }
  
  render() {
    let { open, handleClose } = this.props;
    let { customfieldtype, currencyList, selectType, labelname, filtersCheckbox, contactCheckbox, leadCheckbox, companiesCheckbox, taskCheckbox, opportunitiesCheckbox, projectsCheckbox, fieldkey, selectedDefaultCurrency } = this.state;

    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const data = that.state.data;
        const item = data.splice(fromIndex, 1)[0];
        data.splice(toIndex, 0, item);
        that.setState({ data });
      },
      nodeSelector: 'li',
      handleSelector: 'a.drag-handle'
    };
    return (
      <Dialog className="crm-custom-bg-primary" onClose={handleClose} open={open} maxWidth="sm" fullWidth={true} disableBackdropClick >
        <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">
          <h4 className="m-0 text-white">Edit custom field</h4>
          <div className="d-flex">
            <TooltipNew title="Close">
              <IconButton onClick={handleClose}>
                <Icon className="text-white">clear</Icon>
              </IconButton>
            </TooltipNew>
          </div>
        </div>
        <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
          <div className="dialog-body pt-16">
            <FormControl className="w-100 input_bx mb-16">              
              <label className="crm-custom-field-label">Type</label>
              <InputLabel>{customfieldtype.name}</InputLabel>
            </FormControl>
            
            <FormControl className="w-100 input_bx mb-16 mt-16">
              <TextValidator
                className="w-100 input_bx mb-16"
                label={<label className="crm-custom-field-label">Label</label>}
                onChange={this.handleChange}
                type="text"
                name="labelname"
                value={labelname}
                validators={["required"]}
                errorMessages={["This field is required"]}
              />
            </FormControl>

            <FormControl className="w-100 input_bx mb-30">
              <label className="text-lable">Field Key <TooltipNew title="The Field Key is unique to each field and cannot be changed. It is used for integrations and automations"><Icon color="primary" size="small">info</Icon></TooltipNew></label>
              <TextValidator
                className="w-100 input_bx"
                placeholder="Add Field Key"
                onChange={this.handleChange}
                validators={["required"]}
                type="text"
                disabled={true}
                name="fieldkey"
                value={fieldkey}
              />
            </FormControl>

            {/* on selection of currency  */}
            {selectType === '10' ?
              (<FormControl className="mb-16 w-100 input_bx mt-16">
                <SearchDropdown data={this} dropdownData={currencyList} defaultValue={selectedDefaultCurrency} dropdownType="currency" />
                {/* <InputLabel>Currency</InputLabel>
                <Select
                  name="currency"
                  value={currency}
                  onChange={this.handleChange}
                  MenuProps={{ className: 'currency_dropdown' }}
                >
                  {currencyList.map((currencyitem, currencyindex)=>(
                    <MenuItem className="select-menu-item" value={currencyitem.id}><span className="pr-16">{currencyitem.code}</span>{currencyitem.name}</MenuItem>
                  ))}
                </Select> */}
              </FormControl>
              )
              :
              ''
            }
            {/* on selection of dropdown or select  */}
            {selectType === '4' || selectType === '5' ?
              <div className="mb-16">
                <div className="d-flex justify-content-between">
                  <label className="text-lable">Dropdown Options <span className="font-weight-normal">(Must have at least 1 option and no more than 500 options)</span></label>
                  <label className="text-lable">Sort <Link to="#" onClick={this.handleAZSorting} className="text_link">A-Z</Link></label>
                </div>
                <ReactDragListView {...dragProps}>
                  <ul className="dropdown_options">
                    {this.state.data.map((item, index) => (
                      <li key={index}>
                        {this.state.data.length > 1 &&
                          <Link to="#" className="drag-handle"><FontAwesome className="fa fa-arrows"></FontAwesome></Link>
                        }
                        <TextValidator
                          className="w-100 input_bx ml-10 mr-10"
                          onChange={this.dropdownHandleChange(index)}
                          placeholder="Enter option name"
                          type="text"
                          name={'dpOption' + index}
                          value={item.optionvalue}
                        />
                        {this.state.data.length > 1 &&
                          <IconButton size="small" onClick={() => this.deleteOption(index)}>
                            <FontAwesome className="fa fa-remove"></FontAwesome>
                          </IconButton>
                        }
                      </li>
                    ))}
                  </ul>
                </ReactDragListView>
                <Button size="small" className="btn_Primary" variant="contained" color="primary" onClick={this.handleAddOption}><Icon>add</Icon> Add Option</Button>
              </div>
              : ''
            }
            {/* on selection of textarea or textbox or url  */}
            {!(selectType === '1' || selectType === '2' || selectType === '9') ?
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filtersCheckbox}
                    name="filtersCheckbox"
                    onChange={this.handleChange}
                    value="filtersCheckbox"
                    color="primary"
                  />
                }
                label="Include in Filters"
                className="mb-16 custom-checkbox"
              />
              : ''
            }
            <FormControl className="mb-10 w-100">
              <label className="d-block">Add this field in other pages</label>
              <Grid>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={leadCheckbox}
                      name="leadCheckbox"
                      onChange={this.handleChange}
                      value="leadCheckbox"
                      color="primary"
                    />
                  }
                  label="Leads"
                  className="custom-checkbox"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={companiesCheckbox}
                      name="companiesCheckbox"
                      onChange={this.handleChange}
                      value="companiesCheckbox"
                      color="primary"
                    />
                  }
                  label="Companies"
                  className="custom-checkbox"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={contactCheckbox}
                      name="contactCheckbox"
                      onChange={this.handleChange}
                      value="contactCheckbox"
                      color="primary"
                    />
                  }
                  label="Contact"
                  className="custom-checkbox"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={taskCheckbox}
                      name="taskCheckbox"
                      onChange={this.handleChange}
                      value="taskCheckbox"
                      color="primary"
                    />
                  }
                  label="Tasks"
                  className="custom-checkbox"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={opportunitiesCheckbox}
                      name="opportunitiesCheckbox"
                      onChange={this.handleChange}
                      value="opportunitiesCheckbox"
                      color="primary"
                    />
                  }
                  label="Opportunities"
                  className="custom-checkbox"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={projectsCheckbox}
                      name="projectsCheckbox"
                      onChange={this.handleChange}
                      value="projectsCheckbox"
                      color="primary"
                    />
                  }
                  label="Projects"
                  className="custom-checkbox"
                />
              </Grid>
            </FormControl>
            {opportunitiesCheckbox &&
              <Paper variant="outlined" square className="p-16 mb-16 paper-bx">
                <Grid container spacing={1} className="flex-nowrap">
                  <Grid item>
                    <Icon color="primary" size="small">info</Icon>
                  </Grid>
                  <Grid item className="flex-auto">
                    <h6 className="mb-4">This field will be added to all pipelines</h6>
                    <p className="mb-0 mt-0">The visibility for individual pipelines can be managed from the Opportunities section</p>
                  </Grid>
                </Grid>
              </Paper>
            }
          </div>
          <DialogActions className="dialog-action">
            <Button variant="contained" onClick={handleClose}>Cancel</Button>
            <Button variant="contained" type="submit" color="primary" className="btn_Primary">Save</Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient
  };
};
const mapDispatchToProps = dispatch => {
  return {
    customFieldList: (client, request) => { dispatch(actions.customFieldList(client, request)) },
    addCustomFieldInput: (client, request) => { dispatch(actions.addCustomFieldInput(client, request)) },
    updateCustomFieldInput: (client, request) => { dispatch(actions.updateCustomFieldInput(client, request)) },
    loadCurrencyList: (client) => dispatch(actions.loadCurrencyList(client)),
    customFieldTypeList: (client) => dispatch(actions.customFieldTypeList(client)),
    customFieldDetail: (client, request) => dispatch(actions.customFieldDetail(client, request)),
    
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(CreateNewFieldLeadDialog);
//export default CreateNewFieldLeadDialog;
