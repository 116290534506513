import React from 'react';
import ReactDOM from 'react-dom';
import "./styles/_index.scss";
import App from './views/App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store/store';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { getMainDefinition } from 'apollo-utilities';
import { ApolloLink, split } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { InMemoryCache } from 'apollo-cache-inmemory';
import urlSettings from "./Common/Server"
const sessionToken = localStorage.getItem('jwt_token');

const httpLink = new HttpLink({
    uri: urlSettings.GraphApi,
    headers: {
        Authorization: sessionToken ? `Bearer ${sessionToken}` : "",
    },
});

const wsLink = new WebSocketLink({
    uri: urlSettings.Socket_URI,
    options: {
        reconnect: true,
        connectionParams: {
            headers: {
                Authorization: sessionToken ? `Bearer ${sessionToken}` : "",
            },
        }
    },
});

const terminatingLink = split(
    ({ query }) => {
        const { kind, operation } = getMainDefinition(query);
        return (
            kind === 'OperationDefinition' && operation === 'subscription'
        );
    },
    wsLink,
    httpLink,
);

const link = ApolloLink.from([terminatingLink]);

const cache = new InMemoryCache();

const client = new ApolloClient({
    link,
    cache,
});

ReactDOM.render(
    <Provider store={store}>
        <ApolloProvider client={client}>
            <App />
        </ApolloProvider>
    </Provider>
    ,
    document.getElementById('root'),
);


// ReactDOM.render(<App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA  
serviceWorker.unregister();
