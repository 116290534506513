import React, { Component } from "react";
import {
  Dialog,
  IconButton,
  Button,
  Icon,
  Tooltip,
  Grid,
  FormControl,
  Select,
  InputLabel,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  DialogActions,
  Chip,
  TextField,
  CircularProgress,
  withStyles,
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { createDynamicFields } from "./CreateFieldsDynamic";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
const TooltipNew = withStyles((theme) => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);
class CreateNewLeadDialog extends Component {
  state = {
    lastname: "",
    city: "",
    street: "",
    addressState: "",
    zipcode: "",
    country: "",
    countryListArray: [],
    gmailSuggestion: [],
    companyCurrencyData: [],
    focusValue: false,
    fieldsList: [],
    fieldsObj: {
      websites: {
        fieldid: "15",
        values: [{ srno: "1", id: null, value: "" }],
      },
      social: {
        fieldid: "10",
        values: [{ srno: "1", id: null, value: "" }],
      },
      phones: {
        fieldid: "7",
        values: [{ srno: "1", id: null, value: "" }],
      },
      email: {
        fieldid: "2",
        values: [{ srno: "1", id: null, value: "" }],
      },
    },
    tags: [],
    systemTagsList: [],
    usersListArray: [],
    selectedDate: new Date(),
    userSettings: "",
    isDataLoading: true,
    customFields: [],
    teamvisible: true,
    hasActiveTeamPermission: false,
    userListForVisibility: [],
    selectedUserList: [],
    isEditVisibility: false,
    selectedTeamList: [],
    teamList: [],
    teamVisibilityLabel: "",
    teamUser: [],
  };

  componentWillMount() {
    ValidatorForm.addValidationRule('IsURLValid', (value) => {
      if (value && value.length && value.length > 0) {
        var specialChar = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
        if (!specialChar.test(value)) {
          return false;
        }
      }
      return true;
    });
    
    this.props.getLoginUserPermission(this.props.apolloClient.client);
    let val = { typeId: 1 };
    let userData = JSON.parse(localStorage.getItem("user_data_session"));
    let userid = { userid: userData.id };
    this.props.getUserSetting(this.props.apolloClient.client, userid);
    this.props.customFieldList(this.props.apolloClient.client, val);
    this.props.getUserList(this.props.apolloClient.client);
    this.props.getCountryList(this.props.apolloClient.client);
    this.props.getCompanyCurrency(this.props.apolloClient.client);
    this.props.getTagsList(this.props.apolloClient.client);
    this.props.getTeam(this.props.apolloClient.client);
    this.setState({ isFromTopBar :  this.props.isFromTopBar });
  }
  AllMemberDisplay = () => {
    let teamVisibilityLabel = "";
    let userCount = 0;
    let teamCount = 0;
    let teamUser = [];
    let usersListArray = this.props.settingReducer.usersListArray.data ? this.props.settingReducer.usersListArray.data : []
    usersListArray.length > 0 && usersListArray.map((user) => {
      if (user.teamlist.length > 0) {
        let userdata = { userteam: [], id: "", username: "" }
        teamCount = teamCount < user.teamlist.length ? user.teamlist.length : teamCount;
        user.teamlist.map((u) => { userdata.userteam.push(u.team.teamname) })
        userdata.id = user.id;
        userdata.username = user.name;
        userCount = userCount + 1;
        teamUser.push(userdata)
      }
    })
    teamVisibilityLabel = userCount + " users from " + teamCount + " team ";
    this.setState({
      teamVisibilityLabel: teamVisibilityLabel,
      teamUser: teamUser
    }, () => this.forceUpdate())
  }
  selectedUserDetails = (mainuser) => {
    let teamVisibilityLabel = "";
    let teamUser = [];
    let userdata = { userteam: [], id: "", username: "" }
    mainuser.teamlist.map((u) => { userdata.userteam.push(u.team.teamname) })
    userdata.id = mainuser.id;
    userdata.username = mainuser.name;
    teamUser.push(userdata)
    teamVisibilityLabel = "1 users from " + mainuser.teamlist.length + " team ";
    this.setState({
      teamVisibilityLabel: teamVisibilityLabel,
      teamUser: teamUser
    }, () => this.forceUpdate())
  }
  themSelves = (selectedOwner) => {
    let teamVisibilityLabel = "";
    let userCount = 0;
    let teamCount = 0;
    let teamUser = [];
    let usersListArray = this.props.settingReducer.usersListArray.data ? this.props.settingReducer.usersListArray.data : []
    usersListArray.length > 0 && usersListArray.map((user) => {
      if (user.teamlist.length > 0) {
        if (user.teamlist[0].teammanager === true || (user.email === selectedOwner.email)) {
          let userdata = { userteam: [], id: "", username: "" }
          teamCount = teamCount < user.teamlist.length ? user.teamlist.length : teamCount;
          user.teamlist.map((u) => { userdata.userteam.push(u.team.teamname) })
          userdata.id = user.id;
          userdata.username = user.name;
          userCount = userCount + 1;
          teamUser.push(userdata)
        }
      }
    })
    teamVisibilityLabel = userCount + " users from " + teamCount + " team ";
    this.setState({
      teamVisibilityLabel: teamVisibilityLabel,
      teamUser: teamUser
    }, () => this.forceUpdate())
  }
  getUserPermission = () => {
    if (this.props.settingReducer.userPermissionDetails.data) {
      let data = this.props.settingReducer.userPermissionDetails.data.data;
      if (data.teampermissionstatus === true &&
        data.visibilityaccess.length > 0) {
        let leaddt = data.visibilityaccess.find(function (e) { return e.sourcetype === 1 });
        if (leaddt.isaccess) {
          if (leaddt.viewoption === 1) {
            this.AllMemberDisplay();
          }
          if (leaddt.viewoption === 2 || leaddt.viewoption === 3) {
            var selectedOwner = this.state.fieldsObj["owner"]
            if (selectedOwner && selectedOwner.values) {
              if (selectedOwner.values.teamlist.length > 0) {//if member in any team 
                if (selectedOwner.values.teamlist[0].teammanager === false) {//owner is not  teammanager
                  if (leaddt.viewoption === 2) {
                    this.AllMemberDisplay();
                  } else {
                    this.themSelves(selectedOwner.values);
                  }
                } else {//owner is teammanager   
                  this.selectedUserDetails(selectedOwner.values);
                }
              } else { // not in any team 
                this.setState({ teamVisibilityLabel: "0 users", teamUser: [] }, () => this.forceUpdate())
              }
            } else {
              this.setState({ teamVisibilityLabel: "0 users", teamUser: [] }, () => this.forceUpdate())
            }
          }
        } else {//leaddt.isaccess === false
          this.setState({ teamVisibilityLabel: "0 users", teamUser: [] }, () => this.forceUpdate())
        }
        this.setState({
          hasActiveTeamPermission: true,
          viewoption: leaddt.viewoption,
        })
      } else {
        this.setState({ teamvisible: false })
      }
    }
  }

  componentWillReceiveProps(props) {
    this.getUserPermission();
    if (props.settingReducer.teamList.data && this.state.teamList !== props.settingReducer.teamList.data) {
      const teamList = props.settingReducer.teamList.data;
      let data = [];
      teamList.map((v, i) => {
        data.push({ id: v.id, teamname: v.teamname })
      });
      this.setState({ teamList: data })
    }
    //storing user setting data in state
    if (
      props.settingReducer.userSettingArray.data &&
      this.state.userSettings == ""
    ) {
      const settingData = props.settingReducer.userSettingArray.data;
      this.setState({
        userSettings: settingData,
      });
    }

    if (
      props.settingReducer.customFieldList.data &&
      props.settingReducer.customFieldList.is_loaded
    ) {
      props.settingReducer.customFieldList.is_loaded = false;
      const customFields = props.settingReducer.customFieldList.data;
      this.setState({ fieldsList: customFields, isDataLoading: false });
      var statuskey = this.findWithAttr(customFields, "id", "3");
      var defaultKeyStatus = this.findWithAttr(
        customFields[statuskey].options,
        "is_default",
        true
      );

      var vkey = this.findWithAttr(customFields, "id", "5");
      var visibilitydefault = this.findWithAttr(
        customFields[vkey].options,
        "is_default",
        true
      );
      //setting selected date in date picker
      customFields.map((v, i) => {
        if (v.fieldtype.id === "8") {
          var data = {
            fieldid: v.id,
            values: this.state.selectedDate,
          };
          this.setState({
            fieldsObj: {
              ...this.state.fieldsObj,
              [v.fieldkey]: data,
            },
          });
        }
      });

      //setting default object for status
      this.setState({
        customFields: customFields,
        fieldsObj: {
          ...this.state.fieldsObj,
          ["status"]: {
            fieldid: "3",
            values: customFields[statuskey].options[defaultKeyStatus],
          },
          ["visibility"]: {
            fieldid: "5",
            values: customFields[vkey].options[visibilitydefault],
          },
        },
      });
    }
    if (props.settingReducer.countryListGql.data) {
      const countryies = props.settingReducer.countryListGql.data;
      this.setState({ countryListArray: countryies });
    }
    if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
      const usersListArray = props.settingReducer.usersListArray.data;
      let userListForVisibility = [];

      usersListArray.map((data, i) => {
        userListForVisibility.push({ id: data.id, name: data.name })
      })
      let userData = JSON.parse(localStorage.getItem('user_data_session'))
      var companyOwnerKey = this.findWithAttr(usersListArray, "email", userData.email);
      this.setState({
        usersListArray: usersListArray,
        userListForVisibility: userListForVisibility,
        fieldsObj: {
          ...this.state.fieldsObj,
          ["owner"]: { fieldid: "4", values: usersListArray[companyOwnerKey] },
        },
      });
    }
    if (props.notification && props.notification.status === true) {
      // props.handleClose();
      props.clearNotification();
    }

    if (props.settingReducer.systemTagsList.data) {
      const tagsData = props.settingReducer.systemTagsList.data;
      this.setState({ systemTagsList: tagsData });
    }

    if (props.settingReducer.companyCurrencyData.data != null) {
      var cData = props.settingReducer.companyCurrencyData.data;
      this.setState({ companyCurrencyData: cData[0] });
    }

    if (
      props.settingReducer.gmailSuggestions &&
      props.settingReducer.gmailSuggestions.data
    ) {
      let gmailSuggestion = [];
      let name = "";
      let city = "";
      let region = "";
      let countryCode = "";
      let first_name = "";
      let last_name = "";
      let avtar = "";
      let phones = "";
      let social = "";
      props.settingReducer.gmailSuggestions.data.map((suggestion) => {
        if (suggestion.enrichment_data.hasOwnProperty("enrich_data")) {
          if (suggestion.enrichment_data.enrich_data.hasOwnProperty("locations")) {
            if (suggestion.enrichment_data.enrich_data.locations.length > 0) {
              city = suggestion.enrichment_data.enrich_data.locations[0].city;

              region = suggestion.enrichment_data.enrich_data.locations[0].region;
              countryCode =
                suggestion.enrichment_data.enrich_data.locations[0].countryCode;
            }
          }
          if (suggestion.enrichment_data.enrich_data.hasOwnProperty("name")) {
            first_name = suggestion.enrichment_data.enrich_data.name.given;
            last_name = suggestion.enrichment_data.enrich_data.name.family;
          }

          if (suggestion.enrichment_data.enrich_data.hasOwnProperty("photos")) {
            avtar = suggestion.enrichment_data.enrich_data.photos.length > 0 &&
              suggestion.enrichment_data.enrich_data.photos[0].value;
          }

          if (suggestion.enrichment_data.enrich_data.hasOwnProperty("phones")) {
            phones = suggestion.enrichment_data.enrich_data.phones;
          }
          if (
            suggestion.enrichment_data.enrich_data.hasOwnProperty("profiles")
          ) {
            social = suggestion.enrichment_data.enrich_data.profiles;
          }
        }

        gmailSuggestion.push({
          label: suggestion.name,
          value: suggestion.id,
          email: suggestion.email,
          city: city,
          region: region,
          countryCode: countryCode,
          first_name: first_name,
          last_name: last_name,
          avtar: avtar,
          phones: phones,
          social: social,
        });
      });
      this.setState({ gmailSuggestion });
    }
  }

  getChangeSuggestions = (event, fieldkey, values) => {
    let val = { name: event.target.value };
    this.handleChange(
      { fieldid: "1", leadvalue: event.target.value.trim() },
      fieldkey,
      values
    );
    this.props.getSuggestionData(this.props.apolloClient.client, val);
  };

  handleNameChange = (event, values, fieldkey, valEmail) => {
    console.log("aur o", event, values, fieldkey, valEmail)
    if (values) {
      if (values.email)
        this.handleAddMoreField("value", "email", 0, values.email);

      if (values.label) {
        this.handleChange(
          { fieldid: "1", leadvalue: values.label.trim() },
          fieldkey,
          values.email
        );
      } else {
        this.handleChange(
          { fieldid: "1", leadvalue: "" },
          fieldkey,
          values.email
        );
      }
    }
  };

  addNewField = (key, option) => {
    let data = this.state.fieldsObj[key];
    data.values.push({
      srno: (data.values.length + 1).toString(),
      id: option.id,
      value: "",
    });

    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [key]: data,
      },
    });
  };

  handleAddMoreField = (name, key, index, value) => {
    let data = this.state.fieldsObj[key];
    data.values[index][name] = value;

    this.setState(
      {
        fieldsObj: {
          ...this.state.fieldsObj,
          [key]: data,
        },
      },
      () => this.forceUpdate()
    );

    var userDataId = this.findWithAttr(
      this.state.gmailSuggestion,
      "email",
      value
    );

    if (
      userDataId == 0 ||
      (userDataId != "" && userDataId != null && userDataId != undefined)
    ) {
      var userData = this.state.gmailSuggestion[userDataId];
      if (userData != undefined && userData != null) {
        var userEmail = userData.email;
        if (value == userEmail) {
          var key = this.findWithAttr(
            this.state.countryListArray,
            "code",
            userData.countryCode
          );
          key = key;
          let country = "";
          country = this.state.countryListArray[key];
          this.setState(
            {
              ...this.state,
              city: userData.city,
              addressState: userData.region,
              state: userData.region,
              country: country,
              lastname: userData.last_name,
            },
            () => this.forceUpdate()
          );

          let phoneData = this.state.fieldsObj["phones"];
          phoneData.values = [];
          phoneData.values[0] = {
            srno: 1,
            id: null,
            value: "",
          };
          if (
            userData.phones != "" &&
            userData.phones != null &&
            userData.phones != "undefined"
          ) {
            userData.phones.map((phone, index) => {
              phoneData.values[index] = {
                srno: index,
                id: 1,
                value: phone.value,
              };
              this.setState(
                {
                  fieldsObj: {
                    ...this.state.fieldsObj,
                    ["phones"]: phoneData,
                  },
                },
                () => this.forceUpdate()
              );
            });
          } else {
            let phoneData = this.state.fieldsObj["phones"];
            phoneData.values[index] = {
              srno: 1,
              id: 1,
              value: "",
            };
            this.setState(
              {
                fieldsObj: {
                  ...this.state.fieldsObj,
                  ["phones"]: phoneData,
                },
              },
              () => this.forceUpdate()
            );
          }

          let socialData = this.state.fieldsObj["social"];
          socialData.values = [];
          socialData.values[0] = {
            srno: 1,
            id: null,
            value: "",
          };
          if (
            userData.social != "" &&
            userData.social != null &&
            userData.social != "undefined"
          ) {
            const objectArray = Object.entries(userData.social);

            let customeIndex = 0;

            objectArray.forEach(([key, value]) => {
              var filedsListKey = this.findWithAttr(
                this.state.fieldsList,
                "id",
                socialData.fieldid
              );

              const optionsArray = Object.entries(
                this.state.fieldsList[filedsListKey].options
              );

              let optionId = 10;
              optionsArray.forEach(([optionKey, optionValue]) => {
                var text = optionValue.optionvalue.toLowerCase();

                if (text == value.service) {
                  optionId = optionValue.id;
                }
              });

              socialData.values[customeIndex] = {
                srno: customeIndex,
                id: optionId,
                value: value.url,
              };
              customeIndex++;
              this.setState(
                {
                  fieldsObj: {
                    ...this.state.fieldsObj,
                    ["social"]: socialData,
                  },
                },
                () => this.forceUpdate()
              );
            });
          }
        }
      }
    }
  };

  handleNormalFields = (event) => {
    this.setState({ [event.target.name]: event.target.value, });
    let namekey = event.target.name;
    if (namekey === 'street' || namekey === 'city' || namekey === 'addressState' || namekey === 'zipcode') {
      let name = event.target.name === 'addressState' ? 'state' : event.target.name;
      let dataleadvalue = {
        fieldid: "8", leadvalue: {
          [name]: this.state[namekey]
        }
      }
      this.setState({
        fieldsObj: {
          ...this.state.fieldsObj,
          ['address']: dataleadvalue,
        },
      });
    }
  };

  handleCountryChange = (event, values) => {
    console.log('Values :', values)
    let dataleadvalue = {
      fieldid: "8", leadvalue: {
        ['country']: values && values.name
      }
    }
    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        ['address']: dataleadvalue,
      },
    });
    this.setState({ country: values });
  };

  focusInValue = () => {
    this.setState({ focusValue: true });
  };

  focusOutValue = () => {
    this.setState({ focusValue: false });
  };

  currencyDropdownClose = () => {
    this.setState({ focusValue: false });
  };

  handleChange = (val, name, email) => {
    if (val.fieldid === "5") {
      this.setState({ isEditVisibility: true }, () => this.forceUpdate())
      if (val.values.id === 2) {
        this.setState({ selectedTeamList: [{}] }, () => this.forceUpdate())
      } else if (val.values.id === 3) {
        this.setState({ selectedUserList: [{}] }, () => this.forceUpdate())
      }
    }
    var key = this.findWithAttr(this.state.fieldsList, "id", val.fieldid);
    if (key != -1 &&
      this.state.fieldsList[key].fieldtype.id != "4" &&
      this.state.fieldsList[key].fieldtype.id != "8"
    ) {
      if (this[name]) {
        this[name].validate(val.value);
      }
    }

    if (email != null && email != "undefined" && email != "") {
      this.state.gmailSuggestion.map((userData, i) => {
        if (userData.email == email) {
          var data1 = {
            fieldid: "1",
            // fieldid: "16", Updated
            leadvalue: userData.first_name?userData.first_name:userData.label?userData.label:"",
          };
          let fieldsObj = this.state.fieldsObj;
          fieldsObj = {
            ...fieldsObj,
            ["name"]: data1,
          };
          this.setState({
            fieldsObj,
          });
        }
      });
      // this.state.gmailSuggestion.map((userData, i) => {
      //   if (userData.email == email) {
      //     var data1 = {
      //       fieldid: "1",
      //       leadvalue: userData.first_name,
      //     };
      //     let fieldsObj = this.state.fieldsObj;
      //     fieldsObj = {
      //       ...fieldsObj,
      //       ["name"]: data1,
      //     };
      //     this.setState({
      //       fieldsObj,
      //     });
      //   }
      // });
    } else {
      if (val.fieldid === "4") {
        if ((this.state.viewoption === 2 || this.state.viewoption === 3) && val.values === null) {
          this.setState({
            teamVisibilityLabel: "0 users",
            teamUser: []
          }, () => this.forceUpdate())
        } else {
          this.setState({
            fieldsObj: {
              ...this.state.fieldsObj,
              [name]: val,
            }
          }, () => this.getUserPermission())
        }
      }
      this.setState({
        fieldsObj: {
          ...this.state.fieldsObj,
          [name]: val,
        },
      });
    }
  };

  // handleChange = (val, name) => {
  //   var key = this.findWithAttr(this.state.fieldsList, "id", val.fieldid);
  //   if (
  //     key != -1 &&
  //     this.state.fieldsList[key].fieldtype.id != "4" &&
  //     this.state.fieldsList[key].fieldtype.id != "8"
  //   ) {
  //     if (this[name]) {
  //       this[name].validate(val.value);
  //     }
  //   }
  //   this.setState({
  //     fieldsObj: {
  //       ...this.state.fieldsObj,
  //       [name]: val,
  //     },
  //   });
  // };

  handleFormSubmit = () => {
    let submitData = JSON.parse(JSON.stringify(this.state.fieldsObj));

    submitData = Object.values(submitData).map((row) => {
      if (row.fieldid == "1") {
        //name field
        row.names = { firstname: row.leadvalue, lastname: this.state.lastname };
        delete row["leadvalue"];
      }
      if (row.fieldid == "14") {
        //Value fiels
        row.values = [
          {
            id: this.state.companyCurrencyData.currencyid.toString(),
            srno: "1",
            value: row.leadvalue,
          },
        ];
        delete row["leadvalue"];
      }

      if (
        row.fieldid == "15" ||
        row.fieldid == "10" ||
        row.fieldid == "7" ||
        row.fieldid == "2"
      ) {
        if (
          row.values.length == 1 &&
          (row.values[0].id == null || row.values[0].value == "")
        ) {
          row.values = [{ fieldid: row.fieldid, value: [] }];
        }

        if (row.values.length > 1) {
          row.values.map((val, i) => {
            if (val.value == "") {
              row.values.splice(i, 1);
            }
          });
        }
        if (row.values.length > 0) {
          //adding selected dropdown val as lable
          row.values.map((val, i) => {
            if (val.id) {
              var fKey = this.findWithAttr(
                this.state.fieldsList,
                "id",
                row.fieldid
              );
              if (fKey != -1) {
                var optKey = this.findWithAttr(
                  this.state.fieldsList[fKey].options,
                  "id",
                  val.id
                );
                row.values[i].label = this.state.fieldsList[fKey].options[
                  optKey
                ].optionvalue;
              }
            }
          });
        }

        if (row.fieldid == "7") {
          if (row.values.length === 1) {
            if (row.values[0].fieldid) {
              row.values = [];
            } else {
              if (row.values.length > 0) {
                var foundMobile = this.findWithAttr(row.values, "id", "2");
                if (foundMobile != -1) {
                  var index = 1;
                  row.values.map((val, i) => {
                    if (val.id == "2") {
                      row.values[i].srno = "1";
                    } else {
                      row.values[i].srno = (index + 1).toString();
                    }
                    index++;
                  });
                }
              }
            }
          } else {
            if (row.values.length > 0) {
              var foundMobile = this.findWithAttr(row.values, "id", "2");
              if (foundMobile != -1) {
                var index = 1;
                row.values.map((val, i) => {
                  if (val.id == "2") {
                    row.values[i].srno = "1";
                  } else {
                    row.values[i].srno = (index + 1).toString();
                  }
                  index++;
                });
              }
            }
          }
        }
        if (row.fieldid == "15" || row.fieldid == "10" || row.fieldid == "2") {
          //make value blank array if notfill in this field
          if (row.values.length === 1) {
            if (row.values[0].fieldid) {
              row.values = [];
            }
          }
        }
      }
      if (row.fieldid === "3" || row.fieldid === "11" || row.fieldid === "5") {
        if (row.fieldid === "5") {
          let filterVisibleList = [];
          if (row.values.id === 2) {
            filterVisibleList = this.state.selectedTeamList.length > 0 ? this.state.selectedTeamList.filter(el => Object.keys(el).length) : []
          } else if (row.values.id === 3) {
            filterVisibleList = this.state.selectedUserList.length > 0 ? this.state.selectedUserList.filter(el => Object.keys(el).length) : []
          }
          row.values = [
            {
              srno: "1",
              id: row.values.id,
              value: row.values.name,
              visiblelist: filterVisibleList
            }
          ];
        } else {
          row.values = [{ srno: "1", id: row.values.id, value: row.values.name }];
        }
      }
      var key = this.findWithAttr(this.state.fieldsList, "id", row.fieldid);
      if (
        this.state.fieldsList[key].fieldtype.id == "4" &&
        this.state.fieldsList[key].is_default == false
      ) {
        row.values = [
          { srno: "1", id: row.values.id, value: row.values.optionvalue },
        ];
      }
      if (
        this.state.fieldsList[key].fieldtype.id == "5" &&
        this.state.fieldsList[key].is_default == false
      ) {
        if (row.values.length > 0) {
          var ar = [];
          row.values.map((val, i) => {
            ar.push({
              srno: (i + 1).toString(),
              id: val.id,
              value: val.optionvalue,
            });
          });
        }
        row.values = ar;
      }

      //validating date fiels
      if (this.state.fieldsList[key].fieldtype.id == "8") {
        //date field
        if (
          this.state.fieldsList[key].recordcreationdefaultvalue == 2 &&
          row.values == null
        ) {
          return false;
        } else if (
          this.state.fieldsList[key].recordcreationdefaultvalue != 2 &&
          row.values == null
        ) {
          row.leadvalue = "";
          delete row.values;
        } else if (row.values == "Invalid Date") {
          return false;
        } else {
          // let date_test = moment(row.values).format('YYYY-MM-DD HH:mm');
          // var logdate = moment.tz(date_test, this.state.userSettings.timezone).format();
          // row.leadvalue = moment.utc(logdate).format('X');
          var dt = moment(row.values).format("YYYY-MM-DD");
          row.leadvalue = dt;
          delete row.values;
        }
      }

      if (row.fieldid == "12") {
        if (row.values.length > 0) {
          var objArr = [];
          row.values.map((val, k) => {
            var foundKey = this.findWithAttr(
              this.state.systemTagsList,
              "tagname",
              val
            );
            var tagId = "0";
            if (foundKey !== -1) {
              tagId = this.state.systemTagsList[foundKey].id;
            }
            objArr.push({
              srno: (k + 1).toString(),
              value: val.toLowerCase(),
              id: tagId,
            });
          });
          row.values = objArr;
        }
      }

      if (row.fieldid == "8") {
        row.leadvalue = {
          street: this.state.street,
          city: this.state.city,
          state: this.state.addressState,
          zipcode: this.state.zipcode,
          country: this.state.country.name,
        };
      }
      if (row.fieldid == "4") {
        //owner value set
        if (row.values) {
          row.values = [
            {
              srno: "1",
              id: row.values.id,
              value: row.values.email,
              name: row.values.name,
            },
          ];
        } else {
          row.values = [];
        }
        delete row.leadvalue;
      }

      return row;
    });

    //not to submit if any field is false
    var allowToSubmit = true;
    submitData.map((row) => {
      if (!row) {
        allowToSubmit = false;
      }
    });
    console.log("sFromTopBar",this.state.isFromTopBar);
    //return
    if (allowToSubmit) {
      let req = {
        LeadData: submitData,
        teamvisible: this.state.teamvisible,
        isFromTopBar : this.state.isFromTopBar
      };
      this.props.handleClose();
      this.props.saveLeadDetail(this.props.apolloClient.client, req);
    }
  };

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };

  arrDifference = (arr1, arr2) => {
    var arr = [];
    arr1 = arr1.toString().split(",").map(Number);
    arr2 = arr2.toString().split(",").map(Number);
    // for array1
    for (var i in arr1) {
      if (arr2.indexOf(arr1[i]) === -1) arr.push(arr1[i]);
    }
    // for array2
    for (i in arr2) {
      if (arr1.indexOf(arr2[i]) === -1) arr.push(arr2[i]);
    }
    return arr.sort((x, y) => x - y);
  };

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  };
  handleBlur = (event, ref) => {
    if (this[ref].props.id == "tagInputId1") {
      setTimeout(() => {
        document.getElementById("tagInputId1").value = "";
      }, 10);
    }
    this[ref].validate(event.target.value);
  };
  teamHandleChange = (index, value, name) => {
    let mianteam = this.state.teamList;
    let selectedTeamList = this.state.selectedTeamList
    let mianUsers = this.state.usersListArray;
    let selectedUserList = this.state.selectedUserList
    if (name === "team") {
      if (value !== null) {
        selectedTeamList[index] = value;
        if (mianteam.length + 1 > selectedTeamList.length) {
          selectedTeamList[index + 1] = {};
        }
      } else {
        selectedTeamList.splice(index, 1);
        if (selectedTeamList.length === 0) {
          selectedTeamList[0] = {};
        }
      }
    } else {
      if (value !== null) {
        selectedUserList[index] = { id: value.id, name: value.name }
        if (mianUsers.length + 1 > selectedUserList.length) {
          selectedUserList[index + 1] = {};
        }
      } else {
        selectedUserList.splice(index, 1);
        if (selectedUserList.length === 0) {
          selectedUserList[0] = {};
        }
      }
    }
    this.setState({
      selectedTeamList: selectedTeamList,
      selectedUserList: selectedUserList
    })
  }
  handleToggleEditVisibility = () => {
    this.setState({
      isEditVisibility: !this.state.isEditVisibility
    })
  }

  handleChangeVisibilityRadio = (value) => {
    if (value) {
      let customFields = this.state.customFields
      var vkey = this.findWithAttr(customFields, "id", "5");
      var visibilitydefault = this.findWithAttr(
        customFields[vkey].options,
        "is_default",
        true
      );
      this.setState({
        selectedTeamList: [],
        selectedUserList: [],
        fieldsObj: {
          ...this.state.fieldsObj,
          ["visibility"]: {
            fieldid: "5",
            values: customFields[vkey].options[visibilitydefault],
          },
        },
      });
    }
    this.setState({
      teamvisible: value === "true" ? true : false
    })
  }
  render() {
    let {
      open,
      handleClose,
      handleMinimize,
      handleFull,
      fullScreen,
      minimizeScreen,
    } = this.props;
    return (
      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth="md"
        fullWidth={true}
        className={`crm-custom-bg-primary custom_dialog ${minimizeScreen && fullScreen ? "alignitemsend-fullscreen" : ""
          } ${minimizeScreen ? "alignitemsend" : ""} ${fullScreen ? "fullscreen" : ""
          }`}
        disableBackdropClick
      >
        <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">
          <h4 className="m-0 text-white">Create New Lead</h4>
          <div className="d-flex">
            <TooltipNew
              title={minimizeScreen ? "Normal Screen" : "Minimize Screen"}
            >
              <IconButton onClick={handleMinimize}>
                <Icon className="text-white">
                  {minimizeScreen ? "add" : "remove"}
                </Icon>
              </IconButton>
            </TooltipNew>
            <TooltipNew title={fullScreen ? "Exit Full Screen" : "Full Screen"}>
              <IconButton onClick={handleFull}>
                <Icon className="text-white">
                  {fullScreen ? "fullscreen_exit" : "fullscreen"}
                </Icon>
              </IconButton>
            </TooltipNew>
            <TooltipNew title="Close">
              <IconButton onClick={handleClose}>
                <Icon className="text-white">clear</Icon>
              </IconButton>
            </TooltipNew>
          </div>
        </div>
        <ValidatorForm
          noValidate={true}
          instantValidate={false}
          debounceTime={500}
          onSubmit={this.handleFormSubmit}
        >
          <div
            className={`${this.state.isDataLoading
              ? "dialog-body popupHeightCustom"
              : "dialog-body p-16"
              }`}
          >
            <Grid container spacing={2}>
              {this.state.isDataLoading && (
                <CircularProgress size={25} className="loaderPopupCenter" />
              )}
              {!this.state.isDataLoading && createDynamicFields(this)}
            </Grid>
          </div>

          <DialogActions className="dialog-action">
            <Button variant="contained" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              disabled={this.state.isDataLoading}
              variant="contained"
              type="submit"
              className="btn_Primary"
            >
              Save
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
  authReducer: state.authReducer
});

const mapDispatchToProps = (dispatch) => {
  return {
    getLoginUserPermission: (client) => dispatch(actionCreators.getLoginUserPermission(client)),

    getTeam: (client) => dispatch(actionCreators.getTeam(client)),
    getSuggestionData: (client, request) => dispatch(actionCreators.getSuggestionData(client, request)),
    customFieldList: (client, request) => dispatch(actionCreators.customFieldList(client, request)),
    saveLeadDetail: (client, request) => dispatch(actionCreators.saveLeadDetail(client, request)),
    getCountryList: (client) => dispatch(actionCreators.getCountryList(client)),
    clearNotification: () => dispatch(actionCreators.clearNotification()),
    getCompanyCurrency: (client) => dispatch(actionCreators.getCompanyCurrency(client)),
    getTagsList: (client) => dispatch(actionCreators.getTagsList(client)),
    getUserList: (client) => dispatch(actionCreators.getUserList(client)),
    getUserSetting: (client, data) => dispatch(actionCreators.getUserSetting(client, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewLeadDialog);
