import React, { Component } from "react";
import TableComponent from './TableComponent';
// import TaskCreationRule from './TaskCreationRule'
import { Switch, FormControlLabel, IconButton, withStyles, Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import FontAwesome from 'react-fontawesome'
// import CreateNewFieldTaskDialog from "./CreateNewFieldTaskDialog";
import * as actions from '../../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';
import { Icon, InlineIcon } from '@iconify/react';
import userFriends from '@iconify/icons-fa-solid/user-friends';
import handshakeIcon from '@iconify/icons-fa-solid/handshake';
import businessTime from '@iconify/icons-fa-solid/business-time';
import BusinessIcon from '@material-ui/icons/Business';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventIcon from '@material-ui/icons/Event';
import AttachmentIcon from '@material-ui/icons/Attachment';
import DescriptionIcon from '@material-ui/icons/Description';
import ListIcon from '@material-ui/icons/List';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.5rem",
    }
}))(Icon);

class RelatedSettingComapny extends Component {

    constructor(props) {
        super(props);

        this.state = {
            companydata: [],
            companyFieldIds: [],
            companyOrderIds: [],
            activedatas: [],
            inactivedatas: [],
            shouldOpenCreateFieldTaskDialog: false,
            updatelistorder: true,
        };
    }



    componentDidMount() {
        let val = { recordtypeid: 3 }
        this.props.getRelatedMasterList(this.props.apolloClient.client, val);
        // this.props.customFieldTypeList(this.props.apolloClient.client);
    }

    componentWillReceiveProps(props) {

        if (props.settingReducer.companyrelatedMaster.data) {
            const companyrelatedMaster = props.settingReducer.companyrelatedMaster.data;
            let activedatas = [];
            let inactivedatas = [];
            let companyFieldIds = [];
            let companyOrderIds = [];

            companyrelatedMaster.map((fieldData, index) => {

                // let fieldlicon = (fieldData.icon === 'fa-dollar-sign') ? 'fa-usd' : fieldData.icon;

                if (fieldData.isactive === true) {
                    companyFieldIds.push(parseFloat(fieldData.id));
                    companyOrderIds.push(parseFloat(fieldData.orderno));

                    activedatas.push({
                        drag: <Link className="drag-handle" companyOrderIds={companyOrderIds} to="#"><DragHandleIcon /></Link>,
                        key: fieldData.id,
                        name: (
                            <Grid container>
                                {/* <FontAwesome className={'crm-fontawsome-icon ' + fieldlicon} ></FontAwesome> */}
                                <div className='crm-fontawsome-icon'>
                                    {/* <Icon>{fieldlicon}</Icon> */}
                                    {fieldData.icon === 'fa-check-square' && <EventAvailableIcon />}
                                    {fieldData.icon === 'fa-dollar-sign' && <Icon icon={handshakeIcon} />}
                                    {fieldData.icon === 'fa-calendar' && <EventIcon />}
                                    {fieldData.icon === 'fa-paperclip' && <AttachmentIcon />}
                                    {fieldData.icon === 'fa-user' && <Icon icon={userFriends} />}
                                    {fieldData.icon === 'fa-suitcase' && <Icon icon={businessTime} />}
                                    {fieldData.icon === 'fa-usd' && <Icon icon={handshakeIcon} />}
                                    {fieldData.name == "Quick Book" && <DescriptionIcon />}
                                    {fieldData.name === 'Xero' && <DescriptionIcon />}
                                    {fieldData.alias === 'zendesk' && <DescriptionIcon />}
                                    {fieldData.alias === 'mailchimp' && <ListIcon />}
                                    {fieldData.alias === 'docusing' && <Icon icon={userFriends} />}
                                </div>
                                <label className="crm-custom-field-label">{fieldData.name}</label>
                            </Grid>
                        ),
                        action: <React.Fragment>
                            <IconButton onClick={(id) => this.handleUpdateStatus(fieldData.id, false)}><RemoveCircleOutlineIcon /></IconButton>
                        </React.Fragment>
                    })
                }

                if (fieldData.isactive === false) {
                    inactivedatas.push({
                        key: fieldData.id,
                        name: (
                            <Grid container>
                                {/* <FontAwesome className={'crm-fontawsome-icon ' + fieldlicon} ></FontAwesome> */}
                                <div className='crm-fontawsome-icon'>
                                    {/* <Icon>{fieldlicon}</Icon> */}
                                    {fieldData.icon === 'fa-check-square' && <EventAvailableIcon />}
                                    {fieldData.icon === 'fa-dollar-sign' && <Icon icon={handshakeIcon} />}
                                    {fieldData.icon === 'fa-calendar' && <EventIcon />}
                                    {fieldData.icon === 'fa-paperclip' && <AttachmentIcon />}
                                    {fieldData.icon === 'fa-user' && <Icon icon={userFriends} />}
                                    {fieldData.icon === 'fa-suitcase' && <Icon icon={businessTime} />}
                                    {fieldData.icon === 'fa-usd' && <Icon icon={handshakeIcon} />}
                                    {fieldData.name == "Quick Book" && <DescriptionIcon />}
                                    {fieldData.name === 'Xero' && <DescriptionIcon />}
                                    {fieldData.alias === 'zendesk' && <DescriptionIcon />}
                                    {fieldData.alias === 'mailchimp' && <ListIcon />}
                                    {fieldData.alias === 'docusing' && <Icon icon={userFriends} />}
                                </div>
                                <label className="crm-custom-field-label">{fieldData.name}</label>
                            </Grid>
                        ),
                        action: <React.Fragment>
                            <IconButton onClick={(id) => this.handleUpdateStatus(fieldData.id, true)}><RemoveCircleOutlineIcon /></IconButton>
                        </React.Fragment>
                    })
                }
            })

            if (this.state.updatelistorder == true)
                this.setState({ activedatas: activedatas, inactivedatas: inactivedatas, companyFieldIds: companyFieldIds, companyOrderIds: companyOrderIds }, () => this.forceUpdate());
        }
    }

    closePopUp = () => {
        this.setState({ shouldOpenCreateFieldTaskDialog: false });
    }
    handleUpdateStatus = (id, status) => {
        let val = {
            status: status,
            relatedid: parseFloat(id),
            recordtypeid: 3,
        }

        this.props.updateRelatedStatus(this.props.apolloClient.client, val);
    }

    updateFieldsOrder = (fromIndex, toIndex) => {

        let companyFieldIds = [];

        const companydata = this.state.activedatas;
        const item = companydata.splice(fromIndex, 1)[0];

        companydata.splice(toIndex, 0, item);

        this.setState({
            companydata: companydata,
            updatelistorder: false,
        })
        const companyOrderIds = this.state.companyOrderIds;
        companydata.map((value, index) => {
            companyFieldIds.push(parseFloat(value.key));
        });

        let val1 = {
            orderno: companyOrderIds,
            relatedid: companyFieldIds,
            recordtypeid: 3,
        }
        this.props.updateRelatedOrder(this.props.apolloClient.client, val1);
    }
    handleTaskDialogClose = () => {
        this.setState({ shouldOpenCreateFieldTaskDialog: false });
    };
    render() {
        let { shouldOpenCreateFieldTaskDialog } = this.state;
        return (
            <>
                <TableComponent activedata={this.state.activedatas} inActivedata={this.state.inactivedatas} allData={this} />
            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        settingReducer: state.settingReducer,
        apolloClient: state.apolloClient
    };
};
const mapDispatchToProps = dispatch => {
    return {

        getRelatedMasterList: (client, request) => { dispatch(actions.getRelatedMasterList(client, request)) },
        customFieldList: (client, request) => { dispatch(actions.customFieldList(client, request)) },
        customFieldTypeList: (client) => { dispatch(actions.customFieldTypeList(client)) },
        updateRelatedStatus: (client, request) => { dispatch(actions.updateRelatedStatus(client, request)) },
        updateRelatedOrder: (client, request) => { dispatch(actions.updateRelatedOrder(client, request)) },

    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(RelatedSettingComapny);
