import React, { Component } from "react";
import ProfileLeft from "./ProfileLeft";
import ProfileCenter from "./ProfileCenter";
import ProfileRight from "./ProfileRight";

class Project extends Component {
   state={
    project_id:""
  }
  componentWillMount() {
    this.setState({
      project_id:parseInt(this.props.match.params.id)
    })
  }
  render() {
    return (
      <React.Fragment>
        <ProfileLeft project_id={this.state.project_id}/>
        <ProfileCenter project_id={this.state.project_id}/>
        <div className= "lead_right">
          <ProfileRight project_id={this.state.project_id}/>
        </div>
        {/* <ProfileRight /> */}
      </React.Fragment>
    );
  }
}

export default Project;
