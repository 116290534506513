import React, { Component } from "react";
import { Fab, Grid, withStyles, Switch, InputLabel, FormControl, Input, FormHelperText, RadioGroup, Radio, FormControlLabel, Divider, DialogContent, InputAdornment, Button, IconButton, Icon, MenuItem, Card, TextField, Dialog, DialogActions, DialogTitle, Drawer, Avatar } from "@material-ui/core";
import TaskProfileFrame from "../Tasks/TaskProfileFrame";
import CreateNewTaskDialog from "../Tasks/CreateNewTaskDialog";
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { NavLink, withRouter } from "react-router-dom";
import { SingleSelect } from "react-select-material-ui";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Link } from "react-router-dom";
import FontAwesome from "react-fontawesome";
import moment from 'moment';
const formatCreateLabel = (value) => `Create "${value}"`;
const isValidNewOption = (value) => value != "";
const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.5rem",
        color: 'rgba(0, 0, 0, 0.6)'
    }
}))(Icon);
const filter = createFilterOptions();


class Task extends Component {
    state = {
        attachment: null,
        drawerOpenTask: false,
        showAddTask: false,
        task_id: "",
        taskListArray: [],
        allProjectList: [],
        createNewprojectName: "",
        filterdata: [],
        removeTaskPopup: false,
        shouldOpenCreateTaskDialog: false,
        FullCreateTaskDialog: false,
        minimizeTaskDialog: false,
        type: "tasks",
        recordtype: "",
        masterActivityTypesList: [],
        dateformat: 'MM/DD/YYYY',
        timeZone: 'America/New_York',
        newTaskData: {},
        UserListArr: [],
        UserID: '',
    };

    componentWillUnmount() {
        this.props.settingReducer.relatedDataTask = [];
      }

    componentDidMount() {
        let userData = JSON.parse(localStorage.getItem('user_data_session'));
        this.props.getActivityTypes(this.props.apolloClient.client);
        if (this.props.recordId) {
            this.setState({
                recordId: this.props.recordId,
                recordtype: this.props.recordtype,
            }, () => {
                this.forceUpdate()
                //this.props.getOpportunitiesCompanyList(this.props.apolloClient.client, this.state.recordId);
                var req = {
                    id: this.props.recordId,
                    type: this.state.type,
                    recordtype: this.props.recordtype,
                }
                this.props.getRecordRelatedData(this.props.apolloClient.client, req);
            });
        }
        this.props.getUserList(this.props.apolloClient.client);
        let dataofEmail = {
            email: userData.email
        }
        this.props.getUserDetailByEmail(this.props.apolloClient.client, dataofEmail);
    }
    componentWillReceiveProps(props) {
        if (props.settingReducer.getuserdetailbyemail) {
            const user_Data = props.settingReducer.getuserdetailbyemail.data && props.settingReducer.getuserdetailbyemail.data;
            if (user_Data && user_Data.id) {
                this.setState({ UserID: user_Data.id, });
            }
        }
        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            let usersetting = props.settingReducer.userSettingArray.data;

            let format = usersetting.dateformat;
            let timezone = usersetting.timezone;

            this.setState({ dateformat: format, timeZone: timezone })
        }
        if (props.settingReducer.masterActivityTypesList && props.settingReducer.masterActivityTypesList.is_loaded == true && props.settingReducer.masterActivityTypesList.data) {
            props.settingReducer.masterActivityTypesList.is_loaded = false
            var dataList = props.settingReducer.masterActivityTypesList.data;
            var count = dataList.filter(function (element) {
                if (element.is_disable == true) {
                    return element
                }
            });
            this.setState({
                masterActivityTypesList: dataList,
            });
        }
        if (props.recordId != this.state.recordId) {//if(props.recordId !== undefined && this.state.oppoFlag){
            this.setState({
                recordId: props.recordId,
                recordtype: props.recordtype,
            }, () => {
                this.forceUpdate()
                var req = {
                    id: props.recordId,
                    type: this.state.type,
                    recordtype: props.recordtype,
                }
                this.props.getRecordRelatedData(this.props.apolloClient.client, req);
            });
        }
        if (props.taskReducer.taskFieldData.data && props.taskReducer.taskFieldData.is_loaded) {
            this.setState({
                isDataLoading: false,
            }, () => this.forceUpdate())
        }


        if (props.settingReducer.relatedDataTask.data) {
            props.settingReducer.relatedDataTask.is_loaded = false
            this.setState({
                taskListArray: props.settingReducer.relatedDataTask.data.data,
            }, () => this.forceUpdate());
            if (props.settingReducer.relatedDataTask.data.data.length > 0) {
                this.setState({ isOpenedTask: true });
            } else {
                this.setState({ isOpenedTask: false });
            }
        } else {
            this.setState({
                taskListArray: [],
                isOpenedTask: false
            }, () => this.forceUpdate());
        }

        if (props.settingReducer.usersListArray.data !== undefined
            && this.state.UserListArr !== props.settingReducer.usersListArray.data) {
            this.setState({ UserListArr: props.settingReducer.usersListArray.data, isDataLoading: false })
        }
    }

    toggleTask = () => {
        this.setState(oldStateProject => ({ isOpenedTask: !oldStateProject.isOpenedTask }));
        this.setState({
            showAddTask: true
        }, () => this.forceUpdate());

    }

    /* START cmp dialoge */

    handleTaskDialogClose = () => {
        this.setState({ shouldOpenCreateTaskDialog: false, FullCreateTaskDialog: false, minimizeTaskDialog: false });
    };

    handleTaskDialogMinimize = () => {
        this.setState(oldStateminimizeTaskDialog => ({ minimizeTaskDialog: !oldStateminimizeTaskDialog.minimizeTaskDialog }));
        this.setState({ FullCreateTaskDialog: false })
    };

    handleTaskDialogFull = () => {
        this.setState(oldStateFullCreateTaskDialog => ({ FullCreateTaskDialog: !oldStateFullCreateTaskDialog.FullCreateTaskDialog }));
        this.setState({ minimizeTaskDialog: false })

    };

    /* END cmp dialoge */
    closeDrawerRight = (e) => {
        //e.preventDefault()
        this.setState({
            drawerOpenTask: false,
            drawerOpenTask: false,
        });
    };
    toggleAddTask = () => {
        var data = {
            recordtype: this.state.recordtype,
            type: this.state.type,
            id: parseInt(this.state.recordId),
        }

        this.setState({
            newTaskData: data,
        }, () => {
            this.setState({
                isOpenedTask: true,
                showAddTask: !this.state.showAddTask,
                shouldOpenCreateTaskDialog: true
            });
        })

    }
    handleDrawerTaskRight = (task_id) => {
        if (this.state.task_id && (this.state.task_id === task_id)) { } else {
            this.setState({
                task_id: task_id,
                isDataLoading: true,
            }, () => {
                this.forceUpdate()
            })
        }

        setTimeout(() => {
            this.setState({
                drawerOpenTask: true,
            }, () => {
                this.forceUpdate()
            })
        }, 2000);

    };

    handleRemoveTask = (task_id) => {
        // let deleteTaskParams = [];
        // deleteTaskParams['deleteid'] = { taskid: this.state.deleteIds };
        // this.props.deleteTask(this.props.apolloClient.client, deleteTaskParams);
        this.setState({
            removeTaskPopup: true,
            deleteTaskId: task_id,
        }, () => {
            this.forceUpdate()
        })

    };
    toggleRemoveTaskPopup = () => {
        if (this.state.removeTaskPopup) {
            this.setState({
                deleteTaskId: "",
            }, () => {
                this.forceUpdate()
            })

        }
        this.setState({
            removeTaskPopup: !this.state.removeTaskPopup,
        }, () => {
            this.forceUpdate()
        })

    };
    confirmremoveTaskPopup = () => {
        console.log("this.state.deleteTaskId", this.state.deleteTaskId);
        if (this.state.deleteTaskId) {
            let deleteTaskParams = []
            deleteTaskParams['deleteid'] = { taskid: [parseInt(this.state.deleteTaskId)] };
            deleteTaskParams['sidebarParams'] = {
                id: parseInt(this.state.recordId),
                type: this.state.type,
                recordtype: this.state.recordtype,
            };
            this.props.deleteTask(this.props.apolloClient.client, deleteTaskParams);

            this.setState({
                deleteTaskId: "",
                removeTaskPopup: false,
            }, () => {
                this.forceUpdate()
            })
        }

    };

    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }

    handleCompleteTask = (task1, status1) => {
        let taskCompleteParams = [];
        taskCompleteParams['complete'] = { status: !status1, taskid: parseFloat(task1) };
        taskCompleteParams['sidebarParams'] = {
            id: parseInt(this.state.recordId),
            type: this.state.type,
            recordtype: this.state.recordtype,
        };
        this.props.taskComplete(this.props.apolloClient.client, taskCompleteParams);
        this.setState({ isDataLoading: true });
    }
    render() {
        let { drawerOpenTask, isOpenedTask } = this.state;
        /*STRAT project Display*/
        let displayTaskList = []
        {
            this.state.taskListArray.map((item, key) => {
                if (item.__taskdetail__.length > 0) {
                    var cName = ""
                    var relatedToID = ""
                    var relatedToType = ""
                    var relatedToValue = ""
                    var owner = ""
                    var activity_icon = ""
                    var dueDate = ""

                    item.__taskdetail__.map((data, key1) => {
                        if (data.__field__.id == "57") {//name
                            cName = data.fieldvalue;
                        }
                        if (data.__field__.id == "59" && data.values.length > 0) {//related to
                            relatedToID = data.values[0].id
                            relatedToType = data.values[0].type
                            relatedToValue = data.values[0].value
                        }
                        if (data.__field__.id == "61" && data.values.length > 0) {//owner
                            owner = data.values[0].name
                        }
                        if (data.__field__.id == "58" && data.values.length > 0) {//Activity type
                            activity_icon = data.values[0].icon;
                            var k = this.findWithAttr(this.state.masterActivityTypesList, 'id', data.values[0].id)
                            if (k != -1) {
                                activity_icon = this.state.masterActivityTypesList[k].icon;
                            }
                        }
                        if (data.__field__.id == "60" && data.fieldvalue) {//due date
                            dueDate = moment.unix(data.fieldvalue).tz(this.state.timeZone).format(this.state.dateformat)
                        }
                    })
                    displayTaskList.push(
                        <div className="box_portlet mt-10 crm-calendar-event p-4">
                            <div className="box_body px-10 py-20">
                                <div className="lead_pl" style={{ paddingLeft: '50px' }}>
                                    <div className="icon_left">
                                        <Fab
                                            size="small"
                                            aria-label="Task"
                                            onClick={(task1, status1) => this.handleCompleteTask(item.id, item.iscomplete)}
                                            className={`wrap_icons_task_sidebar vert-middle ${(item.iscomplete == true) ? 'bg-success-task_sidebar' : 'bg-grey_sidebar'}`}
                                            style={{ marginTop : '-5px' }}
                                        >
                                            {(activity_icon == '') ?
                                                <Icon>{(item.iscomplete == true) ? 'check_box' : 'check_box_outline_blank'}</Icon>
                                                :
                                                <>
                                                    <Icon className="hover_icon" >{(item.iscomplete == true) ? 'check_box' : 'check_box_outline_blank'}</Icon>
                                                    <FontAwesome className={"activity_icon " + activity_icon} />
                                                </>
                                            }
                                        </Fab>
                                    </div>
                                    <a onClick={() => this.handleDrawerTaskRight(item.id)} target="_blank" className={"cursor_poiter"}>
                                        <Grid container justify="space-between">
                                            <h6 className="m-0 text_ellipsis" style={{ width: '65%' }}>{cName}</h6>
                                            <h6 className="m-0 related-text">{dueDate}</h6>
                                        </Grid>
                                    </a>
                                    <span className="related-text text_ellipsis" style={{ width: '50%' }}>{relatedToID && relatedToType && <Link to={"/" + relatedToType + "/" + relatedToType + "/" + relatedToID}>{relatedToValue}</Link>}</span>
                                    <span className="related-text text_ellipsis" style={{ width: '50%' }}>{owner && <>{" / Owner :"} <Link to={"/profile/company_user/" + this.state.UserID}>{owner}</Link></>}</span>
                                    <div className="hover_show" style={{ top: '-21px', right: '-7px' }}>
                                        <IconButton aria-label="Delete" size="small" onClick={() => this.handleRemoveTask(item.id)}>
                                            <IconSmall style={{ fontSize: '18px' }}>close</IconSmall>
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        </div>


                    )
                }

            })
        }
        /*END project Display*/

        return (
            <Card elevation={3} className="p-16 mt-16" style={{ overflow: 'initial' }}>
                <Grid container justify="space-between">
                    <h5 className="flex-auto cursor_poiter mb-0 pt-4" onClick={this.toggleTask}>Tasks ({displayTaskList.length}) <Icon className="vert-middle">{isOpenedTask ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon></h5>
                    <IconButton aria-label="Add" size="small" onClick={() => this.toggleAddTask()}>
                        <IconSmall className={this.state.showAddTask ? "" : ""}>add</IconSmall>
                    </IconButton>
                </Grid>
                {isOpenedTask &&
                    <>
                        {(displayTaskList.length == 0) &&
                            <label className="text-hint">
                                <IconButton onClick={() => this.toggleAddTask()} className="mr-8" component="span">
                                    <Icon>check_box</Icon>
                                </IconButton>
                                Add Task
                            </label>
                        }
                        {displayTaskList}

                    </>
                }

                {this.state.shouldOpenCreateTaskDialog && (
                    <CreateNewTaskDialog
                        handleClose={this.handleTaskDialogClose}
                        open={this.state.shouldOpenCreateTaskDialog}
                        handleMinimize={this.handleTaskDialogMinimize}
                        handleFull={this.handleTaskDialogFull}
                        minimizeScreen={this.state.minimizeTaskDialog}
                        fullScreen={this.state.FullCreateTaskDialog}
                        dataFromSidebar={this.state.newTaskData}
                    />
                )}

                <Dialog
                    fullWidth={true}
                    maxWidth={'xs'}
                    open={this.state.removeTaskPopup}
                    onClose={this.toggleRemoveTaskPopup}
                    disableBackdropClick

                    aria-labelledby="ar-remove-dialog-title"
                >
                    <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to remove the Associated Task?"}</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.toggleRemoveTaskPopup} color="primary">
                            Cancel
                                          </Button>
                        <Button type="Button" onClick={() => this.confirmremoveTaskPopup()} className="btn_Primary">
                            Ok
                                          </Button>

                    </DialogActions>
                </Dialog>
                <Drawer
                    width={"100px"}
                    variant="temporary"
                    anchor={"right"}
                    className="drawer_right"
                    open={drawerOpenTask}
                    onClose={this.closeDrawerRight}
                    ModalProps={{
                        keepMounted: true
                    }}
                >
                    {this.state.task_id && <TaskProfileFrame task_id={parseInt(this.state.task_id)} handleClose={this.closeDrawerRight} />}
                </Drawer>
                {this.state.isDataLoading &&
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        open={this.state.isDataLoading}
                        TransitionComponent={Fade}
                        message={<><CircularProgress size={20} style={{ color: 'white' }} /><span style={{ paddingLeft: '6px', color: 'white' }}>{" Loading..."}</span></>}
                        variant={'success'}
                    />
                }
            </Card>
        );
    }
}

const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
    notification: state.notification,
    taskReducer: state.taskReducer,
});

const mapDispatchToProps = dispatch => {
    return {
        getRecordRelatedData: (client, req) => { dispatch(actionCreators.getRecordRelatedData(client, req)) },
        getActivityTypes: (client) => dispatch(actionCreators.getActivityTypes(client)),
        taskComplete: (client, request) => { dispatch(actionCreators.taskComplete(client, request)) },
        getUserList: (client) => dispatch(actionCreators.getUserList(client)),
        deleteTask: (client, request) => { dispatch(actionCreators.deleteTask(client, request)) },
        getUserDetailByEmail: (client, req) => { dispatch(actionCreators.getUserDetailByEmail(client, req)) },
    }
}


export default (connect(mapStateToProps, mapDispatchToProps)(withRouter(Task)));
