import React, { Component } from "react";
import { Button, IconButton, Icon, withStyles, Card, DialogContent, Dialog, DialogActions, DialogTitle, Typography, MenuItem, InputLabel, Radio, FormControlLabel, RadioGroup, Badge, Drawer, Tooltip } from "@material-ui/core";
import Grid, { default as MaterialGrid } from '@material-ui/core/Grid';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from '../../store/actions/index';
import Select from '@material-ui/core/Select';
import FilterListIcon from '@material-ui/icons/FilterList';

import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import moment from 'moment';//moment
import 'moment-timezone';//timezone
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

class ConversionFunnel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportTitile: "Conversion Funnel",
            reportSubTitle: "Amount total along with the closing rate.",
            totlaValue: "$375 K",
            totalWinRate: "0%",
            selectedPipline: "1",
            selectedFilter: "1",
            totalopp: "1",
            value_type: "1",
            drawerOpen: false,
            dateopen: false,
            dateformat: 'MM/DD/YYYY',
            selectedDate: null,
            durationtype: "1",
            conversionfunnel: [],
            pipelineList: [],
            memberid: 0

        }
    }
    componentDidMount() {
        let req = {
            piplineid: parseFloat(this.state.selectedPipline),
            memberid: parseFloat(this.state.memberid)
        }
        this.props.getPipelines(this.props.apolloClient.client);
        this.props.conversionFunnelReport(this.props.apolloClient.client, req);
    }

    componentWillReceiveProps(props) {
        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            let usersetting = props.settingReducer.userSettingArray.data;
            this.setState({ dateformat: usersetting.dateformat, timeZone: usersetting.timezone })
        }

        if (props.reportReducer.conversionFunnelReport && props.reportReducer.conversionFunnelReport.data) {
            let conversionfunnel = props.reportReducer.conversionFunnelReport.data.data
            this.setState({ conversionfunnel });
        }

        if (props.settingReducer.pipelineListArray && props.settingReducer.pipelineListArray.data) {
            this.setState({ pipelineList: props.settingReducer.pipelineListArray.data })
        }
    }
    handlePiplineChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        }, () => this.updateGraphSettings())
    }
    updateGraphSettings = () => {
        let req = {
            piplineid: parseFloat(this.state.selectedPipline),
            memberid: parseFloat(this.state.memberid)
        }
        this.props.conversionFunnelReport(this.props.apolloClient.client, req);
    }
    render() {

        let { pipelineList, reportTitile, reportSubTitle, dateopen, totalopp, selectedFilter, totlaValue, drawerOpen, totalWinRate, selectedPipline, value_type, durationtype, conversionfunnel } = this.state;

        const options = {
            chart: {
                type: 'bar',
                marginTop: 50
            },
            title: {
                text: ''
            },
            xAxis: {
                title: {
                    text: 'Stages'
                },
                categories: conversionfunnel.categories
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        color: ( // theme
                            Highcharts.defaultOptions.title.style &&
                            Highcharts.defaultOptions.title.style.color
                        ) || 'gray'
                    }
                }
            },
            legend: {
                align: 'left',
                verticalAlign: 'top',
                floating: true,
            },
            plotOptions: {
                series: {
                    stacking: 'normal',
                    borderWidth: 0,
                }
            },
            formatter: function () {
                return this.total;
            },
            series: conversionfunnel.series
        }

        return (
            <Card elevation={3}>
                <div className="report_card">
                    <div className="report_header">
                        <Grid container justify="space-between">
                            <h3>{reportTitile}</h3>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                        >
                            <div className="report_filter">
                                <InputLabel id="Pipeline-select-helper-label">Pipeline</InputLabel>
                                <Select
                                    className="w-200"
                                    labelId="Pipeline-select-label"
                                    name="selectedPipline"
                                    id="Pipeline"
                                    value={selectedPipline}
                                    onChange={this.handlePiplineChange}
                                >
                                    <MenuItem value="0">All</MenuItem>
                                    {pipelineList && pipelineList.map((pipeline, i) => (
                                        <MenuItem key={i} value={pipeline.id}>{pipeline.name}</MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </Grid>
                    </div>
                    <div className="report_content">
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                        />
                    </div>
                </div>
            </Card>

        );
    }
}

const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
    notification: state.notification,
    reportReducer: state.reportReducer,
});
const mapDispatchToProps = dispatch => {
    return {
        getPipelines: (client) => dispatch(actions.getPipelines(client)),
        conversionFunnelReport: (client, value) => dispatch(actions.conversionFunnelReport(client, value)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ConversionFunnel);
