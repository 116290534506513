import React, { Component } from "react";
import { Button, IconButton, Icon, withStyles, Card, DialogContent, Dialog, DialogActions, DialogTitle, Typography, MenuItem, InputLabel, Radio, FormControlLabel, RadioGroup, Badge, Drawer, Tooltip } from "@material-ui/core";
import Grid, { default as MaterialGrid } from '@material-ui/core/Grid';

import { connect } from "react-redux";
import * as actions from '../../store/actions/index';

import ReportSidebarMenu from "../Layout/ReportSidebarMenu";
import Select from '@material-ui/core/Select';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Link, withRouter } from "react-router-dom";

import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import moment from 'moment';//moment
import 'moment-timezone';//timezone
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

class SalesBySource extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportTitile: "Sales By Source",
            reportSubTitle: "Amount total along with the closing rate.",
            totlaValue: "$375 K",
            totalWinRate: "0%",
            selectedPipline: "1",
            selectedFilter: "1",
            totalopp: "1",
            value_type: "1",
            drawerOpen: false,
            dateopen: false,
            dateformat: 'MM/DD/YYYY',
            selectedDate: null,
            durationtype: "1",
            salesbysourcedata: [],
        }
    }
    componentDidMount() {
        let params = {
            filterdata: [],
            piplineid: parseFloat(this.state.selectedPipline),
            type: parseFloat(this.state.value_type),
            fromdate: moment().startOf('month').subtract(2, 'months').format('YYYY-MM-DD'),
            todate: moment().endOf('month').format('YYYY-MM-DD'),
            memberid: 0
        };
        this.props.salesBySourceReport(this.props.apolloClient.client, params);
    }

    componentWillReceiveProps(props) {
        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            let usersetting = props.settingReducer.userSettingArray.data;
            this.setState({ dateformat: usersetting.dateformat, timeZone: usersetting.timezone })
        }

        if (props.reportReducer.reportsalesbysource && props.reportReducer.reportsalesbysource.data) {
            let salesbysourcedata = props.reportReducer.reportsalesbysource.data.data
            this.setState({ salesbysourcedata })
        }
    }

    handleChange = (event) => {
        let params = {
            filterdata: [],
            piplineid: parseFloat(this.state.selectedPipline),
            type: parseFloat(event.target.value),
            fromdate: moment().startOf('month').subtract(2, 'months').format('YYYY-MM-DD'),
            todate: moment().endOf('month').format('YYYY-MM-DD'),
            memberid: 0
        };
        this.props.salesBySourceReport(this.props.apolloClient.client, params);
        this.setState({ [event.target.name]: event.target.value })
    }
    handleOpenDrawer = () => {
        this.setState({ drawerOpen: true, })
    }
    handleCloseDrawer = () => {
        this.setState({ drawerOpen: false, })
    }

    toggle = () => {
        this.setState({ dateopen: !this.state.dateopen });
    }

    setDateRange = (range) => {
        let startDate = moment.utc(range.startDate).format(this.state.dateformat);
        let endDate = moment.utc(range.endDate).format(this.state.dateformat);
        if (range.label) {
            this.setState({ selectedDate: range.label })
        } else {
            this.setState({ selectedDate: startDate + " To " + endDate })
        }
    }
    render() {
        let { salesbysourcedata, reportTitile, reportSubTitle, dateopen, totalopp, selectedFilter, totlaValue, drawerOpen, totalWinRate, selectedPipline, value_type, durationtype } = this.state;

        let pointFormat = "";
        if (value_type == 1) {
            pointFormat = '{series.name}: <b>${point.y} ( {point.percentage:.1f}% ) </b>';
        } else if (value_type == 2) {
            pointFormat = '{series.name}: <b>{point.y} ( {point.percentage:.1f}% ) </b>';
        }

        const options = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: null
            },
            tooltip: {
                pointFormat: pointFormat,
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            legend: {
                enabled: true,
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle',
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            series: [{
                name: 'Source',
                colorByPoint: true,
                data: salesbysourcedata.stage,
                // [{
                //     name: 'Advertising',
                //     y: 61,
                //     sliced: true,
                //     selected: true
                // }, {
                //     name: 'Cold Call',
                //     y: 11.84
                // }, {
                //     name: 'Email',
                //     y: 10.85
                // }, {
                //     name: 'No Source',
                //     y: 4.67
                // },]
            }]
        }

        return (
            <React.Fragment >
                <div>
                    {/* <ReportSidebarMenu /> */}
                    <div className="lead_content right-16 settings_view"
                        style={{ overflowY: "auto", overflowX: "hidden" }}>
                        <Card elevation={3}>
                            <div className="report_card">
                                <div className="report_header crm-all-report-filters">
                                    <h3>{reportTitile}</h3>
                                    {/* <Grid container direction="row" justify="space-between" alignItems="center">
                                        <Grid><Typography className="Typography" variant="p">{reportSubTitle}</Typography></Grid>
                                        <Grid><div className="mr-36">
                                            <IconButton onClick={() => this.handleOpenDrawer()}>
                                                <Badge badgeContent={1} color="primary">
                                                    <FilterListIcon />
                                                </Badge>
                                            </IconButton>
                                        </div></Grid>
                                    </Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="center">
                                        <div className="number_label">
                                            <div>{totlaValue}</div>
                                            <div className=" p-4">Total Value </div>
                                        </div>
                                        <div className="number_label">
                                            <div>{totalopp}</div>
                                            <div className=" p-4">Total Win Rate</div>
                                        </div>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center"
                                    >
                                        <div className="report_filter">
                                            <InputLabel id="Pipeline-select-helper-label">Pipeline</InputLabel>
                                            <Select
                                                className="w-200"
                                                labelId="Pipeline-select-label"
                                                name="selectedPipline"
                                                id="Pipeline"
                                                value={selectedPipline}
                                                onChange={this.handleChange}
                                            >
                                                <MenuItem value={"Sales"}>Sales</MenuItem>
                                                <MenuItem value={"BusinessDevelopment"}>Business Development</MenuItem>
                                            </Select>
                                        </div>
                                        <div className="report_filter">
                                            <InputLabel id="Saved-Filter-select-helper-label">Saved Filter</InputLabel>
                                            <Select
                                                className="w-200"
                                                labelId="Saved-Filter-select-label"
                                                id="Saved-Filter-select"
                                                value={selectedFilter}
                                                name="selectedFilter"
                                                onChange={this.handleChange}
                                            >
                                                <MenuItem value={"1"}>Open Opportunities</MenuItem>

                                            </Select>
                                        </div>
                                        <div className="cursor-pointer report_date_picker w-200" onClick={this.toggle}>
                                            <InputLabel className="mb-10" onClick={this.toggle} id="">Close Date</InputLabel>
                                            {this.state.selectedDate}
                                        </div>
                                        <div className="">
                                            <RadioGroup className="mt-10" row aria-label="gender" name="durationtype" value={durationtype} onChange={this.handleChange}>
                                                <FormControlLabel value="1" control={<Radio color="primary" />} label="Daily" />
                                                <FormControlLabel value="2" control={<Radio color="primary" />} label="Weekly" />
                                                <FormControlLabel value="3" control={<Radio color="primary" />} label="Monthly" />
                                                <FormControlLabel value="4" control={<Radio color="primary" />} label="Quarterly" />
                                            </RadioGroup>
                                        </div> */}
                                    <div className="">
                                        <RadioGroup className="ml-16 mt-10" row aria-label="gender" name="value_type" value={value_type} onChange={this.handleChange}>
                                            <FormControlLabel value="1" control={<Radio color="primary" />} label="Revenue" />
                                            <FormControlLabel value="2" control={<Radio color="primary" />} label="Count of Deals" />
                                        </RadioGroup>
                                    </div>
                                    <div className="crm-all-report-filters-daterange">
                                        <DateRangePicker
                                            open={dateopen}
                                            toggle={this.toggle}
                                            onChange={(range) => this.setDateRange(range)}
                                        />
                                    </div>

                                    {/* </Grid> */}
                                </div>
                                <div className="report_content">
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={options}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
                <Drawer
                    width={"100px"}
                    variant="temporary"
                    anchor={"right"}
                    className="drawer_right"
                    open={drawerOpen}
                    onClose={this.handleCloseDrawer}
                    ModalProps={{ keepMounted: true }}
                >
                    <React.Fragment>
                        <div className="frame_body">
                            <div className="filter_Drawer_header">
                                <Grid container justify="space-between" alignItems="center">
                                    <Grid item>
                                        <IconButton size="small" className="ml-10"
                                            onClick={this.handleCloseDrawer}><Icon>arrow_forward</Icon>
                                        </IconButton></Grid>
                                    <Grid item>
                                        <h4 className="m-10">Filter Opportunities </h4>
                                    </Grid>
                                    <Grid item className="m-10">
                                        <Button type="button" className="btn_Primary mb-10 mt-10 px-12" size="small" >Clear Filter</Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </React.Fragment>
                </Drawer>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
    notification: state.notification,
    reportReducer: state.reportReducer,
});
const mapDispatchToProps = dispatch => {
    return {
        salesBySourceReport: (client, value) => dispatch(actions.salesBySourceReport(client, value)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SalesBySource));