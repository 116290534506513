import { Icon } from '@material-ui/core';
import React from 'react';
export default function LoadingState(props) {
  return (
    <div className="text-center">
      <div className="empty_goal">
        <Icon>{props.icon}</Icon>
      </div>
      <div className="font-size-20 font-weight-600 text-hint text_">{props.title}</div>
      <div className="">{props.subtitle}</div>
    </div>
  );
}
