import React, { Component } from "react";
import { Fab, Grid, withStyles, Switch, InputLabel, FormControl, Input, FormHelperText, RadioGroup, Radio, FormControlLabel, Divider, DialogContent, InputAdornment, Button, IconButton, Icon, MenuItem, Card, TextField, Dialog, DialogActions, DialogTitle, Drawer, Avatar } from "@material-ui/core";
import ProjectProfileFrame from "../Projects/ProjectProfileFrame";
import CreateNewProjectDialog from "../Projects/CreateNewProjectDialog";
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { NavLink, withRouter } from "react-router-dom";
import { SingleSelect } from "react-select-material-ui";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Link, } from "react-router-dom";
import ProjectStage from "./ProjectStage";
import ProjectStatus from "./ProjectStatus";
const formatCreateLabel = (value) => `Create "${value}"`;
const isValidNewOption = (value) => value != "";
const IconSmall = withStyles(() => ({
  root: {
    fontSize: "1.5rem",
    color: 'rgba(0, 0, 0, 0.6)'
  }
}))(Icon);
const filter = createFilterOptions();


class Project extends Component {
  state = {
    attachment: null,
    drawerOpenProject: false,
    showAddProject: false,
    project_id: "",
    projectListArray: [],
    allProjectList: [],
    createNewprojectName: "",
    filterdata: [],
    removeProjectPopup: false,
    shouldOpenCreateProjectDialog: false,
    FullCreateProjectDialog: false,
    minimizeProjectDialog: false,
    type: "projects",
    recordtype: "",
    newProjectData: {},
    compnayCrud: {
      isaccess: true,
      iscreate: true,
    },
    isDataLoading2: true,
    isFlag: 0,
  };

  componentWillUnmount() {
    this.props.settingReducer.relatedDataProject = [];
  }

  componentDidMount() {
    this.props.getProjectList(this.props.apolloClient.client);
    this.props.getUserIntegration(this.props.apolloClient.client);

    if (this.props.recordId) {
      this.setState({
        recordId: this.props.recordId,
        recordtype: this.props.recordtype,
      }, () => {
        this.forceUpdate()
        var req = {
          id: this.props.recordId,
          type: this.state.type,
          recordtype: this.props.recordtype,
        }
        this.props.getRecordRelatedData(this.props.apolloClient.client, req);
      });
    }
  }
  getUserPermission = () => {
    if (this.props.settingReducer.userPermissionDetails.data) {
      let data = this.props.settingReducer.userPermissionDetails.data.data;
      if (data.teampermissionstatus === true &&
        data.visibilityaccess.length > 0) {
        let compnayCrud = "";
        if (this.props.authReducer.userRole.id === 3) {
          compnayCrud = data.visibilityaccess.find(function (e) { return e.sourcetype === 5 });
        }
        this.setState({
          compnayCrud: compnayCrud === "" ? this.state.compnayCrud : compnayCrud,
        })
      }
    }
  }
  componentWillReceiveProps(props) {
    this.getUserPermission()
    if (props.recordId != this.state.recordId) {//if(props.recordId !== undefined && this.state.oppoFlag){
      this.setState({
        recordId: props.recordId,
        recordtype: props.recordtype,
      }, () => {
        this.forceUpdate()
        var req = {
          id: props.recordId,
          type: this.state.type,
          recordtype: props.recordtype,
        }
        this.props.getRecordRelatedData(this.props.apolloClient.client, req);
      });
    }
    if (props.projectReducer.projectFieldData.data && props.projectReducer.projectFieldData.is_loaded) {
      this.setState({
        isDataLoading: false,
      }, () => this.forceUpdate())
    }


    if (props.settingReducer.relatedDataProject.data && props.settingReducer.relatedDataProject.data.data) {
      props.settingReducer.relatedDataProject.is_loaded = false
      this.setState({
        projectListArray: props.settingReducer.relatedDataProject.data.data,
      }, () => this.forceUpdate());
      if (props.settingReducer.relatedDataProject.data.data.length > 0) {
        this.setState({ isOpenedProject: true });
      } else {
        this.setState({ isOpenedProject: false });
      }
    } else {
      this.setState({
        projectListArray: [],
        isOpenedProject: false,
      }, () => this.forceUpdate());
    }

    if (props.settingReducer.projectRecList && props.settingReducer.projectRecList.data && props.settingReducer.projectRecList.data.data) {
      let projectOptions = [];
      props.settingReducer.projectRecList.data.data.map((opt) => {
        projectOptions.push({ label: opt.name, value: opt.project_id });
      })
      this.setState({ allProjectList: projectOptions });
    }

  }

  toggleProject = () => {
    this.setState(oldStateProject => ({ isOpenedProject: !oldStateProject.isOpenedProject }));
    if (this.state.isOpenedProject) {
      this.setState({
        showAddProject: false
      }, () => this.forceUpdate());
    }
  }

  /* START cmp dialoge */

  handleProjectDialogClose = () => {
    this.setState({ shouldOpenCreateProjectDialog: false, FullCreateProjectDialog: false, minimizeProjectDialog: false });
  };

  handleProjectDialogMinimize = () => {
    this.setState(oldStateminimizeProjectDialog => ({ minimizeProjectDialog: !oldStateminimizeProjectDialog.minimizeProjectDialog }));
    this.setState({ FullCreateProjectDialog: false })
  };

  handleProjectDialogFull = () => {
    this.setState(oldStateFullCreateProjectDialog => ({ FullCreateProjectDialog: !oldStateFullCreateProjectDialog.FullCreateProjectDialog }));
    this.setState({ minimizeProjectDialog: false })

  };

  /* END cmp dialoge */
  closeDrawerRight = (e) => {
    this.setState({
      isDataLoading: true,
    });

    setTimeout(() => {
      this.setState({
        drawerOpenProject: false,
        isDataLoading: false,
        isFlag: 1
      }, () => {
        this.forceUpdate()
      })
    }, 2500);

    let recordtype = this.state.FlagType;
    let FlagId = this.state.FlagId;

    // if (recordtype !== "project") {
    let calendarevents = {
      id: parseFloat(FlagId),
      type: 'calendarevents',
      recordtype: recordtype,
    }
    this.props.getRecordRelatedData(this.props.apolloClient.client, calendarevents);
    let projects = {
      id: parseFloat(FlagId),
      type: 'projects',
      recordtype: recordtype,
    }
    this.props.getRecordRelatedData(this.props.apolloClient.client, projects);
    // }
    let tasks = {
      id: parseFloat(FlagId),
      type: 'tasks',
      recordtype: recordtype,
    }
    this.props.getRecordRelatedData(this.props.apolloClient.client, tasks);
    let files = {
      id: parseFloat(FlagId),
      type: 'files',
      recordtype: recordtype,
    }
    this.props.getRecordRelatedData(this.props.apolloClient.client, files);
    // if (recordtype !== "contact") {
    // if (recordtype !== "company") {
    let companies = {
      id: parseFloat(FlagId),
      type: 'companies',
      recordtype: recordtype,
    }
    this.props.getRecordRelatedData(this.props.apolloClient.client, companies);
    // }
    let contact = {
      id: parseFloat(FlagId),
      type: 'contact',
      recordtype: recordtype,
    }
    this.props.getRecordRelatedData(this.props.apolloClient.client, contact);
    // }
    // if (recordtype !== "opportunities") {
    let opportunities = {
      id: parseFloat(FlagId),
      type: 'opportunities',
      recordtype: recordtype,
    }
    this.props.getRecordRelatedData(this.props.apolloClient.client, opportunities);
    // }
  };
  toggleAddProject = () => {
    this.setState({
      isOpenedProject: true,
      showAddProject: !this.state.showAddProject
    });
  }
  handleDrawerProjectRight = (project_id) => {
    if (this.state.project_id && (this.state.project_id === project_id)) { } else {
      this.setState({
        project_id: project_id,
        isDataLoading: true,
        FlagId: this.state.recordId,
        FlagType: this.state.recordtype,
      }, () => {
        this.forceUpdate()
      })
    }

    setTimeout(() => {
      this.setState({
        drawerOpenProject: true,
      }, () => {
        this.forceUpdate()
      })
    }, 3000);

    if (this.state.isFlag > 0) {
      let calendarevents = {
        id: parseFloat(project_id),
        type: 'calendarevents',
        recordtype: this.state.type,
      }
      this.props.getRecordRelatedData(this.props.apolloClient.client, calendarevents);
      let req = {
        id: parseFloat(project_id),
        type: 'contact',
        recordtype: this.state.type,
      }
      this.props.getRecordRelatedData(this.props.apolloClient.client, req);
      let opportunities = {
        id: parseFloat(project_id),
        type: 'opportunities',
        recordtype: this.state.type,
      }
      this.props.getRecordRelatedData(this.props.apolloClient.client, opportunities);
      let tasks = {
        id: parseFloat(project_id),
        type: 'tasks',
        recordtype: this.state.type,
      }
      this.props.getRecordRelatedData(this.props.apolloClient.client, tasks);
      let files = {
        id: parseFloat(project_id),
        type: 'files',
        recordtype: this.state.type,
      }
      this.props.getRecordRelatedData(this.props.apolloClient.client, files);
      let companies = {
        id: parseFloat(project_id),
        type: 'companies',
        recordtype: this.state.type,
      }
      this.props.getRecordRelatedData(this.props.apolloClient.client, companies);
      let projects = {
        id: parseFloat(project_id),
        type: 'projects',
        recordtype: this.state.type,
      }
      this.props.getRecordRelatedData(this.props.apolloClient.client, projects);
    }

  };

  handleProjectLaunch = (project_id) => {
    this.props.history.push('/project/projects/' + project_id)
  };

  handleRemoveProject = (project_id) => {
    this.setState({
      removeProjectPopup: true,
      deleteProjectId: project_id,
    }, () => {
      this.forceUpdate()
    })
  };

  toggleRemoveProjectPopup = () => {
    if (this.state.removeProjectPopup) {
      this.setState({
        deleteProjectId: "",
      }, () => {
        this.forceUpdate()
      })
    }
    this.setState({
      removeProjectPopup: !this.state.removeProjectPopup,
    }, () => {
      this.forceUpdate()
    })

  };
  confirmremoveProjectPopup = () => {
    if (this.state.deleteProjectId) {
      if (this.state.recordtype == "opportunities") {
        var req = {
          projectid: parseInt(this.state.deleteProjectId),
          opportunitiesid: parseInt(this.state.recordId),
        }
        this.props.deleteProjectOpportunities(this.props.apolloClient.client, req)
      }
      if (this.state.recordtype == "contact") {
        var req = {
          projectid: parseInt(this.state.deleteProjectId),
          contactid: parseInt(this.state.recordId),
        }
        this.props.deleteProjectContact(this.props.apolloClient.client, req)
      }
      if (this.state.recordtype == "company") {
        var req = {
          projectid: parseInt(this.state.deleteProjectId),
          companyid: parseInt(this.state.recordId),
        }
        this.props.deleteProjectCompany(this.props.apolloClient.client, req)
      }
      this.setState({
        deleteProjectId: "",
        removeProjectPopup: false,
      }, () => {
        this.forceUpdate()
      })
    }
  };

  handleProjectChange = (val, newValue) => {
    if (newValue) {
      let id = (newValue.__isNew__) ? "0" : newValue.value;
      if (id == "0") {
        var data = {
          recordtype: this.state.recordtype,
          type: this.state.type,
          id: parseInt(this.state.recordId),
        }
        this.setState({
          newProjectData: data,
          createNewprojectName: newValue.label
        }, () => {
          this.forceUpdate()
          this.setState({
            shouldOpenCreateProjectDialog: true,
          })
        })
      } else {
        var isAlreadyAdded = this.state.projectListArray.filter(function (element) {
          if (element.project.id == id) {
            return element
          }
        });
        if (isAlreadyAdded.length > 0) {
          this.setState({ showAddProject: false });
          return
        }
        let data = { srno: "1", id: id, value: newValue.label };
        if (this.state.recordtype == "opportunities") {
          var req = {
            opportunitiesdetail: { id: parseInt(this.state.recordId) },
            projectid: parseInt(id),
          }
          this.props.addProjectOpportunities(this.props.apolloClient.client, req)
        }
        if (this.state.recordtype == "contact") {
          var req = {
            contactdetail: { id: parseInt(this.state.recordId) },
            projectid: data.id,
          }
          this.props.addProjectContact(this.props.apolloClient.client, req)
        }
        if (this.state.recordtype == "company") {
          var req = {
            companydetail: { id: parseInt(this.state.recordId) },
            projectid: parseInt(id),
          }
          this.props.addProjectCompany(this.props.apolloClient.client, req)
        }
      }
      this.setState({ showAddProject: false });
    }
  };

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }
  render() {
    let { drawerOpenProject, isOpenedProject, isDataLoading2 } = this.state;
    /*STRAT project Display*/
    let displayProjectList = []
    {
      this.state.projectListArray.map((item, key) => {
        var projectimg = ""
        var stageOptions = []
        var oldStageId = ""
        var oldStatusId = ""
        var statusOptions = []
        if (item.project.image) {
          projectimg = item.project.image
        }
        if (item.project.__projectdetail__.length > 0) {
          var cName = ""
          var relatedToID = ""
          var relatedToType = ""
          var relatedToValue = ""
          var getRelatedReq = {}
          item.project.__projectdetail__.map((data, key1) => {
            getRelatedReq = {
              id: parseInt(this.state.recordId),
              type: this.state.type,
              recordtype: this.state.recordtype,
            }
            if (data.__field__.id == "67") {//name
              cName = data.fieldvalue;
            }
            if (data.__field__.id == "68" && data.values.length > 0) {//related to
              relatedToID = data.values[0].id
              relatedToType = data.values[0].type
              relatedToValue = data.values[0].value
            }
            if (data.__field__.id == "75") {//stage
              oldStageId = data.values[0].id;
              stageOptions = data.__field__.options
            }
            if (data.__field__.id == "70") {//status
              oldStatusId = data.values[0].id;
              statusOptions = data.__field__.options
            }

          })
          displayProjectList.push(
            <div className="box_portlet mt-10">
              <div className="box_body p-10 box_action">
                <Grid container className="mb-10 lead_pl" style={{ 'position': 'inherit', 'paddingLeft': '0px' }}>
                  <Grid item>
                    <Fab
                      size="small"
                      aria-label="Task"
                      onClick={() => this.handleDrawerProjectRight(item.project.id)}
                      className={`wrap_icons_task_sidebar vert-middle bg-grey_sidebar`}
                    >
                      <Icon className="activity_icon_normal">business_center</Icon>
                    </Fab>
                  </Grid>
                  <Grid item className="flex-auto pl-10" style={{ width: '50%' }}>
                    <div className="font-size-13 cursor_poiter" onClick={() => this.handleDrawerProjectRight(item.project.id)}><span className="font-weight-500 text_ellipsis" style={{ width: '100%' }}>{cName}</span></div>
                    <div className="font-size-13"><span className="related-text">{relatedToID && relatedToType && <Link to={"/" + relatedToType + "/" + relatedToType + "/" + relatedToID}>{relatedToValue}</Link>}</span></div>
                  </Grid>
                </Grid>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <div className="font-size-13"><span className="font-weight-500">Status</span> <ProjectStatus getRelatedReq={getRelatedReq} statusOptions={statusOptions} projectid={item.project.id} oldStatusId={oldStatusId} /></div>
                  </Grid>
                  <Grid item>
                    <div className="font-size-13"><span className="font-weight-500">Stage</span> <ProjectStage getRelatedReq={getRelatedReq} stageOptions={stageOptions} projectid={item.project.id} oldStageId={oldStageId} /></div>
                  </Grid>
                </Grid>
                <div className="hover_show" style={{ marginBottom: '61px' }}>
                  <IconButton aria-label="Launch" size="small" onClick={() => this.handleProjectLaunch(item.project.id)}>
                    <IconSmall style={{ fontSize: '18px' }}>launch</IconSmall>
                  </IconButton>
                  <IconButton aria-label="Delete" size="small" onClick={() => this.handleRemoveProject(item.project.id)}>
                    <IconSmall style={{ fontSize: '18px' }}>close</IconSmall>
                  </IconButton>
                </div>
              </div>
            </div>
          )
        }

      })
    }
    /*END project Display*/

    return (
      <Card elevation={3} className="p-16 mt-16" style={{ overflow: 'initial' }}>
        <Grid container justify="space-between">
          <h5 className="flex-auto cursor_poiter mb-0 pt-4" onClick={this.toggleProject}>Projects ({displayProjectList.length}) <Icon className="vert-middle">{isOpenedProject ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon></h5>
          <IconButton aria-label="Add" size="small">
            <IconSmall onClick={() => this.toggleAddProject()} className={this.state.showAddProject ? "plusIconTransform" : ""}>add</IconSmall>
          </IconButton>
        </Grid>
        {isOpenedProject &&
          <>
            {this.state.showAddProject &&
              <FormControl className="w-100 input_bx" style={{ zIndex: 10000 }}>
                <SingleSelect
                  className="add_singleselection"
                  SelectProps={{
                    isCreatable: this.state.compnayCrud.isaccess && this.state.compnayCrud.iscreate ? true : false,
                    formatCreateLabel,
                    isValidNewOption,
                  }}
                  placeholder="Add Project"
                  options={this.state.compnayCrud.isaccess && this.state.allProjectList}
                  onChange={this.handleProjectChange}
                />
              </FormControl>

            }
            {(!this.state.showAddProject && displayProjectList.length == 0) &&
              <label className="text-hint">
                <IconButton className="mr-8" component="span">
                  <Icon onClick={() => this.toggleAddProject()}>business_center</Icon>
                </IconButton>Project
              </label>
            }
            {displayProjectList}

          </>
        }
        {this.state.shouldOpenCreateProjectDialog && (
          <CreateNewProjectDialog
            handleClose={this.handleProjectDialogClose}
            open={this.state.shouldOpenCreateProjectDialog}
            handleMinimize={this.handleProjectDialogMinimize}
            handleFull={this.handleProjectDialogFull}
            minimizeScreen={this.state.minimizeProjectDialog}
            fullScreen={this.state.FullCreateProjectDialog}
            name={this.state.createNewprojectName}
            dataFromSidebar={this.state.newProjectData}
          />
        )}

        <Dialog
          fullWidth={true}
          maxWidth={'xs'}
          open={this.state.removeProjectPopup}
          onClose={this.toggleRemoveProjectPopup}
          disableBackdropClick
          aria-labelledby="ar-remove-dialog-title"
        >
          <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to remove the Associated Project?"}</DialogTitle>
          <DialogActions>
            <Button onClick={this.toggleRemoveProjectPopup} color="primary">
              Cancel
            </Button>
            <Button type="Button" onClick={() => this.confirmremoveProjectPopup()} className="btn_Primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Drawer
          width={"100px"}
          variant="temporary"
          anchor={"right"}
          className="drawer_right"
          open={drawerOpenProject}
          onClose={this.closeDrawerRight}
          ModalProps={{
            keepMounted: true
          }}
        >
          {this.state.project_id && <ProjectProfileFrame project_id={parseInt(this.state.project_id)} handleClose={this.closeDrawerRight} />}
        </Drawer>
        {this.state.isDataLoading &&
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={this.state.isDataLoading}
            TransitionComponent={Fade}
            message={<><CircularProgress size={20} style={{ color: 'white' }} /><span style={{ paddingLeft: '6px', color: 'white' }}>{" Loading..."}</span></>}
            variant={'success'}
          />
        }
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
  projectReducer: state.projectReducer,
  authReducer: state.authReducer
});

const mapDispatchToProps = dispatch => {
  return {
    getProjectList: (client) => { dispatch(actionCreators.getProjectList(client)) },
    addProjectOpportunities: (client, req) => { dispatch(actionCreators.addProjectOpportunities(client, req)) },
    deleteProjectOpportunities: (client, req) => { dispatch(actionCreators.deleteProjectOpportunities(client, req)) },
    getRecordRelatedData: (client, req) => { dispatch(actionCreators.getRecordRelatedData(client, req)) },
    addProjectContact: (client, req) => { dispatch(actionCreators.addProjectContact(client, req)) },
    deleteProjectContact: (client, req) => { dispatch(actionCreators.deleteProjectContact(client, req)) },
    addProjectCompany: (client, req) => { dispatch(actionCreators.addProjectCompany(client, req)) },
    deleteProjectCompany: (client, req) => { dispatch(actionCreators.deleteProjectCompany(client, req)) },
    getUserIntegration: (client) => dispatch(actionCreators.getUserIntegration(client)),
  }
}


export default (connect(mapStateToProps, mapDispatchToProps)(withRouter(Project)));
