import React, { Component } from "react";
import { Button, IconButton, Icon, withStyles, Card ,DialogContent, Dialog,DialogActions,DialogTitle} from "@material-ui/core";
import { default as MaterialGrid } from '@material-ui/core/Grid';
import SettingSidebar from '../Layout/SettingSidebarMenu'
import AddNewPipeline from './AddNewPipeline'
import {
    Grid,
    DragDropProvider,
    Table,
    TableHeaderRow,
    Toolbar,
    VirtualTable,
    TableColumnVisibility,
    TableColumnReordering,
    PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import {
    SortingState,
    SearchState,
    IntegratedSorting,
    IntegratedFiltering,
    SelectionState,
    IntegratedSelection,
    PagingState,
    IntegratedPaging,
} from '@devexpress/dx-react-grid';
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';


const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.5rem",
    }
}))(Icon);
class SettingPipeline extends Component {
    state = {
        shouldOpenNewPipelineDialog: false,
        FullNewPipelineDialog: false,
        minimizePipelineDialog: false,
        defaulSortingData: [
            { columnName: 'pipelinename', direction: 'asc' },
            { columnName: 'stages', direction: 'desc' },
            { columnName: 'visibility', direction: 'desc' },
            { columnName: 'opportunities', direction: 'desc' },
            { columnName: 'actions', direction: 'desc' }
        ],
        selection: [],
        pipelineListData: [],
        isPipelineDeleteConfirmDialogOpen:false,
        deltePiplineId:'',
        editPiplineData:'',
    };

    componentWillReceiveProps(props){
        //getting pipleline data for update when click on edit icon and load in popup
        if(props.settingReducer.singlePipelineData.data && props.settingReducer.singlePipelineData.data.id){
            var data = props.settingReducer.singlePipelineData.data;
            this.setState({ 
                editPiplineData: data, 
                shouldOpenNewPipelineDialog: true, 
            },()=>{
                this.forceUpdate()
                props.settingReducer.singlePipelineData.data = {}
            })
        }
        if (props.settingReducer.pipelineListArray.data) {
            const pipelineListArray = props.settingReducer.pipelineListArray.data;
            var dataList = [];
            
            var promise = new Promise((resolve, reject) => {
                pipelineListArray.map((v,i)=>{
                    dataList.push(
                        {
                            pipelinename: v.name,
                            stages: v.stage.length,
                            // visibility: "1 Teams",
                            opportunities: "1",
                            actions:<React.Fragment>
                                        <IconButton aria-label="edit" size="small" onClick={()=>this.updatePipeline(v.id)}>
                                            <IconSmall>edit</IconSmall>
                                        </IconButton>
                                        <IconButton aria-label="delete" disabled={(v.id=="1")?true:false} onClick={()=>this.opneDeletePipelineDialog(v.id)}>
                                            <IconSmall>delete</IconSmall>
                                        </IconButton>
                                    </React.Fragment>
                        }
                    )
                })
                resolve(true)     
            });

            promise.then( result => {
                this.setState({ 
                    pipelineListData: dataList 
                },()=>{
                    this.forceUpdate()
                })
            });
            
            

        }
    }

    componentDidMount() {
        this.props.getPipelines(this.props.apolloClient.client);
    }

    toggleLeadList = () => {
        this.setState(oldStateLeadList => ({ isOpenedLeadList: !oldStateLeadList.isOpenedLeadList }));
    }

    toggleCustomFilters = () => {
        this.setState(oldStateCustomFilters => ({ isOpenedCustomFilters: !oldStateCustomFilters.isOpenedCustomFilters }));
    }

    toggleRange = (e) => {
        e.preventDefault();
        this.setState(oldStateRange => ({ isOpenedRange: !oldStateRange.isOpenedRange }));
    }

    changeDefaultSorting = (val) => {
        let data = this.state.defaulSortingData;
        data = data.filter(sort => sort.columnName !== val[0].columnName).map(sort => {
            sort.direction = "desc"
            return sort;
        })
        data.push(val[0]);
        this.setState({
            defaulSortingData: data
        }, () => this.forceUpdate)
    }
    handlePipelineDialogClose = () => {
        this.setState({ shouldOpenNewPipelineDialog: false, FullNewPipelineDialog: false, minimizePipelineDialog: false ,editPiplineData:'',});
    };
    handlePipelineDialogMinimize = () => {
        this.setState(oldStateminimizePipelineDialog => ({ minimizePipelineDialog: !oldStateminimizePipelineDialog.minimizePipelineDialog }));
        this.setState({FullNewPipelineDialog: false})
    };
    handlePipelineDialogFull = () => {
        this.setState(oldStateFullNewPipelineDialog => ({ FullNewPipelineDialog: !oldStateFullNewPipelineDialog.FullNewPipelineDialog }));
        this.setState({minimizePipelineDialog: false})

    }
    setSelection = (val) => {
        this.setState({
            selection: val
        })
    }

    opneDeletePipelineDialog = (delId) =>{
        this.setState({
            deltePiplineId: delId,
            isPipelineDeleteConfirmDialogOpen:true
        })
    }
    closeDeletePipelineDialog = () => {
        this.setState({ 
            isPipelineDeleteConfirmDialogOpen: false,
            deltePiplineId: '',
        });
    };
    confirmDeletePipeline = () => {
        
        let req = {
            pipelinesid:this.state.deltePiplineId,
        }

        this.props.deletePipelines(this.props.apolloClient.client,req);
        this.closeDeletePipelineDialog()
    };
    updatePipeline = (updateId) => {
        let req = {
            pipelinesid:parseInt(updateId),
        }

        this.props.getPipelinesDetail(this.props.apolloClient.client,req);
        // this.setState({ 
        //     shouldOpenNewPipelineDialog: true,
        //     editPiplineData:updateId,
        // });
    };
    render() {
        let {shouldOpenNewPipelineDialog, minimizePipelineDialog,FullNewPipelineDialog,pipelineListData } = this.state;
        const tableColumnExtensions = [
            { columnName: 'rating', width: 40 },
            { columnName: 'pipelinename', width: 250 },

        ];

        const columns = [
            {
                name: "pipelinename",
                title: "Name"
            },
            {
                name: "stages",
                title: "Stages"
            },
            // {
            //     name: "visibility",
            //     title: "Visibility"
            // },
            {
                name: "opportunities",
                title: "Opportunities"
            },
            {
                name: "actions",
                title: "Actions"
            }
        ];

        const toolbarTemplate = params => {
            return (
                <div className="d-flex mt-16 mb-16">
                    <MaterialGrid container justify="space-between">
                        <MaterialGrid>
                            <MaterialGrid container justify="space-between">
                                <MaterialGrid>
                                    <h3 className="ml-16">Pipelines</h3>
                                </MaterialGrid>
                            </MaterialGrid>
                        </MaterialGrid>
                        <MaterialGrid>
                            <div className="d-inline-block ml-8 mr-16">
                                <Button variant="contained" color="primary" className="btn_Primary" onClick={() => this.setState({ shouldOpenNewPipelineDialog: true })} size="small">
                                    Add New Pipeline
                                </Button>
                                {shouldOpenNewPipelineDialog && (
                                    <AddNewPipeline
                                        handleClose={this.handlePipelineDialogClose}
                                        open={shouldOpenNewPipelineDialog}
                                        handleMinimize={this.handlePipelineDialogMinimize}
                                        handleFull={this.handlePipelineDialogFull}
                                        minimizeScreen={minimizePipelineDialog}
                                        fullScreen={FullNewPipelineDialog}
                                        pipelineEditData={this.state.editPiplineData}
                                    />
                                )}
                            </div>
                        </MaterialGrid>
                    </MaterialGrid>
                </div>
            );
        };

        const defaultHiddenColumnNames = ['lastName'];
        const tableColumnVisibilityColumnExtensions = [
            { columnName: 'pipelinename', togglingEnabled: false },
        ];

        return (
            <React.Fragment>
                <SettingSidebar />
                <div className="lead_content right-16 settings_view">
                    <Card elevation={3} className="h-100 table__scroll pipeline_list_table">
                        <div className="leads_list_table">
                            <Grid rows={pipelineListData} columns={columns}>
                                <SelectionState
                                    selection={this.state.selection}
                                    onSelectionChange={this.setSelection}
                                />
                                <IntegratedSelection />
                                <VirtualTable />
                                <SearchState defaultValue="" />
                                <IntegratedFiltering />
                                <SortingState
                                    defaultSorting={this.state.defaulSortingData}
                                    onSortingChange={(val => { this.changeDefaultSorting(val) })}
                                />
                                <IntegratedSorting />
                                <DragDropProvider />
                                <PagingState
                                    defaultCurrentPage={0}
                                    pageSize={10}
                                />
                                <IntegratedPaging />
                                <Table columnExtensions={tableColumnExtensions} />
                                <TableColumnReordering
                                    defaultOrder={['stages', 'visibility', 'opportunities', 'actions']}
                                    onOrderChange={(val) => { console.log(val) }}
                                />
                                <TableHeaderRow showSortingControls />

                                <PagingPanel
                                    pageSizes={[5, 10, 15, 0]}
                                />
                                {/*<TableFixedColumns
                                leftColumns={leftColumns}
                            />*/}
                                <Toolbar rootComponent={toolbarTemplate} />
                                <TableColumnVisibility
                                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                                    columnExtensions={tableColumnVisibilityColumnExtensions}
                                />
                            </Grid>
                        </div>
                    </Card>
                </div>

                <Dialog
                    fullWidth={true}
                    maxWidth={'xs'}
                    open={this.state.isPipelineDeleteConfirmDialogOpen}
                    onClose={this.closeDeletePipelineDialog}
                    disableBackdropClick
                    // disableEscapeKeyDown
                    aria-labelledby="ar-remove-dialog-title"
                >
                    <DialogTitle id="ar-remove-dialog-title">{"Are you sure to remove this pipeline ?"}</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.closeDeletePipelineDialog} color="primary">Cancel</Button>
                        <Button type="Button" onClick={() => this.confirmDeletePipeline()} className="btn_Primary">Ok</Button>

                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
});
const mapDispatchToProps = dispatch => {
  return {
    getPipelines: (client) => dispatch(actionCreators.getPipelines(client)),
    deletePipelines: (client,req) => dispatch(actionCreators.deletePipelines(client,req)),
    clearNotification: () => dispatch(actionCreators.clearNotification()),
    getPipelinesDetail: (client,req) => dispatch(actionCreators.getPipelinesDetail(client,req)),
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingPipeline);