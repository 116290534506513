import React, { Component } from 'react';

class Chrome extends Component {
   constructor(props) {
      super(props);
      this.state = {

      }
   }

   componentDidMount() {
      const axios = require('axios');
      const queryString = require('query-string');

      const parsed = queryString.parse(this.props.location.search);

      if (parsed.code != undefined) {
         let code = parsed.code;
         //request for google login with token
         //live url new - https://restapi.idrivecrm.com/
         //dev url  - https://kanhasoftcrm.club:1236/
         
         axios.post('https://kanhasoftcrm.club:1236/GoogleLoginUser/GoogleLogin/', {
            "code": code
         })
            .then(response => {
               console.log('with code = ' + response.data.data);

               let data = { 'data': response.data.data, 'is_crm': true }
               window.opener.postMessage(data, '*');
               window.close();
            })
            .catch(error => {
               console.log('Error fetching and parsing data', error);
            });
      }
      else {
         //request for google auth
         window.open('https://kanhasoftcrm.club:1236/auth/google/', '_self', 'height=500, width=500');
      }
   }

   render() {

      return (
         <>
         </>
      );
   }
}

export default Chrome;