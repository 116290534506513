import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { NavLink, withRouter } from "react-router-dom";
import { IconButton, MenuItem, Avatar, Link, Tooltip, Grid,withStyles } from "@material-ui/core";

import { navigations } from "../navigations";
import TopSearchBox from "./TopSearchBox";
import DropdownMenu from "./DropdownMenu";
import DropdownDefault from "./DropdownDefault";

// notifivation darwer
import NotificationAppbar from "./NotificationAppBar";

//user profile dialog box 
import UserProfileDialog from "../Profile/profile";

//components
import CreateNewLeadDialog from "../Leads/CreateNewLeadDialog";
import CreateNewContactDialog from "../Contacts/CreateNewContactDialog";
import CreateNewCompanyDialog from "../Company/CreateNewCompanyDialog";
import CreateNewOpportunityDialog from "../Opportunities/CreateNewOpportunityDialog";
import CreateNewProjectDialog from "../Projects/CreateNewProjectDialog";
import CreateNewTaskDialog from "../Tasks/CreateNewTaskDialog";

//icons
import { Icon, InlineIcon } from '@iconify/react';
import userFriends from '@iconify/icons-fa-solid/user-friends';
import crosshairsIcon from '@iconify/icons-fa-solid/crosshairs';
import handshakeIcon from '@iconify/icons-fa-solid/handshake';
import chartBar from '@iconify/icons-fa-solid/chart-bar';
import bxsEnvelope from '@iconify/icons-bx/bxs-envelope';
import businessTime from '@iconify/icons-fa-solid/business-time';
import cogsIcon from '@iconify/icons-el/cogs';
import plusCircle from '@iconify/icons-fa-solid/plus-circle';
import bxsPalette from '@iconify/icons-bx/bxs-palette';
import BusinessIcon from '@material-ui/icons/Business';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';



// npm install --save @iconify/react @iconify/icons-fa-solid
// npm install --save @iconify/react @iconify/icons-bx
// npm install --save @iconify/react @iconify/icons-el

const styles = theme => ({
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    }
});

const AddOptions = [
    {
        name: "New Lead",
        component: CreateNewLeadDialog,
        icon: "supervised_user_circle",
        isaccess: true,
        sourcetype: 1,
    },
    {
        name: "New Contact",
        component: CreateNewContactDialog,
        icon: "group",
        isaccess: true,
        sourcetype: 2,
    },
    {
        name: "New Company",
        component: CreateNewCompanyDialog,
        icon: "supervised_user_circle",
        isaccess: true,
        sourcetype: 3,
    },
    {
        name: "New Opportunity",
        component: CreateNewOpportunityDialog,
        icon: "business",
        isaccess: true,
        sourcetype: 4,
    },
    {
        name: "New Project",
        component: CreateNewProjectDialog,
        icon: "business_center",
        isaccess: true,
        sourcetype: 5,
    },
    {
        name: "New Task",
        component: CreateNewTaskDialog,
        icon: "list",
        isaccess: true,
        sourcetype: 6,
    },
];

class TopNavbar extends Component {
    state = {
        shouldOpenProfileDialog: false,
        imagePreview: '',
        isloading: true,
        shouldOpenCreateLeadDialog: false,
        shouldOpenCreateContactDialog: false,
        shouldOpenCreateCompanyDialog: false,
        shouldOpenCreateOpportunityDialog: false,
        shouldOpenCreateProjectDialog: false,
        shouldOpenCreateTaskDialog: false,
        FullCreateDialog: false,
        minimizeDialog: false,

        currentPage: 0,
        pageSize: 10,
        sorttype: "",
        type: "list",
        column_name: "",
        searchText: "",
        filterdata: [],
        fieldId: 1,
    }
    componentDidMount() {
        // console.log('going into topnavbar file')
        let userData = JSON.parse(localStorage.getItem('user_data_session'))
        let val = { userid: userData.id }

        //common APIs for all modules
        this.props.getUserSetting(this.props.apolloClient.client, val);
        this.props.getCityList(this.props.apolloClient.client);
        this.props.getStateList(this.props.apolloClient.client);
        this.props.getZipcodeList(this.props.apolloClient.client);
        this.props.getUserList(this.props.apolloClient.client);
        this.props.getCompanyCurrency(this.props.apolloClient.client);
        this.props.getCountryList(this.props.apolloClient.client);
        //-----------------

        this.props.customFieldTypeList(this.props.apolloClient.client);
        this.props.getUserIntegration(this.props.apolloClient.client);
        this.props.getPipelines(this.props.apolloClient.client);
        this.props.getLeadSettings(this.props.apolloClient.client);
    }

    componentWillReceiveProps(props) {
        if (props.settingReducer.leadsettingslist && props.settingReducer.leadsettingslist.data) {
            this.setState({ leadsettingslist: props.settingReducer.leadsettingslist.data });

            let leadsettingsstatus = props.settingReducer.leadsettingslist.data.lead;
            // console.log(leadsettingsstatus,'leadsettingsstatus');
            if (leadsettingsstatus == null || leadsettingsstatus == false) {
                navigations[1].isaccess = false;
                AddOptions[0].isaccess = false;
            } else {
                navigations[1].isaccess = true;
                AddOptions[0].isaccess = true;
            }
        }

        if (props.settingReducer.userPermissionDetails.data) {
            // let leadsettingsstatus = this.state.leadsettingslist.lead;

            this.setState({ isloading: false })

            let udata = props.settingReducer.userPermissionDetails.data.data
            if (udata.visibilityaccess.length > 0 &&
                udata.teampermissionstatus === true &&
                this.props.authReducer.userRole.id === 3) {
                udata.visibilityaccess.map((page, i) => {
                    navigations.find((element) => {
                        if (element.sourcetype === page.sourcetype && element.sourcetype !== 4) {
                            if (page.isaccess === true) {
                                element.isaccess = true
                            } else {
                                element.isaccess = false
                            }
                        }
                    })
                    AddOptions.find((ele) => {
                        if (ele.sourcetype === page.sourcetype) {
                            if (ele.sourcetype !== 4) {
                                if (page.iscreate === true) {
                                    ele.isaccess = true
                                } else {
                                    ele.isaccess = false
                                }
                            }
                        }
                    })
                })
                navigations[4].isaccess = false;
                AddOptions[3].isaccess = false;


                if (props.settingReducer.pipelineListArray && props.settingReducer.pipelineListArray.data) {
                    let pipelines = props.settingReducer.pipelineListArray.data;

                    pipelines.map((pipeline) => {
                        let pil1 = udata.visibilityaccess.find(function (e) { return e.piplineid === parseInt(pipeline.id) });

                        if (pil1 && pil1.isaccess === true) {
                            navigations[4].isaccess = true
                        }

                        if (pil1 && pil1.iscreate === true) {
                            AddOptions[3].isaccess = true
                        }
                    })
                }
                // let pil1 = udata.visibilityaccess.find(function (e) { return e.piplineid === 1 });
                // let pil2 = udata.visibilityaccess.find(function (e) { return e.piplineid === 2 });
                // console.log(navigations[4].isaccess,'navigations[4].isaccess');
            }
            // if(leadsettingsstatus == null || leadsettingsstatus == false){
            //     navigations[1].isaccess = false;
            // }else{
            //     navigations[1].isaccess = true;
            // }
        }
        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            let user_datas = props.settingReducer.userSettingArray.data;
            let profile_image = user_datas.profileimage ? user_datas.profileimage : null;
            this.setState({ imagePreview: profile_image })
        }
    }
    openSetting = () => {
        this.props.history.push("/settings/my-preferences/")
    }
    redirectPage = (url) => {
        window.location.href = url;
    }

    logoutUser = () => {
        localStorage.removeItem('user_data_session')
        localStorage.removeItem('selected_contact_filter_id')
        localStorage.removeItem('selected_lead_filter_id')
        localStorage.removeItem('selected_company_filter_id')
        localStorage.clear();
        window.location.href = '/sign-in'
    }
    handleLeadDialogClose = () => {
        this.setState({ shouldOpenProfileDialog: false });
    };


    handleNewCreate = (module_sourcetype) => {
        if (module_sourcetype === 1) {
            this.setState({
                shouldOpenCreateLeadDialog: true,
                shouldOpenCreateContactDialog: false,
                shouldOpenCreateCompanyDialog: false,
                shouldOpenCreateOpportunityDialog: false,
                shouldOpenCreateProjectDialog: false,
                shouldOpenCreateTaskDialog: false
            });
        }
        if (module_sourcetype === 2) {
            this.setState({
                shouldOpenCreateLeadDialog: false,
                shouldOpenCreateContactDialog: true,
                shouldOpenCreateCompanyDialog: false,
                shouldOpenCreateOpportunityDialog: false,
                shouldOpenCreateProjectDialog: false,
                shouldOpenCreateTaskDialog: false
            });
        }
        if (module_sourcetype === 3) {
            this.setState({
                shouldOpenCreateLeadDialog: false,
                shouldOpenCreateContactDialog: false,
                shouldOpenCreateCompanyDialog: true,
                shouldOpenCreateOpportunityDialog: false,
                shouldOpenCreateProjectDialog: false,
                shouldOpenCreateTaskDialog: false
            });
        }
        if (module_sourcetype === 4) {
            this.setState({
                shouldOpenCreateLeadDialog: false,
                shouldOpenCreateContactDialog: false,
                shouldOpenCreateCompanyDialog: false,
                shouldOpenCreateOpportunityDialog: true,
                shouldOpenCreateProjectDialog: false,
                shouldOpenCreateTaskDialog: false
            });
        }
        if (module_sourcetype === 5) {
            this.setState({
                shouldOpenCreateLeadDialog: false,
                shouldOpenCreateContactDialog: false,
                shouldOpenCreateCompanyDialog: false,
                shouldOpenCreateOpportunityDialog: false,
                shouldOpenCreateProjectDialog: true,
                shouldOpenCreateTaskDialog: false
            });
        }
        if (module_sourcetype === 6) {
            this.setState({
                shouldOpenCreateLeadDialog: false,
                shouldOpenCreateContactDialog: false,
                shouldOpenCreateCompanyDialog: false,
                shouldOpenCreateOpportunityDialog: false,
                shouldOpenCreateProjectDialog: false,
                shouldOpenCreateTaskDialog: true
            });
        }
    }

    handleNewCreateClose = () => {
        this.setState({
            shouldOpenCreateLeadDialog: false,
            shouldOpenCreateContactDialog: false,
            shouldOpenCreateCompanyDialog: false,
            shouldOpenCreateOpportunityDialog: false,
            shouldOpenCreateProjectDialog: false,
            shouldOpenCreateTaskDialog: false,
        });
    };

    handleDialogMinimize = () => {
        this.setState(oldStateminimizeDialog => ({ minimizeDialog: !oldStateminimizeDialog.minimizeDialog }));
        this.setState({ FullCreateTaskDialog: false })
    };

    handleDialogFull = () => {
        this.setState(oldStateFullCreateTDialog => ({ FullCreateDialog: !oldStateFullCreateTDialog.FullCreateDialog }));
        this.setState({ minimizeDialog: false })
    };

    render() {
        let userData = JSON.parse(localStorage.getItem('user_data_session'))
        if (userData.googlestatus && userData.googlestatus === true) {//Only show if google login
            navigations[7].isaccess = true
        } else {
            navigations[7].isaccess = false
        }
        const {
            shouldOpenProfileDialog,
            shouldOpenCreateLeadDialog,
            shouldOpenCreateContactDialog,
            shouldOpenCreateCompanyDialog,
            shouldOpenCreateOpportunityDialog,
            shouldOpenCreateProjectDialog,
            shouldOpenCreateTaskDialog,
            FullCreateDialog,
            minimizeDialog
        } = this.state;

        function renderLevels(levels) {
            return levels.map((item, key) => {
                if (item.isaccess) {
                    return (
                        <li key={key}>
                            <NavLink
                                className={window.location.pathname === item.path ? "selectedmainMenu" : ""}
                                to={item.path}>
                                {item.icon &&
                                    (
                                        <>
                                            {item.name === "Dashboard" && <Icon icon={bxsPalette} style={{ width: '21px', height: '21px', margin: '6px 4px 8px 6px' }} />}
                                            {item.name === "Leads" && <Icon icon={crosshairsIcon} style={{ width: '21px', height: '18px', margin: '6px 4px 8px 6px' }} />}
                                            {item.name === "Contacts" && <Icon icon={userFriends} style={{ width: '21px', height: '22px', margin: '6px 4px 8px 6px' }} />}
                                            {item.name === "Companies" && <BusinessIcon className="" style={{ width: '22px', height: '22px', margin: '4px 4px 8px 6px' }} />}
                                            {item.name === "Opportunities" && <Icon icon={handshakeIcon} style={{ width: '23px', height: '23px', margin: '6px 6px 8px 6px' }} />}
                                            {item.name === "Reports" && <Icon icon={chartBar} style={{ width: '21px', height: '20px', margin: '6px 6px 8px 6px' }} />}
                                            {item.name === "My Tracked Emails" && <Icon icon={bxsEnvelope} style={{ width: '21px', height: '21px', margin: '4px 6px 6px 6px' }} />}
                                            {item.name === "Projects" && <Icon icon={businessTime} style={{ width: '21px', height: '21px', margin: '4px 6px 6px 6px' }} />}
                                            {item.name === "Tasks" && <EventAvailableIcon className="" style={{ width: '22px', height: '22px', margin: '5px 4px 8px 6px' }} />}
                                        </>
                                    )}
                                {item.name}
                            </NavLink>
                        </li>
                    )
                }
            });
        }
        return (
            this.state.isloading ?
                <body style={{ zIndex: '9999' }}>
                    <noscript>You need to enable JavaScript to run this app.</noscript>
                    <div id="root">
                        <div className="loadermain">
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="center"
                            >
                                <img
                                    src="/assets/images/animatedloader.gif"
                                    alt=""
                                    style={{ width: '200px', height: '200px', margin: '250px 0 0 0' }}
                                />
                            </Grid>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>

                </body>
                :
                <div className="navbar" >
                    <div className="container">
                        <div className="d-flex align-items-center h-100">
                            <div className="d-flex align-items-center brand">
                                <img src="/assets/images/headerlogo.png" alt="company-logo" className="header_logo" style={{ width: '95px', height: '30px', marginBottom: '6px' }} />
                            </div>
                            <div className="d-flex flex-auto justify-content-between">
                                <ul className="menu">{renderLevels(navigations)}</ul>
                                <div className="position-relative align-items-center d-flex header_icons" style={{ minWidth: 240 }}>
                                    <TopSearchBox />
                                    {/* <IconButton className="btn-white">
                                        <Icon icon={plusCircle} style={{ width: '20px' }} />
                                    </IconButton> */}
                                    <DropdownMenu
                                        menuButton={
                                            <Icon icon={plusCircle} className="cursor-pointer" style={{ width: '30px', color: 'white', height: '18px', margin: '9px 5px 5px 5px' }} />
                                        }
                                        module="topnavbar"
                                    >
                                        {AddOptions.map((addOption, index) => (
                                            addOption.isaccess &&
                                            <MenuItem key={index} className="flex flex-middle" onClick={() => this.handleNewCreate(addOption.sourcetype)}>
                                                {addOption.name === "New Lead" && <Icon className="mr-16 icon_color" icon={crosshairsIcon} style={{ width: '18px', height: '18px' }} />}
                                                {addOption.name === "New Contact" && <Icon className="mr-16 icon_color" icon={userFriends} style={{ width: '18px', height: '18px', }} />}
                                                {addOption.name === "New Company" && <BusinessIcon className="mr-16 icon_color" style={{ width: '18px', height: '18px', }} />}
                                                {addOption.name === "New Opportunity" && <Icon className="mr-16 icon_color" icon={handshakeIcon} style={{ width: '18px', height: '18px', }} />}
                                                {addOption.name === "New Project" && <Icon className="mr-16 icon_color" icon={businessTime} style={{ width: '18px', height: '18px', }} />}
                                                {addOption.name === "New Task" && <EventAvailableIcon className="mr-16 icon_color" style={{ width: '18px', height: '18px', }} />}
                                                {addOption.name}
                                            </MenuItem>
                                        ))}
                                    </DropdownMenu>
                                    <IconButton className="btn-white" onClick={this.openSetting}>
                                        <Icon icon={cogsIcon} />
                                    </IconButton>
                                    <NotificationAppbar />
                                    <DropdownMenu
                                        menuButton={
                                            <Avatar className="mx-8 text-middle circular-image-small cursor-pointer" alt="Profile Picture" src={this.state.imagePreview} />
                                        }
                                    >
                                        <MenuItem
                                            className="d-flex align-items-center "
                                            style={{ minWidth: 185 }}
                                            onClick={() => this.redirectPage("/")}
                                        >
                                            {/* <Icon> home </Icon> */}
                                            <HomeIcon />
                                            <span className="pl-16"> Home </span>
                                        </MenuItem>
                                        <MenuItem
                                            className="d-flex align-items-center "
                                            style={{ minWidth: 185 }}
                                            onClick={() => this.setState({ shouldOpenProfileDialog: true })}
                                        >
                                            {/* <Icon> person </Icon> */}
                                            <PersonIcon />
                                            <span className="pl-16"> Profile </span>
                                        </MenuItem>
                                        <MenuItem
                                            className="d-flex align-items-center "
                                            style={{ minWidth: 185 }}
                                            onClick={() => this.logoutUser()}
                                        >
                                            <PowerSettingsNewIcon />
                                            <span className="pl-16"> Logout </span>
                                        </MenuItem>
                                    </DropdownMenu>
                                </div>
                            </div>
                        </div>
                    </div>
                    {shouldOpenProfileDialog && (
                        <UserProfileDialog
                            handleClose={this.handleLeadDialogClose}
                            open={shouldOpenProfileDialog}
                        />
                    )}
                    {shouldOpenCreateLeadDialog && (
                        <CreateNewLeadDialog
                            handleClose={this.handleNewCreateClose}
                            open={shouldOpenCreateLeadDialog}
                            handleMinimize={this.handleDialogMinimize}
                            handleFull={this.handleDialogFull}
                            minimizeScreen={minimizeDialog}
                            fullScreen={FullCreateDialog}
                            isFromTopBar={true}
                        />
                    )}
                    {shouldOpenCreateContactDialog && (
                        <CreateNewContactDialog
                            handleClose={this.handleNewCreateClose}
                            open={shouldOpenCreateContactDialog}
                            handleMinimize={this.handleDialogMinimize}
                            handleFull={this.handleDialogFull}
                            minimizeScreen={minimizeDialog}
                            fullScreen={FullCreateDialog}
                            allstates={this.state}
                            isFromTopBar={true}
                        />
                    )}
                    {shouldOpenCreateCompanyDialog && (
                        <CreateNewCompanyDialog
                            handleCloseCompany={this.handleNewCreateClose}
                            openCompany={shouldOpenCreateCompanyDialog}
                            handleMinimizeCompany={this.handleDialogMinimize}
                            handleFullCompany={this.handleDialogFull}
                            minimizeScreenCompany={minimizeDialog}
                            fullScreenCompany={FullCreateDialog}
                            allstates={this.state}
                            isFromTopBar={true}
                        />
                    )}
                    {shouldOpenCreateOpportunityDialog && (
                        <CreateNewOpportunityDialog
                            handleClose={this.handleNewCreateClose}
                            open={shouldOpenCreateOpportunityDialog}
                            handleMinimize={this.handleDialogMinimize}
                            handleFull={this.handleDialogFull}
                            minimizeScreen={minimizeDialog}
                            fullScreen={FullCreateDialog}
                            isFromTopBar={true}
                        />
                    )}
                    {shouldOpenCreateProjectDialog && (
                        <CreateNewProjectDialog
                            handleClose={this.handleNewCreateClose}
                            open={shouldOpenCreateProjectDialog}
                            handleMinimize={this.handleDialogMinimize}
                            handleFull={this.handleDialogFull}
                            minimizeScreen={minimizeDialog}
                            fullScreen={FullCreateDialog}
                            isFromTopBar={true}
                        />
                    )}
                    {shouldOpenCreateTaskDialog && (
                        <CreateNewTaskDialog
                            handleClose={this.handleNewCreateClose}
                            open={shouldOpenCreateTaskDialog}
                            handleMinimize={this.handleDialogMinimize}
                            handleFull={this.handleDialogFull}
                            minimizeScreen={minimizeDialog}
                            fullScreen={FullCreateDialog}
                            isFromTopBar={true}
                        />
                    )}
                </div >
        );
    }
}

// export default withRouter(TopNavbar);
const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
    authReducer: state.authReducer
});
const mapDispatchToProps = dispatch => {
    return {
        getUserSetting: (client, data) => dispatch(actionCreators.getUserSetting(client, data)),
        customFieldTypeList: (client) => dispatch(actionCreators.customFieldTypeList(client)),
        getUserIntegration: (client) => dispatch(actionCreators.getUserIntegration(client)),
        getPipelines: (client) => dispatch(actionCreators.getPipelines(client)),
        getLeadSettings: (client) => dispatch(actionCreators.getLeadSettings(client)),


        //common APis
        getCountryList: (client) => dispatch(actionCreators.getCountryList(client)),
        getCityList: (client) => { dispatch(actionCreators.getCityList(client)) },
        getStateList: (client) => { dispatch(actionCreators.getStateList(client)) },
        getZipcodeList: (client) => { dispatch(actionCreators.getZipcodeList(client)) },
        getUserList: (client) => dispatch(actionCreators.getUserList(client)),
        getCompanyCurrency: (client) => dispatch(actionCreators.getCompanyCurrency(client)),

        //---------------------

        // getUserProfileInfoFirst: (data) => dispatch(actionCreators.getUserProfileInfoFirst(data)),
        // updateCompanyInfo: (data) => dispatch(actionCreators.updateCompanyInfo(data)),
        // getBussinessType: () => dispatch(actionCreators.getBussinessType()),
        // GetCompanyStrength: () => dispatch(actionCreators.GetCompanyStrength()),
        // companyListById: (data) => dispatch(actionCreators.companyListById(data)),
    }
}
export default (connect(mapStateToProps, mapDispatchToProps)(withRouter(TopNavbar)));
