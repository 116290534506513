import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Box, Typography } from "@material-ui/core";
import TaskAutomationLead from "./TaskAutomationLead";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={0}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}


export default function TaskAutomationTab(data) {
    const [value, setValue] = React.useState(0);
    let tasklist = [];
    getTaskAutomationData(data.automationData);

    function getTaskAutomationData(allDatas){

        allDatas.map((taskdata)=>{
            if(taskdata.entity == 1){
                tasklist['lead'] = []; 
                tasklist['lead'].push(taskdata);
            }
            if(taskdata.entity == 2){
                tasklist['contact'] = []; 
                tasklist['contact'].push(taskdata);
            }
            if(taskdata.entity == 3){
                tasklist['company'] = []; 
                tasklist['company'].push(taskdata);
            }
            if(taskdata.entity == 4){
                tasklist['opportunity'] = []; 
                tasklist['opportunity'].push(taskdata);
            }
            if(taskdata.entity == 5){
                tasklist['project'] = []; 
                tasklist['project'].push(taskdata);
            }
        });

    }
    
    if(tasklist.lead){
        console.log(typeof(tasklist.lead));
    }

    function handleChange(event, newValue) {
        setValue(newValue);
    }
    
    return (
        <React.Fragment>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                className="settings_tabs"
                aria-label="scrollable auto tabs"
            >
                {/* <Tab label={"Leads ("+(tasklist.lead) && tasklist.lead.count+")"} {...a11yProps(0)} />
                <Tab label={"Contacts ("+(tasklist.contact) && tasklist.contact.count+")"} {...a11yProps(1)} />
                <Tab label={"Company ("+(tasklist.company) && tasklist.company.count+")"} {...a11yProps(2)} />
                <Tab label={"Opportunity ("+(tasklist.opportunity) && tasklist.opportunity.count+")"} {...a11yProps(3)} />
                <Tab label={"Projects ("+(tasklist.project) && tasklist.project.count+")"} {...a11yProps(4)} /> */}
            </Tabs>
            <div className="v_scroll position-relative task_automation pt-16 pb-16">
                <TabPanel value={value} index={0} className="h-100">
                    <TaskAutomationLead />
                </TabPanel>
                <TabPanel value={value} index={1} className="h-100">

                </TabPanel>
                <TabPanel value={value} index={2} className="h-100">

                </TabPanel>
                <TabPanel value={value} index={3} className="h-100">

                </TabPanel>
                <TabPanel value={value} index={4} className="h-100">

                </TabPanel>
            </div>
        </React.Fragment>
    );
}