import React, { Component } from "react";
import { Checkbox } from "@material-ui/core";
import { Table } from 'antd';
import { connect } from "react-redux";
import * as actionCreators from '../../../store/actions/index';
class AssignmentsNotification extends Component {
    state = {
        selection: [],
        checkbox_assign_email1: '',
        checkbox_assign_email2: '',
        checkbox_assign_email3: '',
        checkbox_assign_email4: '',
        checkbox_assign_email5: '',
        checkbox_assign_email6: '',
        checkbox_assign_inapp1: '',
        checkbox_assign_inapp2: '',
        checkbox_assign_inapp3: '',
        checkbox_assign_inapp4: '',
        checkbox_assign_inapp5: '',
        checkbox_assign_inapp6: '',
        checkbox_assign_mobile1: '',
        checkbox_assign_mobile2: '',
        checkbox_assign_mobile3: '',
        checkbox_assign_mobile4: '',
        checkbox_assign_mobile5: '',
        checkbox_assign_mobile6: '',
    };
    componentDidMount() {
        this.props.getNotificationSetting(this.props.apolloClient.client);
        if (this.props.settingReducer.getnotificationsettingdata) {
            let data = this.props.settingReducer.getnotificationsettingdata.data;
            this.setnotification(data);
            this.setState({ selection: data });
        }
    }
    componentWillReceiveProps(props) {
        if (props.settingReducer.getnotificationsettingdata) {
            let data = props.settingReducer.getnotificationsettingdata.data;
            this.setnotification(data);
            this.setState({ selection: data });
        }
    }
    setnotification = (data) => {
        if (data && data.length > 0) {
            data.map((ndata) => {
                if (ndata) {
                    if (ndata.notification_type === "ASSIGNMENTS_01") {
                        this.setState({
                            checkbox_assign_inapp1: ndata.in_app,
                            checkbox_assign_email1: ndata.in_email,
                            checkbox_assign_mobile1: ndata.in_mobile,
                        });
                    }
                    if (ndata.notification_type === "ASSIGNMENTS_02") {
                        this.setState({
                            checkbox_assign_inapp2: ndata.in_app,
                            checkbox_assign_email2: ndata.in_email,
                            checkbox_assign_mobile2: ndata.in_mobile,
                        });
                    }
                    if (ndata.notification_type === "ASSIGNMENTS_03") {
                        this.setState({
                            checkbox_assign_inapp3: ndata.in_app,
                            checkbox_assign_email3: ndata.in_email,
                            checkbox_assign_mobile3: ndata.in_mobile,
                        });
                    }
                    if (ndata.notification_type === "ASSIGNMENTS_04") {
                        this.setState({
                            checkbox_assign_inapp4: ndata.in_app,
                            checkbox_assign_email4: ndata.in_email,
                            checkbox_assign_mobile4: ndata.in_mobile,
                        });
                    }
                    if (ndata.notification_type === "ASSIGNMENTS_05") {
                        this.setState({
                            checkbox_assign_inapp5: ndata.in_app,
                            checkbox_assign_email5: ndata.in_email,
                            checkbox_assign_mobile5: ndata.in_mobile,
                        });
                    }
                    if (ndata.notification_type === "ASSIGNMENTS_06") {
                        this.setState({
                            checkbox_assign_inapp6: ndata.in_app,
                            checkbox_assign_email6: ndata.in_email,
                            checkbox_assign_mobile6: ndata.in_mobile,
                        });
                    }
                }
            });
        }
    }
    handleCheckBoxChange = (event, name) => {
        let submitData;

        if (name === 'checkbox_assign_email1') {
            submitData = [{
                type: 'ASSIGNMENTS_01', in_email: event.target.checked, in_app: this.state.checkbox_assign_inapp1, in_mobile: this.state.checkbox_assign_mobile1
            }]
        }
        if (name === 'checkbox_assign_email2') {
            submitData = [{
                type: 'ASSIGNMENTS_02', in_email: event.target.checked, in_app: this.state.checkbox_assign_inapp2, in_mobile: this.state.checkbox_assign_mobile2
            }]
        }
        if (name === 'checkbox_assign_email3') {
            submitData = [{
                type: 'ASSIGNMENTS_03', in_email: event.target.checked, in_app: this.state.checkbox_assign_inapp3, in_mobile: this.state.checkbox_assign_mobile3
            }]
        }
        if (name === 'checkbox_assign_email4') {
            submitData = [{
                type: 'ASSIGNMENTS_04', in_email: event.target.checked, in_app: this.state.checkbox_assign_inapp4, in_mobile: this.state.checkbox_assign_mobile4
            }]
        }
        if (name === 'checkbox_assign_email5') {
            submitData = [{
                type: 'ASSIGNMENTS_05', in_email: event.target.checked, in_app: this.state.checkbox_assign_inapp5, in_mobile: this.state.checkbox_assign_mobile5
            }]
        }
        if (name === 'checkbox_assign_email6') {
            submitData = [{
                type: 'ASSIGNMENTS_06', in_email: event.target.checked, in_app: this.state.checkbox_assign_inapp6, in_mobile: this.state.checkbox_assign_mobile6
            }]
        }
        if (name === 'checkbox_assign_inapp1') {
            submitData = [{
                type: 'ASSIGNMENTS_01', in_app: event.target.checked, in_email: this.state.checkbox_assign_email1, in_mobile: this.state.checkbox_assign_mobile1
            }]
        }
        if (name === 'checkbox_assign_inapp2') {
            submitData = [{
                type: 'ASSIGNMENTS_02', in_app: event.target.checked, in_email: this.state.checkbox_assign_email2, in_mobile: this.state.checkbox_assign_mobile2
            }]
        }
        if (name === 'checkbox_assign_inapp3') {
            submitData = [{
                type: 'ASSIGNMENTS_03', in_app: event.target.checked, in_email: this.state.checkbox_assign_email3, in_mobile: this.state.checkbox_assign_mobile3
            }]
        }
        if (name === 'checkbox_assign_inapp4') {
            submitData = [{
                type: 'ASSIGNMENTS_04', in_app: event.target.checked, in_email: this.state.checkbox_assign_email4, in_mobile: this.state.checkbox_assign_mobile4
            }]
        }
        if (name === 'checkbox_assign_inapp5') {
            submitData = [{
                type: 'ASSIGNMENTS_05', in_app: event.target.checked, in_email: this.state.checkbox_assign_email5, in_mobile: this.state.checkbox_assign_mobile5
            }]
        }
        if (name === 'checkbox_assign_inapp6') {
            submitData = [{
                type: 'ASSIGNMENTS_06', in_app: event.target.checked, in_email: this.state.checkbox_assign_email6, in_mobile: this.state.checkbox_assign_mobile6
            }]
        }
        if (name === 'checkbox_assign_mobile1') {
            submitData = [{
                type: 'ASSIGNMENTS_01', in_mobile: event.target.checked, in_email: this.state.checkbox_assign_email1, in_app: this.state.checkbox_assign_inapp1
            }]
        }
        if (name === 'checkbox_assign_mobile2') {
            submitData = [{
                type: 'ASSIGNMENTS_02', in_mobile: event.target.checked, in_email: this.state.checkbox_assign_email2, in_app: this.state.checkbox_assign_inapp2
            }]
        }
        if (name === 'checkbox_assign_mobile3') {
            submitData = [{
                type: 'ASSIGNMENTS_03', in_mobile: event.target.checked, in_email: this.state.checkbox_assign_email3, in_app: this.state.checkbox_assign_inapp3
            }]
        }
        if (name === 'checkbox_assign_mobile4') {
            submitData = [{
                type: 'ASSIGNMENTS_04', in_mobile: event.target.checked, in_email: this.state.checkbox_assign_email4, in_app: this.state.checkbox_assign_inapp4
            }]
        }
        if (name === 'checkbox_assign_mobile5') {
            submitData = [{
                type: 'ASSIGNMENTS_05', in_mobile: event.target.checked, in_email: this.state.checkbox_assign_email5, in_app: this.state.checkbox_assign_inapp5
            }]
        }
        if (name === 'checkbox_assign_mobile6') {
            submitData = [{
                type: 'ASSIGNMENTS_06', in_mobile: event.target.checked, in_email: this.state.checkbox_assign_email6, in_app: this.state.checkbox_assign_inapp6
            }]
        }
        let req = { settingdata: submitData };
        this.props.UpdateNotificationSetting(this.props.apolloClient.client, req);
        this.setState({ [name]: event.target.checked });
    };
    render() {
        const columns = [];
        const rows = [];
        const general_rows = [];

        const general_columns = [

            {
                title: "",
                dataIndex: "name",
                className: "align_left",
                width: 300
            },
            {
                title: "Email",
                dataIndex: "email",
                // className: "align_left"
            },
            {
                title: "In App",
                dataIndex: "inapp",
                // className: "align_left"
            },
            {
                title: "Mobile",
                dataIndex: "mobile",
                // className: "align_left"
            },
            {
                title: "",
                dataIndex: "action",
            }
        ];

        general_rows.push(
            {
                name: (
                    <div className="ml-10">
                        <label className="crm-custom-field-label font-weight-500">Task is owned by me</label>
                    </div>
                ),
                email: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_assign_email1}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_assign_email1')}
                        value="checkbox_assign_email1"
                        color="primary"
                    />
                </React.Fragment>),
                inapp: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_assign_inapp1}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_assign_inapp1')}
                        value="checkbox_assign_inapp1"
                        color="primary"
                    />
                </React.Fragment>),
                mobile: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_assign_mobile1}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_assign_mobile1')}
                        value="checkbox_assign_mobile1"
                        color="primary"
                    />
                </React.Fragment>),
            },
            {
                name: (
                    <div className="ml-10">
                        <label className="crm-custom-field-label font-weight-500">Lead is owned by me</label>
                    </div>
                ),
                email: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_assign_email2}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_assign_email2')}
                        value="checkbox_assign_email2"
                        color="primary"
                    />
                </React.Fragment>),
                inapp: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_assign_inapp2}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_assign_inapp2')}
                        value="checkbox_assign_inapp2"
                        color="primary"
                    />
                </React.Fragment>),
                mobile: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_assign_mobile2}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_assign_mobile2')}
                        value="checkbox_assign_mobile2"
                        color="primary"
                    />
                </React.Fragment>),
            },
            {
                name: (
                    <div className="ml-10">
                        <label className="crm-custom-field-label font-weight-500">Opportunity is owned by me</label>
                    </div>
                ),
                email: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_assign_email3}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_assign_email3')}
                        value="checkbox_assign_email3"
                        color="primary"
                    />
                </React.Fragment>),
                inapp: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_assign_inapp3}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_assign_inapp3')}
                        value="checkbox_assign_inapp3"
                        color="primary"
                    />
                </React.Fragment>),
                mobile: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_assign_mobile3}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_assign_mobile3')}
                        value="checkbox_assign_mobile3"
                        color="primary"
                    />
                </React.Fragment>),
            },
            {
                name: (
                    <div className="ml-10">
                        <label className="crm-custom-field-label font-weight-500">Project is owned by me</label>
                    </div>
                ),
                email: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_assign_email4}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_assign_email4')}
                        value="checkbox_assign_email4"
                        color="primary"
                    />
                </React.Fragment>),
                inapp: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_assign_inapp4}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_assign_inapp4')}
                        value="checkbox_assign_inapp4"
                        color="primary"
                    />
                </React.Fragment>),
                mobile: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_assign_mobile4}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_assign_mobile4')}
                        value="checkbox_assign_mobile4"
                        color="primary"
                    />
                </React.Fragment>),
            },
            {
                name: (
                    <div className="ml-10">
                        <label className="crm-custom-field-label font-weight-500">Person is owned by me</label>
                    </div>
                ),
                email: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_assign_email5}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_assign_email5')}
                        value="checkbox_assign_email5"
                        color="primary"
                    />
                </React.Fragment>),
                inapp: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_assign_inapp5}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_assign_inapp5')}
                        value="checkbox_assign_inapp5"
                        color="primary"
                    />
                </React.Fragment>),
                mobile: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_assign_mobile5}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_assign_mobile5')}
                        value="checkbox_assign_mobile5"
                        color="primary"
                    />
                </React.Fragment>),
            },
            {
                name: (
                    <div className="ml-10">
                        <label className="crm-custom-field-label font-weight-500">Company is owned by me</label>
                    </div>
                ),
                email: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_assign_email6}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_assign_email6')}
                        value="checkbox_assign_email6"
                        color="primary"
                    />
                </React.Fragment>),
                inapp: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_assign_inapp6}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_assign_inapp6')}
                        value="checkbox_assign_inapp6"
                        color="primary"
                    />
                </React.Fragment>),
                mobile: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_assign_mobile6}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_assign_mobile6')}
                        value="checkbox_assign_mobile6"
                        color="primary"
                    />
                </React.Fragment>),
            }
        )



        return (
            <React.Fragment>
                <div className="table_width antd-tables">
                    <Table
                        bordered={false}
                        columns={general_columns}
                        pagination={false}
                        dataSource={general_rows}
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        apolloClient: state.apolloClient,
        settingReducer: state.settingReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getNotificationSetting: (client) => dispatch(actionCreators.getNotificationSetting(client)),
        UpdateNotificationSetting: (client, request) => dispatch(actionCreators.UpdateNotificationSetting(client, request)),
    }
}
export default (connect(mapStateToProps, mapDispatchToProps)(AssignmentsNotification));
