import React, { Component } from "react";
import {
  Grid, Icon, Card, withStyles, IconButton,
  Dialog, Fab, DialogTitle, Checkbox,
  InputLabel, DialogContent, DialogActions,
  Button, Typography, CircularProgress
} from "@material-ui/core";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { withRouter } from "react-router-dom";

const IconSmall = withStyles(() => ({
  root: {
    fontSize: "1.5rem",
    color: 'rgba(0, 0, 0, 0.6)'
  }
}))(Icon);

class MailChimp extends Component {
  state = {
    isOpenedMailChimp: false,
    dateformat: 'MM/DD/YYYY',
    timeZone: 'America/New_York',
    isDataLoading: false,
    MailChimpList: [],
    Domain: '',
    SubscriptionDialog: false,
    audience: '',
    MailchimpAudienceList: [],
    keyGen: [],
    RemoveSubscribersDialog: false,
    valid_status: false,
    AudienceId: '',
  };

  componentWillUnmount() {
    this.props.settingReducer.getMemberAudience = [];
    this.props.settingReducer.getMailchimp_Audience = [];
  }

  componentDidMount() {
    this.props.getMailchimpAudience();
    var req = {
      type: this.props.recordtype,
      id: this.props.recordId,
    }
    this.props.getMemberInAudience(req);
  }

  componentWillReceiveProps(props) {
    this.setState({ userSelectedEmail: this.props.userSelectedEmail });
    if (props.settingReducer.getMailchimp_Audience) {
      const MailchimpAudienceList = props.settingReducer.getMailchimp_Audience;
      if (MailchimpAudienceList && MailchimpAudienceList !== this.state.MailchimpAudienceList) {
        this.setState({ MailchimpAudienceList: MailchimpAudienceList });
      }
    }
    if (props.settingReducer.getMemberAudience) {
      this.setState({ isDataLoading: false });
      const MailChimpList = props.settingReducer.getMemberAudience;
      if (MailChimpList && MailChimpList !== this.state.MailChimpList) {
        this.setState({ MailChimpList: MailChimpList });
      }
      if (MailChimpList.length > 0) {
        this.setState({ isOpenedMailChimp: true });
      }else{
        this.setState({ isOpenedMailChimp: false });
      }
    }
  }

  toggleAddMailChimp = () => {
    if (this.state.userSelectedEmail && this.state.userSelectedEmail !== null && this.state.userSelectedEmail !== undefined) {
      this.setState({ SubscriptionDialog: !this.state.SubscriptionDialog });
    } else {
      this.props.EmailExistorNotNotification(this.props.apolloClient.client);
    }
  }

  toggleRemoveMailChimp = () => {
    this.setState({ RemoveSubscribersDialog: !this.state.RemoveSubscribersDialog });
  }

  toggleMailChimp = () => {
    if (this.state.userSelectedEmail && this.state.userSelectedEmail !== null && this.state.userSelectedEmail !== undefined) {
      if (this.state.isOpenedMailChimp) {
        var req = {
          type: this.props.recordtype,
          id: this.props.recordId,
        }
        this.props.getMemberInAudience(req);
      }
      this.setState({ isOpenedMailChimp: !this.state.isOpenedMailChimp });
    } else {
      this.props.EmailExistorNotNotification(this.props.apolloClient.client);
    }
  }

  handleCheckBoxChange = (event, name, id) => {
    event.persist();
    let evetchanged = [];
    if (event.target.checked) {
      this.setState({
        [name]: event.target.checked,
        keyGen: evetchanged.concat([id])
      })
    } else {
      let status = evetchanged.filter(function (val) { return val !== id });
      this.setState({
        keyGen: status, [name]: event.target.checked
      })
    }
    this.setState({
      [name]: event.target.checked,
    });

  };

  AddSubScriber = () => {
    let ticket = this.state.keyGen.toString();
    let value1 = [];
    if (ticket && ticket !== null) {
      value1 = this.state.keyGen;
      this.setState({ valid_status: false });
      var req = {
        type: this.props.recordtype,
        id: [this.props.recordId],
        audience: value1,
      }
      this.props.AddMailchimpSubscriber(req);
      this.toggleAddMailChimp();
      this.setState({ isDataLoading: true });
    } else {
      this.setState({ valid_status: true });
    }
  }

  handleRemoveAudience = (audience_id) => {
    this.setState({
      RemoveSubscribersDialog: true,
      AudienceId: audience_id,
    }, () => {
      this.forceUpdate()
    })

  };

  toggleRemovePopup = () => {
    if (this.state.RemoveSubscribersDialog) {
      this.setState({
        AudienceId: "",
      }, () => {
        this.forceUpdate()
      })

    }
    this.setState({
      RemoveSubscribersDialog: !this.state.RemoveSubscribersDialog,
    }, () => {
      this.forceUpdate()
    })

  };
  confirmremovePopup = () => {
    if (this.state.AudienceId) {
      this.setState({ isDataLoading: true });
      let data = {
        type: this.props.recordtype,
        id: [this.props.recordId],
        audience: [this.state.AudienceId],
      }
      this.props.deleteMemberInAudience(data);
      this.toggleRemoveMailChimp();
      this.setState({
        AudienceId: "",
        RemoveSubscribersDialog: false,
      }, () => {
        this.forceUpdate()
      })
    }

  };

  render() {
    const { isOpenedMailChimp, MailChimpList, SubscriptionDialog, RemoveSubscribersDialog } = this.state;
    let list = [];
    let name = '';
    if (MailChimpList && MailChimpList !== null) {
      MailChimpList.map((item) => {
        let url = `https://us17.admin.mailchimp.com/lists/members/?id=${item.id}`;
        name = item.name;
        list.push(
          <>
            <lable className="text-hint" >Audience</lable>
            <div className="box_portlet mt-10 crm-calendar-event">
              <div className="box_body px-10 py-20">
                <div className="lead_pl" style={{ paddingLeft: '50px' }}>
                  <div className="icon_left">
                    <Fab
                      size="small"
                      aria-label="Task"
                      className={`wrap_icons_task_sidebar vert-middle bg-grey_sidebar`}
                    >
                      <Icon className="activity_icon_normal">list</Icon>
                    </Fab>
                  </div>
                  <a target="_blank" className={"cursor_poiter"}>
                    <Grid container justify="space-between">
                      <h6 className="m-0" style={{ width: '50%' }}>{item.name}</h6>
                    </Grid>
                  </a>
                  <span className="related-text">{item.member_count} subscribers</span>
                  <div className="hover_show" style={{ top: '-21px', right: '-7px' }}>
                    <a href={url} target="_blank">
                      <IconButton aria-label="Launch" size="small" >
                        <IconSmall style={{ fontSize: '18px' }}>launch</IconSmall>
                      </IconButton>
                    </a>
                    <IconButton aria-label="Delete" size="small" onClick={() => this.handleRemoveAudience(item.id)}>
                      <IconSmall style={{ fontSize: '18px' }}>delete</IconSmall>
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })
    }
    return (
      <Card elevation={3} className="p-16 mt-16" style={{ overflow: 'initial' }}>
        <Grid container justify="space-between">
          <h5 className="flex-auto cursor_poiter mb-0 pt-4"
            onClick={this.toggleMailChimp}>
            MailChimp
            <Icon className="vert-middle">
              {isOpenedMailChimp ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
            </Icon>
          </h5>
          <IconButton aria-label="Add" size="small">
            <IconSmall onClick={this.toggleAddMailChimp}>add</IconSmall>
          </IconButton>
        </Grid>
        {
          isOpenedMailChimp &&
          <>
            {
              (MailChimpList.length == 0) ?
                <label className="text-hint" onClick={this.toggleAddMailChimp}>
                  <IconButton className="mr-8" component="span">
                    <Icon>list</Icon>
                  </IconButton>Add to MailChimp Audience</label>
                :
                <>
                  <div style={{ marginTop: '10px' }}>
                    {
                      this.state.isDataLoading ?
                        <Grid
                          container
                          spacing={0}
                          direction="column"
                          alignItems="center"
                          justify="center"
                          style={{ minHeight: '10vh', backgroundColor: 'rgba(0,0,0,0)' }}
                        >
                          <Grid item xs={3}>
                            <CircularProgress disableShrink size={25} />
                          </Grid>
                        </Grid> :
                        list
                    }
                  </div>
                </>
            }

          </>
        }
        <Dialog className="crm_dialog_setting" maxWidth={'sm'} open={SubscriptionDialog} onClose={this.toggleAddMailChimp} disableBackdropClick >
          <DialogTitle>
            <Typography className="p-10 font-weight-500" variant="h5" color="inherit">
              Add 1 Person to MailChimp
            </Typography>
            <div className="hover_show" style={{ margin: '-65px 0 0 540px' }} >
              <IconButton aria-label="Delete" size="small" onClick={this.toggleAddMailChimp}>
                <IconSmall style={{ fontSize: '18px' }}>clear</IconSmall>
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            <div style={{ margin: '10px' }}>
              <div className="Zend_Integrations_card font-weight-600 font-size-18 text-small" style={{ marginTop: '10px' }} >
                Please select the MailChimp audiences you would like to add this to:
              </div>
              <div style={{ width: '400px', display: 'flex' }} >
                {this.state.MailchimpAudienceList.map((rows) => (
                  <>
                    <Checkbox
                      checked={this.state[rows.name]}
                      onChange={event => this.handleCheckBoxChange(event, "audience", rows.id)}
                      value="audience"
                      color="primary"
                    />
                    <InputLabel className="text-hint p-10" style={{ color: 'black', margin: '10px' }}>
                      {rows.name}
                    </InputLabel>
                  </>
                ))}
              </div>
              {this.state.valid_status === true ? <p style={{ color: 'red', margin: '0 0 0 220px' }} className="text-small">Please select at least one MailChimp audience to proceed.</p> : ""}
            </div>
          </DialogContent>
          <DialogActions className="dialog-action" style={{ margin: "20px 0 0 0" }}>
            <Button variant="contained" onClick={this.toggleAddMailChimp}>Cancel</Button>
            <Button variant="contained" onClick={this.AddSubScriber} className="btn_Primary" type="submit">Subscribe</Button>
          </DialogActions>
        </Dialog>

        {/* For remove Mailchimp */}
        <Dialog className="crm_dialog_setting" maxWidth={'sm'} open={RemoveSubscribersDialog} onClose={this.toggleRemovePopup} disableBackdropClick >
          <DialogTitle>
            <Typography className="p-10 font-weight-500" variant="h5" color="inherit">
              Remove From MailChimp
            </Typography>
            <div className="hover_show" style={{ margin: '-65px 0 0 540px' }} >
              <IconButton aria-label="Delete" size="small" onClick={this.toggleRemovePopup}>
                <IconSmall style={{ fontSize: '18px' }}>clear</IconSmall>
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            <div style={{ margin: '10px' }}>
              <div className="Zend_Integrations_card font-weight-600 font-size-18 text-small" style={{ marginTop: '10px' }} >
                Are you sure you want to remove from it?
              </div>
            </div>
          </DialogContent>
          <DialogActions className="dialog-action" style={{ margin: "20px 0 0 0" }}>
            <Button variant="contained" onClick={this.toggleRemovePopup}>Cancel</Button>
            <Button variant="contained" onClick={() => this.confirmremovePopup()} className="btn_Primary" type="submit">Remove</Button>
          </DialogActions>
        </Dialog>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
});

const mapDispatchToProps = dispatch => {
  return {
    getMailchimpAudience: () => dispatch(actionCreators.getMailchimpAudience()),
    AddMailchimpSubscriber: (value) => dispatch(actionCreators.AddMailchimpSubscriber(value)),
    getMemberInAudience: (value) => dispatch(actionCreators.getMemberInAudience(value)),
    deleteMemberInAudience: (value) => dispatch(actionCreators.deleteMemberInAudience(value)),
    EmailExistorNotNotification: (client) => dispatch(actionCreators.EmailExistorNotNotification(client))
  }
}
export default (connect(mapStateToProps, mapDispatchToProps)(withRouter(MailChimp)));