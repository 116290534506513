import React, { Component } from "react";
import { Checkbox, Typography, Card, Grid, Button, Icon, IconButton, Dialog, DialogTitle, DialogActions, RadioGroup, Radio, FormControlLabel, FormControl, Select, MenuItem } from "@material-ui/core";
import { Table } from 'antd';
import BusinessIcon from '@material-ui/icons/Business';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PersonIcon from '@material-ui/icons/Person';
import { DropzoneArea } from 'material-ui-dropzone'
import { ExcelReader } from './ExcelReader'
import TableComponent from './TableComponent'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ImportDialog from './ImportDialog';
import ImportFixDialogOpportunity from './ImportFixDialogOpportunity';
import moment from 'moment';

import * as actions from '../../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';

class UploadOpportunities extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            uploadedRecords: [],
            customFields: [],
            selectedFields: [],
            submitData: [],
            fileName: "",
            drop_matching: 'selectfield',
            // selectedAutocomplete:[],
            samplefilelink: '',
            validColsArr: [],
            companyRecList: [],
            wrongValues: [],
            wrongRowKeys: [],
            valueToFixArr: [],
            columnToFix: "",
            checkbox_exclude: true,
            checkbox_ignore: false,
            restrictMultiAllow: [],
            userSettings: "",
            usersListArray: [],
            oldColumnValues: [],
            newColumnValues: [],
            pipesList: [],
            currencyList: [],

            isCancelImportDialog: false,
            matchField: false,
            checkbox_matching: false,
            radio_matching: 'skip',
            shouldOpenImportDialog: false,
            minimizeImportDialog: false,
            FullImportDialog: false,

            shouldOpenImportFixDialog: false,
            minimizeImportFixDialog: false,
            FullImportFixDialog: false,


        };
        // let grid_view = document.getElementsByClassName('ag-body-viewport');
    }

    componentDidMount() {
        let params_customfield = { typeId: 4 }
        this.props.customFieldList(this.props.apolloClient.client, params_customfield);
        this.props.getPipelines(this.props.apolloClient.client);

        this.props.getCountryList(this.props.apolloClient.client);
        this.props.getCompanyRecList(this.props.apolloClient.client);
        this.props.getUserList(this.props.apolloClient.client);
        this.props.getCompanyCurrency(this.props.apolloClient.client);


        var req = { recordtypeid: 4, type: this.props.match.params.id.toLowerCase() }
        this.props.sampleFileExport(this.props.apolloClient.client, req);
    }

    componentWillReceiveProps(props) {
        let companyRecList = [];
        if (props.settingReducer.importDataFile.filename) {
            this.setState({ fileName: props.settingReducer.importDataFile.filename });
        }

        if (props.settingReducer.companyCurrencyData && props.settingReducer.companyCurrencyData.data) {
            this.setState({ currencyList: props.settingReducer.companyCurrencyData.data })
        }

        if (props.settingReducer.usersListArray && props.settingReducer.usersListArray.data) {
            const usersListArray = props.settingReducer.usersListArray.data;
            this.setState({ usersListArray: usersListArray })
        }

        if (props.settingReducer.userSettingArray.data && this.state.userSettings == "") {
            const settingData = props.settingReducer.userSettingArray.data;
            this.setState({
                userSettings: settingData,
            })
        }
        
        if (props.settingReducer.sampleImportFile.data) {
            this.setState({ samplefilelink: props.settingReducer.sampleImportFile.data.message })
            // if (this.state.samplefilelink != props.settingReducer.sampleImportFile.data.message) {
            //     window.open(props.settingReducer.sampleImportFile.data.message, '__blank');
            // }
        }

        if (props.settingReducer.companyRecList.data) {
            companyRecList = props.settingReducer.companyRecList.data.data;
            // this.setState({ companyRecList: props.settingReducer.companyRecList.data.data })
            // console.log(props.settingReducer.companyRecList.data.data,'props.settingReducer.companyRecList.data.data');
        }
        // if (props.settingReducer.pipelineListArray.data){
            
        //     props.settingReducer.pipelineListArray.data.map((pipeline)=>{
        //         console.log(pipeline, 'pipelinepipelinepipeline');
        //     })
        // }
        if (props.settingReducer.opportunitiesCustomFieldList && props.settingReducer.opportunitiesCustomFieldList.data) {
            
            
            var customFields = [];
            customFields.push({ id: 0, title: 'Ignore', type: "ignore" });
            customFields.push({ id: 0, title: 'IDrive CRM id', fieldkey: 'idrivecrmid', type: "idrivecrmid", fieldtype: 6, fieldOptions: [] });
            props.settingReducer.opportunitiesCustomFieldList.data.map((field) => {
                if (field.id == 41) {
                    customFields.push({
                        id: parseFloat(field.id),
                        title: field.fieldlabel,
                        type: "",
                        fieldtype: parseFloat(field.fieldtype.id),
                        fieldkey: field.fieldkey,
                        fieldOptions: props.settingReducer.pipelineListArray.data ? props.settingReducer.pipelineListArray.data : [],
                    });
                }else if (field.id == 46) {
                    customFields.push({
                        id: parseFloat(field.id),
                        title: field.fieldlabel,
                        type: "",
                        fieldtype: parseFloat(field.fieldtype.id),
                        fieldkey: field.fieldkey,
                        fieldOptions: props.settingReducer.usersListArray.data ? props.settingReducer.usersListArray.data : [],
                    });
                } else {
                    customFields.push({ id: parseFloat(field.id), fieldkey: field.fieldkey, title: field.fieldlabel, type: "", fieldtype: parseFloat(field.fieldtype.id), fieldOptions: field.options });
                }
            })

            customFields.push({
                id: 50,
                title: 'Currency Type',
                fieldkey: 'value',
                type: "currencytype",
                fieldtype: 4,
                fieldOptions: props.settingReducer.companyCurrencyData.data ? props.settingReducer.companyCurrencyData.data : [],
            });
            customFields.push({ id: 0, title: 'Created At', fieldkey: 'createdat', type: "createdat", fieldtype: 8, fieldOptions: [] });

            this.setState({ customFields });
        }
    }


    //---------------------------------------
    openImportFixDialog = (fixValue, columnName) => {
        this.setState({ shouldOpenImportFixDialog: true, valueToFixArr: fixValue, columnToFix: columnName });
    };

    handleImportFixDialogClose = () => {
        this.setState({ shouldOpenImportFixDialog: false, FullImportFixDialog: false, minimizeImportFixDialog: false });
    };

    handleImportFixDialogMinimize = () => {
        this.setState(oldStateminimizeImportFixDialog => ({ minimizeImportFixDialog: !oldStateminimizeImportFixDialog.minimizeImportFixDialog }));
        this.setState({ FullImportFixDialog: false })
    };

    handleImportFixDialogFull = () => {
        this.setState(oldStateFullImportFixDialog => ({ FullImportFixDialog: !oldStateFullImportFixDialog.FullImportFixDialog }));
        this.setState({ minimizeImportFixDialog: false })
    };

    //----------------------------------------------
    openImportDialog = () => {
        this.setState({ shouldOpenImportDialog: true });
    };

    handleImportDialogClose = () => {
        this.setState({ shouldOpenImportDialog: false, FullImportDialog: false, minimizeImportDialog: false });
    };

    handleImportDialogMinimize = () => {
        this.setState(oldStateminimizeImportDialog => ({ minimizeImportDialog: !oldStateminimizeImportDialog.minimizeImportDialog }));
        this.setState({ FullImportDialog: false })
    };

    handleImportDialogFull = () => {
        this.setState(oldStateFullImportDialog => ({ FullImportDialog: !oldStateFullImportDialog.FullImportDialog }));
        this.setState({ minimizeImportDialog: false })
    };
    //---------------------------------------------------------

    downloadSampleFile = () => {
        var req = { recordtypeid: 1, type: this.props.match.params.id.toLowerCase() }
        this.props.sampleFileExport(this.props.apolloClient.client, req);
    }

    backToImport = () => {
        this.props.history.push('/settings/import-data/');
    }

    handleChange(files) {

        if (files && files[0]) {
            this.setState({
                files: files[0]
            }, function () {
                var req = {
                    record: 'opportunity',
                    fileInput: files[0],
                }
                this.props.importDataFile(this.props.apolloClient.client, req);

                ExcelReader(files[0], this.state.userSettings.dateformat).then((data) => {
                    this.setState({ uploadedRecords: data, originalData: data }, this.forceUpdate());
                    let selectedFields = this.getNewColumnRowArray(data, false);
                    this.checkValidation(selectedFields);
                    this.setState({ selectedFields });
                }).catch((error) => {
                });
            })
        }
    }

    getDuplicateArrayElements = (arr) => {
        var sorted_arr = arr.slice().sort();
        var results = [];
        for (var i = 0; i < sorted_arr.length - 1; i++) {
            if (sorted_arr[i + 1] === sorted_arr[i]) {
                results.push(sorted_arr[i]);
            }
        }
        return results;
    }

    updateImportTableData = (updatedRecords, updatedField, selectedFields) => {
        let oldColumnValues = this.state.oldColumnValues;
        let newColumnValues = this.state.newColumnValues;

        let updatedColName = Object.keys(updatedField)[0];
        let updatedColKey = this.findWithAttr(updatedRecords['cols_arr'], 'field', updatedColName);

        if (updatedColKey != -1) {
            let tempOld = [];
            let tempNew = [];

            updatedField[updatedColName].map((fieldData) => {
                tempOld.push(fieldData.oldVal);
                tempNew.push(fieldData.newVal);
            });

            oldColumnValues[updatedColKey] = tempOld;
            newColumnValues[updatedColKey] = tempNew;
        }

        this.setState({ selectedFields: selectedFields, uploadedRecords: updatedRecords, oldColumnValues: oldColumnValues, newColumnValues: newColumnValues });

        this.checkValidation(this.state.selectedFields);
        this.handleImportFixDialogClose();
    }

    checkValidation = (selectedFields) => {

        let validColsArr = [];
        let restrictMultiAllow = [];
        let new_repeat_arr = [];

        selectedFields.map((field) => {

            //check if the filed is multiple time selected.
            if (field.ignore == false) {
                if (field.fieldId != 56 ) {//56 tags 
                    new_repeat_arr.push(field.title);
                }
            }//---------------------------------

            //check if the filed Value is valid.
            if (field.fieldId == 46) { // owner
                validColsArr.push(field);
            }

            if (field.fieldtype == 4 || field.fieldtype == 5 ) { //dropdown
                let customKey = this.findWithAttr(this.state.customFields, 'title', field.title);
                if (customKey != -1) {
                    field.fieldOptions = this.state.customFields[customKey].fieldOptions;
                }

                if (parseFloat(field.fieldId) == 50 || parseFloat(field.fieldId) == 54 || parseFloat(field.fieldId) == 51 || parseFloat(field.fieldId) == 47 || parseFloat(field.fieldId) == 41 || parseFloat(field.fieldId) == 48 || parseFloat(field.fieldId) == 45|| parseFloat(field.fieldId) == 53) { // 54 visibility 50 value 51 Priority 47 stage 41 pipeline 48 source
                    if (field.title == 'Currency Type') {
                        let options = [];
                        field.fieldOptions && field.fieldOptions.map((opt) => {
                            opt.detail.code && options.push(opt.detail.code);
                        })
                        field.fieldOptions = options;
                    }
                    if (parseFloat(field.fieldId) == 54 || parseFloat(field.fieldId) == 51 || parseFloat(field.fieldId) == 47 || parseFloat(field.fieldId) == 41 || parseFloat(field.fieldId) == 48 || parseFloat(field.fieldId) == 45|| parseFloat(field.fieldId) == 53) {
                        let options = [];
                        field.fieldOptions && field.fieldOptions.map((opt) => {
                            opt.name && options.push(opt.name);
                        })
                        field.fieldOptions = options;
                    }
                }
                else {
                    let options = [];

                    field.fieldOptions && field.fieldOptions.map((opt) => {
                        opt.optionvalue && options.push(opt.optionvalue);
                    })
                    field.fieldOptions = options;
                }
                if (field.fieldId != 44 || field.fieldId != 42) { // 44 company // 42 primary contact
                    validColsArr.push(field);
                }
            }

            if (field.fieldtype == 1 && (field.fieldId == 50 || field.fieldId == 52)) {// currency type // Win Percentage
                validColsArr.push(field);
            }

            if (field.fieldtype == 8) { // Date
                validColsArr.push(field);
            }

            if (field.fieldtype == 3 || field.fieldtype == 10 || field.fieldtype == 6 || field.fieldtype == 7 || field.fieldId == 40) {//3- checkbox //10- percentage //6-7-  number  // 40- name required 
                validColsArr.push(field);
            }
        })
        
        //check if the filed is multiple time selected.
        restrictMultiAllow = this.getDuplicateArrayElements(new_repeat_arr);

        //check if the filed Value is valid.
        let excelRows = this.state.uploadedRecords['rows'];
        let old_values = [];
        let wrongRowKeys = this.state.wrongRowKeys;
        excelRows.map((row, key) => {
            validColsArr.map((col) => {

                old_values[col.table] = old_values[col.table] ? old_values[col.table] : [];
                wrongRowKeys[col.table] = wrongRowKeys[col.table] ? wrongRowKeys[col.table] : [];

                if (col.fieldtype == 4 || col.fieldtype == 5 ) 
                {
                    if (col.fieldOptions && col.fieldOptions.length > 0) {
                        // let found = col.fieldOptions.indexOf(row[col.table]);
                        let found = -1;
                        col.fieldOptions.map((fildopt)=>{
                            if(fildopt.toLowerCase() == row[col.table].toLowerCase()){
                                found = 1;
                            }
                        })

                        if (found == -1) {
                            if (wrongRowKeys[col.table].indexOf(key) == -1 && row[col.table] && row[col.table].length) {
                                wrongRowKeys[col.table].push(key);
                            }

                            if (old_values[col.table].indexOf(row[col.table]) == -1 && row[col.table] && row[col.table].length) {
                                old_values[col.table].push(row[col.table]);
                            }
                        }
                    }
                }

                if (col.fieldId == 46) { // owner
                    let usersListArray = this.state.usersListArray;
                    let ownerKey = this.findWithAttr(usersListArray, 'name', row[col.table]);
                    if (ownerKey == -1) {
                        if (wrongRowKeys[col.table].indexOf(key) == -1 && row[col.table] && row[col.table].length) {
                            wrongRowKeys[col.table].push(key);
                        }

                        if (old_values[col.table].indexOf(row[col.table]) == -1 && row[col.table] && row[col.table].length) {
                            old_values[col.table].push(row[col.table]);
                        }
                    }
                }
                if (col.fieldtype == 1 && (col.fieldId == 50 || col.fieldId == 52)) { // currency type // Win Percentage
                    if (isNaN(row[col.table]) == true) {
                        //if (typeof (row[col.table]) != 'number') {
                        if (wrongRowKeys[col.table].indexOf(key) == -1 && row[col.table] && row[col.table].length) {
                            wrongRowKeys[col.table].push(key);
                        }

                        if (old_values[col.table].indexOf(row[col.table]) == -1 && row[col.table] && row[col.table].length) {
                            old_values[col.table].push(row[col.table]);
                        }
                    }
                }

                if (col.fieldtype == 6 || col.fieldtype == 7 ) {//number 
                    // if (typeof (row[col.table]) != 'number') {
                    if (isNaN(row[col.table]) == true) {
                        if (wrongRowKeys[col.table].indexOf(key) == -1 && row[col.table] && row[col.table].length) {
                            wrongRowKeys[col.table].push(key);
                        }

                        if (old_values[col.table].indexOf(row[col.table]) == -1 && row[col.table] && row[col.table].length) {
                            old_values[col.table].push(row[col.table]);
                        }
                    }
                }

                if (col.fieldtype == 8) { // date
                    let userSettings = this.state.userSettings;
                    if ((moment(row[col.table], userSettings.dateformat, true).isValid()) != true) {

                        if (wrongRowKeys[col.table].indexOf(key) == -1 && row[col.table] && row[col.table].length) {
                            wrongRowKeys[col.table].push(key);
                        }

                        if (old_values[col.table].indexOf(row[col.table]) == -1 && row[col.table] && row[col.table].length) {
                            old_values[col.table].push(row[col.table]);
                        }
                    }
                }

                if (col.fieldtype == 3) { // checkbox
                    let col_val = (row[col.table] == 'checked') ? true : (row[col.table] == 'unchecked') ? false : '';

                    if (typeof (col_val) != 'boolean') {
                        if (wrongRowKeys[col.table].indexOf(key) == -1 && row[col.table] && row[col.table].length) {
                            wrongRowKeys[col.table].push(key);
                        }

                        if (old_values[col.table].indexOf(row[col.table]) == -1 && row[col.table] && row[col.table].length) {
                            old_values[col.table].push(row[col.table]);
                        }
                    }
                }


                // if (col.fieldId == 2) { // email
                //     const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                //     if (re.test(String(row[col.table]).toLowerCase()) == false) {
                //         if (wrongRowKeys[col.table].indexOf(key) == -1) {
                //             wrongRowKeys[col.table].push(key);
                //         }

                //         if (old_values[col.table].indexOf(row[col.table]) == -1) {
                //             old_values[col.table].push(row[col.table]);
                //         }
                //     }
                // }

                // if (col.fieldId == 10 || col.fieldId == 15) { // website
                //     var re = /(http(s)?:\\)?([\w-]+\.)+[\w-]+[.com|.in|.org]+(\[\?%&=]*)?/
                //     if (re.test(row[col.table]) == false) {
                //         if (wrongRowKeys[col.table].indexOf(key) == -1) {
                //             wrongRowKeys[col.table].push(key);
                //         }

                //         if (old_values[col.table].indexOf(row[col.table]) == -1) {
                //             old_values[col.table].push(row[col.table]);
                //         }
                //     }
                // }
            })
        });

        if (this.state.matchField == false) {
            let matchField = false;
            if (this.state.drop_matching == 'idrivecrmid') {
                let idriveidkey = this.findWithAttr(this.state.selectedFields, 'title', 'IDrive CRM id');
                if (idriveidkey != -1) {
                    matchField = true;
                }
            }
            if (this.state.drop_matching == 'email') {

                this.state.customFields.map((field) => {
                    if (field.id == 2) {
                        let idriveidkey = this.findWithAttr(this.state.selectedFields, 'title', field.title);
                        if (idriveidkey != -1) {
                            matchField = true;
                        }
                    }
                });
            }
            this.setState({ matchField })

        }
        this.setState({ wrongValues: old_values, wrongRowKeys: wrongRowKeys, restrictMultiAllow: restrictMultiAllow });

    }

    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] == value) {
                return i;
            }
        }
        return -1;
    }

    getAllFindWithAttr = (array, attr, value) => {
        let alli = [];
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] == value) {
                alli.push(i);
            }

        }
        return alli;
    }

    getNewColumnRowArray = (currentArray) => {
        let oldColumnValues = this.state.oldColumnValues;
        let newColumnValues = this.state.newColumnValues;

        let selectedFields = [];
        let customfields = this.state.customFields;

        currentArray['cols_arr'] && currentArray['cols_arr'].map((col) => {
            oldColumnValues.push([]);
            newColumnValues.push([]);

            let found = this.findWithAttr(customfields, 'title', col.field);
            if (found != -1) {
                selectedFields.push({ table: col.field, fieldId: customfields[found].id, fieldtype: customfields[found].fieldtype, fieldOptions: customfields[found].fieldOptions, title: customfields[found].title, type: customfields[found].type, isFix: false, ignore: false });
            } else {
                selectedFields.push({ table: col.field, fieldId: "", title: "", type: "", isFix: false, ignore: true, fieldtype: 0, fieldOptions: [] });
            }
        })

        return selectedFields;
    }

    OnScroll = () => {

        var scrollTable = document.getElementsByClassName('ag-body-horizontal-scroll-viewport');

        var div1 = document.getElementById("crm-container-import-modules");
        div1.scrollLeft = scrollTable[0].scrollLeft;
        scrollTable[0].scrollLeft = div1.scrollLeft;
    }

    handleAutoChange = (event, values, tableField) => {

        let selectedFields = this.state.selectedFields;

        if (values != "") {
            let field = this.findWithAttr(this.state.customFields, 'title', values);

            if ((field != -1) && this.state.customFields[field].title != "Ignore") {

                let selectedField = this.state.customFields[field];
                let tablefieldkey = this.findWithAttr(selectedFields, 'table', tableField);
                if (tablefieldkey == -1) {
                    selectedFields.push({ table: tableField, fieldId: selectedField.id, title: selectedField.title, type: selectedField.type, fieldtype: selectedField.fieldtype, fieldOptions: selectedField.fieldOptions, isFix: false, ignore: false })
                } else {
                    selectedFields[tablefieldkey].table = tableField;
                    selectedFields[tablefieldkey].fieldId = selectedField.id;
                    selectedFields[tablefieldkey].title = selectedField.title;
                    selectedFields[tablefieldkey].fieldtype = selectedField.fieldtype;
                    selectedFields[tablefieldkey].fieldOptions = selectedField.fieldOptions;
                    selectedFields[tablefieldkey].type = selectedField.type;
                    selectedFields[tablefieldkey].isFix = false;
                    selectedFields[tablefieldkey].ignore = false;
                }
            } else {
                let tablefieldkey = this.findWithAttr(selectedFields, 'table', tableField);
                if (tablefieldkey != -1) {
                    selectedFields[tablefieldkey].table = tableField;
                    selectedFields[tablefieldkey].fieldId = "";
                    selectedFields[tablefieldkey].title = "Ignore";
                    selectedFields[tablefieldkey].type = "";
                    selectedFields[tablefieldkey].fieldtype = "";
                    selectedFields[tablefieldkey].fieldOptions = [];
                    selectedFields[tablefieldkey].isFix = false;
                    selectedFields[tablefieldkey].ignore = true;
                }
            }
        }
        else {
            let tablefieldkey = this.findWithAttr(selectedFields, 'table', tableField);
            if (tablefieldkey != -1) {
                selectedFields[tablefieldkey].table = tableField;
                selectedFields[tablefieldkey].fieldId = "";
                selectedFields[tablefieldkey].title = "";
                selectedFields[tablefieldkey].type = "";
                selectedFields[tablefieldkey].fieldtype = "";
                selectedFields[tablefieldkey].fieldOptions = [];
                selectedFields[tablefieldkey].isFix = false;
                selectedFields[tablefieldkey].ignore = true;
            }
        }
        this.setState({ selectedFields });

        this.checkValidation(selectedFields);
    }

    handleIgnore = (event) => {
        this.setState({ checkbox_ignore: event.target.checked })
    }

    handleExlude = (event) => {
        this.setState({ checkbox_exclude: event.target.checked });
        let uploaded = [];
        let columns = [];
        let rows = [{}];
        if (event.target.checked === false) {
            this.state.originalData['cols_arr'].map((col, index) => {
                columns.push({
                    headerName: 'column' + (index + 1),
                    field: col.field,
                    width: 170
                });
                rows[0][col.field] = col.field;
            });
            uploaded['cols_arr'] = columns;
            uploaded['rows'] = [...rows, ...this.state.originalData['rows']];
            this.setState({ uploadedRecords: uploaded });
        } else {
            this.setState({ uploadedRecords: this.state.originalData });
        }
    }

    handleMatch = (event) => {
        this.setState({ [event.target.name]: event.target.checked });
    }

    handleRadioMatch = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleDropChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });

        let matchField = false;
        if (event.target.value == 'idrivecrmid') {
            let idriveidkey = this.findWithAttr(this.state.selectedFields, 'title', 'IDrive CRM id');
            if (idriveidkey != -1) {
                matchField = true;
            }
        }
        if (event.target.value == 'email') {

            this.state.customFields.map((field) => {
                if (field.id == 2) {
                    let idriveidkey = this.findWithAttr(this.state.selectedFields, 'title', field.title);
                    if (idriveidkey != -1) {
                        matchField = true;
                    }
                }
            });
        }
        this.setState({ matchField });
    }

    toggleCancelImportDialog = () => {
        this.setState({ isCancelImportDialog: !this.state.isCancelImportDialog });
    };

    confirmCancelImport = () => {
        this.backToImport();
    };

    render() {

        let { wrongRowKeys, restrictMultiAllow, checkbox_ignore, wrongValues, uploadedRecords, customFields, selectedFields, shouldOpenImportDialog, shouldOpenImportFixDialog } = this.state;

        let autocompletefields = [];
        let unselectedField = 0;
        let wrongCols = 0;

        if (selectedFields && selectedFields.length > 0) {

            selectedFields.map((field) => {

                if (checkbox_ignore != true && field.title != "Ignore" && (field.title == "" || field.title == undefined)) {
                    unselectedField++;
                }
                if (restrictMultiAllow.indexOf(field.title) != -1) {
                    unselectedField++;
                }
                if (wrongValues[field.table] != undefined && wrongValues[field.table].length > 0) {
                    wrongCols++;
                    autocompletefields.push(<div className="module">
                        <Grid container>
                            <Grid item md={8}>
                                <Autocomplete
                                    freeSolo
                                    className={(field.title && field.title.length > 0) ? "selected-autocomplete" : "not-selected-autocomplete"}
                                    id="free-solo-2-demo"
                                    onInputChange={(event, values, tableField) => this.handleAutoChange(event, values, field.table)}
                                    disableClearable
                                    name={field.table}
                                    onChange={(event, values, tableField) => this.handleAutoChange(event, values, field.table)}
                                    value={field.title}
                                    options={customFields.map((option) => option.title)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Field"
                                            margin="normal"
                                            value={field.title}
                                            InputProps={{ ...params.InputProps, type: 'search' }}
                                        />
                                    )}
                                />
                                {(restrictMultiAllow.indexOf(field.title) != -1) && <label className="import-validation-repeat-message">Field already in use</label>}
                                {(wrongRowKeys[field.table] && wrongRowKeys[field.table].length > 0) && <label className="import-validation-repeat-message">{wrongRowKeys[field.table].length} invalid cell</label>}
                            </Grid>
                            <Grid item md={2}>
                                <Button className={"import_fix_field_button"} onClick={(fixValue) => this.openImportFixDialog(wrongValues[field.table], field)}>Fix</Button>
                            </Grid>
                        </Grid>
                    </div>)
                } else {
                    if (checkbox_ignore == true) {
                        autocompletefields.push(<div className="module">
                            <Grid container>
                                <Grid item md={8}>
                                    <Autocomplete
                                        freeSolo
                                        className={(field.title && field.title.length > 0) ? "selected-autocomplete" : "not-selected-autocomplete"}
                                        id="free-solo-2-demo"
                                        onInputChange={(event, values, tableField) => this.handleAutoChange(event, values, field.table)}
                                        disableClearable
                                        name={field.table}
                                        onChange={(event, values, tableField) => this.handleAutoChange(event, values, field.table)}
                                        value={field.title.length > 0 ? field.title : "Ignore"}
                                        options={customFields.map((option) => option.title)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select Field"
                                                margin="normal"
                                                value={field.title.length > 0 ? field.title : "Ignore"}
                                                InputProps={{ ...params.InputProps, type: 'search' }}
                                            />
                                        )}
                                    />
                                    {(restrictMultiAllow.indexOf(field.title) != -1) && <label className="import-validation-repeat-message">Field already in use</label>}
                                </Grid>
                            </Grid>
                        </div>)
                    } else {
                        autocompletefields.push(<div className="module">
                            <Grid container>
                                <Grid item md={8}>
                                    <Autocomplete
                                        freeSolo
                                        className={(field.title && field.title.length > 0) ? "selected-autocomplete" : "not-selected-autocomplete"}
                                        id="free-solo-2-demo"
                                        onInputChange={(event, values, tableField) => this.handleAutoChange(event, values, field.table)}
                                        disableClearable
                                        name={field.table}
                                        onChange={(event, values, tableField) => this.handleAutoChange(event, values, field.table)}
                                        value={field.title}
                                        options={customFields.map((option) => option.title)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select Field"
                                                margin="normal"
                                                value={field.title}
                                                InputProps={{ ...params.InputProps, type: 'search' }}
                                            />
                                        )}
                                    />
                                    {(restrictMultiAllow.indexOf(field.title) != -1) && <label className="import-validation-repeat-message">Field already in use</label>}
                                </Grid>
                            </Grid>
                        </div>)
                    }
                }
            })
        }

        return (
            <React.Fragment>
                <div className="v_scroll crm-custom-importdata-settings">
                    <div className="mb-40">
                        <Typography className="p-10" variant="h5" color="inherit">Import Opportunities - {this.props.match.params.id}</Typography>
                        <Typography className="text-hint p-10" variant="p">
                            Import data into IDrive CRM from Google Contacts, CSV files, or Excel files.
                        </Typography>
                    </div>

                    {(uploadedRecords['rows'] && uploadedRecords['rows'].length > 0 && this.state.fileName.length > 0) ?
                        <Card className="p-20 mb-10 ">
                            <Typography className="font-weight-500 p-10" variant="p" color="inherit">Step 3: Match your CSV fields to IDrive CRM fields</Typography>
                            <br />
                            <Typography className="text-hint p-10" variant="p" color="inherit">Here is a preview of your data file. Match the columns in your file to your IDrive CRM fields.</Typography>
                            <Grid container spacing={2} className="mb-10 mt-30 import-buttons">
                                <Grid item md={12}>
                                    <Checkbox
                                        checked={this.state.checkbox_exclude}
                                        onChange={event => this.handleExlude(event)}
                                        value="checkbox_exclude"
                                        color="primary"
                                    />
                                    <label>Exclude the first row.</label>

                                    <Checkbox
                                        checked={this.state.checkbox_ignore}
                                        onChange={event => this.handleIgnore(event)}
                                        value="checkbox_ignore"
                                        name="checkbox_ignore"
                                        color="primary"
                                    />
                                    <label>Ignore all unselected fields.</label>

                                    <Checkbox
                                        checked={this.state.checkbox_matching}
                                        onChange={event => this.handleMatch(event)}
                                        value="checkbox_matching"
                                        name="checkbox_matching"
                                        color="primary"
                                    />
                                    <label>Manage matching records.</label>
                                </Grid>
                                {this.state.checkbox_matching &&
                                    <>
                                        <Grid item md={2}>
                                            <RadioGroup name="radio_matching" value={this.state.radio_matching} onChange={(event) => this.handleRadioMatch(event)} row>
                                                <FormControlLabel
                                                    value="skip"
                                                    control={<Radio color="primary" />}
                                                    label="Skip"
                                                    labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                    value="overwrite"
                                                    control={<Radio color="primary" />}
                                                    label="Overwrite"
                                                    labelPlacement="end"
                                                />
                                            </RadioGroup>
                                        </Grid>
                                        <Grid item md={2}>
                                            <FormControl className="w-100 input_bx">
                                                <Select
                                                    value={this.state.drop_matching}
                                                    onChange={(event) => this.handleDropChange(event)}
                                                    name="drop_matching"
                                                >
                                                    <MenuItem className="select-menu-item w-100 input_bx" value={'selectfield'}>Select Field</MenuItem>
                                                    <MenuItem className="select-menu-item w-100 input_bx" value={'IDrive CRM id'}>IDrive CRM id</MenuItem>
                                                    <MenuItem className="select-menu-item w-100 input_bx" value={'Name'}>Name</MenuItem>
                                                    <MenuItem className="select-menu-item w-100 input_bx" value={'Email Domain'}>Email Domain</MenuItem>
                                                </Select>
                                            </FormControl>
                                            {(this.state.matchField == false) &&
                                                <label className="import-validation-repeat-message">You need to select field first</label>
                                            }
                                        </Grid>
                                    </>
                                }

                            </Grid>
                            <Grid container spacing={2} className="mb-10 mt-30 import-buttons">
                                {/* <Grid item md={12}>
                                        <TableComponent data={uploadedRecords} />
                                    </Grid> */}
                                <div id="container" className="crm-container-import">
                                    <div onScroll={this.OnScroll} id="crm-container-import-modules" className="crm-container-import-modules">
                                        {autocompletefields}
                                    </div>
                                </div>
                                <Typography className="text-hint p-10" variant="p" color="inherit">Note* : Please use shift + mouse scroll to scroll the table OR keyboard left/right key.</Typography>
                                <Grid item md={12} onScroll={this.OnScroll}>
                                    <TableComponent data={uploadedRecords} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} className="mb-10 mt-30 import-buttons">

                                {(unselectedField == 0 && wrongCols == 0) ?
                                    <>
                                        <Grid item md={10} className={"import-errors-div"} style={{ color: 'red' }}></Grid>
                                        <Grid item md={2}>
                                            <Button color="primary" variant="contained" className="pull-right" onClick={this.openImportDialog}>Continue Import</Button>
                                            <Button onClick={this.toggleCancelImportDialog} color="secondary" className="pull-right">Cancel</Button>
                                        </Grid>
                                    </>
                                    :
                                    <>
                                        <Grid item md={10} className={"import-errors-div"} style={{ color: 'red' }}>
                                            <Icon className={"import-errors-icon"} style={{ height: '20px' }}>warning</Icon> <span className={"import-errors-text"}>You need to match {unselectedField} fields and fix {wrongCols} invalid cells</span>
                                        </Grid>
                                        <Grid item md={2}>
                                            <Button color="default" variant="contained" className="pull-right" >Continue Import</Button>
                                            <Button onClick={this.toggleCancelImportDialog} color="secondary" className="pull-right">Cancel</Button>
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        </Card>
                        :
                        <Card className="p-20 mb-10 ">
                            <Typography className="font-weight-500 p-10" variant="p" color="inherit">Step 2: Choose your Excel file to import</Typography>
                            <Grid container spacing={2} className="mb-10 mt-30 import-buttons">
                                <Grid item md={8}>
                                    <DropzoneArea
                                        filesLimit={1}
                                        showPreviewsInDropzone={false}
                                        acceptedFiles={[".csv, text/csv, .xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values"]}
                                        maxFileSize={5000000}
                                        showAlerts={false}
                                        onChange={this.handleChange.bind(this)}
                                    />
                                </Grid>
                                <Grid item md={4} className={'text-left'}>
                                    <div><Typography className="font-weight-500 " variant="p" color="inherit">Need help formatting your Excel file?</Typography></div>
                                    <div>
                                        <Typography className="text-hint" variant="p">
                                            Download our <a className="cursor_poiter default-template-link" href={this.state.samplefilelink}  onClick={this.downloadSampleFile}>pre-formatted Excel template</a> and follow the formatting for the best results.
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                            <Button onClick={this.backToImport} color="secondary" >Cancel</Button>
                        </Card>
                    }
                </div>
                <Dialog
                    fullWidth={true}
                    maxWidth={'xs'}
                    open={this.state.isCancelImportDialog}
                    onClose={this.toggleCancelImportDialog}
                    disableBackdropClick
                    // disableEscapeKeyDown
                    aria-labelledby="ar-remove-dialog-title"
                >
                    <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to cancel the import?"}</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.toggleCancelImportDialog} color="primary">
                            Cancel
                        </Button>
                        <Button type="Button" onClick={() => this.confirmCancelImport()} className="btn_Primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>

                {shouldOpenImportDialog &&
                    (<ImportDialog
                        handleClose={this.handleImportDialogClose}
                        open={shouldOpenImportDialog}
                        handleMinimize={this.handleImportDialogMinimize}
                        handleFull={this.handleImportDialogFull}
                        minimizeScreen={this.state.minimizeImportDialog}
                        fullScreen={this.state.FullImportDialog}
                        fullfilename={this.state.fileName}
                        selectedFields={this.state.selectedFields}
                        backToImport={this.backToImport}
                        oldColumnValues={this.state.oldColumnValues}
                        newColumnValues={this.state.newColumnValues}
                        data={this}
                    />)}

                {shouldOpenImportFixDialog &&
                    (<ImportFixDialogOpportunity
                        handleClose={this.handleImportFixDialogClose}
                        open={shouldOpenImportFixDialog}
                        handleMinimize={this.handleImportFixDialogMinimize}
                        handleFull={this.handleImportFixDialogFull}
                        minimizeScreen={this.state.minimizeImportFixDialog}
                        fullScreen={this.state.FullImportFixDialog}
                        valueToFixArr={this.state.valueToFixArr}
                        columnToFix={this.state.columnToFix}
                        wrongRowKeys={this.state.wrongRowKeys[this.state.columnToFix.table]}
                        selectedFields={this.state.selectedFields}
                        uploadedRecords={this.state.uploadedRecords}
                        customFields={customFields}
                        data={this}
                    />)}

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        settingReducer: state.settingReducer,
        apolloClient: state.apolloClient
    };
};

const mapDispatchToProps = dispatch => {
    return {
        customFieldList: (client, request) => { dispatch(actions.customFieldList(client, request)) },
        getCountryList: (client) => { dispatch(actions.getCountryList(client)) },
        getCompanyRecList: (client) => { dispatch(actions.getCompanyRecList(client)) },
        getUserList: (client) => dispatch(actions.getUserList(client)),
        getCompanyCurrency: (client) => dispatch(actions.getCompanyCurrency(client)),

        getPipelines: (client) => dispatch(actions.getPipelines(client)),

        importDataFile: (client, req) => { dispatch(actions.importDataFile(client, req)) },
        importExcelData: (client, req) => { dispatch(actions.importExcelData(client, req)) },
        sampleFileExport: (client, req) => { dispatch(actions.sampleFileExport(client, req)) },
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(UploadOpportunities);