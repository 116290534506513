import React, { Component } from "react";
import { List, ListItem, Menu, ListItemText, LinearProgress, Fab, Grid, withStyles, Switch, InputLabel, FormControl, Input, FormHelperText, RadioGroup, Radio, FormControlLabel, Divider, DialogContent, InputAdornment, Button, IconButton, Icon, MenuItem, Card, TextField, Dialog, DialogActions, DialogTitle, Drawer, Avatar } from "@material-ui/core";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { NavLink, withRouter } from "react-router-dom";

class Project extends Component {
    state = {
        options: [],
        isOpen: false,
        selectedIndex: 1,
        anchorEl: null,
        oldStageId: "",
        opportunitiesid: "",
        stageOptions: "",
        getRelatedReq: "",
    };

    componentDidMount() {
        if(this.props.opportunitiesid){
            this.setState({
                opportunitiesid:this.props.opportunitiesid
            })
        }
        if(this.props.getRelatedReq){
            this.setState({
                getRelatedReq:this.props.getRelatedReq
            })
        }
        if(this.props.stageOptions){
            var array = []
            this.props.stageOptions.map((v,k)=>{
                array.push(v.name)
            })
            this.setState({
                options:array,
                stageOptions:this.props.stageOptions
            })
            if(this.props.oldStageId){
                var key = this.findWithAttr(this.props.stageOptions, 'id', this.props.oldStageId);
                if(key != -1){
                    var selectedKey = array.indexOf(this.props.stageOptions[key].name)
                    this.setState({
                        oldStageId:parseInt(this.props.oldStageId),
                        selectedIndex:selectedKey,
                    })
                }
                
            }
        }
    }
    componentWillReceiveProps(props) {

    }

    handleClickListItem(event) {
        this.setState({
            isOpen: !this.state.isOpen,
            anchorEl: event.currentTarget,
        })
    }

    handleMenuItemClick = (event, index) => {
        var key = this.findWithAttr(this.state.stageOptions, 'name',this.state.options[index]);
        var newStage = this.state.stageOptions[key]
        this.setState({
            selectedIndex: index,
        })
        var req = {
            updated_stage_id:newStage.id,
            current_stage_id:parseInt(this.state.oldStageId),
            opportunitiesid:parseInt(this.state.opportunitiesid),
            getRelatedReq:this.state.getRelatedReq,
        }
        this.props.updateOpportunitiesStage(this.props.apolloClient.client,req);

        this.handleClose()
        this.setState({
            oldStageId: newStage.id,
        })
    }

    handleClose() {
        this.setState({ isOpen: false })
    }

    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] == value) {
                return i;
            }
        }
        return -1;
    }
    render() {

        return (
            <>
            <List className="dorpdown_menu">
                    <ListItem
                        aria-haspopup="true"
                        aria-controls="lock-menu"
                        onClick={(event)=>this.handleClickListItem(event)}
                    >
                        <ListItemText className="dorpdown_menu_list"
                            secondary={this.state.options[this.state.selectedIndex]}
                        />
                        <Icon>arrow_drop_down</Icon>
                    </ListItem>
                </List>
                <Menu 
                id = "lock-menu"
                anchorEl = { this.state.anchorEl } 
                keepMounted 
                open = { this.state.isOpen } 
                onClose = {() => this.handleClose() } >
                {
                    this.state.options.map((option, index) => (
                        <MenuItem
                                className="select-menu-item"
                                key={option}
                                selected={index === this.state.selectedIndex}
                                onClick={(event)=> this.handleMenuItemClick(event, index)}
                            >{option}
                            </MenuItem>
                    ))
                }
            </Menu>
            </>
        );
    }
}

const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
    notification: state.notification,
    opportunityReducer: state.opportunityReducer,
});

const mapDispatchToProps = dispatch => {
    return {
        updateOpportunitiesStage: (client, req) => { dispatch(actionCreators.updateOpportunitiesStage(client, req)) },
    }
}


export default (connect(mapStateToProps, mapDispatchToProps)(withRouter(Project)));