import React, { Component } from 'react';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import XLSX from 'xlsx';
import { make_cols } from './MakeColumns';
import { SheetJSFT } from './types';

export const ExcelReader = (files, dateformat) => {
  let ReturnExcelReadFile = [];
  // let cols_arr = [];
  // let rows = [];
  // Get the current 'global' time from an API using Promise
  return new Promise((resolve, reject) => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true, cellDates: true, dateNF: dateformat
      // , cellNF: false, cellText: false
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      
      
      let cols = [];
      // var cells = Object.keys(ws);
      // for (var i = 0; i < Object.keys(cells).length; i++) {
      //   if (cells[i].indexOf('1') > -1) {
          
      //     cols.push({ headerName: ws[cells[i]].v, field: ws[cells[i]].v, width: 170, lockPosition: true });
      //   }
      // }
      
      
      let cells = XLSX.utils.sheet_to_json(ws, { header: 1 })[0];
      cells.map((cell)=>{
        cols.push({ headerName: cell, field: cell, width: 170, lockPosition: true });
      })
      
      const data = XLSX.utils.sheet_to_json(ws, { raw: false });

      ReturnExcelReadFile['rows'] = data;
      ReturnExcelReadFile['cols_arr'] = cols;  

      // if (data && data.length > 0) {
      //   data.map((datas) => {
      //     Object.entries(datas).forEach(([key, value]) => {
      //       // if (findWithAttr(cols, 'field', key) == -1) {
      //       //   // cols.push({ headerName: key, field: key, width:170})
      //       //   // cols.push({ title: key, dataIndex: key, width:150 })
      //       //   // cols.push({ name: key, title: key })
      //       //   // cols.push({ field: key, caption: key, size: '30%',attr: 'align=center' })
      //       // }
      //       // col[key] = key
      //     });
      //   });
      //   // data.unshift(col);
        
      //   
      // }
    };
   
    
    if (rABS) {
      reader.readAsBinaryString(files);
    } else {
      reader.readAsArrayBuffer(files);
    };

    setTimeout(function () {
      
      ReturnExcelReadFile['rows'].length > 0 ? resolve(ReturnExcelReadFile) : reject(0);
    }, 1000);
  })
}

const findWithAttr = (array, attr, value) => {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] == value) {
      return i;
    }
  }
  return -1;
}