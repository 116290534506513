import React from "react";
import { Icon, List, ListItem, Menu, ListItemText, MenuItem } from "@material-ui/core";


const options = [
    "None",
    "Name",
    "Read only",
    "Required",
];

export default function CreationRule() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    function handleClickListItem(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleMenuItemClick(event, index) {
        setSelectedIndex(index);
        setAnchorEl(null);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <React.Fragment>
            <List className="dorpdown_menu">
                <ListItem
                    aria-haspopup="true"
                    aria-controls="lock-menu"
                    onClick={handleClickListItem}
                >
                    <ListItemText className="dorpdown_menu_list"
                        secondary={options[selectedIndex]}
                    />
                    <Icon>arrow_drop_down</Icon>
                </ListItem>
            </List>
            <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {options.map((option, index) => (
                    <MenuItem
                        className="select-menu-item"
                        key={option}
                        selected={index === selectedIndex}
                        onClick={event => handleMenuItemClick(event, index)}
                    >{option}
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    );
}
