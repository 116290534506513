import React, { Component } from "react";
import { Dialog, IconButton, Button, Icon, Tooltip, withStyles, Grid,DialogActions } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';


const TooltipNew = withStyles((theme) => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);

class AddLossReason extends Component {
  state = {
    name:'',
    isEdit:false,
    reasonid :'',
    dialogTitle:'Create Loss Reason',
  };


  handleChange = (event, source) => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  handleFormSubmit = () => {
    let error = [];
    if (!this.state.name || !this.state.name.trim()) {
      error.push("name")
    }

    let req = {};

    if(this.state.isEdit){
      req.name = this.state.name;
      req.id = parseInt(this.state.reasonid);
      this.props.updateLossReasonDetail(this.props.apolloClient.client,req);
    }else{
      req.name = this.state.name;
      console.log("this.state.name", this.state.name);
      this.props.saveLossReason(this.props.apolloClient.client,req);
    }
  };

  componentWillReceiveProps(props){
    if(props.notification && props.notification.status === true){
        this.props.handleClose();
        this.props.clearNotification();
    }
  }

  componentDidMount() {
    ValidatorForm.addValidationRule('nameTrim', (value) => {
      if (!value || !value.trim()) {
        return false;
      }
      return true;
    });

    if(this.props.lossReasonsData.isEdit){
      this.setState({
          reasonid:this.props.lossReasonsData.id,
          name:this.props.lossReasonsData.name,
          isEdit:true,
          dialogTitle:'Update Loss Reason',
      });
    }else{
      this.setState({
          name:'',
          isEdit:false,
          reasonid :'',
          dialogTitle:'Create Loss Reason',
      });
    }
  }

  render() {
    let { open, handleClose, handleMinimize, handleFull, fullScreen, minimizeScreen} = this.props;
    return (
      <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth={true} className={`crm-custom-bg-primary custom_dialog ${minimizeScreen && fullScreen ? 'alignitemsend-fullscreen' : ''} ${minimizeScreen ? 'alignitemsend' : ''} ${fullScreen ? 'fullscreen' : ''}`} disableBackdropClick>
        <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">
          <h4 className="m-0 text-white">{this.state.dialogTitle}</h4>
          <div className="d-flex">
            <TooltipNew title="Minimize Screen">
              <IconButton onClick={handleMinimize}>
                <Icon className="text-white">remove</Icon>
              </IconButton>
            </TooltipNew>
            <TooltipNew title="Full Screen">
              <IconButton onClick={handleFull}>
                <Icon className="text-white rotate-45">height</Icon>
              </IconButton>
            </TooltipNew>
            <TooltipNew title="Close">
              <IconButton onClick={handleClose}>
                <Icon className="text-white">clear</Icon>
              </IconButton>
            </TooltipNew>
          </div>
        </div>
          <div className="dialog-body-no-scroll">
            <Grid container spacing={4}>
              <div className="p-24 mt-20 width-100">
                <ValidatorForm ref="form" noValidate debounceTime={500} onSubmit={this.handleFormSubmit}>
                  <Grid className="mb-16" container spacing={4}>
                    <Grid  sm={12} xs={12}>
                      <TextValidator
                        className="w-100 mb-16"
                        placeholder={"Enter Loss Reason Name"}
                        onChange={this.handleChange}
                        type="text"
                        name="name"
                        value={this.state.name}
                        validators={["required", "nameTrim"]}
                        errorMessages={["This field is required", "This field is required"]}
                        autoComplete='off'
                      />
                    </Grid>
                  </Grid>
                  <DialogActions className="padding__0">
                    <Button onClick={() => this.props.handleClose()} variant="contained">Cancel</Button>
                    {!this.state.isEdit && 
                      <Button variant="contained" type="submit" color="primary" className="btn_Primary">Save</Button>
                    }
                    {this.state.isEdit && 
                      <Button variant="contained" type="submit" color="primary" className="btn_Primary">Update</Button>
                    }
                    
                  </DialogActions>
                </ValidatorForm>
              </div>
            </Grid>
          </div>
      </Dialog>
    );
  }
}
const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
});
const mapDispatchToProps = dispatch => {
  return {
    saveLossReason: (c,req) => dispatch(actionCreators.saveLossReason(c,req)),
    updateLossReasonDetail: (client,req) => dispatch(actionCreators.updateLossReasonDetail(client,req)),
    clearNotification: () => dispatch(actionCreators.clearNotification()),
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddLossReason);
