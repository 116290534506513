import React, { Component } from "react";
import { IconButton, Icon, MenuItem, withStyles, Fab, Button, DialogContent, Dialog, DialogActions, DialogTitle, Avatar } from "@material-ui/core";
import DropdownDefault from "../Layout/DropdownDefault";
import ProfileDetails from "./ProfileDetails";
import * as actionCreators from '../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';
import { Redirect, Route } from 'react-router-dom'
import moment from 'moment';
//import ConvertLeadDialog from './convertLeadDialog'
import CreatelogDialog from "../CommonLogUI/CreatelogDialog";
import CreateNewTaskDialog from "../Tasks/CreateNewTaskDialog";
import InboxComposeDialog from "../CommonComposeMail/InboxComposeDialog";
import { parse } from "date-fns";

const IconButtonWhite = withStyles(theme => ({
    root: {
        // color: theme.palette.getContrastText(purple[500]),
        backgroundColor: "transparent",
        padding: "0 5px 5px 5px",
    }
}))(IconButton);

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.3rem",
    }
}))(Icon);
class ProfileLeft extends Component {
    state = {
        isOpenedEmail: false,
        isOpenedReplayEmail: false,
        isEditCommentLog: false,
        Commentoptions: '',
        showCommentoptions: false,
        logvaluetype: '',
        logtype: 0,
        selectedDate: new Date(),
        textvalue: '',
        shouldOpenCreateLogDialog: false,
        FullCreateOpportunityDialog: false,
        minimizeOpportunityDialog: false,
        isDeleteLogConfirmDialoOpen: false,
        isEditLog: false,
        logid: '',
        TabValue: 0,
        allLogs: [],
        name: "",
        isDeleteConfirmDialoOpen: false,
        title: "",
        companyName: "",
        colorClass: '',
        interaction: 0,
        lastcontacted: "--",
        leadstatus: "",
        inactivedays: 0,
        dateformat: 'MM/DD/YYYY',
        timeZone: 'America/New_York',
        isfavorite: false,
        star_icon: 'star_border',
        fieldsObj: {},
        fieldsList: [],
        usersListArray: [],
        leadImage: "",
        countryListArray: [],
        systemTagsList: [],
        companyCurrencyData: [],
        userSettings: "",
        redirecttoListing: false,

        shouldOpenCreateTaskDialog: false,
        FullCreateTaskDialog: false,
        minimizeTaskDialog: false,
        newTaskData: {},
        isOpenedClickEmail: false,
        crudPermission: {
            iscreate: true,
            isdelete: true,
        }
    }
    getUserPermission = () => {
        if (this.props.settingReducer.userPermissionDetails.data) {
            let udata = this.props.settingReducer.userPermissionDetails.data.data
            if (udata.visibilityaccess.length > 0 &&
                udata.teampermissionstatus === true &&
                this.props.authReducer.userRole.id === 3) {
                let selectedPipelineId = localStorage.getItem('selected_pipeline_id')
                let maindata = udata.visibilityaccess.find(function (e) { return e.piplineid === parseInt(selectedPipelineId) });
                this.setState({
                    crudPermission: {
                        iscreate: maindata.iscreate,
                        isdelete: maindata.isdelete,
                        isedit: maindata.isedit,
                    }
                })
            }
        }
    }

    componentDidMount() {
        let val = { typeId: 4 }
        let userData = JSON.parse(localStorage.getItem('user_data_session'))
        let userid = { userid: userData.id }
        this.props.customFieldList(this.props.apolloClient.client, val);
        this.props.getPipelines(this.props.apolloClient.client);

        if (this.props.opportunities_id) {

            this.setState({
                opportunities_id: this.props.opportunities_id,
            }, () => this.forceUpdate());
        }
    }
    componentWillReceiveProps(props) {
        this.getUserPermission();
        //Pipelines list array
        if (props.settingReducer.pipelineListArray.data) {
            var pipeData = props.settingReducer.pipelineListArray.data
            //Apply pipeline id to localstorage
            var selectedPipelineId = localStorage.getItem('selected_pipeline_id');
            if (!selectedPipelineId) {
                localStorage.setItem('selected_pipeline_id', "1");
            } else {
                selectedPipelineId = localStorage.getItem('selected_pipeline_id');
            }
            selectedPipelineId = localStorage.getItem('selected_pipeline_id');

            this.setState({
                pipelineListArray: pipeData,
                isLoadingPipelineList: false,
                selectedPipelineId: selectedPipelineId,
                //isDataLoading: false,
            }, () => {
                this.forceUpdate()
                props.settingReducer.pipelineListArray.is_loaded = false
            })
        }
        //storing user setting data in state
        if (props.settingReducer.userSettingArray.data && this.state.userSettings == "") {
            const settingData = props.settingReducer.userSettingArray.data;
            this.setState({
                userSettings: settingData,
            })
        }
        if (props.opportunities_id !== undefined) {
            this.setState({
                opportunities_id: props.opportunities_id,
            }, () => this.forceUpdate());
        }


        if (props.opportunityReducer.opportunityFieldData.data) {
            var fielddata = props.opportunityReducer.opportunityFieldData.data;

            let star_ic = fielddata[0].leadfavourite ? 'star' : 'star_border';
            this.setState({ star_icon: star_ic, isfavorite: fielddata[0].leadfavourite });

            if (fielddata[0].opportunitiesdetail && fielddata[0].opportunitiesdetail.length > 0 && fielddata[0].opportunitiesdetail[0].lead) {
                this.setState({
                    interaction: fielddata[0].opportunitiesdetail[0].lead.interactions ? fielddata[0].opportunitiesdetail[0].lead.interactions : 0,
                    lastcontacted: fielddata[0].opportunitiesdetail[0].lead.lastcontacted ? moment.unix(fielddata[0].opportunitiesdetail[0].lead.lastcontacted).tz(this.state.timeZone).format(this.state.dateformat) : "--",
                    inactivedays: fielddata[0].opportunitiesdetail[0].lead.inactivedays ? fielddata[0].opportunitiesdetail[0].lead.inactivedays : 0,
                })
            }


            if (props.leadLogs && props.leadLogs.length > 0) {
                // console.log(props.leadLogs, 'props.leadLogs');
                // return
                let logged_in_user_email = JSON.parse(localStorage.getItem("user_data_session")).email;
                let logData = [];
                props.leadLogs.map((data) => {
                    if (data.email_type) {

                    } else {
                        let template = data.template;
                        let color_class = (data.__user__.name) ? data.__user__.name.charAt(0) : "";
                        let text = (data.text) ? data.text : "";
                        let loguser = (data.__user__.email == logged_in_user_email) ? 'You' : data.__user__.name;
                        let template1 = (data.template) ? template.replace("{{user}}", loguser) : "";

                        let new_template = "";

                        let create_date = data.createddate;
                        let updateddate = data.updateddate;
                        let comments = [];
                        if (data.logtype == 1) // create time
                        {
                            new_template = template1.replace("{{source_type}}", "Lead");
                        }
                        else if (data.logtype == 0) // Phone call
                        {
                            updateddate = data.logdate;
                            new_template = template1.replace("{{property_name}}", '"' + data.propertyname + '"');
                        }
                        else if (data.logtype == 1000) {// RingCentral Call
                            updateddate = data.logdate;
                            new_template = "Ring Central Calls";
                        }
                        else if (data.logtype == 2) // name change
                        {
                            let old_template = template1.replace("{{source_type}}", "Lead");
                            new_template = old_template.replace("{{source name=new_value}}", '"' + data.newvalue + '"');
                        }
                        else if (data.logtype == 3 && data.__assignuser__) // owner change
                        {
                            let logAssignUser = (data.__assignuser__.email == logged_in_user_email) ? 'You' : data.__assignuser__.name;
                            new_template = template1.replace("{{target}}", '"' + logAssignUser + '"');
                        }
                        else if (data.logtype == 4) // for status
                        {
                            let old_template = template1.replace("{{old_value}}", data.oldvalue);
                            new_template = old_template.replace("{{new_value}}", data.newvalue);
                        }
                        else if (data.logtype == 5) // for note
                        {
                            updateddate = data.logdate;
                            comments = data.__commentlist__;
                            new_template = template1.replace("{{property_name}}", '"' + data.propertyname + '"');
                        }

                        logData.push(
                            {
                                logId: data.id,
                                emojidetail: data.emojidetail && data.emojidetail !== null ? JSON.parse(data.emojidetail) : [],
                                emailtype: 0,
                                comments: comments,
                                user: data.__user__.name,
                                propertyType: data.propertyname,
                                color: color_class,
                                template: new_template,
                                text: text,
                                logtype: data.logtype,
                                time: (updateddate != null)
                                    ?
                                    moment.unix(updateddate).tz(this.state.timeZone).format('hh:mm a')
                                    :
                                    moment.unix(create_date).tz(this.state.timeZone).format('hh:mm a'),
                                date: (updateddate != null)
                                    ?
                                    moment.unix(updateddate).tz(this.state.timeZone).format(this.state.dateformat)
                                    :
                                    moment.unix(create_date).tz(this.state.timeZone).format(this.state.dateformat)
                            }
                        );
                    }
                });
                this.setState({ allLogs: logData });
            }


            var companyKey = this.findWithAttr(fielddata, 'id', "44");
            if (companyKey != -1 && fielddata[companyKey].opportunitiesdetail.length > 0) {
                this.setState({
                    companyName: fielddata[companyKey].opportunitiesdetail[0].values[0].value,
                }, () => this.forceUpdate())
            }
            else {
                this.setState({
                    companyName: "",
                }, () => this.forceUpdate())
            }

            var nameKey = this.findWithAttr(fielddata, 'id', "40");
            if (nameKey != -1 && fielddata[nameKey].opportunitiesdetail.length > 0 && fielddata[nameKey].opportunitiesdetail[0].values) {
                var names = fielddata[nameKey].opportunitiesdetail[0].fieldvalue;
                let colorClass = (names.firstname) ? names.firstname.charAt(0).toLowerCase() : "";
                this.setState({
                    name: names,
                    colorClass: colorClass,
                }, () => this.forceUpdate())
            }


        }



    }

    handleTaskDialogClose = () => {
        this.setState({ shouldOpenCreateTaskDialog: false, FullCreateTaskDialog: false, minimizeTaskDialog: false });
    };
    handleTaskDialogMinimize = () => {
        this.setState(oldStateminimizeTaskDialog => ({ minimizeTaskDialog: !oldStateminimizeTaskDialog.minimizeTaskDialog }));
        this.setState({ FullCreateTaskDialog: false })
    };
    handleTaskDialogFull = () => {
        this.setState(oldStateFullCreateTaskDialog => ({ FullCreateTaskDialog: !oldStateFullCreateTaskDialog.FullCreateTaskDialog }));
        this.setState({ minimizeTaskDialog: false })
    };

    //----log functions------------
    handleOpportunitiesDialogClose = () => {
        this.setState({ shouldOpenCreateLogDialog: false, FullCreateOpportunityDialog: false, minimizeOpportunityDialog: false });
    };
    handleOpportunitiesDialogMinimize = () => {
        this.setState(oldStateminimizeOpportunityDialog => ({ minimizeOpportunityDialog: !oldStateminimizeOpportunityDialog.minimizeOpportunityDialog }));
        this.setState({ FullCreateOpportunityDialog: false })
    };
    handleOpportunitiesDialogFull = () => {
        this.setState(oldStateFullCreateOpportunityDialog => ({ FullCreateOpportunityDialog: !oldStateFullCreateOpportunityDialog.FullCreateOpportunityDialog }));
        this.setState({ minimizeOpportunityDialog: false })
    };

    handletextchange = (event) => {
        this.setState({ textvalue: event.target.value })
    }

    handleDateChange = date => {
        this.setState({ selectedDate: date });
    };

    handleSaveEditLog = () => {
        let date = moment(this.state.selectedDate).format("X");
        let textvalue = this.state.textvalue;
        let saveLogparams = [];

        saveLogparams['update'] = {
            text: textvalue,
            logtime: parseFloat(date),
            logdate: parseFloat(date),
            logid: parseFloat(this.state.logid),
        };
        // saveLogparams['opportunitiesid'] = this.state.opportunities_id
        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        saveLogparams['getlog'] = { emailid: emailParam, opportunitiesid: parseFloat(this.props.opportunities_id) };

        this.props.updateLeadLog(this.props.apolloClient.client, saveLogparams);
        this.setState({ isEditLog: false })
    }

    handleEditLog = (id, date, time, text) => {
        let date1 = new Date(date + ',' + time);
        this.setState({ isEditLog: true, textvalue: text, selectedDate: date1, logid: id });
    }
    handleChangeLog = event => {
        event.persist();
        this.setState({ [event.target.name]: event.target.value });
    };
    handleCancelEdit = () => {
        this.setState({ isEditLog: false })
    }

    handleDeleteLeadLog = (logid) => {
        this.setState({ logid: logid });
        this.toggleDeleteLogDialog()
    }

    toggleDeleteLogDialog = () => {
        this.setState({ isDeleteLogConfirmDialoOpen: !this.state.isDeleteLogConfirmDialoOpen });
    };

    confirmDeleteLog = () => {
        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        let deletelogParams = { logid: parseFloat(this.state.logid), opportunitiesid: parseFloat(this.state.opportunities_id), emailid: emailParam };
        this.props.deleteLeadLogDetail(this.props.apolloClient.client, deletelogParams);
        this.toggleDeleteLogDialog();
    };

    //- log comments
    handleClick = (event) => {
        this.setState({ Commentoptions: event.target, showCommentoptions: true });
    }
    handleCloseOptions = (event) => {
        this.setState({ Commentoptions: null, showCommentoptions: false });
    }
    toggleComment = (logid) => {
        this.setState(({ isOpenedComment: !this.state.isOpenedComment, logid: logid }));
    }

    handleCancelComment = () => {
        this.setState({ commentvalue: '' });
    }

    handleSaveCommentLog = () => {
        let commentParams = { comment: this.state.commentvalue, logid: parseFloat(this.state.logid), opportunitiesid: parseFloat(this.state.opportunities_id) };
        this.props.addLeadLogComment(this.props.apolloClient.client, commentParams);
        this.setState({ commentvalue: '' });
    }

    //-edit comment

    //-delete comment

    //------------
    //---- email functions
    toggleEmail = (id) => {
        this.setState(({ logid: id, isOpenedEmail: !this.state.isOpenedEmail }));
    }
    toggleReplayEmail = () => {
        this.setState(oldStateReplayEmail => ({ isOpenedReplayEmail: !oldStateReplayEmail.isOpenedReplayEmail }));
    }

    //------------
    //--------------------


    handleSubmit = event => {
        console.log("submitted");
        console.log(event);
    };

    handleChange = event => {
        event.persist();
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeTab = (event, newValue) => {
        this.setState({ TabValue: newValue });
    }

    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }

    handleDeleteOpportunity = () => {
        this.toggleDeleteDialog()
    }

    toggleDeleteDialog = () => {
        this.setState({ isDeleteConfirmDialoOpen: !this.state.isDeleteConfirmDialoOpen });
    };
    confirmDeleteCustomField = () => {
        let deleteOpportunityParams = [];

        deleteOpportunityParams['deleteid'] = {
            opportunitiesid: [parseFloat(this.state.opportunities_id)],
            piplineid: parseFloat(this.state.selectedPipelineId),
        };
        deleteOpportunityParams['listval'] = {
            filterdata: [],
            list: {
                limit: 10,
                pageno: 1,
                searchtext: "",
                sorttype: "",
                type: "list",
                fieldId: 0,
                columnname: "",
            },
            filterid: 1
        };
        this.toggleDeleteDialog();
        this.props.deleteOpportunities(this.props.apolloClient.client, deleteOpportunityParams);
        this.setState({ redirecttoListing: true });
    };
    handleFavorite = (opportunitiesid, status) => {
        let setFavoriteParams = [];

        let fav = !status;
        let star_i = fav ? 'star' : 'star_border';
        this.setState({ isfavorite: fav, star_icon: star_i });

        setFavoriteParams['favorite'] = { status: !status, opportunitiesid: opportunitiesid };
        setFavoriteParams['listval'] = {
            filterdata: [],
            list: {
                limit: 10,
                pageno: 1,
                searchtext: "",
                sorttype: "",
                type: "list",
                fieldId: 0,
                columnname: ""
            },
            filterid: 1
        };

        this.props.addFavouriteOpportunities(this.props.apolloClient.client, setFavoriteParams);
    }
    opeAddNewTaskPopup = () => {
        var data = {
            recordtype: "opportunities",
            type: "tasks",
            id: parseInt(this.state.opportunities_id),
        }
        this.setState({
            newTaskData: data,
        }, () => {
            this.setState({
                shouldOpenCreateTaskDialog: true
            });
        })
    }
    isOpenedClickEmailToggle = () => {
        this.setState({
            isOpenedClickEmail: !this.state.isOpenedClickEmail
        })
    }
    handleSaveAddLog = (data) => {
        let saveLogparams = [];
        saveLogparams['save'] = {
            mentionuser: data.mentionuser,
            mentiondetail: data.mentiondetail,
            propertyname: data.propertyname,
            propertyid: data.propertyid,
            text: data.text,
            logtime: data.logtime,
            logdate: data.logdate,
            oldvalue: "",
            newvalue: "",
            logtype: data.logtype,
            fieldid: data.fieldid,
            opportunitiesid: parseFloat(this.props.opportunities_id)
        };
        saveLogparams['getlog'] = { opportunitiesid: parseFloat(this.props.opportunities_id), activitypeid: [], logtypeid: [] };
        console.log(saveLogparams, 'saveLogparams');
        this.props.saveOpportunitiesLog(this.props.apolloClient.client, saveLogparams);
        this.setState({ shouldOpenCreateLogDialog: false });
    }
    render() {
        let { FullCreateTaskDialog, minimizeTaskDialog, shouldOpenCreateTaskDialog, redirecttoListing, isOpenedEmail, isOpenedReplayEmail, commentvalue, textvalue, selectedDate, shouldOpenCreateLogDialog, TabValue, allLogs, colorClass } = this.state;
        let { handleClose } = this.props;

        return (
            (redirecttoListing) ?
                <Redirect to='/opportunities/opportunities-list' />
                :
                <aside className="sidenav">
                    <div className="sidenav__user">
                        <div className="more_vert_menu">
                            <DropdownDefault
                                menuButton={
                                    <IconButton
                                        aria-label="More"
                                        aria-haspopup="true"
                                    >
                                        <Icon>more_vert</Icon>
                                    </IconButton>
                                }
                            >
                                {/* <MenuItem className="flex flex-middle"><Icon className="mr-16 text-muted">launch</Icon>Open in web</MenuItem> */}
                                {/* <MenuItem className="flex flex-middle"><Icon className="mr-16 text-muted">inbox</Icon> Filer inbox</MenuItem> */}
                                {/* <MenuItem className="flex flex-middle"><svg viewBox="0 0 24 24" style={{ width: 24 }} className="mr-16" fill="rgba(0, 0, 0, 0.54)" title="LinkedIn" xmlns="http://www.w3.org/2000/svg"><path d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z"></path></svg> In Linkedin</MenuItem> */}
                                {
                                    this.state.crudPermission.isdelete &&
                                    <MenuItem onClick={this.handleDeleteOpportunity} className="flex flex-middle"><Icon className="mr-8 text-muted">delete</Icon>Delete</MenuItem>
                                }
                            </DropdownDefault>
                        </div>
                        <div className="username-photo white_space_nowrap">
                            <Avatar className={"avatar_circle color_" + colorClass + " custom-avatar-sidebar"}>{"$"}</Avatar>
                            <span className="username">
                                <h5 className="text_ellipsis" style={{ maxWidth: "175px" }}>
                                    {this.state.name}
                                </h5>
                                <IconButtonWhite className="filled_star crm-star-custom-icon" size="small" onClick={(lead, status) => this.handleFavorite(this.state.opportunities_id, this.state.isfavorite)}>
                                    <IconSmall>{this.state.star_icon}</IconSmall>
                                </IconButtonWhite>
                            </span>
                            <p className="user_designation">{this.state.companyName}</p>
                            {!this.state.title && !this.state.companyName && ""}
                        </div>
                        <div className="user__menu">
                            <div className="text-center">
                                <Fab
                                    size="small"
                                    color="primary"
                                    className="btn-primary"
                                    aria-label="Note"
                                    onClick={() => this.setState({ logtype: 5, logvaluetype: 'Note', shouldOpenCreateLogDialog: true })}
                                >
                                    <Icon className="primary_color">library_books</Icon>
                                </Fab>
                                <div className="text-gray">Note</div>
                            </div>
                            {/* <div className="text-center">
                                <Fab
                                    size="small"
                                    color="primary"
                                    className="btn-primary"
                                    aria-label="Email"
                                    onClick={() => this.isOpenedClickEmailToggle()}>

                                    <Icon className="primary_color">email</Icon>
                                </Fab>
                                <div className="text-gray">Email</div>
                            </div> */}
                            <div className="text-center">
                                <Fab
                                    size="small"
                                    color="primary"
                                    className="btn-primary"
                                    aria-label="Log"
                                    onClick={() => this.setState({ logtype: 0, logvaluetype: 'Phone Call', shouldOpenCreateLogDialog: true })}
                                >
                                    <Icon className="primary_color">add_circle</Icon>
                                </Fab>
                                <div className="text-gray">Log</div>
                            </div>
                            <div className="text-center">
                                <Fab
                                    size="small"
                                    color="primary"
                                    className="btn-primary"
                                    aria-label="Task"
                                    onClick={() => this.opeAddNewTaskPopup()}
                                >
                                    <Icon className="primary_color">storage</Icon>
                                </Fab>
                                <div className="text-gray">Task</div>
                            </div>
                            <div className="text-center">
                                <Fab
                                    size="small"
                                    color="primary"
                                    className="btn-primary"
                                    aria-label="Meet"
                                    onClick={() => this.setState({ logtype: 0, logvaluetype: 'Meeting', shouldOpenCreateLogDialog: true })}
                                >
                                    <Icon className="primary_color">calendar_today</Icon>
                                </Fab>
                                <div className="text-gray">Meet</div>
                            </div>
                        </div>
                        <hr style={{ height: "5px" }} className="bg-primary m-0" />
                        <Dialog
                            fullWidth={true}
                            maxWidth={'xs'}
                            open={this.state.isDeleteConfirmDialoOpen}
                            onClose={this.toggleDeleteDialog}
                            disableBackdropClick

                            aria-labelledby="ar-remove-dialog-title"
                        >
                            <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to remove this Opportunity ?"}</DialogTitle>
                            <DialogActions>
                                <Button onClick={this.toggleDeleteDialog} color="primary">
                                    Cancel
                                      </Button>
                                <Button type="Button" onClick={() => this.confirmDeleteCustomField()} className="btn_Primary">
                                    Ok
                                      </Button>

                            </DialogActions>
                        </Dialog>
                    </div>
                    {shouldOpenCreateLogDialog && (
                        <CreatelogDialog
                            handleClose={this.handleOpportunitiesDialogClose}
                            open={shouldOpenCreateLogDialog}
                            handleMinimize={this.handleOpportunitiesDialogMinimize}
                            handleFull={this.handleOpportunitiesDialogFull}
                            minimizeScreen={this.state.minimizeOpportunityDialog}
                            fullScreen={this.state.FullCreateOpportunityDialog}
                            handleSaveAddLog={this.handleSaveAddLog}
                            logvaluetype={this.state.logvaluetype}
                            logtype={this.state.logtype}
                        />
                    )}
                    {shouldOpenCreateTaskDialog && (
                        <CreateNewTaskDialog
                            handleClose={this.handleTaskDialogClose}
                            open={shouldOpenCreateTaskDialog}
                            handleMinimize={this.handleTaskDialogMinimize}
                            handleFull={this.handleTaskDialogFull}
                            minimizeScreen={minimizeTaskDialog}
                            fullScreen={FullCreateTaskDialog}
                            allstates={this.state}
                            dataFromSidebar={this.state.newTaskData}
                        />
                    )}
                    <div className="ml-16 mr-0">
                        <ProfileDetails opportunities_id={this.state.opportunities_id} />
                    </div>
                </aside>
        );
    }
}

const mapStateToProps = state => {
    return {
        opportunityReducer: state.opportunityReducer,
        settingReducer: state.settingReducer,
        apolloClient: state.apolloClient,
        authReducer: state.authReducer

    };
};
const mapDispatchToProps = dispatch => {
    return {
        deleteOpportunities: (client, request) => { dispatch(actionCreators.deleteOpportunities(client, request)) },
        addFavouriteOpportunities: (client, request) => { dispatch(actionCreators.addFavouriteOpportunities(client, request)) },
        saveOpportunitiesLog: (client, request) => dispatch(actionCreators.saveOpportunitiesLog(client, request)),
        customFieldList: (client, request) => { dispatch(actionCreators.customFieldList(client, request)) },
        saveLeadLog: (client, request) => dispatch(actionCreators.saveLeadLog(client, request)),
        deleteLeadLogDetail: (client, request) => dispatch(actionCreators.deleteLeadLogDetail(client, request)),
        updateLeadLog: (client, request) => dispatch(actionCreators.updateLeadLog(client, request)),
        getPipelines: (client) => dispatch(actionCreators.getPipelines(client)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ProfileLeft);
