import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Snackbar, Fade, CircularProgress, Icon, Card, IconButton, Dialog, DialogActions, InputLabel, DialogTitle, Fab, Avatar, Checkbox, Typography, FormControlLabel } from "@material-ui/core";
import { connect } from "react-redux";
import ConfirmationDialog from './ConfirmationDialog';
import CreateNewUser from './CreateNewUser'
import {
    Grid,

    Table,
    TableHeaderRow,
    Toolbar,
    VirtualTable,
    TableSelection,
    SearchPanel,
    PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import {
    SortingState,
    SearchState,
    IntegratedSorting,
    IntegratedFiltering,
    SelectionState,
    IntegratedSelection,
    PagingState,
    IntegratedPaging,
    TableColumnResizing,
} from '@devexpress/dx-react-grid';
import * as actionCreators from '../../../store/actions/index';
import FontAwesome from "react-fontawesome";
import { default as MaterialGrid } from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";


class userManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            UserListArr: [],
            openEditDialog: false,
            selectoptions: [],
            confirmationDialog: false,
            delete_id: '',
            shouldOpenCreateDialog: false,
            selection: [],
            isDataLoading: true,
            comp_name: "",
            isEdit: false,
            onlySystemAdminCreate: false
        }
    }
    componentDidMount() {
        let userdata = JSON.parse(localStorage.getItem('user_data_session'));
        var req = {
            companyid: userdata.companyId
        }
        this.props.getCompanyDetail(this.props.apolloClient.client, req)
        this.props.getUserList(this.props.apolloClient.client)
    }

    componentWillReceiveProps(props) {
        if (props.settingReducer.userCompanyDetails.data) {
            let dt = props.settingReducer.userCompanyDetails.data;
            this.setState({
                onlySystemAdminCreate: dt.allowsystemadmin ? dt.allowsystemadmin : false,
                comp_name: dt.displayname
            })
        }
        if (props.settingReducer.usersListArray.data !== undefined
            && this.state.UserListArr !== props.settingReducer.usersListArray.data) {
            this.setState({ UserListArr: props.settingReducer.usersListArray.data, isDataLoading: false })
        } else {
            setTimeout(() => {
                this.setState({ isDataLoading: false })
            }, 1500);
        }
    }
    handleDialogClose = () => {
        this.setState({
            openEditDialog: false, confirmationDialog: false
        })
    }
    handleDeleteUser = (userid) => {
        this.setState({
            delete_id: userid,
            confirmationDialog: true
        });
    };

    handleConfirmationResponse = () => {
        this.deleteUser();
        this.handleDialogClose();
        this.fetchSettingUserList();
    };

    fetchSettingUserList = () => {
        this.setState({
            isDataLoading: true
        });
        setTimeout(() => {
            this.props.getUserList(this.props.apolloClient.client)
        }, 1500);
    }

    deleteUser = () => {
        let send_data = ({ userid: [this.state.delete_id] })
        this.props.inviteUserDelete(send_data);
    };

    handleEditUser = (value) => {
        this.setState({ userDataEdit: value, shouldOpenCreateDialog: true })
    }
    setSelection = (val) => {
        this.setState({ selection: val });
    }

    handleLeadDialogClose = () => {
        this.setState({ shouldOpenCreateDialog: false });
    };
    multipleDelete = () => {
        let deleteArray = []
        this.state.selection.map(val => {
            deleteArray.push(this.state.UserListArr[val].id)
        }
        )
        if (deleteArray.length >= 1) {
            let send_data = ({ userid: deleteArray })
            this.props.inviteUserDelete(send_data);
        }
        deleteArray = [];
    }
    handleCancle = () => {
        this.setState({ comp_name: this.state.team_name, isEdit: false })
    }
    handleFormSubmit = () => {
        this.updateSettings()
        this.setState({
            isEdit: false
        })
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            isEdit: true
        })
    };
    handleCheckBoxChange = (event) => {
        this.setState({
            [event.target.name]: event.target.checked
        }, () => this.updateSettings())
    };
    updateSettings = () => {
        let req = {
            allowsystemadmin: this.state.onlySystemAdminCreate,
            companyname: this.state.comp_name
        }
        this.props.companyUpdate(this.props.apolloClient.client, req)
    }
    makeSystemAdmin = (event, userdata) => {
        this.setState({
            isDataLoading: true
        })
        let teamdata = userdata.teamlist
        let data = [];
        if (teamdata.length > 0) {
            teamdata.map((v, i) => {
                data.push({ teamid: v.team.id, ismanager: v.teammanager })
            })
        }
        const req = ({
            name: userdata.name,
            email: userdata.email,
            googlesync: userdata.googlesync,
            systemadmin: userdata.systemadmin === "0" ? "1" : "0",
            title: userdata.title,
            dataexport: userdata.dataexport,
            dropbox: userdata.dropbox,
            companyid: userdata.companyId,
            teamdata: data,
            deleteteam: [],
        });
        let edit_user_id = ({ userid: userdata.id });
        let newstate = Object.assign(req, edit_user_id);
        console.log(newstate)
        this.props.saveEditUser(newstate);
        this.fetchSettingUserList();

    };

    render() {
        let { shouldOpenCreateDialog, minimizeLeadDialog, FullCreateLeadDialog } = this.state;
        let { confirmationDialog } = this.state;
        let userdata = JSON.parse(localStorage.getItem('user_data_session'));
        const { value } = this.state;
        const columns = [
            {
                name: "name",
                title: "Name"

            },
            {
                name: "systemAdmin",
                title: "System Admin"
            },
            {
                name: "team",
                title: "Teams"
            },
            {
                name: "actions",
                title: " "
            }
        ];

        let rows = [];
        if (this.state.UserListArr) {
            this.state.UserListArr.map((val) => {
                rows.push(
                    {
                        name: (
                            <MaterialGrid container alignItems="center">
                                <MaterialGrid item>
                                    <Fab
                                        size="small"
                                        aria-label="Task"
                                        className={`wrap_icons_task_sidebar vert-middle bg-grey_sidebar`}
                                    >
                                        <Avatar className="bg-body">
                                            <Icon className="activity_icon_normal">person</Icon>
                                        </Avatar>
                                    </Fab>
                                </MaterialGrid>
                                <MaterialGrid item>
                                    <h5 className="d-inline-flex pl-10">
                                        <Link
                                            to={"/profile/company_user/" + val.id}
                                            className="company_user_name">{val.name}
                                        </Link>
                                    </h5>
                                    <p className="m-0 p-0 pl-10">{val.email}</p>
                                </MaterialGrid>
                            </MaterialGrid>
                        ),
                        systemAdmin: (
                            val.companyowner === "true" ?
                                <MaterialGrid
                                    container
                                    style={{ width: "fit-content" }}
                                    className="pr-20 pl-20 box_portlet"
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="center"
                                >
                                    <MaterialGrid className="pr-10" >
                                        <FontAwesome className={"fa fa-key"} />
                                    </MaterialGrid>
                                    <MaterialGrid>
                                        Account <br />Owner
                                </MaterialGrid>
                                </MaterialGrid>
                                :
                                <MaterialGrid
                                    container
                                    className="pr-20 pl-20 "
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="center"
                                >
                                    <Checkbox
                                        onChange={(e) => this.makeSystemAdmin(e, val)}
                                        color="primary"
                                        disabled={this.props.authReducer.userRole.id === 3 ? true : false}
                                        checked={val.systemadmin === "1" ? true : false}
                                    />
                                </MaterialGrid>
                        ),
                        team: (
                            val.teamlist.length > 0 ?
                                val.teamlist.map((data, i) => (
                                    data.team ?
                                        <>
                                            {i ? "," : " "}
                                            <Link key={i} to={"/settings/team-detail/" + data.team.id} className="text_link">{data.team.teamname}</Link>
                                        </>
                                        : "-"))
                                : "-"
                        ),
                        actions:
                            (
                                (this.props.authReducer.userRole.id !== 3) &&
                                (
                                    val.companyowner !== "true"
                                        ?
                                        <>
                                            <IconButton onClick={() => this.handleEditUser(val)} aria-label="delete">
                                                <Icon>edit</Icon>
                                            </IconButton>
                                            <IconButton onClick={() => this.handleDeleteUser(val.id)} aria-label="delete">
                                                <Icon>delete</Icon>
                                            </IconButton>
                                        </>
                                        : (val.companyowner === "true" && this.props.authReducer.userRole.id === 1) ?
                                            <IconButton onClick={() => this.handleEditUser(val)} aria-label="delete">
                                                <Icon>edit</Icon>
                                            </IconButton>
                                            :
                                            " "
                                )
                            )
                    })
            })
        }
        const toolbarTemplate = params => {
            return (
                <div className="m-10">

                </div>
            );
        };
        const columnWidths = [
            { columnName: "name", width: 300 },
            { columnName: "systemAdmin", width: 150 },
            { columnName: "team", width: 200 },
            { columnName: "actions", width: 140 },

        ]
        return (
            <div style={{ overflow: 'auto', height: '100%' }} className="v_scrollI">
                <ValidatorForm noValidate instantValidate={false} ref="form" onSubmit={this.handleFormSubmit}>
                    <Typography variant="h5" color="inherit" className="top_typograpy">
                        Company Settings</Typography>
                    <div style={{ marginBottom: '20px' }}>
                        <span>
                            Manage your company name and invite team members
                    </span>
                    </div>
                    {
                        (this.props.authReducer.userRole.id !== 3) &&
                        <Card className="m-10">
                            <div className="lead_upcoming">
                                <div className="box_portlet p-20">
                                    <MaterialGrid container spacing={2} item xs={4}>
                                        <InputLabel className="pt-10">Company Name</InputLabel>
                                        <TextValidator
                                            className="w-100 mb-16"
                                            style={{marginLeft:"10px"}}
                                            required={true}
                                            placeholder="Enter Company Name"
                                            type="text"
                                            name="comp_name"
                                            onChange={this.handleChange}
                                            value={this.state.comp_name}
                                            validators={["required"]}
                                            errorMessages={["This field is required"]}
                                            autoComplete='off'
                                        />
                                    </MaterialGrid>
                                    {this.state.isEdit &&
                                        <MaterialGrid container spacing={2} xs={4}>
                                            <DialogActions className="dialog-action">
                                                <Button variant="contained" onClick={this.handleCancle}>
                                                    Cancel</Button>
                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                    color="primary"
                                                    className="btn_Primary"
                                                >Save</Button>
                                            </DialogActions>
                                        </MaterialGrid>
                                    }
                                </div>
                            </div>
                        </Card>
                    }
                    <Card className="m-10">
                        <div className="lead_upcoming">
                            <div className="box_portlet p-20">
                                <FormControlLabel className="font-size-14"
                                    control={
                                        <Checkbox
                                            checked={this.state.onlySystemAdminCreate}
                                            name="onlySystemAdminCreate"
                                            disabled={this.props.authReducer.userRole.id === 3 ? true : false}
                                            onChange={this.handleCheckBoxChange}
                                            color="primary"
                                        />
                                    }
                                    label={<span className="font-size-14">
                                        Only allow System Admins to invite new users
                                        </span>}
                                />
                            </div>
                        </div>
                    </Card>
                    <div >
                        <MaterialGrid className="pb-4 pl-10 pr-10 pt-4"
                            container justify="space-between">
                            <span className="font-size-18 pt-4">Users({rows.length})</span>
                            {
                                (this.props.authReducer.userRole.id !== 3 ||
                                    (this.props.authReducer.userRole.id === 3 && this.state.onlySystemAdminCreate !== true)) ?
                                    < Button
                                        color="primary"
                                        onClick={() => this.setState({ shouldOpenCreateDialog: true, userDataEdit: null })}
                                        variant="contained"
                                        className="btn_Primary"
                                        size="small"
                                    >
                                        Invite New User
                                    </Button>
                                    :
                                    <Button
                                        variant="contained"
                                        disabled={true}
                                        size="small">
                                        Invite New User
                                    </Button>
                            }
                        </MaterialGrid>
                    </div>
                    <Card className="m-10">
                        <div className="v_scroll user_list_table_wrapper position-relative">
                            <div className="leads_list_table">
                                <Grid rows={rows} columns={columns}>
                                    <SelectionState
                                    // selection={this.state.selection}
                                    // onSelectionChange={this.setSelection}
                                    />
                                    <IntegratedSelection />
                                    <VirtualTable />
                                    <SearchState defaultValue="" />
                                    <IntegratedFiltering />
                                    {/* <IntegratedSorting /> */}
                                    <Table />
                                    {/* <TableSelection   /> */}
                                    <TableColumnResizing
                                        defaultColumnWidths={columnWidths}
                                    />
                                    <TableHeaderRow />
                                    <Toolbar rootComponent={toolbarTemplate} />
                                    <SearchPanel />
                                </Grid>
                            </div>
                        </div>

                        {confirmationDialog && (
                            <ConfirmationDialog
                                open={confirmationDialog}
                                onConfirmDialogClose={this.handleDialogClose}
                                onYesClick={this.handleConfirmationResponse}

                            />
                        )}
                        {
                            shouldOpenCreateDialog && (
                                <CreateNewUser
                                    handleClose={this.handleLeadDialogClose}
                                    open={shouldOpenCreateDialog}
                                    handleMinimize={this.handleLeadDialogMinimize}
                                    handleFull={this.handleLeadDialogFull}
                                    minimizeScreen={minimizeLeadDialog}
                                    fullScreen={FullCreateLeadDialog}
                                    userDataEdit={this.state.userDataEdit}
                                    fetchUserList={this.fetchSettingUserList}
                                />
                            )
                        }
                        {
                            this.state.isDataLoading &&
                            <Snackbar
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                open={this.state.isDataLoading}
                                TransitionComponent={Fade}
                                message={<><CircularProgress size={20} style={{ color: 'white' }} /><span style={{ paddingLeft: '6px', color: 'white' }}>{" Loading..."}</span></>}
                                variant={'success'}
                            />
                        }
                    </Card >
                </ValidatorForm>
            </div >
        );
    }
}

const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
    authReducer: state.authReducer

});


const mapDispatchToProps = dispatch => {
    return {
        saveEditUser: (data) => dispatch(actionCreators.saveEditUser(data)),

        getCompanyDetail: (client, req) => dispatch(actionCreators.getCompanyDetail(client, req)),
        companyUpdate: (client, req) => dispatch(actionCreators.companyUpdate(client, req)),
        getUserList: (data) => dispatch(actionCreators.getUserList(data)),
        getSettingUserList: (sendData) => dispatch(actionCreators.getSettingUserList(sendData)),
        inviteUserDelete: (delete_data) => dispatch(actionCreators.inviteUserDelete(delete_data))
    }
}

export default (connect(mapStateToProps, mapDispatchToProps)(userManagement));
