import React, { Component } from "react";
import { LinearProgress, Fab, Grid, withStyles, Switch, InputLabel, FormControl, Input, FormHelperText, RadioGroup, Radio, FormControlLabel, Divider, DialogContent, InputAdornment, Button, IconButton, Icon, MenuItem, Card, TextField, Dialog, DialogActions, DialogTitle, Drawer, Avatar } from "@material-ui/core";
import OpportunityProfileFrame from "../Opportunities/OpportunityProfileFrame";
import CreateNewOpportunityDialog from "../Opportunities/CreateNewOpportunityDialog";
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { NavLink, withRouter } from "react-router-dom";
import { SingleSelect } from "react-select-material-ui";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Link } from "react-router-dom";
import moment from 'moment';
import OpportunitiesStag from "./OpportunitiesStag";
const formatCreateLabel = (value) => `Create "${value}"`;
const isValidNewOption = (value) => value != "";
const IconSmall = withStyles(() => ({
  root: {
    fontSize: "1.5rem",
    color: 'rgba(0, 0, 0, 0.6)'
  }
}))(Icon);
const filter = createFilterOptions();

const LanesColorArray = ['primary', 'secondary', 'yellow', 'green', 'red', 'blue', 'black', 'orange', 'purple', 'gray']
class Project extends Component {
  state = {
    attachment: null,
    drawerOpenOpportunity: false,
    showAddOpportunity: false,
    opportunities_id: "",
    opportunityListArray: [],
    allOpportunityList: [],
    createNewOpportunityName: "",
    filterdata: [],
    removeOpportunityPopup: false,
    shouldOpenCreateOpportunityDialog: false,
    FullCreateOpportunityDialog: false,
    minimizeOpportunitytDialog: false,
    type: "opportunities",
    recordtype: "",
    newOpportunityData: {},
    companyCurrencyData: [],
    pipelineListArray: [],
    userSettings: "",
    compnayCrud: {
      isaccess: true,
      iscreate: true,
    },
    isDataLoadingData: true,
    isFlag: 0,
    selectedPipelineId: 1,
  };

  componentWillUnmount() {
    this.props.settingReducer.relatedDataOpportunity = [];
  }

  componentDidMount() {
    let userData = JSON.parse(localStorage.getItem('user_data_session'))
    let userprefparams = { userid: userData.id }
    let val1 = { typeId: 4 }
    this.props.getPipelines(this.props.apolloClient.client);
    this.props.getUserSetting(this.props.apolloClient.client, userprefparams);
    this.props.getOpportunitiesList(this.props.apolloClient.client);
    this.props.getUserIntegration(this.props.apolloClient.client);

    if (this.props.recordId) {
      this.setState({
        recordId: this.props.recordId,
        recordtype: this.props.recordtype,
      }, () => {
        this.forceUpdate()
        var req = {
          id: this.props.recordId,
          type: this.state.type,
          recordtype: this.props.recordtype,
        }
        this.props.getRecordRelatedData(this.props.apolloClient.client, req);
      });
    }

    let pipelineId = this.state.selectedPipelineId;
    if (localStorage.getItem('selected_pipeline_id')) {
      pipelineId = localStorage.getItem('selected_pipeline_id')
    } else {
      localStorage.setItem('selected_pipeline_id', this.state.selectedPipelineId)
      pipelineId = localStorage.getItem('selected_pipeline_id')
    }
    this.setState({ selectedPipelineId: pipelineId });
  }
  getUserPermission = () => {
    if (this.props.settingReducer.userPermissionDetails.data) {
      let data = this.props.settingReducer.userPermissionDetails.data.data;
      if (data.teampermissionstatus === true &&
        data.visibilityaccess.length > 0) {
        let compnayCrud = "";
        let selectedPipelineId = localStorage.getItem('selected_pipeline_id')
        if (this.props.authReducer.userRole.id === 3) {
          if (selectedPipelineId === "1") {
            compnayCrud = data.visibilityaccess.find(function (e) { return e.piplineid === 1 });
          } else if (selectedPipelineId === "2") {
            compnayCrud = data.visibilityaccess.find(function (e) { return e.piplineid === 2 });
          }
        }
        this.setState({
          compnayCrud: compnayCrud === "" ? this.state.compnayCrud : compnayCrud,
        })
      }
    }
  }
  componentWillReceiveProps(props) {
    this.getUserPermission()
    if (props.settingReducer.pipelineListArray.data) {
      var pipeData = props.settingReducer.pipelineListArray.data
      this.setState({
        pipelineListArray: pipeData,
      })
    }
    //storing user setting data in state
    if (props.settingReducer.userSettingArray.data && this.state.userSettings == "") {
      const settingData = props.settingReducer.userSettingArray.data;
      this.setState({
        userSettings: settingData,
      })
      let format = settingData.dateformat;
      let timezone = settingData.timezone;

      this.setState({ dateformat: format, timeZone: timezone })
    }
    if (props.settingReducer.companyCurrencyData.data != null) {
      var cData = props.settingReducer.companyCurrencyData.data;
      this.setState({ companyCurrencyData: cData[0] });
    }
    if (props.recordId != this.state.recordId) {//if(props.recordId !== undefined && this.state.oppoFlag){
      this.setState({
        recordId: props.recordId,
        recordtype: props.recordtype,
      }, () => {
        this.forceUpdate()
        //this.props.getOpportunitiesCompanyList(this.props.apolloClient.client, this.state.recordId);
        var req = {
          id: props.recordId,
          type: this.state.type,
          recordtype: props.recordtype,
        }
        this.props.getRecordRelatedData(this.props.apolloClient.client, req);
      });
    }
    if (props.opportunityReducer.opportunityFieldData.data && props.opportunityReducer.opportunityFieldData.is_loaded) {
      this.setState({
        isDataLoading: false,
      }, () => this.forceUpdate())
    }


    if (props.settingReducer.relatedDataOpportunity.data && props.settingReducer.relatedDataOpportunity.data.data) {
      props.settingReducer.relatedDataOpportunity.is_loaded = false
      this.setState({
        opportunityListArray: props.settingReducer.relatedDataOpportunity.data.data,
        isOpenedContact: true
      }, () => this.forceUpdate());
      if (props.settingReducer.relatedDataOpportunity.data.data.length > 0) {
        this.setState({ isOpenedOpportunity: true });
      } else {
        this.setState({ isOpenedCompany: false });
      }
    } else {
      this.setState({
        opportunityListArray: [],
        isOpenedContact: false
      }, () => this.forceUpdate());
    }

    if (props.settingReducer.opportunityRecList && props.settingReducer.opportunityRecList.data && props.settingReducer.opportunityRecList.data.data) {
      let opportunityOptions = [];
      props.settingReducer.opportunityRecList.data.data.map((opt) => {
        opportunityOptions.push({ label: opt.name, value: opt.opportunities_id });
      })
      this.setState({ allOpportunityList: opportunityOptions });
    }
  }

  toggleProject = () => {
    this.setState(oldStateProject => ({ isOpenedOpportunity: !oldStateProject.isOpenedOpportunity }));
    if (this.state.isOpenedOpportunity) {
      this.setState({
        showAddOpportunity: false
      }, () => this.forceUpdate());
    }
  }

  /* START cmp dialoge */

  handleProjectDialogClose = () => {
    this.setState({ shouldOpenCreateOpportunityDialog: false, FullCreateOpportunityDialog: false, minimizeOpportunitytDialog: false });
  };

  handleOpportunityDialogMinimize = () => {
    this.setState(oldStateminimizeOpportunitytDialog => ({ minimizeOpportunitytDialog: !oldStateminimizeOpportunitytDialog.minimizeOpportunitytDialog }));
    this.setState({ FullCreateOpportunityDialog: false })
  };

  handleOpportunityDialogFull = () => {
    this.setState(oldStateFullCreateOpportunityDialog => ({ FullCreateOpportunityDialog: !oldStateFullCreateOpportunityDialog.FullCreateOpportunityDialog }));
    this.setState({ minimizeOpportunitytDialog: false })

  };

  /* END cmp dialoge */

  closeDrawerRight = (e) => {
    this.setState({ isDataLoading: true, isDataLoading2: true });

    setTimeout(() => {
      this.setState({
        drawerOpenOpportunity: false,
        isDataLoading: false,
        isFlag: 1
      }, () => {
        this.forceUpdate()
      })
    }, 2500);

    let recordtype = this.state.FlagType;
    let FlagId = this.state.FlagId;


    let calendarevents = {
      id: parseFloat(FlagId),
      type: 'calendarevents',
      recordtype: recordtype,
    }
    this.props.getRecordRelatedData(this.props.apolloClient.client, calendarevents);
    // if (recordtype !== "project") {
    let projects = {
      id: parseFloat(FlagId),
      type: 'projects',
      recordtype: recordtype,
    }
    this.props.getRecordRelatedData(this.props.apolloClient.client, projects);
    // }
    let tasks = {
      id: parseFloat(FlagId),
      type: 'tasks',
      recordtype: recordtype,
    }
    this.props.getRecordRelatedData(this.props.apolloClient.client, tasks);
    let files = {
      id: parseFloat(FlagId),
      type: 'files',
      recordtype: recordtype,
    }
    this.props.getRecordRelatedData(this.props.apolloClient.client, files);
    // if (recordtype !== "contact") {
    // if (recordtype !== "company") {
    let companies = {
      id: parseFloat(FlagId),
      type: 'companies',
      recordtype: recordtype,
    }
    this.props.getRecordRelatedData(this.props.apolloClient.client, companies);
    // }
    let contact = {
      id: parseFloat(FlagId),
      type: 'contact',
      recordtype: recordtype,
    }
    this.props.getRecordRelatedData(this.props.apolloClient.client, contact);
    let opportunities = {
      id: parseFloat(FlagId),
      type: 'opportunities',
      recordtype: recordtype,
    }
    this.props.getRecordRelatedData(this.props.apolloClient.client, opportunities);
    // }


  };
  toggleAddOpportunity = () => {
    this.setState({
      isOpenedOpportunity: true,
      showAddOpportunity: !this.state.showAddOpportunity
    });
  };

  handleDrawerOpportunityRight = (opportunities_id) => {
    this.props.getPipelines(this.props.apolloClient.client);
    if (this.state.opportunities_id && (this.state.opportunities_id === opportunities_id)) { } else {
      this.setState({
        FlagId: this.state.recordId,
        FlagType: this.state.recordtype,
        opportunities_id: opportunities_id,
        isDataLoading: true,
      }, () => {
        this.forceUpdate()
      })
    }

    setTimeout(() => {
      this.setState({
        drawerOpenOpportunity: true,
      }, () => {
        this.forceUpdate()
      })
    }, 3000);

    if (this.state.isFlag > 0) {
      let calendarevents = {
        id: parseFloat(opportunities_id),
        type: 'calendarevents',
        recordtype: this.state.type,
      }
      this.props.getRecordRelatedData(this.props.apolloClient.client, calendarevents);
      let req = {
        id: parseFloat(opportunities_id),
        type: 'contact',
        recordtype: this.state.type,
      }
      this.props.getRecordRelatedData(this.props.apolloClient.client, req);
      let tasks = {
        id: parseFloat(opportunities_id),
        type: 'tasks',
        recordtype: this.state.type,
      }
      this.props.getRecordRelatedData(this.props.apolloClient.client, tasks);
      let files = {
        id: parseFloat(opportunities_id),
        type: 'files',
        recordtype: this.state.type,
      }
      this.props.getRecordRelatedData(this.props.apolloClient.client, files);
      let companies = {
        id: parseFloat(opportunities_id),
        type: 'companies',
        recordtype: this.state.type,
      }
      this.props.getRecordRelatedData(this.props.apolloClient.client, companies);
      let projects = {
        id: parseFloat(opportunities_id),
        type: 'projects',
        recordtype: this.state.type,
      }
      this.props.getRecordRelatedData(this.props.apolloClient.client, projects);
      let opportunities = {
        id: parseFloat(opportunities_id),
        type: 'opportunities',
        recordtype: this.state.type,
      }
      this.props.getRecordRelatedData(this.props.apolloClient.client, opportunities);
    }

  };

  handleOpportunityLaunch = (opportunities_id) => {
    this.props.history.push('/opportunities/opportunities/' + opportunities_id)
  };

  handleRemoveOpportunity = (opportunities_id) => {
    this.setState({
      removeOpportunityPopup: true,
      deleteOpportunityId: opportunities_id,
    }, () => {
      this.forceUpdate()
    })

  };
  toggleRemoveOpportunityPopup = () => {
    if (this.state.removeOpportunityPopup) {
      this.setState({
        deleteOpportunityId: "",
      }, () => {
        this.forceUpdate()
      })

    }
    this.setState({
      removeOpportunityPopup: !this.state.removeOpportunityPopup,
    }, () => {
      this.forceUpdate()
    })

  };
  confirmremoveOpportunityPopup = () => {
    console.log("this.state.deleteOpportunityId", this.state.deleteOpportunityId);
    if (this.state.deleteOpportunityId) {
      if (this.state.recordtype == "project") {
        var req = {
          opportunitiesid: parseInt(this.state.deleteOpportunityId),
          projectid: parseInt(this.state.recordId),
        }
        this.props.deleteProjectOpportunities(this.props.apolloClient.client, req)
      }
      if (this.state.recordtype == "contact") {
        var req = {
          contactid: parseInt(this.state.recordId),
          opportunitiesid: parseInt(this.state.deleteOpportunityId),
        }
        this.props.deleteOpportunitiesContact(this.props.apolloClient.client, req)
      }
      if (this.state.recordtype == "company") {
        var req = {
          companyid: parseInt(this.state.recordId),
          opportunitiesid: parseInt(this.state.deleteOpportunityId),
        }
        this.props.deleteOpportunitiesCompany(this.props.apolloClient.client, req)
      }

      this.setState({
        deleteOpportunityId: "",
        removeOpportunityPopup: false,
      }, () => {
        this.forceUpdate()
      })
    }

  };

  handleOpportunityChange = (val, newValue) => {
    console.log("this.state.recordtype", this.state.recordtype);

    if (newValue) {
      var relateddata = {
        recordtype: this.state.recordtype,
        type: this.state.type,
        id: parseInt(this.state.recordId),
      }

      let id = (newValue.__isNew__) ? "0" : newValue.value;

      if (id == "0") {
        this.setState({
          newOpportunityData: relateddata,
          shouldOpenCreateOpportunityDialog: true,
          createNewOpportunityName: newValue.label
        })
      } else {
        var isAlreadyAdded = this.state.opportunityListArray && this.state.opportunityListArray.filter(function (element) {
          if (element.opportunities.id == id) {
            return element
          }
        });
        if (isAlreadyAdded.length > 0) {
          this.setState({ showAddOpportunity: false });
          return
        }
        let data = { srno: "1", id: id, value: newValue.label };
        if (this.state.recordtype == "project") {
          var req = {
            opportunitiesdetail: data,
            projectid: parseInt(this.state.recordId),
          }
          this.props.addProjectOpportunities(this.props.apolloClient.client, req)
        }
        if (this.state.recordtype == "contact") {
          var req = {
            contactdetail: { id: parseInt(this.state.recordId) },
            opportunitiesid: data.id,

          }
          this.props.addNewOpportunitiesContact(this.props.apolloClient.client, req)
        }
        if (this.state.recordtype == "company") {
          var req = {
            companydetail: { id: parseInt(this.state.recordId) },
            opportunitiesid: parseInt(id),
          }
          this.props.addNewOpportunitiesCompany(this.props.apolloClient.client, req)
        }
      }
      this.setState({ showAddOpportunity: false });

    }
  };

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] == value) {
        return i;
      }
    }
    return -1;
  }
  render() {
    let { drawerOpenOpportunity, isOpenedOpportunity } = this.state;

    /*STRAT opportunity Display*/
    let displayOpportunityList = []
    {
      this.state.opportunityListArray && this.state.opportunityListArray.map((item, key) => {
        if (item.opportunities.__opportunitiesdetail__.length > 0) {
          var cName = ""
          var pipelineName = ""
          var stageName = ""
          var statusName = ""
          var valueField = ""
          var closeDate = ""
          var priority = ""
          var stageOptions = []
          var oldStageId = ""
          var pipelineId = ""
          var progressColor = "primary"
          var progressValue = 0
          var getRelatedReq = {}
          item.opportunities.__opportunitiesdetail__.map((data, key1) => {
            getRelatedReq = {
              id: parseInt(this.state.recordId),
              type: this.state.type,
              recordtype: this.state.recordtype,
            }
            if (data.__field__.id == "40") {//name
              cName = data.fieldvalue;
            }
            if (data.__field__.id == "41") {//pipeline
              if (data.values.length > 0) {
                pipelineName = data.values[0].value;
                pipelineId = parseInt(data.values[0].id);
                var pipeKey = this.findWithAttr(this.state.pipelineListArray, 'id', pipelineId)
                var stateField = item.opportunities.__opportunitiesdetail__.filter(function (element) {
                  if (element.__field__.id == "47") {
                    return element
                  }
                });
                if (pipeKey != -1) {
                  if (stateField.length > 0 && stateField[0].values.length > 0) {
                    var stageKey = this.findWithAttr(this.state.pipelineListArray[pipeKey].stage, 'id', stateField[0].values[0].id)
                    progressColor = LanesColorArray[stageKey]
                    progressValue = this.state.pipelineListArray[pipeKey].stage[stageKey].probability
                  }

                  stageOptions = this.state.pipelineListArray[pipeKey].stage
                }
              }
            }
            if (data.__field__.id == "45") {//status
              if (data.values.length > 0) {
                statusName = data.values[0].value;
              }

            }
            if (data.__field__.id == "50") {//value
              if (data.values.length > 0) {
                valueField = this.state.companyCurrencyData.symbol + data.values[0].value;
              }

            }
            if (data.__field__.id == "47") {//stage
              if (data.values.length > 0) {
                stageName = data.values[0].value;
                oldStageId = data.values[0].id;
              }
            }
            if (data.__field__.id == "43") {//close date
              if (data.fieldvalue) {
                var dt = moment(data.fieldvalue).format(this.state.userSettings.dateformat)
                closeDate = dt;
              }
            }
            if (data.__field__.id == "51") {//priority
              if (data.values.length > 0) {
                priority = data.values[0].value;
              }
            }


          })
          displayOpportunityList.push(
            <div className="box_portlet mt-10  crm-calendar-event" style={{ 'border': '1px solid #ebedf2' }}>
              <div className="box_header">
                <div className="box_label"><h5 className="box_title">{statusName}</h5></div>
              </div>
              <div className="box_body  box_action">
                <Grid container className="p-4">
                  <Grid item>
                    <Icon className="font-size-36 ml--4" color="primary">monetization_on</Icon>
                  </Grid>
                  <Grid item className="flex-auto pl-10">
                    <Grid container justify="space-between" alignItems="center">
                      <Grid item>
                        <div onClick={() => this.handleDrawerOpportunityRight(item.opportunities.id)}
                          className={"cursor_poiter font-size-13 text_ellipsis opp_name"}
                        >
                          <span className="font-weight-500">{cName}</span>({pipelineName})</div>
                      </Grid>
                      <Grid item>
                        {valueField && <div className="font-size-13"> <span className="font-weight-500">Value</span> {valueField}</div>}
                      </Grid>
                    </Grid>
                    <LinearProgress variant="determinate" className={`opportunity-progress` + ' ' + progressColor} value={progressValue} />
                  </Grid>
                </Grid>
                <Grid container className="pl-4" >
                  <div className="font-size-13">
                    <span className="font-weight-500">Stage</span> <OpportunitiesStag getRelatedReq={getRelatedReq} stageOptions={stageOptions} opportunitiesid={item.opportunities.id} oldStageId={oldStageId} />
                  </div>
                </Grid>
                <Grid container className="pl-4">
                  <div className="font-size-13">
                    <span className="font-weight-500">Priority</span> {priority}
                  </div>
                </Grid>
                <Grid container className="pl-4" >
                  <div className="font-size-13">
                    <span className="font-weight-500">Close date</span> {closeDate}
                  </div>
                </Grid>
                <div className="hover_show" style={{ top: '75px', right: '3px' }}>
                  <IconButton aria-label="Launch" size="small" onClick={() => this.handleOpportunityLaunch(item.opportunities.id)}>
                    <IconSmall style={{ fontSize: '18px' }}>launch</IconSmall>
                  </IconButton>
                  <IconButton aria-label="Delete" size="small" onClick={() => this.handleRemoveOpportunity(item.opportunities.id)}>
                    <IconSmall style={{ fontSize: '18px' }}>close</IconSmall>
                  </IconButton>
                </div>
              </div>
            </div>
          )
        }

      })
    }
    /*END opportunities Display*/

    return (
      <Card elevation={3} className="p-16 mt-16" style={{ overflow: 'initial' }}>
        <Grid container justify="space-between">
          <h5 className="flex-auto cursor_poiter mb-0 pt-4" onClick={this.toggleProject}>Opportunities ({displayOpportunityList.length}) <Icon className="vert-middle">{isOpenedOpportunity ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon></h5>
          <IconButton aria-label="Add" size="small">
            <IconSmall onClick={() => this.toggleAddOpportunity()} className={this.state.showAddOpportunity ? "plusIconTransform" : ""}>add</IconSmall>
          </IconButton>
        </Grid>
        {isOpenedOpportunity &&
          <>
            {this.state.showAddOpportunity &&
              <FormControl className="w-100 input_bx" style={{ zIndex: 10000 }}>
                <SingleSelect
                  className="add_singleselection"
                  SelectProps={{
                    isCreatable: this.state.compnayCrud.isaccess && this.state.compnayCrud.iscreate ? true : false,
                    formatCreateLabel,
                    isValidNewOption,
                  }}
                  placeholder="Add Opportunity"
                  options={this.state.compnayCrud.isaccess && this.state.allOpportunityList}
                  onChange={this.handleOpportunityChange}
                />
              </FormControl>
            }
            {(!this.state.showAddOpportunity && displayOpportunityList.length == 0) &&
              <label className="text-hint">
                <IconButton className="mr-8" component="span">
                  <Icon onClick={() => this.toggleAddOpportunity()} >attach_money</Icon>
                </IconButton>
                                Opportunities
                            </label>
            }
            {displayOpportunityList}

          </>
        }

        {this.state.shouldOpenCreateOpportunityDialog && (
          <CreateNewOpportunityDialog
            handleClose={this.handleProjectDialogClose}
            open={this.state.shouldOpenCreateOpportunityDialog}
            handleMinimize={this.handleOpportunityDialogMinimize}
            handleFull={this.handleOpportunityDialogFull}
            minimizeScreen={this.state.minimizeOpportunitytDialog}
            fullScreen={this.state.FullCreateOpportunityDialog}
            name={this.state.createNewOpportunityName}
            contactsid={this.state.recordtype == 'contact' ? this.state.recordId : ''}
            companysid={this.state.recordtype == 'company' ? this.state.recordId : ''}
            projectid={this.state.recordtype == 'project' ? this.state.recordId : ''}
            allstates={this.state}
            dataFromSidebar={this.state.newOpportunityData}
          />
        )}

        <Dialog
          fullWidth={true}
          maxWidth={'xs'}
          open={this.state.removeOpportunityPopup}
          onClose={this.toggleRemoveOpportunityPopup}
          disableBackdropClick
          aria-labelledby="ar-remove-dialog-title"
        >
          <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to remove the Associated Opportunity?"}</DialogTitle>
          <DialogActions>
            <Button onClick={this.toggleRemoveOpportunityPopup} color="primary">
              Cancel
            </Button>
            <Button type="Button" onClick={() => this.confirmremoveOpportunityPopup()} className="btn_Primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Drawer
          width={"100px"}
          variant="temporary"
          anchor={"right"}
          className="drawer_right"
          open={drawerOpenOpportunity}
          onClose={this.closeDrawerRight}
          ModalProps={{
            keepMounted: true
          }}
        >
          {this.state.opportunities_id && <OpportunityProfileFrame opportunities_id={parseInt(this.state.opportunities_id)} handleClose={this.closeDrawerRight} />}
        </Drawer>
        {this.state.isDataLoading &&
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={this.state.isDataLoading}
            TransitionComponent={Fade}
            message={<><CircularProgress size={20} style={{ color: 'white' }} /><span style={{ paddingLeft: '6px', color: 'white' }}>{" Loading..."}</span></>}
            variant={'success'}
          />
        }
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
  opportunityReducer: state.opportunityReducer,
  authReducer: state.authReducer

});

const mapDispatchToProps = dispatch => {
  return {
    getPipelines: (client) => dispatch(actionCreators.getPipelines(client)),
    getUserSetting: (client, data) => dispatch(actionCreators.getUserSetting(client, data)),
    getOpportunitiesList: (client) => { dispatch(actionCreators.getOpportunitiesList(client)) },
    addProjectOpportunities: (client, req) => { dispatch(actionCreators.addProjectOpportunities(client, req)) },
    deleteProjectOpportunities: (client, req) => { dispatch(actionCreators.deleteProjectOpportunities(client, req)) },
    getRecordRelatedData: (client, req) => { dispatch(actionCreators.getRecordRelatedData(client, req)) },
    addNewOpportunitiesContact: (client, req) => { dispatch(actionCreators.addNewOpportunitiesContact(client, req)) },
    deleteOpportunitiesContact: (client, req) => { dispatch(actionCreators.deleteOpportunitiesContact(client, req)) },
    addNewOpportunitiesCompany: (client, req) => { dispatch(actionCreators.addNewOpportunitiesCompany(client, req)) },
    deleteOpportunitiesCompany: (client, req) => { dispatch(actionCreators.deleteOpportunitiesCompany(client, req)) },
    getUserIntegration: (client) => dispatch(actionCreators.getUserIntegration(client)),
  }
}


export default (connect(mapStateToProps, mapDispatchToProps)(withRouter(Project)));
