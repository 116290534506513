import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { NavLink, withRouter } from "react-router-dom";
import {Fab, IconButton, Grid, Card, Icon, withStyles, Tooltip } from "@material-ui/core";
import moment from 'moment';
import { Link } from "react-router-dom";

const IconSmall = withStyles(() => ({
  root: {
    fontSize: "1.5rem",
    color: "rgba(0, 0, 0, 0.6)",
  },
}))(Icon);

const TooltipNew = withStyles((theme) => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);

class Calendar extends Component {
  state = {
    attachment: null,
    isOpenedCalendar: false,
    currentData:[],
    recordId:'',
    recordtype:'',
    itemName:'',
    itemEmails: '',
    link:'',
    type:'calendarevents',
    eventsList :[],
    dateformat: 'MM/DD/YYYY',
    timeZone: 'America/New_York',
  };
  componentDidMount() {
    
    if (this.props.recordId) {
      this.setState({
        recordId: this.props.recordId,
        recordtype: this.props.recordtype,
      }, () => {
        this.forceUpdate()
        //this.props.getOpportunitiesCompanyList(this.props.apolloClient.client, this.state.recordId);
        var req = {
          id: this.props.recordId,
          type: this.state.type,
          recordtype: this.props.recordtype,
        }
        //console.log("req", req);
        this.props.getRecordRelatedData(this.props.apolloClient.client, req);
      });
    }
  }
  componentWillReceiveProps(props){
    if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
      let usersetting = props.settingReducer.userSettingArray.data;

      let format = usersetting.dateformat;
      let timezone = usersetting.timezone;
      this.setState({ dateformat: format, timeZone: timezone })
    }

    if (props.settingReducer.relatedDataCalandar.data && props.settingReducer.relatedDataCalandar.data.data){
      let calendarEvent = props.settingReducer.relatedDataCalandar.data.data;
      let lengthOfcalendarEvent = props.settingReducer.relatedDataCalandar.data.data.length;
      
      let events = [];
      calendarEvent.map((event)=>{
        let startDate = '';
        let endDate = '';
        let emails = [];
        let att_email = [];
        
        if(event.start.date) {
          startDate = new Date(event.start.date);
          endDate = new Date(event.end.date);
          startDate = moment(startDate).tz(this.state.timeZone).format(this.state.dateformat);
          endDate = moment(endDate).tz(this.state.timeZone).format(this.state.dateformat);
        }else{
          if (moment(event.start.dateTime).tz(this.state.timeZone).format('D') == moment(event.end.dateTime).tz(this.state.timeZone).format('D')){
            startDate = moment(event.start.dateTime).tz(this.state.timeZone).format(this.state.dateformat) + " | " + moment(event.start.dateTime).tz(this.state.timeZone).format("hh:mm a");
            endDate = moment(event.end.dateTime).tz(this.state.timeZone).format("hh:mm a");
          }
          else{
            startDate = moment(event.start.dateTime).tz(this.state.timeZone).format(this.state.dateformat) + " | " + moment(event.start.dateTime).tz(this.state.timeZone).format("hh:mm a");
            endDate = moment(event.end.dateTime).tz(this.state.timeZone).format(this.state.dateformat) + " | " + moment(event.end.dateTime).tz(this.state.timeZone).format("hh:mm a");
          }
        }
        if (event.attendees && event.attendees.length > 0) {
          event.attendees.map((att, key)=>{
            let localUser = JSON.parse(localStorage.getItem('user_data_session'));
            
            if (att.email == localUser.email){
              emails.push(
                <TooltipNew title={att.email}>
                  <div className="text_ellipsis" style={{ maxWidth: '100%' }}>
                    <label className="cursor_poiter" style={{ fontSize: '12px' }}>
                      You
                    </label>
                  </div>
                </TooltipNew>
              );
              if (event.attendees.length > key+1) {
                emails.push(<>, </>);
              }
            } 
            if (att.lead){
              emails.push(
                <TooltipNew title={att.lead.name}>
                  <div className="text_ellipsis" style={{ maxWidth: '100%' }}>
                    <label className="cursor_poiter" style={{ fontSize: '12px' }}>
                      <Link className="crm-calendar-event-links" to={'/lead/lead/' + att.lead.id} className="link">{att.lead.name}</Link>
                    </label>
                  </div>
                </TooltipNew>)
                if (event.attendees.length > key+1) {
                  emails.push(<>, </>);
                }
            } 
            if (att.contact){
              emails.push(
                <TooltipNew title={att.contact.name}>
                  <div className="text_ellipsis" style={{ maxWidth: '100%' }}>
                    <label className="cursor_poiter" style={{ fontSize: '12px' }}>
                      <Link className="crm-calendar-event-links" to={'/contact/contact/' + att.contact.id} className="link">{att.contact.name}</Link>
                    </label>
                  </div>
                </TooltipNew>)
                if (event.attendees.length > key+1){
                  emails.push(<>, </>);
                }
            }
          });
        }
        
        events.push({
          title: event.summary,
          startDate: startDate,
          endDate: endDate,
          allemail: emails,
          htmlLink: event.htmlLink,
          interval:''
        });
      });

      this.setState({ eventsList: events })

      if (lengthOfcalendarEvent > 0) {
        this.setState({ isOpenedCalendar: true });
      }else{
        this.setState({ isOpenedCalendar: false });
      }
    } else {
      this.setState({
        eventsList: [],
        isOpenedCalendar: false
      }, () => this.forceUpdate());
    }
    
    if (props.recordId != this.state.recordId) {
      this.setState({
        recordId: parseInt(props.recordId),
        recordtype: props.recordtype,
      }, () => {
        this.forceUpdate()
      });
    }
    let link = "https://calendar.google.com/calendar/r/eventedit?text=Meeting with ";
    if (props.recordtype == 'lead') {
      if (props.leadReducer.leadFieldData.data) {
        let itemkey = this.findWithAttr(props.leadReducer.leadFieldData.data, 'fieldkey', 'name');
        let itemData = props.leadReducer.leadFieldData.data[itemkey];
        this.setState({ itemName: itemData.leaddetail[0].fieldvalue })
        if (itemData.leaddetail) {
          link = link + itemData.leaddetail[0].fieldvalue;
        }
        let emailkey = this.findWithAttr(props.leadReducer.leadFieldData.data, 'fieldkey', 'email');
        let emailData = props.leadReducer.leadFieldData.data[emailkey];

        if (emailData.leaddetail && emailData.leaddetail.length > 0 && emailData.leaddetail[0].values.length > 0) {
          this.setState({ itemEmails: emailData.leaddetail[0].values[0].value })
          link = link + "&add=" + emailData.leaddetail[0].values[0].value;
        }
      }
    }

    if (props.recordtype == 'contact') {
      if (props.contactReducer.contactFieldData.data) {
        let itemkey = this.findWithAttr(props.contactReducer.contactFieldData.data, 'fieldkey', 'name');
        let itemData = props.contactReducer.contactFieldData.data[itemkey];
        this.setState({ itemName: itemData.contactdetail[0].fieldvalue })
        if (itemData.contactdetail) {
          link = link + itemData.contactdetail[0].fieldvalue;
        }
        let emailkey = this.findWithAttr(props.contactReducer.contactFieldData.data, 'fieldkey', 'email');
        let emailData = props.contactReducer.contactFieldData.data[emailkey];
        if (emailData.contactdetail && emailData.contactdetail.length > 0  && emailData.contactdetail[0].values.length > 0) {
          this.setState({ itemEmails: emailData.contactdetail[0].values[0].value })
          link = link + "&add=" + emailData.contactdetail[0].values[0].value;
        }
      }
    }

    if (props.recordtype == 'company') {
      let emails = [];
      if (props.companyReducer.companyFieldData.data) {
        let itemkey = this.findWithAttr(props.companyReducer.companyFieldData.data, 'fieldkey', 'name');
        let itemData = props.companyReducer.companyFieldData.data[itemkey];
        this.setState({ itemName: itemData.companydetail[0].fieldvalue })
        if (itemData.companydetail) {
          link = link + itemData.companydetail[0].fieldvalue;
        }
      }
      
      if (props.settingReducer.relatedDataContact.data && props.settingReducer.relatedDataContact.data.data) {
        let relatedContact = props.settingReducer.relatedDataContact.data.data;
        relatedContact.map((contact)=>{
          contact.contact.__contactdetail__.map((data, key1)=>{
            if (data.__field__.id == "21" && data.values.length > 0) {//email
              let email = data.values[0].value;
              if(emails.indexOf(email) == -1)
                emails.push(email);
            }
          })
        });
        let email_string = emails.toString();
        link = link + "&add=" + email_string;
      }
    }

    if (props.recordtype == 'opportunities') {
      let emails = [];
      if (props.opportunityReducer.opportunityFieldData.data){
        let oppData = props.opportunityReducer.opportunityFieldData.data;
        let itemkey = this.findWithAttr(oppData, 'fieldkey', 'name');
        let itemData = props.opportunityReducer.opportunityFieldData.data[itemkey];
        this.setState({ itemName: itemData.opportunitiesdetail[0].fieldvalue })
        if (itemData.opportunitiesdetail) {
          link = link + itemData.opportunitiesdetail[0].fieldvalue;
        }
      }
      
      if (props.settingReducer.relatedDataContact.data && props.settingReducer.relatedDataContact.data.data) {
        let relatedContact = props.settingReducer.relatedDataContact.data.data;
        relatedContact.map((contact) => {
          contact.contact.__contactdetail__.map((data, key1) => {
            if (data.__field__.id == "21" && data.values.length > 0) {//email
              let email = data.values[0].value;
              if (emails.indexOf(email) == -1)
                emails.push(email);
            }
          })
        });

        let email_string = emails.toString();
        link = link + "&add=" + email_string;
      }
    }
    this.setState({link});
  }

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] == value) {
        return i;
      }
    }
    return -1;
  }

  handleAttachmentSelection = (event) => {
    this.setState({
      attachment: event.target.files[0],
    });
  };

  toggleCalendar = () => {
    this.setState((oldStateCalendar) => ({
      isOpenedCalendar: !oldStateCalendar.isOpenedCalendar,
    }));
  };

  renderNewEvents = () => {
    let that = this;
    const interval = setInterval(function () {
      var req = {
        id: that.props.recordId,
        type: that.state.type,
        recordtype: that.props.recordtype,
      }
      that.props.getRecordRelatedData(that.props.apolloClient.client, req);
    }, 5000);

    this.setState({ interval });
  }

  addNewCalendarEvent= () => {
    let link = this.state.link;
    window.open(link, '_blank');
    var req = {
      id: this.props.recordId,
      type: this.state.type,
      recordtype: this.props.recordtype,
    }
    this.renderNewEvents()
  }
  openGoogleEvent = (link) => {
    window.open(link, '_blank');
  }

  componentWillUnmount(){
    clearInterval(this.state.interval); 
  }

  render() {
    let { isOpenedCalendar, eventsList } = this.state;
    let emails = [];
    return (
      <Card  elevation={3} className="p-16 mt-16">
        <Grid container justify="space-between">
          <h5
            className="flex-auto cursor_poiter mb-0 pt-4"
            onClick={this.toggleCalendar}
          >
            Calendar Meeting ({eventsList.length})
            <Icon className="vert-middle">
              {isOpenedCalendar ? "keyboard_arrow_up" : "keyboard_arrow_down"}
            </Icon>
          </h5>
          <IconButton onClick={this.addNewCalendarEvent} aria-label="Add" size="small">
            <IconSmall>add</IconSmall>
          </IconButton>
        </Grid>
        {isOpenedCalendar && (
          (eventsList.length > 0) ? 
            eventsList.map((event)=>(
              <div className="box_portlet mt-10 cursor_poiter" >
                <div className="box_body p-10 box_action crm-calendar-event">
                  {/* <a onClick={() => this.handleDrawerContactRight(item.contact.id)} className={"cursor_poiter"}> */}
                    <Grid container style={{ marginTop: '7px' }}>
                      <Grid item md={2} onClick={(link)=>this.openGoogleEvent(event.htmlLink)}>
                        <div className="icon_left">
                            <Fab
                                  size="small"
                                  aria-label="Task"
                                  className={`wrap_icons_task_sidebar vert-middle bg-grey_sidebar`}
                              >
                                <Icon className="activity_icon_normal" style={{display:'inherit',fontSize:'20px',color:'#7467ef'}}>attachment</Icon>
                              </Fab>
                        </div>
                      </Grid>
                      <Grid item md={10} className="flex-auto pl-10" style={{marginLeft:'-6px'}}>
                        <TooltipNew title={event.title} onClick={(link)=>this.openGoogleEvent(event.htmlLink)}>
                          <div className="text_ellipsis" style={{maxWidth:'100%'}}><h6 className="m-0">{event.title}</h6></div>
                        </TooltipNew><br />
                        <TooltipNew title={event.startDate + " to " + event.endDate} onClick={(link)=>this.openGoogleEvent(event.htmlLink)}>
                          <div className="text_ellipsis" style={{maxWidth:'100%'}}>
                            <label className="text-hint related-text">
                              {event.startDate + " to " + event.endDate}
                            </label>
                          </div>
                        </TooltipNew><br />
                        {event.allemail.map((email)=>(
                          <>{email}</>
                        ))}
                      </Grid>
                    </Grid>
                  {/* </a> */}
                </div>
              </div>      
            ))
          :
            <div>
              <label className="text-hint" onClick={this.addNewCalendarEvent}>
                <IconButton className="mr-8" component="span">
                  <Icon>event</Icon>
                </IconButton>
                Add Calendar Meetings/Events
              </label>
            </div>
        )}
      </Card>
    );
  }
}

const mapStateToProps = state => ({  
  apolloClient: state.apolloClient,
  notification: state.notification,
  settingReducer: state.settingReducer,
  leadReducer: state.leadReducer,
  contactReducer: state.contactReducer,
  companyReducer: state.companyReducer,
  opportunityReducer: state.opportunityReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    getRecordRelatedData: (client, req) => { dispatch(actionCreators.getRecordRelatedData(client, req)) },
  }
}


export default (connect(mapStateToProps, mapDispatchToProps)(withRouter(Calendar)));
