import React, { Component } from "react";
import {
    Checkbox, IconButton, Icon, Button, List, ListItem,
    ListItemText, ListItemIcon, ListItemSecondaryAction, Card, Typography,
    Dialog, DialogActions, DialogTitle, Snackbar, Fade, CircularProgress
} from "@material-ui/core";
import "date-fns";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import RootRef from "@material-ui/core/RootRef";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import EditIcon from "@material-ui/icons/Edit";
import AddActivityType from "./AddActivityType"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FontAwesome from "react-fontawesome";
class ActivityTypes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            masterActivityTypesList: [],
            openAddPop: false,
            FullStatusDialog: false,
            minimizeStatusDialog: false,
            isDeleteConfirmDialoOpen: false,
            itemName: '',
            dataToToggle: '',
            data: {},
            disabledCount: 0,
            isDataLoading: true
        };
    }

    handleLeadDialogClose = () => {
        this.setState({ openAddPop: false });
    };
    handleDialogMinimize = () => {
        this.setState(oldStateminimizeDialog => ({ minimizeStatusDialog: !oldStateminimizeDialog.minimizeStatusDialog }));
        this.setState({ FullStatusDialog: false })
    };
    toCamelCase = (s) => {
        return s.replace(/([-_][a-z])/ig, ($1) => {
            return $1.toUpperCase()
                .replace('-', '')
                .replace('_', '');
        });
    };

    handleDialogFull = () => {
        this.setState(oldStateFullCreateDialog => ({ FullStatusDialog: !oldStateFullCreateDialog.FullStatusDialog }));
        this.setState({ minimizeStatusDialog: false })

    };

    componentDidMount() {
        this.props.getActivityTypes(this.props.apolloClient.client);
    }

    componentWillReceiveProps(props) {
        if (props.settingReducer.masterActivityTypesList && props.settingReducer.masterActivityTypesList.is_loaded == true && props.settingReducer.masterActivityTypesList.data) {
            var dataList = props.settingReducer.masterActivityTypesList.data;
            var count = dataList.filter(function (element) {
                if (element.is_disable == true) {
                    return element
                }
            });
            setTimeout(() => {
                this.setState({ isDataLoading: false });
            }, 800);
            this.setState({
                masterActivityTypesList: dataList,
                disabledCount: count.length,
            });
        }
    }


    handleAddUpdate = (data) => {
        let info = {}
        if (data && data != undefined) {
            info.name = data.name
            info.id = data.id
            info.icon = data.icon
            info.ininteractions = data.ininteractions
            info.is_disable = data.is_disable
            info.isEdit = true
        } else {
            info.isEdit = false
        }
        this.setState({ openAddPop: false }, () => {
            this.setState({ data: info, openAddPop: true });
        });
    }

    handleDisableToggle = (data) => {
        this.setState({ itemName: data.name });
        this.toggleDialog(data)
    };
    handleEnable = (data) => {
        this.setState({
            dataToToggle: data, isDataLoading: true
        }, () => {
            this.forceUpdate()
            var datas = this.state.masterActivityTypesList
            var key = this.findWithAttr(datas, "id", this.state.dataToToggle.id)
            if (key != -1) {
                var req = {}
                req.icon = datas[key].icon;
                req.isdisable = (datas[key].is_disable ? false : true);
                req.ininteractions = datas[key].ininteractions;
                req.activitytypename = datas[key].name;
                req.activitytypeid = parseInt(datas[key].id);
                datas[key].is_disable = req.isdisable;
                this.setState({
                    masterActivityTypesList: datas,
                })
                this.props.updateActivityTypesDetail(this.props.apolloClient.client, req);
            }
            this.setState({ dataToToggle: "" });
        })
    };

    toggleDialog = (data) => {
        this.setState({ isDeleteConfirmDialoOpen: !this.state.isDeleteConfirmDialoOpen, dataToToggle: data });
    };

    confirmDialoge = () => {

        var datas = this.state.masterActivityTypesList
        var key = this.findWithAttr(datas, "id", this.state.dataToToggle.id)
        if (key != -1) {
            var req = {}
            req.icon = datas[key].icon;
            req.isdisable = (datas[key].is_disable ? false : true);
            req.ininteractions = datas[key].ininteractions;
            req.activitytypename = datas[key].name;
            req.activitytypeid = parseInt(datas[key].id);
            datas[key].is_disable = req.isdisable;
            this.setState({
                masterActivityTypesList: datas,
            })
            this.props.updateActivityTypesDetail(this.props.apolloClient.client, req);
        }
        this.toggleDialog()
        this.setState({ dataToToggle: "" , isDataLoading: true });
    };

    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] == value) {
                return i;
            }
        }
        return -1;
    }

    handleChange = (event, source) => {
        event.persist();
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    handleInteractionChange = (event, item) => {
        var datas = this.state.masterActivityTypesList
        var key = this.findWithAttr(datas, "id", item.id)

        if (key != -1) {
            var req = {}
            req.icon = datas[key].icon;
            req.isdisable = datas[key].is_disable;
            req.ininteractions = event.target.checked ? 1 : 0;
            req.activitytypename = datas[key].name;
            req.activitytypeid = parseInt(datas[key].id);
            datas[key].ininteractions = event.target.checked ? 1 : 0;
            this.setState({
                masterActivityTypesList: datas,
                isDataLoading : true
            })
            this.props.updateActivityTypesDetail(this.props.apolloClient.client, req);
        }
    };

    render() {
        return (
            <div className="v_scrollI">
                <Typography className="card-margin-leadstatus" variant="h5" color="inherit">Activity Types</Typography>
                <Typography className="card-margin-leadstatus"></Typography>
                <Card className="signup-card">
                    <div className="lead_upcoming">
                        <div className="box_portlet">
                            <div className="box_header">
                                <div className="box_label"><h3 className="box_title" style={{ marginLeft: '65px' }}></h3></div>
                                <div className="box_tools">
                                    <Button variant="contained" color="secondary" onClick={() => this.handleAddUpdate()} size="small" style={{ minWidth: 95 }}>
                                        Create New Activity Type
                                    </Button>
                                    {this.state.openAddPop && (
                                        <AddActivityType
                                            handleClose={this.handleLeadDialogClose}
                                            open={this.state.openAddPop}
                                            handleMinimize={this.handleDialogMinimize}
                                            handleFull={this.handleDialogFull}
                                            minimizeScreen={this.state.minimizeStatusDialog}
                                            fullScreen={this.state.FullStatusDialog}
                                            data={this.state.data}
                                            allState={this}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="box_body" >
                                {/* style={{height:'475px',overflowX:'auto'}} */}
                              Create and customize Activity Types to organize your sales activities
                                 <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Activity</TableCell>
                                            <TableCell >Count as interactions</TableCell>
                                            <TableCell ></TableCell>
                                            <TableCell ></TableCell>
                                            <TableCell >Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="v_scroll">
                                        {this.state.masterActivityTypesList.length > 0 && this.state.masterActivityTypesList.map((item, key) => {
                                            if (item.is_disable == false || item.is_disable == "false") {
                                                var iconStr = this.toCamelCase(item.icon)
                                                return <TableRow key={item.name}>
                                                    <TableCell component="th" scope="row">
                                                        <FontAwesome className={item.icon} style={{ fontSize: '20px', padding: '15px' }} />{item.name}
                                                    </TableCell>
                                                    <TableCell >
                                                        <Checkbox
                                                            disabled={item.is_default ? true : false}
                                                            name={item.name}
                                                            id={item.id}
                                                            checked={(item.ininteractions == 1) ? true : false}
                                                            onChange={(event, value) => this.handleInteractionChange(event, item)}
                                                            value={item.ininteractions == 1 ? 1 : 0}
                                                            color="primary"
                                                        />
                                                    </TableCell>
                                                    <TableCell ></TableCell>
                                                    <TableCell ></TableCell>
                                                    <TableCell >
                                                        {!item.is_default && <IconButton onClick={() => this.handleAddUpdate(item)} ><Icon>edit</Icon></IconButton>}
                                                        {!item.is_default && <IconButton onClick={() => this.handleDisableToggle(item)}><Icon>removecircleoutlineoutlined</Icon></IconButton>}
                                                        {item.is_default && <IconButton><Icon>lock</Icon></IconButton>}
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        }
                                        )}
                                    </TableBody>
                                </Table>
                                <br />
                                <br />
                                <span className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1">Disabled  Activities ({this.state.disabledCount})</span>
                                <Table>
                                    <TableBody className="v_scroll">
                                        {this.state.masterActivityTypesList.length > 0 && this.state.masterActivityTypesList.map((item, key) => {
                                            var iconStr = this.toCamelCase(item.icon)
                                            if (item.is_disable == true || item.is_disable == "true") {
                                                return <TableRow key={item.name}>
                                                    <TableCell component="th" scope="row">
                                                        <FontAwesome className={item.icon} style={{ fontSize: '20px', padding: '15px' }} />{item.name}
                                                    </TableCell>
                                                    <TableCell >

                                                    </TableCell>
                                                    <TableCell ></TableCell>
                                                    <TableCell ></TableCell>
                                                    <TableCell >
                                                        {!item.is_default && <IconButton onClick={() => this.handleEnable(item)}><Icon>addcircle</Icon></IconButton>}
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        }
                                        )}
                                    </TableBody>
                                </Table>
                            </div>
                            <Dialog
                                fullWidth={true}
                                maxWidth={'xs'}
                                open={this.state.isDeleteConfirmDialoOpen}
                                onClose={this.toggleDialog}
                                disableBackdropClick
                                //   disableEscapeKeyDown
                                aria-labelledby="ar-remove-dialog-title"
                            >
                                <DialogTitle id="ar-remove-dialog-title">{"You are about to disable "} {this.state.itemName} {"as Activity Type"}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText style={{ textAlign: "justify" }}>
                                        You will still be able to view previously logged activities of this type but you won't be able to log new activities for this type.
                                        <br />
                                        Are you sure you want to do this?
                                      </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.toggleDialog} color="primary">
                                        Cancel
                                      </Button>
                                    <Button type="Button" onClick={() => this.confirmDialoge()} className="btn_Primary">
                                        Ok
                                      </Button>

                                </DialogActions>
                            </Dialog>
                        </div>
                    </div>
                </Card>
                {this.state.isDataLoading &&
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        open={this.state.isDataLoading}
                        TransitionComponent={Fade}
                        message={<><CircularProgress size={20} style={{ color: 'white' }} /><span style={{ paddingLeft: '6px', color: 'white' }}>{" Loading..."}</span></>}
                        variant={'success'}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient
});


const mapDispatchToProps = dispatch => {
    return {
        getActivityTypes: (client) => dispatch(actionCreators.getActivityTypes(client)),
        updateActivityTypesDetail: (client, req) => dispatch(actionCreators.updateActivityTypesDetail(client, req)),
    }
}

export default (connect(mapStateToProps, mapDispatchToProps)(ActivityTypes));
