import React, { Component } from 'react';
import { Dialog, IconButton, RadioGroup, withStyles,Button, Icon, Radio, Tooltip, Grid, FormControl, Select, InputLabel, Menu, MenuItem, List, ListItem, ListItemText, DialogActions, Chip, TextField } from "@material-ui/core";
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
const StyledTooltip = withStyles({
  tooltipPlacementTop: {
    margin: "7px 0",
  },
})(Tooltip);

class CommonVisibiltyAdd extends Component {
  render() {
    let data = this.props.data;
    let v = this.props.v;
    let tilt = (
      data.state.teamUser && data.state.teamUser.map((u) => (
        <>
          <span style={{ maxWidth: "200px" }} className="text_ellipsis capitalize">
            {
              u.username + "(" + u.userteam.map((t, i) => t) + ")"
            }
          </span><br />
        </>
      ))
    )
    return (
      data.state.hasActiveTeamPermission === true ?
        <Grid item xs={12}>
          <InputLabel>{v.fieldlabel}</InputLabel>
          {!data.state.isEditVisibility ?
            <Grid container justify="space-between" className="edit_visibilty">
              <div className="d-flex">
                <h5 className="font-size-13 font-weight-400 mt-4">
                  {data.state.teamVisibilityLabel}
                </h5>
                {data.state.teamUser && data.state.teamUser.length > 0 &&
                  <StyledTooltip placement="top" arrow title={tilt}>
                    <span className="font-size-13 font-weight-400 ml-4 text_link">(preview)</span>
                  </StyledTooltip>
                }
              </div>
              {
                data.props.authReducer.userRole.id !== 3 &&
                <span
                  type="button"
                  onClick={data.handleToggleEditVisibility}
                  className="cursor-pointer font-size-13 mt-4 hover_show pr-16 text_link" >
                  Edit
                  </span>
              }
            </Grid>
            :
            <Grid container className="visibility_radio">
              <RadioGroup aria-label="gender" value={data.state.teamvisible} name="teamvisible"
                onChange={(event) =>
                  data.handleChangeVisibilityRadio(event.target.value)
                }>
                <FormControlLabel value={true} className="pb-4" control={<Radio color={"primary"} />}
                  label={
                    <div className="w-100">
                      <span
                        className={`
             ${data.state.teamvisible === true ?
                            "font-weight-500 " : "font-weight-400 "}
              "d-block font-size-13 "`}
                      >Use team visibility settings</span>
                      <h5 className="font-size-13 font-weight-400">
                        {data.state.teamVisibilityLabel}
                      </h5>
                    </div>
                  }
                />
                <FormControlLabel value={false} control={<Radio color={"primary"} />}
                  label={
                    <div className="w-100">
                      <span
                        className={`${data.state.teamvisible === false ? "font-weight-500 " : "font-weight-400 "}
              "d-block font-size-13 "`}>Manually set visibility</span>
                      {
                        data.state.teamvisible === true &&
                        <h5 className="font-size-13 font-weight-400 ">
                          {data.state.fieldsObj[v.fieldkey]
                            ? data.state.fieldsObj[v.fieldkey].values.name
                            : ""}
                        </h5>
                      }
                    </div>
                  } />
              </RadioGroup>
              {data.state.teamvisible === false &&
                <SelectValidator
                  ref={(ref) => (data[v.fieldkey] = ref)}
                  disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                  value={
                    data.state.fieldsObj[v.fieldkey]
                      ? data.state.fieldsObj[v.fieldkey].values
                      : ""
                  }
                  className="w-100 input_bx mt--16 pl-24"
                  onChange={(event) =>
                    data.handleChange(
                      { fieldid: v.id, values: event.target.value },
                      v.fieldkey
                    )
                  }
                  name={v.fieldlabel}
                  id={v.id}
                  required={v.recordcreationdefaultvalue == 2 ? true : false}
                  // label={v.fieldlabel}
                  placeholder={"Add " + v.fieldlabel}
                  validators={
                    v.recordcreationdefaultvalue == 2 ? ["required"] : []
                  }
                  errorMessages={
                    v.recordcreationdefaultvalue == 2
                      ? ["This field is required"]
                      : []
                  }
                >
                  {v.options &&
                    v.options.map((item, key) => (
                      <MenuItem
                        className="select-menu-item w-100 input_bx"
                        key={item.id}
                        value={item}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                </SelectValidator>
              }
              {
                data.state.fieldsObj[v.fieldkey]
                && data.state.fieldsObj[v.fieldkey].values.id === 2 &&
                data.state.selectedTeamList.map((team, i) => (
                  <Autocomplete
                    key={i}
                    disableOpenOnFocus
                    forcePopupIcon={false}
                    name={"team" + i}
                    className="w-100 input_bx "
                    options={data.state.teamList}
                    getOptionLabel={(option) => option.teamname ? option.teamname : ""}
                    value={team.teamname}
                    onChange={(event, value) =>
                      data.teamHandleChange(i, value, "team")}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        variant="standard"
                        className="w-100 input_bx pl-24"
                        fullWidth
                        placeholder="Add Team "
                        name={"team" + i}
                        value={team.teamname && team.teamname.length > 0 ? team.teamname : null}
                      />
                    )}
                  />
                ))
              }
              {
                data.state.fieldsObj[v.fieldkey]
                && data.state.fieldsObj[v.fieldkey].values.id === 3 &&
                data.state.selectedUserList.map((user, i) => (
                  <Autocomplete
                    key={i}
                    disableOpenOnFocus
                    forcePopupIcon={false}
                    name={"user" + i}
                    className="w-100 input_bx "
                    options={data.state.userListForVisibility}
                    getOptionLabel={(option) => option.name ? option.name : ""}
                    value={user.name}
                    onChange={(event, value) =>
                      data.teamHandleChange(i, value, "user")}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        variant="standard"
                        className="w-100 input_bx pl-24"
                        fullWidth
                        placeholder="Add user "
                        name={"user" + i}
                        value={user.name && user.name.length > 0 ? user.name : null}
                      />
                    )}
                  />
                ))
              }
            </Grid>
          }
        </Grid>

        :
        <Grid item xs={12}>
          <InputLabel>{v.fieldlabel}</InputLabel>
          <SelectValidator
            ref={(ref) => (data[v.fieldkey] = ref)}
            disabled={v.recordcreationdefaultvalue == 3 ? true : false}
            value={
              data.state.fieldsObj[v.fieldkey]
                ? data.state.fieldsObj[v.fieldkey].values
                : ""
            }
            className="w-100 input_bx"
            onChange={(event) =>
              data.handleChange(
                { fieldid: v.id, values: event.target.value },
                v.fieldkey
              )
            }
            name={v.fieldlabel}
            id={v.id}
            required={v.recordcreationdefaultvalue == 2 ? true : false}
            // label={v.fieldlabel}
            placeholder={"Add " + v.fieldlabel}
            validators={
              v.recordcreationdefaultvalue == 2 ? ["required"] : []
            }
            errorMessages={
              v.recordcreationdefaultvalue == 2
                ? ["This field is required"]
                : []
            }
          >
            {v.options &&
              v.options.map((item, key) => (
                <MenuItem
                  className="select-menu-item w-100 input_bx"
                  key={item.id}
                  value={item}
                >
                  {item.name}
                </MenuItem>
              ))}

          </SelectValidator>
          {
            data.state.fieldsObj[v.fieldkey]
            && data.state.fieldsObj[v.fieldkey].values.id === 2 &&
            data.state.selectedTeamList.map((team, i) => (
              <Autocomplete
                key={i}
                disableOpenOnFocus
                forcePopupIcon={false}
                name={"team" + i}
                className="w-100 input_bx "
                options={data.state.teamList}
                getOptionLabel={(option) => option.teamname ? option.teamname : ""}
                value={team.teamname}
                onChange={(event, value) =>
                  data.teamHandleChange(i, value, "team")}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    variant="standard"
                    className="w-100 input_bx "
                    fullWidth
                    placeholder="Add Team "
                    name={"team" + i}
                    value={team.teamname && team.teamname.length > 0 ? team.teamname : null}
                  />
                )}
              />
            ))
          }
          {
            data.state.fieldsObj[v.fieldkey]
            && data.state.fieldsObj[v.fieldkey].values.id === 3 &&
            data.state.selectedUserList.map((user, i) => (
              <Autocomplete
                key={i}
                disableOpenOnFocus
                forcePopupIcon={false}
                name={"user" + i}
                className="w-100 input_bx "
                options={data.state.userListForVisibility}
                getOptionLabel={(option) => option.name ? option.name : ""}
                value={user.name}
                onChange={(event, value) =>
                  data.teamHandleChange(i, value, "user")}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    variant="standard"
                    className="w-100 input_bx "
                    fullWidth
                    placeholder="Add user "
                    name={"user" + i}
                    value={user.name && user.name.length > 0 ? user.name : null}
                  />
                )}
              />
            ))
          }
        </Grid>)
  }
}
export default CommonVisibiltyAdd;
