import React, { Component } from "react";
import { Dialog, IconButton, Button, Icon, Tooltip, Grid, FormControl, Select, InputLabel, Menu, MenuItem, List, ListItem, ListItemText, DialogActions, Chip, TextField } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { CreateFieldsDynamicContactEdit } from './CreateFieldsDynamicContactEdit'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';
class ContactProfileDetails extends Component {
  state = {
    contact_id: "",
    lastname: "",
    city: "",
    addressState: "",
    zipcode: "",
    country: "",
    countryListArray: [],
    companyCurrencyData: [],
    focusValue: false,
    fieldsList: [],
    fieldsObj: {
      websites: {
        fieldid: "23",//Website Field
        values: [
          { srno: "1", id: null, value: "" },
        ]
      },
      social: {
        fieldid: "24",
        values: [
          { srno: "1", id: null, value: "" },
        ]
      },
      phones: {
        fieldid: "22",
        values: [
          { srno: "1", id: null, value: "" },
        ]
      },
      email: {
        fieldid: "21",
        values: [
          { srno: "1", id: null, value: "" },
        ]
      },
    },
    systemTagsList: [],
    isUpdate: true,
    isLeadDetailUpdate: true,
    contactFieldData: [],
    usersListArray: [],
    fnameError: false,
    userSettings: "",
    comp_options: [],
    comp_value: '',
    isAnyValueChange: false,
    isEditEmail: true,
    allSettings: "",
    mailboxid: "",
    updatedfieldid: "",
    isEditPhone: "",
    hasRingCentralConnect: false,
    itegrationDetails: [],
    ringCentralData: [],
    logEmailMain: "",
    logphoneMain: "",
    isEditVisibility: false,
    teamvisible: true,
    oldteamvisible: true,
    hasActiveTeamPermission: false,
    teamVisibilityLabel: "",
    crudPermission: {
      isdelete: true,
      isedit: true,
    },
    teamList: [],
    selectedTeamList: [],
    selectedUserList: [{}],
    oldSlectedUserList: [],
    oldSelctedTeamList: [],
    userListForVisibility: [],
    userTeamDetails: {},
    compnayCrud: {
      isaccess: true,
      iscreate: true,
    },
  };

  // componentWillMount() {
  componentDidMount() {

    ValidatorForm.addValidationRule('IsURLValid', (value) => {
      if (value && value.length && value.length > 0) {
        var specialChar = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
        if (!specialChar.test(value)) {
          return false;
        }
      }
      return true;
    });

    this.props.getCompanyRecList(this.props.apolloClient.client);

    let val = { typeId: 2 }
    let userData = JSON.parse(localStorage.getItem('user_data_session'))
    let userid = { userid: userData.id }
    this.props.generateMailBoxId(this.props.apolloClient.client);
    this.props.getEmailSetting(this.props.apolloClient.client);
    this.props.getUserSetting(this.props.apolloClient.client, userid);
    this.props.customFieldList(this.props.apolloClient.client, val);
    this.props.getUserList(this.props.apolloClient.client);
    this.props.getCountryList(this.props.apolloClient.client);
    this.props.getCompanyCurrency(this.props.apolloClient.client);
    this.props.getTagsList(this.props.apolloClient.client);
    this.props.getUserIntegration(this.props.apolloClient.client);
    this.props.getTeam(this.props.apolloClient.client);

    if (this.props.contact_id && (this.state.contact_id != this.props.contact_id)) {
      this.props.getContactField(this.props.apolloClient.client, parseInt(this.props.contact_id));
      this.setState({
        contact_id: parseInt(this.props.contact_id),
        isUpdate: true,
      }, () => this.forceUpdate())
    }

  }
  componentWillUnmount() {
    this.props.contactReducer.contactFieldData = [];
    this.props.contactReducer.contactLogList = [];
  }
  AllMemberDisplay = () => {
    let teamVisibilityLabel = "";
    let userCount = 0;
    let teamCount = 0;
    let teamUser = [];
    let usersListArray = this.props.settingReducer.usersListArray.data ? this.props.settingReducer.usersListArray.data : []
    usersListArray.length > 0 && usersListArray.map((user) => {
      if (user.teamlist.length > 0) {
        let userdata = { userteam: [], id: "", username: "" }
        teamCount = teamCount < user.teamlist.length ? user.teamlist.length : teamCount;
        user.teamlist.map((u) => { userdata.userteam.push(u.team.teamname) })
        userdata.id = user.id;
        userdata.username = user.name;
        userCount = userCount + 1;
        teamUser.push(userdata)
      }
    })
    teamVisibilityLabel = userCount + " users from " + teamCount + " team ";
    this.setState({
      teamVisibilityLabel: teamVisibilityLabel,
      teamUser: teamUser
    }, () => this.forceUpdate())
  }
  selectedUserDetails = (mainuser) => {
    let teamVisibilityLabel = "";
    let teamUser = [];
    let userdata = { userteam: [], id: "", username: "" }
    mainuser.teamlist.map((u) => { userdata.userteam.push(u.team.teamname) })
    userdata.id = mainuser.id;
    userdata.username = mainuser.name;
    teamUser.push(userdata)
    teamVisibilityLabel = "1 users from " + mainuser.teamlist.length + " team ";
    this.setState({
      teamVisibilityLabel: teamVisibilityLabel,
      teamUser: teamUser
    }, () => this.forceUpdate())
  }
  themSelves = (selectedOwner) => {
    let teamVisibilityLabel = "";
    let userCount = 0;
    let teamCount = 0;
    let teamUser = [];
    let usersListArray = this.props.settingReducer.usersListArray.data ? this.props.settingReducer.usersListArray.data : []
    usersListArray.length > 0 && usersListArray.map((user) => {
      if (user.teamlist.length > 0) {
        if (user.teamlist[0].teammanager === true || (user.email === selectedOwner.email)) {
          let userdata = { userteam: [], id: "", username: "" }
          teamCount = teamCount < user.teamlist.length ? user.teamlist.length : teamCount;
          user.teamlist.map((u) => { userdata.userteam.push(u.team.teamname) })
          userdata.id = user.id;
          userdata.username = user.name;
          userCount = userCount + 1;
          teamUser.push(userdata)
        }
      }
    })
    teamVisibilityLabel = userCount + " users from " + teamCount + " team ";
    this.setState({
      teamVisibilityLabel: teamVisibilityLabel,
      teamUser: teamUser
    }, () => this.forceUpdate())
  }
  getUserPermission = () => {
    if (this.props.settingReducer.userPermissionDetails.data) {
      let data = this.props.settingReducer.userPermissionDetails.data.data;
      if (data.teampermissionstatus === true &&
        data.visibilityaccess.length > 0) {
        let conctdt = data.visibilityaccess.find(function (e) { return e.sourcetype === 2 });
        let crudPermission = "";
        let compnayCrud = "";
        if (this.props.authReducer.userRole.id === 3) {
          compnayCrud = data.visibilityaccess.find(function (e) { return e.sourcetype === 3 });
          crudPermission = conctdt;
        }
        if (conctdt.isaccess) {
          if (conctdt.viewoption === 1) {
            this.AllMemberDisplay();
          }
          if (conctdt.viewoption === 2 || conctdt.viewoption === 3) {
            var selectedOwner = this.state.fieldsObj["owner"]
            if (selectedOwner && selectedOwner.values) {
              if (selectedOwner.values.teamlist.length > 0) {//if member in any team 
                if (selectedOwner.values.teamlist[0].teammanager === false) {//owner is not  teammanager
                  if (conctdt.viewoption === 2) {
                    this.AllMemberDisplay();
                  } else {
                    this.themSelves(selectedOwner.values);
                  }
                } else {//owner is teammanager   
                  this.selectedUserDetails(selectedOwner.values);
                }
              } else { // not in any team 
                this.setState({ teamVisibilityLabel: "0 users", teamUser: [] }, () => this.forceUpdate())
              }
            } else {
              this.setState({ teamVisibilityLabel: "0 users", teamUser: [] }, () => this.forceUpdate())
            }
          }
        } else {//conctdt.isaccess === false
          this.setState({ teamVisibilityLabel: "0 users", teamUser: [] }, () => this.forceUpdate())
        }
        this.setState({
          hasActiveTeamPermission: true,
          viewoption: conctdt.viewoption,
          crudPermission: crudPermission === "" ? this.state.crudPermission : crudPermission,
          compnayCrud: compnayCrud === "" ? this.state.compnayCrud : compnayCrud
        })
      } else {
        this.setState({ teamvisible: false })
      }
    }
  }
  componentWillReceiveProps(props) {
    this.getUserPermission();
    if (props.settingReducer.teamList.data && this.state.teamList !== props.settingReducer.teamList.data) {
      const teamList = props.settingReducer.teamList.data;
      let data = [];
      teamList.map((v, i) => {
        data.push({ id: v.id, teamname: v.teamname })
      });
      this.setState({ teamList: data })
    }
    if (props.settingReducer.generatedmailboxid.data) {
      let mailboxid = props.settingReducer.generatedmailboxid.data.mailboxid;
      this.setState({ mailboxid });
    }
    if (props.settingReducer.userItegration.data && props.settingReducer.userItegration.data !== this.state.itegrationDetails) {
      let data = props.settingReducer.userItegration.data;
      let hasRingCentralConnect = false;
      data.map((item) => {
        if (item.status && item.integrationid == 10) {
          hasRingCentralConnect = true;
        }
      });
      this.setState({
        itegrationDetails: data,
        hasRingCentralConnect: hasRingCentralConnect,
      });
    }
    if (props.settingReducer.emailsettings.data) {
      const mian_data = props.settingReducer.emailsettings.data
      this.setState({
        allSettings: mian_data,
      })
    }
    if (props.settingReducer.companyRecList && props.settingReducer.companyRecList.data && props.settingReducer.companyRecList.data.data) {
      let companyOptions = [];
      props.settingReducer.companyRecList.data.data.map((compOpt) => {
        companyOptions.push({ label: compOpt.name, value: compOpt.company_id });
      })
      this.setState({ comp_options: companyOptions });
    }

    //storing user setting data in state
    if (props.settingReducer.userSettingArray.data && this.state.userSettings == "") {
      const settingData = props.settingReducer.userSettingArray.data;
      this.setState({
        userSettings: settingData,
      })
    }
    if (props.contactReducer.contactFieldData.error == true && props.contactReducer.contactFieldData.notify_msg.indexOf('Permission:') != -1 ) {
      this.props.history.push('/unauthorized');
    }else{
      if (props.contact_id && (this.state.contact_id != props.contact_id) || this.state.isUpdate && props.contact_id != undefined) {
        props.getContactField(props.apolloClient.client, parseInt(props.contact_id));
  
        let mailid = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        let logParams = { contactid: parseFloat(props.contact_id), emailid: mailid, activitypeid: [], logtypeid: [] }
        this.props.getContactLogListNew(this.props.apolloClient.client, logParams);
  
        if (props.contactReducer.contactFieldData.data) {
          var emailkey1 = this.findWithAttr(props.contactReducer.contactFieldData.data, 'fieldkey', "email");
          var phonekey1 = this.findWithAttr(props.contactReducer.contactFieldData.data, 'fieldkey', "phones");
          if (emailkey1 != -1) {
            let emailData = props.contactReducer.contactFieldData.data[emailkey1];
            if (emailData.contactdetail[0] && emailData.contactdetail[0].values &&
              emailData.contactdetail[0].values.length > 0 &&
              this.state.logEmailMain !== emailData.contactdetail[0].values[0].value) {
              this.setState({
                logEmailMain: emailData.contactdetail[0].values[0].value,
              }, () => this.forceUpdate())
              localStorage.setItem('emailLogEmail', emailData.contactdetail[0].values[0].value);
              let logParams = { contactid: parseFloat(props.contact_id), emailid: emailData.contactdetail[0].values[0].value, activitypeid: [], logtypeid: [] }
              this.props.getContactLogListNew(this.props.apolloClient.client, logParams);
            }
            else {
              this.setState({
                isEditEmail: true
              }, () => this.forceUpdate())
              localStorage.removeItem('emailLogEmail');
            }
          }
          if (phonekey1 != -1) {
            let phonedata = props.contactReducer.contactFieldData.data[phonekey1];
            if (phonedata.contactdetail[0] &&
              phonedata.contactdetail[0].values &&
              phonedata.contactdetail[0].values.length > 0) {
              this.setState({
                logphoneMain: phonedata.contactdetail[0].values[0].value,
              }, () => this.forceUpdate())
            } else {
              this.setState({
                isEditPhone: true
              }, () => this.forceUpdate())
            }
          }
        }
        this.setState({
          contact_id: parseInt(props.contact_id),
        }, () => this.forceUpdate())
      }
    }
    
    if (props.settingReducer.contactCustomFieldList.data && this.state.fieldsList.length == 0) {
      const customFields = props.settingReducer.contactCustomFieldList.data;
      var vkey = this.findWithAttr(customFields, "id", "26");
      var visibilitydefault = this.findWithAttr(customFields[vkey].options, "is_default", true);
      this.setState({
        fieldsList: customFields,
        fieldsObj: {
          ...this.state.fieldsObj,
          ["visibility"]: { fieldid: "26", values: customFields[vkey].options[visibilitydefault], },
        }
      })
    }

    if (props.settingReducer.countryListGql.data && this.state.countryListArray.length == 0) {
      const countryies = props.settingReducer.countryListGql.data;
      this.setState({ countryListArray: countryies })
    }
    if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
      const usersListArray = props.settingReducer.usersListArray.data;

      let userListForVisibility = [];
      usersListArray.map((data, i) => {
        userListForVisibility.push({ id: data.id, name: data.name })
      })
      this.setState({ usersListArray: usersListArray, userListForVisibility: userListForVisibility })
      var companyOwnerKey = this.findWithAttr(usersListArray, 'companyowner', "true");
      // this.setState({
      //   fieldsObj: {
      //     ...this.state.fieldsObj,
      //     ["owner"]: { fieldid: "4", values:usersListArray[companyOwnerKey]}
      //   }
      // })
    }


    if (props.settingReducer.systemTagsList.data && this.state.systemTagsList.length == 0) {
      const tagsData = props.settingReducer.systemTagsList.data;
      this.setState({ systemTagsList: tagsData })
    }

    if (props.settingReducer.companyCurrencyData.data != null && this.state.companyCurrencyData.length == 0) {
      var cData = props.settingReducer.companyCurrencyData.data;
      this.setState({ companyCurrencyData: cData[0] });
    }

    if (props.contactReducer.contactFieldData.data && props.contactReducer.contactFieldData.is_loaded) {
      this.setState({
        fieldsObj: {
          websites: {
            fieldid: "23",//Website Field
            values: [
              { srno: "1", id: null, value: "" },
            ]
          },
          social: {
            fieldid: "24",
            values: [
              { srno: "1", id: null, value: "" },
            ]
          },
          phones: {
            fieldid: "22",
            values: [
              { srno: "1", id: null, value: "" },
            ]
          },
          email: {
            fieldid: "21",
            values: [
              { srno: "1", id: null, value: "" },
            ]
          },
        },
        comp_value: '',
        city: '',
        addressState: '',
        zipcode: '',
      }, () => {
        let fieldsObj = this.state.fieldsObj;
        this.setState({ contactFieldData: props.contactReducer.contactFieldData.data })
        let country = "";
        let selectedTeamList = [];
        let selectedUserList = [];
        const contactDataForEdit = props.contactReducer.contactFieldData.data;

        var promise = new Promise((resolve, reject) => {
          contactDataForEdit.map((v, i) => {
            //if(!v.contactdetail.length){return false}
            if (v.fieldtype.id == '1' && ((v.fieldkey == "websites" || v.fieldkey == "social" || v.fieldkey == "phones" || v.fieldkey == "email") && v.is_default)) {
              if (v.contactdetail.length > 0 && v.contactdetail[0].values.length > 0) {
                var data = {
                  fieldid: v.id,
                  values: v.contactdetail[0].values
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }

              }
            } else if (v.fieldtype.id == '1' && v.id == '16') {//for first/last name          
              if (v.contactdetail.length > 0) {
                var data = {
                  fieldid: v.id,
                  contactvalue: v.contactdetail[0].values.firstname
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }
                this.setState({
                  oldteamvisible: v.contactdetail[0].contact.teamvisible !== null && v.contactdetail[0].contact.teamvisible,
                  teamvisible: v.contactdetail[0].contact.teamvisible !== null && v.contactdetail[0].contact.teamvisible,
                  lastname: v.contactdetail[0].values.lastname
                })
              }
            }
            else if (v.fieldtype.id == '1' && v.id == '20') {//for owner field        
              if (v.contactdetail.length > 0 && v.contactdetail[0].values.length && v.contactdetail[0].values[0].id) {
                var companyOwnerKey = this.findWithAttr(this.state.usersListArray, 'id', v.contactdetail[0].values[0].id);
                if (companyOwnerKey !== -1) {
                  var ownerData = {
                    fieldid: v.id,
                    values: this.state.usersListArray[companyOwnerKey]
                  }
                  fieldsObj = {
                    ...fieldsObj,
                    [v.fieldkey]: ownerData,
                  }
                }
              }
            } else if (v.fieldtype.id == '1' && v.fieldkey == 'tags') {//for tag input
              var tagArray = []
              if (v.contactdetail.length > 0 && v.contactdetail[0].values.length > 0) {
                v.contactdetail[0].values.map((v, i) => {
                  tagArray.push(v.value);
                })
              }
              var data = {
                fieldid: v.id,
                values: tagArray
              }
              fieldsObj = {
                ...fieldsObj,
                [v.fieldkey]: data,
              }
            } else if (v.fieldtype.id == '1' && v.id == '40') {//For value with currency Field
              if (v.contactdetail.length > 0) {
                var data = {
                  fieldid: v.id,
                  contactvalue: v.contactdetail[0].values[0].value
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }
              }
            } else if (v.fieldtype.id == '1' || v.fieldtype.id == '6' || v.fieldtype.id == '10' || v.fieldtype.id == '7' || v.fieldtype.id == '9') {//other textfield and number input
              if (v.contactdetail.length > 0 && v.contactdetail.length > 0) {
                var data = {
                  fieldid: v.id,
                  contactvalue: v.contactdetail[0].fieldvalue
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }
              }
            }

            if (v.fieldtype.id == '2' && v.id == "25") {//Text aread with Address field
              if (v.contactdetail.length > 0 && v.contactdetail[0].values.length > 0) {
                var data = {
                  fieldid: v.id,
                  contactvalue: v.contactdetail[0].values[0].street
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }


                this.setState({
                  city: v.contactdetail[0].values[0].city,
                  addressState: v.contactdetail[0].values[0].state,
                  zipcode: v.contactdetail[0].values[0].zipcode,
                })

                if (v.contactdetail[0].values[0].country !== "" && this.state.countryListArray.length > 0) {
                  var key = this.findWithAttr(this.state.countryListArray, 'name', v.contactdetail[0].values[0].country);
                  country = this.state.countryListArray[key];
                }
              }
            } else if (v.fieldtype.id == '2') {//TextArea
              if (v.contactdetail.length > 0) {
                var data = {
                  fieldid: v.id,
                  contactvalue: v.contactdetail[0].fieldvalue
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }
              }
            }

            if (v.fieldtype.id == "3") {//checkboxes
              if (v.contactdetail.length > 0) {
                var data = {
                  fieldid: v.id,
                  contactvalue: v.contactdetail[0].fieldvalue
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }
              }
            }
            //if(this.state.fieldsList && this.state.fieldsList.length >0){
            if (v.fieldtype.id === '4') {//dropdown
              //11-source/3-status/5-visibility
              if (v.id === "26" || v.id === "19") {
                if (v.contactdetail.length > 0 && v.contactdetail[0].values.length > 0) {
                  var optId = this.findWithAttr(v.options, 'id', parseInt(v.contactdetail[0].values[0].id));
                  fieldsObj = {
                    ...fieldsObj,
                    [v.fieldkey]: { fieldid: v.id, values: v.options[optId] },
                  }
                  if (v.id === "26") {
                    if (v.contactdetail[0].values[0].id === 2) {
                      let filteredselectedTeamList = this.state.selectedTeamList.length > 0 ? this.state.selectedTeamList.filter(el => Object.keys(el).length) : []
                      if (v.contactdetail[0].values[0].visiblelist && filteredselectedTeamList !== v.contactdetail[0].values[0].visiblelist) {
                        selectedTeamList = v.contactdetail[0].values[0].visiblelist
                      }
                    } else if (v.contactdetail[0].values[0].id === 3) {
                      let filteredselectedUserList = this.state.selectedUserList.length > 0 ? this.state.selectedUserList.filter(el => Object.keys(el).length) : []
                      if (v.contactdetail[0].values[0].visiblelist && filteredselectedUserList !== v.contactdetail[0].values[0].visiblelist) {
                        selectedUserList = v.contactdetail[0].values[0].visiblelist
                      }
                    }
                  }
                }
              } else if (v.id === "17") {
                if (v.contactdetail.length > 0 && v.contactdetail[0].values.length > 0) {
                  var optId = v.contactdetail[0].values[0].id;
                  this.setState({ comp_value: optId });
                }

              } else {
                if (v.contactdetail.length > 0 && v.contactdetail[0].values.length > 0) {
                  var optId = this.findWithAttr(v.options, 'id', v.contactdetail[0].values[0].id);
                  fieldsObj = {
                    ...fieldsObj,
                    [v.fieldkey]: { fieldid: v.id, values: v.options[optId] },
                  }
                }
              }
            }
            if (v.fieldtype.id === '5') {//multi dropdown
              if (v.contactdetail.length > 0 && v.contactdetail[0].values.length > 0) {
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: { fieldid: v.id, values: v.contactdetail[0].values },
                }
              }
            }
            if (v.fieldtype.id === '8') {//datepicker
              if (v.contactdetail.length > 0 && v.contactdetail[0].fieldvalue != "") {
                //var dt = moment.unix(v.contactdetail[0].fieldvalue).format(this.state.userSettings.dateformat).toString()
                var dt = new Date(moment(v.contactdetail[0].fieldvalue).format('YYYY-MM-DD'))
                dt = new Date(dt)
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: { fieldid: v.id, values: dt },
                }
              } else if (v.contactdetail.length > 0 && v.contactdetail[0].fieldvalue == "") {
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: { fieldid: v.id, values: null },
                }
              }
            }
            //}
          })

          this.setState({
            fieldsObj,
            country,
            isUpdate: false,
            oldSelctedTeamList: [...selectedTeamList, {}],
            oldSlectedUserList: [...selectedUserList, {}],
            selectedTeamList: [...selectedTeamList, {}],
            selectedUserList: [...selectedUserList, {}]
          }, () => {
            resolve(true)
          })
        });

        promise.then(result => {
          props.contactReducer.contactFieldData.is_loaded = false;
          this.setState({
            isAnyValueChange: false
          }, () => {
          })
        });
      })
    }
  }
  handleCompanyChange = (val, newValue) => {
    let id = (newValue.__isNew__) ? "0" : newValue.value;
    let comp_values = { id: id, name: newValue.label };
    this.handleChange({ fieldid: "17", values: comp_values }, 'company', 'allowSubmitChange');
    this.setState({ comp_value: id });
  };


  addNewField = (key, option) => {
    let data = this.state.fieldsObj[key];
    data.values.push({
      srno: (data.values.length + 1).toString(),
      id: option.id,
      value: ""
    })

    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [key]: data
      }
    })
  }

  handleAddMoreField = (name, key, index, value) => {
    let data = this.state.fieldsObj[key];
    data.values[index][name] = value;
    var keyName = key;
    var keyVal = data;
    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [key]: data
      },
      isAnyValueChange: true
    }, () => {
      // setTimeout(() => {
      //   //dont upadte lead if first name is empty
      //   if(this.state.fieldsObj['name'] && this.state.fieldsObj['name'].contactvalue == ""){
      //     return false
      //   }
      //   //dont allow to update if value length 1 and value is blank
      //   if(keyVal.values.length == 1 && keyVal.values[0].value == ""){
      //     return false
      //   }
      //   if (this.state.fieldsObj[keyName] == keyVal) {
      //     this.handleFormSubmit()
      //   }
      // }, 2500);
    })
  }

  handleNormalFields = (event) => {
    var keyName = event.target.name;
    var keyVal = event.target.value;
    this.setState({
      [event.target.name]: event.target.value,
      isAnyValueChange: true
    }, () => {
      // //dont upadte lead if first name is empty
      // if(this.state.fieldsObj['name'] && this.state.fieldsObj['name'].contactvalue == ""){
      //   return false
      // }
      // setTimeout(() => {
      //   if (this.state[keyName] == keyVal) {
      //     this.handleFormSubmit()
      //   }
      // }, 1500);
    })
  };

  handleCountryChange = (event, values, updatedfieldid) => {
    this.setState({
      country: values,
      isAnyValueChange: true,
      updatedfieldid: parseFloat(updatedfieldid),
    }, () => {
      //dont upadte lead if first name is empty
      // if(this.state.fieldsObj['name'] && this.state.fieldsObj['name'].contactvalue == ""){
      //   return false
      // }
      setTimeout(() => {
        if (this.state.country == values) {
          this.handleFormSubmit()
        }
      }, 500);
    })
  }

  focusInValue = () => {
    this.setState({
      focusValue: true,
    }, () => this.forceUpdate())
  };


  currencyDropdownClose = () => {
    this.setState({ focusValue: false })
  };

  handleChange = (val, name, isSubmit) => {
    if (val.fieldid === "26") {
      this.setState({ isEditVisibility: true }, () => this.forceUpdate())
      if (val.values.id === 2) {
        this.setState({ selectedTeamList: [{}] }, () => this.forceUpdate())
      } else if (val.values.id === 3) {
        this.setState({ selectedUserList: [{}] }, () => this.forceUpdate())
      }
    }
    var key = this.findWithAttr(this.state.fieldsList, 'id', val.fieldid);
    if (key != -1 && this.state.fieldsList[key].fieldtype.id != "4" && this.state.fieldsList[key].fieldtype.id != "8") {
      if (this[name]) {
        this[name].validate(val.value);
      }
    }
    if (val.fieldid === "20") {
      if ((this.state.viewoption === 2 || this.state.viewoption === 3) && val.values === null) {
        this.setState({
          teamVisibilityLabel: "0 users",
          teamUser: []
        }, () => this.forceUpdate())
      } else {
        this.setState({
          fieldsObj: {
            ...this.state.fieldsObj,
            [name]: val,
          }
        }, () => this.getUserPermission())
      }
    }
    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [name]: val
      },
      isAnyValueChange: true,
      updatedfieldid: parseFloat(val.fieldid)
    }, () => {
      // if(name === "name" && val.contactvalue ===""){
      //   this.setState({ fnameError: true })
      //   return false
      // }else{
      //   this.setState({ fnameError: false })
      // }
      // //dont upadte lead if first name is empty
      // if(this.state.fieldsObj['name'].contactvalue == ""){
      //   return false
      // }
      if (!isSubmit) {
        return false
      }
      setTimeout(() => {
        if (this.state.fieldsObj[name] == val) {
          this.handleFormSubmit()
        }
      }, 500);
    })
  };


  focusOutSubmitForm = (event, fieldId, ref) => {
    if (this[ref].props.name == "email" && this[ref].props.value !== "" && this[ref].state.isValid) {
      this.setState({ isEditEmail: false });
    }
    this.setState({ isEditPhone: false, updatedfieldid: parseFloat(fieldId) })
    if (this[ref].props && this[ref].props.id == "tagInputId1") {
      setTimeout(() => {
        document.getElementById("tagInputId1").value = "";
      }, 10)
    }
    this[ref].validate(event.target.value);
    setTimeout(() => {
      if (this.state.isAnyValueChange) {
        this.handleFormSubmit()
      }
    }, 0);



  }

  handleFormSubmit = () => {

    this.updateContactForm.isFormValid(false).then(isValid => {
      console.log("isValid", isValid);
      if (isValid) {
        let logData = [];
        let submitData = JSON.parse(JSON.stringify(this.state.fieldsObj));
        submitData = Object.values(submitData).map((row) => {
          if (row.fieldid == "16") { // name
            row.names = { firstname: row.contactvalue, lastname: this.state.lastname }
            delete row['contactvalue'];
          }
          // if(row.fieldid == "14"){ // 
          //   row.values = [{id:this.state.companyCurrencyData.currencyid.toString(),srno:"1",value:row.contactvalue}]
          //   delete row['contactvalue'];
          // }

          if (row.fieldid == "23" || row.fieldid == "24" || row.fieldid == "22" || row.fieldid == "21") { //website social phone email
            if (row.values.length == 1 && (row.values[0].id == null || row.values[0].value == "")) {
              row.values = [{ "fieldid": row.fieldid, value: [] }]
            }
            if (row.values.length > 1) {
              row.values.map((val, i) => {
                if (val.value == "") {
                  row.values.splice(i, 1);
                }
              })
            }

            if (row.values.length > 0) {//adding selected dropdown val as lable
              row.values.map((val, i) => {
                if (val.id) {
                  var fKey = this.findWithAttr(this.state.fieldsList, 'id', row.fieldid);
                  if (fKey != -1) {
                    var optKey = this.findWithAttr(this.state.fieldsList[fKey].options, 'id', val.id);
                    row.values[i].label = this.state.fieldsList[fKey].options[optKey].optionvalue
                  }
                }
              })
            }

            if (row.fieldid == "22") {
              if (row.values.length === 1) {
                if (row.values[0].fieldid) {
                  row.values = []
                } else {
                  if (row.values.length > 0) {
                    var foundMobile = this.findWithAttr(row.values, 'id', "2");
                    if (foundMobile != -1) {
                      var index = 1;
                      row.values.map((val, i) => {
                        if (val.id == "2") {
                          row.values[i].srno = "1"
                        } else {
                          row.values[i].srno = (index + 1).toString()
                        }
                        index++;
                      })
                    }
                  }
                }
              } else {
                if (row.values.length > 0) {
                  var foundMobile = this.findWithAttr(row.values, 'id', "2");
                  if (foundMobile != -1) {
                    var index = 1;
                    row.values.map((val, i) => {
                      if (val.id == "2") {
                        row.values[i].srno = "1"
                      } else {
                        row.values[i].srno = (index + 1).toString()
                      }
                      index++;
                    })
                  }
                }
              }
            }
            if (row.fieldid == "23" || row.fieldid == "24" || row.fieldid == "21") {//make value blank array if notfill in this field
              if (row.values.length === 1) {
                if (row.values[0].fieldid) {
                  row.values = []
                }
              }
            }
          }
          if (row.fieldid === "26" || row.fieldid === "19" || row.fieldid === "17") {
            if (row.fieldid === "26") {
              let filterVisibleList = [];
              if (row.values.id === 2) {
                filterVisibleList = this.state.selectedTeamList.length > 0 ? this.state.selectedTeamList.filter(el => Object.keys(el).length) : []
              } else if (row.values.id === 3) {
                filterVisibleList = this.state.selectedUserList.length > 0 ? this.state.selectedUserList.filter(el => Object.keys(el).length) : []
              }
              row.values = [
                {
                  srno: "1",
                  id: row.values.id,
                  value: row.values.name,
                  visiblelist: filterVisibleList
                }
              ];
            } else {
              row.values = [{ srno: "1", id: row.values.id, value: row.values.name }]
            }
          }
          var key = this.findWithAttr(this.state.fieldsList, 'id', row.fieldid);
          if (this.state.fieldsList[key].fieldtype.id == "4" && this.state.fieldsList[key].is_default == false) {
            row.values = [{ srno: "1", id: row.values.id, value: row.values.optionvalue }]
          }
          if (this.state.fieldsList[key].fieldtype.id == "5" && this.state.fieldsList[key].is_default == false) {
            var ar = [];
            if (row.values.length > 0) {
              row.values.map((val, i) => {
                var optName;
                if (val.optionvalue) {
                  optName = val.optionvalue
                } else {
                  optName = val.value
                }
                ar.push({ srno: (i + 1).toString(), id: val.id, value: optName })
              })
            }
            row.values = ar
          }

          //validating date fiels
          if (this.state.fieldsList[key].fieldtype.id == "8") {//date field
            if (row.values == null) {
              row.contactvalue = "";
              delete row.values;
            } else if (row.values == "Invalid Date") {
              return false
            } else {
              // let date_test = moment(row.values).format('YYYY-MM-DD HH:mm');
              // var logdate = moment.tz(date_test, this.state.userSettings.timezone).format();
              // row.contactvalue = moment.utc(logdate).format('X');
              // row.contactvalue = moment(row.values).format("X");
              var dt = moment(row.values).format('YYYY-MM-DD')
              row.contactvalue = dt;
              delete row.values;
            }
          }

          if (row.fieldid == "28") {
            if (row.values.length > 0) {
              var objArr = [];
              row.values.map((val, k) => {
                var foundKey = this.findWithAttr(this.state.systemTagsList, 'tagname', val);
                var tagId = "0";
                if (foundKey !== -1) {
                  tagId = this.state.systemTagsList[foundKey].id
                }
                objArr.push({ "srno": (k + 1).toString(), value: val.toLowerCase(), id: tagId, isdeleted: "0" })
              })

              //add tags from props value which is removed   with id deleted id "1"
              var tagsKeyFromProps = this.findWithAttr(this.props.contactReducer.contactFieldData.data, 'id', "28");
              if (this.props.contactReducer.contactFieldData.data[tagsKeyFromProps].contactdetail.length > 0) {
                var oldTagsValues = this.props.contactReducer.contactFieldData.data[tagsKeyFromProps].contactdetail[0].values;
                if (oldTagsValues.length > 0) {
                  oldTagsValues.map((v, k) => {
                    var checkKey = this.findWithAttr(objArr, 'value', v.value);
                    if (checkKey === -1) {
                      v.isdeleted = "1";
                      v.srno = (objArr.length + 1).toString();
                      objArr.push(v);
                    }
                  })
                }
              }

              row.values = objArr
              if (this.props.contactReducer.contactFieldData.data[tagsKeyFromProps].contactdetail.length > 0) {
                this.props.contactReducer.contactFieldData.data[tagsKeyFromProps].contactdetail[0].values = objArr;
              }
            }
          }

          if (row.fieldid == "25") {//address field
            row.contactvalue = {
              street: row.contactvalue,
              city: this.state.city,
              state: this.state.addressState,
              zipcode: this.state.zipcode,
              country: (this.state.country && this.state.country.name) ? this.state.country.name : ""
            }
          }
          if (row.fieldid == "20") {//owner value set
            if (row.values) {
              row.values = [{ "srno": "1", id: row.values.id, value: row.values.email, name: row.values.name }];
            } else {
              row.values = [];
            }
            delete row.contactvalue;
          }
          return row;
        })

        //adding  contactdetailid for update object from this.state.contactFieldData
        submitData = submitData.map((row, i) => {
          var key = this.findWithAttr(this.state.contactFieldData, 'id', row.fieldid);
          if (key !== -1) {
            if (this.state.contactFieldData[key].contactdetail.length > 0) {
              row.contactdetailid = this.state.contactFieldData[key].contactdetail[0].id;
            } else {
              row.contactdetailid = ""
            }
          }
          return row
        })

        //not to submit if any field is false
        var allowToSubmit = true
        submitData.map((row) => {
          if (!row) {
            allowToSubmit = false
          }
        })

        console.log("submitData", submitData);
        //return
        if (this.state.contact_id && allowToSubmit && this.state.crudPermission.isedit) {

          // update contact field data afte update.... (For log)
          this.setState({ isUpdate: true, isEditVisibility: false });
          // -- ---------------


          let req = {
            contactdata: submitData,
            contactid: this.state.contact_id,
            contactImage: "",
            updatedfieldid: this.state.updatedfieldid,
            teamvisible: this.state.teamvisible,

          }
          this.props.updateContactDetail(this.props.apolloClient.client, req);
        }
      }
    })

  };

  arrDifference = (arr1, arr2) => {
    var arr = [];
    arr1 = arr1.toString().split(',').map(Number);
    arr2 = arr2.toString().split(',').map(Number);
    // for array1
    for (var i in arr1) {
      if (arr2.indexOf(arr1[i]) === -1)
        arr.push(arr1[i]);
    }
    // for array2
    for (i in arr2) {
      if (arr1.indexOf(arr2[i]) === -1)
        arr.push(arr2[i]);
    }
    return arr.sort((x, y) => x - y);
  }

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] == value) {
        return i;
      }
    }
    return -1;
  }
  onEmailClick = () => {
    this.setState({
      isEditEmail: true
    })

  }

  onPhoneClick = () => {
    this.setState({
      isEditPhone: true
    })
  }

  isOpenedClickEmailToggle = (e) => {
    e.preventDefault();
    this.props.isOpenedClickEmailToggle()
  }
  teamHandleChange = (index, value, name) => {
    let mianteam = this.state.teamList;
    let selectedTeamList = this.state.selectedTeamList
    let mianUsers = this.state.usersListArray;
    let selectedUserList = this.state.selectedUserList
    if (name === "team") {
      if (value !== null) {
        selectedTeamList[index] = value;
        if (mianteam.length + 1 > selectedTeamList.length) {
          selectedTeamList[index + 1] = {};
        }
      } else {
        selectedTeamList.splice(index, 1);
        if (selectedTeamList.length === 0) {
          selectedTeamList[0] = {};
        }
      }
    } else {
      if (value !== null) {
        selectedUserList[index] = { id: value.id, name: value.name }
        if (mianUsers.length + 1 > selectedUserList.length) {
          selectedUserList[index + 1] = {};
        }
      } else {
        selectedUserList.splice(index, 1);
        if (selectedUserList.length === 0) {
          selectedUserList[0] = {};
        }
      }
    }
    this.setState({
      selectedTeamList: selectedTeamList,
      selectedUserList: selectedUserList
    })
  }
  handleToggleEditVisibility = () => {
    this.setState({
      isEditVisibility: !this.state.isEditVisibility
    })
  }

  handleChangeVisibilityRadio = (value) => {
    if (value) {
      let customFields = [...this.state.fieldsList]
      var vkey = this.findWithAttr(customFields, "id", "26");
      var visibilitydefault = this.findWithAttr(
        customFields[vkey].options,
        "is_default",
        true
      );
      this.setState({
        selectedTeamList: [],
        updatedfieldid: 26,
        fieldsObj: {
          ...this.state.fieldsObj,
          ["visibility"]: {
            fieldid: "26",
            values: customFields[vkey].options[visibilitydefault],
          },
        },
      });
    }
    this.setState({
      teamvisible: value === "true" ? true : false
    })

  }
  handleVisibilitySave = () => {
    this.setState({
      updatedfieldid: 26
    }, () => this.handleFormSubmit())
  }
  hadleCancelvisibility = () => {
    // this.props.getContactField(this.props.apolloClient.client, parseInt(this.props.contact_id));
    let fieldsObj = this.state.fieldsObj
    let customFields = [...this.state.fieldsList]
    var vkey = this.findWithAttr(customFields, "id", "26");
    var visibilitydefault = this.findWithAttr(customFields[vkey].options, "is_default", true);

    let contactFieldData = [...this.state.contactFieldData]
    var key1 = this.findWithAttr(contactFieldData, 'id', "26");
    if (contactFieldData[key1].contactdetail[0].values[0]) { //if visibilty not efault 
      let mainoldValue = contactFieldData[key1].contactdetail[0].values[0];
      var opn = this.findWithAttr(contactFieldData[key1].options, 'id', mainoldValue.id);
      fieldsObj = {
        ...fieldsObj,
        ["visibility"]: {
          fieldid: "26",
          values: contactFieldData[key1].options[opn],
        },
      }
    } else {//set  visibilty  default 
      fieldsObj = {
        ...fieldsObj,
        ["visibility"]: {
          fieldid: "26",
          values: customFields[vkey].options[visibilitydefault],
        },
      }
    }
    this.setState({
      selectedTeamList: this.state.oldSelctedTeamList,
      selectedUserList: this.state.oldSlectedUserList,
      fieldsObj: fieldsObj,
      teamvisible: this.state.oldteamvisible,

    });
    this.handleToggleEditVisibility()
  }
  render() {
    return (
      <div className="v_scroll position-relative label-size-14 pr-16">
        <ValidatorForm ref={updateContactForm => this.updateContactForm = updateContactForm} noValidate instantValidate={false} onSubmit={this.handleFormSubmit}>
          <Grid container spacing={2}>
            {CreateFieldsDynamicContactEdit(this)}
          </Grid>
        </ValidatorForm>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
  contactReducer: state.contactReducer,
  authReducer: state.authReducer

});

const mapDispatchToProps = dispatch => {
  return {
    getTeam: (client) => dispatch(actionCreators.getTeam(client)),

    getCompanyRecList: (client) => { dispatch(actionCreators.getCompanyRecList(client)) },
    updateContactDetail: (client, request) => { dispatch(actionCreators.updateContactDetail(client, request)) },
    customFieldList: (client, request) => { dispatch(actionCreators.customFieldList(client, request)) },
    getContactLogListNew: (client, contactid) => dispatch(actionCreators.getContactLogListNew(client, contactid)),


    getCountryList: (client) => { dispatch(actionCreators.getCountryList(client)) },
    clearNotification: () => dispatch(actionCreators.clearNotification()),
    getCompanyCurrency: (client) => dispatch(actionCreators.getCompanyCurrency(client)),
    getTagsList: (client) => dispatch(actionCreators.getTagsList(client)),
    getContactField: (client, leadId) => dispatch(actionCreators.getContactField(client, leadId)),
    getUserList: (client) => dispatch(actionCreators.getUserList(client)),
    getUserSetting: (client, data) => dispatch(actionCreators.getUserSetting(client, data)),
    getEmailSetting: (client) => dispatch(actionCreators.getEmailSetting(client)),
    generateMailBoxId: (client) => { dispatch(actionCreators.generateMailBoxId(client)) },
    getUserIntegration: (client) => dispatch(actionCreators.getUserIntegration(client)),
    saveRingCentralData: (client, value) => dispatch(actionCreators.saveRingCentralData(client, value)),
  }
}

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(ContactProfileDetails);
export default (connect(mapStateToProps, mapDispatchToProps)(withRouter(ContactProfileDetails)));