import React, { Component } from "react";
import { Button, IconButton, Icon, withStyles, Card, DialogContent, Dialog, DialogActions, DialogTitle, Typography, MenuItem, InputLabel, Radio, FormControlLabel, RadioGroup, Badge, Drawer, Tooltip } from "@material-ui/core";
import Grid, { default as MaterialGrid } from '@material-ui/core/Grid';

import { connect } from "react-redux";
import * as actions from '../../../store/actions/index';

// import ReportSidebarMenu from "../../Layout/ReportSidebarMenu";
import Select from '@material-ui/core/Select';
import FilterListIcon from '@material-ui/icons/FilterList';

import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import moment from 'moment';//moment
import 'moment-timezone';//timezone
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

class SalesBySource extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportTitile: "Sales By Source",
            reportSubTitle: "Amount total along with the closing rate.",
            totlaValue: "$375 K",
            totalWinRate: "0%",
            selectedPipline: "1",
            selectedFilter: "1",
            totalopp: "1",
            value_type: "1",
            drawerOpen: false,
            dateopen: false,
            dateformat: 'MM/DD/YYYY',
            selectedDate: null,
            durationtype: "1",
            salesbysourcedata: [],
            fromdate: moment().startOf("month").format('YYYY-MM-DD'),
            todate: moment().endOf("month").format('YYYY-MM-DD'),
            memberid: 0
        }
    }
    componentDidMount() {
        let fromDate = localStorage.getItem('dashboardFromDate');
        let toDate = localStorage.getItem('dashboardToDate');
        this.setState({
            fromdate: fromDate, todate: toDate
        }, () => this.updateGraphSettings())
    }

    componentWillReceiveProps(props) {
        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            let usersetting = props.settingReducer.userSettingArray.data;
            this.setState({ dateformat: usersetting.dateformat, timeZone: usersetting.timezone })
        }

        if (props.reportReducer.reportsalesbysource && props.reportReducer.reportsalesbysource.data) {
            let salesbysourcedata = props.reportReducer.reportsalesbysource.data.data
            this.setState({ salesbysourcedata })
        }
        if (props.reportReducer.UserDashboardId) {
            if (this.state.memberid !== props.reportReducer.UserDashboardId) {
              {
                this.setState({
                  memberid: props.reportReducer.UserDashboardId
                }, () => this.updateGraphSettings())
              }
            }
          }
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value }, () => this.updateGraphSettings())
    }
    toggle = () => {
        this.setState({ dateopen: !this.state.dateopen });
    }
    setDateRange = (range) => {
        let startDate = moment.utc(range.startDate).format(this.state.dateformat);
        let endDate = moment.utc(range.endDate).format(this.state.dateformat);
        let mainlabel = range.label ? range.label : startDate + " To " + endDate
        this.setState({ selectedDate: mainlabel }, () => this.updateGraphSettings())
    }
    componentDidUpdate(PrevProps) {
        if (PrevProps.reportReducer.UserDashboardId !== this.props.reportReducer.UserDashboardId) {
            this.setState({
                memberid: this.props.reportReducer.UserDashboardId
            }, () => this.updateGraphSettings())
        }
    }
    updateGraphSettings = () => {
        let params = {
            filterdata: [],
            piplineid: parseFloat(this.state.selectedPipline),
            type: parseFloat(this.state.value_type),
            fromdate: this.state.fromdate,
            todate: this.state.todate,
            memberid: parseFloat(this.state.memberid)
        };
        this.props.salesBySourceReport(this.props.apolloClient.client, params);
    }
    render() {
        let { salesbysourcedata, reportTitile, reportSubTitle, dateopen, totalopp, selectedFilter, totlaValue, drawerOpen, totalWinRate, selectedPipline, value_type, durationtype } = this.state;
        let pointFormat = "";
        if (value_type == 1) {
            pointFormat = '{series.name}: <b>${point.y} ( {point.percentage:.1f}% ) </b>';
        } else if (value_type == 2) {
            pointFormat = '{series.name}: <b>{point.y} ( {point.percentage:.1f}% ) </b>';
        }
        const options = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: null
            },
            tooltip: {
                pointFormat: pointFormat
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            legend: {
                enabled: true,
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle',
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            series: [{
                name: 'Source',
                colorByPoint: true,
                data: salesbysourcedata.stage,
            }]
        }

        return (
            <Card elevation={3} className="mb-10">
                <div className="report_card">
                    <div className="report_header crm-all-report-filters">
                        <h3>{reportTitile}</h3>
                        <div className="">
                            <RadioGroup className="ml-16 mt-10" row aria-label="gender" name="value_type" value={value_type} onChange={this.handleChange}>
                                <FormControlLabel value="1" control={<Radio color="primary" />} label="Revenue" />
                                <FormControlLabel value="2" control={<Radio color="primary" />} label="Count of Deals" />
                            </RadioGroup>
                        </div>
                        <div className="crm-all-report-filters-daterange">
                            <DateRangePicker
                                open={dateopen}
                                toggle={this.toggle}
                                onChange={(range) => this.setDateRange(range)}
                            />
                        </div>

                        {/* </Grid> */}
                    </div>
                    <div className="report_content">
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                        />
                    </div>
                </div>
            </Card>
        );
    }
}

const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
    notification: state.notification,
    reportReducer: state.reportReducer,
});
const mapDispatchToProps = dispatch => {
    return {
        salesBySourceReport: (client, value) => dispatch(actions.salesBySourceReport(client, value)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SalesBySource);