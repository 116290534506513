import React, { Component } from "react";
import TaskTableComponent from './TaskTableComponent';
import TaskCreationRule from './TaskCreationRule'
import { Icon, Switch, FormControlLabel, IconButton, withStyles, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import FontAwesome from 'react-fontawesome'
import CreateNewFieldTaskDialog from "./CreateNewFieldTaskDialog";
import * as actions from '../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.5rem",
    }
}))(Icon);

class SettingTask extends Component {

    constructor(props) {
        super(props);

        this.state = {
            taskdata: [],
            taskFieldIds: [],
            taskOrderIds: [],
            activetaskdatas:[],
            inactivetaskdatas:[],
            shouldOpenCreateFieldTaskDialog: false,
        };
    }
    
    handleChange = (event) => {
        let val = {
            typeId: 6,
            fieldstatus: event.target.checked,
            generatedfieldid: event.target.value
        }

        this.props.updateCustomFieldStatus(this.props.apolloClient.client, val);
    }

    componentDidMount() {
        let val = { typeId: 6 }
        this.props.customFieldList(this.props.apolloClient.client, val);
        this.props.customFieldTypeList(this.props.apolloClient.client);   
    }

    componentWillReceiveProps(props){
        if (props.settingReducer.taskCustomFieldList.data && props.settingReducer.customFieldTypeList.data ) {
            const customFields = props.settingReducer.taskCustomFieldList.data;

            let activetaskdatas = [];
            let inactivetaskdatas = [];
            let taskFieldIds = [];
            let taskOrderIds = [];
            
            customFields.map((fieldData, index) => {
                if (fieldData.fieldstatus === true) {
                    taskFieldIds.push(parseFloat(fieldData.id));
                    taskOrderIds.push(parseFloat(fieldData.orderno));
                    
                    let fieldlicon = (fieldData.fieldicon === 'fa-dollar-sign') ? 'fa-usd' : fieldData.fieldicon;
                   
                    activetaskdatas.push({
                        drag: <Link className="drag-handle" taskOrderIds={taskOrderIds} to="#"><Icon>drag_handle</Icon></Link>,
                        key: fieldData.id,
                        name: (
                            <div>
                                <FontAwesome className={'crm-fontawsome-icon ' + fieldlicon} ></FontAwesome> 
                                <label className="crm-custom-field-label">{fieldData.fieldlabel}</label>
                            </div>
                            ),
                        fieldType: fieldData.fieldtype.name,
                        creationRule: (fieldData.recordcreationrules_editable) ?
                            <TaskCreationRule dropName="create" fieldId={index} options={fieldData.recordcreationrulesvalue} disabled={false} defaultChecked={fieldData.recordcreationdefaultvalue} />
                            :
                            <TaskCreationRule dropName="create" fieldId={index} options={fieldData.recordcreationrulesvalue} disabled={true} defaultChecked={fieldData.recordcreationdefaultvalue} />,
                        editRule: (fieldData.recordeditrules_editable) ?
                            <TaskCreationRule dropName="edit" fieldId={index} options={fieldData.recordeditrulesvalue} disabled={false} defaultChecked={fieldData.recordeditdefaultvalue} />
                            :
                            <TaskCreationRule dropName="edit" fieldId={index} options={fieldData.recordeditrulesvalue} disabled={true} defaultChecked={fieldData.recordeditdefaultvalue} />,
                        action: <React.Fragment>
                          
                            <FormControlLabel
                                value={fieldData.generatedfieldid}
                                control={(fieldData.fieldstatus) ? <Switch onChange={this.handleChange} color="primary" defaultChecked disabled={!fieldData.isdiactivated} /> : <Switch onChange={this.handleChange} color="primary" disabled={!fieldData.isdiactivated} />}
                                labelPlacement="start"
                                className="ml-0 mr-10"
                            />
                        </React.Fragment>
                    })
                }

                if (fieldData.fieldstatus === false) {
                    inactivetaskdatas.push({
                        key: fieldData.id,
                        name: fieldData.fieldlabel,
                        action: <React.Fragment>
                                <FormControlLabel
                                    value={fieldData.generatedfieldid}
                                    control={(fieldData.fieldstatus) ? <Switch onChange={this.handleChange} color="primary" defaultChecked disabled={!fieldData.isdiactivated} /> : <Switch onChange={this.handleChange} color="primary" disabled={!fieldData.isdiactivated} />}
                                    labelPlacement="start"
                                    className="ml-0 mr-10"
                                />
                            </React.Fragment>
                    })
                }
            })
            
            this.setState({ activetaskdatas: activetaskdatas, inactivetaskdatas: inactivetaskdatas, taskFieldIds: taskFieldIds, taskOrderIds: taskOrderIds },()=>this.forceUpdate());
        }
    }
    closePopUp=()=>{
        this.setState({ shouldOpenCreateFieldTaskDialog: false });
    }
    updateFieldsOrder = (fromIndex, toIndex) =>{
       
        let taskFieldIds = [];

        const taskdata = this.state.activetaskdatas;
        const item = taskdata.splice(fromIndex, 1)[0];
        
        taskdata.splice(toIndex, 0, item);
        
        this.setState({
            taskdata
        })
        const taskOrderIds = this.state.taskOrderIds;
        taskdata.map((value, index)=>{
            taskFieldIds.push(parseFloat(value.key));
        });
        
        let val1 = {
            typeId: 6,
            orderno: taskOrderIds,
            fieldid: taskFieldIds
        }        
        this.props.updateCustomFieldOrder(this.props.apolloClient.client, val1);    
    }
    handleTaskDialogClose = () => {
        this.setState({ shouldOpenCreateFieldTaskDialog: false });
    };
    render() {
        let { shouldOpenCreateFieldTaskDialog } = this.state;
        return (
            <>
                <div className="d-flex justify-content-end mb-16">
                    <Button className="btn_Primary" variant="contained" color="primary" onClick={() => this.setState({ shouldOpenCreateFieldTaskDialog: true })} size="small">Add Field</Button>
                    {shouldOpenCreateFieldTaskDialog && (
                        <CreateNewFieldTaskDialog
                            handleClose={this.handleTaskDialogClose}
                            open={shouldOpenCreateFieldTaskDialog}
                            allData={this}
                        />
                    )}
                </div>
                <TaskTableComponent activetaskdata={this.state.activetaskdatas} inActivetaskdata={this.state.inactivetaskdatas} allData={this}  />
            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        settingReducer: state.settingReducer,
        apolloClient: state.apolloClient
    };
};
const mapDispatchToProps = dispatch => {
    return {
        customFieldList: (client, request) => {dispatch(actions.customFieldList(client, request))},
        customFieldTypeList: (client) => { dispatch(actions.customFieldTypeList(client)) },
        updateCustomFieldStatus: (client, request) => { dispatch(actions.updateCustomFieldStatus(client, request)) },
        updateCustomFieldOrder: (client, request) => { dispatch(actions.updateCustomFieldOrder(client, request)) },
        
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(SettingTask);
