import React, { Component } from "react";
import { Icon, IconButton, CircularProgress, Avatar, Grid, Fab, MenuItem, Card, } from "@material-ui/core";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Link, withRouter } from "react-router-dom";

const democss = {
  position: "absolute",
  background: "white",
  width: '900px',
  right: " 11%",
  top: "56px",
  maxHeight: "500px",
  overflow: "auto",
}
class TopSearchBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      searchtext: '',
      masterSerachData: [],
      allData: [],
      isDataLoading: false,
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

  }
  componentWillReceiveProps(props) {
    if (props.settingReducer.masterSerach && props.settingReducer.masterSerach.data) {
      const masterSerachData = props.settingReducer.masterSerach.data;
      if (masterSerachData !== this.state.masterSerachData) {
        let data = masterSerachData.data;
        if (masterSerachData.type === this.state.searchtext) {
          let leadData = [];
          let contactData = [];
          let CompnayData = [];
          let oppoData = [];
          let ProjectData = [];
          let taskData = [];
          if (data) {
            data.map((item) => {
              if (item.recordtype === "lead") {
                let arrayItem = {};
                let logdata = [];
                let url = '';

                if (item.__leaddetail__) {
                  arrayItem.id = item.id;
                  url = "/lead/lead/" + item.id;
                  logdata = this.getDetails(item.__leaddetail__);
                } else if (item.__source__) {
                  // console.log('item.__source__ :::::', item.__source__);
                  arrayItem.id = item.__source__.id;
                  url = "/lead/lead/" + item.__source__.id;
                  logdata = this.getLogDetails(item, item.__source__.__leaddetail__ && item.__source__.__leaddetail__);
                }

                arrayItem.recordtype = item.recordtype;
                arrayItem.userImageURL = item.image;
                arrayItem.data = logdata;
                arrayItem.URLMain = url;
                leadData.push(arrayItem);
              } else if (item.recordtype === "contact") {
                let arrayItem = {};
                let logdata = [];
                let url = '';

                if (item.__contactdetail__) {
                  arrayItem.id = item.id;
                  url = "/contact/contact/" + item.id;
                  logdata = this.getDetails(item.__contactdetail__);
                } else if (item.__source__) {
                  arrayItem.id = item.__source__.id;
                  url = "/contact/contact/" + item.__source__.id;
                  logdata = this.getLogDetails(item, item.__source__.__contactdetail__ && item.__source__.__contactdetail__);
                }

                arrayItem.recordtype = item.recordtype;
                arrayItem.userImageURL = item.image;
                arrayItem.data = logdata;
                arrayItem.URLMain = url;
                contactData.push(arrayItem);
              } else if (item.recordtype === "company") {
                let arrayItem = {};
                let logdata = [];
                let url = '';

                if (item.__companydetail__) {
                  arrayItem.id = item.id;
                  url = "/company/company/" + item.id;
                  logdata = this.getDetails(item.__companydetail__);
                } else if (item.__source__) {
                  arrayItem.id = item.__source__.id;
                  url = "/company/company/" + item.__source__.id;
                  logdata = this.getLogDetails(item, item.__source__.__companydetail__ && item.__source__.__companydetail__);
                }

                arrayItem.recordtype = item.recordtype;
                arrayItem.userImageURL = item.image;
                arrayItem.data = logdata;
                arrayItem.URLMain = url;

                CompnayData.push(arrayItem);
              } else if (item.recordtype === "opportunities") {
                let arrayItem = {};
                let logdata = [];
                let url = '';

                if (item.__opportunitiesdetail__) {
                  arrayItem.id = item.id;
                  url = "/opportunities/opportunities/" + item.id;
                  logdata = this.getDetails(item.__opportunitiesdetail__);
                } else if (item.__source__) {
                  arrayItem.id = item.__source__.id;
                  url = "/opportunities/opportunities/" + item.__source__.id;
                  logdata = this.getLogDetails(item, item.__source__.__opportunitiesdetail__ && item.__source__.__opportunitiesdetail__);
                }

                arrayItem.recordtype = item.recordtype;
                arrayItem.userImageURL = item.image;
                arrayItem.icon = "business";
                arrayItem.data = logdata;
                arrayItem.URLMain = url;

                oppoData.push(arrayItem);
              } else if (item.recordtype === "project") {
                let arrayItem = {};
                let logdata = [];
                let url = ' ';

                if (item.__projectdetail__) {
                  arrayItem.id = item.id;
                  url = "/project/projects/" + item.id;
                  logdata = this.getDetails(item.__projectdetail__);
                } else if (item.__source__) {
                  arrayItem.id = item.__source__.id;
                  url = "/project/projects/" + item.__source__.id;
                  logdata = this.getLogDetails(item, item.__source__.__projectdetail__ && item.__source__.__projectdetail__);
                }

                arrayItem.recordtype = item.recordtype;
                arrayItem.userImageURL = item.image;
                arrayItem.icon = "business_center";
                arrayItem.data = logdata;
                arrayItem.URLMain = url;

                ProjectData.push(arrayItem);
              } else if (item.recordtype === "task") {
                let arrayItem = {};
                let logdata = [];

                if (item.__taskdetail__) {
                  arrayItem.id = item.id;
                  logdata = this.getDetails(item.__taskdetail__);
                } else if (item.__source__) {
                  arrayItem.id = item.__source__.id;
                  logdata = this.getLogDetails(item, item.__source__.__taskdetail__ && item.__source__.__taskdetail__);
                }

                arrayItem.recordtype = item.recordtype;
                arrayItem.userImageURL = item.image;
                arrayItem.URLMain = "/task/task-list";
                arrayItem.icon = "list";
                arrayItem.data = logdata;

                taskData.push(arrayItem);
              }
            })
            let SerachLogs = [];
            SerachLogs.push(this.filterdata(leadData, "Lead"));
            SerachLogs.push(this.filterdata(contactData, "Contact"));
            SerachLogs.push(this.filterdata(CompnayData, "Company"));
            SerachLogs.push(this.filterdata(oppoData, "Opportunities"));
            SerachLogs.push(this.filterdata(ProjectData, "Project"));
            SerachLogs.push(this.filterdata(taskData, "Task"));
            this.setState({
              masterSerachData: SerachLogs,
              isDataLoading: false,
              allData: data
            });
          }
        }
      } else {
        setTimeout(() => {
          this.setState({
            isDataLoading: false
          });
        }, 1000);
      }
    }
  }
  filterdata = (data, key) => {
    return { data, key }
  };

  getLogDetails = (ALlLogData, data) => {
    let demo = {};
    demo['name'] = data[0].fieldvalue;
    demo['text'] = ALlLogData.text;
    demo['propertyname'] = ALlLogData.propertyname;
    this.setState({ log_id: ALlLogData.id });
    return demo;
  };

  getDetails = (DetailsData) => {
    let demo = {};
    DetailsData && DetailsData.length > 0 && DetailsData.map((dt, i) => {
      if (dt.__field__ && dt.allvalues.length > 0) {
        if (dt.__field__.fieldkey === "owner" && dt.allvalues[0].name) {
          demo[dt.__field__.fieldkey] = dt.allvalues[0].name
        }
        else if (dt.allvalues[0].value) {
          demo[dt.__field__.fieldkey] = dt.allvalues[0].value
        }
      }
    })
    return demo;
  };

  toggle = () => {
    this.setState({
      open: !this.state.open,
      searchtext: "",
      masterSerachData: [],
      allData: [],
      openPopup: false
    })
  };

  handleChange = (event) => {
    if (event.target.value === '') {
      this.setState({ masterSerachData: [] });
    }
    if (event.target.value.length > 0) {
      let req = { searchtext: event.target.value }
      this.props.masterSearch(this.props.apolloClient.client, req);
    }
    this.setState({
      [event.target.name]: event.target.value,
      isDataLoading: event.target.value.length > 0 ? true : false,
      openPopup: event.target.value.length > 0 ? true : false,
    });
  };

  detailsUI = (op) => {
    if (op.data && this.state.searchtext) {
      let maindata = op.data;

      // console.log('maindata ]]]]].....----->>', maindata);
      const keyword = this.state.searchtext;
      const entries = Object.values(maindata);

      let matchcase = entries.filter(word => {
        let e = word.toString()
        return e.toLowerCase().includes(keyword.toLowerCase())
      });

      matchcase.push(maindata.contacttype, maindata.text, maindata.propertyname, maindata.title, maindata.company, maindata.stage,
        maindata.value, maindata.status, maindata.emaildomain, maindata.primarycontact);

      // console.log('matchcase -------->', maindata.text, maindata.propertyname);
      const propertyname = matchcase.find(function (element) { return element === maindata.propertyname });
      let ValueofMatchCasees = [
        { Name: 'title', field: matchcase.find(function (element) { return element === maindata.title }) },
        { Name: 'email', field: matchcase.find(function (element) { return element === maindata.email }) },
        { Name: 'owner', field: matchcase.find(function (element) { return element === maindata.owner }) },
        { Name: 'company', field: matchcase.find(function (element) { return element === maindata.company }) },
        { Name: 'phones', field: matchcase.find(function (element) { return element === maindata.phones }) },
        { Name: 'description', field: matchcase.find(function (element) { return element === maindata.description }) },
        { Name: 'tags', field: matchcase.find(function (element) { return element === maindata.tags }) },
        { Name: 'websites', field: matchcase.find(function (element) { return element === maindata.websites }) },
        { Name: 'social', field: matchcase.find(function (element) { return element === maindata.social }) },
        { Name: 'activity Type', field: matchcase.find(function (element) { return element === maindata.activitytype }) },
        { Name: 'visibility', field: matchcase.find(function (element) { return element === maindata.visibility }) },
        { Name: 'value', field: matchcase.find(function (element) { return element === maindata.value }) },
        { Name: 'priority', field: matchcase.find(function (element) { return element === maindata.priority }) },
        { Name: 'primary Contact', field: matchcase.find(function (element) { return element === maindata.primarycontact }) },
        { Name: 'pipeline', field: matchcase.find(function (element) { return element === maindata.pipeline }) },
        { Name: 'status', field: matchcase.find(function (element) { return element === maindata.status }) },
        { Name: 'stage', field: matchcase.find(function (element) { return element === maindata.stage }) },
        { Name: 'source', field: matchcase.find(function (element) { return element === maindata.source }) },
        { Name: 'Email Domain', field: matchcase.find(function (element) { return element === maindata.emaildomain }) },
        { Name: 'address', field: matchcase.find(function (element) { return element === maindata.address }) },
        { Name: 'related To', field: matchcase.find(function (element) { return element === maindata.relatedto }) },
        { Name: 'contact Type', field: matchcase.find(function (element) { return element === maindata.contacttype }) },
        { Name: propertyname, field: matchcase.find(function (element) { return element === maindata.text }) },
      ];


      let ALLData = ValueofMatchCasees.filter((ele, ind) => ele.field !== undefined && ind === ValueofMatchCasees.findIndex(elem => elem.field === ele.field));

      // console.log('ALLData :::', ALLData);
      return (
        ALLData && ALLData.map((item, index) => {
          return (<span className="mr-4" key={index}>

            {item.Name === 'title' || item.Name === 'value' ||
              item.Name === 'company' || item.Name === 'stage' ||
              item.Name === 'emaildomain' || item.Name === 'status' ?
              (index ? ' | ' : '') + item.field
              :
              item.Name ?
                item.Name === 'description' ?
                  <><span>{(index ? ' - ' : '') + ` ${item.Name.charAt(0).toUpperCase() + item.Name.slice(1)}: `}{item.field}</span></> :
                  <>{(index ? ' - ' : '') + ` ${item.Name.charAt(0).toUpperCase() + item.Name.slice(1)}: `}<span style={{ color: '#0C0D0D' }}> {item.field} </span></> : ""

            }
          </span>);
        })
      )
    }
  };

  mainLink = (data) => {
    this.props.history.push(data.URLMain);
    this.setState({
      searchtext: "",
      open: false,
      masterSerachData: [],
      allData: [],
      openPopup: false,
    })
  };

  serachreultmainLink = () => {
    if (this.state.searchtext) {
      this.props.history.push(`/search-results/${this.state.searchtext}`)
    }
    this.setState({
      searchtext: "",
      open: false,
      masterSerachData: [],
      allData: [],
      openPopup: false,
    })
  };

  setWrapperRef(node) {
    this.wrapperRef = node;
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  };

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ openPopup: false })
    }
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  };

  handleFocus = (event) => {
    // console.log('event', event);
    this.handleChange(event)
  };

  render() {
    let { masterSerachData, isDataLoading, searchtext, open, openPopup } = this.state;
    return (
      <React.Fragment >
        {!open && (
          <IconButton onClick={this.toggle} className="btn-white">
            <Icon>search</Icon>
          </IconButton>
        )}
        {open &&
          <div className="d-flex align-items-center top-search-box" ref={this.setWrapperRef} >
            <ValidatorForm
              noValidate
              instantValidate={false}
            >
              <TextValidator
                autoFocus
                fullWidth
                name="searchtext"
                variant="standard"
                placeholder="Search"
                value={searchtext}
                onFocus={this.handleFocus}
                onChange={this.handleChange}
                onClick={this.handleFocus}
                className="w-100 input_bx text-white"
              />
            </ValidatorForm>
            <IconButton onClick={this.toggle} className="btn-white">
              <Icon>close</Icon>
            </IconButton>
          </div>
        }
        {
          openPopup > 0 &&
          <Card elevation={3} style={democss} ref={this.setWrapperRef} >
            {
              isDataLoading ?
                <Grid
                  container
                  spacing={0}
                  className="p-20"
                  direction="column"
                  alignItems="center"
                  justify="center"
                  style={{ minHeight: '10vh', backgroundColor: 'rgba(0,0,0,0)' }}
                >
                  <Grid item xs={3}>
                    <CircularProgress disableShrink size={30} />
                  </Grid>
                </Grid>
                :
                this.state.allData.length > 0 && searchtext && searchtext !== '' ?
                  <>
                    <MenuItem onClick={this.serachreultmainLink}>
                      <Grid className="d-flex " style={{ cursor: 'pointer', margin: '15px 0 10px 0' }}
                        onClick={this.serachreultmainLink} >
                        <Grid item >
                          <Icon style={{ width: '20px' }}>search</Icon>
                        </Grid>
                        <Grid className="pt-5 ml-16 d-flex" item md={10}>
                          <span
                            style={{ maxWidth: "800px" }}
                            className="font-weight-400">
                            See all results for "{this.state.searchtext}"
                            </span>
                        </Grid>
                      </Grid>
                    </MenuItem>
                    {(this.state.allData.length) > 0 &&
                      masterSerachData &&
                      searchtext &&
                      searchtext !== '' &&
                      masterSerachData.map((option, i) => (
                        <>
                          {
                            (option.data.length) > 0 ?
                              <Grid className="font-weight-500 p-10 text-lable"
                                style={{ borderTop: "1px solid #c1c1c1" }}>
                                {option.key}
                              </Grid>
                              :
                              ""
                          }
                          {option && option.data && option.data.map((optionz, i) => (
                            <MenuItem>
                              <Grid container spacing={1} className="mb-4 mt-4" onClick={() => this.mainLink(optionz)}>
                                <Grid className="d-flex">
                                  <Grid item className="mr-10 ml-10">
                                    {optionz.icon ?
                                      <Fab size="small" className="small-size overflow-hidden custom-avatar" style={{ padding: '20px' }}>
                                        <Icon className={"m-0 font-weight-normal"} >{optionz.icon} </Icon>
                                      </Fab>
                                      :
                                      <>
                                        {!optionz.userImageURL &&
                                          <Avatar
                                            style={{ cursor: 'pointer' }}
                                            className={`avatar_circle color_${optionz.data.name}` ? optionz.data.name.charAt(0).toUpperCase() : ""}
                                          >
                                            {(optionz.data.name) ? optionz.data.name.charAt(0).toUpperCase() : ""}
                                          </Avatar>
                                        }
                                        {optionz.userImageURL &&
                                          <Avatar
                                            style={{ cursor: 'pointer' }}
                                            className="avatar_circle color_a"
                                            alt="Lead image"
                                            src={optionz.userImageURL}
                                          />
                                        }
                                      </>
                                    }
                                  </Grid>
                                  <Grid className="pt-5" item md={10}>
                                    {optionz.data && (
                                      <span className="capitalize d-block font-weight-500">{optionz.data.name}</span>
                                    )}
                                    <p style={{ maxWidth: "800px" }} className="font-weight-400 font-size-14 mt--4 text-gray text_ellipsis">
                                      {this.detailsUI(optionz)}
                                    </p>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </MenuItem>
                          ))}
                        </>
                      ))}
                  </>
                  :
                  <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: '10vh', backgroundColor: 'rgba(0,0,0,0)' }}
                  >
                    <h6 className="font-size-22 font-weight-600 mt-20 text-center text-hint">No Results Found</h6>
                    <span className="mb-20 text-center">
                      Try narrowing your search by adding words like "People:", "Opportunities:", or "Lead:" before your search.
                      </span>

                  </Grid>
            }
          </Card>
        }
      </React.Fragment >
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
});
const mapDispatchToProps = dispatch => {
  return {
    masterSearch: (client, req) => dispatch(actionCreators.masterSearch(client, req)),
  }
}
export default (connect(mapStateToProps, mapDispatchToProps)(withRouter(TopSearchBox)));