import React, { Component } from "react";
import TableComponent from './TableComponent';

import { Icon, Switch, FormControlLabel, IconButton, withStyles, Button, Dialog,DialogActions, DialogTitle } from "@material-ui/core";
import { Link } from "react-router-dom";
import FontAwesome from 'react-fontawesome'
import CreateTemplate from "./CreateTemplate";
import EditTemplate from "./EditTemplate";
import * as actions from '../../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';


const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.5rem",
    }
}))(Icon);

class SharedTemplates extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activedatas: [],
            shouldOpenCreateTemplateDialog: false,
            shouldOpenEditTemplateDialog: false,
            FullCreateTemplateDialog: false,
            minimizeCreateTemplateDialog: false,
            isDeleteConfirmDialogOpen: false,
            deleteId: "",
        };
    }



    componentDidMount() {
        this.props.getEmailTemplateList(this.props.apolloClient.client);
        // this.props.customFieldTypeList(this.props.apolloClient.client);
    }

    componentWillReceiveProps(props) {
        // console.log(props.settingReducer,'props.settingReducer');
        // return
        if (props.settingReducer.emailtemplatelist.data) {
            const emailtemplatelist = props.settingReducer.emailtemplatelist.data;
            let activedatas = [];
            // console.log(emailtemplatelist,'emailtemplatelist');
            
            emailtemplatelist.map((fieldData, index) => {
                if (fieldData.isshared == true)
                {
                    activedatas.push({
                        key: fieldData.id,
                        name: (
                            <div>
                                <label className="crm-custom-field-label font-weight-500">{fieldData.name}</label>
                            </div>
                        ),
                        action: <React.Fragment>
                            <IconButton onClick={(id) => this.handleEditTemplate(fieldData.id)}><Icon>edit</Icon></IconButton>
                            <IconButton onClick={(id) => this.handleDeleteTemplate(fieldData.id)}><Icon>delete</Icon></IconButton>
                        </React.Fragment>
                    })
                }
            })

            // if (this.state.updatelistorder == true)
                this.setState({ activedatas: activedatas }, () => this.forceUpdate());
        }
    }

    closeEditPopUp = () => {
        this.setState({ shouldOpenEditTemplateDialog: false });
    }
    closePopUp = () => {
        this.setState({ shouldOpenCreateTemplateDialog: false });
    }
    handleEditTemplate = (id) => {
        let editTemplateParams = { templateid: parseFloat(id) };
        this.props.getEmailTemplateDetail(this.props.apolloClient.client, editTemplateParams);
        this.setState({ shouldOpenEditTemplateDialog:true})
    }
    handleDeleteTemplate = (id) => {
        this.toggleDeleteDialog()
        this.setState({ deleteId: id })
    }

    toggleDeleteDialog = () => {
        this.setState({ isDeleteConfirmDialogOpen: !this.state.isDeleteConfirmDialogOpen });
    };

    confirmDeleteCustomField = () => {
        let deleteTemplateParams = { templateid: parseFloat(this.state.deleteId) };
        
        this.props.deleteEmailTemplate(this.props.apolloClient.client, deleteTemplateParams);
        this.setState({
            deleteId : ""
        });
        this.toggleDeleteDialog()
    };


    handleCreateTemplateDialogClose = () => {
        this.setState({ shouldOpenCreateTemplateDialog: false, FullCreateTemplateDialog: false, minimizeCreateTemplateDialog: false });
    };

    handleCreateTemplateDialogMinimize = () => {
        this.setState(oldStateminimizeCreateTemplateDialog => ({ minimizeCreateTemplateDialog: !oldStateminimizeCreateTemplateDialog.minimizeCreateTemplateDialog }));
        this.setState({ FullCreateTemplateDialog: false })
    };
    handleCreateTemplateDialogFull = () => {
        this.setState(oldStateFullCreateTemplateDialog => ({ FullCreateTemplateDialog: !oldStateFullCreateTemplateDialog.FullCreateTemplateDialog }));
        this.setState({ minimizeCreateTemplateDialog: false })
    };

    handleEditTemplateDialogClose = () => {
        this.setState({ shouldOpenEditTemplateDialog: false, FullEditTemplateDialog: false, minimizeEditTemplateDialog: false });
    };

    handleEditTemplateDialogMinimize = () => {
        this.setState(oldStateminimizeEditTemplateDialog => ({ minimizeEditTemplateDialog: !oldStateminimizeEditTemplateDialog.minimizeEditTemplateDialog }));
        this.setState({ FullCreateTemplateDialog: false })
    };
    handleEditTemplateDialogFull = () => {
        this.setState(oldStateFullEditTemplateDialog => ({ FullEditTemplateDialog: !oldStateFullEditTemplateDialog.FullEditTemplateDialog }));
        this.setState({ minimizeEditTemplateDialog: false })
    };
    render() {
        let { shouldOpenCreateTemplateDialog, isDeleteConfirmDialogOpen, shouldOpenEditTemplateDialog } = this.state;
        return (
            <>
                <div className="d-flex justify-content-end mb-16">
                    <Button variant="contained" color="primary" className="btn_Primary" onClick={() => this.setState({ shouldOpenCreateTemplateDialog: true })} size="small">Add Template</Button>
                    {shouldOpenCreateTemplateDialog &&
                    (   
                        <CreateTemplate
                            handleClose={this.handleCreateTemplateDialogClose}
                            open={shouldOpenCreateTemplateDialog}
                            handleMinimize={this.handleCreateTemplateDialogMinimize}
                            handleFull={this.handleCreateTemplateDialogFull}
                            minimizeScreen={this.state.minimizeCreateTemplateDialog}
                            fullScreen={this.state.FullCreateTemplateDialog}
                            states={this.state}
                            isshared={true}
                        />
                    )}
                    {shouldOpenEditTemplateDialog &&
                    (   
                        <EditTemplate
                            handleClose={this.handleEditTemplateDialogClose}
                            open={shouldOpenEditTemplateDialog}
                            handleMinimize={this.handleEditTemplateDialogMinimize}
                            handleFull={this.handleEditTemplateDialogFull}
                            minimizeScreen={this.state.minimizeEditTemplateDialog}
                            fullScreen={this.state.FullEditTemplateDialog}
                            states={this.state}
                            isshared={true}
                        />
                    )}
                    {isDeleteConfirmDialogOpen && 
                        <Dialog
                            fullWidth={true}
                            maxWidth={'xs'}
                            open={this.state.isDeleteConfirmDialogOpen}
                            onClose={this.toggleDeleteDialog}
                            disableBackdropClick
                            // disableEscapeKeyDown
                            aria-labelledby="ar-remove-dialog-title"
                        >
                            <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to remove selected email template ?"}</DialogTitle>
                            <DialogActions>
                                <Button onClick={this.toggleDeleteDialog} color="primary">
                                    Cancel
                                </Button>
                                <Button type="Button" onClick={() => this.confirmDeleteCustomField()} className="btn_Primary">
                                    Ok
                                </Button>
                            </DialogActions>
                        </Dialog>
                    }
                </div>
                <TableComponent activedata={this.state.activedatas} allData={this} />
            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        settingReducer: state.settingReducer,
        apolloClient: state.apolloClient
    };
};
const mapDispatchToProps = dispatch => {
    return {

        getEmailTemplateList: (client, request) => { dispatch(actions.getEmailTemplateList(client, request)) },
        customFieldList: (client, request) => { dispatch(actions.customFieldList(client, request)) },
        customFieldTypeList: (client) => { dispatch(actions.customFieldTypeList(client)) },
        updateRelatedStatus: (client, request) => { dispatch(actions.updateRelatedStatus(client, request)) },
        deleteEmailTemplate: (client, request) => { dispatch(actions.deleteEmailTemplate(client, request)) },
        getEmailTemplateDetail: (client, request) => { dispatch(actions.getEmailTemplateDetail(client, request)) },
        
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(SharedTemplates);
