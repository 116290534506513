import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import * as actionCreators from '../store/actions/index';
import * as Constant from "./Constant";
const jwt = require('jsonwebtoken');

class AddUserSession extends Component {

    componentWillMount() {
        if (this.props.match.params.data) {
            let data = JSON.parse(this.props.match.params.data)
            let jwtData = jwt.verify(data.token, Constant.SESSION_KEY, function (err, decoded) { return decoded })
            let req = { email: jwtData.email, token: data.token }
            console.log("CheckLoginToken,", req)
            this.props.CheckLoginToken(req)
        }
    }
    componentWillReceiveProps(props) {
        if (props.notification && props.notification.status === true) {
            this.props.clearNotification();
        }
        if (props.settingReducer.userListById.data) {
            if (props.settingReducer.userListById.data) {
                let data = props.settingReducer.userListById.data;
                if (data.sessiontoken) {
                    localStorage.setItem("jwt_token", data.sessiontoken);
                    localStorage.setItem("user_data_session", JSON.stringify(data));
                    if (data.googlestatus) {
                        localStorage.setItem("email_pref." + data.email, 1)
                    }
                    if (this.props.match.params.data) {
                        let data = this.props.match.params.data
                        let mainData = JSON.parse(data)
                        let urlRedirect = "/";
                        if (mainData.detailsUrl) {
                            let urlData = mainData.detailsUrl
                            if (urlData.source_type === 1) {
                                urlRedirect = "/lead/lead/" + urlData.id;
                            } else if (urlData.source_type === 2) {
                                urlRedirect = "/contact/contact/" + urlData.id;
                            } else if (urlData.source_type === 3) {
                                urlRedirect = "/company/company/" + urlData.id;
                            } else if (urlData.source_type === 4) {
                                urlRedirect = "/opportunities/opportunities/" + urlData.id;
                            } else if (urlData.source_type === 5) {
                                urlRedirect = "/project/projects/" + urlData.id;
                            } else if (urlData.source_type === 6) {
                                urlRedirect = "/task/task-list";
                            }
                        } else if (mainData.searchUrl) {
                            urlRedirect = `/search-results/${mainData.searchUrl.searchtext}`
                        }
                        window.location = urlRedirect;
                    }
                }
            }
        }
    }
    render() {
        return (
            <React.Fragment></React.Fragment>
        );
    }
}
const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
});
const mapDispatchToProps = dispatch => {
    return {
        CheckLoginToken: (req) => dispatch(actionCreators.CheckLoginToken(req)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddUserSession));
