import React, { Component } from "react";
import { LinearProgress, Grid, FormControl, Select, IconButton, Icon, MenuItem, withStyles, Fab, Button, DialogContent, Dialog, DialogActions, DialogTitle, Avatar, Card, CircularProgress } from "@material-ui/core";

import { connect } from "react-redux";
import { compose } from 'redux';
import * as actionCreators from '../../../store/actions/index';
import moment from "moment";


class GoalsReportDatas extends Component {
  constructor(props) {
    super(props)
    this.state = {
      date: moment().startOf('month'),
      monthList: [],
      selecteddatetype: 1,
      reportData: [],
      goaltype: this.props.type,
      datetypesArray: ['months', 'quarters', 'years'],
      reportDataSales: [],
      reportDataWon: [],
      reportDataActivity: [],
      userreport: {},
      isDataloding: true
    };
  }

  componentDidMount = () => {
    var j = 0;
    let date = moment().startOf('month').subtract(12, "months");
    let monthList = [];

    for (j = 0; j < 24; j++) {
      monthList.push({ start: moment(date).add(j, "months"), end: moment(date).add(j + 1, "months") })
    }
    this.setState({ monthList })
    this.getGoalReport();


  }

  componentWillReceiveProps(props) {
    if (props.type) {
      this.setState({ goaltype: props.type })
    }

    if (props.settingReducer.get_goal_report_data && props.settingReducer.get_goal_report_data.data) {
      let reportDataSales = this.state.reportDataSales;
      let reportDataWon = this.state.reportDataWon;
      let reportDataActivity = this.state.reportDataActivity;

      let goalreportData = props.settingReducer.get_goal_report_data.data.data;

      this.setState({
        userreport: goalreportData.userreport,
        isDataloding: false
      })
      if (goalreportData.sales && goalreportData.sales.length) {
        reportDataSales = goalreportData.sales;
        this.setState({ reportDataSales })
      }

      if (goalreportData.won && goalreportData.won.length) {
        reportDataWon = goalreportData.won;
        this.setState({ reportDataWon })
      }

      if (goalreportData.activity && goalreportData.activity.length) {
        reportDataActivity = goalreportData.activity;
        this.setState({ reportDataActivity })
      }
    } else {
      setTimeout(() => {
        this.setState({
          isDataloding: false
        })
      }, 3000);
    }
  }

  getGoalReport = () => {
    let goalParams = { datetype: parseInt(this.state.selecteddatetype), goaltype: parseInt(this.state.goaltype) };
    this.props.goalReport(this.props.apolloClient.client, goalParams);
  }

  nextMonth = () => {
    let date = moment(this.state.date).add("1", "month")

    if (date < this.state.monthList[this.state.monthList.length - 1].start) {
      this.setState({ date })
    }
  }

  prevMonth = () => {

    let date = moment(this.state.date).subtract("1", "month");

    if (date > this.state.monthList[0].start) {
      this.setState({ date })
    }
  }

  nextQuarter = () => {
    let date = moment(this.state.date).add("1", "quarters")

    if (date < this.state.monthList[this.state.monthList.length - 1].start) {
      this.setState({ date })
    }
  }

  prevQuarter = () => {

    let date = moment(this.state.date).subtract("1", "quarters");

    if (date > this.state.monthList[0].start) {
      this.setState({ date })
    }
  }

  nextYear = () => {
    let date = moment(this.state.date).add("1", "year");

    if (date < this.state.monthList[this.state.monthList.length - 1].start) {
      this.setState({ date })
    }

    // let date = moment(this.state.date).add("1", "year")
    // this.setState({ date })
  }
  prevYear = () => {
    let date = moment(this.state.date).subtract("1", "year")
    // this.setState({ date })
    if (date > this.state.monthList[0].start) {
      this.setState({ date })
    }
  }



  handleDatetypeChange = (event) => {
    if (parseInt(event.target.value) == 1) {
      var j = 0;
      let date = moment().startOf('month').subtract(12, "months");
      let monthList = [];

      for (j = 0; j < 24; j++) {
        monthList.push({ start: moment(date).add(j, "months"), end: moment(date).add(j + 1, "months") })
      }
      this.setState({ monthList, date: moment().startOf('month') })

      this.setState({ selecteddatetype: event.target.value }, () =>
        setTimeout(() => {
          this.getGoalReport();
        }, 1000));
    }

    if (parseInt(event.target.value) == 2) {
      var j = 0;
      let date = moment().startOf('quarters').subtract(12, "quarters");
      let quarterList = [];

      for (j = 0; j < 24; j++) {
        quarterList.push({ start: moment(date).add(j, "quarters"), end: moment(date).add(j + 1, "quarters") })
      }

      this.setState({ monthList: quarterList, date: moment().startOf('quarters') })

      this.setState({ selecteddatetype: event.target.value }, () =>
        setTimeout(() => {
          this.getGoalReport();
        }, 1000));
    }

    if (parseInt(event.target.value) == 3) {

      var j = 0;
      let date = moment().startOf('years').subtract(5, "years");
      let yearList = [];

      for (j = 0; j < 24; j++) {
        yearList.push({ start: moment(date).add(j, "years"), end: moment(date).add(j + 1, "years") })
      }

      this.setState({ monthList: yearList, date: moment().startOf('years') })
      this.setState({ selecteddatetype: event.target.value }, () =>
        setTimeout(() => {
          this.getGoalReport();
        }, 1000));
    }
    this.setState({
      isDataloding: true
    })
  }

  handleDateChange = () => event => {
    this.setState({ date: moment(event.target.value, "YYYY-MM-DD") })
  }

  render() {
    let opt = [
      { id: 1, name: "Monthly", value: 1 },
      { id: 2, name: "Quarterly", value: 2 },
      { id: 3, name: "Yearly", value: 3 },
    ]
    let { reportDataSales, userreport, reportDataWon, reportDataActivity, date, datetypesArray, selecteddatetype } = this.state;
    let reporttype = this.props.type == 1 ? 'sales' : this.props.type == 2 ? "won" : "activity";

    // let startDate = moment(date).startOf(datetypesArray[selecteddatetype - 1]).format('YYYY-MM-DD');
    // let endDate = moment(date).endOf(datetypesArray[selecteddatetype - 1]).format('YYYY-MM-DD');
    // console.log(reportDataSales, moment(date).startOf("quarters").format('YYYY-MM-DD'), moment(date).endOf("quarters").format('YYYY-MM-DD'));

    return (
      <>

        {(this.props.type == 1 && reportDataSales && reportDataSales.length > 0) &&
          <Card elevation={3} className="p-16 m-10 h-100">
            <Grid container justify="space-between" className="bg-default">
              <Grid item md={2} className="mt-10">
                <FormControl className="w-100 input_bx">
                  <Select
                    className="pt-5"
                    name="selecteddatetype"
                    value={this.state.selecteddatetype}
                    onChange={(event) => this.handleDatetypeChange(event)}
                    MenuProps={{ className: 'choose_field_menu' }}
                  >
                    {opt.map((t, i) => (
                      userreport.sales && userreport.sales.includes(t.id) &&
                      < MenuItem className="font-weight-500" value={t.value} >{t.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} className="m-20">
                <label className="pt-5">
                  {
                    this.props.type == 1 ?
                      "Sales Revenue Goal"
                      : this.props.type == 2 ?
                        "Won Opportunities Goal"
                        :
                        "Activities Goal"
                  }
                </label>
              </Grid>
              <Grid item md={3} className="m-10">
                {
                  (selecteddatetype == 1) ?
                    <div color="primary" fontSizeSmall >
                      <Icon className="text-middle" onClick={this.prevMonth} color="primary" fontSizeSmall>keyboard_arrow_left</Icon>

                      <Select displayEmpty
                        value={this.state.date.format("YYYY-MM-DD")}
                        onChange={this.handleDateChange()}
                        inputProps={{ className: "text-primary font-weight-500" }}
                        MenuProps={{ className: 'choose_field_menu' }}
                        className="text-middle"
                      >
                        {this.state.monthList.map(val => {
                          return <MenuItem value={val.start.format("YYYY-MM-DD")} className="select-menu-item">{val.start.format("MMMM YYYY")}</MenuItem>
                        })}
                      </Select>
                      <Icon className="text-middle" onClick={this.nextMonth} color="primary" fontSizeSmall >keyboard_arrow_right</Icon>
                    </div>
                    : (selecteddatetype == 2) ?
                      <div color="primary" fontSizeSmall >
                        <Icon className="text-middle" onClick={this.prevQuarter} color="primary" fontSizeSmall>keyboard_arrow_left</Icon>
                        <Select
                          displayEmpty
                          value={this.state.date.format("YYYY-MM-DD")}
                          onChange={this.handleDateChange()}
                          inputProps={{ className: "text-primary font-weight-500" }}
                          MenuProps={{ className: 'choose_field_menu' }}
                          className="text-middle"
                        >
                          {this.state.monthList.map(val => {
                            return <MenuItem value={val.start.format("YYYY-MM-DD")} className="select-menu-item ">{val.start.format("MMM YY")} - {val.end.format("MMM YYYY")}</MenuItem>
                          })}
                        </Select>
                        <Icon className="text-middle" onClick={this.nextQuarter} color="primary" fontSizeSmall >keyboard_arrow_right</Icon>
                      </div>
                      :
                      <div color="primary" fontSizeSmall >
                        <Icon className="text-middle" onClick={this.prevYear} color="primary" fontSizeSmall>keyboard_arrow_left</Icon>
                        <Select displayEmpty
                          value={this.state.date.format("YYYY-MM-DD")}
                          onChange={this.handleDateChange()}
                          inputProps={{ className: "text-primary font-weight-500" }}
                          MenuProps={{ className: 'choose_field_menu' }}
                          className="text-middle"
                        >
                          {this.state.monthList.map(val => {
                            return <MenuItem value={val.start.format("YYYY-MM-DD")} className="select-menu-item">{val.start.format("YYYY")}</MenuItem>
                          })}
                        </Select>
                        <Icon className="text-middle" onClick={this.nextYear} color="primary" fontSizeSmall >keyboard_arrow_right</Icon>
                      </div>
                }
              </Grid>
              {this.state.isDataloding &&
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justify="center"                    >
                  <Grid item xs={3}>
                    <CircularProgress disableShrink size={25} />
                  </Grid>
                </Grid>
              }
              {reportDataSales && reportDataSales.map((report) => (
                <>
                  {!this.state.isDataloding &&
                    <Grid item md={12} className="m-10">
                      <label className="font-weight-600">{report.pipline.name}</label>
                    </Grid>
                  }
                  {report.goaldata && report.goaldata.map((goal) => (
                    (goal.startdate == moment(date).startOf(datetypesArray[selecteddatetype - 1]).format('YYYY-MM-DD') && goal.enddate == moment(date).endOf(datetypesArray[selecteddatetype - 1]).format('YYYY-MM-DD')) &&
                    <>
                      <Grid item md={6} className="m-10">
                        <Grid container className="bg-default">
                          <Grid item md={2} className="m-10">
                            <div className="font-size-13">Goal</div>
                            <div className="font-weight-600">{this.props.type == 1 && "$"}{goal.goalval.toFixed(2)}</div>
                          </Grid>
                          <Grid item md={2} className="m-10">
                            <div className="font-size-13">Actual</div>
                            <div className="font-weight-600">{this.props.type == 1 && "$"}{goal.actual.toFixed(2)}</div>
                          </Grid>
                          <Grid item md={2} className="m-10">
                            <div className="font-size-13">Attainment</div>
                            <div className="font-weight-600">{goal.attainment.toFixed(2) + "%"}</div>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item md={12} className="m-10">
                        <LinearProgress variant="determinate" value={(goal.attainment > 100) ? 100 : goal.attainment.toFixed(2)} color="primary" />
                      </Grid>
                    </>
                  ))}
                </>
              ))
              }
            </Grid>
          </Card>
        }
        {
          (this.props.type == 2 && reportDataWon && reportDataWon.length > 0) &&
          <Card elevation={3} className="p-16 m-10 h-100">
            <Grid container justify="space-between" className="bg-default">
              <Grid item md={2} className="mt-10">
                <FormControl className="w-100 input_bx">
                  <Select
                    className="pt-5"
                    name="selecteddatetype"
                    value={this.state.selecteddatetype}
                    onChange={(event) => this.handleDatetypeChange(event)}
                    MenuProps={{ className: 'choose_field_menu' }}
                  >
                    {opt.map((t, i) => (
                      userreport.won && userreport.won.includes(t.id) &&
                      < MenuItem className="font-weight-500" value={t.value} >{t.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} className="m-20">
                <label className="pt-5">
                  {
                    this.props.type == 1 ?
                      "Sales Revenue Goal"
                      : this.props.type == 2 ?
                        "Won Opportunities Goal"
                        :
                        "Activities Goal"
                  }
                </label>
              </Grid>
              <Grid item md={3} className="m-10">
                {
                  (selecteddatetype == 1) ?
                    <div color="primary" fontSizeSmall >
                      <Icon className="text-middle" onClick={this.prevMonth} color="primary" fontSizeSmall>keyboard_arrow_left</Icon>

                      <Select displayEmpty
                        value={this.state.date.format("YYYY-MM-DD")}
                        onChange={this.handleDateChange()}
                        inputProps={{ className: "text-primary font-weight-500" }}
                        MenuProps={{ className: 'choose_field_menu' }}
                        className="text-middle"
                      >
                        {this.state.monthList.map(val => {
                          return <MenuItem value={val.start.format("YYYY-MM-DD")} className="select-menu-item">{val.start.format("MMMM YYYY")}</MenuItem>
                        })}
                      </Select>
                      <Icon className="text-middle" onClick={this.nextMonth} color="primary" fontSizeSmall >keyboard_arrow_right</Icon>
                    </div>
                    : (selecteddatetype == 2) ?
                      <div color="primary" fontSizeSmall >
                        <Icon className="text-middle" onClick={this.prevQuarter} color="primary" fontSizeSmall>keyboard_arrow_left</Icon>
                        <Select
                          displayEmpty
                          value={this.state.date.format("YYYY-MM-DD")}
                          onChange={this.handleDateChange()}
                          inputProps={{ className: "text-primary font-weight-500" }}
                          MenuProps={{ className: 'choose_field_menu' }}
                          className="text-middle"
                        >
                          {this.state.monthList.map(val => {
                            return <MenuItem value={val.start.format("YYYY-MM-DD")} className="select-menu-item ">{val.start.format("MMM YY")} - {val.end.format("MMM YYYY")}</MenuItem>
                          })}
                        </Select>
                        <Icon className="text-middle" onClick={this.nextQuarter} color="primary" fontSizeSmall >keyboard_arrow_right</Icon>
                      </div>
                      :
                      <div color="primary" fontSizeSmall >
                        <Icon className="text-middle" onClick={this.prevYear} color="primary" fontSizeSmall>keyboard_arrow_left</Icon>
                        <Select displayEmpty
                          value={this.state.date.format("YYYY-MM-DD")}
                          onChange={this.handleDateChange()}
                          inputProps={{ className: "text-primary font-weight-500" }}
                          MenuProps={{ className: 'choose_field_menu' }}
                          className="text-middle"
                        >
                          {this.state.monthList.map(val => {
                            return <MenuItem value={val.start.format("YYYY-MM-DD")} className="select-menu-item">{val.start.format("YYYY")}</MenuItem>
                          })}
                        </Select>
                        <Icon className="text-middle" onClick={this.nextYear} color="primary" fontSizeSmall >keyboard_arrow_right</Icon>
                      </div>
                }
              </Grid>
              {/* <Grid item md={3} className="m-10">
                <div color="primary" fontSizeSmall >
                  <Icon className="text-middle" onClick={this.prevMonth} color="primary" fontSizeSmall>keyboard_arrow_left</Icon>

                  <Select displayEmpty
                    value={this.state.date.format("YYYY-MM-DD")}
                    onChange={this.handleDateChange()}
                    inputProps={{ className: "text-primary font-weight-500" }}
                    MenuProps={{ className: 'choose_field_menu' }}
                    className="text-middle"
                  >
                    {this.state.monthList.map(val => {
                      return <MenuItem value={val.start.format("YYYY-MM-DD")} className="select-menu-item">{val.start.format("MMMM YYYY")}</MenuItem>
                    })}
                  </Select>
                  <Icon className="text-middle" onClick={this.nextMonth} color="primary" fontSizeSmall >keyboard_arrow_right</Icon>
                </div>
              </Grid> */}
              {this.state.isDataloding &&
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justify="center"                    >
                  <Grid item xs={3}>
                    <CircularProgress disableShrink size={25} />
                  </Grid>
                </Grid>
              }
              {reportDataWon && reportDataWon.map((report) => (
                <>
                  {!this.state.isDataloding &&
                    <Grid item md={12} className="m-10">
                      <label className="font-weight-600">{report.pipline.name}</label>
                    </Grid>
                  }
                  {report.goaldata && report.goaldata.map((goal) => (
                    (goal.startdate == moment(date).startOf(datetypesArray[selecteddatetype - 1]).format('YYYY-MM-DD') && goal.enddate == moment(date).endOf(datetypesArray[selecteddatetype - 1]).format('YYYY-MM-DD')) &&
                    <>
                      <Grid item md={6} className="m-10">
                        <Grid container className="bg-default">
                          <Grid item md={2} className="m-10">
                            <div className="font-size-13">Goal</div>
                            <div className="font-weight-600">{this.props.type == 1 && "$"}{goal.goalval.toFixed(2)}</div>
                          </Grid>
                          <Grid item md={2} className="m-10">
                            <div className="font-size-13">Actual</div>
                            <div className="font-weight-600">{this.props.type == 1 && "$"}{goal.actual.toFixed(2)}</div>
                          </Grid>
                          <Grid item md={2} className="m-10">
                            <div className="font-size-13">Attainment</div>
                            <div className="font-weight-600">{goal.attainment.toFixed(2) + "%"}</div>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item md={12} className="m-10">
                        <LinearProgress variant="determinate" value={(goal.attainment > 100) ? 100 : goal.attainment.toFixed(2)} color="primary" />
                      </Grid>
                    </>
                  ))}


                </>
              ))
              }
            </Grid>
          </Card>
        }
        {
          (this.props.type == 3 && reportDataActivity && reportDataActivity.length > 0) &&
          <Card elevation={3} className="p-16 m-10 h-100">
            <Grid container justify="space-between" className="bg-default">
              <Grid item md={2} className="mt-10">
                <FormControl className="w-100 input_bx">
                  <Select
                    className="pt-5"
                    name="selecteddatetype"
                    value={this.state.selecteddatetype}
                    onChange={(event) => this.handleDatetypeChange(event)}
                    MenuProps={{ className: 'choose_field_menu' }}
                  >
                    {opt.map((t, i) => (
                      userreport.activity && userreport.activity.includes(t.id) &&
                      < MenuItem className="font-weight-500" value={t.value} >{t.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} className="m-20">
                <label className="pt-5">
                  {
                    this.props.type == 1 ?
                      "Sales Revenue Goal"
                      : this.props.type == 2 ?
                        "Won Opportunities Goal"
                        :
                        "Activities Goal"
                  }
                </label>
              </Grid>
              {/* <Grid item md={3} className="m-10">
                <div color="primary" fontSizeSmall >
                  <Icon className="text-middle" onClick={this.prevMonth} color="primary" fontSizeSmall>keyboard_arrow_left</Icon>

                  <Select displayEmpty
                    value={this.state.date.format("YYYY-MM-DD")}
                    onChange={this.handleDateChange()}
                    inputProps={{ className: "text-primary font-weight-500" }}
                    MenuProps={{ className: 'choose_field_menu' }}
                    className="text-middle"
                  >
                    {this.state.monthList.map(val => {
                      return <MenuItem value={val.start.format("YYYY-MM-DD")} className="select-menu-item">{val.start.format("MMMM YYYY")}</MenuItem>
                    })}
                  </Select>
                  <Icon className="text-middle" onClick={this.nextMonth} color="primary" fontSizeSmall >keyboard_arrow_right</Icon>
                </div>
              </Grid> */}
              {this.state.isDataloding &&
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justify="center"                    >
                  <Grid item xs={3}>
                    <CircularProgress disableShrink size={25} />
                  </Grid>
                </Grid>
              }
              <Grid item md={3} className="m-10">
                {
                  (selecteddatetype == 1) ?
                    <div color="primary" fontSizeSmall >
                      <Icon className="text-middle" onClick={this.prevMonth} color="primary" fontSizeSmall>keyboard_arrow_left</Icon>

                      <Select displayEmpty
                        value={this.state.date.format("YYYY-MM-DD")}
                        onChange={this.handleDateChange()}
                        inputProps={{ className: "text-primary font-weight-500" }}
                        MenuProps={{ className: 'choose_field_menu' }}
                        className="text-middle"
                      >
                        {this.state.monthList.map(val => {
                          return <MenuItem value={val.start.format("YYYY-MM-DD")} className="select-menu-item">{val.start.format("MMMM YYYY")}</MenuItem>
                        })}
                      </Select>
                      <Icon className="text-middle" onClick={this.nextMonth} color="primary" fontSizeSmall >keyboard_arrow_right</Icon>
                    </div>
                    : (selecteddatetype == 2) ?
                      <div color="primary" fontSizeSmall >
                        <Icon className="text-middle" onClick={this.prevQuarter} color="primary" fontSizeSmall>keyboard_arrow_left</Icon>
                        <Select
                          displayEmpty
                          value={this.state.date.format("YYYY-MM-DD")}
                          onChange={this.handleDateChange()}
                          inputProps={{ className: "text-primary font-weight-500" }}
                          MenuProps={{ className: 'choose_field_menu' }}
                          className="text-middle"
                        >
                          {this.state.monthList.map(val => {
                            return <MenuItem value={val.start.format("YYYY-MM-DD")} className="select-menu-item ">{val.start.format("MMM YY")} - {val.end.format("MMM YYYY")}</MenuItem>
                          })}
                        </Select>
                        <Icon className="text-middle" onClick={this.nextQuarter} color="primary" fontSizeSmall >keyboard_arrow_right</Icon>
                      </div>
                      :
                      <div color="primary" fontSizeSmall >
                        <Icon className="text-middle" onClick={this.prevYear} color="primary" fontSizeSmall>keyboard_arrow_left</Icon>
                        <Select displayEmpty
                          value={this.state.date.format("YYYY-MM-DD")}
                          onChange={this.handleDateChange()}
                          inputProps={{ className: "text-primary font-weight-500" }}
                          MenuProps={{ className: 'choose_field_menu' }}
                          className="text-middle"
                        >
                          {this.state.monthList.map(val => {
                            return <MenuItem value={val.start.format("YYYY-MM-DD")} className="select-menu-item">{val.start.format("YYYY")}</MenuItem>
                          })}
                        </Select>
                        <Icon className="text-middle" onClick={this.nextYear} color="primary" fontSizeSmall >keyboard_arrow_right</Icon>
                      </div>
                }
              </Grid>

              {reportDataActivity && reportDataActivity.map((report) => (
                <>
                  <Grid item md={12} className="m-10">
                    <label className="font-weight-600">{report.activity.name}</label>
                  </Grid>
                  {report.goaldata && report.goaldata.map((goal) => (
                    (goal.startdate == moment(date).startOf(datetypesArray[selecteddatetype - 1]).format('YYYY-MM-DD') && goal.enddate == moment(date).endOf(datetypesArray[selecteddatetype - 1]).format('YYYY-MM-DD')) &&
                    <>
                      <Grid item md={6} className="m-10">
                        <Grid container className="bg-default">
                          <Grid item md={2} className="m-10">
                            <div className="font-size-13">Goal</div>
                            <div className="font-weight-600">{this.props.type == 1 && "$"}{goal.goalval.toFixed(2)}</div>
                          </Grid>
                          <Grid item md={2} className="m-10">
                            <div className="font-size-13">Actual</div>
                            <div className="font-weight-600">{this.props.type == 1 && "$"}{goal.actual.toFixed(2)}</div>
                          </Grid>
                          <Grid item md={2} className="m-10">
                            <div className="font-size-13">Attainment</div>
                            <div className="font-weight-600">{goal.attainment && goal.attainment.toFixed(2) + "%"}</div>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item md={12} className="m-10">
                        <LinearProgress variant="determinate" value={(goal.attainment > 100) ? 100 : goal.attainment && goal.attainment.toFixed(2)} color="primary" />
                      </Grid>
                    </>
                  ))}


                </>
              ))
              }
            </Grid>
          </Card>
        }
      </>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
});

const mapDispatchToProps = dispatch => {
  return {
    goalReport: (client, request) => dispatch(actionCreators.goalReport(client, request)),

  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(GoalsReportDatas);