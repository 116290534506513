import React, { Component } from "react";
import { withStyles, Dialog, IconButton, Button, Icon, Tooltip, DialogActions, Select, MenuItem, Grid, FormControl, InputLabel, FormControlLabel, Checkbox, Paper } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import ReactDragListView from "react-drag-listview";
import { Link } from "react-router-dom";
import FontAwesome from 'react-fontawesome'

import * as actions from '../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';
import SearchDropdown from "./SearchDropdown";

const TooltipNew = withStyles((theme) => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);
class CreateNewFieldProjectDialog extends Component {
  state = {
    selectType: '1',
    search: '',
    labelname: '',
    fieldkey: '',
    filtersCheckbox: false,
    contactCheckbox: false,
    companiesCheckbox: false,
    opportunitiesCheckbox: false,
    currency: '',
    data: [{
      title: "Option 1"
    }],
    currencyList: [],
    customfieldtype:[],
    selectedDefaultCurrency:''
  };

  componentDidMount(){
    this.props.loadCurrencyList(this.props.apolloClient.client);
    this.props.customFieldTypeList(this.props.apolloClient.client);
  }
  handleChange = event => {
    event.persist();
    if (event.target.type === 'checkbox') {
      this.setState({ [event.target.name]: event.target.checked });
    }
    else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  componentWillReceiveProps(props){
    
    if (props.settingReducer.currencyList.data) {
      var dataList = props.settingReducer.currencyList.data
      //getting default option key to set in dropdown selected value for default lead status
      var key = this.findWithAttr(dataList, 'is_default', true);
      
      let currency = dataList[key].id;
     
      this.setState({ currencyList: dataList, selectedDefaultCurrency: key, currency: currency});
    }
    if (props.settingReducer.customFieldTypeList.data)
    {
      props.settingReducer.customFieldTypeList.data.sort(this.sortByProperty("id")); 

      this.setState({ customfieldtype: props.settingReducer.customFieldTypeList.data });
    }
  }
  
  sortByProperty = (property) => {
    return function (a, b) {
      if (a[property] > b[property])
        return 1;
      else if (a[property] < b[property])
        return -1;

      return 0;
    }
  }
  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  handleFormSubmit = () => {
    let options = [];
    let currency = '';

    if (this.state.selectType == 4 || this.state.selectType == 5)
    {
      this.state.data.map((data, index) => {
        options.push(data.title);
      });
    }
    if (this.state.selectType == 10)
    {
      currency = this.state.currency;
    }

    let val = {
      field:{
        fieldtype: parseFloat(this.state.selectType),
        fieldlabel: this.state.labelname,
        fieldkey: this.state.fieldkey,
        inleads: 0,
        inpeople: (this.state.contactCheckbox) ? 1 : 0 ,
        incompany: (this.state.companiesCheckbox) ? 1 : 0 ,
        inopportunities: (this.state.opportunitiesCheckbox) ? 1 : 0,
        inprojects: 1,
        intasks: 0,
        includeinfilter: this.state.filtersCheckbox,
        optionvalue: options,
        currencytype: "" + currency,
        pipelinesid: [0]
      }
    } 

    let data = [];
    data['data'] = val;
    data['typeId'] = {'typeId': 5};
    this.props.addCustomFieldInput(this.props.apolloClient.client, data);
    this.props.allData.closePopUp();
  };
  dropdownHandleChange = (index) => event => {
    var options = this.state.data

    options[index].title = event.target.value;
    this.setState({ data: options })

  };
  handleAddOption = () => {
    var options = this.state.data
    options.push({ title: "Option " + (options.length + 1) })
    this.setState({ data: options })
  }
  deleteOption = (index) => {
    let options = this.state.data.filter((val, ind) => index !== ind);
    this.setState({
      data: options
    })
  }
  handleAZSorting = () => {
    let options = this.state.data;

    options = options.sort(function (a, b) {
      if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
      else if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
      return 0;
    });
    
    this.setState({
      data: options
    })
    
  }
  
  setCurrency = (currency) => {
    this.setState({ currency: currency.id});
  }

  setFieldType = (fieldType) => {
    this.setState({ selectType: fieldType.id });
  }

  render() {
    let { open, handleClose } = this.props;
    let { currencyList, customfieldtype, selectType, search, labelname, filtersCheckbox, contactCheckbox, companiesCheckbox, opportunitiesCheckbox, currency, fieldkey, selectedDefaultCurrency } = this.state;
    
    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const data = that.state.data;
        const item = data.splice(fromIndex, 1)[0];
        data.splice(toIndex, 0, item);
        that.setState({ data });
      },
      nodeSelector: 'li',
      handleSelector: 'a.drag-handle'
    };
    
    return (
      <Dialog className="crm-custom-bg-primary" onClose={handleClose} open={open} maxWidth="sm" fullWidth={true} disableBackdropClick >
        <div className=" d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">
          <h4 className="m-0 text-white">Create New Field for Project</h4>
          <div className="d-flex">
            <TooltipNew title="Close">
              <IconButton onClick={handleClose}>
                <Icon className="text-white">clear</Icon>
              </IconButton>
            </TooltipNew>
          </div>
        </div>
        <ValidatorForm ref="form" instantValidate={false} noValidate onSubmit={this.handleFormSubmit}>
          <div className="dialog-body pt-16">
            <FormControl className="w-100 input_bx mb-16">
              
              <SearchDropdown data={this} dropdownData={customfieldtype} defaultValue={0} dropdownType="type" />
             
            </FormControl>
            <TextValidator
              className="w-100 input_bx mb-16"
              label="Label name"
              onChange={this.handleChange}
              type="text"
              name="labelname"
              value={labelname}
              required={true}
              placeholder="Enter Label name"
              validators={["required"]}
              errorMessages={["This field is required"]}
              InputLabelProps={{shrink: true, disableAnimation: false, focused: true }}
            />
            <FormControl className="w-100 input_bx mb-16">
              <label className="text-lable">Field Key <TooltipNew title="The Field Key is unique to each field and cannot be changed. It is used for integrations and automations"><Icon color="primary" size="small">info</Icon></TooltipNew></label>
              <TextValidator
                className="w-100 input_bx"
                placeholder="Add Field Key"
                onChange={this.handleChange}
                type="text"
                name="fieldkey"
                validators={["required"]}
                value={fieldkey}
                errorMessages={["This field is required"]}
              />
            </FormControl>
            {/* on selection of currency  */}
            {selectType === '10' ?
              (<FormControl className="mb-16 w-100 input_bx">
                <SearchDropdown data={this} dropdownData={currencyList} defaultValue={selectedDefaultCurrency} dropdownType="currency" />
              </FormControl>
              )
              :
              ''
            }
            {/* on selection of dropdown or select  */}
            {selectType === '4' || selectType === '5' ?
              <div className="mb-16">
                <div className="d-flex justify-content-between">
                  <label className="text-lable">Dropdown Options <span className="font-weight-normal">(Must have at least 1 option and no more than 500 options)</span></label>
                  <label className="text-lable">Sort <Link to="#" onClick={this.handleAZSorting} className="text_link">A-Z</Link></label>
                </div>
                <ReactDragListView {...dragProps}>
                  <ul className="dropdown_options">
                    {this.state.data.map((item, index) => (
                      <li key={index}>
                        {this.state.data.length > 1 &&
                          <Link to="#" className="drag-handle"><FontAwesome className="fa fa-arrows"></FontAwesome></Link>
                        }
                        <TextValidator
                          className="w-100 input_bx ml-10 mr-10"
                          onChange={this.dropdownHandleChange(index)}
                          placeholder="Enter option name"
                          type="text"
                          name={'dpOption' + index}
                          value={item.title}
                        />
                        {this.state.data.length > 1 &&
                          <IconButton size="small" onClick={() => this.deleteOption(index)}>
                            <FontAwesome className="fa fa-remove"></FontAwesome>
                          </IconButton>
                        }
                      </li>
                    ))}
                  </ul>
                </ReactDragListView>
                <Button size="small" className="btn_Primary" variant="contained" color="primary" onClick={this.handleAddOption}><Icon>add</Icon> Add Option</Button>
              </div>
              : ''
            }
            {/* on selection of textarea or textbox or url  */}
            {!(selectType === '1' || selectType === '2' || selectType === '9') ?
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filtersCheckbox}
                    name="filtersCheckbox"
                    onChange={this.handleChange}
                    value="filtersCheckbox"
                    color="primary"
                  />
                }
                label="Include in Filters"
                className="mb-16 custom-checkbox"
              />
              : ''
            }
          </div>
          <DialogActions className="dialog-action">
            <Button variant="contained" onClick={handleClose}>Cancel</Button>
            <Button variant="contained" className="btn_Primary" type="submit" color="primary">Save</Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient
  };
};
const mapDispatchToProps = dispatch => {
  return {
    customFieldList: (client, request) => { dispatch(actions.customFieldList(client, request)) },
    addCustomFieldInput: (client, request) => { dispatch(actions.addCustomFieldInput(client, request)) },
    loadCurrencyList: (client) => dispatch(actions.loadCurrencyList(client)),
    customFieldTypeList: (client) => dispatch(actions.customFieldTypeList(client)),
    
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(CreateNewFieldProjectDialog);
