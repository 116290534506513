import * as actionTypes from './actionTypes';
import { gql } from "apollo-boost";
import * as dataSet from './dataSet';
import * as actionCreators from './index';

/**
 * TYPE : GET
 * Getting Contact Type
 */
export const getContactType = (client) => {
    return function (dispatch) {
        const query = gql`
                query getContactType
                {
                    getContactType {
                        id
                        name
                        alias
                        order_no
                        is_allowto_delete
                        createdby
                        createdhost
                        createddate
                        lastmodifiedby
                        lastmodifiedhost
                        lastmodifieddate
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache'
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getContactType);
            dispatch(setResponseDetails(actionTypes.GET_CONTACT_TYPE, return_data));
        }).catch((error) => {
            var returnData = {
                'data': {},
                'error': false,
                'notify_msg': ""
            }
            dispatch(setResponseDetails(actionTypes.GET_CONTACT_TYPE, returnData));
        });
    }
}

export const saveContactType = (client, request) => {
    return dispatch => {
        const mutation = gql`
            mutation saveContactType($contacttypename:String!) {
                saveContactType(contacttypename:$contacttypename) {
                    id
                    name
                    alias
                    order_no
                    is_allowto_delete
                    createdby
                    createdhost
                    createddate
                    lastmodifiedby
                    lastmodifiedhost
                    lastmodifieddate
                }
            }
            `;
        client.mutate({
            mutation: mutation,
            variables: request
        })
            .then(response => {
                let returnData = [];

                if (response.data && response.data != undefined) {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "Contact Type added successfully"
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    dispatch(actionCreators.getContactType(client));
                }

            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const updateContactTypeOrder = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateContactTypeOrder($orderno:[Float!]!, $contacttypeid:[Float!]!) {
                 updateContactTypeOrder(orderno:$orderno,contacttypeid:$contacttypeid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                orderno: request.contactTypeOrder,
                contacttypeid: request.contactTypeIds,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateContactTypeOrder.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateContactTypeOrder.message
                        }
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateContactTypeOrder.message
                        }
                    }
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const updateContactTypeDetail = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateContactTypeDetail($contacttypename: String!,$contacttypeid: Float!) {
                 updateContactTypeDetail(contacttypename:$contacttypename,contacttypeid:$contacttypeid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                contacttypename: request.contacttypename,
                contacttypeid: request.id,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateContactTypeDetail.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateContactTypeDetail.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateContactTypeDetail.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
                dispatch(actionCreators.getContactType(client))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const deleteContactType = (client, request) => {
    return dispatch => {
        const query = gql`
             query deleteContactType($contacttypeid:Float!) {
                 deleteContactType(contacttypeid:$contacttypeid) {
                    status
                    message
                 }
               }
             `;
        client.query({
            query: query,
            variables: {
                contacttypeid: request.contacttypeid,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.deleteContactType.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.deleteContactType.message
                        }
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.deleteContactType.message
                        }
                    }
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
                dispatch(actionCreators.getContactType(client))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}


export const saveContactDetail = (client, request) => {
    if (!request.opportunitiesdata || request.opportunitiesdata == undefined) {
        request.opportunitiesdata = []
    }
    return dispatch => {
        const mutation = gql`
             mutation saveContactDetail($opportunitiesdata:[JSON!]!,$contactdata:[JSON!]!, $leadid:Float!,$teamvisible:Boolean!) {
                 saveContactDetail(opportunitiesdata:$opportunitiesdata,contactdata:$contactdata,leadid:$leadid,teamvisible:$teamvisible) {
                    status
                    message
                    id
                    opportunities
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                opportunitiesdata: request.opportunitiesdata,
                contactdata: request.contactdata,
                leadid: request.leadid,
                teamvisible: request.teamvisible
            }
        })
            .then(response => {
                var opportunitiesdata = [];
                var insertedContactId = ""
                let returnData = [];
                if (response.data && response.data != undefined) {
                    opportunitiesdata = response.data.saveContactDetail.opportunities
                    insertedContactId = response.data.saveContactDetail.id
                    if (parseInt(request.leadid) === 0) {
                        if (response.data.saveContactDetail.status == "true") {
                            returnData = {
                                'data': {},
                                'error': false,
                                'notify_msg': response.data.saveContactDetail.message
                            }
                            dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                            if (request.isFromTopBar && response.data.saveContactDetail.id) {
                                console.log(response.data.saveContactDetail.id);
                                let redirectURL = '/contact/contact/' + response.data.saveContactDetail.id;
                                window.location.href = redirectURL;
                            }
                        } else {
                            returnData = {
                                'data': {},
                                'error': true,
                                'notify_msg': response.data.saveContactDetail.message
                            }
                            dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                        }
                    } else {
                        if (request.opportunitiesdata && request.opportunitiesdata !== [] && request.opportunitiesdata !== null && request.opportunitiesdata.length > 0 )  {
                            if (response.data.saveContactDetail.status == "true") {
                                returnData = {
                                    'data': {},
                                    'error': false,
                                    'notify_msg': "Lead is converted to Contact and Opportunity is created!"
                                }
                                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                            }
                        } else {
                            if (response.data.saveContactDetail.status == "true") {
                                returnData = {
                                    'data': {},
                                    'error': false,
                                    'notify_msg': response.data.saveContactDetail.message
                                }
                                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                            }
                        }
                    }
                }

                dispatch(actionCreators.getContactFilterList(client, request.listParams))

                var checkOpportunityId = localStorage.getItem('opportunitiesid')
                if (checkOpportunityId && insertedContactId) {
                    var addContactForOpportunity = {
                        contactdetail: { srno: "1", id: insertedContactId, value: "" },
                        opportunitiesid: parseInt(checkOpportunityId)
                    }
                    dispatch(actionCreators.addNewOpportunitiesContact(client, addContactForOpportunity))
                    localStorage.removeItem('opportunitiesid');
                }
                if (opportunitiesdata.length > 0) {
                    var pipelineId = 1;
                    if (localStorage.getItem('selected_pipeline_id')) {
                        pipelineId = localStorage.getItem('selected_pipeline_id')
                    } else {
                        pipelineId = 1
                    }
                    var req = {
                        opportunitiesdata: opportunitiesdata,
                        piplineid: parseFloat(pipelineId),
                        leadid: request.leadid,
                        teamvisible: request.teamvisible
                    }
                    dispatch(actionCreators.saveOpportunitiesDetail(client, req))
                }

                if (request.sidebarParams && request.sidebarParams !== "" && Object.keys(request.sidebarParams).length > 0) {
                    if (insertedContactId && request.sidebarParams.recordtype == 'project') {
                        var addContactForProject = {
                            contactdetail: { srno: "1", id: insertedContactId, value: "" },
                            projectid: parseInt(request.sidebarParams.id)
                        }
                        dispatch(actionCreators.addProjectContact(client, addContactForProject))
                    }
                    dispatch(actionCreators.getRecordRelatedData(client, request.sidebarParams))
                }
            }).catch((error) => {
                // let err_data = errorMessageGraphQl(error);
                // dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}



export const updateContactDetail = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateContactDetail($updatedfieldid:Float!,$contactdata:[JSON!]!,$contactid:Float!,$fileInput: Upload!,$teamvisible:Boolean!) {
                 updateContactDetail(updatedfieldid:$updatedfieldid, contactdata:$contactdata,contactid:$contactid,fileInput:$fileInput,teamvisible:$teamvisible) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                contactdata: request.contactdata,
                contactid: request.contactid,
                fileInput: request.contactImage,
                updatedfieldid: request.updatedfieldid,
                teamvisible: request.teamvisible

            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateContactDetail.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateContactDetail.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                        dispatch(actionCreators.getContactLogList(client, request.contactid))
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateContactDetail.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }

                dispatch(actionCreators.getContactField(client, request.contactid))

                let val = {
                    filterdata: [],
                    list: {
                        limit: 10,
                        pageno: 1,
                        searchtext: "",
                        sorttype: "",
                        type: "list",
                        fieldId: 1,
                        columnname: ''
                    },
                    filterid: 1
                }
                dispatch(actionCreators.getContactFilterList(client, val))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}


/**
 * TYPE : GET
 * Getting particular Contact Field data
 */
export const getContactField = (client, contactid) => {
    return function (dispatch) {
        const query = gql`
                query getContactField($contactid: Float!) 
                {
                    getContactField(contactid: $contactid) {
                        id
                        is_default
                        fieldstatus
                        fieldkey
                        fieldtype{
                          id
                          name
                        }
                        fieldicon
                        fieldlabel
                        generatedfieldid
                        contactdetail{
                          id
                          values
                          fieldvalue
                          contact{
                                interactions
                                inactivedays
                                lastcontacted
                                teamvisible
                            }
                        }
                        options
                        leadfavourite
                        image
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                contactid: contactid
            },
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getContactField);
            dispatch(setResponseDetails(actionTypes.CONTACT_FIELD_DATA, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);

            dispatch(setResponseDetails(actionTypes.CONTACT_FIELD_DATA, err_data));
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

/**
 * TYPE : GET
 * Getting contact Filter List
 */
export const getContactFilterList = (client, value) => {
    return function (dispatch) {
        const query = gql`
                query getContactFilterList($filterdata: [JSON!]!,$list: ContactListInput!, $filterid: Float!)                 
                {
                    getContactFilterList(filterdata:$filterdata, list: $list, filterid: $filterid) {
                        totalrecord
                        totalpage
                        data
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: value,
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getContactFilterList);
            dispatch(setResponseDetails(actionTypes.GET_CONTACT_FILTER_LIST, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            var returnData = {
                'data': {},
                'error': false,
                'notify_msg': ""
            }
            dispatch(setResponseDetails(actionTypes.GET_CONTACT_FILTER_LIST, returnData));
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

/**
 * TYPE : GET
 * Delete Contact 
 */
export const deleteContact = (client, request) => {
    return dispatch => {
        const query = gql`
            query deleteContact($contactid: [Float!]!)                 
            {
                deleteContact(contactid:$contactid) {
                    status
                    message
                }
            }
        `;
        client.query({
            query: query,
            variables: request['deleteid'],
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.deleteContact.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.deleteContact.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.deleteContact.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
            dispatch(actionCreators.getContactFilterList(client, request['listval']))
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

/**
 * TYPE : POST
 * Add contact to favorite
 */
export const addFavouriteContact = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation addFavouriteContact($status:Boolean!, $contactid:Float!) {
                 addFavouriteContact(status:$status,contactid:$contactid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: request['favorite']
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.addFavouriteContact.status == "true") {
                        // returnData = {
                        //     'data': {},
                        //     'error': false,
                        //     'notify_msg': response.data.addFavouriteContact.message
                        // }
                        // dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                        dispatch(actionCreators.getContactFilterList(client, request['listval']))
                        dispatch(actionCreators.getContactField(client, request['favorite'].contactid))
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.addFavouriteContact.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }

            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}


export const updateContactListColumnOrder = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateContactListColumnOrder($orderno:[Float!]!, $columnstatus:[Boolean!]!,$fieldid:[Float!]!) {
                 updateContactListColumnOrder(orderno:$orderno,columnstatus:$columnstatus,fieldid:$fieldid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: request
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateContactListColumnOrder.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateContactListColumnOrder.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                        dispatch(actionCreators.getContactListColumnOrder(client))
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateContactListColumnOrder.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

/**
 * TYPE : GET
 * Getting Contact Column Order
 */
export const getContactListColumnOrder = (client) => {

    return function (dispatch) {
        const query = gql`
                query getContactListColumnOrder
                {
                    getContactListColumnOrder {
                        id
                        field{
                            id
                            fieldkey
                            fieldlabel
                        }
                        userid
                        columnid
                        orderno
                        isactive
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getContactListColumnOrder);
            dispatch(setResponseDetails(actionTypes.GET_CONTACT_LIST_ORDER, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            var returnData = {
                'data': {},
                'error': false,
                'notify_msg': ""
            }
            dispatch(setResponseDetails(actionTypes.GET_CONTACT_LIST_ORDER, returnData));
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}


/**
 * [addContactLogComment saves comments of log]
 */
export const addContactLogComment = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation addContactLogComment($logid:Float!, $comment:String!,$type:String!,$mentiondetail:String!,$mentionuser:[Float!],$contactid:Float!) {
                 addContactLogComment(logid:$logid, comment:$comment, type:$type,mentiondetail:$mentiondetail, mentionuser:$mentionuser,contactid:$contactid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                logid: request.logid,
                comment: request.comment,
                type: request.type,
                mentiondetail: request.mentiondetail,
                mentionuser: request.mentionuser, contactid: request.contactid
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.addContactLogComment.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.addContactLogComment.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                        let logParams = { contactid: request.contactid, emailid: request.emailid, activitypeid: request.activitypeid, logtypeid: request.logtypeid }
                        dispatch(actionCreators.getContactActivityLogList(client, logParams))
                        dispatch(actionCreators.getContactLogListNew(client, logParams))
                        dispatch(actionCreators.getContactField(client, request.contactid))
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.addContactLogComment.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}
/**
 * [updateContactLogComment saves comments of log]
 */
export const updateContactLogComment = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateContactLogComment($commentid:Float!, $comment:String!,$type:String!,$mentiondetail:String!,$mentionuser:[Float!]) {
                 updateContactLogComment(commentid:$commentid, comment:$comment, type:$type,mentiondetail:$mentiondetail, mentionuser:$mentionuser) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                commentid: request.commentid,
                comment: request.comment,
                type: request.type,
                mentiondetail: request.mentiondetail,
                mentionuser: request.mentionuser,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateContactLogComment.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateContactLogComment.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));

                        let logParams = { contactid: request.contactid, emailid: request.emailid, activitypeid: request.activitypeid, logtypeid: request.logtypeid }
                        dispatch(actionCreators.getContactActivityLogList(client, logParams))
                        dispatch(actionCreators.getContactLogListNew(client, logParams))
                        dispatch(actionCreators.getContactField(client, request.contactid))
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateContactLogComment.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}
export const deleteContactLogComment = (client, request) => {

    return dispatch => {
        const query = gql`
            query deleteContactLogComment($commentid: Float!, $type: String!)                 
            {
                deleteContactLogComment(commentid:$commentid, type:$type) {
                    status
                    message
                }
            }
        `;
        client.query({
            query: query,
            variables: { commentid: request.commentid, type: request.type },
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.deleteContactLogComment.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.deleteContactLogComment.message
                    }

                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    let logParams = { contactid: request.contactid, emailid: request.emailid, activitypeid: request.activitypeid, logtypeid: request.logtypeid }
                    dispatch(actionCreators.getContactActivityLogList(client, logParams))
                    dispatch(actionCreators.getContactLogListNew(client, logParams))
                    dispatch(actionCreators.getContactField(client, request.contactid))
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.deleteContactLogComment.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}




/**
 * TYPE : GET
 * Getting Contact Log List with filter
 */
export const getContactActivityLogList = (client, request) => {
    return function (dispatch) {
        const query = gql`
                query getContactActivityLogList($contactid: Float!, $emailid: String!, $logtypeid:[Float!], $activitypeid:[Float!], $filterchange: Boolean)                 
                {
                    getContactActivityLogList(contactid: $contactid,emailid:$emailid,logtypeid:$logtypeid,activitypeid:$activitypeid,filterchange:$filterchange) {
                        status
                        message
                        pined
                        today
                        yesterday
                        thisweek
                        lastweek
                        thismonth
                        lastmonth
                        earlier
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: request,
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getContactActivityLogList);
            dispatch(setResponseDetails(actionTypes.GET_CONTACT_SIDEBAR_LOG_LIST, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            var returnData = {
                'data': {},
                'error': false,
                'notify_msg': ""
            }
            dispatch(setResponseDetails(actionTypes.GET_CONTACT_SIDEBAR_LOG_LIST, returnData));
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}
/**
 * TYPE : GET
 * Getting Contact Log List with filter
 */
export const getContactLogListNew = (client, request) => {
    let paramtype = request.logtypeid;
    let pullarr = [1000, 1001];
    pullarr.map((arr) => {
        if (paramtype.indexOf(arr) != -1) {
            let ind = paramtype.indexOf(arr);
            paramtype.splice(ind, 1);
        }
    });

    return function (dispatch) {
        const query = gql`
                query getContactLogListNew($contactid: Float!, $emailid: String!, $logtypeid:[Float!], $activitypeid:[Float!])                 
                {
                    getContactLogListNew(contactid: $contactid,emailid:$emailid,logtypeid:$logtypeid,activitypeid:$activitypeid) {
                        logdata
                        status
                        message
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: { contactid: request.contactid, emailid: request.emailid, logtypeid: paramtype, activitypeid: request.activitypeid },
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getContactLogListNew);
            dispatch(setResponseDetails(actionTypes.GET_CONTACT_LOG_LIST, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            var returnData = {
                'data': {},
                'error': false,
                'notify_msg': ""
            }
            dispatch(setResponseDetails(actionTypes.GET_CONTACT_LOG_LIST, returnData));
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}
/**
 * TYPE : GET
 * Getting Contact Log List
 */
export const getContactLogList = (client, request) => {
    return function (dispatch) {
        const query = gql`
                query getContactLogList($contactid: Float!, $emailid: String!)                 
                {
                    getContactLogList(contactid: $contactid,emailid: $emailid) {
                        logdata
                        status
                        message
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: request,
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getContactLogList);
            dispatch(setResponseDetails(actionTypes.GET_CONTACT_LOG_LIST, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            var returnData = {
                'data': {},
                'error': false,
                'notify_msg': ""
            }
            dispatch(setResponseDetails(actionTypes.GET_CONTACT_LOG_LIST, returnData));
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}


export const saveContactLog = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation saveContactLog($mentiondetail:String!, $mentionuser:[Float!], $propertyname:String!, $propertyid:Float!,$contactid:Float!, $text:String!, $oldvalue:String!, $newvalue:String!, $logtime:Float!, $logdate:Float!, $logtype:Float!, $fieldid:Float!) {
                 saveContactLog(mentiondetail:$mentiondetail, mentionuser:$mentionuser, propertyname:$propertyname,propertyid:$propertyid, contactid:$contactid, text:$text, oldvalue:$oldvalue, newvalue:$newvalue, logtype:$logtype, logtime:$logtime, logdate:$logdate, fieldid:$fieldid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: request['save']
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.saveContactLog.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.saveContactLog.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                        dispatch(actionCreators.getContactActivityLogList(client, request['getlog']))
                        dispatch(actionCreators.getContactLogListNew(client, request['getlog']))
                        dispatch(actionCreators.getContactField(client, request['getlog'].contactid))
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.saveContactLog.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}
export const updateContactLog = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateContactLog($mentiondetail:String!, $mentionuser:[Float!], $text:String!, $logtime:Float!, $logdate:Float!, $logid:Float!) {
                 updateContactLog(mentiondetail:$mentiondetail, mentionuser:$mentionuser, text:$text, logtime:$logtime, logdate:$logdate, logid:$logid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: request['update']
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateContactLog.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateContactLog.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                        dispatch(actionCreators.getContactActivityLogList(client, request['getlog']))
                        dispatch(actionCreators.getContactLogListNew(client, request['getlog']))
                        dispatch(actionCreators.getContactField(client, request['getlog'].contactid))


                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateContactLog.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}
export const deleteContactLogDetail = (client, request) => {

    return dispatch => {
        const query = gql`
            query deleteContactLogDetail($logid: Float!)                 
            {
                deleteContactLogDetail(logid:$logid) {
                    status
                    message
                }
            }
        `;
        client.query({
            query: query,
            variables: { logid: request.logid },
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.deleteContactLogDetail.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.deleteContactLogDetail.message
                    }

                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    let logParams = { contactid: request.contactid, emailid: request.emailid, activitypeid: request.activitypeid, logtypeid: request.logtypeid }
                    dispatch(actionCreators.getContactActivityLogList(client, logParams))
                    dispatch(actionCreators.getContactLogListNew(client, logParams))
                    dispatch(actionCreators.getContactField(client, request.contactid))
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.deleteContactLogDetail.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}




export const getFilterListContact = (client, recordtypeid) => {
    return function (dispatch) {
        const query = gql`
                query getFilterList($recordtypeid: Float!) 
                {
                    getFilterList(recordtypeid: $recordtypeid) {
                        id
                        fitertype
                        userid
                        filtername
                        filterquery
                        filteroption
                        isdefault
                        recordtypeid
                        isselected
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                recordtypeid: recordtypeid
            },
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getFilterList);
            dispatch(setResponseDetails(actionTypes.GET_CONTACTFILTER_LIST, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const saveFilterDetailContact = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation saveFilterDetail($filterdata:[JSON!]!,$fitertype:String!,$filtername: String!,$recordtypeid: Float!) {
                 saveFilterDetail(filterdata:$filterdata,fitertype:$fitertype,filtername:$filtername,recordtypeid:$recordtypeid) {
                    id
                    fitertype
                    userid
                    filtername
                    filterquery
                    filteroption
                    isdefault
                    recordtypeid
                    isselected
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                filterdata: request.filterdata,
                fitertype: request.fitertype,
                filtername: request.filtername,
                recordtypeid: request.recordtypeid,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.saveFilterDetail.id) {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': "Filter saved successfully"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    }
                }
                dispatch(actionCreators.getFilterListContact(client, 2))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const deleteFilterContact = (client, filterid) => {
    return function (dispatch) {
        const query = gql`
                query deleteFilter($filterid: Float!) 
                {
                    deleteFilter(filterid: $filterid) {
                        status
                        message
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                filterid: filterid
            },
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.deleteFilter.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.deleteFilter.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.deleteFilter.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
            dispatch(actionCreators.getFilterListContact(client, 2))
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const updateFilterDetailContact = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateFilterDetail($filterdata: [JSON!]!, $fitertype:String!,$filtername:String!,$filterid:Float!) {
                 updateFilterDetail(filterdata:$filterdata,fitertype:$fitertype,filtername:$filtername,filterid:$filterid) {
                    id
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                filterdata: request.filterdata,
                fitertype: request.fitertype,
                filtername: request.filtername,
                filterid: request.filterid,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateFilterDetail.id) {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': "Filter updated successfully"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': "Filter couldn't get updated"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
                dispatch(actionCreators.getFilterListContact(client, 2))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const updateFilterDefaultContact = (client, filterid) => {
    return dispatch => {
        const mutation = gql`
             mutation updateFilterDefault($type:String!, $fiterid:Float!,$recordtypeid:Float!) {
                 updateFilterDefault(type:$type,fiterid:$fiterid,recordtypeid:$recordtypeid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                type: "isdefault",
                fiterid: filterid,
                recordtypeid: 2
            },
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateFilterDefault.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateFilterDefault.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateFilterDefault.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
                dispatch(actionCreators.getFilterListContact(client, 2))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const addContactLogEmoji = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation addContactLogEmoji($logid:Float!,$emoji:String!, $emojidetail:String!,$logtype:String!) {
                addContactLogEmoji(logid:$logid,emoji:$emoji,emojidetail:$emojidetail,logtype:$logtype) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                logid: request.logid,
                emoji: request.emoji,
                emojidetail: request.emojidetail,
                logtype: request.logtype
            }
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.addContactLogEmoji.status == "true") {
                    let logParams =
                    {
                        contactid: request.contactid, emailid: request.emailid, activitypeid: request.activitypeid, logtypeid: request.logtypeid
                    }
                    dispatch(actionCreators.getContactActivityLogList(client, logParams))
                    dispatch(actionCreators.getContactLogListNew(client, logParams))
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.addContactLogEmoji.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}


const setResponseDetails = (newType, data) => {
    return {
        type: newType,
        val: data
    };
}

const notificationAll = (newType, data, status) => {
    return {
        type: newType,
        val: data,
        status: status,
    }
}

const errorMessageGraphQl = (err) => {
    let msg = 'Something Went Wrong!';
    if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        msg = err.graphQLErrors[0].message;
    } else if (err.networkError && err.networkError.result) {
        msg = err.networkError.result.errors[0].message;
    } else if (err.message) {
        msg = err.message;
    }

    return {
        notify_msg: msg,
        error: true
    }
}

export const clearNotification = (value) => {
    return function (dispatch) {
        dispatch(clearNotificationAll(actionTypes.NOTIFICATIONS, {}, false));
    }
}
const clearNotificationAll = (newType, data, status) => {
    return {
        type: actionTypes.NOTIFICATIONS,
        val: '',
        status: '',
    }
}
