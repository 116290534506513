import React, { Component } from "react";
import { CircularProgress, IconButton, Grid, Card, Icon, withStyles } from "@material-ui/core";
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import { compose } from "redux";
import ListComponent from "../Common/ListComponent";

class ProfileRight extends Component {
  state = {
    attachment: null,
    activedatas: [],
    updatelistorder: true,
    project_id: "",
    isDataLoading: true,
  };

  componentWillMount() {
    let val = { recordtypeid: 5 }
    this.props.getRelatedMasterList(this.props.apolloClient.client, val);
    if (this.props.project_id) {
      this.setState({
        project_id: this.props.project_id,
      }, () => {
        this.forceUpdate()
      });
    }
  }
  componentWillReceiveProps(props) {
    if (props.project_id != this.state.project_id) {
      this.setState({
        project_id: parseInt(props.project_id),
      }, () => {
        this.forceUpdate()
      });
    }
    if (props.settingReducer.projectrelatedMaster.data) {
      const leadrelatedMaster = props.settingReducer.projectrelatedMaster.data;
      let activedatas = [];

      leadrelatedMaster.map((fieldData, index) => {
        if (fieldData.isactive === true) {
          activedatas.push({
            key: fieldData.id,
            alias: fieldData.alias,
            isactive: fieldData.isactive,
            orderid: fieldData.orderno,
          });
        }
      });
      if (this.state.updatelistorder == true)
        this.setState({ activedatas: activedatas, isDataLoading: false }, () => this.forceUpdate());
    }
  }

  render() {
    var flag = 0;
    if (window.location.pathname == "/opportunities/opportunities-card" || window.location.pathname == "/opportunities/opportunities-list") {
      flag = 1
    }
    return (
      <>
        {
          this.state.isDataLoading ?
            <CircularProgress size={25} disableShrink className="loaderPopupCenter" />
            :
            this.state.project_id && <ListComponent recordId={this.state.project_id} recordtype={"project"} activedata={this.state.activedatas} allData={this} />}

      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getRelatedMasterList: (client, request) => {
      dispatch(actions.getRelatedMasterList(client, request));
    },
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ProfileRight
);
