import React, { Component } from "react";
import { Dialog, IconButton, Button, Snackbar, Tooltip, DialogActions, Avatar, Grid, FormControl, Select, CircularProgress, MenuItem, InputLabel, Typography, Card, Fade } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import IntegrationSelect from '../Select';
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import Server from "../../Common/Server"
import Autocomplete from "@material-ui/lab/Autocomplete";

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}
class companyUser extends Component {
    state = {
        uid: '',
        country: "",
        imagePreview: '',
        countryListArray: [],
        isDataLoading: true,
        userData: "",
        disabledAll: true,
    };
    componentWillMount() {
        this.setState({ uid: this.props.match.params.userid })
        this.props.getCountryList(this.props.apolloClient.client);
        this.props.getUserById(({ userid: this.props.match.params.userid, type: "user" }))
    }
    componentWillReceiveProps(props) {
        if (props.notification && props.notification.status === true) {
            this.props.clearNotification();
        }
        if (props.settingReducer.countryListGql.data) {
            const countryies = props.settingReducer.countryListGql.data;
            let countryListArray = countryies.map(val => {
                return { label: val.name, value: val.name }
            })
            this.setState({ countryListArray })
        }
        if (props.settingReducer.userSettingArray &&
            props.settingReducer.userSettingArray.data) {
            let user_datas = props.settingReducer.userSettingArray.data;
            let profile_image = user_datas.profileimage ? user_datas.profileimage : null;
            this.setState({ imagePreview: profile_image })
        }
        if (props.settingReducer.userListById) {
            let userData = props.settingReducer.userListById;
            if (userData.data !== undefined) {
                let sessionData = JSON.parse(localStorage.getItem('user_data_session'))
                let disabledAll= true;
                if (sessionData.email === userData.data.email) {
                    disabledAll = false
                } else {
                    if (this.props.authReducer.userRole.id !== 3) {
                        disabledAll = false
                    } else {
                        disabledAll = true;
                    }
                }
                this.setState({
                    disabledAll: disabledAll,
                    userData: userData.data,
                    uid: userData.data.id,
                    userName: userData.data.name,
                    name: userData.data.name,
                    email: userData.data.email,
                    phoneno: userData.data.phoneno,
                    address: userData.data.address,
                    pincode: userData.data.pincode,
                    country: userData.data.country,
                    state: userData.data.state,
                    title: capitalize(userData.data.title),
                    city: userData.data.city,
                    profileImage: Server.API + "/uploads/" + userData.data.profileimage,
                    isDataLoading: false
                })
            }
        }
    }
    handleChange = (event, source) => {
        event.persist();
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    handleCheckBoxChange = (event, name) => {
        event.persist();
        this.setState({ [name]: event.target.checked });
    };
    handleFormSubmit = () => {
        let sendData = {
            userid: this.state.uid,
            email: this.state.email,
            name: this.state.name,
            title: this.state.title,
            phoneno: this.state.phoneno,
            address: this.state.address,
            country: this.state.country,
            state: this.state.state,
            pincode: this.state.pincode,
            city: this.state.city
        }
        this.props.updateAllCompanyUser(sendData);

    };

    render() {
        let { name, email, title, phoneno, pincode, state, address, country, city } = this.state;

        return (
            <React.Fragment>
                <div className="v_scroll crm-custom-importdata-settings">
                    <div className="">
                        <Typography className="mt-16 p-10" variant="h5" color="inherit" style={{ margin: "0px 0px 10px 290px" }}>
                            Company Users
                            </Typography>
                    </div>
                    <Card className="p-20 " style={{ margin: "0% 20%" }}>
                        <ValidatorForm ref="form" onSubmit={this.handleFormSubmit} noValidate>
                            <div className="pt-16">
                                <Grid className="mb-16 pb-10"
                                    style={{ borderBottom: "1px solid #bdbdbd52" }}
                                    container spacing={1} alignItems="center">
                                    <Grid item>
                                        <Avatar alt="Cindy Baker" src={this.state.imagePreview} />
                                    </Grid>
                                    <Grid item>
                                        <h6 className="font-size-18 mb-0">{this.state.userName}</h6>
                                        <p className="m-0 text-muted">{this.state.title}</p>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item sm={6} xs={12}>
                                        <InputLabel>Name *</InputLabel>
                                        <TextValidator
                                            required={true}
                                            type="text"
                                            id="name"
                                            className="w-100 input_bx"
                                            onChange={this.handleChange}
                                            name="name"
                                            disabled={this.state.disabledAll}
                                            value={name ? name : ''}
                                            validators={["required"]}
                                            errorMessages={["This field is required"]}
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <InputLabel>Phone No</InputLabel>
                                        <TextValidator
                                            className="w-100 input_bx"
                                            placeholder="Add Phone No"
                                            onChange={this.handleChange}
                                            type="text"
                                            name="phoneno"
                                            disabled={this.state.disabledAll}
                                            value={phoneno ? phoneno : ''}
                                            autoComplete='off'
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12} >
                                        <InputLabel>Email</InputLabel>
                                        <TextValidator
                                            required={true}
                                            className="w-100 input_bx"
                                            placeholder="Add Email"
                                            onChange={this.handleChange}
                                            type="email"
                                            name="email"
                                            value={email ? email : ''}
                                            validators={["required", 'isEmail']}
                                            errorMessages={["This field is required", "email is not valid"]}
                                            autoComplete='off'
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <InputLabel>Title</InputLabel>
                                        <TextValidator
                                            className="w-100 input_bx"
                                            placeholder="Add Title"
                                            onChange={this.handleChange}
                                            type="text"
                                            disabled={this.state.disabledAll}
                                            name="title"
                                            value={title ? title : ''}
                                            autoComplete='off'
                                        />
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <InputLabel>Address</InputLabel>
                                        <TextValidator
                                            className="w-100 input_bx"
                                            placeholder="Street"
                                            onChange={this.handleChange}
                                            type="text"
                                            disabled={this.state.disabledAll}
                                            name="address"
                                            value={address ? address : ''}
                                            autoComplete='off'
                                        />
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                        <TextValidator
                                            className="w-100 input_bx"
                                            placeholder="City"
                                            onChange={this.handleChange}
                                            type="text"
                                            disabled={this.state.disabledAll}
                                            name="city"
                                            value={city ? city : ''}
                                            autoComplete='off'
                                        />
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                        <TextValidator
                                            className="w-100 input_bx"
                                            placeholder="State"
                                            onChange={this.handleChange}
                                            type="text"
                                            name="state"
                                            disabled={this.state.disabledAll}
                                            value={state ? state : ''}
                                            autoComplete='off'
                                        />
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                        <TextValidator
                                            className="w-100 input_bx"
                                            placeholder="Zip Code"
                                            onChange={this.handleChange}
                                            type="text"
                                            disabled={this.state.disabledAll}
                                            name="pincode"
                                            value={pincode ? pincode : ''}
                                            autoComplete='off'
                                        />
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <Select
                                            name="country"
                                            disabled={this.state.disabledAll}

                                            value={country}
                                            className="w-100 input_bx"
                                            onChange={this.handleChange}
                                            MenuProps={{ className: 'choose_field_menu' }}
                                        >
                                            {this.state.countryListArray && this.state.countryListArray.map((opt, i) => {
                                                return <MenuItem className="select-menu-item" value={opt.value} key={i}>{opt.label}</MenuItem>
                                            })}
                                        </Select>
                                    </Grid>
                                </Grid>
                            </div>
                            <DialogActions className="dialog-action">
                                {
                                    this.state.disabledAll !== true &&
                                    <Button variant="contained" color="primary" type="submit">Update</Button>
                                }
                            </DialogActions>
                        </ValidatorForm>
                        {
                            this.state.isDataLoading &&
                            <Snackbar
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                open={this.state.isDataLoading}
                                TransitionComponent={Fade}
                                message={<><CircularProgress size={20} style={{ color: 'white' }} /><span style={{ paddingLeft: '6px', color: 'white' }}>{" Loading..."}</span></>}
                                variant={'success'}
                            />
                        }
                    </Card>
                </div>
            </React.Fragment >
        );
    }
}

const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    notification: state.notification,
    apolloClient: state.apolloClient,
    authReducer: state.authReducer

});
const mapDispatchToProps = dispatch => {
    return {
        updateAllCompanyUser: (data) => dispatch(actionCreators.updateAllCompanyUser(data)),
        getCountryList: (client) => { dispatch(actionCreators.getCountryList(client)) },
        getUserById: (id) => dispatch(actionCreators.getUserById(id)),
        clearNotification: () => dispatch(actionCreators.clearNotification()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(companyUser);
