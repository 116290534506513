import React, { Component } from "react";
import { withStyles, Dialog, IconButton, Button, Icon, Tooltip, Grid, DialogActions, Select, MenuItem, Checkbox, TextField } from "@material-ui/core";
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import ReactDragListView from "react-drag-listview";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Table } from 'antd';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const followers = [
  { title: 'Everyone' },
  { title: 'Team abcd' },
  { title: 'Team xyz' },
  { title: 'Team 123' },
];
const TooltipNew = withStyles((theme) => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);
class AddNewPipeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogTitle: 'Add New Pipeline',
      pipelinesname: '',
      pipelineEditId: '',
      pipelineError: false,
      winPercentageError: false,
      stagedata: [],
      stageValues:{
        stagekey1:{
          name:"",
          probability:""
        },
        stagekey2:{
          name:"",
          probability:""
        },
      }
    };
    this.columns = [
      {
        title: "",
        dataIndex: "drag",
        width: 50,
        onCell: () => {
          return {
            style: {
              whiteSpace: 'nowrap',
              maxWidth: 50,
            }
          }
        },
      },
      {
        title: "Name",
        dataIndex: "name",
        width: '40%',
        onCell: () => {
          return {
            style: {
              whiteSpace: 'nowrap',
              maxWidth: '40%',
            }
          }
        },
      },
      {
        title: "Win percentage",
        dataIndex: "winPercentage",
        width: '40%',
        onCell: () => {
          return {
            style: {
              whiteSpace: 'nowrap',
              maxWidth: '40%',
            }
          }
        },
      },
      {
        title: "Delete",
        dataIndex: "delete",
      }
    ];

    const that = this;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const stagedata = that.state.stagedata;
        const item = stagedata.splice(fromIndex, 1)[0];
        stagedata.splice(toIndex, 0, item);
        that.setState({
          stagedata
        }, () => that.forceUpdate())
      },
      handleSelector: "a"
    };
  }

  componentDidMount(){
    this.pushDefaultStage()
    if(this.props.pipelineEditData){
      var data = this.props.pipelineEditData;
      console.log("data", data);
      this.setState({
        pipelineEditId:parseInt(data.id),
        dialogTitle: 'Edit pipeline',
        pipelinesname: data.name,
        stagedata: [],
        stageValues:{}
      },() => {
          this.forceUpdate()
          data.stage.sort(function(a,b){return a.orderno - b.orderno});
          
          let stagedata = this.state.stagedata
          var promise = new Promise((resolve, reject) => {
              let stageValues = {}
              data.stage.map((row,ind)=>{
                var key = (ind+1).toString();
                var pnamekey = "name";
                var wnamekey = "probability";
                var dynamicKey = "stagekey"+key;

                //adding  value in state variable while edit
                var stagekey = "stagekey"+key;
                var jsonObj = {}
                jsonObj[pnamekey] = row.name
                jsonObj[wnamekey] = row.probability
                jsonObj["id"] = row.id
                stageValues = {
                    ...stageValues,
                    [stagekey]: jsonObj
                }
                this.setState({
                  stageValues
                }, () => {
                  this.forceUpdate()
                })
                resolve(true)
              })
          })

          promise.then( result => {
            data.stage.map((row,ind)=>{
                var key = (ind+1).toString();
                var pnamekey = "name";
                var wnamekey = "probability";
                var dynamicKey = "stagekey"+key;
                
                stagedata.push({
                  key:key,
                  drag: <Link className="drag-handle" to="#"><Icon>drag_handle</Icon></Link>,
                  name: <TextValidator
                    className="w-100 input_bx"
                    onChange={(event, value) => this.stageChange(event.target.value,pnamekey,dynamicKey)}
                    type="text"
                    name={pnamekey}
                    id={pnamekey}
                    defaultValue={this.state.stageValues[dynamicKey] ? this.state.stageValues[dynamicKey].name : ""}
                    placeholder="Add new stage"
                     
                  />,
                  winPercentage: <TextValidator
                    className="w-100 input_bx"
                    placeholder="Enter value up to 100%"
                    onChange={(event, value) => this.stageChange(event.target.value,wnamekey,dynamicKey)}
                    defaultValue={this.state.stageValues[dynamicKey] ? this.state.stageValues[dynamicKey].probability : ""} 
                    type="text"
                    name={wnamekey}
                    id={wnamekey}
                  />,
                  delete: <IconButton aria-label="edit" size="small" className="text-muted" onClick={() => this.deletePipelineStage(key)}><Icon>delete</Icon></IconButton>,
                })
                
                

                this.setState({
                  stagedata:stagedata,
                }, () => {
                  this.forceUpdate()
                })
            })
          });
          
      })
    }
  }


  pushDefaultStage = () => {
    var defaultDataArray = []
    defaultDataArray.push(
      {
        key: "1",
        drag: <Link className="drag-handle" to="#"><Icon>drag_handle</Icon></Link>,
        name: <TextValidator
          className="w-100 input_bx"
          onChange={(event, value) => this.stageChange(event.target.value,"name","stagekey1")}
          type="text"
          name={"name"}
          id={"name"}
          
          placeholder="Add new stage"
           
        />,
        winPercentage: <TextValidator
                  className="w-100 input_bx"
                  placeholder="Enter value up to 100%"
                  onChange={(event, value) => this.stageChange(event.target.value,"probability","stagekey1")}
                  type="text"
                  name={"probability"}
                  id={"probability"}
                />,
        delete: <IconButton aria-label="delete"  size="small" className="text-muted" onClick={()=>this.deletePipelineStage(1)}><Icon>delete</Icon></IconButton>,
      }
      ,
      {
        key: "2",
        drag: <Link className="drag-handle" to="#"><Icon>drag_handle</Icon></Link>,
        name: <TextValidator
          className="w-100 input_bx"
          onChange={(event, value) => this.stageChange(event.target.value,"name","stagekey2")}
          type="text"
          name={"name"}
          id={"name"}
          placeholder="Add new stage"
           
        />,
        winPercentage: <TextValidator
                  className="w-100 input_bx"
                  placeholder="Enter value up to 100%"
                  onChange={(event, value) => this.stageChange(event.target.value,"probability","stagekey2")}
                  type="text"
                  name={"probability"}
                  id={"probability"}
                />,
        delete: <IconButton aria-label="delete" size="small" id="2" className="text-muted" onClick={()=>this.deletePipelineStage(2)}><Icon>delete</Icon></IconButton>,
      },
    )

    this.setState({
      stagedata:defaultDataArray
    }, () => this.forceUpdate())

  }

  stageChange = (val,keyName,stagekey) => {
    var values = this.state.stageValues[stagekey];
    values[keyName] = val;
    this.setState({
      pipelineError:false,
      winPercentageError:false,
      stageValues: {
        ...this.state.stageValues,
        [stagekey]: values
      }
    },() => {
      this.forceUpdate()
      console.log(this.state.stageValues)
    })

  };


  handleChange = event => {
    event.persist();
    this.setState({ [event.target.name]: event.target.value });
  }

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  handleFormSubmit = () => {
    var stageObject = [];
    var allowToSavePipeline = true
    Object.values(this.state.stageValues).map((row,i)=>{
      //set allowToSavePipeline flag false if any field not contain value

      if(row.name == "" || row.probability == ""){
        allowToSavePipeline = false
      }
      if(row.probability != ""){
        var numnerOnly = /^[0-9]+$/;

        if (!numnerOnly.test(row.probability) || row.probability > 100) {
          this.setState({ winPercentageError: true });
          allowToSavePipeline = false
        }
         
      }

      var orderKey = this.findWithAttr(this.state.stagedata, 'key', (i+1).toString());
      row.orderno = orderKey
      stageObject.push(row)
    })

    if(allowToSavePipeline){
      let req = {
        stagedata:stageObject,
        pipelinesname:this.state.pipelinesname
      }
      if(this.state.pipelineEditId){
        var oldTagsValues = this.props.pipelineEditData.stage;
        
        req.stagedata.map((v,k)=>{
          req.stagedata[k].isdeleted= "0";
        })
        
        oldTagsValues.map((v,k)=>{
          var checkKey = this.findWithAttr(req.stagedata, 'id', v.id);
          if(checkKey === -1){
            console.log("v", v);
            v.isdeleted="1"
            req.stagedata.push(v)
          }
        })
        req.pipelinesid = parseInt(this.state.pipelineEditId);
        this.props.updatePipelinesDetail(this.props.apolloClient.client,req);

      }else{
        this.props.savePipelines(this.props.apolloClient.client,req);
      }
      this.props.handleClose();

    }else{
      this.setState({
        pipelineError:true
      }, () => {
        this.forceUpdate()
      })
    }


  }
  handleAddstage = () => {
    let stagedata = this.state.stagedata
    var key = (stagedata.length+1).toString();
    var pnamekey = "name";
    var wnamekey = "probability";
    var dynamicKey = "stagekey"+key;
    stagedata.push({
      key:key,
      drag: <Link className="drag-handle" to="#"><Icon>drag_handle</Icon></Link>,
      name: <TextValidator
        className="w-100 input_bx"
        onChange={(event, value) => this.stageChange(event.target.value,pnamekey,dynamicKey)}
        type="text"
        name={pnamekey}
        id={pnamekey}
        placeholder="Add new stage"
         
      />,
      winPercentage: <TextValidator
                  className="w-100 input_bx"
                  placeholder="Enter value up to 100%"
                  onChange={(event, value) => this.stageChange(event.target.value,wnamekey,dynamicKey)}
                  type="text"
                  name={wnamekey}
                  id={wnamekey}
                />,
      delete: <IconButton aria-label="edit" size="small" className="text-muted" onClick={() => this.deletePipelineStage(key)}><Icon>delete</Icon></IconButton>,
    })
    
    //adding blank value in state variable while push new
    var stagekey = "stagekey"+key;
    var jsonObj = {}
    jsonObj[pnamekey] = ""
    jsonObj[wnamekey] = ""

    this.setState({
      stagedata:stagedata,
      stageValues: {
        ...this.state.stageValues,
        [stagekey]: jsonObj
      }
    }, () => {
      this.forceUpdate()
    })
  }
  
  deletePipelineStage = (index) => {
    var stagekey = "stagekey"+index;
    let stagedata = this.state.stagedata.filter((val, ind) => val.key !== index.toString());
    
    let data = JSON.parse(JSON.stringify(this.state.stageValues));
    var newVal = {}
    this.setState({
      stageValues: {}
    }, () => {
      Object.keys(data).map((key,i)=>{
        if(stagekey !== key){
          var jsonObj = data[key]
          newVal[key] = jsonObj;
        }
      })
    })
  
    this.setState({
      stagedata:stagedata,
      stageValues:newVal
    }, () => {
      this.forceUpdate()
    })
  }
  render() {
    let { open, handleClose, handleMinimize, handleFull, fullScreen, minimizeScreen, } = this.props;
    let { pipelinesname } = this.state;
    let stagedataCount = this.state.stagedata.length < 2 ?true:false;

    return (
      <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth={true} className={`crm-custom-bg-primary custom_dialog ${minimizeScreen && fullScreen ? 'alignitemsend-fullscreen' : ''} ${minimizeScreen ? 'alignitemsend' : ''} ${fullScreen ? 'fullscreen' : ''}`} disableBackdropClick>
        <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">
          <h4 className="m-0 text-white">{this.state.dialogTitle}</h4>
          <div className="d-flex">
            <TooltipNew title={minimizeScreen ? 'Normal Screen' : 'Minimize Screen'}>
              <IconButton onClick={handleMinimize}>
                <Icon className="text-white">{minimizeScreen ? 'add' : 'remove'}</Icon>
              </IconButton>
            </TooltipNew>
            <TooltipNew title={fullScreen ? 'Exit Full Screen' : 'Full Screen'}>
              <IconButton onClick={handleFull}>
                <Icon className="text-white">{fullScreen ? 'fullscreen_exit' : 'fullscreen'}</Icon>
              </IconButton>
            </TooltipNew>
            <TooltipNew title="Close">
              <IconButton onClick={handleClose}>
                <Icon className="text-white">clear</Icon>
              </IconButton>
            </TooltipNew>
          </div>
        </div>
        <ValidatorForm ref={addPipelineFrm => this.addPipelineFrm = addPipelineFrm} noValidate debounceTime={500} onSubmit={this.handleFormSubmit}>
          <div className="dialog-body">
            <Grid container spacing={2} style={{ marginTop : '6px' }}>
              <Grid item xs={12}>
                <TextValidator
                  className="w-100 input_bx"
                  label="Pipeline name"
                  placeholder="Add pipeline name"
                  onChange={this.handleChange}
                  type="text"
                  name="pipelinesname"
                  value={pipelinesname}
                  required={true}
                  validators={["required"]}
                  errorMessages={["This field is required"]}
                  InputLabelProps={{shrink: true, disableAnimation: false, focused: true }}
                />
              </Grid>
            </Grid>
            <div className="box_portlet mb-16 mt-16 border-0">
              <div className="box_header">
                <div className="box_label"><h3 className="box_title">Manage pipeline stages</h3></div>
              </div>
              <div className="box_body p-0">
                <ReactDragListView {...this.dragProps}>
                  <Table
                    columns={this.columns}
                    pagination={false}
                    dataSource={this.state.stagedata}
                  />
                </ReactDragListView>
                <Button size="small" className="mt-16 mb-16 btn_Primary" variant="contained" color="primary"  onClick={this.handleAddstage}><Icon>add</Icon> Add stage</Button>
                <p className="text-lable">Note: Won, Abandoned, and Last these stages always will be set as default</p>
              </div>
            </div>
            {/* <div className="box_portlet mb-16 mt-16">
              <div className="box_header">
                <div className="box_label"><h3 className="box_title">Followers</h3></div>
              </div>
              <div className="box_body p-16">
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <lable className="text-lable">Who can able to see this pipeline:</lable>
                  </Grid>
                  <Grid item xs={8}>
                    <Autocomplete
                      multiple
                      options={followers}
                      disableCloseOnSelect
                      getOptionLabel={option => option.title}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            color="primary"
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.title}
                        </React.Fragment>
                      )}
                      style={{ width: '100%' }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </div>
            </div> */}
          </div>
          <DialogActions className="dialog-action">
            {(stagedataCount || this.state.pipelineError) && <p className="lead-filter-error">*Pipeline must have at least 2 stages and all stage values are required.</p>}
            {(this.state.winPercentageError) && <p className="lead-filter-error">*Please enter integer for win percentage value less then equal to 100.</p>}
            <Button variant="contained" onClick={handleClose}>Cancel</Button>
            <Button variant="contained" disabled={(stagedataCount || this.state.pipelineError)} type="submit" className="btn_Primary" color="primary">
              {this.state.pipelineEditId?"Update":"Save"}
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
});
const mapDispatchToProps = dispatch => {
  return {
    savePipelines: (client,req) => dispatch(actionCreators.savePipelines(client,req)),
    updatePipelinesDetail: (client,req) => dispatch(actionCreators.updatePipelinesDetail(client,req)),
    clearNotification: () => dispatch(actionCreators.clearNotification()),
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewPipeline);