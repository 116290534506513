import React, { Component } from 'react';
import { connect } from "react-redux";
import * as actionCreators from '../store/actions/index';
import { Dialog, Button, Grid, FormControl, Select, InputLabel, MenuItem, Icon, DialogContent, Avatar, DialogActions, Typography } from "@material-ui/core";
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import FormData from 'form-data';
import CompanyDashboard from './Dashboard/CompanyDashboard';
import UserDashboard from './Dashboard/UserDashboard';

let sessionData = JSON.parse(localStorage.getItem("user_data_session"));

class InfoPage extends Component {
    state = {
        open: false,
        fileName: '',
        companyId: null,
        name: null,
        phoneno: null,
        email: '',
        select_team_size: null,
        select_business_type: null,
        teamError: false,
        businessTypeError: false,
        imagePreview: "",
        DashboardType: "user",
        usersListArray: [],
        hasCompnayDashboard: true,
        dashboardList: [
            { id: 0, label: "Company Dashboard", name: 0 },
            { id: sessionData.id, label: sessionData.name, name: "user" },

        ],

    }
    componentWillMount() {
        let sessionData = JSON.parse(localStorage.getItem("user_data_session"));
        this.setState({ email: sessionData.email })
        this.props.getUserProfileInfoFirst(({ userid: sessionData.id, type: "profile" }))
        this.props.getBussinessType();
        this.props.GetCompanyStrength();
        this.props.companyListById(({ companyid: sessionData.companyId }))
        this.props.getUserList(this.props.apolloClient.client);
    }
    getUserPermission = () => {
        if (this.props.settingReducer.userPermissionDetails.data) {
            let udata = this.props.settingReducer.userPermissionDetails.data.data
            if (udata.reportVisibilityaccess.length > 0 &&
                udata.teampermissionstatus === true &&
                this.props.authReducer.userRole.id === 3) {
                let mainData = udata.reportVisibilityaccess.find(function (e) { return e.reporttype === 4 })
                this.setState({
                    hasCompnayDashboard: mainData.isaccess
                })
            }
        }
    }
    componentWillReceiveProps(props) {
        this.getUserPermission()
        let userData = props.settingReducer.firstUserInfo;
        if (userData.data !== undefined) {
            if (userData.data.companyinfo != true) {
                this.setState({ open: true })
            }
        }
        if (props.settingReducer.bussinessType.data !== undefined) {
            let newArr = [];
            props.settingReducer.bussinessType.data.map((val, index) => {
                newArr.push({ value: val.id, label: val.type_name })
            })
            this.setState({ businessTypeData: newArr })
        }

        if (props.settingReducer.compStrength.data !== undefined) {
            let strengthArr = [];
            props.settingReducer.compStrength.data.map((val, index) => {
                strengthArr.push({ value: val.id, label: val.strength })
            })
            this.setState({ strengthData: strengthArr })
        }

        if (props.settingReducer.compList.data !== undefined) {
            this.setState({
                name: props.settingReducer.compList.data.displayname,
                welName: props.settingReducer.compList.data.name
            })
        }

        if (props.settingReducer.usersListArray.data &&
            this.state.usersListArray.length === 0) {
            let da = this.state.dashboardList;
            const usersListArray = props.settingReducer.usersListArray.data;
            if (this.props.authReducer.userRole.id !== 3) {
                let sessionData = JSON.parse(localStorage.getItem("user_data_session"));
                usersListArray.map((val, index) => {
                    if (val.email.replace(/ /g, '') !== sessionData.email.replace(/ /g, '')) {
                        da.push({ id: val.id, label: val.name, name: val.id, teamlist: val.teamlist })
                    }
                })
            }
            this.setState({ dashboardList: da, usersListArray: usersListArray });
        }
    }

    handleFormSubmit = () => {
        //validation for select component
        if (this.state.select_team_size == null) {
            this.setState({ teamError: true })
        }
        if (this.state.select_business_type == null) {
            this.setState({ businessTypeError: true })
        }
        if (this.state.teamError == true || this.state.businessTypeError == true) {
            return false
        }


        let sessionData = JSON.parse(localStorage.getItem('user_data_session'))
        var data = new FormData()

        data.append('file', this.state.selectedFile);
        data.append('companyid', sessionData.companyId);
        data.append('companyname', this.state.name);
        data.append('phoneno', this.state.phoneno);
        data.append('strength', this.state.select_team_size.value);
        data.append('businesstype', this.state.select_business_type.value);
        var detailObj = {
            companyname: this.state.name,
            phoneno: this.state.phoneno,
            strength: this.state.select_team_size.value.toString(),
            businesstype: this.state.select_business_type.value.toString(),
        }

        var req = {
            detail: detailObj,
            fileInput: this.state.selectedFile ? this.state.selectedFile : "",
        }
        this.props.updateCompanyInfoGraphQl(this.props.apolloClient.client, req);
        this.props.updateDemoData(this.props.apolloClient.client);

        this.handleDialogClose()
    };


    handleChange = (event, source) => {
        event.persist();
        this.setState({
            [event.target.name]: event.target.value
        });
        if (event.target.name == 'select_team_size') {
            this.setState({ teamError: false })
        }
        if (event.target.name == 'select_business_type') {
            this.setState({ businessTypeError: false })
        }
    };



    onFileChangeHandler = (event) => {
        event.persist();
        if (event.target.files.length > 0) {
            this.setState({
                selectedFile: event.target.files[0],
                loaded: 0,
                fileName: event.target.files[0].name,
                imagePreview: URL.createObjectURL(event.target.files[0])
            })
        }
    }


    handleDialogClose = () => {
        this.props.settingReducer.firstUserInfo.data.companyinfo = true;
        setTimeout(() => {
            let sessionData = JSON.parse(localStorage.getItem("user_data_session"));
            this.props.getUserProfileInfoFirst(({ userid: sessionData.id, type: "profile" }))
            this.setState({ open: false })
        }, 1000);
    }
    handleChangeDashboard = (event, source) => {
        if (event.target.value !== "user") {
            this.props.setUserDashboardId(parseFloat(event.target.value))
        }
        this.setState({
            [event.target.name]: event.target.value
        });
    };


    render() {
        let open = this.state.open;
        let { name, phoneno } = this.state;
        const {
            error,
            errorMessages,
            validators,
            requiredError,
            helperText,
            validatorListener,
            withRequiredValidator,
            ...rest
        } = this.props;
        const { isValid } = this.state;
        return (
            <React.Fragment>
                <div className=" crm-custom-importdata-settings">
                    <div className="mt-20">
                        <Select
                            className="w-200 pull-right mr-40 mt--10"
                            labelId="Pipeline-select-label"
                            name="DashboardType"
                            id="Pipeline"
                            value={this.state.DashboardType}
                            onChange={this.handleChangeDashboard}
                        >
                            {
                                this.state.dashboardList.map((d, key) => (
                                    d.id === 0 ?
                                        this.state.hasCompnayDashboard &&
                                        <MenuItem value={d.name} key={key}>
                                            <span className="font-size-14 font-weight-500">
                                                {d.label}
                                            </span>
                                        </MenuItem>
                                        :
                                        <MenuItem value={d.name} key={key}>
                                            <span className="font-size-14 font-weight-500">
                                                {d.label}
                                                {/* {console.log(d.label)} */}
                                                <br />
                                                {d.teamlist
                                                    && d.teamlist.length > 0 &&
                                                    d.teamlist.map((t, i) => (
                                                        <span key={i} style={{ fontSize: "11px" }} >
                                                            {t.team.teamname}
                                                        </span>
                                                    ))
                                                }
                                            </span>
                                        </MenuItem>
                                ))
                            }
                        </Select>
                    </div>
                    {this.state.DashboardType == "user" ?
                        <UserDashboard />
                        :
                        <CompanyDashboard memberid={this.state.DashboardType} /> // user wise 
                    }
                </div>

                <Dialog open={open} maxWidth="xs">
                    <ValidatorForm ref="form" noValidate debounceTime={500} onSubmit={this.handleFormSubmit}>
                        <DialogContent className="user_info_modal pl-0 pr-0 leads_list_table">
                            <h3 className="text-center pl-30 pr-30">Welcome {this.state.welName + "!"}</h3>
                            <p className="text-center text-muted pl-30 pr-30">You are almost there! Just one more thing needed to complete your profile</p>
                            <div className="pl-30 pr-30">
                                <Grid container spacing={1} alignItems="center" className="mt-24">
                                    <Grid item>
                                        <label htmlFor="attachment">
                                            <Avatar alt="Profile Picture" style={{ cursor: 'pointer' }} src={this.state.imagePreview} />
                                        </label>
                                        <input style={{ display: 'none' }} accept="image/*" type="file" onChange={this.onFileChangeHandler}
                                            ref={fileInput => this.fileInput = fileInput}
                                        />
                                        <input accept="image/x-png,image/gif,image/jpeg" id="attachment" className="d-none" type="file" onChange={this.onFileChangeHandler} ref={fileInput => this.fileInput = fileInput} />
                                    </Grid>
                                    <Grid item>
                                        <h6 className="mb-0">{this.state.welName}</h6>
                                        <p className="m-0 text-muted">{this.state.email}</p>
                                    </Grid>
                                </Grid>
                                <TextValidator
                                    className="w-100 mb-8 mt-20"
                                    placeholder="Add Name"
                                    onChange={this.handleChange}
                                    type="text"
                                    name="name"
                                    required={true}
                                    value={name}
                                    validators={["required"]}
                                    errorMessages={["This field is required"]}
                                    autoComplete='off'
                                />
                                <TextValidator
                                    className="w-100 mb-8 mt-8"
                                    placeholder="Add Phone No"
                                    label={'Phone No'}
                                    onChange={this.handleChange}
                                    name="phoneno"
                                    value={phoneno}
                                    required={true}
                                    validators={["required", 'minNumber:0']}
                                    errorMessages={["This field is required", "Only positive numbers"]}
                                    autoComplete='off'
                                />
                                <FormControl className="w-100 mb-8 mt-8" error={this.state.teamError}>
                                    <SelectValidator
                                        label={'Team Size'}
                                        className="w-100 mb-8 mt-8"
                                        value={this.state.select_team_size}
                                        name="select_team_size"
                                        required={true}
                                        validators={["required"]}
                                        errorMessages={["This field is required"]}
                                        onChange={this.handleChange}
                                    >
                                        {this.state.strengthData && this.state.strengthData.map((opt, key) => {
                                            return <MenuItem key={key} className="select-menu-item" value={opt}>{opt.label}</MenuItem>
                                        })}
                                    </SelectValidator>
                                </FormControl>
                                <FormControl className="w-100 mb-16 mt-8" error={this.state.businessTypeError}>
                                    <SelectValidator
                                        label={'Business Type'}
                                        className="w-100 mb-8 mt-8"
                                        value={this.state.select_business_type}
                                        name="select_business_type"
                                        required={true}
                                        validators={["required"]}
                                        errorMessages={["This field is required"]}
                                        onChange={this.handleChange}
                                    >
                                        {this.state.businessTypeData && this.state.businessTypeData.map((opt, key) => {
                                            return <MenuItem key={key} className="select-menu-item" value={opt}>{opt.label}</MenuItem>
                                        })}
                                    </SelectValidator>
                                </FormControl>
                            </div>
                        </DialogContent>
                        <DialogActions className="pl-30 pr-30">
                            <Button className="button_hover" variant="contained" color="primary" fullWidth type="submit">Save</Button>
                        </DialogActions>
                    </ValidatorForm>
                </Dialog>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
    authReducer: state.authReducer

});
const mapDispatchToProps = dispatch => {
    return {
        getUserProfileInfoFirst: (data) => dispatch(actionCreators.getUserProfileInfoFirst(data)),
        updateCompanyInfo: (data) => dispatch(actionCreators.updateCompanyInfo(data)),
        getBussinessType: () => dispatch(actionCreators.getBussinessType()),
        GetCompanyStrength: () => dispatch(actionCreators.GetCompanyStrength()),
        companyListById: (data) => dispatch(actionCreators.companyListById(data)),
        updateCompanyInfoGraphQl: (client, req) => dispatch(actionCreators.updateCompanyInfoGraphQl(client, req)),
        updateDemoData: (client) => dispatch(actionCreators.updateDemoData(client)),
        getUserList: (client) => dispatch(actionCreators.getUserList(client)),
        setUserDashboardId: (id) => dispatch(actionCreators.setUserDashboardId(id)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(InfoPage);
