import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Checkbox, List, ListItem, FormControl, Select, IconButton, InputLabel, Icon, Grid, Menu, withStyles, Typography, MenuItem, Avatar, Fab, Button, Tabs, Tab, Box, Dialog, DialogTitle, DialogActions, Tooltip } from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import AddComment from "./AddComment";
import EditComment from "./EditComment";
import EditCommentMenu from './EditCommentMenu'
import { connect } from "react-redux";
import FontAwesome from "react-fontawesome";

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";

import { EditorState, convertToRaw, getPlainText, genKey } from 'draft-js';
import createSingleLinePlugin from 'draft-js-single-line-plugin';
import { MegadraftEditor, editorStateFromRaw, editorStateToJSON, MegadraftPlugin, insertDataBlock } from "megadraft-denistsuman";
import Editor from 'draft-js-plugins-editor';
import createMentionPlugin, { defaultSuggestionsFilter } from 'draft-js-mention-plugin';
import { fromJS } from 'immutable';
import 'draft-js-mention-plugin/lib/plugin.css';
import _ from 'lodash'
import EmojiTemplate from "../Layout/EmojiTemplate";

let mentions = [];

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.3rem",
    }
}))(Icon);

class CallLogs extends Component {
    constructor(props) {
        super(props);

        let editorState = EditorState.createEmpty();
        const myContent = {
            "entityMap": {},
            "blocks": [
                {
                    "key": genKey(),
                    "text": " ",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                }
            ]
        };
        this.state = {
            usersListArray: [],
            isEditLog: false,
            editorState: editorState,
            suggestions: mentions,
            openComment: false,
            dateformat: 'MM/DD/YYYY',
            timeZone: 'America/New_York',
            isSubmitDisable: true,
        }

        this.mentionPlugin = createMentionPlugin({
            mentions,
            entityMutability: 'IMMUTABLE',
            mentionPrefix: '@',
            supportWhitespace: true
        });

        this.singleLinePlugin = createSingleLinePlugin({
            stripEntities: false
        });
    }

    componentWillReceiveProps(props) {

        if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
            const usersListArray = props.settingReducer.usersListArray.data;
            mentions = usersListArray;
            this.setState({ usersListArray: usersListArray })
        }

        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            let usersetting = props.settingReducer.userSettingArray.data;

            let format = usersetting.dateformat;
            let timezone = usersetting.timezone;

            this.setState({ dateformat: format, timeZone: timezone })
        }
    }
    //----log functions------------
    onChange = (editorState) => {
        const content = editorState.getCurrentContent();
        const textvalue = content.getPlainText('\u0001');

        this.setState({
            textvalue1: textvalue,
            editorState: editorState,
            isSubmitDisable:
            (this.state.editorState.getCurrentContent().hasText() && editorState.getCurrentContent().hasText()) ?
              false : true
        });
    };

    onSearchChange = ({ value }) => {
        this.setState({
            suggestions: defaultSuggestionsFilter(value, mentions),
        });
    };

    onAddMention = (mention) => {
        let that = this;
        setTimeout(() => {
            this.setState({ editorState: EditorState.moveFocusToEnd(that.state.editorState) })
        }, 10);
    }

    focus = () => {
        this.editor.focus();
    };
    handleEditLog = (id, date, time, text, mentiondetail, mentionid) => {
        let entitymap = [];
        let offset = 0;
        let length = 0;

        mentionid.map((id) => {
            let mentionuserkey = this.findWithAttr(mentions, 'id', id + "");
            if (mentionuserkey != -1) {

                entitymap.push({
                    "type": "mention",
                    "mutability": "SEGMENTED",
                    "data": {
                        "mention": mentions[mentionuserkey]
                    }
                });

            }
        });

        let exampleState = {
            blocks: [{
                "key": "fuq9m",
                "text": text,
                "type": "unstyled",
                "depth": 0,
                "inlineStyleRanges": [],
                "entityRanges": [{ "offset": 0, "length": 0, "key": 0 }],
                "data": {}
            }],
            entityMap: entitymap
        };
        let editorState = editorStateFromRaw(exampleState);
        this.setState({ editorState })
        let date1 = new Date(date + ',' + time);
        this.props.thatAll.saveId(id, date1);

        this.setState({ isEditLog: true, textvalue: text, selectedDate: date1, logid: id });
    }
    saveLogData = () => {
        const content = this.state.editorState.getCurrentContent();
        const textvalue = content.getPlainText('\u0001');
        const raw = JSON.parse(editorStateToJSON(this.state.editorState));
        this.props.thatAll.handleSaveEditLog({ textvalue, raw })
        this.setState({ isEditLog: false });
    }
    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }
    handleCheckBoxChange = (event) => {
        this.setState({
            [event.target.name]: event.target.type === "checkbox" ?
                event.target.checked
                :
                event.target.value
        })
    }
    toggleComment = (id) => {
        this.setState({ openComment: !this.state.openComment })
        this.props.thatAll.toggleComment(id)
    }
    render() {
        let { log, selectedDate, dateformat, timeZone, commentid, showSelector, isOpenedCommentIds, isEditComment, commentvalue, thatAll, logid } = this.props;
        const { MentionSuggestions } = this.mentionPlugin;
        const plugins = [this.singleLinePlugin, this.mentionPlugin];
        let current_logUser = JSON.parse(localStorage.getItem('user_data_session')).email;

        return (

            <div className="box_body">
                <div className="lead_pl p-0">
                    <Grid container justify="space-between">
                        <Grid>
                            <h5 className="mb-0">
                                <FontAwesome className={log.activitytype.icon + " mr-10 vert-middle"} style={{ fontSize: '20px', padding: '5px' }} />
                                {log.propertyType}
                            </h5>
                            {/* <p className="mt-4 mb-4 font-size-13 text-hint">{log.creaetedDate}</p> */}
                            <p className="mt-4 mb-4 font-size-13 text-hint">{log.date}, {log.time}</p>
                        </Grid>
                        <Grid>
                            <div className="action_menu hover_show">
                                <EmojiTemplate
                                    menuButton={
                                        <IconButton aria-label="Add" size="small">
                                            <IconSmall>mood</IconSmall>
                                        </IconButton>
                                    }
                                    isright={true}
                                    logs={log}
                                    handleSelect={thatAll.handleSelect}
                                />
                                {(log.ispined == true) ?
                                    <IconButton aria-label="Pin" size="small" onClick={(logId, pinned) => thatAll.togglePin(log.logId, log.ispined)}>
                                        <svg viewBox="0 0 24 24" style={{ width: 18 }} fill="var(--primary)" xmlns="http://www.w3.org/2000/svg"><path d="M7 4l2 1.971V10l-4 3v2h6v7h2v-7h6v-2l-4-3V5.971l2-1.97V2H7v2z"></path></svg>
                                    </IconButton>
                                    :
                                    <IconButton aria-label="Pin" size="small" onClick={(logId, pinned) => thatAll.togglePin(log.logId, log.ispined)} >
                                        <svg viewBox="0 0 24 24" style={{ width: 18 }} fill="black" xmlns="http://www.w3.org/2000/svg"><path d="M7 4l2 1.971V10l-4 3v2h6v7h2v-7h6v-2l-4-3V5.971l2-1.97V2H7v2z"></path></svg>
                                    </IconButton>
                                }
                                <IconButton aria-label="Add" size="small" onClick={() => thatAll.AddNewLog(log)}>
                                    <IconSmall>add_circle</IconSmall>
                                </IconButton>
                                {(log.useremail == current_logUser) &&
                                    <IconButton aria-label="Delete" size="small" onClick={(logId) => thatAll.handleDeleteLog(log.logId)}>
                                        <IconSmall>delete</IconSmall>
                                    </IconButton>
                                }
                            </div>
                        </Grid>
                    </Grid>
                    {(this.state.isEditLog && (logid == log.logId)) ?
                        <>
                            <ValidatorForm noValidate debounceTime={500} onSubmit={this.saveLogData}>
                                <Grid item xs={12} className={'crm-editor-mention'} onClick={this.focus}>
                                    <label style={{ fontSize: '12px' }} >Text</label>
                                    <Editor
                                        editorState={this.state.editorState}
                                        onChange={this.onChange}
                                        plugins={plugins}
                                        blockRenderMap={createSingleLinePlugin.blockRenderMap}
                                        ref={(element) => { this.editor = element; }}
                                    />
                                    <MentionSuggestions
                                        onSearchChange={this.onSearchChange}
                                        suggestions={this.state.suggestions}
                                        onAddMention={this.onAddMention}
                                        onClose={() => this.setState({ ...this, suggestions: fromJS([]) })}
                                    />
                                </Grid>
                                <div className="mt-40">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={6} className="pt-0">
                                                <InputLabel htmlFor="assignedto" className="font-size-14 font-weight-500">Date</InputLabel>
                                                <KeyboardDatePicker
                                                    margin="normal"
                                                    id="date-picker-dialog"
                                                    format={dateformat.replace("DD", "dd").replace("YYYY", "yyyy")}
                                                    maxDate={new Date()}
                                                    value={selectedDate}
                                                    onKeyPress={(e) => e.preventDefault()}
                                                    onChange={thatAll.handleDateChange}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} className="pt-0">
                                                <InputLabel htmlFor="assignedto" className="font-size-14 font-weight-500">Time</InputLabel>
                                                <KeyboardTimePicker
                                                    margin="normal"
                                                    id="time-picker"
                                                    format="hh:mm a"
                                                    value={selectedDate}
                                                    onKeyPress={(e) => e.preventDefault()}
                                                    onChange={thatAll.handleDateChange}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change time',
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                    <div className="pull-right">
                                        <Button className="mr-10" color="secondary" onClick={() => this.setState({ isEditLog: false })}>Cancel</Button>
                                        <Button variant="contained" className={"btn_Primary mr-10 text-white"} type="submit" disabled={this.state.isSubmitDisable}>Update</Button>
                                    </div>
                                </div>
                            </ValidatorForm>
                        </>
                        :
                        <>
                            <div className="border_bx">
                                {log.text}
                                {(log.useremail == current_logUser) &&
                                    <div className="hover_show">
                                        <IconButton aria-label="Delete" size="small"
                                            onClick={(id, date, time, text) =>
                                                this.handleEditLog(log.logId, log.date, log.time, log.text, log.mentiondetail, log.mentionid)}>
                                            <IconSmall>edit</IconSmall>
                                        </IconButton>
                                    </div>
                                }
                            </div>
                            <div className="emoji_counter">
                                <li >
                                    <EmojiTemplate
                                        menuButton={
                                            <>
                                                <svg height="16" fill="#7467ef" width="7" viewBox="0 0 7 16" >
                                                    <path d="M4 7V4H3v3H0v1h3v3h1V8h3V7H4z"></path>
                                                </svg>
                                                <IconButton aria-label="Add" size="small" color="primary">
                                                    <IconSmall>mood</IconSmall>
                                                </IconButton>
                                            </>
                                        }
                                        logs={log}
                                        handleSelect={thatAll.handleSelect}
                                    />
                                </li>
                                {
                                    _.map(_.groupBy(log.emojidetail, 'emoji'), (c, emoji) => {
                                        return (
                                            <li onClick={() => thatAll.handleSelect(emoji, log)} >
                                                {emoji}{c.length}
                                            </li>
                                        )
                                    })
                                }
                            </div>
                        </>
                    }
                    <p className="font-size-14 pt-10">
                        <Grid container spacing={1}>
                            <Grid item>
                                <Fab className="small-size overflow-hidden mr-8" color="secondary" size="small">
                                    <h5 className={"text-white m-0 font-weight-normal avatar_circle color_" + log.color}>{log.color.toUpperCase()}</h5>
                                </Fab>
                            </Grid>
                            <Grid item md={10}>
                                <span className="font-weight-500">{log.user}</span>  added a  {log.propertyType}
                            </Grid>
                        </Grid>
                    </p>

                    <p className="mb-0 font-size-14">
                        <span className="cursor_poiter" onClick={() => this.toggleComment(log.logId)}>
                            <Icon className="vert-middle">
                                {this.state.openComment ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                            </Icon>
                            Comments ({(log.comments) ? log.comments.length : 0})
                            </span>
                    </p>
                    {this.state.openComment &&
                        <Grid container spacing={1}>
                            <Grid item className="flex-auto">
                                <div className="border_bx pr-10">
                                    {(log.comments && log.comments.length > 0) &&
                                        (log.comments).map((cmmt) => (
                                            <Grid container className="mb-10" spacing={1}>
                                                <Grid item>
                                                    <Fab className="small-size overflow-hidden mr-8 mt-10" color="secondary" size="small">
                                                        <h5 className={"text-white m-0 font-weight-normal avatar_circle color_" + (cmmt.__commentby__.name).charAt(0)}>{(cmmt.__commentby__.name).charAt(0).toUpperCase()}</h5>
                                                    </Fab>
                                                </Grid>
                                                {
                                                    (isEditComment && (commentid == cmmt.id)) ?
                                                        <>
                                                            <Grid className="mb-10" item md={9}>
                                                                <ValidatorForm noValidate debounceTime={500} onSubmit={(type) => thatAll.handleSaveEditComment('log')}>
                                                                    <Grid className="mb-10" item>
                                                                        <EditComment
                                                                            getEditData={thatAll.getEditCommentData}
                                                                            data={cmmt}
                                                                        />
                                                                    </Grid>
                                                                    {(commentvalue && commentvalue.length > 0) &&
                                                                        <Grid className="mb-10" item>
                                                                            <Button className="mr-10" color="secondary" onClick={thatAll.handleCancelEditComment}>Cancel</Button>
                                                                            <Button variant="contained" className={"btn_Primary mr-10 text-white"} type="submit" color="primary">Comment</Button>
                                                                        </Grid>
                                                                    }
                                                                </ValidatorForm>
                                                            </Grid>
                                                        </>
                                                        :
                                                        <>
                                                            <Grid item>
                                                                <p className="mt-4 mb-4 font-size-13 text-hint">
                                                                    {
                                                                        (cmmt.lastmodifieddate != null)
                                                                            ?
                                                                            moment.unix(cmmt.lastmodifieddate).tz(timeZone).format(dateformat) + ", " +
                                                                            moment.unix(cmmt.lastmodifieddate).tz(timeZone).format("hh:mm A")
                                                                            :
                                                                            moment.unix(cmmt.createddate).tz(timeZone).format(dateformat) + ", " +
                                                                            moment.unix(cmmt.createddate).tz(timeZone).format("hh:mm A")
                                                                    }
                                                                </p>
                                                                <p className="mt-4 mb-4 font-size-13">{cmmt.comment}</p>
                                                            </Grid>
                                                            {(cmmt.__commentby__.email == current_logUser) &&
                                                                <EditCommentMenu allData={thatAll} cmt={cmmt} type="log" />
                                                            }
                                                        </>
                                                }
                                            </Grid>
                                        ))
                                    }
                                    {(!isEditComment) &&
                                        <ValidatorForm noValidate debounceTime={500} onSubmit={(type) => thatAll.handleSaveCommentLog('log')}>
                                            <Grid container spacing={1}>
                                                <Grid className="mb-10" item >
                                                    <Fab className="small-size overflow-hidden mr-8 mt-10" color="secondary" size="small">
                                                        <h5 className={"text-white m-0 font-weight-normal avatar_circle color_" + log.color}>{log.color.toUpperCase()}</h5>
                                                    </Fab>
                                                </Grid>
                                                <Grid className="mb-10" item md={9}>
                                                    <Grid className="mb-10" item>
                                                        <AddComment
                                                            getdata={thatAll.getdata}
                                                            commentvalue={commentvalue}
                                                        />
                                                    </Grid>
                                                    {
                                                        (commentvalue && commentvalue.length > 0 && (log.logId == logid)) &&
                                                        <Grid className="mb-10" item>
                                                            <Button className="mr-10" color="secondary" onClick={thatAll.handleCancelComment}>Cancel</Button>
                                                            <Button variant="contained" className={"btn_Primary mr-10 text-white"} type="submit" color="primary">Comment</Button>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </ValidatorForm>
                                    }

                                </div>
                            </Grid>
                            <Grid item className="align-self-center">

                            </Grid>
                        </Grid>
                    }
                </div>
            </div>

        );
    }
}


const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
});


export default connect(mapStateToProps)(CallLogs);

