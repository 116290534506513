import React, { Component } from "react";
import { Dialog, IconButton, Button, Icon, Tooltip,RadioGroup,Radio, Grid, FormControl, Select, InputLabel, Menu, MenuItem, List, ListItem, ListItemText, DialogActions, Chip, TextField } from "@material-ui/core";
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { default as MaterialGrid } from '@material-ui/core/Grid';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';

import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import NumberFormat from 'react-number-format';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';

export const FilterComponent = (data) => {
  if(!data.state.fieldsList){
    return false
  }
  var elements = data.state.fieldsList
  var filterFieldsArray = [];
  

  //STRAT static interaction,Activity Type,Last Contacted,Inactive Days,Owned By,Status Duration,Followed,Date Added,Currency 
  let interactionsFrom = data.state.filterRangeArray["interactions"] ? data.state.filterRangeArray["interactions"][0] : ""
  let interactionsTo = data.state.filterRangeArray["interactions"] ? data.state.filterRangeArray["interactions"][1] : ""
  
  let inactivedaysFrom = data.state.filterRangeArray["inactivedays"] ? data.state.filterRangeArray["inactivedays"][0] : ""
  let inactivedaysTo = data.state.filterRangeArray["inactivedays"] ? data.state.filterRangeArray["inactivedays"][1] : ""

  var activitytypeVal = [];
  if (data.state.filterObject['activitytype'] && data.state.filterObject['activitytype'].value && data.state.filterObject['activitytype'].value.length > 0) {
    activitytypeVal = data.state.filterObject['activitytype'].value;
  }
  
  let checkBoxFollow = (data.state.filterObject['followed'] && data.state.filterObject['followed'].chktype == "follow" && data.state.filterObject['followed'].value[0] == "1") ? "1" : "0"
  let checkBoxNotFollow = (data.state.filterObject['followed'] && data.state.filterObject['followed'].chktype == "notfollow" && data.state.filterObject['followed'].value[0] == "1") ? "1" : "0"
  
  var ownerArray = [];
  if (data.state.filterObject['owner']) {
    ownerArray = data.state.filterObject['owner'].value
  }

  var ownerArray = [];
  if (data.state.filterObject['owner'] && data.state.filterObject['owner'].value && data.state.filterObject['owner'].value.length > 0) {
    let ownerVals = data.state.filterObject['owner'].value;
      if(ownerVals.length > 0 && ownerVals[0].id){
        ownerVals = ownerVals.map(r => r.email);
      }
      ownerArray = data.state.usersListArray.filter(row => ownerVals.filter(val => val == row.email).length);
  }
  
  let lastcontactedFrom = data.state.filterRangeArray["lastcontacted"] ? data.state.filterRangeArray["lastcontacted"][0] : null
  let lastcontactedTo = data.state.filterRangeArray["lastcontacted"] ? data.state.filterRangeArray["lastcontacted"][1] : null

  let createddateFrom = data.state.filterRangeArray["createddate"] ? data.state.filterRangeArray["createddate"][0] : null
  let createddateTo = data.state.filterRangeArray["createddate"] ? data.state.filterRangeArray["createddate"][1] : null

  filterFieldsArray.push(<>
      <MaterialGrid container justify="space-between" style={{marginTop:'15px'}}>
          <MaterialGrid>
              <span className="font-size-14 font-weight-500">{"Interactions"}</span>
          </MaterialGrid>
          <MaterialGrid className="flex flex-middle">
              <Link  className="text_link" onClick={()=>data.handleToggleFieldDisplay('interactions')}>Select Range</Link>
          </MaterialGrid>
      </MaterialGrid>
      {data.state.fieldDisplay.includes("interactions") && <MaterialGrid container spacing={2} className="flex-nowrap label-size-14" alignItems="flex-end">
            <MaterialGrid item xs={12}>
                <TextField
                    id="From"
                    pattern="[0-9]*"
                    label="From"
                    type="number"
                    fullWidth
                    value={interactionsFrom}
                    onChange={(event) => data.rangeFilter({ fieldid: "0", type:"range",value:event.target.value,case:0}, "interactions")}
                />
            </MaterialGrid>
            <MaterialGrid item>
                <TextField
                    id="to"
                    pattern="[0-9]*"
                    label="To"
                    type="number"
                    fullWidth
                    value={interactionsTo}
                    onChange={(event) => data.rangeFilter({ fieldid: "0", type:"range",value:event.target.value,case:1}, "interactions")}
                />
            </MaterialGrid>
            <MaterialGrid item>
                <IconButton
                    className="text-green"
                    aria-label="Apply"
                    color="primary"
                    size="small"
                    onClick={()=>data.rangeClick("interactions","0")}
                >
                    <Icon>check_circle</Icon>
                </IconButton>
            </MaterialGrid>
        </MaterialGrid>
      }

      <MaterialGrid container justify="space-between" onClick={()=>data.dropdownMenu('activitytype')} className="mt-16 cursor_poiter">
          <span className="font-size-14 font-weight-500">{'Activity Type'}</span>
          <Icon>{data.state.dropdownhide.includes('activitytype') ? 'expand_less' : 'expand_more'}</Icon>
      </MaterialGrid>
      
      {data.state.dropdownhide.includes('activitytype') &&
          <>
          <span style={{fontSize:'12px',color:'#696E6F'}}>Match &nbsp;</span>
          <Select
              name="activityquery"
              id="activityquery"
              className="font-size-13"
              value={data.state.activityquery}
              disableUnderline
              onChange={data.handlechangeActivityQuery}
          >
            <MenuItem key="any" className="" value="any">any</MenuItem>
            <MenuItem key="all" className="" value="all">all</MenuItem>
            <MenuItem key="none" className="" value="none">none</MenuItem>
          </Select>
          <span style={{fontSize:'12px',color:'#696E6F'}}>&nbsp;of the following</span>
          <Autocomplete
            multiple
            filterSelectedOptions
            autoSelect={false}
            value={activitytypeVal}
            name={"activitytype"}
            onChange={(event, value) => data.handleChangeFilter({ fieldid: "0", name:"activitytype",type:"log",value: value ,query:data.state.activityquery}, "activitytype")}
            id={"activitytype"}
            forcePopupIcon={false}
            options={data.state.masterActivityTypesList}
            getOptionLabel={option => option.name ? option.name : ""}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                placeholder={'Add Activity Type'}
                fullWidth
              />
            )}
          />
          </>
      }

      <MaterialGrid container justify="space-between" style={{marginTop:'15px'}}>
          <MaterialGrid>
              <span className="font-size-14 font-weight-500">{"Last Contacted"}</span>
          </MaterialGrid>
          <MaterialGrid className="flex flex-middle">
              <Link  className="text_link" onClick={()=>data.handleToggleFieldDisplay('lastcontacted')}>Select Date Range</Link>
          </MaterialGrid>
      </MaterialGrid>
      {data.state.fieldDisplay.includes("lastcontacted") && 
        <>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <FormControl className="w-100 input_bx">
              <KeyboardDatePicker
                  label="From date"
                  maxDate={new Date()}
                  format={data.state.dateformat.replace("DD", "dd").replace("YYYY", "yyyy")}
                  placeholder={data.state.dateformat.replace("DD", "dd").replace("YYYY", "yyyy").replace("MM", "mm")}
                  value={lastcontactedFrom?lastcontactedFrom:null}
                  onChange={(event) => data.rangeFilterDate({ fieldid: "0", type:"date",value:event,case:0}, "lastcontacted")}
                  KeyboardButtonProps={{
                      'aria-label': 'change date',
                  }}
              />
            </FormControl>
            <FormControl className="w-100 input_bx">
              <KeyboardDatePicker
                  label="To date"
                  maxDate={new Date()}
                  minDate={lastcontactedFrom?lastcontactedFrom:null}
                  format={data.state.dateformat.replace("DD", "dd").replace("YYYY", "yyyy")}
                  placeholder={data.state.dateformat.replace("DD", "dd").replace("YYYY", "yyyy").replace("MM", "mm")}
                  value={lastcontactedTo?lastcontactedTo:null}
                  onChange={(event) => data.rangeFilterDate({ fieldid: "0", type:"date",value:event,case:1}, "lastcontacted")}
                  KeyboardButtonProps={{
                      'aria-label': 'change date',
                  }}
              />
            </FormControl>
          </MuiPickersUtilsProvider>
        </>
      }

      <MaterialGrid container justify="space-between" style={{marginTop:'15px'}}>
          <MaterialGrid>
              <span className="font-size-14 font-weight-500">{"Inactive Days"}</span>
          </MaterialGrid>
          <MaterialGrid className="flex flex-middle">
              <Link  className="text_link" onClick={()=>data.handleToggleFieldDisplay('inactivedays')}>Select Range</Link>
          </MaterialGrid>
      </MaterialGrid>
      {data.state.fieldDisplay.includes("inactivedays") && <MaterialGrid container spacing={2} className="flex-nowrap label-size-14" alignItems="flex-end">
            <MaterialGrid item xs={12}>
                <TextField
                    pattern="[0-9]*"
                    id="From"
                    label="From"
                    type="number"
                    fullWidth
                    value={inactivedaysFrom}
                    onChange={(event) => data.rangeFilter({ fieldid: "0", type:"range",value:event.target.value,case:0}, "inactivedays")}
                />
            </MaterialGrid>
            <MaterialGrid item>
                <TextField
                    pattern="[0-9]*"
                    id="to"
                    label="To"
                    type="number"
                    fullWidth
                    value={inactivedaysTo}
                    onChange={(event) => data.rangeFilter({ fieldid: "0", type:"range",value:event.target.value,case:1}, "inactivedays")}
                />
            </MaterialGrid>
            <MaterialGrid item>
                <IconButton
                    className="text-green"
                    aria-label="Apply"
                    color="primary"
                    size="small"
                    onClick={()=>data.rangeClick("inactivedays","0")}
                >
                    <Icon>check_circle</Icon>
                </IconButton>
            </MaterialGrid>
        </MaterialGrid>
      }

      <MaterialGrid container justify="space-between" onClick={()=>data.dropdownMenu('owner')} className="mt-16 cursor_poiter">
          <span className="font-size-14 font-weight-500">{'Owned By'}</span>
          <Icon>{data.state.dropdownhide.includes('owner') ? 'expand_less' : 'expand_more'}</Icon>
      </MaterialGrid>
      {data.state.dropdownhide.includes('owner') &&
          <Autocomplete
            multiple
            filterSelectedOptions
            autoSelect={false}
            name={'owner'}
            value={ownerArray}
            onChange={(event, value) => data.handleChangeFilter({ fieldid: "20", name:'owner',type:"value",value:value }, 'owner')}
            id={'owner'}
            forcePopupIcon={false}
            options={data.state.usersListArray}
            getOptionLabel={option => option.name ? option.name : ""}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                placeholder={"Add User"}
                fullWidth
              />
            )}
          />
      }

      <MaterialGrid container justify="space-between" onClick={()=>data.dropdownMenu('followed')} className="mt-16 cursor_poiter">
          <span className="font-size-14 font-weight-500">{'Followed'}</span>
          <Icon>{data.state.dropdownhide.includes('followed') ? 'expand_less' : 'expand_more'}</Icon>
      </MaterialGrid>
      
      {data.state.dropdownhide.includes('followed') &&
          <>
            <FormControlLabel
              style={{marginLeft:'12px',margin:'0px','marginBottom':'-11px'}}
              control={
                <Checkbox
                  checked={(checkBoxFollow=="1")?true:false}
                  onChange={(event, value) => data.handleFollowedCheckbox({ fieldid: '0', name:"followed",type:"followed",value: [event.target.checked],chktype:'follow' }, 'followed')}
                  color="primary"
                  
                />
              }
              label={<span style={{fontSize:"13px"}}>Followed</span>}
            />
            <FormControlLabel
              style={{marginLeft:'12px',margin:'0px'}}
              control={
                <Checkbox
                  checked={(checkBoxNotFollow=="1")?true:false}
                  onChange={(event, value) => data.handleFollowedCheckbox({ fieldid: '0', name:"followed",type:"followed",value: [event.target.checked],chktype:'notfollow' }, 'followed')}
                  color="primary"
                  
                />
              }
              label={<span style={{fontSize:"13px"}}>Not Followed</span>}
            />
          </>
      }

      <MaterialGrid container justify="space-between" style={{marginTop:'15px'}}>
          <MaterialGrid>
              <span className="font-size-14 font-weight-500">{"Date Added"}</span>
          </MaterialGrid>
          <MaterialGrid className="flex flex-middle">
              <Link  className="text_link" onClick={()=>data.handleToggleFieldDisplay('createddate')}>Select Date Range</Link>
          </MaterialGrid>
      </MaterialGrid>
      {data.state.fieldDisplay.includes("createddate") && 
        <>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <FormControl className="w-100 input_bx">
              <KeyboardDatePicker
                  label="From date"
                  format={data.state.dateformat.replace("DD", "dd").replace("YYYY", "yyyy")}
                  placeholder={data.state.dateformat.replace("DD", "dd").replace("YYYY", "yyyy").replace("MM", "mm")}
                  value={createddateFrom?createddateFrom:null}
                  onChange={(event) => data.rangeFilterDate({ fieldid: "0", type:"date",value:event,case:0}, "createddate")}
                  KeyboardButtonProps={{
                      'aria-label': 'change date',
                  }}
              />
            </FormControl>
            <FormControl className="w-100 input_bx">
              <KeyboardDatePicker
                  label="To date"
                  minDate={createddateFrom?createddateFrom:null}
                  format={data.state.dateformat.replace("DD", "dd").replace("YYYY", "yyyy")}
                  placeholder={data.state.dateformat.replace("DD", "dd").replace("YYYY", "yyyy").replace("MM", "mm")}
                  value={createddateTo?createddateTo:null}
                  onChange={(event) => data.rangeFilterDate({ fieldid: "0", type:"date",value:event,case:1}, "createddate")}
                  KeyboardButtonProps={{
                      'aria-label': 'change date',
                  }}
              />
            </FormControl>
          </MuiPickersUtilsProvider>
        </>
      }

  </>)
  //END static interaction,Activity Type,Last Contacted,Inactive Days,Owned By,Status Duration,Followed,Date Added,Currency 
  
  elements.map((v, i) => {
    if (v.fieldstatus === true && v.includeinfilter === true) {
      if (v.fieldtype.id == '5') {
        var multiDropdownValuesArray = [];
        if (data.state.filterObject[v.fieldkey] && data.state.filterObject[v.fieldkey].value && data.state.filterObject[v.fieldkey].value.length > 0) {
          let dropdownvalues = data.state.filterObject[v.fieldkey].value;
            if(dropdownvalues.length > 0 && dropdownvalues[0].id){
              dropdownvalues = dropdownvalues.map(r => r.optionvalue);
            }
          multiDropdownValuesArray = v.options.filter(row => dropdownvalues.filter(val => val == row.optionvalue).length);
        }
        filterFieldsArray.push(
            <>
            <MaterialGrid container justify="space-between" onClick={()=>data.dropdownMenu(v.fieldkey)} className="mt-16 cursor_poiter">
                <span className="font-size-14 font-weight-500">{v.fieldlabel}</span>
                <Icon>{data.state.dropdownhide.includes(v.fieldkey) ? 'expand_less' : 'expand_more'}</Icon>
            </MaterialGrid>
            {data.state.dropdownhide.includes(v.fieldkey) &&
                <Autocomplete
                  multiple
                  filterSelectedOption
                  autoSelect={false}
                  name={v.fieldkey}
                  value={multiDropdownValuesArray}
                  onChange={(event, value) => data.handleChangeFilter({ fieldid: v.id, name:v.fieldkey,type:"multi",value: value }, v.fieldkey)}
                  id={v.id}
                  options={v.options}
                  getOptionLabel={option => option.optionvalue ? option.optionvalue : ""}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder={"Select" +" "+ v.fieldlabel}
                      fullWidth
                    />
                  )}
                />
            }
            </>
          )
      }else if(v.fieldtype.id === "4" && v.id != "26"){
        if(v.fieldkey == "company"){// company dropdown filter
          var selectedCompaniesTofilter = [];
          if (data.state.filterObject[v.fieldkey] && data.state.filterObject[v.fieldkey].value && data.state.filterObject[v.fieldkey].value.length > 0) {
            let companySelectedVal= data.state.filterObject[v.fieldkey].value;
            if(companySelectedVal.length > 0 && companySelectedVal[0].company_id){
                companySelectedVal = companySelectedVal.map(r => r.name);
              }
              selectedCompaniesTofilter = data.state.companiesList.filter(row => companySelectedVal.filter(val => val == row.name).length);
          }
          filterFieldsArray.push(
            <>
            <MaterialGrid container justify="space-between" onClick={()=>data.dropdownMenu(v.fieldkey)} className="mt-16 cursor_poiter">
                <span className="font-size-14 font-weight-500">{v.fieldlabel}</span>
                <Icon>{data.state.dropdownhide.includes(v.fieldkey) ? 'expand_less' : 'expand_more'}</Icon>
            </MaterialGrid>
            {data.state.dropdownhide.includes(v.fieldkey) &&
                <Autocomplete
                  multiple
                  filterSelectedOptions
                  autoSelect={false}
                  name={v.fieldkey}
                  value={selectedCompaniesTofilter}
                  onChange={(event, value) => data.handleChangeFilter({ fieldid: v.id, name:v.fieldkey,type:"value",value: value }, v.fieldkey)}
                  id={v.id}
                  forcePopupIcon={false}
                  options={data.state.companiesList}
                  getOptionLabel={option => option.name ? option.name : ""}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder={"Select" +" "+ v.fieldlabel}
                      fullWidth
                    />
                  )}
                />
            }
            </>
          )
        }else if(v.fieldkey == "contacttype"){
          var checkValues = []
          if (data.state.filterObject[v.fieldkey] && data.state.filterObject[v.fieldkey].value && data.state.filterObject[v.fieldkey].value.length > 0) {
            checkValues = data.state.filterObject[v.fieldkey].value;
            console.log("checkValues", checkValues);
          }
          filterFieldsArray.push(
            <>
              <MaterialGrid container justify="space-between" onClick={()=>data.dropdownMenu(v.fieldkey)} className="mt-16 cursor_poiter">
                  <span className="font-size-14 font-weight-500">{v.fieldlabel}</span>
                  <Icon>{data.state.dropdownhide.includes(v.fieldkey) ? 'expand_less' : 'expand_more'}</Icon>
              </MaterialGrid>
              
              {data.state.dropdownhide.includes(v.fieldkey) &&
                  <>
                    {v.options && v.options.map((item, key) =>
                      <FormControlLabel
                        style={{marginLeft:'12px',margin:'0px','marginBottom':'-11px'}}
                        control={
                          <Checkbox
                            checked={(checkValues.includes(item.name))?true:false}
                            onChange={(event, value) => data.handleContactTypeCheckbox({ fieldid: v.id, name:v.fieldkey,type:"value",value:checkValues,checked:event.target.checked,text:item.name}, v.fieldkey)}
                            color="primary"
                            
                          />
                        }
                        label={<span className="font-size-13">{item.name}</span>}
                      />
                    )}
                  </>
              }

            </>
          )
        }else{
          var dropdownValuesArray = [];
          if (data.state.filterObject[v.fieldkey] && data.state.filterObject[v.fieldkey].value && data.state.filterObject[v.fieldkey].value.length > 0) {
            let dropdownvalues = data.state.filterObject[v.fieldkey].value;
            if(v.is_default){
              if(dropdownvalues.length > 0 && dropdownvalues[0].id){
                dropdownvalues = dropdownvalues.map(r => r.name);
              }
              dropdownValuesArray = v.options.filter(row => dropdownvalues.filter(val => val == row.name).length);
            }else{
              if(dropdownvalues.length > 0 && dropdownvalues[0].id){
                dropdownvalues = dropdownvalues.map(r => r.optionvalue);
              }
              dropdownValuesArray = v.options.filter(row => dropdownvalues.filter(val => val == row.optionvalue).length);
            }

          }
          filterFieldsArray.push(
            <>
            <MaterialGrid container justify="space-between" onClick={()=>data.dropdownMenu(v.fieldkey)} className="mt-16 cursor_poiter">
                <span className="font-size-14 font-weight-500">{v.fieldlabel}</span>
                <Icon>{data.state.dropdownhide.includes(v.fieldkey) ? 'expand_less' : 'expand_more'}</Icon>
            </MaterialGrid>
            {data.state.dropdownhide.includes(v.fieldkey) &&
                <Autocomplete
                  multiple
                  filterSelectedOptions
                  autoSelect={false}
                  name={v.fieldkey}
                  value={dropdownValuesArray}
                  onChange={(event, value) => data.handleChangeFilter({ fieldid: v.id, name:v.fieldkey,type:"value",value: value }, v.fieldkey)}
                  id={v.id}
                  forcePopupIcon={false}
                  options={v.options}
                  getOptionLabel={option => option.name ? option.name ? option.name : "" : option.optionvalue ? option.optionvalue : ""}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder={"Select" +" "+ v.fieldlabel}
                      fullWidth
                    />
                  )}
                />
            }
            </>
          )
        }
        
      }else if(v.fieldtype.id === "1"){
        if(v.fieldkey === "tags"){//tag field
          var valTag = [];
          if (data.state.filterObject[v.fieldkey]) {
            valTag = data.state.filterObject[v.fieldkey].value
          }
          filterFieldsArray.push(
            <>
            <MaterialGrid container justify="space-between" onClick={()=>data.dropdownMenu(v.fieldkey)} className="mt-16 cursor_poiter">
                <span className="font-size-14 font-weight-500">{v.fieldlabel}</span>
                <Icon>{data.state.dropdownhide.includes(v.fieldkey) ? 'expand_less' : 'expand_more'}</Icon>
            </MaterialGrid>
            {data.state.dropdownhide.includes(v.fieldkey) &&
                <>
                <span style={{fontSize:'12px',color:'#696E6F'}}>Match &nbsp;</span>
                <Select
                    name="tagsquery"
                    id="tagsquery"
                    className="font-size-13"
                    value={data.state.tagsquery}
                    disableUnderline
                    onChange={data.handlechangeTagsQuery}
                >
                  <MenuItem key="any" className="" value="any">any</MenuItem>
                  <MenuItem key="all" className="" value="all">all</MenuItem>
                  <MenuItem key="none" className="" value="none">none</MenuItem>
                </Select>
                <span style={{fontSize:'12px',color:'#696E6F'}}>&nbsp;of the following</span>
                <Autocomplete
                  multiple
                  value={valTag}
                  filterSelectedOption
                  name={v.fieldkey}
                  id={v.fieldkey}
                  options={data.state.systemTagsList.map(option => option.tagname)}
                  forcePopupIcon={false}
                  onChange={(event, value) => data.handleChangeFilter({ fieldid: v.id, name:v.fieldkey,type:"multi",value: value ,query:data.state.tagsquery}, v.fieldkey)}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip key={index} label={option} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      id={v.fieldkey}
                      placeholder={"Select" +" "+ v.fieldlabel}
                      className="w-100 input_bx"
                  />
                )}
              />
              </>
            }
            </>
          )
        }else if(v.id === "14") {
          let dataValFrom = data.state.filterRangeArray[v.fieldkey] ? data.state.filterRangeArray[v.fieldkey][0] : ""
          let dataValTo = data.state.filterRangeArray[v.fieldkey] ? data.state.filterRangeArray[v.fieldkey][1] : ""
          filterFieldsArray.push(<>
            <MaterialGrid container justify="space-between" style={{marginTop:'15px'}}>
                <MaterialGrid>
                    <span className="font-size-14 font-weight-500">{v.fieldlabel}</span>
                </MaterialGrid>
                <MaterialGrid className="flex flex-middle">
                    <Link  className="text_link" onClick={()=>data.handleToggleFieldDisplay(v.fieldkey)}>Select Range</Link>
                </MaterialGrid>
            </MaterialGrid>
            {data.state.fieldDisplay.includes(v.fieldkey) && <MaterialGrid container spacing={2} className="flex-nowrap label-size-14" alignItems="flex-end">
                <MaterialGrid item xs={12}>
                    <TextField
                        pattern="[0-9]*"
                        id="From"
                        label="From"
                        type="number"
                        fullWidth
                        value={dataValFrom}
                        onChange={(event) => data.rangeFilter({ fieldid: v.id, type:"range",value:event.target.value,case:0}, v.fieldkey)}
                    />
                </MaterialGrid>
                <MaterialGrid item>
                    <TextField
                        pattern="[0-9]*"
                        id="to"
                        label="To"
                        type="number"
                        fullWidth
                        value={dataValTo}
                        onChange={(event) => data.rangeFilter({ fieldid: v.id, type:"range",value:event.target.value,case:1}, v.fieldkey)}
                    />
                </MaterialGrid>
                <MaterialGrid item>
                    <IconButton
                        className="text-green"
                        aria-label="Apply"
                        color="primary"
                        size="small"
                        onClick={()=>data.rangeClick(v.fieldkey,v.id)}
                    >
                        <Icon>check_circle</Icon>
                    </IconButton>
                </MaterialGrid>
            </MaterialGrid>}
          </>)
        }
      }else if(v.fieldtype.id === "2"){// Text Area address field
        var zipvalues = [];
        if (data.state.filterObject['zipcode'] && data.state.filterObject['zipcode'].value && data.state.filterObject['zipcode'].value.length > 0) {
          let newFieldValue = data.state.filterObject['zipcode'].value;
            if(newFieldValue.length > 0 && newFieldValue[0].id){
              newFieldValue = newFieldValue.map(r => r.name);
            }
            zipvalues = data.state.systemZipcodeListArray.filter(row => newFieldValue.filter(val => val == row.name).length);
        }
        var cityvalues = [];
        if (data.state.filterObject['city'] && data.state.filterObject['city'].value && data.state.filterObject['city'].value.length > 0) {
          let newFieldValueCity = data.state.filterObject['city'].value;
            if(newFieldValueCity.length > 0 && newFieldValueCity[0].id){
              newFieldValueCity = newFieldValueCity.map(r => r.name);
            }
            cityvalues = data.state.systemCityListArray.filter(row => newFieldValueCity.filter(val => val == row.name).length);
        }
        var statevalues = [];
        if (data.state.filterObject['state'] && data.state.filterObject['state'].value && data.state.filterObject['state'].value.length > 0) {
          let newFieldValueState = data.state.filterObject['state'].value;
            if(newFieldValueState.length > 0 && newFieldValueState[0].id){
              newFieldValueState = newFieldValueState.map(r => r.name);
            }
            statevalues = data.state.systemStateListArray.filter(row => newFieldValueState.filter(val => val == row.name).length);
        }
        var countryvalues = [];
        if (data.state.filterObject['country'] && data.state.filterObject['country'].value && data.state.filterObject['country'].value.length > 0) {
          let newFieldValueCountry = data.state.filterObject['country'].value;
            if(newFieldValueCountry.length > 0 && newFieldValueCountry[0].id){
              newFieldValueCountry = newFieldValueCountry.map(r => r.name);
            }
            countryvalues = data.state.countryListArray.filter(row => newFieldValueCountry.filter(val => val == row.name).length);
        }
        if(v.id === "25"){//address field id
          filterFieldsArray.push(
            <>
            <MaterialGrid container justify="space-between" onClick={()=>data.dropdownMenu('city')} className="mt-16 cursor_poiter">
                <span className="font-size-14 font-weight-500">{'City'}</span>
                <Icon>{data.state.dropdownhide.includes('city') ? 'expand_less' : 'expand_more'}</Icon>
            </MaterialGrid>
            {data.state.dropdownhide.includes('city') &&
                <Autocomplete
                  multiple
                  filterSelectedOptions
                  autoSelect={false}
                  name={"city"}
                  value={cityvalues}
                  forcePopupIcon={false}
                  onChange={(event, value) => data.handleChangeFilter({ fieldid: v.id, name:"city",type:"value",value: value }, "city")}
                  id={"city"}
                  options={data.state.systemCityListArray}
                  getOptionLabel={option => option.name ? option.name : ""}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder={"Search"}
                      fullWidth
                    />
                  )}
                />
            }
            <MaterialGrid container justify="space-between" onClick={()=>data.dropdownMenu('state')} className="mt-16 cursor_poiter">
                <span className="font-size-14 font-weight-500">{'State'}</span>
                <Icon>{data.state.dropdownhide.includes('state') ? 'expand_less' : 'expand_more'}</Icon>
            </MaterialGrid>
            {data.state.dropdownhide.includes('state') &&
                <Autocomplete
                  multiple
                  forcePopupIcon={false}
                  filterSelectedOptions
                  autoSelect={false}
                  name={"state"}
                  value={statevalues}
                  onChange={(event, value) => data.handleChangeFilter({ fieldid: v.id, name:"state",type:"value",value: value }, "state")}
                  id={"state"}
                  options={data.state.systemStateListArray}
                  getOptionLabel={option => option.name ? option.name : ""}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder={"Search"}
                      fullWidth
                    />
                  )}
                />
            }
            <MaterialGrid container justify="space-between" onClick={()=>data.dropdownMenu('country')} className="mt-16 cursor_poiter">
                <span className="font-size-14 font-weight-500">{'Country'}</span>
                <Icon>{data.state.dropdownhide.includes('country') ? 'expand_less' : 'expand_more'}</Icon>
            </MaterialGrid>
            {data.state.dropdownhide.includes('country') &&
                <Autocomplete
                  multiple
                  filterSelectedOptions
                  autoSelect={false}
                  name={"country"}
                  value={countryvalues}
                  forcePopupIcon={false}
                  onChange={(event, value) => data.handleChangeFilter({ fieldid: v.id, name:"country",type:"value",value: value }, "country")}
                  id={"country"}
                  options={data.state.countryListArray}
                  getOptionLabel={option => option.name ? option.name : ""}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder={"Search"}
                      fullWidth
                    />
                  )}
                />
            }
            <MaterialGrid container justify="space-between" onClick={()=>data.dropdownMenu('zipcode')} className="mt-16 cursor_poiter">
                <span className="font-size-14 font-weight-500">{'Zip'}</span>
                <Icon>{data.state.dropdownhide.includes('zipcode') ? 'expand_less' : 'expand_more'}</Icon>
            </MaterialGrid>

            {data.state.dropdownhide.includes('zipcode') &&
                <Autocomplete
                  multiple
                  filterSelectedOptions
                  autoSelect={false}
                  name={"zipcode"}
                  value={zipvalues}
                  onChange={(event, value) => data.handleChangeFilter({ fieldid: v.id, name:"zipcode",type:"value",value: value }, "zipcode")}
                  id={"zipcode"}
                  forcePopupIcon={false}
                  options={data.state.systemZipcodeListArray}
                  getOptionLabel={option => option.name ? option.name : ""}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder={"Search"}
                      fullWidth
                    />
                  )}
                />
            }
            </>
          )
        }
      }else if(v.fieldtype.id === "10" || v.fieldtype.id === "7" || v.fieldtype.id === "6"){
        let dataValFrom = data.state.filterRangeArray[v.fieldkey] ? data.state.filterRangeArray[v.fieldkey][0] : ""
        let dataValTo = data.state.filterRangeArray[v.fieldkey] ? data.state.filterRangeArray[v.fieldkey][1] : ""
        filterFieldsArray.push(<>
          <MaterialGrid container justify="space-between" style={{marginTop:'15px'}}>
              <MaterialGrid>
                  <span className="font-size-14 font-weight-500">{v.fieldlabel}</span>
              </MaterialGrid>
              <MaterialGrid className="flex flex-middle">
                  <Link  className="text_link" onClick={()=>data.handleToggleFieldDisplay(v.fieldkey)}>Select Range</Link>
              </MaterialGrid>
          </MaterialGrid>
          {data.state.fieldDisplay.includes(v.fieldkey) && <MaterialGrid container spacing={2} className="flex-nowrap label-size-14" alignItems="flex-end">
              <MaterialGrid item xs={12}>
                  <TextField
                      pattern="[0-9]*"
                      id="From"
                      label="From"
                      type="number"
                      fullWidth
                      value={dataValFrom}
                      onChange={(event) => data.rangeFilter({ fieldid: v.id, type:"range",value:event.target.value,case:0}, v.fieldkey)}
                  />
              </MaterialGrid>
              <MaterialGrid item>
                  <TextField
                      pattern="[0-9]*"
                      id="to"
                      label="To"
                      type="number"
                      fullWidth
                      value={dataValTo}
                      onChange={(event) => data.rangeFilter({ fieldid: v.id, type:"range",value:event.target.value,case:1}, v.fieldkey)}
                  />
              </MaterialGrid>
              <MaterialGrid item>
                  <IconButton
                      className="text-green"
                      aria-label="Apply"
                      color="primary"
                      size="small"
                      onClick={()=>data.rangeClick(v.fieldkey,v.id)}
                  >
                      <Icon>check_circle</Icon>
                  </IconButton>
              </MaterialGrid>
          </MaterialGrid>}
        </>)
      }else if(v.fieldtype.id === "3"){//Checkbox 
        let checkedValue = (data.state.filterObject[v.fieldkey] && data.state.filterObject[v.fieldkey].value[0] == "1") ? "1" : "0"
        filterFieldsArray.push(
          <>
          <MaterialGrid container justify="space-between" onClick={()=>data.dropdownMenu(v.fieldkey)} className="mt-16 cursor_poiter">
              <span className="font-size-14 font-weight-500">{v.fieldlabel}</span>
              <Icon>{data.state.dropdownhide.includes(v.fieldkey) ? 'expand_less' : 'expand_more'}</Icon>
          </MaterialGrid>
          {data.state.dropdownhide.includes(v.fieldkey) &&
              <FormControl className="w-100 input_bx">
                <FormControlLabel
                  style={{marginLeft:'12px',margin:'0px','marginBottom':'-11px'}}
                  control={
                    <Checkbox
                      name={v.fieldkey}
                      id={v.id}
                      checked={(checkedValue=="1")?true:false}
                      onChange={(event, value) => data.handleChangeFilter({ fieldid: v.id, name:v.fieldkey,type:"value",value:event.target.checked?["1"]:["0"] }, v.fieldkey)}
                      color="primary"
                    />
                  }
                  label={<span style={{fontSize:"13px"}}>{v.fieldlabel}</span>}
                />
              </FormControl>
          }
          </>
        )
      }else if(v.fieldtype.id == "8"){//custom date filter
        let dtFrom = data.state.filterRangeArray[v.fieldkey] ? data.state.filterRangeArray[v.fieldkey][0] : null
        let dtTo = data.state.filterRangeArray[v.fieldkey] ? data.state.filterRangeArray[v.fieldkey][1] : null
        
        filterFieldsArray.push(
          <>
            <MaterialGrid container justify="space-between" style={{marginTop:'15px'}}>
                <MaterialGrid>
                    <span className="font-size-14 font-weight-500">{v.fieldlabel}</span>
                </MaterialGrid>
                <MaterialGrid className="flex flex-middle">
                    <Link  className="text_link" onClick={()=>data.handleToggleFieldDisplay(v.fieldkey)}>Select Date Range</Link>
                </MaterialGrid>
            </MaterialGrid>
            {data.state.fieldDisplay.includes(v.fieldkey) && 
              <>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <FormControl className="w-100 input_bx">
                    <KeyboardDatePicker
                        label="From date"
                        format={data.state.dateformat.replace("DD", "dd").replace("YYYY", "yyyy")}
                        placeholder={data.state.dateformat.replace("DD", "dd").replace("YYYY", "yyyy").replace("MM", "mm")}
                        value={dtFrom?dtFrom:null}
                        onChange={(event) => data.rangeFilterDate({ fieldid: v.id, type:"date",value:event,case:0}, v.fieldkey)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                  </FormControl>
                  <FormControl className="w-100 input_bx">
                    <KeyboardDatePicker
                        label="To date"
                        minDate={dtFrom?dtFrom:null}
                        format={data.state.dateformat.replace("DD", "dd").replace("YYYY", "yyyy")}
                        placeholder={data.state.dateformat.replace("DD", "dd").replace("YYYY", "yyyy").replace("MM", "mm")}
                        value={dtTo?dtTo:null}
                        onChange={(event) => data.rangeFilterDate({ fieldid: v.id, type:"date",value:event,case:1}, v.fieldkey)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                  </FormControl>
                </MuiPickersUtilsProvider>
              </>
            }
          </>
        )
      }


    }
  })

  return filterFieldsArray;
}
