import React, { Component } from "react";
import ReportSidebarMenu from '../Layout/ReportSidebarMenu';
import "../../styles/_settings.scss"
import { Route, withRouter } from 'react-router-dom';
import PipelineSummary from "./PipelineSummary";
import SalesForecast from "./SalesForecast";
import SalesPerformance from "./SalesPerformance";
import SalesPerformanceSummary from "./SalesPerformanceSummary";
import SalesBySource from "./SalesBySource";
import ActivitiesLeaderboard from "./ActivitiesLeaderboard";
import ConversionFunnel from "./ConversionFunnel";
import LatestActivity from "./LatestActivity";
import MyOpenTask from "./MyOpenTasks"
import { connect } from "react-redux";
import * as actions from '../../store/actions/index';
import UnAuthorized from '../../components/views/UnAuthorized';


class ReportRoute extends Component {
    constructor(props) {
        super(props)
        this.state = {
            accessPage: [1, 2, 3]
        }
    }
    getUserPermission = () => {
        if (this.props.settingReducer.userPermissionDetails.data) {
            let mainData = []
            let udata = this.props.settingReducer.userPermissionDetails.data.data
            if (udata.reportVisibilityaccess.length > 0 &&
                udata.teampermissionstatus === true &&
                this.props.authReducer.userRole.id === 3) {
                udata.reportVisibilityaccess.map((report, i) => {
                    if (report.isaccess === true && this.state.accessPage.includes(report.reporttype)) {
                        mainData.push(report.reporttype)
                    }
                })
            } else {
                mainData = [1, 2, 3];
            }
            this.setState({ accessPage: mainData })
        }
    }
    componentWillReceiveProps(props) {
        this.getUserPermission()
    }
    // this method sets the current state of a menu item i.e whether it is in expanded or collapsed or a collapsed state
    handleClick(item) {
        this.setState(prevState => (
            { [item]: !prevState[item] }
        ))
    }
    render() {
        let defaultclassname = PipelineSummary;
        if (this.state.accessPage.includes(1)) {
            defaultclassname = PipelineSummary;
        } else if (this.state.accessPage.includes(2)) {
            defaultclassname = SalesForecast
        } else {
            defaultclassname = ActivitiesLeaderboard;
        }
        return (
            <React.Fragment>
                {
                    this.state.accessPage.length > 0 ?
                        <>
                            <ReportSidebarMenu />
                            <div className="lead_content right-16 settings_view crm-all-reports-page-wrapper">
                                <Route exact path="/reports/report-default/"
                                    component={defaultclassname} />

                                <Route exact path="/reports/sales-forecast/"
                                    component={this.state.accessPage.includes(2) ? SalesForecast : UnAuthorized} />

                                <Route exact path="/reports/sales-performance/"
                                    component={this.state.accessPage.includes(2) ? SalesPerformance : UnAuthorized} />

                                <Route exact path="/reports/sales-performance-summary/"
                                    component={this.state.accessPage.includes(2) ? SalesPerformanceSummary : UnAuthorized} />

                                <Route exact path="/reports/sales-by-source/"
                                    component={this.state.accessPage.includes(2) ? SalesBySource : UnAuthorized} />

                                <Route exact path="/reports/conversion-funnel/"
                                    component={this.state.accessPage.includes(2) ? ConversionFunnel : UnAuthorized} />

                                <Route exact path="/reports/activities-leaderboard/"
                                    component={this.state.accessPage.includes(3) ? ActivitiesLeaderboard : UnAuthorized} />

                                <Route exact path="/reports/latest-activities/"
                                    component={this.state.accessPage.includes(3) ? LatestActivity : UnAuthorized} />

                                <Route exact path="/reports/my-open-task/"
                                    component={this.state.accessPage.includes(3) ? MyOpenTask : UnAuthorized} />

                            </div>
                        </>
                        :
                        <UnAuthorized />
                }
            </React.Fragment>
        );
    }
}



const mapStateToProps = state => ({
    notification: state.notification,
    apolloClient: state.apolloClient,
    settingReducer: state.settingReducer,
    authReducer: state.authReducer
});
const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReportRoute));