import React, { Component } from "react";
import { Checkbox, Typography, Card, Grid, Button } from "@material-ui/core";
import { Table } from 'antd';
import BusinessIcon from '@material-ui/icons/Business';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PersonIcon from '@material-ui/icons/Person';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import moment, { now } from 'moment';
import RevertImportDialog from './RevertImportDialog';

//icons
import { Icon, InlineIcon } from '@iconify/react';
import userFriends from '@iconify/icons-fa-solid/user-friends';
import crosshairsIcon from '@iconify/icons-fa-solid/crosshairs';
import handshakeIcon from '@iconify/icons-fa-solid/handshake';
import businessTime from '@iconify/icons-fa-solid/business-time';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';

import * as actions from '../../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';
class ImportData extends Component {
    state = {
        pastImportFileList:[],
        revertImportDetail:[],
        dateformat: 'MM/DD/YYYY',
        timeZone: 'America/New_York',

        shouldOpenImportDialog: false,
        minimizeImportDialog: false,
        FullImportDialog: false,
        isSummary:false,
        field:0,
        leadsettingsstatus: false,
    };
    componentDidMount(){
        
        this.props.getImportFileList(this.props.apolloClient.client);
    }

    componentWillReceiveProps(props){
        
        if(props.settingReducer.pastImportFileList.data){
            this.setState({ pastImportFileList: props.settingReducer.pastImportFileList.data })
        }
        
        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            let usersetting = props.settingReducer.userSettingArray.data;

            let format = usersetting.dateformat;
            let timezone = usersetting.timezone;
            this.setState({ dateformat: format, timeZone: timezone })
        }
        if(props.settingReducer.leadsettingslist && props.settingReducer.leadsettingslist.data){
            this.setState({leadsettingslist: props.settingReducer.leadsettingslist.data});

            let leadsettingsstatus = props.settingReducer.leadsettingslist.data.lead;
            this.setState({leadsettingsstatus});
        }
    }

    //----------------------------------------------
    openImportDialog = () => {
        this.setState({ shouldOpenImportDialog: true });
    };

    handleImportDialogClose = () => {
        this.setState({ shouldOpenImportDialog: false, FullImportDialog: false, minimizeImportDialog: false });
    };

    handleImportDialogMinimize = () => {
        this.setState(oldStateminimizeImportDialog => ({ minimizeImportDialog: !oldStateminimizeImportDialog.minimizeImportDialog }));
        this.setState({ FullImportDialog: false })
    };

    handleImportDialogFull = () => {
        this.setState(oldStateFullImportDialog => ({ FullImportDialog: !oldStateFullImportDialog.FullImportDialog }));
        this.setState({ minimizeImportDialog: false })
    };

    importCompanies = () => {
        this.props.history.push('/settings/import-companies/');
    }

    importPeople = () => {
        this.props.history.push('/settings/import-people/');
    }

    importOpportunity = () => {
        this.props.history.push('/settings/import-opportunities/');
    }
    importLead = () => {
        this.props.history.push('/settings/import-leads/');
    }
    summaryImportDetail = (importid) =>{
        this.setState({ isSummary: true, field: importid})
        this.openImportDialog();
    }

    revertImportData = (field) => {
        this.setState({ field: parseFloat(field), isSummary: false});
        this.openImportDialog();
    }

    render() {
        let { leadsettingsstatus, pastImportFileList, shouldOpenImportDialog, shouldOpenImportFixDialog } = this.state;
        
        return (
            <React.Fragment>
                <div className="v_scroll crm-custom-importdata-settings">
                    <div className="mb-40">
                        <Typography className=" p-10" variant="h5" color="inherit">Import your Data</Typography>
                        <Typography className="text-hint p-10" variant="p">
                            Import data into IDrive CRM from Google Contacts, CSV files, or Excel files.
                        </Typography>
                    </div>
                    <Card className="p-20 mb-10">
                        <Typography className="font-size-18 p-10" variant="h6" color="inherit">Start a New Import</Typography>
                        <Grid container>
                            <Grid item md={leadsettingsstatus ? 3 : 4} className="text-center">
                                <div>
                                    <BusinessIcon className="text-hint font-size-100" style={{ fontSize: '70px' }} />
                                </div>
                                <div>
                                    <Button variant="contained" color="primary" className="btn_Primary" onClick={this.importCompanies} size="small">IMPORT COMPANIES</Button>
                                </div>
                            </Grid>
                            <Grid item md={leadsettingsstatus ? 3 : 4} className="text-center">
                                <div>
                                    <Icon icon={userFriends} className="text-hint" style={{ fontSize: '70px' }}></Icon>
                                </div>
                                <div>
                                    <Button variant="contained" color="primary" className="btn_Primary" onClick={this.importPeople} size="small">IMPORT CONTACTS</Button>
                                </div>
                            </Grid>
                            <Grid item md={leadsettingsstatus ? 3 : 4} className="text-center">
                                <div>
                                    <Icon icon={handshakeIcon} className="text-hint" style={{ fontSize: '70px' }}></Icon>
                                </div>
                                <div>
                                    <Button variant="contained" color="primary" className="btn_Primary" onClick={this.importOpportunity} size="small">IMPORT OPPORTUNITIES</Button>
                                </div>

                            </Grid>
                            {leadsettingsstatus && 
                                <Grid item md={3} className="text-center">
                                    <div>
                                        <Icon icon={crosshairsIcon} className="text-hint" style={{ fontSize: '70px' }}></Icon>
                                    </div>
                                    <div>
                                        <Button variant="contained" color="primary" className="btn_Primary" onClick={this.importLead} size="small">IMPORT LEADS</Button>
                                    </div>
                                </Grid>
                            }
                            
                        </Grid>
                    </Card>
                    <Card className="p-20 mt-10 mb-10">
                        <Typography className="font-size-18 p-10" variant="h6" color="inherit">Past Imports</Typography>
                        <div className="p-10">
                        {(pastImportFileList.length > 0) ?
                                pastImportFileList.map((fileData)=>(
                                    <Grid container >
                                        <Grid item md={2}>{fileData.filename}</Grid>
                                        <Grid item md={8}>{(fileData.fullfilename.split('.')[0])} by {fileData.user.name} on {moment.unix(fileData.createddate).tz(this.state.timeZone).format(this.state.dateformat) + " " + moment.unix(fileData.createddate).tz(this.state.timeZone).format("hh:mm A")}</Grid>
                                        <Grid item md={1}><a href="javascript:;" onClick={(field) => this.summaryImportDetail(fileData.id)}>Summary</a></Grid>
                                        <Grid item md={1}><a href="javascript:;" onClick={(field) => this.revertImportData(fileData.id)}>Revert Import</a></Grid>
                                    </Grid>
                                ))
                            :
                            <p >You have no imports on record. Any imports initiated henceforth will show up here!</p>
                        } 
                        </div>
                    </Card>
                    <Card className="p-20 mt-10 mb-10">
                        <Typography className="font-size-18 p-10" variant="h6" color="inherit">Tips For a Smooth Import</Typography>
                        <ul>
                            <li>Import Companies first, then Contacts and then Opportunities. Importing in this order will ensure that all records are related correctly. Leads can be imported at any time.</li>
                            <li>We recommend using our formatted Excel and CSV templates to ensure your data is formatted properly. You'll be given the option to download the templates during the import process. Learn more about the import process here.</li>
                            <li>We support file sizes up to 3MB. If your file is larger than 3MB, we suggest you break it up into multiple files.</li>
                            <li>It's a good idea to do a test with a smaller import file first to make sure your file works.</li>
                            <li>Set up your custom fields and contact types before you import so you can import those fields.</li>
                            <li>The first row of your import file must be the column headers you wish to import to.</li>
                            <li>If a column within the data table does not have a column header, your file will not import properly.</li>
                            <li>If there is data in a cell outside of the data table, your file will not import properly.</li>
                            <li>Importing tags with more than 50 characters will cause that import row to fail.</li>
                        </ul>
                    </Card>
                </div>
                {shouldOpenImportDialog &&
                    (<RevertImportDialog
                        handleClose={this.handleImportDialogClose}
                        open={shouldOpenImportDialog}
                        handleMinimize={this.handleImportDialogMinimize}
                        handleFull={this.handleImportDialogFull}
                        minimizeScreen={this.state.minimizeImportDialog}
                        fullScreen={this.state.FullImportDialog}
                        fullfilename={this.state.fileName}
                        selectedFields={this.state.selectedFields}
                        backToImport={this.backToImport}
                        oldColumnValues={this.state.oldColumnValues}
                        newColumnValues={this.state.newColumnValues}
                        isSummary={this.state.isSummary}
                        data={this}
                    />)}
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {
        settingReducer: state.settingReducer,
        apolloClient: state.apolloClient
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getImportFileList: (client) => dispatch(actions.getImportFileList(client)),
        revertImportData: (client,request) => dispatch(actions.revertImportData(client,request)),
        getRevertDetail: (client, request) => dispatch(actions.getRevertDetail(client, request)),
        
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ImportData);

