import React, { Component } from "react";
import { Grid, IconButton, Icon, Card, Fab, Badge, Paper, Box, CircularProgress, Tooltip, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { NavLink, withRouter } from "react-router-dom";
import moment from 'moment';
const IconSmall = withStyles(() => ({
  root: {
    fontSize: "1.5rem",
    color: 'rgba(0, 0, 0, 0.6)'
  }
}))(Icon);

const TooltipNew = withStyles((theme) => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);
class Quickbooks extends Component {
  state = {
    isOpenedQuickbook: false,
    invoiceList: [],
    dateformat: 'MM/DD/YYYY',
    timeZone: 'America/New_York',
    hasMore: true,
    totalCount: 0,
    currentPage: 1,
    isDataLoading: true,
    hasPagination: false,
    recordId: ""
  };
  componentWillUnmount() {
    this.props.settingReducer.relatedDataQuickBook = []
  }

  componentWillMount() {
    if (this.props.recordId) {
      this.setState({
        recordId: this.props.recordId,
      });
      var req = {
        id: this.props.recordId,
        type: this.props.recordtype === "opportunities" ? "opportunity" : this.props.recordtype,
        page: this.state.currentPage,
      }
      this.props.getQuickBookInvoice(req);
    }
  }
  componentWillReceiveProps(props) {
    if (props.recordId != this.state.recordId) {//if(props.recordId !== undefined && this.state.oppoFlag){
      this.setState({
        recordId: this.props.recordId,
        hasPagination: false,
        currentPage: 1
      }, () => {
        this.forceUpdate()
        var req = {
          id: props.recordId,
          type: props.recordtype === "opportunities" ? "opportunity" : props.recordtype,
          page: this.state.currentPage,
        }
        this.props.getQuickBookInvoice(req);
      })
    }
    if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
      let usersetting = props.settingReducer.userSettingArray.data;

      let format = usersetting.dateformat;
      let timezone = usersetting.timezone;

      this.setState({ dateformat: format, timeZone: timezone })
    }
    if (props.settingReducer.relatedDataQuickBook.data) {
      if (props.settingReducer.relatedDataQuickBook.data.data) {
        var data = props.settingReducer.relatedDataQuickBook.data.data;
        let totalRecord = props.settingReducer.relatedDataQuickBook.data.count;
        this.setState({
          totalCount: totalRecord,
          isDataLoading: false,
          hasMore: totalRecord > data.length ? true : false,
        }, () => this.forceUpdate())
        if (this.state.currentPage === 1) {
          this.setState({
            invoiceList: data,
            hasPagination: true,
          }, () => this.forceUpdate())
        }
      }
      if (props.settingReducer.relatedDataQuickBook.data.data.length > 0) {
        this.setState({ isOpenedQuickbook: true });
      }else{
        this.setState({ isOpenedQuickbook: false });
      }
    } else {
      setTimeout(() => {
        this.setState({ isDataLoading: false });
      }, 1000);
    }
  }
  componentDidUpdate = (prevProps) => {
    let thisPropsData = this.props.settingReducer.relatedDataQuickBook.data;
    let prevPropsData = prevProps.settingReducer.relatedDataQuickBook.data
    if (this.state.invoiceList.length > 0 && prevPropsData) {
      if (prevPropsData.data !== thisPropsData.data && this.state.invoiceList !== thisPropsData.data) {
        const data_new = this.state.invoiceList.concat(thisPropsData.data)
        this.setState({
          hasMore: data_new.length >= this.state.totalCount ? false : true,
          invoiceList: data_new,
        }, () =>
          setTimeout(() => {
            this.setState({
              isDataLoading: false
            })
          }, 1000))
      }
    }
  }
  fetchMoreData = (e) => {
    e.preventDefault();
    if (this.state.invoiceList.length >= this.state.totalCount) {
      this.setState({ hasMore: false });
      return;
    }
    this.getNextPageData(this.state.currentPage + 1)
  };
  getNextPageData = (page) => {
    this.setState({
      currentPage: page, isDataLoading: true
    }, () => {
      this.forceUpdate()
      var req = {
        id: this.props.recordId,
        type: this.props.recordtype === "opportunities" ? "opportunity" : this.props.recordtype,
        page: page,
      }
      this.props.getQuickBookInvoice(req);
    })
  }
  toggleQuickbook = () => {
    this.setState({ isOpenedQuickbook: !this.state.isOpenedQuickbook });
  }
  render() {
    const { isOpenedQuickbook, invoiceList } = this.state;
    let displayInvoiceList = []
    if (invoiceList.length > 0) {
      invoiceList.map((invoice, i) => {
        let invoiceLink = "https://qbo.intuit.com/app/invoice?txnId=" + invoice.id;
        let status = ""
        let color = '';
        let invoice_Date = "-"
        if (invoice.balance === 0) {
          color = "#546e7a"
          status = "PAID"
        } else if (invoice.balance > 0 && moment(invoice.due_at).isBefore(moment())) {
          color = "red"
          status = "OVERDUE"
        } else {
          color = "#08ad6c"
          status = "OPEN"
        }
        if (invoice.due_at) {
          invoice_Date = moment(invoice.due_at, 'YYYY-MM-DD').format(this.state.dateformat)
        }
        displayInvoiceList.push(
          <div className="box_portlet mt-10 crm-calendar-event" key={i}>
            <div className="box_body px-10 py-20">
              <div className="lead_pl" style={{ paddingLeft: '50px' }}>
                <div className="icon_left">
                  <Fab
                    size="small"
                    aria-label="Task"
                    className={`wrap_icons_task_sidebar vert-middle bg-grey_sidebar`}
                  >
                    <Icon className="activity_icon_normal">description</Icon>
                  </Fab>
                </div>
                <Grid container justify="space-between">
                  <h6 className="m-0 font-weight-600">Invoice #{invoice.doc_number}</h6>
                  <Box className="text-white hover_hide" style={{ padding: "0 4px", background: `${color}`, fontSize: "11px", height: "15px" }}>{status}</Box>
                  <Box className="Toggle_hover_hide" style={{ padding: "0 4px", height: "15px" }}>
                    <a href={invoiceLink} target="_blank">
                      <TooltipNew title="Open in QuickBooks" placement="top" arrow>
                        <IconSmall style={{ fontSize: '22px' }}>launch</IconSmall>
                      </TooltipNew>
                    </a>
                  </Box>
                </Grid>
                <span className="capitalize font-weight-500 font-size-13">{invoice.customer_name}</span>
                <Grid container justify="space-between">
                  <Grid item><span className="font-size-13 font-weight-500">Due:</span></Grid>
                  <Grid item><span className="font-size-13 font-weight-500">{invoice_Date}</span></Grid>
                </Grid>
                <Grid container justify="space-between">
                  <Grid item><span className="font-weight-500 font-size-13">Total Due:</span></Grid>
                  <Grid item><span className="font-weight-500 font-size-13">{invoice.currency}{" "}{invoice.total}</span></Grid>
                </Grid>
                <Grid container justify="space-between">
                  <Grid item><span className="font-weight-500 font-size-13">Balance Due:</span></Grid>
                  <Grid item><span className="font-weight-500 font-size-13">{invoice.currency}{" "}{invoice.balance}</span></Grid>
                </Grid>
              </div>
            </div>
          </div >
        )
      })
    }
    return (
      <Card elevation={3} className="p-16 mt-16" style={{ overflow: 'initial' }}>
        <Grid container justify="space-between">
          <h5 className="flex-auto cursor_poiter mb-0 pt-4"
            onClick={this.toggleQuickbook}>
            QuickBooks ({this.state.totalCount})
            <Icon className="vert-middle">{isOpenedQuickbook ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
          </h5>
        </Grid>
        {
          isOpenedQuickbook &&
          <>
            {
              (invoiceList.length == 0) ?
                <label className="text-hint">
                  <IconButton className="mr-8" component="span">
                    <Icon>description</Icon>
                  </IconButton>No Invoices</label>
                :
                <>
                  {displayInvoiceList}
                  {
                    this.state.isDataLoading ?
                      <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                        style={{ minHeight: '10vh', backgroundColor: 'rgba(0,0,0,0)' }}
                      >
                        <Grid item xs={3}>
                          <CircularProgress disableShrink size={25} />
                        </Grid>
                      </Grid>
                      :
                      this.state.hasMore && ((this.state.totalCount - invoiceList.length) > 0) &&
                      < Grid container item justify="center" className="mt-10">
                        <a href="" className="text-small text_link"
                          onClick={this.fetchMoreData}
                        >
                          SHOW {this.state.totalCount - invoiceList.length} MORE INVOICES
                           <Icon style={{ margin: "-8px 0px" }}>expand_more</Icon>
                        </a>
                      </Grid>
                  }
                </>
            }

          </>
        }
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
});

const mapDispatchToProps = dispatch => {
  return {
    getQuickBookInvoice: (req) => { dispatch(actionCreators.getQuickBookInvoice(req)) },
  }
}
export default (connect(mapStateToProps, mapDispatchToProps)(withRouter(Quickbooks)));