import React, { Component } from "react";
import { IconButton, Icon, Grid, MenuItem, withStyles, Fab, Button, DialogContent, Dialog, DialogActions, DialogTitle, Avatar, Card } from "@material-ui/core";

import { connect } from "react-redux";
import { compose } from 'redux';

import ConversionFunnel from "./ConversionFunnel"
import SalesBySource from "./SalesBySource"


class MarketingDashboard extends Component {
  state = {
  };
  render() {
    return (
      <React.Fragment>
          <Grid container>
            <Grid item xs={12}>
              <SalesBySource />
            </Grid>
            <Grid item xs={12}>
              <ConversionFunnel />
            </Grid>
          </Grid>        
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};
const mapDispatchToProps = dispatch => {
  return {

  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(MarketingDashboard);