import React, { Component } from "react";
import { Button, Card, Icon, DialogTitle, DialogActions, IconButton, withStyles, Dialog, Checkbox, CircularProgress, Fade, Snackbar, Tooltip, Fab, Avatar } from "@material-ui/core";
import { default as MaterialGrid } from '@material-ui/core/Grid';
import { Link } from "react-router-dom";
import {
    Grid,
    TableHeaderRow,
    Toolbar,
    VirtualTable,
    TableSelection,
    SearchPanel,
    Table,
    TableFixedColumns,
} from '@devexpress/dx-react-grid-material-ui';
import {
    SortingState,
    SearchState,
    IntegratedSorting,
    SelectionState,
    IntegratedSelection,
} from '@devexpress/dx-react-grid';
import AddTeamMember from "./AddTeamMember"
import * as actions from '../../../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';

const StyledTooltip = withStyles({
    tooltipPlacementTop: {
      margin: "7px 0",
    },
  })(Tooltip);
function LoadingState(props) {
    return (
        <td colSpan={4} className="">
            {!props.loading &&
                <div className="">
                    <div className="pt-20  font-size-20 font-weight-500 text-center text-hint">No Members Yet</div>
                </div>
            }
        </td>
    );
}

class MemberList extends Component {
    state = {
        selection: [],
        shouldOpenDialog: false,
        page: this.props.team,
        isDeleteConfirmDialogOpen: false,
        teamMemberList: [],
        isDataLoading: true,
    };
    componentDidMount() {
        let getTeamParams = { teamid: parseFloat(this.props.teamid) };
        this.props.getTeamMember(this.props.apolloClient.client, getTeamParams)
    }
    componentWillReceiveProps(props) {
        if (props.settingReducer.teamMemberList.data) {
            const teamMemberList = props.settingReducer.teamMemberList.data;
            this.setState({ teamMemberList: teamMemberList, isDataLoading: false })
        } else {
            setTimeout(() => {
                this.setState({
                    isDataLoading: false,
                })
            }, 1500);
        }
    }
    setSelection = (val) => {
        this.setState({ selection: val })
    }
    handleIsManger = (val, i) => {

        let req = {
            teamid: parseFloat(this.props.teamid),
            ismanager: !val.teammanager,
            userid: parseFloat(val.user.id),
            memberid: parseFloat(val.id)
        }
        this.setState({ isDataLoading: true });
        this.props.saveTeamMember(this.props.apolloClient.client, req)
    }
    handleDialogClose = () => {
        this.setState({ shouldOpenDialog: false });
    };
    toggleDeleteDialog = () => {
        this.setState({ isDeleteConfirmDialogOpen: !this.state.isDeleteConfirmDialogOpen });
    };
    confirmDeleteCustomField = () => {
        let deleteMemberParams = {
            memberid: parseFloat(this.state.deleteId),
            teamid: parseFloat(this.props.teamid)
        };
        this.setState({ deleteId: "", isDataLoading: true });
        this.props.deleteTeamMember(this.props.apolloClient.client, deleteMemberParams)
        this.toggleDeleteDialog()
    };
    handleDeleteMember = (id) => {
        this.toggleDeleteDialog()
        this.setState({ deleteId: id })
    }
    render() {
        let hasEditAccess = false;
        if ((this.props.userRole !== 3) || (this.props.userRole === 3 && this.props.isTeamManger)) {
            hasEditAccess = true
        } else {
            hasEditAccess = false
        }
        const tableColumnExtensions = [{}];
        const columns = [
            {
                name: "name",
                title: "Name"
            },
            {
                name: "role",
                title: (
                    <>
                        Team Manager {" "}
                        <StyledTooltip placement="top" arrow={true}
                            title="Team Managers can edit team settings and add members to their teams. Team members can’t access team settings or add new members.">
                            <i className="fa fa-question-circle-o text-primary"></i>
                        </StyledTooltip>
                    </>
                )
            },
            {
                name: "Action",
                title: " "
            },
        ];

        let rows = [];
        if (this.state.teamMemberList.length > 0) {
            this.state.teamMemberList.map((val, i) => {
                rows.push(
                    {
                        name: (
                            <MaterialGrid container alignItems="center">
                                <MaterialGrid item>
                                    <Fab
                                        size="small"
                                        aria-label="Task"
                                        className={`wrap_icons_task_sidebar vert-middle bg-grey_sidebar`}
                                    >
                                        <Avatar className="bg-body">
                                            <Icon className="activity_icon_normal">person</Icon>
                                        </Avatar>
                                    </Fab>
                                </MaterialGrid>
                                <MaterialGrid item>
                                    {val.user &&
                                        <>
                                            <h5 className="d-inline-flex pl-10">
                                                <Link
                                                    to={"/profile/company_user/" + val.user.id}
                                                    className="company_user_name">
                                                    {val.user.name}
                                                </Link>

                                            </h5>
                                            <p className="m-0 p-0 pl-10">{val.user.email}</p>
                                        </>
                                    }
                                </MaterialGrid>
                            </MaterialGrid>
                        ),
                        role:
                            <Checkbox
                                checked={val.teammanager}
                                color="primary"
                                disabled={!hasEditAccess}
                                onChange={() => this.handleIsManger(val, i)} />,
                        Action: (
                            hasEditAccess &&
                            <IconButton onClick={(id) => this.handleDeleteMember(val.id)}>
                                <Icon>delete</Icon>
                            </IconButton>
                        )
                    })
            })
        }
        const toolbarMember = params => {
            return (
                <div className="d-flex mt-16 mb-8 ml-16 mr-16">
                    {params.children}
                    {this.state.selection.length ?
                        <MaterialGrid className="flex flex-middle ml-10">
                            {/* <Button variant="contained" color="secondary" size="small">
                                Delete
                                    </Button> */}
                        </MaterialGrid>
                        :
                        ''
                    }
                    {hasEditAccess &&
                        <div className="d-inline-block ml-8">
                            <Button variant="contained" color="primary"
                                onClick={() => this.setState({ shouldOpenDialog: true })} size="small" className="btn_Primary" style={{ minWidth: 95 }}>
                                Add Team Member </Button>
                        </div>
                    }
                </div>
            );
        };
        return (
            <div className="v_scroll position-relative">
                <div className="leads_list_table">
                    <Grid rows={rows} columns={columns}>
                        <SelectionState
                            selection={this.state.selection}
                            onSelectionChange={this.setSelection}
                        />
                        <IntegratedSelection />
                        <VirtualTable />
                        {/* <SearchState defaultValue="" /> */}

                        {/* <SortingState /> */}
                        {/* <IntegratedSorting /> */}
                        <Table columnExtensions={tableColumnExtensions}
                            noDataCellComponent={() =>
                                <LoadingState componetname="Lead" icon="supervised_user_circle" loading={this.state.isDataLoading} />} />
                        <TableSelection showSelectAll highlightRow />

                        <TableHeaderRow />

                        <TableFixedColumns />
                        <Toolbar rootComponent={toolbarMember} />
                        {/* <SearchPanel /> */}
                    </Grid>

                    {this.state.shouldOpenDialog && (
                        <AddTeamMember
                            handleClose={this.handleDialogClose}
                            open={this.state.shouldOpenDialog}
                            teamid={this.props.teamid}
                        />
                    )}
                    {this.state.isDeleteConfirmDialogOpen &&
                        <Dialog
                            fullWidth={true}
                            maxWidth={'xs'}
                            open={this.state.isDeleteConfirmDialogOpen}
                            onClose={this.toggleDeleteDialog}
                            disableBackdropClick
                            // disableEscapeKeyDown
                            aria-labelledby="ar-remove-dialog-title"
                        >
                            <DialogTitle id="ar-remove-dialog-title">{"Are you sure to remove selected Team Member ?"}</DialogTitle>
                            <DialogActions>
                                <Button onClick={this.toggleDeleteDialog} color="primary">
                                    Cancel
                                </Button>
                                <Button type="Button" onClick={this.confirmDeleteCustomField} className="btn_Primary">
                                    Ok
                                </Button>
                            </DialogActions>
                        </Dialog>
                    }
                </div>
                {this.state.isDataLoading &&
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        open={this.state.isDataLoading}
                        TransitionComponent={Fade}
                        message={<><CircularProgress size={20} style={{ color: 'white' }} /><span style={{ paddingLeft: '6px', color: 'white' }}>{" Loading..."}</span></>}
                        variant={'success'}
                    />
                }
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        settingReducer: state.settingReducer,
        apolloClient: state.apolloClient
    };
};

const mapDispatchToProps = dispatch => {
    return {
        saveTeamMember: (client, req) => dispatch(actions.saveTeamMember(client, req)),

        getTeamMember: (client, req) => dispatch(actions.getTeamMember(client, req)),
        deleteTeamMember: (client, req) => dispatch(actions.deleteTeamMember(client, req)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(MemberList);