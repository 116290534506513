import React, { Component } from "react";
import {
    Button, withStyles, CardContent, Icon,
    DialogActions, DialogTitle, DialogContent,
    Typography, Dialog, IconButton,
} from "@material-ui/core";
import { connect } from "react-redux";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import * as actionCreators from '../../../store/actions/index';
import ZendeskDialog from './Zendesk/ZendeskDialog';
import MailChimpDialog from './MailChimpDialog';
import Server from "../../../Common/Server"

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.5rem",
        color: 'rgba(0, 0, 0, 0.6)'
    }
}))(Icon);

class NativeIntegrations extends Component {
    state = {
        isDataLoading: true,
        settings: [
            {
                id: 4,
                heading: "Google Drive",
                icon: {
                    color: "#1fe58c"
                },
                subtitle: "Browse and link files and folders",
                path: "/assets/images/intregation_logo/google_drive_logo.png",
                Status: false,
                settingUrl: ""
            },
            {
                id: 6,
                heading: "MailChimp",
                icon: {
                    color: "#38a3fc"
                },
                subtitle: "Add Contacts to MailChimp Lists",
                path: "/assets/images/intregation_logo/mailchimp_freddie.png",
                Status: false,
                settingUrl: ""
            },
            {
                id: 3,
                heading: "Dropbox",
                icon: {
                    color: "#87bfe4"
                },
                subtitle: "Browse and link files and folders",
                path: "/assets/images/intregation_logo/dropbox_logo.png",
                Status: false,
                settingUrl: ""
            },
            {
                id: 2,
                heading: "Slack",
                icon: {
                    color: "#EA759A"
                },
                subtitle: "Real-time opportunity updates",
                path: "/assets/images/intregation_logo/slack-logo.png",
                Status: false,
                settingUrl: "/settings/slack/integrations"
            },
            {
                id: 1,
                heading: "Zendesk",
                icon: {
                    color: "#68868B"
                },
                subtitle: "View tickets on related records",
                path: "/assets/images/intregation_logo/zendesk_logo.png",
                Status: false,
                settingUrl: "/settings/zendesk/integrations"
            },
            {
                id: 7,
                heading: "Xero",
                icon: {
                    color: "#76D2E7"
                },
                subtitle: "View invoices on related records",
                path: "/assets/images/intregation_logo/xero_logo.png",
                Status: false,
                settingUrl: "/settings/xero/integrations"
            },
            {
                id: 9,
                heading: "DocuSign",
                icon: {
                    color: "#DB7468"
                },
                subtitle: "Track signatures with DocuSign",
                path: "/assets/images/intregation_logo/DocuSign.png",
                Status: false,
                settingUrl: "/settings/docuSign/integrations"
            },
            {
                id: 8,
                heading: "Quickbook",
                icon: {
                    color: "#FFF"
                },
                subtitle: "View invoices on related records",
                path: "/assets/images/intregation_logo/quickbooks_logo.png",
                Status: false,
                settingUrl: "/settings/quickbook/integrations"
            },
            {
                id: 5,
                heading: "Zapire",
                icon: {
                    color: "#FFF"
                },
                subtitle: "View invoices on related records",
                path: "/assets/images/intregation_logo/quickbooks_logo.png",
                Status: false,
                settingUrl: ""
            },
            {
                id: 10,
                heading: "RingCentral",
                icon: {
                    color: "#f5bb63"
                },
                subtitle: "Make, receive, and log calls",
                path: "/assets/images/intregation_logo/ring-central-logo.png",
                Status: false,
                settingUrl: ""
            },
        ],
        shouldOpenZendeskDialog: false,
        shouldOpenMailChimpDialog: false,
        QuickBook: false,
        MailChimpAPIKey: '',
        MailChimpdata: [],
    };

    componentDidMount() {
        this.props.getUserIntegration(this.props.apolloClient.client);
        let val = 6;
        this.props.getIntegrationDetail(this.props.apolloClient.client, val);
    }

    componentWillReceiveProps(props) {
        if (props.settingReducer.userItegration) {
            let data = props.settingReducer.userItegration.data;
            if (data) {
                data.map((log) => {
                    var test = [... this.state.settings]
                    var found = test.find(function (element) {
                        return element.id === log.integrationid
                    });
                    found.Status = log.status;
                    this.setState({
                        isDataLoading: false,
                        settings: test
                    }, () => this.forceUpdate())

                    if (log.status && log.integrationid == 10) {
                        console.log('Native Intregation');
                        var rcs = document.createElement("script");
                        rcs.src = "https://ringcentral.github.io/ringcentral-embeddable/adapter.js?&disableGlip=true&disableConferenceCall=true&disableMessages=true&clientId=hPpoApgkSt2Hy1VZqUrrhQ";
                        var rcs0 = document.getElementsByTagName("script")[0];
                        rcs0.parentNode.insertBefore(rcs, rcs0);
                        if (window.RCAdapter) {
                            window.RCAdapter.setMinimized(false);
                            window.RCAdapter.setClosed(false);
                        }
                    }
                });
            }

        }
        if (props.settingReducer.getintegationDetails.data) {
            const data = props.settingReducer.getintegationDetails.data;
            if (data.integrationid === 6) {
                this.setState({
                    isDataLoading: false,
                    MailChimpdata: data,
                    MailChimpAPIKey: data.accesstoken,
                })
            }
        }
        if (props.settingReducer.MailChimpVerifyData.data) {
            const data = props.settingReducer.MailChimpVerifyData.data;
            this.setState({
                isDataLoading: false,
                MailChimpAPIKey: data.token,
            })
        }
    }

    handleDisConnected = (id, status) => {
        let req = {};
        req.status = false;
        req.integrationid = id;
        this.props.UpdateIntegrationStatus(this.props.apolloClient.client, req);
        let test = [... this.state.settings]
        let found = test.find(function (element) { return element.id === id });
        found.Status = false;
        this.setState({ settings: test });
        if (id === 10 && found.Status === false) {
            if (window.RCAdapter) {
                window.RCAdapter.setClosed(true);
            }
        }
    }

    handleConnected = (id, status) => {
        //Zendesk Connect button
        if (id === 1 && status === false) {
            this.setState({ shouldOpenZendeskDialog: true })
        }
        //Slack Connect button
        if (id === 2 && status === false) {
            const sessionToken = localStorage.getItem('jwt_token');
            var CallBackApi = Server.Intregation_URI + "slack/connect/?code=" + sessionToken
            window.location.href = CallBackApi;
        }
        //Goggle Drive & Dropbox connect button
        if (id === 3 || id === 4) {
            let req = {};
            req.status = !status;
            req.integrationid = id;
            this.props.UpdateIntegrationStatus(this.props.apolloClient.client, req);
            let test = [... this.state.settings]
            let found = test.find(function (element) { return element.id === id; });
            found.Status = !status;
            this.setState({ settings: test });
        }
        //MAilChimp Connect Button
        if (id === 6 && status === false) {
            this.setState({ shouldOpenMailChimpDialog: true });
        }
        //Xero Connect button
        if (id === 7 && status === false) {
            const sessionToken = localStorage.getItem('jwt_token');
            var CallBackApi = Server.Intregation_URI + "xero/connect/?code=" + sessionToken
            console.log("xero CallBackApi", CallBackApi)
            window.location.href = CallBackApi;
        }
        //QuickBook Connect Button
        if (id === 8 && status === false) {
            this.setState({ QuickBook: !this.state.QuickBook });
        }

        //Dosigun Connect Button
        if (id === 9 && status === false) {
            // https://devcrmpython.kanhacrm.xyz/ds/connect/?code=
            const sessionToken = localStorage.getItem('jwt_token');
            var CallBackApi = Server.Intregation_URI + "ds/connect/?code=" + sessionToken
            console.log("DocuSign CallBackApi", CallBackApi)
            window.location.href = CallBackApi;
        }
        //Ring Central Connect Button
        if (id === 10) {
            this.handleConnectRingCentral();
        }
    }

    handleConnectRingCentral = () => {
        var rcs = document.createElement("script");
        rcs.src = "https://ringcentral.github.io/ringcentral-embeddable/adapter.js?&disableGlip=true&disableConferenceCall=true&disableMessages=true&clientId=hPpoApgkSt2Hy1VZqUrrhQ";
        var rcs0 = document.getElementsByTagName("script")[0];
        rcs0.parentNode.insertBefore(rcs, rcs0);
        if (window.RCAdapter) {
            window.RCAdapter.setMinimized(false);
            window.RCAdapter.setClosed(false);
        }
        let req = {};
        req.status = true;
        req.integrationid = 10;
        this.props.UpdateIntegrationStatus(this.props.apolloClient.client, req);
        let test = [... this.state.settings]
        let found = test.find(function (element) { return element.id === 10; });
        found.Status = true;
        this.setState({ settings: test });
    }


    openSetting = (settingUrl) => {
        window.location.href = settingUrl;
    }

    handleOnClickQuick = () => {
        const sessionToken = localStorage.getItem('jwt_token');
        var CallBackApi = Server.Intregation_URI + "quickbook/connect/?code=" + sessionToken
        console.log("quickbook CallBackApi", CallBackApi)
        window.location.href = CallBackApi;
    }

    handleZendeskDialogClose = () => {
        this.setState({ shouldOpenZendeskDialog: false });
    };
    handleClose = () => {
        this.setState({ open: !this.state.open });
    }
    handleClosebook = () => {
        this.setState({ QuickBook: !this.state.QuickBook });
    }
    handleMailChimpDialogClose = () => {
        this.setState({ shouldOpenMailChimpDialog: false });
    }

    render() {
        let { settings, open, QuickBook, shouldOpenZendeskDialog, shouldOpenMailChimpDialog, MailChimpAPIKey } = this.state;
        return (
            <React.Fragment >
                <div className="IntegrationBlock_contents"  >
                    {settings.map((val, index) => (
                        val.heading === 'Zapire' ?
                            "" :
                            <div className="Integrations_card">
                                <CardContent style={{ background: val.icon.color }}>
                                    <div className="d-flex">
                                        <div className="itergation_logo" >
                                            <img className="itergation_logo_size" src={val.path} />
                                        </div>
                                        <div className="IntegrationCard_title font-weight-600 font-size-18"
                                            style={{ color: val.id === 8 ? "black" : "white", width: "700px" }} >
                                            {val.heading}
                                            <div className="IntegrationCard_title text-small" style={{ color: val.id === 8 ? "black" : "white" }}>
                                                {
                                                    val.Status === false ?
                                                        val.subtitle :
                                                        val.id === 6 && MailChimpAPIKey ?
                                                            <div className="IntegrationCard_title text-small" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} >
                                                                API Key : {MailChimpAPIKey}
                                                            </div> :
                                                            val.subtitle
                                                }
                                            </div>
                                        </div>
                                        {val.Status === true &&
                                            <div className="IntegrationCard_activeTag">
                                                <div className="active__button"></div>
                                                <div className="ActiveFont">ACTIVE</div>
                                            </div>
                                        }
                                    </div>
                                </CardContent>
                                <div className="Alloy-CardSection-footer">
                                    {
                                        val.Status === false ?
                                            <>
                                                {/* <Button size="small" color="primary">
                                                    <div className="Btn Btn-flatPrimary">LEARN MORE</div>
                                                </Button> */}
                                                <Button size="small" color="primary" onClick={() => this.handleConnected(val.id, val.Status)}>
                                                    <div className="Btn Btn-flatPrimary">
                                                        {val.id === 8 ? "GET STARTED" : "CONNECT"}
                                                    </div>
                                                </Button>
                                            </>
                                            :
                                            <>
                                                <p>
                                                    {val.id === 1 || val.id === 8 || val.id === 7 || val.id === 2 || val.id === 9 ?
                                                        <Button size="small" color="primary"
                                                            onClick={() => this.openSetting(val.settingUrl)}>
                                                            <div className="Btn Btn-flatPrimary">SETTINGS</div>
                                                        </Button>
                                                        : ""
                                                    }
                                                </p>
                                                <Button size="small" color="primary" onClick={() => this.handleDisConnected(val.id, val.Status)}>
                                                    <div className="Btn Btn-flatPrimary">DISCONNECT</div>
                                                </Button>
                                            </>
                                    }
                                </div>
                            </div>
                    ))}
                    {shouldOpenZendeskDialog && (
                        <ZendeskDialog
                            handleClose={this.handleZendeskDialogClose}
                            open={shouldOpenZendeskDialog}
                        />
                    )}
                    {shouldOpenMailChimpDialog && (
                        <MailChimpDialog
                            handleClose={this.handleMailChimpDialogClose}
                            open={shouldOpenMailChimpDialog}
                        />
                    )}
                    <Dialog maxWidth={'md'} className="crm_dialog_setting" onClose={this.handleClosebook} disableBackdropClick={true} 
                    // disableEscapeKeyDown={true} 
                    open={QuickBook}>
                        <DialogTitle>
                            <Typography className="p-10" variant="h5" color="inherit">QuickBooks Integration</Typography>
                            <div className="clear_icon2 hover_show">
                                <IconButton aria-label="Delete" size="small" onClick={this.handleClosebook}>
                                    <IconSmall style={{ fontSize: '18px' }}>clear</IconSmall>
                                </IconButton>
                            </div>
                        </DialogTitle>
                        <DialogContent>
                            <div style={{ margin: '10px' }}>
                                <img style={{ width: '50%' }} src="/assets/images/intregation_logo/quickbooks-logo-horz.png" />
                                <div className="Zend_Integrations_card font-size-18" style={{ margin: "10px" }}>
                                    Get the complete picture of your customer with their interaction history and financial information on one page
                                </div>
                                <div className="Zend_Integrations_card font-size-18" style={{ margin: "10px" }}>
                                    You will be directed to QuickBooks to connect
                                </div>
                                <Button size="small" onClick={this.handleOnClickQuick} className="QuickBooksIntegrationCard_connectButton">
                                    <img src='/assets/images/intregation_logo/C2QB_green_btn_lg_default.png' />
                                </Button>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button size="small" color="primary" onClick={this.handleClosebook} style={{ borderRadius: '10px', width: '100px' }} >Cancel</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </React.Fragment>
        );
    }
}


const mapStateToProps = state => {
    return {
        apolloClient: state.apolloClient,
        settingReducer: state.settingReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getUserIntegration: (client) => dispatch(actionCreators.getUserIntegration(client)),
        UpdateIntegrationStatus: (client, request) => dispatch(actionCreators.UpdateIntegrationStatus(client, request)),
        getIntegrationDetail: (client, request) => dispatch(actionCreators.getIntegrationDetail(client, request)),
    }
}
export default (connect(mapStateToProps, mapDispatchToProps)(NativeIntegrations));