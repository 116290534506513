import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom'
import "../styles/_app.scss";
import Leads from "./Leads/Leads";
import LeadsList from "./Leads/LeadsList";
import Contacts from "./Contacts/Contact";
import ContactList from "./Contacts/ContactList";
import Company from "./Company/Company";
import CompanyList from "./Company/CompanyList";
import TasksList from "./Tasks/TasksList";
import Opportunities from "./Opportunities/Opportunities";
import OpportunitiesList from "./Opportunities/OpportunitiesList";
import Project from "./Projects/Project";
import ProjectList from "./Projects/ProjectList";
import TopNavbar from "./Layout/TopNavbar";
import OpportunitiesCard from "./Opportunities/OpportunitiesCard";
import ProjectCard from "./Projects/ProjectsCard";
import Settings from "./Settings/Settings";
import { connect } from "react-redux";
import { IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { green } from '@material-ui/core/colors';
import GoogleLogins from '../components/views/google_login';
import ByGoogleLogin from '../components/views/by_google_login';
import SignIn from '../components/views/sessions/SignIn';
import SignUp from '../components/views/sessions/SignUp';
import ForgotPassword from '../components/views/sessions/ForgotPassword';
import Snackbar from '@material-ui/core/Snackbar';
import CheckEmail from '../components/views/email/CheckEmail';
import UserCredential from '../components/views/sessions/UserCredential';
import Page404 from '../components/views/page404';
import InfoPage from './InfoPage';
import MultipleCompany from '../components/views/dashboard/MultipleCompany';
import { ApolloProvider } from '@apollo/react-hooks';
import Profile from "./Profile/profile";
import NotificationAppbar from "./Layout/NotificationAppBar";
import Crome from "../crome";
import ProgressionReport from "./Opportunities/ProgressionReport";
import TrackEmailList from "./CommonComposeMail/TrackEmailList";
import ReportRoute from "./Reports/ReportRoute";
import companyUser from "./Profile/companyUser"
import * as actionCreators from '../store/actions/index'
import UnAuthorized from '../components/views/UnAuthorized';
import MasterSerach from './Layout/MasterSearch/MasterSearch';
import AddUserSession from './AddUserSession';


import { createBrowserHistory } from "history";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: false,
      renderNow: false,
      open: false,
      message: '',
      color: '',
      bgcolor: '',
      openFixedUserDetail: true,
      isShowMultiCompany: false,
      accessPage: [0, 1, 2, 3, 4, 5],
    }
  }
  componentWillMount() {
    var userDataSession = JSON.parse(localStorage.getItem('user_data_session'));
    this.chkCompanyIdUser();
    if (userDataSession !== null) {
      this.props.getLoginUserPermission(this.props.apolloClient.client);
      this.props.setUserRole();
      this.setState({ isLogin: true });
      if (userDataSession.isMultiple == true) {
        let getMultipleCompanyId = localStorage.getItem('setMultipleCompanyId');
        if (getMultipleCompanyId !== null) {
          this.setState({ isLogin: true });
        } else {
          this.setState({ isShowMultiCompany: true })
        }
      }
    }
  }

  componentWillReceiveProps(props) {
    if (props.settingReducer.userPermissionDetails.data) {
      let mainData = []
      let udata = props.settingReducer.userPermissionDetails.data.data
      if (udata.visibilityaccess.length > 0 &&
        udata.teampermissionstatus === true &&
        this.props.authReducer.userRole.id === 3) {
        udata.visibilityaccess.map((page, i) => {
          if (page.isaccess === true) {
            mainData.push(page.sourcetype)
          }
        })
      } else {
        mainData = [1, 2, 3, 4, 5]
      }
      this.setState({ accessPage: mainData })
    }
    if (props.notification.message) {
      if (props.notification.message.error) {
        this.setState({
          message: props.notification.message.notify_msg,
          color: '#b50012',
          bgcolor: "#f8d7da",
          renderNow: true,
          open: true
        })
      } else {
        this.setState({
          message: props.notification.message.notify_msg,
          color: '#0e630f',
          bgcolor: "#d1edb9",
          renderNow: true,
          open: true
        })
      }
      let that = this;
      setTimeout(
        function () {
          that.toggleSnackbar();
        }.bind(this),
        4000
      );
    }
  }
  chkCompanyIdUser() {
    //console.log("chkCompanyIdUser==>")
  }

  toggleSnackbar = () => {
    this.props.clearNotification()
    this.setState({ open: false })
  }
  renderRoutes = () => {
    return (
      <div >
        <Route path='/' exact component={SignIn} />
        <Route path='/crome' exact component={Crome} />
        <Route path="/google-login" component={GoogleLogins} />
        <Route path='/by-google-login' component={ByGoogleLogin} />
        <Route path='/sign-in' exact component={SignIn} />
        <Route path='/sign-up' component={SignUp} />
        <Route path='/forgot-password' component={ForgotPassword} />
        <Route path='/email/confirm-email' component={CheckEmail} />
        <Route path='/password' component={UserCredential} />
      </div>
    );
  }

  render() {
    let { accessPage } = this.state;
    var userDataSession = JSON.parse(localStorage.getItem('user_data_session'));

    var notify;
    if (this.state.renderNow) {
      notify =
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          onClick={this.toggleSnackbar}
          open={this.state.open}
          autoHideDuration={700000}
          ContentProps={{
            'role': 'alertdialog',
            // style: { background: this.state.bgcolor, color: this.state.color, marginTop: '35px' },
            style: { background: this.state.bgcolor, marginTop: '35px' },
          }}
          message={<span style={{ color: this.state.color }}>{this.state.message}</span>}
          action={
            <IconButton key="close" aria-label="close" color="inherit">
              <CloseIcon />
            </IconButton>
          }
        />
    }
    let notloginRoutes = ['/crome', "/google-login", '/by-google-login', '/sign-in', '/forgot-password', '/email/confirm-email', '/password'];
    const history = createBrowserHistory();


    return (
      <>
        <Router>
          <Route path='/404' component={Page404} />
          <Route exact path="/openWeb/:data" component={AddUserSession} />

          {this.state.isShowMultiCompany ?
            <MultipleCompany data={this} />
            :
            ''
          }
          {this.state.isLogin && !this.state.isShowMultiCompany ?
            <React.Fragment>
              <TopNavbar />
              <Route exact path="/" component={InfoPage} />
              <Route exact path="/unauthorized" component={UnAuthorized} />

              <Route exact path="/lead/leads-list/"
                component={accessPage.includes(1) ? LeadsList : UnAuthorized} />
              <Route exact path="/lead/lead/:id"
                component={accessPage.includes(1) ? Leads : UnAuthorized} />

              <Route exact path="/contact/contact-list/"
                component={accessPage.includes(2) ? ContactList : UnAuthorized} />
              <Route exact path="/contact/contact/:id"
                component={accessPage.includes(2) ? Contacts : UnAuthorized} />

              <Route exact path="/company/company-list/"
                component={accessPage.includes(3) ? CompanyList : UnAuthorized} />
              <Route exact path="/company/company/:id"
                component={accessPage.includes(3) ? Company : UnAuthorized} />


              <Route exact path="/opportunities/opportunities-list/"
                component={accessPage.includes(4) ? OpportunitiesList : UnAuthorized} />
              <Route exact path="/opportunities/opportunities-card/"
                component={accessPage.includes(4) ? OpportunitiesCard : UnAuthorized} />
              <Route exact path="/opportunities/opportunities/:id"
                component={accessPage.includes(4) ? Opportunities : UnAuthorized} />
              <Route exact path="/opportunities/progression-report/"
                component={accessPage.includes(4) ? ProgressionReport : UnAuthorized} />

              <Route exact path="/project/projects/:id"
                component={accessPage.includes(5) ? Project : UnAuthorized} />
              <Route exact path="/project/projects-list/"
                component={accessPage.includes(5) ? ProjectList : UnAuthorized} />
              <Route exact path="/project/projects-card/"
                component={accessPage.includes(5) ? ProjectCard : UnAuthorized} />


              <Route exact path="/task/task-list" component={TasksList} />

              <Route exact path="/tracked-emails-list/"
                component={userDataSession.googlestatus === true ? TrackEmailList : Page404} />

              <Route exact path="/settings/:page/" component={Settings} />
              <Route exact path="/settings/:page/:id" component={Settings} />

              <Route exact path="/reports/:page/" component={ReportRoute} />

              <Route exact path="/profile/company_user/:userid/" component={companyUser} />
              <Route exact path="/user/Profile/" component={Profile} />
              <Route exact path="/search-results/:phrase" component={MasterSerach} />

            </React.Fragment>
            :
            this.renderRoutes()
            // (notloginRoutes.indexOf(history.location.pathname) != -1) ?
            //   this.renderRoutes()
            //   :
            //   <div >
            //     {this.renderRoutes()}
            //     <Redirect path="/sign-in" />
            //   </div>
          }
          {notify}
        </Router>
      </>
    );
  }
}

const mapStateToProps = state => ({
  userData: state.authReducer,
  notification: state.notification,
  apolloClient: state.apolloClient,
  settingReducer: state.settingReducer,
  authReducer: state.authReducer

});
const mapDispatchToProps = dispatch => {
  return {
    getLoginUserPermission: (client) => dispatch(actionCreators.getLoginUserPermission(client)),
    setUserRole: () => dispatch(actionCreators.setUserRole()),
    clearNotification: () => dispatch(actionCreators.clearNotification()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);