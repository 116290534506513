import React, { Component } from "react";
import ReactDragListView from "react-drag-listview";
import { Table } from 'antd';
import Files from "../Common/Files";
import Tasks from "../Common/Tasks";
import Calendar from "../Common/Calendar";
import Projects from "../Common/Projects";
import People from "../Common/People";
import Companies from "../Common/Companies";
import Opportunity from "../Common/Opportunity";
import Zendesk from '../Common/Zendesk';
import MailChimp from '../Common/MailChimp';
import Xero from '../Common/Xero';
import Quickbooks from '../Common/Quickbook';
import DocuSign from '../Common/DocuSign';
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';

class ListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            leaddata: [],
            rows: [],
            recordId: "",
            recordtype: "",
            itegrationDetails: [],
            hasQuickbookConnect: false,
            hasZendeskConnect: false,
            hasXeroConnect: false,
            hasMailChimpConnect: false,
            hasDocusingConnect: false,
        };
    }
    componentDidMount() {
        if (this.props.recordId) {
            this.setState({
                recordId: this.props.recordId,
                recordtype: this.props.recordtype,
            }, () => {
                this.props.getUserIntegration(this.props.apolloClient.client);
            });
        }
    }
    componentWillMount() {
        this.props.getUserIntegration(this.props.apolloClient.client);
    }
    componentWillReceiveProps(props) {
        this.setState({ rows: this.props.activedata });
        this.setState({ inRows: this.props.inActivedata });
        this.setState({ userSelectedEmail: props.userSelectedEmail, Username: props.Username });
        if (props.recordId != this.state.recordId) {
            this.setState({
                recordId: parseInt(props.recordId),
                recordtype: props.recordtype,
            }, () => {
                this.forceUpdate()
            });
        }
        if (props.settingReducer.userItegration.data && props.settingReducer.userItegration.data !== this.state.itegrationDetails) {
            let data = props.settingReducer.userItegration.data;
            var hasQuickbookConnect, hasXeroConnect, hasZendeskConnect, hasMailChimpConnect, hasDocusingConnect = false;
            data.map((item) => {
                if (item.status && item.integrationid == 8) {
                    hasQuickbookConnect = true;
                }
                if (item.status && item.integrationid == 1) {
                    hasZendeskConnect = true;
                }
                if (item.status && item.integrationid == 7) {
                    hasXeroConnect = true;
                }
                if (item.status && item.integrationid == 6) {
                    hasMailChimpConnect = true;
                }
                if (item.status && item.integrationid == 9) {
                    hasDocusingConnect = true;
                }
            });
            this.setState({
                itegrationDetails: data,
                hasZendeskConnect: hasZendeskConnect,
                hasQuickbookConnect: hasQuickbookConnect,
                hasXeroConnect: hasXeroConnect,
                hasMailChimpConnect: hasMailChimpConnect,
                hasDocusingConnect: hasDocusingConnect,
            });
        }
    }

    render() {
        const { rows, hasQuickbookConnect, hasZendeskConnect, hasXeroConnect, hasMailChimpConnect, hasDocusingConnect } = this.state;
        const items = [];
        for (const [index, value] of rows.entries()) {
            if (value.alias == 'tasks') {
                items.push(<Tasks recordId={this.state.recordId} recordtype={this.state.recordtype} />);
            }
            if (value.alias == 'files') {
                items.push(<Files recordId={this.state.recordId} recordtype={this.state.recordtype} />);

            }
            if (value.alias == 'calendarevents') {
                items.push(<Calendar recordId={this.state.recordId} recordtype={this.state.recordtype} />);

            }
            if (value.alias == 'contact') {
                items.push(<People recordId={this.state.recordId} recordtype={this.state.recordtype} />);

            }
            if (value.alias == 'companies') {
                items.push(<Companies recordId={this.state.recordId} recordtype={this.state.recordtype} />);

            }
            if (value.alias == 'projects') {
                items.push(<Projects recordId={this.state.recordId} recordtype={this.state.recordtype} />);

            }
            if (value.alias == 'opportunities') {
                items.push(<Opportunity recordId={this.state.recordId} recordtype={this.state.recordtype} />);

            }
            if (value.alias == 'quickbook' && hasQuickbookConnect) {
                items.push(<Quickbooks recordId={this.state.recordId} recordtype={this.state.recordtype} />);
            }

            if (value.alias == 'zendesk' && hasZendeskConnect) {
                items.push(<Zendesk recordId={this.state.recordId} recordtype={this.state.recordtype} />);
            }

            if (value.alias == 'xero' && hasXeroConnect) {
                items.push(<Xero recordId={this.state.recordId} recordtype={this.state.recordtype} />);
            }

            if (value.alias == 'mailchimp' && hasMailChimpConnect) {
                items.push(<MailChimp recordId={this.state.recordId} recordtype={this.state.recordtype} userSelectedEmail={this.state.userSelectedEmail} />);
            }
            
            if (value.alias == 'docusing' && hasDocusingConnect) {
                items.push(<DocuSign recordId={this.state.recordId} recordtype={this.state.recordtype} userSelectedEmail={this.state.userSelectedEmail} Username={this.state.Username} />);
            }


        }
        return (
            <>
                {items}
            </>

        );
    }
}
const mapStateToProps = state => {
    return {
        apolloClient: state.apolloClient,
        settingReducer: state.settingReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getUserIntegration: (client) => dispatch(actionCreators.getUserIntegration(client)),
    }
}
export default (connect(mapStateToProps, mapDispatchToProps)(ListComponent));

