import React, { Component } from "react";
import {
  Dialog, IconButton, Button, Icon, Tooltip, Grid,
  FormControl, Select, InputLabel, Menu, MenuItem, List,
  ListItem, ListItemText, DialogActions, withStyles,
  Chip, TextField, CircularProgress
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { createDynamicFields } from './CreateFieldsDynamic'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';
const TooltipNew = withStyles((theme) => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);
class CreateNewProjectDialog extends Component {
  state = {
    lastname: "",
    city: "",
    addressState: "",
    zipcode: "",
    country: "",
    countryListArray: [],
    companyCurrencyData: [],
    focusValue: false,
    fieldsList: [],
    fieldsObj: {},
    tags: [],
    systemTagsList: [],
    usersListArray: [],
    selectedDate: new Date(),
    userSettings: "",
    isDataLoading: true,
    relatedListArray: [],
    isAddedFromSidebar: false,
    teamvisible: true,
    hasActiveTeamPermission: false,
    userListForVisibility: [],
    selectedUserList: [],
    isEditVisibility: false,
    selectedTeamList: [],
    teamList: [],
    teamVisibilityLabel: "",
    teamUser: [],
  };

  componentWillMount() {

    ValidatorForm.addValidationRule('IsURLValid', (value) => {
      if (value && value.length && value.length > 0) {
        var specialChar = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
        if (!specialChar.test(value)) {
          return false;
        }
      }
      return true;
    });
    
    this.props.getRelatedList(this.props.apolloClient.client);
    let val = { typeId: 5 }
    let userData = JSON.parse(localStorage.getItem('user_data_session'))
    let userid = { userid: userData.id }
    this.props.getUserSetting(this.props.apolloClient.client, userid);
    this.props.customFieldList(this.props.apolloClient.client, val);
    this.props.getUserList(this.props.apolloClient.client);
    this.props.getCountryList(this.props.apolloClient.client);
    this.props.getCompanyCurrency(this.props.apolloClient.client);
    this.props.getTagsList(this.props.apolloClient.client);
    this.props.getTeam(this.props.apolloClient.client);
    this.props.getLoginUserPermission(this.props.apolloClient.client);
    this.setState({ isFromTopBar :  this.props.isFromTopBar });
  }

  AllMemberDisplay = () => {
    let teamVisibilityLabel = "";
    let userCount = 0;
    let teamCount = 0;
    let teamUser = [];
    let usersListArray = this.props.settingReducer.usersListArray.data ? this.props.settingReducer.usersListArray.data : []
    usersListArray.length > 0 && usersListArray.map((user) => {
      if (user.teamlist.length > 0) {
        let userdata = { userteam: [], id: "", username: "" }
        teamCount = teamCount < user.teamlist.length ? user.teamlist.length : teamCount;
        user.teamlist.map((u) => { userdata.userteam.push(u.team.teamname) })
        userdata.id = user.id;
        userdata.username = user.name;
        userCount = userCount + 1;
        teamUser.push(userdata)
      }
    })
    teamVisibilityLabel = userCount + " users from " + teamCount + " team ";
    this.setState({
      teamVisibilityLabel: teamVisibilityLabel,
      teamUser: teamUser
    }, () => this.forceUpdate())
  }
  selectedUserDetails = (mainuser) => {
    let teamVisibilityLabel = "";
    let teamUser = [];
    let userdata = { userteam: [], id: "", username: "" }
    mainuser.teamlist.map((u) => { userdata.userteam.push(u.team.teamname) })
    userdata.id = mainuser.id;
    userdata.username = mainuser.name;
    teamUser.push(userdata)
    teamVisibilityLabel = "1 users from " + mainuser.teamlist.length + " team ";
    this.setState({
      teamVisibilityLabel: teamVisibilityLabel,
      teamUser: teamUser
    }, () => this.forceUpdate())
  }
  themSelves = (selectedOwner) => {
    let teamVisibilityLabel = "";
    let userCount = 0;
    let teamCount = 0;
    let teamUser = [];
    let usersListArray = this.props.settingReducer.usersListArray.data ? this.props.settingReducer.usersListArray.data : []
    usersListArray.length > 0 && usersListArray.map((user) => {
      if (user.teamlist.length > 0) {
        if (user.teamlist[0].teammanager === true || (user.email === selectedOwner.email)) {
          let userdata = { userteam: [], id: "", username: "" }
          teamCount = teamCount < user.teamlist.length ? user.teamlist.length : teamCount;
          user.teamlist.map((u) => { userdata.userteam.push(u.team.teamname) })
          userdata.id = user.id;
          userdata.username = user.name;
          userCount = userCount + 1;
          teamUser.push(userdata)
        }
      }
    })
    teamVisibilityLabel = userCount + " users from " + teamCount + " team ";
    this.setState({
      teamVisibilityLabel: teamVisibilityLabel,
      teamUser: teamUser
    }, () => this.forceUpdate())
  }
  getUserPermission = () => {
    if (this.props.settingReducer.userPermissionDetails.data) {
      let data = this.props.settingReducer.userPermissionDetails.data.data;
      if (data.teampermissionstatus === true &&
        data.visibilityaccess.length > 0) {
        let prodt = data.visibilityaccess.find(function (e) { return e.sourcetype === 5 });

        if (prodt.isaccess) {
          if (prodt.viewoption === 1) {
            this.AllMemberDisplay();
          }
          if (prodt.viewoption === 2 || prodt.viewoption === 3) {
            var selectedOwner = this.state.fieldsObj["owner"]
            if (selectedOwner && selectedOwner.values) {
              if (selectedOwner.values.teamlist.length > 0) {//if member in any team 
                if (selectedOwner.values.teamlist[0].teammanager === false) {//owner is not  teammanager
                  if (prodt.viewoption === 2) {
                    this.AllMemberDisplay();
                  } else {
                    this.themSelves(selectedOwner.values);
                  }
                } else {//owner is teammanager   
                  this.selectedUserDetails(selectedOwner.values);
                }
              } else { // not in any team 
                this.setState({ teamVisibilityLabel: "0 users", teamUser: [] }, () => this.forceUpdate())
              }
            } else {
              this.setState({ teamVisibilityLabel: "0 users", teamUser: [] }, () => this.forceUpdate())
            }
          }
        } else {//prodt.isaccess === false
          this.setState({ teamVisibilityLabel: "0 users", teamUser: [] }, () => this.forceUpdate())
        }
        this.setState({
          hasActiveTeamPermission: true,
          viewoption: prodt.viewoption,
        })
      } else {
        this.setState({ teamvisible: false })
      }
    }
  }

  componentWillReceiveProps(props) {
    this.getUserPermission();
    if (props.settingReducer.teamList.data && this.state.teamList !== props.settingReducer.teamList.data) {
      const teamList = props.settingReducer.teamList.data;
      let data = [];
      teamList.map((v, i) => {
        data.push({ id: v.id, teamname: v.teamname })
      });
      this.setState({ teamList: data })
    }
    if (props.settingReducer.relatedListArray.data) {
      var selectedObj = [];
      props.settingReducer.relatedListArray.is_loaded = false
      const relatedData = props.settingReducer.relatedListArray.data;
      var NewArray = []
      // NewArray = NewArray.concat(relatedData.lead); 
      NewArray = NewArray.concat(relatedData.contact);
      NewArray = NewArray.concat(relatedData.company);
      NewArray = NewArray.concat(relatedData.opportunities);
      // NewArray = NewArray.concat(relatedData.project); 
      this.setState({ relatedListArray: NewArray }, () => {
        if (props.dataFromSidebar) {
          let fieldsObj = this.state.fieldsObj;

          selectedObj = this.state.relatedListArray.filter(function (element) {
            if (element.id == props.dataFromSidebar.id && element.type == props.dataFromSidebar.recordtype) {
              return element
            }
          });
          if (selectedObj.length > 0) {

            this.setState({
              fieldsObj: {
                ...this.state.fieldsObj,
                ["relatedto"]: { fieldid: "68", values: selectedObj[0] },
                ["name"]: { fieldid: "67", projectvalue: this.props.name }
              },
              isAddedFromSidebar: true,
              sidebarParams: props.dataFromSidebar,
            })
          }
        }
      })

    }

    //storing user setting data in state
    if (props.settingReducer.userSettingArray.data && this.state.userSettings == "") {
      const settingData = props.settingReducer.userSettingArray.data;
      this.setState({
        userSettings: settingData,
      })
    }

    if (props.settingReducer.projectCustomFieldList.data && props.settingReducer.projectCustomFieldList.is_loaded) {
      console.log("props.settingReducer.projectCustomFieldList", props.settingReducer.projectCustomFieldList);
      props.settingReducer.projectCustomFieldList.is_loaded = false;
      const customFields = props.settingReducer.projectCustomFieldList.data;
      var vkey = this.findWithAttr(customFields, "id", "72");
      var visibilitydefault = this.findWithAttr(customFields[vkey].options, "is_default", true);

      this.setState({ fieldsList: customFields, isDataLoading: false })
      var statuskey = this.findWithAttr(customFields, 'id', "70");
      var defaultKeyStatus = this.findWithAttr(customFields[statuskey].options, 'is_default', true);


      var stagekey = this.findWithAttr(customFields, 'id', "75");
      var defaultKeyStage = this.findWithAttr(customFields[stagekey].options, 'id', 1);

      //setting selected date in date picker
      customFields.map((v, i) => {
        if (v.fieldtype.id === "8") {
          var data = {
            fieldid: v.id,
            values: this.state.selectedDate
          }
          this.setState({
            fieldsObj: {
              ...this.state.fieldsObj,
              [v.fieldkey]: data
            }
          })
        }
      })

      //setting default object for status
      this.setState({
        fieldsObj: {
          ...this.state.fieldsObj,
          ["status"]: { fieldid: "70", values: customFields[statuskey].options[defaultKeyStatus] },
          ["stage"]: { fieldid: "75", values: customFields[stagekey].options[defaultKeyStage] },
          ["visibility"]: { fieldid: "72", values: customFields[vkey].options[visibilitydefault] },
        }
      })

    }
    if (props.settingReducer.countryListGql.data) {
      const countryies = props.settingReducer.countryListGql.data;
      this.setState({ countryListArray: countryies })
    }
    if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
      const usersListArray = props.settingReducer.usersListArray.data;
      let userListForVisibility = [];
      usersListArray.map((data, i) => {
        userListForVisibility.push({ id: data.id, name: data.name })
      })
      let userData = JSON.parse(localStorage.getItem('user_data_session'))
      var companyOwnerKey = this.findWithAttr(usersListArray, "email", userData.email);

      this.setState({
        usersListArray: usersListArray,
        userListForVisibility: userListForVisibility,
        fieldsObj: {
          ...this.state.fieldsObj,
          ["owner"]: { fieldid: "69", values: usersListArray[companyOwnerKey] }
        }
      })
    }
    if (props.notification && props.notification.status === true) {
      // props.handleClose();
      props.clearNotification();
    }

    if (props.settingReducer.systemTagsList.data) {
      const tagsData = props.settingReducer.systemTagsList.data;
      this.setState({ systemTagsList: tagsData })
    }

    if (props.settingReducer.companyCurrencyData.data != null) {
      var cData = props.settingReducer.companyCurrencyData.data;
      this.setState({ companyCurrencyData: cData[0] });
    }
  }

  addNewField = (key, option) => {
    let data = this.state.fieldsObj[key];
    data.values.push({
      srno: (data.values.length + 1).toString(),
      id: option.id,
      value: ""
    })

    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [key]: data
      }
    })
  }

  handleAddMoreField = (name, key, index, value) => {
    let data = this.state.fieldsObj[key];
    data.values[index][name] = value;

    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [key]: data
      }
    }, () => this.forceUpdate())
  }

  handleNormalFields = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleCountryChange = (event, values) => {
    this.setState({ country: values });
  }

  focusInValue = () => {
    this.setState({ focusValue: true })
  };

  focusOutValue = () => {
    this.setState({ focusValue: false })
  };

  currencyDropdownClose = () => {
    this.setState({ focusValue: false })
  };

  handleChange = (val, name) => {
    if (val.fieldid === "72") {
      this.setState({ isEditVisibility: true }, () => this.forceUpdate())
      if (val.values.id === 2) {
        this.setState({ selectedTeamList: [{}] }, () => this.forceUpdate())
      } else if (val.values.id === 3) {
        this.setState({ selectedUserList: [{}] }, () => this.forceUpdate())
      }
    }
    var key = this.findWithAttr(this.state.fieldsList, 'id', val.fieldid);
    if (key != -1 && this.state.fieldsList[key].fieldtype.id != "4" && this.state.fieldsList[key].fieldtype.id != "8") {
      if (this[name]) {
        this[name].validate(val.value);
      }
    }

    if (val.fieldid === "69") {
      if ((this.state.viewoption === 2 || this.state.viewoption === 3) && val.values === null) {
        this.setState({
          teamVisibilityLabel: "0 users",
          teamUser: []
        }, () => this.forceUpdate())
      } else {
        this.setState({
          fieldsObj: {
            ...this.state.fieldsObj,
            [name]: val,
          }
        }, () => this.getUserPermission())
      }
    }
    //return
    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [name]: val
      }
    })

  };

  handleFormSubmit = () => {
    let submitData = JSON.parse(JSON.stringify(this.state.fieldsObj));
    submitData = Object.values(submitData).map((row) => {
      if (row.fieldid == "68") {//realated  field
        if (row.values == null) {
          return false
        }
        row.values = [{ srno: "1", id: row.values.id, value: row.values.name, type: row.values.type }]
      }

      if (row.fieldid === "72" || row.fieldid === "70" || row.fieldid === "75") {
        if (row.fieldid === "72") { //Visibility,
          let filterVisibleList = [];
          if (row.values.id === 2) {
            filterVisibleList = this.state.selectedTeamList.length > 0 ? this.state.selectedTeamList.filter(el => Object.keys(el).length) : []
          } else if (row.values.id === 3) {
            filterVisibleList = this.state.selectedUserList.length > 0 ? this.state.selectedUserList.filter(el => Object.keys(el).length) : []
          }
          row.values = [
            {
              srno: "1",
              id: row.values.id,
              value: row.values.name,
              visiblelist: filterVisibleList
            }
          ];
        } else {
          row.values = [{ srno: "1", id: row.values.id, value: row.values.name }]
        }
      }
      var key = this.findWithAttr(this.state.fieldsList, 'id', row.fieldid);
      if (this.state.fieldsList[key].fieldtype.id == "4" && this.state.fieldsList[key].is_default == false) {
        row.values = [{ srno: "1", id: row.values.id, value: row.values.optionvalue }]
      }
      if (this.state.fieldsList[key].fieldtype.id == "5" && this.state.fieldsList[key].is_default == false) {
        if (row.values.length > 0) {
          var ar = [];
          row.values.map((val, i) => {
            ar.push({ srno: (i + 1).toString(), id: val.id, value: val.optionvalue })
          })
        }
        row.values = ar
      }

      //validating date fiels
      if (this.state.fieldsList[key].fieldtype.id == "8") {//date field
        console.log("row.values", row.values);
        if (this.state.fieldsList[key].recordcreationdefaultvalue == 2 && row.values == null) {
          return false
        } else if (this.state.fieldsList[key].recordcreationdefaultvalue != 2 && row.values == null) {
          row.projectvalue = "";
          delete row.values;
        } else if (row.values == "Invalid Date") {
          return false
        } else {
          /*let date_test = moment(row.values).format('YYYY-MM-DD HH:mm');
          var logdate = moment.tz(date_test, this.state.userSettings.timezone).format();
          row.projectvalue = moment.utc(logdate).format('X');*/
          // row.projectvalue = moment(row.values).format("X");
          var dt = moment(row.values).format('YYYY-MM-DD')
          row.projectvalue = dt;
          delete row.values;
        }
      }

      if (row.fieldid == "74") {
        if (row.values.length > 0) {
          var objArr = [];
          row.values.map((val, k) => {
            var foundKey = this.findWithAttr(this.state.systemTagsList, 'tagname', val);
            var tagId = "0";
            if (foundKey !== -1) {
              tagId = this.state.systemTagsList[foundKey].id
            }
            console.log("val", val);
            objArr.push({ "srno": (k + 1).toString(), value: val.toLowerCase(), id: tagId })
          })
          row.values = objArr
        }
      }


      if (row.fieldid == "69") {//owner value set
        if (row.values) {
          row.values = [{ "srno": "1", id: row.values.id, value: row.values.email, name: row.values.name }];
        } else {
          row.values = [];
        }
        delete row.projectvalue;
      }
      return row;
    })


    //not to submit if any field is false
    var allowToSubmit = true
    submitData.map((row) => {
      if (!row) {
        allowToSubmit = false
      }
    })
    console.log("submitData", submitData);
    console.log("allowToSubmit", allowToSubmit);
    // return
    if (allowToSubmit) {
      let req = {
        projectdata: submitData,
        sidebarParams: this.state.sidebarParams,
        teamvisible: this.state.teamvisible,
        isFromTopBar : this.state.isFromTopBar
      }
      this.props.handleClose();
      this.props.saveProjectDetail(this.props.apolloClient.client, req);
    }
  };

  handleDateChange = date => {
    console.log("date", date);
    this.setState({ selectedDate: date });
  };

  arrDifference = (arr1, arr2) => {
    var arr = [];
    arr1 = arr1.toString().split(',').map(Number);
    arr2 = arr2.toString().split(',').map(Number);
    // for array1
    for (var i in arr1) {
      if (arr2.indexOf(arr1[i]) === -1)
        arr.push(arr1[i]);
    }
    // for array2
    for (i in arr2) {
      if (arr1.indexOf(arr2[i]) === -1)
        arr.push(arr2[i]);
    }
    return arr.sort((x, y) => x - y);
  }

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }
  handleBlur = (event, ref) => {
    if (this[ref].props.id == "tagInputId1") {
      setTimeout(() => {
        document.getElementById("tagInputId1").value = "";
      }, 10)
    }
    this[ref].validate(event.target.value);
  }
  teamHandleChange = (index, value, name) => {
    let mianteam = this.state.teamList;
    let selectedTeamList = this.state.selectedTeamList
    let mianUsers = this.state.usersListArray;
    let selectedUserList = this.state.selectedUserList
    if (name === "team") {
      if (value !== null) {
        selectedTeamList[index] = value;
        if (mianteam.length + 1 > selectedTeamList.length) {
          selectedTeamList[index + 1] = {};
        }
      } else {
        selectedTeamList.splice(index, 1);
        if (selectedTeamList.length === 0) {
          selectedTeamList[0] = {};
        }
      }
    } else {
      if (value !== null) {
        selectedUserList[index] = { id: value.id, name: value.name }
        if (mianUsers.length + 1 > selectedUserList.length) {
          selectedUserList[index + 1] = {};
        }
      } else {
        selectedUserList.splice(index, 1);
        if (selectedUserList.length === 0) {
          selectedUserList[0] = {};
        }
      }
    }
    this.setState({
      selectedTeamList: selectedTeamList,
      selectedUserList: selectedUserList
    })
  }
  handleToggleEditVisibility = () => {
    this.setState({
      isEditVisibility: !this.state.isEditVisibility
    })
  }

  handleChangeVisibilityRadio = (value) => {
    if (value) {
      let customFields = this.state.fieldsList
      var vkey = this.findWithAttr(customFields, "id", "72");
      var visibilitydefault = this.findWithAttr(customFields[vkey].options, "is_default", true);
      this.setState({
        selectedTeamList: [],
        selectedUserList: [],
        fieldsObj: {
          ...this.state.fieldsObj,
          ["visibility"]: { fieldid: "72", values: customFields[vkey].options[visibilitydefault] },
        },
      });
    }
    this.setState({
      teamvisible: value === "true" ? true : false
    })
  }
  render() {
    let { open, handleClose, handleMinimize, handleFull, fullScreen, minimizeScreen } = this.props;
    return (
      <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth={true} className={`crm-custom-bg-primary custom_dialog ${minimizeScreen && fullScreen ? 'alignitemsend-fullscreen' : ''} ${minimizeScreen ? 'alignitemsend' : ''} ${fullScreen ? 'fullscreen' : ''}`} disableBackdropClick >
        <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">
          <h4 className="m-0 text-white">Create New Project</h4>
          <div className="d-flex">
            <TooltipNew title={minimizeScreen ? 'Normal Screen' : 'Minimize Screen'}>
              <IconButton onClick={handleMinimize}>
                <Icon className="text-white">{minimizeScreen ? 'add' : 'remove'}</Icon>
              </IconButton>
            </TooltipNew >
            <TooltipNew title={fullScreen ? 'Exit Full Screen' : 'Full Screen'}>
              <IconButton onClick={handleFull}>
                <Icon className="text-white">{fullScreen ? 'fullscreen_exit' : 'fullscreen'}</Icon>
              </IconButton>
            </TooltipNew >
            <TooltipNew title="Close">
              <IconButton onClick={handleClose}>
                <Icon className="text-white">clear</Icon>
              </IconButton>
            </TooltipNew >
          </div>
        </div>
        <ValidatorForm noValidate instantValidate={false} onSubmit={this.handleFormSubmit}>
          <div className={`${this.state.isDataLoading ? "dialog-body popupHeightCustom" : "dialog-body"}`}>
            <Grid container spacing={2}>
              {this.state.isDataLoading && <CircularProgress size={25} className="loaderPopupCenter" />}
              {!this.state.isDataLoading && createDynamicFields(this)}
            </Grid>
          </div>

          <DialogActions className="dialog-action">
            <Button variant="contained" onClick={handleClose}>Cancel</Button>
            <Button disabled={this.state.isDataLoading} variant="contained" type="submit" color="primary" className="btn_Primary">Save</Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
  authReducer: state.authReducer

});

const mapDispatchToProps = dispatch => {
  return {
    getLoginUserPermission: (client) => dispatch(actionCreators.getLoginUserPermission(client)),
    getTeam: (client) => dispatch(actionCreators.getTeam(client)),

    customFieldList: (client, request) => { dispatch(actionCreators.customFieldList(client, request)) },
    saveProjectDetail: (client, request) => { dispatch(actionCreators.saveProjectDetail(client, request)) },
    getCountryList: (client) => { dispatch(actionCreators.getCountryList(client)) },
    clearNotification: () => dispatch(actionCreators.clearNotification()),
    getCompanyCurrency: (client) => dispatch(actionCreators.getCompanyCurrency(client)),
    getTagsList: (client) => dispatch(actionCreators.getTagsList(client)),
    getUserList: (client) => dispatch(actionCreators.getUserList(client)),
    getUserSetting: (client, data) => dispatch(actionCreators.getUserSetting(client, data)),
    getRelatedList: (client) => dispatch(actionCreators.getRelatedList(client)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNewProjectDialog);
