import React, { Component } from "react";
import { Grid, Button, CardContent, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import * as actionCreators from '../../../store/actions/index';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Tooltip from '@material-ui/core/Tooltip';

const TooltipNew = withStyles((theme) => ({
    tooltip: {
      fontSize: 14,
    },
  }))(Tooltip);

class ZapireIntegrations extends Component {
    state = {
        integrationId: 5,
        email: '',
        token: '',
        intregationData: [],
    };

    componentDidMount() {
        let val = this.state.integrationId;
        this.props.getIntegrationDetail(this.props.apolloClient.client, val);
    }
    componentWillReceiveProps(props) {
        if (props.settingReducer.getintegationDetails.data) {
            const token_data = props.settingReducer.getintegationDetails.data;
            if (token_data && token_data !== this.state.intregationData) {
                if (token_data.integrationid === 5) {
                    console.log(token_data, "getintegationDetails data of Zapire");
                    this.setState({
                        token: token_data.accesstoken,
                        email: token_data.email,
                        intregationData: token_data,
                    });
                }
            }
        }
    }

    handleReGenerateZapierToken = () => {
        this.props.GenerateZapierToken(this.props.apolloClient.client);
    }

    handleOnCopy = () => {
        this.props.copyTokenNotification(this.props.apolloClient.client);
    }

    render() {
        let { email, token } = this.state;

        return (
            <React.Fragment>
                <div className="IntegrationBlock_contents" >
                    <div className="Zapier_Integrations_card">
                        <CardContent>
                            <div className="d-flex">
                                {token && email ?
                                    <Grid container justify="space-between">
                                        <div className="d-flex">
                                            <img src="/assets/images/intregation_logo/zapier.png" style={{ width: "40px", height: "40px", margin: '16px 10px 10px 10px' }} />
                                            <div style={{ padding: '5px 0px 0px 10px' }}>
                                                <div className="font-weight-600 font-size-20">Zapier</div>
                                                <div className='d-flex'>
                                                    <div className="text-small" >API token:</div>
                                                    <TooltipNew title="Click To Copy">
                                                        <CopyToClipboard text={token} onCopy={this.handleOnCopy} >
                                                            <div style={{ width: '900px', fontSize: '12px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} >
                                                                {token}
                                                            </div>
                                                        </CopyToClipboard>
                                                    </TooltipNew>
                                                </div>
                                                <div className="text-small" style={{ width: '400px' }}>Generated by:{email}</div>
                                            </div>
                                        </div>
                                        <Button size="small" color="primary" onClick={this.handleReGenerateZapierToken}  >
                                            <div className="Btn Btn-flatPrimary" style={{ marginTop: '12px', color: '#2B41B2' }} >REGENERATE ZAPIER TOKEN</div>
                                        </Button>
                                    </Grid>
                                    :
                                    <Grid container justify="space-between">
                                        <div className="d-flex">
                                            <img src="/assets/images/intregation_logo/zapier.png" style={{ width: "40px", height: "40px", margin: '10px' }} />
                                            <div style={{ padding: '5px 0px 0px 10px' }}>
                                                <div className="font-weight-600 font-size-20">Zapier</div>
                                                <div className="text-small">
                                                    Enable more than 2,000 integrations with Zapier
                                                </div>
                                            </div>
                                        </div>
                                        <Button size="small" color="primary" onClick={this.handleReGenerateZapierToken}>
                                            <div className="Btn Btn-flatPrimary" style={{ marginTop: '12px', color: '#2B41B2' }} >GENERATE ZAPIER TOKEN</div>
                                        </Button>
                                    </Grid>
                                }
                            </div>
                        </CardContent>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}

const mapStateToProps = state => {
    return {
        apolloClient: state.apolloClient,
        settingReducer: state.settingReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        GenerateZapierToken: (client) => dispatch(actionCreators.GenerateZapierToken(client)),
        copyTokenNotification: (client) => dispatch(actionCreators.copyTokenNotification(client)),
        saveIntegrationSetting: (client, req) => dispatch(actionCreators.saveIntegrationSetting(client, req)),
        getIntegrationDetail: (client, request) => dispatch(actionCreators.getIntegrationDetail(client, request)),
        UpdateIntegrationStatus: (client, request) => dispatch(actionCreators.UpdateIntegrationStatus(client, request)),
    }
}
export default (connect(mapStateToProps, mapDispatchToProps)(ZapireIntegrations));
