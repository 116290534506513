import React, { Component } from "react";
import { Grid, FormControl, Select, IconButton, Icon, MenuItem, withStyles, Fab, Button, DialogContent, Dialog, DialogActions, DialogTitle, Avatar, Card } from "@material-ui/core";

import { connect } from "react-redux";
import { compose } from 'redux';
import * as actionCreators from '../../../store/actions/index';
import moment from "moment";
import GoalsReportDatas from "./GoalsReportDatas";



class GoalsDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      date: moment().startOf('month'),
      previousdate: moment().startOf('month').subtract("1", "month"),
      nextdate: moment().startOf('month').add("1", "months"),     
      monthList: [],
      selectedPipeline: "1",
         
    };
  }

  componentDidMount = () => {
    // var j = 0;
    // let date = moment().startOf('month').subtract(12, "months");
    // let monthList = [];
    // for (j = 0; j < 24; j++) {
    //   monthList.push({ start: moment(date).add(j, "months"), end: moment(date).add(j + 1, "months") })
    // }
    // this.setState({ monthList })
  }

  // getGoalReport = () => {
  //   let goalParams = { datetype: parseInt(this.state.selectedPipeline), goaltype: 0 };
  //   this.props.goalReport(this.props.apolloClient.client, goalParams); 
  // }
  
  componentWillReceiveProps(props) {
    // if(props.settingReducer.get_goal_report_data && props.settingReducer.get_goal_report_data.data){
    //   let goalreportData = props.settingReducer.get_goal_report_data.data.data;
    //   this.setState({reportData: goalreportData})
    // }
  }

  // handleDatetypeChange = (event) => {
  //   this.setState({ selectedPipeline: event.target.value });    
  // }

  // handleDateChange = () => event => {
  //   this.setState({ date: moment(event.target.value, "DD-MM-YYYY") })
  // }

  render() {
    return (
      <>
        <div>
          <GoalsReportDatas type={1} data={this}/>
        </div>
        
        <div>
          <GoalsReportDatas type={2} data={this} />
        </div>

        <div>
          <GoalsReportDatas type={3} data={this} />
        </div>
      </>
    );
  }

}

const mapStateToProps = state => ({
  reportReducer: state.reportReducer,
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
});

const mapDispatchToProps = dispatch => {
  return {
    goalReport: (client, request) => dispatch(actionCreators.goalReport(client, request)),
   
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(GoalsDashboard);