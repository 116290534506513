import React, { Component } from "react";
import { FormHelperText, Input, FormControl, Popover, Divider, Button, IconButton, Icon, Paper, Card, DialogContent, Dialog, DialogActions, DialogTitle, Typography, MenuItem, InputLabel, Radio, FormControlLabel, RadioGroup, Badge, Drawer, Tooltip, Avatar } from "@material-ui/core";
import Grid, { default as MaterialGrid } from '@material-ui/core/Grid';
import ReportSidebarMenu from "../Layout/ReportSidebarMenu";
import Select from '@material-ui/core/Select';
import FilterListIcon from '@material-ui/icons/FilterList';

import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import CreateIcon from '@material-ui/icons/Create';
import moment from 'moment';//moment
import 'moment-timezone';//timezone
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import HttpsIcon from '@material-ui/icons/Https';

import { opportunityFilterComponent } from './opportunityFilterComponent';
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";

import * as actions from '../../store/actions/index';
import { connect } from "react-redux";

class SalesPerformanceSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportTitile: "Sales Performance Summary",
            reportSubTitle: "Amount total along with the closing rate.",
            totalWinRate: "0%",
            selectedPipline: "1",
            selectedFilter: "1",
            totalopp: "1",
            value_type: "1",
            drawerOpen: false,
            dateopen: false,
            dateformat: 'MM/DD/YYYY',
            // selectedDate: null,
            datetype: "1",
            
            selectedDate: {from: moment().startOf("year").format('YYYY-MM-DD'), to: moment().endOf("year").format('YYYY-MM-DD')},
            selectedDatelabel:"This Year",
            definedRanges: [
                {label: "Today",
                timeno:0,
                startDate:moment().format('YYYY-MM-DD'),
                endDate:  moment().format('YYYY-MM-DD')},

                {label: "Yesterday",
                timeno:0,
                startDate:moment().subtract(1, 'days').format('YYYY-MM-DD'),
                endDate:  moment().subtract(1, 'days').format('YYYY-MM-DD')},

                {label: "This Week",
                timeno:1,
                compareLabel:"Compared to last week",
                startDate:moment().day(1),
                endDate:  moment().day(7)},

                {label: "Last 7 days",
                timeno:2,
                compareLabel:"Compared to last 7 days",
                startDate:moment().subtract(6, 'days').format('YYYY-MM-DD'),
                endDate:  moment().format('YYYY-MM-DD')},

                {label: "Last 30 days",
                timeno:3,
                compareLabel:"Compared to last 30 days",
                startDate:moment().subtract(30, 'days').format('YYYY-MM-DD'),
                endDate:  moment().format('YYYY-MM-DD')},

                {label: "This Month",
                timeno:4,
                compareLabel:"Compared to last month",
                startDate:moment().startOf("month"),
                endDate:  moment().endOf("month")},

                {label: "This Quarter",
                timeno:5,
                compareLabel:"Compared to last quarter",
                startDate:moment().startOf('quarter'),
                endDate:  moment().endOf('quarter')},

                {label: "This Year",
                timeno:6,
                compareLabel:"Compared to last year",
                startDate:moment().startOf('year'),
                endDate:  moment().endOf('year')},

            ],
            timeno:0,
            compareLabel:"",
            fromdate:moment().startOf("year").format('YYYY-MM-DD'),
            todate:moment().endOf('year').format('YYYY-MM-DD'),
            pipelineList: [],
            fieldDisplay: [],
            dropdownhide: [],
            systemTagsList: [],
            masterActivityTypesList: [],
            usersListArray: [],

            performanceSummaryArray: [{
                id: 1,
                name: "New Deals Created",
                value: 0,
                precentage: "0%",
                color: "bg-primary",
                icon: <CreateIcon />
            }, {
                id: 2,
                name: "Deal Closed Won",
                value: 0,
                precentage: "0%",
                color: "bg-green",
                icon: <AssignmentTurnedInIcon />
            }, {
                id: 3,
                name: "Deals Worked",
                value: 0,
                precentage: "0%",
                color: "bg-secondary",
                icon: <CreateIcon />
            }, {
                id: 4,
                name: "Amount Closed",
                value: 0,
                color: "bg-error",
                precentage: "0%",
                icon: <HttpsIcon />
            }],

            filterSaveDialoge: false,
            fitertype: "1",
            filterRangeArray: [],
            filterObject: {},
            filternameError: false,
            filtername: "",
            displaySelectedFilterName: "All Opportunity",
            filterdata: [],
            recordtypeid: 4,
            reportno: 4,
            filterlist:[],
            AllOpportunityFilterKey:[],
            allowStateUpdateFilter: true,
            anchorEl: null,            
            isOpenedFiltersDefault: false,
            activityquery:'any',
            allDefaultKeyFilter: {id:0, filteroption:[], filtername: "All Opportunity"}, 
        }
    }

    componentDidMount() {
        
        localStorage.removeItem('selected_report_pipeline_summary_filter_id');
        
        let performanceparams = {
            filterdata:[],
            piplineid: parseFloat(this.state.selectedPipline),
            type:parseFloat(this.state.value_type),
            fromdate:moment().startOf("year").format('YYYY-MM-DD'),
            todate:moment().endOf('year').format('YYYY-MM-DD'),
            datetype:parseFloat(this.state.datetype),
            timeno: this.state.timeno,
            memberid: 0,
        };
        this.getsalesperformance(performanceparams);

        this.props.getPipelines(this.props.apolloClient.client);
        let val = { typeId: 4 }
        this.props.customFieldList(this.props.apolloClient.client, val);
        this.props.getTagsList(this.props.apolloClient.client);
        this.props.getUserList(this.props.apolloClient.client);
        this.props.getActivityTypes(this.props.apolloClient.client);
        
        this.setState({ selectedDate: {from: moment().startOf("year").format('YYYY-MM-DD'), to: moment().endOf("year").format('YYYY-MM-DD')} })
    }

    componentWillReceiveProps(props) {
        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            let usersetting = props.settingReducer.userSettingArray.data;
            this.setState({ dateformat: usersetting.dateformat, timeZone: usersetting.timezone })
        }
        
        if (props.settingReducer.masterActivityTypesList && props.settingReducer.masterActivityTypesList.is_loaded == true && props.settingReducer.masterActivityTypesList.data) {
            props.settingReducer.masterActivityTypesList.is_loaded = false
            var dataList = props.settingReducer.masterActivityTypesList.data;
            var count  = dataList.filter(function(element) {
                if(element.is_disable == true){
                  return element
                }
            });
            this.setState({
                masterActivityTypesList: dataList,
            });
        }
        if (props.settingReducer.systemTagsList.data && this.state.systemTagsList.length == 0) {
            const tagsData = props.settingReducer.systemTagsList.data;
            this.setState({ systemTagsList: tagsData })
        }

        if(props.settingReducer.pipelineListArray && props.settingReducer.pipelineListArray.data){
            this.setState({pipelineList: props.settingReducer.pipelineListArray.data})
        }

        if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
            const usersListArray = props.settingReducer.usersListArray.data;
            this.setState({ usersListArray: usersListArray })
        }

        if(props.reportReducer.salesPerformanceSummary && props.reportReducer.salesPerformanceSummary.data){
            let salesPerformanceSummary = props.reportReducer.salesPerformanceSummary.data.data;
            let performanceSummaryArray = this.state.performanceSummaryArray;

            performanceSummaryArray[0].value = salesPerformanceSummary.value.created;
            performanceSummaryArray[0].precentage = Math.round(salesPerformanceSummary.percentage.created)+"%";

            performanceSummaryArray[1].value = salesPerformanceSummary.value.closed;
            performanceSummaryArray[1].precentage =Math.round(salesPerformanceSummary.percentage.closed)+"%";
            
            performanceSummaryArray[2].value = salesPerformanceSummary.value.worked;
            performanceSummaryArray[2].precentage =Math.round(salesPerformanceSummary.percentage.worked)+"%";

            performanceSummaryArray[3].value = "$ "+salesPerformanceSummary.value.closedamnt;
            performanceSummaryArray[3].precentage =Math.round(salesPerformanceSummary.percentage.closedamnt)+"%";

            this.setState({salesPerformanceSummary, performanceSummaryArray});
        }

        if(props.reportReducer.performancesummaryreportfilterlist.data && this.state.allowStateUpdateFilter){
            const filterlist = props.reportReducer.performancesummaryreportfilterlist.data;
            let displaySelectedFilterName = this.state.displaySelectedFilterName;
            var AllfilterIdKey = this.findWithAttr(filterlist, 'id', "6");
            
            if (AllfilterIdKey != -1) {
                this.setState({ AllOpportunityFilterKey: AllfilterIdKey })
            }
            //Aplying default filter selected  STRAT

            let defaultFilter;

            //checkdefault filter  in localStorage
            var localStorageFilterId = localStorage.getItem('selected_report_pipeline_summary_filter_id');
            if (localStorageFilterId) {
                var localId = this.findWithAttr(filterlist, 'id', localStorageFilterId);
                defaultFilter = filterlist[localId];
                this.setState({displaySelectedFilterName: (defaultFilter && defaultFilter.length > 0 ) ? defaultFilter.filtername :  displaySelectedFilterName})
            } else {
                var defaultKeyStatus = this.findWithAttr(filterlist, 'id', "6");
                if(defaultKeyStatus != -1){
                    defaultFilter = filterlist[defaultKeyStatus];
                    localStorage.setItem('selected_report_pipeline_summary_filter_id', defaultFilter.id);

                    this.setState({displaySelectedFilterName: defaultFilter.filtername})
                }
            }

            if(this.state.newSavedFilterName){
                this.setState({displaySelectedFilterName: this.state.newSavedFilterName})
            }


            this.setState({ filterlist: filterlist, allowStateUpdateFilter: false });
            
            //updateFilter name to display and menudisplay array set
            this.setState({
                // displaySelectedFilterName: this.state.newSavedFilterName ? this.state.newSavedFilterName : defaultFilter.filtername,
                fieldDisplay: [],
                dropdownhide: [],
            })

            if (defaultFilter && defaultFilter.filteroption.length > 0) {
                let filterObject = this.state.filterObject;
                let filterRangeArray = this.state.filterRangeArray;
                let displayField = []
                let dropdownhideShow = []
                var promise = new Promise((resolve, reject) => {
                    defaultFilter.filteroption.map((val, k) => {
                        if (val.name == "activitytype") {
                            this.setState({ activityquery: val.query })
                            if(val.value.length > 0){
                                var arr = []
                                val.value.map(v=>{
                                    var k = this.findWithAttr(this.state.masterActivityTypesList, 'id',v)
                                    if(k != -1){
                                        arr.push(this.state.masterActivityTypesList[k])
                                    }
                                })
                                val.value = arr;

                            }
                        }

                        filterObject = {
                            ...filterObject,
                            [val.name]: val
                        }

                        if (val.name == "tags") {
                            this.setState({ tagsquery: val.query })
                        }

                        if (val.type === "range") {
                            filterRangeArray[val.name] = ["", ""];
                            filterRangeArray[val.name][0] = val.value[0];
                            filterRangeArray[val.name][1] = val.value[1];
                            this.setState({ filterRangeArray })
                            displayField.push(val.name)
                        }
                        if (val.type === "date") {
                            filterRangeArray[val.name] = ["", ""];
                            filterRangeArray[val.name][0] = val.value[0] == "" ? null : val.value[0];
                            filterRangeArray[val.name][1] = val.value[1] == "" ? null : val.value[1];
                            this.setState({ filterRangeArray })
                            if (val.value[0] != "" && val.value[1] != "") {
                                displayField.push(val.name)
                            } else if (val.value[0] != "" || val.value[1] != "") {
                                displayField.push(val.name)
                            }
                        }
                        if (val.name == "zipcode" || val.name == "city" || val.name == "state" || val.name == "country") {//hideshow ENABLE state/city/country/state
                            dropdownhideShow.push(val.name)
                        } else {
                            dropdownhideShow.push(val.name)
                        }
                    })
                    this.setState({
                        filterObject,
                        fieldDisplay: displayField,
                        dropdownhide: dropdownhideShow,
                        newSavedFilterName: "",
                    }, () => this.forceUpdate())
                    resolve(true)
                });

                promise.then(result => {
                    // if (localStorage.getItem('selected_pipeline_id')) {
                    //     this.setState({
                    //         selectedPipelineId: localStorage.getItem('selected_pipeline_id'),
                    //     }, () => this.forceUpdate())
                    // }
                    this.handleFilterSubmit();
                });
            }
            //Aplying default filter selected  END
        }

        if (props.settingReducer.opportunitiesCustomFieldList.data) {
            const customFields = props.settingReducer.opportunitiesCustomFieldList.data;
            this.setState({ fieldsList: customFields })
        }
    }

    getsalesperformance = (performanceparams) => {
        let performancedata = performanceparams;
        performancedata.memberid = 0
        this.props.salesPerformanceSummary(this.props.apolloClient.client, performancedata);
        this.props.getReportFilterList(this.props.apolloClient.client, {reportno:4});
    }
    
    handlePiplineChange = (event) => {
        let performanceparams = {
            filterdata: this.state.filterdata,
            piplineid: parseFloat(event.target.value),
            type: parseFloat(this.state.value_type),
            fromdate: this.state.fromdate,
            todate: this.state.todate,
            datetype: parseFloat(this.state.datetype),
            timeno: this.state.timeno,
            memberid: 0,
        };
        this.getsalesperformance(performanceparams);

        this.setState({ [event.target.name]: event.target.value });
    }

    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] == value) {
            return i;
            }
        }
        return -1;
    }
    
    // filter functions ----------------------------
    handleClick = (event) => {
        this.setState({anchorEl : event.target, isOpenedFiltersDefault: true});
    };

    handleClose = () => {
        this.setState({anchorEl : null, isOpenedFiltersDefault: false});
    };

    handleFollowedCheckbox = (val, name) => {
        // console.log("name1", name);
        // console.log("val1", val);
        this.setState({
            filterObject: {
                ...this.state.filterObject,
                [name]: val
            }
        }, () => {
            setTimeout(() => {
                if (this.state.filterObject[name] == val) {
                    this.handleFilterSubmit()
                }
            }, 1500);
        })
    }

    handleChangeFilter = (val, name) => {

        console.log(val, name,'val, name');
        this.setState({
            filterObject: {
                ...this.state.filterObject,
                [name]: val
            }
        }, () => {
            setTimeout(() => {
                if (this.state.filterObject[name] == val) {
                    this.handleFilterSubmit()
                }
            }, 3000);
        })
    };

    rangeClick = (key, id) => {
        var rangeData = this.state.filterRangeArray[key];
        if (this.state.filterRangeArray[key][0] == "" && this.state.filterRangeArray[key][1] == "") {
            rangeData = []
        }

        var value = { fieldid: id, name: key, type: "range", value: rangeData };
        this.setState({
            filterObject: {
                ...this.state.filterObject,
                [key]: value
            }
        }, () => {
            this.forceUpdate()
            setTimeout(() => {
                if (this.state.filterObject[key] == value) {
                    this.handleFilterSubmit()
                }
            }, 1500);
        })
    }

    rangeFilter = (rval, rname) => {
        let filterRangeArray = this.state.filterRangeArray;
        filterRangeArray[rname][rval.case] = rval.value;
        this.setState({ filterRangeArray })
    }

    rangeClickDate = (key, id) => {
        var rangeData = this.state.filterRangeArray[key];
        if (this.state.filterRangeArray[key][0] == "" && this.state.filterRangeArray[key][1] == "") {
            rangeData = []
        }

        var value = { fieldid: id, name: key, type: "date", value: rangeData };
        this.setState({
            filterObject: {
                ...this.state.filterObject,
                [key]: value
            }
        }, () => {
            this.forceUpdate()
            setTimeout(() => {
                if (this.state.filterObject[key] == value) {
                    this.handleFilterSubmit()
                }
            }, 1500);
        })
    }

    rangeFilterDate = (rval, rname) => {
        let filterRangeArray = this.state.filterRangeArray;
        filterRangeArray[rname][rval.case] = rval.value;
        this.setState({
            filterRangeArray
        }, () => {
            this.forceUpdate()
            setTimeout(() => {
                this.rangeClickDate(rname, rval.fieldid)
            }, 100);
        })
    }

    handleToggleFieldDisplay = (name) => {
        var exists = this.state.fieldDisplay.includes(name);
        var joined = [];
        let filterRangeArray = this.state.filterRangeArray;

        if (exists) {
            joined = this.state.fieldDisplay.filter((item) => item != name)
            if (filterRangeArray[name]) {
                //delete filterRangeArray[name];
            }
        } else {
            joined = this.state.fieldDisplay.concat(name);
            if (!filterRangeArray[name]) {
                filterRangeArray[name] = ["", ""];
            }
        }

        this.setState({ fieldDisplay: joined, filterRangeArray })
    }

    dropdownMenu = (fkey) => {
        var exists = this.state.dropdownhide.includes(fkey);
        var joined = [];
        if (exists) {
            joined = this.state.dropdownhide.filter((item) => item != fkey)
        } else {
            joined = this.state.dropdownhide.concat(fkey);
        }
        this.setState({ dropdownhide: joined })
    }

    handleFilterSubmit = () => {
        var allowToFilter = true
        let copyObj = JSON.parse(JSON.stringify(this.state.filterObject));
        var filterValuesArray = Object.keys(copyObj);
        var finalFilterData = filterValuesArray.map((k, i) => {
            let v = copyObj[k];

            if (v.value.length > 0) {
                var valuesArray = [];
                v.value.map((val, k) => {
                    if (v.type === "date") {
                        if (val == "Invalid Date") {
                            allowToFilter = false
                        } else if (val == "") {
                            valuesArray.push(val)
                        } else if (val == null) {
                            valuesArray.push("")
                        } else if (val != null) {
                            if(v.fieldid != "0"){
                                 val = moment(val).format('YYYY-MM-DD');
                            }else{
                                //val = moment(val).format("X");
                                val = moment(val).format('MM/DD/YYYY');
                            }
                            valuesArray.push(val)
                        }
                    }else if(v.type == "log"){
                        valuesArray.push(val.id);
                    } else if (v.fieldid == '46' && val.email) {
                        valuesArray.push(val.email)
                    } else if (v.fieldid != '46' && val.name) {
                        valuesArray.push(val.name)
                    } else if (val.optionvalue) {
                        valuesArray.push(val.optionvalue)
                    } else if (v.fieldid === "56" || v.type === "range") {//tag array and value
                        valuesArray.push(val);
                    } else {
                        valuesArray.push(val);
                    }
                })


                let checkBoxKey = -1
                if (this.state.fieldsList != undefined) {
                    checkBoxKey = this.findWithAttr(this.state.fieldsList, 'id', v.fieldid);
                }



                v.value = valuesArray;
                if (v.type == "followed") {//follow/not follow filter only value is true
                    if (v.value[0] === true) {
                        return v
                    }
                } else if (checkBoxKey != -1 && this.state.fieldsList[checkBoxKey].fieldtype.id == "3" && v.value[0] == "1") {
                    return v
                } else if (checkBoxKey != -1 && this.state.fieldsList[checkBoxKey].fieldtype.id != "3") {
                    return v
                } else if (checkBoxKey != -1 && this.state.fieldsList[checkBoxKey].fieldtype.id == "3") {
                } else {
                    return v
                }
            }
        })

        //filter null and undefined object 
        finalFilterData = finalFilterData.filter(function (element) {
            return element !== undefined || (element !== undefined && element.value.length != 0);
        });
        console.log("allowToFilter", allowToFilter);
        console.log("finalFilterData", finalFilterData);
        if (allowToFilter) {
            //saveFilter Data for save in future use.
            this.setState({
                filterdata: finalFilterData,
                isDataLoading: true,
            })

            let performanceparams = {
                filterdata: this.state.filterdata,
                piplineid: parseFloat(this.state.selectedPipline),
                type: parseFloat(this.state.value_type),
                fromdate: this.state.fromdate,
                todate: this.state.todate,
                datetype: parseFloat(this.state.datetype),
                timeno: this.state.timeno,
                memberid: 0,
            };
    
            this.getsalesperformance(performanceparams);
        }
    }

    handleSavefilter = () => {
        if (this.state.filtername == "") {
            this.setState({ filternameError: true })
            return false
        }
        if (this.state.filterdata && this.state.filterdata.length > 0) {
            let req = [];
            req['savefilter'] = {
                filterdata: this.state.filterdata,
                fitertype: this.state.fitertype,
                filtername: this.state.filtername,
                recordtypeid: this.state.recordtypeid,
                reportno: this.state.reportno,
            };
            req['getfilter'] = {reportno:this.state.reportno};
            console.log(req,'filter save data');
            this.props.saveReportFilterDetail(this.props.apolloClient.client, req);
            this.handleSavefilterDialogeClose()
            this.setState({
                displaySelectedFilterName: this.state.filtername,
                newSavedFilterName: this.state.filtername,
            })
        } else {
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
        }
        setTimeout(() => {
            this.setState({
                allowStateUpdateFilter: true,
            })
        }, 2000);
    }

    updateOpportunityFilter = (data) => {
        // console.log("data", data);
        this.setState({
            filterSaveDialoge: true,
            filterUpdateId: data.id,
            filtername: data.filtername,
            fitertype: data.fitertype.toString(),
            oldFilterOption: data.filteroption,
        })
    }

    handleUpdatefilter = () => {
        if (this.state.filtername == "") {
            this.setState({ filternameError: true })
            return false
        }
        let req = {
            filterdata: this.state.oldFilterOption,
            fitertype: this.state.fitertype,
            filtername: this.state.filtername,
            filterid: parseInt(this.state.filterUpdateId),
            reportno: this.state.reportno,
        }
        //return
        this.props.updateReportFilterDetail(this.props.apolloClient.client, req);
        let updateDataInState = this.findWithAttr(this.state.filterlist, 'id', this.state.filterUpdateId);
        if (updateDataInState !== -1) {
            this.state.filterlist[updateDataInState].filtername = this.state.filtername;
            this.state.filterlist[updateDataInState].fitertype = this.state.fitertype;
        }
        this.handleSavefilterDialogeClose()
    }

    updateFilterOptions = () => {
        let key = this.findWithAttr(this.props.reportReducer.performancesummaryreportfilterlist.data, 'id', localStorage.getItem('selected_report_pipeline_summary_filter_id'));
        var data = ""
        if (key !== -1) {
            data = this.props.reportReducer.performancesummaryreportfilterlist.data[key];
            if (this.state.filterdata && this.state.filterdata.length > 0) {
                let req = {
                    filterdata: this.state.filterdata,
                    fitertype: data.fitertype.toString(),
                    filtername: data.filtername,
                    filterid: parseInt(data.id),
                    reportno: this.state.reportno,
                }
                this.props.updateReportFilterDetail(this.props.apolloClient.client, req);
                this.handleSavefilterDialogeClose()
            } else {
                //dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
            }
            setTimeout(() => {
                this.setState({
                    allowStateUpdateFilter: true,
                })
            }, 1500);
        }
    }

    confirmDeleteFilter = () => {
        this.props.deleteReportFilter(this.props.apolloClient.client, {filterid:parseInt(this.state.filterDeleteId), reportno: this.state.reportno});
        let removeFilterObjKey = this.findWithAttr(this.props.reportReducer.performancesummaryreportfilterlist.data, 'id', this.state.filterDeleteId);
        if (removeFilterObjKey !== -1) {
            if (!this.props.reportReducer.performancesummaryreportfilterlist.data[removeFilterObjKey].isdefault == true) {
                this.props.reportReducer.performancesummaryreportfilterlist.data.splice(removeFilterObjKey, 1);
            }
        }
        this.filterRemoveDialoge()
    }

    filterRemoveDialoge = () => {
        this.setState({ isDeleteFilter: false, filterDeleteId: "", allowStateUpdateFilter: true })
    }

    handleSavefilterDialoge = () => {
        this.setState({ filterSaveDialoge: true })
    }

    handleSavefilterDialogeClose = () => {
        this.setState({
            filterSaveDialoge: false,
            filternameError: false,
            fitertype: "1",
            filterUpdateId: "",
            oldFilterOption: "",
            filtername: "",
        })
    }

    handleFilterChange = (filterdata) => {
        
        this.setState({
            filterObject: {},
            filterRangeArray: [],
            fieldDisplay: [],
            dropdownhide: [],
        }, () => {
            this.forceUpdate()
            this.setState({ displaySelectedFilterName: filterdata.filtername, currentPage: 0 })
            if (filterdata.filteroption.length > 0) {
                
                let filterObject = this.state.filterObject;
                // console.log(filterdata.filteroption, filterObject,'filterdata.filteroption');

                let filterRangeArray = this.state.filterRangeArray;
                let displayField = []
                let dropdownhideShow = []
                var promise = new Promise((resolve, reject) => {
                    filterdata.filteroption.map((val, k) => {
                        if (val.name == "activitytype") {
                            this.setState({ activityquery: val.query })
                            if(val.value.length > 0){
                                var arr = []
                                val.value.map(v=>{
                                    var k = this.findWithAttr(this.state.masterActivityTypesList, 'id',v)
                                    if(k != -1){
                                        arr.push(this.state.masterActivityTypesList[k])
                                    }
                                })
                                val.value = arr;

                            }
                        }

                        filterObject = {
                            ...filterObject,
                            [val.name]: val
                        }
                        if (val.name == "tags") {
                            this.setState({ tagsquery: val.query })
                        }

                        if (val.type === "range") {
                            filterRangeArray[val.name] = ["", ""];
                            filterRangeArray[val.name][0] = val.value[0];
                            filterRangeArray[val.name][1] = val.value[1];
                            this.setState({ filterRangeArray })
                            displayField.push(val.name)
                        }

                        if (val.type == "date") {
                            filterRangeArray[val.name] = ["", ""];
                            filterRangeArray[val.name][0] = val.value[0] == "" ? null : val.value[0];
                            filterRangeArray[val.name][1] = val.value[1] == "" ? null : val.value[1];
                            this.setState({ filterRangeArray })
                            if (val.value[0] != "" && val.value[1] != "") {
                                displayField.push(val.name)
                            } else if (val.value[0] != "" || val.value[1] != "") {
                                displayField.push(val.name)
                            }
                        }

                    })
                    this.setState({
                        filterObject,
                        fieldDisplay: displayField,
                        dropdownhide: dropdownhideShow,
                    }, () => this.forceUpdate())
                    resolve(true)
                });

                promise.then(result => {
                    this.handleFilterSubmit();
                    localStorage.setItem('selected_report_pipeline_summary_filter_id', filterdata.id)
                });
            } else {
                localStorage.setItem('selected_report_pipeline_summary_filter_id', filterdata.id)
                this.handleFilterSubmit();
            }
        })

    }

    updateFilterDefaultReport = (data) => {
        this.props.updateReportFilterDefault(this.props.apolloClient.client, {filterid:parseInt(data.id), reportno: this.state.reportno});
        setTimeout(() => {
            this.setState({
                allowStateUpdateFilter: true,
            })
        }, 2000);
    }

    // -----------------------------------------------------

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    handleOpenDrawer = () => {
        this.setState({ drawerOpen: true, })
        this.closedatepicker();
    }

    handleCloseDrawer = () => {
        this.setState({ drawerOpen: false, })
    }

    toggle = () => {
        this.setState({ dateopen: !this.state.dateopen });
    }
    
    closedatepicker = () => {
        this.setState({ dateopen: false});
    }

    setDateRange = (range) => {
        console.log(range, range.timeno, range.label, range.startDate, range.endDate, 'range');
        let startDate = moment(range.startDate).format('YYYY-MM-DD');
        let endDate = moment(range.endDate).format('YYYY-MM-DD');

        let performanceparams = {
            filterdata: this.state.filterdata,
            piplineid: parseFloat(this.state.selectedPipline),
            type: parseFloat(this.state.value_type),
            fromdate: startDate,
            todate: endDate,
            datetype: parseFloat(this.state.datetype),
            timeno: range.timeno ? parseFloat(range.timeno) : 0,
            memberid: 0,
        };
        this.getsalesperformance(performanceparams);
        
        
        
        if (range.label) {
            this.setState({ selectedDatelabel: range.label })
        } else {            
            this.setState({ selectedDate: {from: startDate, to: endDate}, selectedDatelabel:"" });
        }
        if(range.compareLabel){
            this.setState({compareLabel: range.compareLabel})
        }
        this.setState({fromdate: startDate, todate: endDate, timeno: range.timeno ? parseFloat(range.timeno) : 0})
    }


    render() {
        // filter includes 
        var localStorageFilter = localStorage.getItem('selected_report_pipeline_summary_filter_id');
        let updateSaveFilterBtnDisabled = (this.state.filterdata == undefined || !this.state.filterdata.length) ? ((this.state.oldFilterOption == undefined || !this.state.oldFilterOption.length) ? true : false) : false;
        
        let { pipelineList, reportTitile, isOpenedFiltersDefault, selectedDatelabel, selectedDate, reportSubTitle, dateopen, totalopp, selectedFilter, totlaValue, drawerOpen, totalWinRate, selectedPipline, value_type, datetype } = this.state;
        return (
            <React.Fragment>
                <div>
                    {/* <ReportSidebarMenu /> */}
                    <div className="lead_content right-16 settings_view">
                        <Card elevation={3}>
                            <div className="report_card" style={{ height : "calc(100vh - 200px)", overflowY: "auto", overflowX: "hidden",  }}>
                                <div className="report_header">
                                    <h3>{reportTitile}</h3>
                                    <Grid container direction="row" justify="space-between" alignItems="center" onClick={this.closedatepicker}>
                                        <Grid><Typography className="Typography" variant="p">{reportSubTitle}</Typography></Grid>
                                        <Grid><div className="mr-36">
                                            <IconButton onClick={() => this.handleOpenDrawer()}>
                                                <Badge badgeContent={this.state.filterdata ? this.state.filterdata.length : 0} color="primary">
                                                    <FilterListIcon />
                                                </Badge>
                                            </IconButton>
                                        </div></Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center"
                                        className="mt-40 mb-40"
                                        className="crm-all-report-filters"
                                    >
                                        <div className="report_filter" onClick={this.closedatepicker}>
                                            <InputLabel id="Pipeline-select-helper-label">Pipeline</InputLabel>
                                            <Select
                                                className="w-200"
                                                labelId="Pipeline-select-label"
                                                name="selectedPipline"
                                                id="Pipeline"
                                                value={selectedPipline}
                                                onChange={this.handlePiplineChange}
                                            >
                                                <MenuItem value="0">All</MenuItem>
                                                {pipelineList && pipelineList.map((pipeline)=>(
                                                    <MenuItem value={pipeline.id}>{pipeline.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                        {/* ---------------- filter start ------------------------- */}
                                        <div className="report_filter" onClick={this.closedatepicker}>
                                            <div>
                                                <Button aria-describedby={'simple-popover'} variant="" color="default" onClick={this.handleClick}>
                                                        <Tooltip title={this.state.displaySelectedFilterName }>
                                                            <div className="text_ellipsis" style={{maxWidth:'130px',fontSize:'13px',margin:'3px 0px 0px 0px',fontWeight:'400'}}>
                                                                {this.state.displaySelectedFilterName}
                                                            </div>
                                                        </Tooltip>
                                                        <Icon className="vert-middle flex-end">{isOpenedFiltersDefault ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                                                </Button>
                                                <Popover
                                                    id={'simple-popover'}
                                                    open={this.state.isOpenedFiltersDefault}
                                                    anchorEl={this.state.anchorEl}
                                                    onClose={this.handleClose}
                                                    anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                    }}
                                                    transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                    }}
                                                >
                                                    <Card elevation={3} className="report_filter_menu_custom">
                                                        {isOpenedFiltersDefault &&
                                                            <div>
                                                                <h6 className="mt-8 font-size-14 mb-0 pl-16 pr-16">Private Filters </h6>
                                                                <ul className="pl-20">
                                                                    {this.state.filterlist.length > 0 && this.state.filterlist.map((item, key) => {
                                                                        let star_icon = item.isdefault ? "star" : "star_border";
                                                                        if (item.fitertype == 1 && item.id != localStorage.getItem('selected_report_pipeline_summary_filter_id')) {
                                                                            return (<li key={item.key}>
                                                                                <label className={"mr-20"} onClick={() => this.handleFilterChange(item)}>{item.filtername}</label>
                                                                                <>
                                                                                    <IconButton className="text-secondary ml-4" size="small">
                                                                                        <Icon onClick={() => this.updateOpportunityFilter(item)} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{'settings'}</Icon>
                                                                                    </IconButton>
                                                                                    <IconButton className="text-secondary ml-4" size="small">
                                                                                        <Icon onClick={() => this.setState({ isDeleteFilter: true, filterDeleteId: item.id })} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{'delete'}</Icon>
                                                                                    </IconButton>
                                                                                    <IconButton className="text-secondary ml-4" size="small">
                                                                                        <Icon onClick={() => this.updateFilterDefaultReport(item)} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{star_icon}</Icon>
                                                                                    </IconButton>
                                                                                </>
                                                                            </li>)
                                                                        }

                                                                        
                                                                    }
                                                                    )}
                                                                </ul>
                                                                <h6 className="mt-8 font-size-14 mb-0 mt-16 pl-16 pr-16">Public Filters</h6>
                                                                <ul className="pl-20">
                                                                    {this.state.filterlist.length > 0 && this.state.filterlist.map((items, key) => {
                                                                        let star_icon = items.isdefault ? "star" : "star_border";
                                                                        if (items.fitertype == 0 && items.id != localStorage.getItem('selected_report_pipeline_summary_filter_id')) {
                                                                            return <li key={items.key}>
                                                                                <label className={"mr-20"} onClick={() => this.handleFilterChange(items)}>{items.filtername}</label>
                                                                                {!["6"].includes(items.id) &&
                                                                                    <>
                                                                                        <IconButton className="text-secondary ml-4" size="small">
                                                                                            <Icon onClick={() => this.updateOpportunityFilter(items)} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{'settings'}</Icon>
                                                                                        </IconButton>
                                                                                        <IconButton className="text-secondary ml-4" size="small">
                                                                                            <Icon onClick={() => this.setState({ isDeleteFilter: true, filterDeleteId: items.id })} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{'delete'}</Icon>
                                                                                        </IconButton>
                                                                                    </>
                                                                                }
                                                                                <IconButton className="text-secondary ml-4" size="small">
                                                                                    <Icon onClick={() => this.updateFilterDefaultReport(items)} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{star_icon}</Icon>
                                                                                </IconButton>
                                                                            </li>
                                                                        }
                                                                    }
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        }
                                                    </Card>
                                                </Popover>
                                            </div>
                                        </div>
                                        {/* -----------------filter end-------------------------- */}
                                        <div className="cursor-pointer report_date_picker w-200" onClick={this.toggle}>
                                            <InputLabel className="mb-10" onClick={this.toggle} id="">Date Range</InputLabel>
                                            {/* {this.state.selectedDate} */}
                                            {this.state.selectedDatelabel == "" ? this.state.selectedDate.from + " to " + this.state.selectedDate.to : this.state.selectedDatelabel}
                                        
                                            <div className="crm-all-report-filters-daterange">
                                            <DateRangePicker
                                                    // wrapperClassName={"position-absolute"}
                                                    open={dateopen}
                                                    toggle={this.toggle}
                                                    definedRanges={this.state.definedRanges}
                                                    onChange={(range) => this.setDateRange(range)}
                                                    initialDateRange={{startDate: moment().startOf('year'),endDate: moment().endOf('year')}}
                                                />
                                            </div>
                                        </div>

                                        
                                    </Grid>
                                </div>
                                <div className="report_content" onClick={this.closedatepicker}>
                                    <Grid container spacing={3} justify="center">
                                        {this.state.performanceSummaryArray.map((fieldData, index) => (
                                            <Grid item xs={3}>
                                                <Card className="p-16 font-weight-500" style={{ border: "1px solid #bdbdbd" }} >
                                                    <Grid justify="space-between" container>
                                                        <Grid item xs="9">
                                                            <h6 className="font-weight-600 text-gray uppercase">{fieldData.name}</h6>
                                                            <h3>{fieldData.value}</h3>
                                                        </Grid>
                                                        <Grid item xs="3">
                                                            <Avatar className={fieldData.color}>
                                                                {fieldData.icon}
                                                            </Avatar>
                                                        </Grid>
                                                    </Grid>
                                                    {this.state.timeno != 0 &&
                                                        <Grid>
                                                            <ArrowUpwardIcon className="mr-4 text-green" style={{ marginBottom: "-5px" }} />{fieldData.precentage}{(this.state.compareLabel != "") ? " ("+this.state.compareLabel+")" : "dfgdfg"}
                                                        </Grid>
                                                    }
                                                </Card>
                                            </Grid>
                                        ))}

                                    </Grid>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
                {/*-------------------- filter start------------------------- */}
                <Drawer
                    width={"100px"}
                    variant="temporary"
                    anchor={"right"}
                    className="drawer_right"
                    open={drawerOpen}
                    onClose={this.handleCloseDrawer}
                    ModalProps={{ keepMounted: true }}

                    onClick={this.closedatepicker}
                >
                    <React.Fragment>
                        <div className="frame_body">
                            <div className="filter_Drawer_header">
                                <Grid container justify="space-between" alignItems="center">
                                    <Grid item>
                                        <IconButton size="small" className="ml-10"
                                            onClick={this.handleCloseDrawer}><Icon>arrow_forward</Icon>
                                        </IconButton></Grid>
                                    <Grid item>
                                        <h4 className="m-10">Filter Opportunities </h4>
                                    </Grid>
                                    <Grid item className="m-10">
                                        <Button onClick={() => this.handleFilterChange(this.state.allDefaultKeyFilter)} type="button" className="btn_Primary mb-10 mt-10 px-12" size="small" >Clear Filter</Button>
                                    </Grid>
                                    <Grid item md={12} className={"report_opportunity_filter"}>
                                        <div className="v_scroll position-relative ml-16 mt-16 pr-16">
                                            {/* <Link className="text_link" >Clear All</Link> */}
                                            {opportunityFilterComponent(this)}
                                        </div>
                                        <div className="fixed-bottom" style={{ position: 'initial' }}>
                                            <Button type="button" className="btn_Primary  mb-16 px-12" size="small" onClick={() => this.handleSavefilterDialoge()}>Save Filter</Button>
                                            {localStorageFilter && !["6"].includes(localStorageFilter) &&
                                                <Button type="button" className="btn_Primary  mb-16 px-12 ml-16" size="small" onClick={() => this.updateFilterOptions()}>Update Filter</Button>
                                            }
                                        </div>
                                    </Grid>
                                </Grid>
                                {/* {isOpenedFilters && */}
                                
                                {/* } */}
                            </div>
                        </div>
                    </React.Fragment>
                </Drawer>

                <Dialog
                    fullWidth={true}
                    maxWidth={'xs'}
                    open={this.state.filterSaveDialoge}
                    onClose={() => this.handleSavefilterDialogeClose()}
                    disableBackdropClick
                    disableEscapeKeyDown
                    aria-labelledby="delele-dialog-title"
                >
                    <DialogTitle id="">
                        {!this.state.filterUpdateId ? "Add a New Filter" : "Edit Filter"}
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <ValidatorForm instantValidate={false} ref="form">
                            <FormControl error={this.state.filternameError} className="w-100 input_bx">
                                <InputLabel htmlFor="component-error">Name</InputLabel>
                                <Input
                                    type="text"
                                    id={'filtername'}
                                    label={'Filter Name'}
                                    className="w-100 input_bx"
                                    onChange={(event) => this.handleChange(event)}
                                    name={'filtername'}
                                    value={this.state.filtername}
                                />
                                {this.state.filternameError && <FormHelperText id="component-error-text">This field is required</FormHelperText>}
                            </FormControl>
                            <br /><br />
                            <div>
                                <span>visibility</span>
                                <br />
                                <RadioGroup name="fitertype" value={this.state.fitertype} onChange={(event) => this.handleChange(event)} row>
                                    <FormControlLabel
                                        value="1"
                                        control={<Radio color="primary" />}
                                        label="Private"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="0"
                                        control={<Radio color="primary" />}
                                        label="Public"
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </div>
                        </ValidatorForm>
                    </DialogContent>
                    <DialogActions>
                        {updateSaveFilterBtnDisabled && <p className="lead-filter-error">Please select filter options to save filter</p>}
                        <Button onClick={() => this.handleSavefilterDialogeClose()} color="primary">
                            Cancel
                        </Button>
                        {!this.state.filterUpdateId && <Button variant="contained" disabled={updateSaveFilterBtnDisabled} type="button" color="primary" onClick={() => this.handleSavefilter()}>Save</Button>}
                        {this.state.filterUpdateId && <Button variant="contained" disabled={updateSaveFilterBtnDisabled} type="button" color="primary" onClick={() => this.handleUpdatefilter()}>Update</Button>}
                    </DialogActions>
                </Dialog>
                <Dialog
                    fullWidth={true}
                    maxWidth={'xs'}
                    open={this.state.isDeleteFilter}
                    onClose={this.filterRemoveDialoge}
                    disableBackdropClick
                    disableEscapeKeyDown
                    aria-labelledby="ar-remove-dialog-title"
                >
                    <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to delete filter ?"}</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.filterRemoveDialoge} color="primary">Cancel</Button>
                        <Button type="Button" onClick={() => this.confirmDeleteFilter()} className="btn_Primary">
                            Ok
                    </Button>
                    </DialogActions>
                </Dialog>
                {/*-------------------- filter done------------------------- */}
            </React.Fragment >
        );
    }
}

const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
    notification: state.notification,
    reportReducer: state.reportReducer,
});
const mapDispatchToProps = dispatch => {
    return {
        getPipelines: (client) => dispatch(actions.getPipelines(client)),
        getTagsList: (client) => dispatch(actions.getTagsList(client)),
        getUserList: (client) => dispatch(actions.getUserList(client)),
        getActivityTypes: (client) => dispatch(actions.getActivityTypes(client)),
        customFieldList: (client, value) => dispatch(actions.customFieldList(client, value)),

        salesPerformanceSummary: (client, value) => dispatch(actions.salesPerformanceSummary(client, value)),
        getReportFilterList: (client, value) => dispatch(actions.getReportFilterList(client, value)),
        saveReportFilterDetail: (client, value) => dispatch(actions.saveReportFilterDetail(client, value)),
        deleteReportFilter: (client, value) => dispatch(actions.deleteReportFilter(client, value)),
        updateReportFilterDefault: (client, value) => dispatch(actions.updateReportFilterDefault(client, value)),
        updateReportFilterDetail: (client, value) => dispatch(actions.updateReportFilterDetail(client, value)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SalesPerformanceSummary);