import React, { Component } from "react";
import ContactTableComponent from './ContactTableComponent';
import ContactCreationRule from './ContactCreationRule'
import { Icon, Switch, FormControlLabel, IconButton, withStyles, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import FontAwesome from 'react-fontawesome'
import CreateNewFieldcontactDialog from "./CreateNewFieldcontactDialog";
import * as actions from '../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.5rem",
    }
}))(Icon);

class SettingContact extends Component {

    constructor(props) {
        super(props);

        this.state = {
            contactData: [],
            fieldIds: [],
            orderIds: [],
            activecontactDatas: [],
            inactivecontactDatas: [],
            shouldOpenCreateFieldcontactDialog: false,
        };
    }
    handleChange = (event) => {
        let val = {
            typeId: 2,
            fieldstatus: event.target.checked,
            generatedfieldid: event.target.value
        }

        this.props.updateCustomFieldStatus(this.props.apolloClient.client, val);
    }
    componentDidMount() {
        let val = { typeId: 2 }
        this.props.customFieldList(this.props.apolloClient.client, val);
        this.props.customFieldTypeList(this.props.apolloClient.client);


    }
    componentWillReceiveProps(props) {
        
        if (props.settingReducer.contactCustomFieldList.data && props.settingReducer.customFieldTypeList.data) {
            const contactCustomFieldList = props.settingReducer.contactCustomFieldList.data;

            let activecontactDatas = [];
            let inactivecontactDatas = [];
            let fieldIds = [];
            let orderIds = [];
           

            contactCustomFieldList.map((fieldData, index) => {
                
                if (fieldData.fieldstatus === true) {
                    fieldIds.push(parseFloat(fieldData.id));
                    orderIds.push(parseFloat(fieldData.orderno));

                    let fieldlicon = (fieldData.fieldicon === 'fa-dollar-sign') ? 'fa-usd' : fieldData.fieldicon;

                    activecontactDatas.push({
                        drag: <Link className="drag-handle" orderIds={orderIds} to="#"><Icon>drag_handle</Icon></Link>,
                        key: fieldData.id,
                        name: (
                            <div>
                                <FontAwesome className={'crm-fontawsome-icon ' + fieldlicon} ></FontAwesome>
                                <label className="crm-custom-field-label">{fieldData.fieldlabel}</label>
                            </div>
                        ),
                        fieldType: fieldData.fieldtype.name,
                        creationRule: (fieldData.recordcreationrules_editable) ?
                            <ContactCreationRule dropName="create" contactFieldId={index} options={fieldData.recordcreationrulesvalue} disabled={false} defaultChecked={fieldData.recordcreationdefaultvalue} />
                            :
                            <ContactCreationRule dropName="create" contactFieldId={index} options={fieldData.recordcreationrulesvalue} disabled={true} defaultChecked={fieldData.recordcreationdefaultvalue} />,
                        editRule: (fieldData.recordeditrules_editable) ?
                            <ContactCreationRule dropName="edit" contactFieldId={index} options={fieldData.recordeditrulesvalue} disabled={false} defaultChecked={fieldData.recordeditdefaultvalue} />
                            :
                            <ContactCreationRule dropName="edit" contactFieldId={index} options={fieldData.recordeditrulesvalue} disabled={true} defaultChecked={fieldData.recordeditdefaultvalue} />,
                        action: <React.Fragment>

                            <FormControlLabel
                                value={fieldData.generatedfieldid}
                                control={(fieldData.fieldstatus) ? <Switch onChange={this.handleChange} color="primary" defaultChecked disabled={!fieldData.isdiactivated} /> : <Switch onChange={this.handleChange} color="primary" disabled={!fieldData.isdiactivated} />}
                                labelPlacement="start"
                                className="ml-0 mr-10"
                            />
                        </React.Fragment>
                    })
                }

                if (fieldData.fieldstatus === false) {
                    inactivecontactDatas.push({
                        key: fieldData.id,
                        name: fieldData.fieldlabel,
                        action: <React.Fragment>
                            <FormControlLabel
                                value={fieldData.generatedfieldid}
                                control={(fieldData.fieldstatus) ? <Switch onChange={this.handleChange} color="primary" defaultChecked disabled={!fieldData.isdiactivated} /> : <Switch onChange={this.handleChange} color="primary" disabled={!fieldData.isdiactivated} />}
                                labelPlacement="start"
                                className="ml-0 mr-10"
                            />
                        </React.Fragment>
                    })
                }
            })

            this.setState({ activecontactDatas: activecontactDatas, inactivecontactDatas: inactivecontactDatas, fieldIds: fieldIds, orderIds: orderIds }, () => this.forceUpdate());
        }
    }
    closePopUp = () => {
        this.setState({ shouldOpenCreateFieldcontactDialog: false });
    }
    updateContactFieldsOrder = (fromIndex, toIndex) => {

        let fieldIds = [];

        const contactData = this.state.activecontactDatas;
        const item = contactData.splice(fromIndex, 1)[0];

        contactData.splice(toIndex, 0, item);

        this.setState({
            contactData
        })
        const orderIds = this.state.orderIds;
        contactData.map((value, index) => {
            fieldIds.push(parseFloat(value.key));
        });

        let val = {
            typeId: 2,
            orderno: orderIds,
            fieldid: fieldIds
        }
        this.props.updateCustomFieldOrder(this.props.apolloClient.client, val);
    }
    handlecontactDialogClose = () => {
        this.setState({ shouldOpenCreateFieldcontactDialog: false });
    };
    render() {
        let { shouldOpenCreateFieldcontactDialog } = this.state;
        return (
            <>
                <div className="d-flex justify-content-end mb-16">
                    <Button variant="contained" color="primary" className="btn_Primary" onClick={() => this.setState({ shouldOpenCreateFieldcontactDialog: true })} size="small">Add Field</Button>
                    {shouldOpenCreateFieldcontactDialog && (
                        <CreateNewFieldcontactDialog
                            handleClose={this.handlecontactDialogClose}
                            open={shouldOpenCreateFieldcontactDialog}
                            allData={this}
                        />
                    )}
                </div>
                <ContactTableComponent fieldIds={this.state.fieldIds} orderIds={this.state.orderIds} activecontactData={this.state.activecontactDatas} inActivecontactData={this.state.inactivecontactDatas} allData={this} />
            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        settingReducer: state.settingReducer,
        apolloClient: state.apolloClient
    };
};
const mapDispatchToProps = dispatch => {
    return {
        customFieldList: (client, request) => { dispatch(actions.customFieldList(client, request)) },
        customFieldTypeList: (client) => { dispatch(actions.customFieldTypeList(client)) },
        updateCustomFieldStatus: (client, request) => { dispatch(actions.updateCustomFieldStatus(client, request)) },
        updateCustomFieldOrder: (client, request) => { dispatch(actions.updateCustomFieldOrder(client, request)) },

    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(SettingContact);
