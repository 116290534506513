import React, { Component } from "react";
import SettingSidebar from '../Layout/SettingSidebarMenu';
import "../../styles/_settings.scss"
import { Route } from 'react-router-dom';
import RelatedSettingTab from "./Relatedsettings/RelatedSettingTab";
import EmailSettings from "./EmailSettings/EmailSettingTab";
import Notifications from "./Notifications/Notifications";
import ImportData from "./ManageData/ImportData";
import ImportCompanies from "./ManageData/ImportCompanies";
import ImportOpportunities from "./ManageData/ImportOpportunities";
import ImportPeople from "./ManageData/ImportPeople";
import ImportLead from "./ManageData/ImportLead";
import UploadCompanies from "./ManageData/UploadCompanies";
import UploadOpportunities from "./ManageData/UploadOpportunities";
import UploadPeople from "./ManageData/UploadPeople";
import UploadLead from "./ManageData/UploadLead";
import ExportData from "./ManageData/ExportData";
import SettingTab from "./SettingTab";
import SettingsPipeLine from "./SettingPipeline";
import UserManagement from './UserManagement/UserManagement'
import SettingTask from './SettingTask';
import LeadStatuses from "./LeadStatuses";
import ContactTypes from "./ContactTypes";


import Sources from "./Sources";
import Currency from "./Currency";
import MyPreferences from './MyPreferences';
import LossReason from "./LossReason";
import ActivityTypes from "./ActivityTypes";
import TagManagement from "./TagManagement";
import projectStage from "./projectStage";
import Integrations from "./Integrations/Integrations";
import ZendeskIntegrations from './Integrations/Zendesk/ZendeskIntegrations';
import QuickBookIntegrations from './Integrations/QuickBookIntegrations';
import XeroIntegrations from './Integrations/XeroIntegrations';
import SlackIntegrations from './Integrations/SlackIntegrations';
import DocuSignIntegrations from './Integrations/DocuSignIntegrations';
import WorkflowAutomation from "./WorkflowAutomation/WorkflowAutomation";
import AddWorkAutomation from "./WorkflowAutomation/AddWorkAutomation";
import EditWorkAutomation from "./WorkflowAutomation/EditWorkAutomation";
import TaskAutomation from "./TaskAutomation/TaskAutomation";
import AddTaskAutomation from "./TaskAutomation/AddTaskAutomation";
import TeamList from "./Teams/TeamVisibility/TeamList";
import TeamDetails from "./Teams/TeamVisibility/TeamDetails";

import Goals from "./Teams/Goals/Goalsmain";
import Billing from "./Billing/BillingMain";
import StripePay from "./Billing/StripePay";
import SaveBilling from "./Billing/SaveBilling";



class Settings extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    // this method sets the current state of a menu item i.e whether it is in expanded or collapsed or a collapsed state
    handleClick(item) {
        this.setState(prevState => (
            { [item]: !prevState[item] }
        ))
    }
    render() {
        return (
            <React.Fragment>
                <SettingSidebar />
                <div className="lead_content right-16 settings_view">
                    <Route exact path="/settings/settings/" component={SettingTab} />
                    <Route exact path="/settings/relatedsettings" component={RelatedSettingTab} />
                    <Route exact path="/settings/setting-task/" component={SettingTask} />
                    <Route exact path="/settings/pipeline-stages/" component={SettingsPipeLine} />
                    <Route exact path="/settings/user-management/" component={UserManagement} />
                    <Route exact path="/settings/lead-statuses" component={LeadStatuses} />
                    <Route exact path="/settings/contact-types" component={ContactTypes} />
                    <Route exact path="/settings/sources" component={Sources} />
                    <Route exact path="/settings/currency" component={Currency} />
                    <Route exact path="/settings/my-preferences/" component={MyPreferences} />
                    <Route exact path="/settings/email-settings/" component={EmailSettings} />
                    <Route exact path="/settings/notifications/" component={Notifications} />
                    <Route exact path="/settings/import-data/" component={ImportData} />
                    <Route exact path="/settings/import-companies/" component={ImportCompanies} />
                    <Route exact path="/settings/import-companies/:id" component={UploadCompanies} />
                    <Route exact path="/settings/import-opportunities/" component={ImportOpportunities} />
                    <Route exact path="/settings/import-opportunities/:id" component={UploadOpportunities} />
                    <Route exact path="/settings/import-people/" component={ImportPeople} />
                    <Route exact path="/settings/import-people/:id" component={UploadPeople} />
                    <Route exact path="/settings/import-leads/" component={ImportLead} />
                    <Route exact path="/settings/import-leads/:id" component={UploadLead} />

                    <Route exact path="/settings/export-data/" component={ExportData} />

                    <Route exact path="/settings/loss-reason" component={LossReason} />
                    <Route exact path="/settings/activity-types" component={ActivityTypes} />
                    <Route exact path="/settings/tag-management" component={TagManagement} />
                    <Route exact path="/settings/project-stage" component={projectStage} />
                    <Route exact path="/settings/workflow-automation/" component={WorkflowAutomation} />
                    <Route exact path="/settings/add-new-workflow-automation/" component={AddWorkAutomation} />
                    <Route exact path="/settings/edit-workflow-automation/:id" component={EditWorkAutomation} />
                    <Route exact path="/settings/task-automation/" component={TaskAutomation} />
                    <Route exact path="/settings/add-new-task-automation/" component={AddTaskAutomation} />
                    <Route exact path="/settings/integrations" component={Integrations} />
                    <Route exact path="/settings/zendesk/integrations" component={ZendeskIntegrations} />
                    <Route exact path="/settings/quickbook/integrations" component={QuickBookIntegrations} />
                    <Route exact path="/settings/xero/integrations" component={XeroIntegrations} />
                    <Route exact path="/settings/slack/integrations" component={SlackIntegrations} />
                    <Route exact path="/settings/docuSign/integrations" component={DocuSignIntegrations} />

                    <Route exact path="/settings/goals/" component={Goals} />
                    <Route exact path="/settings/teams-permissions" component={TeamList} />
                    <Route exact path="/settings/team-detail/:teamId" component={TeamDetails} />
                    <Route exact path="/settings/billings" component={Billing} />
                    <Route exact path="/settings/checkout" component={StripePay} />
                    <Route exact path="/settings/saveBilling" component={SaveBilling} />
                    
                    

                </div>
            </React.Fragment>
        );
    }
}

export default Settings;


