import React, { Component } from "react";
import {
    Button, withStyles, CardContent, Icon, Snackbar,
    Tooltip, Grid, DialogActions, DialogTitle,
    DialogContent, InputLabel, Dialog, IconButton, Link
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import * as actions from '../../../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';

const TooltipNew = withStyles((theme) => ({
    tooltip: {
        fontSize: 14,
    },
}))(Tooltip);

class AddTeam extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            minimizeScreen: false,
            fullScreen: false
        }
    }
    handleViewEmailDialogMinimize = () => {
        this.setState(oldStateminimizeScreen => ({ minimizeScreen: !oldStateminimizeScreen.minimizeScreen }));
        this.setState({ fullScreen: false })
    };

    handleViewEmailDialogFull = () => {
        this.setState(oldStatefullScreen => ({ fullScreen: !oldStatefullScreen.fullScreen }));
        this.setState({ minimizeScreen: false })
    };


    handleFormSubmit = () => {
        let req = {
            teamid: 0,
            teamname: this.state.name
        }
        this.props.saveTeam(this.props.apolloClient.client, req)
        this.props.handleClose()
    }
    handleChange = (event, source) => {

        event.persist();
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    render() {
        let { minimizeScreen, fullScreen } = this.state;

        let { open, handleClose } = this.props;
        return (
            <Dialog
                onClose={handleClose}
                open={open}
                maxWidth="md"
                fullWidth={true}
                className={`crm-custom-bg-primary custom_dialog ${minimizeScreen && fullScreen ? "alignitemsend-fullscreen" : ""
                    } ${minimizeScreen ? "alignitemsend" : ""} ${fullScreen ? "fullscreen" : ""
                    }`}
                disableBackdropClick

            >
                <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">

                    <h4 className="m-0 text-white">Add Team</h4>
                    <div className="d-flex">
                        <TooltipNew title={minimizeScreen ? 'Normal Screen' : 'Minimize Screen'}>
                            <IconButton onClick={this.handleViewEmailDialogMinimize}>
                                <Icon className="text-white">{minimizeScreen ? 'add' : 'remove'}</Icon>
                            </IconButton>
                        </TooltipNew>
                        <TooltipNew title={fullScreen ? 'Exit Full Screen' : 'Full Screen'}>
                            <IconButton onClick={this.handleViewEmailDialogFull}>
                                <Icon className="text-white">{fullScreen ? 'fullscreen_exit' : 'fullscreen'}</Icon>
                            </IconButton>
                        </TooltipNew>
                        <TooltipNew title="Close">
                            <IconButton onClick={handleClose}>
                                <Icon className="text-white">clear</Icon>
                            </IconButton>
                        </TooltipNew>
                    </div>
                </div>
                <ValidatorForm noValidate instantValidate={false} ref="form" onSubmit={this.handleFormSubmit}>
                    <div className="dialog-body pt-16 pb-16 ">
                        <Grid container spacing={2}>
                            <InputLabel className="pt-10">Team Name *</InputLabel>
                            <TextValidator
                                className="w-100 mb-16"
                                style={{marginLeft:"10px"}}
                                required={true}
                                placeholder="Enter Team Name"
                                ref={ref => this['name'] = ref}
                                onChange={this.handleChange}
                                type="text"
                                name="name"
                                value={this.state.name}
                                validators={["required"]}
                                errorMessages={["This field is required"]}
                                autoComplete='off'
                            />
                        </Grid>
                    </div>

                    <DialogActions className="dialog-action">
                        <Button variant="contained" onClick={() => this.props.handleClose()} >
                            Cancel            </Button>
                        <Button
                            disabled={this.state.isDataLoading}
                            variant="contained"
                            type="submit"
                            color="primary"
                            className="btn_Primary"
                        > Save            </Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        )
    }
}
const mapStateToProps = state => {
    return {
        settingReducer: state.settingReducer,
        apolloClient: state.apolloClient
    };
};

const mapDispatchToProps = dispatch => {
    return {
        saveTeam: (client, request) => dispatch(actions.saveTeam(client, request)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(AddTeam);

