import React, { Component } from "react";
import { Dialog, IconButton, Button, Icon, Tooltip, Grid, FormControl, Select, InputLabel, Menu, MenuItem, List, ListItem, ListItemText, DialogActions, Chip, TextField } from "@material-ui/core";
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { SingleSelect } from "react-select-material-ui";
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';
import CommonDisbledLock from "../Layout/CommonDisbledLock"
import CommonVisibiltyEdit from "../Layout/CommonVisibiltyEdit"


export const CreateFieldsDynamicCompanyEdit = (data) => {

  var elements = data.state.fieldsList
  var fieldsArray = [];
  const formatCreateLabel = (value) => `Create "${value}"`;
  const isValidNewOption = (value) => value != "";
  let disabledAll = !data.state.crudPermission.isedit

  elements.map((v, i) => {

    if (v.fieldstatus === true) {
      if (v.fieldtype.id == '1' && ((v.fieldkey == "websites" || v.fieldkey == "social" || v.fieldkey == "phones") && v.is_default)) {
        {
          let tempFieldArray = []
          data.state.fieldsObj[v.fieldkey].values && data.state.fieldsObj[v.fieldkey].values.map((row, index) => {
            if (!row.id) {
              data.handleAddMoreField("id", v.fieldkey, index, v.options[0].id)
            }
            if (data.state.hasRingCentralConnect === true) {
              if (v.fieldkey === "phones") {
                tempFieldArray.push(
                  <div className={row.srno > 1 && "mt-10"}>
                    <Select
                      ref={ref => data[v.fieldkey] = ref}
                      name={v.fieldkey}
                      id={v.id}
                      value={row.id}
                      disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
                      className="input_bx"
                      disableUnderline
                      onChange={(event) => data.handleAddMoreField("id", v.fieldkey, index, event.target.value)}
                      SelectDisplayProps={{ className: "multiple-text-dropdown" }}
                    >
                      {v.options && v.options.map((item, key) =>
                        <MenuItem key={item.id} value={item.id}>{item.optionvalue}</MenuItem>
                      )}
                    </Select>
                    <span style={{ color: '#0000008a' }}>
                      {(v.recordeditdefaultvalue == 2) ? "*" : ""}
                      {(v.recordeditdefaultvalue == 3 || disabledAll) &&
                        <CommonDisbledLock />
                      }
                    </span>
                    {
                      data.state.isEditPhone ?
                        <TextValidator
                          type="text"
                          id={v.id}
                          // placeholder={"Add " + v.fieldkey}
                          placeholder={`Add ${v.fieldkey && v.fieldkey.charAt(0).toUpperCase() + v.fieldkey.slice(1)}`}
                          className="w-100 input_bx"
                          onChange={(event) => data.handleAddMoreField("value", v.fieldkey, index, event.target.value)}
                          ref={ref => data[v.fieldkey] = ref}
                          onBlur={(event) => data.focusOutSubmitForm(event, v.id, v.fieldkey)}
                          name={v.fieldkey}
                          value={row.value}
                          disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
                        />
                        :
                        <div className="w-100 input_bx"
                          onClick={() => data.onPhoneClick()}>
                          {
                            data.state.hasRingCentralConnect === true ?
                              <a target="_blank"
                                href={"tel:" + row.value}
                                className="text_link">
                                {row.value}
                              </a>
                              :
                              <a target="_blank"
                                className="text_link">
                                {row.value}
                              </a>
                          }
                        </div>
                    }
                    {v.fieldkey != "email" && row.value && index == (data.state.fieldsObj[v.fieldkey].values.length - 1) &&
                      <span className="add-more-field" type="button" onClick={() => { data.addNewField(v.fieldkey, v.options[0]) }}>Add More</span>
                    }
                  </div>
                )
              }
            } else {
              tempFieldArray.push(
                <>
                  <Select
                    ref={ref => data[v.fieldkey] = ref}
                    name={v.fieldkey}
                    id={v.id}
                    value={row.id}
                    className="input_bx"
                    disableUnderline
                    disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
                    onChange={(event) => data.handleAddMoreField("id", v.fieldkey, index, event.target.value)}
                    SelectDisplayProps={{ className: "multiple-text-dropdown" }}
                  >
                    {v.options && v.options.map((item, key) =>
                      <MenuItem key={item.id} value={item.id}>{item.optionvalue}</MenuItem>
                    )}
                  </Select>
                  <span style={{ color: '#0000008a' }}>
                    {(v.recordeditdefaultvalue == 2) ? "*" : ""}
                    {(v.recordeditdefaultvalue == 3 || disabledAll) &&
                      <CommonDisbledLock />
                    }
                  </span>
                  <TextValidator
                    type="text"
                    id={v.id}
                    placeholder={`Add ${v.fieldkey && v.fieldkey.charAt(0).toUpperCase() + v.fieldkey.slice(1)}`}
                    className="w-100 input_bx"
                    onChange={(event) => data.handleAddMoreField("value", v.fieldkey, index, event.target.value)}
                    ref={ref => data[v.fieldkey] = ref}
                    onBlur={(event) => data.focusOutSubmitForm(event, v.id, v.fieldkey)}
                    name={v.fieldkey}
                    value={row.value}
                    disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
                  />
                  {v.fieldkey != "email" && row.value && index == (data.state.fieldsObj[v.fieldkey].values.length - 1) &&
                    <span className="add-more-field" type="button" onClick={() => { data.addNewField(v.fieldkey, v.options[0]) }}>Add More</span>
                  }
                </>
              )
            }
          })
          fieldsArray.push(
            <Grid item xs={12}>
              {tempFieldArray}
            </Grid>
          )
        }

      } else if (v.fieldtype.id == '1' && v.fieldkey == 'emaildomain') {
        fieldsArray.push(<><Grid item xs={12}>
          <InputLabel>Email Domain{(v.recordeditdefaultvalue == 3 || disabledAll) && <CommonDisbledLock />}</InputLabel>
          <TextValidator
            type="text"
            id={v.id}
            placeholder={`Add Email Domain`}
            disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
            className="w-100 input_bx"
            // label={v.fieldlabel}
            onChange={(event) => data.handleChange({ fieldid: v.id, companyvalue: event.target.value }, v.fieldkey)}
            ref={ref => data[v.fieldkey] = ref}
            onBlur={(event) => data.focusOutSubmitForm(event, v.id, v.fieldkey)}
            name={v.fieldkey}
            value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].companyvalue : ""}
            validators={(v.recordcreationdefaultvalue == 2) ? ["required", "emailDomain"] : ["emailDomain"]}
            errorMessages={(v.recordcreationdefaultvalue == 2) ? ["This field is required", "Please enter a valid email domain."] : ["Please enter a valid email domain."]}
          />
        </Grid></>
        )
      } else if (v.fieldtype.id == '1' && v.id == '29') { // name
        fieldsArray.push(<><Grid item xs={12}>
          {/* <InputLabel>Name{(v.recordeditdefaultvalue == 3 || disabledAll) && <CommonDisbledLock />}</InputLabel> */}
          <TextValidator
            id={v.id}
            type="text"
            className="w-100 input_bx"
            placeholder={`Add ${v.fieldkey && v.fieldkey.charAt(0).toUpperCase() + v.fieldkey.slice(1)}`}
            disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
            required={(disabledAll) ? false : (v.recordcreationdefaultvalue == 2) ? true : false}
            label={<>Name{(v.recordeditdefaultvalue == 3 || disabledAll) && <CommonDisbledLock />}</>}
            onChange={(event) => data.handleChange({ fieldid: v.id, companyvalue: event.target.value }, v.fieldkey)}
            ref={ref => data[v.fieldkey] = ref}
            onBlur={(event) => data.focusOutSubmitForm(event, v.id, v.fieldkey)}
            name={v.fieldkey}
            value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].companyvalue : ""}
            validators={(v.recordeditdefaultvalue == 2) ? ["required"] : []}
            errorMessages={(v.recordeditdefaultvalue == 2) ? ["This field is required"] : []}
            InputLabelProps={{ shrink: true, disableAnimation: false, focused: true }}
          />
        </Grid>
        </>
        )
      } else if (v.fieldtype.id == '1' && v.fieldkey == 'tags') {
        var valTag = [];
        if (data.state.fieldsObj[v.fieldkey]) {
          valTag = data.state.fieldsObj[v.fieldkey].values
        }
        fieldsArray.push(<Grid item xs={12}>
          <InputLabel>Tags{(v.recordeditdefaultvalue == 3 || disabledAll) && <CommonDisbledLock />}</InputLabel>
          <Autocomplete
            disableOpenOnFocus
            multiple
            value={valTag}
            filterSelectedOptions
            disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
            name={v.fieldkey}
            id="tagInputId"
            options={data.state.systemTagsList.map(option => option.tagname)}
            freeSolo
            onChange={(event, value) => data.handleChange({ fieldid: v.id, values: value }, v.fieldkey, 'allowSubmitChange')}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip label={option.charAt(0).toUpperCase() + option.slice(1)} {...getTagProps({ index })} />
              ))
            }
            renderInput={params => (
              <TextValidator
                {...params}
                id={v.fieldkey}
                // label="Tags"
                placeholder="Add Tag"
                className="w-100 input_bx"
                ref={ref => data[v.fieldkey] = ref}
                disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
                onBlur={(event) => data.focusOutSubmitForm(event, v.id, v.fieldkey)}
                onFocus={() =>
                  setTimeout(() => {
                    document.getElementById("tagInputId").value = "";
                  }, 0)
                }
              />
            )}
          />
        </Grid>)
      } else if (v.fieldtype.id == '1' && v.id == '31') {//owner field
        var nameOwner = [];
        if (data.state.fieldsObj[v.fieldkey]) {
          nameOwner = data.state.fieldsObj[v.fieldkey].values
        }
        fieldsArray.push(<Grid item xs={12}>
          {/* <FormControl className="w-100 input_bx"> */}
          <InputLabel>Owner{(v.recordeditdefaultvalue == 3 || disabledAll) && <CommonDisbledLock />}</InputLabel>
          <Autocomplete
            disableOpenOnFocus
            forcePopupIcon={false}
            name={v.fieldkey}
            options={data.state.usersListArray}
            getOptionLabel={option => option.name ? option.name : ""}
            value={nameOwner}
            disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
            name={v.fieldkey}
            onChange={(event, value) => data.handleChange({ fieldid: v.id, values: value }, v.fieldkey, 'allowSubmitChange')}
            id={v.id}
            renderInput={params => (
              <TextValidator
                ref={ref => data[v.fieldkey] = ref}
                disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
                onBlur={(event) => data.focusOutSubmitForm(event, v.id, v.fieldkey)}
                {...params}
                variant="standard"
                // label={"Owner"}
                placeholder="Add Owner"
                fullWidth
                className="w-100 input_bx"
              />
            )}
          />
          {/* </FormControl> */}
        </Grid>)
      } else if (v.fieldtype.id == '1' && v.id == '32') {// value field
        fieldsArray.push(
          <>
            {data.state.focusValue &&
              <Grid item xs={4}>
                <FormControl className="w-100 input_bx">
                  <InputLabel>{v.fieldlabel}{(v.recordeditdefaultvalue == 3 || disabledAll) && <CommonDisbledLock />}</InputLabel>
                  <SelectValidator
                    ref={ref => data['currency'] = ref}
                    // label={v.fieldlabel}
                    value={data.state.companyCurrencyData.currencyid}
                    disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
                    name={"currency"}
                    id={"currency"}
                    SelectProps={{ onClose: data.currencyDropdownClose }}
                    className="w-100 input_bx"

                  >
                    <MenuItem className="select-menu-item item-with-link" value={data.state.companyCurrencyData.currencyid}>{data.state.companyCurrencyData.detail.code}</MenuItem>
                  </SelectValidator>
                </FormControl>
              </Grid >
            }
            <Grid item xs={data.state.focusValue ? 8 : 12} row className={data.state.focusValue ? "checkbox-margin-18" : ""}>
              <InputLabel>{v.fieldlabel}{(v.recordeditdefaultvalue == 3 || disabledAll) && <CommonDisbledLock />}</InputLabel>
              <TextValidator
                type="number"
                pattern="[0-9]*"
                id={v.id}
                disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
                className="w-100 input_bx"
                onFocus={data.focusInValue}
                // label={!data.state.focusValue ? v.fieldlabel : ""}
                onChange={(event) => data.handleChange({ fieldid: v.id, companyvalue: event.target.value }, v.fieldkey)}
                onKeyPress={event => (event.key === '-') && event.preventDefault()}

                ref={ref => data[v.fieldkey] = ref}
                onBlur={(event) => data.focusOutSubmitForm(event, v.id, v.fieldkey)}
                name={v.fieldkey}
                placeholder={"Add Value"}
                value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].companyvalue : ""}


                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {data.state.companyCurrencyData.symbol}
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          </>
        )
      } else if (v.fieldtype.id == '1' || v.fieldtype.id == '9') {
        fieldsArray.push(<Grid item xs={12}>
          <InputLabel>{v.fieldlabel}{(v.recordeditdefaultvalue == 3 || disabledAll) && <CommonDisbledLock />}</InputLabel>
          <TextValidator
            type="text"
            id={v.id}
            disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
            className="w-100 input_bx"
            // label={v.fieldlabel}
            onChange={(event) => data.handleChange({ fieldid: v.id, companyvalue: event.target.value }, v.fieldkey)}
            ref={ref => data[v.fieldkey] = ref}
            onBlur={(event) => data.focusOutSubmitForm(event, v.id, v.fieldkey)}
            name={v.fieldkey}
            value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].companyvalue : ""}
            placeholder={`Add ${v.fieldkey && v.fieldkey.charAt(0).toUpperCase() + v.fieldkey.slice(1)}`}
            validators={v.recordcreationdefaultvalue == 2 ? ["required", "IsURLValid"] : ["IsURLValid"]}
            errorMessages={
              v.recordcreationdefaultvalue == 2
                ? ["This field is required", "Please enter a valid domain"]
                : ["Please enter a valid domain"]
            }
          />
        </Grid>)
      }
      else if (v.fieldtype.id == '6' || v.fieldtype.id == '10' || v.fieldtype.id == '7') {
        fieldsArray.push(<Grid item xs={12}>
          <InputLabel>{v.fieldlabel}{(v.recordeditdefaultvalue == 3 || disabledAll) && <CommonDisbledLock />}</InputLabel>
          <TextValidator
            type="number"
            id={v.id}
            disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
            className="w-100 input_bx"
            // label={v.fieldlabel}
            onChange={(event) => data.handleChange({ fieldid: v.id, companyvalue: event.target.value }, v.fieldkey)}
            ref={ref => data[v.fieldkey] = ref}
            onBlur={(event) => data.focusOutSubmitForm(event, v.id, v.fieldkey)}
            name={v.fieldkey}
            value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].companyvalue : ""}
            placeholder={`Add ${v.fieldkey && v.fieldkey.charAt(0).toUpperCase() + v.fieldkey.slice(1)}`}
          />
        </Grid>)
      }

      if (v.fieldtype.id == '2' && v.id == "36") {//Address Fields.
        var displayMapLink = false;
        var mapAddress = "";

        if (data.state.fieldsObj[v.fieldkey] && data.state.fieldsObj[v.fieldkey].companyvalue != "" || data.state.city || data.state.addressState || data.state.country || data.state.zipcode) {
          displayMapLink = true
          var line1 = ""

          if (data.state.fieldsObj[v.fieldkey] != undefined) {
            line1 = data.state.fieldsObj[v.fieldkey].companyvalue
          }
          var line2 = data.state.city
          var line3 = data.state.addressState
          var line4 = (data.state.country && data.state.country.name) ? data.state.country.name : "";
          var line5 = data.state.zipcode
          var mapAddress = encodeURI(line1 + " " + line2 + " " + line3 + " " + line5 + " " + line4);
        }
        fieldsArray.push(
          <>
            {displayMapLink &&
              <Grid item xs={12}>
                <a className="pull-right" style={{ color: '#7467ef' }} target="_blank" href={"https://maps.google.com/?q=" + mapAddress}>
                  Address Map</a>
              </Grid>
            }
            <Grid item xs={12}>
              <InputLabel>Address{(v.recordeditdefaultvalue == 3 || disabledAll) && <CommonDisbledLock />}</InputLabel>
              <TextValidator
                multiline
                placeholder={"Street"}
                type="text"
                id={v.id}
                disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
                className="w-100 input_bx"
                // label={v.fieldlabel}
                onChange={(event) => data.handleChange({ fieldid: v.id, companyvalue: event.target.value }, v.fieldkey)}
                ref={ref => data[v.fieldkey] = ref}
                onBlur={(event) => data.focusOutSubmitForm(event, v.id, v.fieldkey)}
                name={v.fieldkey}
                value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].companyvalue : ""}
              />
            </Grid>
            <Grid item xs={4}>
              <TextValidator
                type="text"
                id={"city"}
                placeholder={"City"}
                disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
                className="w-100 input_bx"
                // label={"City"}
                onChange={(event) => data.handleNormalFields(event)}
                ref={ref => data['city'] = ref}
                onBlur={(event) => data.focusOutSubmitForm(event, v.id, 'city')}
                name={"city"}
                value={data.state.city}
              />
            </Grid>
            <Grid item xs={4}>
              <TextValidator
                type="text"
                id={"addressState"}
                disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
                className="w-100 input_bx"
                // label={"State"}
                onChange={(event) => data.handleNormalFields(event)}
                ref={ref => data['addressState'] = ref}
                onBlur={(event) => data.focusOutSubmitForm(event, v.id, 'addressState')}
                name={"addressState"}
                value={data.state.addressState}
                placeholder={"State"}
              />
            </Grid>
            <Grid item xs={4}>
              <TextValidator
                type="text"
                id={"zipcode"}
                disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
                className="w-100 input_bx"
                // label={"Zip code"}
                onChange={(event) => data.handleNormalFields(event)}
                ref={ref => data['zipcode'] = ref}
                onBlur={(event) => data.focusOutSubmitForm(event, v.id, 'zipcode')}
                name={"zipcode"}
                value={data.state.zipcode}
                placeholder={"Zip code"}
              />
            </Grid>
            <Grid item xs={12}>
              {/* <FormControl className="w-100 input_bx"> */}
              <Autocomplete
                disableOpenOnFocus
                forcePopupIcon={false}
                disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
                name={"country"}
                onChange={(event, values) => data.handleCountryChange(event, values, v.id)}
                id={"country"}
                value={data.state.country}
                options={data.state.countryListArray}
                getOptionLabel={option => option.name ? option.name : ""}
                renderInput={params => (
                  <TextValidator
                    ref={ref => data[v.fieldkey] = ref}
                    onBlur={(event) => data.focusOutSubmitForm(event, v.id, v.fieldkey)}
                    {...params}
                    variant="standard"
                    // label={"Country"}
                    fullWidth
                    placeholder={"Country"}
                    className="w-100 input_bx"
                  />
                )}
              />
              {/* </FormControl> */}
            </Grid>
          </>
        )
      } else if (v.fieldtype.id == '2') {
        fieldsArray.push(<Grid item xs={12}>
          <InputLabel >{v.fieldlabel}{(v.recordeditdefaultvalue == 3 || disabledAll) && <CommonDisbledLock />}</InputLabel>
          <TextValidator
            multiline
            type="text"
            id={v.id}
            disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
            className="w-100 input_bx"
            // label={v.fieldlabel}
            onChange={(event) => data.handleChange({ fieldid: v.id, companyvalue: event.target.value }, v.fieldkey)}
            ref={ref => data[v.fieldkey] = ref}
            onBlur={(event) => data.focusOutSubmitForm(event, v.id, v.fieldkey)}
            name={v.fieldkey}
            value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].companyvalue : ""}
            placeholder={`Add ${v.fieldkey && v.fieldkey.charAt(0).toUpperCase() + v.fieldkey.slice(1)}`}
          />
        </Grid>)
      }

      if (v.fieldtype.id == '4') {
        if (v.id === "38" || v.id === "33") { //38 visibility //33- contact type
          var valDropdown = "";
          if (v.options && data.state.fieldsObj[v.fieldkey] && data.state.fieldsObj[v.fieldkey].values) {
            valDropdown = v.options.filter(row => row.id == data.state.fieldsObj[v.fieldkey].values.id)[0]
          }
          //set Visibility
          if (v.id === "38") {
            fieldsArray.push(
              <CommonVisibiltyEdit data={data} v={v} />
            )
          }
          else {
            fieldsArray.push(<Grid item xs={12}>
              {/* <FormControl className="w-100 input_bx"> */}
              <InputLabel >{v.fieldlabel}{(v.recordeditdefaultvalue == 3 || disabledAll) && <CommonDisbledLock />}</InputLabel>
              <SelectValidator
                ref={ref => data[v.fieldkey] = ref}
                disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
                value={valDropdown}
                onChange={(event) => data.handleChange({ fieldid: v.id, values: event.target.value }, v.fieldkey, 'allowSubmitChange')}
                name={v.fieldkey}
                id={v.id}
                // label={v.fieldlabel}
                className="w-100 input_bx"
              >
                {v.options && v.options.map((item, key) =>
                  <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item}>{item.name}</MenuItem>
                )}
              </SelectValidator>
              {/* </FormControl> */}
            </Grid >
            )
          }
        } else {
          var valDropdown = "none";
          if (v.options && data.state.fieldsObj[v.fieldkey] && data.state.fieldsObj[v.fieldkey].values) {
            valDropdown = v.options.filter(row => row.id == data.state.fieldsObj[v.fieldkey].values.id)[0]
          }
          fieldsArray.push(<Grid item xs={12}>
            <InputLabel>{v.fieldlabel}{(v.recordeditdefaultvalue == 3 || disabledAll) && <CommonDisbledLock />}</InputLabel>
            <SelectValidator
              ref={ref => data[v.fieldkey] = ref}
              disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
              value={valDropdown}
              onChange={(event) => data.handleChange({ fieldid: v.id, values: event.target.value }, v.fieldkey, 'allowSubmitChange')}
              name={v.fieldlabel}
              id={v.id}
              className="w-100 input_bx"
            >
              <MenuItem value="none" disabled style={{ display: 'none' }}><span className="text-hint">{`Add ${v.fieldlabel && v.fieldlabel.charAt(0).toUpperCase() + v.fieldlabel.slice(1)}`}</span></MenuItem>
              {v.options && v.options.map((item, key) =>
                <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item}>{item.optionvalue}</MenuItem>
              )}
            </SelectValidator>
          </Grid >
          )
        }
      }
      if (v.fieldtype.id == '5') {//multi dropdown
        var multiSelectedVal = [];
        if (data.state.fieldsObj[v.fieldkey] && data.state.fieldsObj[v.fieldkey].values && data.state.fieldsObj[v.fieldkey].values.length > 0) {
          multiSelectedVal = v.options.filter(row => data.state.fieldsObj[v.fieldkey].values.filter(val => val.id == row.id).length);
        }
        fieldsArray.push(<Grid item xs={12}>
          {/* <FormControl className="w-100 input_bx"> */}
          <InputLabel>{v.fieldlabel}{(v.recordeditdefaultvalue == 3 || disabledAll) && <CommonDisbledLock />}</InputLabel>
          <Autocomplete
            multiple
            value={multiSelectedVal}
            disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
            name={v.fieldkey}
            onChange={(event, value) => data.handleChange({ fieldid: v.id, values: value }, v.fieldkey, 'allowSubmitChange')}
            id={v.id}
            options={v.options}
            getOptionLabel={option => option.optionvalue ? option.optionvalue : ""}
            renderInput={params => (
              <TextValidator
                ref={ref => data[v.fieldkey] = ref}
                onBlur={(event) => data.focusOutSubmitForm(event, v.id, v.fieldkey)}
                {...params}
                variant="standard"
                placeholder={`Add ${v.fieldkey && v.fieldkey.charAt(0).toUpperCase() + v.fieldkey.slice(1)}`}
                // label={v.fieldlabel}
                fullWidth
                className="w-100 input_bx"
              />
            )}
          />
          {/* </FormControl> */}
        </Grid >
        )
      }
      if (v.fieldtype.id == '3') {//checkbox field
        fieldsArray.push(<Grid item xs={12}>
          <FormControl className="w-100 input_bx">
            <FormControlLabel
              control={
                <Checkbox
                  name={v.fieldkey}
                  disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
                  id={v.id}
                  checked={(data.state.fieldsObj[v.fieldkey] && data.state.fieldsObj[v.fieldkey].companyvalue == "1") ? true : false}
                  onChange={(event, value) => data.handleChange({ fieldid: v.id, companyvalue: value ? "1" : "0" }, v.fieldkey, 'allowSubmitChange')}
                  value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].companyvalue : ""}
                  color="primary"
                />
              }
              label={<span className="font-size-13">{v.fieldlabel}</span>}
            />
          </FormControl>
        </Grid >
        )
      }
      if (v.fieldtype.id == '8') {//datepicker field
        var dtFormat = "MM/dd/yyyy";
        var selectedDate = data.state.selectedDate;
        if (data.state.fieldsObj[v.fieldkey]) {
          selectedDate = data.state.fieldsObj[v.fieldkey].values
        }

        if (data.state.userSettings != "") {
          dtFormat = data.state.userSettings.dateformat;
          dtFormat = dtFormat.replace("DD", "dd").replace("YYYY", "yyyy");
        }

        fieldsArray.push(<Grid item xs={12} >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <FormControl className="w-100">
              <KeyboardDatePicker
                label={v.fieldlabel}
                className="input_bx"
                format={dtFormat}
                disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
                placeholder={dtFormat.replace("DD", "dd").replace("YYYY", "yyyy").replace("MM", "mm")}
                error={(selectedDate == "Invalid Date") ? true : false}
                value={selectedDate}
                onChange={(event, value) => data.handleChange({ fieldid: v.id, values: event == "Invalid Date" ? "Invalid Date" : event }, v.fieldkey, 'allowSubmitChange')}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                helperText={(selectedDate == "Invalid Date") ? "Please enter a valid date." : ""}
              />
            </FormControl>
          </MuiPickersUtilsProvider>
        </Grid >
        )
      }
    }
  })

  return fieldsArray;
}