import React, { Component } from "react";
import {
    IconButton, InputLabel, Grid, Button, List, ListItem,
    ListItemText, ListItemIcon, ListItemSecondaryAction, FormControl, Select, MenuItem, Card, Typography,
    Dialog, DialogActions, DialogTitle, FormControlLabel, Switch
} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';


import crosshairsIcon from '@iconify/icons-fa-solid/crosshairs';
import { Icon, InlineIcon } from '@iconify/react';
import "date-fns";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import RootRef from "@material-ui/core/RootRef";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import EditIcon from "@material-ui/icons/Edit";
import DragHandleIcon from '@material-ui/icons/DragHandle';
import LockIcon from '@material-ui/icons/Lock';
import AddUpdateLeadStatus from "./AddUpdateLeadStatus";



// reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.map((value, index) => {
        result[index].order_no = index + 1;
    })
    return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
        background: "rgb(235,235,235)"
    })
});

const getListStyle = isDraggingOver => ({
    //background: isDraggingOver ? 'lightblue' : 'lightgrey',
});

class LeadStatuses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            LeadStatusList: [],
            defaultLeadStatusSelected: '',
            openAddUpdateLeadStatusDialoge: false,
            FullLeadStatusDialog: false,
            minimizeLeadStatusDialog: false,
            LeadstatusData: {},
            isDeleteConfirmDialoOpen: false,
            deleteItemName: '',
            deleteData: '',
            leadsettingslist: [],
        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }
    handleLeadDialogClose = () => {
        this.setState({ openAddUpdateLeadStatusDialoge: false });
    };
    handleLeadDialogMinimize = () => {
        this.setState(oldStateminimizeLeadDialog => ({ minimizeLeadStatusDialog: !oldStateminimizeLeadDialog.minimizeLeadStatusDialog }));
        this.setState({ FullLeadStatusDialog: false })
    };
    handleLeadDialogFull = () => {
        this.setState(oldStateFullCreateLeadDialog => ({ FullLeadStatusDialog: !oldStateFullCreateLeadDialog.FullLeadStatusDialog }));
        this.setState({ minimizeLeadStatusDialog: false })

    };
    componentDidMount() {
        this.props.getLeadSettings(this.props.apolloClient.client);
        this.props.loadLeadStatues(this.props.apolloClient.client);
    }
    componentWillReceiveProps(props) {
        if(props.settingReducer.leadsettingslist && props.settingReducer.leadsettingslist.data){
            this.setState({leadsettingslist: props.settingReducer.leadsettingslist.data})
        }
        if (props.settingReducer.leadStatusList.data != null) {
            var dataList = props.settingReducer.leadStatusList.data;
            if (dataList !== this.state.LeadStatusList) {
                //getting default option key to set in dropdown selected value for default lead status
                var key = this.findWithAttr(dataList, 'is_default', true);
                this.setState({ LeadStatusList: dataList });
                if (!this.state.defaultLeadStatusSelected && key != -1) {
                    this.setState({ defaultLeadStatusSelected: dataList[key].id });
                }
            }
            this.setState({ LeadStatusList: dataList });
        }
    }

    onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.LeadStatusList,
            result.source.index,
            result.destination.index
        );

        this.setState({ LeadStatusList: items });

        var idsArray = [];
        var sortOrderArray = [];
        items.map((value, index) => {
            idsArray.push(parseInt(value.id));
            sortOrderArray.push(value.order_no);
        })

        let req = {
            leadStatusIds: idsArray,
            leadStatusOrder: sortOrderArray,
        }
        this.props.updateLeadStatusesOrder(this.props.apolloClient.client, req);
        this.props.settingReducer.leadStatusList.data = items;
    }

    handleChangeLeadStatusDropDown = event => {
        let req = {
            statusid: parseInt(event.target.value),
        }
        this.props.updateDefaultLeadStatus(this.props.apolloClient.client, req);
        this.setState({ defaultLeadStatusSelected: event.target.value });
    };
    handleAddUpdate = (data) => {

        let info = {}
        if (data && data != undefined) {
            info.name = data.name
            info.id = data.id
            info.isEdit = true
        } else {
            info.isEdit = false
        }
        this.setState({ openAddUpdateLeadStatusDialoge: false }, () => {
            this.setState({ LeadstatusData: info, openAddUpdateLeadStatusDialoge: true });
        });

    };
    handleDeleteStatus = (data) => {
        this.setState({ deleteItemName: data.name });
        this.toggleDeleteDialog(data)
    };

    toggleDeleteDialog = (data) => {
        this.setState({ isDeleteConfirmDialoOpen: !this.state.isDeleteConfirmDialoOpen, deleteData: data });
    };
    confirmDeleteLeadStatus = () => {
        let req = {
            statusid: parseInt(this.state.deleteData.id),
        }
        this.props.deleteLeadStatus(this.props.apolloClient.client, req);
        this.toggleDeleteDialog()
    };

    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }
    handleChangeLeadSettings = (event, value) => {
        let req = {
            status: value,
        }
        console.log(req,'req');
        this.props.updateLeadSettings(this.props.apolloClient.client, req);
    }
    render() {
        let { leadsettingslist } = this.state;
        return (
            <div>
                <Grid container justify="space-between">
                    <Typography className="card-margin-leadstatus mt-10 ml-10 font-weight-500" variant="h5" color="inherit">Lead Management</Typography>
                    <Button variant="contained" color="primary" onClick={() => this.handleAddUpdate()} size="small" style={{ minWidth: 95, height: '30px', margin: '10px' }}>
                        Add New Status
                    </Button>
                    {this.state.openAddUpdateLeadStatusDialoge && (
                        <AddUpdateLeadStatus
                            handleClose={() => this.handleLeadDialogClose()}
                            open={this.state.openAddUpdateLeadStatusDialoge}
                            handleMinimize={this.handleLeadDialogMinimize}
                            handleFull={this.handleLeadDialogFull}
                            minimizeScreen={this.state.minimizeLeadStatusDialog}
                            fullScreen={this.state.FullLeadStatusDialog}
                            LeadstatusData={this.state.LeadstatusData}
                        />
                    )}
                </Grid>
                <Card className="signup-card">
                    <div className="lead_upcoming">
                        {/* <div className="box_portlet">
                            <div className="box_header"> */}
                        {/* <div className="box_label"><h3 className="box_title"></h3></div> */}
                    </div>
                    <div className="box_body">
                        <p style={{ margin: '18px' }}>
                            Customize the Lead Status options. Users can keep track of the qualification process of each Lead using the Lead Status field
                        </p>
                        
                        <Grid container justify="space-between">
                            <Grid item className="ml-30">
                                <Icon icon={crosshairsIcon} style={{ width: '35px', height: '35px', margin: '10px 8px 8px 20px' }} />
                                {/* <Icon icon={crosshairsIcon} style={{ width: '50px', height: '20px'}} className="crm-fontawsome-icon" /> */}
                            </Grid>
                            <Grid item md={9}>
                                <Typography className="ml-10 font-weight-500 font-size-20" color="inherit">
                                    Enable Leads
                                </Typography>
                                <Typography className="font-size-13 ml-10" color="inherit">
                                    The Lead Management feature allows users to create, manage, and convert Leads.
                                </Typography>
                            </Grid>
                            <Grid item md={1}>
                                <Switch color="primary" checked={leadsettingslist.lead == true ? true : false} name="switchView" onChange={this.handleChangeLeadSettings} />
                            </Grid>
                        </Grid>
                        
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <RootRef rootRef={provided.innerRef}>
                                        <List style={getListStyle(snapshot.isDraggingOver)} className="crm-lead-status">
                                            {this.state.LeadStatusList && this.state.LeadStatusList.map((item, index) => (
                                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                                    {(provided, snapshot) => (
                                                        <ListItem
                                                            ContainerComponent="li"
                                                            ContainerProps={{ ref: provided.innerRef }}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                        >
                                                            <ListItemIcon> <DragHandleIcon /></ListItemIcon>
                                                            <ListItemText primary={item.name && item.name.charAt(0).toUpperCase() + item.name.slice(1)} />
                                                            <ListItemSecondaryAction>
                                                                {item.is_allowto_edit && <IconButton onClick={() => this.handleAddUpdate(item)} ><EditIcon /></IconButton>}
                                                                {(item.is_default == false) ? <IconButton onClick={() => this.handleDeleteStatus(item)}><DeleteIcon /> </IconButton> : ""}
                                                                {/* {item.is_allowto_delete && <IconButton onClick={()=>this.handleDeleteStatus(item)}><Icon>delete</Icon></IconButton>} */}
                                                                {!item.is_allowto_delete && !item.is_allowto_edit && <IconButton><LockIcon /></IconButton>}

                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </List>
                                    </RootRef>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                    <Grid item xs={3} style={{ padding: '20px' }}>
                        <FormControl className="w-100 input_bx">
                            <InputLabel>Select Default status</InputLabel>
                            <Select
                                name="default_selected_lead_status"
                                value={this.state.defaultLeadStatusSelected}
                                onChange={this.handleChangeLeadStatusDropDown}
                            >
                                {this.state.LeadStatusList.map((item, key) =>
                                    <MenuItem key={item.id} value={item.id}>{item.name && item.name.charAt(0).toUpperCase() + item.name.slice(1)}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Dialog
                        fullWidth={true}
                        maxWidth={'xs'}
                        open={this.state.isDeleteConfirmDialoOpen}
                        onClose={this.toggleDeleteDialog}
                        disableBackdropClick
                        //   disableEscapeKeyDown
                        aria-labelledby="ar-remove-dialog-title"
                    >
                        <DialogTitle id="ar-remove-dialog-title">{"Are you sure to remove "} {this.state.deleteItemName} {"?"}</DialogTitle>
                        <DialogActions>
                            <Button onClick={this.toggleDeleteDialog} color="primary">
                                Cancel
                            </Button>
                            <Button type="Button" onClick={() => this.confirmDeleteLeadStatus()} className="btn_Primary">
                                Ok
                            </Button>

                        </DialogActions>
                    </Dialog>
                    {/* </div>
                    </div> */}
                </Card>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient
});


const mapDispatchToProps = dispatch => {
    return {
        
        getLeadSettings: (client) => dispatch(actionCreators.getLeadSettings(client)),
        updateLeadSettings: (client, req) => dispatch(actionCreators.updateLeadSettings(client, req)),

        loadLeadStatues: (client) => dispatch(actionCreators.loadLeadStatues(client)),
        updateLeadStatusesOrder: (client, req) => dispatch(actionCreators.updateLeadStatusesOrder(client, req)),
        updateDefaultLeadStatus: (client, req) => dispatch(actionCreators.updateDefaultLeadStatus(client, req)),
        deleteLeadStatus: (client, req) => dispatch(actionCreators.deleteLeadStatus(client, req)),
    }
}

export default (connect(mapStateToProps, mapDispatchToProps)(LeadStatuses));
