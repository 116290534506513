import React, { Component } from "react";
import { Dialog, IconButton, Button, Icon, Tooltip, Grid, FormControl, Select, InputLabel, Menu, MenuItem, List, ListItem, ListItemText, DialogActions, Chip, TextField } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { CreateFieldsDynamicCompanyEdit } from './CreateFieldsDynamicCompanyEdit'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';
class CompanyProfileDetails extends Component {
  state = {
    company_id: "",
    lastname: "",
    city: "",
    addressState: "",
    zipcode: "",
    country: "",
    countryListArray: [],
    companyCurrencyData: [],
    focusValue: false,
    fieldsList: [],
    fieldsObj: {
      websites: {
        fieldid: "32",
        values: [
          { srno: "1", id: null, value: "" },
        ]
      },
      social: {
        fieldid: "35",
        values: [
          { srno: "1", id: null, value: "" },
        ]
      },
      phones: {
        fieldid: "30",
        values: [
          { srno: "1", id: null, value: "" },
        ]
      },
    },
    systemTagsList: [],
    isUpdate: true,
    isLeadDetailUpdate: true,
    companyFieldData: [],
    usersListArray: [],
    fnameError: false,
    userSettings: "",
    comp_options: [],
    comp_value: '',
    isAnyValueChange: false,
    updatedfieldid: "",
    isEditPhone: "",
    hasRingCentralConnect: false,
    itegrationDetails: [],
    ringCentralData: [],
    logphoneMain: "",
    isEditVisibility: false,
    teamvisible: true,
    oldteamvisible: true,
    hasActiveTeamPermission: false,
    teamVisibilityLabel: "",
    crudPermission: {
      isdelete: true,
      isedit: true,
    },
    teamList: [],
    selectedTeamList: [],
    selectedUserList: [{}],
    oldSlectedUserList: [],
    oldSelctedTeamList: [],
    userListForVisibility: [],
    teamUser: [],
  };

  // componentWillMount() {
  componentDidMount() {
    // this.props.getCompanyRecList(this.props.apolloClient.client);
    ValidatorForm.addValidationRule('emailDomain', (value) => {
      if (value && value.length && value.length > 0) {
        var specialChar = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
        if (!specialChar.test(value)) {
          return false;
        }
      }
      return true;
    });

    ValidatorForm.addValidationRule('IsURLValid', (value) => {
      if (value && value.length && value.length > 0) {
        var specialChar = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
        if (!specialChar.test(value)) {
          return false;
        }
      }
      return true;
    });

    let val = { typeId: 3 }
    let userData = JSON.parse(localStorage.getItem('user_data_session'))
    let userid = { userid: userData.id }
    this.props.getUserSetting(this.props.apolloClient.client, userid);
    this.props.customFieldList(this.props.apolloClient.client, val);
    this.props.getUserList(this.props.apolloClient.client);
    this.props.getCountryList(this.props.apolloClient.client);
    this.props.getCompanyCurrency(this.props.apolloClient.client);
    this.props.getTagsList(this.props.apolloClient.client);
    this.props.getUserIntegration(this.props.apolloClient.client);
    this.props.getTeam(this.props.apolloClient.client);

    if (this.props.company_id && (this.state.company_id != this.props.company_id)) {
      this.props.getCompanyRecField(this.props.apolloClient.client, parseInt(this.props.company_id));
      this.setState({
        company_id: parseInt(this.props.company_id),
        isUpdate: true,
      }, () => this.forceUpdate())
    }
  }
  componentWillUnmount() {
    // emailLogEmail
    localStorage.removeItem('emailLogEmail');
    this.props.companyReducer.companyFieldData = [];
    this.props.companyReducer.companyLogList = [];
  }
  AllMemberDisplay = () => {
    let teamVisibilityLabel = "";
    let userCount = 0;
    let teamCount = 0;
    let teamUser = [];
    let usersListArray = this.props.settingReducer.usersListArray.data ? this.props.settingReducer.usersListArray.data : []
    usersListArray.length > 0 && usersListArray.map((user) => {
      if (user.teamlist.length > 0) {
        let userdata = { userteam: [], id: "", username: "" }
        teamCount = teamCount < user.teamlist.length ? user.teamlist.length : teamCount;
        user.teamlist.map((u) => { userdata.userteam.push(u.team.teamname) })
        userdata.id = user.id;
        userdata.username = user.name;
        userCount = userCount + 1;
        teamUser.push(userdata)
      }
    })
    teamVisibilityLabel = userCount + " users from " + teamCount + " team ";
    this.setState({
      teamVisibilityLabel: teamVisibilityLabel,
      teamUser: teamUser
    }, () => this.forceUpdate())
  }
  selectedUserDetails = (mainuser) => {
    let teamVisibilityLabel = "";
    let teamUser = [];
    let userdata = { userteam: [], id: "", username: "" }
    mainuser.teamlist.map((u) => { userdata.userteam.push(u.team.teamname) })
    userdata.id = mainuser.id;
    userdata.username = mainuser.name;
    teamUser.push(userdata)
    teamVisibilityLabel = "1 users from " + mainuser.teamlist.length + " team ";
    this.setState({
      teamVisibilityLabel: teamVisibilityLabel,
      teamUser: teamUser
    }, () => this.forceUpdate())
  }
  themSelves = (selectedOwner) => {
    let teamVisibilityLabel = "";
    let userCount = 0;
    let teamCount = 0;
    let teamUser = [];
    let usersListArray = this.props.settingReducer.usersListArray.data ? this.props.settingReducer.usersListArray.data : []
    usersListArray.length > 0 && usersListArray.map((user) => {
      if (user.teamlist.length > 0) {
        if (user.teamlist[0].teammanager === true || (user.email === selectedOwner.email)) {
          let userdata = { userteam: [], id: "", username: "" }
          teamCount = teamCount < user.teamlist.length ? user.teamlist.length : teamCount;
          user.teamlist.map((u) => { userdata.userteam.push(u.team.teamname) })
          userdata.id = user.id;
          userdata.username = user.name;
          userCount = userCount + 1;
          teamUser.push(userdata)
        }
      }
    })
    teamVisibilityLabel = userCount + " users from " + teamCount + " team ";
    this.setState({
      teamVisibilityLabel: teamVisibilityLabel,
      teamUser: teamUser
    }, () => this.forceUpdate())
  }
  getUserPermission = () => {
    if (this.props.settingReducer.userPermissionDetails.data) {
      let data = this.props.settingReducer.userPermissionDetails.data.data;
      if (data.teampermissionstatus === true &&
        data.visibilityaccess.length > 0) {
        let cmpdt = data.visibilityaccess.find(function (e) { return e.sourcetype === 3 });
        let crudPermission = "";
        if (this.props.authReducer.userRole.id === 3) {
          crudPermission = cmpdt;
        }
        if (cmpdt.isaccess) {
          if (cmpdt.viewoption === 1) {
            this.AllMemberDisplay();
          }
          if (cmpdt.viewoption === 2 || cmpdt.viewoption === 3) {
            var selectedOwner = this.state.fieldsObj["owner"]
            if (selectedOwner && selectedOwner.values) {
              if (selectedOwner.values.teamlist.length > 0) {//if member in any team 
                if (selectedOwner.values.teamlist[0].teammanager === false) {//owner is not  teammanager
                  if (cmpdt.viewoption === 2) {
                    this.AllMemberDisplay();
                  } else {
                    this.themSelves(selectedOwner.values);
                  }
                } else {//owner is teammanager   
                  this.selectedUserDetails(selectedOwner.values);
                }
              } else { // not in any team 
                this.setState({ teamVisibilityLabel: "0 users", teamUser: [] }, () => this.forceUpdate())
              }
            } else {
              this.setState({ teamVisibilityLabel: "0 users", teamUser: [] }, () => this.forceUpdate())
            }
          }
        } else {//cmpdt.isaccess === false
          this.setState({ teamVisibilityLabel: "0 users", teamUser: [] }, () => this.forceUpdate())
        }
        this.setState({
          crudPermission: crudPermission === "" ? this.state.crudPermission : crudPermission,
          hasActiveTeamPermission: true,
          viewoption: cmpdt.viewoption,
        })
      } else {
        this.setState({ teamvisible: false })
      }
    }
  }
  componentWillReceiveProps(props) {
    this.getUserPermission();
    if (props.settingReducer.teamList.data && this.state.teamList !== props.settingReducer.teamList.data) {
      const teamList = props.settingReducer.teamList.data;
      let data = [];
      teamList.map((v, i) => {
        data.push({ id: v.id, teamname: v.teamname })
      });
      this.setState({ teamList: data })
    }
    if (props.settingReducer.userSettingArray.data && this.state.userSettings == "") {
      const settingData = props.settingReducer.userSettingArray.data;
      this.setState({
        userSettings: settingData,
      })
    }
    if (props.settingReducer.userItegration.data && props.settingReducer.userItegration.data !== this.state.itegrationDetails) {
      let data = props.settingReducer.userItegration.data;
      let hasRingCentralConnect = false;
      data.map((item) => {
        if (item.status && item.integrationid == 10) {
          hasRingCentralConnect = true;
        }
      });
      this.setState({
        itegrationDetails: data,
        hasRingCentralConnect: hasRingCentralConnect,
      });
    }

    if (props.companyReducer.companyFieldData.error == true && props.companyReducer.companyFieldData.notify_msg.indexOf('Permission:') != -1 ) {
      this.props.history.push('/unauthorized');
    } else{
      if (props.company_id && (this.state.company_id != props.company_id) || this.state.isUpdate && props.company_id != undefined) {
        let logparams = { companyid: parseFloat(props.company_id), emailid: '', logtypeid: [], activitypeid: [] }
        this.props.getCompanyRecLogListNew(this.props.apolloClient.client, logparams);
  
        props.getCompanyRecField(props.apolloClient.client, parseInt(props.company_id));
  
        if (props.companyReducer.companyFieldData.data) {
          var phonekey1 = this.findWithAttr(props.companyReducer.companyFieldData.data, 'fieldkey', "phones");
          if (phonekey1 != -1) {
            let phonedata = props.companyReducer.companyFieldData.data[phonekey1];
            if (phonedata.companydetail[0] &&
              phonedata.companydetail[0].values &&
              phonedata.companydetail[0].values.length > 0) {
              this.setState({
                logphoneMain: phonedata.companydetail[0].values[0].value,
              }, () => this.forceUpdate())
            } else {
              this.setState({
                isEditPhone: true
              }, () => this.forceUpdate())
            }
          }
        }
  
        this.setState({
          company_id: parseInt(props.company_id),
        }, () => this.forceUpdate())
      }
    }
    
    if (props.settingReducer.companyCustomFieldList.data && this.state.fieldsList.length == 0) {
      const customFields = props.settingReducer.companyCustomFieldList.data;
      var vkey = this.findWithAttr(customFields, "id", "38");
      var visibilitydefault = this.findWithAttr(customFields[vkey].options, "is_default", true);
      this.setState({
        fieldsList: customFields,
        fieldsObj: {
          ...this.state.fieldsObj,
          ["visibility"]: { fieldid: "38", values: customFields[vkey].options[visibilitydefault], },
        }
      })
    }

    if (props.settingReducer.countryListGql.data && this.state.countryListArray.length == 0) {
      const countryies = props.settingReducer.countryListGql.data;
      this.setState({ countryListArray: countryies })
    }
    if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
      const usersListArray = props.settingReducer.usersListArray.data;
      let userListForVisibility = [];
      usersListArray.map((data, i) => {
        userListForVisibility.push({ id: data.id, name: data.name })
      })
      this.setState({ usersListArray: usersListArray, userListForVisibility: userListForVisibility })
      var companyOwnerKey = this.findWithAttr(usersListArray, 'companyowner', "true");
      // this.setState({
      //   fieldsObj: {
      //     ...this.state.fieldsObj,
      //     ["owner"]: { fieldid: "4", values:usersListArray[companyOwnerKey]}
      //   }
      // })
    }


    if (props.settingReducer.systemTagsList.data && this.state.systemTagsList.length == 0) {
      const tagsData = props.settingReducer.systemTagsList.data;
      this.setState({ systemTagsList: tagsData })
    }

    if (props.settingReducer.companyCurrencyData.data != null && this.state.companyCurrencyData.length == 0) {
      var cData = props.settingReducer.companyCurrencyData.data;
      this.setState({ companyCurrencyData: cData[0] });
    }
    if (props.companyReducer.companyFieldData.data && props.companyReducer.companyFieldData.is_loaded) {

      this.setState({
        fieldsObj: {
          websites: {
            fieldid: "32",
            values: [
              { srno: "1", id: null, value: "" },
            ]
          },
          social: {
            fieldid: "35",
            values: [
              { srno: "1", id: null, value: "" },
            ]
          },
          phones: {
            fieldid: "30",
            values: [
              { srno: "1", id: null, value: "" },
            ]
          },
        },
        comp_value: '',
        city: '',
        addressState: '',
        zipcode: '',
      }, () => {
        let fieldsObj = this.state.fieldsObj;
        this.setState({ companyFieldData: props.companyReducer.companyFieldData.data })
        let country = "";
        let selectedTeamList = [];
        let selectedUserList = [];
        const companyDataForEdit = props.companyReducer.companyFieldData.data;

        var key1 = this.findWithAttr(companyDataForEdit, 'id', "29");
        if (key1 !== -1) {
          this.setState({
            oldteamvisible: companyDataForEdit[key1].companydetail[0].company.teamvisible !== null && companyDataForEdit[key1].companydetail[0].company.teamvisible,
            teamvisible: companyDataForEdit[key1].companydetail[0].company.teamvisible !== null && companyDataForEdit[key1].companydetail[0].company.teamvisible,
          })
        }

        var promise = new Promise((resolve, reject) => {
          companyDataForEdit.map((v, i) => {

            //if(!v.companydetail.length){return false}
            if (v.fieldtype.id == '1' && ((v.fieldkey == "websites" || v.fieldkey == "social" || v.fieldkey == "phones") && v.is_default)) {
              if (v.companydetail.length > 0 && v.companydetail[0].values.length > 0) {
                var data = {
                  fieldid: v.id,
                  values: v.companydetail[0].values
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }

              }
            } else if (v.fieldtype.id == '1' && v.id == '29') {//for first/last name      

              if (v.companydetail.length > 0) {
                var data = {
                  fieldid: v.id,
                  companyvalue: v.companydetail[0].fieldvalue
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }
              }
            }
            else if (v.fieldtype.id == '1' && v.id == '31') {//for owner field        
              if (v.companydetail.length > 0 && v.companydetail[0].values.length && v.companydetail[0].values[0].id) {
                var companyOwnerKey = this.findWithAttr(this.state.usersListArray, 'id', v.companydetail[0].values[0].id);
                if (companyOwnerKey !== -1) {
                  var ownerData = {
                    fieldid: v.id,
                    values: this.state.usersListArray[companyOwnerKey]
                  }


                  fieldsObj = {
                    ...fieldsObj,
                    [v.fieldkey]: ownerData,
                  }
                }
              }
            } else if (v.fieldtype.id == '1' && v.fieldkey == 'tags') {//for tag input
              var tagArray = []
              if (v.companydetail.length > 0 && v.companydetail[0].values.length > 0) {
                v.companydetail[0].values.map((v, i) => {
                  tagArray.push(v.value);
                })
              }
              var data = {
                fieldid: v.id,
                values: tagArray
              }
              fieldsObj = {
                ...fieldsObj,
                [v.fieldkey]: data,
              }
            } else if (v.fieldtype.id == '1' && v.id == '40') {//For value with currency Field
              if (v.companydetail.length > 0) {
                var data = {
                  fieldid: v.id,
                  companyvalue: v.companydetail[0].values[0].value
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }
              }
            } else if (v.fieldtype.id == '1' || v.fieldtype.id == '6' || v.fieldtype.id == '10' || v.fieldtype.id == '7' || v.fieldtype.id == '9') {//other textfield and number input
              if (v.companydetail.length > 0 && v.companydetail.length > 0) {
                var data = {
                  fieldid: v.id,
                  companyvalue: v.companydetail[0].fieldvalue
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }
              }
            }

            if (v.fieldtype.id == '2' && v.id == "36") {//Text aread with Address field
              if (v.companydetail.length > 0 && v.companydetail[0].values.length > 0) {
                var data = {
                  fieldid: v.id,
                  companyvalue: v.companydetail[0].values[0].street
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }


                this.setState({
                  city: v.companydetail[0].values[0].city,
                  addressState: v.companydetail[0].values[0].state,
                  zipcode: v.companydetail[0].values[0].zipcode,
                })

                if (v.companydetail[0].values[0].country !== "" && this.state.countryListArray.length > 0) {
                  var key = this.findWithAttr(this.state.countryListArray, 'name', v.companydetail[0].values[0].country);
                  country = this.state.countryListArray[key];
                }
              }
            } else if (v.fieldtype.id == '2') {//TextArea
              if (v.companydetail.length > 0) {
                var data = {
                  fieldid: v.id,
                  companyvalue: v.companydetail[0].fieldvalue
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }
              }
            }

            if (v.fieldtype.id == "3") {//checkboxes
              if (v.companydetail.length > 0) {
                var data = {
                  fieldid: v.id,
                  companyvalue: v.companydetail[0].fieldvalue
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }
              }
            }
            //if(this.state.fieldsList && this.state.fieldsList.length >0){
            if (v.fieldtype.id === '4') {//dropdown
              //38-visibility 33- contact type
              if (v.id === "38" || v.id === "33") {
                if (v.companydetail.length > 0 && v.companydetail[0].values.length > 0) {
                  var optId = this.findWithAttr(v.options, 'id', v.companydetail[0].values[0].id);
                  fieldsObj = {
                    ...fieldsObj,
                    [v.fieldkey]: { fieldid: v.id, values: v.options[optId] },
                  }
                  if (v.id === "38") {
                    if (v.companydetail[0].values[0].id === 2) {
                      let filteredselectedTeamList = this.state.selectedTeamList.length > 0 ? this.state.selectedTeamList.filter(el => Object.keys(el).length) : []
                      if (v.companydetail[0].values[0].visiblelist && filteredselectedTeamList !== v.companydetail[0].values[0].visiblelist) {
                        selectedTeamList = v.companydetail[0].values[0].visiblelist
                      }
                    } else if (v.companydetail[0].values[0].id === 3) {
                      let filteredselectedUserList = this.state.selectedUserList.length > 0 ? this.state.selectedUserList.filter(el => Object.keys(el).length) : []
                      if (v.companydetail[0].values[0].visiblelist && filteredselectedUserList !== v.companydetail[0].values[0].visiblelist) {
                        selectedUserList = v.companydetail[0].values[0].visiblelist
                      }
                    }
                  }
                }
              } else {
                if (v.companydetail.length > 0 && v.companydetail[0].values.length > 0) {
                  var optId = this.findWithAttr(v.options, 'id', v.companydetail[0].values[0].id);
                  fieldsObj = {
                    ...fieldsObj,
                    [v.fieldkey]: { fieldid: v.id, values: v.options[optId] },
                  }
                }
              }
            }
            if (v.fieldtype.id === '5') {//multi dropdown
              if (v.companydetail.length > 0 && v.companydetail[0].values.length > 0) {
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: { fieldid: v.id, values: v.companydetail[0].values },
                }
              }
            }
            if (v.fieldtype.id === '8') {//datepicker
              if (v.companydetail.length > 0 && v.companydetail[0].fieldvalue != "") {
                // var dt = moment.unix(v.companydetail[0].fieldvalue).format(this.state.userSettings.dateformat).toString()
                // dt = new Date(dt)
                var dt = new Date(moment(v.companydetail[0].fieldvalue).format('YYYY-MM-DD'))
                dt = new Date(dt)
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: { fieldid: v.id, values: dt },
                }
              } else if (v.companydetail.length > 0 && v.companydetail[0].fieldvalue == "") {
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: { fieldid: v.id, values: null },
                }
              }
            }
            //}
          })

          this.setState({
            fieldsObj,
            country,
            isUpdate: false,
            isAnyValueChange: false,
            oldSelctedTeamList: [...selectedTeamList, {}],
            oldSlectedUserList: [...selectedUserList, {}],
            selectedTeamList: [...selectedTeamList, {}],
            selectedUserList: [...selectedUserList, {}],
          }, () => {
            resolve(true)
          })
        });

        promise.then(result => {
          props.companyReducer.companyFieldData.is_loaded = false;
          this.setState({
            isAnyValueChange: false
          }, () => {
          })
        });
      })
    }
  }
  handleCompanyChange = (val, newValue) => {
    let id = (newValue.__isNew__) ? "0" : newValue.value;
    let comp_values = { id: id, name: newValue.label };
    this.handleChange({ fieldid: "17", values: comp_values }, 'company', 'allowSubmitChange');
    this.setState({ comp_value: id });
  };


  addNewField = (key, option) => {
    let data = this.state.fieldsObj[key];
    data.values.push({
      srno: (data.values.length + 1).toString(),
      id: option.id,
      value: ""
    })

    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [key]: data
      }
    })
  }

  handleAddMoreField = (name, key, index, value) => {
    let data = this.state.fieldsObj[key];
    data.values[index][name] = value;
    var keyName = key;
    var keyVal = data;
    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [key]: data
      },
      isAnyValueChange: true
    }, () => {
      // setTimeout(() => {
      //   //dont upadte lead if first name is empty
      //   if (this.state.fieldsObj['name'] && this.state.fieldsObj['name'].companyvalue == "") {
      //     return false
      //   }
      //   //dont allow to update if value length 1 and value is blank
      //   if (keyVal.values.length == 1 && keyVal.values[0].value == "") {
      //     return false
      //   }
      //   if (this.state.fieldsObj[keyName] == keyVal) {
      //     this.handleFormSubmit()
      //   }
      // }, 2500);
    })
  }

  handleNormalFields = (event) => {
    var keyName = event.target.name;
    console.log("keyName ", keyName);
    var keyVal = event.target.value;
    this.setState({
      [event.target.name]: event.target.value,
      isAnyValueChange: true
    }, () => {
      // //dont upadte lead if first name is empty
      // if (this.state.fieldsObj['name'] && this.state.fieldsObj['name'].companyvalue == "") {
      //   return false
      // }
      // setTimeout(() => {
      //   if (this.state[keyName] == keyVal) {
      //     this.handleFormSubmit()
      //   }
      // }, 1500);
    })
  };

  handleCountryChange = (event, values, updatedfieldid) => {
    this.setState({
      country: values,
      isAnyValueChange: true,
      updatedfieldid: parseFloat(updatedfieldid),
    }, () => {
      //dont upadte lead if first name is empty
      if (this.state.fieldsObj['name'].companyvalue == "") {
        return false
      }
      setTimeout(() => {
        if (this.state.country == values) {
          this.handleFormSubmit()
        }
      }, 500);
    })
  }

  focusInValue = () => {
    this.setState({
      focusValue: true,
    }, () => this.forceUpdate())
  };


  currencyDropdownClose = () => {
    this.setState({ focusValue: false })
  };

  handleChange = (val, name, isSubmit) => {
    if (val.fieldid === "38") {
      this.setState({ isEditVisibility: true }, () => this.forceUpdate())
      if (val.values.id === 2) {
        this.setState({ selectedTeamList: [{}] }, () => this.forceUpdate())
      } else if (val.values.id === 3) {
        this.setState({ selectedUserList: [{}] }, () => this.forceUpdate())
      }
    }
    var key = this.findWithAttr(this.state.fieldsList, 'id', val.fieldid);
    if (key != -1 && this.state.fieldsList[key].fieldtype.id != "4" && this.state.fieldsList[key].fieldtype.id != "8") {
      if (this[name]) {
        this[name].validate(val.value);
      }
    }
    if (val.fieldid === "31") {
      if ((this.state.viewoption === 2 || this.state.viewoption === 3) && val.values === null) {
        this.setState({
          teamVisibilityLabel: "0 users",
          teamUser: []
        }, () => this.forceUpdate())
      } else {
        this.setState({
          fieldsObj: {
            ...this.state.fieldsObj,
            [name]: val,
          }
        }, () => this.getUserPermission())
      }
    }

    console.log("isSubmit", isSubmit);
    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [name]: val
      },
      isAnyValueChange: true,
      updatedfieldid: parseFloat(val.fieldid)
    }, () => {
      if (!isSubmit) {
        return false
      }
      // if (name === "name" && val.companyvalue === "") {
      //   this.setState({ fnameError: true })
      //   return false
      // } else {
      //   this.setState({ fnameError: false })
      // }
      // //dont upadte lead if first name is empty
      // if (this.state.fieldsObj['name'].companyvalue == "") {
      //   return false
      // }
      setTimeout(() => {
        if (this.state.fieldsObj[name] == val) {
          this.handleFormSubmit()
        }
      }, 0);
    })
  };

  focusOutSubmitForm = (event, fieldId, ref) => {
    this.setState({ isEditPhone: false, updatedfieldid: parseFloat(fieldId) })

    console.log("this[ref]", this[ref]);
    if (this[ref].props && this[ref].props.id == "tagInputId1") {
      setTimeout(() => {
        document.getElementById("tagInputId1").value = "";
      }, 10)
    }
    this[ref].validate(event.target.value);
    setTimeout(() => {
      if (this.state.isAnyValueChange) {
        this.handleFormSubmit()
      }
    }, 0);
  }

  onPhoneClick = () => {
    this.setState({
      isEditPhone: true
    })
  }

  handleFormSubmit = () => {
    this.updateCompanyForm.isFormValid(false).then(isValid => {
      this.setState({
        isAnyValueChange: false
      }, () => this.forceUpdate())
      if (isValid) {
        let logData = [];
        let submitData = JSON.parse(JSON.stringify(this.state.fieldsObj));
        submitData = Object.values(submitData).map((row) => {
          if (row.fieldid == "32" || row.fieldid == "35" || row.fieldid == "30") { //website social phone email
            if (row.values.length == 1 && (row.values[0].id == null || row.values[0].value == "")) {
              row.values = [{ "fieldid": row.fieldid, value: [] }]
            }
            if (row.values.length > 1) {
              row.values.map((val, i) => {
                if (val.value == "") {
                  row.values.splice(i, 1);
                }
              })
            }

            if (row.values.length > 0) {//adding selected dropdown val as lable
              row.values.map((val, i) => {
                if (val.id) {
                  var fKey = this.findWithAttr(this.state.fieldsList, 'id', row.fieldid);
                  if (fKey != -1) {
                    var optKey = this.findWithAttr(this.state.fieldsList[fKey].options, 'id', val.id);
                    row.values[i].label = this.state.fieldsList[fKey].options[optKey].optionvalue
                  }
                }
              })
            }

            if (row.fieldid == "30") {
              if (row.values.length === 1) {
                if (row.values[0].fieldid) {
                  row.values = []
                } else {
                  if (row.values.length > 0) {
                    var foundMobile = this.findWithAttr(row.values, 'id', "2");
                    if (foundMobile != -1) {
                      var index = 1;
                      row.values.map((val, i) => {
                        if (val.id == "2") {
                          row.values[i].srno = "1"
                        } else {
                          row.values[i].srno = (index + 1).toString()
                        }
                        index++;
                      })
                    }
                  }
                }
              } else {
                if (row.values.length > 0) {
                  var foundMobile = this.findWithAttr(row.values, 'id', "2");
                  if (foundMobile != -1) {
                    var index = 1;
                    row.values.map((val, i) => {
                      if (val.id == "2") {
                        row.values[i].srno = "1"
                      } else {
                        row.values[i].srno = (index + 1).toString()
                      }
                      index++;
                    })
                  }
                }
              }
            }
            if (row.fieldid == "32" || row.fieldid == "35") {//make value blank array if notfill in this field
              if (row.values.length === 1) {
                if (row.values[0].fieldid) {
                  row.values = []
                }
              }
            }
          }
          if (row.fieldid === "38" || row.fieldid === "33") {
            if (row.fieldid === "38") {
              let filterVisibleList = [];
              if (row.values.id === 2) {
                filterVisibleList = this.state.selectedTeamList.length > 0 ? this.state.selectedTeamList.filter(el => Object.keys(el).length) : []
              } else if (row.values.id === 3) {
                filterVisibleList = this.state.selectedUserList.length > 0 ? this.state.selectedUserList.filter(el => Object.keys(el).length) : []
              }
              row.values = [
                {
                  srno: "1",
                  id: row.values.id,
                  value: row.values.name,
                  visiblelist: filterVisibleList
                }
              ];
            } else {
              row.values = [{ srno: "1", id: row.values.id, value: row.values.name }]
            }
          }
          var key = this.findWithAttr(this.state.fieldsList, 'id', row.fieldid);
          if (this.state.fieldsList[key].fieldtype.id == "4" && this.state.fieldsList[key].is_default == false) {
            row.values = [{ srno: "1", id: row.values.id, value: row.values.optionvalue }]
          }
          if (this.state.fieldsList[key].fieldtype.id == "5" && this.state.fieldsList[key].is_default == false) {
            var ar = [];
            if (row.values.length > 0) {
              row.values.map((val, i) => {
                var optName;
                if (val.optionvalue) {
                  optName = val.optionvalue
                } else {
                  optName = val.value
                }
                ar.push({ srno: (i + 1).toString(), id: val.id, value: optName })
              })
            }
            row.values = ar
          }

          //validating date fiels
          if (this.state.fieldsList[key].fieldtype.id == "8") {//date field
            if (row.values == null) {
              row.companyvalue = "";
              delete row.values;
            } else if (row.values == "Invalid Date") {
              return false
            } else {
              // row.companyvalue = moment(row.values).format("X");

              // let date_test = moment(row.values).format('YYYY-MM-DD HH:mm');
              // var logdate = moment.tz(date_test, this.state.userSettings.timezone).format();
              // row.companyvalue = moment.utc(logdate).format('X');
              var dt = moment(row.values).format('YYYY-MM-DD')
              row.companyvalue = dt;

              delete row.values;
            }
          }

          if (row.fieldid == "39") {
            if (row.values.length > 0) {
              var objArr = [];
              row.values.map((val, k) => {
                var foundKey = this.findWithAttr(this.state.systemTagsList, 'tagname', val);
                var tagId = "0";
                if (foundKey !== -1) {
                  tagId = this.state.systemTagsList[foundKey].id
                }
                objArr.push({ "srno": (k + 1).toString(), value: val.toLowerCase(), id: tagId, isdeleted: "0" })
              })

              //add tags from props value which is removed   with id deleted id "1"
              var tagsKeyFromProps = this.findWithAttr(this.props.companyReducer.companyFieldData.data, 'id', "39");
              if (this.props.companyReducer.companyFieldData.data[tagsKeyFromProps].companydetail.length > 0) {
                var oldTagsValues = this.props.companyReducer.companyFieldData.data[tagsKeyFromProps].companydetail[0].values;
                if (oldTagsValues.length > 0) {
                  oldTagsValues.map((v, k) => {
                    var checkKey = this.findWithAttr(objArr, 'value', v.value);
                    if (checkKey === -1) {
                      v.isdeleted = "1";
                      v.srno = (objArr.length + 1).toString();
                      objArr.push(v);
                    }
                  })
                }
              }

              row.values = objArr
              if (this.props.companyReducer.companyFieldData.data[tagsKeyFromProps].companydetail.length > 0) {
                this.props.companyReducer.companyFieldData.data[tagsKeyFromProps].companydetail[0].values = objArr;
              }
            }
          }

          if (row.fieldid == "36") {//address field
            row.companyvalue = {
              street: row.companyvalue,
              city: this.state.city,
              state: this.state.addressState,
              zipcode: this.state.zipcode,
              country: (this.state.country && this.state.country.name) ? this.state.country.name : ""
            }
          }
          if (row.fieldid == "31") {//owner value set
            if (row.values) {
              row.values = [{ "srno": "1", id: row.values.id, value: row.values.email, name: row.values.name }];
            } else {
              row.values = [];
            }
            delete row.companyvalue;
          }
          return row;
        })

        //adding  companydetailid for update object from this.state.companyFieldData
        submitData = submitData.map((row, i) => {
          var key = this.findWithAttr(this.state.companyFieldData, 'id', row.fieldid);
          if (key !== -1) {
            if (this.state.companyFieldData[key].companydetail.length > 0) {
              row.companydetailid = this.state.companyFieldData[key].companydetail[0].id;
            } else {
              row.companydetailid = ""
            }
          }
          return row
        })

        //not to submit if any field is false
        var allowToSubmit = true
        submitData.map((row) => {
          if (!row) {
            allowToSubmit = false
          }
        })

        console.log("submitData", submitData);

        if (this.state.company_id && allowToSubmit && this.state.crudPermission.isedit) {
          this.setState({ isEditVisibility: false });
          let req = {
            companydata: submitData,
            companyid: this.state.company_id,
            companyImage: "",
            updatedfieldid: this.state.updatedfieldid,
            teamvisible: this.state.teamvisible,

          }
          this.props.updateCompanyRecDetail(this.props.apolloClient.client, req);
        }
      }

    })

  };

  arrDifference = (arr1, arr2) => {
    var arr = [];
    arr1 = arr1.toString().split(',').map(Number);
    arr2 = arr2.toString().split(',').map(Number);
    // for array1
    for (var i in arr1) {
      if (arr2.indexOf(arr1[i]) === -1)
        arr.push(arr1[i]);
    }
    // for array2
    for (i in arr2) {
      if (arr1.indexOf(arr2[i]) === -1)
        arr.push(arr2[i]);
    }
    return arr.sort((x, y) => x - y);
  }

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] == value) {
        return i;
      }
    }
    return -1;
  }
  teamHandleChange = (index, value, name) => {
    let mianteam = this.state.teamList;
    let selectedTeamList = this.state.selectedTeamList
    let mianUsers = this.state.usersListArray;
    let selectedUserList = this.state.selectedUserList
    if (name === "team") {
      if (value !== null) {
        selectedTeamList[index] = value;
        if (mianteam.length + 1 > selectedTeamList.length) {
          selectedTeamList[index + 1] = {};
        }
      } else {
        selectedTeamList.splice(index, 1);
        if (selectedTeamList.length === 0) {
          selectedTeamList[0] = {};
        }
      }
    } else {
      if (value !== null) {
        selectedUserList[index] = { id: value.id, name: value.name }
        if (mianUsers.length + 1 > selectedUserList.length) {
          selectedUserList[index + 1] = {};
        }
      } else {
        selectedUserList.splice(index, 1);
        if (selectedUserList.length === 0) {
          selectedUserList[0] = {};
        }
      }
    }
    this.setState({
      selectedTeamList: selectedTeamList,
      selectedUserList: selectedUserList
    })
  }
  handleToggleEditVisibility = () => {
    this.setState({
      isEditVisibility: !this.state.isEditVisibility
    })
  }

  handleChangeVisibilityRadio = (value) => {
    if (value) {
      let customFields = [...this.state.fieldsList]
      var vkey = this.findWithAttr(customFields, "id", "38");
      var visibilitydefault = this.findWithAttr(
        customFields[vkey].options,
        "is_default",
        true
      );
      this.setState({
        selectedTeamList: [],
        updatedfieldid: 38,
        fieldsObj: {
          ...this.state.fieldsObj,
          ["visibility"]: {
            fieldid: "38", values: customFields[vkey].options[visibilitydefault],
          },
        },
      });
    }
    this.setState({
      teamvisible: value === "true" ? true : false
    })

  }
  handleVisibilitySave = () => {
    this.setState({
      updatedfieldid: 38
    }, () => this.handleFormSubmit())
  }
  hadleCancelvisibility = () => {
    let fieldsObj = this.state.fieldsObj
    let customFields = [...this.state.fieldsList]
    var vkey = this.findWithAttr(customFields, "id", "38");
    var visibilitydefault = this.findWithAttr(customFields[vkey].options, "is_default", true);

    let companyFieldData = [...this.state.companyFieldData]
    var key1 = this.findWithAttr(companyFieldData, 'id', "38");
    if (companyFieldData[key1].companydetail[0].values[0]) { //if visibilty not efault 
      let mainoldValue = companyFieldData[key1].companydetail[0].values[0];
      var opn = this.findWithAttr(companyFieldData[key1].options, 'id', mainoldValue.id);
      fieldsObj = {
        ...fieldsObj,
        ["visibility"]: {
          fieldid: "38",
          values: companyFieldData[key1].options[opn],
        },
      }
    } else {//set  visibilty  default 
      fieldsObj = {
        ...fieldsObj,
        ["visibility"]: {
          fieldid: "38",
          values: customFields[vkey].options[visibilitydefault],
        },
      }
    }
    this.setState({
      selectedTeamList: this.state.oldSelctedTeamList,
      selectedUserList: this.state.oldSlectedUserList,
      fieldsObj: fieldsObj,
      teamvisible: this.state.oldteamvisible,

    });
    this.handleToggleEditVisibility()
  }
  render() {
    return (
      <div className="v_scroll position-relative label-size-14 pr-16">
        <ValidatorForm ref={updateCompanyForm => this.updateCompanyForm = updateCompanyForm} noValidate instantValidate={false} onSubmit={this.handleFormSubmit}>
          <Grid container spacing={2}>
            {CreateFieldsDynamicCompanyEdit(this)}
          </Grid>
        </ValidatorForm>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
  companyReducer: state.companyReducer,
  authReducer: state.authReducer
});

const mapDispatchToProps = dispatch => {
  return {
    getTeam: (client) => dispatch(actionCreators.getTeam(client)),

    getCompanyRecList: (client) => { dispatch(actionCreators.getCompanyRecList(client)) },
    updateCompanyRecDetail: (client, request) => { dispatch(actionCreators.updateCompanyRecDetail(client, request)) },
    customFieldList: (client, request) => { dispatch(actionCreators.customFieldList(client, request)) },
    getCompanyRecLogListNew: (client, companyid) => dispatch(actionCreators.getCompanyRecLogListNew(client, companyid)),


    getCountryList: (client) => { dispatch(actionCreators.getCountryList(client)) },
    clearNotification: () => dispatch(actionCreators.clearNotification()),
    getCompanyCurrency: (client) => dispatch(actionCreators.getCompanyCurrency(client)),
    getTagsList: (client) => dispatch(actionCreators.getTagsList(client)),
    getCompanyRecField: (client, companyid) => dispatch(actionCreators.getCompanyRecField(client, companyid)),
    getUserList: (client) => dispatch(actionCreators.getUserList(client)),
    getUserSetting: (client, data) => dispatch(actionCreators.getUserSetting(client, data)),
    getUserIntegration: (client) => dispatch(actionCreators.getUserIntegration(client)),
    saveRingCentralData: (client, value) => dispatch(actionCreators.saveRingCentralData(client, value)),
  }
}

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(CompanyProfileDetails);
export default (connect(mapStateToProps, mapDispatchToProps)(withRouter(CompanyProfileDetails)));