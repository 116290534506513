import React, { Component } from "react";
import { Card, Typography, CardContent, Button } from "@material-ui/core";
import cogsIcon from '@iconify/icons-el/cogs';
import { Icon, InlineIcon } from '@iconify/react';


class SettingIcon extends Component {
    render() {
        return (
            // <React.Fragment >
            <Icon icon={cogsIcon} style={{ width: '30px', height: '23px' }} />
            // {/* </React.Fragment> */}
        );
    }
}

export default SettingIcon;