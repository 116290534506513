import React, { Component } from "react";
import { withStyles, IconButton, Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import userFriends from '@iconify/icons-fa-solid/user-friends';
import crosshairsIcon from '@iconify/icons-fa-solid/crosshairs';
import handshakeIcon from '@iconify/icons-fa-solid/handshake';
import chartBar from '@iconify/icons-fa-solid/chart-bar';
import bxsEnvelope from '@iconify/icons-bx/bxs-envelope';
import businessTime from '@iconify/icons-fa-solid/business-time';
import cogsIcon from '@iconify/icons-el/cogs';
import plusCircle from '@iconify/icons-fa-solid/plus-circle';
import bxsPalette from '@iconify/icons-bx/bxs-palette';
import BusinessIcon from '@material-ui/icons/Business';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import FontAwesome from 'react-fontawesome'
import EditNewFieldLeadDialog from "./EditNewFieldLeadDialog";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import { Icon, InlineIcon } from '@iconify/react';
import * as actions from '../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';
import { Table } from 'antd';
const EditIconIconSmall = withStyles(() => ({
    root: {
        fontSize: "1.5rem",
    }
}))(EditIcon);

const DeleteIconIconSmall = withStyles(() => ({
    root: {
        fontSize: "1.5rem",
    }
}))(DeleteIcon);

class SettingOther extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            shouldOpenEditFieldLeadDialog: '',
            edit_field_id: 0,
            deleteData: '',
            isDeleteConfirmDialoOpen: false,
            typeId: ''
        };
    }
    componentDidMount() {
        this.props.CreatedFieldList(this.props.apolloClient.client);
        this.props.customFieldTypeList(this.props.apolloClient.client);
    }

    componentWillReceiveProps(props) {
        let rows = [];

        if (props.settingReducer.createdFieldList.data && props.settingReducer.customFieldTypeList.data) {
            let newCustomFields = props.settingReducer.createdFieldList.data;
            newCustomFields.map((row, index) => {
                let type = props.settingReducer.customFieldTypeList.data.filter(row1 => row1.id == row.fieldtype)[0];
                let typeIcon = type.fieldicon;
                console.log(typeIcon,'typeIcon');
                rows.push(
                    {
                        fieldname: (
                            <div>
                                <FontAwesome className={'crm-fontawsome-icon ' + typeIcon} ></FontAwesome>
                                <label className="crm-custom-field-label">{row.fieldlabel}</label>
                            </div>
                        ),
                        available: (<div>
                            {(row.inleads === 1) ? <Icon icon={crosshairsIcon} style={{ width: '20px', height: '20px', marginBottom: '3px' }} className="crm-fontawsome-icon" /> : null}
                            {(row.inpeople === 1) ? <Icon icon={userFriends} style={{ width: '24px', height: '24px' }} className="crm-fontawsome-icon mt-8" /> : null}
                            {(row.incompany === 1) ? <BusinessIcon className="crm-fontawsome-icon" /> : null}
                            {(row.inopportunities === 1) ? <Icon style={{ width: '25px', height: '25px' }} icon={handshakeIcon} className="crm-fontawsome-icon" /> : null}
                            {(row.inprojects === 1) ? <Icon icon={businessTime} style={{ width: '25px', height: '25px' }} className="crm-fontawsome-icon" /> : null}
                            {(row.intasks === 1) ? <EventAvailableIcon className="crm-fontawsome-icon" /> : null}
                        </div>),
                        action: (
                            <React.Fragment>
                                <IconButton onClick={() => this.openPopup(row)} size="small"><EditIcon /></IconButton>
                                <IconButton size="small" onClick={() => this.deleteCustomField(row)}><DeleteIcon /></IconButton>
                            </React.Fragment>
                        ),
                    }
                );
            });
            this.setState({ data: rows });
        }
    }

    deleteCustomField = (delete_Data) => {
        this.toggleDeleteDialog(delete_Data)
    }

    toggleDeleteDialog = (data) => {
        this.setState({ isDeleteConfirmDialoOpen: !this.state.isDeleteConfirmDialoOpen, deleteData: data });
    };
    confirmDeleteCustomField = () => {
        var param = [];
        param['fieldId'] = { fieldId: parseFloat(this.state.deleteData.id) };
        param['typeId'] = { typeId: (this.state.deleteData.inleads) ? parseFloat(1) : null };
        param['typeId'] = { typeId: (this.state.deleteData.inpeople) ? parseFloat(2) : null };
        param['typeId'] = { typeId: (this.state.deleteData.incompany) ? parseFloat(3) : null };

        this.props.deleteCustomFieldInput(this.props.apolloClient.client, param);
        this.toggleDeleteDialog()
    };
    openPopup = (data) => {
        this.setState({ shouldOpenEditFieldLeadDialog: true, edit_field_id: data })

    }
    closePopUp = () => {
        this.setState({ shouldOpenEditFieldLeadDialog: false });
    }
    handleLeadDialogClose = () => {
        this.setState({ shouldOpenEditFieldLeadDialog: false });
    };
    render() {
        const columns = [
            {
                title: "Field Name",
                dataIndex: "fieldname",
                className: "align_left",
                onCell: () => {
                    return {
                        style: {
                            textAlign: 'left',
                        }
                    }
                },
            },
            {
                title: "Available On",
                dataIndex: "available",
                className: "align_right",
                onCell: () => {
                    return {
                        style: {
                            textAlign: 'left'
                        }
                    }
                },
            },
            {
                title: "Actions",
                dataIndex: "action",
                width: 120,
                onCell: () => {
                    return {
                        style: {
                            whiteSpace: 'nowrap',
                            maxWidth: 120,
                        }
                    }
                },
            }
        ];
        const { data, shouldOpenEditFieldLeadDialog, edit_field_id } = this.state;

        return (
            <div className="crm-custom-field-table" >
                <React.Fragment>
                    <div className="table_width">
                        <Table
                            columns={columns}
                            pagination={false}
                            dataSource={data}
                        />
                    </div>
                </React.Fragment>
                <div className="d-flex justify-content-end mb-16">
                    {shouldOpenEditFieldLeadDialog && (
                        <EditNewFieldLeadDialog
                            fieldData={edit_field_id}
                            handleClose={this.handleLeadDialogClose}
                            open={shouldOpenEditFieldLeadDialog}
                            allData={this}
                        />
                    )}
                </div>
                <Dialog
                    fullWidth={true}
                    maxWidth={'xs'}
                    open={this.state.isDeleteConfirmDialoOpen}
                    onClose={this.toggleDeleteDialog}
                    disableBackdropClick
                    // disableEscapeKeyDown
                    aria-labelledby="ar-remove-dialog-title"
                >
                    <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to remove custom field?"}</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.toggleDeleteDialog} color="primary">
                            Cancel
                                      </Button>
                        <Button type="Button" onClick={() => this.confirmDeleteCustomField()} className="btn_Primary">
                            Ok
                                      </Button>

                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        settingReducer: state.settingReducer,
        apolloClient: state.apolloClient
    };
};
const mapDispatchToProps = dispatch => {
    return {
        CreatedFieldList: (client, request) => { dispatch(actions.CreatedFieldList(client, request)) },
        customFieldTypeList: (client) => { dispatch(actions.customFieldTypeList(client)) },
        deleteCustomFieldInput: (client, request) => { dispatch(actions.deleteCustomFieldInput(client, request)) },
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(SettingOther);
