import React, { Component } from "react";
import { Card, Typography, CardContent, Button } from "@material-ui/core";
import NativeIntegrations from './NativeIntegrations';
import ZapireIntegrations from './ZapireIntegrations';

class Integrations extends Component {
    render() {
        return (
            <React.Fragment >
                <div className="v_scrollI">
                    <div className="mb-40">
                        <Typography className="p-10" variant="h5" color="inherit">Integrations</Typography>
                        <Typography className="text-hint p-10" variant="p" color="inherit">
                            Integrate with third-party applications to get a full 360-degree view of your customers
                    </Typography>
                    </div>
                    <div className="mt-20">
                        <Typography className="font-size-18 p-10" variant="p" color="inherit">
                            Native Integrations
                        </Typography>
                        <NativeIntegrations />
                    </div>
                    <div className="mt-20">
                        <Typography className="font-size-18 p-10" variant="p" color="inherit">
                            Zapier Integrations
                        </Typography>
                        <ZapireIntegrations />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Integrations;