import React, { Component } from "react";
import {
  Dialog,
  IconButton,
  Button,
  Icon,
  Tooltip,
  Grid,
  DialogActions,
  MenuItem,
  Select,
  ListSubheader,
  withStyles,
} from "@material-ui/core";
import {
  ValidatorForm,
  TextValidator,
} from "react-material-ui-form-validator";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";

import { MegadraftEditor, editorStateFromRaw, editorStateToJSON } from "megadraft-denistsuman";

import { EditorState, Modifier, Entity, genKey } from 'draft-js';

import LinkInput from "megadraft-denistsuman/lib/entity_inputs/LinkInput";
import icons from "megadraft-denistsuman/lib/icons"
import 'megadraft-denistsuman/dist/css/megadraft.css'
import FontAwesome from "react-fontawesome";
import { stateToHTML } from 'draft-js-export-html';
import clearFormatting from 'draft-js-clear-formatting';
import FormatClearIcon from '@material-ui/icons/FormatClear';

const IconSmall = withStyles(() => ({
  root: {
    fontSize: "1.0rem",
  }
}))(Icon);

const TooltipNew = withStyles(() => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);


let messageeditstate = '';

class ClearFormattingIcon extends React.Component {
  render() {
    return (
      <>
        <div className="pt-12">
          {/* <FontAwesome className={"fas fa-remove-format toolbar__button"} style={{ fontSize: '18px', paddingBottom: '15px', paddingTop: "0px" }} /> */}
          <FormatClearIcon onClick={this.clearFormating} style={{ paddingBottom: '6px', width : '30px', height : '30px' }}/>
        </div>
      </>
    );
  }
}

class UnderlineIcon extends React.Component {
  render() {
    return (
      <>
        <div className="pt-12">
          <FontAwesome className={"fa fa-underline toolbar__button"} style={{ fontSize: '18px', paddingBottom: '15px', paddingTop: "0px" }} />
        </div>
      </>
    );
  }
}
class CustomSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      selecttemplate: "none",
      placeholder: "none",
      Template_list: [],
      current_template: [],
      subject_edit: '',
      message_edit: '',
      user_placeholder: [
        { key: "rec#FirstName", label: "#FirstName" },
        { key: "rec#LastName", label: "#LastName" },
        { key: "rec#FullName", label: "#FullName" },
        { key: "rec#Email", label: "#Email" },
        { key: "rec#Phone", label: "#Phone" },
        { key: "rec#CompanyName", label: "#CompanyName" },
        { key: "rec#Title", label: "#Title" },
        { key: "rec#Address", label: "#Address" },
        { key: "rec#ContactType", label: "#ContactType" },
        { key: "rec#Owner", label: "#Owner" },
      ],
      sender_placeholder: [
        { key: "send#SenderName", label: "#SenderName" },
        { key: "send#SenderEmail", label: "#SenderEmail" },
        { key: "send#SenderPhone", label: "#SenderPhone" },
        { key: "send#SenderCompanyName", label: "#SenderCompanyName" },
        { key: "send#SenderAddress", label: "#SenderAddress" },
      ]
    };
  }


  componentWillReceiveProps(props) {

    if (props.thisData.props.settingReducer.emailtemplatelist.data) {
      let email_list = props.thisData.props.settingReducer.emailtemplatelist.data;
      this.setState({ Template_list: email_list })
    }

  }

  handleSelectPlaceholder = (event) => {

    let value = "#" + event.target.value.split("#")[1];
    let type = event.target.value.split("#")[0];

    if (this.props.editorType == "Message") {
      // let meta = event.target.value;
      let editorState = this.props.thisData.state.editorState;
      let currentContent = editorState.getCurrentContent();
      let selection = editorState.getSelection();
      let entityKey = Entity.create('UNSTYLED', 'MUTABLE', " ");
      let textWithEntity = Modifier.insertText(currentContent, selection, " ", null, entityKey);
      let neweditorstate = EditorState.push(editorState, textWithEntity, 'insert-characters');

      currentContent = neweditorstate.getCurrentContent();
      selection = neweditorstate.getSelection();
      let entityKey2 = Entity.create('LINK', 'MUTABLE', { url: '#' + type, value });
      let textWithEntity2 = Modifier.insertText(currentContent, selection, value, null, entityKey2);
      neweditorstate = EditorState.push(editorState, textWithEntity2, 'insert-characters');


      // currentContent = neweditorstate.getCurrentContent();
      // selection = neweditorstate.getSelection();
      // let entityKey1 = Entity.create('UNSTYLED', 'MUTABLE', " ");
      // let textWithEntity1 = Modifier.insertText(currentContent, selection, " ", null, entityKey1);
      // neweditorstate = EditorState.push(neweditorstate, textWithEntity1, 'insert-characters');

      messageeditstate = neweditorstate;
      this.props.thisData.onChange(neweditorstate);
    }
    else {
      //subject
      // let subjectmeta = event.target.value;
      let editorSubjectState = this.props.thisData.state.subject;
      let currentSubjectContent = editorSubjectState.getCurrentContent();
      let subjectSelection = editorSubjectState.getSelection();
      let entityKey = Entity.create('UNSTYLED', 'MUTABLE', " ");
      let textWithEntity = Modifier.insertText(currentSubjectContent, subjectSelection, " ", null, entityKey);
      let newsubjecteditorstate = EditorState.push(editorSubjectState, textWithEntity, 'insert-characters');

      currentSubjectContent = newsubjecteditorstate.getCurrentContent();
      subjectSelection = newsubjecteditorstate.getSelection();
      let entityKey2 = Entity.create('LINK', 'MUTABLE', { url: '#' + type, value });
      let textWithEntity2 = Modifier.insertText(currentSubjectContent, subjectSelection, value, null, entityKey2);
      newsubjecteditorstate = EditorState.push(newsubjecteditorstate, textWithEntity2, 'insert-characters');

      // currentSubjectContent = newsubjecteditorstate.getCurrentContent();
      // subjectSelection = newsubjecteditorstate.getSelection();
      // let entityKey1 = Entity.create('UNSTYLED', 'MUTABLE', " ");
      // let textWithEntity1 = Modifier.insertText(currentSubjectContent, subjectSelection, " ", null, entityKey1);
      // newsubjecteditorstate = EditorState.push(newsubjecteditorstate, textWithEntity1, 'insert-characters');

      this.props.thisData.handleChangeSubject(newsubjecteditorstate);
    }
  }
  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] == value) {
        return i;
      }
    }
    return -1;
  }
  handleSelectTemplate = (event) => {
    let emailTemplatelist = this.state.Template_list;
    let key = this.findWithAttr(emailTemplatelist, 'id', event.target.value);
    if (key != -1) {
      // Mesaage Box with value 
      let selectedMessageTemplate = JSON.parse(JSON.parse(emailTemplatelist[key].message));
      let selectedMessageBlocks = selectedMessageTemplate.blocks;
      let editorState = this.props.thisData.state.editorState;
      (selectedMessageBlocks) && selectedMessageBlocks.map((block, i) => {
        const arr = block.text.split(' ');
        arr.forEach(element => {
          if (element.indexOf('#') === 0) {
            var key = this.findWithAttr(this.state.user_placeholder, 'label', element);
            var ksetsender = this.findWithAttr(this.state.sender_placeholder, 'label', element);
            if (key !== -1) {
              let currentContent = editorState.getCurrentContent();
              let selection = editorState.getSelection();
              let entityKey = Entity.create('LINK', 'MUTABLE', { url: '#rec', value: element });
              let textWithEntity = Modifier.insertText(currentContent, selection, element, null, entityKey);
              editorState = EditorState.push(editorState, textWithEntity, 'insert-characters');
              currentContent = editorState.getCurrentContent();
              selection = editorState.getSelection();
              let entityKey1 = Entity.create('UNSTYLED', 'MUTABLE', " ");
              let textWithEntity1 = Modifier.insertText(currentContent, selection, " ", null, entityKey1);
              editorState = EditorState.push(editorState, textWithEntity1, 'insert-characters');
            } else if (ksetsender !== -1) {
              let currentContent = editorState.getCurrentContent();
              let selection = editorState.getSelection();
              let entityKey = Entity.create('LINK', 'MUTABLE', { url: '#send', value: element });
              let textWithEntity = Modifier.insertText(currentContent, selection, element, null, entityKey);
              editorState = EditorState.push(editorState, textWithEntity, 'insert-characters');
              currentContent = editorState.getCurrentContent();
              selection = editorState.getSelection();
              let entityKey1 = Entity.create('UNSTYLED', 'MUTABLE', " ");
              let textWithEntity1 = Modifier.insertText(currentContent, selection, " ", null, entityKey1);
              editorState = EditorState.push(editorState, textWithEntity1, 'insert-characters');
            } else {
              let currentContent = editorState.getCurrentContent();
              let selection = editorState.getSelection();
              let entityKey1 = Entity.create('LINK', 'MUTABLE', { url: '#send', value: element });
              let textWithEntity1 = Modifier.insertText(currentContent, selection, element, null, entityKey1);
              editorState = EditorState.push(editorState, textWithEntity1, 'insert-characters');
              currentContent = editorState.getCurrentContent();
              selection = editorState.getSelection();
              entityKey1 = Entity.create('UNSTYLED', 'MUTABLE', " ");
              textWithEntity1 = Modifier.insertText(currentContent, selection, " ", null, entityKey1);
              editorState = EditorState.push(editorState, textWithEntity1, 'insert-characters');
            }
          } else {
            let currentContent = editorState.getCurrentContent();
            let selection = editorState.getSelection();
            let entityKey1 = Entity.create('UNSTYLED', 'MUTABLE', element);
            let textWithEntity1 = Modifier.insertText(currentContent, selection, element, null, entityKey1);
            editorState = EditorState.push(editorState, textWithEntity1, 'insert-characters');
            currentContent = editorState.getCurrentContent();
            selection = editorState.getSelection();
            entityKey1 = Entity.create('UNSTYLED', 'MUTABLE', " ");
            textWithEntity1 = Modifier.insertText(currentContent, selection, " ", null, entityKey1);
            editorState = EditorState.push(editorState, textWithEntity1, 'insert-characters');
          }
        });
        selectedMessageBlocks[i].key = genKey();
        let currentContent = editorState.getCurrentContent();
        let selection = editorState.getSelection();
        let entityKey1 = Entity.create('UNSTYLED', 'MUTABLE', '\n');
        let textWithEntity1 = Modifier.insertText(currentContent, selection, '\n', null, entityKey1);
        editorState = EditorState.push(editorState, textWithEntity1, 'insert-characters');
      })
      this.props.thisData.onChange(editorState);
      //close message box

      //subject  with value 
      let selectedSubjectTemplate = JSON.parse(JSON.parse(emailTemplatelist[key].subject));
      let selectedSubjectBlocks = selectedSubjectTemplate.blocks;
      let editorSubjectState = this.props.thisData.state.subject;
      (selectedSubjectBlocks) && selectedSubjectBlocks.map((block) => {
        const arr = block.text.split(' ');
        arr.forEach(element => {
          if (element.indexOf('#') === 0) {
            var key = this.findWithAttr(this.state.user_placeholder, 'label', element);
            var ksetsender = this.findWithAttr(this.state.sender_placeholder, 'label', element);
            if (key !== -1) {
              let currentContent = editorSubjectState.getCurrentContent();
              let selection = editorSubjectState.getSelection();
              let entityKey = Entity.create('LINK', 'MUTABLE', { url: '#rec', value: element });
              let textWithEntity = Modifier.insertText(currentContent, selection, element, null, entityKey);
              editorSubjectState = EditorState.push(editorSubjectState, textWithEntity, 'insert-characters');
              currentContent = editorSubjectState.getCurrentContent();
              selection = editorSubjectState.getSelection();
              let entityKey1 = Entity.create('UNSTYLED', 'MUTABLE', " ");
              let textWithEntity1 = Modifier.insertText(currentContent, selection, " ", null, entityKey1);
              editorSubjectState = EditorState.push(editorSubjectState, textWithEntity1, 'insert-characters');
            } else if (ksetsender !== -1) {
              let currentContent = editorSubjectState.getCurrentContent();
              let selection = editorSubjectState.getSelection();
              let entityKey = Entity.create('LINK', 'MUTABLE', { url: '#send', value: element });
              let textWithEntity = Modifier.insertText(currentContent, selection, element, null, entityKey);
              editorSubjectState = EditorState.push(editorSubjectState, textWithEntity, 'insert-characters');
              currentContent = editorSubjectState.getCurrentContent();
              selection = editorSubjectState.getSelection();
              let entityKey1 = Entity.create('UNSTYLED', 'MUTABLE', " ");
              let textWithEntity1 = Modifier.insertText(currentContent, selection, " ", null, entityKey1);
              editorSubjectState = EditorState.push(editorSubjectState, textWithEntity1, 'insert-characters');
            } else {
              let currentContent = editorSubjectState.getCurrentContent();
              let selection = editorSubjectState.getSelection();
              let entityKey1 = Entity.create('LINK', 'MUTABLE', { url: '#send', value: element });
              let textWithEntity1 = Modifier.insertText(currentContent, selection, element, null, entityKey1);
              editorSubjectState = EditorState.push(editorSubjectState, textWithEntity1, 'insert-characters');
              currentContent = editorSubjectState.getCurrentContent();
              selection = editorSubjectState.getSelection();
              entityKey1 = Entity.create('UNSTYLED', 'MUTABLE', " ");
              textWithEntity1 = Modifier.insertText(currentContent, selection, " ", null, entityKey1);
              editorSubjectState = EditorState.push(editorSubjectState, textWithEntity1, 'insert-characters');
            }
          } else {
            let currentContent = editorSubjectState.getCurrentContent();
            let selection = editorSubjectState.getSelection();
            let entityKey1 = Entity.create('UNSTYLED', 'MUTABLE', element);
            let textWithEntity1 = Modifier.insertText(currentContent, selection, element, null, entityKey1);
            editorSubjectState = EditorState.push(editorSubjectState, textWithEntity1, 'insert-characters');
            currentContent = editorSubjectState.getCurrentContent();
            selection = editorSubjectState.getSelection();
            entityKey1 = Entity.create('UNSTYLED', 'MUTABLE', " ");
            textWithEntity1 = Modifier.insertText(currentContent, selection, " ", null, entityKey1);
            editorSubjectState = EditorState.push(editorSubjectState, textWithEntity1, 'insert-characters');
          }
        });
        this.props.thisData.handleChangeSubject(editorSubjectState);
      })
    }
    //close subject
  }
  togglesidbarCLick = () => {
    this.setState({ checked: !this.state.checked })
  }

  render() {
    let { checked, Template_list } = this.state;

    return (
      <>
        <div className="sidebar__menu">
          <Grid container spacing={1}  >
            {checked ?
              <>
                <Grid item className="ml-10">
                  <Select
                    name="selecttemplate"
                    id="selecttemplate"
                    label="Templates"
                    className="font-size-13"
                    value={this.state.selecttemplate}
                    disableUnderline
                    onChange={this.handleSelectTemplate}
                  >
                    <MenuItem value="none">Templates</MenuItem>
                    <ListSubheader style={{ color: 'rgba(0, 0, 0, 0.54)', fontWeight: '500', paddingTop: '10px', paddingBottom: '10px', backgroundColor: '#fff' }}>My Template</ListSubheader>
                    {Template_list.map((template) => (
                      (template.isshared == false) &&
                      <MenuItem className="" value={template.id}><span className="font-weight-500 font-size-14 ml-5 mr-5"> {template.name}</span></MenuItem>
                    ))}
                    <ListSubheader style={{ color: 'rgba(0, 0, 0, 0.54)', fontWeight: '500', paddingTop: '10px', paddingBottom: '10px', backgroundColor: '#fff' }}>Shared Template</ListSubheader>
                    {Template_list.map((template) => (
                      (template.isshared == true) &&
                      <MenuItem className="" value={template.id}><span className="font-weight-500 font-size-14 ml-5 mr-5"> {template.name}</span></MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item className="">
                  <Select
                    name="placeholder"
                    id="placeholder"
                    label="Merge Fields"
                    className="font-size-13"
                    value={this.state.placeholder}
                    disableUnderline
                    onChange={this.handleSelectPlaceholder}
                  >
                    <MenuItem value="none">Merge Fields</MenuItem>
                    <ListSubheader style={{ color: 'rgba(0, 0, 0, 0.54)', fontWeight: '500', paddingTop: '10px', paddingBottom: '10px', backgroundColor: '#fff' }}>Recipient Merge Fields</ListSubheader>

                    {
                      this.state.user_placeholder.map((val, i) => (
                        <MenuItem className="wrapper-rec" key={i} value={val.key}>
                          <span className="font-weight-500 font-size-14 ml-5 mr-5 crm-rec-hashplaceholder" >
                            {val.label} </span>
                        </MenuItem>
                      ))}

                    <ListSubheader style={{ color: 'rgba(0, 0, 0, 0.54)', fontWeight: '500', paddingTop: '10px', paddingBottom: '10px', backgroundColor: '#fff' }}>Sender Merge Fields</ListSubheader>
                    {
                      this.state.sender_placeholder.map((val, i) => (
                        <MenuItem className="wrapper-send" key={i} value={val.key}>
                          <span className="font-weight-500 font-size-14 ml-5 mr-5 crm-send-hashplaceholder">
                            {val.label}</span>
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
                <Grid item>
                  <IconButton className="p-0 mt-8 mr-4" onClick={this.togglesidbarCLick}><IconSmall >clear</IconSmall></IconButton>
                </Grid>
              </>

              :
              <Grid item>
                <IconButton className="p-0" style={{ marginTop: '-1px' }} onClick={this.togglesidbarCLick}><IconSmall >add</IconSmall></IconButton>
                {/* <IconButton className="toolbar__button mr-0" style={{ fontSize: '12px', backgroundColor: '#fff', color: '#3192e7', width: '13px' }} onClick={this.togglesidbarCLick}><IconSmall >add</IconSmall></IconButton> */}
              </Grid>
            }

          </Grid>
        </div>
        {/* <div className="sidebar__menu">
          <FontAwesome onClick={this.togglesidbarCLick} className={"fa fa-plus-circle toolbar__button"} style={{ fontSize: '30px', backgroundColor:'#fff' }} />
        </div> */}

      </>
    );
  }
}


const entityInputs = {
  LINK: LinkInput,
}



class CreateTemplate extends Component {
  constructor(props) {
    super(props);


    // Here's the content you stored in the base
    // const myContent = load_from_db();
    const myContent = {
      "entityMap": {},
      "blocks": [
        {
          "key": genKey(),
          "text": "",
          "type": "unstyled",
          "depth": 0,
          "inlineStyleRanges": [],
          "entityRanges": [],
          "data": {}
        }
      ]
    };
    const mySubject = {
      "entityMap": {},
      "blocks": [
        {
          "key": genKey(),
          "text": "",
          "type": "unstyled",
          "depth": 0,
          "inlineStyleRanges": [],
          "entityRanges": [],
          "data": {}
        }
      ]
    };
    const editorState = editorStateFromRaw(myContent);
    messageeditstate = editorState;
    const subjectEditorState = editorStateFromRaw(mySubject);
    let name = '';
    this.state = {
      isshared: false,
      editorState: editorState,
      subject: subjectEditorState,
      name: name,
      isSubmitDisable: !(name.length > 0 && subjectEditorState.getCurrentContent().hasText() && editorState.getCurrentContent().hasText()),
    };
  }

  onChange = (editorState) => {
    messageeditstate = editorState;
    this.setState({ editorState });
    this.setState({ isSubmitDisable: !(this.state.name.length > 0 && this.state.subject.getCurrentContent().hasText() && editorState.getCurrentContent().hasText()) })
  }

  handleChangeSubject = (subject) => {
    this.setState({ subject });
    this.setState({ isSubmitDisable: !(this.state.name.length > 0 && this.state.editorState.getCurrentContent().hasText() && subject.getCurrentContent().hasText()) })
  }

  getCustomSidebar = () => {
    return <CustomSidebar thisData={this} editorType="Message" />
  }
  getCustomSubjectSidebar = () => {
    return <CustomSidebar thisData={this} editorType="Subject" />
  }

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  };



  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleFormSubmit = () => {
    const { editorState } = this.state;

    const content = editorStateToJSON(editorState);
    let string = JSON.stringify(content);
    const contentState = editorState.getCurrentContent();
    let html = stateToHTML(contentState);

    const subjectContent = JSON.stringify(editorStateToJSON(this.state.subject));
    const contentSubject = this.state.subject.getCurrentContent();
    let subjecthtml = stateToHTML(contentSubject);


    let saveEmailParams = {
      isshared: this.props.isshared,
      message: string,
      subject: subjectContent,
      name: this.state.name,
      messagehtml: html,
      subjecthtml: subjecthtml,
    };

    this.props.saveEmailTemplate(this.props.apolloClient.client, saveEmailParams);
    this.props.handleClose();
  }

  render() {
    let { open, handleClose, handleMinimize, handleFull, fullScreen, minimizeScreen } = this.props;
    let onChangeFunction = this.onChange;
    return (
      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth="md"
        fullWidth={true}
        className={`crm-custom-bg-primary custom_dialog ${minimizeScreen && fullScreen ? "alignitemsend-fullscreen" : ""
          } ${minimizeScreen ? "alignitemsend" : ""} ${fullScreen ? "fullscreen" : ""
          } crm_dialog_create_template`}
        disableBackdropClick
      // disableEscapeKeyDown
      >
        <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">
          <h4 className="m-0 text-white">Create Email Template</h4>
          <div className="d-flex">
            <TooltipNew
              title={minimizeScreen ? "Normal Screen" : "Minimize Screen"}
            >
              <IconButton onClick={handleMinimize}>
                <Icon className="text-white">
                  {minimizeScreen ? "add" : "remove"}
                </Icon>
              </IconButton>
            </TooltipNew>
            <TooltipNew title={fullScreen ? "Exit Full Screen" : "Full Screen"}>
              <IconButton onClick={handleFull}>
                <Icon className="text-white">
                  {fullScreen ? "fullscreen_exit" : "fullscreen"}
                </Icon>
              </IconButton>
            </TooltipNew>
            <TooltipNew title="Close">
              <IconButton onClick={handleClose}>
                <Icon className="text-white">clear</Icon>
              </IconButton>
            </TooltipNew>
          </div>
        </div>

        <ValidatorForm noValidate instantValidate={false} onSubmit={this.handleFormSubmit}>
          <div className={`${this.state.isDataLoading ? "dialog-body popupHeightCustom" : "dialog-body"} `} style={{ overflow: "visible" }}>
            <Grid container spacing={2}>

              <Grid item xs={12}>
                <label className="font-weight-500" style={{ fontSize: '12px', color: '#696e6f' }}>Template Name</label>
                <TextValidator
                  type="text"
                  className="w-100 input_bx"
                  onChange={(event) => this.handleChange(event)}
                  name={"name"}
                  placeholder="Add Template Name"
                  value={this.state.name}
                />
              </Grid>
              <Grid item xs={12} className="subject-editor">
                <label className="font-weight-500" style={{ fontSize: '12px', color: '#696e6f' }}>Template Content</label>
                <MegadraftEditor
                  placeholder='Add Subject'
                  theme="white-thin"
                  editorState={this.state.subject}
                  onChange={this.handleChangeSubject}
                  actions={[]}
                  sidebarRendererFn={this.getCustomSubjectSidebar}
                />
              </Grid>
              <Grid item xs={12} className="message-editor">
                <MegadraftEditor
                  placeholder='Add Message'
                  theme="white-thin"
                  style={{ fontSize: '12px', color: '#696e6f' }}
                  editorState={this.state.editorState}
                  onChange={this.onChange}
                  actions={[
                    { type: "inline", label: "B", style: "BOLD", icon: icons.BoldIcon },
                    { type: "inline", label: "I", style: "ITALIC", icon: icons.ItalicIcon },
                    { type: "inline", label: "U", style: "UNDERLINE", icon: UnderlineIcon },
                    {
                      type: "custom", icon: ClearFormattingIcon, action() {
                        let options = {
                          inline: true,
                          entities: true,
                          lists: true,
                        }
                        const newEditorState = clearFormatting(messageeditstate, options)
                        messageeditstate = newEditorState;
                        onChangeFunction(newEditorState);
                      }, active() { }
                    },
                    { type: "separator" },
                    { type: "block", label: "UL", style: "unordered-list-item", icon: icons.ULIcon },
                    { type: "block", label: "OL", style: "ordered-list-item", icon: icons.OLIcon },
                    { type: "separator" },
                    { type: "entity", label: "Link", style: "link", entity: "LINK", icon: icons.LinkIcon },
                  ]}
                  entityInputs={entityInputs}
                  sidebarRendererFn={this.getCustomSidebar}
                />
              </Grid>
            </Grid>
          </div>
          <DialogActions className="dialog-action">
            <Button
              style={{
                width: '72px',
                backgroundColor: '#e0e0e0'
              }} color={"default"} onClick={handleClose}>Cancel</Button>
            <Button
              disabled={this.state.isSubmitDisable}
              variant="contained"
              type="submit" className={"btn_Primary"}
              color={this.state.isSubmitDisable ? "default" : "primary"}
            >
              Save
           </Button>
          </DialogActions>
        </ValidatorForm>

      </Dialog>
    );
  }
}
const mapStateToProps = (state) => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  leadReducer: state.leadReducer,
  contactReducer: state.contactReducer,
});
const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyRecList: (client) => { dispatch(actionCreators.getCompanyRecList(client)); },
    getContactType: (client) => dispatch(actionCreators.getContactType(client)),
    getUserList: (client) => dispatch(actionCreators.getUserList(client)),
    saveEmailTemplate: (client, request) => { dispatch(actionCreators.saveEmailTemplate(client, request)); },
    getCompanyCurrency: (client) => dispatch(actionCreators.getCompanyCurrency(client)),
    getPipelines: (client) => dispatch(actionCreators.getPipelines(client)),

    // getLeadField: (client, leadId) => dispatch(actionCreators.getLeadField(client, leadId)),
    // loadLeadStatues: (client) => dispatch(actionCreators.loadLeadStatues(client)),
    // AddLeadStatus: (c, req) => dispatch(actionCreators.AddLeadStatus(c, req)),
    // updateLeadStatusRecord: (c, req) => dispatch(actionCreators.updateLeadStatusRecord(c, req)),
    // clearNotification: () => dispatch(actionCreators.clearNotification()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateTemplate);