import React, { Component } from "react";
import {
  Fab,
  Dialog,
  IconButton,
  Button,
  Icon,
  Tooltip,
  Grid,
  FormControl,
  Select,
  InputLabel,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  DialogActions,
  Chip,
  TextField,
} from "@material-ui/core";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";

import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import DeleteIcon from '@material-ui/icons/Delete';

import moment from "moment";

// draft js imports
import { MegadraftEditor, editorStateFromRaw, editorStateToJSON, MegadraftPlugin, insertDataBlock } from "megadraft-denistsuman";
import { convertFromHTML, EditorState, ContentState, Modifier, Entity, genKey, ContentBlock } from 'draft-js';
import LinkInput from "megadraft-denistsuman/lib/entity_inputs/LinkInput";
import icons from "megadraft-denistsuman/lib/icons"
import 'megadraft-denistsuman/dist/css/megadraft.css'
import { stateToHTML } from 'draft-js-export-html';
import { Map } from 'immutable';
import clearFormatting from 'draft-js-clear-formatting';


export const WorkflowDynamicFields = (data, morefieldindex,  actionindex, otherfieldsArray, requiredFields, selectedFields, conditionfields) => {
    
    
    let conditions = [];
    let indexOfCondition = actionindex;
    let indexoffield = morefieldindex;

    
    var createdFields = [];
    createdFields[indexOfCondition] = [];

    let submitFieldData = data.state.submitFieldData;
    let requiredTotal = requiredFields.length;
    var elements = otherfieldsArray[indexOfCondition];
    
    let field = selectedFields[indexOfCondition][indexoffield+requiredTotal];
    if(field && field.id){

      if (parseFloat(field.fieldtype.id) == 1 || parseFloat(field.fieldtype.id) == 2) {
        //   let names = {};
        //   if(submitFieldData && submitFieldData.actiondetail && submitFieldData.actiondetail[indexOfCondition] && submitFieldData.actiondetail[indexOfCondition].details && submitFieldData.actiondetail[indexOfCondition].details[indexoffield] && submitFieldData.actiondetail[indexOfCondition].details[indexoffield].names){
        //     names = submitFieldData.actiondetail[indexOfCondition].details[indexoffield].names;
        //   }

        //   if (field.fieldkey == 'name' ) {
        //     let fieldtemp = field;
        //     let indexoffield = morefieldindex;

        //     if (parseFloat(field.id) == 1 || parseFloat(field.id) == 16){
        //       createdFields[indexOfCondition].push(
        //         <Grid item xs={11}>
        //           <Grid container spacing={2}>
        //             <Grid item xs={12}>
        //               <TextValidator
        //                 ref={ref => field = ref}
        //                 type="text"
        //                 id={field.id}
        //                 placeholder={"Add firstname"}
        //                 className="w-100 input_bx"
        //                 onChange={(event, field, index, indexoffield, requiredTotal) => data.actionConditionValueChange(event, fieldtemp, indexOfCondition, indexoffield, requiredTotal)}
        //                 name={"firstname"}
        //                 value={names.firstname ? names.firstname : ""}
        //                 validators={["required"]}
        //                 errorMessages={["This field is required"]}
        //               />
        //               <TextValidator
        //                 ref={ref => field = ref}
        //                 type="text"
        //                 id={field.id}
        //                 placeholder={"Add lastname"}
        //                 className="w-100 input_bx"
        //                 onChange={(event, field, index, indexoffield, requiredTotal) => data.actionConditionValueChange(event, fieldtemp, indexOfCondition, indexoffield, requiredTotal)}
        //                 name={"lastname"}
        //                 value={names.lastname ? names.lastname : ""}
        //                 validators={["required"]}
        //                 errorMessages={["This field is required"]}
        //               />
        //             </Grid>
        //           </Grid>
        //         </Grid>
        //       )
        //     }else{
        //       let name = "";
        //       let indexoffield = morefieldindex;
        //       if(submitFieldData && submitFieldData.actiondetail && submitFieldData.actiondetail[indexOfCondition] && submitFieldData.actiondetail[indexOfCondition].details && submitFieldData.actiondetail[indexOfCondition].details[indexoffield]){
        //         name = submitFieldData.actiondetail[indexOfCondition].details[indexoffield].fieldvalue;
        //       }
              
        //       createdFields[indexOfCondition].push(
        //         <Grid item xs={11}>
        //           <Grid container spacing={2}>
        //             <Grid item xs={12}>
        //                 <TextValidator
        //                   ref={ref => field = ref}
        //                   type="text"
        //                   id={field.id}
        //                   placeholder={"Add " + field.fieldkey}
        //                   className="w-100 input_bx"
        //                   onChange={(event, field, index, indexoffield, requiredTotal) => data.actionConditionValueChange(event, fieldtemp, indexOfCondition, indexoffield, requiredTotal)}
        //                   name={field.fieldkey}
        //                   value={name}
        //                   validators={["required"]}
        //                   errorMessages={["This field is required"]}
        //                 />
        //             </Grid>
        //           </Grid>
        //         </Grid>
        //       )
        //     }
        //   } else 
        if (field.fieldkey == 'owner') {

          let fieldtemp = field;
          
          let fieldVal = -1;
          if(submitFieldData && submitFieldData.actiondetail && submitFieldData.actiondetail[indexOfCondition] && submitFieldData.actiondetail[indexOfCondition].details && submitFieldData.actiondetail[indexOfCondition].details[indexoffield+requiredTotal]){
            fieldVal = submitFieldData.actiondetail[indexOfCondition].details[indexoffield+requiredTotal].values; 
          }

          createdFields[indexOfCondition].push(
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <FormControl className="w-100 input_bx">
                    <Autocomplete
                      disableOpenOnFocus
                      forcePopupIcon={false}
                      name={fieldtemp.fieldkey}
                      options={data.state.usersListArray}
                      getOptionLabel={option => option.name}
                      value={fieldVal != -1 ? fieldVal : ''}
                      name={fieldtemp.fieldkey}
                      // onChange={(value, field, fieldkey, index) => this.ActionOwnerChange(value, fieldtemp, fieldtemp.fieldkey, indexOfCondition)}
                      onChange={(event, value, field, index, indexoffield, requiredTotal) => data.ActionDynamicOwnerChange(event, value, fieldtemp, indexOfCondition, morefieldindex, requiredFields.length)}
                      id={field.id}
                      renderInput={params => (
                        <TextValidator
                          {...params}
                          variant="standard"
                          label={"Owner"}
                          fullWidth
                          ref={ref => field = ref}
                          name={fieldtemp.fieldkey}
                          value={fieldVal != -1 ? fieldVal : ''}
                          required={true}
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>    
          )
        } else if(field.fieldkey == "primarycontact" && field.options && field.options.length > 0){
          let fieldtemp = field;
          let fieldVal = "";
          if(submitFieldData && submitFieldData.actiondetail && submitFieldData.actiondetail[indexOfCondition] && submitFieldData.actiondetail[indexOfCondition].details && submitFieldData.actiondetail[indexOfCondition].details[indexoffield+requiredTotal] && submitFieldData.actiondetail[indexOfCondition].details[indexoffield+requiredTotal].values){
            fieldVal = submitFieldData.actiondetail[indexOfCondition].details[indexoffield+requiredTotal].values.id; 
          }

          createdFields[indexOfCondition].push(
            <Grid container spacing={2}>
              <Grid item md={12}>
                <FormControl className="w-100 input_bx">
                  <SelectValidator
                    ref={ref => field = ref}
                    label={field.title}
                    value={fieldVal}
                    onChange={(event, field, index, indexoffield, requiredTotal) => data.ActionDynamicDropChange(event, fieldtemp, indexOfCondition, morefieldindex, requiredFields.length)}
                    // onChange={(event, field, index) => this.handleDropChange(event, fieldtemp, indexOfCondition)}
                    name={field.fieldkey}
                    id={field.id}
                    required={true}
                    validators={["required"]}
                    errorMessages={["This field is required"]}
                  >

                    {field.options && field.options.map((item, key) =>
                      <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item.id}>{item.name}</MenuItem>
                    )}
                  </SelectValidator>
                </FormControl>
              </Grid>
            </Grid>
          )
        } else if(field.fieldkey == "company" && data.state.actionModule[indexOfCondition] == 4 && field.options && field.options.length > 0){
          let fieldtemp = field;
          let fieldVal = "";
          if(submitFieldData && submitFieldData.actiondetail && submitFieldData.actiondetail[indexOfCondition] && submitFieldData.actiondetail[indexOfCondition].details && submitFieldData.actiondetail[indexOfCondition].details[indexoffield+requiredTotal] && submitFieldData.actiondetail[indexOfCondition].details[indexoffield+requiredTotal].values){
            fieldVal = submitFieldData.actiondetail[indexOfCondition].details[indexoffield+requiredTotal].values.id; 
          }

          createdFields[indexOfCondition].push(
            <Grid container spacing={2}>
              <Grid item md={12}>
                <FormControl className="w-100 input_bx">
                  <SelectValidator
                    ref={ref => field = ref}
                    label={field.title}
                    value={fieldVal}
                    onChange={(event, field, index, indexoffield, requiredTotal) => data.ActionDynamicDropChange(event, fieldtemp, indexOfCondition, morefieldindex, requiredFields.length)}
                    // onChange={(event, field, index) => this.handleDropChange(event, fieldtemp, indexOfCondition)}
                    name={field.fieldkey}
                    id={field.id}
                    required={true}
                    validators={["required"]}
                    errorMessages={["This field is required"]}
                  >

                    {field.options && field.options.map((item, key) =>
                      <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item.id}>{item.name}</MenuItem>
                    )}
                  </SelectValidator>
                </FormControl>
              </Grid>
            </Grid>
          )
        } else if (field.fieldkey == 'value' || field.fieldkey == 'winpercentage') { // value // winpercentage

          let fieldtemp = field;
          let fieldvalue = "";
          if(submitFieldData && submitFieldData.actiondetail && submitFieldData.actiondetail[indexOfCondition] && submitFieldData.actiondetail[indexOfCondition].details && submitFieldData.actiondetail[indexOfCondition].details[indexoffield+requiredTotal]){
            fieldvalue = submitFieldData.actiondetail[indexOfCondition].details[indexoffield+requiredTotal].fieldvalue; 
          }

          createdFields[indexOfCondition].push(
            <Grid container spacing={2}>
              <Grid item md={12}>
                <TextValidator
                  ref={ref => field = ref}
                  type="number"
                  required={true}
                  id={field.id}
                  className="w-100 input_bx"
                  label={field.fieldlabel}
                  // onChange={(event, field, index) => this.handleNumberChange(event, fieldtemp, indexOfCondition)}
                  onChange={(event, field, index, indexoffield, requiredTotal) => data.actionDynamicNumberChange(event, fieldtemp, indexOfCondition, morefieldindex, requiredFields.length)}
                  name={field.fieldkey}
                  value={fieldvalue}
                  validators={["required"]}
                  errorMessages={["This field is required"]}
                />
              </Grid>
            </Grid>
          )
        } 
        else {
          // let fieldtemp = field;
          // let fieldvalue = "";
          // let token = [];
          // if(submitFieldData && submitFieldData.actiondetail && submitFieldData.actiondetail[indexOfCondition] && submitFieldData.actiondetail[indexOfCondition].details && submitFieldData.actiondetail[indexOfCondition].details[indexoffield+requiredTotal]){
          //   fieldvalue = submitFieldData.actiondetail[indexOfCondition].details[indexoffield+requiredTotal].fieldvalue; 
            
          //   if(submitFieldData.actiondetail[indexOfCondition].details[indexoffield+requiredTotal].tokentag){
          //     token = submitFieldData.actiondetail[indexOfCondition].details[indexoffield+requiredTotal].tokentag;
          //   }
          // }
          let fieldtemp = field;
            
          let fielddata = data.state.token;
          if(data.state.alldraftFieldData && data.state.alldraftFieldData[indexOfCondition] && data.state.alldraftFieldData[indexOfCondition][indexoffield+requiredTotal]){
            // console.log(data.state.alldraftFieldData[indexOfCondition][indexoffield+requiredTotal],'data.state.alldraftFieldData[indexOfCondition][indexoffield+requiredTotal]');
            fielddata = data.state.alldraftFieldData[indexOfCondition][indexoffield+requiredTotal].fieldvalue;
          }else{
            fielddata = data.tokenIdGenerator(indexOfCondition, indexoffield, requiredTotal, fieldtemp);
          }
                    
          createdFields[indexOfCondition].push(
            <Grid container spacing={2}>
              <Grid item xs={12} className="subject-editor workflow_editor">
                {/* <TextValidator
                  ref={ref => field = ref}
                  type="text"
                  label={field.fieldlabel}
                  id={field.id}
                  placeholder={"Add " + field.fieldlabel}
                  className="w-100 input_bx"
                  onChange={(event, field, index, indexoffield, requiredTotal) => data.actionDynamicValueChange(event, fieldtemp, indexOfCondition, morefieldindex, requiredFields.length)}
                  name={field.fieldkey}
                  value={fieldvalue}
                  validators={["required"]}
                  errorMessages={["This field is required"]}
                /> */}
                <MegadraftEditor
                  data-fieldindex={indexOfCondition}
                  placeholder={"Add " + field.fieldlabel.charAt(0).toUpperCase() + field.fieldlabel.slice(1)}
                  theme="white-thin"
                  editorState={fielddata}
                  onChange={data.handleChangeToken}
                  actions={[]}
                  sidebarRendererFn={(indexc, fieldind)=>data.getCustomTokenSidebar(indexOfCondition, indexoffield+requiredTotal,'normal')}
                />
              </Grid>
              {/* <Grid item xs={12}>
                  <Autocomplete
                    disableOpenOnFocus
                    multiple
                    value={token}
                    filterSelectedOptions
                    name={"token"}
                    id="tagInputId1"
                    options={conditionfields.map(
                      (option) => option.fieldkey
                    )}
                    freeSolo
                    onChange={(event, value, field, index, indexoffield, requiredTotal) => data.ActionDynamicTokenChange(event, value, fieldtemp, indexOfCondition, morefieldindex, requiredFields.length)}
                    renderTags={(token, getTagProps) =>
                      token.map((option, index) => (
                        <Chip
                          label={option.charAt(0).toUpperCase() + option.slice(1)}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextValidator
                        ref={(ref) => (field = ref)}
                        // onBlur={(event) => data.handleBlur(event, v.fieldkey)}
                        {...params}
                        label="Token"
                        placeholder="Add Token"
                        name={"token"}
                        value={token && token.length ? 1 : null}
                        className="w-100 input_bx"                              
                        onFocus={() =>
                          setTimeout(() => {
                            document.getElementById("tagInputId1").value = "";
                          }, 0)
                        }
                      />
                    )}
                  />
                </Grid> */}
            </Grid>
          )
        }
      } 
      else if (parseFloat(field.fieldtype.id) == 8) { // Date
        let fieldtemp = field;
        var dtFormat = "MM/dd/yyyy";
        let dtFormat1 = "MM/DD/YYYY";
        let fieldVal = -1;
        
        if (data.state.userSettings && data.state.userSettings.dateformat) {
          dtFormat1 = data.state.userSettings.dateformat;
          dtFormat = dtFormat.replace("DD", "dd").replace("YYYY", "yyyy");
        }
        
        var selectedDate = moment().format(dtFormat1);
  
        if(submitFieldData && submitFieldData.actiondetail && submitFieldData.actiondetail[indexOfCondition] && submitFieldData.actiondetail[indexOfCondition].details && submitFieldData.actiondetail[indexOfCondition].details[indexoffield+requiredTotal] && submitFieldData.actiondetail[indexOfCondition].details[indexoffield+requiredTotal].fieldvalue){
          selectedDate = submitFieldData.actiondetail[indexOfCondition].details[indexoffield+requiredTotal].fieldvalue; 
        }
      
        
        createdFields[indexOfCondition].push(
          <Grid container spacing={2}>
            <Grid item md={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <FormControl className="w-100 input_bx">
                  <KeyboardDatePicker
                    label={field.fieldlabel}
                    format={dtFormat}
                    required={true}
                    placeholder={dtFormat}
                    error={(selectedDate == null || selectedDate == "Invalid Date") ? true : false}
                    value={selectedDate}
                    onChange={(event, field, index, indexoffield, requiredTotal) => data.ActionDynamicDateChange(event == "Invalid Date" ? "Invalid Date" : event, fieldtemp, indexOfCondition, morefieldindex, requiredFields.length)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    helperText={selectedDate == null ? "This field is required" : (selectedDate == "Invalid Date") ? "Please enter a valid date." : ""}
                  />
                </FormControl>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        )
      } 
      else if (parseFloat(field.fieldtype.id) == 4 || parseFloat(field.fieldtype.id) == 5) { // drop-down
        let fieldtemp = field;
        let fieldVal = "";
        if(submitFieldData && submitFieldData.actiondetail && submitFieldData.actiondetail[indexOfCondition] && submitFieldData.actiondetail[indexOfCondition].details && submitFieldData.actiondetail[indexOfCondition].details[indexoffield+requiredTotal] && submitFieldData.actiondetail[indexOfCondition].details[indexoffield+requiredTotal].values){
          fieldVal = submitFieldData.actiondetail[indexOfCondition].details[indexoffield+requiredTotal].values.id; 
        }

        if (field.fieldkey == 'company' || field.fieldkey == 'status' || field.fieldkey == 'source' || field.fieldkey == 'contacttype' || field.fieldkey == 'pipeline' || field.fieldkey == 'stage' || field.fieldkey == 'priority' || field.fieldkey == 'lossreason' || field.fieldkey == 'activitytype') {
          
          createdFields[indexOfCondition].push(
            <Grid container spacing={2}>
              <Grid item md={12}>
                <FormControl className="w-100 input_bx">
                  <SelectValidator
                    ref={ref => field = ref}
                    label={field.title}
                    value={fieldVal}
                    onChange={(event, field, index, indexoffield, requiredTotal) => data.ActionDynamicDropChange(event, fieldtemp, indexOfCondition, morefieldindex, requiredFields.length)}
                    // onChange={(event, field, index) => this.handleDropChange(event, fieldtemp, indexOfCondition)}
                    name={field.fieldkey}
                    id={field.id}
                    required={true}
                    validators={["required"]}
                    errorMessages={["This field is required"]}
                  >

                    {
                      (field.fieldkey == 'stage') ?
                        field.options && field.options.map((item, key) =>
                          <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item.id}>{item.name} ({item.pipeline_name})</MenuItem>
                        )
                      :
                        field.options && field.options.map((item, key) =>
                          <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item.id}>{item.name}</MenuItem>
                        )
                    }
                  </SelectValidator>
                </FormControl>
              </Grid>
            </Grid>
          )
        } else {
          let fieldtemp = field;
          createdFields[indexOfCondition].push(
            <Grid container spacing={2}>
              <Grid item md={12}>
                <FormControl className="w-100 input_bx">
                  <SelectValidator
                    ref={ref => field = ref}
                    label={field.title}
                    value={fieldVal}
                    onChange={(event, field, index, indexoffield, requiredTotal) => data.ActionDynamicDropChange(event, fieldtemp, indexOfCondition, morefieldindex, requiredFields.length)}
                    // onChange={(event, field, index) => this.handleDropChange(event, fieldtemp, indexOfCondition)}
                    name={field.fieldkey}
                    id={field.id}
                    required={true}
                    validators={["required"]}
                    errorMessages={["This field is required"]}
                  >
                    {field.options && field.options.map((item, key) =>
                      <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item.id}>{item.optionvalue}</MenuItem>
                    )}
                  </SelectValidator>
                </FormControl>
              </Grid>
            </Grid>
          )
        }
      } 
      else if (parseFloat(field.fieldtype.id) == 6 || parseFloat(field.fieldtype.id) == 7 || parseFloat(field.fieldtype.id) == 10) { // number
        let fieldtemp = field;   
        let fieldvalue = "";
        if(submitFieldData && submitFieldData.actiondetail && submitFieldData.actiondetail[indexOfCondition] && submitFieldData.actiondetail[indexOfCondition].details && submitFieldData.actiondetail[indexOfCondition].details[indexoffield+requiredTotal]){
          fieldvalue = submitFieldData.actiondetail[indexOfCondition].details[indexoffield+requiredTotal].fieldvalue; 
        }

        createdFields[indexOfCondition].push(
          <Grid container spacing={2}>
            
            <Grid item md={12}>
              <TextValidator
                ref={ref => field = ref}
                type="number"
                required={true}
                id={field.id}
                className="w-100 input_bx"
                label={field.fieldlabel}
                onChange={(event, field, index, indexoffield, requiredTotal) => data.actionDynamicNumberChange(event, fieldtemp, indexOfCondition, morefieldindex, requiredFields.length)}
                name={field.fieldkey}
                value={fieldvalue}
                validators={["required"]}
                errorMessages={["This field is required"]}
              />
            </Grid>
          </Grid>
        )
      }

  }

  let chooseActionField = "";
  if(submitFieldData && submitFieldData.actiondetail && submitFieldData.actiondetail[indexOfCondition] && submitFieldData.actiondetail[indexOfCondition].details && submitFieldData.actiondetail[indexOfCondition].details[indexoffield+requiredTotal]) {
    chooseActionField = submitFieldData.actiondetail[indexOfCondition].details[indexoffield+requiredTotal].fieldid
  }
  conditions.push(
    <Grid container spacing={2} alignItems="center" className="mt-16 add_more_field" key={indexoffield}>
      <Grid item xs={11}>
        <Grid container spacing={2} alignItems="center" className="mt-16 add_more_field" key={indexoffield}>
          <Grid item md={4}>
            <FormControl className="w-100 input_bx">
                <Select
                    name="chooseActionField"
                    value={chooseActionField}
                    onChange={(event, actionindex, requiredTotal, indexoffield )=>data.selectActionField(event, indexOfCondition, requiredFields.length, morefieldindex)}
                    MenuProps={{ className: 'choose_field_menu' }}
                >
                    {elements && elements.map((option) => (
                      <MenuItem className="select-menu-item" value={option.id}>{option.fieldlabel}</MenuItem>
                    ))}
                </Select>
            </FormControl>
          </Grid>
          <Grid item md={8}>
            {createdFields[indexOfCondition]}
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={1} xs={3}>
        <IconButton size="small" onClick={(event, actionindex, requiredTotal, indexoffield ) => data.deleteMoreFields(event, indexOfCondition, requiredFields.length, morefieldindex)}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
  return conditions;
};
