import React, { Component } from "react";
import { Dialog, IconButton, Button, Icon, Tooltip, Grid, FormControl, Select, InputLabel, Menu, MenuItem, List, ListItem, ListItemText, DialogActions, Chip, TextField } from "@material-ui/core";
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';

import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';
import CommonDisbledLock from "../Layout/CommonDisbledLock"
import CommonVisibiltyEdit from "../Layout/CommonVisibiltyEdit"


export const CreateFieldsDynamicEdit = (data) => {
  let disabledAll = !data.state.crudPermission.isedit

  var elements = data.state.fieldsList
  var fieldsArray = [];
  const relatedOptions = data.state.relatedListArray;
  const options = relatedOptions.map((option) => {
    const firstLetter = option.type;
    return {
      firstLetter: firstLetter,
      ...option,
    };
  });
  elements.map((v, i) => {
    if (v.fieldstatus === true) {
      if (v.fieldtype.id == '1' && v.id == '67') {//name
        fieldsArray.push(<><Grid item xs={12}>
          {/* <InputLabel>Project Name{(v.recordeditdefaultvalue == 3 || disabledAll) && <CommonDisbledLock />}</InputLabel> */}
          <TextValidator
            type="text"
            id={v.id}
            disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
            className="w-100 input_bx"
            placeholder={"Add Name"}
            required={(disabledAll) ? false : (v.recordcreationdefaultvalue == 2) ? true : false}
            label={<>Project Name{(v.recordeditdefaultvalue == 3 || disabledAll) && <CommonDisbledLock />}</>}
            onChange={(event) => data.handleChange({ fieldid: v.id, projectvalue: event.target.value }, v.fieldkey)}
            ref={ref => data[v.fieldkey] = ref}
            onBlur={(event) => data.focusOutSubmitForm(event, v.id, v.fieldkey)}
            name={v.fieldkey}
            value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].projectvalue : ""}
            validators={(v.recordeditdefaultvalue == 2) ? ["required"] : []}
            errorMessages={(v.recordeditdefaultvalue == 2) ? ["This field is required"] : []}
            InputLabelProps={{ shrink: true, disableAnimation: false, focused: true }}
          />
        </Grid></>
        )
      } else if (v.fieldtype.id == '1' && v.id == '68') { // related field
        var relatedVal = [];
        if (data.state.fieldsObj[v.fieldkey]) {
          relatedVal = data.state.fieldsObj[v.fieldkey].values
        }
        fieldsArray.push(<Grid item xs={12}>
          {/* <FormControl className="w-100 input_bx"> */}
          <InputLabel>{v.fieldlabel}{(v.recordeditdefaultvalue == 3 || disabledAll) && <CommonDisbledLock />}</InputLabel>
          <Autocomplete
            disableOpenOnFocus
            name={v.fieldkey}
            options={options}
            groupBy={(option) =>option && option.firstLetter && option.firstLetter.toUpperCase()}
            getOptionLabel={(option) => option.name ? option.name : "" } 
            value={relatedVal}
            disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
            name={v.fieldkey}
            onChange={(event, value) => data.handleChange({ fieldid: v.id, values: value }, v.fieldkey, 'allowSubmitChange')}
            id={v.id}
            renderInput={params => (
              <TextValidator
                ref={ref => data[v.fieldkey] = ref}
                onBlur={(event) => data.focusOutSubmitForm(event, v.id, v.fieldkey)}
                {...params}
                variant="standard"
                // label={v.fieldlabel}
                className="w-100 input_bx"
                fullWidth
                name={v.fieldkey}
                placeholder="Add Relation"
                value={relatedVal && relatedVal.length ? 1 : null}
                required={(v.recordcreationdefaultvalue == 2) ? true : false}
                validators={(v.recordcreationdefaultvalue == 2 && (relatedVal == null || relatedVal.length == 0)) ? ["required"] : []}
                errorMessages={(v.recordcreationdefaultvalue == 2 && (relatedVal == null || relatedVal.length == 0)) ? ["This field is required"] : []}
              />
            )}
          />
          {/* </FormControl> */}
        </Grid >)
      } else if (v.fieldtype.id == '1' && v.fieldkey == 'tags') {
        var valTag = [];
        if (data.state.fieldsObj[v.fieldkey]) {
          valTag = data.state.fieldsObj[v.fieldkey].values
        }
        fieldsArray.push(<Grid item xs={12}>
          <InputLabel>Tags{(v.recordeditdefaultvalue == 3 || disabledAll) && <CommonDisbledLock />}</InputLabel>
          <Autocomplete
            disableOpenOnFocus
            multiple
            value={valTag}
            filterSelectedOptions
            disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
            name={v.fieldkey}
            id="tagInputId"
            options={data.state.systemTagsList.map(option => option.tagname)}
            freeSolo
            onChange={(event, value) => data.handleChange({ fieldid: v.id, values: value }, v.fieldkey, 'allowSubmitChange')}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip label={option.charAt(0).toUpperCase() + option.slice(1)} {...getTagProps({ index })} />
              ))
            }
            renderInput={params => (
              <TextValidator
                {...params}
                // label="Tags"
                placeholder="Add Tag"
                className="w-100 input_bx"
                ref={ref => data[v.fieldkey] = ref}
                onBlur={(event) => data.focusOutSubmitForm(event, v.id, v.fieldkey)}
                onFocus={() =>
                  setTimeout(() => {
                    document.getElementById("tagInputId").value = "";
                  }, 0)
                }
              />
            )}
          />
        </Grid>)
      } else if (v.fieldtype.id == '1' && v.id == '69') {//owner field
        var nameOwner = [];
        if (data.state.fieldsObj[v.fieldkey]) {
          nameOwner = data.state.fieldsObj[v.fieldkey].values
        }
        fieldsArray.push(<Grid item xs={12}>
          {/* <FormControl className="w-100 input_bx"> */}
          <InputLabel>Owner{(v.recordeditdefaultvalue == 3 || disabledAll) && <CommonDisbledLock />}</InputLabel>
          <Autocomplete
            disableOpenOnFocus
            forcePopupIcon={false}
            name={v.fieldkey}
            options={data.state.usersListArray}
            getOptionLabel={option => option.name ? option.name : ""}
            value={nameOwner}
            disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
            name={v.fieldkey}
            onChange={(event, value) => data.handleChange({ fieldid: v.id, values: value }, v.fieldkey, 'allowSubmitChange')}
            id={v.id}
            renderInput={params => (
              <TextValidator
                ref={ref => data[v.fieldkey] = ref}
                onBlur={(event) => data.focusOutSubmitForm(event, v.id, v.fieldkey)}
                {...params}
                variant="standard"
                // label={"Owner"}
                placeholder="Add Owner"
                className="w-100 input_bx"
                fullWidth
              />
            )}
          />
          {/* </FormControl> */}
        </Grid>)
      } else if (v.fieldtype.id == '1') {
        fieldsArray.push(<Grid item xs={12}>
          <InputLabel>{v.fieldlabel}{(v.recordeditdefaultvalue == 3 || disabledAll) && <CommonDisbledLock />}</InputLabel>
          <TextValidator
            type="text"
            id={v.id}
            disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
            className="w-100 input_bx"
            placeholder={`Add ${v.fieldlabel && v.fieldlabel.charAt(0).toUpperCase() + v.fieldlabel.slice(1)}`}
            onChange={(event) => data.handleChange({ fieldid: v.id, projectvalue: event.target.value }, v.fieldkey)}
            ref={ref => data[v.fieldkey] = ref}
            onBlur={(event) => data.focusOutSubmitForm(event, v.id, v.fieldkey)}
            name={v.fieldkey}
            value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].projectvalue : ""}
            validators={v.recordcreationdefaultvalue == 2 ? ["required"] : []}
            errorMessages={
              v.recordcreationdefaultvalue == 2
                ? ["This field is required"]
                : []
            }
          />
        </Grid>)
      }else if (v.fieldtype.id == '9'){
        fieldsArray.push(<Grid item xs={12}>
          <InputLabel>{v.fieldlabel}{(v.recordeditdefaultvalue == 3 || disabledAll) && <CommonDisbledLock />}</InputLabel>
          <TextValidator
            type="text"
            id={v.id}
            disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
            className="w-100 input_bx"
            placeholder={`Add ${v.fieldlabel && v.fieldlabel.charAt(0).toUpperCase() + v.fieldlabel.slice(1)}`}
            onChange={(event) => data.handleChange({ fieldid: v.id, projectvalue: event.target.value }, v.fieldkey)}
            ref={ref => data[v.fieldkey] = ref}
            onBlur={(event) => data.focusOutSubmitForm(event, v.id, v.fieldkey)}
            name={v.fieldkey}
            value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].projectvalue : ""}
            validators={v.recordcreationdefaultvalue == 2 ? ["required", "IsURLValid"] : ["IsURLValid"]}
            errorMessages={
              v.recordcreationdefaultvalue == 2
                ? ["This field is required", "Please enter a valid domain"]
                : ["Please enter a valid domain"]
            }
          />
        </Grid>)
      }
      else if (v.fieldtype.id == '6' || v.fieldtype.id == '10' || v.fieldtype.id == '7') {
        fieldsArray.push(<Grid item xs={12}>
          <InputLabel>{v.fieldlabel}{(v.recordeditdefaultvalue == 3 || disabledAll) && <CommonDisbledLock />}</InputLabel>
          <TextValidator
            type="number"
            id={v.id}
            disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
            className="w-100 input_bx"
            placeholder={`Add ${v.fieldlabel && v.fieldlabel.charAt(0).toUpperCase() + v.fieldlabel.slice(1)}`}
            onChange={(event) => data.handleChange({ fieldid: v.id, projectvalue: event.target.value }, v.fieldkey)}
            ref={ref => data[v.fieldkey] = ref}
            onBlur={(event) => data.focusOutSubmitForm(event, v.id, v.fieldkey)}
            name={v.fieldkey}
            value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].projectvalue : ""}
          />
        </Grid>)
      }

      if (v.fieldtype.id == '2') {
        fieldsArray.push(<Grid item xs={12}>
          <InputLabel>{v.fieldlabel}{(v.recordeditdefaultvalue == 3 || disabledAll) && <CommonDisbledLock />}</InputLabel>
          <TextValidator
            multiline
            type="text"
            id={v.id}
            disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
            className="w-100 input_bx"
            placeholder={`Add ${v.fieldlabel && v.fieldlabel.charAt(0).toUpperCase() + v.fieldlabel.slice(1)}`}
            onChange={(event) => data.handleChange({ fieldid: v.id, projectvalue: event.target.value }, v.fieldkey)}
            ref={ref => data[v.fieldkey] = ref}
            onBlur={(event) => data.focusOutSubmitForm(event, v.id, v.fieldkey)}
            name={v.fieldkey}
            value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].projectvalue : ""}
          />
        </Grid>)
      }

      if (v.fieldtype.id == '4') {
        if (v.id === "70" || v.id === "72" || v.id === "75") {//72-visibility,70-status,75-stage
          var valDropdown = "none";
          if (v.options && data.state.fieldsObj[v.fieldkey] && data.state.fieldsObj[v.fieldkey].values) {
            valDropdown = v.options.filter(row => row.id == data.state.fieldsObj[v.fieldkey].values.id)[0]
          }
          //set Visibility
          if (v.id === "72") {
            fieldsArray.push(
              <CommonVisibiltyEdit data={data} v={v} />
            )
          }
          else {
            fieldsArray.push(<Grid item xs={12}>
              <InputLabel>{v.fieldlabel}{(v.recordeditdefaultvalue == 3 || disabledAll) && <CommonDisbledLock />}</InputLabel>
              <SelectValidator
                className="w-100 input_bx"
                disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
                value={valDropdown}
                onChange={(event) => data.handleChange({ fieldid: v.id, values: event.target.value }, v.fieldkey, 'allowSubmitChange')}
                name={v.fieldkey}
                id={v.id}
                ref={ref => data[v.fieldkey] = ref}

              >
                <MenuItem value="none" disabled style={{display:'none'}}><span className="text-hint">{`Add ${v.fieldlabel && v.fieldlabel.charAt(0).toUpperCase() + v.fieldlabel.slice(1)}`}</span></MenuItem>
                {v.options && v.options.map((item, key) =>
                  <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item}>{item.name}</MenuItem>
                )}
              </SelectValidator>
            </Grid >
            )
          }
        } else {
          var valDropdown = "none";
          if (v.options && data.state.fieldsObj[v.fieldkey] && data.state.fieldsObj[v.fieldkey].values) {
            valDropdown = v.options.filter(row => row.id == data.state.fieldsObj[v.fieldkey].values.id)[0]
          }
          fieldsArray.push(<Grid item xs={12}>
            <InputLabel>{v.fieldlabel}{(v.recordeditdefaultvalue == 3 || disabledAll) && <CommonDisbledLock />}</InputLabel>
            <SelectValidator
              className="w-100 input_bx"              
              disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
              value={valDropdown}
              onChange={(event) => data.handleChange({ fieldid: v.id, values: event.target.value }, v.fieldkey, 'allowSubmitChange')}
              name={v.fieldlabel}
              id={v.id}
              ref={ref => data[v.fieldkey] = ref}

            >
              <MenuItem value="none" disabled style={{display:'none'}}><span className="text-hint">{`Add ${v.fieldlabel && v.fieldlabel.charAt(0).toUpperCase() + v.fieldlabel.slice(1)}`}</span></MenuItem>
              {v.options && v.options.map((item, key) =>
                <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item}>{item.optionvalue}</MenuItem>
              )}

            </SelectValidator>
          </Grid >
          )
        }
      }
      if (v.fieldtype.id == '5') {//multi dropdown
        var multiSelectedVal = [];
        if (data.state.fieldsObj[v.fieldkey] && data.state.fieldsObj[v.fieldkey].values && data.state.fieldsObj[v.fieldkey].values.length > 0) {
          multiSelectedVal = v.options.filter(row => data.state.fieldsObj[v.fieldkey].values.filter(val => val.id == row.id).length);
        }

        fieldsArray.push(<Grid item xs={12}>
          {/* <FormControl className="w-100 input_bx"> */}
          <InputLabel>{v.fieldlabel}{(v.recordeditdefaultvalue == 3 || disabledAll) && <CommonDisbledLock />}</InputLabel>
          <Autocomplete
            multiple
            value={multiSelectedVal}
            disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
            name={v.fieldkey}
            onChange={(event, value) => data.handleChange({ fieldid: v.id, values: value }, v.fieldkey, 'allowSubmitChange')}
            id={v.id}
            options={v.options}
            getOptionLabel={option => option.optionvalue ? option.optionvalue : ""}
            renderInput={params => (
              <TextValidator
                ref={ref => data[v.fieldkey] = ref}
                onBlur={(event) => data.focusOutSubmitForm(event, v.id, v.fieldkey)}
                {...params}
                variant="standard"
                // label={v.fieldlabel}
                placeholder={`Add ${v.fieldlabel && v.fieldlabel.charAt(0).toUpperCase() + v.fieldlabel.slice(1)}`}
                fullWidth
                className="w-100 input_bx"
              />
            )}
          />
          {/* </FormControl> */}
        </Grid >
        )
      }
      if (v.fieldtype.id == '3') {//checkbox field
        fieldsArray.push(<Grid item xs={12}>
          <FormControl className="w-100 input_bx">
            <FormControlLabel
              control={
                <Checkbox
                  name={v.fieldkey}
                  disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
                  id={v.id}
                  checked={(data.state.fieldsObj[v.fieldkey] && data.state.fieldsObj[v.fieldkey].projectvalue == "1") ? true : false}
                  onChange={(event, value) => data.handleChange({ fieldid: v.id, projectvalue: value ? "1" : "0" }, v.fieldkey, 'allowSubmitChange')}
                  value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].projectvalue : ""}
                  color="primary"
                />
              }
              label={<span className="font-size-13">{v.fieldlabel}</span>}
            />
          </FormControl>
        </Grid >
        )
      }
      if (v.fieldtype.id == '8') {//datepicker field
        var dtFormat = "MM/dd/yyyy";
        var selectedDate = data.state.selectedDate;
        if (data.state.fieldsObj[v.fieldkey]) {
          selectedDate = data.state.fieldsObj[v.fieldkey].values
        }

        if (data.state.userSettings != "") {
          dtFormat = data.state.userSettings.dateformat;
          dtFormat = dtFormat.replace("DD", "dd").replace("YYYY", "yyyy");
        }

        fieldsArray.push(<Grid item xs={12} >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <FormControl className="w-100">
              <KeyboardDatePicker
                className="w-100 input_bx"
                disabled={(v.id == '71' ? true : false)}//disable when created date
                disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
                label={v.fieldlabel}
                format={dtFormat}
                placeholder={dtFormat}
                error={(selectedDate == "Invalid Date") ? true : false}
                value={selectedDate}
                onChange={(event, value) => data.handleChange({ fieldid: v.id, values: event == "Invalid Date" ? "Invalid Date" : event }, v.fieldkey, 'allowSubmitChange')}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                helperText={(selectedDate == "Invalid Date") ? "Please enter a valid date." : ""}

              />
            </FormControl>
          </MuiPickersUtilsProvider>
        </Grid >
        )
      }
    }
  })

  return fieldsArray;
}