import React, { Component } from "react";
import ReactDragListView from "react-drag-listview";
import { Table } from 'antd';

class LeadTableComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            leaddata: [],
            rows:[],
            inRows:[]
        };
       
    }   
    componentWillReceiveProps(props) {
        this.setState({ rows: this.props.activetaskdata});
        this.setState({ inRows: this.props.inActivetaskdata });
    }

    render() {   
        
            const columns = [
                {
                    title: "",
                    dataIndex: "drag",
                    width: 50,
                    onCell: () => {
                        return {
                            style: {
                                whiteSpace: 'nowrap',
                                maxWidth: 50,
                            }
                        }
                    },
                },
                {
                    title: "Name",
                    dataIndex: "name",
                    className: "align_left",
                    onCell: () => {
                        return {
                            style: {
                                textAlign: 'left'
                            }
                        }
                    },
                },
                {
                    title: "Field type",
                    dataIndex: "fieldType"
                },
                {
                    title: "Creation rules",
                    dataIndex: "creationRule"
                },
                {
                    title: "Edit rules",
                    dataIndex: "editRule"
                },
                {
                    title: "Actions",
                    dataIndex: "action",
                    width: 120,
                    onCell: () => {
                        return {
                            style: {
                                whiteSpace: 'nowrap',
                                maxWidth: 120,
                                textAlign: 'right'
                            }
                        }
                    },
                }
            ];
            const inactiveColumns = [
   
                {
                    title: "Name",
                    dataIndex: "name",
                    className:"align_left"
                },
                {
                    title: "Actions",
                    dataIndex: "action",
                    width: 120,
                    onCell: () => {
                        return {
                            style: {
                                whiteSpace: 'nowrap',
                                maxWidth: 120,
                                textAlign: 'right'
                            }
                        }
                    },
                }
            ];
        const { rows, inRows } = this.state;

        return (
            <div>
                <div className="crm-custom-field-table" >
                    <React.Fragment>
                        <ReactDragListView handleSelector="a" onDragEnd={this.props.allData.updateFieldsOrder}>
                            <div className="table_width">
                                <Table
                                    columns={columns}
                                    pagination={false}
                                    dataSource={rows}
                                />
                            </div>
                        </ReactDragListView>
                    </React.Fragment>
                </div>
                {(inRows.length > 0 ) ? 
                            <div className="crm-custom-field-table" >
                                <h6>Inactive Fields</h6>
                                <React.Fragment>
                                    <ReactDragListView handleSelector="a">
                                        <div className="table_width">
                                            <Table
                                                columns={inactiveColumns}
                                                pagination={false}
                                                dataSource={inRows}
                                            />
                                        </div>
                                    </ReactDragListView>
                                </React.Fragment >
                            </div>
                    :
                        null
                    }
            
            </div>
        );
    }
}

export default LeadTableComponent;
