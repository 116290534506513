export default {
   // API: process.env.REACT_APP_API,
   
   API: "https://kanhasoftcrm.club:1236/",  // staging
   //API: "https://restapi.idrivecrm.com/",  // client

   GraphApi: "https://kanhasoftcrm.club:4002/graphql",//staging URL
   //GraphApi:"https://api.idrivecrm.com/graphql", //Client new
   

   Socket_URI: `wss://kanhasoftcrm.club:4002/graphql`, //staging URL
   //Socket_URI: `wss://api.idrivecrm.com/graphql`, //Client new

   Intregation_URI : "https://devcrmpython.kanhasoftcrm.club/", // Dev
   //Intregation_URI : "https://crmpython.idrivecrm.com/", // Client

   BCCINBOX: "@inbox.kanhasoftcrm.club",
   //BCCINBOX: "@inbox.idrivecrm.com"
}