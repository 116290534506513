import React, { Component } from "react";
import { Checkbox, List, ListItem, FormControl, Select, IconButton, InputLabel, Icon, Grid, Menu, withStyles, Typography, MenuItem, Avatar, Fab, Button, Tabs, Tab, Box, Dialog, DialogTitle, DialogActions, Tooltip } from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import { connect } from "react-redux";
import FontAwesome from "react-fontawesome";
import PhoneIcon from '@material-ui/icons/Phone';
import "date-fns";
import 'draft-js-mention-plugin/lib/plugin.css';
import _ from 'lodash'


class RingCentralCallLogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usersListArray: [],
            isEditLog: false,
            openComment: false,
            dateformat: 'MM/DD/YYYY',
            timeZone: 'America/New_York',
        }
    }

    handleCheckBoxChange = (event) => {
        this.setState({
            [event.target.name]: event.target.type === "checkbox" ?
                event.target.checked
                :
                event.target.value
        })
    }

    render() {
        let { log, selectedDate, dateformat, timeZone, commentid, showSelector, isOpenedCommentIds, isEditComment, commentvalue, thatAll, logid } = this.props;

        let direction = '';
        let telephonyStatus = '';
        let CallVia = '';
        let fromName = "";
        let callTo = '';
        let endDate = "";

        if (log.text && log.text !== null && log.text !== undefined && log.logtype == 1000) {
            let text = JSON.parse(log.text);
            direction = text.direction;
            telephonyStatus = (text.telephonyStatus == "webphone-session-connected" || text.telephonyStatus == "webphone-session-connecting") ? "No Answer" : text.telephonyStatus;
            CallVia = text.fromphoneNumber;
            fromName = text.fromName;
            callTo = text.phoneNumber;
            endDate = moment(log.date).fromNow();
            // moment([2007, 0, 29]).fromNow(true);
        }

        return (
            <div className="box_body">
                <div className="lead_pl p-0">
                    <Grid container>
                        <Grid item md={1}>
                            {/* <FontAwesome className={"fa fa-refresh fa-spin"} /> */}
                            {/* <FontAwesome className={"fa fa-refresh"} /> */}
                            <PhoneIcon />
                        </Grid>
                        <Grid item md={11}>
                            <Grid container justify="space-between">
                                <Grid className="flex-auto cursor_poiter">
                                    <h5 className="mb-0">
                                        {log.propertyType}
                                    </h5>
                                    <p className="mt-4 mb-4 font-size-13 text-hint">{log.date}, {log.time}</p>
                                </Grid>
                            </Grid>
                            <Grid container style={{ marginTop: '10px', marginBottom: '10px' }} >
                                <Grid container spacing={1}>
                                    <Grid className="font-size-14 mr-10" md={10}> {direction} Call to {endDate}({telephonyStatus}) </Grid>
                                    <Grid className="font-size-14 mr-10" md={10}> Called Via : {CallVia}</Grid>
                                    <Grid className="font-size-14 mr-10" md={10}>Call Information : - </Grid>
                                </Grid>
                            </Grid>
                            <Divider variant="middle" />
                            <Grid style={{ width: '190px', padding: '10px' }} container justify="space-between">
                                <div className="font-size-14">
                                    <Grid className="font-size-14 mr-10" md={10}> Outcome </Grid>
                                    <Grid item xs={15}>
                                        <FormControl className="w-100 input_bx" style={{ marginTop: '7px' }}>
                                            <Select
                                                name="max_num_tickets"
                                                value="No_Answer"
                                                onChange={this.handleCheckBoxChange}
                                            >
                                                <MenuItem key={"No_Answer"} value={"No_Answer"}>No Answer</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </div>
                                <div className="font-size-14">
                                    <Grid className="font-size-14 mr-10" md={10}> Duration </Grid>
                                    <p className="font-size-14 mr-10">00:00</p>
                                </div>
                            </Grid>
                            <Divider variant="middle" />
                            <Grid container >
                                <Grid container spacing={1}>
                                    <Grid item style={{ marginTop: '10px' }}>
                                        <Fab
                                            size="small"
                                            aria-label="person"
                                            className={`wrap_icons_task_sidebar vert-middle bg-grey_sidebar`}
                                        >
                                            <Icon className="activity_icon_normal">person</Icon>
                                        </Fab>
                                    </Grid>
                                    <Grid style={{ margin: '13px 10px 10px 10px' }}>
                                        <span className="font-size-14 font-weight-500">{fromName}</span>  ({CallVia})
                                 <p className="font-size-14" style={{ marginTop: '-1px' }}>to ({callTo})</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid container justify="space-between">
                            <h5 className="mb-0">
                                <FontAwesome className={"fa-phone mr-10 vert-middle"} style={{ fontSize: '20px', padding: '5px' }} />
                                {log.propertyType}
                            </h5>
                            <p className="mt-4 mb-4 font-size-13 text-hint">{log.date}, {log.time}</p>
                        </Grid> */}
                    </Grid>
                </div>
            </div>


        );
    }
}


const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
});


export default connect(mapStateToProps)(RingCentralCallLogs);

