import React, {Component} from 'react';
import {connect} from 'react-redux';
import GoogleLogin from 'react-google-login';


class GoogleLogins extends Component {
    componentDidMount() {
        this.googleSDK();
        console.log('sfsfd');
    }
    handleGoogleAuthSucess = (load) => {
        
       // this.props.onRegisterUser(load);
      //  this.props.onUserVerify(load);
       // this.props.onForgotPasswordRequest(load);
      //  this.props.onForgotPassword(load);
       // this.props.onUserLogin(load);
        console.log("hererff==",load);
       
        // axios.post(`http://103.239.146.251:1234/UserRegister/RegisterUser`, { load })
        // .then(res => {
        //   console.log(res);
        //   console.log(res.data);
        // })
    
    }
    
    handleGoogleAuthFailure = (load) => {

        // this.props.onRegisterUser(load);
        // this.props.onUserVerify(load);
        // this.props.onForgotPasswordRequest(load);
        // this.props.onForgotPassword(load);
        // this.props.onUserLogin(load);
        // console.log("herer",load);
        
        // axios.post(`http://103.239.146.251:1234/UserRegister/RegisterUser/`, { load })
        // .then(res => {
        //   console.log(res);
        //   console.log(res.data);
        // })
      console.log("[Auth fail]:",load)
    }
    
    handleGoogleAfterSignIn = (googleUser) => {
        console.log("hererererererereerererrereer")
    }


    prepareLoginButton = () => {
    console.log(this.refs.googleLoginBtn);
    this.auth2.attachClickHandler(this.refs.googleLoginBtn, {},
        (googleUser) => {
            alert("Asdadasd");
            debugger;
            let profile = googleUser.getBasicProfile();
            console.log(profile);
            let data = googleUser.getAuthResponse();
            console.log(data);
            console.log('Token || ' + googleUser.getAuthResponse().access_token);
            console.log('Token || ' + googleUser.getAuthResponse().id_token);
            console.log('ID: ' + profile.getId());
            console.log('Name: ' + profile.getName());
            console.log('Image URL: ' + profile.getImageUrl());
            console.log('Email: ' + profile.getEmail());
            //YOUR CODE HERE
        }, (error) => {
            alert(JSON.stringify(error, undefined, 2));
        });
    }
    googleSDK = () => {
        window['googleSDKLoaded'] = () => {
            window['gapi'].load('auth2', () => {
                this.auth2 = window['gapi'].auth2.init({
                    // client_id: '161942936141-fsq8al02npjinbf8vm1rr1lop0takre0.apps.googleusercontent.com',
                    client_id: '965427486424-jsdger0lt68ncnd49jki1ojpjkaemndc.apps.googleusercontent.com',
                    cookiepolicy: 'single_host_origin',
                    response_type:'full',
                    prompt:'consent',
                    access_type:'offline',
                    scope: 'https://www.googleapis.com/auth/drive https://mail.google.com https://www.googleapis.com/auth/calendar profile email openid '
                });
            this.prepareLoginButton();
            });
        }
        (function(d, s, id){
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {return;}
          js = d.createElement(s); js.id = id;
          js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'google-jssdk'));
    }
    render(){
        return(
            <div className="row mt-5">  
                <div className="col-md-12">
                    <h2 className="text-left">Google Login Demo</h2>
                    <div className="card mt-3">
                        <div className="card-body">
                            <div className="row mt-5 mb-5">
                                <div className="col-md-4 mt-2 m-auto ">
                                <GoogleLogin
                                    // clientId={"161942936141-fsq8al02npjinbf8vm1rr1lop0takre0.apps.googleusercontent.com"}
                                    clientId={"965427486424-jsdger0lt68ncnd49jki1ojpjkaemndc.apps.googleusercontent.com"}
                                    buttonText={"SignIn"}
                                    onSuccess={this.handleGoogleAuthSucess}
                                    onFailure={this.handleGoogleAuthFailure}
                                    isSignedIn={true}
                                    //responseType="code"
                                    uxMode='redirect'
                                    redirectUri='http://localhost:3000/chrome-login'
                                    scope="https://www.googleapis.com/auth/drive https://mail.google.com https://www.googleapis.com/auth/calendar profile email openid"
                                />
                                <button className="loginBtn loginBtn--google" ref="googleLoginBtn">
                                    Login with Google
                                </button>
                                <p>1. Google Login</p>
                                {/* <button onClick={this.props.onIncrementCounter}></button> */}
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
   // console.log("new props===.",state.ctr.counter)
    return{
        cntr:state.authReducer.counter,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        // onRegisterUser:(response_data) => dispatch(actionCreators.registerUser(response_data)),
       //  onUserVerify:(response_data)=>dispatch(actionCreators.userVerify(response_data)),
       //  onForgotPasswordRequest:(response_data)=>dispatch(actionCreators.forgotPasswordRequest(response_data)),
      //   onForgotPassword:(response_data) => dispatch(actionCreators.forgotPassword(response_data)),
      //   onUserLogin:(response_data) => dispatch(actionCreators.userLogin(response_data))
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(GoogleLogins);
