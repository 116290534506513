export const LOGIN_WITH_EMAIL_AND_PASSWORD = 'LOGIN_WITH_EMAIL_AND_PASSWORD';
export const REGISTER_USER = 'REGISTER_USER';
export const USER_VERIFY = 'USER_VERIFY';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const USER_LOGIN = 'USER_LOGIN';
export const DECREMENT = 'DECREMENT';
export const ADD = "ADD";
export const SUBTRACT = "SUBTRACT";
export const STORE_RESULT = "STORE_RESULT";
export const DELETE_RESULT = "DELETE_RESULT";
export const NOTIFICATIONS = "NOTIFICATIONS";
export const RESEND_EMAIL = "RESEND_EMAIL";
export const SETTING_ADD_USER = "SETTING_ADD_USER";
export const GET_SETTING_USER_LIST = "GET_SETTING_USER_LIST";
export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const INVITE_USER_DELETE = "INVITE_USER_DELETE";
export const SAVE_EDIT_USER = "SAVE_EDIT_USER";
export const GET_USER_PROFILE_INFO_FIRST = "GET_USER_PROFILE_INFO_FIRST";
export const GET_BUSSINESS_TYPE = "GET_BUSSINESS_TYPE";
export const GET_COMPANY_STRENGTH = "GET_COMPANY_STRENGTH";
export const UPDATE_COMPANY_INFO = "UPDATE_COMPANY_INFO";
export const COMPANY_LIST_BY_ID = "COMPANY_LIST_BY_ID";
export const UPDATE_USER = "UPDATE_USER";
export const COUNTRY_LIST = "COUNTRY_LIST";

export const COMPANY_LIST = "COMPANY_LIST";
export const GET_GENERATED_MAIL_ID = "GET_GENERATED_MAIL_ID";
export const GMAIL_SUGGESTION_NAMES = "GMAIL_SUGGESTION_NAMES";

//custom field action types
export const COSTOM_FIELD_TYPE_LIST = "COSTOM_FIELD_TYPE_LIST";
export const COSTOM_FIELD_LIST = "COSTOM_FIELD_LIST";
export const COSTOM_FIELD_DETAIL = "COSTOM_FIELD_DETAIL";
export const CREATED_FIELD_LIST = "CREATED_FIELD_LIST";
export const UPDATE_CUSTOM_FIELD_RECORD = "UPDATE_CUSTOM_FIELD_RECORD";
export const UPDATE_CUSTOM_FIELD_STATUS = "UPDATE_CUSTOM_FIELD_STATUS";
export const UPDATE_CUSTOM_FIELD_ORDER = "UPDATE_CUSTOM_FIELD_ORDER";
export const ADD_CUSTOM_FIELD = "ADD_CUSTOM_FIELD";
export const UPDATE_CUSTOM_FIELD = "UPDATE_CUSTOM_FIELD";
export const DELETE_CUSTOM_FIELD = "DELETE_CUSTOM_FIELD";


//contact action types
export const CONTACT_COSTOM_FIELD_LIST = "CONTACT_COSTOM_FIELD_LIST";
export const GET_CONTACT_TYPE = "GET_CONTACT_TYPE";
export const GET_COMPANY_REC_LIST = "GET_COMPANY_REC_LIST";
export const GET_CONTACT_FILTER_LIST = "GET_CONTACT_FILTER_LIST";
export const GET_CONTACT_LIST_ORDER = "GET_CONTACT_LIST_ORDER";
export const GET_CONTACT_LOG_LIST = "GET_CONTACT_LOG_LIST";
export const GET_CONTACT_SIDEBAR_LOG_LIST = "GET_CONTACT_SIDEBAR_LOG_LIST";

export const CONTACT_FIELD_DATA = "CONTACT_FIELD_DATA";
export const GET_CONTACTFILTER_LIST = "GET_CONTACTFILTER_LIST";


//compan action types
export const COMPANY_COSTOM_FIELD_LIST = "COMPANY_COSTOM_FIELD_LIST";
export const GET_COMAPNY_FILTER_LIST = "GET_COMAPNY_FILTER_LIST";
export const GET_COMPANY_LIST_ORDER = "GET_COMPANY_LIST_ORDER";
export const GET_COMPANYFILTERS = "GET_COMPANYFILTERS";
export const COMPANY_FIELD_DATA = "COMPANY_FIELD_DATA";
export const GET_COMPANY_LOG_LIST = "GET_COMPANY_LOG_LIST";
export const GET_COMPANY_SIDEBAR_LOG_LIST = "GET_COMPANY_SIDEBAR_LOG_LIST";






export const LEAD_STATUSES_LIST_GET = "LEAD_STATUSES_LIST_GET";
export const LEAD_SOUECES_LIST = "LEAD_SOUECES_LIST";
export const ALL_CURRENCY_LIST = "ALL_CURRENCY_LIST";
export const COMPANY_CURRENCY_DATA = "COMPANY_CURRENCY_DATA";
export const COUNTRY_LIST_GQL = "COUNTRY_LIST_GQL";
export const GET_USER_SETTING = "GET_USER_SETTING";
export const SYSTEM_TAGS_LIST = "SYSTEM_TAGS_LIST";
export const USERS_LIST_LIST = "USERS_LIST_LIST";

//Lead action types
export const GET_LEAD_LIST = "GET_LEAD_LIST";
export const GET_LEAD_FILTER_LIST = "GET_LEAD_FILTER_LIST";
export const GET_LEAD_LOG_LIST = "GET_LEAD_LOG_LIST";
export const GET_LEAD_LOG_SIDEBAR_LIST = "GET_LEAD_LOG_SIDEBAR_LIST";
export const GET_LEAD_LIST_ORDER = "GET_LEAD_LIST_ORDER";
export const LEAD_FIELD_DATA = "LEAD_FIELD_DATA";
export const GET_CITY_LIST = "GET_CITY_LIST";
export const GET_STATE_LIST = "GET_STATE_LIST";
export const GET_ZIPCODE_LIST = "GET_ZIPCODE_LIST";
export const GET_TIMEZONE_LIST = "GET_TIMEZONE_LIST";
export const GET_LEADFILTER_LIST = "GET_LEADFILTER_LIST";



//START For opportunity and pipelines 
export const SETTING_PIPELINE_LIST = "SETTING_PIPELINE_LIST";
export const PIPELINE_DATA_BY_ID = "PIPELINE_DATA_BY_ID";
export const LOSS_REASON_LIST = "LOSS_REASON_LIST";


export const GET_OPPORTUNITY_FILTER_LIST = "GET_OPPORTUNITY_FILTER_LIST";
export const GET_OPPORTUNITY_LIST_ORDER = "GET_OPPORTUNITY_LIST_ORDER";
export const GET_OPPORTUNITY_LOG_LIST = "GET_OPPORTUNITY_LOG_LIST";
export const GET_OPPORTUNITY_SIDEBAR_LOG_LIST = "GET_OPPORTUNITY_SIDEBAR_LOG_LIST";

export const OPPORTUNITY_FIELD_DATA = "OPPORTUNITY_FIELD_DATA";
export const GET_OPPORTUNITYFILTER_LIST = "GET_OPPORTUNITYFILTER_LIST";

export const OPPORTUNITIES_COSTOM_FIELD_LIST = "OPPORTUNITIES_COSTOM_FIELD_LIST";
export const OPPORTUNITY_CONTACT_LIST = "OPPORTUNITY_CONTACT_LIST";
export const OPPORTUNITY_COMPANY_LIST = "OPPORTUNITY_COMPANY_LIST";

//END For opportunity and pipelines 

export const GET_CONTACT_REC_LIST = "GET_CONTACT_REC_LIST";
export const MASTER_ACTIVITYTYPES_LIST = "MASTER_ACTIVITYTYPES_LIST";

//Tasks Action types

//contact action types
export const TASKS_COSTOM_FIELD_LIST = "TASKS_COSTOM_FIELD_LIST";
export const GET_TASK_TYPE = "GET_TASK_TYPE";
export const GET_TASK_FILTER_LIST = "GET_TASK_FILTER_LIST";
export const GET_TASK_LIST_ORDER = "GET_TASK_LIST_ORDER";
export const GET_TASK_LOG_LIST = "GET_TASK_LOG_LIST";
export const TASK_FIELD_DATA = "TASK_FIELD_DATA";
export const GET_TASKFILTER_LIST = "GET_TASKFILTER_LIST";

export const GET_RELATEDLIST_LIST = "GET_RELATEDLIST_LIST";
export const EMAIL_TEMPLATE_LIST = "EMAIL_TEMPLATE_LIST";
export const EMAIL_TEMPLATE_DETAIL = "EMAIL_TEMPLATE_DETAIL";



// projects action types
export const PROJECTS_COSTOM_FIELD_LIST = "PROJECTS_COSTOM_FIELD_LIST";

export const LEAD_RELATED_MASTER_LIST = "LEAD_RELATED_MASTER_LIST";
export const CONTACT_RELATED_MASTER_LIST = "CONTACT_RELATED_MASTER_LIST";
export const COMPANY_RELATED_MASTER_LIST = "COMPANY_RELATED_MASTER_LIST";
export const OPPORTUNITY_RELATED_MASTER_LIST = "OPPORTUNITY_RELATED_MASTER_LIST";
export const PROJECT_RELATED_MASTER_LIST = "PROJECT_RELATED_MASTER_LIST";

export const GET_PROJECT_LIST = "GET_PROJECT_LIST";
export const GET_PROJECT_FILTER_LIST = "GET_PROJECT_FILTER_LIST";
export const GET_PROJECT_LOG_LIST = "GET_PROJECT_LOG_LIST";
export const GET_PROJECT_LOG_SIDEBAR_LIST = "GET_PROJECT_LOG_SIDEBAR_LIST";
export const GET_PROJECT_LIST_ORDER = "GET_PROJECT_LIST_ORDER";
export const PROJECT_FIELD_DATA = "PROJECT_FIELD_DATA";
export const GET_PROJECTFILTER_LIST = "GET_PROJECTFILTER_LIST";

export const RELATED_DATALIST_COMPANY = "RELATED_DATALIST_COMPANY";
export const RELATED_DATALIST_CONTACT = "RELATED_DATALIST_CONTACT";
export const RELATED_DATALIST_PROJECT = "RELATED_DATALIST_PROJECT";
export const RELATED_DATALIST_OPPORTUNITY = "RELATED_DATALIST_OPPORTUNITY";
export const RELATED_DATALIST_TASK = "RELATED_DATALIST_TASK";

export const RELATED_DATALIST_CALANDAR = "RELATED_DATALIST_CALANDAR";
export const RELATED_DATALIST_FILE = "RELATED_DATALIST_FILE";
export const RELATED_DATALIST_QUICKBOOK = "RELATED_DATALIST_QUICKBOOK";
export const RELATED_DATALIST_XERO = "RELATED_DATALIST_XERO";

export const GET_PROJECT_REC_LIST = "GET_PROJECT_REC_LIST";
export const GET_OPPORTUNITY_REC_LIST = "GET_OPPORTUNITY_REC_LIST";
export const GET_TASK_REC_LIST = "GET_TASK_REC_LIST";


export const PROJECT_STAGE_LIST = "PROJECT_STAGE_LIST";

export const NOTIFICATIONS_LIST = "NOTIFICATIONS_LIST";
export const NOTIFICATION_READ = "NOTIFICATION_READ";

export const IMPORT_EXCEL_DATA = "IMPORT_EXCEL_DATA";
export const IMPORT_DATA_FILE = "IMPORT_DATA_FILE";
export const SAMPLE_IMPORT_FILE = "SAMPLE_IMPORT_FILE";
export const GET_IMPORT_FILE_LIST = "GET_IMPORT_FILE_LIST";
export const REVERT_IMPORT_DETAIL = "REVERT_IMPORT_DETAIL";


export const EMAIL_SETTINGS = "EMAIL_SETTINGS";
export const GET_NOTIFICATIONS_SETTINGS = "GET_NOTIFICATIONS_SETTINGS";
export const GET_FOLLOW_SETTINGS = "GET_FOLLOW_SETTINGS";


export const AUTOMATION_LIST = "AUTOMATION_LIST";
export const AUTOMATION_DETAIL = "AUTOMATION_DETAIL";


export const GET_USER_INTEGRATION = "GET_USER_INTEGRATION";
export const UPDATE_USER_INTEGRATION = "UPDATE_USER_INTEGRATION";
export const ZENDESK_VERTFY ="ZENDESK_VERTFY";
export const SAVE_INTREGATION_DETAILS = "SAVE_INTREGATION_DETAILS";
export const GET_INTEGRATION_DETAILS ="GET_INTEGRATION_DETAILS";
export const GET_TICKET_LIST = "GET_TICKET_LIST";
export const SLACK_SETTINGS ="SLACK_SETTINGS";
export const SLACK_CHANNELS ="SLACK_CHANNELS";



export const GENERATE_ZAPIER_TOKEN= "GENERATE_ZAPIER_TOKEN";

export const MAILCHIMP_DATA_VERTFY = "MAILCHIMP_DATA_VERTFY";
export const GET_MAILCHIMP_AUDIENCE = "GET_MAILCHIMP_AUDIENCE";
export const ADD_MAILCHIMP_SUBSCRIBER = "ADD_MAILCHIMP_SUBSCRIBER";
export const GET_MEMBER_IN_AUDIENCE = "GET_MEMBER_IN_AUDIENCE";
export const DELETE_MEMBER_IN_AUDIENCE = "DELETE_MEMBER_IN_AUDIENCE";
export const EMAIL_TRACKING_LIST = "EMAIL_TRACKING_LIST";
export const GET_DOCUSING_ROLE = "GET_DOCUSING_ROLE";
export const CREATES_DOCUSIGN = "CREATES_DOCUSIGN";
export const GET_DOCUSING_DATA = "GET_DOCUSING_DATA";

//Syncing
export const GET_SYNC_TIME = "GET_SYNC_TIME";


// reports 

export const GET_REPORT_PIPELINE_SUMMARY = "GET_REPORT_PIPELINE_SUMMARY";
export const GET_REPORT_FILTER_LIST = "GET_REPORT_FILTER_LIST";
export const GET_PIPELINE_REPORT_FILTER_LIST = "GET_PIPELINE_REPORT_FILTER_LIST";
export const GET_FORECAST_REPORT_FILTER_LIST = "GET_FORECAST_REPORT_FILTER_LIST";
export const GET_PERFORMANCE_REPORT_FILTER_LIST = "GET_PERFORMANCE_REPORT_FILTER_LIST";
export const GET_PERFORMANCE_SUMMARY_REPORT_FILTER_LIST = "GET_PERFORMANCE_SUMMARY_REPORT_FILTER_LIST";

export const GET_REPORT_SALES_FORECAST_SUMMARY = "GET_REPORT_SALES_FORECAST_SUMMARY";
export const GET_REPORT_SALES_PERFORMANCE_SUMMARY = "GET_REPORT_SALES_PERFORMANCE_SUMMARY";
export const GET_REPORT_SALES_PERFORMANCE = "GET_REPORT_SALES_PERFORMANCE";
export const OPEN_TASK_REPORT = "OPEN_TASK_REPORT";
export const ACTIVITY_DASHBOARD_REPORT_LIST = "ACTIVITY_DASHBOARD_REPORT_LIST";
export const GET_REPORT_SALES_BY_SOURCE = "GET_REPORT_SALES_BY_SOURCE";
export const ACTIVITY_LEADERBOARD_DATA = "ACTIVITY_LEADERBOARD_DATA";
export const CONVERTION_FUNNEL_DATA = "CONVERTION_FUNNEL_DATA";

//team
export const GET_TEAM_LIST = "GET_TEAM_LIST";
export const GET_TEAM_DETIALS = "GET_TEAM_DETIALS";
export const GET_TEAM_MEMBER_LIST = "GET_TEAM_MEMBER_LIST";
export const GET_TEAM_ACCESS_LIST = "GET_TEAM_ACCESS_LIST";
export const GET_TEAM_REPORT_LIST = "GET_TEAM_REPORT_LIST";
export const GET_USER_PERMISSION = "GET_USER_PERMISSION";
export const GET_COMPANY_DETAILS = "GET_COMPANY_DETAILS";
export const GET_TEAM_SETTINGS = "GET_TEAM_SETTINGS";



//goal
export const GET_SALES_MONTHLY_GOAL_LIST = "GET_SALES_MONTHLY_GOAL_LIST";
export const GET_SALES_QUARTER_GOAL_LIST = "GET_SALES_QUARTER_GOAL_LIST";
export const GET_SALES_YEAR_GOAL_LIST = "GET_SALES_YEAR_GOAL_LIST";

export const GET_WON_MONTHLY_GOAL_LIST = "GET_WON_MONTHLY_GOAL_LIST";
export const GET_WON_QUARTER_GOAL_LIST = "GET_WON_QUARTER_GOAL_LIST";
export const GET_WON_YEAR_GOAL_LIST = "GET_WON_YEAR_GOAL_LIST";

export const GET_ACTIVITY_MONTHLY_GOAL_LIST = "GET_ACTIVITY_MONTHLY_GOAL_LIST";
export const GET_ACTIVITY_QUARTER_GOAL_LIST = "GET_ACTIVITY_QUARTER_GOAL_LIST";
export const GET_ACTIVITY_YEAR_GOAL_LIST = "GET_ACTIVITY_YEAR_GOAL_LIST";

export const GET_USER_FILTER_LIST_FOR_GOAL = "GET_USER_FILTER_LIST_FOR_GOAL";
export const GET_GOAL_REPORT = "GET_GOAL_REPORT";


// billing
export const BILLING_PLANS_LIST = "BILLING_PLANS_LIST";
export const COMPANY_PLAN_PURCHASE = "COMPANY_PLAN_PURCHASE";

export const SELECTED_PLAN_DETALS = "SELECTED_PLAN_DETALS";


// export const GET_TAG_LIST = "GET_TAG_LIST";


//role FE SIDE admin:-1,system admin-2,others:-3

export const USER_ROLE = "USER_ROLE";

export const MASTER_SEARCH = "MASTER_SEARCH";
export const GET_USER_DETAIL_BY_EMAIL = "GET_USER_DETAIL_BY_EMAIL";
export const GET_RELATED_CONTACTS__DATA = "GET_RELATED_CONTACTS__DATA";

export const DASHBOAD_USER_ID = "DASHBOAD_USER_ID";



// task automations 
export const TASK_AUTOMATION_LIST = "TASK_AUTOMATION_LIST";

export const LEAD_SETTINGS_LIST_GET = "LEAD_SETTINGS_LIST_GET";



