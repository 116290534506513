import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Box, Typography, Card } from "@material-ui/core";
import CompanyLogs from "./CompanyLogs";
import CallLogs from "./callLogs";
import NoteLogs from "./NoteLogs";
import CompanyEmailLogs from "./EmailLogs";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={2}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}


export default function CompanyTab(props) {
    const [value, setValue] = React.useState(0);

    function handleChange(event, newValue) {
        setValue(newValue);
    }

    let mt = props.IsGoogleLogin  ? 'mt-40' : 'mt-16';

    return (
        <Card elevation={3} className={`${mt} tab_card`}>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                scrollButtons="auto"
                className="lead_tabs"
                variant="scrollable"
            >
                <Tab label="Activity" {...a11yProps(0)} />
                <Tab label="Notes" {...a11yProps(1)} />
                <Tab label="Emails" {...a11yProps(2)} />
                <Tab label="Call & meeting" {...a11yProps(3)} />
                {/* <Tab label="Task" {...a11yProps(4)} /> */}
            </Tabs>
            <div className="v_scroll position-relative">
                <TabPanel value={value} index={0} className="h-100">
                    <CompanyLogs company_id={props.company_id} />
                </TabPanel>
                <TabPanel value={value} index={1} className="h-100">
                    <NoteLogs company_id={props.company_id} />
                </TabPanel>
                <TabPanel value={value} index={2} className="h-100">
                    <CompanyEmailLogs company_id={props.company_id} />
                </TabPanel>
                <TabPanel value={value} index={3} className="h-100">
                    <CallLogs company_id={props.company_id} />
                </TabPanel>
                {/* <TabPanel value={value} index={4} className="h-100">Task</TabPanel> */}
            </div>
        </Card>
    );
}
