import React, { Component } from "react";
import { IconButton, Icon, Button,List, ListItem,
 ListItemText, ListItemIcon, ListItemSecondaryAction,Card,Typography,
Dialog,DialogActions,DialogTitle} from "@material-ui/core";
import "date-fns";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import RootRef from "@material-ui/core/RootRef";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import EditIcon from "@material-ui/icons/Edit";
import AddLossReason from "./AddLossReason"

// reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.map((value, index) => {
        result[index].order_no = index + 1;
    })
  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: "rgb(235,235,235)"
  })
});

const getListStyle = isDraggingOver => ({
  //background: isDraggingOver ? 'lightblue' : 'lightgrey',
});

class LossReason extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loassReasonList: [],
            openAddPop: false,
            FullLeadStatusDialog: false,
            minimizeLeadStatusDialog: false,
            isDeleteConfirmDialoOpen: false,
            deleteItemName: '',
            deleteData: '',
            lossReasonsData: {},
        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }
    
    handleLeadDialogClose = () => {
        this.setState({ openAddPop: false });
    };
    handleLeadDialogMinimize = () => {
        this.setState(oldStateminimizeLeadDialog => ({ minimizeLeadStatusDialog: !oldStateminimizeLeadDialog.minimizeLeadStatusDialog }));
        this.setState({FullLeadStatusDialog: false})
    };

    handleLeadDialogFull = () => {
        this.setState(oldStateFullCreateLeadDialog => ({ FullLeadStatusDialog: !oldStateFullCreateLeadDialog.FullLeadStatusDialog }));
        this.setState({minimizeLeadStatusDialog: false})

    };

    componentDidMount() {
        this.props.getLossReasonList(this.props.apolloClient.client);
    }

    componentWillReceiveProps(props){
        if (props.settingReducer.loassReasonList.data != null) {
            var dataList = props.settingReducer.loassReasonList.data;
            dataList.sort(function(a,b){return a.order_no - b.order_no});
            this.setState({ loassReasonList: dataList});
        }
    }

    onDragEnd(result) {
        if (!result.destination) {
          return;
        }

        const items = reorder(
          this.state.loassReasonList,
          result.source.index,
          result.destination.index
        );

        this.setState({loassReasonList : items});
        
        var idsArray = [];
        var orderListArray = [];
        items.map((value, index) => {
            idsArray.push(parseInt(value.id));
            orderListArray.push(value.order_no);
        })
        
        let req = {
            reasonIds:idsArray,
            orderListArray:orderListArray,
        }
        this.props.updateLossReasonOrder(this.props.apolloClient.client,req);
        this.props.settingReducer.loassReasonList.data = items;
    }

    handleAddUpdate = (data)=>{
        let info = {}
        if(data && data !=undefined){
            info.name = data.name
            info.id = data.id
            info.isEdit = true
        }else{
            info.isEdit = false
        }
        this.setState({openAddPop:false},()=>{
            this.setState({lossReasonsData:info,openAddPop:true});
        });
    }

    handledeleteLossReason = (data) => {
        this.setState({deleteItemName:data.name});
        this.toggleDeleteDialog(data)
    };

    toggleDeleteDialog = (data) => {
        this.setState({ isDeleteConfirmDialoOpen: !this.state.isDeleteConfirmDialoOpen,deleteData:data});
    };

    confirmdeleteLossReason = () => {
        let req = {
            reasonid:parseInt(this.state.deleteData.id),
        }
        this.props.deleteLossReason(this.props.apolloClient.client,req);
        this.toggleDeleteDialog()
    };

    findWithAttr = (array, attr, value) => {
        for(var i = 0; i < array.length; i += 1) {
            if(array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }

    handleChange = (event, source) => {
        console.log("source", source);
        console.log("event", event);
        event.persist();
        this.setState({
          [event.target.name]: event.target.value
        });
    };

    render() {
        return (
            <div>
                <Typography className="card-margin-leadstatus" variant="h5" color="inherit">Loss Reasons</Typography>
                <Typography className="card-margin-leadstatus"></Typography>
                <Card className="signup-card">
                    <div className="lead_upcoming">
                        <div className="box_portlet">
                            <div className="box_header">
                                <div className="box_label"><h3 className="box_title" style={{marginLeft: '65px'}}>Name</h3></div>
                                <div className="box_tools">
                                    <Button variant="contained" color="secondary" onClick={() => this.handleAddUpdate()} size="small" style={{ minWidth: 95 }}>
                                        Create Loss Reason
                                    </Button>
                                    {this.state.openAddPop && (
                                        <AddLossReason
                                            handleClose={this.handleLeadDialogClose}
                                            open={this.state.openAddPop}
                                            handleMinimize={this.handleLeadDialogMinimize}
                                            handleFull={this.handleLeadDialogFull}
                                            minimizeScreen={this.state.minimizeLeadStatusDialog}
                                            fullScreen={this.state.FullLeadStatusDialog}
                                            lossReasonsData={this.state.lossReasonsData}
                                        />
                                    )} 
                                </div>
                            </div>
                            <div className="box_body">
                                Create and customize Loss Reasons to track why Opportunities are being lost
                                <DragDropContext onDragEnd={this.onDragEnd}>
                                    <Droppable droppableId="droppable">
                                      {(provided, snapshot) => (
                                        <RootRef rootRef={provided.innerRef}>
                                          <List style={getListStyle(snapshot.isDraggingOver)}>
                                            {this.state.loassReasonList && this.state.loassReasonList.map((item, index) => (
                                              <Draggable key={item.id} draggableId={item.id} index={index}>
                                                {(provided, snapshot) => (
                                                    <ListItem
                                                        ContainerComponent="li"
                                                        ContainerProps={{ ref: provided.innerRef }}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                          snapshot.isDragging,
                                                          provided.draggableProps.style
                                                        )}
                                                      >
                                                    <ListItemIcon> <Icon>drag_handle</Icon> </ListItemIcon>
                                                    <ListItemText primary={item.name}/>
                                                    <ListItemSecondaryAction>
                                                        <IconButton onClick={()=>this.handleAddUpdate(item)} ><EditIcon/></IconButton>
                                                        <IconButton onClick={()=>this.handledeleteLossReason(item)}><Icon>delete</Icon></IconButton>
                                                      
                                                      
                                                      
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                )}
                                              </Draggable>
                                            ))}
                                            {provided.placeholder}
                                          </List>
                                        </RootRef>
                                      )}
                                    </Droppable>
                                  </DragDropContext>
                            </div>
                            <Dialog
                                  fullWidth={true}
                                  maxWidth = {'xs'}
                                  open={this.state.isDeleteConfirmDialoOpen}
                                  onClose={this.toggleDeleteDialog}
                                  disableBackdropClick
                                //   disableEscapeKeyDown
                                  aria-labelledby="ar-remove-dialog-title"
                                >
                                    <DialogTitle id="ar-remove-dialog-title">{"Are you sure to remove "} {this.state.deleteItemName} {"?"}</DialogTitle>
                                    <DialogActions>
                                      <Button  onClick={this.toggleDeleteDialog} color="primary">
                                          Cancel
                                      </Button>
                                      <Button  type="Button" onClick={() =>this.confirmdeleteLossReason()}  className="btn_Primary">
                                        Ok
                                      </Button>
                                       
                                    </DialogActions>
                            </Dialog>
                        </div>
                    </div>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient
});


const mapDispatchToProps = dispatch => {
    return {
        getLossReasonList: (client) => dispatch(actionCreators.getLossReasonList(client)),
        updateLossReasonOrder: (client,req) => dispatch(actionCreators.updateLossReasonOrder(client,req)),
        deleteLossReason: (client,req) => dispatch(actionCreators.deleteLossReason(client,req)),
    }
}

export default (connect(mapStateToProps, mapDispatchToProps)(LossReason));
