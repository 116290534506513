import React, { Component } from "react";
import { Fab, Grid, withStyles, Switch, InputLabel, FormControl, Input, FormHelperText, RadioGroup, Radio, FormControlLabel, Divider, DialogContent, InputAdornment, Button, IconButton, Icon, MenuItem, Card, TextField, Dialog, DialogActions, DialogTitle, Drawer, Avatar } from "@material-ui/core";
import ContactProfileFrame from "../Contacts/ContactProfileFrame";
import CreateNewContactDialog from "../Contacts/CreateNewContactDialog";
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { NavLink, withRouter } from "react-router-dom";
import { SingleSelect } from "react-select-material-ui";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import CreateNewCompanyDialog from "../Company/CreateNewCompanyDialog";
import CompanyProfileFrame from "../Company/CompanyProfileFrame";
import People from './People';
import ListComponent from "./ListComponent";
const formatCreateLabel = (value) => `Create "${value}"`;
const isValidNewOption = (value) => value != "";
const IconSmall = withStyles(() => ({
  root: {
    fontSize: "1.5rem",
    color: 'rgba(0, 0, 0, 0.6)'
  }
}))(Icon);
const filter = createFilterOptions();


class Companies extends Component {
  state = {
    attachment: null,
    drawerOpenCompany: false,
    showAddCompany: false,
    opportunities_id: "",
    company_id: "",
    companyListArray: [],
    allCompanyList: [],
    createNewCompanyName: "",
    filterdata: [],
    removeCompanyPopup: false,
    shouldOpenCreateCompanyDialog: false,
    FullCreateCompanyDialog: false,
    minimizeCompanyDialog: false,
    type: "companies",
    recordtype: "",
    opportunities_id: "",
    compnayCrud: {
      isaccess: true,
      iscreate: true,
    },
    activedatas: [],
    isFlag: 0,
  };

  componentWillUnmount() {
    this.props.settingReducer.relatedDataCompany = [];
  }

  componentWillMount() {
    this.props.getCompanyRecList(this.props.apolloClient.client);
    let val = { recordtypeid: 5 }
    this.props.getRelatedMasterList(this.props.apolloClient.client, val);
    this.props.getUserIntegration(this.props.apolloClient.client);

    // console.log("this.props", this.props.recordId);
    if (this.props.recordId) {
      this.setState({
        recordId: this.props.recordId,
        recordtype: this.props.recordtype,
      }, () => {
        this.forceUpdate()
        if (this.props.recordtype == "opportunities") {
          this.setState({
            opportunities_id: this.props.recordId
          })
        }
        var req = {
          id: this.props.recordId,
          type: this.state.type,
          recordtype: this.props.recordtype,
        }
        //console.log("req", req);
        this.props.getRecordRelatedData(this.props.apolloClient.client, req);
      });
    }
  }

  componentDidUpdate = (prevProps) => {
    // console.log("componentDidUpdate", prevProps.recordId, prevProps.recordtype);
    // this.setState({ prevPropsrecordId: prevProps.recordId, prevPropsrecordtype: prevProps.recordtype });
  }

  componentDidMount() {
    this.props.getCompanyRecList(this.props.apolloClient.client);
    if (this.props.recordId) {
      this.setState({
        recordId: this.props.recordId,
        recordtype: this.props.recordtype,
      }, () => {
        this.forceUpdate()
        if (this.props.recordtype == "opportunities") {
          this.setState({
            opportunities_id: this.props.recordId
          })
        }
        var req = {
          id: this.props.recordId,
          type: this.state.type,
          recordtype: this.props.recordtype,
        }
        this.props.getRecordRelatedData(this.props.apolloClient.client, req);
      });
    }
  };

  getUserPermission = () => {
    if (this.props.settingReducer.userPermissionDetails.data) {
      let data = this.props.settingReducer.userPermissionDetails.data.data;
      if (data.teampermissionstatus === true &&
        data.visibilityaccess.length > 0) {
        let compnayCrud = "";
        if (this.props.authReducer.userRole.id === 3) {
          compnayCrud = data.visibilityaccess.find(function (e) { return e.sourcetype === 3 });
        }
        this.setState({
          compnayCrud: compnayCrud === "" ? this.state.compnayCrud : compnayCrud,
        })
      }
    }
  };

  componentWillReceiveProps(props) {
    if (props.settingReducer.projectrelatedMaster.data) {
      const leadrelatedMaster = props.settingReducer.projectrelatedMaster.data;
      let activedatas = [];

      leadrelatedMaster.map((fieldData, index) => {
        if (fieldData.isactive === true) {
          activedatas.push({
            key: fieldData.id,
            alias: fieldData.alias,
            isactive: fieldData.isactive,
            orderid: fieldData.orderno,
          });
        }
      });
      if (this.state.updatelistorder == true)
        this.setState({ activedatas: activedatas }, () => this.forceUpdate());
    }
    this.getUserPermission()
    if (props.recordId != this.state.recordId) {//if(props.recordId !== undefined && this.state.oppoFlag){
      this.setState({
        recordId: props.recordId,
        recordtype: props.recordtype,
      }, () => {
        this.forceUpdate()
        var req = {
          id: props.recordId,
          type: this.state.type,
          recordtype: props.recordtype,
        }
        //console.log("req", req);
        this.props.getRecordRelatedData(this.props.apolloClient.client, req);
      });
    }
    if (props.companyReducer.companyFieldData.data && props.companyReducer.companyFieldData.is_loaded) {
      this.setState({
        isDataLoading: false,
      }, () => this.forceUpdate())
    }


    if (props.settingReducer.relatedDataCompany.data) {
      props.settingReducer.relatedDataCompany.is_loaded = false
      var dd = []
      dd = props.settingReducer.relatedDataCompany.data.data
      this.setState({
        companyListArray: dd,
      }, () => this.forceUpdate());
      if (props.settingReducer.relatedDataCompany.data.data && props.settingReducer.relatedDataCompany.data.data.length > 0) {
        this.setState({ isOpenedCompany: true });
      } else {
        this.setState({ isOpenedCompany: false });
      }
    } else {
      this.setState({
        companyListArray: [],
        isOpenedCompany: false
      }, () => this.forceUpdate());
    }

    if (props.settingReducer.companyRecList && props.settingReducer.companyRecList.data && props.settingReducer.companyRecList.data.data) {
      let companyOptions = [];
      props.settingReducer.companyRecList.data.data.map((compOpt) => {
        companyOptions.push({ label: compOpt.name, value: compOpt.company_id });
      })
      this.setState({ allCompanyList: companyOptions });
    }

  }

  toggleCompany = () => {
    this.setState(oldStateCompany => ({ isOpenedCompany: !oldStateCompany.isOpenedCompany }));
    if (this.state.isOpenedCompany) {
      this.setState({
        showAddCompany: false
      }, () => this.forceUpdate());
    }
  }

  /* START cmp dialoge */

  handleCompanyDialogClose = () => {
    this.setState({ shouldOpenCreateCompanyDialog: false, FullCreateCompanyDialog: false, minimizeCompanyDialog: false });
  };

  handleCompanyDialogMinimize = () => {
    this.setState(oldStateminimizeCompanyDialog => ({ minimizeCompanyDialog: !oldStateminimizeCompanyDialog.minimizeCompanyDialog }));
    this.setState({ FullCreateCompanyDialog: false })
  };

  handleCompanyDialogFull = () => {
    this.setState(oldStateFullCreateCompanyDialog => ({ FullCreateCompanyDialog: !oldStateFullCreateCompanyDialog.FullCreateCompanyDialog }));
    this.setState({ minimizeCompanyDialog: false })

  };

  /* END cmp dialoge */

  closeDrawerRight = (e) => {
    this.setState({
      isDataLoading: true,
    });

    setTimeout(() => {
      this.setState({
        drawerOpenCompany: false,
        isDataLoading: false,
        isFlag: 1
      }, () => {
        this.forceUpdate()
      })
    }, 2500);

    let recordtype = this.state.FlagType;
    let FlagId = this.state.FlagId;

    // if (recordtype !== "project") {
    let projects = {
      id: parseFloat(FlagId),
      type: 'projects',
      recordtype: recordtype,
    }
    this.props.getRecordRelatedData(this.props.apolloClient.client, projects);
    let calendarevents = {
      id: parseFloat(FlagId),
      type: 'calendarevents',
      recordtype: recordtype,
    }
    this.props.getRecordRelatedData(this.props.apolloClient.client, calendarevents);
    // }
    let tasks = {
      id: parseFloat(FlagId),
      type: 'tasks',
      recordtype: recordtype,
    }
    this.props.getRecordRelatedData(this.props.apolloClient.client, tasks);
    let files = {
      id: parseFloat(FlagId),
      type: 'files',
      recordtype: recordtype,
    }
    this.props.getRecordRelatedData(this.props.apolloClient.client, files);
    // if (recordtype !== "contact") {
    // if (recordtype !== "company") {
    let companies = {
      id: parseFloat(FlagId),
      type: 'companies',
      recordtype: recordtype,
    }
    this.props.getRecordRelatedData(this.props.apolloClient.client, companies);
    // }
    let contact = {
      id: parseFloat(FlagId),
      type: 'contact',
      recordtype: recordtype,
    }
    this.props.getRecordRelatedData(this.props.apolloClient.client, contact);
    // }
    // if (recordtype !== "opportunities") {
    let opportunities = {
      id: parseFloat(FlagId),
      type: 'opportunities',
      recordtype: recordtype,
    }
    this.props.getRecordRelatedData(this.props.apolloClient.client, opportunities);
    // }
  };

  toggleAddCompany = () => {
    this.setState({
      isOpenedCompany: true,
      showAddCompany: !this.state.showAddCompany
    });
  };

  handleDrawerCompanyRight = (company_id) => {
    if (this.state.company_id && (this.state.company_id === company_id)) { } else {
      this.setState({
        FlagId: this.state.recordId,
        FlagType: this.state.recordtype,
        company_id: company_id,
        isDataLoading: true,
      }, () => {
        this.forceUpdate()
      })
    };

    setTimeout(() => {
      this.setState({
        drawerOpenCompany: true,
      }, () => {
        this.forceUpdate()
      })
    }, 3000);

    if (this.state.isFlag > 0) {
      let calendarevents = {
        id: parseFloat(company_id),
        type: 'calendarevents',
        recordtype: this.state.type,
      }
      this.props.getRecordRelatedData(this.props.apolloClient.client, calendarevents);
      let req = {
        id: parseFloat(company_id),
        type: 'contact',
        recordtype: this.state.type,
      }
      this.props.getRecordRelatedData(this.props.apolloClient.client, req);
      let opportunities = {
        id: parseFloat(company_id),
        type: 'opportunities',
        recordtype: this.state.type,
      }
      this.props.getRecordRelatedData(this.props.apolloClient.client, opportunities);
      let tasks = {
        id: parseFloat(company_id),
        type: 'tasks',
        recordtype: this.state.type,
      }
      this.props.getRecordRelatedData(this.props.apolloClient.client, tasks);
      let files = {
        id: parseFloat(company_id),
        type: 'files',
        recordtype: this.state.type,
      }
      this.props.getRecordRelatedData(this.props.apolloClient.client, files);
      let companies = {
        id: parseFloat(company_id),
        type: 'companies',
        recordtype: this.state.type,
      }
      this.props.getRecordRelatedData(this.props.apolloClient.client, companies);
      let projects = {
        id: parseFloat(company_id),
        type: 'projects',
        recordtype: this.state.type,
      }
      this.props.getRecordRelatedData(this.props.apolloClient.client, projects);
    }
  };
  handleCompanyLaunch = (company_id) => {
    this.props.history.push('/company/company/' + company_id)
  };
  handleRemoveCompany = (company_id) => {
    this.setState({
      removeCompanyPopup: true,
      deleteCompanyId: company_id,
    }, () => {
      this.forceUpdate()
    })

  };
  toggleRemoveCompanyPopup = () => {
    if (this.state.removeCompanyPopup) {
      this.setState({
        deleteCompanyId: "",
      }, () => {
        this.forceUpdate()
      })

    }
    this.setState({
      removeCompanyPopup: !this.state.removeCompanyPopup,
    }, () => {
      this.forceUpdate()
    })

  };
  confirmremoveCompanyPopup = () => {
    console.log("this.state.deleteCompanyId", this.state.deleteCompanyId);
    if (this.state.deleteCompanyId) {
      if (this.state.recordtype == "opportunities") {
        var req = {
          companyid: parseInt(this.state.deleteCompanyId),
          opportunitiesid: parseInt(this.state.recordId),
        }
        this.props.deleteOpportunitiesCompany(this.props.apolloClient.client, req)
      }
      if (this.state.recordtype == "project") {
        var req = {
          companyid: parseInt(this.state.deleteCompanyId),
          projectid: parseInt(this.state.recordId),
        }
        this.props.deleteProjectCompany(this.props.apolloClient.client, req)
      }

      this.setState({
        deleteCompanyId: "",
        removeCompanyPopup: false,
      }, () => {
        this.forceUpdate()
      })
    }

  };

  handleCompanyChange = (val, newValue) => {
    if (newValue) {
      let id = (newValue.__isNew__) ? "0" : newValue.value;
      if (id == "0") {
        this.setState({
          shouldOpenCreateCompanyDialog: true,
          createNewCompanyName: newValue.label,
          dataFromSidebar: {
            id: parseInt(this.state.recordId),
            type: this.state.type,
            recordtype: this.state.recordtype,
          },
        })
      } else {
        var isAlreadyAdded = this.state.companyListArray.filter(function (element) {
          if (element.company.id == id) {
            return element
          }
        });
        if (isAlreadyAdded.length > 0) {
          this.setState({ showAddCompany: false });
          return
        }
        let data = { srno: "1", id: id, value: newValue.label };
        if (this.state.recordtype == "opportunities") {
          var req = {
            companydetail: data,
            opportunitiesid: parseInt(this.state.recordId),
          }
          this.props.addNewOpportunitiesCompany(this.props.apolloClient.client, req)
        }
        if (this.state.recordtype == "project") {
          var req = {
            companydetail: data,
            projectid: parseInt(this.state.recordId),
          }
          this.props.addProjectCompany(this.props.apolloClient.client, req)
        }


      }
      this.setState({ showAddCompany: false });

    }
  };

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }
  render() {
    let { drawerOpenCompany, isOpenedCompany } = this.state;
    /*STRAT company Display*/
    let displayCompanyList = []
    {
      this.state.companyListArray && this.state.companyListArray.map((item, key) => {
        var companyimg = ""
        if (item.company.image) {
          companyimg = item.company.image
        }
        if (item.company.__companydetail__.length > 0) {
          var name = ""
          item.company.__companydetail__.map((data, key1) => {
            if (data.__field__.id == "29") {//name
              name = data.fieldvalue;
            }
          })
          displayCompanyList.push(
            <div className="box_portlet mt-10 crm-calendar-event">
              <div className="box_body px-10 py-20 cursor_poiter">
                <div className="lead_pl" style={{ paddingLeft: '50px' }}>
                  <div className="icon_left">
                    <Fab
                      size="small"
                      aria-label="Task"
                      onClick={() => this.handleDrawerCompanyRight(item.company.id)}
                      className={`wrap_icons_task_sidebar vert-middle bg-grey_sidebar`}
                      style={{ margin: '-4px' }}
                    >

                      {!companyimg && <Avatar className="bg-body"><Icon className="activity_icon_normal">person</Icon></Avatar>}
                      {companyimg && <Avatar className="bg-body" src={companyimg}></Avatar>}

                    </Fab>

                  </div>
                  <Grid item className="flex-auto">
                    <a onClick={() => this.handleDrawerCompanyRight(item.company.id)} className={"cursor_poiter"}>
                      <Grid container justify="space-between" alignItems="center">
                        <h6 className="m-0 font-size-13 text_ellipsis" style={{ width: '100%' }}><span className="font-weight-500" style={{ color: '#000000de', fontWeight: '500' }}>{name ? name : ""}</span></h6>
                      </Grid>
                    </a>
                  </Grid>
                  <span className="related-text">{item.company.totalcontact + " Contacts"}</span>
                  <div className="hover_show" style={{ backgroundColor: '#FFF', top: '-21px', right: '-7px' }} >
                    <IconButton aria-label="Launch" size="small" onClick={() => this.handleCompanyLaunch(item.company.id)}>
                      <IconSmall style={{ fontSize: '18px' }}>launch</IconSmall>
                    </IconButton>
                    <IconButton aria-label="Delete" size="small" onClick={() => this.handleRemoveCompany(item.company.id)}>
                      <IconSmall style={{ fontSize: '18px' }}>close</IconSmall>
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
          )
        }

      })
    }
    /*END company Display*/

    return (
      <Card elevation={3} className="p-16 mt-16" style={{ overflow: 'initial' }}>
        <Grid container justify="space-between">
          <h5 className="flex-auto cursor_poiter mb-0 pt-4" onClick={this.toggleCompany}>Companies ({displayCompanyList.length}) <Icon className="vert-middle">{isOpenedCompany ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon></h5>
          <IconButton aria-label="Add" size="small">
            <IconSmall onClick={() => this.toggleAddCompany()} className={this.state.showAddCompany ? "plusIconTransform" : ""}>add</IconSmall>
          </IconButton>
        </Grid>
        {isOpenedCompany &&
          <>
            {this.state.showAddCompany &&
              <FormControl className="w-100 input_bx" style={{ zIndex: 10000 }}>
                <SingleSelect
                  className="add_singleselection"
                  SelectProps={{
                    isCreatable: this.state.compnayCrud.isaccess && this.state.compnayCrud.iscreate ? true : false,
                    formatCreateLabel,
                    isValidNewOption,
                  }}
                  placeholder="Add Company"
                  options={this.state.compnayCrud.isaccess && this.state.allCompanyList}
                  onChange={this.handleCompanyChange}
                />
              </FormControl>

            }
            {(!this.state.showAddCompany && displayCompanyList.length == 0) &&
              <label className="text-hint">
                <IconButton className="mr-8" component="span">
                  <Icon onClick={() => this.toggleAddCompany()} >business</Icon>
                </IconButton>
                                Companies
                            </label>
            }
            {displayCompanyList}

          </>
        }

        {this.state.shouldOpenCreateCompanyDialog && (
          <CreateNewCompanyDialog
            handleCloseCompany={() => this.handleCompanyDialogClose()}
            openCompany={this.state.shouldOpenCreateCompanyDialog}
            handleMinimizeCompany={() => this.handleCompanyDialogMinimize()}
            handleFullCompany={() => this.handleCompanyDialogFull()}
            minimizeScreenCompany={this.state.minimizeCompanyDialog}
            fullScreenCompany={this.state.FullCreateCompanyDialog}
            allstates={this.state}
            name={this.state.createNewCompanyName}
            opportunitiesid={this.state.opportunities_id}
            dataFromSidebar={this.state.dataFromSidebar}
          />
        )}

        <Dialog
          fullWidth={true}
          maxWidth={'xs'}
          open={this.state.removeCompanyPopup}
          onClose={this.toggleRemoveCompanyPopup}
          disableBackdropClick

          aria-labelledby="ar-remove-dialog-title"
        >
          <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to remove the Associated Company?"}</DialogTitle>
          <DialogActions>
            <Button onClick={this.toggleRemoveCompanyPopup} color="primary">
              Cancel
            </Button>
            <Button type="Button" onClick={() => this.confirmremoveCompanyPopup()} className="btn_Primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Drawer
          width={"100px"}
          variant="temporary"
          anchor={"right"}
          className="drawer_right"
          open={drawerOpenCompany}
          onClose={this.closeDrawerRight}
          ModalProps={{
            keepMounted: true
          }}
        >
          {this.state.company_id && <CompanyProfileFrame company_id={parseInt(this.state.company_id)} handleClose={this.closeDrawerRight} />}
        </Drawer>

        {/* <Drawer
          width={"100px"}
          variant="temporary"
          anchor={"right"}
          className="drawer_right"
          open={drawerOpen}
          onClose={this.closeDrawerRight}
          ModalProps={{
            keepMounted: true
          }}
        >
          <ProjectProfileFrame project_id={this.state.recordId} handleClose={this.closeDrawerRight} />
        </Drawer> */}
        {this.state.isDataLoading &&
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={this.state.isDataLoading}
            TransitionComponent={Fade}
            message={<><CircularProgress size={20} style={{ color: 'white' }} /><span style={{ paddingLeft: '6px', color: 'white' }}>{" Loading..."}</span></>}
            variant={'success'}
          />
        }
        {this.state.isAlreadyAddedCompany && <ListComponent recordId={parseInt(this.props.match.params.id)} recordtype={"project"} activedata={this.state.activedatas} />}
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
  opportunityReducer: state.opportunityReducer,
  companyReducer: state.companyReducer,
  authReducer: state.authReducer

});

const mapDispatchToProps = dispatch => {
  return {
    deleteOpportunitiesCompany: (client, req) => { dispatch(actionCreators.deleteOpportunitiesCompany(client, req)) },
    getCompanyRecList: (client) => { dispatch(actionCreators.getCompanyRecList(client)) },
    addNewOpportunitiesCompany: (client, req) => { dispatch(actionCreators.addNewOpportunitiesCompany(client, req)) },
    getRecordRelatedData: (client, req) => { dispatch(actionCreators.getRecordRelatedData(client, req)) },
    addProjectCompany: (client, req) => { dispatch(actionCreators.addProjectCompany(client, req)) },
    deleteProjectCompany: (client, req) => { dispatch(actionCreators.deleteProjectCompany(client, req)) },
    getRelatedMasterList: (client, request) => {
      dispatch(actionCreators.getRelatedMasterList(client, request));
    },
    getUserIntegration: (client) => dispatch(actionCreators.getUserIntegration(client)),
  }
}


export default (connect(mapStateToProps, mapDispatchToProps)(withRouter(Companies)));
