import React, { Component } from "react";
import ReactDragListView from "react-drag-listview";
import { Table } from 'antd';

class TableComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            leaddata: [],
            rows:[],
            inRows:[]
        };
       
    }   
    componentWillReceiveProps(props) {
        this.setState({ rows: this.props.activedata});
        // this.setState({ inRows: this.props.inActivedata });
    }

    render() {   
        
            const columns = [
                {
                    title: "Name",
                    dataIndex: "name",
                    className: "align_left",
                    onCell: () => {
                        return {
                            style: {
                                textAlign: 'left'
                            }
                        }
                    },
                },
                {
                    title: "Actions",
                    dataIndex: "action",
                    width: 120,
                    onCell: () => {
                        return {
                            style: {
                                whiteSpace: 'nowrap',
                                maxWidth: 120,
                                textAlign: 'right'
                            }
                        }
                    },
                }
            ];
            const inactiveColumns = [
   
                {
                    title: "Name",
                    dataIndex: "name",
                    className:"align_left"
                },
                {
                    title: "Actions",
                    dataIndex: "action",
                    width: 120,
                    onCell: () => {
                        return {
                            style: {
                                whiteSpace: 'nowrap',
                                maxWidth: 120,
                                textAlign: 'right'
                            }
                        }
                    },
                }
            ];
        const { rows, inRows } = this.state;

        return (
            <div>
                <div className="crm-custom-field-table" >
                    <React.Fragment>
                        <ReactDragListView handleSelector="a" onDragEnd={this.props.allData.updateFieldsOrder}>
                            <div className="table_width">
                                <Table
                                    columns={columns}
                                    pagination={false}
                                    dataSource={rows}
                                />
                            </div>
                        </ReactDragListView>
                    </React.Fragment>
                </div>
            
            </div>
        );
    }
}

export default TableComponent;
