import React, { Component } from "react";
import {
  Fab,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Chip,
  TextField,
  InputLabel,
} from "@material-ui/core";
import {
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { SingleSelect } from "react-select-material-ui";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { LabelBase } from "office-ui-fabric-react";
import CommonVisibiltyAdd from "../Layout/CommonVisibiltyAdd"


export const createContactDynamicFields = (data) => {
  var elements = data.state.fieldsList;
  var fieldsArray = [];
  const formatCreateLabel = (value) => `Create "${value}"`;
  const isValidNewOption = (value) => value != "";
  elements.map((v, i) => {
    if (v.fieldstatus === true) {
      if (
        v.fieldtype.id == "1" &&
        (v.fieldkey == "websites" ||
          v.fieldkey == "social" ||
          v.fieldkey == "phones" ||
          v.fieldkey == "email") &&
        v.is_default
      ) {
        //website ,social, phone, email
        {
          let tempFieldArray = [];

          data.state.fieldsObj[v.fieldkey].values &&
            data.state.fieldsObj[v.fieldkey].values.map((row, index) => {
              if (!row.id) {
                data.handleAddMoreField(
                  "id",
                  v.fieldkey,
                  index,
                  v.options[0].id
                );
              }

              if (v.fieldkey === "email") {
                //for email validation
                tempFieldArray.push(
                  <>
                    <Select
                      name={v.fieldkey}
                      id={v.id}
                      value={row.id}
                      disableUnderline
                      onChange={(event) =>
                        data.handleAddMoreField(
                          "id",
                          v.fieldkey,
                          index,
                          event.target.value
                        )
                      }
                      SelectDisplayProps={{
                        className: "multiple-text-dropdown",
                      }}
                    >
                      {v.options &&
                        v.options.map((item, key) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.optionvalue}
                          </MenuItem>
                        ))}
                    </Select>
                    <span style={{ color: "#0000008a" }}>
                      {v.recordcreationdefaultvalue == 2 ? "*" : ""}
                    </span>
                    <TextValidator
                      ref={(ref) => (data[v.fieldkey] = ref)}
                      onBlur={(event) => data.handleBlur(event, v.fieldkey)}
                      type="text"
                      id={v.id}
                      placeholder={`Add ${v.fieldkey && v.fieldkey.charAt(0).toUpperCase() + v.fieldkey.slice(1)}`}
                      className="w-100 input_bx"
                      onChange={(event) =>
                        data.handleAddMoreField(
                          "value",
                          v.fieldkey,
                          index,
                          event.target.value
                        )
                      }
                      name={v.fieldkey}
                      value={row.value}
                      disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                      validators={
                        v.recordcreationdefaultvalue == 2
                          ? ["required", "isEmail"]
                          : ["isEmail"]
                      }
                      errorMessages={
                        v.recordcreationdefaultvalue == 2
                          ? [
                            "This field is required",
                            "Please enter a valid email address.",
                          ]
                          : ["Please enter a valid email address."]
                      }
                    />
                  </>
                );
              } else {
                tempFieldArray.push(
                  <div className={row.srno > 1 && "mt-10"}>
                    <Select
                      name={v.fieldkey}
                      id={v.id}
                      value={row.id}
                      disableUnderline
                      onChange={(event) =>
                        data.handleAddMoreField(
                          "id",
                          v.fieldkey,
                          index,
                          event.target.value
                        )
                      }
                      SelectDisplayProps={{
                        className: "multiple-text-dropdown",
                      }}
                    >
                      {v.options &&
                        v.options.map((item, key) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.optionvalue}
                          </MenuItem>
                        ))}
                    </Select>
                    <span style={{ color: "#0000008a" }}>
                      {v.recordcreationdefaultvalue == 2 ? "*" : ""}
                    </span>
                    <TextValidator
                      ref={(ref) => (data[v.fieldkey] = ref)}
                      onBlur={(event) => data.handleBlur(event, v.fieldkey)}
                      type="text"
                      id={v.id}
                      placeholder={`Add ${v.fieldkey && v.fieldkey.charAt(0).toUpperCase() + v.fieldkey.slice(1)}`}
                      className="w-100 input_bx"
                      onChange={(event) =>
                        data.handleAddMoreField(
                          "value",
                          v.fieldkey,
                          index,
                          event.target.value
                        )
                      }
                      name={v.fieldkey}
                      value={row.value}
                      disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                      validators={
                        v.recordcreationdefaultvalue == 2 ? ["required"] : []
                      }
                      errorMessages={
                        v.recordcreationdefaultvalue == 2
                          ? ["This field is required"]
                          : []
                      }
                    />
                    {v.fieldkey != "email" &&
                      row.value &&
                      index ==
                      data.state.fieldsObj[v.fieldkey].values.length - 1 && (
                        <span
                          className="add-more-field"
                          type="button"
                          onClick={() => {
                            data.addNewField(v.fieldkey, v.options[0]);
                          }}
                        >
                          Add More
                        </span>
                      )}
                  </div>
                );
              }
            });
          fieldsArray.push(
            <Grid item xs={6}>
              {tempFieldArray}
            </Grid>
          );
        }
      } else if (v.fieldtype.id == "1" && v.id == "16") {

        fieldsArray.push(
          <>
            <Grid item xs={6}>
              {/* <FormControl className="w-100 input_bx"> */}
              {/* <InputLabel>First Name</InputLabel> */}
              <Autocomplete
                freeSolo
                forcePopupIcon={false}
                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                name={v.fieldkey}
                onChange={(event, values, fieldkey) =>
                  data.handleNameChange(event, values, v.fieldkey, valEmail)
                }
                id={v.fieldkey}
                options={data.state.gmailSuggestion}
                getOptionLabel={(option) => option.label ? option.label :  data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].contactvalue : ""}
                renderOption={(option) => (
                  <>
                    <Grid
                      container
                      className="mb-10 mt-10 crm-custom-suggestion-autocomplete"
                      spacing={1}
                    >
                      <Grid item md={2}>
                        {option.avtar ? (
                          <Fab
                            className="small-size overflow-hidden custom-avatar"
                            size="small"
                          >
                            <img
                              alt="Contact image"
                              src={option.avtar}
                              className="MuiAvatar-img"
                            ></img>
                          </Fab>
                        ) : (
                            <Fab
                              className="small-size overflow-hidden custom-avatar"
                              size="small"
                            >
                              <h5
                                className={
                                  "text-white m-0 font-weight-normal avatar_circle color_" +
                                  option.email.charAt(0)
                                }
                              >
                                {option.email.charAt(0).toUpperCase()}
                              </h5>
                            </Fab>
                          )}
                      </Grid>
                      <Grid className="text_ellipsis pt-5" item md={10}>
                        {option.label && (
                          <>
                            <span className="custom-label font-weight-500">
                              {option.label}
                            </span>
                            <br />
                          </>
                        )}
                        <span className="custom-email text-hint">
                          {option.email}
                        </span>
                      </Grid>
                    </Grid>
                  </>
                )}
                value={
                  data.state.fieldsObj[v.fieldkey]
                    ? data.state.fieldsObj[v.fieldkey].contactvalue
                    : ""
                }
                // value={data.state.fieldsObj[v.fieldkey] ? { label: data.state.fieldsObj[v.fieldkey].leadvalue, value: data.state.fieldsObj[v.fieldkey].id } : {}}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    variant="standard"
                    label={'First Name'}
                    fullWidth
                    ref={(ref) => (data[v.fieldkey] = ref)}
                    placeholder="Add First Name"
                    className="w-100 input_bx"
                    required={(v.recordcreationdefaultvalue == 2) ? true : false}
                    disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                    onChange={(event, fieldkey) =>
                      data.getChangeSuggestions(event, v.fieldkey, valEmail)
                    }
                    onBlur={(event) => data.handleBlur(event, v.fieldkey)}
                    name={v.fieldkey}
                    value={
                      data.state.fieldsObj[v.fieldkey]
                        ? data.state.fieldsObj[v.fieldkey].contactvalue
                        : ""
                    }
                    validators={
                      v.recordcreationdefaultvalue == 2 ? ["required"] : []
                    }
                    errorMessages={
                      v.recordcreationdefaultvalue == 2
                        ? ["This field is required"]
                        : []
                    }
                    InputLabelProps={{
                      shrink: true,
                      disableAnimation: false,
                      focused: true
                    }}
                  />
                )}
              />
              {/* </FormControl> */}
            </Grid>
            <Grid item xs={6}>
              <InputLabel>Last Name</InputLabel>
              <TextValidator
                type="text"
                id="lastname"
                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                className="w-100 input_bx"
                placeholder="Add Last Name"
                onChange={(event) => data.handleNormalFields(event)}
                name={"lastname"}
                value={data.state.lastname}
              />
            </Grid>
          </>
        );
      } else if (v.fieldtype.id == "1" && v.fieldkey == "tags") {
        //tags

        var valTag = [];
        if (data.state.fieldsObj[v.fieldkey]) {
          valTag = data.state.fieldsObj[v.fieldkey].values;
        }
        var valEmail = "";
        valEmail = data.state.fieldsObj.email.values[0].value;
        fieldsArray.push(
          <Grid item xs={6}>
            <InputLabel>Tags</InputLabel>
            <Autocomplete
              disableOpenOnFocus
              multiple
              value={valTag}
              filterSelectedOptions
              disabled={v.recordcreationdefaultvalue == 3 ? true : false}
              name={v.fieldkey}
              id="tagInputId1"
              options={data.state.systemTagsList.map(
                (option) => option.tagname
              )}
              freeSolo
              onChange={(event, value) =>
                data.handleChange({ fieldid: v.id, values: value }, v.fieldkey)
              }
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={option.charAt(0).toUpperCase() + option.slice(1)}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextValidator
                  ref={(ref) => (data[v.fieldkey] = ref)}
                  onBlur={(event) => data.handleBlur(event, v.fieldkey)}
                  {...params}
                  // label="Tags"
                  placeholder="Add Tag"
                  name={v.fieldkey}
                  value={valTag && valTag.length ? 1 : null}
                  className="w-100 input_bx"
                  required={v.recordcreationdefaultvalue == 2 ? true : false}
                  disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                  validators={
                    v.recordcreationdefaultvalue == 2 &&
                      (valTag == null || valTag.length == 0)
                      ? ["required"]
                      : []
                  }
                  errorMessages={
                    v.recordcreationdefaultvalue == 2 &&
                      (valTag == null || valTag.length == 0)
                      ? ["This field is required"]
                      : []
                  }
                  onFocus={() =>
                    setTimeout(() => {
                      document.getElementById("tagInputId1").value = "";
                    }, 0)
                  }
                />
              )}
            />
          </Grid>
        );
      } else if (v.fieldtype.id == "1" && v.id == "20") {
        // owner
        var nameOwner = [];
        if (data.state.fieldsObj[v.fieldkey]) {
          nameOwner = data.state.fieldsObj[v.fieldkey].values;
        }
        fieldsArray.push(
          <Grid item xs={6}>
            {/* <FormControl className="w-100 input_bx"> */}
            <InputLabel>Owner</InputLabel>
            <Autocomplete
              disableOpenOnFocus
              forcePopupIcon={false}
              name={v.fieldkey}
              options={data.state.usersListArray}
              getOptionLabel={(option) => option.name ? option.name : ""}
              value={nameOwner}
              disabled={v.recordcreationdefaultvalue == 3 ? true : false}
              name={v.fieldkey}
              onChange={(event, value) =>
                data.handleChange(
                  { fieldid: v.id, values: value },
                  v.fieldkey
                )
              }
              id={v.id}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  variant="standard"
                  // label={"Owner"}
                  placeholder="Add Owner"
                  fullWidth
                  className="w-100 input_bx"
                  ref={(ref) => (data[v.fieldkey] = ref)}
                  onBlur={(event) => data.handleBlur(event, v.fieldkey)}
                  name={v.fieldkey}
                  value={nameOwner && nameOwner.length ? 1 : null}
                  required={v.recordcreationdefaultvalue == 2 ? true : false}
                  disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                  validators={
                    v.recordcreationdefaultvalue == 2 &&
                      (nameOwner == null || nameOwner.length == 0)
                      ? ["required"]
                      : []
                  }
                  errorMessages={
                    v.recordcreationdefaultvalue == 2 &&
                      (nameOwner == null || nameOwner.length == 0)
                      ? ["This field is required"]
                      : []
                  }
                />
              )}
            />
            {/* </FormControl> */}
          </Grid>
        );
      } else if (v.fieldtype.id == "1") {
        //TextField
        fieldsArray.push(
          <Grid item xs={6}>
            <InputLabel>{v.fieldlabel}</InputLabel>
            <TextValidator
              type="text"
              ref={(ref) => (data[v.fieldkey] = ref)}
              onBlur={(event) => data.handleBlur(event, v.fieldkey)}
              required={v.recordcreationdefaultvalue == 2 ? true : false}
              id={v.id}
              disabled={v.recordcreationdefaultvalue == 3 ? true : false}
              className="w-100 input_bx"
              placeholder={`Add ${v.fieldlabel && v.fieldlabel.charAt(0).toUpperCase() + v.fieldlabel.slice(1)}`}
              onChange={(event) =>
                data.handleChange(
                  { fieldid: v.id, contactvalue: event.target.value },
                  v.fieldkey
                )
              }
              name={v.fieldkey}
              value={
                data.state.fieldsObj[v.fieldkey]
                  ? data.state.fieldsObj[v.fieldkey].contactvalue
                  : ""
              }
              validators={v.recordcreationdefaultvalue == 2 ? ["required"] : []}
              errorMessages={
                v.recordcreationdefaultvalue == 2
                  ? ["This field is required"]
                  : []
              }
            />
          </Grid>
        );
      } else if (
        v.fieldtype.id == "6" ||
        v.fieldtype.id == "10" ||
        v.fieldtype.id == "7"
      ) {
        // Number, Currency
        fieldsArray.push(
          <Grid item xs={6}>
            <InputLabel>{v.fieldlabel}</InputLabel>
            <TextValidator
              ref={(ref) => (data[v.fieldkey] = ref)}
              onBlur={(event) => data.handleBlur(event, v.fieldkey)}
              type="number"
              required={v.recordcreationdefaultvalue == 2 ? true : false}
              id={v.id}
              disabled={v.recordcreationdefaultvalue == 3 ? true : false}
              className="w-100 input_bx"
              // label={v.fieldlabel}
              placeholder={`Add ${v.fieldlabel && v.fieldlabel.charAt(0).toUpperCase() + v.fieldlabel.slice(1)}`}
              onChange={(event) =>
                data.handleChange(
                  { fieldid: v.id, contactvalue: event.target.value },
                  v.fieldkey
                )
              }
              name={v.fieldkey}
              value={
                data.state.fieldsObj[v.fieldkey]
                  ? data.state.fieldsObj[v.fieldkey].contactvalue
                  : ""
              }
              validators={v.recordcreationdefaultvalue == 2 ? ["required"] : []}
              errorMessages={
                v.recordcreationdefaultvalue == 2
                  ? ["This field is required"]
                  : []
              }
            />
          </Grid>
        );
      }
      if (v.fieldtype.id == "2" && v.id == "25") {
        // Text Area , Address
        var countryValidation = true;
        if (data.state.country != "" && data.state.country != null) {
          countryValidation = false;
        }
        fieldsArray.push(
          <>
            <Grid item xs={12}>
              <InputLabel>Address</InputLabel>
              <TextValidator
                ref={(ref) => (data[v.fieldkey] = ref)}
                onBlur={(event) => data.handleBlur(event, v.fieldkey)}
                multiline
                type="text"
                id={v.id}
                required={v.recordcreationdefaultvalue == 2 ? true : false}
                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                className="w-100 input_bx"
                // label={v.fieldlabel}
                placeholder="Street"
                onChange={(event) => data.handleNormalFields(event)}
                name={'street'}
                value={data.state.street}
                validators={
                  v.recordcreationdefaultvalue == 2 ? ["required"] : []
                }
                errorMessages={
                  v.recordcreationdefaultvalue == 2
                    ? ["This field is required"]
                    : []
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextValidator
                type="text"
                id={"city"}
                ref={(ref) => (data["city"] = ref)}
                onBlur={(event) => data.handleBlur(event, "city")}
                required={v.recordcreationdefaultvalue == 2 ? true : false}
                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                className="w-100 input_bx"
                placeholder={"City"}
                onChange={(event) => data.handleNormalFields(event)}
                name={"city"}
                value={data.state.city}
                validators={
                  v.recordcreationdefaultvalue == 2 ? ["required"] : []
                }
                errorMessages={
                  v.recordcreationdefaultvalue == 2
                    ? ["This field is required"]
                    : []
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextValidator
                ref={(ref) => (data["addressState"] = ref)}
                onBlur={(event) => data.handleBlur(event, "addressState")}
                type="text"
                required={v.recordcreationdefaultvalue == 2 ? true : false}
                id={"state"}
                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                className="w-100 input_bx"
                placeholder={"State"}
                onChange={(event) => data.handleNormalFields(event)}
                name={"addressState"}
                value={data.state.addressState}
                validators={
                  v.recordcreationdefaultvalue == 2 ? ["required"] : []
                }
                errorMessages={
                  v.recordcreationdefaultvalue == 2
                    ? ["This field is required"]
                    : []
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextValidator
                ref={(ref) => (data["zipcode"] = ref)}
                onBlur={(event) => data.handleBlur(event, "zipcode")}
                required={v.recordcreationdefaultvalue == 2 ? true : false}
                type="text"
                id={"zipcode"}
                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                className="w-100 input_bx"
                placeholder={"Zip code"}
                onChange={(event) => data.handleNormalFields(event)}
                name={"zipcode"}
                value={data.state.zipcode}
                validators={
                  v.recordcreationdefaultvalue == 2 ? ["required"] : []
                }
                errorMessages={
                  v.recordcreationdefaultvalue == 2
                    ? ["This field is required"]
                    : []
                }
              />
            </Grid>
            <Grid item xs={12}>
              {/* <FormControl className="w-100 input_bx"> */}
              <Autocomplete
                disableOpenOnFocus
                forcePopupIcon={false}
                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                name={"country"}
                onChange={data.handleCountryChange}
                id={"country"}
                value={data.state.country}
                options={data.state.countryListArray}
                getOptionLabel={(option) => option.name ? option.name : ""}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    variant="standard"
                    placeholder={"Country"}
                    ref={(ref) => (data["country"] = ref)}
                    onBlur={(event) => data.handleBlur(event, "country")}
                    fullWidth
                    className="w-100 input_bx"
                    name={v.fieldkey}
                    required={
                      v.recordcreationdefaultvalue == 2 ? true : false
                    }
                    validators={
                      v.recordcreationdefaultvalue == 2 && countryValidation
                        ? ["required"]
                        : []
                    }
                    errorMessages={
                      v.recordcreationdefaultvalue == 2 && countryValidation
                        ? ["This field is required"]
                        : []
                    }
                  />
                )}
              />
              {/* </FormControl> */}
            </Grid>
          </>
        );
      } else if (v.fieldtype.id == "2") {
        // Text Area
        fieldsArray.push(
          <Grid item xs={6}>
            <InputLabel>{v.fieldlabel}</InputLabel>
            <TextValidator
              ref={(ref) => (data[v.fieldkey] = ref)}
              onBlur={(event) => data.handleBlur(event, v.fieldkey)}
              multiline
              type="text"
              id={v.id}
              required={v.recordcreationdefaultvalue == 2 ? true : false}
              disabled={v.recordcreationdefaultvalue == 3 ? true : false}
              className="w-100 input_bx"
              placeholder={`Add ${v.fieldlabel && v.fieldlabel.charAt(0).toUpperCase() + v.fieldlabel.slice(1)}`}
              onChange={(event) =>
                data.handleChange(
                  { fieldid: v.id, contactvalue: event.target.value },
                  v.fieldkey
                )
              }
              name={v.fieldkey}
              value={
                data.state.fieldsObj[v.fieldkey]
                  ? data.state.fieldsObj[v.fieldkey].contactvalue
                  : ""
              }
              validators={v.recordcreationdefaultvalue == 2 ? ["required"] : []}
              errorMessages={
                v.recordcreationdefaultvalue == 2
                  ? ["This field is required"]
                  : []
              }
            />
          </Grid>
        );
      } else if (v.fieldtype.id == "9") {
        //TextField
        fieldsArray.push(
          <Grid item xs={6}>
            <InputLabel>{v.fieldlabel}</InputLabel>
            <TextValidator
              type="text"
              ref={(ref) => (data[v.fieldkey] = ref)}
              onBlur={(event) => data.handleBlur(event, v.fieldkey)}
              required={v.recordcreationdefaultvalue == 2 ? true : false}
              id={v.id}
              disabled={v.recordcreationdefaultvalue == 3 ? true : false}
              className="w-100 input_bx"
              placeholder={`Add ${v.fieldlabel && v.fieldlabel.charAt(0).toUpperCase() + v.fieldlabel.slice(1)}`}
              onChange={(event) =>
                data.handleChange(
                  { fieldid: v.id, contactvalue: event.target.value },
                  v.fieldkey
                )
              }
              name={v.fieldkey}
              value={
                data.state.fieldsObj[v.fieldkey]
                  ? data.state.fieldsObj[v.fieldkey].contactvalue
                  : ""
              }
              validators={v.recordcreationdefaultvalue == 2 ? ["required", "IsURLValid"] : ["IsURLValid"]}
              errorMessages={
                v.recordcreationdefaultvalue == 2
                  ? ["This field is required", "Please enter a valid domain"]
                  : ["Please enter a valid domain"]
              }
            />
          </Grid>
        );
      }

      if (v.fieldtype.id == "4") {
        // Drop Down
        if (v.id === "26" || v.id === "19") {// Visibility, contacttype'
          //26 -visibility with team start
          if (v.id === "26") {
            fieldsArray.push(
              <CommonVisibiltyAdd data={data} v={v} />
            )
          }
          //26-visibility with team end
          else {
            fieldsArray.push(
              <Grid item xs={6}>
                {/* <FormControl className="w-100 input_bx"> */}
                <InputLabel>{v.fieldlabel}</InputLabel>
                <SelectValidator
                  ref={(ref) => (data[v.fieldkey] = ref)}
                  disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                  value={
                    data.state.fieldsObj[v.fieldkey]
                      ? data.state.fieldsObj[v.fieldkey].values
                      : ""
                  }
                  onChange={(event) =>
                    data.handleChange(
                      { fieldid: v.id, values: event.target.value },
                      v.fieldkey
                    )
                  }
                  className="w-100 input_bx"
                  name={v.fieldlabel}
                  id={v.id}
                  required={v.recordcreationdefaultvalue == 2 ? true : false}
                  // label={v.fieldlabel}
                  validators={
                    v.recordcreationdefaultvalue == 2 ? ["required"] : []
                  }
                  errorMessages={
                    v.recordcreationdefaultvalue == 2
                      ? ["This field is required"]
                      : []
                  }
                >
                  {v.options &&
                    v.options.map((item, key) => (
                      <MenuItem
                        className="select-menu-item w-100 input_bx"
                        key={item.id}
                        value={item}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  {v.options && v.id == "19" && (
                    <MenuItem
                      className="select-menu-item item-with-link"
                      value={5}
                    >
                      <Link to="/settings/contact-types">
                        Customize Contact Types
                      </Link>
                    </MenuItem>
                  )}
                </SelectValidator>
                {/* </FormControl> */}
              </Grid>
            );
          }
        } else if (v.id === "17") {
          // company

          fieldsArray.push(
            <Grid item xs={6} style={{ zIndex: 20 }}>
              {/* <FormControl className="w-100 input_bx " style={{ zIndex: 20 }}> */}
              <InputLabel
              // style={{ fontSize: "15px", marginTop: "-6px" }}
              // shrink={
              //   data.state.comp_value || data.state.fcus1 ? true : false
              // }
              >
                {" "}
                {v.fieldlabel}
              </InputLabel>
              <SingleSelect

                className="w-100 input_bx add_singleselection "
                required={v.recordcreationdefaultvalue == 2 ? true : false}
                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                value={data.state.comp_value}
                SelectProps={{
                  isCreatable: data.state.compnayCrud.isaccess && data.state.compnayCrud.iscreate ? true : false,
                  formatCreateLabel,
                  isValidNewOption,

                }}
                placeholder="Add Company"
                options={data.state.compnayCrud.isaccess && data.state.comp_options}
                onChange={data.handleCompanyChange}
                onFocus={() => data.setState({ fcus1: true })}
                onBlur={() => data.setState({ fcus1: false })}
                onCreateOption={data.handleCompanyCreate}
                validators={
                  v.recordcreationdefaultvalue == 2 ? ["required"] : []
                }
                errorMessages={
                  v.recordcreationdefaultvalue == 2
                    ? ["This field is required"]
                    : []
                }
              />
              {/* </FormControl> */}
            </Grid >
          );
        } else {
          fieldsArray.push(
            <Grid item xs={6}>
              <InputLabel>{v.fieldlabel && v.fieldlabel.charAt(0).toUpperCase() + v.fieldlabel.slice(1)}</InputLabel>
              <SelectValidator
                ref={(ref) => (data[v.fieldkey] = ref)}
                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                value={
                  data.state.fieldsObj[v.fieldkey]
                    ? data.state.fieldsObj[v.fieldkey].values
                    : "none"
                }
                className="w-100 input_bx"
                onChange={(event) =>
                  data.handleChange(
                    { fieldid: v.id, values: event.target.value },
                    v.fieldkey
                  )
                }
                name={v.fieldlabel}
                id={v.id}
                required={v.recordcreationdefaultvalue == 2 ? true : false}
                validators={
                  v.recordcreationdefaultvalue == 2 ? ["required"] : []
                }
                errorMessages={
                  v.recordcreationdefaultvalue == 2
                    ? ["This field is required"]
                    : []
                }
              >
                <MenuItem value="none" disabled style={{ display: 'none' }}><span className="text-hint">{`Add ${v.fieldlabel && v.fieldlabel.charAt(0).toUpperCase() + v.fieldlabel.slice(1)}`}</span></MenuItem>
                {v.options &&
                  v.options.map((item, key) => (
                    <MenuItem
                      className="select-menu-item w-100 input_bx"
                      key={item.id}
                      value={item}
                    >
                      {item.optionvalue}
                    </MenuItem>
                  ))}
              </SelectValidator>
            </Grid>
          );
        }
      }
      if (v.fieldtype.id == "5") {
        // Multi Drop Down
        if (data.state.fieldsObj[v.fieldkey]) {
          var valDetail = [];
          valDetail = data.state.fieldsObj[v.fieldkey].values;
        }
        fieldsArray.push(
          <Grid item xs={6}>
            <InputLabel>{v.fieldlabel}</InputLabel>
            <Autocomplete
              multiple
              disabled={v.recordcreationdefaultvalue == 3 ? true : false}
              name={v.fieldkey}
              onChange={(event, value) =>
                data.handleChange(
                  { fieldid: v.id, values: value },
                  v.fieldkey
                )
              }
              id={v.id}
              options={v.options}
              getOptionLabel={(option) => option.optionvalue ? option.optionvalue : ""}
              renderInput={(params) => (
                <TextValidator
                  ref={(ref) => (data[v.fieldkey] = ref)}
                  onBlur={(event) => data.handleBlur(event, v.fieldkey)}
                  {...params}
                  variant="standard"
                  placeholder={`Add ${v.fieldlabel && v.fieldlabel.charAt(0).toUpperCase() + v.fieldlabel.slice(1)}`}
                  fullWidth
                  name={v.fieldkey}
                  className="w-100 input_bx"
                  value={valDetail && valDetail.length ? 1 : null}
                  required={v.recordcreationdefaultvalue == 2 ? true : false}
                  validators={
                    v.recordcreationdefaultvalue == 2 &&
                      (valDetail == null || valDetail.length == 0)
                      ? ["required"]
                      : []
                  }
                  errorMessages={
                    v.recordcreationdefaultvalue == 2 &&
                      (valDetail == null || valDetail.length == 0)
                      ? ["This field is required"]
                      : []
                  }
                />
              )}
            />
          </Grid >
        );
      }
      if (v.fieldtype.id == "3") {
        //Checkbox
        fieldsArray.push(
          <Grid item xs={6}>
            <FormControl className="w-100 input_bx">
              <FormControlLabel
                control={
                  <Checkbox
                    name={v.fieldkey}
                    id={v.id}
                    checked={data.state.checkedB}
                    disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                    onChange={(event, value) =>
                      data.handleChange(
                        { fieldid: v.id, contactvalue: value ? "1" : "0" },
                        v.fieldkey
                      )
                    }
                    value={
                      data.state.fieldsObj[v.fieldkey]
                        ? data.state.fieldsObj[v.fieldkey].contactvalue
                        : ""
                    }
                    color="primary"
                  />
                }
                label={<span className="font-size-13">{v.fieldlabel}</span>}
              />
            </FormControl>
          </Grid>
        );
      }
      if (v.fieldtype.id == "8") {
        //datepicker field
        var dtFormat = "MM/dd/yyyy";
        var selectedDate = data.state.selectedDate;
        if (data.state.fieldsObj[v.fieldkey]) {
          selectedDate = data.state.fieldsObj[v.fieldkey].values;
        }

        if (data.state.userSettings != "") {
          dtFormat = data.state.userSettings.dateformat;
          dtFormat = dtFormat.replace("DD", "dd").replace("YYYY", "yyyy");
        }
        fieldsArray.push(
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <FormControl className="w-100 input_bx">
                <KeyboardDatePicker
                  label={v.fieldlabel}
                  format={dtFormat}
                  required={v.recordcreationdefaultvalue == 2 ? true : false}
                  disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                  placeholder={dtFormat.replace("DD", "dd").replace("YYYY", "yyyy").replace("MM", "mm")}
                  error={
                    (v.recordcreationdefaultvalue == 2 &&
                      selectedDate == null) ||
                      selectedDate == "Invalid Date"
                      ? true
                      : false
                  }
                  value={selectedDate}
                  onChange={(event, value) =>
                    data.handleChange(
                      {
                        fieldid: v.id,
                        values:
                          event == "Invalid Date" ? "Invalid Date" : event,
                      },
                      v.fieldkey
                    )
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  helperText={
                    v.recordcreationdefaultvalue == 2 && selectedDate == null
                      ? "This field is required"
                      : selectedDate == "Invalid Date"
                        ? "Please enter a valid date."
                        : ""
                  }
                />
              </FormControl>
            </MuiPickersUtilsProvider>
          </Grid>
        );
      }
    }
  });

  return fieldsArray;
};
