import React, { Component } from "react";
import SettingSidebar from '../../Layout/SettingSidebarMenu';
import { Box, IconButton, Card, Icon, Tab, Tabs, Typography, Button, Checkbox, Chip, Fab, FormControl, Grid, ListItemText, MenuItem, Select, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter } from "@material-ui/core";
import moment from 'moment';
import PropTypes from 'prop-types';

import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";


class BillingMain extends Component {
  constructor(props) {
    super(props)
    this.state = {
      billingPlans: [],
      countryListArray:[],
      selected_plan:[],
    }
  }

  componentDidMount = () => {
    this.props.getPlanList(this.props.apolloClient.client);
    // this.props.getPlanDetail(this.props.apolloClient.client);
    this.props.getCountryList(this.props.apolloClient.client);
  }

  componentWillReceiveProps(props){
    if(props.settingReducer.billingplans_list && props.settingReducer.billingplans_list.data) {
      let billing_plans = props.settingReducer.billingplans_list.data.data;
      
      this.setState({billingPlans: billing_plans})
    }
    if(props.settingReducer.selected_plan_details && props.settingReducer.selected_plan_details.data){
      let selected_plan = props.settingReducer.selected_plan_details.data;
      this.setState({selected_plan})
    }
    if (props.settingReducer.countryListGql.data && this.state.countryListArray.length == 0) {
      const countryies = props.settingReducer.countryListGql.data;
      this.setState({ countryListArray: countryies })
    }
  }

  handleSelectPlan = (event, plan) => {
    // this.props.history.push({
    //   pathname: '/settings/stripe/'+plan,
    //   // search: '?query=abc',
    //   state: { planid: plan }
    // })

    localStorage.setItem('billingPlan',JSON.stringify(plan))
    localStorage.setItem('countryList',JSON.stringify(this.state.countryListArray))
    localStorage.setItem('selectedPlan',JSON.stringify(this.state.selected_plan))
    
    this.props.history.push('/settings/checkout');
  }
  
  render() {
    const { billingPlans } = this.state;
    // (billingPlans.plan && (billingPlans.plan[4].id != 5)) &&
      console.log(billingPlans.plan, 'billingPlans');

    return (
      <React.Fragment>
        <div className='h-100' style={{overflow:'auto'}}>
          <Typography variant="h5" color="inherit" className="top_typograpy mb-20">Compare Plan Features</Typography> 
          <Card className="mt-30">
            <TableContainer component={Paper}>
              <Table className={""} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell width={250} align="center">Details</TableCell>
                    {billingPlans.plan && billingPlans.plan.map((plandata)=>(
                      (plandata.id == 1 || plandata.id == 2 ||plandata.id == 3) &&
                        <TableCell align="center">{plandata.planname}</TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell width={250} align="center"></TableCell>
                    {billingPlans.plan && billingPlans.plan.map((plandata)=>(
                      (plandata.id == 1 || plandata.id == 2 ||plandata.id == 3) &&
                      <TableCell align="center">{plandata.subname}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {billingPlans.permission && billingPlans.permission.map((permission)=>(
                    <TableRow>
                      {permission.value1 == undefined ?
                        <TableCell width={250} align={"left"} ><h5 className={"text-primary"}>{permission.name}</h5></TableCell>
                      :
                        <TableCell width={250} align={"left"}>{permission.name}</TableCell>
                      }
                      <TableCell width={180} align="center">{(typeof(permission.value1) != "boolean") ? permission.value1 : (permission.value1) ? <Icon>check</Icon> : <Icon>close</Icon>}</TableCell>
                      <TableCell width={180} align="center">{(typeof(permission.value2) != "boolean") ? permission.value2 : (permission.value2) ? <Icon>check</Icon> : <Icon>close</Icon>}</TableCell>
                      <TableCell width={180} align="center">{(typeof(permission.value3) != "boolean") ? permission.value3 : (permission.value3) ? <Icon>check</Icon> : <Icon>close</Icon>}</TableCell>
                      {/* <TableCell width={180} align="center">{(typeof(permission.value4) != "boolean") ? permission.value4 : (permission.value4) ? <Icon>check</Icon> : <Icon>close</Icon>}</TableCell>
                      <TableCell width={180} align="center">{(typeof(permission.value5) != "boolean") ? permission.value5 : (permission.value5) ? <Icon>check</Icon> : <Icon>close</Icon>}</TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell width={250} align="center"></TableCell>
                    {billingPlans.plan && billingPlans.plan.map((plandata)=>(
                      (plandata.id == 1 || plandata.id == 2 ||plandata.id == 3) &&
                        <TableCell width={180} align="center">
                          {plandata.isselected ? 
                            <Button disabled={true} className="text-green" > <Icon className="mr-10">check_circle</Icon> Selected </Button>
                          :
                            <Button variant="contained" color="primary" onClick={(event, plan)=>this.handleSelectPlan(event, plandata)} > Select {plandata.planname} </Button>
                          }
                        </TableCell>
                    ))}
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Card> 
        </div>
      </React.Fragment>
    );
  }
}


const mapStateToProps = (state) => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
});
const mapDispatchToProps = (dispatch) => {
  return {
    getPlanList: (client) => dispatch(actionCreators.getPlanList(client)),
    // getPlanDetail: (client) => dispatch(actionCreators.getPlanDetail(client)),
    getCountryList: (client) => dispatch(actionCreators.getCountryList(client)),
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BillingMain);