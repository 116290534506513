import React, { Component } from "react";
import { Paper, Grid, Checkbox, IconButton, Icon, MenuItem } from "@material-ui/core";
import DropdownDefault from "../../Layout/DropdownDefault";

class TaskAutomationLead extends Component {
    state = {
    };
    render() {
        return (
            <React.Fragment>
                <Paper variant="outlined" square className="paper_bx">
                    <Grid container spacing={1}>
                        <Grid item className="automation_icon">
                        <Checkbox value="checkbox" color="secondary" inputProps={{ 'aria-label': 'checkbox' }} />                        
                        </Grid>
                        <Grid item className="automation_details flex-auto">
                        <p>Automation name <span className="font-weight-semi">Regarding new lead</span></p>
                        <p>Trigger <span className="font-weight-semi">If a new Lead is created,</span></p>
                        <p>Task action <span className="font-weight-semi">then create a task named Send welcom message and assign to Amit Chauhan with due date set to 1 day later</span></p>                            
                        <p className="text-align-right text-mute">Created date <span className="font-weight-semi">22th December 2019</span></p>
                        </Grid>
                    </Grid>
                    <DropdownDefault
                            menuButton={
                                <IconButton
                                    aria-label="More"
                                    aria-haspopup="true"                                    
                                    className="list_action"
                                    size="small"
                                >
                                    <Icon>more_vert</Icon>
                                </IconButton>
                            }
                            >
                            <MenuItem><Icon className="action_icon">edit</Icon> Edit</MenuItem>
                            <MenuItem><Icon className="action_icon">delete</Icon> Delete</MenuItem>
                        </DropdownDefault>
                </Paper>
                <Paper variant="outlined" square className="paper_bx">
                    <Grid container spacing={1}>
                        <Grid item className="automation_icon">
                        <Checkbox value="checkbox" color="secondary" inputProps={{ 'aria-label': 'checkbox' }} />                        
                        </Grid>
                        <Grid item className="automation_details flex-auto">
                        <p>Automation name <span className="font-weight-semi">Regarding new lead</span></p>
                        <p>Trigger <span className="font-weight-semi">If a new Lead is created,</span></p>
                        <p>Task action <span className="font-weight-semi">then create a task named Send welcom message and assign to Amit Chauhan with due date set to 1 day later</span></p>                            
                        <p className="text-align-right text-mute">Created date <span className="font-weight-semi">22th December 2019</span></p>
                        </Grid>
                    </Grid>
                    <DropdownDefault
                            menuButton={
                                <IconButton
                                    aria-label="More"
                                    aria-haspopup="true"                                    
                                    className="list_action"
                                    size="small"
                                >
                                    <Icon>more_vert</Icon>
                                </IconButton>
                            }
                            >
                            <MenuItem><Icon className="action_icon">edit</Icon> Edit</MenuItem>
                            <MenuItem><Icon className="action_icon">delete</Icon> Delete</MenuItem>
                        </DropdownDefault>
                </Paper>
            </React.Fragment>
        )
    }
}
export default TaskAutomationLead;