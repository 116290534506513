import React, { Component } from "react";
import SettingSidebar from '../../../Layout/SettingSidebarMenu';
import { Box, IconButton, Card, Icon, Tab, Tabs, Typography, Button, Checkbox, Chip, Fab, FormControl, Grid, ListItemText, MenuItem, Select, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter } from "@material-ui/core";
import moment from 'moment';
import PropTypes from 'prop-types';
import GoalsDialog from "./GoalsDialog"
import MonthlyGoals from "./MonthlyGoals"
import QuatrterlyGoals from "./QuatrterlyGoals"
import YearlyGoals from "./YearlyGoals"


import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from '../../../../store/actions/index';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={2}>{children}</Box>
    </Typography>
  );
}
const names = [
  'All activity',
  'Email',
  'Meeting',
  'Phone call'

];
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}
function a11in1yProps(index) {
  return {
    id: `tabinside1-${index}`,
    'aria-controls': `tabpanelin1-${index}`,
  };
}
function a11in2yProps(index) {
  return {
    id: `tabinside2-${index}`,
    'aria-controls': `tabpanelin2-${index}`,
  };
}
function a11in3yProps(index) {
  return {
    id: `tabinside2-${index}`,
    'aria-controls': `tabpanelin2-${index}`,
  };
}
function a11in4yProps(index) {
  return {
    id: `tabinside2-${index}`,
    'aria-controls': `tabpanelin2-${index}`,
  };
}

class WonOpportunitiesTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userlist: 10,
      addPipeline: 1,
      shouldOpenSaleRevenueGoalsDialog: false,
      FullGoalDialog: false,
      minimizeGoalDialog: false,
      value: 0,
      valueone: 0,
      valuetwo: 0,
      valuethree: 0,
      valuefour: 0,
      personName: ['All activity'],
      date: moment(),
      weeklist: [],
      monthList: [],
      quaterList: [],
      yearList: [],
      usersListArray:[],
      teamList:[], 

      datetype: 1,
      goaltype: 2,
    }
  }

  componentDidMount = () => {
    this.props.getUserList(this.props.apolloClient.client);
    this.props.getTeam(this.props.apolloClient.client);
  }

  componentWillReceiveProps(props){
    
      if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
        const usersListArray = props.settingReducer.usersListArray.data;
        this.setState({ usersListArray: usersListArray })
      }
      
      if (props.settingReducer.teamList && props.settingReducer.teamList.data) {
        const teamList = props.settingReducer.teamList.data;
        this.setState({ teamList: teamList })
      }
  }

  handleOneChange = (event, newValue) => {
    this.setState({ valueone: newValue, date: moment() })
  }

  render() {
    const { usersListArray, addPipeline, shouldOpenSaleRevenueGoalsDialog, minimizeGoalDialog, FullGoalDialog, value, valueone, valuetwo, valuethree, valuefour } = this.state;
    
    return (
      <React.Fragment>
        <Paper variant="outlined" square className="tabs_paper">
          <Tabs
            value={valueone}
            onChange={this.handleOneChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            className="settings_tabs"
            aria-label="scrollable auto tabs"
          >
            <Tab label="MONTHLY GOALS" className="pl-10 pr-10" {...a11in1yProps(0)} />
            <Tab label="QUARTERLY GOALS" className="pl-10 pr-10"  {...a11in1yProps(1)} />
            <Tab label="YEARLY GOALS" className="pl-10 pr-10"  {...a11in1yProps(2)} />
          </Tabs>
          <TabPanel value={valueone} index={0} className="h-100 setting_goal">
            <MonthlyGoals data={this} goaltype={2} />
          </TabPanel>
          <TabPanel value={valueone} index={1} className="h-100 setting_goal">
            <QuatrterlyGoals data={this} goaltype={2} />
          </TabPanel>
          <TabPanel value={valueone} index={2} className="h-100 setting_goal">
            <YearlyGoals data={this} goaltype={2} />
          </TabPanel>
        </Paper>
        
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,    
});

const mapDispatchToProps = dispatch => {
  return {
      getUserList: (client) => dispatch(actionCreators.getUserList(client)),
      getTeam: (client) => dispatch(actionCreators.getTeam(client)),
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(WonOpportunitiesTab);