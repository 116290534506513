import * as actionTypes from './actionTypes';
import { gql } from "apollo-boost";
import axios from 'axios';
import * as dataSet from './dataSet';
import * as actionCreators from './index';
import Server from "../../Common/Server"

const sessionToken = localStorage.getItem('jwt_token');

const config = {
    headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'token': sessionToken,
        'api_for': 1
    },
};

const configForIntregation = {
    headers: {
        'Content-Type': 'application/json',
        Authorization: sessionToken ? `Bearer ${sessionToken}` : "",

    },
};

const configForIntregationB = {
    headers: {
        'Content-Type': 'application/json',
        Authorization: sessionToken ? `bearer ${sessionToken}` : "",

    },
};


const configForFile = {
    headers: {
        'content-type': 'multipart/form-data',
        'token': sessionToken,
        'api_for': 1
    }
}


export const settingAddUser = (value) => {
    return function (dispatch) {
        return axios.post(Server.API + "UserAdd/AddUser", value, config)
            .then(({ data }) => {
                let return_data = dataSet.processData(data);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data));
                dispatch(setResponseDetails(actionTypes.SETTING_ADD_USER, return_data));
                fetchData(dispatch)
            }).catch(function (error) {
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            });
    };
};

export const getSettingUserList = (value) => {
    return function (dispatch) {
        return axios.post(Server.API + "UserList/ListUser", value, config)
            .then(({ data }) => {
                let return_data = dataSet.processData(data);
                //let newstate = Object.assign(return_data , value)
                //dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data));  
                dispatch(setResponseDetails(actionTypes.GET_SETTING_USER_LIST, return_data));
            }).catch(function (error) {
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            });
    };
};

export const getUserById = (value) => {
    return function (dispatch) {
        return axios.post(Server.API + "GetUser/UserDetail", value, config)
            .then(({ data }) => {
                let return_data = dataSet.processData(data);
                //let newstate = Object.assign(return_data , value)
                //dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data));  
                dispatch(setResponseDetails(actionTypes.GET_USER_BY_ID, return_data));
            }).catch(function (error) {
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            });
    };
};

export const inviteUserDelete = (value) => {
    return function (dispatch) {
        return axios.post(Server.API + 'DeleteUser/UserDelete', value, config)
            .then(({ data }) => {
                let return_data = dataSet.processData(data);
                fetchData(dispatch)
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data));
            })
            .catch(function (error) {
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            })
    }
}

const fetchData = (dispatch) => {
    let setMultipleCompanyId = localStorage.getItem('setMultipleCompanyId');
    let fetch_data = '';
    if (setMultipleCompanyId !== null) {
        fetch_data = { companyid: setMultipleCompanyId }
    } else {
        fetch_data = { companyid: JSON.parse(localStorage.getItem('user_data_session')).companyId }
    }

    dispatch(actionCreators.getSettingUserList(fetch_data))
}

export const saveEditUser = (value) => {
    return function (dispatch) {
        return axios.post(Server.API + 'UserEdit/EditUser', value, config)
            .then(({ data }) => {
                let oldsession = JSON.parse(localStorage.getItem('user_data_session'))
                let maindata = data.data;
                console.log(maindata.email, oldsession.email)
                if (oldsession.email === maindata.email) {
                    oldsession.name = maindata.name
                    oldsession.title = maindata.title
                    oldsession.googlesync = maindata.googlesync
                    oldsession.dropbox = maindata.dropbox
                    oldsession.dataexport = maindata.dataexport
                    localStorage.setItem("user_data_session", JSON.stringify(oldsession));
                }
                let return_data = dataSet.processData(data);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data));
                fetchData(dispatch)
            }).catch(function (error) {
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            })
    }
}

export const getUserProfileInfoFirst = (value) => {
    return function (dispatch) {
        return axios.post(Server.API + 'GetUser/UserDetail', value, config)
            .then(({ data }) => {
                let return_data = dataSet.processData(data);
                dispatch(setResponseDetails(actionTypes.GET_USER_PROFILE_INFO_FIRST, return_data));
                //dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data));
            }).catch(function (error) {
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            })
    }
}

export const getBussinessType = () => {
    return function (dispatch) {
        return axios.post(Server.API + 'GetBusiness/BusinessType', '', config)
            .then(({ data }) => {
                let return_data = dataSet.processData(data);
                //dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data));
                dispatch(setResponseDetails(actionTypes.GET_BUSSINESS_TYPE, return_data));
            }).catch(function (error) {
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            })
    }
}


export const GetCompanyStrength = () => {
    return function (dispatch) {
        return axios.post(Server.API + 'GetStrength/CompanyStrength', '', config)
            .then(({ data }) => {
                let return_data = dataSet.processData(data);
                //dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data));
                dispatch(setResponseDetails(actionTypes.GET_COMPANY_STRENGTH, return_data));
            }).catch(function (error) {
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            })
    }
}

export const updateCompanyInfo = (value) => {
    return function (dispatch) {
        return axios.post(Server.API + 'UpdateInfo/CompanyInfo', value, configForFile)
            .then(({ data }) => {
                let return_data = dataSet.processData(data);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data));
                //dispatch(setResponseDetails(actionTypes.GET_COMPANY_STRENGTH, return_data));
            }).catch(function (error) {
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            })
    }
}

export const companyListById = (value) => {
    return function (dispatch) {
        return axios.post(Server.API + 'CompanyList/ListCompany', value, config)
            .then(({ data }) => {
                let return_data = dataSet.processData(data);
                //dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data));
                dispatch(setResponseDetails(actionTypes.COMPANY_LIST_BY_ID, return_data));
            }).catch(function (error) {
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            })
    }
}

export const updateUser = (value) => {
    return function (dispatch) {
        return axios.post(Server.API + 'UpdateDetail/UserUpdate', value, config)
            .then(({ data }) => {
                let resp = { userid: value.userid, type: "profile" }
                localStorage.setItem("user_data_session", JSON.stringify(data.data));
                dispatch(actionCreators.getUserById(resp))
                let return_data = dataSet.processData(data);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data, true));
            }).catch(function (error) {
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            })
    }
}

export const updateAllCompanyUser = (value) => {
    return function (dispatch) {
        return axios.post(Server.API + 'UpdateDetail/UserUpdate', value, config)
            .then(({ data }) => {
                let oldsession = JSON.parse(localStorage.getItem('user_data_session'))
                let maindata = data.data;
                let resp = {};
                let maiemail = oldsession.email.replace(/\s/g, '');
                let newemail = maindata.email.replace(/\s/g, '');
                if (maiemail !== newemail) {
                    resp = { userid: value.userid, type: "user" }
                    console.log("NOT I M")
                } else {
                    console.log("I M")
                    resp = { userid: oldsession.id, type: "profile" }
                    localStorage.setItem("user_data_session", JSON.stringify(data.data));
                }
                dispatch(actionCreators.getUserById(resp))
                let return_data = dataSet.processData(data);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data, true));
            }).catch(function (error) {
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            })
    }
}

export const notifications = (value) => {
    return {
        type: actionTypes.NOTIFICATIONS,
        val: value
    }
}

const setResponseDetails = (newType, data) => {
    return {
        type: newType,
        val: data
    };
}
const notificationAll = (newType, data, status) => {
    return {
        type: newType,
        val: data,
        status: status,
    }
}
const errorMessageAll = (error) => {
    let msg = 'Something Went Wrong!';
    if (error) {
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
            //msg = error.graphQLErrors[0].message;
            error = error.graphQLErrors;
            if (error.result && error.result.errors && error.result.errors.length > 0) {
                msg = error.result.errors[0].message;
            }
        } else if (error.networkError && error.networkError.length > 0) {
            //msg = error.networkError[0].message;
            error = error.graphQLErrors;
            if (error.result && error.result.errors && error.result.errors.length > 0) {
                msg = error.result.errors[0].message;
            }
        }
    }
    return {
        notify_msg: msg,
        error: true
    }

}

export const countryList = (value) => {
    return function (dispatch) {
        return axios.post(Server.API + 'GetCountry/CountryList', value, config)
            .then(({ data }) => {
                let return_data = dataSet.processData(data);
                dispatch(notificationAll(actionTypes.COUNTRY_LIST, return_data));
                //dispatch(setResponseDetails(actionTypes.UPDATE_USER, return_data));
            })
            .catch(function (error) {
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            })
    }
}



/**
 * TYPE : GET
 * Getting Custom Field list
 */
export const customFieldTypeList = (client) => {

    return function (dispatch) {
        const query = gql`
            query getFieldTypeList
            {
                getFieldTypeList {
                   id
                   name
                   fieldicon
                }
            }
        `;
        client.query({
            query: query
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getFieldTypeList);
            dispatch(setResponseDetails(actionTypes.COSTOM_FIELD_TYPE_LIST, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

/**
 * TYPE : GET
 * Getting Custom Field list
 */
export const customFieldList = (client, value) => {

    return function (dispatch) {
        const query = gql`
                query getCustomFieldList($typeId: Float!) 
                {
                    getCustomFieldList(typeId: $typeId) {
                        id
                        recordtypeid
                        customefieldmasterid
                        fieldlabel
                        fieldtype{
                            fieldicon
                            id
                            name
                        }
                        fieldkey
                        recordcreationrules_editable
                        recordeditrules_editable
                        isdiactivated
                        includeinfilter
                        recordcreationdefaultvalue
                        recordeditdefaultvalue
                        generatedfieldid
                        autogeneratefieldkey
                        is_default
                        fieldstatus
                        createdby
                        createdhost
                        createddate
                        lastmodifiedby
                        lastmodifiedhost
                        lastmodifieddate
                        orderno
                        recordcreationrulesvalue
                        recordeditrulesvalue
                        fieldicon
                        currencytype
                        options
                        piplineid
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: value,
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getCustomFieldList);
            if (value.typeId == 1)
                dispatch(setResponseDetails(actionTypes.COSTOM_FIELD_LIST, return_data));

            if (value.typeId == 2)
                dispatch(setResponseDetails(actionTypes.CONTACT_COSTOM_FIELD_LIST, return_data));

            if (value.typeId == 3)
                dispatch(setResponseDetails(actionTypes.COMPANY_COSTOM_FIELD_LIST, return_data));

            if (value.typeId == 4)
                dispatch(setResponseDetails(actionTypes.OPPORTUNITIES_COSTOM_FIELD_LIST, return_data));

            if (value.typeId == 5)
                dispatch(setResponseDetails(actionTypes.PROJECTS_COSTOM_FIELD_LIST, return_data));

            if (value.typeId == 6)
                dispatch(setResponseDetails(actionTypes.TASKS_COSTOM_FIELD_LIST, return_data));

        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

/**
 * TYPE : GET
 * Getting Company List
 */
export const companyList = (client, value) => {
    return function (dispatch) {
        const query = gql`{
            getCompanyList {
             name
             displayname
             companysize
            }
        }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getCompanyList);
            dispatch(setResponseDetails(actionTypes.COMPANY_LIST, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

/**
 * TYPE : POST
 * on dropdown change updating custom field record
 */
export const updateCustomFieldRecord = (client, value) => {
    return function (dispatch) {
        const mutation = gql`
             mutation updateRecordValue($typeId: Float!,$editdefaultvalue: Float!, $creationdefaultvalue: Float!, $generatedfieldid: String!) {
                 updateRecordValue(typeId: $typeId, editdefaultvalue: $editdefaultvalue, creationdefaultvalue:$creationdefaultvalue, generatedfieldid: $generatedfieldid) {
                        id
                        recordtypeid
                        customefieldmasterid
                        fieldlabel
                        fieldtype{
                            fieldicon
                            id
                            name
                        }
                        fieldkey
                        recordcreationrules_editable
                        recordeditrules_editable
                        isdiactivated
                        includeinfilter
                        recordcreationdefaultvalue
                        recordeditdefaultvalue
                        generatedfieldid
                        autogeneratefieldkey
                        is_default
                        fieldstatus
                        createdby
                        createdhost
                        createddate
                        lastmodifiedby
                        lastmodifiedhost
                        lastmodifieddate
                        orderno
                        recordcreationrulesvalue
                        recordeditrulesvalue
                        fieldicon
                        currencytype
                        options
                        piplineid
                        leadfavourite
                        taskcomplete
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: value
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != null) {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "Custom field record updated successfully"
                    }

                    let return_data = dataSet.processGQLData(response.data.updateRecordValue);
                    if (value.typeId == 1)
                        dispatch(setResponseDetails(actionTypes.COSTOM_FIELD_LIST, return_data));
                    if (value.typeId == 2)
                        dispatch(setResponseDetails(actionTypes.CONTACT_COSTOM_FIELD_LIST, return_data));

                    if (value.typeId == 3)
                        dispatch(setResponseDetails(actionTypes.COMPANY_COSTOM_FIELD_LIST, return_data));

                    if (value.typeId == 4)
                        dispatch(setResponseDetails(actionTypes.OPPORTUNITIES_COSTOM_FIELD_LIST, return_data));

                    if (value.typeId == 5)
                        dispatch(setResponseDetails(actionTypes.PROJECTS_COSTOM_FIELD_LIST, return_data));

                    if (value.typeId == 6)
                        dispatch(setResponseDetails(actionTypes.TASKS_COSTOM_FIELD_LIST, return_data));

                    // if (value.typeId == 2 || value.typeId == 3 || value.typeId == 4)   
                    //     dispatch(actionCreators.customFieldList(client, { typeId: value.typeId }))
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

/**
 * TYPE : POST
 * on status change update custom field record
 */
export const updateCustomFieldStatus = (client, value) => {
    return function (dispatch) {
        const mutation = gql`
            mutation updateFieldStatus($typeId: Float!,$pipelinesid:[Float!], $fieldstatus: Boolean!, $generatedfieldid: String!) {
                updateFieldStatus(typeId: $typeId, pipelinesid: $pipelinesid, fieldstatus: $fieldstatus, generatedfieldid: $generatedfieldid) {
                    id
                    recordtypeid
                    customefieldmasterid
                    fieldlabel
                    fieldtype{
                        fieldicon
                        id
                        name
                    }
                    fieldkey
                    recordcreationrules_editable
                    recordeditrules_editable
                    isdiactivated
                    includeinfilter
                    recordcreationdefaultvalue
                    recordeditdefaultvalue
                    generatedfieldid
                    autogeneratefieldkey
                    is_default
                    fieldstatus
                    createdby
                    createdhost
                    createddate
                    lastmodifiedby
                    lastmodifiedhost
                    lastmodifieddate
                    orderno
                    recordcreationrulesvalue
                    recordeditrulesvalue
                    fieldicon
                    currencytype
                    options
                }
            }
            `;
        client.mutate({
            mutation: mutation,
            variables: value
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != null) {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "Custom Field Status Updated Successfully"
                    }

                    let return_data = dataSet.processGQLData(response.data.updateFieldStatus);
                    dispatch(actionCreators.customFieldList(client, { typeId: value.typeId }))
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));

            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}



/**
 * TYPE : POST
 * on order change updating custom field record
 */
export const updateCustomFieldOrder = (client, value) => {
    return function (dispatch) {
        const mutation = gql`
            mutation updateFieldOrder($typeId:Float!, $orderno: [Float!]!, $fieldid: [Float!]!) {
                updateFieldOrder(typeId:$typeId, orderno: $orderno, fieldid: $fieldid) {
                    id
                    recordtypeid
                    customefieldmasterid
                    fieldlabel
                    fieldtype{
                        fieldicon
                        id
                        name
                    }
                    fieldkey
                    recordcreationrules_editable
                    recordeditrules_editable
                    isdiactivated
                    includeinfilter
                    recordcreationdefaultvalue
                    recordeditdefaultvalue
                    generatedfieldid
                    autogeneratefieldkey
                    is_default
                    fieldstatus
                    createdby
                    createdhost
                    createddate
                    lastmodifiedby
                    lastmodifiedhost
                    lastmodifieddate
                    orderno
                    recordcreationrulesvalue
                    recordeditrulesvalue
                    fieldicon
                    currencytype
                    options
                }
            }
            `;
        client.mutate({
            mutation: mutation,
            variables: value
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != null) {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "Custom field order updated successfully"
                    }
                    let return_data = dataSet.processGQLData(response.data.updateFieldOrder);


                    if (value.typeId == 1)
                        dispatch(setResponseDetails(actionTypes.UPDATE_CUSTOM_FIELD_ORDER, return_data));

                    if (value.typeId == 2 || value.typeId == 3 || value.typeId == 4 || value.typeId == 5 || value.typeId == 6)
                        dispatch(actionCreators.customFieldList(client, { typeId: value.typeId }));
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}


/**
 * Type : POST
 * Add/Create new Custom field.
 */
export const addCustomFieldInput = (client, request) => {
    return dispatch => {
        const mutation = gql`
            mutation saveCustomField($field: CustomFieldInput!) {
                saveCustomField(field:$field) {
                    id
                    fieldlabel
                    fieldtype
                    fieldkey
                    inleads
                    inpeople
                    incompany
                    inopportunities
                    inprojects
                    intasks
                    includeinfilter
                    autogeneratefieldkey
                    createdby
                    createdhost
                    createddate
                    lastmodifiedby
                    lastmodifiedhost
                    lastmodifieddate
                    currencytype
                    piplineid
                }
            }
            `;
        client.mutate({
            mutation: mutation,
            variables: request['data'],
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != null) {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "Custom field added successfully"
                    }
                    let return_data = dataSet.processGQLData(response.data.saveCustomField);
                    dispatch(setResponseDetails(actionTypes.ADD_CUSTOM_FIELD, return_data));
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
                dispatch(actionCreators.CreatedFieldList(client))
                dispatch(actionCreators.customFieldTypeList(client))
                dispatch(actionCreators.customFieldList(client, request['typeId']))


            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

/**
 * Type : POST
 * Update Custom field.
 */
export const updateCustomFieldInput = (client, request) => {
    return dispatch => {
        const mutation = gql`
            mutation updateCreatedFieldDetail($field: CustomFiledUpdateInput!) {
                updateCreatedFieldDetail(field:$field) {
                    id
                    fieldlabel
                    fieldtype
                    fieldkey
                    inleads
                    inpeople
                    incompany
                    inopportunities
                    inprojects
                    intasks
                    includeinfilter
                    autogeneratefieldkey
                    createdby
                    createdhost
                    createddate
                    lastmodifiedby
                    lastmodifiedhost
                    lastmodifieddate
                    currencytype
                    options
                    piplineid
                }
            }
            `;
        client.mutate({
            mutation: mutation,
            variables: request['fieldData']
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != null) {
                    let return_data = dataSet.processGQLData(response.data.updateCreatedFieldDetail);
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "Custom Field Updated Successfully"
                    }
                    dispatch(setResponseDetails(actionTypes.UPDATE_CUSTOM_FIELD, return_data));
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
                dispatch(actionCreators.CreatedFieldList(client))
                dispatch(actionCreators.customFieldTypeList(client))
                dispatch(actionCreators.customFieldList(client, { typeId: 1 }))
                dispatch(actionCreators.customFieldList(client, { typeId: 2 }))
                dispatch(actionCreators.customFieldList(client, { typeId: 3 }))
                dispatch(actionCreators.customFieldList(client, { typeId: 4 }))
                dispatch(actionCreators.customFieldList(client, { typeId: 5 }))
                dispatch(actionCreators.customFieldList(client, { typeId: 6 }))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}


/**
 * Type : POST
 * Delete Custom field.
 */
export const deleteCustomFieldInput = (client, request) => {
    return function (dispatch) {
        const query = gql`
            query deleteCustomField($fieldId: Float!) 
            {
                deleteCustomField(fieldId: $fieldId) {
                    status
                    message
                }
            }
        `;
        client.query({
            query: query,
            variables: request['fieldId']
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.deleteCustomField.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.deleteCustomField.message
                    }
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.deleteCustomField.message
                    }
                }
            }
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
            dispatch(actionCreators.CreatedFieldList(client))
            dispatch(actionCreators.customFieldTypeList(client))
            dispatch(actionCreators.customFieldList(client, { typeId: 1 }))
            dispatch(actionCreators.customFieldList(client, { typeId: 2 }))
            dispatch(actionCreators.customFieldList(client, { typeId: 3 }))
            dispatch(actionCreators.customFieldList(client, { typeId: 4 }))
            dispatch(actionCreators.customFieldList(client, { typeId: 5 }))
            dispatch(actionCreators.customFieldList(client, { typeId: 6 }))
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}


/**
 * Type: GET
 * Featch created custom fields
 */
export const CreatedFieldList = (client) => {

    return function (dispatch) {
        const query = gql`
            query getCreateFieldList
            {
                getCreateFieldList {
                    id
                    fieldlabel
                    fieldtype
                    fieldkey
                    inleads
                    inpeople
                    incompany
                    inopportunities
                    inprojects
                    intasks
                    includeinfilter
                    autogeneratefieldkey
                    createdby
                    createdhost
                    createddate
                    lastmodifiedby
                    lastmodifiedhost
                    lastmodifieddate
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getCreateFieldList);
            dispatch(setResponseDetails(actionTypes.CREATED_FIELD_LIST, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

/**
 * Type: GET
 * Featch defails of created custom field
 */
export const customFieldDetail = (client, value) => {

    return function (dispatch) {
        const query = gql`
            query getCreatedFieldDetail($fieldid: Float!) 
            {
                getCreatedFieldDetail(fieldid: $fieldid) {
                    id
                    fieldlabel
                    fieldtype
                    fieldkey
                    inleads
                    inpeople
                    incompany
                    inopportunities
                    inprojects
                    intasks
                    includeinfilter
                    autogeneratefieldkey
                    createdby
                    createdhost
                    createddate
                    lastmodifiedby
                    lastmodifiedhost
                    lastmodifieddate
                    currencytype
                    options
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: value
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getCreatedFieldDetail);
            dispatch(setResponseDetails(actionTypes.COSTOM_FIELD_DETAIL, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}


export const loadLeadStatues = (client) => {
    return dispatch => {
        const query = gql`query getLeadStatus {
                    getLeadStatus{
                        id, name, order_no, is_allowto_delete, is_default,is_allowto_edit
                    }
                }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getLeadStatus);
            dispatch(setResponseDetails(actionTypes.LEAD_STATUSES_LIST_GET, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}
export const updateLeadStatusesOrder = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateStatusOrder($orderno:[Float!]!, $statusid:[Float!]!) {
                 updateStatusOrder(orderno:$orderno,statusid:$statusid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                orderno: request.leadStatusOrder,
                statusid: request.leadStatusIds,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateStatusOrder.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateStatusOrder.message
                        }
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateStatusOrder.message
                        }
                    }
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}
export const updateDefaultLeadStatus = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateStatusDefault($statusid:Float!) {
                 updateStatusDefault(statusid:$statusid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                statusid: request.statusid,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateStatusDefault.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateStatusDefault.message
                        }
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateStatusDefault.message
                        }
                    }
                }
                dispatch(actionCreators.loadLeadStatues(client))
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}
export const AddLeadStatus = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation saveLeadStatus($statusname: String!) {
                 saveLeadStatus(statusname:$statusname) {
                    id
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                statusname: request.name,
            }
        })
            .then(response => {
                let returnData = [];
                returnData = {
                    'data': {},
                    'error': false,
                    'notify_msg': "Lead Status added successfully"
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                dispatch(actionCreators.loadLeadStatues(client))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}
export const updateLeadStatusRecord = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateStatusDetail($statusname: String!,$statusid: Float!) {
                 updateStatusDetail(statusname:$statusname,statusid:$statusid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                statusname: request.name,
                statusid: request.id,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateStatusDetail.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateStatusDetail.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateStatusDetail.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }

                dispatch(actionCreators.loadLeadStatues(client))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const deleteLeadStatus = (client, request) => {
    return dispatch => {
        const query = gql`
             query deleteLeadStatus($statusid:Float!) {
                 deleteLeadStatus(statusid:$statusid) {
                    status
                    message
                 }
               }
             `;
        client.query({
            query: query,
            variables: {
                statusid: request.statusid,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.deleteLeadStatus.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.deleteLeadStatus.message
                        }
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.deleteLeadStatus.message
                        }
                    }
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
                dispatch(actionCreators.loadLeadStatues(client))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const getLeadSettings = (client) => {
    return dispatch => {
        const query = gql`query getLeadSettings {
                    getLeadSettings{
                        lead
                    }
                }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getLeadSettings);
            dispatch(setResponseDetails(actionTypes.LEAD_SETTINGS_LIST_GET, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}
export const updateLeadSettings = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateLeadSettings($status:Boolean!) {
                 updateLeadSettings(status:$status) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: request
        })
            .then(response => {
                console.log(response,'response');
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateLeadSettings.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateLeadSettings.message
                        }
                        dispatch(actionCreators.getLeadSettings(client))
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateLeadSettings.message
                        }
                    }
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const loadSources = (client) => {
    return dispatch => {
        const query = gql`query getSourcesOption {
                    getSourcesOption{
                        id, name, order_no, is_allowto_delete, is_default,is_allowto_edit
                    }
                }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getSourcesOption);
            dispatch(setResponseDetails(actionTypes.LEAD_SOUECES_LIST, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const updateSourceListOrder = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateSourceOrder($orderno:[Float!]!, $sourceid:[Float!]!) {
                 updateSourceOrder(orderno:$orderno,sourceid:$sourceid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                orderno: request.sourceListOrder,
                sourceid: request.sourceIds,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateSourceOrder.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateSourceOrder.message
                        }
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateSourceOrder.message
                        }
                    }
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const deleteSource = (client, request) => {
    return dispatch => {
        const query = gql`
             query deleteSource($sourceid:Float!) {
                 deleteSource(sourceid:$sourceid) {
                    status
                    message
                 }
               }
             `;
        client.query({
            query: query,
            variables: {
                sourceid: request.sourceid,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.deleteSource.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.deleteSource.message
                        }
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.deleteSource.message
                        }
                    }
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
                dispatch(actionCreators.loadSources(client))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}
export const saveSource = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation saveSource($sourcename: String!) {
                 saveSource(sourcename:$sourcename) {
                    id
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                sourcename: request.name,
            }
        })
            .then(response => {
                let returnData = [];
                returnData = {
                    'data': {},
                    'error': false,
                    'notify_msg': "Source added successfully"
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                dispatch(actionCreators.loadSources(client))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}
export const updateSourceRecord = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateSourceDetail($sourcename: String!,$sourceid: Float!) {
                 updateSourceDetail(sourcename:$sourcename,sourceid:$sourceid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                sourcename: request.name,
                sourceid: request.id,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateSourceDetail.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateSourceDetail.message
                        }
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateSourceDetail.message
                        }
                    }
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                dispatch(actionCreators.loadSources(client))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}


export const loadCurrencyList = (client) => {
    return dispatch => {
        const query = gql`query getCurrencyList {
                    getCurrencyList{
                        id,code,name,symbol,is_default
                    }
                }
        `;

        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getCurrencyList);
            dispatch(setResponseDetails(actionTypes.ALL_CURRENCY_LIST, return_data));
        }).catch((error) => {
            // let err_data = errorMessageGraphQl(error);
            // dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const getCompanyCurrency = (client) => {
    return function (dispatch) {
        const query = gql`{
            getCmpCurrencyList {
                id
                currencyid
                symbol
                thousandsseparator
                decimalplaces
                decimalseparator
                detail
            }
        }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getCmpCurrencyList);
            dispatch(setResponseDetails(actionTypes.COMPANY_CURRENCY_DATA, return_data));
        }).catch((error) => {
            // let err_data = errorMessageGraphQl(error);
            // dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const updateCurrencyData = (client, request) => {
    request.id = parseInt(request.id);
    request.currencyid = parseInt(request.currencyid);
    request.decimalplaces = parseInt(request.decimalplaces);
    var params = {
        id: request.id,
        currencyid: request.currencyid,
        symbol: request.symbol,
        thousandsseparator: request.thousandsseparator,
        decimalplaces: request.decimalplaces,
        decimalseparator: request.decimalseparator,
    }
    return dispatch => {
        const mutation = gql`
             mutation updateCmpCurrency($currency: CurrencyUpdateInput!) {
                 updateCmpCurrency(currency:$currency) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                currency: params
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateCmpCurrency.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateCmpCurrency.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateCmpCurrency.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }

                dispatch(actionCreators.getCompanyCurrency(client))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const getCountryList = (client) => {
    return dispatch => {
        const query = gql`query getCountryList {
                    getCountryList{
                        id name code
                    }
                }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getCountryList);
            dispatch(setResponseDetails(actionTypes.COUNTRY_LIST_GQL, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

const errorMessageGraphQl = (err) => {
    let msg = 'Something Went Wrong!';
    if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        msg = err.graphQLErrors[0].message;
    } else if (err.networkError && err.networkError.result) {
        msg = err.networkError.result.errors[0].message;
    }
    else if (err.message) {
        msg = err.message;
    }

    return {
        notify_msg: msg,
        error: true
    }
}

export const clearNotification = (value) => {
    return function (dispatch) {
        dispatch(clearNotificationAll(actionTypes.NOTIFICATIONS, {}, false));
    }
}
const clearNotificationAll = (newType, data, status) => {
    return {
        type: actionTypes.NOTIFICATIONS,
        val: '',
        status: '',
    }
}

export const getUserSetting = (client, value) => {

    return function (dispatch) {
        const query = gql`
            query getUserSetting($userid: Float!) 
            {
                getUserSetting(userid: $userid) {
                    id
                    profileimage
                    dateformat
                    currencyid
                    taskvisible
                    setreminders
                    remindersday
                    userid
                    timezone
                    automationtimezone
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: value,
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getUserSetting);
            dispatch(setResponseDetails(actionTypes.GET_USER_SETTING, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const saveUserSetting = (client, request) => {
    return dispatch => {
        const mutation = gql`
            mutation saveUserSetting($setting: UserSettingInput!, $fileInput: Upload!) {
                saveUserSetting(setting:$setting, fileInput:$fileInput) {
                    id
                    profileimage
                    dateformat
                    currencyid
                    taskvisible
                    setreminders
                    remindersday
                    userid
                    timezone
                }
            }
            `;
        client.mutate({
            mutation: mutation,
            variables: {
                setting: request.setting,
                fileInput: request.fileInput,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.saveUserSetting.id) {
                        returnData = {
                            'data': response.data.saveUserSetting,
                            'error': false,
                            'notify_msg': "Data updated successfully"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': request.setting,
                            'error': true,
                            'notify_msg': "Unable to update data"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
                dispatch(setResponseDetails(actionTypes.GET_USER_SETTING, returnData));

            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const getTagsList = (client) => {
    return dispatch => {
        const query = gql`query getTagsList {
                    getTagsList{
                        id tagname createdby createdhost createddate lastmodifiedby lastmodifieddate taglist
                    }
                }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getTagsList);
            dispatch(setResponseDetails(actionTypes.SYSTEM_TAGS_LIST, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}
export const getUserList = (client) => {
    return dispatch => {
        const query = gql`query getUserList {
                    getUserList{
                        id name email password companyId accesstoken sessiontoken
                        activestatus googlestatus passwordtoken emailverified
                        forgotpasswordtoken forgotverified systemadmin googlesync
                        dataexport dropbox title phoneno address country
                        state city pincode companyowner fcmtoken createdhost 
                        createdby createddate lastmodifiedby lastmodifiedhost 
                        lastmodifieddate lastlogin isFirstCmp
                        teamlist{
                            teammanager
                            user{
                                id
                                name
                                email
                            }
                            team{
                                id
                                teamname
                                totalmember
                                memberlist{
                                    user{
                                        id
                                        name
                                        email
                                    }
                                    teammanager
                                }
                            }
                        }
                    }
                }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getUserList);
            dispatch(setResponseDetails(actionTypes.USERS_LIST_LIST, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}
export const getCityList = (client) => {
    return dispatch => {
        const query = gql`query getCityList {
                    getCityList{
                        id name
                    }
                }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getCityList);
            dispatch(setResponseDetails(actionTypes.GET_CITY_LIST, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}
export const getStateList = (client) => {
    return dispatch => {
        const query = gql`query getStateList {
                    getStateList{
                        id name
                    }
                }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getStateList);
            dispatch(setResponseDetails(actionTypes.GET_STATE_LIST, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}
export const getZipcodeList = (client) => {
    return dispatch => {
        const query = gql`query getZipcodeList {
                    getZipcodeList{
                        id name
                    }
                }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getZipcodeList);
            dispatch(setResponseDetails(actionTypes.GET_ZIPCODE_LIST, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}
export const getTimezoneList = (client) => {
    return dispatch => {
        const query = gql`query getTimezoneList {
                    getTimezoneList{
                        id name timezone
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getTimezoneList);
            dispatch(setResponseDetails(actionTypes.GET_TIMEZONE_LIST, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const getPipelines = (client) => {
    return dispatch => {
        const query = gql`query getPipelines {
                    getPipelines{
                        id stage name
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getPipelines);
            dispatch(setResponseDetails(actionTypes.SETTING_PIPELINE_LIST, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const savePipelines = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation savePipelines($stagedata: [JSON!]!,$pipelinesname:String!) {
                 savePipelines(stagedata:$stagedata,pipelinesname:$pipelinesname) {
                    id stage name
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                stagedata: request.stagedata,
                pipelinesname: request.pipelinesname,
            }
        })
            .then(response => {
                let returnData = [];
                returnData = {
                    'data': {},
                    'error': false,
                    'notify_msg': "Pipeline added successfully"
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                dispatch(actionCreators.getPipelines(client))
                dispatch(actionCreators.getLoginUserPermission(client))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const deletePipelines = (client, request) => {
    return dispatch => {
        const query = gql`
             query deletePipelines($pipelinesid:Float!) {
                 deletePipelines(pipelinesid:$pipelinesid) {
                    status
                    message
                 }
               }
             `;
        client.query({
            query: query,
            variables: {
                pipelinesid: parseInt(request.pipelinesid),
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.deletePipelines.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.deletePipelines.message
                        }
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.deletePipelines.message
                        }
                    }
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
                dispatch(actionCreators.getPipelines(client))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const getPipelinesDetail = (client, request) => {

    return function (dispatch) {
        const query = gql`
            query getPipelinesDetail($pipelinesid: Float!) 
            {
                getPipelinesDetail(pipelinesid: $pipelinesid) {
                    id name stage
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                pipelinesid: request.pipelinesid
            }
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getPipelinesDetail);
            dispatch(setResponseDetails(actionTypes.PIPELINE_DATA_BY_ID, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const updatePipelinesDetail = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updatePipelinesDetail($stagedata:[JSON!]!, $pipelinesname:String!,$pipelinesid:Float!) {
                 updatePipelinesDetail(stagedata:$stagedata,pipelinesname:$pipelinesname,pipelinesid:$pipelinesid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                stagedata: request.stagedata,
                pipelinesname: request.pipelinesname,
                pipelinesid: request.pipelinesid,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updatePipelinesDetail.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updatePipelinesDetail.message
                        }
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updatePipelinesDetail.message
                        }
                    }
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
                dispatch(actionCreators.getPipelines(client))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const getLossReasonList = (client) => {
    return dispatch => {
        const query = gql`query getLossReasonList {
                    getLossReasonList{
                     id name alias order_no is_allowto_delete is_default is_allowto_edit createdby createdhost createddate lastmodifiedby lastmodifiedhost lastmodifieddate
                    }
                }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getLossReasonList);
            dispatch(setResponseDetails(actionTypes.LOSS_REASON_LIST, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const updateLossReasonOrder = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateLossReasonOrder($orderno:[Float!]!, $reasonid:[Float!]!) {
                 updateLossReasonOrder(orderno:$orderno,reasonid:$reasonid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                orderno: request.orderListArray,
                reasonid: request.reasonIds,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateLossReasonOrder.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateLossReasonOrder.message
                        }
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateLossReasonOrder.message
                        }
                    }
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const deleteLossReason = (client, request) => {
    return dispatch => {
        const query = gql`
             query deleteLossReason($reasonid:Float!) {
                 deleteLossReason(reasonid:$reasonid) {
                    status
                    message
                 }
               }
             `;
        client.query({
            query: query,
            variables: {
                reasonid: request.reasonid,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.deleteLossReason.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.deleteLossReason.message
                        }
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.deleteLossReason.message
                        }
                    }
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
                dispatch(actionCreators.getLossReasonList(client))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}
export const saveLossReason = (client, request) => {
    return dispatch => {
        const mutation = gql`
            mutation saveLossReason($reasonname: String!) {
                saveLossReason(reasonname:$reasonname) {
                    id
                }
            }
            `;
        client.mutate({
            mutation: mutation,
            variables: {
                reasonname: request.name,
            }
        })
            .then(response => {
                let returnData = [];
                returnData = {
                    'data': {},
                    'error': false,
                    'notify_msg': "Loss Reason added successfully."
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                dispatch(actionCreators.getLossReasonList(client))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}
export const updateLossReasonDetail = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateLossReasonDetail($reasonname: String!,$reasonid: Float!) {
                 updateLossReasonDetail(reasonname:$reasonname,reasonid:$reasonid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                reasonname: request.name,
                reasonid: request.id,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateLossReasonDetail.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateLossReasonDetail.message
                        }
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateLossReasonDetail.message
                        }
                    }
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                dispatch(actionCreators.getLossReasonList(client))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}
export const updateCompanyInfoGraphQl = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation UpdateCompanyInfo($detail: CompanyInfoInput!,$fileInput: Upload!) {
                 UpdateCompanyInfo(detail:$detail,fileInput:$fileInput) {
                    id
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                detail: request.detail,
                fileInput: request.fileInput,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.UpdateCompanyInfo.id) {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': "Data updated successfully"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': "Unable to save data"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
                let userData = JSON.parse(localStorage.getItem('user_data_session'))
                let userprefparams = { userid: userData.id }
                dispatch(actionCreators.getUserSetting(client, userprefparams))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const updateDemoData = (client) => {
    return function (dispatch) {
        const mutation = gql`
                    mutation updateDemoData
                    {
                        updateDemoData {
                            status
                            message
                        }
                    }
                `;
        client.mutate({
            mutation: mutation,
            fetchPolicy: 'no-cache'
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.updateDemoData.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.updateDemoData.message
                    }
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.updateDemoData.message
                    }
                }
            }
            // dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));            
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            // dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const getCompanyRecList = (client) => {
    return function (dispatch) {
        const query = gql`
                    query getCompanyRecList
                    {
                        getCompanyRecList {
                            data
                        }
                    }
                `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache'
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getCompanyRecList);
            dispatch(setResponseDetails(actionTypes.GET_COMPANY_REC_LIST, return_data));
        }).catch((error) => {
            var returnData = {
                'data': {},
                'error': false,
                'notify_msg': ""
            }
            dispatch(setResponseDetails(actionTypes.GET_COMPANY_REC_LIST, returnData));
        });
    }
}
export const getContactRecList = (client) => {
    return function (dispatch) {
        const query = gql`
                    query getContactList
                    {
                        getContactList {
                            data
                            totalrecord
                            totalpage
                        }
                    }
                `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache'
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getContactList);
            dispatch(setResponseDetails(actionTypes.GET_CONTACT_REC_LIST, return_data));
        }).catch((error) => {
            var returnData = {
                'data': {},
                'error': false,
                'notify_msg': ""
            }
            dispatch(setResponseDetails(actionTypes.GET_CONTACT_REC_LIST, returnData));
        });
    }
}
export const getProjectList = (client) => {
    return function (dispatch) {
        const query = gql`
                    query getProjectList
                    {
                        getProjectList {
                            data
                            totalrecord
                            totalpage
                        }
                    }
                `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache'
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getProjectList);
            dispatch(setResponseDetails(actionTypes.GET_PROJECT_REC_LIST, return_data));
        }).catch((error) => {
            var returnData = {
                'data': {},
                'error': false,
                'notify_msg': ""
            }
            dispatch(setResponseDetails(actionTypes.GET_PROJECT_REC_LIST, returnData));
        });
    }
}
export const getOpportunitiesList = (client) => {
    return function (dispatch) {
        const query = gql`
                    query getOpportunitiesList
                    {
                        getOpportunitiesList {
                            data
                            totalrecord
                            totalpage
                        }
                    }
                `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache'
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getOpportunitiesList);
            dispatch(setResponseDetails(actionTypes.GET_OPPORTUNITY_REC_LIST, return_data));
        }).catch((error) => {
            var returnData = {
                'data': {},
                'error': false,
                'notify_msg': ""
            }
            dispatch(setResponseDetails(actionTypes.GET_OPPORTUNITY_REC_LIST, returnData));
        });
    }
}
export const getTaskList = (client) => {
    return function (dispatch) {
        const query = gql`
                    query getTaskList
                    {
                        getTaskList {
                            data
                            totalrecord
                            totalpage
                        }
                    }
                `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache'
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getTaskList);
            dispatch(setResponseDetails(actionTypes.GET_TASK_REC_LIST, return_data));
        }).catch((error) => {
            var returnData = {
                'data': {},
                'error': false,
                'notify_msg': ""
            }
            dispatch(setResponseDetails(actionTypes.GET_TASK_REC_LIST, returnData));
        });
    }
}
export const getActivityTypes = (client) => {
    return function (dispatch) {
        const query = gql`
                    query getActivityTypes
                    {
                        getActivityTypes {
                            id
                            name
                            alias
                            icon
                            ininteractions
                            is_default
                            is_disable
                            createdby
                            createdhost
                            createddate
                            lastmodifiedby
                            lastmodifiedhost
                            lastmodifieddate
                        }
                    }
                `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache'
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getActivityTypes);
            // console.log("return_data", return_data);
            dispatch(setResponseDetails(actionTypes.MASTER_ACTIVITYTYPES_LIST, return_data));
        }).catch((error) => {
            var returnData = {
                'data': {},
                'error': false,
                'notify_msg': ""
            }
            dispatch(setResponseDetails(actionTypes.MASTER_ACTIVITYTYPES_LIST, returnData));
        });
    }
}

export const saveActivityTypes = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation saveActivityTypes($icon: String!,$ininteractions: Float!,$activitytypename: String!) {
                 saveActivityTypes(icon:$icon,ininteractions:$ininteractions,activitytypename:$activitytypename) {
                    id
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                icon: request.icon,
                ininteractions: request.ininteractions,
                activitytypename: request.activitytypename,
            }
        })
            .then(response => {
                let returnData = [];
                returnData = {
                    'data': {},
                    'error': false,
                    'notify_msg': "Activity type added successfully"
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                dispatch(actionCreators.getActivityTypes(client))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const updateActivityTypesDetail = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateActivityTypesDetail($isdisable: Boolean!, $icon: String!,$ininteractions: Float!,$activitytypename: String! ,$activitytypeid: Float!) {
                 updateActivityTypesDetail(isdisable:$isdisable,icon:$icon,ininteractions:$ininteractions,activitytypename:$activitytypename,activitytypeid:$activitytypeid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                isdisable: request.isdisable,
                icon: request.icon,
                ininteractions: request.ininteractions,
                activitytypename: request.activitytypename,
                activitytypeid: request.activitytypeid
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateActivityTypesDetail.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateActivityTypesDetail.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateActivityTypesDetail.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
                dispatch(actionCreators.getActivityTypes(client))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const getRelatedList = (client) => {
    return function (dispatch) {
        const query = gql`
                    query getRelatedList
                    {
                        getRelatedList {
                            lead
                            contact
                            company
                            opportunities
                            project
                            status
                            message
                        }
                    }
                `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache'
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getRelatedList);
            dispatch(setResponseDetails(actionTypes.GET_RELATEDLIST_LIST, return_data));
        }).catch((error) => {
            var returnData = {
                'data': {},
                'error': false,
                'notify_msg': ""
            }
            dispatch(setResponseDetails(actionTypes.GET_RELATEDLIST_LIST, returnData));
        });
    }
}


/**
* Type: GET
* Featch related master list
*/
export const getRelatedMasterList = (client, value) => {

    return function (dispatch) {
        const query = gql`
            query getRelatedMasterList($recordtypeid: Float!) 
            {
                getRelatedMasterList(recordtypeid: $recordtypeid) {
                    id
                    recordid
                    name
                    icon
                    alias
                    orderno
                    isactive
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: value
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getRelatedMasterList);

            if (value.recordtypeid == 1) {
                dispatch(setResponseDetails(actionTypes.LEAD_RELATED_MASTER_LIST, return_data));
            }
            if (value.recordtypeid == 2) {
                dispatch(setResponseDetails(actionTypes.CONTACT_RELATED_MASTER_LIST, return_data));
            }
            if (value.recordtypeid == 3) {
                dispatch(setResponseDetails(actionTypes.COMPANY_RELATED_MASTER_LIST, return_data));
            }
            if (value.recordtypeid == 4) {
                dispatch(setResponseDetails(actionTypes.OPPORTUNITY_RELATED_MASTER_LIST, return_data));
            }
            if (value.recordtypeid == 5) {
                dispatch(setResponseDetails(actionTypes.PROJECT_RELATED_MASTER_LIST, return_data));
            }

        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}


export const updateRelatedStatus = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateRelatedStatus($status: Boolean!, $relatedid: Float!) {
                 updateRelatedStatus(status:$status,relatedid:$relatedid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                status: request.status,
                relatedid: request.relatedid,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateRelatedStatus.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateRelatedStatus.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                        dispatch(actionCreators.getRelatedMasterList(client, { recordtypeid: request.recordtypeid }))
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateRelatedStatus.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }

            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const updateRelatedOrder = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateRelatedOrder($orderno:[Float!]!, $relatedid:[Float!]!, $recordtypeid: Float!,) {
                 updateRelatedOrder(orderno:$orderno,relatedid:$relatedid, ,recordtypeid:$recordtypeid) {
                    id
                    recordid
                    name
                    icon
                    alias
                    orderno
                    isactive
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                orderno: request.orderno,
                relatedid: request.relatedid,
                recordtypeid: request.recordtypeid,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != null) {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "Related Object Order Updated Successfully"
                    }

                    dispatch(actionCreators.getRelatedMasterList(client, { recordtypeid: request.recordtypeid }))
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));

            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

/**
* Type: GET
* Featch related master list
*/
export const getEmailTemplateList = (client, value) => {

    return function (dispatch) {
        const query = gql`
            query getEmailTemplateList
            {
                getEmailTemplateList {
                    id
                    user{
                        id
                        name
                    }
                    name
                    subject
                    message
                    isshared
                    createdby
                    createdhost
                    createddate
                    lastmodifiedby
                    lastmodifiedhost
                    lastmodifieddate
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: value
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getEmailTemplateList);

            dispatch(setResponseDetails(actionTypes.EMAIL_TEMPLATE_LIST, return_data));

        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const saveEmailTemplate = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation saveEmailTemplate($isshared: Boolean!, $message: String!, $subject: String!, $name: String!, $messagehtml: String!, $subjecthtml: String!) {
                 saveEmailTemplate(isshared:$isshared, message:$message, subject:$subject, name:$name, messagehtml:$messagehtml, subjecthtml:$subjecthtml) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                isshared: request.isshared,
                message: request.message,
                subject: request.subject,
                name: request.name,
                messagehtml: request.messagehtml,
                subjecthtml: request.subjecthtml,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data.saveEmailTemplate.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.saveEmailTemplate.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    dispatch(actionCreators.getEmailTemplateList(client))
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.saveEmailTemplate.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }

            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const deleteEmailTemplate = (client, request) => {

    return dispatch => {
        const query = gql`
            query deleteEmailTemplate($templateid: Float!)                 
            {
                deleteEmailTemplate(templateid:$templateid) {
                    status
                    message
                }
            }
        `;
        client.query({
            query: query,
            variables: { templateid: request.templateid },
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.deleteEmailTemplate.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.deleteEmailTemplate.message
                    }

                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    dispatch(actionCreators.getEmailTemplateList(client))
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.deleteEmailTemplate.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const getEmailTemplateDetail = (client, value) => {

    return function (dispatch) {
        const query = gql`
            query getEmailTemplateDetail($templateid: Float!)
            {
                getEmailTemplateDetail(templateid:$templateid) {
                    id
                    user{
                        id
                        name
                    }
                    name
                    subject
                    message
                    isshared
                    createdby
                    createdhost
                    createddate
                    lastmodifiedby
                    lastmodifiedhost
                    lastmodifieddate
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: value
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getEmailTemplateDetail);


            dispatch(setResponseDetails(actionTypes.EMAIL_TEMPLATE_DETAIL, return_data));
            console.log(return_data, 'return_data');
            return
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const updateEmailTemplate = (client, request) => {
    return dispatch => {
        const mutation = gql`
            mutation updateEmailTemplate($isshared: Boolean!,$message: String!,$subject: String!,$name: String!, $messagehtml: String!, $subjecthtml: String!, $templateid: Float!) {
                updateEmailTemplate(isshared:$isshared,message:$message, subject:$subject, name:$name, templateid:$templateid, messagehtml:$messagehtml, subjecthtml:$subjecthtml) {
                status
                message
                }
            }
            `;
        client.mutate({
            mutation: mutation,
            variables: {
                isshared: request.isshared,
                message: request.message,
                subject: request.subject,
                name: request.name,
                templateid: request.templateid,
                messagehtml: request.messagehtml,
                subjecthtml: request.subjecthtml,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data.updateEmailTemplate.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.updateEmailTemplate.message
                    }

                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    dispatch(actionCreators.getEmailTemplateList(client))
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.updateEmailTemplate.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const getOpportunitiesRelatedData = (client, req) => {

    return function (dispatch) {
        const query = gql`
            query getOpportunitiesRelatedData($id: Float!,$type: String!)
            {
                getOpportunitiesRelatedData(id:$id,type:$type) {
                    status
                    message
                    type
                    data
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                id: req.id,
                type: req.type,
            }
        }).then(response => {
            console.log("response", response);
            // let return_data = dataSet.processGQLData(response.data.getEmailTemplateDetail);


            // dispatch(setResponseDetails(actionTypes.RELATED_DATALIST_COMPANY, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}
export const getRecordRelatedData = (client, req) => {

    return function (dispatch) {
        const query = gql`
            query getRecordRelatedData($id: Float!,$type: String!,$recordtype: String!)
            {
                getRecordRelatedData(id:$id,type:$type,recordtype:$recordtype) {
                    status
                    message
                    type
                    data
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                id: req.id,
                type: req.type,
                recordtype: req.recordtype,
            }
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getRecordRelatedData);
            if (req.type == "companies") {
                dispatch(setResponseDetails(actionTypes.RELATED_DATALIST_COMPANY, return_data));
            }
            if (req.type == "contact") {
                dispatch(setResponseDetails(actionTypes.RELATED_DATALIST_CONTACT, return_data));
            }
            if (req.type == "projects") {
                dispatch(setResponseDetails(actionTypes.RELATED_DATALIST_PROJECT, return_data));
            }
            if (req.type == "opportunities") {
                dispatch(setResponseDetails(actionTypes.RELATED_DATALIST_OPPORTUNITY, return_data));
            }
            if (req.type == "tasks") {
                dispatch(setResponseDetails(actionTypes.RELATED_DATALIST_TASK, return_data));
            }

            if (req.type == "calendarevents") {
                dispatch(setResponseDetails(actionTypes.RELATED_DATALIST_CALANDAR, return_data));
            }

            if (req.type == "files") {
                dispatch(setResponseDetails(actionTypes.RELATED_DATALIST_FILE, return_data));
            }
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            // dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const addProjectCompany = (client, req) => {
    console.log("addProjectCompany", req);
    return dispatch => {
        const mutation = gql`
             mutation addProjectCompany($companydetail:JSON!, $projectid:Float!) {
                 addProjectCompany(companydetail:$companydetail,projectid:$projectid) {
                    id
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                companydetail: req.companydetail,
                projectid: req.projectid,
            },
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.addProjectCompany.length > 0) {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': "Data updated successfully"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': "Unable to update data"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
                var reqData = {
                    id: req.projectid,
                    type: 'companies',
                    recordtype: "project",
                }
                dispatch(actionCreators.getRecordRelatedData(client, reqData))
                var reqData1 = {
                    id: req.companydetail.id,
                    type: "projects",
                    recordtype: "company",
                }
                dispatch(actionCreators.getRecordRelatedData(client, reqData1))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const deleteProjectCompany = (client, req) => {
    return dispatch => {
        const mutation = gql`
             mutation deleteProjectCompany($companyid:Float!, $projectid:Float!) {
                 deleteProjectCompany(companyid:$companyid,projectid:$projectid) {
                    id
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                companyid: req.companyid,
                projectid: req.projectid,
            },
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.deleteProjectCompany) {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': "Data updated successfully"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    }
                    // else {
                    //     returnData = {
                    //         'data': {},
                    //         'error': true,
                    //         'notify_msg': "Unable to update data"
                    //     }
                    //     dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    // }
                }
                var reqData = {
                    id: req.projectid,
                    type: 'companies',
                    recordtype: "project",
                }
                dispatch(actionCreators.getRecordRelatedData(client, reqData))
                var reqData1 = {
                    id: req.companyid,
                    type: "projects",
                    recordtype: "company",
                }
                dispatch(actionCreators.getRecordRelatedData(client, reqData1))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const addProjectOpportunities = (client, req) => {
    return dispatch => {
        const mutation = gql`
             mutation addProjectOpportunities($opportunitiesdetail:JSON!, $projectid:Float!) {
                 addProjectOpportunities(opportunitiesdetail:$opportunitiesdetail,projectid:$projectid) {
                    id
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                opportunitiesdetail: req.opportunitiesdetail,
                projectid: req.projectid,
            },
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.addProjectOpportunities.length > 0) {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': "Data updated successfully"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': "Unable to update data"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
                var reqData = {
                    id: req.projectid,
                    type: 'opportunities',
                    recordtype: "project",
                }
                dispatch(actionCreators.getRecordRelatedData(client, reqData))
                var reqData1 = {
                    id: req.opportunitiesdetail.id,
                    type: "projects",
                    recordtype: "opportunities",
                }
                dispatch(actionCreators.getRecordRelatedData(client, reqData1))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const deleteProjectOpportunities = (client, req) => {
    return dispatch => {
        const mutation = gql`
             mutation deleteProjectOpportunities($opportunitiesid:Float!, $projectid:Float!) {
                 deleteProjectOpportunities(opportunitiesid:$opportunitiesid,projectid:$projectid) {
                    id
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                opportunitiesid: req.opportunitiesid,
                projectid: req.projectid,
            },
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.deleteProjectOpportunities) {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': "Data updated successfully"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    }
                    // else {
                    //     returnData = {
                    //         'data': {},
                    //         'error': true,
                    //         'notify_msg': "Unable to update data"
                    //     }
                    //     dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    // }
                }
                var reqData = {
                    id: req.projectid,
                    type: 'opportunities',
                    recordtype: "project",
                }
                dispatch(actionCreators.getRecordRelatedData(client, reqData))
                var reqData1 = {
                    id: req.opportunitiesid,
                    type: "projects",
                    recordtype: "opportunities",
                }
                dispatch(actionCreators.getRecordRelatedData(client, reqData1))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}
export const addProjectContact = (client, req) => {
    return dispatch => {
        const mutation = gql`
             mutation addProjectContact($contactdetail:JSON!, $projectid:Float!) {
                 addProjectContact(contactdetail:$contactdetail,projectid:$projectid) {
                    id
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                contactdetail: req.contactdetail,
                projectid: req.projectid,
            },
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.addProjectContact.length > 0) {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': "Data updated successfully"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': "Unable to update data"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
                var reqData = {
                    id: req.projectid,
                    type: 'contact',
                    recordtype: "project",
                }
                dispatch(actionCreators.getRecordRelatedData(client, reqData))
                var reqData1 = {
                    id: req.contactdetail.id,
                    type: 'projects',
                    recordtype: "contact",
                }
                dispatch(actionCreators.getRecordRelatedData(client, reqData1))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const deleteProjectContact = (client, req) => {
    return dispatch => {
        const mutation = gql`
             mutation deleteProjectContact($contactid:Float!, $projectid:Float!) {
                 deleteProjectContact(contactid:$contactid,projectid:$projectid) {
                    id
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                contactid: req.contactid,
                projectid: req.projectid,
            },
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.deleteProjectContact) {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': "Data updated successfully"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    }
                    // else {
                    //     returnData = {
                    //         'data': {},
                    //         'error': true,
                    //         'notify_msg': "Unable to update data"
                    //     }
                    //     dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    // }
                }
                var reqData = {
                    id: req.projectid,
                    type: 'contact',
                    recordtype: "project",
                }
                dispatch(actionCreators.getRecordRelatedData(client, reqData))

                var reqData1 = {
                    id: req.contactid,
                    type: 'projects',
                    recordtype: "contact",
                }
                dispatch(actionCreators.getRecordRelatedData(client, reqData1))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const getFreeContactList = (client, req) => {

    return function (dispatch) {
        const query = gql`
            query getFreeContactList($id: Float!,$type: String!,$recordtype: String!)
            {
                getFreeContactList(id:$id,type:$type,recordtype:$recordtype) {
                    status
                    message
                    type
                    data
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                id: req.id,
                type: req.type,
                recordtype: req.recordtype,
            }
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getFreeContactList);

        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const addNewCompanyContact = (client, req) => {
    return dispatch => {
        const mutation = gql`
             mutation addNewCompanyContact($companyid:Float!, $contactid:Float!) {
                 addNewCompanyContact(companyid:$companyid,contactid:$contactid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                companyid: req.companyid,
                contactid: req.contactid,
            },
        })
            .then(response => {
                console.log("response", response);
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.addNewCompanyContact.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': "Data updated successfully"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': "Unable to update data"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
                var reqData = {
                    id: req.companyid,
                    type: 'contact',
                    recordtype: "company",
                }
                dispatch(actionCreators.getRecordRelatedData(client, reqData))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}


export const deleteCompanyContact = (client, req) => {
    return function (dispatch) {
        const query = gql`
            query deleteCompanyContact($contactid: Float!)
            {
                deleteCompanyContact(contactid:$contactid) {
                    status
                    message
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                contactid: req.contactid,
            },
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.deleteCompanyContact.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "Data updated successfully"
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': "Unable to update data"
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
            var reqData = {
                id: req.companyid,
                type: 'contact',
                recordtype: "company",
            }
            dispatch(actionCreators.getRecordRelatedData(client, reqData))
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}



export const generateMailBoxId = (client) => {
    return function (dispatch) {
        const query = gql`
                    query generateMailBoxId
                    {
                        generateMailBoxId {
                            mailboxid
                        }
                    }
                `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache'
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.generateMailBoxId);
            dispatch(setResponseDetails(actionTypes.GET_GENERATED_MAIL_ID, return_data));
        }).catch((error) => {
            var returnData = {
                'data': {},
                'error': false,
                'notify_msg': ""
            }
            dispatch(setResponseDetails(actionTypes.GET_GENERATED_MAIL_ID, returnData));
        });
    }
}

export const getSuggestionData = (client, value) => {

    return function (dispatch) {
        const query = gql`
            query getSuggestionData($name: String!)
            {
                getSuggestionData(name:$name) {
                    id
                    name
                    email
                    emaillist_id_id
                    email_type
                    enrichment_data
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: value
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getSuggestionData);
            dispatch(setResponseDetails(actionTypes.GMAIL_SUGGESTION_NAMES, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const saveRelatedFiles = (client, req) => {
    console.log("saveRelatedFiles", req);
    return dispatch => {
        const mutation = gql`
             mutation saveRelatedFiles($recordid:Float!,$recortypedid:Float!,$fileInput:Upload!) {
                 saveRelatedFiles(recordid:$recordid,recortypedid:$recortypedid,fileInput:$fileInput) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                recordid: req.recordid,
                recortypedid: req.recortypedid,
                fileInput: req.fileInput,
            },
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.saveRelatedFiles.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': "Data updated successfully"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': "Unable to update data"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
                dispatch(actionCreators.getRecordRelatedData(client, req.getParams))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const deleteRelatedFiles = (client, req) => {
    return function (dispatch) {
        const query = gql`
            query deleteRelatedFiles($fileid: Float!)
            {
                deleteRelatedFiles(fileid:$fileid) {
                    status
                    message
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                fileid: req.fileid,
            },
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.deleteRelatedFiles.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "Data updated successfully"
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': "Unable to update data"
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
            dispatch(actionCreators.getRecordRelatedData(client, req.getParams))
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const getProjectStage = (client) => {
    return dispatch => {
        const query = gql`query getProjectStage {
                    getProjectStage{
                        id
                        name
                        alias
                        order_no
                        is_allowto_delete
                        is_allowto_edit
                        createdby
                        createdhost
                        createddate
                        lastmodifiedby
                        lastmodifiedhost
                        lastmodifieddate
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getProjectStage);
            dispatch(setResponseDetails(actionTypes.PROJECT_STAGE_LIST, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}


export const saveProjectStage = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation saveProjectStage($stagename: String!) {
                 saveProjectStage(stagename:$stagename) {
                    id
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                stagename: request.name,
            }
        })
            .then(response => {
                let returnData = [];
                returnData = {
                    'data': {},
                    'error': false,
                    'notify_msg': "Project stage added successfully"
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                dispatch(actionCreators.getProjectStage(client))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}
export const updateProjectStageDetail = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateProjectStageDetail($stagename: String!,$stageid: Float!) {
                 updateProjectStageDetail(stagename:$stagename,stageid:$stageid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                stagename: request.name,
                stageid: request.id,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateProjectStageDetail.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateProjectStageDetail.message
                        }
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateProjectStageDetail.message
                        }
                    }
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                dispatch(actionCreators.getProjectStage(client))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const deleteProjectStage = (client, request) => {
    return dispatch => {
        const query = gql`
             query deleteProjectStage($stageid:Float!) {
                 deleteProjectStage(stageid:$stageid) {
                    status
                    message
                 }
               }
             `;
        client.query({
            query: query,
            variables: {
                stageid: request.stageid,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.deleteProjectStage.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.deleteProjectStage.message
                        }
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.deleteProjectStage.message
                        }
                    }
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
                dispatch(actionCreators.getProjectStage(client))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const updateProjectStageOrder = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateProjectStageOrder($orderno:[Float!]!, $stageid:[Float!]!) {
                 updateProjectStageOrder(orderno:$orderno,stageid:$stageid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                orderno: request.orderno,
                stageid: request.stageid,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateProjectStageOrder.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateProjectStageOrder.message
                        }
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateProjectStageOrder.message
                        }
                    }
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}


export const addLogPined = (client, request) => {
    return dispatch => {
        const mutation = gql`
                 mutation addLogPined($ispined: Boolean!,$logid: Float!,$recordid: Float!,$recordtypeid: Float!)  {
                    addLogPined(ispined: $ispined,logid: $logid,recordid: $recordid,recordtypeid: $recordtypeid) {
                        status
                        message
                    }
                   }
                 `;
        client.mutate({
            mutation: mutation,
            variables: {
                ispined: request.ispined,
                logid: request.logid,
                recordid: request.recordid,
                recordtypeid: request.recordtypeid,
            },
        })
            .then(response => {
                let returnData = [];
                if (response.data.addLogPined.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.addLogPined.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.addLogPined.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
                if (request.recordtypeid == 1) {
                    let logparam = { leadid: request.recordid, emailid: request.emailid, logtypeid: request.logtypeid, activitypeid: request.activitypeid }
                    dispatch(actionCreators.getLeadActivityLogList(client, logparam))
                    dispatch(actionCreators.getLeadLogListNew(client, logparam))
                    dispatch(actionCreators.getLeadField(client, request.recordid))
                }
                if (request.recordtypeid == 2) {
                    let logParams = { contactid: request.recordid, emailid: request.emailid, activitypeid: request.activitypeid, logtypeid: request.logtypeid }
                    dispatch(actionCreators.getContactActivityLogList(client, logParams))
                    dispatch(actionCreators.getContactLogListNew(client, logParams))
                    dispatch(actionCreators.getContactField(client, request.recordid))
                }
                if (request.recordtypeid == 3) {
                    let logparam = { companyid: request.recordid, emailid: "", logtypeid: request.logtypeid, activitypeid: request.activitypeid };
                    dispatch(actionCreators.getCompanyRecLogListNew(client, logparam));
                    dispatch(actionCreators.getCompanyRecActivityLogList(client, logparam));
                    dispatch(actionCreators.getCompanyRecField(client, request.recordid));
                }
                if (request.recordtypeid == 4) {
                    let getlog = { opportunitiesid: request.recordid, activitypeid: request.activitypeid, logtypeid: request.logtypeid };
                    dispatch(actionCreators.getOpportunitiesLogListNew(client, getlog));
                    dispatch(actionCreators.getOpportunitiesActivityLogList(client, getlog))
                    dispatch(actionCreators.getOpportunitiesField(client, request.recordid))
                }
                if (request.recordtypeid == 5) {
                    let logparam = { projectid: request.recordid, emailid: request.emailid, logtypeid: request.logtypeid, activitypeid: request.activitypeid };
                    dispatch(actionCreators.getProjectActivityLogList(client, logparam))
                    dispatch(actionCreators.getProjectLogListNew(client, logparam))
                    dispatch(actionCreators.getProjectField(client, request.recordid))
                }
                if (request.recordtypeid == 6) {
                    let logparam = { taskid: request.recordid, logtypeid: [] };
                    dispatch(actionCreators.getTaskActivityLogList(client, logparam))
                }
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const getNotificationsList = (client) => {
    return function (dispatch) {
        const query = gql`
        query getNotificationList
        {
            getNotificationList{
                status
                message
                emailid
                today
                yesterday
                thisweek
                lastweek
                thismonth
                lastmonth
                earlier                
            }
        }
    `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getNotificationList);
            dispatch(setResponseDetails(actionTypes.NOTIFICATIONS_LIST, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const notificationReadData = (client, notificationid) => {
    return function (dispatch) {
        const query = gql`
                query notificationRead($notificationid: Float!) 
                {
                    notificationRead(notificationid: $notificationid) {
                        status
                        message
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                notificationid: notificationid
            },
        }).then(response => {
            if (response.data.notificationRead && response.data.notificationRead.status === "true") {
                dispatch(actionCreators.getNotificationsList(client))
            }
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}





export const importDataFile = (client, req) => {
    return dispatch => {
        const mutation = gql`
             mutation importDataFile($record:String!,$fileInput:Upload!) {
                 importDataFile(record:$record,fileInput:$fileInput) {
                    status
                    message
                    filename
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                record: req.record,
                fileInput: req.fileInput,
            },
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.importDataFile.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': `File ${req.fileInput.name} successfully added`
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                        dispatch(setResponseDetails(actionTypes.IMPORT_DATA_FILE, response.data.importDataFile));
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.importDataFile.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
                // dispatch(actionCreators.getRecordRelatedData(client, req.getParams))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const importExcelData = (client, req) => {

    return dispatch => {
        const query = gql`
            query importExcelData($matchingstatus: Boolean!, $matchingtype: String!, $matchingcolumn: String!,$record:String!, $filecode:String!, $columnmapdata:[JSON!]!, $fullfilename:String!, $filename:String!, $oldnewvalue:[JSON!]!,) {
                    importExcelData(matchingstatus:$matchingstatus, matchingtype:$matchingtype, matchingcolumn:$matchingcolumn, record:$record, filecode:$filecode, columnmapdata:$columnmapdata, fullfilename:$fullfilename, filename:$filename, oldnewvalue:$oldnewvalue) {
                        status
                        message
                    }
                }
            `;
        client.query({
            query: query,
            variables: {
                matchingstatus: req.matchingstatus,
                matchingcolumn: req.matchingcolumn,
                matchingtype: req.matchingtype,
                record: req.record,
                filecode: req.filecode,
                fullfilename: req.fullfilename,
                filename: req.filename,
                columnmapdata: req.columnmapdata,
                oldnewvalue: req.oldnewvalue
            },
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.importExcelData.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.importExcelData.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                        dispatch(setResponseDetails(actionTypes.IMPORT_EXCEL_DATA, response.data.importExcelData));
                        dispatch(actionCreators.getImportFileList(client))
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.importExcelData.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
                // dispatch(actionCreators.getRecordRelatedData(client, req.getParams))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const getImportFileList = (client) => {
    return function (dispatch) {
        const query = gql`
        query getImportFileList
        {
            getImportFileList{
                id
                user{
                    id
                    name
                }
                recordid
                totalrecord
                fullfilename
                filename
                filecode
                createdby
                createdhost
                createddate
            }
        }
    `;
        client.query({
            fetchPolicy: 'no-cache',
            query: query
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getImportFileList);
            dispatch(setResponseDetails(actionTypes.GET_IMPORT_FILE_LIST, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const revertImportData = (client, request) => {
    return function (dispatch) {
        const query = gql`
        query revertImportData($fileid:Float!) {
            revertImportData(fileid:$fileid) {
               status
               message
              }
          }`;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                fileid: request.field,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.revertImportData.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.revertImportData.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                        dispatch(actionCreators.getImportFileList(client))
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.revertImportData.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }

            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const getRevertDetail = (client, request) => {
    return function (dispatch) {
        const query = gql`
        query getRevertDetail($importid:Float!) {
            getRevertDetail(importid:$importid) {
                id
                user{
                    id
                    name
                }
                recordid
                totalrecord
                importrecord
                skiprecord
                fullfilename
                filename
                filecode
                createdby
                createdhost
                createddate
                lead
                contact
                company
                opportunities
              }
          }`;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                importid: request.importid,
            }
        })
            .then(response => {
                let return_data = dataSet.processGQLData(response.data.getRevertDetail);
                dispatch(setResponseDetails(actionTypes.REVERT_IMPORT_DETAIL, return_data));
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const getEmailSetting = (client) => {
    return function (dispatch) {
        const query = gql`
        query getEmailSetting{
           getEmailSetting{
              setting_one
              setting_two
              setting_three
              setting_four
              setting_five
              setting_six
              setting_seven
              setting_eight  
            }
          }
    `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getEmailSetting);
            dispatch(setResponseDetails(actionTypes.EMAIL_SETTINGS, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            // dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));

        });
    }
}
export const updateEmailSetting = (client, req) => {
    return function (dispatch) {
        const mutation = gql`
        mutation updateEmailSetting($setting: EmailSettingInput!) {
            updateEmailSetting(setting: $setting) {
            setting_one
            setting_two
            setting_three
            setting_four
            setting_five
            setting_six
            setting_seven
            setting_eight  
          }
        }
      `;
        client.mutate({
            mutation: mutation,
            variables: {
                setting: req.setting,
            }
        }).then((response) => {
            let return_data = dataSet.processGQLData(response.data.updateEmailSetting);
            dispatch(setResponseDetails(actionTypes.EMAIL_SETTINGS, return_data));
        }).catch((error) => {
            var returnData = {
                data: {},
                error: false,
                notify_msg: "",
            };
            dispatch(setResponseDetails(actionTypes.EMAIL_SETTINGS, returnData)
            );
        });
    };
};

export const sendGmail = (client, req) => {
    console.log("all request", req)
    return function (dispatch) {
        const mutation = gql`
        mutation sendGmail($mail: MailInput!,$fileInput: [Upload!]!) {
            sendGmail(mail: $mail,fileInput:$fileInput) {
                status
                message
          }
        }
      `;
        client.mutate({
            mutation: mutation,
            variables: req['Send'],
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.sendGmail.status == "true") {
                    if (req['LogComponent'] === "Lead" ) {
                        dispatch(actionCreators.getLeadLogListNew(client, req['LogDetails']))
                        dispatch(actionCreators.getLeadActivityLogList(client, req['LogDetails']))
                    }
                    if (req['LogComponent'] === "Contact") {
                        dispatch(actionCreators.getContactLogListNew(client, req['LogDetails']))
                        dispatch(actionCreators.getContactActivityLogList(client, req['LogDetails']))
                    }
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.sendGmail.message
                    }

                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.sendGmail.message
                    }
                }
            }
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const exportRecordData = (client, req) => {

    return dispatch => {
        const query = gql`
             query exportRecordData($recordtypeid:Float!) {
                 exportRecordData(recordtypeid:$recordtypeid) {
                    status
                    message
                 }
               }
             `;
        client.query({
            query: query,
            variables: {
                recordtypeid: req.recordtypeid,
            },
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.exportRecordData.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.exportRecordData.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.exportRecordData.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const sampleFileExport = (client, req) => {

    return dispatch => {
        const query = gql`
             query sampleFileExport($recordtypeid:Float!, $type:String!) {
                 sampleFileExport(recordtypeid:$recordtypeid, type:$type) {
                    status
                    message
                 }
               }
             `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                recordtypeid: req.recordtypeid,
                type: req.type,
            },
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {

                    if (response.data.sampleFileExport.message) {
                        let return_data = dataSet.processGQLData(response.data.sampleFileExport);
                        dispatch(setResponseDetails(actionTypes.SAMPLE_IMPORT_FILE, return_data));
                    }
                    // else {
                    //     returnData = {
                    //         'data': {},
                    //         'error': true,
                    //         'notify_msg': response.data.sampleFileExport.message
                    //     }
                    //     dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    // }
                }
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const getNotificationSetting = (client) => {
    return function (dispatch) {
        const query = gql`
        query getNotificationSetting
        {
            getNotificationSetting{
                id
                in_app
                in_email
                in_mobile
                notification_type       
            }
        }
    `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache'
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getNotificationSetting);
            dispatch(setResponseDetails(actionTypes.GET_NOTIFICATIONS_SETTINGS, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const UpdateNotificationSetting = (client, request) => {
    return function (dispatch) {
        const mutation = gql`
        mutation updateNotificationSetting($settingdata:[JSON!]!) {
            updateNotificationSetting(settingdata:$settingdata) {
               status
               message
              }
          }`;
        client.mutate({
            mutation: mutation,
            variables: {
                settingdata: request.settingdata,
            }
        })
            .then(response => {
                let return_data = dataSet.processGQLData(response.data.updateNotificationSetting);
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}
export const getFollowSetting = (client) => {
    return function (dispatch) {
        const query = gql`
        query getFollowSetting
        {
            getFollowSetting{
                id
                notification_type
                status      
            }
        }
    `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache'
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getFollowSetting);
            dispatch(setResponseDetails(actionTypes.GET_FOLLOW_SETTINGS, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const UpdateFollowSetting = (client, request) => {
    return function (dispatch) {
        const mutation = gql`
        mutation updateFollowSetting($settingdata:[JSON!]!) {
            updateFollowSetting(settingdata:$settingdata) {
               status
               message
              }
          }`;
        client.mutate({
            mutation: mutation,
            variables: {
                settingdata: request.settingdata,
            }
        })
            .then(response => {
                let return_data = dataSet.processGQLData(response.data.updateFollowSetting);
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const getUserIntegration = (client) => {
    return function (dispatch) {
        const query = gql`
        query getUserIntegration{
            getUserIntegration{
              id
              integrationid
              status
            }
          }
    `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache'
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getUserIntegration);
            dispatch(setResponseDetails(actionTypes.GET_USER_INTEGRATION, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const UpdateIntegrationStatus = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateIntegrationStatus($status: Boolean!, $integrationid: Float!) {
                 updateIntegrationStatus(status:$status,integrationid:$integrationid) {
                        status
                        id
                        integrationid
                    }
                }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                status: request.status,
                integrationid: request.integrationid,
            }
        })
            .then(response => {
                let return_data = dataSet.processGQLData(response.data.updateIntegrationStatus);
                // dispatch(setResponseDetails(actionTypes.GET_USER_INTEGRATION, return_data));
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const saveDropboxRelatedFiles = (client, req) => {
    console.log("saveDropboxRelatedFiles", req);
    return dispatch => {
        const mutation = gql`
             mutation saveDropboxRelatedFiles($filename:String!, $dropboxlink:String!, $recordid:Float!, $recortypedid:Float! ) {
                 saveDropboxRelatedFiles(filename:$filename, dropboxlink:$dropboxlink, recordid:$recordid ,recortypedid:$recortypedid ) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                recordid: req.recordid,
                recortypedid: req.recortypedid,
                filename: req.filename,
                dropboxlink: req.dropboxlink,
            },
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.saveDropboxRelatedFiles.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': "Data updated successfully"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': "Unable to update data"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
                dispatch(actionCreators.getRecordRelatedData(client, req.getParams))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}



export const saveGoogleRelatedFiles = (client, req) => {
    console.log("req", req);
    return dispatch => {
        const mutation = gql`
                 mutation saveGoogleRelatedFiles($googlefileid:String!, $filename:String!, $googlefilelink:String!, $recordid:Float!, $recortypedid:Float! ) {
                     saveGoogleRelatedFiles(googlefileid:$googlefileid, filename:$filename, googlefilelink:$googlefilelink, recordid:$recordid ,recortypedid:$recortypedid ) {
                        status
                        message
                     }
                   }
                 `;
        client.mutate({
            mutation: mutation,
            variables: {
                recordid: req.recordid,
                recortypedid: req.recortypedid,
                filename: req.filename,
                googlefilelink: req.googlefilelink,
                googlefileid: req.googlefileid,
            },
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    console.log(response.data.saveGoogleRelatedFiles, "saveGoogleRelatedFiles");
                    if (response.data.saveGoogleRelatedFiles.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': "Data updated successfully"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': "Unable to update data"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
                dispatch(actionCreators.getRecordRelatedData(client, req.getParams))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}


export const saveIntegrationSetting = (client, request) => {
    return function (dispatch) {
        const mutation = gql`
        mutation saveIntegrationSetting($integration:IntegrationInput!) {
            saveIntegrationSetting(integration:$integration) {
                id
                integrationid
                status
                email
                ticketstatus
                sortby
                domain
                accesstoken
                refereshtoken
                maxnumberrecords
                oncompanies
                onleads
                onopportunities
                onpeople
                onproject
              }
          }`;
        client.mutate({
            mutation: mutation,
            variables: request.data['data'],
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.saveIntegrationSetting);
            console.log(return_data, "API Response of saveIntegrationSetting");
            dispatch(setResponseDetails(actionTypes.GET_INTEGRATION_DETAILS, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}



export const zendeskVerify = (value) => {
    return function (dispatch) {
        return axios.post(Server.Intregation_URI + "/zendesk/validate-token/", value.data, configForIntregation)
            .then(({ data }) => {
                console.log(data, "data");
                let returnData = [];
                if (data.error === false) {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "Data updated successfully",
                    }
                    dispatch(setResponseDetails(actionTypes.ZENDESK_VERTFY, value.getParams));
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }).catch(function (error) {
                let returnData = [];
                returnData = {
                    'data': {},
                    'error': true,
                    'notify_msg': "InValid Data",
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
            });
    };
};

export const getIntegrationDetail = (client, id) => {
    return function (dispatch) {
        const query = gql`
        query getIntegrationDetail($integrationid:Float!)
        {
            getIntegrationDetail(integrationid:$integrationid){
                id
                integrationid
                status
                email
                domain
                accesstoken
                refereshtoken
                maxnumberrecords
                oncompanies
                onleads
                onopportunities
                onpeople
                onproject
                sortby
                ticketstatus
                createdby
                createddate
                createdhost
                lastmodifiedby
                lastmodifieddate
                lastmodifiedhost                
            }
        }
    `;
        client.query({
            query: query,
            variables: {
                integrationid: id,
            },
            fetchPolicy: 'no-cache'
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getIntegrationDetail);
            dispatch(setResponseDetails(actionTypes.GET_INTEGRATION_DETAILS, return_data));
        }).catch((error) => {
            // let err_data = errorMessageGraphQl(error);
            // dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const TicketZendeskList = (value) => {
    return function (dispatch) {
        return axios.post(Server.Intregation_URI + "/zendesk/tickets/", value, configForIntregation)
            .then(({ data }) => {
                let return_data = dataSet.processGQLData(data);
                dispatch(setResponseDetails(actionTypes.GET_TICKET_LIST, return_data));
            }).catch((error) => {
                // dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            });
    };
};

export const GenerateZapierToken = (client) => {
    return function (dispatch) {
        const query = gql`
        query generateZapierToken{
            generateZapierToken{
                token
                email              
            }
        }
    `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache'
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.generateZapierToken);
            if (return_data.data) {
                const Genratedata = return_data.data;
                let val = {
                    integration: {
                        integrationid: 5,
                        status: true,
                        email: Genratedata.email,
                        sortby: '',
                        domain: '',
                        accesstoken: Genratedata.token,
                        refereshtoken: "",
                        maxnumberrecords: 5,
                        oncompanies: false,
                        onleads: false,
                        onopportunities: false,
                        onpeople: false,
                        onproject: false,
                        ticketstatus: '',
                    }
                }
                let data = [];
                data['data'] = val;
                let id = 5;
                let req = { data, id };
                // console.log(req, "response of generateZapierToken UI for saveIntegrationSetting");
                dispatch(actionCreators.saveIntegrationSetting(client, req))
                // dispatch(setResponseDetails(actionTypes.GENERATE_ZAPIER_TOKEN, return_data));
            }
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
};

export const copyTokenNotification = (client) => {
    return function (dispatch) {
        let returnData = [];
        returnData = {
            'data': {},
            'error': false,
            'notify_msg': "Copied the token successfully"
        }
        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
    }
};

export const getQuickBookInvoice = (value) => {
    return function (dispatch) {
        return axios.post(Server.Intregation_URI + "quickbook/invoice/", value, configForIntregation)
            .then(({ data }) => {
                var main = {
                    data: {
                        data: data.data,
                        count: data.count,
                        next: data.next,
                    }
                }
                let return_data = dataSet.processData(main);
                dispatch(setResponseDetails(actionTypes.RELATED_DATALIST_QUICKBOOK, return_data));
                fetchData(dispatch)
            }).catch(function (error) {
                var main = {
                    data: {
                        data: [],
                        count: 0,
                        next: "",
                    }
                }
                let return_data = dataSet.processData(main);
                dispatch(setResponseDetails(actionTypes.RELATED_DATALIST_QUICKBOOK, return_data));
                fetchData(dispatch)
            });
    };
};

export const getXeroInvoice = (value) => {
    return function (dispatch) {
        return axios.post(Server.Intregation_URI + "xero/invoice/", value, configForIntregation)
            .then(({ data }) => {
                var main = {
                    data: {
                        data: data.data,
                        count: data.count,
                        next: data.next,
                        page_size: data.page_size
                    }
                }
                let return_data = dataSet.processData(main);
                dispatch(setResponseDetails(actionTypes.RELATED_DATALIST_XERO, return_data));
                fetchData(dispatch)
            }).catch(function (error) {
                var main = {
                    data: {
                        data: [],
                        count: 0,
                        next: "",
                        page_size: 0
                    }
                }
                let return_data = dataSet.processData(main);
                dispatch(setResponseDetails(actionTypes.RELATED_DATALIST_XERO, return_data));
                fetchData(dispatch)
            });
    };
};


export const getSlackSetting = (client) => {
    return function (dispatch) {
        const query = gql`
        query getSlackSetting{
            getSlackSetting{
                lead_one
                opp_status_one
                opp_status_two
                opp_status_three
                opp_warning_one
                opp_warning_two
                opp_warning_three
                opp_update_one
                opp_update_two
                opp_update_three
                opp_update_four
            }
          }
    `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getSlackSetting);
            dispatch(setResponseDetails(actionTypes.SLACK_SETTINGS, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));

        });
    }
}
export const saveSlackSetting = (client, req) => {
    return function (dispatch) {
        const mutation = gql`
        mutation saveSlackSetting($slacksetting: SlackSettingInput!) {
            saveSlackSetting(slacksetting: $slacksetting) {
                lead_one
                opp_status_one
                opp_status_two
                opp_status_three
                opp_warning_one
                opp_warning_two
                opp_warning_three
                opp_update_one
                opp_update_two
                opp_update_three
                opp_update_four
                } 
        }
      `;
        client.mutate({
            mutation: mutation,
            variables: {
                slacksetting: req
            }
        }).then((response) => {
            let return_data = dataSet.processGQLData(response.data.saveSlackSetting);
            dispatch(setResponseDetails(actionTypes.SLACK_SETTINGS, return_data));
        }).catch((error) => {
            var returnData = {
                data: {},
                error: false,
                notify_msg: "",
            };
        });
    };
};



export const getSlackChannelList = (client) => {
    return function (dispatch) {
        const query = gql`
        query getSlackChannelList{
            getSlackChannelList{
                id
                channel_id
                channel_name
            }
          }
    `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getSlackChannelList);
            dispatch(setResponseDetails(actionTypes.SLACK_CHANNELS, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}



export const getAutomationList = (client) => {
    return function (dispatch) {
        const query = gql`
        query getAutomationList{
           getAutomationList{
                id
                name
                user{
                    id
                    name
                    email
                }
                triggertype
                triggerrecordid
                triggercondition
                actiondetail
                filtercondition
                isactive
                createddate
            }
          }
    `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getAutomationList);
            dispatch(setResponseDetails(actionTypes.AUTOMATION_LIST, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));

        });
    }
}

export const saveAutomation = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation saveAutomation($saveData: SaveAutomationInput!) {
                 saveAutomation(saveData:$saveData) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                saveData: request,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.saveAutomation.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.saveAutomation.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.saveAutomation.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}


export const deleteAutomation = (client, request) => {
    return function (dispatch) {
        const query = gql`
            query deleteAutomation($automationid: Float!) 
            {
                deleteAutomation(automationid: $automationid) {
                    status
                    message
                }
            }
        `;
        client.query({
            query: query,
            variables: request
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.deleteAutomation.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.deleteAutomation.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.deleteAutomation.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }

            dispatch(actionCreators.getAutomationList(client))

        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const updateAutomationStatus = (client, request) => {
    return function (dispatch) {
        const mutation = gql`
            mutation updateAutomationStatus($id: Float!, $status: Boolean!) 
            {
                updateAutomationStatus(id: $id, status: $status) {
                    status
                    message
                }
            }
        `;
        client.mutate({
            mutation: mutation,
            variables: request
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.updateAutomationStatus.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.updateAutomationStatus.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.updateAutomationStatus.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }

            dispatch(actionCreators.getAutomationList(client))

        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const updateAutomationTimezone = (client, request) => {
    return function (dispatch) {
        const mutation = gql`
            mutation updateAutomationTimezone($timezone: String!) 
            {
                updateAutomationTimezone(timezone: $timezone) {
                    status
                    message
                }
            }
        `;
        client.mutate({
            mutation: mutation,
            variables: { timezone: request.timezone }
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.updateAutomationTimezone.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.updateAutomationTimezone.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.updateAutomationTimezone.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
            dispatch(actionCreators.getUserSetting(client, { userid: request.userid }))

        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const getTaskAutomationList = (client) => {
    return function (dispatch) {
        const query = gql`
        query getTaskAutomationList{
           getTaskAutomationList{
                id
                name
                entity
                trigger
                taskdata
            }
          }
    `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getTaskAutomationList);
            dispatch(setResponseDetails(actionTypes.TASK_AUTOMATION_LIST, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));

        });
    }
}





export const VerifyMailchimpData = (value) => {
    return function (dispatch) {
        return axios.post(Server.Intregation_URI + "mailchimp/validate-token/", value.data, configForIntregation)
            .then(({ data }) => {
                let returnData = [];
                if (data.error === false) {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "Data updated successfully",
                    }
                    dispatch(setResponseDetails(actionTypes.MAILCHIMP_DATA_VERTFY, value));
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }).catch(function (error) {
                let returnData = [];
                returnData = {
                    'data': {},
                    'error': true,
                    'notify_msg': "The API key was invalid. Please double-check and try again",
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
            });
    };
};

export const getMailchimpAudience = () => {
    return function (dispatch) {
        return axios.get(Server.Intregation_URI + "mailchimp/audience/", configForIntregation)
            .then(({ data }) => {
                let return_data = data;
                dispatch(setResponseDetails(actionTypes.GET_MAILCHIMP_AUDIENCE, return_data));
            }).catch(function (error) {
                // dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            });
    };
};

export const AddMailchimpSubscriber = (value) => {
    return function (dispatch) {
        return axios.post(Server.Intregation_URI + "mailchimp/add-member-in-audience/", value, configForIntregation)
            .then(({ data }) => {
                let return_data = dataSet.processGQLData(data);
                let returnData = [];
                if (data.error === false) {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "Data Subscribed successfully",
                    }
                    console.log(value.id.length, value.id, "kdnfknsoindflsndo");
                    if (value.id.length === 1) {
                        let req = {
                            type: value.type,
                            id: parseInt(value.id),
                        }
                        dispatch(actionCreators.getMemberInAudience(req))
                    }
                    dispatch(setResponseDetails(actionTypes.ADD_MAILCHIMP_SUBSCRIBER, return_data));
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }).catch((error) => {
                // dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            });
    };
};

export const getMemberInAudience = (value) => {
    return function (dispatch) {
        return axios.post(Server.Intregation_URI + "/mailchimp/member-in-audience/", value, configForIntregation)
            .then(({ data }) => {
                let return_data = data;
                dispatch(setResponseDetails(actionTypes.GET_MEMBER_IN_AUDIENCE, return_data));
            }).catch(function (error) {
                // dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            });
    };
};

export const EmailExistorNotNotification = (client) => {
    return function (dispatch) {
        let returnData = [];
        returnData = {
            'data': {},
            'error': true,
            'notify_msg': "Mailchimp requires an email Address"
        }
        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
    }
};

export const deleteMemberInAudience = (value) => {
    return function (dispatch) {
        return axios.post(Server.Intregation_URI + "/mailchimp/delete-member-in-audience/", value, configForIntregation)
            .then(({ data }) => {
                let returnData = {};
                if (data.error === false) {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "Data Removed successfully",
                    }
                    console.log(value.id.length, value.id, "kdnfknsoindflsndo");
                    if (value.id.length === 1) {
                        let req = {
                            type: value.type,
                            id: parseInt(value.id),
                        }
                        dispatch(actionCreators.getMemberInAudience(req))
                    }
                    dispatch(setResponseDetails(actionTypes.DELETE_MEMBER_IN_AUDIENCE, returnData));
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }).catch(function (error) {
                // dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            });
    };
};




export const getAutomationDetail = (client, request) => {
    return function (dispatch) {
        const query = gql`
        query getAutomationDetail($automationid: Float!) 
        {
            getAutomationDetail(automationid :$automationid) {
                id
                name
                user{
                    id
                    name
                    email
                }
                triggertype
                triggerrecordid
                triggercondition
                actiondetail
                filtercondition
                isactive
                createddate
                alldraftfielddata
            }
        }`;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: request,
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getAutomationDetail);
            dispatch(setResponseDetails(actionTypes.AUTOMATION_DETAIL, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));

        });
    }
}

export const getEmailTrackingList = (client, request) => {
    return function (dispatch) {
        const query = gql`
            query getEmailTrackingList($limit: Float!,$pageno: Float!) 
            {
                getEmailTrackingList(limit :$limit,pageno:$pageno)  {
                    status
                    message
                    today
                    yesterday
                    thisweek
                    lastweek
                    thismonth
                    lastmonth
                    earlier
                    totalpage
                    totalrecord
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: { limit: request.limit, pageno: request.pageno }
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getEmailTrackingList);
            dispatch(setResponseDetails(actionTypes.EMAIL_TRACKING_LIST, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
};

export const getDocuSignRole = (client) => {
    return function (dispatch) {
        const query = gql`
        query getDocuSignRole{
                getDocuSignRole{
                    id
                    name
                    order_no
                
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getDocuSignRole);
            console.log(return_data, "return_data");
            dispatch(setResponseDetails(actionTypes.GET_DOCUSING_ROLE, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
};

export const saveDocuSignRole = (client, request) => {
    return function (dispatch) {
        const mutation = gql`
        mutation saveDocuSignRole($signrole:[JSON!]!) {
            saveDocuSignRole(signrole:$signrole) {
                id
                name
                order_no
              }
          }`;
        client.mutate({
            mutation: mutation,
            variables: {
                signrole: request.signrole,
            }
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                console.log(response.data.saveDocuSignRole, 'data');
                if (response.data.saveDocuSignRole) {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "Data updated successfully"
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    let return_data = dataSet.processGQLData(response.data.saveDocuSignRole);
                    console.log(return_data, "return_data");
                    dispatch(setResponseDetails(actionTypes.GET_DOCUSING_ROLE, return_data));
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': "Unable to save data"
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
};

export const deleteDocuSign = (client, docusignid) => {
    return function (dispatch) {
        const query = gql`
            query deleteDocuSign($docusignid: Float!) 
            {
                deleteDocuSign(docusignid: $docusignid) {
                    status
                    message
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                docusignid: parseInt(docusignid)
            },
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.deleteDocuSign.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.deleteDocuSign.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.deleteDocuSign.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
            dispatch(actionCreators.getDocuSignRole(client))
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
};

export const SaveDocuSignRecipient = (value) => {
    return function (dispatch) {
        return axios.post(Server.Intregation_URI + "ds/create/", value, configForIntregation)
            .then(({ data }) => {
                console.log("response Data :" , data);
                let return_data = data;
                if (return_data.data) {
                    let getData = {
                        id: return_data.data.request_id,
                        type: return_data.data.request_type,
                        page: 1,
                    }
                    dispatch(actionCreators.GetDocuSignData(getData))
                    if (return_data.data.sender_view_url) {
                        console.log("data URL", return_data.data.sender_view_url);
                        window.open(return_data.data.sender_view_url, '_blank');
                    }
                }
                dispatch(setResponseDetails(actionTypes.CREATES_DOCUSIGN, return_data));
            }).catch(function (error) {
                // console.log(error);
                // dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            });
    };
};

export const GetDocuSignData = (value) => {
    return function (dispatch) {
        return axios.post(Server.Intregation_URI + "ds/get/", value, configForIntregation)
            .then(({ data }) => {
                let return_data = dataSet.processGQLData(data);
                dispatch(setResponseDetails(actionTypes.GET_DOCUSING_DATA, return_data));
            }).catch(function (error) {
                // dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            });
    };
};

export const RemoveDocuSignData = (value) => {
    return function (dispatch) {
        return axios.post(Server.Intregation_URI + "ds/delete/" + value.fileid + '/', value, configForIntregationB)
            .then(({ data }) => {
                let return_data = dataSet.processGQLData(data);
                dispatch(actionCreators.GetDocuSignData(value.Data))
                let returnData = {
                    'data': {},
                    'error': false,
                    'notify_msg': "Data Removed successfully",
                }

                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false))
            }).catch(function (error) {
                // dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
            });
    };
};

export const getSyncTime = (client) => {
    return function (dispatch) {
        const query = gql`
            query getSyncTime 
            {
                getSyncTime{
                    id
                    roleId
                    companyId
                    userId
                    accountsetting
                    systemsetting
                    cmpuserid
                    emailsynctime
                    token_expired
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getSyncTime);
            dispatch(setResponseDetails(actionTypes.GET_SYNC_TIME, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
};

export const dataResync = (client,request) => {
    return function (dispatch) {
        const query = gql`
            query dataResync 
            {
                dataResync{
                    status
                    message
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.dataResync);
            let returnData = [];
            if (response.data.dataResync.status == "true") {
                dispatch(actionCreators.getSyncTime(client));
            } 
            if (request.recordtypeid == 1) {
                let logparam = { leadid: request.recordid, emailid: request.emailid, logtypeid: request.logtypeid, activitypeid: request.activitypeid }
                dispatch(actionCreators.getLeadActivityLogList(client, logparam))
                dispatch(actionCreators.getLeadLogListNew(client, logparam))
                dispatch(actionCreators.getLeadField(client, request.recordid))
            }
            if (request.recordtypeid == 2) {
                let logParams = { contactid: request.recordid, emailid: request.emailid, activitypeid: request.activitypeid, logtypeid: request.logtypeid }
                dispatch(actionCreators.getContactActivityLogList(client, logParams))
                dispatch(actionCreators.getContactLogListNew(client, logParams))
                dispatch(actionCreators.getContactField(client, request.recordid))
            }
            if (request.recordtypeid == 3) {
                let logparam = { companyid: request.recordid, emailid: "", logtypeid: request.logtypeid, activitypeid: request.activitypeid };
                dispatch(actionCreators.getCompanyRecLogListNew(client, logparam));
                dispatch(actionCreators.getCompanyRecActivityLogList(client, logparam));
                dispatch(actionCreators.getCompanyRecField(client, request.recordid));
            }
            if (request.recordtypeid == 4) {
                let getlog = { opportunitiesid: request.recordid, activitypeid: request.activitypeid, logtypeid: request.logtypeid };
                dispatch(actionCreators.getOpportunitiesLogListNew(client, getlog));
                dispatch(actionCreators.getOpportunitiesActivityLogList(client, getlog))
                dispatch(actionCreators.getOpportunitiesField(client, request.recordid))
            }
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
};

export const saveRingCentralData = (client, request) => {
    console.log(request, 'request');
    return function (dispatch) {
        const mutation = gql`
        mutation saveRingCentralData($text:String!,$sourceid:Float!,$sourcetype:Float!) {
            saveRingCentralData(text:$text,sourceid:$sourceid,sourcetype:$sourcetype) {
                status
                message
              }
          }`;
        client.mutate({
            mutation: mutation,
            variables: {
                sourcetype: request.sourcetype,
                sourceid: request.sourceid,
                text: request.text,

            }
        }).then(response => {
            if (response.data && response.data != undefined) {
                console.log(response.data.saveRingCentralData, 'data');
                let return_data = dataSet.processGQLData(response.data.saveRingCentralData);
                // console.log(return_data, "return_data");
                // dispatch(setResponseDetails(actionTypes.GET_DOCUSING_ROLE, return_data));
            }
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
};
export const companyUpdate = (client, request) => {
    return function (dispatch) {
        const mutation = gql`
        mutation companyUpdate($allowsystemadmin:Boolean!,$companyname:String!) {
            companyUpdate(allowsystemadmin:$allowsystemadmin,companyname:$companyname) {
                id
                name    
                displayname
                allowsystemadmin
                companyphonenumber
                bussinesstype
                companyinfo
                profileimage
              }
          }`;
        client.mutate({
            mutation: mutation,
            variables: {
                allowsystemadmin: request.allowsystemadmin,
                companyname: request.companyname,
            }
        }).then(response => {
            if (response.data && response.data != undefined) {
                let return_data = dataSet.processGQLData(response.data.companyUpdate);
                dispatch(setResponseDetails(actionTypes.GET_COMPANY_DETAILS, return_data));
            }
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
};
export const getCompanyDetail = (client, request) => {
    return function (dispatch) {
        const query = gql`
            query getCompanyDetail($companyid: Float!) 
            {
                getCompanyDetail(companyid:$companyid){
                    id
                    name    
                    displayname
                    allowsystemadmin
                    companyphonenumber
                    bussinesstype
                    companyinfo
                    profileimage
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                companyid: request.companyid,
            }
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getCompanyDetail);
            dispatch(setResponseDetails(actionTypes.GET_COMPANY_DETAILS, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
};

export const masterSearch = (client, request) => {
    console.log("masterSearch req test :", request.searchtext);
    return function (dispatch) {
        const query = gql`
            query masterSearch($searchtext: String!) 
            {
                masterSearch(searchtext:$searchtext){
                    status
                    message    
                    type
                    data
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                searchtext: request.searchtext,
            }
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.masterSearch);
            dispatch(setResponseDetails(actionTypes.MASTER_SEARCH, return_data));
        }).catch((error) => {
            // let err_data = errorMessageGraphQl(error);
            // dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
};

export const CheckLoginToken = (value) => {
    return function (dispatch) {
        return axios.post(Server.API + "UserToken/CheckLoginToken", {
            email: value.email,
            token: value.token
        }).then(({ data }) => {
            let return_data = dataSet.processData(data);
            dispatch(setResponseDetails(actionTypes.GET_USER_BY_ID, return_data));
        }).catch(function (error) {
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
        });
    };
};

export const getUserDetailByEmail = (client, request) => {
    // console.log("getUserDetailByEmail req test :", request.email);
    return function (dispatch) {
        const query = gql`
            query getUserDetailByEmail($email: String!) 
            {
                getUserDetailByEmail(email:$email){
                    id
                    name
                    email
                    systemadmin
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                email: request.email,
            }
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getUserDetailByEmail);
            // console.log("data" ,response.data.getUserDetailByEmail);
            dispatch(setResponseDetails(actionTypes.GET_USER_DETAIL_BY_EMAIL, return_data));
        }).catch((error) => {
            // let err_data = errorMessageGraphQl(error);
            // dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
};

export const getPlanList = (client) => {
    return function (dispatch) {
        const query = gql`
            query getPlanList
            {
                getPlanList{
                    data
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            // variables: {
            //     searchtext: request.searchtext,
            // }
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getPlanList);
            dispatch(setResponseDetails(actionTypes.BILLING_PLANS_LIST, return_data));
        }).catch((error) => {
            // let err_data = errorMessageGraphQl(error);
            // dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
};

export const companyPlanPurchase = (client, request) => {
    return function (dispatch) {
        const mutation = gql`
        mutation companyPlanPurchase($paymentdata:CompanyPlanInput!) {
            companyPlanPurchase(paymentdata:$paymentdata) {
                status
                message
                data
            }
        }`;
        client.mutate({
            mutation: mutation,
            variables: {
                paymentdata: request,
            }
        }).then(response => {
            if (response.data && response.data != undefined) {
                let return_data = dataSet.processGQLData(response.data.companyPlanPurchase);
                dispatch(setResponseDetails(actionTypes.COMPANY_PLAN_PURCHASE, return_data));
            }
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
};

export const getPlanDetail = (client) => {
    return function (dispatch) {
        const query = gql`
            query getPlanDetail
            {
                getPlanDetail{
                    id
                    company{
                    id
                    name
                    }
                    plan{
                    id
                    planname
                    }
                    transactiondata
                    purchasedate
                    expiredate
                    createddate
                    updateddate
                    firstname
                    lastname
                    address1
                    address2
                    city
                    state
                    postalcode
                    country{
                        id
                        name
                        code
                    }
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getPlanDetail);
            dispatch(setResponseDetails(actionTypes.SELECTED_PLAN_DETALS, return_data));
        }).catch((error) => {
            // let err_data = errorMessageGraphQl(error);
            // dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
};


export const getRelatedContactsData = (client, request) => {
    return function (dispatch) {
        const query = gql`
            query getRelatedContactsData($id:[Float!]!,$type:String!,$recordtype:String!) 
            {
                getRelatedContactsData(id:$id, type:$type, recordtype:$recordtype){
                    status
                    message
                    type
                    data
                }
            }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                id: request.id,
                type: request.type,
                recordtype: request.recordtype,
            }
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getRelatedContactsData);
            console.log('return_data', return_data);
            dispatch(setResponseDetails(actionTypes.GET_RELATED_CONTACTS__DATA, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
};

export const updateTagDetail = (client, request) => {
    return dispatch => {
        const mutation = gql`
            mutation updateTagDetail($tagname: String!, $tagid: Float!) {
                updateTagDetail(tagname:$tagname, tagid:$tagid) {
                    id
                }
            }
            `;
        client.mutate({
            mutation: mutation,
            variables: {
                tagname: request.tagname,
                tagid: request.tagid,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateTagDetail.id) {
                        returnData = {
                            'data': response.data.updateTagDetail,
                            'error': false,
                            'notify_msg': "Data updated successfully"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': request.tagname,
                            'error': true,
                            'notify_msg': "Unable to update data"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
                dispatch(actionCreators.getTagsList(client))

            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}
export const deleteTag = (client, request) => {
    return dispatch => {
        const query = gql`
        query deleteTag( $tagid: Float!) {
                deleteTag(tagid:$tagid) {
                    status
                    message
                }
            }
            `;
        client.query({
            query: query,
            variables: {
                tagid: request.tagid,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    let returnData = [];
                    if (response.data && response.data != undefined) {
                        if (response.data.deleteTag.status == "true") {
                            returnData = {
                                'data': {},
                                'error': false,
                                'notify_msg': response.data.deleteTag.message
                            }
                        } else {
                            returnData = {
                                'data': {},
                                'error': true,
                                'notify_msg': response.data.deleteTag.message
                            }
                        }
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
                }
                dispatch(actionCreators.getTagsList(client))

            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

// export const getPlanDetail = (client) => {
//     return function (dispatch) {
//         const query = gql`
//             query getPlanDetail
//             {
//                 getPlanDetail{
//                     id
//                     company{
//                     id
//                     name
//                     }
//                     plan{
//                     id
//                     planname
//                     }
//                     transactiondata
//                     purchasedate
//                     expiredate
//                     createddate
//                     updateddate
//                     firstname
//                     lastname
//                     address1
//                     address2
//                     city
//                     state
//                     postalcode
//                 }
//             }
//         `;
//         client.query({
//             query: query,
//             fetchPolicy: 'no-cache',
//             // variables: {
//             //     searchtext: request.searchtext,
//             // }
//         }).then(response => {
//             let return_data = dataSet.processGQLData(response.data.getPlanDetail);
//             dispatch(setResponseDetails(actionTypes.SELECTED_PLAN_DETALS, return_data));
//         }).catch((error) => {
//             // let err_data = errorMessageGraphQl(error);
//             // dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
//         });
//     }
// };

// export const getTagsList = (client, request) => {
//     return function (dispatch) {
//         const mutation = gql`
//         mutation getTagsList{
//             getTagsList{
//                 id
//                 tagname
//                 createdby
//                 createdhost
//                 createddate
//                 lastmodifiedby
//                 lastmodifiedhost
//                 lastmodifieddate
//                 taglist
//             }
//         }`;
//         client.mutate({
//             mutation: mutation,
//             variables: {
//                 paymentdata: request,
//             }
//         }).then(response => {
//             if (response.data && response.data != undefined) {
//                 let return_data = dataSet.processGQLData(response.data.getTagsList);
//                 dispatch(setResponseDetails(actionTypes.GET_TAG_LIST, return_data));
//             }
//         }).catch((error) => {
//             let err_data = errorMessageGraphQl(error);
//             dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
//         });
//     }
// };
