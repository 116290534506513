import React, { Component } from 'react';
import { Icon, withStyles } from "@material-ui/core";

const IconLock = withStyles(theme => ({
  root: {
    fontSize: "0.98rem",
    color: "#bdbdbd73",
    marginBottom: "-3px !important",
    marginLeft: "3px"
  }
}))(Icon);

const CommonDisbledLock = props => {
  return (
    <IconLock className="mb--4">lock</IconLock>
  )
}
export default CommonDisbledLock;
