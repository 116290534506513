import React, { Component } from 'react';
// import './App.css';
import { AgGridReact } from 'ag-grid-react';
// import '../../../../node_modules/@ag-grid-community/core/dist/styles/ag-grid.css';
// import '../../../../node_modules/@ag-grid-community/core/dist/styles/ag-theme-alpine.css';

import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';

import { AllCommunityModules } from '@ag-grid-community/all-modules';

class TableComponent extends Component {
  constructor(props) {
    super(props);

  }


  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  render() {
    
    if (this.gridApi != undefined){
      var params = {
        force: true,
        suppressFlash: true,
      };
      this.gridApi.refreshCells(params);
    }
    
    return (
      <div
        className="ag-theme-alpine"
        style={{
          height: 'calc(100vh - 200px)',
          width: '100%'
        }}
      >
        <AgGridReact

          modules={AllCommunityModules}
          columnDefs={this.props.data['cols_arr']}
          rowData={this.props.data['rows']}
          enableCellChangeFlash={true}
          onGridReady={this.onGridReady}
        />
        
      </div>
    );
  }
}

export default TableComponent;