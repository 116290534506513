import React, { Component } from "react";
import { Popover, Tooltip, FormControl, Input, FormHelperText, Divider, Button, IconButton, Icon, withStyles, Card, DialogContent, Dialog, DialogActions, DialogTitle, Typography, MenuItem, InputLabel, Radio, FormControlLabel, RadioGroup, Badge, Drawer } from "@material-ui/core";
import Grid, { default as MaterialGrid } from '@material-ui/core/Grid';


import ReportSidebarMenu from "../Layout/ReportSidebarMenu";
import Select from '@material-ui/core/Select';
import FilterListIcon from '@material-ui/icons/FilterList';
// import Chart from "react-google-charts";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import moment from 'moment';//moment
import 'moment-timezone';//timezone
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import { opportunityFilterComponent } from './opportunityFilterComponent'
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import { Link } from "react-router-dom";
import * as actions from '../../store/actions/index';
import { connect } from "react-redux";



class PipelineSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportTitile: "Pipeline Summary ",
            reportSubTitle: " Summary of your pipeline by stage, based on Opportunity close date.",
            totalvalue: "$0 K",
            totalweight:"$0 K",
            totalopp: "3",
            selectedPipline: "1",
            selectedFilter: "1",
            value_type: "1",
            drawerOpen: false,
            dateopen: false,
            dateformat: 'MM/DD/YYYY',
            selectedDate: { from: moment().startOf("year").format('YYYY-MM-DD'), to: moment().endOf("year").format('YYYY-MM-DD') },
            selectedDatelabel: "This Year",
            definedRanges: [
                {
                    label: "Today",
                    startDate: moment().format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD')
                },

                {
                    label: "Yesterday",
                    startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                    endDate: moment().subtract(1, 'days').format('YYYY-MM-DD')
                },

                {
                    label: "This Week",
                    startDate: moment().day(1),
                    endDate: moment().day(7)
                },

                {
                    label: "This Month",
                    startDate: moment().startOf("month"),
                    endDate: moment().endOf("month")
                },

                {
                    label: "This Quarter",
                    startDate: moment().startOf('quarter'),
                    endDate: moment().endOf('quarter')
                },

                {
                    label: "This Year",
                    startDate: moment().startOf('year'),
                    endDate: moment().endOf('year')
                },

                {
                    label: "Last 7 days",
                    startDate: moment().subtract(6, 'days').format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD')
                },

                {
                    label: "Last 30 days",
                    startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD')
                },
            ],
            fromdate: moment().startOf("year").format('YYYY-MM-DD'),
            todate: moment().endOf('year').format('YYYY-MM-DD'),
            pipelineList: [],
            pipelinesummarydata: [],
            fieldDisplay: [],
            dropdownhide: [],
            systemTagsList: [],
            masterActivityTypesList: [],
            usersListArray: [],

            filterSaveDialoge: false,
            fitertype: "1",
            filterRangeArray: [],
            filterObject: {},
            filternameError: false,
            filtername: "",
            displaySelectedFilterName: "Open Opportunity",
            filterdata: [],
            recordtypeid: 4,
            reportno: 1,
            filterlist: [],
            AllOpportunityFilterKey: [],
            allowStateUpdateFilter: true,
            anchorEl: null,
            isOpenedFiltersDefault: false,
            activityquery: 'any',
            allDefaultKeyFilter: { id: 0, filteroption: [], filtername: "All Opportunity" },
        }
    }


    componentWillMount() {

        localStorage.removeItem('selected_report_pipeline_summary_filter_id');

        this.props.getPipelines(this.props.apolloClient.client);
        let piplineparams = {
            filterdata: [],
            piplineid: parseFloat(this.state.selectedPipline),
            type: parseFloat(this.state.value_type),
            fromdate: moment().startOf("year").format('YYYY-MM-DD'),
            todate: moment().endOf('year').format('YYYY-MM-DD'),
            memberid: 0
        };

        this.getpiplineSummary(piplineparams);

        // setTimeout(() => {
        //     this.getpiplineSummary(piplineparams);
        // }, 2000);

        let val = { typeId: 4 }
        this.props.customFieldList(this.props.apolloClient.client, val);
        this.props.getTagsList(this.props.apolloClient.client);
        this.props.getUserList(this.props.apolloClient.client);
        this.props.getActivityTypes(this.props.apolloClient.client);

        this.setState({ selectedDate: { from: moment().startOf("year").format('YYYY-MM-DD'), to: moment().endOf("year").format('YYYY-MM-DD') } })
    }

    getpiplineSummary = (piplineparams) => {
        let data = piplineparams;
        data.memberid = 0
        this.props.piplineSummary(this.props.apolloClient.client, data);
        this.props.getReportFilterList(this.props.apolloClient.client, { reportno: 1 });
    }

    componentWillReceiveProps(props) {
        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            let usersetting = props.settingReducer.userSettingArray.data;
            this.setState({ dateformat: usersetting.dateformat, timeZone: usersetting.timezone })
        }

        if (props.settingReducer.masterActivityTypesList && props.settingReducer.masterActivityTypesList.is_loaded == true && props.settingReducer.masterActivityTypesList.data) {
            props.settingReducer.masterActivityTypesList.is_loaded = false
            var dataList = props.settingReducer.masterActivityTypesList.data;
            var count = dataList.filter(function (element) {
                if (element.is_disable == true) {
                    return element
                }
            });
            this.setState({
                masterActivityTypesList: dataList,
            });
        }

        if (props.settingReducer.pipelineListArray && props.settingReducer.pipelineListArray.data) {
            this.setState({ pipelineList: props.settingReducer.pipelineListArray.data })
        }

        if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
            const usersListArray = props.settingReducer.usersListArray.data;
            this.setState({ usersListArray: usersListArray })
        }

        if (props.reportReducer.pipelinereportfilterlist.data && this.state.allowStateUpdateFilter) {
            // props.reportReducer.pipelinereportfilterlist.is_loaded = false;
            let displaySelectedFilterName = this.state.displaySelectedFilterName;
            const filterlist = props.reportReducer.pipelinereportfilterlist.data;


            var AllfilterIdKey = this.findWithAttr(filterlist, 'id', "1");
            if (AllfilterIdKey != -1) {
                this.setState({ AllOpportunityFilterKey: AllfilterIdKey })
            }
            //Aplying default filter selected  STRAT

            let defaultFilter;
            //checkdefault filter  in localStorage
            var localStorageFilterId = localStorage.getItem('selected_report_pipeline_summary_filter_id');
            if (localStorageFilterId) {
                var localId = this.findWithAttr(filterlist, 'id', localStorageFilterId);
                defaultFilter = filterlist[localId];
                this.setState({ displaySelectedFilterName: (defaultFilter && defaultFilter.length > 0) ? defaultFilter.filtername : displaySelectedFilterName })

            } else {
                var defaultKeyStatus = this.findWithAttr(filterlist, 'id', "1");
                if (defaultKeyStatus != -1) {
                    defaultFilter = filterlist[defaultKeyStatus];
                    localStorage.setItem('selected_report_pipeline_summary_filter_id', defaultFilter.id);

                    this.setState({ displaySelectedFilterName: defaultFilter.filtername })
                }
            }

            if (this.state.newSavedFilterName) {
                this.setState({ displaySelectedFilterName: this.state.newSavedFilterName })
            }

            //Open opportunity
            if (defaultFilter && defaultFilter.id == "1") {
                let udata = JSON.parse(localStorage.getItem('user_data_session'))
                defaultFilter.filteroption = [
                    {
                        fieldid: "45", name: "status", type: "value", value: ["Open"]
                    }
                ]
            }

            //my open oppoertunity
            if (defaultFilter && defaultFilter.id == "2") {
                let udata = JSON.parse(localStorage.getItem('user_data_session'))
                defaultFilter.filteroption = [
                    {
                        fieldid: "46", name: "owner", type: "value", value: [udata.email]
                    },
                    {
                        fieldid: "45", name: "status", type: "value", value: ["Open"]
                    }
                ]
            }

            this.setState({ filterlist: filterlist, allowStateUpdateFilter: false })

            //updateFilter name to display and menudisplay array set
            this.setState({
                fieldDisplay: [],
                dropdownhide: [],
            })

            if (defaultFilter && defaultFilter.filteroption.length > 0) {
                let filterObject = this.state.filterObject;
                let filterRangeArray = this.state.filterRangeArray;
                let displayField = []
                let dropdownhideShow = []
                var promise = new Promise((resolve, reject) => {
                    defaultFilter.filteroption.map((val, k) => {
                        if (val.name == "activitytype") {
                            this.setState({ activityquery: val.query })
                            if (val.value.length > 0) {
                                var arr = []
                                val.value.map(v => {
                                    var k = this.findWithAttr(this.state.masterActivityTypesList, 'id', v)
                                    if (k != -1) {
                                        arr.push(this.state.masterActivityTypesList[k])
                                    }
                                })
                                val.value = arr;

                            }
                        }

                        filterObject = {
                            ...filterObject,
                            [val.name]: val
                        }

                        if (val.name == "tags") {
                            this.setState({ tagsquery: val.query })
                        }

                        if (val.type === "range") {
                            filterRangeArray[val.name] = ["", ""];
                            filterRangeArray[val.name][0] = val.value[0];
                            filterRangeArray[val.name][1] = val.value[1];
                            this.setState({ filterRangeArray })
                            displayField.push(val.name)
                        }
                        if (val.type === "date") {
                            filterRangeArray[val.name] = ["", ""];
                            filterRangeArray[val.name][0] = val.value[0] == "" ? null : val.value[0];
                            filterRangeArray[val.name][1] = val.value[1] == "" ? null : val.value[1];
                            this.setState({ filterRangeArray })
                            if (val.value[0] != "" && val.value[1] != "") {
                                displayField.push(val.name)
                            } else if (val.value[0] != "" || val.value[1] != "") {
                                displayField.push(val.name)
                            }
                        }
                        if (val.name == "zipcode" || val.name == "city" || val.name == "state" || val.name == "country") {//hideshow ENABLE state/city/country/state
                            dropdownhideShow.push(val.name)
                        } else {
                            dropdownhideShow.push(val.name)
                        }
                    })
                    this.setState({
                        filterObject,
                        fieldDisplay: displayField,
                        dropdownhide: dropdownhideShow,
                        newSavedFilterName: "",
                    }, () => this.forceUpdate())
                    resolve(true)
                });

                promise.then(result => {
                    // if (localStorage.getItem('selected_pipeline_id')) {
                    //     this.setState({
                    //         selectedPipelineId: localStorage.getItem('selected_pipeline_id'),
                    //     }, () => this.forceUpdate())
                    // }
                    this.handleFilterSubmit();
                });
            }
            //Aplying default filter selected  END
        }

        if (props.reportReducer.reportPipelineSummary && props.reportReducer.reportPipelineSummary.data) {
            let pipelinesummarydata = props.reportReducer.reportPipelineSummary.data.data
            let totalvalue = (pipelinesummarydata.graphval.length > 0) ? pipelinesummarydata.graphval.reduce(this.add) : 0;
            this.setState({ pipelinesummarydata, totalweight: (pipelinesummarydata.totalOppWeight && (pipelinesummarydata.totalOppWeight > 0)) ? "$" + (pipelinesummarydata.totalOppWeight) : "$" + 0, totalvalue: (pipelinesummarydata.totaloppamount && (pipelinesummarydata.totaloppamount > 0)) ? "$" + (pipelinesummarydata.totaloppamount) : "$" + 0, totalopp: pipelinesummarydata.totalopportunities ? pipelinesummarydata.totalopportunities : 0 })
        }

        if (props.settingReducer.systemTagsList.data && this.state.systemTagsList.length == 0) {
            const tagsData = props.settingReducer.systemTagsList.data;
            this.setState({ systemTagsList: tagsData })
        }

        // filter
        if (props.settingReducer.opportunitiesCustomFieldList.data) {
            const customFields = props.settingReducer.opportunitiesCustomFieldList.data;
            this.setState({ fieldsList: customFields })
        }
    }

    // filter functions ----------------------------
    handleClick = (event) => {
        this.setState({ anchorEl: event.target, isOpenedFiltersDefault: true });
    };

    handleClose = () => {
        this.setState({ anchorEl: null, isOpenedFiltersDefault: false });
    };

    handleFollowedCheckbox = (val, name) => {


        this.setState({
            filterObject: {
                ...this.state.filterObject,
                [name]: val
            }
        }, () => {
            setTimeout(() => {
                if (this.state.filterObject[name] == val) {
                    this.handleFilterSubmit()
                }
            }, 1500);
        })
    }

    handleChangeFilter = (val, name) => {


        this.setState({
            filterObject: {
                ...this.state.filterObject,
                [name]: val
            }
        }, () => {
            setTimeout(() => {
                if (this.state.filterObject[name] == val) {
                    this.handleFilterSubmit()
                }
            }, 3000);
        })
    };

    rangeClick = (key, id) => {
        var rangeData = this.state.filterRangeArray[key];
        if (this.state.filterRangeArray[key][0] == "" && this.state.filterRangeArray[key][1] == "") {
            rangeData = []
        }

        var value = { fieldid: id, name: key, type: "range", value: rangeData };
        this.setState({
            filterObject: {
                ...this.state.filterObject,
                [key]: value
            }
        }, () => {
            this.forceUpdate()
            setTimeout(() => {
                if (this.state.filterObject[key] == value) {
                    this.handleFilterSubmit()
                }
            }, 1500);
        })
    }

    rangeFilter = (rval, rname) => {
        let filterRangeArray = this.state.filterRangeArray;
        filterRangeArray[rname][rval.case] = rval.value;
        this.setState({ filterRangeArray })
    }

    rangeClickDate = (key, id) => {
        var rangeData = this.state.filterRangeArray[key];
        if (this.state.filterRangeArray[key][0] == "" && this.state.filterRangeArray[key][1] == "") {
            rangeData = []
        }

        var value = { fieldid: id, name: key, type: "date", value: rangeData };
        this.setState({
            filterObject: {
                ...this.state.filterObject,
                [key]: value
            }
        }, () => {
            this.forceUpdate()
            setTimeout(() => {
                if (this.state.filterObject[key] == value) {
                    this.handleFilterSubmit()
                }
            }, 1500);
        })
    }

    rangeFilterDate = (rval, rname) => {
        let filterRangeArray = this.state.filterRangeArray;
        filterRangeArray[rname][rval.case] = rval.value;
        this.setState({
            filterRangeArray
        }, () => {
            this.forceUpdate()
            setTimeout(() => {
                this.rangeClickDate(rname, rval.fieldid)
            }, 100);
        })
    }

    handleToggleFieldDisplay = (name) => {
        var exists = this.state.fieldDisplay.includes(name);
        var joined = [];
        let filterRangeArray = this.state.filterRangeArray;

        if (exists) {
            joined = this.state.fieldDisplay.filter((item) => item != name)
            if (filterRangeArray[name]) {
                //delete filterRangeArray[name];
            }
        } else {
            joined = this.state.fieldDisplay.concat(name);
            if (!filterRangeArray[name]) {
                filterRangeArray[name] = ["", ""];
            }
        }

        this.setState({ fieldDisplay: joined, filterRangeArray })
    }

    dropdownMenu = (fkey) => {
        var exists = this.state.dropdownhide.includes(fkey);
        var joined = [];
        if (exists) {
            joined = this.state.dropdownhide.filter((item) => item != fkey)
        } else {
            joined = this.state.dropdownhide.concat(fkey);
        }
        this.setState({ dropdownhide: joined })
    }

    handleFilterSubmit = () => {
        var allowToFilter = true
        let copyObj = JSON.parse(JSON.stringify(this.state.filterObject));
        var filterValuesArray = Object.keys(copyObj);
        var finalFilterData = filterValuesArray.map((k, i) => {
            let v = copyObj[k];

            if (v.value.length > 0) {
                var valuesArray = [];
                v.value.map((val, k) => {
                    if (v.type === "date") {
                        if (val == "Invalid Date") {
                            allowToFilter = false
                        } else if (val == "") {
                            valuesArray.push(val)
                        } else if (val == null) {
                            valuesArray.push("")
                        } else if (val != null) {
                            if (v.fieldid != "0") {
                                val = moment(val).format('YYYY-MM-DD');
                            } else {
                                //val = moment(val).format("X");
                                val = moment(val).format('MM/DD/YYYY');
                            }
                            valuesArray.push(val)
                        }
                    } else if (v.type == "log") {
                        valuesArray.push(val.id);
                    } else if (v.fieldid == '46' && val.email) {
                        valuesArray.push(val.email)
                    } else if (v.fieldid != '46' && val.name) {
                        valuesArray.push(val.name)
                    } else if (val.optionvalue) {
                        valuesArray.push(val.optionvalue)
                    } else if (v.fieldid === "56" || v.type === "range") {//tag array and value
                        valuesArray.push(val);
                    } else {
                        valuesArray.push(val);
                    }
                })


                let checkBoxKey = -1
                if (this.state.fieldsList != undefined) {
                    checkBoxKey = this.findWithAttr(this.state.fieldsList, 'id', v.fieldid);
                }



                v.value = valuesArray;
                if (v.type == "followed") {//follow/not follow filter only value is true
                    if (v.value[0] === true) {
                        return v
                    }
                } else if (checkBoxKey != -1 && this.state.fieldsList[checkBoxKey].fieldtype.id == "3" && v.value[0] == "1") {
                    return v
                } else if (checkBoxKey != -1 && this.state.fieldsList[checkBoxKey].fieldtype.id != "3") {
                    return v
                } else if (checkBoxKey != -1 && this.state.fieldsList[checkBoxKey].fieldtype.id == "3") {
                } else {
                    return v
                }
            }
        })

        //filter null and undefined object 
        finalFilterData = finalFilterData.filter(function (element) {
            return element !== undefined || (element !== undefined && element.value.length != 0);
        });


        if (allowToFilter) {
            //saveFilter Data for save in future use.
            this.setState({
                filterdata: finalFilterData,
                isDataLoading: true,
            })

            // let val = {
            //     filterdata: finalFilterData,
            //     list: {
            //         limit: this.state.pageSize,
            //         pageno: 1,
            //         searchtext: this.state.searchText,
            //         sorttype: this.state.sorttype,
            //         type: "list",
            //         fieldId: this.state.fieldId,
            //         columnname: this.state.column_name,
            //         islimited:true
            //     },
            //     piplineid: parseFloat(this.state.selectedPipline),
            //     filterid: 1
            // }
            // this.getListing(val)

            let piplineparams = {
                filterdata: finalFilterData,
                piplineid: parseFloat(this.state.selectedPipline),
                type: parseFloat(this.state.value_type),
                fromdate: this.state.fromdate,
                todate: this.state.todate,
                memberid: 0
            };
            this.getpiplineSummary(piplineparams);
        }
    }

    handleSavefilter = () => {
        if (this.state.filtername == "") {
            this.setState({ filternameError: true })
            return false
        }
        if (this.state.filterdata && this.state.filterdata.length > 0) {
            let req = [];
            req['savefilter'] = {
                filterdata: this.state.filterdata,
                fitertype: this.state.fitertype,
                filtername: this.state.filtername,
                recordtypeid: this.state.recordtypeid,
                reportno: this.state.reportno,
            };
            req['getfilter'] = { reportno: this.state.reportno };

            this.props.saveReportFilterDetail(this.props.apolloClient.client, req);
            this.handleSavefilterDialogeClose()
            this.setState({
                displaySelectedFilterName: this.state.filtername,
                newSavedFilterName: this.state.filtername,
            })
        } else {
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
        }
        setTimeout(() => {
            this.setState({
                allowStateUpdateFilter: true,
            })
        }, 2000);
    }

    updateOpportunityFilter = (data) => {

        this.setState({
            filterSaveDialoge: true,
            filterUpdateId: data.id,
            filtername: data.filtername,
            fitertype: data.fitertype.toString(),
            oldFilterOption: data.filteroption,
        })
    }

    handleUpdatefilter = () => {
        if (this.state.filtername == "") {
            this.setState({ filternameError: true })
            return false
        }
        let req = {
            filterdata: this.state.oldFilterOption,
            fitertype: this.state.fitertype,
            filtername: this.state.filtername,
            filterid: parseInt(this.state.filterUpdateId),
            reportno: this.state.reportno,
        }
        //return
        this.props.updateReportFilterDetail(this.props.apolloClient.client, req);
        let updateDataInState = this.findWithAttr(this.state.filterlist, 'id', this.state.filterUpdateId);
        if (updateDataInState !== -1) {
            this.state.filterlist[updateDataInState].filtername = this.state.filtername;
            this.state.filterlist[updateDataInState].fitertype = this.state.fitertype;
        }
        this.handleSavefilterDialogeClose()
    }

    updateFilterOptions = () => {
        let key = this.findWithAttr(this.props.reportReducer.pipelinereportfilterlist.data, 'id', localStorage.getItem('selected_report_pipeline_summary_filter_id'));
        var data = ""
        if (key !== -1) {
            data = this.props.reportReducer.pipelinereportfilterlist.data[key];
            if (this.state.filterdata && this.state.filterdata.length > 0) {
                let req = {
                    filterdata: this.state.filterdata,
                    fitertype: data.fitertype.toString(),
                    filtername: data.filtername,
                    filterid: parseInt(data.id),
                    reportno: this.state.reportno,
                }
                this.props.updateReportFilterDetail(this.props.apolloClient.client, req);
                this.handleSavefilterDialogeClose()
            } else {
                //dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
            }
            setTimeout(() => {
                this.setState({
                    allowStateUpdateFilter: true,
                })
            }, 1500);
        }
    }

    confirmDeleteFilter = () => {
        this.props.deleteReportFilter(this.props.apolloClient.client, { filterid: parseInt(this.state.filterDeleteId), reportno: this.state.reportno });
        let removeFilterObjKey = this.findWithAttr(this.props.reportReducer.pipelinereportfilterlist.data, 'id', this.state.filterDeleteId);
        if (removeFilterObjKey !== -1) {
            if (!this.props.reportReducer.pipelinereportfilterlist.data[removeFilterObjKey].isdefault == true) {
                this.props.reportReducer.pipelinereportfilterlist.data.splice(removeFilterObjKey, 1);
            }
        }
        this.filterRemoveDialoge()
    }

    filterRemoveDialoge = () => {
        this.setState({ isDeleteFilter: false, filterDeleteId: "", allowStateUpdateFilter: true })
    }

    handleSavefilterDialoge = () => {
        this.setState({ filterSaveDialoge: true })
    }

    handleSavefilterDialogeClose = () => {
        this.setState({
            filterSaveDialoge: false,
            filternameError: false,
            fitertype: "1",
            filterUpdateId: "",
            oldFilterOption: "",
            filtername: "",
        })
    }

    handleFilterChange = (filterdata) => {
        // let filterkey = this.findWithAttr(this.state.filterlist, 'id', event.target.value);

        // let filterdata = this.state.filterlist[filterkey];

        //my open oppoertunity
        if (filterdata.id == "2") {
            let udata = JSON.parse(localStorage.getItem('user_data_session'))
            filterdata.filteroption = [
                {
                    fieldid: "46", name: "owner", type: "value", value: [udata.email]
                },
                {
                    fieldid: "45", name: "status", type: "value", value: ["Open"]
                }
            ]
        }

        //Opportunities Im Following
        // if (filterdata.id == "14") {
        //     filterdata.filteroption = [
        //         {
        //             fieldid: "0",
        //             name: "followed",
        //             type: "followed",
        //             value: [true],
        //             chktype: "follow",
        //         }
        //     ]
        // }

        //won opportunity
        // if (filterdata.id == "15") {
        //     let udata = JSON.parse(localStorage.getItem('user_data_session'))
        //     filterdata.filteroption = [
        //         {
        //             fieldid: "45", name: "status", type: "value", value: ["Won"]
        //         }
        //     ]
        // }

        //Open opportunity
        if (filterdata.id == "1") {
            let udata = JSON.parse(localStorage.getItem('user_data_session'))
            filterdata.filteroption = [
                {
                    fieldid: "45", name: "status", type: "value", value: ["Open"]
                }
            ]
        }

        this.setState({
            filterObject: {},
            filterRangeArray: [],
            fieldDisplay: [],
            dropdownhide: [],
        }, () => {
            this.forceUpdate()
            this.setState({ displaySelectedFilterName: filterdata.filtername, currentPage: 0 })
            if (filterdata.filteroption.length > 0) {

                let filterObject = this.state.filterObject;


                let filterRangeArray = this.state.filterRangeArray;
                let displayField = []
                let dropdownhideShow = []
                var promise = new Promise((resolve, reject) => {
                    filterdata.filteroption.map((val, k) => {
                        if (val.name == "activitytype") {
                            this.setState({ activityquery: val.query })
                            if (val.value.length > 0) {
                                var arr = []
                                val.value.map(v => {
                                    var k = this.findWithAttr(this.state.masterActivityTypesList, 'id', v)
                                    if (k != -1) {
                                        arr.push(this.state.masterActivityTypesList[k])
                                    }
                                })
                                val.value = arr;

                            }
                        }

                        filterObject = {
                            ...filterObject,
                            [val.name]: val
                        }
                        if (val.name == "tags") {
                            this.setState({ tagsquery: val.query })
                        }

                        if (val.type === "range") {
                            filterRangeArray[val.name] = ["", ""];
                            filterRangeArray[val.name][0] = val.value[0];
                            filterRangeArray[val.name][1] = val.value[1];
                            this.setState({ filterRangeArray })
                            displayField.push(val.name)
                        }

                        if (val.type == "date") {
                            filterRangeArray[val.name] = ["", ""];
                            filterRangeArray[val.name][0] = val.value[0] == "" ? null : val.value[0];
                            filterRangeArray[val.name][1] = val.value[1] == "" ? null : val.value[1];
                            this.setState({ filterRangeArray })
                            if (val.value[0] != "" && val.value[1] != "") {
                                displayField.push(val.name)
                            } else if (val.value[0] != "" || val.value[1] != "") {
                                displayField.push(val.name)
                            }
                        }

                    })
                    this.setState({
                        filterObject,
                        fieldDisplay: displayField,
                        dropdownhide: dropdownhideShow,
                    }, () => this.forceUpdate())
                    resolve(true)
                });

                promise.then(result => {
                    this.handleFilterSubmit();
                    localStorage.setItem('selected_report_pipeline_summary_filter_id', filterdata.id)
                });
            } else {

                // let val = {
                //     filterdata: [],
                //     list: {
                //         limit: this.state.pageSize,
                //         pageno: 1,
                //         searchtext: this.state.searchText,
                //         sorttype: this.state.sorttype,
                //         type: "list",
                //         fieldId: this.state.fieldId,
                //         columnname: this.state.column_name,
                //         islimited:true
                //     },
                //     piplineid: parseFloat(this.state.selectedPipelineId),
                //     filterid: 1
                // }
                localStorage.setItem('selected_report_pipeline_summary_filter_id', filterdata.id)
                this.handleFilterSubmit();
            }
        })

    }

    updateFilterDefaultReport = (data) => {
        this.props.updateReportFilterDefault(this.props.apolloClient.client, { filterid: parseInt(data.id), reportno: this.state.reportno });
        setTimeout(() => {
            this.setState({
                allowStateUpdateFilter: true,
            })
        }, 2000);
    }

    // -----------------------------------------------------

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    handleTypeChange = (event) => {

        let piplineparams = {
            filterdata: this.state.filterdata,
            piplineid: parseFloat(this.state.selectedPipline),
            type: parseFloat(event.target.value),
            fromdate: this.state.fromdate,
            todate: this.state.todate,
            memberid: 0
        };
        this.getpiplineSummary(piplineparams);

        this.setState({ [event.target.name]: event.target.value })
    }

    handlePiplineChange = (event) => {

        let piplineparams = {
            filterdata: this.state.filterdata,
            piplineid: parseFloat(event.target.value),
            type: parseFloat(this.state.value_type),
            fromdate: this.state.fromdate,
            todate: this.state.todate,
            memberid: 0
        };
        this.getpiplineSummary(piplineparams);


        this.setState({ [event.target.name]: event.target.value })
    }


    handleOpenDrawer = () => {
        this.setState({ drawerOpen: true })
        this.closedatepicker();
    }

    handleCloseDrawer = () => {
        this.setState({ drawerOpen: false, })
    }

    toggle = () => {
        this.setState({ dateopen: !this.state.dateopen });
    }
    closedatepicker = () => {
        this.setState({ dateopen: false });
    }

    setDateRange = (range) => {
        let startDate = moment(range.startDate).format('YYYY-MM-DD');
        let endDate = moment(range.endDate).format('YYYY-MM-DD');

        let piplineparams = {
            filterdata: this.state.filterdata,
            piplineid: parseFloat(this.state.selectedPipline),
            type: parseFloat(this.state.value_type),
            fromdate: startDate,
            todate: endDate,
        };
        this.getpiplineSummary(piplineparams);

        if (range.label) {
            this.setState({ selectedDatelabel: range.label });
        } else {
            this.setState({ selectedDate: { from: startDate, to: endDate }, selectedDatelabel: "" });
        }

        this.setState({ fromdate: startDate, todate: endDate })
    }

    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] == value) {
                return i;
            }
        }
        return -1;
    }

    add = (a, b) => {
        return a + b;
    }

    render() {


        let { isOpenedFiltersDefault, filterlist, pipelinesummarydata, pipelineList, selectedPipline, reportTitile, reportSubTitle, dateopen, selectedFilter, totalvalue, totalweight, drawerOpen, totalopp, value_type } = this.state;
        let stages = [];
        let data = [];

        var localStorageFilter = localStorage.getItem('selected_report_pipeline_summary_filter_id');

        if (pipelinesummarydata && pipelinesummarydata.stage && pipelinesummarydata.graphval) {
            stages = pipelinesummarydata && pipelinesummarydata.stage;
            data = pipelinesummarydata && pipelinesummarydata.graphval;
        } else {
            let selectedStages = [];
            let pipelinekey = this.findWithAttr(pipelineList, 'id', selectedPipline);

            if (pipelinekey != -1) {
                selectedStages = pipelineList[pipelinekey].stage;
                selectedStages.map((stage, key) => {
                    stages.push(stage.name);
                    data.push(0);
                });
            }
        }


        let pointFormat = "";

        if (parseInt(value_type) == 3) {
            pointFormat = '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y}</b></td></tr>';
        } else {
            pointFormat = '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>$ {point.y}</b></td></tr>';
        }


        const options = {
            chart: { type: 'column' },
            title: "",
            subtitle: "",
            xAxis: {
                title: {
                    text: null
                },
                categories: stages,
                crosshair: true
            },
            yAxis: {
                allowDecimals: false,
                title: { text: null },
                min: 0,
                labels: {

                    formatter: function () {
                        if (parseInt(value_type) != 3)
                            return '$' + this.value;
                        else
                            return this.value;
                    }
                }
            },
            tooltip: {
                headerFormat: '<b><span style="font-size:14px">{point.key}</span></b><table>',
                pointFormat: pointFormat,
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                color: "#2196f3",
                name: 'Total Value',
                data: data
            }]
        }
        let updateSaveFilterBtnDisabled = (this.state.filterdata == undefined || !this.state.filterdata.length) ? ((this.state.oldFilterOption == undefined || !this.state.oldFilterOption.length) ? true : false) : false;
        return (
            <React.Fragment>
                <div>
                    {/* <ReportSidebarMenu /> */}
                    <div className="lead_content right-16 settings_view"
                        style={{ overflowY: "auto", overflowX: "hidden" }}>
                        <Card elevation={3}>
                            <div className="report_card">
                                <div className="report_header">
                                    <h3>{reportTitile}</h3>
                                    <Grid container direction="row" justify="space-between" alignItems="center" onClick={this.closedatepicker}>
                                        <Grid><Typography className="Typography" variant="p">{reportSubTitle}</Typography></Grid>
                                        <Grid><div className="mr-36">
                                            <IconButton onClick={() => this.handleOpenDrawer()}>
                                                <Badge badgeContent={this.state.filterdata ? this.state.filterdata.length : 0} color="primary">
                                                    <FilterListIcon />
                                                </Badge>
                                            </IconButton>
                                        </div></Grid>
                                    </Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" onClick={this.closedatepicker}>
                                        <div className="number_label">
                                            <div>{totalvalue}</div>
                                            <div className=" p-4" >Total Value</div>
                                        </div>
                                        <div className="number_label">
                                            <div>{totalweight}</div>
                                            <div className=" p-4" >Total Weighted</div>
                                        </div>
                                        
                                        <div className="number_label">
                                            <div>{totalopp}</div>
                                            <div className=" p-4">Opportunities</div>
                                        </div>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center"
                                        className="crm-all-report-filters"
                                    // onClick={this.closedatepicker}
                                    >
                                        <div className="report_filter" onClick={this.closedatepicker}>
                                            <InputLabel id="Pipeline-select-helper-label">Pipeline</InputLabel>
                                            <Select
                                                className="w-200"
                                                labelId="Pipeline-select-label"
                                                name="selectedPipline"
                                                id="Pipeline"
                                                value={selectedPipline}
                                                onChange={this.handlePiplineChange}
                                                onClick={this.closedatepicker}
                                            >
                                                <MenuItem value="0">All</MenuItem>
                                                {pipelineList && pipelineList.map((pipeline) => (
                                                    <MenuItem value={pipeline.id}>{pipeline.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                        <div className="report_filter" onClick={this.closedatepicker}>
                                            <div>
                                                <Button aria-describedby={'simple-popover'} variant="" color="default" onClick={this.handleClick}>
                                                    <Tooltip title={this.state.displaySelectedFilterName}>
                                                        <div className="text_ellipsis" style={{ maxWidth: '130px', fontSize: '13px', margin: '3px 0px 0px 0px', fontWeight: '400' }}>
                                                            {this.state.displaySelectedFilterName}
                                                        </div>
                                                    </Tooltip>
                                                    <Icon className="vert-middle flex-end">{isOpenedFiltersDefault ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                                                </Button>
                                                <Popover
                                                    id={'simple-popover'}
                                                    open={this.state.isOpenedFiltersDefault}
                                                    anchorEl={this.state.anchorEl}
                                                    onClose={this.handleClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'center',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'center',
                                                    }}
                                                >
                                                    <Card elevation={3} className="report_filter_menu_custom">
                                                        {isOpenedFiltersDefault &&
                                                            <div>
                                                                <h6 className="mt-8 font-size-14 mb-0 pl-16 pr-16">Private Filters </h6>
                                                                <ul className="pl-20">
                                                                    {this.state.filterlist.length > 0 && this.state.filterlist.map((item, key) => {
                                                                        let star_icon = item.isdefault ? "star" : "star_border";
                                                                        if (item.fitertype == 1 && item.id != localStorage.getItem('selected_report_pipeline_summary_filter_id')) {
                                                                            return (<li key={item.key}>
                                                                                <label className={"mr-20"} onClick={() => this.handleFilterChange(item)}>{item.filtername}</label>
                                                                                <>
                                                                                    <IconButton className="text-secondary ml-4" size="small">
                                                                                        <Icon onClick={() => this.updateOpportunityFilter(item)} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{'settings'}</Icon>
                                                                                    </IconButton>
                                                                                    <IconButton className="text-secondary ml-4" size="small">
                                                                                        <Icon onClick={() => this.setState({ isDeleteFilter: true, filterDeleteId: item.id })} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{'delete'}</Icon>
                                                                                    </IconButton>
                                                                                    <IconButton className="text-secondary ml-4" size="small">
                                                                                        <Icon onClick={() => this.updateFilterDefaultReport(item)} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{star_icon}</Icon>
                                                                                    </IconButton>
                                                                                </>
                                                                            </li>)
                                                                        }
                                                                    }
                                                                    )}
                                                                </ul>
                                                                <h6 className="mt-8 font-size-14 mb-0 mt-16 pl-16 pr-16">Public Filters</h6>
                                                                <ul className="pl-20">
                                                                    {this.state.filterlist.length > 0 && this.state.filterlist.map((items, key) => {
                                                                        let star_icon = items.isdefault ? "star" : "star_border";
                                                                        if (items.fitertype == 0 && items.id != localStorage.getItem('selected_report_pipeline_summary_filter_id')) {
                                                                            return <li key={items.key}>
                                                                                <label className={"mr-20"} onClick={() => this.handleFilterChange(items)}>{items.filtername}</label>
                                                                                {!["1", "2"].includes(items.id) &&
                                                                                    <>
                                                                                        <IconButton className="text-secondary ml-4" size="small">
                                                                                            <Icon onClick={() => this.updateOpportunityFilter(items)} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{'settings'}</Icon>
                                                                                        </IconButton>
                                                                                        <IconButton className="text-secondary ml-4" size="small">
                                                                                            <Icon onClick={() => this.setState({ isDeleteFilter: true, filterDeleteId: items.id })} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{'delete'}</Icon>
                                                                                        </IconButton>
                                                                                    </>
                                                                                }
                                                                                <IconButton className="text-secondary ml-4" size="small">
                                                                                    <Icon onClick={() => this.updateFilterDefaultReport(items)} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{star_icon}</Icon>
                                                                                </IconButton>
                                                                            </li>
                                                                        }
                                                                    }
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        }
                                                    </Card>
                                                </Popover>
                                            </div>
                                        </div>
                                        <div className="cursor-pointer report_date_picker w-200 " onClick={this.toggle}>
                                            <InputLabel className="mb-10" onClick={this.toggle} id="">Close Date</InputLabel>
                                            {this.state.selectedDatelabel == "" ? this.state.selectedDate.from + " to " + this.state.selectedDate.to : this.state.selectedDatelabel}
                                        </div>
                                        <div className="right_divider " onClick={this.closedatepicker}> </div>
                                        <RadioGroup className="ml-16 mt-10" row aria-label="gender" name="value_type" value={value_type} onClick={this.closedatepicker} onChange={this.handleTypeChange}>
                                            <FormControlLabel value="1" control={<Radio color="primary" />} label="Value" />
                                            <FormControlLabel value="2" control={<Radio color="primary" />} label="Weighted value" />
                                            <FormControlLabel value="3" control={<Radio color="primary" />} label="Count" />
                                        </RadioGroup>
                                        <div className="crm-all-report-filters-daterange">
                                            <DateRangePicker
                                                open={dateopen}
                                                toggle={this.closedatepicker}
                                                definedRanges={this.state.definedRanges}
                                                onChange={(range) => this.setDateRange(range)}
                                                initialDateRange={{startDate: moment().startOf('year'),endDate: moment().endOf('year')}}
                                            />
                                        </div>
                                    </Grid>
                                </div>
                                <div className="report_content" onClick={this.closedatepicker}>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={options}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
                {/*-------------------- filter start------------------------- */}
                <Drawer
                    width={"100px"}
                    variant="temporary"
                    anchor={"right"}
                    className="drawer_right"
                    open={drawerOpen}
                    onClose={this.handleCloseDrawer}
                    ModalProps={{ keepMounted: true }}
                >
                    <React.Fragment>
                        <div className="frame_body">
                            <div className="filter_Drawer_header">
                                <Grid container justify="space-between" alignItems="center">
                                    <Grid item>
                                        <IconButton size="small" className="ml-10"
                                            onClick={this.handleCloseDrawer}><Icon>arrow_forward</Icon>
                                        </IconButton></Grid>
                                    <Grid item>
                                        <h4 className="m-10">Filter Opportunities </h4>
                                    </Grid>
                                    <Grid item className="m-10">
                                        <Button type="button" onClick={() => this.handleFilterChange(this.state.allDefaultKeyFilter)} className="btn_Primary mb-10 mt-10 px-12" size="small" >Clear Filter</Button>
                                    </Grid>
                                    <Grid item md={12} className={"report_opportunity_filter"}>
                                        <div className="v_scroll position-relative ml-16 mt-16 pr-16">
                                            {/* <Link className="text_link" onClick={() => this.handleFilterChange(this.state.filterlist[this.state.AllOpportunityFilterKey])}>Clear All</Link> */}
                                            {opportunityFilterComponent(this)}
                                        </div>
                                        <div className="fixed-bottom" style={{ position: 'initial' }}>
                                            <Button type="button" className="btn_Primary  mb-16 px-12" size="small" onClick={() => this.handleSavefilterDialoge()}>Save Filter</Button>
                                            {localStorageFilter && !["1", "2"].includes(localStorageFilter) &&
                                                <Button type="button" className="btn_Primary  mb-16 px-12 ml-16" size="small" onClick={() => this.updateFilterOptions()}>Update Filter</Button>
                                            }
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </React.Fragment>
                </Drawer>

                <Dialog
                    fullWidth={true}
                    maxWidth={'xs'}
                    open={this.state.filterSaveDialoge}
                    onClose={() => this.handleSavefilterDialogeClose()}
                    disableBackdropClick
                    disableEscapeKeyDown
                    aria-labelledby="delele-dialog-title"
                >
                    <DialogTitle id="">
                        {!this.state.filterUpdateId ? "Add a New Filter" : "Edit Filter"}
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <ValidatorForm instantValidate={false} ref="form">
                            <FormControl error={this.state.filternameError} className="w-100 input_bx">
                                <InputLabel htmlFor="component-error">Name</InputLabel>
                                <Input
                                    type="text"
                                    id={'filtername'}
                                    label={'Filter Name'}
                                    className="w-100 input_bx"
                                    onChange={(event) => this.handleChange(event)}
                                    name={'filtername'}
                                    value={this.state.filtername}
                                />
                                {this.state.filternameError && <FormHelperText id="component-error-text">This field is required</FormHelperText>}
                            </FormControl>
                            <br /><br />
                            <div>
                                <span>visibility</span>
                                <br />
                                <RadioGroup name="fitertype" value={this.state.fitertype} onChange={(event) => this.handleChange(event)} row>
                                    <FormControlLabel
                                        value="1"
                                        control={<Radio color="primary" />}
                                        label="Private"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="0"
                                        control={<Radio color="primary" />}
                                        label="Public"
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </div>

                        </ValidatorForm>
                    </DialogContent>
                    <DialogActions>
                        {updateSaveFilterBtnDisabled && <p className="lead-filter-error">Please select filter options to save filter</p>}
                        <Button onClick={() => this.handleSavefilterDialogeClose()} color="primary">
                            Cancel
                        </Button>
                        {!this.state.filterUpdateId && <Button variant="contained" disabled={updateSaveFilterBtnDisabled} type="button" color="primary" onClick={() => this.handleSavefilter()}>Save</Button>}
                        {this.state.filterUpdateId && <Button variant="contained" disabled={updateSaveFilterBtnDisabled} type="button" color="primary" onClick={() => this.handleUpdatefilter()}>Update</Button>}
                    </DialogActions>
                </Dialog>
                <Dialog
                    fullWidth={true}
                    maxWidth={'xs'}
                    open={this.state.isDeleteFilter}
                    onClose={this.filterRemoveDialoge}
                    disableBackdropClick
                    disableEscapeKeyDown
                    aria-labelledby="ar-remove-dialog-title"
                >
                    <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to delete filter ?"}</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.filterRemoveDialoge} color="primary">Cancel</Button>
                        <Button type="Button" onClick={() => this.confirmDeleteFilter()} className="btn_Primary">
                            Ok
                    </Button>
                    </DialogActions>
                </Dialog>
                {/*-------------------- filter done------------------------- */}

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    reportReducer: state.reportReducer,
    opportunityReducer: state.opportunityReducer,
    apolloClient: state.apolloClient,
    notification: state.notification,
});
const mapDispatchToProps = dispatch => {
    return {
        getPipelines: (client) => dispatch(actions.getPipelines(client)),
        getTagsList: (client) => dispatch(actions.getTagsList(client)),
        getUserList: (client) => dispatch(actions.getUserList(client)),
        getActivityTypes: (client) => dispatch(actions.getActivityTypes(client)),
        customFieldList: (client, value) => dispatch(actions.customFieldList(client, value)),

        piplineSummary: (client, value) => dispatch(actions.piplineSummary(client, value)),
        getReportFilterList: (client, value) => dispatch(actions.getReportFilterList(client, value)),
        saveReportFilterDetail: (client, value) => dispatch(actions.saveReportFilterDetail(client, value)),
        deleteReportFilter: (client, value) => dispatch(actions.deleteReportFilter(client, value)),
        updateReportFilterDefault: (client, value) => dispatch(actions.updateReportFilterDefault(client, value)),
        updateReportFilterDetail: (client, value) => dispatch(actions.updateReportFilterDetail(client, value)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PipelineSummary);