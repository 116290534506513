import React, { Component } from "react";
import { Dialog, IconButton, Button, Icon, Tooltip, Grid, FormControl, Select, InputLabel, Menu, MenuItem, List, ListItem, ListItemText, DialogActions, Chip, TextField } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { CreateFieldsDynamicTaskEdit } from './CreateFieldsDynamicTaskEdit'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';
class TaskProfileDetails extends Component {
  state = {
    task_id: "",
    city: "",
    addressState: "",
    zipcode: "",
    country: "",

    type: "never",
    repeatdays: 1,
    repeattype: "days",
    days: 0,

    selectedRepeatValue: "never",
    selectedRepeatName: "Set to repeat",

    selectedReminderValue: "0",
    selectedReminderName: "Add reminder",
    reminderMinitue: 0,

    countryListArray: [],
    companyCurrencyData: [],
    focusValue: false,
    fieldsList: [],
    fieldsObj: {
      duedate: {
        fieldid: "60",
        values: new Date()
      },
      reminderdate: {
        fieldid: "62",
        values: new Date()
      },
    },
    systemTagsList: [],
    isUpdate: true,
    istaskdetailUpdate: true,
    taskFieldData: [],
    usersListArray: [],
    fnameError: false,
    userSettings: "",
    comp_options: [],
    comp_value: '',
    isAnyValueChange: false,
    relatedListArray: [],
    updatedfieldid: "",
    hasEdit: false,
    teamList: [],
    selectedTeamList: [],
    selectedUserList: [{}],
    oldSlectedUserList: [],
    oldSelctedTeamList: [],
    userListForVisibility: [],
    hasActiveTeamPermission: false,
    crudPermission: {
      isedit: true,
      isdeleted: true
    },
    isEditVisibility: false,
  }

  // componentWillMount() {
  componentDidMount() {
    ValidatorForm.addValidationRule('IsURLValid', (value) => {
      if (value && value.length && value.length > 0) {
        var specialChar = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
        if (!specialChar.test(value)) {
          return false;
        }
      }
      return true;
    });
    this.props.getCompanyRecList(this.props.apolloClient.client);

    let val = { typeId: 6 }
    let userData = JSON.parse(localStorage.getItem('user_data_session'))
    let userid = { userid: userData.id }
    this.props.getUserSetting(this.props.apolloClient.client, userid);
    this.props.customFieldList(this.props.apolloClient.client, val);
    this.props.getUserList(this.props.apolloClient.client);
    this.props.getCountryList(this.props.apolloClient.client);
    this.props.getCompanyCurrency(this.props.apolloClient.client);
    this.props.getTagsList(this.props.apolloClient.client);
    this.props.getRelatedList(this.props.apolloClient.client);
    this.props.getTeam(this.props.apolloClient.client);

    if (this.props.task_id && (this.state.task_id != this.props.task_id)) {


      this.props.getTaskField(this.props.apolloClient.client, parseInt(this.props.task_id));
      this.setState({
        task_id: parseInt(this.props.task_id),
        isUpdate: true,
      }, () => this.forceUpdate())
    }

  }

  componentWillReceiveProps(props) {
    if (props.settingReducer.teamList.data && this.state.teamList !== props.settingReducer.teamList.data) {
      const teamList = props.settingReducer.teamList.data;
      let data = [];
      teamList.map((v, i) => {
        data.push({ id: v.id, teamname: v.teamname })
      });
      this.setState({ teamList: data })
    }
    if (props.settingReducer.relatedListArray.data) {
      props.settingReducer.relatedListArray.is_loaded = false
      const relatedData = props.settingReducer.relatedListArray.data;
      var NewArray = []
      NewArray = NewArray.concat(relatedData.lead);
      NewArray = NewArray.concat(relatedData.contact);
      NewArray = NewArray.concat(relatedData.company);
      NewArray = NewArray.concat(relatedData.opportunities);
      NewArray = NewArray.concat(relatedData.project);
      this.setState({ relatedListArray: NewArray })
    }
    if (props.settingReducer.companyRecList && props.settingReducer.companyRecList.data && props.settingReducer.companyRecList.data.data) {
      let companyOptions = [];
      props.settingReducer.companyRecList.data.data.map((compOpt) => {
        companyOptions.push({ label: compOpt.name, value: compOpt.company_id });
      })
      this.setState({ comp_options: companyOptions });
    }

    //storing user setting data in state
    if (props.settingReducer.userSettingArray.data && this.state.userSettings == "") {
      const settingData = props.settingReducer.userSettingArray.data;
      this.setState({
        userSettings: settingData,
      })
    }

    if (props.task_id && (this.state.task_id != props.task_id) || this.state.isUpdate && props.task_id != undefined) {
      props.getTaskField(props.apolloClient.client, parseInt(props.task_id));

      let mailid = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
      let logParams = { taskid: parseFloat(props.task_id), logtypeid: [] }
      this.props.getTaskActivityLogList(this.props.apolloClient.client, logParams);

      // if(props.taskReducer.taskFieldData.data )
      // {
      //   var emailkey1 = this.findWithAttr(props.taskReducer.taskFieldData.data, 'fieldkey', "email");
      //   if (emailkey1 != -1)
      //   {
      //     let emailData = props.taskReducer.taskFieldData.data[emailkey1];
      //     if (emailData.taskdetail[0] && emailData.taskdetail[0].values && emailData.taskdetail[0].values.length > 0)
      //     {
      //       localStorage.setItem('emailLogEmail', emailData.taskdetail[0].values[0].value);
      //       let logParams = { taskid: parseFloat(props.task_id), emailid: emailData.taskdetail[0].values[0].value }
      //       this.props.getTaskActivityLogList(this.props.apolloClient.client, logParams);
      //     }
      //     else
      //     {
      //       localStorage.removeItem('emailLogEmail');
      //     }
      //   }
      // }
      this.setState({
        task_id: parseInt(props.task_id),
      }, () => this.forceUpdate())
    }
    if (props.settingReducer.taskCustomFieldList.data && this.state.fieldsList.length == 0) {
      const customFields = props.settingReducer.taskCustomFieldList.data;

      var vkey = this.findWithAttr(customFields, "id", "65");
      var visibilitydefault = this.findWithAttr(customFields[vkey].options, "is_default", true);

      this.setState({
        fieldsList: customFields,
        fieldsObj: {
          ...this.state.fieldsObj,
          ["visibility"]: { fieldid: "65", values: customFields[vkey].options[visibilitydefault], },
        }
      })
    }

    if (props.settingReducer.countryListGql.data && this.state.countryListArray.length == 0) {
      const countryies = props.settingReducer.countryListGql.data;
      this.setState({ countryListArray: countryies })
    }
    if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
      const usersListArray = props.settingReducer.usersListArray.data;
      let userListForVisibility = [];
      usersListArray.map((data, i) => {
        userListForVisibility.push({ id: data.id, name: data.name })
      })
      this.setState({ usersListArray: usersListArray, userListForVisibility: userListForVisibility })

      var companyOwnerKey = this.findWithAttr(usersListArray, 'companyowner', "true");
      // this.setState({
      //   fieldsObj: {
      //     ...this.state.fieldsObj,
      //     ["owner"]: { fieldid: "4", values:usersListArray[companyOwnerKey]}
      //   }
      // })
    }


    if (props.settingReducer.systemTagsList.data && this.state.systemTagsList.length == 0) {
      const tagsData = props.settingReducer.systemTagsList.data;
      this.setState({ systemTagsList: tagsData })
    }

    if (props.settingReducer.companyCurrencyData.data != null && this.state.companyCurrencyData.length == 0) {
      var cData = props.settingReducer.companyCurrencyData.data;
      this.setState({ companyCurrencyData: cData[0] });
    }

    if (props.taskReducer.taskFieldData.data && props.taskReducer.taskFieldData.is_loaded) {
      this.setState({
        fieldsObj: {
          duedate: {
            fieldid: "60",
            values: new Date()
          },
          reminderdate: {
            fieldid: "62",
            values: new Date()
          },
        },
        comp_value: '',
        city: '',
        addressState: '',
        zipcode: '',
      }, () => {
        let fieldsObj = this.state.fieldsObj;
        this.setState({ taskFieldData: props.taskReducer.taskFieldData.data })
        let country = "";
        let selectedTeamList = [];
        let selectedUserList = [];
        const taskDataForEdit = props.taskReducer.taskFieldData.data;

        var promise = new Promise((resolve, reject) => {
          taskDataForEdit.map((v, i) => {
            //if(!v.taskdetail.length){return false}
            if (v.fieldtype.id == '1' && ((v.fieldkey == "websites" || v.fieldkey == "social" || v.fieldkey == "phones" || v.fieldkey == "email") && v.is_default)) {
              if (v.taskdetail.length > 0 && v.taskdetail[0].values.length > 0) {
                var data = {
                  fieldid: v.id,
                  values: v.taskdetail[0].values
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }
              }

            } else if (v.fieldtype.id == '1' && v.id == '57') {//for first/last name 

              if (v.taskdetail.length > 0) {
                var data = {
                  fieldid: v.id,
                  taskvalue: v.taskdetail[0].fieldvalue
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }
              }
              if (v.taskrepeat) {
                var selectedRepeatName = ""
                if (v.taskrepeat.type == "never") {
                  selectedRepeatName = "Set to repeat"
                } else {
                  selectedRepeatName = v.taskrepeat.type.charAt(0).toUpperCase() + v.taskrepeat.type.slice(1)
                }
                this.setState({
                  type: v.taskrepeat.type,
                  days: v.taskrepeat.days,
                  repeatdays: v.taskrepeat.repeatdays,
                  repeattype: v.taskrepeat.repeattype,
                  selectedRepeatValue: v.taskrepeat.type,
                  selectedRepeatName: selectedRepeatName,
                });
              }

            }
            else if (v.fieldtype.id == '1' && v.id == '61') {//for owner field        
              if (v.taskdetail.length > 0 && v.taskdetail[0].values.length && v.taskdetail[0].values[0].id) {
                var companyOwnerKey = this.findWithAttr(this.state.usersListArray, 'id', v.taskdetail[0].values[0].id);
                if (companyOwnerKey !== -1) {
                  var ownerData = {
                    fieldid: v.id,
                    values: this.state.usersListArray[companyOwnerKey]
                  }
                  fieldsObj = {
                    ...fieldsObj,
                    [v.fieldkey]: ownerData,
                  }
                }
              }
            } else if (v.fieldtype.id == '1' && v.fieldkey == 'tags') {//for tag input
              var tagArray = []
              if (v.taskdetail.length > 0 && v.taskdetail[0].values.length > 0) {
                v.taskdetail[0].values.map((v, i) => {
                  tagArray.push(v.value);
                })
              }
              var data = {
                fieldid: v.id,
                values: tagArray
              }
              fieldsObj = {
                ...fieldsObj,
                [v.fieldkey]: data,
              }
            } else if (v.fieldtype.id == '1' && v.id == '59') {//For related field
              if (v.taskdetail.length > 0 && v.taskdetail[0].values.length && v.taskdetail[0].values[0].id) {
                //var relatedKey = this.findWithAttr(this.state.relatedListArray, 'id',v.taskdetail[0].values[0].id);
                var selectedObj = this.state.relatedListArray.filter(function (element) {
                  if (element.id == v.taskdetail[0].values[0].id && element.type == v.taskdetail[0].values[0].type) {
                    return element
                  }
                });
                if (selectedObj.length > 0) {
                  var relatedData = {
                    fieldid: v.id,
                    //values: this.state.relatedListArray[relatedKey]
                    values: selectedObj[0]
                  }
                  fieldsObj = {
                    ...fieldsObj,
                    [v.fieldkey]: relatedData,
                  }
                }
              }
            } else if (v.fieldtype.id == '1' || v.fieldtype.id == '6' || v.fieldtype.id == '10' || v.fieldtype.id == '7' || v.fieldtype.id == '9') {//other textfield and number input
              if (v.taskdetail.length > 0 && v.taskdetail.length > 0) {
                var data = {
                  fieldid: v.id,
                  taskvalue: v.taskdetail[0].fieldvalue
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }
              }
            }

            if (v.fieldtype.id == '2') {//TextArea
              if (v.taskdetail.length > 0) {
                var data = {
                  fieldid: v.id,
                  taskvalue: v.taskdetail[0].fieldvalue
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }
              }
            }

            if (v.fieldtype.id == "3") {//checkboxes
              if (v.taskdetail.length > 0) {
                var data = {
                  fieldid: v.id,
                  taskvalue: v.taskdetail[0].fieldvalue
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }
              }
            }
            //if(this.state.fieldsList && this.state.fieldsList.length >0){
            if (v.fieldtype.id === '4') {//dropdown
              //11-source/3-status/5-visibility
              if (v.id === "65" || v.id === "58") {
                if (v.taskdetail.length > 0 && v.taskdetail[0].values.length > 0) {
                  var optId = this.findWithAttr(v.options, 'id', parseInt(v.taskdetail[0].values[0].id));
                  if (v.id === "65") {
                    if (v.taskdetail[0].values[0].id === 2) {
                      let filteredselectedTeamList = this.state.selectedTeamList.length > 0 ? this.state.selectedTeamList.filter(el => Object.keys(el).length) : []
                      if (v.taskdetail[0].values[0].visiblelist && filteredselectedTeamList !== v.taskdetail[0].values[0].visiblelist) {
                        selectedTeamList = v.taskdetail[0].values[0].visiblelist
                      }
                    } else if (v.taskdetail[0].values[0].id === 3) {
                      let filteredselectedUserList = this.state.selectedUserList.length > 0 ? this.state.selectedUserList.filter(el => Object.keys(el).length) : []
                      if (v.taskdetail[0].values[0].visiblelist && filteredselectedUserList !== v.taskdetail[0].values[0].visiblelist) {
                        selectedUserList = v.taskdetail[0].values[0].visiblelist
                      }
                    }
                  }
                  fieldsObj = {
                    ...fieldsObj,
                    [v.fieldkey]: { fieldid: v.id, values: v.options[optId] },
                  }
                }
              } else if (v.id === "59") {
                if (v.taskdetail.length > 0 && v.taskdetail[0].values.length > 0) {
                  var optId = v.taskdetail[0].values[0].id;
                  this.setState({ comp_value: optId });
                }

              } else {
                if (v.taskdetail.length > 0 && v.taskdetail[0].values.length > 0) {
                  var optId = this.findWithAttr(v.options, 'id', v.taskdetail[0].values[0].id);
                  fieldsObj = {
                    ...fieldsObj,
                    [v.fieldkey]: { fieldid: v.id, values: v.options[optId] },
                  }
                }
              }
            }
            if (v.fieldtype.id === '5') {//multi dropdown
              if (v.taskdetail.length > 0 && v.taskdetail[0].values.length > 0) {
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: { fieldid: v.id, values: v.taskdetail[0].values },
                }
              }
            }
            if (v.fieldtype.id === '8') {//datepicker
              if (v.id == "62") {
                var selectedReminderValue = ""
                var selectedReminderName = ""
                if (v.taskdetail.length > 0 && v.taskdetail[0].values.length > 0) {
                  if (v.taskdetail[0].values[0]) {
                    if (v.taskdetail[0].values[0].date != "") {
                      var dt = moment.unix(v.taskdetail[0].values[0].date).tz(this.state.userSettings.timezone).format('YYYY-MM-DD HH:mm')
                      dt = new Date(dt)
                      fieldsObj = {
                        ...fieldsObj,
                        [v.fieldkey]: { fieldid: v.id, values: dt },
                      }
                    }

                    if (v.taskdetail[0].values[0].type == 0) {
                      selectedReminderValue = v.taskdetail[0].values[0].type
                      selectedReminderName = "Add reminder"
                    } else if (v.taskdetail[0].values[0].type == 1) {
                      selectedReminderValue = v.taskdetail[0].values[0].type
                      selectedReminderName = "30 minutes before"
                    } else if (v.taskdetail[0].values[0].type == 2) {
                      selectedReminderValue = v.taskdetail[0].values[0].type
                      selectedReminderName = "1 hour before"
                    } else if (v.taskdetail[0].values[0].type == 3) {
                      selectedReminderValue = v.taskdetail[0].values[0].type
                      selectedReminderName = "1 day before"
                    } else if (v.taskdetail[0].values[0].type == 4) {
                      selectedReminderValue = v.taskdetail[0].values[0].type
                      selectedReminderName = "1 week before"
                    } else if (v.taskdetail[0].values[0].type == 5) {
                      selectedReminderValue = v.taskdetail[0].values[0].type
                      selectedReminderName = "Custom"
                    }

                    this.setState({
                      reminderMinitue: v.taskdetail[0].values[0].minitue,
                      selectedReminderName, selectedReminderName,
                      selectedReminderValue, selectedReminderValue
                    }, () => this.forceUpdate())
                  }
                }

              } else {
                if (v.taskdetail.length > 0 && v.taskdetail[0].fieldvalue != "" && v.taskdetail[0].fieldvalue != null) {
                  var dt;
                  if (v.id != '60') {
                    dt = new Date(moment(v.taskdetail[0].fieldvalue).format('YYYY-MM-DD'))
                  } else {
                    dt = moment.unix(v.taskdetail[0].fieldvalue).tz(this.state.userSettings.timezone).format('YYYY-MM-DD HH:mm')
                  }
                  dt = new Date(dt)

                  fieldsObj = {
                    ...fieldsObj,
                    [v.fieldkey]: { fieldid: v.id, values: dt },
                  }
                } else if (v.taskdetail.length > 0 && v.taskdetail[0].fieldvalue == "") {
                  fieldsObj = {
                    ...fieldsObj,
                    [v.fieldkey]: { fieldid: v.id, values: null },
                  }
                }
              }
            }
            //}
          })

          this.setState({
            oldSelctedTeamList: [...selectedTeamList, {}],
            oldSlectedUserList: [...selectedUserList, {}],
            selectedTeamList: [...selectedTeamList, {}],
            selectedUserList: [...selectedUserList, {}],
            fieldsObj,
            country,
            isUpdate: false,
          }, () => {
            resolve(true)
          })
        });

        promise.then(result => {
          props.taskReducer.taskFieldData.is_loaded = false;
          this.setState({
            isAnyValueChange: false
          }, () => {
          })
        });
      })
    }
  }

  handleCompanyChange = (val, newValue) => {
    let id = (newValue.__isNew__) ? "0" : newValue.value;
    let comp_values = { id: id, name: newValue.label };
    this.handleChange({ fieldid: "59", values: comp_values }, 'company', 'allowSubmitChange');
    this.setState({ comp_value: id });
  };


  addNewField = (key, option) => {
    let data = this.state.fieldsObj[key];
    data.values.push({
      srno: (data.values.length + 1).toString(),
      id: option.id,
      value: ""
    })

    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [key]: data
      }
    })
  }

  handleAddMoreField = (name, key, index, value) => {
    let data = this.state.fieldsObj[key];
    data.values[index][name] = value;
    var keyName = key;
    var keyVal = data;
    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [key]: data
      },
      isAnyValueChange: true
    }, () => {
      // setTimeout(() => {
      //   //dont upadte lead if first name is empty
      //   if(this.state.fieldsObj['name'] && this.state.fieldsObj['name'].taskvalue == ""){
      //     return false
      //   }
      //   //dont allow to update if value length 1 and value is blank
      //   if(keyVal.values.length == 1 && keyVal.values[0].value == ""){
      //     return false
      //   }
      //   if (this.state.fieldsObj[keyName] == keyVal) {
      //     this.handleFormSubmit()
      //   }
      // }, 2500);
    })
  }

  handleNormalFields = (event) => {
    var keyName = event.target.name;
    var keyVal = event.target.value;
    this.setState({
      [event.target.name]: event.target.value,
      isAnyValueChange: true
    }, () => {
      // //dont upadte lead if first name is empty
      // if(this.state.fieldsObj['name'] && this.state.fieldsObj['name'].taskvalue == ""){
      //   return false
      // }
      // setTimeout(() => {
      //   if (this.state[keyName] == keyVal) {
      //     this.handleFormSubmit()
      //   }
      // }, 1500);
    })

    if (event.target.name == "repeattype") {
      this.setState({
        repeatdays: 1
      });
    }
  };

  handleCountryChange = (event, values) => {
    this.setState({
      country: values,
      isAnyValueChange: true
    }, () => {
      //dont upadte lead if first name is empty
      // if(this.state.fieldsObj['name'] && this.state.fieldsObj['name'].taskvalue == ""){
      //   return false
      // }
      setTimeout(() => {
        if (this.state.country == values) {
          this.handleFormSubmit()
        }
      }, 500);
    })
  }

  focusInValue = () => {
    this.setState({
      focusValue: true,
    }, () => this.forceUpdate())
  };


  currencyDropdownClose = () => {
    this.setState({ focusValue: false })
  };

  handleChange = (val, name, isSubmit) => {
    if (val.fieldid === "65") {
      this.setState({ isEditVisibility: true }, () => this.forceUpdate())
      if (val.values.id === 2) {
        this.setState({ selectedTeamList: [{}] }, () => this.forceUpdate())
      } else if (val.values.id === 3) {
        this.setState({ selectedUserList: [{}] }, () => this.forceUpdate())
      }
    }
    var key = this.findWithAttr(this.state.fieldsList, 'id', val.fieldid);
    if (key != -1 && this.state.fieldsList[key].fieldtype.id != "4" && this.state.fieldsList[key].fieldtype.id != "8") {
      if (this[name]) {
        this[name].validate(val.value);
      }
    }
    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [name]: val
      },
      isAnyValueChange: true,
      updatedfieldid: parseFloat(val.fieldid),
    }, () => {
      // if(name === "name" && val.taskvalue ===""){
      //   this.setState({ fnameError: true })
      //   return false
      // }else{
      //   this.setState({ fnameError: false })
      // }
      // //dont upadte lead if first name is empty
      // if(this.state.fieldsObj['name'].taskvalue == ""){
      //   return false
      // }
      if (!isSubmit) {
        return false
      }
      setTimeout(() => {
        if (this.state.fieldsObj[name] == val) {
          this.handleFormSubmit()
        }
      }, 500);
    })
  };


  focusOutSubmitForm = (event, fieldId, ref) => {
    this.setState({ updatedfieldid: parseFloat(fieldId), hasEdit: false })
    if (this[ref].props && this[ref].props.id == "tagInputId1") {
      setTimeout(() => {
        document.getElementById("tagInputId1").value = "";
      }, 10)
    }
    this[ref].validate(event.target.value);
    setTimeout(() => {
      if (this.state.isAnyValueChange) {
        this.handleFormSubmit()
      }
    }, 0);
  }

  changeRepeatOption = (val, name, days) => {
    this.setState({
      selectedRepeatValue: val,
      selectedRepeatName: name,
      days: days,
      type: val,
    }, () => {
      setTimeout(() => {
        this.handleFormSubmit()
      }, 500);
    });

    if (val == "custom") {
      this.setState({
        repeatdays: 1,
        repeattype: "days",
      }, () => {
        setTimeout(() => {
          this.handleFormSubmit()
        }, 500);
      });
    }
  }

  changeReminderOption = (fieldkey, name, fieldid) => {
    this.setState({
      selectedReminderValue: fieldkey,
      selectedReminderName: name,
      reminderMinitue: fieldid,
      updatedfieldid: parseFloat(fieldid),
    }, () => {
      setTimeout(() => {
        this.handleFormSubmit()
      }, 500);
    });
  }

  handleFormSubmit = () => {

    this.updateTaskForm.isFormValid(false).then(isValid => {
      this.setState({
        isAnyValueChange: false
      }, () => this.forceUpdate())

      if (isValid) {

        var dd = "";
        let submitData = JSON.parse(JSON.stringify(this.state.fieldsObj));
        submitData = Object.values(submitData).map((row) => {
          if (row.fieldid == "59") {//realated  field
            if (row.values == null) {
              return false
            }
            row.values = [{ srno: "1", id: row.values.id, value: row.values.name, type: row.values.type }]
          }
          if (row.fieldid === "58") {
            row.values = [{ srno: "1", id: row.values.id, value: row.values.name, icon: row.values.icon }]
          }
          if (row.fieldid === "65" || row.fieldid === "63") {//visibility
            if (row.fieldid === "65") {
              let filterVisibleList = [];
              if (row.values.id === 2) {
                filterVisibleList = this.state.selectedTeamList.length > 0 ? this.state.selectedTeamList.filter(el => Object.keys(el).length) : []
              } else if (row.values.id === 3) {
                filterVisibleList = this.state.selectedUserList.length > 0 ? this.state.selectedUserList.filter(el => Object.keys(el).length) : []
              }
              row.values = [
                {
                  srno: "1",
                  id: row.values.id,
                  value: row.values.name,
                  visiblelist: filterVisibleList
                }
              ];
            } else {
              row.values = [{ srno: "1", id: row.values.id, value: row.values.name }]
            }
          }
          var key = this.findWithAttr(this.state.fieldsList, 'id', row.fieldid);
          if (this.state.fieldsList[key].fieldtype.id == "4" && this.state.fieldsList[key].is_default == false) {
            row.values = [{ srno: "1", id: row.values.id, value: row.values.optionvalue }]
          }
          if (this.state.fieldsList[key].fieldtype.id == "5" && this.state.fieldsList[key].is_default == false) {
            if (row.values.length > 0) {
              var ar = [];
              row.values.map((val, i) => {
                ar.push({ srno: (i + 1).toString(), id: val.id, value: val.optionvalue })
              })
            }
            row.values = ar
          }

          //validating date fiels
          if (this.state.fieldsList[key].fieldtype.id == "8") {//date field
            if (this.state.fieldsList[key].recordcreationdefaultvalue == 2 && row.values == null) {
              return false
            } else if (this.state.fieldsList[key].recordcreationdefaultvalue != 2 && row.values == null) {
              row.taskvalue = "";
              delete row.values;
            } else if (row.values == "Invalid Date") {
              return false
            } else {
              // row.taskvalue = moment(row.values).format("X");
              let date_test = moment(row.values).format('YYYY-MM-DD HH:mm');
              var logdate = moment.tz(date_test, this.state.userSettings.timezone).format();
              row.taskvalue = moment.utc(logdate).format('X');

              delete row.values;
            }
          }

          if (row.fieldid == "60") {
            if (row.taskvalue == "") {
              // row.taskvalue = moment(new Date()).format("X");
              let date_test = moment(new Date()).format('YYYY-MM-DD HH:mm');
              var logdate = moment.tz(date_test, this.state.userSettings.timezone).format();
              row.taskvalue = moment.utc(logdate).format('X');
              dd = row.taskvalue;
              // dd = moment(new Date()).format("X");

            } else {
              dd = row.taskvalue;
            }
          }
          if (row.fieldid == "62") {
            if (this.state.selectedReminderValue == "0") {
              row.values = [{ type: 0, date: "", minitue: 0 }];
            } else if (this.state.selectedReminderValue == "5") {//custom
              var dt1 = moment.unix(dd).format(this.state.userSettings.dateformat).toString()
              dt1 = new Date(dt1)
              var dt2 = moment.unix(row.taskvalue).format(this.state.userSettings.dateformat).toString()
              dt2 = new Date(dt2)

              var dif = (dt1 - dt2);
              var difMinute = Math.round((dif / 1000) / 60);

              row.values = [{ type: this.state.selectedReminderValue, date: row.taskvalue, minitue: parseInt(difMinute) }];

            } else {
              row.values = [{ type: this.state.selectedReminderValue, date: "", minitue: parseInt(this.state.reminderMinitue) }];
            }

            delete row.taskvalue;
          }

          if (this.state.fieldsList[key].fieldtype.id == "8" && row.fieldid != '60' && row.fieldid != '62') {//For custom date fiels
            if (row.taskvalue != "") {
              var inFormat = moment.unix(row.taskvalue).format('YYYY-MM-DD')
              row.taskvalue = inFormat
            }
          }

          if (row.fieldid == "66") {
            if (row.values.length > 0) {
              var objArr = [];
              row.values.map((val, k) => {
                var foundKey = this.findWithAttr(this.state.systemTagsList, 'tagname', val);
                var tagId = "0";
                if (foundKey !== -1) {
                  tagId = this.state.systemTagsList[foundKey].id
                }
                objArr.push({ "srno": (k + 1).toString(), value: val.toLowerCase(), id: tagId, isdeleted: "0" })
              })

              //add tags from props value which is removed   with id deleted id "1"
              var tagsKeyFromProps = this.findWithAttr(this.props.taskReducer.taskFieldData.data, 'id', "66");
              if (this.props.taskReducer.taskFieldData.data[tagsKeyFromProps].taskdetail.length > 0) {
                var oldTagsValues = this.props.taskReducer.taskFieldData.data[tagsKeyFromProps].taskdetail[0].values;
                if (oldTagsValues.length > 0) {
                  oldTagsValues.map((v, k) => {
                    var checkKey = this.findWithAttr(objArr, 'value', v.value);
                    if (checkKey === -1) {
                      v.isdeleted = "1";
                      v.srno = (objArr.length + 1).toString();
                      objArr.push(v);
                    }
                  })
                }
              }

              row.values = objArr
              if (this.props.taskReducer.taskFieldData.data[tagsKeyFromProps].taskdetail.length > 0) {
                this.props.taskReducer.taskFieldData.data[tagsKeyFromProps].taskdetail[0].values = objArr;
              }
            }
          }

          if (row.fieldid == "61") {//owner value set
            if (row.values) {
              row.values = [{ "srno": "1", id: row.values.id, value: row.values.email, name: row.values.name }];
            } else {
              row.values = [];
            }
            delete row.taskvalue;
          }
          return row;
        })


        //not to submit if any field is false
        var allowToSubmit = true
        submitData.map((row) => {
          if (!row) {
            allowToSubmit = false
          }
        })



        //logic for setting repeat object
        var repeat = {
          type: this.state.type,
          repeatdays: this.state.repeatdays,
          repeattype: this.state.repeattype,
          days: this.state.days,
        }


        if (this.state.type == "never") {
          repeat.repeatdays = 0
          repeat.repeattype = ""
          repeat.days = 0
        }

        if (this.state.type == "custom") {
          if (this.state.repeattype == "days") {
            repeat.days = this.state.repeatdays
          }
          if (this.state.repeattype == "weeks") {
            repeat.days = this.state.repeatdays * 7
          }
          if (this.state.repeattype == "months") {
            repeat.days = this.state.repeatdays * 30
          }
        }

        if (this.state.type != "custom" && this.state.type != "never") {
          repeat.repeatdays = 0
          repeat.repeattype = ""
        }
        // console.log("repeat", repeat);
        // return


        var allowToSubmit = true
        submitData.map((row) => {
          if (!row) {
            allowToSubmit = false
          }
        })

        if (this.state.task_id && allowToSubmit) {

          // update task field data afte update.... (For log)
          this.setState({ isUpdate: true, isEditVisibility: false });          // -- ---------------

          let req = {
            taskdata: submitData,
            repeat: repeat,
            taskid: this.state.task_id,
            taskImage: "",
            updatedfieldid: this.state.updatedfieldid,
          }
          console.log(req, 'reqreq');

          this.props.updateTaskDetail(this.props.apolloClient.client, req);
        }
      }
    });
  };

  arrDifference = (arr1, arr2) => {
    var arr = [];
    arr1 = arr1.toString().split(',').map(Number);
    arr2 = arr2.toString().split(',').map(Number);
    // for array1
    for (var i in arr1) {
      if (arr2.indexOf(arr1[i]) === -1)
        arr.push(arr1[i]);
    }
    // for array2
    for (i in arr2) {
      if (arr1.indexOf(arr2[i]) === -1)
        arr.push(arr2[i]);
    }
    return arr.sort((x, y) => x - y);
  }

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] == value) {
        return i;
      }
    }
    return -1;
  }
  handlehasEdit = () => {
    this.setState({ hasEdit: !this.state.hasEdit })
  }
  validateUrl = (value) => {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
  }

  teamHandleChange = (index, value, name) => {
    let mianteam = this.state.teamList;
    let selectedTeamList = this.state.selectedTeamList
    let mianUsers = this.state.usersListArray;
    let selectedUserList = this.state.selectedUserList
    if (name === "team") {
      if (value !== null) {
        selectedTeamList[index] = value;
        if (mianteam.length + 1 > selectedTeamList.length) {
          selectedTeamList[index + 1] = {};
        }
      } else {
        selectedTeamList.splice(index, 1);
        if (selectedTeamList.length === 0) {
          selectedTeamList[0] = {};
        }
      }
    } else {
      if (value !== null) {
        selectedUserList[index] = { id: value.id, name: value.name }
        if (mianUsers.length + 1 > selectedUserList.length) {
          selectedUserList[index + 1] = {};
        }
      } else {
        selectedUserList.splice(index, 1);
        if (selectedUserList.length === 0) {
          selectedUserList[0] = {};
        }
      }
    }
    this.setState({
      selectedTeamList: selectedTeamList,
      isEditVisibility: true,
      selectedUserList: selectedUserList
    })
  }
  handleToggleEditVisibility = () => {
    this.setState({
      isEditVisibility: !this.state.isEditVisibility
    })
  }

  handleChangeVisibilityRadio = (value) => {
    if (value) {
      let customFields = [...this.state.fieldsList]
      var vkey = this.findWithAttr(customFields, "id", "65");
      var visibilitydefault = this.findWithAttr(
        customFields[vkey].options,
        "is_default",
        true
      );
      this.setState({
        selectedTeamList: [],
        updatedfieldid: 5,
        fieldsObj: {
          ...this.state.fieldsObj,
          ["visibility"]: {
            fieldid: "65",
            values: customFields[vkey].options[visibilitydefault],
          },
        },
      });
    }
    this.setState({
      teamvisible: value === "true" ? true : false
    })

  }
  handleVisibilitySave = () => {
    this.setState({
      updatedfieldid: 65
    }, () => this.handleFormSubmit())
  }
  hadleCancelvisibility = () => {
    let fieldsObj = this.state.fieldsObj
    let customFields = [...this.state.fieldsList]
    var vkey = this.findWithAttr(customFields, "id", "65");
    var visibilitydefault = this.findWithAttr(customFields[vkey].options, "is_default", true);

    let taskFieldData = [...this.state.taskFieldData]
    var key1 = this.findWithAttr(taskFieldData, 'id', "65");
    if (taskFieldData[key1].taskdetail[0].values[0]) { //if visibilty not efault 
      let mainoldValue = taskFieldData[key1].taskdetail[0].values[0];
      var opn = this.findWithAttr(taskFieldData[key1].options, 'id', mainoldValue.id);
      fieldsObj = {
        ...fieldsObj,
        ["visibility"]: {
          fieldid: "65",
          values: taskFieldData[key1].options[opn],
        },
      }
    } else {//set  visibilty  default 
      fieldsObj = {
        ...fieldsObj,
        ["visibility"]: {
          fieldid: "65",
          values: customFields[vkey].options[visibilitydefault],
        },
      }
    }
    this.setState({
      selectedTeamList: this.state.oldSelctedTeamList,
      selectedUserList: this.state.oldSlectedUserList,
      fieldsObj: fieldsObj,
    });
    this.handleToggleEditVisibility()
  }
  render() {
    return (
      <div className="v_scroll position-relative label-size-14 pr-16">
        <ValidatorForm ref={updateTaskForm => this.updateTaskForm = updateTaskForm} noValidate instantValidate={false} onSubmit={this.handleFormSubmit}>
          <Grid container spacing={2}>
            {CreateFieldsDynamicTaskEdit(this)}
          </Grid>
        </ValidatorForm>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
  leadReducer: state.leadReducer,
  taskReducer: state.taskReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    getCompanyRecList: (client) => { dispatch(actionCreators.getCompanyRecList(client)) },
    updateTaskDetail: (client, request) => { dispatch(actionCreators.updateTaskDetail(client, request)) },
    customFieldList: (client, request) => { dispatch(actionCreators.customFieldList(client, request)) },
    getTaskActivityLogList: (client, leadid) => dispatch(actionCreators.getTaskActivityLogList(client, leadid)),

    getTeam: (client) => dispatch(actionCreators.getTeam(client)),

    getCountryList: (client) => { dispatch(actionCreators.getCountryList(client)) },
    clearNotification: () => dispatch(actionCreators.clearNotification()),
    getCompanyCurrency: (client) => dispatch(actionCreators.getCompanyCurrency(client)),
    getTagsList: (client) => dispatch(actionCreators.getTagsList(client)),
    getTaskField: (client, leadId) => dispatch(actionCreators.getTaskField(client, leadId)),
    getUserList: (client) => dispatch(actionCreators.getUserList(client)),
    getUserSetting: (client, data) => dispatch(actionCreators.getUserSetting(client, data)),
    getRelatedList: (client) => dispatch(actionCreators.getRelatedList(client)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskProfileDetails);