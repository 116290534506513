import React, { Component } from "react";
import { Grid, Button, withStyles, CircularProgress, Backdrop, Container } from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import GoogleLogin from 'react-google-login';
import * as actionCreators from '../../../store/actions/index';

const styles = theme => ({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
});

class SignIn extends Component {

  state = {
    email: "",
    password: "",
    loading: false,
    err_msg: '',
  };

  componentDidMount() {
    // custom rule will have name 'isPasswordMatch'
    ValidatorForm.addValidationRule('minLength', (value) => {
      if (value.length < 8) {
        this.setState({
          err_msg: 'Minimum length 8.'
        })
        return false;
      }
      if (value.length > 100) {
        this.setState({
          err_msg: 'Maximum length 100.'
        })
        return false;
      }

      var specialChar = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

      if (!specialChar.test(value)) {
        this.setState({
          err_msg: 'Password should contain special character.'
        })
        return false;
      }

      if (this.checkIsNumeric(value)) {
        this.setState({
          err_msg: 'Should Contain Numeric value.'
        })
        return false;
      }
      if (!value.match(/[A-Z]/g)) {
        this.setState({
          err_msg: 'Should Contain 1 UpperCaseLetter.'
        })
        return false;
      }
      if (!value.match(/[a-z]/g)) {
        this.setState({
          err_msg: 'Should Contain 1 LowerCaseLetter.'
        })
        return false;
      }
      if (value.indexOf(' ') >= 0) {
        this.setState({
          err_msg: 'Remove space from password.'
        })
        return false;
      }
      this.setState({
        err_msg: ''
      })
      return true;

    });
  }

  handleChange = event => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value
    });
    //console.log("this.updateLeadForm", this.updateLeadForm.resetValidations());
    // /console.log("this.updateLeadForm", this.updateLeadForm);

    
  };
  handleFormSubmit = event => {
    // this.updateLeadForm.isFormValid(true).then(isValid => {
    //   console.log("isValid", isValid);


    // })
    this.setState({ loading: true })
    this.props.loginWithEmailAndPassword({ ...this.state });
  };
  handleGoogleAuthSucess = (load_response) => {
    this.props.loginWithGoogle(load_response);
  };
  handleGoogleAuthFailure() {

  }
  componentWillReceiveProps = (props) => {
    this.setState({ loading: false })   
  };
  checkUpperCaseLetter(value) {
    let arr = value.split('');
    let chk_flag = false;

    arr.map((val, index) => {
      if (val == val.toUpperCase() && isNaN(val * 1)) {
        console.log("uppvalue", val)
        chk_flag = true;
      }
    })
    console.log("chk_flag", chk_flag);
    return chk_flag;
  }
  checkIsNumeric(value) {
    let chk_flag = true;
    let arr = value.split('');
    arr.map((val, index) => {
      if (!isNaN(val * 1)) {
        chk_flag = false;
      }
    })
    return chk_flag;
  }

  handleBlur = (event,ref) => {
      this[ref].validate(event.target.value);
  }

  render() {
    let { email, password } = this.state;
    let { classes } = this.props;
    return (
      <Container maxWidth="md" className="h-100vh">
        <Grid container justify="center" alignItems="center" className="h-100">
          <div className="center_bx">
            <Grid container>
              <Grid item sm={6} xs={12}>
                <div className="pl-50 pr-50 py-80">
                  <h3 className="text-center font-weight-bold">Sign In</h3>
                  <p className="text-center mt-0">Need an account? <Link to="/sign-up/" className="link link_primary">Get started!</Link></p>
                  <ValidatorForm  noValidate instantValidate={false} onSubmit={this.handleFormSubmit}>
                    <TextValidator
                      ref={ref => this.emailRef = ref}
                      onBlur={(event)=>this.handleBlur(event,'emailRef')}
                      className="mb-16 w-100"
                      label="Email"
                      onChange={this.handleChange}
                      type="text"
                      name="email"
                      value={email}
                      required={true}
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        "This field is required",
                        "email is not valid"
                      ]}
                      autoComplete="off"
                    />
                    <TextValidator
                      ref={ref => this.passwordRef = ref}
                      onBlur={(event)=>this.handleBlur(event,'passwordRef')}
                      className="mb-24 w-100"
                      label="Password"
                      onChange={this.handleChange}
                      name="password"
                      type="password"
                      required={true}
                      value={password}
                      validators={["required", 'minLength']}
                      errorMessages={["This field is required", this.state.err_msg]}
                      autoComplete="off"
                    />
                    <Button 
                    variant="contained" color="primary" fullWidth /*disabled={this.props.login.loading}*/ className="mb-16 button_hover" type="submit">Sign In</Button>
                    <GoogleLogin
                      clientId={"161942936141-fsq8al02npjinbf8vm1rr1lop0takre0.apps.googleusercontent.com"}
                      //clientId={"965427486424-jsdger0lt68ncnd49jki1ojpjkaemndc.apps.googleusercontent.com"}
                      buttonText={"Sign in with Google"}
                      onSuccess={this.handleGoogleAuthSucess}
                      onFailure={this.handleGoogleAuthFailure}
                      responseType="code"
                      uxMode='redirect'
                      className="google_btn"
                      redirectUri=''
                      scope="https://www.googleapis.com/auth/drive https://mail.google.com https://www.googleapis.com/auth/calendar profile email openid"
                    />
                    <p className="text-center"><Link to="/forgot-password/" className="link link_primary">Forgot password?</Link></p>
                    {this.state.loading && (
                      <Backdrop
                        className={classes.backdrop}
                        open={this.state.loading}
                      >
                        <CircularProgress size={60} color="inherit" />
                      </Backdrop>
                    )}
                  </ValidatorForm>
                </div>
              </Grid>
              <Grid item sm={6} xs={12} className="layers_bg align-items-end d-flex">
                <div>
                  <h2>Hello there!</h2>
                  <p>Meet the crm that allows you to nurture leads and close deals from your inbox. <br /> Login here and start selling more today.</p>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  userData: state.authReducer.userData,
});
const mapDispatchToProps = dispatch => {
  return {
    loginWithEmailAndPassword: (data) => dispatch(actionCreators.loginWithEmailAndPassword(data)),
    loginWithGoogle: (response) => dispatch(actionCreators.loginWithGoogle(response))
  }
}
export default withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SignIn)
);
