import React, { Component } from "react";
// import $ from "jquery";
import {
    Dialog,
    IconButton,
    Button,
    Icon,
    Tooltip,
    Grid,
    DialogActions,
    FormControl,
    MenuItem,
    TextField,
    Checkbox,
    InputLabel,
    FormControlLabel,
    Grow,
    Select,
    ListSubheader,
    withStyles,
    CircularProgress
} from "@material-ui/core";

import { SingleSelect } from "react-select-material-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import { Redirect, Route } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import {
    ValidatorForm,
    TextValidator,
    SelectValidator,
} from "react-material-ui-form-validator";

import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import { lastDayOfDecade } from "date-fns";
import InputAdornment from "@material-ui/core/InputAdornment";
import moment from 'moment';

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.0rem",
    }
}))(Icon);

const TooltipNew = withStyles((theme) => ({
    tooltip: {
      fontSize: 14,
    },
  }))(Tooltip);

class RevertImportDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            filecode:'',
            fullfilename:'',
            type:'',
            afterSubmit:false,
            isLoading: false,
            dateformat: 'MM/DD/YYYY',
            timeZone: 'America/New_York',
            dateofimport: "",
            importby: "",
            skiprecord: 0, 
            totalrecord: 0,
        };
    }

    componentDidMount(){
        if (this.props.data.state) {
            let stateData = this.props.data.state;
            
            let req = { importid: parseFloat(stateData.field) };
            this.props.getRevertDetail(this.props.apolloClient.client, req);

            this.setState({ isSummary: this.props.isSummary, field: parseFloat(stateData.field)})
        }
        
    }
    revertImportData = () => {
       
        let req = { field: parseFloat(this.state.field)};
        this.props.revertImportData(this.props.apolloClient.client, req);
        this.props.handleClose();
    }
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })        
    }

    componentWillReceiveProps(props){
        
        if (props.settingReducer.revertImportDetail && props.settingReducer.revertImportDetail.data){
                console.log(props.settingReducer.revertImportDetail.data, 'stateDatastateDatastateData');
                let revertData = props.settingReducer.revertImportDetail.data;
                
                let fullfilename = revertData.fullfilename;
                
                let dateofimport = moment(revertData.createddate).format(this.state.dateformat) +' '+ moment(revertData.createddate).format('HH:mm');
                let importby = revertData.user.name;


                let cropname = fullfilename.split('.')[0];
                let filenamedata = cropname.split('-');
                let type = filenamedata[0];
                this.setState({ lead: revertData.lead, contact: revertData.contact, company: revertData.company, opportunities: revertData.opportunities, fullfilename: fullfilename, type: filenamedata[0], name: type + ' import', filecode: filenamedata[1], dateofimport, importby, skiprecord: revertData.skiprecord, totalrecord: revertData.totalrecord, recordid: revertData.recordid  })
            
        }
        if (props.settingReducer.importExcelData.status){
            this.setState({ isLoading : false });
        }

        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            let usersetting = props.settingReducer.userSettingArray.data;

            let format = usersetting.dateformat;
            let timezone = usersetting.timezone;
            this.setState({ dateformat: format, timeZone: timezone })
        }
        
    }

    backToImport = () => {
        this.props.backToImport()
        // this.props.history.push('/settings/import-data/');
    }

    render() {
        // let currentDate = new Date();
        // let dateofimport = moment(currentDate).format(this.state.dateformat) +' '+ moment(currentDate).format('HH:mm');
        // let importby = JSON.parse(localStorage.getItem('user_data_session')).name;
        let { lead, contact, company, opportunities, recordid, importby, dateofimport, totalrecord, skiprecord, isSummary} = this.state;
        let { open, handleClose, handleMinimize, handleFull, fullScreen, minimizeScreen} = this.props;
        let onChangeFunction = this.onChange;

        console.log(isSummary, recordid,    'isSummary');
        
        return (
            <Dialog
                onClose={handleClose}
                open={open}
                maxWidth="md"
                fullWidth={true}
                className={`crm-custom-bg-primary custom_dialog ${
                    minimizeScreen && fullScreen ? "alignitemsend-fullscreen" : ""
                    } ${minimizeScreen ? "alignitemsend" : ""} ${
                    fullScreen ? "fullscreen" : ""
                    } crm_dialog_create_template`}
                disableBackdropClick
                // disableEscapeKeyDown
            >
                <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">
                    <h4 className="m-0 text-white">{(isSummary == true) ? "Import Summary" : "Revert an Import"}</h4>
                    <div className="d-flex">
                        <TooltipNew
                            title={minimizeScreen ? "Normal Screen" : "Minimize Screen"}
                        >
                            <IconButton onClick={handleMinimize}>
                                <Icon className="text-white">
                                    {minimizeScreen ? "add" : "remove"}
                                </Icon>
                            </IconButton>
                        </TooltipNew>
                        <TooltipNew title={fullScreen ? "Exit Full Screen" : "Full Screen"}>
                            <IconButton onClick={handleFull}>
                                <Icon className="text-white">
                                    {fullScreen ? "fullscreen_exit" : "fullscreen"}
                                </Icon>
                            </IconButton>
                        </TooltipNew>
                        <TooltipNew title="Close">
                            <IconButton onClick={handleClose}>
                                <Icon className="text-white">clear</Icon>
                            </IconButton>
                        </TooltipNew>
                    </div>
                </div>
                
                <>
                    {this.state.isLoading ?
                        <div className="text-center mt-30 mb-30">
                            <CircularProgress />
                            <h6 className="mt-20">Uploading Data</h6>
                        </div>
                        :
                        <>
                            <div className={`${this.state.isDataLoading ? "dialog-body popupHeightCustom" : "dialog-body"}`} style={{ overflow: "visible" }}>
                                <span className="text-hint">Reverting this import will permanently delete all records that include the import tag listed below.</span>
                                <Grid container spacing={0}>
                                    <Grid item xs={2} className="mt-20 text-hint">Overview</Grid>
                                    <Grid item xs={10} className="mt-20"></Grid>
                                    
                                    <Grid item xs={2} className="m-0"><label className="font-weight-500">Import Tag</label></Grid>
                                    <Grid item xs={10} className="m-0"><label className="text-hint" style={{ fontSize: '12px' }}>{this.state.name + '-' + this.state.filecode}</label></Grid>
                                    
                                    <Grid item xs={2} className="m-0"><label className="font-weight-500">Imported On</label></Grid>
                                    <Grid item xs={10} className="m-0"><label className="text-hint" style={{ fontSize: '12px' }}>{dateofimport}</label></Grid>
                                    
                                    <Grid item xs={2} className="m-0"><label className="font-weight-500">Imported By</label></Grid>
                                    <Grid item xs={10} className="m-0"><label className="text-hint" style={{ fontSize: '12px' }}>{importby}</label></Grid>

                                    {
                                        (isSummary == true) ? 
                                            <>
                                                <Grid item xs={2} className="mt-20 text-hint">Success</Grid>
                                                <Grid item xs={10} className="mt-20"></Grid>

                                                <Grid item xs={2} className="m-0"><label className="font-weight-500">Rows uploaded</label></Grid>
                                                <Grid item xs={10} className="m-0"><label className="text-hint" style={{ fontSize: '12px' }}>{totalrecord}</label></Grid>

                                                <Grid item xs={2} className="m-0"><label className="font-weight-500">Rows skipped</label></Grid>
                                                <Grid item xs={10} className="m-0"><label className="text-hint" style={{ fontSize: '12px' }}>{skiprecord}</label></Grid>

                                                <Grid item xs={6} className="mt-20 text-hint">Records added/changed</Grid>
                                                <Grid item xs={6} className="mt-20"></Grid>
                                                {
                                                (recordid == 1) 
                                                    ?
                                                        <>
                                                            <Grid item xs={2} className="m-0"><label className="font-weight-500">Lead</label></Grid>
                                                            <Grid item xs={10} className="m-0"><label className="text-hint" style={{ fontSize: '12px' }}>{lead}</label></Grid>
                                                        </>

                                                    : (recordid == 2) ?

                                                        <>
                                                            <Grid item xs={2} className="m-0"><label className="font-weight-500">Contact</label></Grid>
                                                            <Grid item xs={10} className="m-0"><label className="text-hint" style={{ fontSize: '12px' }}>{contact}</label></Grid>

                                                            <Grid item xs={2} className="m-0"><label className="font-weight-500">Company</label></Grid>
                                                            <Grid item xs={10} className="m-0"><label className="text-hint" style={{ fontSize: '12px' }}>{company}</label></Grid>
                                                        </>
                                                    : (recordid == 3) ?

                                                        <>
                                                            <Grid item xs={2} className="m-0"><label className="font-weight-500">Company</label></Grid>
                                                            <Grid item xs={10} className="m-0"><label className="text-hint" style={{ fontSize: '12px' }}>{company}</label></Grid>
                                                        </>
                                                    : (recordid == 4) ?

                                                        <>
                                                            <Grid item xs={2} className="m-0"><label className="font-weight-500">Contact</label></Grid>
                                                            <Grid item xs={10} className="m-0"><label className="text-hint" style={{ fontSize: '12px' }}>{contact}</label></Grid>

                                                            <Grid item xs={2} className="m-0"><label className="font-weight-500">Company</label></Grid>
                                                            <Grid item xs={10} className="m-0"><label className="text-hint" style={{ fontSize: '12px' }}>{company}</label></Grid>

                                                            <Grid item xs={2} className="m-0"><label className="font-weight-500">Opportunity</label></Grid>
                                                            <Grid item xs={10} className="m-0"><label className="text-hint" style={{ fontSize: '12px' }}>{opportunities}</label></Grid>
                                                        </>
                                                    : 
                                                        <></>
                                                }
                                                
                                            </>
                                        :
                                            <>
                                                <Grid item xs={6} className="mt-20 text-hint">Records to be removed</Grid>
                                                <Grid item xs={6} className="mt-20"></Grid>
                                                {
                                                    (recordid == 1) 
                                                    ?
                                                        <>
                                                            <Grid item xs={2} className="m-0"><label className="font-weight-500">Lead</label></Grid>
                                                            <Grid item xs={10} className="m-0"><label className="text-hint" style={{ fontSize: '12px' }}>{lead}</label></Grid>
                                                        </>

                                                    : (recordid == 2) ?

                                                        <>
                                                            <Grid item xs={2} className="m-0"><label className="font-weight-500">Contact</label></Grid>
                                                            <Grid item xs={10} className="m-0"><label className="text-hint" style={{ fontSize: '12px' }}>{contact}</label></Grid>

                                                            <Grid item xs={2} className="m-0"><label className="font-weight-500">Company</label></Grid>
                                                            <Grid item xs={10} className="m-0"><label className="text-hint" style={{ fontSize: '12px' }}>{company}</label></Grid>
                                                        </>
                                                    : (recordid == 3) ?

                                                        <>
                                                            <Grid item xs={2} className="m-0"><label className="font-weight-500">Company</label></Grid>
                                                            <Grid item xs={10} className="m-0"><label className="text-hint" style={{ fontSize: '12px' }}>{company}</label></Grid>
                                                        </>
                                                    : (recordid == 4) ?

                                                        <>
                                                            <Grid item xs={2} className="m-0"><label className="font-weight-500">Contact</label></Grid>
                                                            <Grid item xs={10} className="m-0"><label className="text-hint" style={{ fontSize: '12px' }}>{contact}</label></Grid>

                                                            <Grid item xs={2} className="m-0"><label className="font-weight-500">Company</label></Grid>
                                                            <Grid item xs={10} className="m-0"><label className="text-hint" style={{ fontSize: '12px' }}>{company}</label></Grid>

                                                            <Grid item xs={2} className="m-0"><label className="font-weight-500">Opportunity</label></Grid>
                                                            <Grid item xs={10} className="m-0"><label className="text-hint" style={{ fontSize: '12px' }}>{opportunities}</label></Grid>
                                                        </>
                                                    : 
                                                        <></>
                                                }
                                            </>
                                    }
                                    
                                    
                                    

                                </Grid>
                                <div className="text-hint font-weight-500 mt-20"><Icon style={{fontSize:'12px'}}>autorenew</Icon>In Progress</div>
                            </div>
                            <DialogActions className="dialog-action">
                                <Button onClick={handleClose} color={"primary"} >Cancel</Button>
                                {(isSummary == true) 
                                    ? 
                                    <></>
                                    : 
                                    <Button variant="contained" color={"primary"} onClick={this.revertImportData}>Revert This Import</Button>
                                }
                                
                                
                            </DialogActions>
                        </>
                    } 
                </>
                
            </Dialog>
        );
    }
}
const mapStateToProps = (state) => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
});
const mapDispatchToProps = (dispatch) => {
    return {
        revertImportData: (client, request) => dispatch(actions.revertImportData(client, request)),
        getRevertDetail: (client, request) => dispatch(actions.getRevertDetail(client, request)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(RevertImportDialog);
