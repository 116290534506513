import React, { Component } from "react";
import PropTypes from 'prop-types';
import {
    FormControlLabel, Checkbox, TableContainer, Table, TableBody, TableCell, TableHead,
    TableRow, withStyles, makeStyles, Paper, DialogActions, Button, IconButton, Icon, Menu, MenuItem, Grid, Dialog, DialogTitle, Typography, DialogContent, InputLabel
} from "@material-ui/core";

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.5rem",
        color: 'rgba(0, 0, 0, 0.6)'
    }
}))(Icon);

const Headercolumns = [
    { id: '1', label: 'Column Headers', minWidth: 170, align: 'left' },
    { id: '2', label: 'Active Columns', minWidth: 100, align: 'right' },
];

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
});
class HiddenColumns extends Component {
    state = {
        commentid: '',
        Commentoptions: '',
        showCommentoptions: false,
        columns: [],
        hiddencolumns: [],
        setColums: [],
    };

    componentDidMount() {
        let hiddnes = this.props.allData.state.defaultHiddenColumnNames;
        if (this.props.columns) {
            this.setState({ columns: this.props.columns })
        }
        if (this.props.allData.state.defaultHiddenColumnNames) {
            this.setState({ hiddencolumns: hiddnes })
            // setColums : this.props.allData.state.defaultHiddenColumnNames
        }       
    };

    componentWillReceiveProps(props) {
        if (props.allData.state.defaultHiddenColumnNames) {
            this.setState({ hiddencolumns: props.allData.state.defaultHiddenColumnNames })
        }
    };

    handleClick = (event) => {
        this.setState({ Commentoptions: event.target, showCommentoptions: true });
    };

    handleCloseOptions = (event) => {
        this.setState({ Commentoptions: null, showCommentoptions: false });

        // let hiddens = this.state.setColums;
        // this.props.allData.setState({ defaultHiddenColumnNames: hiddens });
        // this.props.allData.handleCLoseColumnHidden(hiddens);
    };

    handleMenuClick = (e, value) => {
        let hiddens = this.state.hiddencolumns;
        // var promise = new Promise((resolve, reject) => {
        if (e.target.checked == true) {
            let key = hiddens.indexOf(value);
            if (key != -1) {
                hiddens.splice(key, 1);
            }
        }
        if (e.target.checked == false) {
            if (hiddens.indexOf(value) == -1) {
                hiddens.push(value);
            }
        }
        
        this.setState({ hiddencolumns: hiddens })
        //     resolve(1)
        // })
        // promise.then(result => {
        // });
    }

    updateSetting = () => {
        let hiddens = this.state.hiddencolumns;
        // this.props.allData.setState({ defaultHiddenColumnNames: hiddens });
        this.props.allData.handleHiddensMain(hiddens);
    }


    render() {
        let { columns, hiddencolumns } = this.state;


        return (
            <Grid item className="ml-auto">
                <IconButton size="small"
                    aria-label="More"
                    // aria-owns={this.state.showCommentoptions ? "long-menu" : undefined}
                    aria-haspopup="true"
                    onClick={this.handleClick}
                    className="button_size"
                >
                    <Icon>visibility_off</Icon>
                </IconButton>
                <Dialog className="crm_dialog_setting" maxWidth={'lg'} open={this.state.showCommentoptions} onClose={this.handleCloseOptions} disableBackdropClick >
                    <Grid container justify="space-between">
                        <Typography className="p-10 font-weight-500" variant="h6" color="inherit" style={{ margin: "20px" }}>
                            Select the columns you want to display in this list
                            </Typography>
                        <div className="hover_show" style={{ margin: "10px" }} >
                            <IconButton aria-label="Delete" size="small" onClick={this.handleCloseOptions}>
                                <IconSmall style={{ fontSize: '18px' }}>clear</IconSmall>
                            </IconButton>
                        </div>
                    </Grid>
                    <DialogContent >
                        <Paper style={{ width: '100%' }} >
                            <TableContainer style={{ maxHeight: '440' }}>
                                <Table stickyHeader={true} aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {Headercolumns.map((headercolumn) => (
                                                <TableCell
                                                    key={headercolumn.id}
                                                    align={headercolumn.align}
                                                    style={{ minWidth: headercolumn.minWidth }}
                                                >
                                                    {headercolumn.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(columns && columns.length > 0) && columns.map((column, i) => (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={i} >
                                                <TableCell component="th" scope="row" align={"left"}>{column.title}</TableCell>
                                                <TableCell align={"right"}>
                                                    <Checkbox
                                                        color={"secondary"}
                                                        disabled={column.title == "Name" ? true : false}
                                                        name={column.name}
                                                        id={column.name}
                                                        checked={(hiddencolumns.indexOf(column.name) == -1) ? true : false}
                                                        onChange={(event, value) => this.handleMenuClick(event, column.name)}
                                                        value={column.name}

                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </DialogContent>
                    <DialogActions className="dialog-action" style={{ margin: "20px 0 0 0" }}>
                        <Button variant="contained" onClick={this.handleCloseOptions}>Cancel</Button>
                        <Button variant="contained" onClick={this.updateSetting} color="primary" className="btn_Primary" type="submit">Save</Button>
                    </DialogActions>
                </Dialog>

                {/* <Menu
                    id="long-menu"
                    anchorEl={this.state.Commentoptions}
                    open={this.state.showCommentoptions}
                    onClose={this.handleCloseOptions}
                    PaperProps={{
                        // style: {
                        //     width: 200
                        // }
                    }}
                >
                    {(columns && columns.length > 0) && columns.map((column,i)=>(
                        <MenuItem className="m-0 pt-0 pb-0" key={i} disabled={column.name == "name" ? true : false}>
                            <FormControlLabel
                                value={column.name}
                                control={
                                    <Checkbox 
                                        color={"secondary"}
                                        disabled={column.name=="name" ? true : false}
                                        // className={"crm_log_checkbox_filter "}
                                        name={column.name}
                                        id={column.name}
                                        checked={(hiddencolumns.indexOf(column.name) == -1) ? true : false}
                                        onChange={(event, value) => this.handleMenuClick(event, column.name)}
                                        value={column.name}
                                        
                                    />
                                }
                                label={column.title}
                                labelPlacement="end"
                            />
                        </MenuItem>
                    ))}
                    <button>hello</button>
                </Menu> */}
            </Grid>
        )
    }
}

export default HiddenColumns;
