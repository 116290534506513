import React, { Component } from "react";
import { Grid, Card } from "@material-ui/core";
import OpportunityTab from "./OpportunityTab";
import * as actions from '../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';
import moment from 'moment';//moment
import 'moment-timezone';//timezone
import FontAwesome from "react-fontawesome";

class ProfileCenter extends Component {
  state = {
    opportunities_id: "",
    interaction: 0,
    dayinstage: 0,
    lastcontacted: "--",
    opportunitystatus: "",
    inactivedays: 0,
    dateformat: 'MM/DD/YYYY',
    timeZone: 'America/New_York',
    allSettings: '',
    SyncStatus: false,
    SyncTime: '',
    SyncData: [],
  }

  componentDidMount() {

    this.setState({
      opportunities_id: parseInt(this.props.opportunities_id)
    })

    let userData = JSON.parse(localStorage.getItem('user_data_session'))
    let userprefparams = { userid: userData.id }
    this.props.getUserSetting(this.props.apolloClient.client, userprefparams);
    this.props.getSyncTime(this.props.apolloClient.client);
    this.props.getEmailSetting(this.props.apolloClient.client);
  }

  componentWillReceiveProps(props) {
    if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
      let usersetting = props.settingReducer.userSettingArray.data;
      let format = usersetting.dateformat;
      let timezone = usersetting.timezone;
      this.setState({ dateformat: format, timeZone: timezone })
    }

    if (props.opportunityReducer.opportunityFieldData && props.opportunityReducer.opportunityFieldData.data) {
      var fielddata = props.opportunityReducer.opportunityFieldData.data;
      if (fielddata[0].opportunitiesdetail && fielddata[0].opportunitiesdetail.length > 0) {
        let interaction = fielddata[0].opportunitiesdetail[0].opportunities.interactions ? fielddata[0].opportunitiesdetail[0].opportunities.interactions : 0
        let lastcontacted = fielddata[0].opportunitiesdetail[0].opportunities.lastcontacted ? moment.unix(fielddata[0].opportunitiesdetail[0].opportunities.lastcontacted).tz(this.state.timeZone).format(this.state.dateformat) : '--'
        let inactivedays = fielddata[0].opportunitiesdetail[0].opportunities.inactivedays ? fielddata[0].opportunitiesdetail[0].opportunities.inactivedays : 0
        let dayinstage = fielddata[0].opportunitiesdetail[0].opportunities.dayinstage ? fielddata[0].opportunitiesdetail[0].opportunities.dayinstage : 0

        this.setState({ interaction: interaction, lastcontacted: lastcontacted, inactivedays: inactivedays, dayinstage: dayinstage })

        fielddata.map((field) => {
          if (field.fieldkey == "status" && field.opportunitiesdetail && field.opportunitiesdetail.length > 0) {
            (field.opportunitiesdetail) && this.setState({ opportunitystatus: field.opportunitiesdetail[0].values[0].value })
          }
        });
      }
    }

    if (props.settingReducer.emailsettings.data) {
      const mian_data = props.settingReducer.emailsettings.data
      this.setState({
        allSettings: mian_data,
      })
    }

    if (props.settingReducer.getsyncTime && props.settingReducer.getsyncTime.data) {
      let data = props.settingReducer.getsyncTime.data;
      if (data !== this.state.SyncData) {
        let time = moment.unix(data.emailsynctime).format("YYYY-MM-DD HH:mm");
        let now = moment(time).fromNow();
        if (now !== this.state.SyncTime) {
          this.setState({ SyncData: data, SyncTime: now, SyncStatus: false });
        }
      }
    }

  }

  handleSync = () => {
    this.setState({ SyncStatus: true });
    // this.props.dataResync(this.props.apolloClient.client);

    let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
    let pinParams = {
      recordid: parseFloat(this.state.opportunities_id),
      recordtypeid: 4,
      emailid: emailParam,
      logtypeid: [1, 2, 3, 4, 15, 20, 21, 5, 100],
      activitypeid: [1, 2, 3, 4, 5, 6]
    }
    this.props.dataResync(this.props.apolloClient.client, pinParams);
  };

  render() {
    let user_data = JSON.parse(localStorage.getItem('user_data_session'));
    let IsGoogleLogin = user_data.googlestatus === true && this.state.allSettings.setting_one && this.state.allSettings.setting_one === 1 ? true : false;
    return (
      <div className="lead_content">
        {IsGoogleLogin ?
          this.state.SyncStatus ?
            <div className="text-hint text-small ml-8 mr-8 mb-8" style={{ cursor: 'pointer' }}>
              Syncing....
            <FontAwesome className={"fa fa-refresh fa-spin"} />
            </div>
            :
            <div className="text-hint text-small ml-8 mr-8 mb-8" style={{ cursor: 'pointer' }} onClick={this.handleSync}>
              Synced {this.state.SyncTime}
              <FontAwesome className={"fa fa-refresh"} style={{ margin: '3px' }} />
            </div>
          : ''}
        <Grid container spacing={2} className="text-center">
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Card elevation={3} className="p-16">
              <span className="font-weight-500 font-size-20 text-primary">{this.state.interaction}</span>
              <p className="m-0 text-gray">Interactions</p>
            </Card>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Card elevation={3} className="p-16">
              <span className="font-weight-500 font-size-20 text-primary">{this.state.lastcontacted}</span>
              <p className="m-0 text-gray">Last contacted</p>
            </Card>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Card elevation={3} className="p-16">
              <span className="font-weight-500 font-size-20 text-primary">{this.state.inactivedays}</span>
              <p className="m-0 text-gray">Inactive days</p>
            </Card>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Card elevation={3} className="p-16">
              <span className="font-weight-500 font-size-20 text-primary">{this.state.dayinstage}</span>
              <p className="m-0 text-gray">Days in Stage</p>
            </Card>
          </Grid>
        </Grid>
        <OpportunityTab opportunities_id={this.props.opportunities_id} IsGoogleLogin={IsGoogleLogin} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    opportunityReducer: state.opportunityReducer,
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient
  };
};
const mapDispatchToProps = dispatch => {
  return {
    //getLeadLogList: (client, opportunitiesid) => dispatch(actions.getLeadLogList(client, opportunitiesid)),
    getUserSetting: (client, data) => dispatch(actions.getUserSetting(client, data)),
    getSyncTime: (client) => dispatch(actions.getSyncTime(client)),
    dataResync: (client, req) => dispatch(actions.dataResync(client, req)),
    getEmailSetting: (client) => dispatch(actions.getEmailSetting(client)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ProfileCenter);