import React, { Component } from "react";
import {
  Dialog, IconButton, Button, Icon, Tooltip, Grid,
  FormControl, Select, InputLabel, Menu, MenuItem, List,
  ListItem, ListItemText, DialogActions, CircularProgress,
  Chip, TextField, withStyles
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { createDynamicFields } from './CreateFieldsDynamic'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';
const TooltipNew = withStyles((theme) => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);
class CreateNewCompanyDialog extends Component {
  state = {
    lastname: "",
    city: "",
    street: "",
    addressState: "",
    zipcode: "",
    country: "",
    countryListArray: [],
    companyCurrencyData: [],
    focusValue: false,
    fieldsList: [],
    fieldsObj: {
      websites: {
        fieldid: "32",
        values: [
          { srno: "1", id: null, value: "" }
        ]
      },
      social: {
        fieldid: "35",
        values: [
          { srno: "1", id: null, value: "" },
        ]
      },
      phones: {
        fieldid: "30",
        values: [
          { srno: "1", id: null, value: "" },
        ]
      },
    },
    tags: [],
    systemTagsList: [],
    usersListArray: [],
    selectedDate: new Date(),
    userSettings: "",
    isDataLoading: true,
    dataFromSidebar: "",
    teamvisible: true,
    hasActiveTeamPermission: false,
    userListForVisibility: [],
    selectedUserList: [],
    isEditVisibility: false,
    selectedTeamList: [],
    teamList: [],
    teamVisibilityLabel: "",
    teamUser: [],
  };

  componentWillMount() {
    // ^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$
    ValidatorForm.addValidationRule('emaildomain', (value) => {
      if (value && value.length && value.length > 0) {
        var specialChar = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
        if (!specialChar.test(value)) {
          return false;
        }
      }
      return true;
    });

    ValidatorForm.addValidationRule('IsURLValid', (value) => {
      if (value && value.length && value.length > 0) {
        var specialChar = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
        if (!specialChar.test(value)) {
          return false;
        }
      }
      return true;
    });

    let val = { typeId: 3 }
    let userData = JSON.parse(localStorage.getItem('user_data_session'))
    let userid = { userid: userData.id }
    this.props.getUserSetting(this.props.apolloClient.client, userid);
    this.props.customFieldList(this.props.apolloClient.client, val);
    this.props.getUserList(this.props.apolloClient.client);
    this.props.getCountryList(this.props.apolloClient.client);
    this.props.getCompanyCurrency(this.props.apolloClient.client);
    this.props.getTagsList(this.props.apolloClient.client);
    this.props.getTeam(this.props.apolloClient.client);
    this.props.getLoginUserPermission(this.props.apolloClient.client);
    this.setState({ isFromTopBar :  this.props.isFromTopBar });

    //if compnay create from opportunity sidebar then set name
    if (this.props.name) {
      this.setState({
        fieldsObj: {
          ...this.state.fieldsObj,
          ["name"]: { fieldid: "29", companyvalue: this.props.name }
        }
      })
    }
    if (this.props.opportunitiesid) {
      localStorage.setItem('opportunitiesid', this.props.opportunitiesid)
    }
  }

  AllMemberDisplay = () => {
    let teamVisibilityLabel = "";
    let userCount = 0;
    let teamCount = 0;
    let teamUser = [];
    let usersListArray = this.props.settingReducer.usersListArray.data ? this.props.settingReducer.usersListArray.data : []
    usersListArray.length > 0 && usersListArray.map((user) => {
      if (user.teamlist.length > 0) {
        let userdata = { userteam: [], id: "", username: "" }
        teamCount = teamCount < user.teamlist.length ? user.teamlist.length : teamCount;
        user.teamlist.map((u) => { userdata.userteam.push(u.team.teamname) })
        userdata.id = user.id;
        userdata.username = user.name;
        userCount = userCount + 1;
        teamUser.push(userdata)
      }
    })
    teamVisibilityLabel = userCount + " users from " + teamCount + " team ";
    this.setState({
      teamVisibilityLabel: teamVisibilityLabel,
      teamUser: teamUser
    }, () => this.forceUpdate())
  }
  selectedUserDetails = (mainuser) => {
    let teamVisibilityLabel = "";
    let teamUser = [];
    let userdata = { userteam: [], id: "", username: "" }
    mainuser.teamlist.map((u) => { userdata.userteam.push(u.team.teamname) })
    userdata.id = mainuser.id;
    userdata.username = mainuser.name;
    teamUser.push(userdata)
    teamVisibilityLabel = "1 users from " + mainuser.teamlist.length + " team ";
    this.setState({
      teamVisibilityLabel: teamVisibilityLabel,
      teamUser: teamUser
    }, () => this.forceUpdate())
  }
  themSelves = (selectedOwner) => {
    let teamVisibilityLabel = "";
    let userCount = 0;
    let teamCount = 0;
    let teamUser = [];
    let usersListArray = this.props.settingReducer.usersListArray.data ? this.props.settingReducer.usersListArray.data : []
    usersListArray.length > 0 && usersListArray.map((user) => {
      if (user.teamlist.length > 0) {
        if (user.teamlist[0].teammanager === true || (user.email === selectedOwner.email)) {
          let userdata = { userteam: [], id: "", username: "" }
          teamCount = teamCount < user.teamlist.length ? user.teamlist.length : teamCount;
          user.teamlist.map((u) => { userdata.userteam.push(u.team.teamname) })
          userdata.id = user.id;
          userdata.username = user.name;
          userCount = userCount + 1;
          teamUser.push(userdata)
        }
      }
    })
    teamVisibilityLabel = userCount + " users from " + teamCount + " team ";
    this.setState({
      teamVisibilityLabel: teamVisibilityLabel,
      teamUser: teamUser
    }, () => this.forceUpdate())
  }
  getUserPermission = () => {
    if (this.props.settingReducer.userPermissionDetails.data) {
      let data = this.props.settingReducer.userPermissionDetails.data.data;
      if (data.teampermissionstatus === true &&
        data.visibilityaccess.length > 0) {
        let cmpdt = data.visibilityaccess.find(function (e) { return e.sourcetype === 3 });
        if (cmpdt.isaccess) {
          if (cmpdt.viewoption === 1) {
            this.AllMemberDisplay();
          }
          if (cmpdt.viewoption === 2 || cmpdt.viewoption === 3) {
            var selectedOwner = this.state.fieldsObj["owner"]
            if (selectedOwner && selectedOwner.values) {
              if (selectedOwner.values.teamlist.length > 0) {//if member in any team 
                if (selectedOwner.values.teamlist[0].teammanager === false) {//owner is not  teammanager
                  if (cmpdt.viewoption === 2) {
                    this.AllMemberDisplay();
                  } else {
                    this.themSelves(selectedOwner.values);
                  }
                } else {//owner is teammanager   
                  this.selectedUserDetails(selectedOwner.values);
                }
              } else { // not in any team 
                this.setState({ teamVisibilityLabel: "0 users", teamUser: [] }, () => this.forceUpdate())
              }
            } else {
              this.setState({ teamVisibilityLabel: "0 users", teamUser: [] }, () => this.forceUpdate())
            }
          }
        } else {//cmpdt.isaccess === false
          this.setState({ teamVisibilityLabel: "0 users", teamUser: [] }, () => this.forceUpdate())
        }
        this.setState({
          hasActiveTeamPermission: true,
          viewoption: cmpdt.viewoption,
        })
      } else {
        this.setState({ teamvisible: false })
      }
    }
  }

  componentWillReceiveProps(props) {
    this.getUserPermission();
    if (props.settingReducer.teamList.data && this.state.teamList !== props.settingReducer.teamList.data) {
      const teamList = props.settingReducer.teamList.data;
      let data = [];
      teamList.map((v, i) => {
        data.push({ id: v.id, teamname: v.teamname })
      });
      this.setState({ teamList: data })
    }
    //storing user setting data in state
    if (props.settingReducer.userSettingArray.data && this.state.userSettings == "") {
      const settingData = props.settingReducer.userSettingArray.data;
    }
    if (props.dataFromSidebar) {
      this.setState({
        dataFromSidebar: props.dataFromSidebar,
      })
    }

    if (props.settingReducer.companyCustomFieldList.data && props.settingReducer.companyCustomFieldList.is_loaded) {
      const customFields = props.settingReducer.companyCustomFieldList.data;
      props.settingReducer.companyCustomFieldList.is_loaded = false
      this.setState({ fieldsList: customFields, isDataLoading: false })
      var vkey = this.findWithAttr(customFields, "id", "38");
      var visibilitydefault = this.findWithAttr(customFields[vkey].options, "is_default", true);

      var contactTypeKey = this.findWithAttr(customFields, 'id', "33");
      this.setState({
        fieldsObj: {
          ...this.state.fieldsObj,
          ["contacttype"]: { fieldid: "33", values: customFields[contactTypeKey].options[0] },
          ["visibility"]: { fieldid: "38", values: customFields[vkey].options[visibilitydefault] }
        }
      })
      //setting selected date in date picker
      customFields.map((v, i) => {
        if (v.fieldtype.id === "8") {
          var data = {
            fieldid: v.id,
            values: this.state.selectedDate
          }
          this.setState({
            fieldsObj: {
              ...this.state.fieldsObj,
              [v.fieldkey]: data
            }
          })
        }
      })

    }
    if (props.settingReducer.countryListGql.data) {
      const countryies = props.settingReducer.countryListGql.data;
      this.setState({ countryListArray: countryies })
    }
    if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
      const usersListArray = props.settingReducer.usersListArray.data;
      let userListForVisibility = [];
      usersListArray.map((data, i) => {
        userListForVisibility.push({ id: data.id, name: data.name })
      })
      let userData = JSON.parse(localStorage.getItem('user_data_session'))
      var companyOwnerKey = this.findWithAttr(usersListArray, "email", userData.email);
      this.setState({
        usersListArray: usersListArray,
        userListForVisibility: userListForVisibility,
        fieldsObj: {
          ...this.state.fieldsObj,
          ["owner"]: { fieldid: "31", values: usersListArray[companyOwnerKey] }
        }
      })
    }
    if (props.notification && props.notification.status === true) {
      // props.handleCloseCompany();
      props.clearNotification();
    }

    if (props.settingReducer.systemTagsList.data) {
      const tagsData = props.settingReducer.systemTagsList.data;
      this.setState({ systemTagsList: tagsData })
    }

    if (props.settingReducer.companyCurrencyData.data != null) {
      var cData = props.settingReducer.companyCurrencyData.data;
      this.setState({ companyCurrencyData: cData[0] });
    }
  }

  addNewField = (key, option) => {
    let data = this.state.fieldsObj[key];
    data.values.push({
      srno: (data.values.length + 1).toString(),
      id: option.id,
      value: ""
    })

    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [key]: data
      }
    })
  }

  handleAddMoreField = (name, key, index, value) => {
    let data = this.state.fieldsObj[key];
    data.values[index][name] = value;

    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [key]: data
      }
    }, () => this.forceUpdate())
  }

  handleNormalFields = (event) => {
    this.setState({ [event.target.name]: event.target.value, });
    let namekey = event.target.name;
    if (namekey === 'street' || namekey === 'city' || namekey === 'addressState' || namekey === 'zipcode' ) {
      let name = event.target.name === 'addressState' ? 'state' : event.target.name;
      let dataleadvalue = {
        fieldid: "36", companyvalue: {
          [name]: this.state[namekey]
        }
      }
      this.setState({
        fieldsObj: {
          ...this.state.fieldsObj,
          ['address']: dataleadvalue,
        },
      });
    }
  };

  handleCountryChange = (event, values) => {
    console.log('Values :', values)
    let dataleadvalue = {
      fieldid: "36", companyvalue: {
        ['country']: values.name
      }
    }
    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        ['address']: dataleadvalue,
      },
    });
    this.setState({ country: values });
  };

  focusInValue = () => {
    this.setState({ focusValue: true })
  };

  focusOutValue = () => {
    this.setState({ focusValue: false })
  };

  currencyDropdownClose = () => {
    this.setState({ focusValue: false })
  };

  handleChange = (val, name) => {
    if (val.fieldid === "38") {
      if (val.values.id === 2) {
        this.setState({ selectedTeamList: [{}] }, () => this.forceUpdate())
      } else if (val.values.id === 3) {
        this.setState({ selectedUserList: [{}] }, () => this.forceUpdate())
      }
    }
    var key = this.findWithAttr(this.state.fieldsList, 'id', val.fieldid);
    if (key != -1 && this.state.fieldsList[key].fieldtype.id != "4" && this.state.fieldsList[key].fieldtype.id != "8") {
      if (this[name]) {
        this[name].validate(val.value);
      }
    }
    //return
    if (val.fieldid === "31") {
      if ((this.state.viewoption === 2 || this.state.viewoption === 3) && val.values === null) {
        this.setState({
          teamVisibilityLabel: "0 users",
          teamUser: []
        }, () => this.forceUpdate())
      } else {
        this.setState({
          fieldsObj: {
            ...this.state.fieldsObj,
            [name]: val,
          }
        }, () => this.getUserPermission())
      }
    }
    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [name]: val
      }
    })

  };
  handleBlur = (event, ref) => {
    console.log("this[ref]", this[ref]);
    if (this[ref].props.id == "tagInputId1") {
      setTimeout(() => {
        document.getElementById("tagInputId1").value = "";
      }, 10)
    }
    this[ref].validate(event.target.value);
  }
  handleFormSubmit = () => {
    let submitData = JSON.parse(JSON.stringify(this.state.fieldsObj));
    submitData = Object.values(submitData).map((row) => {
      if (row.fieldid == "32" || row.fieldid == "35" || row.fieldid == "30") {
        if (row.values.length == 1 && (row.values[0].id == null || row.values[0].value == "")) {
          row.values = [{ "fieldid": row.fieldid, value: [] }]
        }
        if (row.values.length > 1) {
          row.values.map((val, i) => {
            if (val.value == "") {
              row.values.splice(i, 1);
            }
          })
        }
        if (row.values.length > 0) {//adding selected dropdown val as lable
          row.values.map((val, i) => {
            if (val.id) {
              var fKey = this.findWithAttr(this.state.fieldsList, 'id', row.fieldid);
              if (fKey != -1) {
                var optKey = this.findWithAttr(this.state.fieldsList[fKey].options, 'id', val.id);
                row.values[i].label = this.state.fieldsList[fKey].options[optKey].optionvalue
              }
            }
          })
        }

        if (row.fieldid == "30") {
          if (row.values.length === 1) {
            if (row.values[0].fieldid) {
              row.values = []
            } else {
              if (row.values.length > 0) {
                var foundMobile = this.findWithAttr(row.values, 'id', "30");
                if (foundMobile != -1) {
                  var index = 1;
                  row.values.map((val, i) => {
                    if (val.id == "30") {
                      row.values[i].srno = "1"
                    } else {
                      row.values[i].srno = (index + 1).toString()
                    }
                    index++;
                  })
                }
              }
            }
          }
        }
        if (row.fieldid == "32" || row.fieldid == "35" || row.fieldid == "30") {//make value blank array if notfill in this field
          if (row.values.length === 1) {
            if (row.values[0].fieldid) {
              row.values = []
            }
          }
        }
      }

      if (row.fieldid === "33" || row.fieldid === "38") {//38-visibility  33 contact type
        if (row.fieldid === "38") { //Visibility,
          let filterVisibleList = [];
          if (row.values.id === 2) {
            filterVisibleList = this.state.selectedTeamList.length > 0 ? this.state.selectedTeamList.filter(el => Object.keys(el).length) : []
          } else if (row.values.id === 3) {
            filterVisibleList = this.state.selectedUserList.length > 0 ? this.state.selectedUserList.filter(el => Object.keys(el).length) : []
          }
          row.values = [
            {
              srno: "1",
              id: row.values.id,
              value: row.values.name,
              visiblelist: filterVisibleList
            }
          ];
        } else {
          row.values = [{ srno: "1", id: row.values.id, value: row.values.name }]
        }
      }
      var key = this.findWithAttr(this.state.fieldsList, 'id', row.fieldid);
      if (this.state.fieldsList[key].fieldtype.id == "4" && this.state.fieldsList[key].is_default == false) {
        row.values = [{ srno: "1", id: row.values.id, value: row.values.optionvalue }]
      }
      if (this.state.fieldsList[key].fieldtype.id == "5" && this.state.fieldsList[key].is_default == false) {
        if (row.values.length > 0) {
          var ar = [];
          row.values.map((val, i) => {
            ar.push({ srno: (i + 1).toString(), id: val.id, value: val.optionvalue })
          })
        }
        row.values = ar
      }

      //validating date fiels
      if (this.state.fieldsList[key].fieldtype.id == "8") {//date field
        if (this.state.fieldsList[key].recordcreationdefaultvalue == 2 && row.values == null) {
          return false
        } else if (this.state.fieldsList[key].recordcreationdefaultvalue != 2 && row.values == null) {
          row.companyvalue = "";
          delete row.values;
        } else if (row.values == "Invalid Date") {
          return false
        } else {
          // row.companyvalue = moment(row.values).format("X");

          // let date_test = moment(row.values).format('YYYY-MM-DD HH:mm');
          // var logdate = moment.tz(date_test, this.state.userSettings.timezone).format();
          // row.companyvalue = moment.utc(logdate).format('X');
          var dt = moment(row.values).format('YYYY-MM-DD')
          row.companyvalue = dt;

          delete row.values;
        }
      }

      if (row.fieldid == "39") {//for tag
        if (row.values.length > 0) {
          var objArr = [];
          row.values.map((val, k) => {
            var foundKey = this.findWithAttr(this.state.systemTagsList, 'tagname', val);
            var tagId = "0";
            if (foundKey !== -1) {
              tagId = this.state.systemTagsList[foundKey].id
            }
            objArr.push({ "srno": (k + 1).toString(), value: val.toLowerCase(), id: tagId })
          })
          row.values = objArr
        }
      }

      if (row.fieldid == "36") {//address
        row.companyvalue = {
          street: this.state.street,
          city: this.state.city,
          state: this.state.addressState,
          zipcode: this.state.zipcode,
          country: this.state.country.name,
        }
      }
      if (row.fieldid == "31") {//owner value set
        if (row.values) {
          row.values = [{ "srno": "1", id: row.values.id, value: row.values.email, name: row.values.name }];
        } else {
          row.values = [];
        }
        delete row.companyvalue;
      }
      return row;
    })


    //not to submit if any field is false
    var allowToSubmit = true
    submitData.map((row) => {
      if (!row) {
        allowToSubmit = false
      }
    })

    let list = {
      filterdata: this.props.allstates.filterdata,
      list: {
        limit: this.props.allstates.pageSize,
        pageno: 1,
        searchtext: this.props.allstates.searchText,
        sorttype: this.props.allstates.sorttype,
        type: "list",
        fieldId: this.props.allstates.fieldId,
        columnname: this.props.allstates.column_name
      },
      filterid: 1
    };

    console.log("submitData", submitData);
    console.log("list", list);
    //return
    if (allowToSubmit) {
      let req = {
        companydata: submitData,
        listParams: list,
        dataFromSidebar: this.state.dataFromSidebar,
        teamvisible: this.state.teamvisible,
        isFromTopBar : this.state.isFromTopBar
      }
      this.props.handleCloseCompany();
      this.props.saveCompanyRecDetail(this.props.apolloClient.client, req);
    }
  };

  handleDateChange = date => {
    this.setState({ selectedDate: date });
  };

  arrDifference = (arr1, arr2) => {
    var arr = [];
    arr1 = arr1.toString().split(',').map(Number);
    arr2 = arr2.toString().split(',').map(Number);
    // for array1
    for (var i in arr1) {
      if (arr2.indexOf(arr1[i]) === -1)
        arr.push(arr1[i]);
    }
    // for array2
    for (i in arr2) {
      if (arr1.indexOf(arr2[i]) === -1)
        arr.push(arr2[i]);
    }
    return arr.sort((x, y) => x - y);
  }

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }
  teamHandleChange = (index, value, name) => {
    let mianteam = this.state.teamList;
    let selectedTeamList = this.state.selectedTeamList
    let mianUsers = this.state.usersListArray;
    let selectedUserList = this.state.selectedUserList
    if (name === "team") {
      if (value !== null) {
        selectedTeamList[index] = value;
        if (mianteam.length + 1 > selectedTeamList.length) {
          selectedTeamList[index + 1] = {};
        }
      } else {
        selectedTeamList.splice(index, 1);
        if (selectedTeamList.length === 0) {
          selectedTeamList[0] = {};
        }
      }
    } else {
      if (value !== null) {
        selectedUserList[index] = { id: value.id, name: value.name }
        if (mianUsers.length + 1 > selectedUserList.length) {
          selectedUserList[index + 1] = {};
        }
      } else {
        selectedUserList.splice(index, 1);
        if (selectedUserList.length === 0) {
          selectedUserList[0] = {};
        }
      }
    }
    this.setState({
      selectedTeamList: selectedTeamList,
      selectedUserList: selectedUserList
    })
  }
  handleToggleEditVisibility = () => {
    this.setState({
      isEditVisibility: !this.state.isEditVisibility
    })
  }

  handleChangeVisibilityRadio = (value) => {
    if (value) {
      let customFields = this.state.fieldsList
      var vkey = this.findWithAttr(customFields, "id", "38");
      var visibilitydefault = this.findWithAttr(customFields[vkey].options, "is_default", true);
      this.setState({
        selectedTeamList: [],
        selectedUserList: [],
        fieldsObj: {
          ...this.state.fieldsObj,
          ["visibility"]: { fieldid: "38", values: customFields[vkey].options[visibilitydefault] },
        },
      });
    }
    this.setState({
      teamvisible: value === "true" ? true : false
    })
  }
  render() {
    let { openCompany, handleCloseCompany, handleMinimizeCompany, handleFullCompany, fullScreenCompany, minimizeScreenCompany } = this.props;
    return (
      <Dialog onClose={handleCloseCompany} open={openCompany} maxWidth="md" fullWidth={true} className={`crm-custom-bg-primary custom_dialog ${minimizeScreenCompany && fullScreenCompany ? 'alignitemsend-fullscreen' : ''} ${minimizeScreenCompany ? 'alignitemsend' : ''} ${fullScreenCompany ? 'fullscreen' : ''}`} disableBackdropClick>
        <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">
          <h4 className="m-0 text-white">Create New Company</h4>
          <div className="d-flex">
            <TooltipNew title={minimizeScreenCompany ? 'Normal Screen' : 'Minimize Screen'}>
              <IconButton onClick={handleMinimizeCompany}>
                <Icon className="text-white">{minimizeScreenCompany ? 'add' : 'remove'}</Icon>
              </IconButton>
            </TooltipNew>
            <TooltipNew title={fullScreenCompany ? 'Exit Full Screen' : 'Full Screen'}>
              <IconButton onClick={handleFullCompany}>
                <Icon className="text-white">{fullScreenCompany ? 'fullscreen_exit' : 'fullscreen'}</Icon>
              </IconButton>
            </TooltipNew>
            <TooltipNew title="Close">
              <IconButton onClick={handleCloseCompany}>
                <Icon className="text-white">clear</Icon>
              </IconButton>
            </TooltipNew>
          </div>
        </div>
        <ValidatorForm noValidate debounceTime={500} onSubmit={this.handleFormSubmit}>
          <div className={`${this.state.isDataLoading ? "dialog-body popupHeightCustom" : "dialog-body"}`}>
            <Grid container spacing={2}>
              {this.state.isDataLoading && <CircularProgress size={25} className="loaderPopupCenter" />}
              {!this.state.isDataLoading && createDynamicFields(this)}
            </Grid>
          </div>

          <DialogActions className="dialog-action">
            <Button variant="contained" onClick={handleCloseCompany}>Cancel</Button>
            <Button disabled={this.state.isDataLoading} variant="contained" type="submit" color="primary" className="btn_Primary" >Save</Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
  authReducer: state.authReducer
});

const mapDispatchToProps = dispatch => {
  return {
    getLoginUserPermission: (client) => dispatch(actionCreators.getLoginUserPermission(client)),
    getTeam: (client) => dispatch(actionCreators.getTeam(client)),

    customFieldList: (client, request) => { dispatch(actionCreators.customFieldList(client, request)) },
    saveCompanyRecDetail: (client, request) => { dispatch(actionCreators.saveCompanyRecDetail(client, request)) },
    getCountryList: (client) => { dispatch(actionCreators.getCountryList(client)) },
    clearNotification: () => dispatch(actionCreators.clearNotification()),
    getCompanyCurrency: (client) => dispatch(actionCreators.getCompanyCurrency(client)),
    getTagsList: (client) => dispatch(actionCreators.getTagsList(client)),
    getUserList: (client) => dispatch(actionCreators.getUserList(client)),
    getUserSetting: (client, data) => dispatch(actionCreators.getUserSetting(client, data)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNewCompanyDialog);
