import React, { Component } from "react";
import * as actions from '../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';
import { Icon, List, ListItem, Menu, ListItemText, MenuItem } from "@material-ui/core";

class LeadCreationRule extends Component {
    constructor(props) {
        super(props);

        this.state = {
            anchorEl : null,
            selectedIndex: 1,
            fieldId:"",
            options:[]
        };
    }
    componentDidMount()
    {
        const options = [];

        if (this.props.options.none != undefined)
        {
            if (this.props.disabled)
                this.setState({ selectedIndex : 1 })
            else
                this.setState({ selectedIndex: this.props.defaultChecked })
                
            options[1] = "None";
        }
        else
        {
            // options[1] = null;
        }
        if (this.props.options.required != undefined) {
            if (this.props.disabled)
                this.setState({ selectedIndex: 2 })
            else
                this.setState({ selectedIndex: this.props.defaultChecked })


            options[2] = "Required";
        }
        else {
            // options[2] = null;
        }
        if (this.props.options.readonly != undefined) {
            if (this.props.disabled)
                this.setState({ selectedIndex: 3 })
            else
                this.setState({ selectedIndex: this.props.defaultChecked })


            options[3] = "Read Only";
        }
        else {
            // options[3] = null;
        }
        
        this.setState({fieldId: this.props.fieldId, options: options })

    }
    componentWillReceiveProps(props) {
        
    }

    handleClickListItem = (event) => {
        this.setState({ anchorEl: event.currentTarget});
    }

    handleMenuItemClick = (event, index) => {
        this.setState({ selectedIndex: index })
        this.setState({ anchorEl: null }) 
        
        if (this.props.dropName == 'create')
        {
            let val = { 
                typeId: 1,
                editdefaultvalue: parseFloat(this.props.settingReducer.customFieldList.data[this.state.fieldId].recordeditdefaultvalue),
                creationdefaultvalue: parseFloat(index),
                generatedfieldid: this.props.settingReducer.customFieldList.data[this.state.fieldId].generatedfieldid,
            }
            
            this.props.updateCustomFieldRecord(this.props.apolloClient.client, val);
        }
        else
        {
            let val = {
                typeId: 1,
                editdefaultvalue: parseFloat(index),
                creationdefaultvalue: parseFloat(this.props.settingReducer.customFieldList.data[this.state.fieldId].recordcreationdefaultvalue),
                generatedfieldid: this.props.settingReducer.customFieldList.data[this.state.fieldId].generatedfieldid,
            }
            
            this.props.updateCustomFieldRecord(this.props.apolloClient.client, val);
        }
       
        
    }

    handleClose = () => {
        this.setState({ anchorEl: null })   
    }

    render() {
        
        const { selectedIndex, anchorEl, options } = this.state;

        return (
            <React.Fragment>
                <List className="dorpdown_menu">
                    <ListItem
                        aria-haspopup="true"
                        aria-controls="lock-menu"
                        disabled={this.props.disabled}
                        onClick={this.handleClickListItem}
                    >
                        <ListItemText className="dorpdown_menu_list"
                            secondary={options[selectedIndex]}

                        />
                        <Icon>arrow_drop_down</Icon>
                    </ListItem>
                </List>
                <Menu
                    id="lock-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                >
                    {
                        (this.props.disabled) ?
                            null
                            :
                            options.map((option, index) => (
                                <MenuItem
                                    key={option}
                                    selected={index === selectedIndex}
                                    onClick={event => this.handleMenuItemClick(event, index)}
                                >{option}
                                </MenuItem>
                            ))
                    }
                </Menu>
            </React.Fragment>

        )
    }
}

const mapStateToProps = state => {
    return {
        settingReducer: state.settingReducer,
        apolloClient: state.apolloClient
    };
};
const mapDispatchToProps = dispatch => {
    return {
        updateCustomFieldRecord: (client, request) => {
            dispatch(actions.updateCustomFieldRecord(client, request))
        }
    };
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(LeadCreationRule);
