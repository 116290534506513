import React, { Component } from "react";
import PropTypes from 'prop-types';
import { IconButton, Icon, Grid, withStyles, Menu, InputLabel, Tooltip, Typography, MenuItem, Avatar, Fab, Button, Tabs, Tab, Box, Dialog, DialogTitle, DialogActions } from "@material-ui/core";

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.3rem",
    }
}))(Icon);


class EditCommentMenu extends Component {
    state = {
        commentid: '',
        Commentoptions: '',
        showCommentoptions: false,
    };

    handleClick = (event) => {
        this.setState({ Commentoptions: event.target, showCommentoptions: true });
    }
    handleCloseOptions = (event) => {
        this.setState({ Commentoptions: null, showCommentoptions: false });
    }
    handleEditComment = () => {
        this.handleCloseOptions();
        this.props.allData.handleEditCommentMain(this.props.cmt.id, this.props.cmt.comment);
    }
    deletecomment = () => {
        this.handleCloseOptions();
        this.props.allData.deleteComment(this.props.cmt.id, this.props.type);
    }

    render() {
        return (
            <Grid item className="ml-auto">
                <IconButton size="small"
                    aria-label="More"
                    aria-owns={this.state.showCommentoptions ? "long-menu" : undefined}
                    aria-haspopup="true"
                    onClick={this.handleClick}
                    className="button_size"
                >
                    <Icon>more_vert</Icon>
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={this.state.Commentoptions}
                    open={this.state.showCommentoptions}
                    onClose={this.handleCloseOptions}
                    PaperProps={{
                        style: {
                            width: 200
                        }
                    }}
                >
                    <MenuItem onClick={this.handleEditComment}>
                        <IconButton aria-label="Delete" size="small" className="hover_show  mr-10">
                            <IconSmall>edit</IconSmall>
                        </IconButton> Edit Comment
                    </MenuItem>
                    <MenuItem onClick={() => this.deletecomment()}>
                        <IconButton aria-label="Delete" size="small" className="hover_show  mr-10">
                            <IconSmall>delete</IconSmall>
                        </IconButton> Delete Comment
                    </MenuItem>
                </Menu>
            </Grid>
        )
    }
}
export default EditCommentMenu