import React, { Component } from "react";
import { Grid, } from "@material-ui/core";

import { connect } from "react-redux";
import { EditorState, convertToRaw } from 'draft-js';
import createSingleLinePlugin from 'draft-js-single-line-plugin';
import Editor from 'draft-js-plugins-editor';
import createMentionPlugin, { defaultSuggestionsFilter } from 'draft-js-mention-plugin';
import { editorStateToJSON, editorStateFromRaw } from "megadraft-denistsuman/lib/utils";
var mentions = []

class EditComment extends Component {
    constructor(props) {
        super(props);
        this.mentionPlugin = createMentionPlugin({
            mentions,
            entityMutability: 'IMMUTABLE',
            mentionPrefix: '@',
            supportWhitespace: true
        });

        this.singleLinePlugin = createSingleLinePlugin({
            stripEntities: false
        });
        this.state = {
            editorState: EditorState.createEmpty(),
            suggestions: mentions,
        };
    }
    componentWillMount() {
        if (this.props.settingReducer.usersListArray &&
            this.props.settingReducer.usersListArray.data) {
            let users = [];
            this.props.settingReducer.usersListArray.data.map((user) => {
                users.push({
                    id: user.id,
                    name: user.name,
                });
            });
            mentions = users;
        }
        if (this.props.data) {
            this.handleEditComment()
        }
    }

    onChange = (editorState) => {
        this.setState({ editorState });
        const content = editorState.getCurrentContent();
        const textvalue = content.getPlainText('\u0001');
        const raw = JSON.parse(editorStateToJSON(editorState));
        this.props.getEditData({ textvalue: textvalue, raw: raw })
    };

    onSearchChange = ({ value }) => {
        this.setState({
            suggestions: defaultSuggestionsFilter(value, mentions),
        });
    };

    focus = () => {
        this.editor.focus();
    };
    componentDidUpdate = (prevProps) => {
        if (prevProps.ClearTextbox !== this.props.ClearTextbox) {
            if (this.props.ClearTextbox) {
                let editorState = EditorState.createEmpty();
                this.setState({ editorState: editorState })
            }
        }
    }
    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }
    toCamelCase = (s) => {
        return s.replace(/([-_][a-z])/ig, ($1) => {
            return $1.toUpperCase()
                .replace('-', '')
                .replace('_', '');
        });
    };
    onAddMention = (mention) => {
        let that = this;
        setTimeout(() => {
            that.setState({ editorState: EditorState.moveFocusToEnd(that.state.editorState) })
        }, 10);
    }
    handleClose = () => {
        let editorState = EditorState.createEmpty();
        this.setState({ editorState: editorState })
        this.props.handleClose();
    }
    handleEditComment = () => {
        let entitymap = [];
        let offset = 0;
        let length = 0;
        this.props.data.mentionid && this.props.data.mentionid.map((id) => {
            let mentionuserkey = this.findWithAttr(mentions, 'id', id + "");
            if (mentionuserkey != -1) {
                entitymap.push({
                    "type": "mention",
                    "mutability": "SEGMENTED",
                    "data": {
                        "mention": mentions[mentionuserkey]
                    }
                });

            }
        });

        let exampleState = {
            blocks: [{
                "key": "fuq9m",
                "text": this.props.data.comment,
                "type": "unstyled",
                "depth": 0,
                "inlineStyleRanges": [],
                "entityRanges": [{ "offset": 0, "length": 0, "key": 0 }],
                "data": {}
            }],
            entityMap: entitymap
        };
        let editorState = editorStateFromRaw(exampleState);
        this.setState({ editorState })
    }
    render() {
        const { MentionSuggestions } = this.mentionPlugin;
        const plugins = [this.mentionPlugin, this.singleLinePlugin];
        return (
            <>
                <Grid item xs={12} style={{ "minWidth": "200px", "maxWidth": "85%" }}
                    className={"crm-editor-mention"}
                    onClick={this.focus}>
                    <label style={{ fontSize: '12px' }} >Text</label>
                    <Editor
                        editorState={this.state.editorState}
                        onChange={this.onChange}
                        plugins={plugins}
                        blockRenderMap={createSingleLinePlugin.blockRenderMap}
                        ref={(element) => { this.editor = element; }}
                        spellCheck
                        placeholder="Edit Comment"
                    />
                    <MentionSuggestions
                        onSearchChange={this.onSearchChange}
                        suggestions={this.state.suggestions}
                        onAddMention={this.onAddMention}
                    />
                </Grid>
            </>
        );
    }
}

const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
});

export default connect(mapStateToProps,)(EditComment);