import React, { Component } from "react";
import {
  Fab,
  Dialog,
  IconButton,
  Button,
  Icon,
  Tooltip,
  Grid,
  FormControl,
  Select,
  InputLabel,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  DialogActions,
  Chip,
  TextField,
} from "@material-ui/core";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";

import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

// draft js imports
import { MegadraftEditor, editorStateFromRaw, editorStateToJSON, MegadraftPlugin, insertDataBlock } from "megadraft-denistsuman";
import { convertFromHTML, EditorState, ContentState, Modifier, Entity, genKey, ContentBlock } from 'draft-js';
import LinkInput from "megadraft-denistsuman/lib/entity_inputs/LinkInput";
import icons from "megadraft-denistsuman/lib/icons"
import 'megadraft-denistsuman/dist/css/megadraft.css'
import { stateToHTML } from 'draft-js-export-html';
import { Map } from 'immutable';
import clearFormatting from 'draft-js-clear-formatting';


export const WorkflowRequiredFields = (data, actionindex, actionrequiredFields, conditionfields) => {
  // console.log(data, actionindex, actionrequiredFields, conditionfields,'sdhhdhd');
    var elements = actionrequiredFields;
    var fieldsArray = [];
    var requiredFields = [];
    var otherFields = [];
    var createdFields = [];

    let submitFieldData = data.state.submitFieldData;
    
    elements && elements.map((field, i) => {
      if (field.recordcreationdefaultvalue && field.recordcreationdefaultvalue == 2) {
        fieldsArray.push(field);
      }  
    });
    
    let indexOfCondition = actionindex;
    createdFields[indexOfCondition] = [];

    fieldsArray && fieldsArray.map((field, fieldindex) => {
    
        if (parseFloat(field.fieldtype.id) == 1 || parseFloat(field.fieldtype.id) == 2) {
          
          if (field.fieldkey == 'name' ) {
            let fieldtemp = field;
            if (parseFloat(field.id) == 1 || parseFloat(field.id) == 16){
              
              let names = data.state.token;
              if(data.state.alldraftFieldData && data.state.alldraftFieldData[indexOfCondition] && data.state.alldraftFieldData[indexOfCondition][fieldindex]){
                names = data.state.alldraftFieldData[indexOfCondition][fieldindex].names;
              }else{
                names = data.tokenIdGenerator(indexOfCondition, fieldindex, 0, fieldtemp);
              }
              // console.log(data.state.alldraftFieldData,'names');
              createdFields[indexOfCondition].push(
                <>
                  <Grid item xs={11}>
                    <Grid container spacing={2}>
                      <Grid item xs={4} className="text-hint pt-30">
                        <label className="font-weight-500">{field.fieldlabel}</label>
                      </Grid>
                      <Grid item xs={8} className="subject-editor workflow_editor">
                        <MegadraftEditor
                          data-fieldindex={indexOfCondition}
                          placeholder='Add First Name'
                          theme="white-thin"
                          editorState={names.firstname}
                          onChange={data.handleFirstNameChangeToken}
                          actions={[]}
                          sidebarRendererFn={(indexc, fieldind)=>data.getCustomTokenSidebar(indexOfCondition, fieldindex, 'firstname')}
                        />

                        <MegadraftEditor
                          data-fieldindex={indexOfCondition}
                          placeholder='Add Last Name'
                          theme="white-thin"
                          editorState={names.lastname}
                          onChange={data.handleLastNameChangeToken}
                          actions={[]}
                          sidebarRendererFn={(indexc, fieldind)=>data.getCustomTokenSidebar(indexOfCondition, fieldindex, 'lastname')}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )
            }else{
              let fielddata = data.state.token;
              if(data.state.alldraftFieldData && data.state.alldraftFieldData[indexOfCondition] && data.state.alldraftFieldData[indexOfCondition][fieldindex]){
                fielddata = data.state.alldraftFieldData[indexOfCondition][fieldindex].fieldvalue;
              }else{
                fielddata = data.tokenIdGenerator(indexOfCondition, fieldindex, 0, fieldtemp);
              }
             
              createdFields[indexOfCondition].push(
                <Grid item xs={11}>
                  <Grid container spacing={2}>
                    <Grid item xs={4} className="text-hint pt-30">
                      <label className="font-weight-500">{field.fieldlabel}</label>
                    </Grid>
                    <Grid item xs={8} className="subject-editor workflow_editor">
                      <MegadraftEditor
                        data-fieldindex={indexOfCondition}
                        placeholder='Add Name'
                        theme="white-thin"
                        editorState={fielddata}
                        onChange={data.handleChangeToken}
                        actions={[]}
                        sidebarRendererFn={(indexc, fieldind)=>data.getCustomTokenSidebar(indexOfCondition, fieldindex, 'normal')}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )
            }
          } else if (field.fieldkey == 'owner') {

            let fieldtemp = field;
            let fieldVal = -1;
            if(submitFieldData && submitFieldData.actiondetail && submitFieldData.actiondetail[indexOfCondition] && submitFieldData.actiondetail[indexOfCondition].details && submitFieldData.actiondetail[indexOfCondition].details[fieldindex]){
              fieldVal = submitFieldData.actiondetail[indexOfCondition].details[fieldindex].values;
            }

            createdFields[indexOfCondition].push(
              <Grid item xs={11}>
                <Grid container spacing={2}>
                  <Grid item xs={4} className="pt-30">
                    <label className="font-weight-500 text-hint ">{field.fieldlabel}</label>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl className="w-100 input_bx">
                      <Autocomplete
                        disableOpenOnFocus
                        forcePopupIcon={false}
                        name={fieldtemp.fieldkey}
                        options={data.state.usersListArray}
                        getOptionLabel={option => option.name}
                        value={fieldVal != -1 ? fieldVal : ''}
                        name={fieldtemp.fieldkey}
                        onChange={(event, value, field, index, indexoffield) => data.ActionOwnerChange(event, value, fieldtemp, indexOfCondition, fieldindex)}
                        id={field.id}
                        renderInput={params => (
                          <TextValidator
                            {...params}
                            variant="standard"
                            label={"Owner"}
                            fullWidth
                            ref={ref => field = ref}
                            name={fieldtemp.fieldkey}
                            value={fieldVal != -1 ? fieldVal : ''}
                            required={true}
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              
            )
          } else if(field.fieldkey == "primarycontact" && field.options && field.options.length > 0){
            let fieldtemp = field;
            let fieldVal = "";
            if(submitFieldData && submitFieldData.actiondetail && submitFieldData.actiondetail[indexOfCondition] && submitFieldData.actiondetail[indexOfCondition].details && submitFieldData.actiondetail[indexOfCondition].details[fieldindex] && submitFieldData.actiondetail[indexOfCondition].details[fieldindex].values){
              fieldVal = submitFieldData.actiondetail[indexOfCondition].details[fieldindex].values.id;
            }

            createdFields[indexOfCondition].push(
              <Grid item xs={11}>
                <Grid container spacing={2}>
                  <Grid item xs={4} className="text-hint  pt-30">
                    <label className="font-weight-500">{field.fieldlabel}</label>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl className="w-100 input_bx">
                      <SelectValidator
                        ref={ref => field = ref}
                        label={field.title}
                        value={fieldVal}
                        onChange={(event, field, index, indexoffield) => data.ActionDropChange(event, fieldtemp, indexOfCondition, fieldindex)}
                        // onChange={(event, field, index) => this.handleDropChange(event, fieldtemp, indexOfCondition)}
                        name={field.fieldkey}
                        id={field.id}
                        required={true}
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                      >

                        {field.options && field.options.map((item, key) =>
                          <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item.id}>{item.name}</MenuItem>
                        )}

                      </SelectValidator>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )
          }  else if(field.fieldkey == "company" && data.state.actionModule[indexOfCondition] == 4 && field.options && field.options.length > 0){
            let fieldtemp = field;
            let fieldVal = "";
            if(submitFieldData && submitFieldData.actiondetail && submitFieldData.actiondetail[indexOfCondition] && submitFieldData.actiondetail[indexOfCondition].details && submitFieldData.actiondetail[indexOfCondition].details[fieldindex] && submitFieldData.actiondetail[indexOfCondition].details[fieldindex].values){
              fieldVal = submitFieldData.actiondetail[indexOfCondition].details[fieldindex].values.id;
            }

            createdFields[indexOfCondition].push(
              <Grid item xs={11}>
                <Grid container spacing={2}>
                  <Grid item xs={4} className="text-hin  pt-30">
                    <label className="font-weight-500">{field.fieldlabel}</label>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl className="w-100 input_bx">
                      <SelectValidator
                        ref={ref => field = ref}
                        label={field.title}
                        value={fieldVal}
                        onChange={(event, field, index, indexoffield) => data.ActionDropChange(event, fieldtemp, indexOfCondition, fieldindex)}
                        // onChange={(event, field, index) => this.handleDropChange(event, fieldtemp, indexOfCondition)}
                        name={field.fieldkey}
                        id={field.id}
                        required={true}
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                      >

                        {field.options && field.options.map((item, key) =>
                          <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item.id}>{item.name}</MenuItem>
                        )}

                      </SelectValidator>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )
          } else if (field.fieldkey == 'value' || field.fieldkey == 'winpercentage') { // value // winpercentage

            let fieldtemp = field;
            let fieldvalue = "";
            if(submitFieldData && submitFieldData.actiondetail && submitFieldData.actiondetail[indexOfCondition] && submitFieldData.actiondetail[indexOfCondition].details && submitFieldData.actiondetail[indexOfCondition].details[fieldindex]){
              fieldvalue = submitFieldData.actiondetail[indexOfCondition].details[fieldindex].fieldvalue;
            }

            createdFields[indexOfCondition].push(
              <Grid item xs={11}>
                <Grid container spacing={2}>
                  <Grid item xs={4} className="text-hint pt-30">
                    <label className="font-weight-500">{field.fieldlabel}</label>
                  </Grid>
                  <Grid item xs={8}>
                    <TextValidator
                      ref={ref => field = ref}
                      type="number"
                      required={true}
                      id={field.id}
                      className="w-100 input_bx"
                      label={field.fieldlabel}
                      onChange={(event, field, index, indexoffield) => data.actionConditionValueChange(event, fieldtemp, indexOfCondition, fieldindex)}
                      // onChange={(event, field, index) => data.handleNumberChange(event, fieldtemp, indexOfCondition)}
                      name={field.fieldkey}
                      value={fieldvalue}
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )
          } else {
            let fieldtemp = field;
            
            let fielddata = data.state.token;
            if(data.state.alldraftFieldData && data.state.alldraftFieldData[indexOfCondition] && data.state.alldraftFieldData[indexOfCondition][fieldindex]){
              fielddata = data.state.alldraftFieldData[indexOfCondition][fieldindex].fieldvalue;
            }else{
              fielddata = data.tokenIdGenerator(indexOfCondition, fieldindex, 0, fieldtemp);
            }
            
            createdFields[indexOfCondition].push(
              <Grid item xs={11}>
                <Grid container spacing={2}>
                  <Grid item xs={4} className="text-hint pt-30">
                    <label className="font-weight-500">{field.fieldlabel}</label>
                  </Grid>
                  <Grid item xs={8} className="subject-editor workflow_editor">
                    <MegadraftEditor
                      data-fieldindex={indexOfCondition}
                      placeholder={"Add " + field.fieldlabel.charAt(0).toUpperCase() + field.fieldlabel.slice(1)}
                      theme="white-thin"
                      editorState={fielddata}
                      onChange={data.handleChangeToken}
                      actions={[]}
                      sidebarRendererFn={(indexc, fieldind)=>data.getCustomTokenSidebar(indexOfCondition, fieldindex, 'normal')}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )
          }
        } 
        else if (parseFloat(field.fieldtype.id) == 8) { // Date
          let fieldtemp = field;
          var dtFormat = "MM/dd/yyyy";          
          let dtFormat1 = "MM/DD/YYYY";
          let fieldVal = -1;
          
          if (data.state.userSettings && data.state.userSettings.dateformat) {
            dtFormat1 = data.state.userSettings.dateformat;
            dtFormat = dtFormat.replace("DD", "dd").replace("YYYY", "yyyy");
          }
          
          var selectedDate = moment().format(dtFormat1);
          
          if(submitFieldData && submitFieldData.actiondetail && submitFieldData.actiondetail[indexOfCondition] && submitFieldData.actiondetail[indexOfCondition].details && submitFieldData.actiondetail[indexOfCondition].details[fieldindex] && submitFieldData.actiondetail[indexOfCondition].details[fieldindex].fieldvalue){
            selectedDate = submitFieldData.actiondetail[indexOfCondition].details[fieldindex].fieldvalue;
          }

          createdFields[indexOfCondition].push(
            <Grid item xs={11}>
              <Grid container spacing={2}>
                <Grid item xs={4} className="text-hint pt-30">
                  <label className="font-weight-500">{field.fieldlabel}</label>
                </Grid>
                <Grid item xs={8}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <FormControl className="w-100 input_bx">
                      <KeyboardDatePicker
                        label={field.fieldlabel}
                        format={dtFormat}
                        required={true}
                        placeholder={dtFormat}
                        error={(selectedDate == null || selectedDate == "Invalid Date") ? true : false}
                        value={selectedDate}
                        onChange={(event, field, index, indexoffield) => data.ActionDateChange(event == "Invalid Date" ? "Invalid Date" : event, fieldtemp, indexOfCondition, fieldindex)}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        helperText={selectedDate == null ? "This field is required" : (selectedDate == "Invalid Date") ? "Please enter a valid date." : ""}
                      />
                    </FormControl>
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </Grid>
          )
        } 
        else if (parseFloat(field.fieldtype.id) == 4 || parseFloat(field.fieldtype.id) == 5) { // drop-down
          let fieldtemp = field;
          let fieldVal = "";
          if(submitFieldData && submitFieldData.actiondetail && submitFieldData.actiondetail[indexOfCondition] && submitFieldData.actiondetail[indexOfCondition].details && submitFieldData.actiondetail[indexOfCondition].details[fieldindex] && submitFieldData.actiondetail[indexOfCondition].details[fieldindex].values){
            fieldVal = submitFieldData.actiondetail[indexOfCondition].details[fieldindex].values.id;
          }
          
          if (field.fieldkey == 'company' || field.fieldkey == 'status' || field.fieldkey == 'source' || field.fieldkey == 'contacttype' || field.fieldkey == 'pipeline' || field.fieldkey == 'stage' || field.fieldkey == 'priority' || field.fieldkey == 'lossreason' || field.fieldkey == 'activitytype') {
            
            createdFields[indexOfCondition].push(
              <Grid item xs={11}>
                <Grid container spacing={2}>
                  <Grid item xs={4} className="text-hint pt-30">
                    <label className="font-weight-500">{field.fieldlabel}</label>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl className="w-100 input_bx">
                      <SelectValidator
                        ref={ref => field = ref}
                        label={field.title}
                        value={fieldVal}
                        onChange={(event, field, index, indexoffield) => data.ActionDropChange(event, fieldtemp, indexOfCondition, fieldindex)}
                        // onChange={(event, field, index) => this.handleDropChange(event, fieldtemp, indexOfCondition)}
                        name={field.fieldkey}
                        id={field.id}
                        required={true}
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                      >
                        {
                          (field.fieldkey == 'stage') ?
                            field.options && field.options.map((item, key) =>
                              <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item.id}>{item.name} ({item.pipeline_name})</MenuItem>
                            )
                          :
                            field.options && field.options.map((item, key) =>
                              <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item.id}>{item.name}</MenuItem>
                            )
                        }
                      </SelectValidator>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )
          } else {
            let fieldtemp = field;
            createdFields[indexOfCondition].push(
              <Grid item xs={11}>
                <Grid container spacing={2}>
                  <Grid item xs={4} className="text-hint pt-30">
                    <label className="font-weight-500">{field.fieldlabel}</label>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl className="w-100 input_bx">
                      <SelectValidator
                        ref={ref => field = ref}
                        label={field.title}
                        value={fieldVal}
                        onChange={(event, field, index, indexoffield) => data.ActionDropChange(event, fieldtemp, indexOfCondition, fieldindex)}
                        name={field.fieldkey}
                        id={field.id}
                        required={true}
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                      >

                        {field.options && field.options.map((item, key) =>
                          <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item.id}>{item.optionvalue}</MenuItem>
                        )}

                      </SelectValidator>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )
          }
        } 
        else if (parseFloat(field.fieldtype.id) == 6 || parseFloat(field.fieldtype.id) == 7 || parseFloat(field.fieldtype.id) == 10) { // number

          let fieldtemp = field;          

          createdFields[indexOfCondition].push(
            <Grid item xs={11}>
              <Grid container spacing={2}>
                <Grid item xs={4} className="text-hint pt-30">
                  <label className="font-weight-500">{field.fieldlabel}</label>
                </Grid>
                <Grid item xs={8}>
                  <TextValidator
                    ref={ref => field = ref}
                    type="number"
                    required={true}
                    id={field.id}
                    className="w-100 input_bx"
                    label={field.fieldlabel}
                    onChange={(event, field, index, indexoffield) => data.actionConditionValueChange(event, fieldtemp, indexOfCondition, fieldindex)}
                    // onChange={(event, field, index) => data.handleNumberChange(event, fieldtemp, indexOfCondition)}
                    name={field.fieldkey}
                    value={(submitFieldData.actiondetail && submitFieldData.actiondetail[indexOfCondition] && submitFieldData.actiondetail[indexOfCondition].detail && submitFieldData.actiondetail[indexOfCondition].detail[fieldindex]) ? submitFieldData.actiondetail[indexOfCondition].detail[fieldindex].values : ''}
                    validators={["required"]}
                    errorMessages={["This field is required"]}
                  />
                </Grid>
              </Grid>
            </Grid>
          )
        }
    });
  
  return createdFields;
};
