import React, { Component } from "react";
import {
    Paper,
    Chip,
    Checkbox,
    Select,
    Drawer,
    RadioGroup,
    Radio,
    Tooltip,
    Dialog,
    DialogTitle,
    Divider,
    DialogContent,
    FormControl,
    InputLabel,
    Input,
    FormHelperText,
    DialogActions,
    CircularProgress,
    Grid,
    Button,
    IconButton,
    Icon,
    Card,
    TextField,
    LinearProgress,
    Switch,
    FormControlLabel,
    MenuItem,
    InputAdornment,
    withStyles
} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import { Link } from "react-router-dom";
import Board from 'react-trello';
import DropdownDefault from "../Layout/DropdownDefault";
import "../../styles/_opportunities.scss"
import { withRouter } from "react-router-dom";
import CreateNewProjectDialog from "./CreateNewProjectDialog";
import ProjectProfileFrame from "./ProjectProfileFrame";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import AddNewPipeline from '../Settings/AddNewPipeline'
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import { FilterComponent } from './FilterComponent'
import CreateNewTaskDialog from "../Tasks/CreateNewTaskDialog";
import TaskProfileFrame from "../Tasks/TaskProfileFrame";
import FontAwesome from "react-fontawesome";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
let eventBus = undefined
const setEventBus = (handle) => {
    eventBus = handle
}

const styleStatusdiv = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    width: '100%',
    verticalAlign: 'middle',
    cursor: 'pointer',
    width : '100%'
}

const LanesColorArray = ['primary', 'secondary', 'yellow', 'green', 'red', 'blue', 'black', 'orange', 'purple', 'gray']
let clickFlag = false;

const TooltipNew = withStyles((theme) => ({
    tooltip: {
      fontSize: 14,
    },
  }))(Tooltip);
class ProjectCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            systemTagsList: [],
            systemCityListArray: [],
            systemStateListArray: [],
            systemZipcodeListArray: [],
            countryListArray: [],
            usersListArray: [],
            pageSize: 1000,
            column_name: "name",
            sorttype: "asc",
            selectedSortByValue: "name",
            selectedSortByName: "Name",
            selectedSortByFieldId: 67,
            fieldId: 67,
            filterdata: [],
            searchText: '',

            isOpenedPipelines: false,
            isOpenedFilters: true,
            isOpenedCustomFilters: false,
            isOpenedRange: false,
            isOpenedPipline: false,
            PiplinecardId: null,
            switchView: true,

            shouldOpenCreateProjectDialog: false,
            FullCreateProjectDialog: false,
            minimizeProjectDialog: false,
            projectStageList: [],



            lanesData: {
                "lanes": []
            },
            isDataLoading: true,
            userSettings: "",
            companyCurrencyData: [],
            isOpenedFiltersDefault: false,
            fieldDisplay: [],
            filterRangeArray: [],
            dropdownhide: [],
            filterSaveDialoge: false,
            fitertype: "1",
            filtername: "",
            recordtypeid: 5,
            filternameError: false,
            projectFilterListArray: [],
            displaySelectedFilterName: "All Project",
            filterUpdateId: "",
            allowStateUpdateFilter: true,
            filterdata: [],
            activityquery: "any",
            tagsquery: "any",
            dateformat: 'MM/DD/YYYY',
            timeZone: 'America/New_York',
            AllProjectFilterKey: "",
            filterObject: {},
            isDropSubmit: false,
            clickedCardId: "",
            masterActivityTypesList: [],
            loassReasonList: [],
            changeDate: true,


            //Task popup 
            addTaskProjectId: "",
            taskDueDate: new Date(),
            taskName: "",
            shouldOpenCreateTaskDialog: false,
            FullCreateTaskDialog: false,
            minimizeTaskDialog: false,
            showTskpopup: false,
            isLoadingRelatedTask: false,
            taskListArray: [],
            drawerOpenTask: false,
            task_id: "",
            //Task popup
            relatedListArray: [],
            isOpenedProjectList: true,
            dataCount: 0,

            updatedfieldid: "",
            crudPermission: {
                iscreate: true,
                isedit: true,
                isdelete: true,
            },
            teamvisible: false,
            selectedTeamList: [],
            selectedUserList: [],
        };
    }
    getUserPermission = () => {
        if (this.props.settingReducer.userPermissionDetails.data) {
            let udata = this.props.settingReducer.userPermissionDetails.data.data
            if (udata.visibilityaccess.length > 0 &&
                udata.teampermissionstatus === true &&
                this.props.authReducer.userRole.id === 3) {
                let leaddt = udata.visibilityaccess.find(function (e) { return e.sourcetype === 5; });
                this.setState({
                    crudPermission: {
                        iscreate: leaddt.iscreate,
                        isdelete: leaddt.isdelete,
                        isedit: leaddt.isedit,
                    }
                })
            }
        }
    }
    componentDidMount() {
        this.props.getRelatedList(this.props.apolloClient.client);
        window.addEventListener('mouseup', this.mouseKeyUp, false);
        let userData = JSON.parse(localStorage.getItem('user_data_session'))
        let userprefparams = { userid: userData.id }
        let val1 = { typeId: 5 }
        this.props.getProjectStage(this.props.apolloClient.client);
        this.props.getActivityTypes(this.props.apolloClient.client);
        this.props.customFieldList(this.props.apolloClient.client, val1);
        this.props.getFilterListProject(this.props.apolloClient.client, 5);
        this.props.getUserSetting(this.props.apolloClient.client, userprefparams);
        this.props.getCityList(this.props.apolloClient.client);
        this.props.getStateList(this.props.apolloClient.client);
        this.props.getZipcodeList(this.props.apolloClient.client);
        this.props.getUserList(this.props.apolloClient.client);
        this.props.getCompanyCurrency(this.props.apolloClient.client);
        this.props.getTagsList(this.props.apolloClient.client);

        let val = {
            filterdata: this.state.filterdata,
            list: {
                limit: 1,
                pageno: 1,
                searchtext: this.state.searchText,
                sorttype: this.state.sorttype,
                type: "sort",
                fieldId: this.state.fieldId,
                columnname: this.state.column_name,
                islimited: false
            },
            filterid: 1
        }
        this.getListing(val)
    }

    mouseKeyUp = () => {
        //this.closeTaskPopup()
        this.setState({
            isOpenedPipline: false,
        });
    }


    getListing = (val) => {
        this.props.getProjectFilterList(this.props.apolloClient.client, val);
    }

    componentWillReceiveProps(props) {
        this.getUserPermission()

        if (props.settingReducer.relatedListArray.data) {
            props.settingReducer.relatedListArray.is_loaded = false
            const relatedData = props.settingReducer.relatedListArray.data;
            var NewArray = []
            NewArray = NewArray.concat(relatedData.lead);
            NewArray = NewArray.concat(relatedData.contact);
            NewArray = NewArray.concat(relatedData.company);
            NewArray = NewArray.concat(relatedData.opportunities);
            NewArray = NewArray.concat(relatedData.project);
            this.setState({ relatedListArray: NewArray })
        }
        if (props.taskReducer.taskFieldData.data && props.taskReducer.taskFieldData.is_loaded) {
            this.setState({
                isDataLoading: false,
            }, () => this.forceUpdate())
        }
        if (props.settingReducer.relatedDataTask.data) {
            props.settingReducer.relatedDataTask.is_loaded = false
            this.setState({
                taskListArray: props.settingReducer.relatedDataTask.data.data,
                isLoadingRelatedTask: false,
            }, () => this.forceUpdate());
        }

        if (props.projectReducer.projectFieldData.data && props.projectReducer.projectFieldData.is_loaded) {
            clickFlag = false
           
            this.setState({
                fieldsObj: {},
                comp_value: '',
                primarycontact_value: "",
            }, () => {
                let fieldsObj = this.state.fieldsObj;
                let projectFieldData = props.projectReducer.projectFieldData.data
                var key1 = this.findWithAttr(projectFieldData, 'id', "67");
                if (key1 !== -1) {
                    this.setState({
                        teamvisible: projectFieldData[key1].projectdetail[0].project.teamvisible ? projectFieldData[key1].projectdetail[0].project.teamvisible : false,
                    })
                }
                this.setState({ projectFieldData: projectFieldData })
                let country = "";
                let selectedTeamList = [];
                let selectedUserList = [];
                const projectDataForEdit = props.projectReducer.projectFieldData.data;
                var promise = new Promise((resolve, reject) => {
                    projectDataForEdit.map((v, i) => {
                        if (v.fieldtype.id == '1' && v.id == '1') { //for first/last name          
                            if (v.projectdetail.length > 0) {
                                var data = {
                                    fieldid: v.id,
                                    projectvalue: v.projectdetail[0].fieldvalue
                                }
                                fieldsObj = {
                                    ...fieldsObj,
                                    [v.fieldkey]: data,
                                }

                            }
                        } else if (v.fieldtype.id == '1' && v.id == '69') { //for owner field        
                            if (v.projectdetail.length > 0 && v.projectdetail[0].values.length && v.projectdetail[0].values[0].id) {
                                var companyOwnerKey = this.findWithAttr(this.state.usersListArray, 'id', v.projectdetail[0].values[0].id);
                                if (companyOwnerKey !== -1) {
                                    var ownerData = {
                                        fieldid: v.id,
                                        values: this.state.usersListArray[companyOwnerKey]
                                    }
                                    fieldsObj = {
                                        ...fieldsObj,
                                        [v.fieldkey]: ownerData,
                                    }
                                }
                            }
                        } else if (v.fieldtype.id == '1' && v.fieldkey == 'tags') { //for tag input
                            var tagArray = []
                            if (v.projectdetail.length > 0 && v.projectdetail[0].values.length > 0) {
                                v.projectdetail[0].values.map((v, i) => {
                                    tagArray.push(v.value);
                                })
                            }
                            var data = {
                                fieldid: v.id,
                                values: tagArray
                            }
                            fieldsObj = {
                                ...fieldsObj,
                                [v.fieldkey]: data,
                            }
                        } else if (v.fieldtype.id == '1' && v.id == '68') { //related field
                            if (v.projectdetail.length > 0 && v.projectdetail[0].values.length && v.projectdetail[0].values[0].id) {
                                //var relatedKey = this.findWithAttr(this.state.relatedListArray, 'id',v.projectdetail[0].values[0].id);
                                var selectedObj = this.state.relatedListArray.filter(function (element) {
                                    if (element.id == v.projectdetail[0].values[0].id && element.type == v.projectdetail[0].values[0].type) {
                                        return element
                                    }
                                });
                                if (selectedObj.length > 0) {
                                    var relatedData = {
                                        fieldid: v.id,
                                        //values: this.state.relatedListArray[relatedKey]
                                        values: selectedObj[0]
                                    }
                                    fieldsObj = {
                                        ...fieldsObj,
                                        [v.fieldkey]: relatedData,
                                    }
                                }
                            }
                        } else if (v.fieldtype.id == '1' || v.fieldtype.id == '6' || v.fieldtype.id == '10' || v.fieldtype.id == '7' || v.fieldtype.id == '9') { //other textfield and number input
                            if (v.projectdetail.length > 0 && v.projectdetail.length > 0) {
                                var data = {
                                    fieldid: v.id,
                                    projectvalue: v.projectdetail[0].fieldvalue
                                }
                                fieldsObj = {
                                    ...fieldsObj,
                                    [v.fieldkey]: data,
                                }
                            }
                        }

                        if (v.fieldtype.id == '2') { //TextArea
                            if (v.projectdetail.length > 0) {
                                var data = {
                                    fieldid: v.id,
                                    projectvalue: v.projectdetail[0].fieldvalue
                                }
                                fieldsObj = {
                                    ...fieldsObj,
                                    [v.fieldkey]: data,
                                }
                            }
                        }

                        if (v.fieldtype.id == "3") { //checkboxes
                            if (v.projectdetail.length > 0) {
                                var data = {
                                    fieldid: v.id,
                                    projectvalue: v.projectdetail[0].fieldvalue
                                }
                                fieldsObj = {
                                    ...fieldsObj,
                                    [v.fieldkey]: data,
                                }
                            }
                        }
                        //if(this.state.fieldsList && this.state.fieldsList.length >0){
                        if (v.fieldtype.id === '4') { //dropdown
                            if (v.id === "70" || v.id === "72" || v.id === "75") {
                                if (v.projectdetail.length > 0 && v.projectdetail[0].values.length > 0) {
                                    var optId = this.findWithAttr(v.options, 'id', v.projectdetail[0].values[0].id);
                                    fieldsObj = {
                                        ...fieldsObj,
                                        [v.fieldkey]: { fieldid: v.id, values: v.options[optId] },
                                    }
                                    if (v.id === "72") {
                                        if (v.projectdetail[0].values[0].id === 2) {
                                            let filteredselectedTeamList = this.state.selectedTeamList.length > 0 ? this.state.selectedTeamList.filter(el => Object.keys(el).length) : []
                                            if (v.projectdetail[0].values[0].visiblelist && filteredselectedTeamList !== v.projectdetail[0].values[0].visiblelist) {
                                                selectedTeamList = v.projectdetail[0].values[0].visiblelist
                                            }
                                        } else if (v.projectdetail[0].values[0].id === 3) {
                                            let filteredselectedUserList = this.state.selectedUserList.length > 0 ? this.state.selectedUserList.filter(el => Object.keys(el).length) : []
                                            if (v.projectdetail[0].values[0].visiblelist && filteredselectedUserList !== v.projectdetail[0].values[0].visiblelist) {
                                                selectedUserList = v.projectdetail[0].values[0].visiblelist
                                            }
                                        }
                                    }
                                }
                            } else {
                                if (v.projectdetail.length > 0 && v.projectdetail[0].values.length > 0) {
                                    var optId = this.findWithAttr(v.options, 'id', v.projectdetail[0].values[0].id);
                                    fieldsObj = {
                                        ...fieldsObj,
                                        [v.fieldkey]: { fieldid: v.id, values: v.options[optId] },
                                    }
                                }
                            }
                        }
                        if (v.fieldtype.id === '5') { //multi dropdown
                            if (v.projectdetail.length > 0 && v.projectdetail[0].values.length > 0) {
                                fieldsObj = {
                                    ...fieldsObj,
                                    [v.fieldkey]: { fieldid: v.id, values: v.projectdetail[0].values },
                                }
                            }
                        }
                        if (v.fieldtype.id === '8') { //datepicker
                            if (v.projectdetail.length > 0 && v.projectdetail[0].fieldvalue != "") {
                                //var dt = moment.unix(v.projectdetail[0].fieldvalue).format(this.state.userSettings.dateformat).toString()
                                var dt = new Date(moment(v.projectdetail[0].fieldvalue).format('YYYY-MM-DD'))

                                fieldsObj = {
                                    ...fieldsObj,
                                    [v.fieldkey]: { fieldid: v.id, values: dt },
                                }
                            } else if (v.projectdetail.length > 0 && v.projectdetail[0].fieldvalue == "") {
                                fieldsObj = {
                                    ...fieldsObj,
                                    [v.fieldkey]: { fieldid: v.id, values: null },
                                }
                            }
                        }
                        //}

                        this.setState({
                            updatedfieldid: parseFloat(v.id), selectedTeamList: [...selectedTeamList, {}],
                            selectedUserList: [...selectedUserList, {}],
                        })
                    })

                    this.setState({
                        fieldsObj,
                        country,
                        isUpdate: false,
                        isAnyValueChange: false
                    }, () => {
                        resolve(true)
                    })
                });

                promise.then(result => {
                    props.projectReducer.projectFieldData.is_loaded = false;
                    this.setState({
                        isAnyValueChange: false
                    }, () => {
                        setTimeout(() => {
                            if (this.state.isDropSubmit) {
                                this.handleFormSubmit()
                                this.setState({
                                    isDataLoading: false,
                                }, () => {
                                    this.forceUpdate()
                                })
                            }
                        }, 0);
                    })
                });
            })
        }

        if (props.settingReducer.projectCustomFieldList.data) {
            const customFields = props.settingReducer.projectCustomFieldList.data;
            this.setState({ fieldsList: customFields })
        }
        if (props.settingReducer.systemTagsList.data && this.state.systemTagsList.length == 0) {
            const tagsData = props.settingReducer.systemTagsList.data;
            this.setState({ systemTagsList: tagsData })
        }
        if (props.settingReducer.usersListArray.data) {
            const usersListArray = props.settingReducer.usersListArray.data;
            var companyOwnerKey = this.findWithAttr(usersListArray, 'companyowner', "true");
            this.setState({
                //taskOwner:usersListArray[companyOwnerKey]
            })
            this.setState({ usersListArray: usersListArray })
        }
        if (props.settingReducer.companyCurrencyData.data != null) {
            var cData = props.settingReducer.companyCurrencyData.data;
            this.setState({ companyCurrencyData: cData[0] });
        }
        //storing user setting data in state
        if (props.settingReducer.userSettingArray.data && this.state.userSettings == "") {
            const settingData = props.settingReducer.userSettingArray.data;
            this.setState({
                userSettings: settingData,
            })
            let format = settingData.dateformat;
            let timezone = settingData.timezone;

            this.setState({ dateformat: format, timeZone: timezone })
        }

        if (props.projectReducer.projectLogSidebarList && props.projectReducer.projectLogSidebarList.data && props.projectReducer.projectLogSidebarList.data.logdata) {
            this.setState({ projectLogs: props.projectReducer.projectLogSidebarList.data.logdata })
        }

        if (props.projectReducer.projectFilterListArray.data && this.state.allowStateUpdateFilter || props.projectReducer.projectFilterListArray.is_loaded) {
            const projectFilterListArray = props.projectReducer.projectFilterListArray.data;
            props.projectReducer.projectFilterListArray.is_loaded = false
            this.setState({ projectFilterListArray: projectFilterListArray, allowStateUpdateFilter: false })

            var AllfilterIdKey = this.findWithAttr(projectFilterListArray, 'id', "21");
            if (AllfilterIdKey != -1) {
                this.setState({ AllProjectFilterKey: AllfilterIdKey })
            }


            //Aplying default filter selected  STRAT

            let defaultFilter;
            //checkdefault filter  in localStorage
            var localStorageFilterId = localStorage.getItem('selected_project_filter_id');

            if (localStorageFilterId) {
                var localId = this.findWithAttr(projectFilterListArray, 'id', localStorageFilterId);
                defaultFilter = projectFilterListArray[localId];
            } else {
                var defaultKeyStatus = this.findWithAttr(projectFilterListArray, 'isdefault', true);
                defaultFilter = projectFilterListArray[defaultKeyStatus];
                localStorage.setItem('selected_project_filter_id', defaultFilter.id);
            }
            //updateFilter name to display and menudisplay array set

            let udata = JSON.parse(localStorage.getItem('user_data_session'))
            //when my project filter select
            if (defaultFilter.id == "23" && defaultFilter.filtername == "My Projects") {
                defaultFilter.filteroption = [{ fieldid: "69", name: "owner", type: "value", value: [udata.email] }]
            }
            //my open project
            if (defaultFilter.id == "22") {
                defaultFilter.filteroption = [
                    {
                        fieldid: "69", name: "owner", type: "value", value: [udata.email]
                    },
                    {
                        fieldid: "70", name: "status", type: "value", value: ["Open"]
                    }
                ]
            }

            this.setState({
                displaySelectedFilterName: this.state.newSavedFilterName ? this.state.newSavedFilterName : defaultFilter.filtername,
                fieldDisplay: [],
                dropdownhide: [],
            })

            if (defaultFilter.filteroption.length > 0) {
                let filterObject = this.state.filterObject;
                let filterRangeArray = this.state.filterRangeArray;
                let displayField = []
                let dropdownhideShow = []
                var promise = new Promise((resolve, reject) => {
                    defaultFilter.filteroption.map((val, k) => {

                        filterObject = {
                            ...filterObject,
                            [val.name]: val
                        }

                        if (val.name == "tags") {
                            this.setState({ tagsquery: val.query })
                        }
                        if (val.type == "range") {
                            filterRangeArray[val.name] = ["", ""];
                            filterRangeArray[val.name][0] = val.value[0];
                            filterRangeArray[val.name][1] = val.value[1];
                            this.setState({ filterRangeArray })
                            displayField.push(val.name)
                        }
                        if (val.type == "date") {
                            filterRangeArray[val.name] = ["", ""];
                            filterRangeArray[val.name][0] = val.value[0] == "" ? null : val.value[0];
                            filterRangeArray[val.name][1] = val.value[1] == "" ? null : val.value[1];
                            this.setState({ filterRangeArray })
                            if (val.value[0] != "" && val.value[1] != "") {
                                displayField.push(val.name)
                            } else if (val.value[0] != "" || val.value[1] != "") {
                                displayField.push(val.name)
                            }
                        }
                        if (val.name == "zipcode" || val.name == "city" || val.name == "state" || val.name == "country") { //hideshow ENABLE state/city/country/state
                            dropdownhideShow.push(val.name)
                        } else {
                            dropdownhideShow.push(val.name)
                        }
                    })
                    this.setState({
                        filterObject,
                        fieldDisplay: displayField,
                        dropdownhide: dropdownhideShow,
                        newSavedFilterName: "",
                    }, () => this.forceUpdate())
                    resolve(true)
                });

                promise.then(result => {
                    this.handleFilterSubmit();
                });
            }
            //Aplying default filter selected  END
        }

        if (props.projectReducer.projectList.data && props.projectReducer.projectList.is_loaded) {
            let proList = props.projectReducer.projectList.data;
            if (props.settingReducer.projectStageList.data) {
                var pipeData = props.settingReducer.projectStageList.data
                var promise = new Promise((resolve, reject) => {
                    var lanesData = this.state.lanesData.lanes
                    var blankArray = []
                    eventBus.publish({ type: 'UPDATE_LANES', lanes: [] })
                    var pipeKey = 1
                    var colorKey = 0
                    pipeData.map((row, key) => {
                        if (colorKey == 10) {
                            colorKey = 0
                        }
                        var existingKey = this.findWithAttr(lanesData, 'id', row.id.toString());
                        const count = proList.data.filter((obj) => obj.stage[0].id == row.id).length
                        var progressPercentage = parseInt(count / proList.data.length * 100)
                        var obj = {
                            "id": row.id.toString(),
                            "title": row.name,
                            "label": count,
                            "current": progressPercentage,
                            "target": LanesColorArray[colorKey],
                            "style": {
                                "width": 280
                            },
                            "cards": []
                        }
                        colorKey++;


                        if (existingKey == -1) {
                            lanesData.push(obj)
                            eventBus.publish({ type: 'UPDATE_LANES', lanes: lanesData })
                            this.setState({
                                ...this.state.lanesData,
                                ["lanes"]: lanesData,
                            }, () => {
                                this.forceUpdate()
                                resolve(1)
                            })
                        } else {
                            eventBus.publish({ type: 'UPDATE_LANES', lanes: [] })
                            blankArray.push(obj)
                            eventBus.publish({ type: 'UPDATE_LANES', lanes: blankArray })
                            this.setState({
                                ...this.state.lanesData,
                                ["lanes"]: blankArray,
                            }, () => {
                                this.forceUpdate()
                                resolve(1)
                            })
                        }

                    })
                })

                promise.then(result => {
                    props.projectReducer.projectFieldData.is_loaded = false;
                    proList.data.map((row, k) => {
                        var companyOwnerNames = ""
                        var relatedTo = ""
                        var createdDate = ""
                        if (row.owner && row.owner.length > 0) {
                            companyOwnerNames = row.owner[0].name
                        }
                        if (row.relatedto && row.relatedto.length > 0) {
                            relatedTo += "Related To : " + row.relatedto[0].value
                        }

                        if (row.createddate) {
                            var dt = moment.unix(row.createddate).format(this.state.userSettings.dateformat).toString()
                            createdDate = 'Created date : ' + dt;
                        }

                        let star_icon = row.isfavourite ? "star" : "star_border";
                        var chipClass = ""
                        if (row.status && row.status.length > 0) {
                            if (row.status[0].id == '1') {
                                chipClass = "chip_won_card";
                            }
                            if (row.status[0].id == '2') {
                                chipClass = "chip_abandoned_card";
                            }
                            if (row.status[0].id == '3') {
                                chipClass = "chip_lost_card";
                            }
                        }
                        var eleId = "tsk_popup_" + row.project_id
                        var cardObj = {
                            "laneId": row.stage[0].id.toString(),
                            "id": row.project_id.toString(),
                            "title": <h4><Link to={"/project/projects/" + row.project_id} className="opportunityNameHoverr text_ellipsis">{row.name}</Link> <Icon onClick={(lead, status) => this.handleFavorite(row.project_id, row.isfavourite)} className="filled_star" size="small">{star_icon}</Icon></h4>,
                            "label": <div id={eleId} onClick={(e, id) => this.openTaskPopup(e, row.project_id, row.name)} className="circle-bar br-green"></div>,
                            "description": <div className="card-description">
                                <p>Owner : {companyOwnerNames}</p>
                                {relatedTo ? <p style={styleStatusdiv}>{relatedTo}</p> : ""}
                                <p className="font-size-12 text-hint">{createdDate ? createdDate : ""}</p>
                                {chipClass != "" && <Chip label={row.status[0].value} className={chipClass} />}
                            </div>
                        }
                        eventBus.publish({ type: 'ADD_CARD', laneId: row.stage[0].id.toString(), card: cardObj })
                        //}
                    })


                });

                this.setState({
                    projectStageList: pipeData,
                    isLoadingPipelineList: false,
                    isDataLoading: false,
                    dataCount: proList.data.length,
                }, () => {
                    this.forceUpdate()
                    props.settingReducer.projectStageList.is_loaded = false
                    props.projectReducer.projectList.is_loaded = false
                })
            }

        }

    }

    focusInValue = () => {
        this.setState({
            focusValue: true,
        }, () => this.forceUpdate())
    };

    currencyDropdownClose = () => {
        this.setState({ focusValue: false })
    };

    handleChangeVals = (event, source) => {
        if (event.target.type === 'checkbox') {
            this.setState({
                [event.target.name]: event.target.checked
            });
        } else {
            this.setState({
                [event.target.name]: event.target.value
            });
        }
    };

    focusOutSubmitForm = () => {
        setTimeout(() => {
            if (this.state.isAnyValueChange) {
                this.handleFormSubmit()
            }
        }, 0);
    }

    handleFollowedCheckbox = (val, name) => {
        this.setState({
            filterObject: {
                ...this.state.filterObject,
                [name]: val
            }
        }, () => {
            setTimeout(() => {
                if (this.state.filterObject[name] == val) {
                    this.handleFilterSubmit()
                }
            }, 1500);
        })

    }

    handleChangeFilter = (val, name) => {
        this.setState({
            filterObject: {
                ...this.state.filterObject,
                [name]: val
            }
        }, () => {
            setTimeout(() => {
                if (this.state.filterObject[name] == val) {
                    this.handleFilterSubmit()
                }
            }, 1500);
        })
    };

    rangeClick = (key, id) => {
        var rangeData = this.state.filterRangeArray[key];
        if (this.state.filterRangeArray[key][0] == "" && this.state.filterRangeArray[key][1] == "") {
            rangeData = []
        }

        var value = { fieldid: id, name: key, type: "range", value: rangeData };
        this.setState({
            filterObject: {
                ...this.state.filterObject,
                [key]: value
            }
        }, () => {
            this.forceUpdate()
            setTimeout(() => {
                if (this.state.filterObject[key] == value) {
                    this.handleFilterSubmit()
                }
            }, 1500);
        })
    }

    rangeFilter = (rval, rname) => {
        let filterRangeArray = this.state.filterRangeArray;
        filterRangeArray[rname][rval.case] = rval.value;
        this.setState({ filterRangeArray })
    }

    handleProjectDialogClose = () => {
        this.setState({ shouldOpenCreateProjectDialog: false, FullCreateProjectDialog: false, minimizeProjectDialog: false });
    };

    handleProjectDialogMinimize = () => {
        this.setState(oldStateminimizeProjectDialog => ({ minimizeProjectDialog: !oldStateminimizeProjectDialog.minimizeProjectDialog }));
        this.setState({ FullCreateProjectDialog: false })
    };

    handleProjectDialogFull = () => {
        this.setState(oldStateFullCreateProjectDialog => ({ FullCreateProjectDialog: !oldStateFullCreateProjectDialog.FullCreateProjectDialog }));
        this.setState({ minimizeProjectDialog: false })

    };

    rangeClickDate = (key, id) => {
        var rangeData = this.state.filterRangeArray[key];
        if (this.state.filterRangeArray[key][0] == "" && this.state.filterRangeArray[key][1] == "") {
            rangeData = []
        }

        var value = { fieldid: id, name: key, type: "date", value: rangeData };
        this.setState({
            filterObject: {
                ...this.state.filterObject,
                [key]: value
            }
        }, () => {
            this.forceUpdate()
            setTimeout(() => {
                if (this.state.filterObject[key] == value) {
                    this.handleFilterSubmit()
                }
            }, 1500);
        })
    }

    rangeFilterDate = (rval, rname) => {
        let filterRangeArray = this.state.filterRangeArray;
        filterRangeArray[rname][rval.case] = rval.value;
        this.setState({
            filterRangeArray
        }, () => {
            this.forceUpdate()
            setTimeout(() => {
                this.rangeClickDate(rname, rval.fieldid)
            }, 100);
        })
    }

    handleToggleFieldDisplay = (name) => {
        var exists = this.state.fieldDisplay.includes(name);
        var joined = [];
        let filterRangeArray = this.state.filterRangeArray;

        if (exists) {
            joined = this.state.fieldDisplay.filter((item) => item != name)
            if (filterRangeArray[name]) {
                //delete filterRangeArray[name];
            }
        } else {
            joined = this.state.fieldDisplay.concat(name);
            if (!filterRangeArray[name]) {
                filterRangeArray[name] = ["", ""];
            }
        }

        this.setState({ fieldDisplay: joined, filterRangeArray })
    }

    dropdownMenu = (fkey) => {
        var exists = this.state.dropdownhide.includes(fkey);
        var joined = [];
        if (exists) {
            joined = this.state.dropdownhide.filter((item) => item != fkey)
        } else {
            joined = this.state.dropdownhide.concat(fkey);
        }
        this.setState({ dropdownhide: joined })
    }

    handleFilterSubmit = () => {
        var allowToFilter = true
        let copyObj = JSON.parse(JSON.stringify(this.state.filterObject));
        var filterValuesArray = Object.keys(copyObj);
        var finalFilterData = filterValuesArray.map((k, i) => {
            let v = copyObj[k];

            if (v.value.length > 0) {
                var valuesArray = [];
                v.value.map((val, k) => {
                    if (v.type == "date") {
                        if (val == "Invalid Date") {
                            allowToFilter = false
                        } else if (val == "") {
                            valuesArray.push(val)
                        } else if (val == null) {
                            valuesArray.push("")
                        } else if (val != null) {
                            if (v.fieldid != "0") {
                                val = moment(val).format('YYYY-MM-DD');
                            } else {
                                //val = moment(val).format("X");
                                val = moment(val).format('MM/DD/YYYY');
                            }
                            valuesArray.push(val)
                        }
                    } else if (v.type == "log") {
                        valuesArray.push(val.id);
                    } else if (v.fieldid == '69' && val.email) {
                        valuesArray.push(val.email)
                    } else if (v.fieldid != '69' && val.name) {
                        valuesArray.push(val.name)
                    } else if (val.optionvalue) {
                        valuesArray.push(val.optionvalue)
                    } else if (v.fieldid == "74" || v.type == "range") {//tag array and value
                        valuesArray.push(val);
                    } else {
                        valuesArray.push(val);
                    }
                })


                let checkBoxKey = -1
                if (this.state.fieldsList != undefined) {
                    checkBoxKey = this.findWithAttr(this.state.fieldsList, 'id', v.fieldid);
                }



                v.value = valuesArray;
                if (v.type == "followed") {//follow/not follow filter only value is true
                    if (v.value[0] == true) {
                        return v
                    }
                } else if (checkBoxKey != -1 && this.state.fieldsList[checkBoxKey].fieldtype.id == "3" && v.value[0] == "1") {
                    return v
                } else if (checkBoxKey != -1 && this.state.fieldsList[checkBoxKey].fieldtype.id != "3") {
                    return v
                } else if (checkBoxKey != -1 && this.state.fieldsList[checkBoxKey].fieldtype.id == "3") {
                } else {
                    return v
                }
            }
        })

        //filter null and undefined object 
        finalFilterData = finalFilterData.filter(function (element) {
            return element !== undefined || (element !== undefined && element.value.length != 0);
        });
        console.log("allowToFilter", allowToFilter);
        console.log("finalFilterData", finalFilterData);
        if (allowToFilter) {
            //saveFilter Data for save in future use.
            this.setState({
                filterdata: finalFilterData,
                isDataLoading: true,
            })

            let val = {
                filterdata: finalFilterData,
                list: {
                    limit: this.state.pageSize,
                    pageno: 1,
                    searchtext: this.state.searchText,
                    sorttype: this.state.sorttype,
                    type: "list",
                    fieldId: this.state.fieldId,
                    columnname: this.state.column_name,
                    islimited: false,
                },
                filterid: 1
            }
            this.getListing(val)
        }
    }

    handleSavefilter = () => {
        if (this.state.filtername == "") {
            this.setState({ filternameError: true })
            return false
        }
        if (this.state.filterdata && this.state.filterdata.length > 0) {
            let req = {
                filterdata: this.state.filterdata,
                fitertype: this.state.fitertype,
                filtername: this.state.filtername,
                recordtypeid: this.state.recordtypeid,
            }
            this.props.saveFilterDetailProject(this.props.apolloClient.client, req);
            this.setState({
                displaySelectedFilterName: this.state.filtername,
                newSavedFilterName: this.state.filtername,
            })
        }
        setTimeout(() => {
            this.setState({
                allowStateUpdateFilter: true,
            })
        }, 2000);
        this.handleSavefilterDialogeClose()
    }

    updateProjectFilter = (data) => {
        this.setState({
            filterSaveDialoge: true,
            filterUpdateId: data.id,
            filtername: data.filtername,
            fitertype: data.fitertype.toString(),
            oldFilterOption: data.filteroption,
        })
    }

    handleUpdatefilter = () => {
        if (this.state.filtername == "") {
            this.setState({ filternameError: true })
            return false
        }
        let req = {
            filterdata: this.state.oldFilterOption,
            fitertype: this.state.fitertype,
            filtername: this.state.filtername,
            filterid: parseInt(this.state.filterUpdateId),
        }

        this.props.updateFilterDetailProject(this.props.apolloClient.client, req);
        this.setState({
            displaySelectedFilterName: this.state.filtername,
        })
        let updateDataInState = this.findWithAttr(this.state.projectFilterListArray, 'id', this.state.filterUpdateId);
        if (updateDataInState !== -1) {
            this.state.projectFilterListArray[updateDataInState].filtername = this.state.filtername;
            this.state.projectFilterListArray[updateDataInState].fitertype = this.state.fitertype;
        }
        this.handleSavefilterDialogeClose()
    }

    updateFilterOptions = () => {
        let key = this.findWithAttr(this.props.projectReducer.projectFilterListArray.data, 'id', localStorage.getItem('selected_project_filter_id'));
        var data = ""
        if (key !== -1) {
            data = this.props.projectReducer.projectFilterListArray.data[key];
            if (this.state.filterdata && this.state.filterdata.length > 0) {
                let req = {
                    filterdata: this.state.filterdata,
                    fitertype: data.fitertype.toString(),
                    filtername: data.filtername,
                    filterid: parseInt(data.id),
                }
                this.props.updateFilterDetailProject(this.props.apolloClient.client, req);
                this.handleSavefilterDialogeClose()
            }
            setTimeout(() => {
                this.setState({
                    allowStateUpdateFilter: true,
                })
            }, 1500);
        }
    }

    confirmDeleteFilter = () => {
        this.props.deleteFilterProject(this.props.apolloClient.client, parseInt(this.state.filterDeleteId));
        let removeFilterObjKey = this.findWithAttr(this.props.projectReducer.projectFilterListArray.data, 'id', this.state.filterDeleteId);
        if (removeFilterObjKey !== -1) {
            if (!this.props.projectReducer.projectFilterListArray.data[removeFilterObjKey].isdefault == true) {
                this.props.projectReducer.projectFilterListArray.data.splice(removeFilterObjKey, 1);
            }
        }
        this.filterRemoveDialoge()
    }

    filterRemoveDialoge = () => {
        this.setState({ isDeleteFilter: false, filterDeleteId: "", allowStateUpdateFilter: true })
    }

    handleSavefilterDialoge = () => {
        this.setState({ filterSaveDialoge: true })
    }

    handleSavefilterDialogeClose = () => {
        this.setState({
            filterSaveDialoge: false,
            filternameError: false,
            fitertype: "1",
            filterUpdateId: "",
            oldFilterOption: "",
            filtername: "",
        })
    }

    updateFilterDefaultProject = (data) => {
        this.props.updateFilterDefaultProject(this.props.apolloClient.client, parseInt(data.id));
        setTimeout(() => {
            this.setState({
                allowStateUpdateFilter: true,
            })
        }, 2000);
    }

    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }

    handleFilterChange = (filterdata) => {
        console.log("filterdata", filterdata);
        let udata = JSON.parse(localStorage.getItem('user_data_session'))
        //when my project filter select
        if (filterdata.id == "23" && filterdata.filtername == "My Projects") {
            filterdata.filteroption = [{ fieldid: "69", name: "owner", type: "value", value: [udata.email] }]
        }
        //my open project
        if (filterdata.id == "22") {
            filterdata.filteroption = [
                {
                    fieldid: "69", name: "owner", type: "value", value: [udata.email]
                },
                {
                    fieldid: "70", name: "status", type: "value", value: ["Open"]
                }
            ]
        }
        this.setState({
            filterObject: {},
            filterRangeArray: [],
            fieldDisplay: [],
            dropdownhide: [],
        }, () => {
            this.forceUpdate()
            this.setState({ displaySelectedFilterName: filterdata.filtername, currentPage: 0 })
            if (filterdata.filteroption.length > 0) {
                let filterObject = this.state.filterObject;
                let filterRangeArray = this.state.filterRangeArray;
                let displayField = []
                let dropdownhideShow = []
                var promise = new Promise((resolve, reject) => {
                    filterdata.filteroption.map((val, k) => {
                        filterObject = {
                            ...filterObject,
                            [val.name]: val
                        }
                        if (val.name == "tags") {
                            this.setState({ tagsquery: val.query })
                        }

                        if (val.type == "range") {
                            filterRangeArray[val.name] = ["", ""];
                            filterRangeArray[val.name][0] = val.value[0];
                            filterRangeArray[val.name][1] = val.value[1];
                            this.setState({ filterRangeArray })
                            displayField.push(val.name)
                        }

                        if (val.type == "date") {
                            filterRangeArray[val.name] = ["", ""];
                            filterRangeArray[val.name][0] = val.value[0] == "" ? null : val.value[0];
                            filterRangeArray[val.name][1] = val.value[1] == "" ? null : val.value[1];
                            this.setState({ filterRangeArray })
                            if (val.value[0] != "" && val.value[1] != "") {
                                displayField.push(val.name)
                            } else if (val.value[0] != "" || val.value[1] != "") {
                                displayField.push(val.name)
                            }
                        }

                        if (val.name == "zipcode" || val.name == "city" || val.name == "state" || val.name == "country") {//hideshow ENABLE state/city/country/state
                            dropdownhideShow.push(val.name)
                        } else {
                            dropdownhideShow.push(val.name)

                        }
                    })
                    this.setState({
                        filterObject,
                        fieldDisplay: displayField,
                        dropdownhide: dropdownhideShow,
                    }, () => this.forceUpdate())
                    resolve(true)
                });

                promise.then(result => {
                    this.handleFilterSubmit();
                    localStorage.setItem('selected_project_filter_id', filterdata.id)
                });
            } else {
                let val = {
                    filterdata: [],
                    list: {
                        limit: this.state.pageSize,
                        pageno: 1,
                        searchtext: this.state.searchText,
                        sorttype: this.state.sorttype,
                        type: "list",
                        fieldId: this.state.fieldId,
                        columnname: this.state.column_name,
                        islimited: false,
                    },
                    filterid: 1
                }
                localStorage.setItem('selected_project_filter_id', filterdata.id)
                this.handleFilterSubmit();
            }
        })
    }

    handlechangeActivityQuery = (event) => {
        this.setState({
            activityquery: event.target.value
        }, () => {
            setTimeout(() => {
                if (this.state.filterObject['activitytype'] && this.state.filterObject['activitytype'].value.length > 0) {
                    this.state.filterObject['activitytype'].query = event.target.value;
                    this.handleFilterSubmit();
                }
            }, 1500);
        })
    }

    handlechangeTagsQuery = (event) => {
        this.setState({
            tagsquery: event.target.value
        }, () => {
            setTimeout(() => {
                if (this.state.filterObject['tags'] && this.state.filterObject['tags'].value.length > 0) {
                    this.state.filterObject['tags'].query = event.target.value;
                    this.handleFilterSubmit();
                }
            }, 1500);
        })
    }

    handleClearFilterData = () => {
        this.setState({
            fieldDisplay: [],
            filterRangeArray: [],
            dropdownhide: [],
            filterObject: {},
            filterdata: [],
            activityquery: "any",
            tagsquery: "any",
        }, () => {
        })
    }

    handlePipelineADDDialogClose = () => {
        this.setState({ shouldOpenNewPipelineDialog: false, FullNewPipelineDialog: false, minimizePipelineDialog: false, editPiplineData: '', });
    };

    handlePipelineADDDialogMinimize = () => {
        this.setState(oldStateminimizePipelineDialog => ({ minimizePipelineDialog: !oldStateminimizePipelineDialog.minimizePipelineDialog }));
        this.setState({ FullNewPipelineDialog: false })
    };

    handlePipelineADDDialogFull = () => {
        this.setState(oldStateFullNewPipelineDialog => ({ FullNewPipelineDialog: !oldStateFullNewPipelineDialog.FullNewPipelineDialog }));
        this.setState({ minimizePipelineDialog: false })

    }

    togglePipelines = () => {
        this.setState(oldStatePipelines => ({ isOpenedPipelines: !oldStatePipelines.isOpenedPipelines }));
    }

    toggleFilters = () => {
        this.setState(oldStateFilters => ({ isOpenedFilters: !oldStateFilters.isOpenedFilters }));
    }

    toggleFiltersDefault = () => {
        this.setState(oldStateLeadList => ({ isOpenedFiltersDefault: !oldStateLeadList.isOpenedFiltersDefault }));
    }

    toggleCustomFilters = () => {
        this.setState(oldStateCustomFilters => ({ isOpenedCustomFilters: !oldStateCustomFilters.isOpenedCustomFilters }));
    }

    toggleRange = (e) => {
        e.preventDefault();
        this.setState(oldStateRange => ({ isOpenedRange: !oldStateRange.isOpenedRange }));
    }

    handleDragStart = (cardId, laneId) => {
        this.setState({ isOpenedPipline: true, PiplinecardId: cardId });
    }

    handleDragEnd = (cardId, laneId, targetLaneId, position, cardDetails) => {
        //check if card drop in same lane then do not update
        if (laneId == targetLaneId) {
            return false
        }
        if (this.state.crudPermission.isedit) {
            var sKey = this.findWithAttr(this.state.projectStageList, 'id', targetLaneId);
            this.props.getProjectField(this.props.apolloClient.client, parseInt(cardId));
            this.setState({
                isOpenedPipline: false,
                project_id: parseInt(cardId),
                isDropSubmit: true,
                updateStageObj: this.state.projectStageList[sKey]
            });
        } else {
            var sKey = this.findWithAttr(this.state.projectStageList, 'id', laneId);
            this.props.getProjectField(this.props.apolloClient.client, parseInt(cardId));
            this.setState({
                isOpenedPipline: false,
                project_id: parseInt(cardId),
                isDropSubmit: true,
                updateStageObj: this.state.projectStageList[sKey]
            });
        }
    }
    closeDrawerRight = (e) => {
        this.setState({ drawerOpen: false });
    };
    handleDrawerRight = (project_id, metadata, laneId) => {
        if (!clickFlag) {
            if (this.state.project_id && (this.state.project_id === project_id)) { } else {
                this.setState({
                    project_id: project_id,
                    isDataLoading: true,
                }, () => {
                    this.forceUpdate()
                })
            }

            setTimeout(() => {
                this.setState({
                    drawerOpen: true,
                    isDataLoading: false,
                }, () => {
                    this.forceUpdate()
                })
            }, 2000);
        }

    };

    handleFavorite = (project_id, status) => {
        clickFlag = true
        let setFavoriteParams = [];
        setFavoriteParams['favorite'] = { status: !status, projectid: project_id };
        setFavoriteParams['listval'] = {
            filterdata: this.state.filterdata,
            list: {
                limit: 1,
                pageno: 1,
                searchtext: this.state.searchText,
                sorttype: this.state.sorttype,
                type: "list",
                fieldId: this.state.fieldId,
                columnname: this.state.column_name,
                islimited: false
            },
            filterid: 1
        };


        this.props.addFavouriteProject(this.props.apolloClient.client, setFavoriteParams);
    }

    handleFormSubmit = () => {
        let logData = [];
        let submitData = JSON.parse(JSON.stringify(this.state.fieldsObj));
        submitData = Object.values(submitData).map((row) => {
            if (row.fieldid == "68") { //realated  field
                if (row.values == null) {
                    return false
                }
                row.values = [{ srno: "1", id: row.values.id, value: row.values.name, type: row.values.type }]
            }
            if (row.fieldid === "72" || row.fieldid === "70" || row.fieldid === "75") {
                if (row.fieldid === "72") {
                    let filterVisibleList = [];
                    if (row.values.id === 2) {
                        filterVisibleList = this.state.selectedTeamList.length > 0 ? this.state.selectedTeamList.filter(el => Object.keys(el).length) : []
                    } else if (row.values.id === 3) {
                        filterVisibleList = this.state.selectedUserList.length > 0 ? this.state.selectedUserList.filter(el => Object.keys(el).length) : []
                    }
                    row.values = [
                        {
                            srno: "1",
                            id: row.values.id,
                            value: row.values.name,
                            visiblelist: filterVisibleList
                        }
                    ];
                } else {
                    row.values = [{ srno: "1", id: row.values.id, value: row.values.name }]
                }
            }

            if (this.state.isDropSubmit) {
                if (row.fieldid === "75") { //stage 
                    if (row.values.id == this.state.updateStageObj.id) {
                        allowToSubmit = false
                    }
                    if (row.values.name) {
                        row.values = [{ srno: "1", id: row.values.id, value: row.values.name }]
                    } else {
                        row.values = [{ srno: "1", id: this.state.updateStageObj.id, value: this.state.updateStageObj.name }]
                    }
                    this.setState({ isDropSubmit: false });
                }
            }
            var key = this.findWithAttr(this.state.fieldsList, 'id', row.fieldid);
            if (this.state.fieldsList[key].fieldtype.id == "4" && this.state.fieldsList[key].is_default == false) {
                row.values = [{ srno: "1", id: row.values.id, value: row.values.optionvalue }]
            }
            if (this.state.fieldsList[key].fieldtype.id == "5" && this.state.fieldsList[key].is_default == false) {
                var ar = [];
                if (row.values.length > 0) {
                    row.values.map((val, i) => {
                        var optName;
                        if (val.optionvalue) {
                            optName = val.optionvalue
                        } else {
                            optName = val.value
                        }
                        ar.push({ srno: (i + 1).toString(), id: val.id, value: optName })
                    })
                }
                row.values = ar
            }

            //validating date fiels
            if (this.state.fieldsList[key].fieldtype.id == "8") { //date field
                if (row.values == null) {
                    row.projectvalue = "";
                    delete row.values;
                } else if (row.values == "Invalid Date") {
                    return false
                } else {
                    var dt = moment(row.values).format('YYYY-MM-DD')
                    // // row.projectvalue = moment(row.values).format("X");
                    // let date_test = moment(row.values).format('YYYY-MM-DD HH:mm');
                    // var logdate = moment.tz(date_test, this.state.userSettings.timezone).format();
                    row.projectvalue = dt;
                    delete row.values;
                }
            }

            if (row.fieldid == "74") {
                if (row.values.length > 0) {
                    var objArr = [];
                    row.values.map((val, k) => {
                        var foundKey = this.findWithAttr(this.state.systemTagsList, 'tagname', val);
                        var tagId = "0";
                        if (foundKey !== -1) {
                            tagId = this.state.systemTagsList[foundKey].id
                        }
                        objArr.push({ "srno": (k + 1).toString(), value: val.toLowerCase(), id: tagId, isdeleted: "0" })
                    })

                    //add tags from props value which is removed   with id deleted id "1"
                    var tagsKeyFromProps = this.findWithAttr(this.props.projectReducer.projectFieldData.data, 'id', "74");
                    if (this.props.projectReducer.projectFieldData.data[tagsKeyFromProps].projectdetail.length > 0) {
                        var oldTagsValues = this.props.projectReducer.projectFieldData.data[tagsKeyFromProps].projectdetail[0].values;
                        if (oldTagsValues.length > 0) {
                            oldTagsValues.map((v, k) => {
                                var checkKey = this.findWithAttr(objArr, 'value', v.value);
                                if (checkKey === -1) {
                                    v.isdeleted = "1";
                                    v.srno = (objArr.length + 1).toString();
                                    objArr.push(v);
                                }
                            })
                        }
                    }

                    row.values = objArr
                    if (this.props.projectReducer.projectFieldData.data[tagsKeyFromProps].projectdetail.length > 0) {
                        this.props.projectReducer.projectFieldData.data[tagsKeyFromProps].projectdetail[0].values = objArr;
                    }
                }
            }

            if (row.fieldid == "69") { //owner value set
                if (row.values) {
                    row.values = [{ "srno": "1", id: row.values.id, value: row.values.email, name: row.values.name }];
                } else {
                    row.values = [];
                }
                delete row.projectvalue;
            }
            return row;
        })

        //adding  projectdetailid for update object from this.state.projectFieldData
        submitData = submitData.map((row, i) => {
            var key = this.findWithAttr(this.state.projectFieldData, 'id', row.fieldid);
            if (key !== -1) {
                if (this.state.projectFieldData[key].projectdetail.length > 0) {
                    row.projectdetailid = this.state.projectFieldData[key].projectdetail[0].id;
                } else {
                    row.projectdetailid = ""
                }
            }


            return row
        })

        //filter null and undefined object 
        submitData = submitData.filter(function (element) {
            return element !== undefined;
        });

        //not to submit if any field is false
        var allowToSubmit = true
        submitData.map((row) => {
            if (!row) {
                allowToSubmit = false
            }
        })
        console.log("submitData", submitData);
        if (this.state.project_id && allowToSubmit) {
            // update lead field data after update.... (For log)
            this.setState({ isUpdate: true });
            // -- ---------------

            let req = {
                projectdata: submitData,
                projectid: this.state.project_id,
                updatedfieldid: this.state.updatedfieldid,
                teamvisible: this.state.teamvisible,
            }
            this.props.updateProjectDetail(this.props.apolloClient.client, req);
        }
    };

    handleChange = event => {
        event.persist();
        if (event.target.name === 'switchView') {
            let path = `/project/projects-list`;
            this.props.history.push(path);
        } else {
            this.setState({
                [event.target.name]: event.target.value
            });
        }
    };

    changeSortOption = (fieldkey, name, fieldid) => {

        this.setState({
            selectedSortByValue: fieldkey,
            selectedSortByName: name,
            selectedSortByFieldId: fieldid,
        });
        let val = {
            filterdata: this.state.filterdata,
            list: {
                limit: 1,
                pageno: 1,
                searchtext: this.state.searchText,
                sorttype: this.state.sorttype,
                type: "sort",
                fieldId: fieldid,
                columnname: fieldkey,
                islimited: false
            },
            filterid: 1
        }
        this.getListing(val)
        this.props.getProjectStage(this.props.apolloClient.client);
    }
    changeSortType = () => {
        var type = this.state.sorttype
        if (type == 'asc') {
            type = 'desc';
        } else if (type == 'desc') {
            type = 'asc';
        }

        this.setState({
            sorttype: type,
            isDataLoading: true,
        }, () => {
            this.forceUpdate()
            let val = {
                filterdata: this.state.filterdata,
                list: {
                    limit: 1,
                    pageno: 1,
                    searchtext: this.state.searchText,
                    sorttype: this.state.sorttype,
                    type: "sort",
                    fieldId: this.state.selectedSortByFieldId,
                    columnname: this.state.selectedSortByValue,
                    islimited: false
                },
                filterid: 1
            }
            this.getListing(val)
            this.props.getProjectStage(this.props.apolloClient.client);
        })
    }

    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] == value) {
                return i;
            }
        }
        return -1;
    }



    handleSearch = event => {
        this.setState({
            [event.target.name]: event.target.value,
            isDataLoading: true,
        }, () => {
            this.forceUpdate()
            setTimeout(() => {
                let val = {
                    filterdata: this.state.filterdata,
                    list: {
                        limit: 1,
                        pageno: 1,
                        searchtext: this.state.searchtext,
                        sorttype: this.state.sorttype,
                        type: "list",
                        fieldId: this.state.fieldId,
                        columnname: this.state.column_name,
                        islimited: false
                    },
                    filterid: 1
                }
                this.getListing(val)
                this.props.getProjectStage(this.props.apolloClient.client);
            }, 300);
        })

    }

    openTaskPopup = (e, project_id, project_name) => {
        clickFlag = true
        var task_popup = document.querySelector("#task_popup");
        var ele = document.getElementById("tsk_popup_" + project_id)
        this.CancelTaskPopup();
        this.setState({
            addTaskProjectId: project_id,
            project_name: project_name,
            isLoadingRelatedTask: true,
            displayCompletedTask: false,
        }, () => {
            var req = {
                id: parseInt(project_id),
                type: "tasks",
                recordtype: "project",
                taskListArray: [],
            }
            this.props.getRecordRelatedData(this.props.apolloClient.client, req);
        })


        task_popup.style.left = e.pageX + "px";
        task_popup.style.top = e.pageY + "px";
        task_popup.style.display = "block";
    }

    closeTaskPopup = () => {
        clickFlag = false
        this.setState({
            addTaskProjectId: "",
            project_name: "",
            taskDueDate: new Date(),
            taskName: "",
        })
        var task_popup = document.querySelector("#task_popup");
        task_popup.style.display = "none";
    }

    handleChangeTaskField = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleDtChange = (val) => {
        this.setState({ taskDueDate: val });
    };

    handleFormSubmitAddTask = () => {
        var dDate = ""
        var repeatObj = { type: "never", repeatdays: 0, repeattype: "", days: 0 };
        if (this.state.taskDueDate == "Invalid Date") {
            return false;
        }

        if (this.state.taskDueDate == null) {
            let date_test = moment(new Date()).format('YYYY-MM-DD HH:mm');
            var logdate = moment.tz(date_test, this.state.userSettings.timezone).format();
            dDate = moment.utc(logdate).format('X');
        } else {
            let date_test = moment(this.state.taskDueDate).format('YYYY-MM-DD HH:mm');
            var logdate = moment.tz(date_test, this.state.userSettings.timezone).format();
            dDate = moment.utc(logdate).format('X');
        }
        var taskData = [
            { fieldid: "57", taskvalue: this.state.taskName },
            { fieldid: "60", taskvalue: dDate },
            { fieldid: "62", values: [{ type: 0, date: "", minitue: 0 }] },
            { fieldid: "59", values: [{ srno: "1", id: parseInt(this.state.addTaskProjectId), value: this.state.project_name, type: "project" }] },
            { fieldid: "61", values: [{ srno: "1", id: this.state.taskOwner.id.toString(), value: this.state.taskOwner.email, name: this.state.taskOwner.name }] },
        ]
        let req = {
            taskdata: taskData,
            repeat: repeatObj,
            sidebarParams: {
                id: parseInt(this.state.addTaskProjectId),
                type: "tasks",
                recordtype: "project",
            },
        }
        this.props.saveTaskDetail(this.props.apolloClient.client, req);

        this.CancelTaskPopup()

    };

    handleBlur = (event, ref) => {
        this[ref].validate(event.target.value);
    }

    handleChangeTaskOwner = (val, name) => {
        this.setState({
            taskOwner: val.values,
        })
    }

    handleTaskDialogClose = () => {
        this.setState({ shouldOpenCreateTaskDialog: false, FullCreateTaskDialog: false, minimizeTaskDialog: false });
    };

    handleTaskDialogMinimize = () => {
        this.setState(oldStateminimizeTaskDialog => ({ minimizeTaskDialog: !oldStateminimizeTaskDialog.minimizeTaskDialog }));
        this.setState({ FullCreateTaskDialog: false })
    };

    handleTaskDialogFull = () => {
        this.setState(oldStateFullCreateTaskDialog => ({ FullCreateTaskDialog: !oldStateFullCreateTaskDialog.FullCreateTaskDialog }));
        this.setState({ minimizeTaskDialog: false })

    };

    openFullTaskPopup = () => {
        this.setState({
            newTaskData: {
                id: parseInt(this.state.addTaskProjectId),
                type: "tasks",
                recordtype: "project",
            },
            showTskpopup: false,
        }, () => {
            this.setState({
                shouldOpenCreateTaskDialog: true
            }, () => {
                setTimeout(() => {
                    this.makeTAskFieldBlank();
                }, 2000);
            })
        })
    };

    makeTAskFieldBlank = () => {
        this.setState({
            project_name: "",
            taskDueDate: new Date(),
            taskName: "",
            taskOwner: "",
        })
    }

    toggleTaskpopup = () => {
        this.setState({ showTskpopup: !this.state.showTskpopup });
    };

    toggleCompletedTask = () => {
        this.setState({ displayCompletedTask: !this.state.displayCompletedTask });
    };

    CancelTaskPopup = () => {
        this.setState({
            showTskpopup: false,
        })
        this.makeTAskFieldBlank();
    };

    handleCompleteTask = (task1, status1) => {
        let taskCompleteParams = [];
        taskCompleteParams['complete'] = { status: !status1, taskid: parseFloat(task1) };
        taskCompleteParams['sidebarParams'] = {
            id: parseInt(this.state.addTaskProjectId),
            type: "tasks",
            recordtype: "project",
        };
        this.props.taskComplete(this.props.apolloClient.client, taskCompleteParams);
        this.setState({ isDataLoading: true });
        setTimeout(() => {
            this.setState({ isDataLoading: false });
        }, 1000);
    }

    closeDrawerRightTask = (e) => {
        this.setState({
            drawerOpenTask: false,
        });
    };

    handleDrawerTaskRight = (task_id) => {
        if (this.state.task_id && (this.state.task_id === task_id)) { } else {
            this.setState({
                task_id: task_id,
                isDataLoading: true,
            }, () => {
                this.forceUpdate()
            })
        }

        setTimeout(() => {
            this.setState({
                drawerOpenTask: true,
            }, () => {
                this.forceUpdate()
            })
        }, 2000);

    };
    toggleProjectList = () => {
        this.setState(oldStateProjectList => ({ isOpenedProjectList: !oldStateProjectList.isOpenedProjectList }));
    }

    render() {
        let { isOpenedProjectList, projectLogs, drawerOpenTask, isOpenedFiltersDefault, isOpenedPipelines, isOpenedFilters, isOpenedCustomFilters, isOpenedRange, isOpenedPipline, switchView, drawerOpen } = this.state;
        var localStorageFilter = localStorage.getItem('selected_project_filter_id');
        let updateSaveFilterBtnDisabled = (this.state.filterdata == undefined || !this.state.filterdata.length) ? ((this.state.oldFilterOption == undefined || !this.state.oldFilterOption.length) ? true : false) : false
        const CustomLaneHeader = ({ label, title, current, target }) => {
            return (
                <React.Fragment>
                    <header className="trello-header">
                        <div className="d-flex justify-content-between">
                            <h4 className="pipline-name">{title}</h4>
                            <span className="card-count">{label}</span>
                        </div>
                        <LinearProgress variant="determinate" className={`trello-progress ${target}`} value={current} />
                    </header>
                </React.Fragment>
            )
        }

        var dtFormat = "MM/dd/yyyy";
        if (this.state.userSettings != "") {
            dtFormat = this.state.userSettings.dateformat;
            dtFormat = dtFormat.replace("DD", "dd").replace("YYYY", "yyyy");
        }

        var selectedDate = this.state.taskDueDate;
        let completedTaskList = []
        let inCompleteTaskList = []
        {
            this.state.taskListArray.map((item, key) => {
                if (item.__taskdetail__.length > 0) {
                    var cName = ""
                    var relatedToID = ""
                    var relatedToType = ""
                    var relatedToValue = ""
                    var owner = ""
                    var activity_icon = ""
                    var dueDate = ""

                    item.__taskdetail__.map((data, key1) => {
                        if (data.__field__.id == "57") { //name
                            cName = data.fieldvalue;
                        }
                        if (data.__field__.id == "59" && data.values.length > 0) { //related to
                            relatedToID = data.values[0].id
                            relatedToType = data.values[0].type
                            relatedToValue = data.values[0].value
                        }
                        if (data.__field__.id == "61" && data.values.length > 0) { //owner
                            owner = data.values[0].name
                        }
                        if (data.__field__.id == "58" && data.values.length > 0) { //Activity type
                            activity_icon = data.values[0].icon;
                            var k = this.findWithAttr(this.state.masterActivityTypesList, 'id', data.values[0].id)
                            if (k != -1) {
                                activity_icon = this.state.masterActivityTypesList[k].icon;
                            }
                        }
                        if (data.__field__.id == "60" && data.fieldvalue) { //due date
                            dueDate = moment.unix(data.fieldvalue).tz(this.state.timeZone).format(this.state.dateformat)
                        }
                    })
                    if (item.iscomplete == false) {
                        completedTaskList.push(<>
                            <div className="box_portlet mt-10" style={{ border: 'none' }}>
                                <div className="box_body" style={{ padding: '0px' }}>
                                    <div className="lead_pl">
                                        <div className="icon_left" style={{ left: '-4px' }}>
                                            <Icon onClick={(task1, status1) => this.handleCompleteTask(item.id, item.iscomplete)} style={{ cursor: 'pointer', color: '#7467ef' }}>{(item.iscomplete == true) ? 'check_box' : 'check_box_outline_blank'}</Icon>
                                        </div>
                                        <Grid container justify="space-between">
                                            <a className="m-0 taskNameHoverr" onClick={() => this.handleDrawerTaskRight(item.id)} style={{ cursor: 'pointer', fontSize: '14px', color: '#000' }}>{cName}</a>
                                        </Grid>
                                        {relatedToID && relatedToType && <Link style={{ cursor: 'pointer', fontSize: '15px', color: '#7467ef' }} to={"/" + relatedToType + "/" + relatedToType + "/" + relatedToID}>{relatedToValue}</Link>}
                                        {owner && <> <Link style={{ cursor: 'pointer', fontSize: '15px', color: '#7467ef', marginRight: '15px' }}>| Owner :{owner}</Link></>}

                                    </div>
                                </div>
                            </div> </>
                        )
                    } else {
                        inCompleteTaskList.push(<>
                            <div className="box_portlet mt-10" style={{ border: 'none' }}>
                                <div className="box_body" style={{ padding: '0px' }}>
                                    <div className="lead_pl">
                                        <div className="icon_left" style={{ left: '-4px' }}>
                                            <Icon onClick={(task1, status1) => this.handleCompleteTask(item.id, item.iscomplete)} style={{ cursor: 'pointer', color: '#7467ef' }}>{(item.iscomplete == true) ? 'check_box' : 'check_box_outline_blank'}</Icon>
                                        </div>
                                        <Grid container justify="space-between">
                                            <a className="m-0 taskNameHoverr" onClick={() => this.handleDrawerTaskRight(item.id)} style={{ cursor: 'pointer', fontSize: '14px', color: '#000' }}>{cName}</a>
                                        </Grid>
                                        {relatedToID && relatedToType && <Link style={{ cursor: 'pointer', fontSize: '15px', color: '#7467ef' }} to={"/" + relatedToType + "/" + relatedToType + "/" + relatedToID}>{relatedToValue}</Link>}
                                        {owner && <> <Link style={{ cursor: 'pointer', fontSize: '15px', color: '#7467ef', marginRight: '15px' }}>| Owner :{owner}</Link></>}

                                    </div>
                                </div>
                            </div> </>
                        )
                    }
                }

            })
        }
        return (
            <div className="opportunities_card">
                <div className="task_popup" id="task_popup" style={{ display: 'none' }}>
                    <div className="nub" onClick={this.closeTaskPopup}>
                        <Icon>close</Icon>
                        <div className="border_cover"></div>
                    </div>
                    <div className="tasks_title">Tasks <Icon onClick={() => this.toggleTaskpopup()} className={this.state.showTskpopup ? "plusIconTransform add_task" : "add_task"}>add</Icon></div>
                    {this.state.isLoadingRelatedTask && <CircularProgress style={{ marginBottom: '15%', marginLeft: '48%', marginTop: '19%' }} size={20} />}
                    {!this.state.isLoadingRelatedTask && !this.state.showTskpopup && completedTaskList.length > 0 &&
                        <div className="empty_text" style={{ textAlign: 'inherit' }}>
                            {completedTaskList}
                        </div>
                    }
                    {!this.state.isLoadingRelatedTask && completedTaskList && completedTaskList.length == 0 && !this.state.showTskpopup && !this.state.isLoadingRelatedTask &&
                        <><div className="empty_text">
                            No scheduled Tasks
                            <div className="task_button">
                                <Button className="btn_Primary px-12" onClick={() => this.toggleTaskpopup()} size="small">Add Task</Button>
                            </div>
                        </div></>
                    }
                    {!this.state.isLoadingRelatedTask && inCompleteTaskList.length > 0 &&
                        <>
                            {!this.state.showTskpopup && !this.state.displayCompletedTask && <a style={{ padding: '10px', cursor: 'pointer', fontSize: '15px', color: '#7467ef' }} here="#" onClick={() => this.toggleCompletedTask()}>{inCompleteTaskList.length} completed tasks <FontAwesome className="fa-angle-down" /></a>}
                            {!this.state.showTskpopup && this.state.displayCompletedTask && <a style={{ padding: '10px', cursor: 'pointer', fontSize: '15px', color: '#7467ef' }} here="#" onClick={() => this.toggleCompletedTask()}>hide completed tasks <FontAwesome className="fa-angle-up" /></a>}
                        </>
                    }
                    {!this.state.showTskpopup && !this.state.isLoadingRelatedTask && this.state.displayCompletedTask && inCompleteTaskList.length > 0 &&
                        <div className="empty_text" style={{ textAlign: 'inherit' }}>
                            {inCompleteTaskList}
                        </div>
                    }
                    {this.state.showTskpopup &&
                        <div className="empty_text" style={{ marginBottom: '4px' }}>
                            <ValidatorForm noValidate instantValidate={false} onSubmit={this.handleFormSubmitAddTask}>
                                <Grid container spacing={2}>
                                    <TextValidator
                                        ref={ref => this['taskName'] = ref}
                                        onBlur={(event) => this.handleBlur(event, 'taskName')}
                                        type="text"
                                        id={"taskName"}
                                        required={true}
                                        className="w-100 input_bx"
                                        placeholder={"Task Name"}
                                        onChange={this.handleChangeTaskField}
                                        name={"taskName"}
                                        value={this.state.taskName}
                                        validators={["required"]}
                                        errorMessages={["This field is required"]}
                                    />

                                    <Grid container spacing={3} style={{ marginTop: '0px' }}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid item xs={6}>
                                                <FormControl className="w-100 input_bx">
                                                    <KeyboardDatePicker
                                                        name={"taskDueDate"}
                                                        format={dtFormat}
                                                        placeholder={dtFormat}
                                                        error={(selectedDate == "Invalid Date") ? true : false}
                                                        value={this.state.taskDueDate}
                                                        onChange={this.handleDtChange}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                        helperText={(selectedDate == "Invalid Date") ? "This field is required" : (selectedDate == "Invalid Date") ? "Please enter a valid date." : ""}

                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <KeyboardTimePicker
                                                    format="hh:mm a"
                                                    placeholder={"hh:mm a"}
                                                    error={(selectedDate == "Invalid Date") ? true : false}
                                                    value={this.state.taskDueDate}
                                                    onChange={this.handleDtChange}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                    helperText={(selectedDate == "Invalid Date") ? "This field is required" : (selectedDate == "Invalid Date") ? "Please enter a valid date." : ""}
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <FormControl className="w-100 input_bx">
                                        <Autocomplete
                                            disableOpenOnFocus
                                            forcePopupIcon={false}
                                            name={"taskOwner"}
                                            options={this.state.usersListArray}
                                            getOptionLabel={option => option.name ? option.name : " "  }
                                            value={this.state.taskOwner}
                                            name={"taskOwner"}
                                            onChange={(event, value) => this.handleChangeTaskOwner({ fieldid: "id", values: value }, "taskOwner")}
                                            id={"taskOwner"}
                                            renderInput={params => (
                                                <TextValidator
                                                    ref={ref => this["taskOwner"] = ref}
                                                    onBlur={(event) => this.handleBlur(event, "taskOwner")}
                                                    {...params}
                                                    variant="standard"
                                                    placeholder={"Owner"}
                                                    fullWidth
                                                    name={"taskOwner"}
                                                    value={this.state.taskOwner && this.state.taskOwner.length ? 1 : null}
                                                    required={true}
                                                    validators={((this.state.taskOwner == null || this.state.taskOwner.length == 0)) ? ["required"] : []}
                                                    errorMessages={((this.state.taskOwner == null || this.state.taskOwner.length == 0)) ? ["This field is required"] : []}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <div style={{ marginTop: '24px', textAlign: 'end' }}>
                                    <a style={{ cursor: 'pointer', fontSize: '13px', color: '#005E9D', marginRight: '20px' }} here="#" onClick={() => this.openFullTaskPopup()}>Edit Full Details</a>
                                    <Button onClick={() => this.CancelTaskPopup()} variant="contained">Cancel</Button>
                                    <Button style={{ margin: '5px' }} variant="contained" type="submit" color="primary">Save</Button>
                                </div>
                            </ValidatorForm>
                        </div>
                    }
                </div>
                <aside className="aside_left" style={{ overflowY: 'scroll', overflow: 'auto' }}>
                    <Card elevation={3} className="pt-16">
                        <h5 onClick={this.toggleProjectList} className="cursor_poiter d-flex justify-content-between pl-16 pr-16">
                            <TooltipNew title={this.state.displaySelectedFilterName} >
                                <div className="font-weight-500">
                                    <span style={{ maxWidth: '150px' }} className="text_ellipsis "> {this.state.displaySelectedFilterName} </span>
                                    <span className="ml-4"> ({this.state.dataCount})</span>
                                </div>
                            </TooltipNew>
                            <Icon className="vert-middle flex-end">{isOpenedProjectList ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                        </h5>
                        {isOpenedProjectList &&
                            <div className="filter_menu_custom">
                                <h6 className="mt-8 font-size-14 mb-0 pl-16 pr-16">Private Filters </h6>
                                <ul>
                                    {this.state.projectFilterListArray.length > 0 && this.state.projectFilterListArray.map((item, key) => {
                                        let star_icon = item.isdefault ? "star" : "star_border";
                                        if (item.fitertype == 1 && item.id != localStorage.getItem('selected_project_filter_id')) {
                                            return <li key={item.key}>
                                                <div onClick={() => this.handleFilterChange(item)}>{item.filtername}</div>
                                                <><IconButton className="text-secondary ml-4" size="small">
                                                    <Icon onClick={() => this.updateProjectFilter(item)} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{'settings'}</Icon>
                                                </IconButton>
                                                    <IconButton className="text-secondary ml-4" size="small">
                                                        <Icon onClick={() => this.setState({ isDeleteFilter: true, filterDeleteId: item.id })} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{'delete'}</Icon>
                                                    </IconButton>
                                                    <IconButton className="text-secondary ml-4" size="small">
                                                        <Icon onClick={() => this.updateFilterDefaultProject(item)} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{star_icon}</Icon>
                                                    </IconButton>
                                                </>
                                            </li>
                                        }
                                    }
                                    )}
                                </ul>
                                <h6 className="mt-8 font-size-14 mb-0 mt-16 pl-16 pr-16">Public Filters</h6>
                                <ul>
                                    {this.state.projectFilterListArray.length > 0 && this.state.projectFilterListArray.map((items, key) => {
                                        let star_icon = items.isdefault ? "star" : "star_border";
                                        if (items.fitertype == 0 && items.id != localStorage.getItem('selected_project_filter_id')) {
                                            return <li key={items.key}>
                                                <div onClick={() => this.handleFilterChange(items)}>{items.filtername}</div>
                                                {!["21", "22", "23"].includes(items.id) &&
                                                    <><IconButton className="text-secondary ml-4" size="small">
                                                        <Icon onClick={() => this.updateProjectFilter(items)} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{'settings'}</Icon>
                                                    </IconButton>
                                                        <IconButton className="text-secondary ml-4" size="small">
                                                            <Icon onClick={() => this.setState({ isDeleteFilter: true, filterDeleteId: items.id })} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{'delete'}</Icon>
                                                        </IconButton></>
                                                }
                                                <IconButton className="text-secondary ml-4" size="small">
                                                    <Icon onClick={() => this.updateFilterDefaultProject(items)} style={{ marginTop: '5px', display: 'contents', fontSize: '18px' }}>{star_icon}</Icon>
                                                </IconButton>
                                            </li>
                                        }
                                    }
                                    )}
                                </ul>

                                <><Dialog
                                    fullWidth={true}
                                    maxWidth={'xs'}
                                    open={this.state.isDeleteFilter}
                                    onClose={this.filterRemoveDialoge}
                                    disableBackdropClick

                                    aria-labelledby="ar-remove-dialog-title"
                                >
                                    <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to delete filter ?"}</DialogTitle>
                                    <DialogActions>
                                        <Button onClick={this.filterRemoveDialoge} color="primary">Cancel</Button>
                                        <Button type="Button" onClick={() => this.confirmDeleteFilter()} className="btn_Primary">
                                            Ok
                                    </Button>
                                    </DialogActions>
                                </Dialog></>
                            </div>
                        }
                    </Card>
                    <Card elevation={3} className={`pt-16 pb-16`} style={{ marginTop: '5px' }}>
                        <div className="ml-16 mr-16">
                            <Button fullWidth className="btn_Primary" size="small" onClick={this.toggleCustomFilters}><Icon>add</Icon> Add Custom Filters</Button>
                        </div>
                        {isOpenedCustomFilters &&
                            <React.Fragment>
                                <div className="v_scroll position-relative ml-16 mt-16 pr-16">
                                    <Link className="text_link" onClick={() => this.handleFilterChange(this.state.projectFilterListArray[this.state.AllProjectFilterKey])}>Clear All</Link>
                                    {FilterComponent(this)}
                                </div>
                                <div className="fixed-bottom" style={{ position: 'initial' }}>
                                    <Button type="button" className="btn_Primary  mb-16 px-12" size="small" onClick={() => this.handleSavefilterDialoge()}>Save Filter</Button>
                                    {localStorageFilter && !["21", "22", "23"].includes(localStorageFilter) &&
                                        <Button type="button" className="btn_Primary  mb-16 px-12 ml-16" size="small" onClick={() => this.updateFilterOptions()}>Update Filter</Button>
                                    }
                                </div>
                            </React.Fragment>
                        }
                    </Card>
                </aside>
                <Card elevation={3} className="fixed_layout" style={{ bottom: "0px" }}>
                    <Grid container justify="space-between" className="title-with-label pr-16">
                        <Grid item>
                            <Grid container justify="flex-start" alignItems="center" spacing={2}>
                                <Grid item>
                                    <h3 className="ml-16">Projects</h3>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        value="cardview"
                                        control={<Switch color="primary" checked={switchView} name="switchView" onChange={this.handleChange} value="switchView" />}
                                        label="Cardview"
                                        labelPlacement="start"
                                    />
                                </Grid>
                                <Grid item style={{ margin: 'inherit' }}>
                                    <span style={{
                                        fontSize: '1rem',
                                        fontWeight: '400',
                                        lineHeight: '1.5',
                                        marginLeft: '16px',
                                        letterSpacing: '0.00938em'
                                    }}>Sort By</span>
                                    <DropdownDefault
                                        menuButton={
                                            <p style={{
                                                fontSize: '1rem',
                                                fontWeight: '500',
                                                cursor: "pointer",
                                                lineHeight: '1.5',
                                                marginLeft: '5px',
                                                letterSpacing: '0.00938em'
                                            }}
                                            >
                                                {this.state.selectedSortByName}
                                            </p>
                                        }
                                    >
                                        <MenuItem onClick={() => this.changeSortOption('name', 'Name', 67)} className="flex flex-middle">Name</MenuItem>
                                        <MenuItem onClick={() => this.changeSortOption('owner', 'Owned By', 69)} className="flex flex-middle">Owned By</MenuItem>
                                    </DropdownDefault>
                                    <Icon className={`text-muted ${(this.state.sorttype == 'asc') ? 'sortTransform' : ''}`} onClick={() => this.changeSortType()} style={{ marginLeft: '5px', position: 'absolute', marginTop: '16px', cursor: 'pointer' }}>sort</Icon>

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container justify="flex-start" alignItems="center" spacing={2}>
                                <Grid item>
                                    <TextField
                                        id="input-with-icon-textfield"
                                        className="search_bx"
                                        placeholder="Search..."
                                        name="searchtext"
                                        onChange={this.handleSearch}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <DropdownDefault
                                        menuButton={
                                            <Button variant="contained" color="primary" className="btn_Primary" size="small">
                                                Actions
                                            </Button>
                                        }
                                    >
                                        {/* <MenuItem className="flex flex-middle">
                                            <Icon className="mr-16">view_column</Icon> Customize columns
                                        </MenuItem> */}
                                        <Link to={'/settings/settings'}>
                                            <MenuItem className="flex flex-middle">
                                                <Icon className="mr-16">format_list_numbered</Icon> Create custom fileds
                                         </MenuItem>
                                        </Link>
                                    </DropdownDefault>
                                </Grid>
                                {/* <Grid item>
                                    <Button variant="contained" size="small">
                                        Import
                                    </Button>
                                </Grid> */}
                                <Grid item>
                                    {this.state.crudPermission.iscreate &&
                                        <Button variant="contained" onClick={() => this.setState({ shouldOpenCreateProjectDialog: true })} color="secondary" size="small" style={{ minWidth: 95 }}>
                                            Create New
                                    </Button>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className={(isOpenedPipline == true) ? "crm-opportunities-dnd" : "opportunities-dnd-project"} >
                        <Board
                            data={this.state.lanesData}
                            handleDragStart={this.handleDragStart}
                            handleDragEnd={this.handleDragEnd}
                            onCardClick={this.handleDrawerRight}
                            laneDraggable={false}
                            hideCardDeleteIcon={true}
                            components={{ LaneHeader: CustomLaneHeader }}
                            eventBusHandle={setEventBus} />
                    </div>
                </Card>
                <Dialog
                    fullWidth={true}
                    maxWidth={'xs'}
                    open={this.state.filterSaveDialoge}
                    onClose={() => this.handleSavefilterDialogeClose()}
                    disableBackdropClick

                    aria-labelledby="delele-dialog-title"
                >
                    <DialogTitle id="">
                        {!this.state.filterUpdateId ? "Add a New Filter" : "Edit Filter"}
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <ValidatorForm instantValidate={false} ref="form">
                            <FormControl error={this.state.filternameError} className="w-100 input_bx">
                                <InputLabel htmlFor="component-error">Name</InputLabel>
                                <Input
                                    type="text"
                                    id={'filtername'}
                                    label={'Filter Name'}
                                    className="w-100 input_bx"
                                    onChange={(event) => this.handleChange(event)}
                                    name={'filtername'}
                                    value={this.state.filtername}
                                />
                                {this.state.filternameError && <FormHelperText id="component-error-text">This field is required</FormHelperText>}
                            </FormControl>
                            <br /><br />
                            <div>
                                <span>visibility</span>
                                <br />
                                <RadioGroup name="fitertype" value={this.state.fitertype} onChange={(event) => this.handleChange(event)} row>
                                    <FormControlLabel
                                        value="1"
                                        control={<Radio color="primary" />}
                                        label="Private"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="0"
                                        control={<Radio color="primary" />}
                                        label="Public"
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </div>

                        </ValidatorForm>
                    </DialogContent>
                    <DialogActions>
                        {updateSaveFilterBtnDisabled && <p className="lead-filter-error">Please select filter options to save filter</p>}
                        <Button onClick={() => this.handleSavefilterDialogeClose()} color="primary">
                            Cancel
                        </Button>
                        {!this.state.filterUpdateId && <Button variant="contained" disabled={updateSaveFilterBtnDisabled} type="button" color="primary" onClick={() => this.handleSavefilter()}>Save</Button>}
                        {this.state.filterUpdateId && <Button variant="contained" disabled={updateSaveFilterBtnDisabled} type="button" color="primary" onClick={() => this.handleUpdatefilter()}>Update</Button>}
                    </DialogActions>
                </Dialog>
                <Drawer
                    width={"100px"}
                    variant="temporary"
                    anchor={"right"}
                    className="drawer_right"
                    open={drawerOpen}
                    onClose={this.closeDrawerRight}
                    ModalProps={{
                        keepMounted: true
                    }}
                >
                    {this.state.project_id && <ProjectProfileFrame project_id={this.state.project_id} handleClose={this.closeDrawerRight} projectLogs={projectLogs} />}
                </Drawer>
                {this.state.shouldOpenCreateProjectDialog && (
                    <CreateNewProjectDialog
                        handleClose={this.handleProjectDialogClose}
                        open={this.state.shouldOpenCreateProjectDialog}
                        handleMinimize={this.handleProjectDialogMinimize}
                        handleFull={this.handleProjectDialogFull}
                        minimizeScreen={this.state.minimizeProjectDialog}
                        fullScreen={this.state.FullCreateProjectDialog}
                    />
                )}
                {this.state.isDataLoading &&
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        open={this.state.isDataLoading}
                        TransitionComponent={Fade}
                        message={<><CircularProgress size={20} style={{ color: 'white' }} /><span style={{ paddingLeft: '6px', color: 'white' }}>{" Loading..."}</span></>}
                        variant={'success'}
                    />
                }
                {this.state.shouldOpenCreateTaskDialog && (
                    <CreateNewTaskDialog
                        handleClose={this.handleTaskDialogClose}
                        open={this.state.shouldOpenCreateTaskDialog}
                        handleMinimize={this.handleTaskDialogMinimize}
                        handleFull={this.handleTaskDialogFull}
                        minimizeScreen={this.state.minimizeTaskDialog}
                        fullScreen={this.state.FullCreateTaskDialog}
                        dataFromSidebar={this.state.newTaskData}
                        taskNm={this.state.taskName}
                        taskDueDate={this.state.taskDueDate}
                    />
                )}
                <Drawer
                    width={"100px"}
                    variant="temporary"
                    anchor={"right"}
                    className="drawer_right"
                    open={drawerOpenTask}
                    onClose={this.closeDrawerRightTask}
                    ModalProps={{
                        keepMounted: true
                    }}
                >
                    {this.state.task_id && <TaskProfileFrame task_id={parseInt(this.state.task_id)} handleClose={this.closeDrawerRightTask} />}
                </Drawer>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
    notification: state.notification,
    taskReducer: state.taskReducer,
    projectReducer: state.projectReducer,
    authReducer: state.authReducer

});

const mapDispatchToProps = dispatch => {
    return {
        getProjectStage: (client) => dispatch(actionCreators.getProjectStage(client)),
        getProjectFilterList: (client, request) => { dispatch(actionCreators.getProjectFilterList(client, request)) },
        getCityList: (client) => { dispatch(actionCreators.getCityList(client)) },
        getStateList: (client) => { dispatch(actionCreators.getStateList(client)) },
        getZipcodeList: (client) => { dispatch(actionCreators.getZipcodeList(client)) },
        getCountryList: (client) => { dispatch(actionCreators.getCountryList(client)) },
        clearNotification: () => dispatch(actionCreators.clearNotification()),
        getTagsList: (client) => dispatch(actionCreators.getTagsList(client)),
        getUserList: (client) => dispatch(actionCreators.getUserList(client)),
        getCompanyCurrency: (client) => dispatch(actionCreators.getCompanyCurrency(client)),
        getUserSetting: (client, data) => dispatch(actionCreators.getUserSetting(client, data)),
        getProjectField: (client, id) => dispatch(actionCreators.getProjectField(client, id)),
        saveFilterDetailProject: (client, request) => { dispatch(actionCreators.saveFilterDetailProject(client, request)) },
        getFilterListProject: (client, recordtypeid) => { dispatch(actionCreators.getFilterListProject(client, recordtypeid)) },
        updateFilterDefaultProject: (client, filterid) => { dispatch(actionCreators.updateFilterDefaultProject(client, filterid)) },
        updateFilterDetailProject: (client, req) => { dispatch(actionCreators.updateFilterDetailProject(client, req)) },
        deleteFilterProject: (client, req) => { dispatch(actionCreators.deleteFilterProject(client, req)) },
        customFieldList: (client, req) => { dispatch(actionCreators.customFieldList(client, req)) },
        updateProjectDetail: (client, request) => { dispatch(actionCreators.updateProjectDetail(client, request)) },
        getActivityTypes: (client) => dispatch(actionCreators.getActivityTypes(client)),
        addFavouriteProject: (client, request) => { dispatch(actionCreators.addFavouriteProject(client, request)) },
        saveTaskDetail: (client, request) => { dispatch(actionCreators.saveTaskDetail(client, request)) },
        getRecordRelatedData: (client, req) => { dispatch(actionCreators.getRecordRelatedData(client, req)) },
        taskComplete: (client, request) => { dispatch(actionCreators.taskComplete(client, request)) },
        getRelatedList: (client) => dispatch(actionCreators.getRelatedList(client)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectCard);
