import React, { Component } from "react";
import { Checkbox, Typography, Card, Grid, Button } from "@material-ui/core";
import { Table } from 'antd';
import BusinessIcon from '@material-ui/icons/Business';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PersonIcon from '@material-ui/icons/Person';
import WorkIcon from '@material-ui/icons/Work';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import * as actions from '../../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';


//icons
import { Icon, InlineIcon } from '@iconify/react';
import userFriends from '@iconify/icons-fa-solid/user-friends';
import crosshairsIcon from '@iconify/icons-fa-solid/crosshairs';
import handshakeIcon from '@iconify/icons-fa-solid/handshake';
import businessTime from '@iconify/icons-fa-solid/business-time';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';


class ExportData extends Component {
    state = {
        leadsettingsstatus: false,
    };

    handleEportData = (recordtypeid) =>{
        let reqParams = {
            recordtypeid: recordtypeid
        }
        this.props.exportRecordData(this.props.apolloClient.client, reqParams);
    }
    componentWillReceiveProps(props){
        if(props.settingReducer.leadsettingslist && props.settingReducer.leadsettingslist.data){
            this.setState({leadsettingslist: props.settingReducer.leadsettingslist.data});

            let leadsettingsstatus = props.settingReducer.leadsettingslist.data.lead;
            this.setState({leadsettingsstatus});
        }
    }
    render() {
        let {leadsettingsstatus} = this.state;
        return (
            <React.Fragment>
                <div className="v_scroll crm-custom-importdata-settings">
                    <div className="mb-40">
                        <Typography className=" p-10" variant="h5" color="inherit">Export Data</Typography>
                        <Typography className="text-hint p-10" variant="p">
                            Export your IDrive CRM data to an Excel file.
                        </Typography>
                    </div>
                    <Card className="p-20 mb-10">
                        <Typography className="font-size-18 p-10" variant="h6" color="inherit">Start a New Export</Typography>
                        <Grid container>
                            {leadsettingsstatus && 
                                <Grid item md={3} className="text-center">
                                    <div>
                                        <Icon icon={crosshairsIcon} className="text-hint" style={{ fontSize: '70px' }}></Icon>
                                    </div>
                                    <div>
                                        <Button variant="contained" className="btn_Primary" color="primary" onClick={(recordtypeid) => this.handleEportData(1)} size="small">EXPORT LEAD</Button>
                                    </div>
                                </Grid>
                            }
                            <Grid item md={3} className="text-center">
                                <div>
                                    <Icon icon={userFriends} className="text-hint" style={{ fontSize: '70px' }}></Icon>
                                </div>
                                <div>
                                    <Button variant="contained" className="btn_Primary" color="primary" onClick={(recordtypeid)=>this.handleEportData(2)} size="small">EXPORT CONTACTS</Button>
                                </div>
                            </Grid>

                            <Grid item md={3} className="text-center">
                                <div>
                                    <BusinessIcon className="text-hint font-size-100" style={{ fontSize: '70px' }} />
                                </div>
                                <div>
                                    <Button variant="contained" className="btn_Primary" color="primary" onClick={(recordtypeid) => this.handleEportData(3)}  size="small">EXPORT COMPANIES</Button>
                                </div>
                            </Grid>
                            
                            <Grid item md={3} className="text-center">
                                <div>
                                    <Icon icon={handshakeIcon} className="text-hint" style={{ fontSize: '70px' }}></Icon>
                                </div>
                                <div>
                                    <Button variant="contained" className="btn_Primary" color="primary" onClick={(recordtypeid) => this.handleEportData(4)}  size="small">EXPORT OPPORTUNITIES</Button>
                                </div>
                            </Grid>

                            <Grid item md={3} className="text-center">
                                <div>
                                    <Icon icon={businessTime} className={leadsettingsstatus ? "text-hint mt-20" : "text-hint"} style={{ fontSize: '70px' }}></Icon>
                                </div>
                                <div>
                                    <Button variant="contained" className="btn_Primary" color="primary" onClick={(recordtypeid) => this.handleEportData(5)}  size="small">EXPORT PROJECTS</Button>
                                </div>
                            </Grid>
                            
                            <Grid item md={3} className="text-center">
                                <div>
                                    <EventAvailableIcon className="text-hint mt-20" style={{ fontSize: '70px' }} />
                                </div>
                                <div>
                                    <Button variant="contained" className="btn_Primary" color="primary" onClick={(recordtypeid) => this.handleEportData(6)}  size="small">EXPORT TASKS</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Card>                    
                </div>
            </React.Fragment>
        );
    }
}


const mapStateToProps = state => {
    return {
        // leadReducer: state.leadReducer,
        settingReducer: state.settingReducer,
        apolloClient: state.apolloClient
    };
};

const mapDispatchToProps = dispatch => {
    return {
        // customFieldList: (client, request) => { dispatch(actions.customFieldList(client, request)) },
        // importDataFile: (client, req) => { dispatch(actions.importDataFile(client, req)) },
        exportRecordData: (client, req) => { dispatch(actions.exportRecordData(client, req)) },
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ExportData);