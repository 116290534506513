import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Checkbox, List, ListItem, IconButton, InputLabel, Icon, Grid, Menu, withStyles, Typography, MenuItem, Avatar, Fab, Button, Tabs, Tab, Box, Dialog, DialogTitle, DialogActions, Tooltip } from "@material-ui/core";
import DropdownDefault from "../Layout/DropdownDefault";
import TaskProfileDetails from "./TaskProfileDetails";
import moment from 'moment';
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import FontAwesome from "react-fontawesome";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import SystemLog from "../CommonLogUI/SystemLog"
import NoteLog from "../CommonLogUI/NoteLog"
import CreatelogDialog from "./CreatelogDialog"
import FilterLogoCommon from "../CommonLogUI/FilterLogoCommon";
const IconButtonWhite = withStyles(theme => ({
  root: {
    // color: theme.palette.getContrastText(purple[500]),
    backgroundColor: "transparent",
    color: "#F2BF58",
    padding: "5px"
  }
}))(IconButton);

const IconSmall = withStyles(() => ({
  root: {
    fontSize: "1.3rem",
  }
}))(Icon);

const TooltipNew = withStyles((theme) => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={2}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

class FiltersMenu extends Component {
  state = {
    commentid: '',
    Commentoptions: '',
    showCommentoptions: false,
    activities: [],
    typeArray: [],
    activityArray: [],
  };

  componentDidMount() {

    let typeArray1 = this.state.typeArray;
    let activityArray1 = this.state.activityArray;

    typeArray1 = [1, 2, 3, 4, 20, 21, 5];

    this.setState({ typeArray: typeArray1 });
    this.props.thisData.setState({ logtypeid: typeArray1 });
  }

  handleClick = (event) => {
    this.setState({ Commentoptions: event.target, showCommentoptions: true });
  }

  handleCloseOptions = (event) => {
    this.setState({ Commentoptions: null, showCommentoptions: false });
  }

  handleMenuClick = (e, value, type) => {
    let typeArray1 = this.state.typeArray;
    let activityArray1 = this.state.activityArray;
    var promise = new Promise((resolve, reject) => {
      if (e.target.checked == true) {
        if (type == 0) {

          if (activityArray1.indexOf(value) == -1)
            activityArray1.push(value);

        } else if (type == 1000) {

          typeArray1 = [1, 2, 3, 4, 20, 21, 5];

          let activities = this.state.activities;

          if (activities && activities.length > 0) {
            activities.map((activity) => {
              if (activityArray1.indexOf(parseFloat(activity.id)) == -1) {
                activityArray1.push(parseFloat(activity.id));
              }
            })
          }

        } else if (type == 1001) {

          let pusharr = [1, 2, 3, 4, 20, 21];
          pusharr.map((arr) => {
            if (typeArray1.indexOf(arr) == -1) {
              typeArray1.push(arr);
            }
          })

        }
        else {

          if (typeArray1.indexOf(type) == -1)
            typeArray1.push(type);

        }
      }
      if (e.target.checked == false) {
        if (type == 0) {

          let key1 = activityArray1.indexOf(value);
          activityArray1.splice(key1, 1);

        } else if (type == 1000) {

          typeArray1 = [];
          activityArray1 = [];

        } else if (type == 1001) {

          let pullarr = [1, 2, 3, 4, 20, 21];
          pullarr.map((arr) => {
            if (typeArray1.indexOf(arr) != -1) {
              let ind = typeArray1.indexOf(arr);
              typeArray1.splice(ind, 1);
            }
          })

        } else {
          let key = typeArray1.indexOf(type);
          typeArray1.splice(key, 1);
        }
      }
      resolve(1)
    })

    promise.then(result => {
      this.setState({ typeArray: typeArray1, activityArray: activityArray1 });
      this.props.thisData.setState({ logtypeid: typeArray1, activitypeid: activityArray1 });
      this.props.thisData.handlefiltermain(typeArray1, activityArray1);
    });
  }

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  render() {
    let { typeArray } = this.state;
    let def_typearr = [1, 2, 3, 4, 20, 21, 5];
    let flag_all = true;

    def_typearr.map((def) => {
      if (typeArray.indexOf(def) == -1)
        flag_all = false;
    })

    return (
      <>
        <Grid container justify="space-between">
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <List className="p-0 pull-right mr-20 log_filter_list">
              <ListItem
                aria-label="More"
                aria-owns={this.state.showCommentoptions ? "filters_menu" : undefined}
                aria-haspopup="true"
                onClick={this.handleClick}
                className="font-size-14 cursor_poiter  pr-0"
              >
                Filters <Icon>arrow_drop_down</Icon>
              </ListItem>
              <Menu
                className=""
                id="long-menu"
                anchorEl={this.state.Commentoptions}
                open={this.state.showCommentoptions}
                onClose={this.handleCloseOptions}
                PaperProps={{
                  style: {
                    width: 250
                  }
                }}
              >
                <MenuItem className="filter_items">
                  <Icon className="mr-16 ">storage</Icon> <TooltipNew title='All Activities'>
                    <div className="text_ellipsis " style={{ maxWidth: '72px', fontSize: '14px', margin: '3px 0px 0px 0px', fontWeight: '400' }}>All Activities</div></TooltipNew>
                  <Checkbox color="primary"
                    disabled={false}
                    className={"crm_log_checkbox_filter checkB_color"}
                    name={'allActivities'}
                    id={'filterCheck'}
                    checked={flag_all}
                    onChange={(event, value) => this.handleMenuClick(event, 1000, 1000)}
                    value={this.state.filterCheck}
                    color="primary"
                  />
                </MenuItem>
                <MenuItem className="filter_items">
                  <Icon className="mr-16 ">subject</Icon><TooltipNew title='Notes'>
                    <div className="text_ellipsis " style={{ maxWidth: '72px', fontSize: '14px', margin: '3px 0px 0px 0px', fontWeight: '400' }}>Notes</div></TooltipNew>
                  <Checkbox color="primary"
                    disabled={false}
                    className={"crm_log_checkbox_filter checkB_color"}
                    name={"note"}
                    id={'filterCheck'}
                    checked={(typeArray.indexOf(5) != -1) ? true : false}
                    onChange={(event, value) => this.handleMenuClick(event, 5, 5)}
                    value={this.state.filterCheck}
                    color="primary"
                  />
                </MenuItem>
                <MenuItem className="filter_items">
                  <Icon className="mr-16 ">storage</Icon> <TooltipNew title='System Updates'>
                    <div className="text_ellipsis " style={{ maxWidth: '72px', fontSize: '14px', margin: '3px 0px 0px 0px', fontWeight: '400' }}>System Updates</div></TooltipNew>
                  <Checkbox color="primary"
                    disabled={false}
                    className={"crm_log_checkbox_filter checkB_color"}
                    name={'sysupdate'}
                    id={'filterCheck'}
                    checked={(typeArray.indexOf(1) != -1) ? true : false}
                    onChange={(event, value) => this.handleMenuClick(event, 1001, 1001)}
                    value={this.state.filterCheck}
                    color="primary"
                  />
                </MenuItem>
              </Menu>
            </List>
          </Grid>
        </Grid>
      </>
    )
  }
}
class TaskProfileFrame extends Component {

  constructor(props) {
    super(props);
    this.state = {
      logtypeid: [],
      activitypeid: [],
      activities: [],
      isOpenedCommentIds: [],
      viewHtmlData: '',
      shouldOpenViewEmailDialog: false,
      FullViewEmailDialog: false,
      minimizeViewEmailDialog: false,
      isOpenedEmailIds: [],
      isEditComment: false,
      commentid: '',
      isEditCommentLog: false,
      Commentoptions: '',
      showCommentoptions: false,
      logvaluetype: '',
      selectedDate: new Date(),
      textvalue: '',
      textvalue1: '',

      FullCreateLeadDialog: false,
      minimizeLeadDialog: false,
      isDeleteLogConfirmDialoOpen: false,
      isEditLog: false,
      logid: '',

      TabValue: 0,
      allLogs: [],
      firstname: "",
      lastname: "",
      isDeleteConfirmDialoOpen: false,
      title: "",
      companyName: "",
      colorClass: '',
      interaction: 0,
      lastcontacted: "--",
      inactivedays: 0,
      dateformat: 'MM/DD/YYYY',
      timeZone: 'America/New_York',
      isfavorite: false,
      star_icon: 'star_border',
      fieldsObj: {},
      fieldsList: [],
      usersListArray: [],
      taskImage: "",
      countryListArray: [],
      systemTagsList: [],
      companyCurrencyData: [],
      userSettings: "",
      taskIcon: "",
      taskcomplete: "",
      userid: "",
      showSelector: false,
    };
  }
  componentDidMount() {
    if (this.props.task_id) {
      this.setState({
        task_id: this.props.task_id,
      }, () => this.forceUpdate());
    }

    let val = { typeId: 6 }
    let userData = JSON.parse(localStorage.getItem('user_data_session'))
    let userid = { userid: userData.id }
    this.props.customFieldList(this.props.apolloClient.client, val);
    this.props.getCountryList(this.props.apolloClient.client);
    this.props.getTagsList(this.props.apolloClient.client);
    this.props.getCompanyCurrency(this.props.apolloClient.client);
    this.setState({
      userid: userData.id
    })
    this.props.getActivityTypes(this.props.apolloClient.client);
  }
  componentWillUnmount() {
    // emailLogEmail  
    this.props.taskReducer.taskFieldData = [];
    this.props.taskReducer.taskLogList = [];
  }

  componentWillReceiveProps(props) {
    if (props.settingReducer.masterActivityTypesList) {
      this.setState({ activities: props.settingReducer.masterActivityTypesList.data });
    }

    if (props.taskReducer.taskLogList && props.taskReducer.taskLogList.data) {
      let logdata = [];

      let pined = props.taskReducer.taskLogList.data.pined;
      let today = props.taskReducer.taskLogList.data.today;
      let yesterday = props.taskReducer.taskLogList.data.yesterday;
      let thisweek = props.taskReducer.taskLogList.data.thisweek;
      let lastweek = props.taskReducer.taskLogList.data.lastweek;
      let thismonth = props.taskReducer.taskLogList.data.thismonth;
      let lastmonth = props.taskReducer.taskLogList.data.lastmonth;
      let earlier = props.taskReducer.taskLogList.data.earlier;

      logdata.push(this.createLogArray(pined, 'Pinned'));
      logdata.push(this.createLogArray(today, 'Today'));
      logdata.push(this.createLogArray(yesterday, 'Yesterday'));
      logdata.push(this.createLogArray(thisweek, 'This week'));
      logdata.push(this.createLogArray(lastweek, 'Last week'));
      logdata.push(this.createLogArray(thismonth, 'This month'));
      logdata.push(this.createLogArray(lastmonth, 'Last month'));
      logdata.push(this.createLogArray(earlier, 'Earlier'));

      this.setState({ allLogs: logdata });
    }
    //storing user setting data in state
    if (props.settingReducer.userSettingArray.data && this.state.userSettings == "") {
      const settingData = props.settingReducer.userSettingArray.data;
      this.setState({
        userSettings: settingData,
      })
    }
    if (props.settingReducer.companyCurrencyData.data != null && this.state.companyCurrencyData.length == 0) {
      var cData = props.settingReducer.companyCurrencyData.data;
      this.setState({ companyCurrencyData: cData[0] });
    }
    if (props.settingReducer.systemTagsList.data && this.state.systemTagsList.length == 0) {
      const tagsData = props.settingReducer.systemTagsList.data;
      this.setState({ systemTagsList: tagsData })
    }
    if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
      const usersListArray = props.settingReducer.usersListArray.data;
      this.setState({ usersListArray: usersListArray })
    }

    if (props.settingReducer.taskCustomFieldList.data && this.state.fieldsList.length == 0) {
      const customFields = props.settingReducer.taskCustomFieldList.data;
      this.setState({ fieldsList: customFields })
    }

    if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
      let usersetting = props.settingReducer.userSettingArray.data;

      let format = usersetting.dateformat;
      let timezone = usersetting.timezone;
      this.setState({ dateformat: format, timeZone: timezone })
    }

    if (props.task_id !== undefined) {
      this.setState({
        task_id: props.task_id,
      }, () => this.forceUpdate());
    }

    if (props.taskReducer.taskFieldData.data && props.taskReducer.taskFieldData.data.length > 0 && props.taskReducer.taskFieldData.is_loaded) {
      var data = props.taskReducer.taskFieldData.data;

      let star_ic = data[0].leadfavourite ? 'star' : 'star_border';
      this.setState({ star_icon: star_ic, isfavorite: data[0].leadfavourite, taskcomplete: data[0].taskcomplete });

      if (data[0].taskdetail && data[0].taskdetail.length > 0 && data[0].taskdetail[0].task) {
        this.setState({
          interaction: data[0].taskdetail[0].task.interactions ? data[0].taskdetail[0].task.interactions : 0,
          lastcontacted: data[0].taskdetail[0].task.lastcontacted ? moment.unix(data[0].taskdetail[0].task.lastcontacted).tz(this.state.timeZone).format(this.state.dateformat) : "--",
          inactivedays: data[0].taskdetail[0].task.inactivedays ? data[0].taskdetail[0].task.inactivedays : 0,
          taskImage: data[0].image,
        })
      }
      var activityKey = this.findWithAttr(data, 'fieldkey', "activitytype");
      let task_icon = '';
      if (activityKey != -1 && data[activityKey].taskdetail.length > 0 && data[activityKey].taskdetail[0].values) {
        task_icon = data[activityKey].taskdetail[0].values[0].icon;
        this.setState({ taskIcon: task_icon });
      }
      else {
        this.setState({ taskIcon: "" });
      }
      var duedateKey = this.findWithAttr(data, 'fieldkey', "duedate");
      let due_date = '';
      if (duedateKey != -1 && data[duedateKey].taskdetail.length > 0) {
        if(moment.unix(data[duedateKey].taskdetail[0].fieldvalue).tz(this.state.userSettings.timezone))
          due_date = moment.unix(data[duedateKey].taskdetail[0].fieldvalue).tz(this.state.userSettings.timezone).format(this.state.userSettings.dateformat);
        
          //due_date = moment.unix(data[duedateKey].taskdetail[0].fieldvalue).format(this.state.userSettings.dateformat).toString();
        this.setState({ due_date: due_date });
      }
      else {
        this.setState({ due_date: "" });
      }
      var nameKey = this.findWithAttr(data, 'fieldkey', "name");
      if (nameKey != -1 && data[nameKey].taskdetail.length > 0 && data[nameKey].taskdetail[0].values) {
        var names = data[nameKey].taskdetail[0].fieldvalue;
        let colorClass = (names) ? names.charAt(0).toLowerCase() : "";
        this.setState({
          firstname: names,
          lastname: "",
          colorClass: colorClass,
        }, () => this.forceUpdate())
      }
      else {
        this.setState({
          firstname: "",
          lastname: "",
          colorClass: "",
        }, () => this.forceUpdate())
      }

      //STRAT setting fieldsObj for update data with task Image

      this.setState({
        fieldsObj: {
        },
        comp_value: '',
        city: '',
        state: '',
        zipcode: '',
      }, () => {
        let fieldsObj = this.state.fieldsObj;
        this.setState({ taskFieldData: props.taskReducer.taskFieldData.data })
        let country = "";
        const taskDataForEdit = props.taskReducer.taskFieldData.data;

        var promise = new Promise((resolve, reject) => {
          taskDataForEdit.map((v, i) => {
            //if(!v.taskdetail.length){return false}
            if (v.fieldtype.id == '1' && ((v.fieldkey == "websites" || v.fieldkey == "social" || v.fieldkey == "phones" || v.fieldkey == "email") && v.is_default)) {
              if (v.taskdetail.length > 0 && v.taskdetail[0].values.length > 0) {
                var data = {
                  fieldid: v.id,
                  values: v.taskdetail[0].values
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }

              }
            } else if (v.fieldtype.id == '1' && v.id == '57') {//for first/last name          
              if (v.taskdetail.length > 0) {
                var data = {
                  fieldid: v.id,
                  taskvalue: v.taskdetail[0].fieldvalue
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }
                this.setState({
                  firstname: v.taskdetail[0].fieldvalue
                })
              }
            }
            else if (v.fieldtype.id == '1' && v.id == '61') {//for owner field        
              if (v.taskdetail.length > 0 && v.taskdetail[0].values.length && v.taskdetail[0].values[0].id) {
                var companyOwnerKey = this.findWithAttr(this.state.usersListArray, 'id', v.taskdetail[0].values[0].id);
                if (companyOwnerKey !== -1) {
                  var ownerData = {
                    fieldid: v.id,
                    values: this.state.usersListArray[companyOwnerKey]
                  }
                  fieldsObj = {
                    ...fieldsObj,
                    [v.fieldkey]: ownerData,
                  }
                }
              }
            } else if (v.fieldtype.id == '1' && v.fieldkey == 'tags') {//for tag input
              var tagArray = []
              if (v.taskdetail.length > 0 && v.taskdetail[0].values.length > 0) {
                v.taskdetail[0].values.map((v, i) => {
                  tagArray.push(v.value);
                })
              }
              var data = {
                fieldid: v.id,
                values: tagArray
              }
              fieldsObj = {
                ...fieldsObj,
                [v.fieldkey]: data,
              }
            } else if (v.fieldtype.id == '1' && v.id == '40') {//For value with currency Field
              if (v.taskdetail.length > 0) {
                var data = {
                  fieldid: v.id,
                  taskvalue: v.taskdetail[0].values[0].value
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }
              }
            } else if (v.fieldtype.id == '1' || v.fieldtype.id == '6' || v.fieldtype.id == '10' || v.fieldtype.id == '7' || v.fieldtype.id == '9') {//other textfield and number input
              if (v.taskdetail.length > 0 && v.taskdetail.length > 0) {
                var data = {
                  fieldid: v.id,
                  taskvalue: v.taskdetail[0].taskvalue
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }
              }
            }

            if (v.fieldtype.id == '2' && v.id == "25") {//Text aread with Address field
              if (v.taskdetail.length > 0 && v.taskdetail[0].values.length > 0) {
                var data = {
                  fieldid: v.id,
                  taskvalue: v.taskdetail[0].values[0].street
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }


                this.setState({
                  city: v.taskdetail[0].values[0].city,
                  state: v.taskdetail[0].values[0].state,
                  zipcode: v.taskdetail[0].values[0].zipcode,
                })

                if (v.taskdetail[0].values[0].country !== "" && this.state.countryListArray.length > 0) {
                  var key = this.findWithAttr(this.state.countryListArray, 'name', v.taskdetail[0].values[0].country);
                  country = this.state.countryListArray[key];
                }
              }
            } else if (v.fieldtype.id == '2') {//TextArea
              if (v.taskdetail.length > 0) {
                var data = {
                  fieldid: v.id,
                  taskvalue: v.taskdetail[0].fieldvalue
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }
              }
            }

            if (v.fieldtype.id == "3") {//checkboxes
              if (v.taskdetail.length > 0) {
                var data = {
                  fieldid: v.id,
                  taskvalue: v.taskdetail[0].fieldvalue
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }
              }
            }
            //if(this.state.fieldsList && this.state.fieldsList.length >0){
            if (v.fieldtype.id === '4') {//dropdown
              //58-contact type/26-visibility
              if (v.id === "65" || v.id === "58") {
                if (v.taskdetail.length > 0 && v.taskdetail[0].values.length > 0) {
                  var optId = this.findWithAttr(v.options, 'id', parseInt(v.taskdetail[0].values[0].id));
                  fieldsObj = {
                    ...fieldsObj,
                    [v.fieldkey]: { fieldid: v.id, values: v.options[optId] },
                  }
                }
              } else if (v.id === "59") {
                if (v.taskdetail.length > 0 && v.taskdetail[0].values.length > 0) {
                  var optId = v.taskdetail[0].values[0].id;
                  this.setState({ comp_value: optId });
                }

              } else {
                if (v.taskdetail.length > 0 && v.taskdetail[0].values.length > 0) {
                  var optId = this.findWithAttr(v.options, 'id', v.taskdetail[0].values[0].id);
                  fieldsObj = {
                    ...fieldsObj,
                    [v.fieldkey]: { fieldid: v.id, values: v.options[optId] },
                  }
                }
              }
            }
            if (v.fieldtype.id === '5') {//multi dropdown
              if (v.taskdetail.length > 0 && v.taskdetail[0].values.length > 0) {
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: { fieldid: v.id, values: v.taskdetail[0].values },
                }
              }
            }
            if (v.fieldtype.id === '8') {//datepicker
              if (v.taskdetail.length > 0 && v.taskdetail[0].fieldvalue != "") {
                //let date_test = moment(v.taskdetail[0].fieldvalue).format('YYYY-MM-DD HH:mm');
                //var dt = moment.tz(date_test, this.state.userSettings.timeZone).format(this.state.userSettings.dateformat).toString()
                // var dt = moment.unix(v.taskdetail[0].fieldvalue).format(this.state.userSettings.dateformat).toString()
                var dt = new Date(moment(v.taskdetail[0].fieldvalue).format(this.state.userSettings.dateformat))
                dt = new Date(dt)
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: { fieldid: v.id, values: dt },
                }
              } else if (v.taskdetail.length > 0 && v.taskdetail[0].fieldvalue == "") {
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: { fieldid: v.id, values: null },
                }
              }
            }
            //}
          })

          this.setState({
            fieldsObj,
            country,
            isUpdate: false,
          }, () => {
            resolve(true)
          })
        });

        promise.then(result => {
          props.taskReducer.taskFieldData.is_loaded = false;
          //      .log("props", this.state.fieldsObj);
        });
      })

      //END setting fieldsObj for update data with task Image
    }
  }


  handleViewEmailDialogClose = () => {
    this.setState({ shouldOpenViewEmailDialog: false, FullViewEmailDialog: false, minimizeViewEmailDialog: false });
  };

  handleViewEmailDialogMinimize = () => {
    this.setState(oldStateminimizeViewEmailDialog => ({ minimizeViewEmailDialog: !oldStateminimizeViewEmailDialog.minimizeViewEmailDialog }));
    this.setState({ FullViewEmailDialog: false })
  };

  handleViewEmailDialogFull = () => {
    this.setState(oldStateFullViewEmailDialog => ({ FullViewEmailDialog: !oldStateFullViewEmailDialog.FullViewEmailDialog }));
    this.setState({ minimizeViewEmailDialog: false })
  };

  handlefiltermain = (types, activities) => {
    this.setState({ logtypeid: types, activitypeid: activities }, () => this.forceUpdate());

    let getfilterLogParam = { taskid: this.state.task_id, logtypeid: types, filterchange: true, }
    this.props.getTaskActivityLogList(this.props.apolloClient.client, getfilterLogParam);
  }

  createLogArray = (partData, partKey) => {
    let logData = [];

    if (partData && partData.length > 0) {
      let logged_in_user_email = JSON.parse(localStorage.getItem("user_data_session")).email;

      partData.map((data) => {
        let pinned = data.ispined;

        let template = data.template;
        let color_class = (data.__user__.name) ? data.__user__.name.charAt(0) : "";
        let text = (data.text) ? data.text : "";
        let loguser = (data.__user__.email == logged_in_user_email) ? 'You' : data.__user__.name;
        let template1 = (data.template) ? template.replace("{{user}}", loguser) : "";

        let new_template = "";

        let create_date = data.createddate;
        let updateddate = data.updateddate;
        if (data.logtype == 1) // create time
        {
          new_template = template1.replace("{{source_type}}", "task");
        }
        else if (data.logtype == 0) // Phone call
        {
          updateddate = data.logdate;
          new_template = template1.replace("{{property_name}}", '"' + data.propertyname + '"');
        }
        else if (data.logtype == 1000) {// RingCentral Call
          updateddate = data.logdate;
          new_template = "Ring Central Calls";
        }
        else if (data.logtype == 2) // name change
        {
          let old_template = template1.replace("{{source_type}}", "task");
          new_template = old_template.replace("{{source name=new_value}}", '"' + data.newvalue + '"');
        }
        else if (data.logtype == 3 && data.__assignuser__) // owner change
        {
          let logAssignUser = (data.__assignuser__.email == logged_in_user_email) ? 'You' : data.__assignuser__.name;
          new_template = template1.replace("{{target}}", '"' + logAssignUser + '"');
        }
        else if (data.logtype == 4) // for status
        {
          let old_template = template1.replace("{{old_value}}", data.oldvalue);
          new_template = old_template.replace("{{new_value}}", data.newvalue);
        }
        else if (data.logtype == 5) // for note
        {
          updateddate = data.logdate;
          new_template = template1.replace("{{property_name}}", '"' + data.propertyname + '"');
        }
        else if (data.logtype == 20) // for note
        {
          new_template = template1.replace("{{source_type}}", "task");
        }
        else if (data.logtype == 21) // for note
        {
          new_template = template1.replace("{{source_type}}", "task");
        }

        logData.push({

          logId: data.id,
          emojidetail: data.emojidetail && data.emojidetail !== null ? JSON.parse(data.emojidetail) : [],
          mentionid: data.mentionid,
          mentiondetail: data.mentiondetail ? data.mentiondetail : "",
          ispined: pinned,
          emailtype: 0,
          comments: data.__commentlist__,
          user: data.__user__.name,
          useremail: data.__user__.email,
          propertyType: data.propertyname,
          color: color_class,
          template: new_template,
          text: text,
          logtype: data.logtype,
          time: (updateddate != null)
            ?
            moment.unix(updateddate).tz(this.state.timeZone).format('hh:mm A')
            :
            moment.unix(create_date).tz(this.state.timeZone).format('hh:mm A'),
          date: (updateddate != null)
            ?
            moment.unix(updateddate).tz(this.state.timeZone).format(this.state.dateformat)
            :
            moment.unix(create_date).tz(this.state.timeZone).format(this.state.dateformat)

        });
      });
    }
    return { key: partKey, datas: logData };
  }

  handlechangeText = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }
  // handlecancelText = (event) => {
  //   alert()
  //   this.setState({ textvalue : ""})
  // }

  handleDateChange = date => {
    this.setState({ selectedDate: date });
  };

  handleSaveEditLog = (data) => {
    let mention = [];
    Object.entries(data.raw.entityMap).map((entity) => {
      if (mention.indexOf(entity[1].data.mention.id) == -1) {
        mention.push(parseFloat(entity[1].data.mention.id))
      }
    });
    // let date = moment(this.state.selectedDate).format("X");

    let date_test = moment(this.state.selectedDate).format('YYYY-MM-DD HH:mm');
    console.log(date_test, 'inserted');

    var logdate = moment.tz(date_test, this.state.timeZone).format();
    console.log(logdate, this.state.timeZone);

    var date = moment.utc(logdate).format('X');
    console.log(date, 'utc');

    console.log(moment.unix(date).tz(this.state.timeZone).format('YYYY-MM-DD hh:mm A'), 'conv');

    // let textvalue = this.state.textvalue;
    let saveLogparams = [];

    saveLogparams['update'] = {
      text: data.textvalue,
      mentionuser: mention,
      mentiondetail: JSON.stringify(data.raw),
      logtime: parseFloat(date),
      logdate: parseFloat(date),
      logid: parseFloat(this.state.logid),
      // propertyid: parseFloat(0)
    };

    saveLogparams['getlog'] = { taskid: parseFloat(this.props.task_id), logtypeid: this.state.logtypeid };

    this.props.updateTaskLog(this.props.apolloClient.client, saveLogparams);
    this.setState({ isEditLog: false })
  }



  handleCancelEdit = () => {
    this.setState({ isEditLog: false })
  }

  //------delete log
  handleDeleteLog = (logid) => {
    this.setState({ logid: logid });
    this.toggleDeleteLogDialog()
  }

  toggleDeleteLogDialog = () => {
    this.setState({ isDeleteLogConfirmDialoOpen: !this.state.isDeleteLogConfirmDialoOpen });
  };

  confirmDeleteLog = () => {
    let deletelogParams = { logid: parseFloat(this.state.logid), taskid: parseFloat(this.state.task_id), logtypeid: this.state.logtypeid };
    this.props.deleteTaskLogDetail(this.props.apolloClient.client, deletelogParams);
    this.toggleDeleteLogDialog()
  };

 

  //email menu functions
  handleEmailHtmlPopupMain = (logdata) => {
    this.setState({ shouldOpenViewEmailDialog: true, viewHtmlData: logdata });
  }

  //- comments-----------------
  toggleComment = (logid) => {
    let isOpenedCommentIds = this.state.isOpenedCommentIds;

    if (isOpenedCommentIds.indexOf(logid) == -1) {
      isOpenedCommentIds.push(logid);
    }
    else {
      let index = isOpenedCommentIds.indexOf(logid);
      isOpenedCommentIds.splice(index, 1);
    }
    this.setState(({ logid: logid, isOpenedCommentIds: isOpenedCommentIds }));
  }

  handleCancelComment = () => {
    this.setState({ commentvalue: '' });
  }

  handleSaveCommentLog = (type) => {
    const textvalue = this.state.commentState.textvalue
    const raw = this.state.commentState.raw
    let mention = [];
    Object.entries(raw.entityMap).map((entity) => {
      if (mention.indexOf(entity[1].data.mention.id) == -1) {
        mention.push(parseFloat(entity[1].data.mention.id))
      }
    });
    let commentParams = {
      comment: this.state.commentvalue,
      logid: parseFloat(this.state.logid),
      taskid: parseFloat(this.state.task_id),
      logtypeid: this.state.logtypeid,
      mentionuser: mention,
      mentiondetail: "",
    };
    this.props.addTaskLogComment(this.props.apolloClient.client, commentParams);
    this.setState({ commentvalue: '' });
  }

  //-edit comment
  handleSaveEditComment = (type) => {
    const textvalue = this.state.commentState.textvalue
    const raw = this.state.commentState.raw
    let mention = [];
    Object.entries(raw.entityMap).map((entity) => {
      if (mention.indexOf(entity[1].data.mention.id) == -1) {
        mention.push(parseFloat(entity[1].data.mention.id))
      }
    });
    let commentParams = {
      commentid: parseFloat(this.state.commentid),
      comment: this.state.commentvalue,
      taskid: parseFloat(this.state.task_id),
      logtypeid: this.state.logtypeid,
      mentionuser: mention,
      mentiondetail: "",
    };
    this.setState({ commentvalue: '', isEditComment: false, commentid: '' });
    this.props.updateTaskLogComment(this.props.apolloClient.client, commentParams);
  }

  handleEditCommentMain = (id, comment) => {
    this.setState({ isEditComment: true, commentvalue: comment, commentid: id });
  }

  handleCancelEditComment = () => {
    this.setState({ isEditComment: false, commentvalue: '' })
  }

  //-delete comment
  deleteComment = (cmt_id, type) => {
    let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
    let commentParams = { commentid: cmt_id, taskid: parseFloat(this.state.task_id), logtypeid: this.state.logtypeid };
    this.props.deleteTaskLogComment(this.props.apolloClient.client, commentParams);
  }
  //--------------comments functions-----------

  //---- email functions
  toggleEmail = (id) => {
    let isOpenedEmailIds = this.state.isOpenedEmailIds;

    if (isOpenedEmailIds.indexOf(id) == -1) {
      isOpenedEmailIds.push(id);
    }
    else {
      let index = isOpenedEmailIds.indexOf(id);
      isOpenedEmailIds.splice(index, 1);
    }

    this.setState(({ logid: id, isOpenedEmailIds: isOpenedEmailIds }));
  }

  toggleReplayEmail = () => {
    this.setState(oldStateReplayEmail => ({ isOpenedReplayEmail: !oldStateReplayEmail.isOpenedReplayEmail }));
  }

  componentWillUnmount() {
    // emailLogEmail
    localStorage.removeItem('emailLogEmail');
  }

  //-----------------------------------------------

  handleSubmit = event => {
    console.log("submitted");
  };

  handlechange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleChange = event => {
    alert(2735427463);
    event.persist();
    this.setState({ [event.target.name]: event.target.value });
  };


  handleChangeTab = (event, newValue) => {
    this.setState({ TabValue: newValue });
  }

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  handleDeleteTask = () => {
    this.toggleDeleteDialog()
  }

  toggleDeleteDialog = () => {
    this.setState({ isDeleteConfirmDialoOpen: !this.state.isDeleteConfirmDialoOpen });
  };

  confirmDeleteCustomField = () => {
    let deleteTaskParams = [];
    deleteTaskParams['deleteid'] = { taskid: [parseFloat(this.state.task_id)] };
    deleteTaskParams['listval'] = {
      filterdata: [],
      list: {
        limit: 10,
        pageno: 1,
        searchtext: "",
        sorttype: "",
        type: "list",
        fieldId: 0,
        columnname: "",
      },
      filterid: 1
    };
    this.toggleDeleteDialog();
    this.props.handleClose();
    this.props.deleteTask(this.props.apolloClient.client, deleteTaskParams);
  };
  handleFavorite = (task, status) => {
    let setFavoriteParams = [];

    let fav = !status;
    let star_i = fav ? 'star' : 'star_border';
    this.setState({ isfavorite: fav, star_icon: star_i });

    setFavoriteParams['favorite'] = { status: !status, taskid: task };
    setFavoriteParams['listval'] = {
      filterdata: [],
      list: {
        limit: 10,
        pageno: 1,
        searchtext: "",
        sorttype: "",
        type: "list",
        fieldId: 0,
        columnname: ""
      },
      filterid: 1
    };

    this.props.addFavouriteTask(this.props.apolloClient.client, setFavoriteParams);
  }

  handleTaskImageChange = event => {
    var taskImage;
    if (event.target.files[0] == undefined) {
      return false
    }

    taskImage = event.target.files[0]

    let logData = [];
    let submitData = JSON.parse(JSON.stringify(this.state.fieldsObj));
    submitData = Object.values(submitData).map((row) => {
      if (row.fieldid == "57") { // name
        row.taskvalue = row.values;
        delete row['taskvalue'];
      }
      // if(row.fieldid == "14"){ // 
      //   row.values = [{id:this.state.companyCurrencyData.currencyid.toString(),srno:"1",value:row.taskvalue}]
      //   delete row['taskvalue'];
      // }

      if (row.fieldid == "23" || row.fieldid == "24" || row.fieldid == "22" || row.fieldid == "21") { //website social phone email
        if (row.values.length == 1 && (row.values[0].id == null || row.values[0].value == "")) {
          row.values = [{ "fieldid": row.fieldid, value: [] }]
        }
        if (row.values.length > 1) {
          row.values.map((val, i) => {
            if (val.value == "") {
              row.values.splice(i, 1);
            }
          })
        }

        if (row.fieldid == "22") {
          if (row.values.length === 1) {
            if (row.values[0].fieldid) {
              row.values = []
            } else {
              if (row.values.length > 0) {
                var foundMobile = this.findWithAttr(row.values, 'id', "2");
                if (foundMobile != -1) {
                  var index = 1;
                  row.values.map((val, i) => {
                    if (val.id == "2") {
                      row.values[i].srno = "1"
                    } else {
                      row.values[i].srno = (index + 1).toString()
                    }
                    index++;
                  })
                }
              }
            }
          } else {
            if (row.values.length > 0) {
              var foundMobile = this.findWithAttr(row.values, 'id', "2");
              if (foundMobile != -1) {
                var index = 1;
                row.values.map((val, i) => {
                  if (val.id == "2") {
                    row.values[i].srno = "1"
                  } else {
                    row.values[i].srno = (index + 1).toString()
                  }
                  index++;
                })
              }
            }
          }
        }
        if (row.fieldid == "23" || row.fieldid == "24" || row.fieldid == "21") {//make value blank array if notfill in this field
          if (row.values.length === 1) {
            if (row.values[0].fieldid) {
              row.values = []
            }
          }
        }
      }
      if (row.fieldid === "65" || row.fieldid === "58") {

        row.values = [{ srno: "1", id: row.values.id, value: row.values.name }]
      }
      var key = this.findWithAttr(this.state.fieldsList, 'id', row.fieldid);
      if (this.state.fieldsList[key].fieldtype.id == "4" && this.state.fieldsList[key].is_default == false) {
        row.values = [{ srno: "1", id: row.values.id, value: row.values.optionvalue }]
      }
      if (this.state.fieldsList[key].fieldtype.id == "5" && this.state.fieldsList[key].is_default == false) {
        var ar = [];
        if (row.values.length > 0) {
          row.values.map((val, i) => {
            var optName;
            if (val.optionvalue) {
              optName = val.optionvalue
            } else {
              optName = val.value
            }
            ar.push({ srno: (i + 1).toString(), id: val.id, value: optName })
          })
        }
        row.values = ar
      }

      //validating date fiels
      if (this.state.fieldsList[key].fieldtype.id == "8") {//date field
        if (row.values == null) {
          row.taskvalue = "";
          delete row.values;
        } else if (row.values == "Invalid Date") {
          return false
        } else {
          // row.taskvalue = moment(row.values).format("X");

          let date_test = moment(row.values).format('YYYY-MM-DD HH:mm');
          var logdate = moment.tz(date_test, this.state.userSettings.timeZone).format();
          row.taskvalue = moment.utc(logdate).format('X');

          delete row.values;
        }
      }

      if (this.state.fieldsList[key].fieldtype.id == "8" && row.fieldid != '60' && row.fieldid != '62') {//For custom date fiels
        if (row.taskvalue != "") {
          var inFormat = moment.unix(row.taskvalue).format('YYYY-MM-DD')
          row.taskvalue = inFormat
        }
      }

      if (row.fieldid == "28") {
        if (row.values.length > 0) {
          var objArr = [];
          row.values.map((val, k) => {
            var foundKey = this.findWithAttr(this.state.systemTagsList, 'tagname', val);
            var tagId = "0";
            if (foundKey !== -1) {
              tagId = this.state.systemTagsList[foundKey].id
            }
            objArr.push({ "srno": (k + 1).toString(), value: val.toLowerCase(), id: tagId, isdeleted: "0" })
          })

          //add tags from props value which is removed   with id deleted id "1"
          var tagsKeyFromProps = this.findWithAttr(this.props.taskReducer.taskFieldData.data, 'id', "28");
          if (this.props.taskReducer.taskFieldData.data[tagsKeyFromProps].taskdetail.length > 0) {
            var oldTagsValues = this.props.taskReducer.taskFieldData.data[tagsKeyFromProps].taskdetail[0].values;
            if (oldTagsValues.length > 0) {
              oldTagsValues.map((v, k) => {
                var checkKey = this.findWithAttr(objArr, 'value', v.value);
                if (checkKey === -1) {
                  v.isdeleted = "1";
                  v.srno = (objArr.length + 1).toString();
                  objArr.push(v);
                }
              })
            }
          }

          row.values = objArr
          if (this.props.taskReducer.taskFieldData.data[tagsKeyFromProps].taskdetail.length > 0) {
            this.props.taskReducer.taskFieldData.data[tagsKeyFromProps].taskdetail[0].values = objArr;
          }
        }
      }


      if (row.fieldid == "61") {//owner value set
        if (row.values) {
          row.values = [{ "srno": "1", id: row.values.id, value: row.values.email, name: row.values.name }];
        } else {
          row.values = [];
        }
        delete row.taskvalue;
      }
      return row;
    })

    //adding  taskdetailid for update object from this.state.taskFieldData
    submitData = submitData.map((row, i) => {
      var key = this.findWithAttr(this.state.taskFieldData, 'id', row.fieldid);
      if (key !== -1) {
        if (this.state.taskFieldData[key].taskdetail.length > 0) {
          row.taskdetailid = this.state.taskFieldData[key].taskdetail[0].id;
        } else {
          row.taskdetailid = ""
        }
      }
      return row
    })

    //not to submit if any field is false
    var allowToSubmit = true
    submitData.map((row) => {
      if (!row) {
        allowToSubmit = false
      }
    })

    console.log("submitData", submitData);
    //return
    if (this.state.task_id && allowToSubmit) {
      let req = {
        taskdata: submitData,
        taskid: this.state.task_id,
        taskImage: taskImage,
      }
      this.props.updateTaskDetail(this.props.apolloClient.client, req);
    }
  }

  handleCompleteTask = (task1, status1) => {
    let taskCompleteParams = [];
    taskCompleteParams['complete'] = { status: !status1, taskid: parseFloat(task1) };
    taskCompleteParams['listval'] = {
      filterdata: [],
      list: {
        limit: 10,
        pageno: 1,
        searchtext: "",
        sorttype: "",
        type: "list",
        fieldId: 0,
        columnname: ""
      },
      filterid: 1
    };

    this.props.taskComplete(this.props.apolloClient.client, taskCompleteParams);
    // console.log(taskCompleteParams, 'taskCompleteParams');
    this.setState({ isDataLoading: true });
  }

  togglePin = (logId, pinned) => {
    let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';

    let pinParams = {
      ispined: !pinned,
      logid: parseFloat(logId),
      recordid: parseFloat(this.state.task_id),
      recordtypeid: 6,
      emailid: emailParam,
      logtypeid: this.state.logtypeid,
      activitypeid: this.state.activitypeid
    }

    this.props.addLogPined(this.props.apolloClient.client, pinParams);
  }
  handleAdd = (log) => this.setState({ showSelector: !this.state.showSelector, logid: log.logId })

  handleSelect = (emoji, data) => {

    const index = data.emojidetail.findIndex(obj => obj.emoji === emoji && obj.by === this.state.userid)
    var main_data = data.emojidetail;
    if (index > -1) {
      main_data = [...main_data.slice(0, index), ...main_data.slice(index + 1)]
    } else {
      main_data = [...main_data, { emoji, by: this.state.userid }]
    }
    this.setState({ showSelector: false })

    let req = {
      logid: parseFloat(data.logId),
      emoji: emoji,
      emojidetail: JSON.stringify(main_data),
      taskid: parseFloat(this.state.task_id),
      logtypeid: this.state.logtypeid,
    }
    this.props.addTaskLogEmoji(this.props.apolloClient.client, req)
  }
  getdata = (data) => {
    console.log("data ....", data)
    this.setState({ commentvalue: data.textvalue, commentState: data })
  }
  getEditCommentData = (data) => {
    console.log("data edit ....", data)
    this.setState({ commentvalue: data.textvalue, commentState: data })
  }
  saveId = (id, date) => {
    this.setState({ logid: id, selectedDate: date })
  }
  AddNewLog = (log) => {
    this.setState({
      logtype: log.logtype,
      logvaluetype: log.propertyType,
      shouldOpenCreateLogDialog: true
    })
  }

  render() {
    let { isOpenedEmail, isOpenedReplayEmail, commentvalue, textvalue, selectedDate,  TabValue, allLogs, colorClass } = this.state;
    let { handleClose } = this.props;
    let current_logUser = JSON.parse(localStorage.getItem('user_data_session')).email;
    let loghtml = [];
    let logbody = [];

    allLogs.map((logdata) => {
      logbody = [];
      if (logdata.datas.length > 0) {
        logbody.push(
          <FilterLogoCommon headerKey={logdata.key} />

        );
        logdata.datas.map((log) => {
          if (log.logtype == 5) {
            logbody.push(
              <NoteLog
                thatAll={this}
                log={log}
                commentvalue={this.state.commentvalue}
                selectedDate={this.state.selectedDate}
                logid={this.state.logid}
                dateformat={this.state.dateformat}
                showSelector={this.state.showSelector}
                isOpenedCommentIds={this.state.isOpenedCommentIds}
                isEditComment={this.state.isEditComment}
                commentid={this.state.commentid}
                timeZone={this.state.timeZone}
              />
            )
          }
          else {
            logbody.push(
              <SystemLog log={log} />
            )
          }
        })
        if (logbody && logbody.length > 0) {
          loghtml.push(logbody);
        }
      }
    })

    return (
      <React.Fragment>
        <div className="frame_body">
          <div className="mt-10 ml-16 mr-16">
            <Grid container justify="space-between">
              <Grid item><IconButton size="small" className="ml--4" onClick={handleClose}><Icon>arrow_forward</Icon></IconButton></Grid>
              <Grid item>
                {/* <IconButton size="small"><Icon>turned_in_not</Icon></IconButton> */}
                <DropdownDefault
                  menuButton={
                    <IconButton size="small" className="ml-4 mr--8">
                      <Icon>more_vert</Icon>
                    </IconButton>
                  }
                >
                  {/* <MenuItem className="flex flex-middle"><Icon className="mr-16 text-muted">launch</Icon>Open in web</MenuItem> */}
                  {/* <MenuItem className="flex flex-middle"><Icon className="mr-16 text-muted">inbox</Icon> Filer inbox</MenuItem> */}
                  {/* <MenuItem className="flex flex-middle"><svg viewBox="0 0 24 24" style={{ width: 24 }} className="mr-16" fill="rgba(0, 0, 0, 0.54)" title="LinkedIn" xmlns="http://www.w3.org/2000/svg"><path d="M58 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z"></path></svg> In Linkedin</MenuItem> */}
                  <MenuItem onClick={this.handleDeleteTask} className="flex flex-middle"><Icon className="mr-8 text-muted">delete</Icon>Delete</MenuItem>
                </DropdownDefault>
              </Grid>
            </Grid>
          </div>
          <div className="list_user_profile white_space_nowrap">
            <Grid container spacing={2}>
              <Grid item>
                <input style={{ display: 'none' }} accept="image/*" type="file" onChange={this.handleTaskImageChange}
                  ref={fileInput => this.fileInput = fileInput}
                />
                <Fab
                  size="small"
                  aria-label="Task"
                  onClick={(task1, status1) => this.handleCompleteTask(this.state.task_id, this.state.taskcomplete)}
                  className={`wrap_icons_task vert-middle ${(this.state.taskcomplete == true) ? 'bg-success' : 'bg-grey'}`}
                >
                  {(this.state.taskIcon == '') ?
                    <Icon>{(this.state.taskcomplete == true) ? 'check_box' : 'check_box_outline_blank'}</Icon>
                    :
                    <>
                      <Icon className="hover_icon" >{(this.state.taskcomplete == true) ? 'check_box' : 'check_box_outline_blank'}</Icon>
                      <FontAwesome className={"activity_icon " + this.state.taskIcon} />
                    </>
                  }
                </Fab>
              </Grid>
              <Grid item>
                <h5 className="text_ellipsis" style={{ maxWidth: "160px" }}>
                  {this.state.firstname ? this.state.firstname : ""} {this.state.lastname ? this.state.lastname : ""}

                </h5>
                <IconButtonWhite className="filled_star crm-star-custom-icon" size="small" onClick={(task, status) => this.handleFavorite(this.state.task_id, this.state.isfavorite)} >
                  <IconSmall>{this.state.star_icon}</IconSmall>
                </IconButtonWhite>
                <p>Due: {this.state.due_date}</p>
                <p className="position_text">
                  {this.state.title} {(this.state.companyName) && 'at ' + this.state.companyName}</p>
              </Grid>
            </Grid>
          </div>
          <Tabs
            value={TabValue}
            onChange={this.handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            className="lead_tabs"
          >
            <Tab label="About" {...a11yProps(0)} />
            <Tab label="Notes" {...a11yProps(1)} />
            {/* <Tab label="Related" {...a11yProps(2)} /> */}
          </Tabs>
          <TabPanel value={TabValue} index={0} className="rightbar_vscroll_task rightbar_vscroll">
            <TaskProfileDetails task_id={this.state.task_id} />
          </TabPanel>
          <TabPanel value={TabValue} index={1} className="rightbar_vscroll rightbar_vscroll_task">
            <CreatelogDialog task_id={this.state.task_id} />
            <div>
              <ul className="sidebar_activity crm-leads-sidebar-logs-list-task crm-leads-sidebar-logs-list">
                <FiltersMenu proVar={this.state.activities} thisData={this} />
                <div>
                  {loghtml.map((day_log) => (
                    <div className="lead_upcoming">
                      <div className="box_portlet">
                        {day_log}
                      </div>
                    </div>
                  ))}
                </div>
              </ul>
            </div>
          </TabPanel>
          <Dialog
            fullWidth={true}
            maxWidth={'xs'}
            open={this.state.isDeleteConfirmDialoOpen}
            onClose={this.toggleDeleteDialog}
            disableBackdropClick
            
            aria-labelledby="ar-remove-dialog-title"
          >
            <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to remove selected task ?"}</DialogTitle>
            <DialogActions>
              <Button onClick={this.toggleDeleteDialog} color="primary">
                Cancel
                                    </Button>
              <Button type="Button" onClick={() => this.confirmDeleteCustomField()} className="btn_Primary">
                Ok
                          </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            fullWidth={true}
            maxWidth={'xs'}
            open={this.state.isDeleteLogConfirmDialoOpen}
            onClose={this.toggleDeleteLogDialog}
            disableBackdropClick
            
            aria-labelledby="ar-remove-dialog-title"
          >
            <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to remove selected task log ?"}</DialogTitle>
            <DialogActions>
              <Button onClick={this.toggleDeleteLogDialog} color="primary">
                Cancel
                          </Button>
              <Button type="Button" onClick={() => this.confirmDeleteLog()} className="btn_Primary">
                Ok
                          </Button>
            </DialogActions>
          </Dialog>

                 
        </div>
      </React.Fragment>
    );
  }
}


const mapStateToProps = state => ({
  taskReducer: state.taskReducer,
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
});

const mapDispatchToProps = dispatch => {
  return {
    addLogPined: (client, request) => dispatch(actionCreators.addLogPined(client, request)),

    taskComplete: (client, request) => { dispatch(actionCreators.taskComplete(client, request)) },
    deleteTask: (client, request) => { dispatch(actionCreators.deleteTask(client, request)) },
    addFavouriteTask: (client, request) => { dispatch(actionCreators.addFavouriteTask(client, request)) },

    customFieldList: (client, request) => { dispatch(actionCreators.customFieldList(client, request)) },
    getUserList: (client) => dispatch(actionCreators.getUserList(client)),
    updateTaskDetail: (client, request) => { dispatch(actionCreators.updateTaskDetail(client, request)) },
    getCountryList: (client) => { dispatch(actionCreators.getCountryList(client)) },
    getCompanyCurrency: (client) => dispatch(actionCreators.getCompanyCurrency(client)),
    getTagsList: (client) => dispatch(actionCreators.getTagsList(client)),

    getActivityTypes: (client) => dispatch(actionCreators.getActivityTypes(client)),
    getTaskActivityLogList: (client, leadid) => dispatch(actionCreators.getTaskActivityLogList(client, leadid)),
    saveTaskLog: (client, request) => dispatch(actionCreators.saveTaskLog(client, request)),
    updateTaskLog: (client, request) => dispatch(actionCreators.updateTaskLog(client, request)),
    deleteTaskLogDetail: (client, request) => dispatch(actionCreators.deleteTaskLogDetail(client, request)),
    addTaskLogComment: (client, request) => dispatch(actionCreators.addTaskLogComment(client, request)),
    updateTaskLogComment: (client, request) => dispatch(actionCreators.updateTaskLogComment(client, request)),
    deleteTaskLogComment: (client, request) => dispatch(actionCreators.deleteTaskLogComment(client, request)),
    addTaskLogEmoji: (client, request) => dispatch(actionCreators.addTaskLogEmoji(client, request)),


  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskProfileFrame);
