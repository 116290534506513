import * as actionTypes from './actionTypes';
import { gql } from "apollo-boost";
import * as dataSet from './dataSet';
import * as actionCreators from './index';


/**
 * TYPE : GET
 * Getting Task Type
 */
export const getTaskType = (client) => {
    return function (dispatch) {
        const query = gql`
                query getTaskType
                {
                    getTaskType {
                        id
                        name
                        alias
                        order_no
                        is_allowto_delete
                        createdby
                        createdhost
                        createddate
                        lastmodifiedby
                        lastmodifiedhost
                        lastmodifieddate
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache'
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getTaskType);
            dispatch(setResponseDetails(actionTypes.GET_TASK_TYPE, return_data));
        }).catch((error) => {
            var returnData = {
                'data': {},
                'error': false,
                'notify_msg': ""
            }
            dispatch(setResponseDetails(actionTypes.GET_TASK_TYPE, returnData));
        });
    }
}

export const saveTaskType = (client, request) => {
    return dispatch => {
        const mutation = gql`
            mutation saveTaskType($tasktypename:String!) {
                saveTaskType(tasktypename:$tasktypename) {
                    id
                    name
                    alias
                    order_no
                    is_allowto_delete
                    createdby
                    createdhost
                    createddate
                    lastmodifiedby
                    lastmodifiedhost
                    lastmodifieddate
                }
            }
            `;
        client.mutate({
            mutation: mutation,
            variables: request
        })
            .then(response => {
                let returnData = [];

                if (response.data && response.data != undefined) {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "Task Type added successfully"
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    dispatch(actionCreators.getTaskType(client));
                }

            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const updateTaskTypeOrder = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateTaskTypeOrder($orderno:[Float!]!, $tasktypeid:[Float!]!) {
                 updateTaskTypeOrder(orderno:$orderno,tasktypeid:$tasktypeid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                orderno: request.taskTypeOrder,
                tasktypeid: request.taskTypeIds,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateTaskTypeOrder.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateTaskTypeOrder.message
                        }
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateTaskTypeOrder.message
                        }
                    }
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const updateTaskTypeDetail = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateTaskTypeDetail($tasktypename: String!,$tasktypeid: Float!) {
                 updateTaskTypeDetail(tasktypename:$tasktypename,tasktypeid:$tasktypeid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                tasktypename: request.tasktypename,
                tasktypeid: request.id,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateTaskTypeDetail.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateTaskTypeDetail.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateTaskTypeDetail.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
                dispatch(actionCreators.getTaskType(client))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const deleteTaskType = (client, request) => {
    return dispatch => {
        const query = gql`
             query deleteTaskType($tasktypeid:Float!) {
                 deleteTaskType(tasktypeid:$tasktypeid) {
                    status
                    message
                 }
               }
             `;
        client.query({
            query: query,
            variables: {
                tasktypeid: request.tasktypeid,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.deleteTaskType.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.deleteTaskType.message
                        }
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.deleteTaskType.message
                        }
                    }
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
                dispatch(actionCreators.getTaskType(client))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}


export const saveTaskDetail = (client, request) => {
    if (request.repeat.repeatdays) {
        request.repeat.repeatdays = parseInt(request.repeat.repeatdays);
    }
    if (request.repeat.days) {
        request.repeat.days = parseInt(request.repeat.days);
    }
    return dispatch => {
        const mutation = gql`
             mutation saveTaskDetail($taskdata:[JSON!]!, $repeat:TaskRepeatInput!) {
                 saveTaskDetail(taskdata:$taskdata, repeat:$repeat) {
                    id
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                taskdata: request.taskdata,
                repeat: request.repeat,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.saveTaskDetail.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.saveTaskDetail.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                        if (request.isFromTopBar && response.data.saveTaskDetail.id) {
                            let redirectURL = '/task/task-list';
                            window.location.href = redirectURL;
                        }
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.saveTaskDetail.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
                let val = {
                    filterdata: [],
                    list: {
                        limit: 10,
                        pageno: 1,
                        searchtext: "",
                        sorttype: "",
                        type: "list",
                        fieldId: 1,
                        columnname: ''
                    },
                    filterid: 1
                }
                dispatch(actionCreators.getTaskFilterList(client, val))
                if (request.sidebarParams && request.sidebarParams !== "") {
                    dispatch(actionCreators.getRecordRelatedData(client, request.sidebarParams))
                }
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}



export const updateTaskDetail = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateTaskDetail($updatedfieldid:Float!,$taskdata:[JSON!]!,$taskid:Float!,$fileInput: Upload!, $repeat:TaskRepeatInput!) {
                 updateTaskDetail(updatedfieldid:$updatedfieldid,taskdata:$taskdata,taskid:$taskid,fileInput:$fileInput, repeat:$repeat) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                taskdata: request.taskdata,
                taskid: request.taskid,
                fileInput: request.taskImage,
                repeat: request.repeat,
                updatedfieldid:request.updatedfieldid,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateTaskDetail.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateTaskDetail.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                        let logparam = { taskid: request.taskid, logtypeid: [] };
                        dispatch(actionCreators.getTaskActivityLogList(client, logparam))
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateTaskDetail.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }

                dispatch(actionCreators.getTaskField(client, request.taskid))

                let val = {
                    filterdata: [],
                    list: {
                        limit: 10,
                        pageno: 1,
                        searchtext: "",
                        sorttype: "",
                        type: "list",
                        fieldId: 1,
                        columnname: ''
                    },
                    filterid: 1
                }
                dispatch(actionCreators.getTaskFilterList(client, val))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}


/**
 * TYPE : GET
 * Getting particular Task Field data
 */
export const getTaskField = (client, taskid) => {
    return function (dispatch) {
        const query = gql`
                query getTaskField($taskid: Float!) 
                {
                    getTaskField(taskid: $taskid) {
                        id
                        is_default
                        fieldstatus
                        fieldkey
                        fieldtype{
                          id
                          name
                        }
                        fieldicon
                        fieldlabel
                        generatedfieldid
                        taskdetail{
                          id
                          values
                          fieldvalue
                          
                        }
                        options
                        leadfavourite
                        taskcomplete
                        image
                        taskrepeat{
                            id
                            type
                            repeatdays
                            repeattype
                            days
                        }
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                taskid: taskid
            },
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getTaskField);
            dispatch(setResponseDetails(actionTypes.TASK_FIELD_DATA, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

/**
 * TYPE : GET
 * Getting Task Filter List
 */
export const getTaskFilterList = (client, value) => {
    return function (dispatch) {
        const query = gql`
                query getTaskFilterList($filterdata: [JSON!]!,$list: TaskListInput!, $filterid: Float!)                 
                {
                    getTaskFilterList(filterdata:$filterdata, list: $list, filterid: $filterid) {
                        totalrecord
                        totalpage
                        data
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: value,
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getTaskFilterList);
            dispatch(setResponseDetails(actionTypes.GET_TASK_FILTER_LIST, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            var returnData = {
                'data': {},
                'error': false,
                'notify_msg': ""
            }
            dispatch(setResponseDetails(actionTypes.GET_TASK_FILTER_LIST, returnData));
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

/**
 * TYPE : GET
 * Delete Task 
 */
export const deleteTask = (client, request) => {
    return dispatch => {
        const query = gql`
            query deleteTask($taskid: [Float!]!)                 
            {
                deleteTask(taskid:$taskid) {
                    status
                    message
                }
            }
        `;
        client.query({
            query: query,
            variables: request['deleteid'],
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.deleteTask.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.deleteTask.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.deleteTask.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
            if (request['listval']) {
                dispatch(actionCreators.getTaskFilterList(client, request['listval']))
            }
            if (request['sidebarParams']) {
                dispatch(actionCreators.getRecordRelatedData(client, request['sidebarParams']))
            }


        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}


/**
 * TYPE : POST
 * Add TASK to comoplete
 */
export const taskComplete = (client, request) => {

    return dispatch => {
        const query = gql`
             query taskComplete($status:Boolean!, $taskid:Float!) {
                 taskComplete(status:$status,taskid:$taskid) {
                    status
                    message
                 }
               }
             `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: request['complete']
        })
            .then(response => {
                console.log(response.data, 'response.data');

                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.taskComplete.status == "true") {
                        if (request['listval']) {
                            dispatch(actionCreators.getTaskFilterList(client, request['listval']))
                        }
                        dispatch(actionCreators.getTaskField(client, request['complete'].taskid))
                        if (request['sidebarParams'] && request['sidebarParams'] !== "") {
                            dispatch(actionCreators.getRecordRelatedData(client, request['sidebarParams']))
                        }
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.taskComplete.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }

            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}
/**
 * TYPE : POST
 * Add TASK to favorite
 */
export const addFavouriteTask = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation addFavouriteTask($status:Boolean!, $taskid:Float!) {
                 addFavouriteTask(status:$status,taskid:$taskid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: request['favorite']
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.addFavouriteTask.status == "true") {
                        // returnData = {
                        //     'data': {},
                        //     'error': false,
                        //     'notify_msg': response.data.addFavouriteTask.message
                        // }
                        // dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                        dispatch(actionCreators.getTaskFilterList(client, request['listval']))
                        dispatch(actionCreators.getTaskField(client, request['favorite'].taskid))
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.addFavouriteTask.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }

            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}


export const updateTaskListColumnOrder = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateTaskListColumnOrder($orderno:[Float!]!, $columnstatus:[Boolean!]!,$fieldid:[Float!]!) {
                 updateTaskListColumnOrder(orderno:$orderno,columnstatus:$columnstatus,fieldid:$fieldid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: request
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateTaskListColumnOrder.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateTaskListColumnOrder.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                        dispatch(actionCreators.getTaskListColumnOrder(client))
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateTaskListColumnOrder.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

/**
 * TYPE : GET
 * Getting Task Column Order
 */
export const getTaskListColumnOrder = (client) => {

    return function (dispatch) {
        const query = gql`
                query getTaskListColumnOrder
                {
                    getTaskListColumnOrder {
                        id
                        field{
                            id
                            fieldkey
                            fieldlabel
                        }
                        userid
                        columnid
                        orderno
                        isactive
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getTaskListColumnOrder);
            dispatch(setResponseDetails(actionTypes.GET_TASK_LIST_ORDER, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            var returnData = {
                'data': {},
                'error': false,
                'notify_msg': ""
            }
            dispatch(setResponseDetails(actionTypes.GET_TASK_LIST_ORDER, returnData));
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

/**
 * TYPE : GET
 * Getting Task Log List
 */
export const getTaskActivityLogList = (client, request) => {
    return function (dispatch) {
        const query = gql`
                query getTaskActivityLogList($taskid: Float!, $logtypeid: [Float!], $filterchange: Boolean)                 
                {
                    getTaskActivityLogList(taskid: $taskid,logtypeid: $logtypeid, filterchange: $filterchange) {
                        status
                        message
                        today
                        yesterday
                        thisweek
                        lastweek
                        thismonth
                        lastmonth
                        earlier
                        pined
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: request,
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getTaskActivityLogList);
            dispatch(setResponseDetails(actionTypes.GET_TASK_LOG_LIST, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            var returnData = {
                'data': {},
                'error': false,
                'notify_msg': ""
            }
            dispatch(setResponseDetails(actionTypes.GET_TASK_LOG_LIST, returnData));
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const saveTaskLog = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation saveTaskLog($mentionuser:[Float!],$mentiondetail: String, $propertyname:String! ,$taskid:Float!, $text:String!, $oldvalue:String!, $newvalue:String!, $logtime:Float!, $logdate:Float!, $logtype:Float!, $fieldid:Float!) {
                 saveTaskLog(mentionuser:$mentionuser, mentiondetail:$mentiondetail, propertyname:$propertyname , taskid:$taskid, text:$text, oldvalue:$oldvalue, newvalue:$newvalue, logtype:$logtype, logtime:$logtime, logdate:$logdate, fieldid:$fieldid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: request['save']
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.saveTaskLog.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.saveTaskLog.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                        dispatch(actionCreators.getTaskActivityLogList(client, request['getlog']))
                        dispatch(actionCreators.getTaskField(client, request['getlog'].taskid))
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.saveTaskLog.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}
export const updateTaskLog = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateTaskLog($mentionuser:[Float!],$mentiondetail: String, $text:String!, $logtime:Float!, $logdate:Float!, $logid:Float!) {
                 updateTaskLog(mentionuser:$mentionuser, mentiondetail:$mentiondetail, text:$text, logtime:$logtime, logdate:$logdate, logid:$logid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: request['update']
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateTaskLog.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateTaskLog.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                        dispatch(actionCreators.getTaskActivityLogList(client, request['getlog']))
                        dispatch(actionCreators.getTaskField(client, request['getlog'].taskid))


                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateTaskLog.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}
export const deleteTaskLogDetail = (client, request) => {

    return dispatch => {
        const query = gql`
            query deleteTaskLogDetail($logid: Float!)                 
            {
                deleteTaskLogDetail(logid:$logid) {
                    status
                    message
                }
            }
        `;
        client.query({
            query: query,
            variables: { logid: request.logid },
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.deleteTaskLogDetail.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.deleteTaskLogDetail.message
                    }

                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    let logparam = { taskid: request.taskid, logtypeid: request.logtypeid };
                    dispatch(actionCreators.getTaskActivityLogList(client, logparam))
                    dispatch(actionCreators.getTaskField(client, request.taskid))
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.deleteTaskLogDetail.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}
/**
 * [addTaskLogComment saves comments of log]
 */
export const addTaskLogComment = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation addTaskLogComment($logid:Float!, $comment:String!,$mentionuser:[Float!],$mentiondetail: String!,$taskid: Float! ) {
                 addTaskLogComment(logid:$logid, comment:$comment,mentionuser:$mentionuser, mentiondetail:$mentiondetail,taskid: $taskid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                logid: request.logid,
                comment: request.comment,
                mentionuser: request.mentionuser,
                mentiondetail: request.mentiondetail,
                taskid: request.taskid
            }
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.addTaskLogComment.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.addTaskLogComment.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    let logparam = { taskid: request.taskid, logtypeid: request.logtypeid };
                    dispatch(actionCreators.getTaskActivityLogList(client, logparam))
                    dispatch(actionCreators.getTaskField(client, request.taskid))
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.addTaskLogComment.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}
/**
 * [updateTaskLogComment update comments of log]
 */
export const updateTaskLogComment = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateTaskLogComment($commentid:Float!, $comment:String!,$mentionuser:[Float!],$mentiondetail: String!) {
                 updateTaskLogComment(commentid:$commentid, comment:$comment,mentionuser:$mentionuser, mentiondetail:$mentiondetail) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                commentid: request.commentid,
                comment: request.comment,
                mentionuser: request.mentionuser,
                mentiondetail: request.mentiondetail,
            }
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.updateTaskLogComment.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.updateTaskLogComment.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));

                    let logparam = { taskid: request.taskid, logtypeid: request.logtypeid };
                    dispatch(actionCreators.getTaskActivityLogList(client, logparam))
                    dispatch(actionCreators.getTaskField(client, request.taskid))
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.updateTaskLogComment.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}
/**
 * [deleteTaskLogComment delete comments of log]
 */
export const deleteTaskLogComment = (client, request) => {

    return dispatch => {
        const query = gql`
            query deleteTaskLogComment($commentid: Float!)                 
            {
                deleteTaskLogComment(commentid:$commentid) {
                    status
                    message
                }
            }
        `;
        client.query({
            query: query,
            variables: { commentid: request.commentid },
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.deleteTaskLogComment.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.deleteTaskLogComment.message
                    }

                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    let logparam = { taskid: request.taskid, logtypeid: request.logtypeid };
                    dispatch(actionCreators.getTaskActivityLogList(client, logparam))
                    dispatch(actionCreators.getTaskField(client, request.taskid))
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.deleteTaskLogComment.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}





export const getFilterListTask = (client, recordtypeid) => {
    return function (dispatch) {
        const query = gql`
                query getFilterList($recordtypeid: Float!) 
                {
                    getFilterList(recordtypeid: $recordtypeid) {
                        id
                        fitertype
                        userid
                        filtername
                        filterquery
                        filteroption
                        isdefault
                        recordtypeid
                        isselected
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                recordtypeid: recordtypeid
            },
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getFilterList);
            dispatch(setResponseDetails(actionTypes.GET_TASKFILTER_LIST, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const saveFilterDetailTask = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation saveFilterDetail($filterdata:[JSON!]!,$fitertype:String!,$filtername: String!,$recordtypeid: Float!) {
                 saveFilterDetail(filterdata:$filterdata,fitertype:$fitertype,filtername:$filtername,recordtypeid:$recordtypeid) {
                    id
                    fitertype
                    userid
                    filtername
                    filterquery
                    filteroption
                    isdefault
                    recordtypeid
                    isselected
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                filterdata: request.filterdata,
                fitertype: request.fitertype,
                filtername: request.filtername,
                recordtypeid: request.recordtypeid,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.saveFilterDetail.id) {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': "Filter saved successfully"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    }
                }
                dispatch(actionCreators.getFilterListTask(client, 6))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const deleteFilterTask = (client, filterid) => {
    return function (dispatch) {
        const query = gql`
                query deleteFilter($filterid: Float!) 
                {
                    deleteFilter(filterid: $filterid) {
                        status
                        message
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                filterid: filterid
            },
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.deleteFilter.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.deleteFilter.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.deleteFilter.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
            dispatch(actionCreators.getFilterListTask(client, 6))
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const updateFilterDetailTask = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateFilterDetail($filterdata: [JSON!]!, $fitertype:String!,$filtername:String!,$filterid:Float!) {
                 updateFilterDetail(filterdata:$filterdata,fitertype:$fitertype,filtername:$filtername,filterid:$filterid) {
                    id
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                filterdata: request.filterdata,
                fitertype: request.fitertype,
                filtername: request.filtername,
                filterid: request.filterid,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateFilterDetail.id) {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': "Filter updated successfully"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': "Filter couldn't get updated"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
                dispatch(actionCreators.getFilterListTask(client, 6))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const updateFilterDefaultTask = (client, filterid) => {
    return dispatch => {
        const mutation = gql`
             mutation updateFilterDefault($type:String!, $fiterid:Float!,$recordtypeid:Float!) {
                 updateFilterDefault(type:$type,fiterid:$fiterid,recordtypeid:$recordtypeid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                type: "isdefault",
                fiterid: filterid,
                recordtypeid: 6
            },
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateFilterDefault.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateFilterDefault.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateFilterDefault.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
                dispatch(actionCreators.getFilterListTask(client, 6))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const addTaskLogEmoji = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation addTaskLogEmoji($logid:Float!,$emoji:String!, $emojidetail:String!) {
                addTaskLogEmoji(logid:$logid,emoji:$emoji,emojidetail:$emojidetail) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                logid: request.logid,
                emoji: request.emoji,
                emojidetail: request.emojidetail,
            }
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.addTaskLogEmoji.status == "true") {
                    let logparam = { taskid: request.taskid, logtypeid: request.logtypeid };
                    dispatch(actionCreators.getTaskActivityLogList(client, logparam))
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.addTaskLogEmoji.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}
const setResponseDetails = (newType, data) => {
    return {
        type: newType,
        val: data
    };
}

const notificationAll = (newType, data, status) => {
    return {
        type: newType,
        val: data,
        status: status,
    }
}

const errorMessageGraphQl = (err) => {
    let msg = 'Something Went Wrong!';
    if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        msg = err.graphQLErrors[0].message;
    } else if (err.networkError && err.networkError.result) {
        msg = err.networkError.result.errors[0].message;
    } else if (err.message) {
        msg = err.message;
    }

    return {
        notify_msg: msg,
        error: true
    }
}

export const clearNotification = (value) => {
    return function (dispatch) {
        dispatch(clearNotificationAll(actionTypes.NOTIFICATIONS, {}, false));
    }
}
const clearNotificationAll = (newType, data, status) => {
    return {
        type: actionTypes.NOTIFICATIONS,
        val: '',
        status: '',
    }
}





