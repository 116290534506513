import React, { Component } from "react";
// import $ from "jquery";
import {
    Dialog,
    IconButton,
    Button,
    Icon,
    Tooltip,
    Grid,
    DialogActions,
    FormControl,
    MenuItem,
    TextField,
    Checkbox,
    InputLabel,
    FormControlLabel,
    Grow,
    Select,
    ListSubheader,
    withStyles,
    CircularProgress
} from "@material-ui/core";
import { Link } from "react-router-dom";

import { SingleSelect } from "react-select-material-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import { Redirect, Route } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import {
    ValidatorForm,
    TextValidator,
    SelectValidator,
} from "react-material-ui-form-validator";

import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import { lastDayOfDecade } from "date-fns";
import InputAdornment from "@material-ui/core/InputAdornment";
import moment from 'moment';

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.0rem",
    }
}))(Icon);


const TooltipNew = withStyles((theme) => ({
    tooltip: {
        fontSize: 14,
    },
}))(Tooltip);

class ImportFixDialogCompany extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            filecode: '',
            fullfilename: '',
            type: '',
            afterSubmit: false,
            isLoading: false,
            dateformat: 'MM/DD/YYYY',
            timeZone: 'America/New_York',
            valueToFixArr: [],
            columnToFix: [],
            wrongRowKeys: [],
            uploadedRecords: [],
            selectedFields: [],
            customFields: [],
            updateFieldData: [],

            selectedCurrentCustomField: [],
        };
    }

    componentDidMount() {

        this.setState({
            valueToFixArr: this.props.valueToFixArr,
            columnToFix: this.props.columnToFix,
            wrongRowKeys: this.props.wrongRowKeys,
            uploadedRecords: this.props.uploadedRecords,
            selectedFields: this.props.selectedFields,
            customFields: this.props.customFields,
        })
    }

    componentWillReceiveProps(props) {
        if (props.settingReducer.importExcelData.status) {
            this.setState({ isLoading: false });
        }

        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            let usersetting = props.settingReducer.userSettingArray.data;

            let format = usersetting.dateformat;
            let timezone = usersetting.timezone;
            this.setState({ dateformat: format, timeZone: timezone })
        }
    }
    backToImport = () => {
        this.props.backToImport()
    }

    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] == value) {
                return i;
            }
        }
        return -1;
    }

    handleFormSubmit = () => {
        let oldToNewRecord = [];
        let cust_key = this.findWithAttr(this.state.customFields, 'title', this.state.columnToFix.title);
        let field = {};
        if (cust_key != -1) {
            field = this.state.customFields[cust_key];
        }

        let table_col = this.state.columnToFix.table;
        let uploadedRows = this.state.uploadedRecords['rows'];
        let selectedFields = this.state.selectedFields;
        oldToNewRecord[table_col] = [];

        this.state.wrongRowKeys.map((key) => {
            let valueToFixkey = this.state.valueToFixArr.indexOf(uploadedRows[key][table_col]);

            //get key from selected fields for make "isFix" true
            let isfixTrueKey = this.findWithAttr(selectedFields, 'table', table_col);
            if (isfixTrueKey != -1) {
                selectedFields[isfixTrueKey].isFix = true;
            }

            oldToNewRecord[table_col][key] = this.state.updateFieldData[field.fieldkey][valueToFixkey];

            if (field.fieldtype == 3 || field.fieldtype == 1 || field.fieldtype == 6 || field.fieldtype == 7 || field.fieldtype == 10) {
                if (field.id === 31) {
                    if (this.state.updateFieldData[field.fieldkey][valueToFixkey].newVal && typeof (this.state.updateFieldData[field.fieldkey][valueToFixkey].newVal) == 'object') {
                        uploadedRows[key][table_col] = this.state.updateFieldData[field.fieldkey][valueToFixkey].newVal.values.name;
                        oldToNewRecord[table_col][key].newVal = this.state.updateFieldData[field.fieldkey][valueToFixkey].newVal.values.name;
                    } else if (this.state.updateFieldData[field.fieldkey][valueToFixkey].newVal && typeof (this.state.updateFieldData[field.fieldkey][valueToFixkey].newVal) == 'string') {
                        uploadedRows[key][table_col] = this.state.updateFieldData[field.fieldkey][valueToFixkey].newVal;
                        oldToNewRecord[table_col][key].newVal = this.state.updateFieldData[field.fieldkey][valueToFixkey].newVal;
                    }
                } else {
                    uploadedRows[key][table_col] = this.state.updateFieldData[field.fieldkey][valueToFixkey].newVal;
                }
            }
            if (field.fieldtype == 4 || field.fieldtype == 5) {
                if (this.state.updateFieldData[field.fieldkey][valueToFixkey].newVal && typeof (this.state.updateFieldData[field.fieldkey][valueToFixkey].newVal) == 'object') {
                    if (field.id === 38 || field.id === 33 || field.id === 36) {
                        uploadedRows[key][table_col] = this.state.updateFieldData[field.fieldkey][valueToFixkey].newVal.name;
                        oldToNewRecord[table_col][key].newVal = this.state.updateFieldData[field.fieldkey][valueToFixkey].newVal.name;
                    } else {
                        uploadedRows[key][table_col] = this.state.updateFieldData[field.fieldkey][valueToFixkey].newVal.optionvalue;
                        oldToNewRecord[table_col][key].newVal = this.state.updateFieldData[field.fieldkey][valueToFixkey].newVal.optionvalue;
                    }
                } else if (this.state.updateFieldData[field.fieldkey][valueToFixkey].newVal && typeof (this.state.updateFieldData[field.fieldkey][valueToFixkey].newVal) == 'string') {
                    if (field.id === 38 || field.id === 33 || field.id === 36) {
                        uploadedRows[key][table_col] = this.state.updateFieldData[field.fieldkey][valueToFixkey].newVal;
                        oldToNewRecord[table_col][key].newVal = this.state.updateFieldData[field.fieldkey][valueToFixkey].newVal;
                    } else {
                        uploadedRows[key][table_col] = this.state.updateFieldData[field.fieldkey][valueToFixkey].newVal;
                        oldToNewRecord[table_col][key].newVal = this.state.updateFieldData[field.fieldkey][valueToFixkey].newVal;
                    }
                }

            }
            if (field.fieldtype == 8) {
                uploadedRows[key][table_col] = moment(this.state.updateFieldData[field.fieldkey][valueToFixkey].newVal).format(this.state.dateformat);
                oldToNewRecord[table_col][key].newVal = moment(this.state.updateFieldData[field.fieldkey][valueToFixkey].newVal).format(this.state.dateformat);
            }

        })

        let updatedRecords = [];
        updatedRecords['cols_arr'] = this.state.uploadedRecords['cols_arr'];
        updatedRecords['rows'] = uploadedRows;


        this.props.data.updateImportTableData(updatedRecords, oldToNewRecord, selectedFields);
    }

    handleChange = (event, old_val, old_key) => {
        let updateFieldData = [];
        updateFieldData[event.target.name] = this.state.updateFieldData[event.target.name] ? this.state.updateFieldData[event.target.name] : [];


        let selectedKey = this.findWithAttr(updateFieldData[event.target.name], 'oldKey', old_key);
        if (selectedKey == -1) {
            updateFieldData[event.target.name].push({ oldVal: old_val, oldKey: old_key, newVal: event.target.value });
        } else {
            updateFieldData[event.target.name].splice(selectedKey, 1);
            updateFieldData[event.target.name].push({ oldVal: old_val, oldKey: old_key, newVal: event.target.value });
        }

        this.setState({ [event.target.name]: updateFieldData[event.target.name], updateFieldData: updateFieldData })
    }
    handleNumberChange = (event, old_val, old_key) => {
        let updateFieldData = [];
        updateFieldData[event.target.name] = this.state.updateFieldData[event.target.name] ? this.state.updateFieldData[event.target.name] : [];


        let selectedKey = this.findWithAttr(updateFieldData[event.target.name], 'oldKey', old_key);
        if (selectedKey == -1) {
            updateFieldData[event.target.name].push({ oldVal: old_val, oldKey: old_key, newVal: parseFloat(event.target.value) });
        } else {
            updateFieldData[event.target.name].splice(selectedKey, 1);
            updateFieldData[event.target.name].push({ oldVal: old_val, oldKey: old_key, newVal: parseFloat(event.target.value) });
        }

        this.setState({ [event.target.name]: updateFieldData[event.target.name], updateFieldData: updateFieldData })
    }

    handleOwnerChange = (new_val, fieldkey, old_key, old_val) => {

        let updateFieldData = [];
        updateFieldData[fieldkey] = this.state.updateFieldData[fieldkey] ? this.state.updateFieldData[fieldkey] : [];
        let selectedKey = this.findWithAttr(updateFieldData[fieldkey], 'oldKey', old_key);
        if (selectedKey == -1) {
            updateFieldData[fieldkey].push({ oldVal: old_val, oldKey: old_key, newVal: new_val });
        } else {
            updateFieldData[fieldkey].splice(selectedKey, 1);
            updateFieldData[fieldkey].push({ oldVal: old_val, oldKey: old_key, newVal: new_val });
        }
        // console.log(updateFieldData);
        this.setState({ updateFieldData: updateFieldData })
    }

    handleDropChange = (event, old_val, old_key) => {
        let updateFieldData = [];
        updateFieldData[event.target.name] = this.state.updateFieldData[event.target.name] ? this.state.updateFieldData[event.target.name] : [];
        let selectedKey = this.findWithAttr(updateFieldData[event.target.name], 'oldKey', old_key);
        if (selectedKey == -1) {
            updateFieldData[event.target.name].push({ oldVal: old_val, oldKey: old_key, newVal: event.target.value });
        } else {
            updateFieldData[event.target.name].splice(selectedKey, 1);
            updateFieldData[event.target.name].push({ oldVal: old_val, oldKey: old_key, newVal: event.target.value });
        }
        // console.log(updateFieldData);
        this.setState({ updateFieldData: updateFieldData })
    }

    handleDateChange = (event, fieldkey, old_val, old_key) => {
        let updateFieldData = [];
        updateFieldData[fieldkey] = this.state.updateFieldData[fieldkey] ? this.state.updateFieldData[fieldkey] : [];
        let selectedKey = this.findWithAttr(updateFieldData[fieldkey], 'oldKey', old_key);
        if (selectedKey == -1) {
            updateFieldData[fieldkey].push({ oldVal: old_val, oldKey: old_key, newVal: event });
        } else {
            updateFieldData[fieldkey].splice(selectedKey, 1);
            updateFieldData[fieldkey].push({ oldVal: old_val, oldKey: old_key, newVal: event });
        }
        // console.log(updateFieldData);
        this.setState({ updateFieldData: updateFieldData })
    }

    render() {
        let currentDate = new Date();
        let dateofimport = moment(currentDate).format(this.state.dateformat) + ' ' + moment(currentDate).format('HH:mm');
        let importby = JSON.parse(localStorage.getItem('user_data_session')).name;
        let fieldsArray = [];

        let { open, handleClose, handleMinimize, handleFull, fullScreen, minimizeScreen, data } = this.props;

        let { valueToFixArr, columnToFix, customFields } = this.state;

        let field = {};

        let cust_key = this.findWithAttr(customFields, 'title', columnToFix.title);
        if (cust_key != -1) {
            valueToFixArr.map((val, value_fix_key) => {

                field = customFields[cust_key];

                fieldsArray.push(<Grid item xs={6}>
                    <span style={{ fontSize: '11px' }}>{val}</span><br />
                    <span style={{ fontSize: '11px', color: 'red' }}>Invalid {columnToFix.table}</span>
                </Grid>)

                if (field.fieldtype == 3) { //checkbox
                    let fieldVal = -1;
                    if (this.state.updateFieldData[field.fieldkey]) {
                        fieldVal = this.findWithAttr(this.state.updateFieldData[field.fieldkey], 'oldKey', value_fix_key);
                    }

                    fieldsArray.push(<Grid item xs={6} className="checkbox-margin-18">
                        <FormControl className="w-100 input_bx">
                            <SelectValidator
                                ref={ref => data[field.fieldkey] = ref}
                                value={fieldVal != -1 ? this.state.updateFieldData[field.fieldkey][fieldVal].newVal : ''}
                                onChange={(event, old_val, old_key) => this.handleChange(event, val, value_fix_key)}
                                name={field.fieldkey}
                                id={field.id}
                                label={field.title}
                                placeholder={field.title}
                                validators={["required"]}
                                errorMessages={["This field is required"]}
                            >
                                <MenuItem className="select-menu-item w-100 input_bx" value={'checked'}>Checked</MenuItem>
                                <MenuItem className="select-menu-item w-100 input_bx" value={'unchecked'}>Un-checked</MenuItem>
                            </SelectValidator>
                        </FormControl>
                    </Grid>
                    )
                }

                if (field.fieldtype == 4 || field.fieldtype == 5) { //dropdown
                    let fieldVal = -1;
                    if (this.state.updateFieldData[field.fieldkey]) {
                        fieldVal = this.findWithAttr(this.state.updateFieldData[field.fieldkey], 'oldKey', value_fix_key);
                    }

                    if (field.id === 38 || field.id === 33 || field.id === 36) {  // Visibility, contacttype, country

                        fieldsArray.push(<Grid item xs={6}>
                            <FormControl className="w-100 input_bx">
                                <SelectValidator
                                    ref={ref => data[field.fieldkey] = ref}
                                    value={fieldVal != -1 ? this.state.updateFieldData[field.fieldkey][fieldVal].newVal : ''}
                                    onChange={(event, old_val, old_key) => this.handleDropChange(event, val, value_fix_key)}
                                    name={field.fieldkey}
                                    id={field.id}
                                    label={field.title}
                                    validators={["required"]}
                                    errorMessages={["This field is required"]}
                                >
                                    {field.fieldOptions && field.fieldOptions.map((item, key) =>
                                        <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item}>{item.name}</MenuItem>
                                    )}
                                    {field.fieldOptions && field.id == 33 &&
                                        <MenuItem className="select-menu-item item-with-link" value={5}><Link to="/settings/contact-types">Customize Contact Types</Link></MenuItem>
                                    }
                                </SelectValidator>
                            </FormControl>
                        </Grid>
                        )
                    } else {
                        fieldsArray.push(<Grid item xs={6}>
                            <FormControl className="w-100 input_bx">
                                <SelectValidator
                                    ref={ref => data[field.fieldkey] = ref}
                                    label={field.title}
                                    value={fieldVal != -1 ? this.state.updateFieldData[field.fieldkey][fieldVal].newVal : ''}
                                    onChange={(event, old_val, old_key) => this.handleDropChange(event, val, value_fix_key)}
                                    name={field.fieldkey}
                                    id={field.id}
                                    required={true}
                                    validators={["required"]}
                                    errorMessages={["This field is required"]}
                                >

                                    {field.fieldOptions && field.fieldOptions.map((item, key) =>
                                        <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item}>{item.optionvalue}</MenuItem>
                                    )}

                                </SelectValidator>
                            </FormControl>
                        </Grid>
                        )
                    }
                }

                if (field.fieldtype == 8) { // Date
                    var dtFormat = "MM/dd/yyyy";
                    let fieldVal = -1;
                    var selectedDate = null;
                    if (this.state.updateFieldData[field.fieldkey]) {
                        fieldVal = this.findWithAttr(this.state.updateFieldData[field.fieldkey], 'oldKey', value_fix_key);
                        selectedDate = fieldVal != -1 ? this.state.updateFieldData[field.fieldkey][fieldVal].newVal : null;
                    }

                    if (data.state.userSettings != "") {
                        dtFormat = data.state.userSettings.dateformat;
                        dtFormat = dtFormat.replace("DD", "dd").replace("YYYY", "yyyy");
                    }
                    fieldsArray.push(<Grid item xs={6} >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <FormControl className="w-100 input_bx">
                                <KeyboardDatePicker
                                    label={field.fieldlabel}
                                    format={dtFormat}
                                    required={true}
                                    placeholder={dtFormat}
                                    error={(selectedDate == null || selectedDate == "Invalid Date") ? true : false}
                                    value={selectedDate}
                                    onChange={(event, fieldKey, old_val, old_key) => this.handleDateChange(event == "Invalid Date" ? "Invalid Date" : event, field.fieldkey, val, value_fix_key)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    helperText={selectedDate == null ? "This field is required" : (selectedDate == "Invalid Date") ? "Please enter a valid date." : ""}
                                />
                            </FormControl>
                        </MuiPickersUtilsProvider>
                    </Grid>
                    )
                }

                if (field.fieldtype == 1 && field.id == 31) {
                    let fieldVal = -1;
                    if (this.state.updateFieldData[field.fieldkey]) {
                        fieldVal = this.findWithAttr(this.state.updateFieldData[field.fieldkey], 'oldKey', value_fix_key);
                    }

                    fieldsArray.push(<Grid item xs={6}>
                        <FormControl className="w-100 input_bx">
                            <Autocomplete
                                disableOpenOnFocus
                                forcePopupIcon={false}
                                name={field.fieldkey}
                                options={data.state.usersListArray}
                                getOptionLabel={option => option.name}
                                value={fieldVal != -1 ? this.state.updateFieldData[field.fieldkey][fieldVal].newVal.values : ''}
                                name={field.fieldkey}
                                onChange={(event, value, old_key, old_val) => this.handleOwnerChange({ fieldid: field.id, values: value }, field.fieldkey, value_fix_key, val)}
                                id={field.id}
                                renderInput={params => (
                                    <TextValidator
                                        {...params}
                                        variant="standard"
                                        label={"Owner"}
                                        fullWidth
                                        ref={ref => data[field.fieldkey] = ref}
                                        name={field.fieldkey}
                                        value={fieldVal != -1 ? 1 : null}
                                        required={true}
                                        validators={["required"]}
                                        errorMessages={["This field is required"]}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>)
                }
                // if (field.fieldtype == 1 && field.id === 21) {//  21- email
                //     let fieldVal = -1;
                //     if (this.state.updateFieldData[field.fieldkey]) {
                //         fieldVal = this.findWithAttr(this.state.updateFieldData[field.fieldkey], 'oldKey', value_fix_key);
                //     }

                //     fieldsArray.push(
                //         <Grid item xs={6}>
                //             <TextValidator
                //                 ref={ref => data[field.fieldkey] = ref}
                //                 type="text"
                //                 id={field.id}
                //                 placeholder={"Add " + field.fieldkey}
                //                 className="w-100 input_bx"
                //                 onChange={(event, old_val, old_key) => this.handleChange(event, val, value_fix_key)}
                //                 name={field.fieldkey}
                //                 value={fieldVal != -1 ? this.state.updateFieldData[field.fieldkey][fieldVal].newVal : ''}
                //                 validators={["required", "isEmail"]}
                //                 errorMessages={["This field is required", "Please enter a valid email address."]}
                //             />
                //         </Grid>
                //     )
                // }
                if (field.fieldtype == 1 && (field.id == 32 || field.id == 34 || field.id == 35)) {// 32-34-35- website
                    let fieldVal = -1;
                    if (this.state.updateFieldData[field.fieldkey]) {
                        fieldVal = this.findWithAttr(this.state.updateFieldData[field.fieldkey], 'oldKey', value_fix_key);
                    }

                    fieldsArray.push(
                        <Grid item xs={6}>
                            <TextValidator
                                ref={ref => data[field.fieldkey] = ref}
                                onChange={(event, old_val, old_key) => this.handleChange(event, val, value_fix_key)}
                                type="text"
                                id={field.id}
                                placeholder={"Add " + field.fieldkey}
                                className="w-100 input_bx"
                                name={field.fieldkey}
                                value={fieldVal != -1 ? this.state.updateFieldData[field.fieldkey][fieldVal].newVal : ''}
                                validators={["required"]}
                                errorMessages={["This field is required"]}
                            />
                        </Grid>
                    )
                }
                if (field.fieldtype == 6 || field.fieldtype == 7 || field.fieldtype == 10) { //6-7-  number // 10 percentage  
                    let fieldVal = -1;
                    if (this.state.updateFieldData[field.fieldkey]) {
                        fieldVal = this.findWithAttr(this.state.updateFieldData[field.fieldkey], 'oldKey', value_fix_key);
                    }

                    if (field.fieldtype == '6' || field.fieldtype == '10' || field.fieldtype == '7') {// Number, Currency
                        fieldsArray.push(<Grid item xs={6}>
                            <TextValidator
                                ref={ref => data[field.fieldkey] = ref}
                                type="number"
                                required={true}
                                id={field.id}
                                className="w-100 input_bx"
                                label={field.fieldlabel}
                                onChange={(event, old_val, old_key) => this.handleNumberChange(event, val, value_fix_key)}
                                name={field.fieldkey}
                                value={fieldVal != -1 ? this.state.updateFieldData[field.fieldkey][fieldVal].newVal : ''}
                                validators={["required"]}
                                errorMessages={["This field is required"]}
                            />
                        </Grid>)
                    }
                }
            });
        }

        return (
            <Dialog
                onClose={handleClose}
                open={open}
                maxWidth="md"
                fullWidth={true}
                className={`crm-custom-bg-primary custom_dialog ${minimizeScreen && fullScreen ? "alignitemsend-fullscreen" : ""
                    } ${minimizeScreen ? "alignitemsend" : ""} ${fullScreen ? "fullscreen" : ""
                    } crm_dialog_create_template`}
                disableBackdropClick
            // disableEscapeKeyDown
            >
                <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">
                    <h4 className="m-0 text-white">Invalid '{columnToFix.table}' Values</h4>
                    <div className="d-flex">
                        <TooltipNew
                            title={minimizeScreen ? "Normal Screen" : "Minimize Screen"}
                        >
                            <IconButton onClick={handleMinimize}>
                                <Icon className="text-white">
                                    {minimizeScreen ? "add" : "remove"}
                                </Icon>
                            </IconButton>
                        </TooltipNew>
                        <TooltipNew title={fullScreen ? "Exit Full Screen" : "Full Screen"}>
                            <IconButton onClick={handleFull}>
                                <Icon className="text-white">
                                    {fullScreen ? "fullscreen_exit" : "fullscreen"}
                                </Icon>
                            </IconButton>
                        </TooltipNew>
                        <TooltipNew title="Close">
                            <IconButton onClick={handleClose}>
                                <Icon className="text-white">clear</Icon>
                            </IconButton>
                        </TooltipNew>
                    </div>
                </div>
                <ValidatorForm noValidate instantValidate={false} ref="form"
                    onSubmit={this.handleFormSubmit}
                    onError={errors => console.log(errors)}>
                    <>
                        <div className={`${this.state.isDataLoading ? "dialog-body popupHeightCustom" : "dialog-body"}`} style={{ overflow: "visible" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <span className="text-hint font-size-10" style={{ fontSize: '11px' }}>There are invalid values in the '{columnToFix.table}' column in your import file. Correct the errors below</span>
                                </Grid>

                                <Grid item xs={6}>
                                    <label className="font-weight-500" style={{ fontSize: '12px' }}>Invalid values from your file</label>
                                </Grid>
                                <Grid item xs={6}>
                                    <label className="font-weight-500" style={{ fontSize: '12px' }}>Please update to a valid value</label><br />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                {/* {valueToFixArr.map((val) => (
                                    
                                ))} */}
                                {fieldsArray}
                            </Grid>
                        </div>
                        <DialogActions className="dialog-action">
                            <Button variant="contained" onClick={handleClose}>Cancel</Button>
                            <Button disabled={this.state.isDataLoading} className="btn_Primary" variant="contained" type="submit" color="primary">Save</Button>
                        </DialogActions>
                    </>
                </ValidatorForm>
            </Dialog>
        );
    }
}
const mapStateToProps = (state) => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
});
const mapDispatchToProps = (dispatch) => {
    return {
        importExcelData: (client, req) => { dispatch(actions.importExcelData(client, req)) },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ImportFixDialogCompany);
