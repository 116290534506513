import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Button, Icon } from '@material-ui/core';
import moment from 'moment';
import "date-fns";

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.2rem",
        color: "white"
    }
}))(Icon);

const TrackDataPopover = props => {
    const onLaunch = () => {
        var link = 'https://mail.google.com/mail/u/0/#inbox/' + props.threadId;
        window.open(link, '_blank');
    }
    let date, time = ""
    let DiaplyData = [];
    if (props.tracking_data.length > 0) {
        props.tracking_data.map((log, i) => {
            date = moment.unix(log.createddate).tz(props.timeZone).format(props.dateformat);
            time = moment.unix(log.createddate).tz(props.timeZone).format('hh:mm A');
            DiaplyData.push(<p key={i} >Viewed by someone on {date}, {time}</p>)
        })
    }
    return (
        <React.Fragment>
            <div className="tracking_popover "
                onMouseLeave={props.handleCloseTrackDetails}
            >
                <h5 className={"p-10 m-0 show_status"}>Email Activity</h5>
                <div className="tracking_list">
                    {
                        DiaplyData.length > 0 ?
                            DiaplyData
                            :
                            <p className="text-center font-size-14 font-weight-500 text-hint">
                                No Views Yet</p>
                    }
                </div>
                <hr />
                <div className="tracking_footer" >
                    <Button className="btn_Primary" size="small" onClick={() => onLaunch()}>
                        View in Gmail {" "}<IconSmall>launch</IconSmall>
                    </Button>
                </div>
            </div>
        </React.Fragment >
    );
}
export default TrackDataPopover;