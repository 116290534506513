import React, { Component } from "react";
import { Popover, Tooltip, FormControl, Input, FormHelperText, Divider, Button, IconButton, Icon, withStyles, Card, DialogContent, Dialog, DialogActions, DialogTitle, Typography, MenuItem, InputLabel, Radio, FormControlLabel, RadioGroup, Badge, Drawer } from "@material-ui/core";
import Grid, { default as MaterialGrid } from '@material-ui/core/Grid';

import Select from '@material-ui/core/Select';
import FilterListIcon from '@material-ui/icons/FilterList';
// import Chart from "react-google-charts";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import moment from 'moment';//moment
import 'moment-timezone';//timezone
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import { Link } from "react-router-dom";
import * as actions from '../../../store/actions/index';
import { connect } from "react-redux";

class PipelineSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportTitile: "Pipeline Summary ",
            reportSubTitle: " Summary of your pipeline by stage, based on Opportunity close date.",
            totalvalue: "$0 K",
            totalweight:"$0 K",
            totalopp: "3",
            selectedPipline: "1",
            selectedFilter: "1",
            value_type: "1",
            drawerOpen: false,
            dateopen: false,
            dateformat: 'MM/DD/YYYY',
            selectedDate: { from: moment().startOf("year").format('YYYY-MM-DD'), to: moment().endOf("year").format('YYYY-MM-DD') },
            selectedDatelabel: "",
            fromdate: moment().startOf("month").format('YYYY-MM-DD'),
            todate: moment().endOf('month').format('YYYY-MM-DD'),
            pipelineList: [],
            pipelinesummarydata: [],
            fieldDisplay: [],
            dropdownhide: [],
            systemTagsList: [],
            masterActivityTypesList: [],
            usersListArray: [],
            memberid: 0
        }
    }


    componentDidMount() {
        localStorage.removeItem('selected_report_pipeline_summary_filter_id');
        this.props.getPipelines(this.props.apolloClient.client);
        let fromDate = localStorage.getItem('dashboardFromDate');
        let toDate = localStorage.getItem('dashboardToDate');
        let val = { typeId: 4 }
        this.props.customFieldList(this.props.apolloClient.client, val);
        this.props.getTagsList(this.props.apolloClient.client);
        this.props.getUserList(this.props.apolloClient.client);
        this.props.getActivityTypes(this.props.apolloClient.client);
        this.setState({
            selectedDate: {
                from: moment().startOf("month").format('YYYY-MM-DD'),
                to: moment().endOf('month').format('YYYY-MM-DD')
            },
            fromdate: fromDate,
            todate: toDate
        }, () => this.updateGraphSettings())
    }



    componentWillReceiveProps(props) {
        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            let usersetting = props.settingReducer.userSettingArray.data;
            this.setState({ dateformat: usersetting.dateformat, timeZone: usersetting.timezone })
        }

        if (props.settingReducer.masterActivityTypesList && props.settingReducer.masterActivityTypesList.is_loaded == true && props.settingReducer.masterActivityTypesList.data) {
            props.settingReducer.masterActivityTypesList.is_loaded = false
            var dataList = props.settingReducer.masterActivityTypesList.data;
            var count = dataList.filter(function (element) {
                if (element.is_disable == true) {
                    return element
                }
            });
            this.setState({
                masterActivityTypesList: dataList,
            });
        }

        if (props.settingReducer.pipelineListArray && props.settingReducer.pipelineListArray.data) {
            this.setState({ pipelineList: props.settingReducer.pipelineListArray.data })
        }

        if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
            const usersListArray = props.settingReducer.usersListArray.data;
            this.setState({ usersListArray: usersListArray })
        }

        if (props.reportReducer.reportPipelineSummary && props.reportReducer.reportPipelineSummary.data) {
            let pipelinesummarydata = props.reportReducer.reportPipelineSummary.data.data

            let totalvalue = (pipelinesummarydata.totaloppamount && (pipelinesummarydata.totaloppamount > 0)) ? (pipelinesummarydata.totaloppamount) : 0;
            this.setState({ pipelinesummarydata, totalweight: (pipelinesummarydata.totalOppWeight && (pipelinesummarydata.totalOppWeight > 0)) ? "$" + (pipelinesummarydata.totalOppWeight) : "$" + 0, totalvalue: "$" + totalvalue, totalopp: pipelinesummarydata.totalopportunities ? pipelinesummarydata.totalopportunities : 0 })
        }

        if (props.settingReducer.systemTagsList.data && this.state.systemTagsList.length == 0) {
            const tagsData = props.settingReducer.systemTagsList.data;
            this.setState({ systemTagsList: tagsData })
        }

        // filter
        if (props.settingReducer.opportunitiesCustomFieldList.data) {
            const customFields = props.settingReducer.opportunitiesCustomFieldList.data;
            this.setState({ fieldsList: customFields })
        }
        if (props.reportReducer.UserDashboardId) {
            if (this.state.memberid !== props.reportReducer.UserDashboardId) {
              {
                this.setState({
                  memberid: props.reportReducer.UserDashboardId
                }, () => this.updateGraphSettings())
              }
            }
          }
    }
    handlePiplineChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        }, () => this.updateGraphSettings())
    }

    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] == value) {
                return i;
            }
        }
        return -1;
    }
    componentDidUpdate(PrevProps) {
        if (PrevProps.reportReducer.UserDashboardId !== this.props.reportReducer.UserDashboardId) {
            this.setState({
                memberid: this.props.reportReducer.UserDashboardId
            }, () => this.updateGraphSettings())
        }
    }
    updateGraphSettings = () => {
        let piplineparams = {
            filterdata: [],
            piplineid: parseFloat(this.state.selectedPipline),
            type: parseFloat(this.state.value_type),
            fromdate: this.state.fromdate,
            todate: this.state.todate,
            memberid: parseFloat(this.state.memberid)
        };
        this.props.piplineSummary(this.props.apolloClient.client, piplineparams);
    }
    render() {
        let { isOpenedFiltersDefault, filterlist, totalweight, pipelinesummarydata, pipelineList, selectedPipline, reportTitile, reportSubTitle, dateopen, selectedFilter, totalvalue, drawerOpen, totalopp, value_type } = this.state;
        let stages = [];
        let data = [];


        if (pipelinesummarydata && pipelinesummarydata.stage && pipelinesummarydata.graphval) {
            stages = pipelinesummarydata && pipelinesummarydata.stage;
            data = pipelinesummarydata && pipelinesummarydata.graphval;
        } else {
            let selectedStages = [];
            let pipelinekey = this.findWithAttr(pipelineList, 'id', selectedPipline);

            if (pipelinekey != -1) {
                selectedStages = pipelineList[pipelinekey].stage;
                selectedStages.map((stage, key) => {
                    stages.push(stage.name);
                    data.push(0);
                });
            }
        }
        const options = {
            chart: { type: 'column' },
            title: "",
            subtitle: "",
            xAxis: {
                title: {
                    text: null
                },
                categories: stages,
                crosshair: true
            },
            yAxis: {
                title: { text: null },
                min: 0,
                labels: {
                    formatter: function () {
                        return '$' + this.value;
                    }
                }
            },
            tooltip: {
                headerFormat: '<b><span style="font-size:14px">{point.key}</span></b><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                color: "#2196f3",
                name: 'Total Value',
                data: data
            }]
        }


        return (
            <Card elevation={3} className="dashboard_card mb-10">
                <div className="report_card">
                    <div className="report_header">
                        <Grid container justify="space-between">
                            <h3 className="text-primary">{reportTitile}</h3>
                            <Link className="viewmore text_link" to="/reports/report-default/">View Full Report
                            </Link>
                        </Grid>

                        <Grid container direction="row" justify="flex-start" alignItems="center">
                            <div className="number_label">
                                <div>{totalvalue}</div>
                                <div className=" p-4" >Total Value</div>
                            </div>
                            <div className="number_label">
                                <div>{totalweight}</div>
                                <div className=" p-4" >Total Weighted</div>
                            </div>
                            <div className="number_label">
                                <div>{totalopp}</div>
                                <div className=" p-4">Opportunities</div>
                            </div>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                        >
                            <div className="report_filter">
                                <InputLabel id="Pipeline-select-helper-label">Pipeline</InputLabel>
                                <Select
                                    className="w-200"
                                    labelId="Pipeline-select-label"
                                    name="selectedPipline"
                                    id="Pipeline"
                                    value={selectedPipline}
                                    onChange={this.handlePiplineChange}
                                >
                                    <MenuItem value={"0"}>All</MenuItem>
                                    {pipelineList && pipelineList.map((pipeline, o) => (
                                        <MenuItem key={o} value={pipeline.id}>{pipeline.name}</MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </Grid>
                    </div>
                    <div className="report_content">
                        <HighchartsReact
                            style={{ width: "100%" }}
                            highcharts={Highcharts}
                            options={options}
                        />
                    </div>
                </div>
            </Card>
        );
    }
}

const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    reportReducer: state.reportReducer,
    opportunityReducer: state.opportunityReducer,
    apolloClient: state.apolloClient,
    notification: state.notification,
});
const mapDispatchToProps = dispatch => {
    return {
        getPipelines: (client) => dispatch(actions.getPipelines(client)),
        getTagsList: (client) => dispatch(actions.getTagsList(client)),
        getUserList: (client) => dispatch(actions.getUserList(client)),
        getActivityTypes: (client) => dispatch(actions.getActivityTypes(client)),
        customFieldList: (client, value) => dispatch(actions.customFieldList(client, value)),

        piplineSummary: (client, value) => dispatch(actions.piplineSummary(client, value)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PipelineSummary);