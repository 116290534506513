import React, { Component } from "react";
import {
    Button, withStyles, Icon, DialogActions, DialogTitle,
    DialogContent, Typography, Dialog, IconButton, Link
} from "@material-ui/core";
import { connect } from "react-redux";
import * as actionCreators from '../../../store/actions/index';
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.5rem",
        color: 'rgba(0, 0, 0, 0.6)'
    }
}))(Icon);

class MailChimpDialog extends Component {
    state = {
        integrationid: 6,
        token: "",
        Data: [],
    };


    componentWillReceiveProps(props) {
        if (props.settingReducer.MailChimpVerifyData.data) {
            const obj = props.settingReducer.MailChimpVerifyData.data;
            if (obj !== this.state.Data) {
                this.setState({
                    Data: obj
                }, () => this.updateSetting())

                setTimeout(() => {
                    this.props.handleClose();
                }, 3000);
            }
        }
    }

    updateSetting = () => {
        let sessionData = JSON.parse(localStorage.getItem('user_data_session'));
        let useremail = sessionData.email;
        let val = {
            integration: {
                integrationid: this.state.integrationid,
                oncompanies: true,
                onleads: true,
                onopportunities: true,
                onpeople: true,
                onproject: false,
                maxnumberrecords: 5,
                sortby: '',
                status: true,
                email: useremail,
                domain: '',
                accesstoken: this.state.token,
                refereshtoken: '',
                ticketstatus: '',
            }
        }
        let data = [];
        data['data'] = val;
        let id = 6;
        let req = { data, id };
        console.log(req);
        this.props.saveIntegrationSetting(this.props.apolloClient.client, req);
    }


    handleChange = event => {
        event.persist();
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleFormSubmit = event => {
        let verify_data = {
            "token": this.state.token,
            "secret_key": "obb+7+z-2j&6tx=^r%(3(@66d-bix#g6u!uf+@(!3_6y(cu%6v"
        }
        let submitData = [{
            'token': this.state.token,
        }]
        let zendeskVerifyreq = {
            data: verify_data,
            getParams: submitData,
        }
        this.props.VerifyMailchimpData(zendeskVerifyreq);
    };

    render() {
        let { token } = this.state;
        let { open, handleClose } = this.props;
        return (
            <Dialog onClose={handleClose} open={open} className="crm_dialog_setting" fullWidth={true} disableBackdropClick 
            // disableEscapeKeyDown
            >
                <ValidatorForm ref="form" noValidate onSubmit={this.handleFormSubmit}>
                    <DialogTitle>
                        <Typography className="p-10 font-weight-500" variant="h5" color="inherit">MailChimp Integration</Typography>
                        <div className="clear_icon hover_show">
                            <IconButton aria-label="Delete" size="small" onClick={handleClose}>
                                <IconSmall style={{ fontSize: '18px' }}>clear</IconSmall>
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ margin: '20px 10px 10px 10px' }}>
                            {/* <lable className="Zend_Integrations_card font-weight-600 font-size-18 text-small">MailChimp API key:</lable> */}
                            <div style={{ width: '100%', display: 'flex' }}>
                                <TextValidator
                                    required={true}
                                    type="text"
                                    id="token"
                                    className="w-100 input_bx"
                                    onChange={this.handleChange}
                                    name="token"
                                    value={token}
                                    fullWidth
                                    placeholder="e.g. DNWRCsTC235Jpe49GocxDZxDWizt9jnYURh5yBXq"
                                    validators={["required"]}
                                    errorMessages={["This field is required"]}
                                    autoComplete='off'
                                    label={'MailChimp API key'}
                                    InputLabelProps={{shrink: true, disableAnimation: false, focused: true }}
                                />
                            </div>
                        </div>
                        <div className="Zend_Integrations_card font-weight-600 font-size-18 text-small" style={{ margin: '25px 10px 0 10px' }}>
                            Your MailChimp key can be found here :&nbsp;
                            <span style={{ color: 'blue' }}>
                                <a target="_blank" href='https://us17.admin.mailchimp.com/account/api/' >API Keys</a>
                            </span>
                        </div>
                        <div className="Zend_Integrations_card font-weight-600 font-size-18 text-small" style={{ margin: '0 10px 10px 10px' }}>
                            Log in to MailChimp &gt; Account &gt; Extras &gt; API keys
                    </div>
                        <div className="Zend_Integrations_card font-weight-600 font-size-18 text-small" style={{ margin: '25px 10px 10px 10px' }}>
                            Can't find it? Contact our support to set up MailChimp integration
                    </div>
                    </DialogContent>
                    <DialogActions className="dialog-action">
                        <Button variant="contained" onClick={handleClose}>Cancel</Button>
                        <Button variant="contained" color="primary" type="submit">Enable</Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        apolloClient: state.apolloClient,
        settingReducer: state.settingReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        VerifyMailchimpData: (response) => dispatch(actionCreators.VerifyMailchimpData(response)),
        saveIntegrationSetting: (client, req) => dispatch(actionCreators.saveIntegrationSetting(client, req)),
    }
}
export default (connect(mapStateToProps, mapDispatchToProps)(MailChimpDialog));
