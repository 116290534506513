import React, { Component } from "react";
import SettingSidebar from '../../Layout/SettingSidebarMenu';
import { Box, IconButton, Card, Icon, Tab, Tabs, Typography, Button, Checkbox, Chip, Fab, FormControl, Grid, ListItemText, MenuItem, Select, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter } from "@material-ui/core";
import moment from 'moment';
import PropTypes from 'prop-types';

import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";


class SaveBilling extends Component {
  constructor(props) {
    super(props)
    this.state = {
      purchased_details: [],
    }
  }

  componentDidMount = () => {
    let billingparams = this.props.location.state.saveparams;
    this.props.companyPlanPurchase(this.props.apolloClient.client, billingparams);

  }
  
  componentWillReceiveProps(props){
    if(props.settingReducer.success_purchased_plan ) {
      let purchased_details = props.settingReducer.success_purchased_plan;
      console.log(purchased_details,'purchased_details');
      this.setState({purchased_details})
    }
  }

  
  render() {
    const { billingPlans } = this.state;

    return (
      <React.Fragment>
        <div className='h-100'  style={{overflow:'auto'}}>
          <Card className="pt-30 pb-30 text-center">
            <Typography variant="h5" color="inherit" className="font-weight-500 m-30 text-center">Thank You for choosing our plan, We are processing your request.</Typography>
            <Button
              className="capitalize"
              variant="contained"
              color="primary"
              onClick={() => this.props.history.push('/')}
            >
              Back to Dashboard
            </Button>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}


const mapStateToProps = (state) => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
});
const mapDispatchToProps = (dispatch) => {
  return {
    companyPlanPurchase: (client, request) => dispatch(actionCreators.companyPlanPurchase(client, request)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SaveBilling);