import React, { Component } from "react";
import SettingSidebar from '../../Layout/SettingSidebarMenu';
import PropTypes from 'prop-types';
import { Card, FormControl, Tabs, Tab, Typography, Box, Icon, withStyles, Grid, Button, FormControlLabel, Checkbox, Select, MenuItem, Chip, TextField, IconButton } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import * as actions from '../../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={2}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    classes: PropTypes.object.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
const styles = theme => ({
    bigIndicator: {
        display: 'none',
    },
});
class AddTaskAutomation extends Component {
    state = {
        name: '',
        TabValue: 0,
        selctedEvent: 0,
        selctedButton: 0,
        SetTasks: [{
            title: "Set tasks 1"
        }],
        taskname: '',
        search: '',
        activitytype: 1,
        dueDate: 1,
        daylaterOptions: 1,
        reminder: '',
        owner: 1,
        priority: 1,
        visibility: 1,
        tags: [
            { title: 'CRM' },
            { title: 'CMS' },
        ],
        description: 'Test task automation',
        masterActivityTypesList: [],
        usersListArray: [],
        submitFieldData: [],

    };

    componentDidMount(){
        this.props.getUserList(this.props.apolloClient.client);
        this.props.getActivityTypes(this.props.apolloClient.client);
        
        // this.props.getCompanyRecList(this.props.apolloClient.client);
        // this.props.getContactRecList(this.props.apolloClient.client);
    }

    componentWillReceiveProps(props) {
        // if (props.settingReducer.companyRecList && props.settingReducer.companyRecList.data && props.settingReducer.companyRecList.data.data) {
        //     let companyOptions = this.state.companyOptions;
        //     props.settingReducer.companyRecList.data.data.map((company) => {
        //         let companykey = this.findWithAttr(companyOptions, 'id', company.company_id);

        //         if (companykey == -1)
        //             companyOptions.push({ id: company.company_id, name: company.name });
        //     })
        //     this.setState({ companyOptions });
        // }

        // if (props.settingReducer.contactRecList && props.settingReducer.contactRecList.data && props.settingReducer.contactRecList.data.data) {
        //     let contactOptions = this.state.contactOptions;
        //     props.settingReducer.contactRecList.data.data.map((contact) => {
        //         let contactkey = this.findWithAttr(contactOptions, 'id', contact.contact_id);

        //         if (contactkey == -1)
        //             contactOptions.push({ id: contact.contact_id, name: contact.name });
        //     })

        //     this.setState({ contactOptions });
        // }
        if (props.settingReducer.masterActivityTypesList && props.settingReducer.masterActivityTypesList.is_loaded == true && props.settingReducer.masterActivityTypesList.data) {
            var dataList = props.settingReducer.masterActivityTypesList.data;
            var count  = dataList.filter(function(element) {
                if(element.is_disable == true){
                  return element;
                }
            });
            this.setState({
                masterActivityTypesList: dataList,
                disabledCount: count.length,
            });

            console.log(dataList,'dataList');
        }
        if (props.settingReducer.usersListArray && props.settingReducer.usersListArray.data) {
            const usersListArray = props.settingReducer.usersListArray.data;

            this.setState({ usersListArray: usersListArray })
        }
        if (props.settingReducer.userSettingArray.data) {
            const settingData = props.settingReducer.userSettingArray.data;
            this.setState({
                userSettings: settingData,
            })
        }
    }
    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
          if (array[i][attr] === value) {
            return i;
          }
        }
        return -1;
      }
    handleChange = event => {
        event.persist();
        if (event.target.type === 'checkbox') {
            this.setState({ [event.target.name]: event.target.checked });
        }
        else {
            this.setState({ [event.target.name]: event.target.value });
        }
    };
    handleFormSubmit = () => {
        console.log('submited')
    };
    handleChangeTab = (event, newValue) => {
        this.setState({ TabValue: newValue });
    }
    handleCheck = (e) => {
        this.setState({ selctedEvent: parseInt(e.currentTarget.dataset.id) });
    }
    handleButton = (e) => {
        this.setState({ selctedButton: parseInt(e.currentTarget.dataset.id) });
    }
    handleSetTasks = () => {
        let settasks = this.state.SetTasks
        settasks.push({ title: "Set tasks " + (settasks.length + 1) })
        this.setState({ SetTasks: settasks })
    }
    deleteSetTasks = (index) => {
        let settasks = this.state.SetTasks.filter((val, ind) => index !== ind);
        this.setState({ SetTasks: settasks })
    }
    handleOwnerChange = (values, fieldkey, field, index) => {
        // console.log(values,'values');

        let submitFieldData = this.state.submitFieldData;
        if (submitFieldData[index] != undefined) {
            submitFieldData[index] = { fieldid: field.id, fieldkey: fieldkey, values: values.email, fieldvalues: values };
        } else {
            submitFieldData[index] = [];
            submitFieldData[index] = { fieldid: field.id, fieldkey: fieldkey, values: values.email, fieldvalues: values }
        }
        if (submitFieldData[index].condition == undefined && this.state.chooseOperator.length > 0) {
            submitFieldData[index].condition = this.state.chooseOperator[index].value;
            submitFieldData[index].conditionkey = this.state.chooseOperator[index];
        }

        console.log(submitFieldData, 'submitFieldData');
        this.setState({ submitFieldData })
    }

    render() {
        const { classes } = this.props;
        let { submitFieldData, name, TabValue, selctedEvent, taskname, activitytype, search, dueDate, daylaterOptions, reminder, owner, priority, visibility, tags, description } = this.state;
        return (
            <React.Fragment>
                <SettingSidebar />
                <div className="lead_content right-16 settings_view">
                    <Card elevation={3} className="h-100">
                        <div className="d-flex justify-content-between m-16">
                            <h3>Setup task automation for a lead</h3>
                        </div>
                        <div className="pl-16 pr-16 v_scroll">
                            <ValidatorForm instantValidate={false} noValidate ref="form" onSubmit={this.handleFormSubmit}>
                                <div className="box_portlet mb-16">
                                    <div className="box_header">
                                        <div className="box_label"><h3 className="box_title">Basic detail</h3></div>
                                    </div>
                                    <div className="box_body">
                                        <FormControl className="w-100 input_bx mb-16">
                                            {/* <label style={{ fontSize: '12px' }}>Name</label> */}
                                            <TextValidator
                                                className="w-100 input_bx"
                                                placeholder="Add name"
                                                onChange={this.handleChange}
                                                type="text"
                                                name="name"
                                                value={name}
                                                required={true}
                                                label={'Name'}
                                                validators={["required"]}
                                                errorMessages={["This field is required"]}
                                                InputLabelProps={{ shrink: true, disableAnimation: false, focused: true }}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="box_portlet mb-16">
                                    <div className="box_header">
                                        <div className="box_label"><h3 className="box_title">Trigger</h3></div>
                                    </div>
                                    <div className="box_body p-0">
                                        <Grid container className="select_trigger">
                                            <Grid item className="trigger_modules"><label className="text-lable">Select Entity</label></Grid>
                                            <Grid item className="trigger_event flex-auto"><label className="text-lable">Select Trigger</label></Grid>
                                        </Grid>
                                        <div className="d-flex workflow_trigger task_trigger">
                                            <Tabs
                                                orientation="vertical"
                                                variant="scrollable"
                                                value={TabValue}
                                                onChange={this.handleChangeTab}
                                                aria-label="Vertical tabs"
                                                classes={{ indicator: classes.bigIndicator }}
                                            >
                                                <Tab className="trigger_button" label={<React.Fragment><Icon>supervised_user_circle</Icon> Lead <Icon className="tabicon_active">check</Icon></React.Fragment>} {...a11yProps(0)} />
                                                <Tab className="trigger_button" label={<React.Fragment><Icon>person</Icon> Contact <Icon className="tabicon_active">check</Icon></React.Fragment>}{...a11yProps(1)} />
                                                <Tab className="trigger_button" label={<React.Fragment><Icon>business</Icon> Company <Icon className="tabicon_active">check</Icon></React.Fragment>} {...a11yProps(2)} />
                                                <Tab className="trigger_button" label={<React.Fragment><Icon>attach_money</Icon> Opportunity <Icon className="tabicon_active">check</Icon></React.Fragment>} {...a11yProps(3)} />
                                                <Tab className="trigger_button" label={<React.Fragment><Icon>business_center</Icon> Project <Icon className="tabicon_active">check</Icon></React.Fragment>} {...a11yProps(4)} />
                                                {/* <Tab className="trigger_button" label={<React.Fragment><Icon>list</Icon> Task <Icon className="tabicon_active">check</Icon></React.Fragment>} {...a11yProps(5)} /> */}
                                            </Tabs>
                                            <TabPanel value={TabValue} className="trigger_panel flex-auto" index={0}>
                                                <ul>
                                                    <li onClick={this.handleCheck} data-id="1" className={selctedEvent === 1 && 'trigger_active'}>
                                                        <p>When lead status <span className="font-weight-semi">Is created</span></p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="2" className={selctedEvent === 2 && 'trigger_active'}>
                                                        <p>When lead status <span className="font-weight-semi">Is changed to</span></p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="3" className={selctedEvent === 3 && 'trigger_active'}>
                                                        <p>When lead status <span className="font-weight-semi">Is assigned to</span></p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="4" className={selctedEvent === 4 && 'trigger_active'}>
                                                        <p>When lead status <span className="font-weight-semi">Is new and status duration is more than</span></p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="5" className={selctedEvent === 5 && 'trigger_active'}>
                                                        <p>When lead status <span className="font-weight-semi">Is new and status Inactive days is more than</span></p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                </ul>
                                            </TabPanel>
                                            <TabPanel value={TabValue} index={1} className="trigger_panel flex-auto">
                                                <ul>
                                                    <li onClick={this.handleCheck} data-id="1" className={selctedEvent === 1 && 'trigger_active'}>
                                                        <p>When contact <span className="font-weight-semi">is created</span></p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="2" className={selctedEvent === 2 && 'trigger_active'}>
                                                        <p>When contact <span className="font-weight-semi">is created from Lead conversion</span></p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="3" className={selctedEvent === 3 && 'trigger_active'}>
                                                        <p>When contact type <span className="font-weight-semi">is changed to</span></p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="4" className={selctedEvent === 4 && 'trigger_active'}>
                                                        <p>When <span className="font-weight-semi">Inactive days is more than</span></p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                </ul>
                                            </TabPanel>
                                            <TabPanel value={TabValue} index={2} className="trigger_panel flex-auto">
                                                <ul>
                                                    <li onClick={this.handleCheck} data-id="1" className={selctedEvent === 1 && 'trigger_active'}>
                                                        <p>When company <span className="font-weight-semi">1s created</span></p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="2" className={selctedEvent === 2 && 'trigger_active'}>
                                                        <p>When contact type <span className="font-weight-semi">Is changed to</span></p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="3" className={selctedEvent === 3 && 'trigger_active'}>
                                                        <p>When <span className="font-weight-semi">Inactive days is more than</span></p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                </ul>
                                            </TabPanel>
                                            <TabPanel value={TabValue} index={3} className="trigger_panel flex-auto">
                                                <ul>
                                                    <li onClick={this.handleCheck} data-id="1" className={selctedEvent === 1 && 'trigger_active'}>
                                                        <p>When opportunity <span className="font-weight-semi">Is created</span></p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="2" className={selctedEvent === 2 && 'trigger_active'}>
                                                        <p>When opportunity stage <span className="font-weight-semi">Is changed to</span></p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="3" className={selctedEvent === 3 && 'trigger_active'}>
                                                        <p>When opportunity <span className="font-weight-semi">Pipeline is changed to</span></p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="4" className={selctedEvent === 4 && 'trigger_active'}>
                                                        <p>When opportunity status <span className="font-weight-semi">Is changed to</span></p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="5" className={selctedEvent === 5 && 'trigger_active'}>
                                                        <p>When opportunity status <span className="font-weight-semi">Is assigned to</span></p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="6" className={selctedEvent === 6 && 'trigger_active'}>
                                                        <p>When opportunity status <span className="font-weight-semi">Is open and days in stage is more than</span></p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="7" className={selctedEvent === 7 && 'trigger_active'}>
                                                        <p>When opportunity status <span className="font-weight-semi">Is open and Inactive days is more than</span></p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="8" className={selctedEvent === 8 && 'trigger_active'}>
                                                        <p>When opportunity status <span className="font-weight-semi">Is open and close date is within</span></p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                </ul>
                                            </TabPanel>
                                            <TabPanel value={TabValue} index={4} className="trigger_panel flex-auto">
                                                <ul>
                                                    <li onClick={this.handleCheck} data-id="1" className={selctedEvent === 1 && 'trigger_active'}>
                                                        <p>When project <span className="font-weight-semi">Is created</span></p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="2" className={selctedEvent === 2 && 'trigger_active'}>
                                                        <p>When project status <span className="font-weight-semi">Is changed to</span></p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                </ul>
                                            </TabPanel>
                                            {/* <TabPanel value={TabValue} index={5} className="trigger_panel flex-auto">
                                                <ul>
                                                    <li onClick={this.handleCheck} data-id="26" className={selctedEvent === 26 && 'trigger_active'}>
                                                        <p>When task status <span className="font-weight-semi">Is created</span></p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="27" className={selctedEvent === 27 && 'trigger_active'}>
                                                        <p>When task status <span className="font-weight-semi">Is changed to</span></p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="28" className={selctedEvent === 28 && 'trigger_active'}>
                                                        <p>When task status <span className="font-weight-semi">Is assigned to</span></p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="29" className={selctedEvent === 29 && 'trigger_active'}>
                                                        <p>When task status <span className="font-weight-semi">Is new and status duration is more than</span></p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="30" className={selctedEvent === 30 && 'trigger_active'}>
                                                        <p>When task status <span className="font-weight-semi">Is new and status Inactive days is more than</span></p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                </ul>
                                            </TabPanel> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="box_portlet mb-16">
                                    <div className="box_header">
                                        <div className="box_label"><h3 className="box_title">Select status</h3></div>
                                    </div>
                                    <div className="box_body">
                                        <FormControlLabel className="custom-checkbox" control={<Checkbox name="New" value="New" color="primary" />} label="New" onChange={this.handleChange} />
                                        <FormControlLabel className="custom-checkbox" control={<Checkbox name="Open" value="Open" color="primary" />} label="Open" onChange={this.handleChange} />
                                        <FormControlLabel className="custom-checkbox" control={<Checkbox name="Unqualied" value="Unqualied" color="primary" />} label="Unqualied" onChange={this.handleChange} />
                                        <FormControlLabel className="custom-checkbox" control={<Checkbox name="Won" value="Won" color="primary" />} label="Won" onChange={this.handleChange} />
                                        <FormControlLabel className="custom-checkbox" control={<Checkbox name="Lost" value="Lost" color="primary" />} label="Lost" onChange={this.handleChange} />
                                    </div>
                                </div>
                                <div className="box_portlet mb-16">
                                    <div className="box_header">
                                        <div className="box_label"><h3 className="box_title">Set Tasks</h3></div>
                                    </div>
                                    <div className="box_body p-0">
                                        {this.state.SetTasks.map((item, index) => (
                                            <div className="set_task" key={index}>
                                                {this.state.SetTasks.length > 1 &&
                                                    <IconButton size="small" className="delete_settask" onClick={() => this.deleteSetTasks(index)}>
                                                        <Icon className="delete_icon">close</Icon>
                                                    </IconButton>
                                                }
                                                <Grid container spacing={3} alignItems="center">
                                                    <Grid item sm={6} xs={12}>
                                                        <FormControl className="w-100 input_bx mb-16">
                                                            {/* <label className="text-lable">Task Name <span className="text-error">*</span></label> */}
                                                            <TextValidator
                                                                className="w-100 input_bx"
                                                                placeholder="Add task name"
                                                                onChange={this.handleChange}
                                                                type="text"
                                                                name="taskname"
                                                                required={true}
                                                                label={'Task Name'}
                                                                value={taskname}
                                                                validators={["required"]}
                                                                errorMessages={["This field is required"]}
                                                                InputLabelProps={{ shrink: true, disableAnimation: false, focused: true }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item sm={6} xs={12}>
                                                        <FormControl className="w-100 input_bx mb-16">
                                                            <label style={{ fontSize: '12px' }}>Activity Type</label>
                                                            <Select
                                                                name="activitytype"
                                                                value={activitytype}
                                                                className="w-100 mt-0 custom-select"
                                                                onChange={this.handleChange}
                                                            >
                                                                <MenuItem className="select-menu-item" value={1}><Icon>email</Icon> Email</MenuItem>
                                                                <MenuItem className="select-menu-item" value={2}><Icon>phone</Icon> Phone Call</MenuItem>
                                                                <MenuItem className="select-menu-item" value={3}><Icon>event</Icon> Meeting</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={3} alignItems="center">
                                                    <Grid item sm={6} xs={12}>
                                                        <FormControl className="w-100 input_bx mb-16">
                                                            {/* <label className="text-lable">Due Date <span className="text-error">*</span></label> */}
                                                            <Grid container spacing={2} alignItems="center">
                                                                <Grid item>
                                                                    <TextValidator
                                                                        className="w-100 input_bx"
                                                                        placeholder="1"
                                                                        onChange={this.handleChange}
                                                                        type="text"
                                                                        name="dueDate"
                                                                        value={dueDate}
                                                                        required={true}
                                                                        label={'Due Date'}
                                                                        validators={["required"]}
                                                                        errorMessages={["This field is required"]}
                                                                        InputLabelProps={{ shrink: true, disableAnimation: false, focused: true }}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <label classname="text-label">day later at</label>
                                                                </Grid>
                                                                <Grid item className="flex-auto">
                                                                    <Select
                                                                        name="daylaterOptions"
                                                                        value={daylaterOptions}
                                                                        onChange={this.handleChange}
                                                                        MenuProps={{ className: 'choose_field_menu' }}
                                                                        className="w-100"
                                                                    >
                                                                        <MenuItem className="select-menu-input">
                                                                            <TextValidator
                                                                                className="w-100 input_bx"
                                                                                onChange={this.handleChange}
                                                                                type="text"
                                                                                name="search"
                                                                                placeholder="Search"
                                                                                value={search}
                                                                                onClick={e => e.stopPropagation()}
                                                                            />
                                                                        </MenuItem>
                                                                        <MenuItem className="select-menu-item" value={1}>Midnight</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={2}>12:30 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={3}>1:00 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={4}>1:30 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={5}>2:00 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={6}>2:30 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={7}>3:00 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={8}>3:30 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={9}>4:00 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={10}>4:30 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={11}>5:00 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={12}>5:30 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={13}>6:00 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={14}>6:30 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={15}>7:00 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={16}>7:30 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={17}>8:00 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={18}>8:30 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={19}>9:00 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={20}>9:30 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={21}>10:00 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={22}>10:30 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={23}>11:00 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={24}>11:30 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={25}>Noon</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={26}>12:30 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={27}>1:00 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={28}>1:30 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={29}>2:00 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={30}>2:30 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={31}>3:00 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={32}>3:30 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={33}>4:00 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={34}>4:30 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={35}>5:00 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={36}>5:30 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={37}>6:00 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={38}>6:30 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={39}>7:00 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={40}>7:30 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={41}>8:00 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={42}>8:30 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={43}>9:00 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={44}>9:30 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={45}>10:00 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={46}>10:30 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={47}>11:00 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={48}>11:30 PM</MenuItem>
                                                                    </Select>
                                                                </Grid>
                                                            </Grid>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item sm={6} xs={12}>
                                                        <FormControl className="w-100 input_bx mb-16">
                                                            {/* <label className="text-lable">Reminder</label> */}
                                                            <Grid container spacing={2} alignItems="center">
                                                                <Grid item>
                                                                    <TextValidator
                                                                        className="w-100 input_bx"
                                                                        placeholder="1"
                                                                        onChange={this.handleChange}
                                                                        label={'Reminder'}
                                                                        required={true}
                                                                        type="text"
                                                                        name="reminder"
                                                                        value={reminder}
                                                                        validators={["required"]}
                                                                        errorMessages={["This field is required"]}
                                                                        InputLabelProps={{ shrink: true, disableAnimation: false, focused: true }}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <label classname="text-label">day later at</label>
                                                                </Grid>
                                                                <Grid item className="flex-auto">
                                                                    <Select
                                                                        name="daylaterOptions"
                                                                        value={daylaterOptions}
                                                                        onChange={this.handleChange}
                                                                        MenuProps={{ className: 'choose_field_menu' }}
                                                                        className="w-100"
                                                                    >
                                                                        <MenuItem className="select-menu-input">
                                                                            <TextValidator
                                                                                className="w-100 input_bx"
                                                                                onChange={this.handleChange}
                                                                                type="text"
                                                                                name="search"
                                                                                placeholder="Search"
                                                                                value={search}
                                                                                onClick={e => e.stopPropagation()}
                                                                            />
                                                                        </MenuItem>
                                                                        <MenuItem className="select-menu-item" value={1}>Midnight</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={2}>12:30 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={3}>1:00 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={4}>1:30 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={5}>2:00 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={6}>2:30 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={7}>3:00 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={8}>3:30 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={9}>4:00 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={10}>4:30 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={11}>5:00 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={12}>5:30 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={13}>6:00 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={14}>6:30 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={15}>7:00 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={16}>7:30 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={17}>8:00 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={18}>8:30 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={19}>9:00 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={20}>9:30 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={21}>10:00 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={22}>10:30 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={23}>11:00 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={24}>11:30 AM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={25}>Noon</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={26}>12:30 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={27}>1:00 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={28}>1:30 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={29}>2:00 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={30}>2:30 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={31}>3:00 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={32}>3:30 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={33}>4:00 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={34}>4:30 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={35}>5:00 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={36}>5:30 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={37}>6:00 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={38}>6:30 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={39}>7:00 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={40}>7:30 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={41}>8:00 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={42}>8:30 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={43}>9:00 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={44}>9:30 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={45}>10:00 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={46}>10:30 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={47}>11:00 PM</MenuItem>
                                                                        <MenuItem className="select-menu-item" value={48}>11:30 PM</MenuItem>
                                                                    </Select>
                                                                </Grid>
                                                            </Grid>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={3} alignItems="center">
                                                    <Grid item sm={6} xs={12}>
                                                        <FormControl className="w-100 input_bx">
                                                            {/* <Autocomplete
                                                                disableOpenOnFocus
                                                                forcePopupIcon={false}
                                                                // name={fieldtemp.fieldkey}
                                                                options={this.state.usersListArray}
                                                                getOptionLabel={option => option.name}
                                                                value={submitFieldData[indexOfCondition] ? submitFieldData[indexOfCondition].fieldvalues : ''}
                                                                // name={fieldtemp.fieldkey}
                                                                onChange={(event, value, field, index) => this.handleOwnerChange(value, fieldtemp.fieldkey, fieldtemp, indexOfCondition)}
                                                                id={field.id}
                                                                renderInput={params => (
                                                                    <TextValidator
                                                                        {...params}
                                                                        variant="standard"
                                                                        label={"Owner"}
                                                                        fullWidth
                                                                        ref={ref => field = ref}
                                                                        // name={fieldtemp.fieldkey}
                                                                        value={submitFieldData[indexOfCondition] ? submitFieldData[indexOfCondition].fieldvalues : ''}
                                                                        required={true}
                                                                        validators={["required"]}
                                                                        errorMessages={["This field is required"]}
                                                                    />
                                                                )}
                                                            /> */}
                                                        </FormControl>
                                                        {/* <FormControl className="w-100 input_bx mb-16">
                                                            <label style={{ fontSize: '12px' }}>Owner</label>
                                                            <Select
                                                                name="owner"
                                                                value={owner}
                                                                className="w-100 mt-0"
                                                                onChange={this.handleChange}
                                                            >
                                                                <MenuItem className="select-menu-item" value={1}>Justin</MenuItem>
                                                                <MenuItem className="select-menu-item" value={2}>John</MenuItem>
                                                                <MenuItem className="select-menu-item" value={3}>Nick</MenuItem>
                                                            </Select>
                                                        </FormControl> */}
                                                    </Grid>
                                                    <Grid item sm={6} xs={12}>
                                                        <FormControl className="w-100 input_bx mb-16">
                                                            <label style={{ fontSize: '12px' }}>Priority</label>
                                                            <Select
                                                                name="priority"
                                                                value={priority}
                                                                className="w-100 mt-0"
                                                                onChange={this.handleChange}
                                                            >
                                                                <MenuItem className="select-menu-item" value={1}>None</MenuItem>
                                                                <MenuItem className="select-menu-item" value={2}>High</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={3} alignItems="center">
                                                    <Grid item sm={6} xs={12}>
                                                        <FormControl className="w-100 input_bx mb-16">
                                                            <label style={{ fontSize: '12px' }}>Visibility</label>
                                                            <Select
                                                                name="visibility"
                                                                value={visibility}
                                                                className="w-100 mt-0"
                                                                onChange={this.handleChange}
                                                            >
                                                                <MenuItem className="select-menu-item" value={1}>Everyone</MenuItem>
                                                                <MenuItem className="select-menu-item" value={2}>Teams</MenuItem>
                                                                <MenuItem className="select-menu-item" value={3}>Individuals</MenuItem>
                                                                <MenuItem className="select-menu-item" value={4}>Only Owner</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item sm={6} xs={12}>
                                                        <FormControl className="w-100 input_bx mb-16">
                                                            <label style={{ fontSize: '12px' }}>Tags</label>
                                                            <Autocomplete
                                                                multiple
                                                                id="tags-filled"
                                                                options={tags.map(option => option.title)}
                                                                defaultValue={[tags[0].title]}
                                                                freeSolo
                                                                renderTags={(value, getTagProps) =>
                                                                    value.map((option, index) => (
                                                                        <Chip label={option} {...getTagProps({ index })} />
                                                                    ))
                                                                }
                                                                renderInput={params => (
                                                                    <TextField
                                                                        {...params}
                                                                        fullWidth
                                                                    />
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={3} alignItems="center">
                                                    <Grid item xs={12}>
                                                        <FormControl className="w-100 input_bx mb-16">
                                                            <label style={{ fontSize: '12px' }}>Description</label>
                                                            <TextField
                                                                multiline
                                                                rows="4"
                                                                name="description"
                                                                value={description}
                                                                className="w-100 mt-4"
                                                                onChange={this.handleChange}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        ))}
                                        <Button size="small" className="ml-16 mb-16 mr-16" variant="contained" color="primary" onClick={this.handleSetTasks}><Icon>add</Icon> Add New Task</Button>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-end mt-16 mb-40">
                                    <Button variant="contained" className="mr-8">Cancel</Button>
                                    <Button variant="contained" type="submit" color="primary">Save</Button>
                                </div>
                            </ValidatorForm>
                        </div>
                    </Card>
                </div>
            </React.Fragment>
        );
    }
}

// export default withStyles(styles)(AddTaskAutomation);

const mapStateToProps = state => {
    return {
        settingReducer: state.settingReducer,
        apolloClient: state.apolloClient
    };
};

const mapDispatchToProps = dispatch => {
    return {
        customFieldList: (client, request) => dispatch(actions.customFieldList(client, request)),
        saveAutomation: (client, request) => dispatch(actions.saveAutomation(client, request)),

        getActivityTypes: (client) => dispatch(actions.getActivityTypes(client)),
        getCompanyRecList: (client) => dispatch(actions.getCompanyRecList(client)),
        getContactRecList: (client) => dispatch(actions.getContactRecList(client)),

        getUserList: (client) => dispatch(actions.getUserList(client)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withStyles(styles)(AddTaskAutomation));