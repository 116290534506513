import React, { Component } from 'react';
import { Dialog, IconButton, withStyles,RadioGroup, Button, Icon, Radio, Tooltip, Grid, FormControl, Select, InputLabel, Menu, MenuItem, List, ListItem, ListItemText, DialogActions, Chip, TextField } from "@material-ui/core";
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CommonDisbledLock from "./CommonDisbledLock"
const StyledTooltip = withStyles({
  tooltipPlacementTop: {
    margin: "7px 0",
  },
})(Tooltip);
class CommonVisibiltyEdit extends Component {
  render() {

    let data = this.props.data;
    let v = this.props.v;
    let disabledAll = !data.state.crudPermission.isedit
    var valDropdown = {};
    if (v.options && data.state.fieldsObj[v.fieldkey] && data.state.fieldsObj[v.fieldkey].values) {
      valDropdown = v.options.filter(row => row.id == data.state.fieldsObj[v.fieldkey].values.id)[0]
    }
    let tilt = (
      data.state.teamUser && data.state.teamUser.map((u, l) => (
        <>
          <span key={l} style={{ maxWidth: "200px" }} className="text_ellipsis capitalize">
            {
              u.username + "(" + u.userteam.map((t, i) => t) + ")"
            }
          </span><br />
        </>
      ))
    )
    return (
      <>
        {
          data.state.hasActiveTeamPermission === true ?
            <Grid item xs={12}>
              <InputLabel>{v.fieldlabel}{(v.recordeditdefaultvalue == 3 || disabledAll) && <CommonDisbledLock />}</InputLabel>
              {!data.state.isEditVisibility ?
                <Grid container justify="space-between" className="edit_visibilty">
                  {
                    data.state.teamvisible === false ?
                      <h5 className="font-size-13 font-weight-400 mt-4" >
                        Manually set visibility:
                       {data.state.fieldsObj[v.fieldkey]
                          && data.state.fieldsObj[v.fieldkey].values.name ?
                          data.state.fieldsObj[v.fieldkey].values.name
                          : ""}
                      </h5>
                      :
                      <div className="d-flex">
                        <h5 className="font-size-13 font-weight-400 mt-4" >
                          {data.state.teamVisibilityLabel}
                        </h5>
                        {
                          data.state.teamUser && data.state.teamUser.length > 0 &&
                          <StyledTooltip placement="top" arrow title={tilt}>
                            <span className="font-size-13 font-weight-400 ml-4 text_link">(preview)</span>
                          </StyledTooltip>
                        }
                      </div>
                  }
                  {
                    data.props.authReducer.userRole.id !== 3 &&
                    <span
                      disabled={(disabledAll) ? true : false}
                      type="button"
                      onClick={data.handleToggleEditVisibility}
                      className="cursor-pointer font-size-13 mt-4 hover_show pr-16 text_link" >
                      Edit
                  </span>
                  }
                </Grid>
                :
                <Grid container className="pl-4 pt-4 visibility_radio">
                  <RadioGroup aria-label="gender" value={data.state.teamvisible} name="teamvisible"
                    onChange={(event) =>
                      data.handleChangeVisibilityRadio(event.target.value)
                    }>
                    <FormControlLabel value={true} control={<Radio color={"primary"} />}
                      label={
                        <div className="w-100">
                          <span
                            className={`
                 ${data.state.teamvisible === true ?
                                "font-weight-500 " : "font-weight-400 "}
                  "d-block font-size-13 "`}
                          >Use team visibility settings</span>
                          <h5 className="font-size-13 font-weight-400">
                            {data.state.teamVisibilityLabel}
                          </h5>
                        </div>
                      }
                    />
                    <FormControlLabel value={false} control={<Radio color={"primary"} />}
                      label={
                        <div className="w-100">
                          <span
                            className={`
                 ${data.state.teamvisible === false ? "font-weight-500 " : "font-weight-400 "}
                  "d-block font-size-13 "`}>Manually set visibility</span>
                          {
                            data.state.teamvisible === true &&
                            <h5 className="font-size-13 font-weight-400 ">
                              {data.state.fieldsObj[v.fieldkey]
                                ? data.state.fieldsObj[v.fieldkey].values.name
                                : ""}
                            </h5>
                          }
                        </div>
                      } />
                  </RadioGroup>
                  {data.state.teamvisible === false &&
                    <SelectValidator
                      ref={(ref) => (data[v.fieldkey] = ref)}
                      disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
                      value={valDropdown}
                      className="w-100 input_bx mt--16 pl-24"
                      onChange={(event) =>
                        data.handleChange(
                          { fieldid: v.id, values: event.target.value },
                          v.fieldkey
                        )
                      }
                      name={v.fieldlabel}
                      id={v.id}
                      required={v.recordcreationdefaultvalue == 2 ? true : false}
                      placeholder={"Add " + v.fieldlabel}
                      validators={
                        v.recordcreationdefaultvalue == 2 ? ["required"] : []
                      }
                      errorMessages={
                        v.recordcreationdefaultvalue == 2
                          ? ["This field is required"]
                          : []
                      }
                    >
                      {v.options &&
                        v.options.map((item, key) => (
                          <MenuItem
                            className="select-menu-item w-100 input_bx"
                            key={item.id}
                            value={item}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                    </SelectValidator>
                  }
                  {
                    data.state.fieldsObj[v.fieldkey] &&
                    data.state.fieldsObj[v.fieldkey].values &&
                    data.state.fieldsObj[v.fieldkey].values.id === 2 &&
                    data.state.selectedTeamList.map((team, i) => (
                      <Autocomplete
                        disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}

                        key={i}
                        disableOpenOnFocus
                        forcePopupIcon={false}
                        name={"team" + i}
                        className="w-100 input_bx "
                        options={data.state.teamList}
                        getOptionLabel={(option) => option.teamname ? option.teamname : ""}
                        value={team}
                        onChange={(event, value) =>
                          data.teamHandleChange(i, value, "team")}
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
                            required={i == 0 ? true : false}
                            variant="standard"
                            className="w-100 input_bx pl-24"
                            fullWidth
                            placeholder="Add Team "
                            name={"team" + i}
                            value={team.teamname && team.teamname.length > 0 ? team.teamname : null}
                          />
                        )}
                      />
                    ))
                  }
                  {
                    data.state.fieldsObj[v.fieldkey] &&
                    data.state.fieldsObj[v.fieldkey].values &&
                    data.state.fieldsObj[v.fieldkey].values.id === 3 &&
                    data.state.selectedUserList.map((user, i) => (
                      <Autocomplete
                        disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}

                        key={i}
                        disableOpenOnFocus
                        forcePopupIcon={false}
                        name={"user" + i}
                        className="w-100 input_bx "
                        options={data.state.userListForVisibility}
                        getOptionLabel={(option) => option.name ? option.name : ""}
                        value={user}
                        onChange={(event, value) =>
                          data.teamHandleChange(i, value, "user")}
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            required={i == 0 ? true : false}
                            disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}

                            variant="standard"
                            className="w-100 input_bx pl-24"
                            fullWidth
                            placeholder="Add user "
                            name={"user" + i}
                            value={user.name && user.name.length > 0 ? user.name : null}
                          />
                        )}
                      />
                    ))
                  }
                </Grid>
              }
            </Grid>
            :
            <Grid item xs={12}>
              <InputLabel>{v.fieldlabel}{(v.recordeditdefaultvalue == 3 || disabledAll) && <CommonDisbledLock />}</InputLabel>
              <SelectValidator
                ref={(ref) => (data[v.fieldkey] = ref)}
                disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
                value={valDropdown}
                className="w-100 input_bx"
                onChange={(event) =>
                  data.handleChange(
                    { fieldid: v.id, values: event.target.value },
                    v.fieldkey
                  )
                }
                name={v.fieldlabel}
                id={v.id}
                required={v.recordcreationdefaultvalue == 2 ? true : false}
                // label={v.fieldlabel}
                placeholder={"Add " + v.fieldlabel}
                validators={
                  v.recordcreationdefaultvalue == 2 ? ["required"] : []
                }
                errorMessages={
                  v.recordcreationdefaultvalue == 2
                    ? ["This field is required"]
                    : []
                }
              >
                {v.options &&
                  v.options.map((item, key) => (
                    <MenuItem
                      className="select-menu-item w-100 input_bx"
                      key={item.id}
                      value={item}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
              </SelectValidator>
              {
                data.state.fieldsObj[v.fieldkey] &&
                data.state.fieldsObj[v.fieldkey].values &&
                data.state.fieldsObj[v.fieldkey].values.id === 2 &&
                data.state.selectedTeamList.map((team, i) => (
                  <Autocomplete
                    disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}

                    key={i}
                    disableOpenOnFocus
                    forcePopupIcon={false}
                    name={"team" + i}
                    className="w-100 input_bx "
                    options={data.state.teamList}
                    getOptionLabel={(option) => option.teamname ? option.teamname : ""}
                    value={team}
                    onChange={(event, value) =>
                      data.teamHandleChange(i, value, "team")}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
                        variant="standard"
                        className="w-100 input_bx"
                        fullWidth
                        placeholder="Add Team "
                        name={"team" + i}
                        value={team.teamname && team.teamname.length > 0 ? team.teamname : null}
                      />
                    )}
                  />
                ))
              }
              {
                data.state.fieldsObj[v.fieldkey] &&
                data.state.fieldsObj[v.fieldkey].values &&
                data.state.fieldsObj[v.fieldkey].values.id === 3 &&
                data.state.selectedUserList.map((user, i) => (
                  <Autocomplete
                    disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
                    key={i}
                    disableOpenOnFocus
                    forcePopupIcon={false}
                    name={"user" + i}
                    className="w-100 input_bx "
                    options={data.state.userListForVisibility}
                    getOptionLabel={(option) => option.name ? option.name : ""}
                    value={user}
                    onChange={(event, value) =>
                      data.teamHandleChange(i, value, "user")}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        required={i == 0 ? true : false}
                        disabled={(v.recordeditdefaultvalue == 3 || disabledAll) ? true : false}
                        variant="standard"
                        className="w-100 input_bx "
                        fullWidth
                        placeholder="Add user "
                        name={"user" + i}
                        value={user.name && user.name.length > 0 ? user.name : null}
                      />
                    )}
                  />
                ))
              }
            </Grid>
        }
        {data.state.isEditVisibility &&
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            spacing={2}
            className="mt-4"
          >
            <Button variant="contained" size="small" className="mr-10"
              onClick={data.hadleCancelvisibility}>
              Cancel</Button>
            <Button variant="contained"
              size="small" onClick={data.handleVisibilitySave}
              color="primary">Save</Button>
          </Grid >
        }
      </>
    )
  }
}

export default CommonVisibiltyEdit