import React, { Component } from "react";
import {
  Dialog,
  IconButton,
  Button,
  Icon,
  Tooltip,
  Grid,
  DialogActions,
  CircularProgress,
  withStyles
} from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";

import { connect } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { createContactDynamicFields } from "./createContactDynamicFields";
import moment from "moment";
const TooltipNew = withStyles((theme) => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);
class CreateNewContact extends Component {
  state = {
    lastname: "",
    avtar: "",
    city: "",
    street: "",
    addressState: "",
    zipcode: "",
    country: "",
    countryListArray: [],
    gmailSuggestion: [],
    companyCurrencyData: [],
    comp_options: [],
    comp_value: "",
    comp_isLoading: false,
    focusValue: false,
    fieldsList: [],
    fieldsObj: {
      websites: {
        fieldid: "23",
        values: [{ srno: "1", id: null, value: "" }],
      },
      social: {
        fieldid: "24",
        values: [{ srno: "1", id: null, value: "" }],
      },
      phones: {
        fieldid: "22",
        values: [{ srno: "1", id: null, value: "" }],
      },
      email: {
        fieldid: "21",
        values: [{ srno: "1", id: null, value: "" }],
      },
    },
    tags: [],
    systemTagsList: [],
    usersListArray: [],
    userSettings: "",
    isDataLoading: true,
    datafromsidebar: {},
    teamvisible: true,
    hasActiveTeamPermission: false,
    userListForVisibility: [],
    selectedUserList: [],
    isEditVisibility: false,
    selectedTeamList: [],
    teamList: [],
    teamVisibilityLabel: "",
    teamUser: [],
    compnayCrud: {
      isaccess: true,
      iscreate: true,
    }
  };

  componentWillMount() {
    
    ValidatorForm.addValidationRule('IsURLValid', (value) => {
      if (value && value.length && value.length > 0) {
        var specialChar = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
        if (!specialChar.test(value)) {
          return false;
        }
      }
      return true;
    });
    
    this.props.getTeam(this.props.apolloClient.client);
    this.props.getLoginUserPermission(this.props.apolloClient.client);

    this.props.getCompanyRecList(this.props.apolloClient.client);
    this.setState({ isFromTopBar :  this.props.isFromTopBar });
    //if contact create from opportunity sidebar then set name
    if (this.props.name) {
      let fieldsObj = this.state.fieldsObj;
      var data = {
        fieldid: "16",
        contactvalue: this.props.name,
      };

      fieldsObj = {
        ...fieldsObj,
        ["name"]: data,
      };
      this.setState({
        datafromsidebar: this.props.dataFromSidebar,
        fieldsObj,
      });
    }

    if (this.props.opportunitiesid) {
      localStorage.setItem("opportunitiesid", this.props.opportunitiesid);
    }
    if (this.props.cmpId) {
      this.setState({
        comp_value: this.props.cmpId,
      });
    }

    // let userData = JSON.parse(localStorage.getItem('user_data_session'))
    // let userid = { userid: userData.id }
    // this.props.getUserSetting(this.props.apolloClient.client, userid);

    let val = { typeId: 2 };
    this.props.customFieldList(this.props.apolloClient.client, val);
    this.props.getUserList(this.props.apolloClient.client);
    this.props.getCountryList(this.props.apolloClient.client);
    this.props.getCompanyCurrency(this.props.apolloClient.client);
    this.props.getTagsList(this.props.apolloClient.client);
  }
  AllMemberDisplay = () => {
    let teamVisibilityLabel = "";
    let userCount = 0;
    let teamCount = 0;
    let teamUser = [];
    let usersListArray = this.props.settingReducer.usersListArray.data ? this.props.settingReducer.usersListArray.data : []
    usersListArray.length > 0 && usersListArray.map((user) => {
      if (user.teamlist.length > 0) {
        let userdata = { userteam: [], id: "", username: "" }
        teamCount = teamCount < user.teamlist.length ? user.teamlist.length : teamCount;
        user.teamlist.map((u) => { userdata.userteam.push(u.team.teamname) })
        userdata.id = user.id;
        userdata.username = user.name;
        userCount = userCount + 1;
        teamUser.push(userdata)
      }
    })
    teamVisibilityLabel = userCount + " users from " + teamCount + " team ";
    this.setState({
      teamVisibilityLabel: teamVisibilityLabel,
      teamUser: teamUser
    }, () => this.forceUpdate())
  }
  selectedUserDetails = (mainuser) => {
    let teamVisibilityLabel = "";
    let teamUser = [];
    let userdata = { userteam: [], id: "", username: "" }
    mainuser.teamlist.map((u) => { userdata.userteam.push(u.team.teamname) })
    userdata.id = mainuser.id;
    userdata.username = mainuser.name;
    teamUser.push(userdata)
    teamVisibilityLabel = "1 users from " + mainuser.teamlist.length + " team ";
    this.setState({
      teamVisibilityLabel: teamVisibilityLabel,
      teamUser: teamUser
    }, () => this.forceUpdate())
  }
  themSelves = (selectedOwner) => {
    let teamVisibilityLabel = "";
    let userCount = 0;
    let teamCount = 0;
    let teamUser = [];
    let usersListArray = this.props.settingReducer.usersListArray.data ? this.props.settingReducer.usersListArray.data : []
    usersListArray.length > 0 && usersListArray.map((user) => {
      if (user.teamlist.length > 0) {
        if (user.teamlist[0].teammanager === true || (user.email === selectedOwner.email)) {
          let userdata = { userteam: [], id: "", username: "" }
          teamCount = teamCount < user.teamlist.length ? user.teamlist.length : teamCount;
          user.teamlist.map((u) => { userdata.userteam.push(u.team.teamname) })
          userdata.id = user.id;
          userdata.username = user.name;
          userCount = userCount + 1;
          teamUser.push(userdata)
        }
      }
    })
    teamVisibilityLabel = userCount + " users from " + teamCount + " team ";
    this.setState({
      teamVisibilityLabel: teamVisibilityLabel,
      teamUser: teamUser
    }, () => this.forceUpdate())
  }
  getUserPermission = () => {
    if (this.props.settingReducer.userPermissionDetails.data) {
      let data = this.props.settingReducer.userPermissionDetails.data.data;
      if (data.teampermissionstatus === true &&
        data.visibilityaccess.length > 0) {
        let usersListArray = this.props.settingReducer.usersListArray.data ? this.props.settingReducer.usersListArray.data : []
        let leaddt = data.visibilityaccess.find(function (e) { return e.sourcetype === 2 });
        let compnayCrud = "";
        if (this.props.authReducer.userRole.id === 3) {
          compnayCrud = data.visibilityaccess.find(function (e) { return e.sourcetype === 3 });
        }
        if (leaddt.isaccess) {
          if (leaddt.viewoption === 1) {
            this.AllMemberDisplay();
          }
          if (leaddt.viewoption === 2 || leaddt.viewoption === 3) {
            var selectedOwner = this.state.fieldsObj["owner"]
            if (selectedOwner && selectedOwner.values) {
              if (selectedOwner.values.teamlist.length > 0) {//if member in any team 
                if (selectedOwner.values.teamlist[0].teammanager === false) {//owner is not  teammanager
                  if (leaddt.viewoption === 2) {
                    this.AllMemberDisplay();
                  } else {
                    this.themSelves(selectedOwner.values);
                  }
                } else {//owner is teammanager   
                  this.selectedUserDetails(selectedOwner.values);
                }
              } else { // not in any team 
                this.setState({ teamVisibilityLabel: "0 users", teamUser: [] }, () => this.forceUpdate())
              }
            } else {
              this.setState({ teamVisibilityLabel: "0 users", teamUser: [] }, () => this.forceUpdate())
            }
          }
        } else {//leaddt.isaccess === false
          this.setState({ teamVisibilityLabel: "0 users", teamUser: [] }, () => this.forceUpdate())
        }
        this.setState({
          hasActiveTeamPermission: true,
          viewoption: leaddt.viewoption,
          compnayCrud: compnayCrud === "" ? this.state.compnayCrud : compnayCrud
        })
      } else {
        this.setState({ teamvisible: false })
      }
    }
  }
  componentWillReceiveProps(props) {
    this.getUserPermission();
    if (props.settingReducer.teamList.data && this.state.teamList !== props.settingReducer.teamList.data) {
      const teamList = props.settingReducer.teamList.data;
      let data = [];
      teamList.map((v, i) => {
        data.push({ id: v.id, teamname: v.teamname })
      });
      this.setState({ teamList: data })
    }
    //storing user setting data in state
    if (
      props.settingReducer.userSettingArray.data &&
      this.state.userSettings == ""
    ) {
      const settingData = props.settingReducer.userSettingArray.data;
      this.setState({
        userSettings: settingData,
      });
    }

    if (
      props.settingReducer.companyRecList &&
      props.settingReducer.companyRecList.data &&
      props.settingReducer.companyRecList.data.data
    ) {
      let companyOptions = [];
      props.settingReducer.companyRecList.data.data.map((compOpt) => {
        companyOptions.push({ label: compOpt.name, value: compOpt.company_id });
      });
      this.setState({ comp_options: companyOptions }, () => {
        if (this.props.cmpId) {
          let compSelectkey = this.findWithAttr(
            companyOptions,
            "value",
            this.state.comp_value
          );
          if (compSelectkey != -1) {
            this.handleChange(
              {
                fieldid: "17",
                values: {
                  id: companyOptions[compSelectkey].value,
                  name: companyOptions[compSelectkey].label,
                },
              },
              "company"
            );
            this.setState({ comp_value: this.props.cmpId });
          }
        }
      });
    }

    if (
      props.settingReducer.contactCustomFieldList.data &&
      props.settingReducer.contactCustomFieldList.is_loaded
    ) {
      const customFields = props.settingReducer.contactCustomFieldList.data;
      props.settingReducer.contactCustomFieldList.is_loaded = false;
      this.setState({ fieldsList: customFields, isDataLoading: false });
      var vkey = this.findWithAttr(customFields, "id", "26");
      var contactTypeKey = this.findWithAttr(customFields, "id", "19");
      var visibilitydefault = this.findWithAttr(customFields[vkey].options, "is_default", true);

      this.setState({
        fieldsObj: {
          ...this.state.fieldsObj,
          ["contacttype"]: {
            fieldid: "19",
            values: customFields[contactTypeKey].options[0],
          },
          ["visibility"]: {
            fieldid: "26",
            values: customFields[vkey].options[visibilitydefault],
          },
        },
      });
    }
    if (props.settingReducer.countryListGql.data) {
      const countryies = props.settingReducer.countryListGql.data;
      this.setState({ countryListArray: countryies });
    }
    if (
      props.settingReducer.usersListArray.data &&
      this.state.usersListArray.length == 0
    ) {
      const usersListArray = props.settingReducer.usersListArray.data;

      let userListForVisibility = [];

      usersListArray.map((data, i) => {
        userListForVisibility.push({ id: data.id, name: data.name })
      })
      let userData = JSON.parse(localStorage.getItem('user_data_session'))
      var companyOwnerKey = this.findWithAttr(usersListArray, "email", userData.email);

      this.setState({
        usersListArray: usersListArray,
        userListForVisibility: userListForVisibility,
        fieldsObj: {
          ...this.state.fieldsObj,
          ["owner"]: { fieldid: "20", values: usersListArray[companyOwnerKey] },
        },
      });
    }

    if (props.notification && props.notification.status === true) {
      // props.handleClose();
      props.clearNotification();
    }

    if (props.settingReducer.systemTagsList.data) {
      const tagsData = props.settingReducer.systemTagsList.data;
      this.setState({ systemTagsList: tagsData });
    }

    if (props.settingReducer.companyCurrencyData.data != null) {
      var cData = props.settingReducer.companyCurrencyData.data;
      this.setState({ companyCurrencyData: cData[0] });
    }
    if (
      props.settingReducer.gmailSuggestions &&
      props.settingReducer.gmailSuggestions.data
    ) {
      let gmailSuggestion = [];
      let name = "";
      let city = "";
      let region = "";
      let countryCode = "";
      let first_name = "";
      let last_name = "";
      let avtar = "";
      let phones = "";
      let social = "";
      props.settingReducer.gmailSuggestions.data.map((suggestion) => {
        // if (suggestion.name != null) {
        //   name = suggestion.name;
        // } else {
        //   name = suggestion.email.split("@")[0];
        // }

        if (suggestion.enrichment_data.hasOwnProperty("enrich_data")) {
          if (
            suggestion.enrichment_data.enrich_data.hasOwnProperty("locations")
          ) {
            if (suggestion.enrichment_data.enrich_data.locations.length > 0) {
              city = suggestion.enrichment_data.enrich_data.locations[0].city;

              region = suggestion.enrichment_data.enrich_data.locations[0].region;
              countryCode =
                suggestion.enrichment_data.enrich_data.locations[0].countryCode;
            }
          }
          if (suggestion.enrichment_data.enrich_data.hasOwnProperty("name")) {
            first_name = suggestion.enrichment_data.enrich_data.name.given;
            last_name = suggestion.enrichment_data.enrich_data.name.family;
          }

          if (suggestion.enrichment_data.enrich_data.hasOwnProperty("photos")) {
            avtar = suggestion.enrichment_data.enrich_data.photos.length > 0 &&
              suggestion.enrichment_data.enrich_data.photos[0].value;
          }

          if (suggestion.enrichment_data.enrich_data.hasOwnProperty("phones")) {
            phones = suggestion.enrichment_data.enrich_data.phones;
          }
          if (
            suggestion.enrichment_data.enrich_data.hasOwnProperty("profiles")
          ) {
            social = suggestion.enrichment_data.enrich_data.profiles;
          }
        }

        gmailSuggestion.push({
          label: suggestion.name,
          value: suggestion.id,
          email: suggestion.email,
          city: city,
          region: region,
          countryCode: countryCode,
          first_name: first_name,
          last_name: last_name,
          avtar: avtar,
          phones: phones,
          social: social,
        });
      });

      this.setState({ gmailSuggestion });
    }
    // if (props.settingReducer.gmailSuggestions.data) {
    //   this.setState({ gmailSuggestion: props.settingReducer.gmailSuggestions.data })
    // }
  }

  getChangeSuggestions = (event, fieldkey, values) => {
    let val = { name: event.target.value };
    this.handleChange(
      { fieldid: "16", contactvalue: event.target.value.trim() },
      fieldkey,
      values
    );
    this.props.getSuggestionData(this.props.apolloClient.client, val);
  };

  handleNameChange = (event, values, fieldkey, valEmail) => {
    if (values) {
      if (values.email)
        this.handleAddMoreField("value", "email", 0, values.email);

      if (values.label) {
        this.handleChange(
          { fieldid: "16", contactvalue: values.label.trim() },
          fieldkey,
          values.email
        );
      } else {
        this.handleChange(
          { fieldid: "16", contactvalue: "" },
          fieldkey,
          values.email
        );
      }
    }
  };

  handleCompanyChange = (val, newValue) => {
    let id = newValue.__isNew__ ? "0" : newValue.value;
    let comp_values = { id: id, name: newValue.label };
    this.handleChange({ fieldid: "17", values: comp_values }, "company");
    this.setState({ comp_value: id });
  };

  // handleCompanyCreate = (inputValue) => {

  //   console.log(inputValue,'ajsdakjshdk');
  //     const { comp_options } = this.state;
  //     const newOption = { label: inputValue, value: inputValue, isnew: 1};
  //     let comp_values = { id: "0", name: newOption.label };
  //     this.handleChange({ fieldid: "17", values: comp_values }, 'company');
  //     comp_options.map((opt,ind)=>{
  //       if (opt.isnew)
  //       {
  //         delete opt[ind];
  //       }
  //       else
  //       {
  //         comp_options.push(newOption)
  //       }
  //     })

  //     this.setState({
  //       comp_isLoading: false,
  //       comp_options: comp_options,
  //       comp_value: newOption,
  //     });

  // };
  addNewField = (key, option) => {
    let data = this.state.fieldsObj[key];
    data.values.push({
      srno: (data.values.length + 1).toString(),
      id: option.id,
      value: "",
    });

    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [key]: data,
      },
    });
  };

  handleAddMoreField = (name, key, index, value) => {
    let data = this.state.fieldsObj[key];
    data.values[index][name] = value;

    this.setState(
      {
        fieldsObj: {
          ...this.state.fieldsObj,
          [key]: data,
        },
      },
      () => this.forceUpdate()
    );

    this.state.gmailSuggestion.map((userData, i) => {
      if (userData.email == value) {
        var key = this.findWithAttr(
          this.state.countryListArray,
          "code",
          userData.countryCode
        );

        key = key;
        let country = "";
        country = this.state.countryListArray[key];
        this.setState(
          {
            ...this.state,
            city: userData.city,
            addressState: userData.region,
            state: userData.region,
            country: country,
            lastname: userData.last_name,
          },
          () => this.forceUpdate()
        );

        let phoneData = this.state.fieldsObj["phones"];
        phoneData.values = [];
        phoneData.values[0] = {
          srno: 1,
          id: null,
          value: "",
        };
        if (
          userData.phones != "" &&
          userData.phones != null &&
          userData.phones != "undefined"
        ) {
          userData.phones.map((phone, index) => {
            phoneData.values[index] = {
              srno: index,
              id: 1,
              value: phone.value,
            };
            this.setState(
              {
                fieldsObj: {
                  ...this.state.fieldsObj,
                  ["phones"]: phoneData,
                },
              },
              () => this.forceUpdate()
            );
          });
        }

        let socialData = this.state.fieldsObj["social"];
        socialData.values = [];
        socialData.values[0] = {
          srno: 1,
          id: null,
          value: "",
        };
        if (
          userData.social != "" &&
          userData.social != null &&
          userData.social != "undefined"
        ) {
          const objectArray = Object.entries(userData.social);

          let customeIndex = 0;

          objectArray.forEach(([key, value]) => {
            var filedsListKey = this.findWithAttr(
              this.state.fieldsList,
              "id",
              socialData.fieldid
            );

            const optionsArray = Object.entries(
              this.state.fieldsList[filedsListKey].options
            );

            let optionId = 10;
            optionsArray.forEach(([optionKey, optionValue]) => {
              var text = optionValue.optionvalue.toLowerCase();

              if (text == value.service) {
                optionId = optionValue.id;
              }
            });

            socialData.values[customeIndex] = {
              srno: customeIndex,
              id: optionId,
              value: value.url,
            };
            customeIndex++;
            this.setState(
              {
                fieldsObj: {
                  ...this.state.fieldsObj,
                  ["social"]: socialData,
                },
              },
              () => this.forceUpdate()
            );
          });
        }
      }
    });
  };

  handleNormalFields = (event) => {
    this.setState({ [event.target.name]: event.target.value, });
    let namekey = event.target.name;
    if (namekey === 'street' || namekey === 'city' || namekey === 'addressState' || namekey === 'zipcode') {
      let name = event.target.name === 'addressState' ? 'state' : event.target.name;
      let dataleadvalue = {
        fieldid: "25", contactvalue: {
          [name]: this.state[namekey]
        }
      }
      this.setState({
        fieldsObj: {
          ...this.state.fieldsObj,
          ['address']: dataleadvalue,
        },
      });
    }
  };

  handleCountryChange = (event, values) => {
    console.log('Values :', values)
    let dataleadvalue = {
      fieldid: "25", contactvalue: {
        ['country']: values.name
      }
    }
    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        ['address']: dataleadvalue,
      },
    });
    this.setState({ country: values });
  };

  focusInValue = () => {
    this.setState({ focusValue: true });
  };

  focusOutValue = () => {
    this.setState({ focusValue: false });
  };

  currencyDropdownClose = () => {
    this.setState({ focusValue: false });
  };

  handleChange = (val, name, email) => {
    if (val.fieldid === "26") {
      this.setState({ isEditVisibility: true }, () => this.forceUpdate())
      if (val.values.id === 2) {
        this.setState({ selectedTeamList: [{}] }, () => this.forceUpdate())
      } else if (val.values.id === 3) {
        this.setState({ selectedUserList: [{}] }, () => this.forceUpdate())
      }
    }
    var key = this.findWithAttr(this.state.fieldsList, "id", val.fieldid);
    if (
      key != -1 &&
      this.state.fieldsList[key].fieldtype.id != "4" &&
      this.state.fieldsList[key].fieldtype.id != "8"
    ) {
      if (this[name]) {
        this[name].validate(val.value);
      }
    }
    if (email != null && email != "undefined" && email != "") {
      this.state.gmailSuggestion.map((userData, i) => {
        if (userData.email == email) {
          var data1 = {
            fieldid: "16",
            contactvalue: userData.first_name?userData.first_name:userData.label?userData.label:"",
          };
          let fieldsObj = this.state.fieldsObj;
          fieldsObj = {
            ...fieldsObj,
            ["name"]: data1,
          };
          this.setState({
            fieldsObj,
          });
        }
      });
    } else {
      if (val.fieldid === "20") {
        if ((this.state.viewoption === 2 || this.state.viewoption === 3) && val.values === null) {
          this.setState({
            teamVisibilityLabel: "0 users",
            teamUser: []
          }, () => this.forceUpdate())
        } else {
          this.setState({
            fieldsObj: {
              ...this.state.fieldsObj,
              [name]: val,
            }
          }, () => this.getUserPermission())
        }
      }
      this.setState({
        fieldsObj: {
          ...this.state.fieldsObj,
          [name]: val,
        },
      });
    }
  };
  handleBlur = (event, ref) => {
    if (this[ref].props.id == "tagInputId1") {
      setTimeout(() => {
        document.getElementById("tagInputId1").value = "";
      }, 10);
    }
    this[ref].validate(event.target.value);
  };

  handleFormSubmit = () => {
    let submitData = JSON.parse(JSON.stringify(this.state.fieldsObj));

    submitData = Object.values(submitData).map((row) => {
      if (row.fieldid == "16") {
        // name
        row.names = {
          firstname: row.contactvalue,
          lastname: this.state.lastname,
        };
        delete row["contactvalue"];
      }

      if (
        row.fieldid == "23" ||
        row.fieldid == "24" ||
        row.fieldid == "22" ||
        row.fieldid == "21"
      ) {
        //website ,social, phone, email
        if (
          row.values.length == 1 &&
          (row.values[0].id == null || row.values[0].value == "")
        ) {
          row.values = [{ fieldid: row.fieldid, value: [] }];
        }
        if (row.values.length > 1) {
          row.values.map((val, i) => {
            if (val.value == "") {
              row.values.splice(i, 1);
            }
          });
        }

        if (row.values.length > 0) {
          //adding selected dropdown val as lable
          row.values.map((val, i) => {
            if (val.id) {
              var fKey = this.findWithAttr(
                this.state.fieldsList,
                "id",
                row.fieldid
              );
              if (fKey != -1) {
                var optKey = this.findWithAttr(
                  this.state.fieldsList[fKey].options,
                  "id",
                  val.id
                );
                row.values[i].label = this.state.fieldsList[fKey].options[
                  optKey
                ].optionvalue;
              }
            }
          });
        }

        if (row.fieldid == "22") {
          if (row.values.length === 1) {
            if (row.values[0].fieldid) {
              row.values = [];
            } else {
              if (row.values.length > 0) {
                var foundMobile = this.findWithAttr(row.values, "id", "2");
                if (foundMobile != -1) {
                  var index = 1;
                  row.values.map((val, i) => {
                    if (val.id == "2") {
                      row.values[i].srno = "1";
                    } else {
                      row.values[i].srno = (index + 1).toString();
                    }
                    index++;
                  });
                }
              }
            }
          } else {
            if (row.values.length > 0) {
              var foundMobile = this.findWithAttr(row.values, "id", "2");
              if (foundMobile != -1) {
                var index = 1;
                row.values.map((val, i) => {
                  if (val.id == "2") {
                    row.values[i].srno = "1";
                  } else {
                    row.values[i].srno = (index + 1).toString();
                  }
                  index++;
                });
              }
            }
          }
        }
        if (row.fieldid == "23" || row.fieldid == "24" || row.fieldid == "21") {
          //make value blank array if notfill in this field
          if (row.values.length === 1) {
            if (row.values[0].fieldid) {
              row.values = [];
            }
          }
        }
      }
      if (
        row.fieldid === "26" ||
        row.fieldid === "19" ||
        row.fieldid === "17"
      ) {
        if (row.fieldid === "26") { //Visibility,
          let filterVisibleList = [];
          if (row.values.id === 2) {
            filterVisibleList = this.state.selectedTeamList.length > 0 ? this.state.selectedTeamList.filter(el => Object.keys(el).length) : []
          } else if (row.values.id === 3) {
            filterVisibleList = this.state.selectedUserList.length > 0 ? this.state.selectedUserList.filter(el => Object.keys(el).length) : []
          }
          row.values = [
            {
              srno: "1",
              id: row.values.id,
              value: row.values.name,
              visiblelist: filterVisibleList
            }
          ];
        } else {
          //Dropdown company, contacttype
          row.values = [{ srno: "1", id: row.values.id, value: row.values.name }];
        }
      }
      var key = this.findWithAttr(this.state.fieldsList, "id", row.fieldid);
      if (
        this.state.fieldsList[key].fieldtype.id == "4" &&
        this.state.fieldsList[key].is_default == false
      ) {
        row.values = [
          { srno: "1", id: row.values.id, value: row.values.optionvalue },
        ];
      }
      if (
        this.state.fieldsList[key].fieldtype.id == "5" &&
        this.state.fieldsList[key].is_default == false
      ) {
        if (row.values.length > 0) {
          var ar = [];
          row.values.map((val, i) => {
            ar.push({
              srno: (i + 1).toString(),
              id: val.id,
              value: val.optionvalue,
            });
          });
        }
        row.values = ar;
      }
      //validating date fiels
      if (this.state.fieldsList[key].fieldtype.id == "8") {
        //date field
        if (
          this.state.fieldsList[key].recordcreationdefaultvalue == 2 &&
          row.values == null
        ) {
          return false;
        } else if (
          this.state.fieldsList[key].recordcreationdefaultvalue != 2 &&
          row.values == null
        ) {
          row.contactvalue = "";
          delete row.values;
        } else if (row.values == "Invalid Date") {
          return false;
        } else {
          // let date_test = moment(row.values).format('YYYY-MM-DD HH:mm');
          // var logdate = moment.tz(date_test, this.state.userSettings.timezone).format();
          // row.contactvalue = moment.utc(logdate).format('X');
          var dt = moment(row.values).format("YYYY-MM-DD");
          row.contactvalue = dt;
          delete row.values;
        }
      }

      if (row.fieldid == "28") {
        //tags
        if (row.values.length > 0) {
          var objArr = [];
          row.values.map((val, k) => {
            var foundKey = this.findWithAttr(
              this.state.systemTagsList,
              "tagname",
              val
            );
            var tagId = "0";
            if (foundKey !== -1) {
              tagId = this.state.systemTagsList[foundKey].id;
            }
            objArr.push({ srno: (k + 1).toString(), value: val, id: tagId });
          });
          row.values = objArr;
        }
      }

      if (row.fieldid == "25") {
        // Address
        row.contactvalue = {
          street: this.state.street,
          city: this.state.city,
          state: this.state.addressState,
          zipcode: this.state.zipcode,
          country: this.state.country.name,
        };
      }

      if (row.fieldid == "20") {
        //owner value set
        if (row.values) {
          row.values = [
            {
              srno: "1",
              id: row.values.id,
              value: row.values.email,
              name: row.values.name,
            },
          ];
        } else {
          row.values = [];
        }
        delete row.contactvalue;
      }
      return row;
    });

    let list = {
      filterdata: this.props.allstates.filterdata,
      list: {
        limit: this.props.allstates.pageSize,
        pageno: this.props.allstates.currentPage + 1,
        searchtext: this.props.allstates.searchText,
        sorttype: this.props.allstates.sorttype,
        type: "list",
        fieldId: this.props.allstates.fieldId,
        columnname: this.props.allstates.column_name,
      },
      filterid: 1,
    };
    let req = {
      listParams: list,
      contactdata: submitData,
      leadid: 0,
      sidebarParams: this.state.datafromsidebar,
      teamvisible: this.state.teamvisible,
      isFromTopBar : this.state.isFromTopBar
    };

    this.props.handleClose();
    this.props.saveContactDetail(this.props.apolloClient.client, req);

    // var arr1 = []
    // var arr2 = []
    // this.state.fieldsList.map((v,i)=>{
    //   arr1.push(parseInt(v.id))
    // })
    // Object.values(this.state.fieldsObj).map((v,i)=>{
    //   arr2.push(parseInt(v.fieldid))
    // })
    // var valueNotEnter = this.arrDifference(arr1,arr2)
    // console.log("valueNotEnter", valueNotEnter);
    // valueNotEnter.map((v,i)=>{
    //   const dataobj = this.state.fieldsList.find(val => parseInt(val.id) === v);
    //   console.log(v,"-",dataobj.fieldtype);
    //   //if(dataobj.fieldtype)
    // })
  };

  arrDifference = (arr1, arr2) => {
    var arr = [];
    arr1 = arr1.toString().split(",").map(Number);
    arr2 = arr2.toString().split(",").map(Number);
    // for array1
    for (var i in arr1) {
      if (arr2.indexOf(arr1[i]) === -1) arr.push(arr1[i]);
    }
    // for array2
    for (i in arr2) {
      if (arr1.indexOf(arr2[i]) === -1) arr.push(arr2[i]);
    }
    return arr.sort((x, y) => x - y);
  };

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  };
  teamHandleChange = (index, value, name) => {
    let mianteam = this.state.teamList;
    let selectedTeamList = this.state.selectedTeamList
    let mianUsers = this.state.usersListArray;
    let selectedUserList = this.state.selectedUserList
    if (name === "team") {
      if (value !== null) {
        selectedTeamList[index] = value;
        if (mianteam.length + 1 > selectedTeamList.length) {
          selectedTeamList[index + 1] = {};
        }
      } else {
        selectedTeamList.splice(index, 1);
        if (selectedTeamList.length === 0) {
          selectedTeamList[0] = {};
        }
      }
    } else {
      if (value !== null) {
        selectedUserList[index] = { id: value.id, name: value.name }
        if (mianUsers.length + 1 > selectedUserList.length) {
          selectedUserList[index + 1] = {};
        }
      } else {
        selectedUserList.splice(index, 1);
        if (selectedUserList.length === 0) {
          selectedUserList[0] = {};
        }
      }
    }
    this.setState({
      selectedTeamList: selectedTeamList,
      selectedUserList: selectedUserList
    })
  }
  handleToggleEditVisibility = () => {
    this.setState({
      isEditVisibility: !this.state.isEditVisibility
    })
  }

  handleChangeVisibilityRadio = (value) => {
    if (value) {
      let customFields = this.state.fieldsList
      var vkey = this.findWithAttr(customFields, "id", "26");
      var visibilitydefault = this.findWithAttr(
        customFields[vkey].options,
        "is_default",
        true
      );
      this.setState({
        selectedTeamList: [],
        selectedUserList: [],
        fieldsObj: {
          ...this.state.fieldsObj,
          ["visibility"]: {
            fieldid: "26",
            values: customFields[vkey].options[visibilitydefault],
          },
        },
      });
    }
    this.setState({
      teamvisible: value === "true" ? true : false
    })
  }
  render() {
    let {
      open,
      handleClose,
      handleMinimize,
      handleFull,
      fullScreen,
      minimizeScreen,
    } = this.props;
    return (
      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth="md"
        fullWidth={true}
        className={`crm-custom-bg-primary custom_dialog ${minimizeScreen && fullScreen ? "alignitemsend-fullscreen" : ""
          } ${minimizeScreen ? "alignitemsend" : ""} ${fullScreen ? "fullscreen" : ""
          }`}
        disableBackdropClick

      >
        <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">
          <h4 className="m-0 text-white">Create New Contact</h4>
          <div className="d-flex">
            <TooltipNew
              title={minimizeScreen ? "Normal Screen" : "Minimize Screen"}
            >
              <IconButton onClick={handleMinimize}>
                <Icon className="text-white">
                  {minimizeScreen ? "add" : "remove"}
                </Icon>
              </IconButton>
            </TooltipNew>
            <TooltipNew title={fullScreen ? "Exit Full Screen" : "Full Screen"}>
              <IconButton onClick={handleFull}>
                <Icon className="text-white">
                  {fullScreen ? "fullscreen_exit" : "fullscreen"}
                </Icon>
              </IconButton>
            </TooltipNew>
            <TooltipNew title="Close">
              <IconButton onClick={handleClose}>
                <Icon className="text-white">clear</Icon>
              </IconButton>
            </TooltipNew>
          </div>
        </div>
        <ValidatorForm
          noValidate
          instantValidate={false}
          onSubmit={this.handleFormSubmit}
        >
          <div
            className={`${this.state.isDataLoading
              ? "dialog-body popupHeightCustom"
              : "dialog-body"
              }`}
          >
            <Grid container spacing={2}>
              {this.state.isDataLoading && (
                <CircularProgress size={25} className="loaderPopupCenter" />
              )}
              {!this.state.isDataLoading && createContactDynamicFields(this)}
            </Grid>
          </div>

          <DialogActions className="dialog-action">
            <Button variant="contained" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              disabled={this.state.isDataLoading}
              variant="contained"
              type="submit"
              color="primary"
              className="btn_Primary"
            >
              Save
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  settingReducer: state.settingReducer,
  contactReducer: state.contactReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
  authReducer: state.authReducer

});

const mapDispatchToProps = (dispatch) => {
  return {
    getSuggestionData: (client, request) => {
      dispatch(actionCreators.getSuggestionData(client, request));
    },
    getCompanyRecList: (client) => {
      dispatch(actionCreators.getCompanyRecList(client));
    },
    customFieldList: (client, request) => {
      dispatch(actionCreators.customFieldList(client, request));
    },
    saveContactDetail: (client, request) => {
      dispatch(actionCreators.saveContactDetail(client, request));
    },
    getCountryList: (client) => {
      dispatch(actionCreators.getCountryList(client));
    },
    clearNotification: () => dispatch(actionCreators.clearNotification()),
    getCompanyCurrency: (client) =>
      dispatch(actionCreators.getCompanyCurrency(client)),
    getTagsList: (client) => dispatch(actionCreators.getTagsList(client)),
    getUserList: (client) => dispatch(actionCreators.getUserList(client)),
    getLoginUserPermission: (client) => dispatch(actionCreators.getLoginUserPermission(client)),
    getTeam: (client) => dispatch(actionCreators.getTeam(client)),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewContact);
