import React, { Component } from "react";
import { Fab, Grid, withStyles, Switch, InputLabel, FormControl, Input, FormHelperText, RadioGroup, Radio, FormControlLabel, Divider, DialogContent, InputAdornment, Button, IconButton, Icon, MenuItem, Card, TextField, Dialog, DialogActions, DialogTitle, Drawer, Avatar } from "@material-ui/core";
import ContactProfileFrame from "../Contacts/ContactProfileFrame";
import CreateNewContactDialog from "../Contacts/CreateNewContactDialog";
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { NavLink, withRouter } from "react-router-dom";
import { SingleSelect } from "react-select-material-ui";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
const formatCreateLabel = (value) => `Create "${value}"`;
const isValidNewOption = (value) => value != "";
const IconSmall = withStyles(() => ({
  root: {
    fontSize: "1.5rem",
    color: 'rgba(0, 0, 0, 0.6)'
  }
}))(Icon);
const filter = createFilterOptions();


class People extends Component {
  state = {
    attachment: null,
    drawerOpenContact: false,
    showAddContact: false,
    contact_id: "",
    contactListArray: [],
    allContactList: [],
    createNewContactName: "",
    filterdata: [],
    removeContactPopup: false,
    shouldOpenCreateContactDialog: false,
    FullCreateContactDialog: false,
    minimizeContactDialog: false,
    type: "contact",
    recordtype: "",
    newContactData: {},
    opportunities_id: "",
    cmpId: "",
    compnayCrud: {
      isaccess: true,
      iscreate: true,
    },
    isDataLoadingData: true,
    isFlag: 0,
  };

  componentWillUnmount() {
    this.props.settingReducer.relatedDataContact = [];
  }

  componentDidMount() {
    this.props.getContactRecList(this.props.apolloClient.client);
    this.props.getUserIntegration(this.props.apolloClient.client);
    if (this.props.recordId) {
      this.setState({
        recordId: this.props.recordId,
        recordtype: this.props.recordtype,
      }, () => {
        this.forceUpdate()
        if (this.props.recordtype == "opportunities") {
          this.setState({
            opportunities_id: this.props.recordId
          })
        }
        if (this.props.recordtype == "company") {
          this.setState({
            cmpId: this.props.recordId
          })
        }
        var req = {
          id: this.props.recordId,
          type: this.state.type,
          recordtype: this.props.recordtype,
        }
        this.props.getRecordRelatedData(this.props.apolloClient.client, req);
      });
    }
  }
  getUserPermission = () => {
    if (this.props.settingReducer.userPermissionDetails.data) {
      let data = this.props.settingReducer.userPermissionDetails.data.data;
      if (data.teampermissionstatus === true &&
        data.visibilityaccess.length > 0) {
        let compnayCrud = "";
        if (this.props.authReducer.userRole.id === 3) {
          compnayCrud = data.visibilityaccess.find(function (e) { return e.sourcetype === 2 });
        }
        this.setState({
          compnayCrud: compnayCrud === "" ? this.state.compnayCrud : compnayCrud,
        })
      }
    }
  }
  componentWillReceiveProps(props) {
    this.getUserPermission()
    // console.log("componentWillReceiveProps", props.recordId, props.recordtype);
    if (props.recordId != this.state.recordId) {//if(props.recordId !== undefined && this.state.oppoFlag){
      this.setState({
        recordId: props.recordId,
        recordtype: props.recordtype,
      }, () => {
        this.forceUpdate()
        if (this.props.recordtype == "opportunities") {
          this.setState({
            opportunities_id: this.props.recordId
          })
        }
        if (this.props.recordtype == "company") {
          this.setState({
            cmpId: this.props.recordId
          })
        }
        var req = {
          id: props.recordId,
          type: this.state.type,
          recordtype: props.recordtype,
        }
        this.props.getRecordRelatedData(this.props.apolloClient.client, req);
      });
    }
    if (props.contactReducer.contactFieldData.data && props.contactReducer.contactFieldData.is_loaded) {
      this.setState({
        isDataLoading: false,
      }, () => this.forceUpdate())
    }

    if (props.settingReducer.relatedDataContact.data && props.settingReducer.relatedDataContact.data.data) {
      props.settingReducer.relatedDataContact.is_loaded = false
      // if(props.settingReducer.relatedDataContact.data.data != this.state.contactListArray){
        this.setState({
          contactListArray: props.settingReducer.relatedDataContact.data.data,
          isOpenedContact: true
        }, () => this.forceUpdate());
      // }
      if (props.settingReducer.relatedDataContact.data.data.length > 0) {
        this.setState({ isOpenedContact: true });
      } else {
        this.setState({ isOpenedCompany: false });
      }
    } else {
      this.setState({
        contactListArray: [],
        isOpenedContact: false
      }, () => this.forceUpdate());
    }

    if (props.settingReducer.contactRecList && props.settingReducer.contactRecList.data && props.settingReducer.contactRecList.data.data) {
      let contactOptions = [];
      props.settingReducer.contactRecList.data.data.map((compOpt) => {
        contactOptions.push({ label: compOpt.name, value: compOpt.contact_id });
      })
      this.setState({ allContactList: contactOptions });
    }

  }

  toggleContact = () => {
    this.setState(oldStateContact => ({ isOpenedContact: !oldStateContact.isOpenedContact }));
    if (this.state.isOpenedContact) {
      this.setState({
        showAddContact: false
      }, () => this.forceUpdate());
    }
  }

  /* START cmp dialoge */

  handleContactDialogClose = () => {
    this.setState({ shouldOpenCreateContactDialog: false, FullCreateContactDialog: false, minimizeContactDialog: false });
  };

  handleContactDialogMinimize = () => {
    this.setState(oldStateminimizeContactDialog => ({ minimizeContactDialog: !oldStateminimizeContactDialog.minimizeContactDialog }));
    this.setState({ FullCreateContactDialog: false })
  };

  handleContactDialogFull = () => {
    this.setState(oldStateFullCreateContactDialog => ({ FullCreateContactDialog: !oldStateFullCreateContactDialog.FullCreateContactDialog }));
    this.setState({ minimizeContactDialog: false })

  };

  /* END cmp dialoge */
  closeDrawerRight = (e) => {
    this.setState({
      isDataLoading: true,
    });

    setTimeout(() => {
      this.setState({
        drawerOpenContact: false,
        isDataLoading: false,
        isFlag: 1
      }, () => {
        this.forceUpdate()
      })
    }, 2500);


    let recordtype = this.state.FlagType;
    let FlagId = this.state.FlagId;

    // if (recordtype !== "project") {
    let projects = {
      id: parseFloat(FlagId),
      type: 'projects',
      recordtype: recordtype,
    }
    this.props.getRecordRelatedData(this.props.apolloClient.client, projects);
    let calendarevents = {
      id: parseFloat(FlagId),
      type: 'calendarevents',
      recordtype: recordtype,
    }
    this.props.getRecordRelatedData(this.props.apolloClient.client, calendarevents);
    // }
    let tasks = {
      id: parseFloat(FlagId),
      type: 'tasks',
      recordtype: recordtype,
    }
    this.props.getRecordRelatedData(this.props.apolloClient.client, tasks);
    let files = {
      id: parseFloat(FlagId),
      type: 'files',
      recordtype: recordtype,
    }
    this.props.getRecordRelatedData(this.props.apolloClient.client, files);
    // if (recordtype !== "contact") {
    // if (recordtype !== "company") {
    let companies = {
      id: parseFloat(FlagId),
      type: 'companies',
      recordtype: recordtype,
    }
    this.props.getRecordRelatedData(this.props.apolloClient.client, companies);
    // }
    let contact = {
      id: parseFloat(FlagId),
      type: 'contact',
      recordtype: recordtype,
    }
    this.props.getRecordRelatedData(this.props.apolloClient.client, contact);
    // }
    // if (recordtype !== "opportunities") {
    let opportunities = {
      id: parseFloat(FlagId),
      type: 'opportunities',
      recordtype: recordtype,
    }
    this.props.getRecordRelatedData(this.props.apolloClient.client, opportunities);
    // }

  };
  toggleAddContact = () => {
    this.setState({
      isOpenedContact: true,
      showAddContact: !this.state.showAddContact
    });
  }
  handleDrawerContactRight = (contact_id) => {
    if (this.state.contact_id && (this.state.contact_id === contact_id)) { } else {
      this.setState({
        contact_id: contact_id,
        isDataLoading: true,
        FlagId: this.state.recordId,
        FlagType: this.state.recordtype,
      }, () => {
        this.forceUpdate()
      })
    }

    setTimeout(() => {
      this.setState({
        drawerOpenContact: true,
      }, () => {
        this.forceUpdate()
      })
    }, 3000);

    if (this.state.isFlag > 0) {
      let calendarevents = {
        id: parseFloat(contact_id),
        type: 'calendarevents',
        recordtype: this.state.type,
      }
      this.props.getRecordRelatedData(this.props.apolloClient.client, calendarevents);
      let req = {
        id: parseFloat(contact_id),
        type: 'contact',
        recordtype: this.state.type,
      }
      this.props.getRecordRelatedData(this.props.apolloClient.client, req);
      let opportunities = {
        id: parseFloat(contact_id),
        type: 'opportunities',
        recordtype: this.state.type,
      }
      this.props.getRecordRelatedData(this.props.apolloClient.client, opportunities);
      let tasks = {
        id: parseFloat(contact_id),
        type: 'tasks',
        recordtype: this.state.type,
      }
      this.props.getRecordRelatedData(this.props.apolloClient.client, tasks);
      let files = {
        id: parseFloat(contact_id),
        type: 'files',
        recordtype: this.state.type,
      }
      this.props.getRecordRelatedData(this.props.apolloClient.client, files);
      let companies = {
        id: parseFloat(contact_id),
        type: 'companies',
        recordtype: this.state.type,
      }
      this.props.getRecordRelatedData(this.props.apolloClient.client, companies);
      let projects = {
        id: parseFloat(contact_id),
        type: 'projects',
        recordtype: this.state.type,
      }
      this.props.getRecordRelatedData(this.props.apolloClient.client, projects);
    }

  };
  handleContactLaunch = (contact_id) => {
    this.props.history.push('/contact/contact/' + contact_id)
  };
  handleRemoveContact = (contact_id) => {
    this.setState({
      removeContactPopup: true,
      deleteContactId: contact_id,
    }, () => {
      this.forceUpdate()
    })

  };
  toggleRemoveContactPopup = () => {
    if (this.state.removeContactPopup) {
      this.setState({
        deleteContactId: "",
      }, () => {
        this.forceUpdate()
      })

    }
    this.setState({
      removeContactPopup: !this.state.removeContactPopup,
    }, () => {
      this.forceUpdate()
    })

  };
  confirmremoveContactPopup = () => {
    console.log("this.state.deleteContactId", this.state.deleteContactId);
    if (this.state.deleteContactId) {
      if (this.state.recordtype == "opportunities") {
        var req = {
          contactid: parseInt(this.state.deleteContactId),
          opportunitiesid: parseInt(this.state.recordId),
        }
        this.props.deleteOpportunitiesContact(this.props.apolloClient.client, req)
      }
      if (this.state.recordtype == "project") {
        var req = {
          contactid: parseInt(this.state.deleteContactId),
          projectid: parseInt(this.state.recordId),
        }
        this.props.deleteProjectContact(this.props.apolloClient.client, req)
      }
      if (this.state.recordtype == "company") {
        var req = {
          contactid: parseInt(this.state.deleteContactId),
          companyid: parseInt(this.state.recordId),
        }
        this.props.deleteCompanyContact(this.props.apolloClient.client, req)
      }

      this.setState({
        deleteContactId: "",
        removeContactPopup: false,
      }, () => {
        this.forceUpdate()
      })
    }

  };

  handleContactChange = (val, newValue) => {
    if (newValue) {
      var relateddata = {
        recordtype: this.state.recordtype,
        type: this.state.type,
        id: parseInt(this.state.recordId),
      }

      let id = (newValue.__isNew__) ? "0" : newValue.value;
      if (id == "0") {
        this.setState({
          newContactData: relateddata,
          shouldOpenCreateContactDialog: true,
          createNewContactName: newValue.label
        })
      } else {
        var isAlreadyAdded = this.state.contactListArray.filter(function (element) {
          if (element.contact.id == id) {
            return element
          }
        });
        if (isAlreadyAdded.length > 0) {
          this.setState({ showAddContact: false });
          return
        }
        let data = { srno: "1", id: id, value: newValue.label };
        if (this.state.recordtype == "opportunities") {
          var req = {
            contactdetail: data,
            opportunitiesid: parseInt(this.state.recordId),
          }
          this.props.addNewOpportunitiesContact(this.props.apolloClient.client, req)
        }
        if (this.state.recordtype == "project") {
          var req = {
            contactdetail: data,
            projectid: parseInt(this.state.recordId),
          }
          this.props.addProjectContact(this.props.apolloClient.client, req)
        }
        if (this.state.recordtype == "company") {
          var req = {
            companyid: parseInt(this.state.recordId),
            contactid: parseInt(id),
          }
          this.props.addNewCompanyContact(this.props.apolloClient.client, req)
        }


      }
      this.setState({ showAddContact: false });

    }
  };

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }
  render() {
    let { drawerOpenContact, isOpenedContact, isDataLoadingData } = this.state;
    /*STRAT contact Display*/
    let displayContactList = []
    {
      this.state.contactListArray && this.state.contactListArray.map((item, key) => {
        var contactimg = ""
        if (item.contact.image) {
          contactimg = item.contact.image
        }
        if (item.contact.__contactdetail__.length > 0) {
          var cName = ""
          var cmpName = ""
          var title = ""
          var phone = ""
          var email = ""
          item.contact.__contactdetail__.map((data, key1) => {
            if (data.__field__.id == "16") {//name
              cName = data.fieldvalue;
            }
            if (data.__field__.id == "17" && data.values.length > 0) {//company
              cmpName = data.values[0].value;
            }
            if (data.__field__.id == "18") {//title
              title = data.fieldvalue + " at ";
            }
            if (data.__field__.id == "22" && data.values.length > 0) {//phone
              var srData = data.values.filter(function (element) {
                if (element.srno == "1") {
                  return element
                }
              });
              if (srData.length > 0) {
                phone = srData[0].value
              } else {
                phone = data.values[0].value
              }

            }
            if (data.__field__.id == "21" && data.values.length > 0) {//email
              email = data.values[0].value;
            }

          })
          displayContactList.push(
            <div className="box_portlet mt-10">
              <div className="box_body px-10 py-20 cursor_poiter">
                <div className="lead_pl" style={{ paddingLeft: '50px' }}>
                  <div className="icon_left">
                    <Fab
                      size="small"
                      aria-label="Task"
                      onClick={() => this.handleDrawerContactRight(item.contact.id)}
                      className={`wrap_icons_task_sidebar vert-middle bg-grey_sidebar`}
                    >

                      {!contactimg && <Avatar className="bg-body"><Icon className="activity_icon_normal">person</Icon></Avatar>}
                      {contactimg && <Avatar className="bg-body" src={contactimg}></Avatar>}

                    </Fab>

                  </div>
                  {/* <Grid item className="flex-auto"> */}
                  <a onClick={() => this.handleDrawerContactRight(item.contact.id)} className={"cursor_poiter"}>
                    <Grid justify="space-between" alignItems="center">
                      <h6 className="text_ellipsis m-0" style={{ width: '100%' }}>{cName ? cName : ""}</h6>
                    </Grid>
                  </a>
                  <Grid justify="space-between" alignItems="center">
                    <h6 className="font-size-13 related-text m-0 text_ellipsis" style={{ width: '100%' }}>{title}{cmpName ? cmpName : ""}</h6>
                  </Grid>
                  <Grid justify="space-between" alignItems="center">
                    <Grid item className="text_ellipsis" >
                      <div className="font-size-13 related-text">{email ? email : ""}</div>
                    </Grid>
                    <Grid item className="text_ellipsis">
                      <div className="font-size-13 related-text">{phone ? phone : ""}</div>
                    </Grid>
                  </Grid>
                  {/* </Grid> */}
                  {!email && !phone && <span className="related-text">&nbsp;</span>}
                  <div className="hover_show" style={{ backgroundColor: '#FFF', top: '-21px', right: '-7px' }} >
                    <IconButton aria-label="Launch" size="small" onClick={() => this.handleContactLaunch(item.contact.id)}>
                      <IconSmall style={{ fontSize: '18px' }}>launch</IconSmall>
                    </IconButton>
                    <IconButton aria-label="Delete" size="small" onClick={() => this.handleRemoveContact(item.contact.id)}>
                      <IconSmall style={{ fontSize: '18px' }}>close</IconSmall>
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
          )
        }

      })
    }
    /*END contact Display*/

    return (
      <Card elevation={3} className="p-16 mt-16" style={{ overflow: 'initial' }}>
        <Grid container justify="space-between">
          <h5 className="flex-auto cursor_poiter mb-0 pt-4" onClick={this.toggleContact}>Contacts ({displayContactList.length}) <Icon className="vert-middle">{isOpenedContact ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon></h5>
          <IconButton aria-label="Add" size="small">
            <IconSmall onClick={() => this.toggleAddContact()} className={this.state.showAddContact ? "plusIconTransform" : ""}>add</IconSmall>
          </IconButton>
        </Grid>
        {isOpenedContact &&
          <>
            {this.state.showAddContact &&
              <FormControl className="w-100 input_bx" style={{ zIndex: 10000 }}>
                <SingleSelect
                  className="add_singleselection"
                  SelectProps={{
                    isCreatable: this.state.compnayCrud.isaccess && this.state.compnayCrud.iscreate ? true : false,
                    formatCreateLabel,
                    isValidNewOption,
                  }}
                  placeholder="Add Contact"
                  options={this.state.compnayCrud.isaccess && this.state.allContactList}
                  onChange={this.handleContactChange}
                />
              </FormControl>

            }
            {(!this.state.showAddContact && displayContactList.length == 0) &&
              <label className="text-hint">
                <IconButton className="mr-8" component="span">
                  <Icon onClick={() => this.toggleAddContact()}>people</Icon>
                </IconButton>
                    Add Contact
                </label>}
            {displayContactList}
          </>
        }

        {this.state.shouldOpenCreateContactDialog && (
          <CreateNewContactDialog
            handleClose={this.handleContactDialogClose}
            open={this.state.shouldOpenCreateContactDialog}
            handleMinimize={this.handleContactDialogMinimize}
            handleFull={this.handleContactDialogFull}
            minimizeScreen={this.state.minimizeContactDialog}
            fullScreen={this.state.FullCreateContactDialog}
            name={this.state.createNewContactName}
            opportunitiesid={this.state.opportunities_id}
            cmpId={this.state.cmpId}
            allstates={this.state}
            dataFromSidebar={this.state.newContactData}
          />
        )}

        <Dialog
          fullWidth={true}
          maxWidth={'xs'}
          open={this.state.removeContactPopup}
          onClose={this.toggleRemoveContactPopup}
          disableBackdropClick

          aria-labelledby="ar-remove-dialog-title"
        >
          <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to remove the Associated Contact?"}</DialogTitle>
          <DialogActions>
            <Button onClick={this.toggleRemoveContactPopup} color="primary">
              Cancel
                                          </Button>
            <Button type="Button" onClick={() => this.confirmremoveContactPopup()} className="btn_Primary">
              Ok
                                          </Button>

          </DialogActions>
        </Dialog>
        <Drawer
          width={"100px"}
          variant="temporary"
          anchor={"right"}
          className="drawer_right"
          open={drawerOpenContact}
          onClose={this.closeDrawerRight}
          ModalProps={{
            keepMounted: true
          }}
        >
          {this.state.contact_id && <ContactProfileFrame contact_id={parseInt(this.state.contact_id)} handleClose={this.closeDrawerRight} />}
        </Drawer>
        {this.state.isDataLoading &&
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={this.state.isDataLoading}
            TransitionComponent={Fade}
            message={<><CircularProgress size={20} style={{ color: 'white' }} /><span style={{ paddingLeft: '6px', color: 'white' }}>{" Loading..."}</span></>}
            variant={'success'}
          />
        }
      </Card>

    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
  opportunityReducer: state.opportunityReducer,
  contactReducer: state.contactReducer,
  authReducer: state.authReducer
});

const mapDispatchToProps = dispatch => {
  return {
    deleteOpportunitiesContact: (client, req) => { dispatch(actionCreators.deleteOpportunitiesContact(client, req)) },
    getContactRecList: (client) => { dispatch(actionCreators.getContactRecList(client)) },
    addNewOpportunitiesContact: (client, req) => { dispatch(actionCreators.addNewOpportunitiesContact(client, req)) },
    getRecordRelatedData: (client, req) => { dispatch(actionCreators.getRecordRelatedData(client, req)) },
    addProjectContact: (client, req) => { dispatch(actionCreators.addProjectContact(client, req)) },
    deleteProjectContact: (client, req) => { dispatch(actionCreators.deleteProjectContact(client, req)) },
    addNewCompanyContact: (client, req) => { dispatch(actionCreators.addNewCompanyContact(client, req)) },
    deleteCompanyContact: (client, req) => { dispatch(actionCreators.deleteCompanyContact(client, req)) },
    getUserIntegration: (client) => dispatch(actionCreators.getUserIntegration(client)),
  }
}


export default (connect(mapStateToProps, mapDispatchToProps)(withRouter(People)));
