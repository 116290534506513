import React, { Component } from "react";
import ProfileLeft from "./ProfileLeft";
import ProfileCenter from "./ProfileCenter";
import ProfileRight from "./ProfileRight";

class Leads extends Component {
  state={
    lead_id:""
  }
  componentWillMount() {
    this.setState({
      lead_id:parseInt(this.props.match.params.id)
    })
  }
  render() {
    return (
      <React.Fragment>
        <ProfileLeft lead_id={this.state.lead_id}/>
        <ProfileCenter lead_id={this.state.lead_id}/>
        <div className="lead_right">
          <ProfileRight lead_id={this.state.lead_id}/>
        </div>
      </React.Fragment>
    );
  }
}

export default Leads;
