import React from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import { Grid, Button, withStyles, CircularProgress, Container, Backdrop, Card, Typography } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

import FontAwesome from "react-fontawesome";
import { Redirect, Route } from 'react-router-dom';
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#bcbcbc',
      color: '#000',
      fontWeight: 500,
      // fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#000',
      },
      '::placeholder': {
        color: '#bcbcbc',
      },
    },
    invalid: {
      iconColor: '#FFC7EE',
      color: '#FFC7EE',
    },
  },
};

const CardField = ({onChange}) => (
  <div className="FormRow">
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);


const ResetButton = ({onClick}) => (
  <button type="button" className="ResetButton" onClick={onClick}>
    <svg width="32px" height="32px" viewBox="0 0 32 32">
      <path
        fill="#FFF"
        d="M15,7.05492878 C10.5000495,7.55237307 7,11.3674463 7,16 C7,20.9705627 11.0294373,25 16,25 C20.9705627,25 25,20.9705627 25,16 C25,15.3627484 24.4834055,14.8461538 23.8461538,14.8461538 C23.2089022,14.8461538 22.6923077,15.3627484 22.6923077,16 C22.6923077,19.6960595 19.6960595,22.6923077 16,22.6923077 C12.3039405,22.6923077 9.30769231,19.6960595 9.30769231,16 C9.30769231,12.3039405 12.3039405,9.30769231 16,9.30769231 L16,12.0841673 C16,12.1800431 16.0275652,12.2738974 16.0794108,12.354546 C16.2287368,12.5868311 16.5380938,12.6540826 16.7703788,12.5047565 L22.3457501,8.92058924 L22.3457501,8.92058924 C22.4060014,8.88185624 22.4572275,8.83063012 22.4959605,8.7703788 C22.6452866,8.53809377 22.5780351,8.22873685 22.3457501,8.07941076 L22.3457501,8.07941076 L16.7703788,4.49524351 C16.6897301,4.44339794 16.5958758,4.41583275 16.5,4.41583275 C16.2238576,4.41583275 16,4.63969037 16,4.91583275 L16,7 L15,7 L15,7.05492878 Z M16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 Z"
      />
    </svg>
  </button>
);

const DEFAULT_STATE = {
  error: null,
  cardComplete: false,
  processing: false,
  paymentMethod: null,
  email: '',
  phone: '',
  name: '',
};

// class CountryList extends React.Component {

// }

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DEFAULT_STATE: DEFAULT_STATE,
      // planid: parseInt(this.props.match.params.planid),
      planDetails:[],
      isSubmitted: false,
      saveParams:[],
      country:[]
    }
  }
  handlechange = (event) =>{
    this.setState({[event.target.name]: event.target.value});
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    const {stripe, elements} = this.props;
    const {email, phone, name, error, cardComplete} = this.state;

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement('card').focus();
      return;
    }

    if (cardComplete) {
      this.setState({processing: true});
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    
    if (result.error) {
      console.log(result.error.message);
    } else {
      
      let params = {
        amount: parseInt(this.state.planDetails.planprice),
        cardtoken:(result.token).id,
        planid: parseInt(this.state.planDetails.id),
        firstname : this.state.firstname,
        lastname : this.state.lastname,
        address1 : this.state.address1,
        address2 : this.state.address2,
        city : this.state.city,
        state : this.state.state,
        postalcode : parseInt(this.state.postalCode),
        country : parseInt(this.state.country),
      };
      
      this.setState({isSubmitted:true, saveParams: params})
    }

    this.setState({processing: false});
  };

  componentDidMount(){
    let billingPlan = JSON.parse(localStorage.getItem('billingPlan'));
    this.setState({planDetails: billingPlan});

    let userDetails = JSON.parse(localStorage.getItem('user_data_session'));
    let countryList = JSON.parse(localStorage.getItem('countryList'));
    let countrykey = this.findWithAttr(countryList, 'name', userDetails.country);

    this.setState({
      firstname: userDetails.name,
      lastname: "",
      address1: userDetails.address,
      address2: "",
      city: userDetails.city,
      state: userDetails.state,
      postalcode: userDetails.pincode,
      country: countryList[countrykey]
    })
  }

  // componentWillUnmount(){
  //   localStorage.removeItem('countryList');
  //   localStorage.removeItem('billingPlan');
  //   localStorage.removeItem('selectedPlan');
  // }


  reset = () => {
    this.setState(this.state.DEFAULT_STATE);
  };

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  handlechangecountry = (event, value) => {    
    if(value)
      this.setState({country:value})
  }

  render() {
    const {error, processing, paymentMethod, name, email, phone, planDetails, country} = this.state;
    const {stripe} = this.props;
    let countryList = JSON.parse(localStorage.getItem('countryList'));

    return (
      this.state.isSubmitted ? 
        <Redirect 
          to={{
            pathname: "/settings/saveBilling",
            state: { saveparams: this.state.saveParams }
          }}
        />
      :
      <>
        <Card>          
          <Grid container spacing={2} style={{height:"100vh", overflow:"auto" }}>
            <Grid item md={12}>                  
              <div className="pl-50 pr-50 py-80" >
                
                <h3 className="text-center font-weight-bold mb-40"> 
                <i class="fas fa-user-friends"></i>
                <i class="fas fa-handshake"></i>
                <FontAwesome className="fas fa-handshake" />
                
                Almost done, {JSON.parse(localStorage.getItem('user_data_session')).name}!</h3>

                <Grid container spacing={2}>
                  <Grid item md={4}>
                    <label className="font-size-13 text-hint">Plan Type</label>
                    <h5>{planDetails.planname}</h5>
                  </Grid>
                  <Grid item md={4}>
                    <label className="font-size-13 text-hint">Plan Price</label>
                    <h5>{planDetails.subname}</h5>
                  </Grid>
                  <Grid item md={4}>
                    <label className="font-size-13 text-hint">Total</label>
                    <h5>{planDetails.planprice}</h5>
                  </Grid>
                </Grid>
                
                <ValidatorForm instantValidate={false} onSubmit={this.handleSubmit}>

                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <TextValidator
                        label="First Name"
                        id="firstname"
                        name="firstname"
                        type="text"
                        placeholder="First Name"
                        required
                        autoComplete="name"
                        value={this.state.firstname}
                        onChange={this.handlechange}
                        autoComplete="off"
                        className="mb-24 w-100"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextValidator
                        label="Last Name"
                        id="lastname"
                        name="lastname"
                        type="text"
                        placeholder="Last Name"
                        required
                        autoComplete="name"
                        value={this.state.lastname}
                        onChange={this.handlechange}
                        autoComplete="off"
                        className="mb-24 w-100"
                      />
                    </Grid>
                  </Grid>
                  
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <TextValidator
                        label="Address1"
                        id="address1"
                        name="address1"
                        type="text"
                        placeholder="Address1"
                        required
                        value={this.state.address1}
                        onChange={this.handlechange}
                        autoComplete="off"
                        className="mb-24 w-100"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextValidator
                        label="Address2"
                        id="address2"
                        name="address2"
                        type="text"
                        placeholder="Address2"
                        autoComplete="name"
                        value={this.state.address2}
                        onChange={this.handlechange}
                        autoComplete="off"
                        className="mb-24 w-100"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <TextValidator
                        label="City"
                        id="city"
                        name="city"
                        type="text"
                        placeholder="City"
                        required
                        value={this.state.city}
                        onChange={this.handlechange}
                        autoComplete="off"
                        className="mb-24 w-100"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextValidator
                        label="State"
                        id="state"
                        name="state"
                        type="text"
                        placeholder="State"
                        required
                        value={this.state.state}
                        onChange={this.handlechange}
                        autoComplete="off"
                        className="mb-24 w-100"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <TextValidator
                        label="Postal Code"
                        id="postalCode"
                        name="postalCode"
                        // type="text"
                        type="number"
                        placeholder="Postal Code"
                        required
                        value={this.state.postalcode}
                        onChange={this.handlechange}
                        autoComplete="off"
                        className="mb-24 w-100"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Autocomplete
                        disableOpenOnFocus
                        forcePopupIcon={false}
                        name={"country"}
                        onChange={(event, value)=> this.handlechangecountry(event, value)}
                        id={"country"}
                        value={country}
                        options={countryList}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextValidator
                            required={true}
                            ref={ref => countryList = ref}
                            // onBlur={(event) => data.focusOutSubmitForm(event, v.id, v.fieldkey)}
                            {...params}
                            variant="standard"
                            value={country && country.name}
                            label={"Country"}
                            fullWidth
                            placeholder={"Country"}
                            className="w-100 input_bx"
                          />
                        )}
                      />
                      {/* <TextValidator
                        label="Country"
                        id="country"
                        name="country"
                        type="text"
                        placeholder="Country"
                        required 
                        value={this.state.country}
                        onChange={this.handlechange}
                        autoComplete="off"
                        className="mb-24 w-100"
                      /> */}
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} className="mt-20" style={{borderBottom:'1px solid #999'}}>
                    <Grid item md={12}>
                      <CardField
                        onChange={(event) => {
                          this.setState({
                            error: event.error,
                            cardComplete: event.complete,
                          });
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container className="mt-20 " alignItems="center">
                    <Grid item md={12}>
                      <Button variant="contained" className="pull-right" color="primary" type="submit">
                        Purchase
                      </Button>
                      {this.state.loading && (
                        <Backdrop
                          open={this.state.backdrop}
                        >
                          <CircularProgress size={60} color="inherit" />
                        </Backdrop>
                      )}            
                    </Grid>
                  </Grid>  

                </ValidatorForm>
              </div>            
            </Grid>
          </Grid>
        </Card>
      </>
    );
  }
}

const InjectedCheckoutForm = () => (
  <ElementsConsumer>
    {({stripe, elements}) => (
      <CheckoutForm stripe={stripe} elements={elements} />
    )}
  </ElementsConsumer>
);

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
    },
  ],
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51Ht7JaIeaYndLUGfkvIc8sR8uLoqGf0gSCLGlpRCnnJNfgPboJlEmQGJfsKLD4KzMqPoyTWZ0nWwk5wbylUkQrCU00C4UIwqtM');

const StripePay = () => {
  return (
    <Elements stripe={stripePromise}>
      <InjectedCheckoutForm />
    </Elements>
  );
};



const mapStateToProps = (state) => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
});
const mapDispatchToProps = (dispatch) => {
  return {
    getPlanList: (client) => dispatch(actionCreators.getPlanList(client)),
    companyPlanPurchase: (client, request) => dispatch(actionCreators.companyPlanPurchase(client, request)),
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(StripePay);