import React, { Component } from "react";
import {
  Typography, Table, TableBody, TableCell, TableContainer,
  Dialog, Button, Tooltip, TableHead, TableRow,
  IconButton, Icon, Card, Paper, Link, Grid, DialogActions, DialogTitle
} from "@material-ui/core";
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actionCreators from '../../store/actions/index';
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

class TagManagement extends Component {
  state = {
    minimizeViewEmailDialog: false,
    FullViewEmailDialog: false,
    open: false,
    tagslist: [],
    name: "",
    tagid: "",
    isDeleteConfirmDialoOpen: false,
    delId: "",
  }

  componentWillMount() {
    this.props.getTagsList(this.props.apolloClient.client);
  }

  componentWillReceiveProps(props) {
    if (props.settingReducer.systemTagsList && props.settingReducer.systemTagsList.data) {
      let tagslist = props.settingReducer.systemTagsList.data;
      tagslist.map((tags, key) => {
        let lead = 0;
        let contact = 0;
        let company = 0;
        let opportunity = 0;
        let project = 0;
        let task = 0;
        tags.taglist && tags.taglist.map((relateddata) => {
          if (relateddata.recordtypeid == 1)
            lead++;

          if (relateddata.recordtypeid == 2)
            contact++;

          if (relateddata.recordtypeid == 3)
            company++;

          if (relateddata.recordtypeid == 4)
            opportunity++;

          if (relateddata.recordtypeid == 5)
            project++;

          if (relateddata.recordtypeid == 6)
            task++;
        })

        tagslist[key].lead = lead;
        tagslist[key].contact = contact;
        tagslist[key].company = company;
        tagslist[key].opportunity = opportunity;
        tagslist[key].project = project;
        tagslist[key].task = task;
      })
      this.setState({ tagslist })
    }

  }

  toggleDeleteDialog = () => {
    this.setState({ isDeleteConfirmDialoOpen: !this.state.isDeleteConfirmDialoOpen });
  };



  handleFull = (tag) => {
    console.log(tag)
    this.setState({ open: true,name:tag.tagname,tagid:tag.id })
  };

  handleClose = () => {
    this.setState({ open: false,name:"" })
  }


  handleChange = (event) => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleFormSubmit = () => {
    if(this.state.tagid){
      let req = {
        tagname:this.state.name,
        tagid:parseInt(this.state.tagid)
      }
      this.props.updateTagDetail(this.props.apolloClient.client,req)
      this.handleClose()
    }
  };

  confirmDeleteTag = () => {
    if(this.state.delId){
      let req = {
        tagid:parseInt(this.state.delId)
      }
      
      this.props.deleteTag(this.props.apolloClient.client, req);
      this.toggleDeleteDialog();
    }
  };
  openDeleteDialog = (tag) => {
    if(tag.id){
      this.setState({ isDeleteConfirmDialoOpen:true, delId:tag.id});
    }
  };

  render() {
    let { open, minimizeViewEmailDialog, FullViewEmailDialog } = this.props;
    let { tagslist } = this.state;

    console.log(tagslist, 'tagslist');
    return (
      <React.Fragment>
        <Grid className="v_scrollI">
          <Typography className="p-10" variant="h5">Tag Management</Typography>
          <Typography className="text-hint p-10" variant="p" >
            Edit and delete tags that are used in your system. Changes made here will be reflected company wide.
          </Typography>
          <Card className="mt-30">
            <TableContainer component={Paper}>
              <Table className={""} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell width={400} align="left">Tag Name</TableCell>
                    <TableCell width={400} align="left">Related Records</TableCell>
                    <TableCell width={400} align="center">{ }</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tagslist && tagslist.map(tag => (
                    <TableRow>
                      <TableCell width={180} align="left">{tag.tagname}</TableCell>
                      <TableCell width={180} align="left">
                        <p style={{ cursor: 'pointer' }}>
                          <Link>
                            {(tag.lead > 0) && "Lead (" + tag.lead + ")"}
                          </Link>
                        </p>
                        <p style={{ cursor: 'pointer' }}>
                          <Link>
                            {(tag.contact > 0) && "Contact (" + tag.contact + ")"}
                          </Link>
                        </p>
                        <p style={{ cursor: 'pointer' }}>
                          <Link>
                            {(tag.company > 0) && "Company (" + tag.company + ")"}
                          </Link>
                        </p>
                        <p style={{ cursor: 'pointer' }}>
                          <Link>
                            {(tag.opportunity > 0) && "Opportunity (" + tag.opportunity + ")"}
                          </Link>
                        </p>
                        <p style={{ cursor: 'pointer' }}>
                          <Link>
                            {(tag.project > 0) && "Project (" + tag.project + ")"}
                          </Link>
                        </p>
                        <p style={{ cursor: 'pointer' }}>
                          <Link>
                            {(tag.project > 0) && "Project (" + tag.project + ")"}
                          </Link>
                        </p>
                        <p style={{ cursor: 'pointer' }}>
                          <Link>
                            {(tag.task > 0) && "Task (" + tag.task + ")"}
                          </Link>
                        </p>
                        {/* <p style={{ cursor: 'pointer' }}><Link> Projects (1)</Link></p>
                      <p style={{ cursor: 'pointer' }}><Link>Tasks (9)</Link></p>
                      <p style={{ cursor: 'pointer' }}><Link> Sales Opportunities (3)</Link></p>
                      <p style={{ cursor: 'pointer' }}><Link>Companies (3)</Link></p> */}
                      </TableCell>
                      <TableCell width={180} align="center">
                        <IconButton aria-label="edit" size="small" onClick={()=>this.handleFull(tag)}>
                          <Icon onClick={()=>this.handleFull(tag)}>edit</Icon>
                        </IconButton>
                        <IconButton aria-label="delete" size="small" onClick={()=>this.openDeleteDialog(tag)}>
                          <Icon onClick={()=>this.openDeleteDialog(tag)}>delete</Icon>
                        </IconButton></TableCell>
                    </TableRow>
                  ))}

                </TableBody>
              </Table>
            </TableContainer>
          </Card>
          <Dialog open={this.state.open} maxWidth="md" fullWidth={true} className={`crm-custom-bg-primary custom_dialog ${minimizeViewEmailDialog && FullViewEmailDialog ? 'alignitemsend-fullscreen' : ''} ${minimizeViewEmailDialog ? 'alignitemsend' : ''} ${FullViewEmailDialog ? 'fullscreen' : ''}`} disableBackdropClick >
            <ValidatorForm ref="form" noValidate onSubmit={this.handleFormSubmit}>
              <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">
                <h4 className="m-0 text-white">Rename sample</h4>
                <div className="d-flex">
                  <Tooltip title="Close">
                    <IconButton onClick={this.handleClose}>
                      <Icon className="text-white">clear</Icon>
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              <div className="dialog-body">
                <p>Renaming this tag will affect 21 records. After saving, it could take a few minutes for the changes to be updated throughout the system.</p>
                <TextValidator
                  required={true}
                  id="name"
                  className="w-100 input_bx"
                  onChange={(event)=>this.handleChange(event)}
                  name="name"
                  value={this.state.name}
                  fullWidth
                  placeholder="enter text"
                  validators={["required"]}
                  errorMessages={[
                    "This field is required",
                  ]}
                  autoComplete='off'
                />
              </div>
              <DialogActions className="dialog-action">

                <Button variant="contained" onClick={this.handleClose}>Cancel</Button>
                <Button variant="contained" className="btn_Primary" color="primary" type="submit">Save</Button>


              </DialogActions>
            </ValidatorForm>
          </Dialog>
        </Grid>
        <Dialog
            fullWidth={true}
            maxWidth={'xs'}
            open={this.state.isDeleteConfirmDialoOpen}
            onClose={this.toggleDeleteDialog}
            disableBackdropClick

            aria-labelledby="ar-remove-dialog-title"
          >
            <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to remove ?"}</DialogTitle>
            <DialogActions>
              <Button onClick={this.toggleDeleteDialog} color="primary">
                Cancel
                                      </Button>
              <Button type="Button" onClick={() => this.confirmDeleteTag()} className="btn_Primary">
                Ok
                                      </Button>

            </DialogActions>
          </Dialog>
          <Dialog
            fullWidth={true}
            maxWidth={'xs'}
            open={this.state.isDeleteLogConfirmDialoOpen}
            onClose={this.toggleDeleteLogDialog}
            disableBackdropClick

            aria-labelledby="ar-remove-dialog-title"
          ></Dialog>
      </React.Fragment>
    );
  }
}
// const mapStateToProps = state => ({
//   settingReducer: state.settingReducer,
//   apolloClient: state.apolloClient,

// });
// const mapDispatchToProps = dispatch => {
//   return {
//     clearNotification: () => dispatch(actionCreators.clearNotification()),
//   }
// }
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(TagManagement);

const mapStateToProps = state => {
  return {
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTagsList: (client) => dispatch(actionCreators.getTagsList(client)),
    updateTagDetail: (client,req) => dispatch(actionCreators.updateTagDetail(client,req)),
    deleteTag: (client,req) => dispatch(actionCreators.deleteTag(client,req)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps)(TagManagement));
