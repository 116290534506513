import React, { Component } from "react";
import {
  Dialog, IconButton, Button, Icon, Tooltip, Grid,
  FormControl, Select, InputLabel, Menu, MenuItem, List,
  ListItem, ListItemText, DialogActions,
  Chip, TextField, withStyles, Fab, Card, CircularProgress
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';
import FontAwesome from "react-fontawesome";

import { EditorState, convertToRaw, getPlainText, genKey } from 'draft-js';
import createSingleLinePlugin from 'draft-js-single-line-plugin';
import { MegadraftEditor, editorStateFromRaw, editorStateToJSON, MegadraftPlugin, insertDataBlock } from "megadraft-denistsuman";
import Editor from 'draft-js-plugins-editor';
import createMentionPlugin, { defaultSuggestionsFilter } from 'draft-js-mention-plugin';
import { fromJS } from 'immutable';
import forEach from 'lodash/forEach';
import 'draft-js-mention-plugin/lib/plugin.css';
let mentions = [];


const TooltipNew = withStyles((theme) => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);

class CreatelogDialog extends Component {
  constructor(props) {
    super(props);
    this.mentionPlugin = createMentionPlugin({
      mentions,
      entityMutability: 'IMMUTABLE',
      mentionPrefix: '@',
      supportWhitespace: true
    });

    this.singleLinePlugin = createSingleLinePlugin({
      stripEntities: false
    });
    this.state = {
      editorState: EditorState.createEmpty(),
      selectedDate: new Date(),
      logvaluetype: 'Phone Call',
      textvalue: '',
      dateformat: 'MM/DD/YYYY',
      timeZone: 'America/New_York',
      logtype: 0,
      propertyid: 1,
      activities: [],
      suggestions: mentions,
      isDataLoading: true,
      isSubmitDisable: true
    };
  }
  componentDidMount() {
    this.props.getActivityTypes(this.props.apolloClient.client);
    if (this.props.logvaluetype != '') {
      this.setState({ logvaluetype: this.props.logvaluetype });
    }
  }
  componentWillReceiveProps(props) {
    if (props.settingReducer.usersListArray) {
      let usersList = props.settingReducer.usersListArray;
    }

    if (this.props.settingReducer.usersListArray && this.props.settingReducer.usersListArray.data) {
      let users = [];
      this.props.settingReducer.usersListArray.data.map((user) => {
        users.push({
          id: user.id,
          name: user.name,
        });
      });
      mentions = users;
    }


    if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
      let usersetting = props.settingReducer.userSettingArray.data;

      let format = usersetting.dateformat;
      let timezone = usersetting.timezone;
      this.setState({ dateformat: format, timeZone: timezone })
    }

    if (props.logtype) {
      this.setState({ logtype: props.logtype });
    }

    if (props.settingReducer.masterActivityTypesList && props.settingReducer.masterActivityTypesList.data) {
      this.setState({ activities: props.settingReducer.masterActivityTypesList.data, isDataLoading: false });
    }
  }

  onChange = (editorState) => {
    this.setState({
      editorState: editorState,
      isSubmitDisable:
        (this.state.editorState.getCurrentContent().hasText() && editorState.getCurrentContent().hasText()) ?
          false : true
    });
  };

  onSearchChange = ({ value }) => {
    this.setState({
      suggestions: defaultSuggestionsFilter(value, mentions),
    });
  };

  onAddMention = (mention) => {
    let that = this;
    setTimeout(() => {
      that.setState({ editorState: EditorState.moveFocusToEnd(that.state.editorState) })
    }, 10);
  }

  focus = () => {
    this.editor.focus();
  };


  handleDateChange = date => {
    this.setState({ selectedDate: date });
  };

  handleCancelAddLog = () => {
    this.setState({ textvalue: '' })
  }
  handlechange = (event) => {
    let key = this.findWithAttr(this.state.activities, 'id', event.target.value);
    let prop_name = this.state.activities[key].name;

    this.setState({ propertyid: event.target.value, logvaluetype: prop_name })
  }
  SaveLog = () => {
    const content = this.state.editorState.getCurrentContent();
    const raw = JSON.parse(JSON.stringify(convertToRaw(content)));
    let mention = [];

    Object.entries(raw.entityMap).map((entity) => {
      if (mention.indexOf(entity[1].data.mention.id) == -1) {
        mention.push(parseFloat(entity[1].data.mention.id))
      }
    });
    const textvalue = content.getPlainText('\u0001');

    let date_test = moment(this.state.selectedDate).format('YYYY-MM-DD HH:mm');
    var logdate = moment.tz(date_test, this.state.timeZone).format();
    var date = moment.utc(logdate).format('X');
    let propname = this.state.logvaluetype;
    let prop_id = (this.props.logtype == 0) ? parseFloat(this.state.propertyid) : 0;
    let saveLogparams = {
      mentionuser: mention,
      mentiondetail: JSON.stringify(raw),
      propertyname: propname,
      propertyid: prop_id,
      text: textvalue,
      logtime: parseFloat(date),
      logdate: parseFloat(date),
      oldvalue: "",
      newvalue: "",
      logtype: this.props.logtype,
      fieldid: 0,
    };
    if (textvalue.length > 0) {
      this.props.handleSaveAddLog(saveLogparams)
    } else {
      this.editor.focus()
    }
    let editorState = EditorState.createEmpty();
    this.setState({ editorState: editorState })
  }
  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }
  handleClose = () => {
    let editorState = EditorState.createEmpty();
    this.setState({ editorState: editorState })
    this.props.handleClose();
  }
  render() {
    const { MentionSuggestions } = this.mentionPlugin;
    const plugins = [this.singleLinePlugin, this.mentionPlugin];

    let { open, handleMinimize, handleFull, fullScreen, minimizeScreen } = this.props;
    const { propertyid, activities, selectedDate } = this.state
    return (
      <Dialog onClose={this.handleClose} open={open} maxWidth="md" fullWidth={true} className={`custom_dialog ${minimizeScreen && fullScreen ? 'alignitemsend-fullscreen' : ''} ${minimizeScreen ? 'alignitemsend' : ''} ${fullScreen ? 'fullscreen' : ''}`} disableBackdropClick >
        <div className="crm-custom-bg-primary">
          <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary ">
            <h4 className="m-0 text-white">
              {(this.props.logtype == 0) ?
                "Add New Log"
                : (this.props.logtype == 5) ?
                  "Add New Note"
                  :
                  null
              }
            </h4>
            <div className="d-flex">
              <TooltipNew title={minimizeScreen ? 'Normal Screen' : 'Minimize Screen'}>
                <IconButton onClick={handleMinimize}>
                  <Icon className="text-white">{minimizeScreen ? 'add' : 'remove'}</Icon>
                </IconButton>
              </TooltipNew>
              <TooltipNew title={fullScreen ? 'Exit Full Screen' : 'Full Screen'}>
                <IconButton onClick={handleFull}>
                  <Icon className="text-white">{fullScreen ? 'fullscreen_exit' : 'fullscreen'}</Icon>
                </IconButton>
              </TooltipNew>
              <TooltipNew title="Close">
                <IconButton onClick={this.handleClose}>
                  <Icon className="text-white">clear</Icon>
                </IconButton>
              </TooltipNew>
            </div>
          </div>
        </div>
        <ValidatorForm noValidate debounceTime={500} onSubmit={this.SaveLog}>
          {this.state.isDataLoading && (
            <Grid
              container
              spacing={0}
              className="p-20"
              direction="column"
              alignItems="center"
              justify="center"
              style={{ minHeight: '30vh', backgroundColor: 'rgba(0,0,0,0)' }}
            >
              <Grid item xs={3}>
                <CircularProgress disableShrink size={30} />
              </Grid>
            </Grid>
          )}
          {!this.state.isDataLoading &&
            <div className="dialog-body">
              <Grid className="p-20" container spacing={2}>
                <Grid item style={{ margin: '10px 40px 10px 0px', padding: 0 }}  >
                  {(this.props.logtype == 0) &&
                    (
                      <Select
                        name="phone_log_drop"
                        id="phone_log_drop"
                        className="font-size-13"
                        value={propertyid}
                        disableUnderline
                        defaultValue={propertyid}
                        onChange={this.handlechange}
                      >
                        {activities.map((activity) => (
                          (activity.is_disable == false) &&
                          <MenuItem key={activity.name} className="" value={activity.id}>
                            {/* {console.log("activity.icon", activity.icon)} */}
                            {activity.icon && activity.icon === "fa-paper-plane" && <FontAwesome className={activity.icon + " pr-10 crm_activitytype_icon"} style={{ fontSize: '15px', marginLeft: '-2px' }} />}
                            {activity.icon && activity.icon !== "fa-paper-plane" && <FontAwesome className={activity.icon + " pr-10 crm_activitytype_icon"} style={{ fontSize: '14px' , marginRight: '2px'}}  />}
                            <span className="font-weight-500 font-size-14 ml-5 mr-5"> {activity.name}</span>
                          </MenuItem>
                        ))}
                      </Select>
                    )
                  }
                </Grid>
                <Grid item xs={12} className={"crm-editor-mention mb-10"}
                  onClick={this.focus}>
                  <label style={{ fontSize: '12px', marginBottom: '4px' }} >{this.props.logtype == 0 ? "Log Text" : "Note Text"}*</label>
                  <Editor
                    editorState={this.state.editorState}
                    onChange={this.onChange}
                    plugins={plugins}
                    blockRenderMap={createSingleLinePlugin.blockRenderMap}
                    ref={(element) => { this.editor = element; }}
                    spellCheck
                    placeholder={this.props.logtype == 0 ? "Add Log Text" : "Add Note Text"}
                  />
                  <MentionSuggestions
                    onSearchChange={this.onSearchChange}
                    suggestions={this.state.suggestions}
                    onAddMention={this.onAddMention}
                    onClose={() => this.setState({ ...this, suggestions: fromJS([]) })}
                  />
                </Grid>
                <div className="mt-40">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container spacing={3}>
                      <Grid item xs={6} className="pt-0">
                        <InputLabel htmlFor="assignedto" className="font-size-14 font-weight-500" style={{ marginBottom: '-18px' }} >Select Date</InputLabel>
                        <KeyboardDatePicker
                          margin="normal"
                          id="date-picker-dialog"
                          format={this.state.dateformat.replace("DD", "dd").replace("YYYY", "yyyy")}
                          maxDate={new Date()}
                          value={selectedDate}
                          onKeyPress={(e) => e.preventDefault()}
                          onChange={this.handleDateChange}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          placeholder={this.state.dateformat.replace("DD", "dd").replace("YYYY", "yyyy").replace("MM", "mm")}
                        />
                      </Grid>
                      <Grid item xs={6} className="pt-0">
                        <InputLabel htmlFor="assignedto" className="font-size-14 font-weight-500" style={{ marginBottom: '-18px' }} >Time</InputLabel>
                        <KeyboardTimePicker
                          margin="normal"
                          id="time-picker"
                          format="hh:mm a"
                          value={selectedDate}
                          onKeyPress={(e) => e.preventDefault()}
                          onChange={this.handleDateChange}
                          KeyboardButtonProps={{
                            'aria-label': 'change time',
                          }}
                        />
                      </Grid>
                    </Grid>
                  </MuiPickersUtilsProvider>
                  <div className="pull-right">
                  </div>
                </div>
              </Grid>
            </div>
          }
          <DialogActions className="dialog-action">
            <Button className="mr-10" color="secondary" onClick={this.handleClose}>Cancel</Button>
            <Button variant="contained" className={"btn_Primary mr-10"} type="submit" disabled={this.state.isSubmitDisable}>Save</Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  companyReducer: state.companyReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    saveCompanyRecLog: (client, request) => dispatch(actionCreators.saveCompanyRecLog(client, request)),
    getActivityTypes: (client) => { dispatch(actionCreators.getActivityTypes(client)) },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatelogDialog);
