import * as actionTypes from '../actions/actionTypes';
import { updateObject } from "../utility";
import { notification } from 'antd';
const initialState = {
  results: [],
  userList: [],
  userListById: [],
  firstUserInfo: [],
  bussinessType: [],
  compStrength: [],
  compList: [],
  companyList: [],
  customFieldList: [],
  //contact custom field
  contactCustomFieldList: [],
  //company custom field
  companyCustomFieldList: [],

  //opportunities custom field
  opportunitiesCustomFieldList: [],

  //projects custom field 
  projectCustomFieldList: [],

  //tasks custom field
  taskCustomFieldList: [],

  addCustomList: [],
  updateCustomList: [],
  deleteCustomList: [],
  createdFieldList: [],
  customFieldOrder: [],
  leadStatusList: [],
  sourcesList: [],
  //customFieldTypeList: [ 'None', 'Text field' ,  'Text area' ,  'Checkbox' ,  'Drop down' ,  'Multi drop down' ,  'Number' , 'Percentage' , 'Date' , 'Url' , 'Currency' , 'Connect field' ],
  customFieldTypeList: [],
  currencyList: [],
  companyCurrencyData: [],
  fieldDetail: [],
  countryListGql: [],
  userSettingList: [],
  systemTagsList: [],
  usersListArray: [],
  systemCityListArray: [],
  systemStateListArray: [],
  systemZipcodeListArray: [],
  userSettingArray: [],
  timezoneList: [],
  pipelineListArray: [],
  singlePipelineData: [],
  loassReasonList: [],
  companyRecList: [],
  contactRecList: [],
  projectRecList: [],
  opportunityRecList: [],
  taskRecList: [],
  masterActivityTypesList: [],
  relatedListArray: [],
  leadrelatedMaster: [],
  contactrelatedMaster: [],
  companyrelatedMaster: [],
  opportunityrelatedMaster: [],
  projectrelatedMaster: [],
  emailtemplatelist: [],
  emailtemplatedetail: [],
  relatedDataCompany: [],
  relatedDataContact: [],
  relatedDataProject: [],
  relatedDataOpportunity: [],
  relatedDataTask: [],

  relatedDataCalandar: [],

  relatedDataFile: [],
  relatedDataQuickBook: [],
  relatedDataXero: [],

  generatedmailboxid: [],
  gmailSuggestions: [],
  projectStageList: [],

  notificationList: [],
  notificationReadList: [],


  importExcelData: [],
  importDataFile: [],
  sampleImportFile: [],
  pastImportFileList: [],
  revertImportDetail: [],
  emailsettings: [],
  getnotificationsettingdata: [],
  getfollowSettingdata: [],
  automationList: [],
  automationDetail: [],
  automationDetail: [],

  //itregation 
  userItegration: [],
  UpdateuserItegration: [],
  saveIntregationDetails: [],
  getintegationDetails: [],

  //zendesk
  zendeskVerify: [],
  getticketList: [],

  //zapire
  generateZapireToken: [],

  slackSettings: [],
  slackChannelList: [],

  //mailchimp
  MailChimpVerifyData: [],
  getMailchimp_Audience: [],
  addMailchimp: [],
  getMemberAudience: [],
  
  //DocuSing
  getDocuSingRole: [],
  CreateDocuSign: [],
  getuserdetailbyemail: [],
  getDocusingData: [],

  TrackedEmailList: [],
  
  teamList: [],
  teamDetails: [],
  teamMemberList: [],
  teamAcessList: [],
  
  sales_montly_goallist: [],
  sales_quarter_goallist: [],
  sales_year_goallist: [],

  won_montly_goallist: [],
  won_quarter_goallist: [],
  won_year_goallist: [],

  activity_montly_goallist: [],
  activity_quarter_goallist: [],
  activity_year_goallist: [],

  get_goal_report_data:[],

  user_filter_list_goal:[],

  
  teamReportAceessList: [],
  userPermissionDetails: [],
  userCompanyDetails: [],
  teamPermissionStatus: [],
  
  getsyncTime: [],
  masterSerach: [],

  billingplans_list:[],
  success_purchased_plan:[],
  taglist:[],
  selected_plan_details:[],

  taskautomationlist:[],

  leadsettingslist : [],
  getcontactRelatedData : [],

}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SETTING_ADD_USER:
      return { ...state, results: action.val };
    case actionTypes.GET_SETTING_USER_LIST:
      return { ...state, userList: action.val };
    case actionTypes.GET_USER_BY_ID:
      return updateObject(state, { userListById: action.val })
    case actionTypes.GET_USER_PROFILE_INFO_FIRST:
      return updateObject(state, { firstUserInfo: action.val })
    case actionTypes.GET_BUSSINESS_TYPE:
      return updateObject(state, { bussinessType: action.val })
    case actionTypes.GET_COMPANY_STRENGTH:
      return updateObject(state, { compStrength: action.val })
    case actionTypes.COMPANY_LIST_BY_ID:
      return updateObject(state, { compList: action.val })
    case actionTypes.COUNTRY_LIST:
      return updateObject(state, { countryList: action.val })
    case actionTypes.COMPANY_LIST:
      return updateObject(state, { companyList: action.val })


    case actionTypes.COSTOM_FIELD_TYPE_LIST:
      return updateObject(state, { customFieldTypeList: action.val })
    case actionTypes.COSTOM_FIELD_LIST:
      return updateObject(state, { customFieldList: action.val })
    case actionTypes.UPDATE_CUSTOM_FIELD_RECORD:
      return updateObject(state, { customFieldList: action.val })
    case actionTypes.UPDATE_CUSTOM_FIELD_STATUS:
      return updateObject(state, { customFieldList: action.val })
    case actionTypes.UPDATE_CUSTOM_FIELD_ORDER:
      return updateObject(state, { customFieldList: action.val })


    case actionTypes.CONTACT_COSTOM_FIELD_LIST:
      return updateObject(state, { contactCustomFieldList: action.val })

    case actionTypes.COMPANY_COSTOM_FIELD_LIST:
      return updateObject(state, { companyCustomFieldList: action.val })

    case actionTypes.OPPORTUNITIES_COSTOM_FIELD_LIST:
      return updateObject(state, { opportunitiesCustomFieldList: action.val })

    case actionTypes.PROJECTS_COSTOM_FIELD_LIST:
      return updateObject(state, { projectCustomFieldList: action.val })

    case actionTypes.TASKS_COSTOM_FIELD_LIST:
      return updateObject(state, { taskCustomFieldList: action.val })


    case actionTypes.ADD_CUSTOM_FIELD:
      return updateObject(state, { addCustomList: action.val })
    case actionTypes.UPDATE_CUSTOM_FIELD:
      return updateObject(state, { updateCustomList: action.val })
    case actionTypes.DELETE_CUSTOM_FIELD:
      return updateObject(state, { deleteCustomList: action.val })
    case actionTypes.CREATED_FIELD_LIST:
      return updateObject(state, { createdFieldList: action.val })
    case actionTypes.COSTOM_FIELD_DETAIL:
      return updateObject(state, { fieldDetail: action.val })

    case actionTypes.LEAD_STATUSES_LIST_GET:
      return updateObject(state, { leadStatusList: action.val })
    case actionTypes.LEAD_SOUECES_LIST:
      return updateObject(state, { sourcesList: action.val })
    case actionTypes.ALL_CURRENCY_LIST:
      return updateObject(state, { currencyList: action.val })
    case actionTypes.COMPANY_CURRENCY_DATA:
      return updateObject(state, { companyCurrencyData: action.val })
    case actionTypes.COUNTRY_LIST_GQL:
      return updateObject(state, { countryListGql: action.val })
    case actionTypes.SYSTEM_TAGS_LIST:
      return updateObject(state, { systemTagsList: action.val })
    case actionTypes.USERS_LIST_LIST:
      return updateObject(state, { usersListArray: action.val })
    case actionTypes.GET_CITY_LIST:
      return updateObject(state, { systemCityListArray: action.val })
    case actionTypes.GET_STATE_LIST:
      return updateObject(state, { systemStateListArray: action.val })
    case actionTypes.GET_ZIPCODE_LIST:
      return updateObject(state, { systemZipcodeListArray: action.val })
    case actionTypes.GET_USER_SETTING:
      return updateObject(state, { userSettingArray: action.val })
    case actionTypes.GET_TIMEZONE_LIST:
      return updateObject(state, { timezoneList: action.val })
    case actionTypes.SETTING_PIPELINE_LIST:
      return updateObject(state, { pipelineListArray: action.val })
    case actionTypes.PIPELINE_DATA_BY_ID:
      return updateObject(state, { singlePipelineData: action.val })
    case actionTypes.LOSS_REASON_LIST:
      return updateObject(state, { loassReasonList: action.val })

    case actionTypes.GET_COMPANY_REC_LIST:
      return updateObject(state, { companyRecList: action.val })
    case actionTypes.GET_CONTACT_REC_LIST:
      return updateObject(state, { contactRecList: action.val })
    case actionTypes.GET_PROJECT_REC_LIST:
      return updateObject(state, { projectRecList: action.val })
    case actionTypes.GET_OPPORTUNITY_REC_LIST:
      return updateObject(state, { opportunityRecList: action.val })
    case actionTypes.GET_TASK_REC_LIST:
      return updateObject(state, { taskRecList: action.val })
    case actionTypes.MASTER_ACTIVITYTYPES_LIST:
      return updateObject(state, { masterActivityTypesList: action.val })
    case actionTypes.GET_RELATEDLIST_LIST:
      return updateObject(state, { relatedListArray: action.val })
    case actionTypes.LEAD_RELATED_MASTER_LIST:
      return updateObject(state, { leadrelatedMaster: action.val })
    case actionTypes.CONTACT_RELATED_MASTER_LIST:
      return updateObject(state, { contactrelatedMaster: action.val })
    case actionTypes.COMPANY_RELATED_MASTER_LIST:
      return updateObject(state, { companyrelatedMaster: action.val })
    case actionTypes.OPPORTUNITY_RELATED_MASTER_LIST:
      return updateObject(state, { opportunityrelatedMaster: action.val })
    case actionTypes.PROJECT_RELATED_MASTER_LIST:
      return updateObject(state, { projectrelatedMaster: action.val })
    case actionTypes.EMAIL_TEMPLATE_LIST:
      return updateObject(state, { emailtemplatelist: action.val })
    case actionTypes.EMAIL_TEMPLATE_DETAIL:
      return updateObject(state, { emailtemplatedetail: action.val })
    case actionTypes.RELATED_DATALIST_COMPANY:
      return updateObject(state, { relatedDataCompany: action.val })
    case actionTypes.RELATED_DATALIST_CONTACT:
      return updateObject(state, { relatedDataContact: action.val })
    case actionTypes.RELATED_DATALIST_PROJECT:
      return updateObject(state, { relatedDataProject: action.val })
    case actionTypes.RELATED_DATALIST_OPPORTUNITY:
      return updateObject(state, { relatedDataOpportunity: action.val })
    case actionTypes.RELATED_DATALIST_TASK:
      return updateObject(state, { relatedDataTask: action.val })

    case actionTypes.RELATED_DATALIST_CALANDAR:
      return updateObject(state, { relatedDataCalandar: action.val })

    case actionTypes.RELATED_DATALIST_FILE:
      return updateObject(state, { relatedDataFile: action.val })
    case actionTypes.RELATED_DATALIST_QUICKBOOK:
      return updateObject(state, { relatedDataQuickBook: action.val })
    case actionTypes.RELATED_DATALIST_XERO:
      return updateObject(state, { relatedDataXero: action.val })

    case actionTypes.GET_USER_INTEGRATION:
      return updateObject(state, { userItegration: action.val })
    case actionTypes.UPDATE_USER_INTEGRATION:
      return updateObject(state, { UpdateuserItegration: action.val })

    case actionTypes.GET_GENERATED_MAIL_ID:
      return updateObject(state, { generatedmailboxid: action.val })
    case actionTypes.GMAIL_SUGGESTION_NAMES:
      return updateObject(state, { gmailSuggestions: action.val })
    case actionTypes.PROJECT_STAGE_LIST:
      return updateObject(state, { projectStageList: action.val })

    case actionTypes.NOTIFICATIONS_LIST:
      return updateObject(state, { notificationList: action.val })
    case actionTypes.NOTIFICATION_READ:
      return updateObject(state, { notificationReadList: action.val })

    case actionTypes.IMPORT_EXCEL_DATA:
      return updateObject(state, { importExcelData: action.val })
    case actionTypes.IMPORT_DATA_FILE:
      return updateObject(state, { importDataFile: action.val })
    case actionTypes.SAMPLE_IMPORT_FILE:
      return updateObject(state, { sampleImportFile: action.val })
    case actionTypes.GET_IMPORT_FILE_LIST:
      return updateObject(state, { pastImportFileList: action.val })
    case actionTypes.REVERT_IMPORT_DETAIL:
      return updateObject(state, { revertImportDetail: action.val })
    case actionTypes.GET_NOTIFICATIONS_SETTINGS:
      return updateObject(state, { getnotificationsettingdata: action.val })
    case actionTypes.GET_FOLLOW_SETTINGS:
      return updateObject(state, { getfollowSettingdata: action.val })

    case actionTypes.EMAIL_SETTINGS:
      return updateObject(state, { emailsettings: action.val })
    case actionTypes.AUTOMATION_LIST:
      return updateObject(state, { automationList: action.val })

    case actionTypes.AUTOMATION_DETAIL:
      return updateObject(state, { automationDetail: action.val })




    case actionTypes.ZENDESK_VERTFY:
      return updateObject(state, { zendeskVerify: action.val })
    case actionTypes.SAVE_INTREGATION_DETAILS:
      return updateObject(state, { saveIntregationDetails: action.val })
    case actionTypes.GET_INTEGRATION_DETAILS:
      return updateObject(state, { getintegationDetails: action.val })

    case actionTypes.GET_TICKET_LIST:
      return updateObject(state, { getticketList: action.val })

    case actionTypes.GENERATE_ZAPIER_TOKEN:
      return updateObject(state, { generateZapireToken: action.val })

    case actionTypes.GET_TICKET_LIST:
      return updateObject(state, { getticketList: action.val })

    case actionTypes.GENERATE_ZAPIER_TOKEN:
      return updateObject(state, { generateZapireToken: action.val })

    case actionTypes.SLACK_SETTINGS:
      return updateObject(state, { slackSettings: action.val })
    case actionTypes.SLACK_CHANNELS:
      return updateObject(state, { slackChannelList: action.val })

    case actionTypes.MAILCHIMP_DATA_VERTFY:
      return updateObject(state, { MailChimpVerifyData: action.val })
    case actionTypes.GET_MAILCHIMP_AUDIENCE:
      return updateObject(state, { getMailchimp_Audience: action.val })
    case actionTypes.ADD_MAILCHIMP_SUBSCRIBER:
      return updateObject(state, { addMailchimp: action.val })
    case actionTypes.GET_MEMBER_IN_AUDIENCE:
      return updateObject(state, { getMemberAudience: action.val })

    case actionTypes.EMAIL_TRACKING_LIST:
      return updateObject(state, { TrackedEmailList: action.val })

    case actionTypes.GET_DOCUSING_ROLE:
      return updateObject(state, { getDocuSingRole: action.val })
    case actionTypes.CREATES_DOCUSIGN:
      return updateObject(state, { CreateDocuSign: action.val })

    case actionTypes.GET_SYNC_TIME:
      return updateObject(state, { getsyncTime: action.val })


    case actionTypes.GET_TEAM_LIST:
      return updateObject(state, { teamList: action.val })
    case actionTypes.GET_TEAM_DETIALS:
      return updateObject(state, { teamDetails: action.val })
    case actionTypes.GET_TEAM_MEMBER_LIST:
      return updateObject(state, { teamMemberList: action.val })

    case actionTypes.GET_TEAM_ACCESS_LIST:
      return updateObject(state, { teamAcessList: action.val })

    // goals
    case actionTypes.GET_SALES_MONTHLY_GOAL_LIST:
      return updateObject(state, { sales_montly_goallist: action.val })
    case actionTypes.GET_SALES_QUARTER_GOAL_LIST:
      return updateObject(state, { sales_quarter_goallist: action.val })
    case actionTypes.GET_SALES_YEAR_GOAL_LIST:
      return updateObject(state, { sales_year_goallist: action.val })

    case actionTypes.GET_WON_MONTHLY_GOAL_LIST:
      return updateObject(state, { won_montly_goallist: action.val })
    case actionTypes.GET_WON_QUARTER_GOAL_LIST:
      return updateObject(state, { won_quarter_goallist: action.val })
    case actionTypes.GET_WON_YEAR_GOAL_LIST:
      return updateObject(state, { won_year_goallist: action.val })

    case actionTypes.GET_ACTIVITY_MONTHLY_GOAL_LIST:
      return updateObject(state, { activity_montly_goallist: action.val })
    case actionTypes.GET_ACTIVITY_QUARTER_GOAL_LIST:
      return updateObject(state, { activity_quarter_goallist: action.val })
    case actionTypes.GET_ACTIVITY_YEAR_GOAL_LIST:
      return updateObject(state, { activity_year_goallist: action.val })

    case actionTypes.GET_USER_FILTER_LIST_FOR_GOAL:
      return updateObject(state, { user_filter_list_goal: action.val })

    case actionTypes.GET_GOAL_REPORT:
      return updateObject(state, { get_goal_report_data: action.val })
    //------


    case actionTypes.GET_TEAM_REPORT_LIST:
      return updateObject(state, { teamReportAceessList: action.val })

    case actionTypes.GET_USER_PERMISSION:
      return updateObject(state, { userPermissionDetails: action.val })

    case actionTypes.GET_COMPANY_DETAILS:
      return updateObject(state, { userCompanyDetails: action.val })

    case actionTypes.GET_TEAM_SETTINGS:
      return updateObject(state, { teamPermissionStatus: action.val })

    case actionTypes.MASTER_SEARCH:
      return updateObject(state , { masterSerach : action.val })

    case actionTypes.GET_USER_DETAIL_BY_EMAIL:
      return updateObject(state, { getuserdetailbyemail: action.val })

    case actionTypes.GET_DOCUSING_DATA :
      return updateObject(state, { getDocusingData: action.val })

    case actionTypes.BILLING_PLANS_LIST:
      return updateObject(state , { billingplans_list : action.val })
    
    case actionTypes.COMPANY_PLAN_PURCHASE:
      return updateObject(state , { success_purchased_plan : action.val })
      
    case actionTypes.SELECTED_PLAN_DETALS:
      return updateObject(state , { selected_plan_details : action.val })
      
    case actionTypes.TASK_AUTOMATION_LIST:
      return updateObject(state , { taskautomationlist : action.val })
      
    case actionTypes.LEAD_SETTINGS_LIST_GET:
      return updateObject(state , { leadsettingslist : action.val })
    // case actionTypes.GET_TAG_LIST:
    //   return updateObject(state , { taglist : action.val })

    case actionTypes.GET_RELATED_CONTACTS__DATA:
      return updateObject(state , { getcontactRelatedData : action.val })

      
    default:
      return state;
  }
};
export default reducer;
