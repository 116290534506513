import React, { Component } from "react";
import { Dialog, IconButton, Button, Icon, Tooltip, Grid, FormControl, Select, InputLabel, Menu, MenuItem, List, ListItem, ListItemText, DialogActions, Chip, TextField } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { CreateFieldsDynamicEdit } from './CreateFieldsDynamicEdit'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';
class PojectProfileDetails extends Component {
  state = {
    project_id: "",
    lastname: "",
    city: "",
    addressState: "",
    zipcode: "",
    country: "",
    countryListArray: [],
    companyCurrencyData: [],
    focusValue: false,
    fieldsList: [],
    fieldsObj: {},
    systemTagsList: [],
    isUpdate: true,
    isLeadDetailUpdate: true,
    projectFieldData: [],
    usersListArray: [],
    fnameError: false,
    userSettings: "",
    isAnyValueChange: false,
    relatedListArray: [],
    selectedDate: new Date(),
    updatedfieldid: "",
    isEditVisibility: false,
    teamvisible: true,
    oldteamvisible: true,
    hasActiveTeamPermission: false,
    teamVisibilityLabel: "",
    crudPermission: {
      isdelete: true,
      isedit: true,
    },
    teamList: [],
    selectedTeamList: [],
    selectedUserList: [{}],
    oldSlectedUserList: [],
    oldSelctedTeamList: [],
    userListForVisibility: [],
    userTeamDetails: {},
  };

  // componentWillMount() {
  componentDidMount() {
    ValidatorForm.addValidationRule('IsURLValid', (value) => {
      if (value && value.length && value.length > 0) {
        var specialChar = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
        if (!specialChar.test(value)) {
          return false;
        }
      }
      return true;
    });
    this.props.getRelatedList(this.props.apolloClient.client);
    let val = { typeId: 5 }
    let userData = JSON.parse(localStorage.getItem('user_data_session'))
    let userid = { userid: userData.id }
    this.props.getTeam(this.props.apolloClient.client);
    this.props.getUserSetting(this.props.apolloClient.client, userid);
    this.props.customFieldList(this.props.apolloClient.client, val);
    this.props.getUserList(this.props.apolloClient.client);
    this.props.getCountryList(this.props.apolloClient.client);
    this.props.getCompanyCurrency(this.props.apolloClient.client);
    this.props.getTagsList(this.props.apolloClient.client);

    if (this.props.project_id && (this.state.project_id != this.props.project_id)) {
      this.props.getProjectField(this.props.apolloClient.client, parseInt(this.props.project_id));
      this.setState({
        project_id: parseInt(this.props.project_id),
        isUpdate: true,
      }, () => this.forceUpdate())
    }

  }
  componentWillUnmount() {
    this.props.projectReducer.projectFieldData = [];
    this.props.projectReducer.leadLogList = [];
  }
  AllMemberDisplay = () => {
    let teamVisibilityLabel = "";
    let userCount = 0;
    let teamCount = 0;
    let teamUser = [];
    let usersListArray = this.props.settingReducer.usersListArray.data ? this.props.settingReducer.usersListArray.data : []
    usersListArray.length > 0 && usersListArray.map((user) => {
      if (user.teamlist.length > 0) {
        let userdata = { userteam: [], id: "", username: "" }
        teamCount = teamCount < user.teamlist.length ? user.teamlist.length : teamCount;
        user.teamlist.map((u) => { userdata.userteam.push(u.team.teamname) })
        userdata.id = user.id;
        userdata.username = user.name;
        userCount = userCount + 1;
        teamUser.push(userdata)
      }
    })
    teamVisibilityLabel = userCount + " users from " + teamCount + " team ";
    this.setState({
      teamVisibilityLabel: teamVisibilityLabel,
      teamUser: teamUser
    }, () => this.forceUpdate())
  }
  selectedUserDetails = (mainuser) => {
    let teamVisibilityLabel = "";
    let teamUser = [];
    let userdata = { userteam: [], id: "", username: "" }
    mainuser.teamlist.map((u) => { userdata.userteam.push(u.team.teamname) })
    userdata.id = mainuser.id;
    userdata.username = mainuser.name;
    teamUser.push(userdata)
    teamVisibilityLabel = "1 users from " + mainuser.teamlist.length + " team ";
    this.setState({
      teamVisibilityLabel: teamVisibilityLabel,
      teamUser: teamUser
    }, () => this.forceUpdate())
  }
  themSelves = (selectedOwner) => {
    let teamVisibilityLabel = "";
    let userCount = 0;
    let teamCount = 0;
    let teamUser = [];
    let usersListArray = this.props.settingReducer.usersListArray.data ? this.props.settingReducer.usersListArray.data : []
    usersListArray.length > 0 && usersListArray.map((user) => {
      if (user.teamlist.length > 0) {
        if (user.teamlist[0].teammanager === true || (user.email === selectedOwner.email)) {
          let userdata = { userteam: [], id: "", username: "" }
          teamCount = teamCount < user.teamlist.length ? user.teamlist.length : teamCount;
          user.teamlist.map((u) => { userdata.userteam.push(u.team.teamname) })
          userdata.id = user.id;
          userdata.username = user.name;
          userCount = userCount + 1;
          teamUser.push(userdata)
        }
      }
    })
    teamVisibilityLabel = userCount + " users from " + teamCount + " team ";
    this.setState({
      teamVisibilityLabel: teamVisibilityLabel,
      teamUser: teamUser
    }, () => this.forceUpdate())
  }
  getUserPermission = () => {
    if (this.props.settingReducer.userPermissionDetails.data) {
      let data = this.props.settingReducer.userPermissionDetails.data.data;
      if (data.teampermissionstatus === true &&
        data.visibilityaccess.length > 0) {
        let prodt = data.visibilityaccess.find(function (e) { return e.sourcetype === 5 });
        let crudPermission = "";
        if (this.props.authReducer.userRole.id === 3) {
          crudPermission = prodt;
        }
        if (prodt.isaccess) {
          if (prodt.viewoption === 1) {
            this.AllMemberDisplay();
          }
          if (prodt.viewoption === 2 || prodt.viewoption === 3) {
            var selectedOwner = this.state.fieldsObj["owner"]
            if (selectedOwner && selectedOwner.values) {
              if (selectedOwner.values.teamlist.length > 0) {//if member in any team 
                if (selectedOwner.values.teamlist[0].teammanager === false) {//owner is not  teammanager
                  if (prodt.viewoption === 2) {
                    this.AllMemberDisplay();
                  } else {
                    this.themSelves(selectedOwner.values);
                  }
                } else {//owner is teammanager   
                  this.selectedUserDetails(selectedOwner.values);
                }
              } else { // not in any team 
                this.setState({ teamVisibilityLabel: "0 users", teamUser: [] }, () => this.forceUpdate())
              }
            } else {
              this.setState({ teamVisibilityLabel: "0 users", teamUser: [] }, () => this.forceUpdate())
            }
          }
        } else {//prodt.isaccess === false
          this.setState({ teamVisibilityLabel: "0 users", teamUser: [] }, () => this.forceUpdate())
        }
        this.setState({
          crudPermission: crudPermission === "" ? this.state.crudPermission : crudPermission,

          hasActiveTeamPermission: true,
          viewoption: prodt.viewoption,
        })
      } else {
        this.setState({ teamvisible: false })
      }
    }
  }

  componentWillReceiveProps(props) {
    this.getUserPermission();
    if (props.settingReducer.teamList.data && this.state.teamList !== props.settingReducer.teamList.data) {
      const teamList = props.settingReducer.teamList.data;
      let data = [];
      teamList.map((v, i) => {
        data.push({ id: v.id, teamname: v.teamname })
      });
      this.setState({ teamList: data })
    }
    if (props.settingReducer.relatedListArray.data) {
      props.settingReducer.relatedListArray.is_loaded = false
      const relatedData = props.settingReducer.relatedListArray.data;
      var NewArray = []
      //NewArray = NewArray.concat(relatedData.lead); 
      NewArray = NewArray.concat(relatedData.contact);
      NewArray = NewArray.concat(relatedData.company);
      NewArray = NewArray.concat(relatedData.opportunities);
      //NewArray = NewArray.concat(relatedData.project); 
      this.setState({ relatedListArray: NewArray })
    }
    //storing user setting data in state
    if (props.settingReducer.userSettingArray.data && this.state.userSettings == "") {
      const settingData = props.settingReducer.userSettingArray.data;
      this.setState({
        userSettings: settingData,
      })
    }

    if (props.project_id && (this.state.project_id != props.project_id) || this.state.isUpdate && props.project_id != undefined) {
      props.getProjectField(props.apolloClient.client, parseInt(props.project_id));

      let logParams = { projectid: parseFloat(props.project_id), emailid: "", activitypeid: [], logtypeid: [] }
      this.props.getProjectLogListNew(this.props.apolloClient.client, logParams);


      this.setState({
        project_id: parseInt(props.project_id),
      }, () => this.forceUpdate())
    }

    if (props.settingReducer.projectCustomFieldList.data && this.state.fieldsList.length == 0) {
      const customFields = props.settingReducer.projectCustomFieldList.data;
      this.setState({ fieldsList: customFields })
      var statuskey = this.findWithAttr(customFields, 'id', "70");
      var defaultKeyStatus = this.findWithAttr(customFields[statuskey].options, 'is_default', true);
      var vkey = this.findWithAttr(customFields, "id", "72");
      var visibilitydefault = this.findWithAttr(customFields[vkey].options, "is_default", true);

      this.setState({
        fieldsObj: {
          ...this.state.fieldsObj,
          ["visibility"]: { fieldid: "72", values: customFields[vkey].options[visibilitydefault], },

          ["status"]: { fieldid: "70", values: customFields[statuskey].options[defaultKeyStatus] }
        }
      })
    }

    if (props.settingReducer.countryListGql.data && this.state.countryListArray.length == 0) {
      const countryies = props.settingReducer.countryListGql.data;
      this.setState({ countryListArray: countryies })
    }
    if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
      const usersListArray = props.settingReducer.usersListArray.data;
      let userListForVisibility = [];
      usersListArray.map((data, i) => {
        userListForVisibility.push({ id: data.id, name: data.name })
      })
      this.setState({ usersListArray: usersListArray, userListForVisibility: userListForVisibility })
      var companyOwnerKey = this.findWithAttr(usersListArray, 'companyowner', "true");
      // this.setState({
      //   fieldsObj: {
      //     ...this.state.fieldsObj,
      //     ["owner"]: { fieldid: "4", values:usersListArray[companyOwnerKey]}
      //   }
      // })
    }


    if (props.settingReducer.systemTagsList.data && this.state.systemTagsList.length == 0) {
      const tagsData = props.settingReducer.systemTagsList.data;
      this.setState({ systemTagsList: tagsData })
    }

    if (props.settingReducer.companyCurrencyData.data != null && this.state.companyCurrencyData.length == 0) {
      var cData = props.settingReducer.companyCurrencyData.data;
      this.setState({ companyCurrencyData: cData[0] });
    }

    if (props.projectReducer.projectFieldData.data && props.projectReducer.projectFieldData.is_loaded) {
      let projectFieldData = props.projectReducer.projectFieldData.data
      var key1 = this.findWithAttr(projectFieldData, 'id', "67");
      if (key1 !== -1) {
        this.setState({
          oldteamvisible: projectFieldData[key1].projectdetail[0].project.teamvisible !== null && projectFieldData[key1].projectdetail[0].project.teamvisible,
          teamvisible: projectFieldData[key1].projectdetail[0].project.teamvisible !== null && projectFieldData[key1].projectdetail[0].project.teamvisible,
        })
      }
      this.setState({
        fieldsObj: {},
      }, () => {
        let fieldsObj = this.state.fieldsObj;
        this.setState({ projectFieldData: projectFieldData })
        let country = "";
        let selectedTeamList = [];
        let selectedUserList = [];

        const projectDataForEdit = props.projectReducer.projectFieldData.data

        var promise = new Promise((resolve, reject) => {
          projectDataForEdit.map((v, i) => {
            if (v.fieldtype.id == '1' && v.id == '1') {//for first/last name          
              if (v.projectdetail.length > 0) {

                var data = {
                  fieldid: v.id,
                  projectvalue: v.projectdetail[0].fieldvalue
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }

              }
            }
            else if (v.fieldtype.id == '1' && v.id == '69') {//for owner field        
              if (v.projectdetail.length > 0 && v.projectdetail[0].values.length && v.projectdetail[0].values[0].id) {
                var companyOwnerKey = this.findWithAttr(this.state.usersListArray, 'id', v.projectdetail[0].values[0].id);
                if (companyOwnerKey !== -1) {
                  var ownerData = {
                    fieldid: v.id,
                    values: this.state.usersListArray[companyOwnerKey]
                  }
                  fieldsObj = {
                    ...fieldsObj,
                    [v.fieldkey]: ownerData,
                  }
                }
              }
            } else if (v.fieldtype.id == '1' && v.fieldkey == 'tags') {//for tag input
              var tagArray = []
              if (v.projectdetail.length > 0 && v.projectdetail[0].values.length > 0) {
                v.projectdetail[0].values.map((v, i) => {
                  tagArray.push(v.value);
                })
              }
              var data = {
                fieldid: v.id,
                values: tagArray
              }
              fieldsObj = {
                ...fieldsObj,
                [v.fieldkey]: data,
              }
            } else if (v.fieldtype.id == '1' && v.id == '68') {//related field
              if (v.projectdetail.length > 0 && v.projectdetail[0].values.length && v.projectdetail[0].values[0].id) {
                //var relatedKey = this.findWithAttr(this.state.relatedListArray, 'id',v.projectdetail[0].values[0].id);
                var selectedObj = this.state.relatedListArray.filter(function (element) {
                  if (element.id == v.projectdetail[0].values[0].id && element.type == v.projectdetail[0].values[0].type) {
                    return element
                  }
                });
                if (selectedObj.length > 0) {
                  var relatedData = {
                    fieldid: v.id,
                    //values: this.state.relatedListArray[relatedKey]
                    values: selectedObj[0]
                  }
                  fieldsObj = {
                    ...fieldsObj,
                    [v.fieldkey]: relatedData,
                  }
                }
              }
            } else if (v.fieldtype.id == '1' || v.fieldtype.id == '6' || v.fieldtype.id == '10' || v.fieldtype.id == '7' || v.fieldtype.id == '9') {//other textfield and number input
              if (v.projectdetail.length > 0 && v.projectdetail.length > 0) {
                var data = {
                  fieldid: v.id,
                  projectvalue: v.projectdetail[0].fieldvalue
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }
              }
            }

            if (v.fieldtype.id == '2') {//TextArea
              if (v.projectdetail.length > 0) {
                var data = {
                  fieldid: v.id,
                  projectvalue: v.projectdetail[0].fieldvalue
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }
              }
            }

            if (v.fieldtype.id == "3") {//checkboxes
              if (v.projectdetail.length > 0) {
                var data = {
                  fieldid: v.id,
                  projectvalue: v.projectdetail[0].fieldvalue
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }
              }
            }
            //if(this.state.fieldsList && this.state.fieldsList.length >0){
            if (v.fieldtype.id === '4') {//dropdown
              if (v.id === "70" || v.id === "72" || v.id === "75") {
                if (v.projectdetail.length > 0 && v.projectdetail[0].values.length > 0) {
                  var optId = this.findWithAttr(v.options, 'id', v.projectdetail[0].values[0].id);
                  fieldsObj = {
                    ...fieldsObj,
                    [v.fieldkey]: { fieldid: v.id, values: v.options[optId] },
                  }
                  if (v.id === "72") {
                    if (v.projectdetail[0].values[0].id === 2) {
                      let filteredselectedTeamList = this.state.selectedTeamList.length > 0 ? this.state.selectedTeamList.filter(el => Object.keys(el).length) : []
                      if (v.projectdetail[0].values[0].visiblelist && filteredselectedTeamList !== v.projectdetail[0].values[0].visiblelist) {
                        selectedTeamList = v.projectdetail[0].values[0].visiblelist
                      }
                    } else if (v.projectdetail[0].values[0].id === 3) {
                      let filteredselectedUserList = this.state.selectedUserList.length > 0 ? this.state.selectedUserList.filter(el => Object.keys(el).length) : []
                      if (v.projectdetail[0].values[0].visiblelist && filteredselectedUserList !== v.projectdetail[0].values[0].visiblelist) {
                        selectedUserList = v.projectdetail[0].values[0].visiblelist
                      }
                    }
                  }
                }
              } else {
                if (v.projectdetail.length > 0 && v.projectdetail[0].values.length > 0) {
                  var optId = this.findWithAttr(v.options, 'id', v.projectdetail[0].values[0].id);
                  fieldsObj = {
                    ...fieldsObj,
                    [v.fieldkey]: { fieldid: v.id, values: v.options[optId] },
                  }
                }
              }
            }
            if (v.fieldtype.id === '5') {//multi dropdown
              if (v.projectdetail.length > 0 && v.projectdetail[0].values.length > 0) {
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: { fieldid: v.id, values: v.projectdetail[0].values },
                }
              }
            }
            if (v.fieldtype.id === '8') {//datepicker
              if (v.projectdetail.length > 0 && v.projectdetail[0].fieldvalue != "") {
                //var dt = moment.unix(v.projectdetail[0].fieldvalue).format(this.state.userSettings.dateformat).toString()
                var dt = new Date(moment(v.projectdetail[0].fieldvalue).format('YYYY-MM-DD'))

                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: { fieldid: v.id, values: dt },
                }
              } else if (v.projectdetail.length > 0 && v.projectdetail[0].fieldvalue == "") {
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: { fieldid: v.id, values: null },
                }
              }
            }
            //}
          })

          this.setState({
            fieldsObj,
            country,
            isUpdate: false,
            isAnyValueChange: false,
            oldSelctedTeamList: [...selectedTeamList, {}],
            oldSlectedUserList: [...selectedUserList, {}],
            selectedTeamList: [...selectedTeamList, {}],
            selectedUserList: [...selectedUserList, {}],

          }, () => {
            resolve(true)
          })
        });

        promise.then(result => {
          props.projectReducer.projectFieldData.is_loaded = false;
          this.setState({
            isAnyValueChange: false
          }, () => {
          })
        });
      })



    }
  }

  addNewField = (key, option) => {
    let data = this.state.fieldsObj[key];
    data.values.push({
      srno: (data.values.length + 1).toString(),
      id: option.id,
      value: ""
    })

    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [key]: data
      }
    })
  }

  handleAddMoreField = (name, key, index, value) => {
    let data = this.state.fieldsObj[key];
    data.values[index][name] = value;
    var keyName = key;
    var keyVal = data;
    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [key]: data
      },
      isAnyValueChange: true
    }, () => {
      // setTimeout(() => {
      //   //dont upadte lead if first name is empty
      //   if(this.state.fieldsObj['name'] && this.state.fieldsObj['name'].projectvalue == ""){
      //     return false
      //   }
      //   //dont allow to update if value length 1 and value is blank
      //   if(keyVal.values.length == 1 && keyVal.values[0].value == ""){
      //     return false
      //   }
      //   if (this.state.fieldsObj[keyName] == keyVal) {
      //     this.handleFormSubmit()
      //   }
      // }, 2500);
    })
  }

  handleNormalFields = (event) => {
    var keyName = event.target.name;
    var keyVal = event.target.value;
    this.setState({
      [event.target.name]: event.target.value,
      isAnyValueChange: true
    }, () => {
      //dont upadte lead if first name is empty
      // if(this.state.fieldsObj['name'] && this.state.fieldsObj['name'].projectvalue == ""){
      //   return false
      // }
      // setTimeout(() => {
      //   if (this.state[keyName] == keyVal) {
      //     this.handleFormSubmit()
      //   }
      // }, 1500);
    })
  };

  handleCountryChange = (event, values) => {
    this.setState({
      country: values,
      isAnyValueChange: true
    }, () => {
      //dont upadte lead if first name is empty
      if (this.state.fieldsObj['name'] && this.state.fieldsObj['name'].projectvalue == "") {
        return false
      }
      setTimeout(() => {
        if (this.state.country == values) {
          this.handleFormSubmit()
        }
      }, 500);
    })
  }

  focusInValue = () => {
    this.setState({
      focusValue: true,
    }, () => this.forceUpdate())
  };


  currencyDropdownClose = () => {
    this.setState({ focusValue: false })
  };

  handleChange = (val, name, isSubmit) => {
    if (val.fieldid === "72") {
      this.setState({ isEditVisibility: true }, () => this.forceUpdate())
      if (val.values.id === 2) {
        this.setState({ selectedTeamList: [{}] }, () => this.forceUpdate())
      } else if (val.values.id === 3) {
        this.setState({ selectedUserList: [{}] }, () => this.forceUpdate())
      }
    }
    var key = this.findWithAttr(this.state.fieldsList, 'id', val.fieldid);
    if (key != -1 && this.state.fieldsList[key].fieldtype.id != "4" && this.state.fieldsList[key].fieldtype.id != "8") {
      if (this[name]) {
        this[name].validate(val.value);
      }
    }
    if (val.fieldid === "69") {
      if ((this.state.viewoption === 2 || this.state.viewoption === 3) && val.values === null) {
        this.setState({
          teamVisibilityLabel: "0 users",
          teamUser: []
        }, () => this.forceUpdate())
      } else {
        this.setState({
          fieldsObj: {
            ...this.state.fieldsObj,
            [name]: val,
          }
        }, () => this.getUserPermission())
      }
    }
    this.setState({
      fieldsObj: {
        ...this.state.fieldsObj,
        [name]: val
      },
      isAnyValueChange: true,
      updatedfieldid: parseFloat(val.fieldid),
    }, () => {

      if (!isSubmit) {
        return false
      }
      // if(name === "name" && val.projectvalue ===""){
      //   this.setState({ fnameError: true })
      //   return false
      // }else{
      //   this.setState({ fnameError: false })
      // }
      // //dont upadte lead if first name is empty
      // if(this.state.fieldsObj['name'].projectvalue == ""){
      //   return false
      // }
      setTimeout(() => {
        if (this.state.fieldsObj[name] == val) {
          this.handleFormSubmit()
        }
      }, 500);
    })
  };

  focusOutSubmitForm = (event, fieldId, ref) => {
    console.log("this[ref]", this[ref]);
    this.setState({ updatedfieldid: parseFloat(fieldId) })
    if (this[ref].props && this[ref].props.id == "tagInputId1") {
      setTimeout(() => {
        document.getElementById("tagInputId1").value = "";
      }, 10)
    }
    this[ref].validate(event.target.value);
    setTimeout(() => {
      if (this.state.isAnyValueChange) {
        this.handleFormSubmit()
      }
    }, 0);
  }

  handleFormSubmit = () => {
    this.updateProjectForm.isFormValid(false).then(isValid => {
      this.setState({
        isAnyValueChange: false
      }, () => this.forceUpdate())

      if (isValid) {
        let logData = [];
        let submitData = JSON.parse(JSON.stringify(this.state.fieldsObj));
        submitData = Object.values(submitData).map((row) => {
          if (row.fieldid == "68") {//realated  field
            if (row.values == null) {
              return false
            }
            row.values = [{ srno: "1", id: row.values.id, value: row.values.name, type: row.values.type }]
          }
          if (row.fieldid === "72" || row.fieldid === "70" || row.fieldid === "75") {
            if (row.fieldid === "72") {
              let filterVisibleList = [];
              if (row.values.id === 2) {
                filterVisibleList = this.state.selectedTeamList.length > 0 ? this.state.selectedTeamList.filter(el => Object.keys(el).length) : []
              } else if (row.values.id === 3) {
                filterVisibleList = this.state.selectedUserList.length > 0 ? this.state.selectedUserList.filter(el => Object.keys(el).length) : []
              }
              row.values = [
                {
                  srno: "1",
                  id: row.values.id,
                  value: row.values.name,
                  visiblelist: filterVisibleList
                }
              ];
            } else {
              row.values = [{ srno: "1", id: row.values.id, value: row.values.name }]
            }
          }
          var key = this.findWithAttr(this.state.fieldsList, 'id', row.fieldid);
          if (this.state.fieldsList[key].fieldtype.id == "4" && this.state.fieldsList[key].is_default == false) {
            row.values = [{ srno: "1", id: row.values.id, value: row.values.optionvalue }]
          }
          if (this.state.fieldsList[key].fieldtype.id == "5" && this.state.fieldsList[key].is_default == false) {
            var ar = [];
            if (row.values.length > 0) {
              row.values.map((val, i) => {
                var optName;
                if (val.optionvalue) {
                  optName = val.optionvalue
                } else {
                  optName = val.value
                }
                ar.push({ srno: (i + 1).toString(), id: val.id, value: optName })
              })
            }
            row.values = ar
          }

          //validating date fiels
          if (this.state.fieldsList[key].fieldtype.id == "8") {//date field
            if (row.values == null) {
              row.projectvalue = "";
              delete row.values;
            } else if (row.values == "Invalid Date") {
              return false
            } else {
              var dt = moment(row.values).format('YYYY-MM-DD')
              // // row.projectvalue = moment(row.values).format("X");
              // let date_test = moment(row.values).format('YYYY-MM-DD HH:mm');
              // var logdate = moment.tz(date_test, this.state.userSettings.timezone).format();
              row.projectvalue = dt;
              delete row.values;
            }
          }

          if (row.fieldid == "74") {
            if (row.values.length > 0) {
              var objArr = [];
              row.values.map((val, k) => {
                var foundKey = this.findWithAttr(this.state.systemTagsList, 'tagname', val);
                var tagId = "0";
                if (foundKey !== -1) {
                  tagId = this.state.systemTagsList[foundKey].id
                }
                objArr.push({ "srno": (k + 1).toString(), value: val.toLowerCase(), id: tagId, isdeleted: "0" })
              })

              //add tags from props value which is removed   with id deleted id "1"
              var tagsKeyFromProps = this.findWithAttr(this.props.projectReducer.projectFieldData.data, 'id', "74");
              if (this.props.projectReducer.projectFieldData.data[tagsKeyFromProps].projectdetail.length > 0) {
                var oldTagsValues = this.props.projectReducer.projectFieldData.data[tagsKeyFromProps].projectdetail[0].values;
                if (oldTagsValues.length > 0) {
                  oldTagsValues.map((v, k) => {
                    var checkKey = this.findWithAttr(objArr, 'value', v.value);
                    if (checkKey === -1) {
                      v.isdeleted = "1";
                      v.srno = (objArr.length + 1).toString();
                      objArr.push(v);
                    }
                  })
                }
              }

              row.values = objArr
              if (this.props.projectReducer.projectFieldData.data[tagsKeyFromProps].projectdetail.length > 0) {
                this.props.projectReducer.projectFieldData.data[tagsKeyFromProps].projectdetail[0].values = objArr;
              }
            }
          }

          if (row.fieldid == "69") {//owner value set
            if (row.values) {
              row.values = [{ "srno": "1", id: row.values.id, value: row.values.email, name: row.values.name }];
            } else {
              row.values = [];
            }
            delete row.projectvalue;
          }
          return row;
        })

        //adding  projectdetailid for update object from this.state.projectFieldData
        submitData = submitData.map((row, i) => {
          var key = this.findWithAttr(this.state.projectFieldData, 'id', row.fieldid);
          if (key !== -1) {
            if (this.state.projectFieldData[key].projectdetail.length > 0) {
              row.projectdetailid = this.state.projectFieldData[key].projectdetail[0].id;
            } else {
              row.projectdetailid = ""
            }
          }


          return row
        })

        //filter null and undefined object 
        submitData = submitData.filter(function (element) {
          return element !== undefined;
        });

        //not to submit if any field is false
        var allowToSubmit = true
        submitData.map((row) => {
          if (!row) {
            allowToSubmit = false
          }
        })
        console.log("submitData", submitData);
        //return

        if (this.state.project_id && allowToSubmit && this.state.crudPermission.isedit) {
          // update lead field data after update.... (For log)
          this.setState({ isUpdate: true, isEditVisibility: false });
          // -- ---------------

          let req = {
            projectdata: submitData,
            projectid: this.state.project_id,
            updatedfieldid: this.state.updatedfieldid,
            teamvisible: this.state.teamvisible,

          }
          this.props.updateProjectDetail(this.props.apolloClient.client, req);
        }
      }
    });
  };

  arrDifference = (arr1, arr2) => {
    var arr = [];
    arr1 = arr1.toString().split(',').map(Number);
    arr2 = arr2.toString().split(',').map(Number);
    // for array1
    for (var i in arr1) {
      if (arr2.indexOf(arr1[i]) === -1)
        arr.push(arr1[i]);
    }
    // for array2
    for (i in arr2) {
      if (arr1.indexOf(arr2[i]) === -1)
        arr.push(arr2[i]);
    }
    return arr.sort((x, y) => x - y);
  }

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] == value) {
        return i;
      }
    }
    return -1;
  }
  teamHandleChange = (index, value, name) => {
    let mianteam = this.state.teamList;
    let selectedTeamList = this.state.selectedTeamList
    let mianUsers = this.state.usersListArray;
    let selectedUserList = this.state.selectedUserList
    if (name === "team") {
      if (value !== null) {
        selectedTeamList[index] = value;
        if (mianteam.length + 1 > selectedTeamList.length) {
          selectedTeamList[index + 1] = {};
        }
      } else {
        selectedTeamList.splice(index, 1);
        if (selectedTeamList.length === 0) {
          selectedTeamList[0] = {};
        }
      }
    } else {
      if (value !== null) {
        selectedUserList[index] = { id: value.id, name: value.name }
        if (mianUsers.length + 1 > selectedUserList.length) {
          selectedUserList[index + 1] = {};
        }
      } else {
        selectedUserList.splice(index, 1);
        if (selectedUserList.length === 0) {
          selectedUserList[0] = {};
        }
      }
    }
    this.setState({
      selectedTeamList: selectedTeamList,
      selectedUserList: selectedUserList
    })
  }
  handleToggleEditVisibility = () => {
    this.setState({
      isEditVisibility: !this.state.isEditVisibility
    })
  }

  handleChangeVisibilityRadio = (value) => {
    if (value) {
      let customFields = [...this.state.fieldsList]
      var vkey = this.findWithAttr(customFields, "id", "72");
      var visibilitydefault = this.findWithAttr(
        customFields[vkey].options,
        "is_default",
        true
      );
      this.setState({
        selectedTeamList: [],
        updatedfieldid: 72,
        fieldsObj: {
          ...this.state.fieldsObj,
          ["visibility"]: {
            fieldid: "72",
            values: customFields[vkey].options[visibilitydefault],
          },
        },
      });
    }
    this.setState({
      teamvisible: value === "true" ? true : false
    })

  }
  handleVisibilitySave = () => {
    this.setState({
      updatedfieldid: 72
    }, () => this.handleFormSubmit())
  }
  hadleCancelvisibility = () => {
    // this.props.getProjectField(this.props.apolloClient.client, parseInt(this.props.project_id));
    let fieldsObj = this.state.fieldsObj
    let customFields = [...this.state.fieldsList]
    var vkey = this.findWithAttr(customFields, "id", "72");
    var visibilitydefault = this.findWithAttr(customFields[vkey].options, "is_default", true);

    let projectFieldData = [...this.state.projectFieldData]
    var key1 = this.findWithAttr(projectFieldData, 'id', "72");
    if (projectFieldData[key1].projectdetail[0].values[0]) { //if visibilty not efault 
      let mainoldValue = projectFieldData[key1].projectdetail[0].values[0];
      var opn = this.findWithAttr(projectFieldData[key1].options, 'id', mainoldValue.id);
      fieldsObj = {
        ...fieldsObj,
        ["visibility"]: {
          fieldid: "72",
          values: projectFieldData[key1].options[opn],
        },
      }
    } else {//set  visibilty  default 
      fieldsObj = {
        ...fieldsObj,
        ["visibility"]: {
          fieldid: "72",
          values: customFields[vkey].options[visibilitydefault],
        },
      }
    }
    this.setState({
      selectedTeamList: this.state.oldSelctedTeamList,
      selectedUserList: this.state.oldSlectedUserList,
      fieldsObj: fieldsObj,
      teamvisible: this.state.oldteamvisible,

    });
    this.handleToggleEditVisibility()
  }
  render() {
    return (
      <div className="v_scroll position-relative label-size-14 pr-16">
        <ValidatorForm ref={updateProjectForm => this.updateProjectForm = updateProjectForm} noValidate instantValidate={false} onSubmit={this.handleFormSubmit}>
          <Grid container spacing={2}>
            {CreateFieldsDynamicEdit(this)}
          </Grid>
        </ValidatorForm>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
  projectReducer: state.projectReducer,
  authReducer: state.authReducer

});

const mapDispatchToProps = dispatch => {
  return {
    getTeam: (client) => dispatch(actionCreators.getTeam(client)),
    getProjectLogListNew: (client, projectid) => dispatch(actionCreators.getProjectLogListNew(client, projectid)),
    customFieldList: (client, request) => { dispatch(actionCreators.customFieldList(client, request)) },
    updateProjectDetail: (client, request) => { dispatch(actionCreators.updateProjectDetail(client, request)) },
    getCountryList: (client) => { dispatch(actionCreators.getCountryList(client)) },
    clearNotification: () => dispatch(actionCreators.clearNotification()),
    getCompanyCurrency: (client) => dispatch(actionCreators.getCompanyCurrency(client)),
    getTagsList: (client) => dispatch(actionCreators.getTagsList(client)),
    getProjectField: (client, projectId) => dispatch(actionCreators.getProjectField(client, projectId)),
    getUserList: (client) => dispatch(actionCreators.getUserList(client)),
    getUserSetting: (client, data) => dispatch(actionCreators.getUserSetting(client, data)),
    getRelatedList: (client) => dispatch(actionCreators.getRelatedList(client)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PojectProfileDetails);