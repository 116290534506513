import React, { Component } from "react";

import ProjectTableComponent from './ProjectTableComponent';
import ProjectCreationRule from './ProjectCreationRule'
import { Icon, Switch, FormControlLabel, IconButton, withStyles, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import FontAwesome from 'react-fontawesome'
import CreateNewFieldProjectDialog from "./CreateNewFieldProjectDialog";

import * as actions from '../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.5rem",
    }
}))(Icon);

class SettingProjects extends Component {

    constructor(props) {
        super(props);

        this.state = {
            projectdata: [],
            projectFieldIds: [],
            projectOrderIds: [],
            activeprojectdatas: [],
            inactiveprojectdatas: [],
            shouldOpenCreateFieldProjectDialog: false,

        };
    }

    handleChange = (event) => {
        let val = {
            typeId: 5,
            fieldstatus: event.target.checked,
            generatedfieldid: event.target.value
        }

        this.props.updateCustomFieldStatus(this.props.apolloClient.client, val);
    }

    componentDidMount() {
        let val = { typeId: 5 }
        this.props.customFieldList(this.props.apolloClient.client, val);
        this.props.customFieldTypeList(this.props.apolloClient.client);
    }

    componentWillReceiveProps(props) {

        if (props.settingReducer.projectCustomFieldList.data && props.settingReducer.customFieldTypeList.data) {
            const customFields = props.settingReducer.projectCustomFieldList.data;

            let activeprojectdatas = [];
            let inactiveprojectdatas = [];
            let projectFieldIds = [];
            let projectOrderIds = [];

            customFields.map((fieldData, index) => {
                if (fieldData.fieldstatus === true) {
                    projectFieldIds.push(parseFloat(fieldData.id));
                    projectOrderIds.push(parseFloat(fieldData.orderno));

                    let fieldlicon = (fieldData.fieldicon === 'fa-dollar-sign') ? 'fa-usd' : fieldData.fieldicon;

                    activeprojectdatas.push({
                        drag: <Link className="drag-handle" projectOrderIds={projectOrderIds} to="#"><Icon>drag_handle</Icon></Link>,

                        key: fieldData.id,
                        name: (
                            <div>
                                <FontAwesome className={'crm-fontawsome-icon ' + fieldlicon} ></FontAwesome>
                                <label className="crm-custom-field-label">{fieldData.fieldlabel}</label>
                            </div>
                        ),
                        fieldType: fieldData.fieldtype.name,
                        creationRule: (fieldData.recordcreationrules_editable) ?

                            <ProjectCreationRule dropName="create" fieldId={index} options={fieldData.recordcreationrulesvalue} disabled={false} defaultChecked={fieldData.recordcreationdefaultvalue} />
                            :
                            <ProjectCreationRule dropName="create" fieldId={index} options={fieldData.recordcreationrulesvalue} disabled={true} defaultChecked={fieldData.recordcreationdefaultvalue} />,
                        editRule: (fieldData.recordeditrules_editable) ?
                            <ProjectCreationRule dropName="edit" fieldId={index} options={fieldData.recordeditrulesvalue} disabled={false} defaultChecked={fieldData.recordeditdefaultvalue} />
                            :
                            <ProjectCreationRule dropName="edit" fieldId={index} options={fieldData.recordeditrulesvalue} disabled={true} defaultChecked={fieldData.recordeditdefaultvalue} />,

                        action: <React.Fragment>

                            <FormControlLabel
                                value={fieldData.generatedfieldid}
                                control={(fieldData.fieldstatus) ? <Switch onChange={this.handleChange} color="primary" defaultChecked disabled={!fieldData.isdiactivated} /> : <Switch onChange={this.handleChange} color="primary" disabled={!fieldData.isdiactivated} />}
                                labelPlacement="start"
                                className="ml-0 mr-10"
                            />
                        </React.Fragment>
                    })
                }

                if (fieldData.fieldstatus === false) {

                    inactiveprojectdatas.push({

                        key: fieldData.id,
                        name: fieldData.fieldlabel,
                        action: <React.Fragment>
                            <FormControlLabel
                                value={fieldData.generatedfieldid}
                                control={(fieldData.fieldstatus) ? <Switch onChange={this.handleChange} color="primary" defaultChecked disabled={!fieldData.isdiactivated} /> : <Switch onChange={this.handleChange} color="primary" disabled={!fieldData.isdiactivated} />}
                                labelPlacement="start"
                                className="ml-0 mr-10"
                            />
                        </React.Fragment>
                    })
                }
            })


            this.setState({ activeprojectdatas: activeprojectdatas, inactiveprojectdatas: inactiveprojectdatas, projectFieldIds: projectFieldIds, projectOrderIds: projectOrderIds }, () => this.forceUpdate());
        }
    }
    closePopUp = () => {
        this.setState({ shouldOpenCreateFieldProjectDialog: false });
    }
    updateFieldsOrder = (fromIndex, toIndex) => {

        let projectFieldIds = [];

        const projectdata = this.state.activeprojectdatas;
        const item = projectdata.splice(fromIndex, 1)[0];

        projectdata.splice(toIndex, 0, item);

        this.setState({
            projectdata
        })
        const projectOrderIds = this.state.projectOrderIds;
        projectdata.map((value, index) => {
            projectFieldIds.push(parseFloat(value.key));

        });

        let val1 = {
            typeId: 5,

            orderno: projectOrderIds,
            fieldid: projectFieldIds
        }
        this.props.updateCustomFieldOrder(this.props.apolloClient.client, val1);
    }
    handleProjectDialogClose = () => {
        this.setState({ shouldOpenCreateFieldProjectDialog: false });
    };
    render() {
        let { shouldOpenCreateFieldProjectDialog } = this.state;
        return (
            <>
                <div className="d-flex justify-content-end mb-16">
                    <Button className="btn_Primary" variant="contained" color="primary" onClick={() => this.setState({ shouldOpenCreateFieldProjectDialog: true })} size="small">Add Field</Button>
                    {shouldOpenCreateFieldProjectDialog && (
                        <CreateNewFieldProjectDialog
                            handleClose={this.handleProjectDialogClose}
                            open={shouldOpenCreateFieldProjectDialog}

                            allData={this}
                        />
                    )}
                </div>

                <ProjectTableComponent activeprojectdata={this.state.activeprojectdatas} inActiveprojectdata={this.state.inactiveprojectdatas} allData={this} />

            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        settingReducer: state.settingReducer,
        apolloClient: state.apolloClient
    };
};
const mapDispatchToProps = dispatch => {
    return {
        customFieldList: (client, request) => { dispatch(actions.customFieldList(client, request)) },
        customFieldTypeList: (client) => { dispatch(actions.customFieldTypeList(client)) },
        updateCustomFieldStatus: (client, request) => { dispatch(actions.updateCustomFieldStatus(client, request)) },
        updateCustomFieldOrder: (client, request) => { dispatch(actions.updateCustomFieldOrder(client, request)) },

    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(SettingProjects);