import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Checkbox, List, ListItem, IconButton, Icon, Tooltip, Grid, withStyles, Menu, InputLabel, Typography, MenuItem, Avatar, Fab, Button, Tabs, Tab, Box, Dialog, DialogTitle, DialogActions } from "@material-ui/core";
import DropdownDefault from "../Layout/DropdownDefault";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import ProfileDetails from "./ProfileDetails";
import ProfileRight from "./ProfileRight";
import moment from 'moment';
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import CreatelogDialog from "../CommonLogUI/CreatelogDialog";
import FontAwesome from "react-fontawesome";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import SystemLog from "../CommonLogUI/SystemLog"
import DateFnsUtils from "@date-io/date-fns";
import CreateNewTaskDialog from "../Tasks/CreateNewTaskDialog";
import _ from 'lodash'
import AddComment from "../CommonLogUI/AddComment";
import EditComment from "../CommonLogUI/EditComment";
import forEach from 'lodash/forEach';
import InboxComposeDialog from "../CommonComposeMail/InboxComposeDialog";
import NoteLog from "../CommonLogUI/NoteLog";
import CallLogs from "../CommonLogUI/CallLogs";
import RingCentralCallLogs from "../CommonLogUI/RingCentralCallLogs";
import FilterLogoCommon from "../CommonLogUI/FilterLogoCommon";
import EmailTypeOne from "../CommonLogUI/EmailTypeOne";
import EmailTypeTwo from "../CommonLogUI/EmailTypeTwo";
import EmailTypeFour from "../CommonLogUI/EmailTypeFour";
import ViewHtmlDialog from "../CommonLogUI/ViewHtmlDialog";

const IconButtonWhite = withStyles(theme => ({
  root: {
    // color: theme.palette.getContrastText(purple[500]),
    backgroundColor: "transparent",
    color: "#F2BF58",
    padding: "5px"
  }
}))(IconButton);

const IconSmall = withStyles(() => ({
  root: {
    fontSize: "1.3rem",
  }
}))(Icon);

const IconSmaller = withStyles(() => ({
  root: {
    fontSize: "1.0rem",
    color: 'rgba(0, 0, 0, 0.6)'
  }
}))(Icon);

const TooltipNew = withStyles((theme) => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={2}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}



class FiltersMenu extends Component {
  state = {
    commentid: '',
    Commentoptions: '',
    showCommentoptions: false,
    activities: [],
    typeArray: [],
    activityArray: [],
    activityflag: true,
  };

  componentDidMount() {

    let typeArray1 = this.state.typeArray;
    let activityArray1 = this.state.activityArray;

    typeArray1 = [1, 2, 3, 4, 8, 9, 16, 17, 18, 20, 21, 5, 100];

    this.setState({ typeArray: typeArray1 });
    this.props.thisData.setState({ logtypeid: typeArray1 });
  }
  componentWillReceiveProps(props) {

    if (props.proVar && props.proVar.length > 0) {
      this.setState({ activities: props.proVar });
      if (this.state.activityflag) {
        let activityArray1 = this.state.activityArray;
        let activities = props.proVar;

        if (activities && activities.length > 0) {
          activities.map((activity) => {
            if (activityArray1.indexOf(parseFloat(activity.id)) == -1) {
              activityArray1.push(parseFloat(activity.id));
            }
          })
          this.setState({ activityArray: activityArray1, activityflag: false });
          props.thisData.setState({ activitypeid: activityArray1 });
        }
      }
    }
  }

  handleClick = (event) => {
    this.setState({ Commentoptions: event.target, showCommentoptions: true });
  }

  handleCloseOptions = (event) => {
    this.setState({ Commentoptions: null, showCommentoptions: false });
  }

  handleMenuClick = (e, value, type) => {
    let typeArray1 = this.state.typeArray;
    let activityArray1 = this.state.activityArray;
    var promise = new Promise((resolve, reject) => {
      if (e.target.checked == true) {
        if (type == 0) {

          if (activityArray1.indexOf(value) == -1)
            activityArray1.push(value);

        } else if (type == 1000) {

          typeArray1 = [1, 2, 3, 4, 8, 9, 16, 17, 18, 20, 21, 5, 100];

          let activities = this.state.activities;

          if (activities && activities.length > 0) {
            activities.map((activity) => {
              if (activityArray1.indexOf(parseFloat(activity.id)) == -1) {
                activityArray1.push(parseFloat(activity.id));
              }
            })
          }

        } else if (type == 1001) {

          let pusharr = [1, 2, 3, 4, 8, 9, 16, 17, 18, 20, 21];
          pusharr.map((arr) => {
            if (typeArray1.indexOf(arr) == -1) {
              typeArray1.push(arr);
            }
          })

        }
        else {

          if (typeArray1.indexOf(type) == -1)
            typeArray1.push(type);

          if (activityArray1.indexOf(3) == -1)
            activityArray1.push(3);

        }
      }
      if (e.target.checked == false) {
        if (type == 0) {

          let key1 = activityArray1.indexOf(value);
          activityArray1.splice(key1, 1);

        } else if (type == 1000) {

          typeArray1 = [];
          activityArray1 = [];

        } else if (type == 1001) {

          let pullarr = [1, 2, 3, 4, 8, 9, 16, 17, 18, 20, 21];
          pullarr.map((arr) => {
            if (typeArray1.indexOf(arr) != -1) {
              let ind = typeArray1.indexOf(arr);
              typeArray1.splice(ind, 1);
            }
          })

        } else {
          let key = typeArray1.indexOf(type);
          typeArray1.splice(key, 1);

          let key1 = activityArray1.indexOf(3);
          activityArray1.splice(key1, 1);
        }
      }
      resolve(1)
    })

    promise.then(result => {
      this.setState({ typeArray: typeArray1, activityArray: activityArray1 });
      this.props.thisData.setState({ logtypeid: typeArray1, activitypeid: activityArray1 });
      this.props.thisData.handlefiltermain(typeArray1, activityArray1);
    });
  }

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  render() {
    let { activities, activityArray, typeArray } = this.state;
    let def_typearr = [1, 2, 3, 4, 8, 9, 16, 17, 18, 20, 21, 5, 100];
    let flag_all = true;

    if (activities && activities.length > 0) {
      activities.map((act) => {
        if (activityArray.indexOf(parseFloat(act.id)) == -1)
          flag_all = false;
      })
    } else {
      flag_all = false;
    }

    def_typearr.map((def) => {
      if (typeArray.indexOf(def) == -1)
        flag_all = false;
    })

    return (
      <>
        <Grid container justify="space-between">
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <List className="p-0 pull-right mr-20 log_filter_list">
              <ListItem
                aria-label="More"
                aria-owns={this.state.showCommentoptions ? "filters_menu" : undefined}
                aria-haspopup="true"
                onClick={this.handleClick}
                className="font-size-14 cursor_poiter  pr-0"
              >
                Filters <Icon>arrow_drop_down</Icon>
              </ListItem>
              <Menu
                className=""
                id="long-menu"
                anchorEl={this.state.Commentoptions}
                open={this.state.showCommentoptions}
                onClose={this.handleCloseOptions}
                PaperProps={{
                  style: {
                    width: 200
                  }
                }}
              >
                <MenuItem className="filter_items">
                  <Icon className="mr-16 ">storage</Icon><TooltipNew title='All Activities'>
                    <div className="text_ellipsis " style={{ maxWidth: '72px', fontSize: '14px', margin: '3px 0px 0px 0px', fontWeight: '400' }}>All Activities</div></TooltipNew>
                  <Checkbox color="primary"
                    disabled={false}
                    className={"crm_log_checkbox_filter checkB_color"}
                    name={'allActivities'}
                    id={'filterCheck'}
                    checked={flag_all}
                    onChange={(event, value) => this.handleMenuClick(event, 1000, 1000)}
                    value={this.state.filterCheck}
                    color="primary"
                  />
                </MenuItem>
                {(activities && activities.length > 0) && activities.map((activity) => (
                  parseFloat(activity.id) !== 3 && (
                    <MenuItem className="filter_items">
                      {activity.icon && activity.icon === "fa-paper-plane" && <FontAwesome className={activity.icon + " p-5 crm_activitytype_icon"} style={{ fontSize: '20px', padding: '5px 18px 5px 2px' }} />}
                      {activity.icon && activity.icon !== "fa-paper-plane" && <FontAwesome className={activity.icon + " mr-16"} style={{ fontSize: '15px', padding: '5px' }} />}
                      <TooltipNew title={activity.name}>
                        <div className="text_ellipsis " style={{ maxWidth: '72px', fontSize: '14px', margin: '3px 0px 0px 0px', fontWeight: '400' }}>{activity.name}</div></TooltipNew>
                      <Checkbox
                        className={"crm_log_checkbox_filter checkB_color"}
                        disabled={false}
                        name={activity.name}
                        id={'filterCheck'}
                        checked={(activityArray.indexOf(parseFloat(activity.id)) != -1) ? true : false}
                        onChange={(event, value) => this.handleMenuClick(event, parseFloat(activity.id), 0)}
                        value={this.state.filterCheck}
                        color="primary"
                      />
                    </MenuItem>)
                ))}
                <MenuItem className="filter_items">
                  <FontAwesome className={"fa-credit-card p-5 crm_activitytype_icon"} style={{ fontSize: '17px', padding: '5px 18px 5px 5px' }} />
                  <TooltipNew title='Emails'>
                    <div className="text_ellipsis " style={{ maxWidth: '72px', fontSize: '14px', margin: '3px 0px 0px 0px', fontWeight: '400' }}>Emails</div></TooltipNew>
                  <Checkbox
                    disabled={false}
                    className={"crm_log_checkbox_filter checkB_color"}
                    name={'email'}
                    id={'filterCheck'}
                    checked={(typeArray.indexOf(100) != -1) ? true : false}
                    onChange={(event, value) => this.handleMenuClick(event, 100, 100)}
                    value={this.state.filterCheck}
                    color="primary"
                  />
                </MenuItem>
                <MenuItem className="filter_items">
                  <Icon className="mr-16 ">subject</Icon><TooltipNew title='Notes'>
                    <div className="text_ellipsis " style={{ maxWidth: '72px', fontSize: '14px', margin: '3px 0px 0px 0px', fontWeight: '400' }}>Notes</div></TooltipNew>
                  <Checkbox color="primary"
                    disabled={false}
                    className={"crm_log_checkbox_filter checkB_color"}
                    name={"note"}
                    id={'filterCheck'}
                    checked={(typeArray.indexOf(5) != -1) ? true : false}
                    onChange={(event, value) => this.handleMenuClick(event, 5, 5)}
                    value={this.state.filterCheck}
                    color="primary"
                  />
                </MenuItem>
                <MenuItem className="filter_items">
                  <Icon className="mr-16 ">storage</Icon> <TooltipNew title='System Updates'>
                    <div className="text_ellipsis " style={{ maxWidth: '72px', fontSize: '14px', margin: '3px 0px 0px 0px', fontWeight: '400' }}>System Updates</div></TooltipNew>
                  <Checkbox color="primary"
                    disabled={false}
                    className={"crm_log_checkbox_filter checkB_color"}
                    name={'sysupdate'}
                    id={'filterCheck'}
                    checked={(typeArray.indexOf(1) != -1) ? true : false}
                    onChange={(event, value) => this.handleMenuClick(event, 1001, 1001)}
                    value={this.state.filterCheck}
                    color="primary"
                  />
                </MenuItem>
              </Menu>
            </List>
          </Grid>
        </Grid>
      </>
    )
  }
}

class OpportunityProfileFrame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logtypeid: [],
      activitypeid: [],
      activities: [],
      isOpenedCommentIds: [],

      isOpenedEmail: false,
      isOpenedReplayEmail: false,
      isEditCommentLog: false,
      Commentoptions: '',
      showCommentoptions: false,
      logvaluetype: '',
      logtype: 0,
      selectedDate: new Date(),
      textvalue: '',
      shouldOpenCreateLogDialog: false,
      FullCreateOpportunityDialog: false,
      minimizeOpportunityDialog: false,
      isDeleteLogConfirmDialoOpen: false,
      isEditLog: false,
      logid: '',
      TabValue: 0,
      allLogs: [],
      name: "",
      isDeleteConfirmDialoOpen: false,
      title: "",
      companyName: "",
      colorClass: '',
      interaction: 0,
      lastcontacted: "--",
      leadstatus: "",
      inactivedays: 0,
      dateformat: 'MM/DD/YYYY',
      timeZone: 'America/New_York',
      isfavorite: false,
      star_icon: 'star_border',
      fieldsObj: {},
      fieldsList: [],
      usersListArray: [],
      leadImage: "",
      countryListArray: [],
      systemTagsList: [],
      companyCurrencyData: [],
      userSettings: "",

      shouldOpenCreateTaskDialog: false,
      FullCreateTaskDialog: false,
      minimizeTaskDialog: false,
      newTaskData: {},
      userid: "",
      showSelector: false,
      userName: "",
      allSettings: "",
      isOpenedEmailIds: [],
      isOpenedReplayEmailLog: false,
      SyncStatus: false,
      SyncTime: '',
      SyncData: [],
      crudPermission: {
        iscreate: true,
        isdelete: true,
      }
    }
  }
  getUserPermission = () => {
    if (this.props.settingReducer.userPermissionDetails.data) {
      let udata = this.props.settingReducer.userPermissionDetails.data.data
      if (udata.visibilityaccess.length > 0 &&
        udata.teampermissionstatus === true &&
        this.props.authReducer.userRole.id === 3) {
        let selectedPipelineId = localStorage.getItem('selected_pipeline_id')
        let maindata = udata.visibilityaccess.find(function (e) { return e.piplineid === parseInt(selectedPipelineId) });
        this.setState({
          crudPermission: {
            iscreate: maindata.iscreate,
            isdelete: maindata.isdelete,
            isedit: maindata.isedit,
          }
        })
      }
    }
  }
  componentDidMount() {
    let val = { typeId: 4 }
    let userData = JSON.parse(localStorage.getItem('user_data_session'))
    this.setState({
      userid: userData.id
    })

    this.props.customFieldList(this.props.apolloClient.client, val);

    if (this.props.opportunities_id) {
      this.setState({
        opportunities_id: this.props.opportunities_id,
      }, () => this.forceUpdate());

      let mailid = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
      let logParams = { opportunitiesid: parseFloat(this.props.opportunities_id), activitypeid: [], logtypeid: [] }
      this.props.getOpportunitiesActivityLogList(this.props.apolloClient.client, logParams);
      this.props.getEmailSetting(this.props.apolloClient.client);
      this.props.getSyncTime(this.props.apolloClient.client);
    }

    if (this.props.phrase) {
      this.setState({ phrase: this.props.phrase });
    }
  }
  componentWillUnmount() {
    // emailLogEmail  
    this.props.opportunityReducer.opportunityFieldData = [];
    this.props.opportunityReducer.opportunityLogSidebarList = [];
  }
  componentWillReceiveProps(props) {
    this.getUserPermission()
    if (props.settingReducer.masterActivityTypesList) {
      this.setState({ activities: props.settingReducer.masterActivityTypesList.data });
    }

    if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
      let usersetting = props.settingReducer.userSettingArray.data;

      let format = usersetting.dateformat;
      let timezone = usersetting.timezone;
      this.setState({ dateformat: format, timeZone: timezone })
    }

    if (props.settingReducer.emailsettings.data) {
      const mian_data = props.settingReducer.emailsettings.data
      this.setState({ allSettings: mian_data, })
    }

    if (props.settingReducer.emailsettings.data) {
      const mian_data = props.settingReducer.emailsettings.data
      this.setState({
        allSettings: mian_data,
      })
    }

    if (props.settingReducer.getsyncTime && props.settingReducer.getsyncTime.data) {
      let data = props.settingReducer.getsyncTime.data;
      if (data !== this.state.SyncData) {
        let time = moment.unix(data.emailsynctime).format("YYYY-MM-DD HH:mm");
        let now = moment(time).fromNow();
        if (now !== this.state.SyncTime) {
          this.setState({ SyncData: data, SyncTime: now, SyncStatus: false });
        }
      }
    }

    if (props.opportunityReducer.opportunityLogSidebarList && props.opportunityReducer.opportunityLogSidebarList.data) {
      let logdata = [];
      let source = '';
      if (props.opportunityReducer.opportunityFieldData.data) {
        let fieldSource = props.opportunityReducer.opportunityFieldData.data;
        var sourcekey = this.findWithAttr(fieldSource, 'fieldkey', 'name');

        if (fieldSource[sourcekey].opportunitiesdetail && fieldSource[sourcekey].opportunitiesdetail[0].fieldvalue)
          source = fieldSource[sourcekey].opportunitiesdetail[0].fieldvalue;
      }

      let pined = props.opportunityReducer.opportunityLogSidebarList.data.pined;
      let today = props.opportunityReducer.opportunityLogSidebarList.data.today;
      let yesterday = props.opportunityReducer.opportunityLogSidebarList.data.yesterday;
      let thisweek = props.opportunityReducer.opportunityLogSidebarList.data.thisweek;
      let lastweek = props.opportunityReducer.opportunityLogSidebarList.data.lastweek;
      let thismonth = props.opportunityReducer.opportunityLogSidebarList.data.thismonth;
      let lastmonth = props.opportunityReducer.opportunityLogSidebarList.data.lastmonth;
      let earlier = props.opportunityReducer.opportunityLogSidebarList.data.earlier;

      logdata.push(this.createLogArray(source, pined, 'Pinned'));
      logdata.push(this.createLogArray(source, today, 'Today'));
      logdata.push(this.createLogArray(source, yesterday, 'Yesterday'));
      logdata.push(this.createLogArray(source, thisweek, 'This week'));
      logdata.push(this.createLogArray(source, lastweek, 'Last week'));
      logdata.push(this.createLogArray(source, thismonth, 'This month'));
      logdata.push(this.createLogArray(source, lastmonth, 'Last month'));
      logdata.push(this.createLogArray(source, earlier, 'Earlier'));

      this.setState({ allLogs: logdata });
    }

    //storing user setting data in state
    if (props.settingReducer.userSettingArray.data && this.state.userSettings == "") {
      const settingData = props.settingReducer.userSettingArray.data;
      this.setState({
        userSettings: settingData,
      })
    }

    if (props.opportunities_id !== undefined) {
      this.setState({
        opportunities_id: props.opportunities_id,
      }, () => this.forceUpdate());


      if (props.opportunities_id != this.state.opportunities_id) {
        let mailid = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        let logParams = { opportunitiesid: parseFloat(props.opportunities_id), activitypeid: [], logtypeid: [] }
        this.props.getOpportunitiesActivityLogList(this.props.apolloClient.client, logParams);
      }
    }

    if (props.opportunityReducer.opportunityFieldData.data) {
      var fielddata = props.opportunityReducer.opportunityFieldData.data;

      let star_ic = fielddata[0].leadfavourite ? 'star' : 'star_border';
      this.setState({ star_icon: star_ic, isfavorite: fielddata[0].leadfavourite });

      let namekey1 = this.findWithAttr(fielddata, 'fieldkey', "name");
      if (namekey1 != -1) {
        let nameData = fielddata[namekey1];
        this.setState({ userName: nameData.opportunitiesdetail.length > 0 ? nameData.opportunitiesdetail[0].fieldvalue : null })
      }

      if (fielddata[0].opportunitiesdetail && fielddata[0].opportunitiesdetail.length > 0 && fielddata[0].opportunitiesdetail[0].opportunities) {
        let interaction = fielddata[0].opportunitiesdetail[0].opportunities.interactions ? fielddata[0].opportunitiesdetail[0].opportunities.interactions : 0
        let lastcontacted = fielddata[0].opportunitiesdetail[0].opportunities.lastcontacted ? moment.unix(fielddata[0].opportunitiesdetail[0].opportunities.lastcontacted).tz(this.state.timeZone).format(this.state.dateformat) : '--'
        let inactivedays = fielddata[0].opportunitiesdetail[0].opportunities.inactivedays ? fielddata[0].opportunitiesdetail[0].opportunities.inactivedays : 0
        let dayinstage = fielddata[0].opportunitiesdetail[0].opportunities.dayinstage ? fielddata[0].opportunitiesdetail[0].opportunities.dayinstage : 0

        this.setState({ interaction: interaction, lastcontacted: lastcontacted, inactivedays: inactivedays, dayinstage: dayinstage })

        // this.setState({
        //   interaction: fielddata[0].opportunitiesdetail[0].opportunities.interactions ? fielddata[0].opportunitiesdetail[0].opportunities.interactions : 0,
        //   lastcontacted: fielddata[0].opportunitiesdetail[0].opportunities.lastcontacted ? moment.unix(fielddata[0].opportunitiesdetail[0].opportunities.lastcontacted).tz(this.state.timeZone).format(this.state.dateformat) : "--",
        //   inactivedays: fielddata[0].opportunitiesdetail[0].opportunities.inactivedays ? fielddata[0].opportunitiesdetail[0].opportunities.inactivedays : 0,
        // })
      }

      var companyKey = this.findWithAttr(fielddata, 'id', "44");
      if (companyKey != -1 && fielddata[companyKey].opportunitiesdetail.length > 0) {
        this.setState({
          companyName: fielddata[companyKey].opportunitiesdetail[0].values[0].value,
        }, () => this.forceUpdate())
      }
      else {
        this.setState({
          companyName: "",
        }, () => this.forceUpdate())
      }

      var nameKey = this.findWithAttr(fielddata, 'id', "40");
      if (nameKey != -1 && fielddata[nameKey].opportunitiesdetail.length > 0 && fielddata[nameKey].opportunitiesdetail[0].values) {
        var names = fielddata[nameKey].opportunitiesdetail[0].fieldvalue;
        let colorClass = (names.firstname) ? names.firstname.charAt(0).toLowerCase() : "";
        this.setState({
          name: names,
          colorClass: colorClass,
        }, () => this.forceUpdate())
      }
    }
  }

  handleTaskDialogClose = () => {
    this.setState({ shouldOpenCreateTaskDialog: false, FullCreateTaskDialog: false, minimizeTaskDialog: false });
  };

  handleTaskDialogMinimize = () => {
    this.setState(oldStateminimizeTaskDialog => ({ minimizeTaskDialog: !oldStateminimizeTaskDialog.minimizeTaskDialog }));
    this.setState({ FullCreateTaskDialog: false })
  };

  handleTaskDialogFull = () => {
    this.setState(oldStateFullCreateTaskDialog => ({ FullCreateTaskDialog: !oldStateFullCreateTaskDialog.FullCreateTaskDialog }));
    this.setState({ minimizeTaskDialog: false })
  };

  //----log functions------------

  handleOpportunityDialogClose = () => {
    this.setState({ shouldOpenCreateLogDialog: false, FullCreateOpportunityDialog: false, minimizeOpportunityDialog: false });
  };

  handleOpportunityDialogMinimize = () => {
    this.setState(oldStateminimizeOpportunityDialog => ({ minimizeOpportunityDialog: !oldStateminimizeOpportunityDialog.minimizeOpportunityDialog }));
    this.setState({ FullCreateOpportunityDialog: false })
  };

  handleOpportunityDialogFull = () => {
    this.setState(oldStateFullCreateOpportunityDialog => ({ FullCreateOpportunityDialog: !oldStateFullCreateOpportunityDialog.FullCreateOpportunityDialog }));
    this.setState({ minimizeOpportunityDialog: false })
  };

  handleViewEmailDialogClose = () => {
    this.setState({ shouldOpenViewEmailDialog: false, FullViewEmailDialog: false, minimizeViewEmailDialog: false });
  };

  handleViewEmailDialogMinimize = () => {
    this.setState(oldStateminimizeViewEmailDialog => ({ minimizeViewEmailDialog: !oldStateminimizeViewEmailDialog.minimizeViewEmailDialog }));
    this.setState({ FullViewEmailDialog: false })
  };

  handleViewEmailDialogFull = () => {
    this.setState(oldStateFullViewEmailDialog => ({ FullViewEmailDialog: !oldStateFullViewEmailDialog.FullViewEmailDialog }));
    this.setState({ minimizeViewEmailDialog: false })
  };

  handlefiltermain = (types, activities) => {
    this.setState({ logtypeid: types, activitypeid: activities }, () => this.forceUpdate());

    let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
    let getfilterLogParam = { opportunitiesid: parseFloat(this.state.opportunities_id), logtypeid: types, activitypeid: activities, filterchange: true, }
    this.props.getOpportunitiesActivityLogList(this.props.apolloClient.client, getfilterLogParam);
  }

  createLogArray = (source, partData, partKey) => {
    let logData = [];

    if (partData && partData.length > 0) {
      let logged_in_user_email = JSON.parse(localStorage.getItem("user_data_session")).email;

      partData.map((data) => {
        let pinned = data.ispined;
        if (data.email_type) {
          let log_user = JSON.parse(localStorage.getItem('user_data_session'));
          let color_class = (log_user.name) ? log_user.name.charAt(0) : "";

          if (data.email_type == 1) {
            let date = moment(data.details.email_date_time).format("X");
            logData.push(
              {
                logId: data.id,
                emojidetail: data.emojidetail && data.emojidetail !== null ? JSON.parse(data.emojidetail) : [],
                emailtype: 1,
                name: data.name,
                ispined: pinned,
                email: data.email,
                color: color_class,
                comments: data.__commentlist__,
                attachment: data.attachment,
                emaildetails: data.details,
                emailTo: data.emailTo,
                snippet: data.details.snippet,
                subject: data.details.subject,
                body_html: data.details.body_html,
                email_date_time: moment.unix(data.createddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " + moment.unix(data.createddate).tz(this.state.timeZone).format('hh:mm A'),
              }
            );
          } else if (data.email_type == 2) {
            logData.push(
              {
                logId: data.id,
                emojidetail: data.emojidetail && data.emojidetail !== null ? JSON.parse(data.emojidetail) : [],
                emailtype: 2,
                ispined: pinned,
                name: data.name,
                email: data.email,
                color: color_class,
                comments: data.__commentlist__,
                attachment: data.attachment,
                emaildetails: data.details,
                emailTo: data.emailTo,
                snippet: data.details.snippet,
                subject: data.details.subject,
                body_html: data.details.body_html,
                email_date_time: moment.unix(data.createddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " + moment.unix(data.createddate).tz(this.state.timeZone).format('hh:mm A'),
              }
            );
          } else if (data.email_type == 4) {
            logData.push(
              {
                logId: data.id,
                emojidetail: data.emojidetail && data.emojidetail !== null ? JSON.parse(data.emojidetail) : [],
                emailtype: 4,
                ispined: pinned,
                name: data.name,
                email: data.email,
                color: color_class,
                comments: data.__commentlist__,
                attachment: data.attachment,
                emaildetails: data.details,
                emailTo: data.emailTo,
                snippet: data.details.snippet,
                subject: data.details.subject,
                body_html: data.details.body_html,
                email_date_time: moment.unix(data.createddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " + moment.unix(data.createddate).tz(this.state.timeZone).format('hh:mm A'),
              }
            );
          }
        } else {
          let template = data.template;
          let color_class = (data.__user__.name) ? data.__user__.name.charAt(0) : "";
          let text = (data.text) ? data.text : "";
          let loguser = (data.__user__.email == logged_in_user_email) ? 'You' : data.__user__.name;
          let template1 = (data.template) ? template.replace("{{user}}", loguser) : "";

          let new_template = "";

          let create_date = data.createddate;
          let updateddate = data.updateddate;

          if (data.logtype == 1) // create time
          {
            new_template = template1.replace("{{source_type}}", "opportunity");
          }
          else if (data.logtype == 0) // Phone call
          {
            updateddate = data.logdate;
            new_template = template1.replace("{{property_name}}", '"' + data.propertyname + '"');
          }
          else if (data.logtype == 1000) {// RingCentral Call
            updateddate = data.logdate;
            new_template = "Ring Central Calls";
          }
          else if (data.logtype == 2) // name change
          {
            let old_template = template1.replace("{{source_type}}", "opportunity");
            new_template = old_template.replace("{{source name=new_value}}", '"' + data.newvalue + '"');
          }
          else if (data.logtype == 3 && data.__assignuser__) // owner change
          {
            let logAssignUser = (data.__assignuser__.email == logged_in_user_email) ? 'You' : data.__assignuser__.name;
            new_template = template1.replace("{{target}}", '"' + logAssignUser + '"');
          }
          else if (data.logtype == 4) // for status
          {
            let old_template = template1.replace("{{old_value}}", data.oldvalue);
            new_template = old_template.replace("{{new_value}}", data.newvalue);
          }
          else if (data.logtype == 5) // for note
          {
            if (text != "") {
              updateddate = data.logdate;
              new_template = template1.replace("{{property_name}}", '"' + data.propertyname + '"');
            }
          }
          else if (data.logtype == 6) // when convert lead and assigned a opportunity
          {
            data.__people__ && data.__people__.__contactdetail__.map((opportunitieslog) => {
              if (opportunitieslog.__field__ && opportunitieslog.__field__.id == "16") {
                new_template = template1.replace("{{people}}", opportunitieslog.fieldvalue);
              }
            });
          }
          else if (data.logtype == 8) // stage changes
          {
            let old_template = template1.replace("{{source_type}}", "opportunity");
            let old_template1 = old_template.replace("{{old_value}}", data.oldvalue);
            new_template = old_template1.replace("{{new_value}}", data.newvalue);
          }
          else if (data.logtype == 9) // status change of opportunity
          {
            new_template = template1.replace("{{source_type}}", "opportunity");
          }
          else if (data.logtype == 16) // contqact type change from contact
          {
            let old_template = template1.replace("{{property_name}}", data.propertyname);
            let old_template1 = old_template.replace("{{source_type}}", "contact");
            let old_template2 = old_template1.replace("{{actor}}", loguser);
            let old_template3 = "";
            data.__contact__ && data.__contact__.__contactdetail__.map((contactlog) => {
              if (contactlog.__field__ && contactlog.__field__.id == "16") {
                old_template3 = old_template2.replace("{{contact}}", contactlog.fieldvalue);
              }
            });
            let old_template4 = old_template3.replace("{{old_value}}", data.oldvalue);
            new_template = old_template4.replace("{{new_value}}", data.newvalue);
          }
          else if (data.logtype == 17) {
            //{{user}} added the Contact "{{contact}}"
            data.__contact__ && data.__contact__.__contactdetail__.map((contactlog) => {
              if (contactlog.__field__ && contactlog.__field__.id == "16") {
                new_template = template1.replace("{{contact}}", contactlog.fieldvalue);
              }
            });
          }
          else if (data.logtype == 18) {
            // "{{user}} assigned the Contact "{{contact}}" to {{target}}"
            let old_template = '';
            data.__contact__ && data.__contact__.__contactdetail__.map((companylog) => {
              if (companylog.__field__ && companylog.__field__.id == "16") {
                old_template = template1.replace("{{contact}}", companylog.fieldvalue);
              }
            });
            let logAssignUser = (data.__assignuser__.email == logged_in_user_email) ? 'You' : data.__assignuser__.name;
            new_template = old_template.replace("{{target}}", logAssignUser);
          }
          // else if (data.logtype == 19) // for note
          // {
          //     // { { user } } logged a { { property_name } } to the { { source_type } } "{{contact}}
          //     old_template = template1.replace("{{property_name}}", '"' + data.propertyname + '"');
          //     let old_template1 = old_template.replace("{{source_type}}", "contact");
          // }
          else if (data.logtype == 20) // for task
          {
            if (data.__task__ != null) {
              let old_template = template1.replace("{{task}}", data.__task__.__taskdetail__[0].fieldvalue);
              new_template = old_template.replace("{{source}}", source);
            }
          }
          else if (data.logtype == 21) // for task
          {
            if (data.__task__ != null) {
              let old_template = template1.replace("{{task}}", data.__task__.__taskdetail__[0].fieldvalue);
              let old_template1 = old_template.replace("{{source}}", source);
              new_template = old_template1.replace("{{target}}", loguser);
            }
          }

          if (new_template != "") {
            logData.push({

              logId: data.id,
              emojidetail: data.emojidetail && data.emojidetail !== null ? JSON.parse(data.emojidetail) : [],
              mentionid: data.mentionid,
              mentiondetail: data.mentiondetail ? data.mentiondetail : "",
              ispined: pinned,
              emailtype: 0,
              activitytype: data.__activitytype__,
              comments: data.__commentlist__,
              user: data.__user__.name,
              useremail: data.__user__.email,
              propertyType: data.propertyname,
              color: color_class,
              template: new_template,
              text: text,
              logtype: data.logtype,
              time: (updateddate != null)
                ?
                moment.unix(updateddate).tz(this.state.timeZone).format('hh:mm A')
                :
                moment.unix(create_date).tz(this.state.timeZone).format('hh:mm A'),
              date: (updateddate != null)
                ?
                moment.unix(updateddate).tz(this.state.timeZone).format(this.state.dateformat)
                :
                moment.unix(create_date).tz(this.state.timeZone).format(this.state.dateformat)

            });
          }
        }

      });
    }
    return { key: partKey, datas: logData };
  }

  handlechange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleDateChange = date => {
    this.setState({ selectedDate: date });
  };

  handleCancelAddLog = () => {
    this.setState({ textvalue: '' })
  }
  handleSaveEditLog = (data) => {
    let mention = [];
    Object.entries(data.raw.entityMap).map((entity) => {
      if (mention.indexOf(entity[1].data.mention.id) == -1) {
        mention.push(parseFloat(entity[1].data.mention.id))
      }
    });
    // let date = moment(this.state.selectedDate).format("X");

    let date_test = moment(this.state.selectedDate).format('YYYY-MM-DD HH:mm');
    console.log(date_test, 'inserted');

    var logdate = moment.tz(date_test, this.state.timeZone).format();
    console.log(logdate, this.state.timeZone);

    var date = moment.utc(logdate).format('X');
    console.log(date, 'utc');

    console.log(moment.unix(date).tz(this.state.timeZone).format('YYYY-MM-DD hh:mm A'), 'conv');

    // let textvalue = this.state.textvalue;
    let saveLogparams = [];

    saveLogparams['update'] = {
      text: data.textvalue,
      mentionuser: mention,
      mentiondetail: JSON.stringify(data.raw),
      logtime: parseFloat(date),
      logdate: parseFloat(date),
      logid: parseFloat(this.state.logid),
      // propertyid: parseFloat(0)
    };

    let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
    saveLogparams['getlog'] = { opportunitiesid: parseFloat(this.props.opportunities_id), logtypeid: this.state.logtypeid, activitypeid: this.state.activitypeid };
    console.log(saveLogparams);
    this.props.updateOpportunitiesLog(this.props.apolloClient.client, saveLogparams);
    this.setState({ isEditLog: false })
  }


  //------delete log
  handleDeleteLog = (logid) => {
    this.setState({ logid: logid });
    this.toggleDeleteLogDialog()
  }

  toggleDeleteLogDialog = () => {
    this.setState({ isDeleteLogConfirmDialoOpen: !this.state.isDeleteLogConfirmDialoOpen });
  };

  confirmDeleteLog = () => {
    let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
    let deletelogParams = { logid: parseFloat(this.state.logid), opportunitiesid: parseFloat(this.state.opportunities_id), emailid: emailParam, logtypeid: this.state.logtypeid, activitypeid: this.state.activitypeid };
    this.props.deleteOpportunitiesLogDetail(this.props.apolloClient.client, deletelogParams);
    this.toggleDeleteLogDialog()
  };


  //email menu functions
  handleEmailHtmlPopupMain = (logdata) => {
    this.setState({ shouldOpenViewEmailDialog: true, viewHtmlData: logdata });
  }

  //- comments-----------------
  toggleComment = (logid) => {
    let isOpenedCommentIds = this.state.isOpenedCommentIds;

    if (isOpenedCommentIds.indexOf(logid) == -1) {
      isOpenedCommentIds.push(logid);
    }
    else {
      let index = isOpenedCommentIds.indexOf(logid);
      isOpenedCommentIds.splice(index, 1);
    }
    this.setState(({ logid: logid, isOpenedCommentIds: isOpenedCommentIds }));
  }

  handleCancelComment = () => {
    this.setState({ commentvalue: '' });
  }

  handleSaveCommentLog = (type) => {
    const raw = this.state.commentState.raw
    let mention = [];
    Object.entries(raw.entityMap).map((entity) => {
      if (mention.indexOf(entity[1].data.mention.id) == -1) {
        mention.push(parseFloat(entity[1].data.mention.id))
      }
    });
    let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
    let commentParams = {
      type: type,
      comment: this.state.commentvalue,
      logid: parseFloat(this.state.logid),
      opportunitiesid: parseFloat(this.state.opportunities_id),
      emailid: emailParam,
      logtypeid: this.state.logtypeid,
      activitypeid: this.state.activitypeid,
      mentionuser: mention,
      mentiondetail: "",
    };
    this.props.addOpportunitiesLogComment(this.props.apolloClient.client, commentParams);
    this.setState({ commentvalue: '' });
  }

  //-edit comment
  handleSaveEditComment = (type) => {
    const raw = this.state.commentState.raw
    let mention = [];
    Object.entries(raw.entityMap).map((entity) => {
      if (mention.indexOf(entity[1].data.mention.id) == -1) {
        mention.push(parseFloat(entity[1].data.mention.id))
      }
    });
    let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
    let commentParams = {
      type: type,
      commentid: parseFloat(this.state.commentid),
      comment: this.state.commentvalue,
      opportunitiesid: parseFloat(this.state.opportunities_id),
      emailid: emailParam,
      logtypeid: this.state.logtypeid,
      activitypeid: this.state.activitypeid,
      mentionuser: mention,
      mentiondetail: "",
    };
    this.setState({ commentvalue: '', isEditComment: false, commentid: '' });
    this.props.updateOpportunitiesLogComment(this.props.apolloClient.client, commentParams);
  }
  handleEditCommentMain = (id, comment) => {
    this.setState({ isEditComment: true, commentvalue: comment, commentid: id });
  }

  handleCancelEditComment = () => {
    this.setState({ isEditComment: false, commentvalue: '' })
  }

  //-delete comment
  deleteComment = (cmt_id, type) => {
    let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
    let commentParams = {
      commentid: cmt_id,
      type: type,
      emailid: emailParam,
      opportunitiesid: parseFloat(this.state.opportunities_id),
      logtypeid: this.state.logtypeid,
      activitypeid: this.state.activitypeid
    };
    this.props.deleteOpportunitiesLogComment(this.props.apolloClient.client, commentParams);
  }
  //--------------comments functions-----------

  //---- email functions
  toggleEmail = (id) => {
    let isOpenedEmailIds = this.state.isOpenedEmailIds;

    if (isOpenedEmailIds.indexOf(id) == -1) {
      isOpenedEmailIds.push(id);
    }
    else {
      let index = isOpenedEmailIds.indexOf(id);
      isOpenedEmailIds.splice(index, 1);
    }

    this.setState(({ logid: id, isOpenedEmailIds: isOpenedEmailIds }));
  }

  toggleReplayEmail = () => {
    this.setState(oldStateReplayEmail => ({ isOpenedReplayEmail: !oldStateReplayEmail.isOpenedReplayEmail }));
  }

  componentWillUnmount() {
    // emailLogEmail
    localStorage.removeItem('emailLogEmail');
  }

  //-----------------------------------------------


  handleSubmit = event => {
    console.log("submitted");
    console.log(event);
  };

  handleChange = event => {
    event.persist();
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeTab = (event, newValue) => {
    this.setState({ TabValue: newValue });
  }

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  handleDeleteOpportunity = () => {
    this.toggleDeleteDialog()
  }

  toggleDeleteDialog = () => {
    this.setState({ isDeleteConfirmDialoOpen: !this.state.isDeleteConfirmDialoOpen });
  };
  confirmDeleteCustomField = () => {
    let deleteOpportunityParams = [];

    deleteOpportunityParams['deleteid'] = {
      opportunitiesid: [parseFloat(this.state.opportunities_id)],
      piplineid: parseFloat(localStorage.getItem('selected_pipeline_id'))
    };
    deleteOpportunityParams['listval'] = {
      filterdata: [],
      list: {
        limit: 10,
        pageno: 1,
        searchtext: "",
        sorttype: "",
        type: "list",
        fieldId: 0,
        columnname: "",
      },
      filterid: 1
    };
    this.toggleDeleteDialog();
    this.props.handleClose();
    this.props.deleteOpportunities(this.props.apolloClient.client, deleteOpportunityParams);
  };
  handleFavorite = (opportunitiesid, status) => {
    let setFavoriteParams = [];

    let fav = !status;
    let star_i = fav ? 'star' : 'star_border';
    this.setState({ isfavorite: fav, star_icon: star_i });

    setFavoriteParams['favorite'] = { status: !status, opportunitiesid: parseInt(opportunitiesid) };
    setFavoriteParams['listval'] = {
      filterdata: [],
      list: {
        limit: 10,
        pageno: 1,
        searchtext: "",
        sorttype: "",
        type: "list",
        fieldId: 0,
        columnname: ""
      },
      piplineid: parseFloat(localStorage.getItem('selected_pipeline_id')),
      filterid: 1
    };

    this.props.addFavouriteOpportunities(this.props.apolloClient.client, setFavoriteParams);
  }
  opeAddNewTaskPopup = () => {
    var data = {
      recordtype: "opportunities",
      type: "tasks",
      id: parseInt(this.state.opportunities_id),
    }
    this.setState({
      newTaskData: data,
    }, () => {
      this.setState({
        shouldOpenCreateTaskDialog: true
      });
    })
  }
  togglePin = (logId, pinned) => {
    let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';

    let pinParams = {
      ispined: !pinned,
      logid: parseFloat(logId),
      recordid: parseFloat(this.state.opportunities_id),
      recordtypeid: 4,
      emailid: emailParam,
      logtypeid: this.state.logtypeid,
      activitypeid: this.state.activitypeid
    }

    this.props.addLogPined(this.props.apolloClient.client, pinParams);
  }
  handleAdd = (log) => this.setState({ showSelector: !this.state.showSelector, logid: log.logId })

  handleSelect = (emoji, data) => {

    const index = data.emojidetail.findIndex(obj => obj.emoji === emoji && obj.by === this.state.userid)
    var main_data = data.emojidetail;
    if (index > -1) {
      main_data = [...main_data.slice(0, index), ...main_data.slice(index + 1)]
    } else {
      main_data = [...main_data, { emoji, by: this.state.userid }]
    }
    this.setState({ showSelector: false })
    console.log("new... ", main_data)
    let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';

    let req = {
      logid: data.emailtype !== 0 ? parseFloat(data.emaildetails.id) : parseFloat(data.logId),
      emoji: emoji,
      emojidetail: JSON.stringify(main_data),
      opportunitiesid: parseFloat(this.state.opportunities_id),
      activitypeid: this.state.activitypeid,
      emailid: emailParam,
      logtypeid: this.state.logtypeid,
      logtype: data.emailtype !== 0 ? "email" : "log"
    }
    this.props.addOpportunitiesLogEmoji(this.props.apolloClient.client, req)
  }
  isOpenedClickEmailToggle = () => {
    this.setState({
      isOpenedClickEmail: !this.state.isOpenedClickEmail
    })
  }
  getdata = (data) => {
    console.log("data ....", data)
    this.setState({ commentvalue: data.textvalue, commentState: data })
  }
  getEditCommentData = (data) => {
    console.log("data edit ....", data)
    this.setState({ commentvalue: data.textvalue, commentState: data })
  }
  saveId = (id, date) => {
    this.setState({ logid: id, selectedDate: date })
  }
  AddNewLog = (log) => {
    this.setState({
      logtype: log.logtype,
      logvaluetype: log.propertyType,
      shouldOpenCreateLogDialog: true
    })
  }
  handleSaveAddLog = (data) => {
    let saveLogparams = [];
    saveLogparams['save'] = {
      mentionuser: data.mentionuser,
      mentiondetail: data.mentiondetail,
      propertyname: data.propertyname,
      propertyid: data.propertyid,
      text: data.text,
      logtime: data.logtime,
      logdate: data.logdate,
      oldvalue: "",
      newvalue: "",
      logtype: data.logtype,
      fieldid: data.fieldid,
      opportunitiesid: parseFloat(this.props.opportunities_id)
    };
    saveLogparams['getlog'] = { opportunitiesid: parseFloat(this.props.opportunities_id), activitypeid: [], logtypeid: [] };
    console.log(saveLogparams, 'saveLogparams');
    this.props.saveOpportunitiesLog(this.props.apolloClient.client, saveLogparams);
    this.setState({ shouldOpenCreateLogDialog: false });
  };

  toggleReplayEmail = () => {
    this.setState({
      isOpenedReplayEmailLog: !this.state.isOpenedReplayEmailLog
    });
  };

  openNewMail = (log) => {
    this.setState({ isOpenedReplayEmailLog: !this.state.isOpenedReplayEmailLog, emaildata: log });
  };

  handleSync = () => {
    this.setState({ SyncStatus: true });
    // this.props.dataResync(this.props.apolloClient.client);

    let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
    let pinParams = {
      recordid: parseFloat(this.state.opportunities_id),
      recordtypeid: 4,
      emailid: emailParam,
      logtypeid: [1, 2, 3, 4, 15, 20, 21, 5, 100],
      activitypeid: [1, 2, 3, 4, 5, 6]
    }
    this.props.dataResync(this.props.apolloClient.client, pinParams);
  };

  render() {

    let { isOpenedReplayEmailLog, FullCreateTaskDialog, minimizeTaskDialog, shouldOpenCreateTaskDialog, isOpenedEmail, isOpenedReplayEmail, commentvalue, textvalue, selectedDate, shouldOpenCreateLogDialog, TabValue, allLogs, colorClass } = this.state;
    let { handleClose } = this.props;
    let user_data = JSON.parse(localStorage.getItem('user_data_session'));
    let current_logUser = user_data.email;
    let allowUserReply = user_data.googlestatus === true && this.state.allSettings.setting_one && this.state.allSettings.setting_one === 1 ? true : false;
    let activity_ui = allowUserReply ? 'Google_activity_summary' : 'activity_summary';
    let loghtml = [];
    let logbody = [];

    allLogs.map((logdata) => {
      logbody = [];
      if (logdata.datas.length > 0) {
        logbody.push(
          <FilterLogoCommon headerKey={logdata.key} />

        );
        logdata.datas.map((log) => {
          if (log.emailtype != 0) {
            if (log.emailtype == 1) {
              logbody.push(
                <EmailTypeOne
                  allowUserReply={allowUserReply}
                  commentvalue={commentvalue}
                  log={log}
                  logid={this.state.logid}
                  userName={this.state.userName}
                  opportunities_id={this.state.opportunities_id}
                  isOpenedEmailIds={this.state.isOpenedEmailIds}
                  showSelector={this.state.showSelector}
                  dateformat={this.state.dateformat}
                  timeZone={this.state.timeZone}
                  commentid={this.state.commentid}
                  isOpenedCommentIds={this.state.isOpenedCommentIds}
                  isEditComment={this.state.isEditComment}
                  thatAll={this}
                  transferLink={"/opportunities/opportunities/" + this.state.opportunities_id}
                />
              )
            }
            else if (log.emailtype == 2) {
              logbody.push(
                <EmailTypeTwo
                  allowUserReply={allowUserReply}
                  commentvalue={commentvalue}
                  log={log}
                  logid={this.state.logid}
                  userName={this.state.userName}
                  opportunities_id={this.state.opportunities_id}
                  isOpenedEmailIds={this.state.isOpenedEmailIds}
                  showSelector={this.state.showSelector}
                  dateformat={this.state.dateformat}
                  timeZone={this.state.timeZone}
                  commentid={this.state.commentid}
                  isOpenedCommentIds={this.state.isOpenedCommentIds}
                  isEditComment={this.state.isEditComment}
                  thatAll={this}
                  transferLink={"/opportunities/opportunities/" + this.state.opportunities_id}
                />
              )
            }
            else if (log.emailtype == 4) {
              logbody.push(
                <EmailTypeFour
                  allowUserReply={allowUserReply}
                  commentvalue={commentvalue}
                  log={log}
                  logid={this.state.logid}
                  userName={this.state.userName}
                  opportunities_id={this.state.opportunities_id}
                  isOpenedEmailIds={this.state.isOpenedEmailIds}
                  showSelector={this.state.showSelector}
                  dateformat={this.state.dateformat}
                  timeZone={this.state.timeZone}
                  commentid={this.state.commentid}
                  isOpenedCommentIds={this.state.isOpenedCommentIds}
                  isEditComment={this.state.isEditComment}
                  thatAll={this}
                  transferLink={"/opportunities/opportunities/" + this.state.opportunities_id}
                />
              )
            }
          } else {
            if (log.logtype == 0) {
              logbody.push(
                <CallLogs
                  thatAll={this}
                  log={log}
                  commentvalue={this.state.commentvalue}
                  selectedDate={this.state.selectedDate}
                  logid={this.state.logid}
                  dateformat={this.state.dateformat}
                  showSelector={this.state.showSelector}
                  isOpenedCommentIds={this.state.isOpenedCommentIds}
                  isEditComment={this.state.isEditComment}
                  commentid={this.state.commentid}
                  timeZone={this.state.timeZone}
                />
              )
            } else if (log.logtype == 1000) {
              logbody.push(
                <RingCentralCallLogs
                  thatAll={this}
                  log={log}
                  commentvalue={this.state.commentvalue}
                  selectedDate={this.state.selectedDate}
                  logid={this.state.logid}
                  dateformat={this.state.dateformat}
                  showSelector={this.state.showSelector}
                  isOpenedCommentIds={this.state.isOpenedCommentIds}
                  isEditComment={this.state.isEditComment}
                  commentid={this.state.commentid}
                  timeZone={this.state.timeZone}
                />)
            } else if (log.logtype == 5) {
              logbody.push(
                <NoteLog
                  thatAll={this}
                  log={log}
                  commentvalue={this.state.commentvalue}
                  selectedDate={this.state.selectedDate}
                  logid={this.state.logid}
                  dateformat={this.state.dateformat}
                  showSelector={this.state.showSelector}
                  isOpenedCommentIds={this.state.isOpenedCommentIds}
                  isEditComment={this.state.isEditComment}
                  commentid={this.state.commentid}
                  timeZone={this.state.timeZone}
                />)
            } else {
              logbody.push(
                <SystemLog log={log} />
              )
            }
          }
        })
        if (logbody && logbody.length > 0) {
          loghtml.push(logbody);
        }
      }
    })

    return (
      <React.Fragment>
        <div className="frame_body">
          <div className="mt-10 ml-16 mr-16">
            <Grid container justify="space-between">
              <Grid item><IconButton size="small" className="ml--4" onClick={handleClose}><Icon>arrow_forward</Icon></IconButton></Grid>
              <Grid item>
                {/* <IconButton size="small"><Icon>turned_in_not</Icon></IconButton> */}
                <DropdownDefault
                  menuButton={
                    <IconButton size="small" className="ml-4 mr--8">
                      <Icon>more_vert</Icon>
                    </IconButton>
                  }
                >
                  {/* <MenuItem className="flex flex-middle"><Icon className="mr-16 text-muted">launch</Icon>Open in web</MenuItem> */}
                  {/* <MenuItem className="flex flex-middle"><Icon className="mr-16 text-muted">inbox</Icon> Filer inbox</MenuItem> */}
                  {/* <MenuItem className="flex flex-middle"><svg viewBox="0 0 24 24" style={{ width: 24 }} className="mr-16" fill="rgba(0, 0, 0, 0.54)" title="LinkedIn" xmlns="http://www.w3.org/2000/svg"><path d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z"></path></svg> In Linkedin</MenuItem> */}
                  {this.state.crudPermission.isdelete &&
                    <MenuItem onClick={this.handleDeleteOpportunity} className="flex flex-middle"><Icon className="mr-8 text-muted">delete</Icon>Delete</MenuItem>
                  }
                </DropdownDefault>
              </Grid>
            </Grid>
          </div>
          <div className="list_user_profile white_space_nowrap">
            <Grid container spacing={2}>
              <Grid item>
                <Avatar className={"avatar_circle color_" + colorClass}>$</Avatar>
              </Grid>
              <Grid item>
                <h5 className="text_ellipsis" style={{ maxWidth: "165px" }}>
                  {this.state.name}
                </h5>
                <IconButtonWhite className="filled_star crm-star-custom-icon" size="small" onClick={(lead, status) => this.handleFavorite(this.state.opportunities_id, this.state.isfavorite)}>
                  <IconSmall>{this.state.star_icon}</IconSmall>
                </IconButtonWhite>
                <p>{this.state.companyName}</p>
              </Grid>
            </Grid>
            <div className="user__menu">
              <div className="text-center">
                <Fab
                  size="small"
                  color="primary"
                  className="btn-primary"
                  aria-label="Note"
                  onClick={() => this.setState({ logtype: 5, logvaluetype: 'Note', shouldOpenCreateLogDialog: true })}
                >
                  <Icon className="primary_color">library_books</Icon>
                </Fab>
                <div className="text-gray">Note</div>
              </div>
              {/* <div className="text-center">
                <Fab
                  size="small"
                  color="primary"
                  className="btn-primary"
                  aria-label="Email"
                  onClick={() => this.isOpenedClickEmailToggle()}>

                  <Icon className="primary_color">email</Icon>
                </Fab>
                <div className="text-gray">Email</div>
              </div> */}
              <div className="text-center">
                <Fab
                  size="small"
                  color="primary"
                  className="btn-primary"
                  aria-label="Log"
                  onClick={() => this.setState({ logtype: 0, logvaluetype: 'Phone Call', shouldOpenCreateLogDialog: true })}
                >
                  <Icon className="primary_color">add_circle</Icon>
                </Fab>
                <div className="text-gray">Log</div>
              </div>
              <div className="text-center">
                <Fab
                  size="small"
                  color="primary"
                  className="btn-primary"
                  aria-label="Task"
                  onClick={() => this.opeAddNewTaskPopup()}
                >
                  <Icon className="primary_color">storage</Icon>
                </Fab>
                <div className="text-gray">Task</div>
              </div>
              <div className="text-center">
                <Fab
                  size="small"
                  color="primary"
                  className="btn-primary"
                  aria-label="Meet"
                  onClick={() => this.setState({ logvaluetype: 'Meeting', shouldOpenCreateLogDialog: true })}
                >
                  <Icon className="primary_color">calendar_today</Icon>
                </Fab>
                <div className="text-gray">Meet</div>
              </div>
            </div>
            <hr style={{ height: "5px" }} className="bg-primary m-0" />

          </div>
          <Tabs
            value={TabValue}
            onChange={this.handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            className="lead_tabs"
          >
            <Tab label="About" {...a11yProps(0)} />
            <Tab label="Activity" {...a11yProps(1)} />
            <Tab label="Related" {...a11yProps(2)} />
          </Tabs>
          <TabPanel value={TabValue} index={0} className="rightbar_vscroll">
            <ProfileDetails opportunities_id={this.state.opportunities_id} />
          </TabPanel>
          <TabPanel value={TabValue} index={1} className="rightbar_vscroll">
            {allowUserReply ?
              this.state.SyncStatus ?
                <div className="text-hint text-small m-10" style={{ cursor: 'pointer' }}>
                  Syncing....<FontAwesome className={"fa fa-refresh fa-spin"} />
                </div>
                :
                <div className="text-hint text-small m-10" style={{ cursor: 'pointer' }} onClick={this.handleSync}>
                  Synced {this.state.SyncTime}<FontAwesome className={"fa fa-refresh"} style={{ margin: '3px' }} />
                </div>
              : ''}
            <Grid container className={`text-center flex-nowrap mb-16 ${activity_ui}`}>
              <Grid item xs={4}>
                <span className="font-weight-500 font-size-15 text-primary">{this.state.interaction}</span>
                <p className="m-0 text-gray">Interactions</p>
              </Grid>
              <Grid item xs={4}>
                <span className="font-weight-500 font-size-15 text-primary">{this.state.inactivedays}</span>
                <p className="m-0 text-gray">Inactive days</p>
              </Grid>
              <Grid item xs={4}>
                <span className="font-weight-500 font-size-15 text-primary">{this.state.dayinstage}</span>
                <p className="m-0 text-gray">Days in Stage</p>
              </Grid>
            </Grid>
            <div className="pl-16 pr-16">
            </div>
            <ul className="sidebar_activity crm-leads-sidebar-logs-list">
              <FiltersMenu proVar={this.state.activities} thisData={this} />
              <div>
                {loghtml.map((day_log) => (
                  <div className="lead_upcoming">
                    <div className="box_portlet">
                      {day_log}
                    </div>
                  </div>
                ))}
              </div>
            </ul>
          </TabPanel>
          <TabPanel value={TabValue} index={2} className="rightbar_vscroll">
            <div className="rightbar_related v_scroll p-0">
              {this.state.opportunities_id && <ProfileRight opportunities_id={parseInt(this.state.opportunities_id)} phrase={this.state.phrase} />}
            </div>
          </TabPanel>
          <Dialog
            fullWidth={true}
            maxWidth={'xs'}
            open={this.state.isDeleteConfirmDialoOpen}
            onClose={this.toggleDeleteDialog}
            disableBackdropClick

            aria-labelledby="ar-remove-dialog-title"
          >
            <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to remove selected Opportunity ?"}</DialogTitle>
            <DialogActions>
              <Button onClick={this.toggleDeleteDialog} color="primary">
                Cancel
              </Button>
              <Button type="Button" onClick={() => this.confirmDeleteCustomField()} className="btn_Primary">
                Ok
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            fullWidth={true}
            maxWidth={'xs'}
            open={this.state.isDeleteLogConfirmDialoOpen}
            onClose={this.toggleDeleteLogDialog}
            disableBackdropClick

            aria-labelledby="ar-remove-dialog-title"
          >
            <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to remove selected log ?"}</DialogTitle>
            <DialogActions>
              <Button onClick={this.toggleDeleteLogDialog} color="primary">Cancel</Button>
              <Button type="Button" onClick={() => this.confirmDeleteLog()} className="btn_Primary">Ok</Button>
            </DialogActions>
          </Dialog>

          {shouldOpenCreateLogDialog && (
            <CreatelogDialog
              handleClose={this.handleOpportunityDialogClose}
              open={shouldOpenCreateLogDialog}
              handleMinimize={this.handleOpportunityDialogMinimize}
              handleFull={this.handleOpportunityDialogFull}
              minimizeScreen={this.state.minimizeOpportunityDialog}
              fullScreen={this.state.FullCreateOpportunityDialog}
              handleSaveAddLog={this.handleSaveAddLog}
              logvaluetype={this.state.logvaluetype}
              logtype={this.state.logtype}
            />
          )}
          {shouldOpenCreateTaskDialog && (
            <CreateNewTaskDialog
              handleClose={this.handleTaskDialogClose}
              open={shouldOpenCreateTaskDialog}
              handleMinimize={this.handleTaskDialogMinimize}
              handleFull={this.handleTaskDialogFull}
              minimizeScreen={minimizeTaskDialog}
              fullScreen={FullCreateTaskDialog}
              allstates={this.state}
              dataFromSidebar={this.state.newTaskData}
            />
          )}
          {isOpenedReplayEmailLog &&
            <InboxComposeDialog
              handleClose={this.toggleReplayEmail}
              open={isOpenedReplayEmailLog}
              emaildata={this.state.emaildata}
              mainUserData={this.state.mainUserData}
              LogDetails={{
                opportunitiesid: this.state.opportunities_id,
                emailid: localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '',
                logtypeid: this.state.logtypeid,
                activitypeid: this.state.activitypeid,
              }}
              LogComponent="Opportunities"
            />
          }
          {this.state.shouldOpenViewEmailDialog && (
            <ViewHtmlDialog
              handleClose={this.handleViewEmailDialogClose}
              open={this.state.shouldOpenViewEmailDialog}
              logDatas={this.state.viewHtmlData}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}


const mapStateToProps = state => ({
  opportunityReducer: state.opportunityReducer,
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  authReducer: state.authReducer

});

const mapDispatchToProps = dispatch => {
  return {
    addLogPined: (client, request) => dispatch(actionCreators.addLogPined(client, request)),
    getOpportunitiesActivityLogList: (client, request) => { dispatch(actionCreators.getOpportunitiesActivityLogList(client, request)) },
    saveOpportunitiesLog: (client, request) => dispatch(actionCreators.saveOpportunitiesLog(client, request)),
    updateOpportunitiesLog: (client, request) => dispatch(actionCreators.updateOpportunitiesLog(client, request)),
    deleteOpportunitiesLogDetail: (client, request) => dispatch(actionCreators.deleteOpportunitiesLogDetail(client, request)),
    addOpportunitiesLogComment: (client, request) => dispatch(actionCreators.addOpportunitiesLogComment(client, request)),
    updateOpportunitiesLogComment: (client, request) => dispatch(actionCreators.updateOpportunitiesLogComment(client, request)),
    deleteOpportunitiesLogComment: (client, request) => dispatch(actionCreators.deleteOpportunitiesLogComment(client, request)),


    deleteOpportunities: (client, request) => { dispatch(actionCreators.deleteOpportunities(client, request)) },
    addFavouriteOpportunities: (client, request) => { dispatch(actionCreators.addFavouriteOpportunities(client, request)) },
    customFieldList: (client, request) => { dispatch(actionCreators.customFieldList(client, request)) },
    saveLeadLog: (client, request) => dispatch(actionCreators.saveLeadLog(client, request)),
    deleteLeadLogDetail: (client, request) => dispatch(actionCreators.deleteLeadLogDetail(client, request)),
    updateLeadLog: (client, request) => dispatch(actionCreators.updateLeadLog(client, request)),
    addOpportunitiesLogEmoji: (client, request) => dispatch(actionCreators.addOpportunitiesLogEmoji(client, request)),
    getEmailSetting: (client) => dispatch(actionCreators.getEmailSetting(client)),
    getSyncTime: (client) => dispatch(actionCreators.getSyncTime(client)),
    dataResync: (client, req) => dispatch(actionCreators.dataResync(client , req)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OpportunityProfileFrame);
