import React, { Component } from "react";
import { Button } from "@material-ui/core";

const mystyle = {
  height:'200px'
};

class NotFound extends Component {
  
  state = {};
  render() {
    return (
      <div className="flex flex-center flex-middle w-100 h-100vh">
        <div className="flex flex-column flex-center flex-middle" style={{ maxWidth: "320px" }}>
          <img className="mb-32" style={mystyle} src="/assets/images/404.svg" alt="" />
          <label className="text-hint text-center">Oops! Sorry Page Not Found Or You Are Not Logged In..</label>
          <Button
            className="capitalize button_hover"
            variant="contained"
            color="primary"
            onClick={() => this.props.history.push('/')}
          >
            Back to Dashboard / SignIn
          </Button>
        </div>
      </div>
    );
  }
}

export default NotFound;
