import React, { Component } from "react";
import SettingSidebar from '../../../Layout/SettingSidebarMenu';
import { Avatar, Dialog, DialogTitle, DialogActions, Input, InputAdornment, InputLabel, withStyles, Box, IconButton, Card, Icon, Tab, Tabs, Typography, Button, Checkbox, Chip, Fab, FormControl, Grid, ListItemText, MenuItem, Select, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter } from "@material-ui/core";
import moment from 'moment';
import PropTypes from 'prop-types';
import GoalsDialog from "./GoalsDialog"
import LoadingState from "./LoadingState"

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from '../../../../store/actions/index';
const IconSmall = withStyles(() => ({
  root: {
    fontSize: "1rem",
    color: "rgba(0, 0, 0, 0.6)",
  },
}))(Icon);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={2}>{children}</Box>
    </Typography>
  );
}
const names = [
  'All activity',
  'Email',
  'Meeting',
  'Phone call'

];
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}
function a11in1yProps(index) {
  return {
    id: `tabinside1-${index}`,
    'aria-controls': `tabpanelin1-${index}`,
  };
}
function a11in2yProps(index) {
  return {
    id: `tabinside2-${index}`,
    'aria-controls': `tabpanelin2-${index}`,
  };
}
function a11in3yProps(index) {
  return {
    id: `tabinside2-${index}`,
    'aria-controls': `tabpanelin2-${index}`,
  };
}
function a11in4yProps(index) {
  return {
    id: `tabinside2-${index}`,
    'aria-controls': `tabpanelin2-${index}`,
  };
}

class SettingsQuarterlyGoal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedUserList: "all-users",
      userlist: this.props.data.state.usersListArray,

      shouldOpenSaleRevenueGoalsDialog: false,
      FullGoalDialog: false,
      minimizeGoalDialog: false,
      value: 0,
      valueone: 0,
      valuetwo: 0,
      valuethree: 0,
      valuefour: 0,
      personName: ['All activity'],
      yearList: [],

      pipelineList: [],
      selectedPipelineList: [],
      goalList: [],
      selectedPipeline: "0",
      usersListArray: [],
      teamList: [],
      date: moment(),
      previousdate: moment().subtract("1", "years"),
      nextdate: moment().add("1", "years"),
      datetype: 3,
      goaltype: this.props.goaltype,
      submitGoalData: [],
      pipelinetotal: [],

      isDeleteConfirmDialoOpen: false,
      goaldeleteid: "",
      goaldelete: [],
      deletepipeline: [],
      deletegoalmessage: "",
    }
  }

  componentDidMount = () => {
    var j = 0;
    let date = moment().subtract(5, "years");
    let yearList = [];

    for (j = 0; j < 24; j++) {
      yearList.push({ start: moment(date).add(j, "years"), end: moment(date).add(j + 1, "years") })
    }

    this.setState({ yearList })

    this.props.getPipelines(this.props.apolloClient.client);
    let goalParams = { datetype: parseInt(this.state.datetype), goaltype: parseInt(this.state.goaltype), filterdata: [] };
    this.props.getGoalList(this.props.apolloClient.client, goalParams);
  }
  componentWillReceiveProps(props) {
    let reducer_param = "";
    if (this.state.goaltype == 1)
      reducer_param = "sales_year_goallist";

    if (this.state.goaltype == 2)
      reducer_param = "won_year_goallist";

    if (this.state.goaltype == 3)
      reducer_param = "activity_year_goallist";

    if (props.settingReducer[reducer_param] && props.settingReducer[reducer_param].data && props.settingReducer[reducer_param].is_loaded) {
      let goalslist = props.settingReducer[reducer_param].data;
      let submitGoalData = [];
      let pipelinelist = this.state.pipelineList;
      let pipelinetotal = [];


      if (props.settingReducer.teamMemberList && props.settingReducer.teamMemberList.data && this.state.selectedUserList != 'all-users') {
        let teamMemberList = props.settingReducer.teamMemberList.data;

        let memberlist = [];

        teamMemberList.map((teamuser) => {
          memberlist.push(teamuser.user);
        })

        goalslist.map((goal, goalfilterkey) => {
          let userkey = this.findWithAttr(memberlist, 'id', goal.user.id);
          if (userkey == -1) {
            goalslist.splice(goalfilterkey, 1);
          }
        });
      }

      goalslist && goalslist.map((goaldata, goalkey) => {
        if (!submitGoalData[goalkey])
          submitGoalData[goalkey] = [];

        if (!submitGoalData[goalkey].pipeline)
          submitGoalData[goalkey].pipeline = [];

        if (!submitGoalData[goalkey].previousdate)
          submitGoalData[goalkey].previousdate = [];

        if (!submitGoalData[goalkey].currentdate)
          submitGoalData[goalkey].currentdate = [];

        if (!submitGoalData[goalkey].nextdate)
          submitGoalData[goalkey].nextdate = [];

        if (!submitGoalData[goalkey].alldate)
          submitGoalData[goalkey].alldate = [];

        if (!submitGoalData[goalkey].goalid)
          submitGoalData[goalkey].id = goaldata.id;

        goaldata.pipline && goaldata.pipline.map((pipedata, pipekey) => {

          if (!submitGoalData[goalkey].alldate[pipekey])
            submitGoalData[goalkey].alldate[pipekey] = [];

          let isavailable = this.findWithAttr(submitGoalData[goalkey].pipeline, 'id', pipedata.id);

          if (isavailable == -1) {

            submitGoalData[goalkey].pipeline.push(pipedata);
            submitGoalData[goalkey].alldate[pipekey] = goaldata.alldate[pipekey]
          }

          goaldata.alldate[pipekey].map((allval, allkey) => {

            if (!pipelinetotal[allkey])
              pipelinetotal[allkey] = {};

            if (!pipelinetotal[allkey][pipedata.id])
              pipelinetotal[allkey][pipedata.id] = {};


            let totalpipelinevalue = (pipelinetotal[allkey][pipedata.id].totalValue) ? pipelinetotal[allkey][pipedata.id].totalValue : 0;
            if ((allval.pipelineid == parseInt(pipedata.id)) && (allval.pipelinekey == pipekey)) {
              totalpipelinevalue += parseInt(allval.value);
            }
            pipelinetotal[allkey][pipedata.id] = {
              totalValue: parseInt(totalpipelinevalue),
              pipelineid: pipedata.id,
              startdate: allval.startdate,
              enddate: allval.enddate,
            }
          })
        })
      })

      props.settingReducer[reducer_param].is_loaded = false;

      this.setState({ goalList: goalslist, submitGoalData, pipelinetotal })
    }



    if (props.settingReducer.pipelineListArray && props.settingReducer.pipelineListArray.data) {
      let pipelines = props.settingReducer.pipelineListArray.data;
      let submitGoalData = this.state.submitGoalData;

      if (submitGoalData && submitGoalData.length > 0) {
        // submitGoalData.map((goaldata, goalkey)=>{

        //   goaldata.pipeline
        //   let pipelinekey = this.findWithAttr(this.state.pipelineList, 'id', pipedata.id);

        //   if(pipelinekey != -1)
        //     pipelinelist.splice(pipelinekey, 1);

        //   pipelinelist[goalkey] = pipelines;
        //   console.log(goaldata,'goaldataasd as ads asd');
        // })
      }
      this.setState({ pipelineList: props.settingReducer.pipelineListArray.data })
    }

    if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
      const usersListArray = props.settingReducer.usersListArray.data;
      this.setState({ usersListArray: usersListArray })
    }

    if (props.goaltype) {
      this.setState({ goaltype: props.goaltype })
    }

    if (props.data.state.teamList) {
      this.setState({ teamList: props.data.state.teamList })
    }
  }

  handleGoalDialogClose = () => {
    this.setState({ shouldOpenSaleRevenueGoalsDialog: false, FullGoalDialog: false, minimizeGoalDialog: false });
  };

  handleGoalDialogMinimize = () => {
    this.setState(oldStateminimizeGoalDialog => ({ minimizeGoalDialog: !oldStateminimizeGoalDialog.minimizeGoalDialog }));
    this.setState({ FullGoalDialog: false })
  };

  handleGoalDialogFull = () => {
    this.setState(oldStateFullGoalDialog => ({ FullGoalDialog: !oldStateFullGoalDialog.FullGoalDialog }));
    this.setState({ minimizeGoalDialog: false })
  };

  nextYear = () => {
    let date = moment(this.state.date).add("1", "years")
    let previousdate = moment(date).subtract("1", "years");
    let nextdate = moment(date).add("1", "years");

    if (date < this.state.yearList[this.state.yearList.length - 1].start) {
      this.setState({ date, previousdate, nextdate })
    }
  }

  prevYear = () => {
    let date = moment(this.state.date).subtract("1", "years");
    let previousdate = moment(date).subtract("1", "years");
    let nextdate = moment(date).add("1", "years");
    if (date > this.state.yearList[0].start) {
      this.setState({ date, previousdate, nextdate })
    }
  }


  handleDateChange = () => event => {
    this.setState({ date: moment(event.target.value, "DD-MM-YYYY") })
  }

  handleChange = event => {
    event.persist();
    if (event.target.type === 'checkbox') {
      this.setState({ [event.target.name]: event.target.checked });
    }
    else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  yearFilterList = () => {
    return (
      <div color="primary" fontSizeSmall >
        <Icon className="text-middle" onClick={this.prevYear} color="primary" fontSizeSmall>keyboard_arrow_left</Icon>
        <Select displayEmpty
          value={this.state.date.format("DD-MM-YYYY")}
          onChange={this.handleDateChange()}
          inputProps={{ className: "text-primary font-weight-500" }}
          MenuProps={{ className: 'choose_field_menu' }}
          className="text-middle"
        >
          {this.state.yearList.map(val => {
            return <MenuItem value={val.start.format("DD-MM-YYYY")} className="select-menu-item">{val.start.format("YYYY")}</MenuItem>
          })}
        </Select>
        <Icon className="text-middle" onClick={this.nextYear} color="primary" fontSizeSmall >keyboard_arrow_right</Icon>
      </div>
    )
  }

  // monthFilterList = () => {
  //   return (<div color="primary" fontSizeSmall >
  //     <Icon className="text-middle" onClick={this.prevYear} color="primary" fontSizeSmall>keyboard_arrow_left</Icon>

  //     <Select displayEmpty
  //       value={this.state.date.format("DD-MM-YYYY")}
  //       onChange={this.handleDateChange()}
  //       MenuProps={{ className: 'choose_field_menu' }}
  //       className="text-middle"
  //     >
  //       {this.state.yearList.map(val => {
  //         return <MenuItem value={val.start.format("DD-MM-YYYY")} className="select-menu-item">{val.start.format("MMMM YYYY")}</MenuItem>
  //       })}
  //     </Select>
  //     <Icon className="text-middle" onClick={this.nextYear} color="primary" fontSizeSmall >keyboard_arrow_right</Icon>
  //   </div>)
  // }

  handlePipelineChange = (event, gkey, goaldata) => {

    let selectedPipelineList = this.state.selectedPipelineList;
    let submitGoalData = this.state.submitGoalData;
    let pipelinelist = this.state.pipelineList;

    if (!submitGoalData[gkey])
      submitGoalData[gkey] = [];

    if (!submitGoalData[gkey].pipeline)
      submitGoalData[gkey].pipeline = [];

    if (!submitGoalData[gkey].previousdate)
      submitGoalData[gkey].previousdate = [];

    if (!submitGoalData[gkey].currentdate)
      submitGoalData[gkey].currentdate = [];

    if (!submitGoalData[gkey].nextdate)
      submitGoalData[gkey].nextdate = [];

    if (!submitGoalData[gkey].alldate)
      submitGoalData[gkey].alldate = [];

    submitGoalData[gkey].id = goaldata.id;

    let pipekey = this.findWithAttr(this.state.pipelineList, 'id', event.target.value);
    let isavailable = this.findWithAttr(submitGoalData[gkey].pipeline, 'id', event.target.value);

    if (pipekey != -1 && isavailable == -1) {
      let newpipelinekey = submitGoalData[gkey].pipeline.length;
      submitGoalData[gkey].pipeline[newpipelinekey] = pipelinelist[pipekey];


      submitGoalData[gkey].previousdate[newpipelinekey] = {}
      submitGoalData[gkey].currentdate[newpipelinekey] = {}
      submitGoalData[gkey].nextdate[newpipelinekey] = {}

      if (!submitGoalData[gkey].alldate[newpipelinekey])
        submitGoalData[gkey].alldate[newpipelinekey] = [];

      this.state.yearList.map((quarter) => {
        submitGoalData[gkey].alldate[newpipelinekey].push(
          {
            'value': 0,
            'pipelinekey': newpipelinekey,
            'pipelineid': parseInt(pipelinelist[pipekey].id),
            'startdate': moment(quarter.start).startOf('years').format('YYYY-MM-DD'),
            'enddate': moment(quarter.start).endOf('quarter').format('YYYY-MM-DD')
          }
        )
      })

      this.setState({ submitGoalData })
    }

    let goalParams = [];

    goalParams['savegoal'] = {
      goalid: parseInt(goaldata.id),
      goaltype: parseInt(goaldata.goaltype),
      datetype: parseInt(goaldata.datetype),
      userid: parseInt(goaldata.user.id),
      teamid: (goaldata.user.teamlist && goaldata.user.teamlist.length) ? parseInt(goaldata.user.teamlist[0].team.id) : 0,
      pipline: submitGoalData[gkey].pipeline,
      alldate: submitGoalData[gkey].alldate,
      previousdate: [],
      currentdate: [],
      nextdate: [],
      activity: [],
    };
    goalParams['type'] = 'Pipeline';
    this.props.saveGoal(this.props.apolloClient.client, goalParams, 'pipeline');

  }

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] == value) {
        return i;
      }
    }
    return -1;
  }

  handleChangegoaldata = (event, gkey, goaldetail, data, alldatakey) => {



    let selectedPipelineList = this.state.selectedPipelineList;
    let submitGoalData = this.state.submitGoalData;
    let pipelinelist = this.state.pipelineList;

    let current_data_key = submitGoalData[gkey].pipeline.length - 1;

    if (!submitGoalData[gkey])
      submitGoalData[gkey] = [];

    if (!submitGoalData[gkey].alldate)
      submitGoalData[gkey].alldate = [];

    if (!submitGoalData[gkey].goaldata)
      submitGoalData[gkey].goaldata = [];

    if (!submitGoalData[gkey].alldate[data.pipelinekey])
      submitGoalData[gkey].alldate[data.pipelinekey] = [];

    submitGoalData[gkey].alldate[data.pipelinekey][alldatakey].value = event.target.value;

    submitGoalData[gkey].goaldata = goaldetail;

    this.setState({ submitGoalData })

    let goalParams = {
      goalid: parseInt(goaldetail.id),
      goaltype: parseInt(goaldetail.goaltype),
      datetype: parseInt(goaldetail.datetype),
      userid: parseInt(goaldetail.user.id),
      teamid: goaldetail.user.teamlist && goaldetail.user.teamlist.length ? parseInt(goaldetail.user.teamlist[0].team.id) : 0,
      pipline: submitGoalData[gkey].pipeline,
      previousdate: [],
      currentdate: [],
      nextdate: [],
      activity: [],
      alldate: submitGoalData[gkey].alldate
    };

    setTimeout(() => {
      this.props.saveGoal(this.props.apolloClient.client, goalParams);
    }, 500);

  }

  handleDeletePipelineGoal = (deletegoal, pipelinedata) => {

    this.setState({
      deletegoalmessage: "Are you sure you want to delete " + pipelinedata.name + " pipeline from " + deletegoal.user.name + " goal ?",
      isDeleteConfirmDialoOpen: !this.state.isDeleteConfirmDialoOpen,
      goaldelete: deletegoal,
      deletepipeline: pipelinedata,
    });
  };

  confirmDeletePipelineGoal = () => {
    let goaldelete = this.state.goaldelete;
    let deletepipeline = this.state.deletepipeline;
    let submitGoalData = this.state.submitGoalData;

    let pipelinekey = this.findWithAttr(goaldelete.pipline, 'id', deletepipeline.id);
    let submitgoaldatakey = this.findWithAttr(submitGoalData, 'id', goaldelete.id)

    if (submitgoaldatakey != -1) {
      let submitpipelinekey = this.findWithAttr(submitGoalData[submitgoaldatakey].pipeline, 'id', deletepipeline.id);
      if (submitpipelinekey != -1) {
        submitGoalData[submitgoaldatakey].alldate.splice(submitpipelinekey, 1);
        submitGoalData[submitgoaldatakey].pipeline.splice(submitpipelinekey, 1);
      }
      // console.log(submitGoalData[submitgoaldatakey],'submitGoalData');
      this.setState({ submitGoalData })
    }

    if (pipelinekey != -1) {
      goaldelete.alldate.splice(pipelinekey, 1);
      goaldelete.pipline.splice(pipelinekey, 1);
    }

    let goalParams = [];

    goalParams['savegoal'] = {
      goalid: parseInt(goaldelete.id),
      goaltype: parseInt(goaldelete.goaltype),
      datetype: parseInt(goaldelete.datetype),
      userid: parseInt(goaldelete.user.id),
      teamid: (goaldelete.user.teamlist && goaldelete.user.teamlist.lenght > 0) ? parseInt(goaldelete.user.teamlist[0].team.id) : 0,
      pipline: goaldelete.pipline,
      alldate: goaldelete.alldate,
      previousdate: [],
      currentdate: [],
      nextdate: [],
      activity: [],
    };
    goalParams['type'] = 'Pipeline data';
    this.props.saveGoal(this.props.apolloClient.client, goalParams);
    this.closegoalRemoveDialoge()
  }

  handleDeleteGoal = (deletegoal) => {
    this.setState({
      deletegoalmessage: "Are you sure you want to delete " + deletegoal.user.name + " goal ?",
      isDeleteConfirmDialoOpen: !this.state.isDeleteConfirmDialoOpen,
      goaldeleteid: parseInt(deletegoal.id),
    });
  };

  confirmDeleteGoal = () => {
    this.props.deleteGoal(this.props.apolloClient.client, { goalid: parseInt(this.state.goaldeleteid), datetype: this.state.datetype, goaltype: this.state.goaltype });

    this.closegoalRemoveDialoge()
  }

  setthisyear = () => {
    let date = moment();
    let previousdate = moment(date).subtract("1", "years");
    let nextdate = moment(date).add("1", "years");
    this.setState({ date, previousdate, nextdate })
  }

  closegoalRemoveDialoge = () => {
    this.setState({ deletegoalmessage: "", isDeleteConfirmDialoOpen: false, goaldeleteid: "", goaldelete: [], deletepipeline: [], })
  }

  handleChangeSelectedList = (event) => {
    let usersListArray = this.state.usersListArray;
    if (event.target.value == 'all-users') {
      let goalParams = { datetype: parseInt(this.state.datetype), goaltype: parseInt(this.state.goaltype) };
      this.props.getGoalList(this.props.apolloClient.client, goalParams);

      this.setState({ selectedUserList: event.target.value });
    } else {
      let teammembersParams = { teamid: parseInt(event.target.value) };
      let goalParams = { datetype: parseInt(this.state.datetype), goaltype: parseInt(this.state.goaltype) };
      this.props.getGoalList(this.props.apolloClient.client, goalParams);
      setTimeout(() => {
        this.props.getTeamMember(this.props.apolloClient.client, teammembersParams);
      }, 100);

      this.setState({ selectedUserList: event.target.value });
    }
  }

  render() {
    const { teamList, deletepipeline, pipelinetotal, submitGoalData, selectedPipelineList, pipelineList, goalList, selectedUserList, usersListArray, selectedPipeline, shouldOpenSaleRevenueGoalsDialog, minimizeGoalDialog, FullGoalDialog, value, valueone, valuetwo, valuethree, valuefour } = this.state;

    return (
      <React.Fragment>
        <Grid container justify="space-between" className="bg-default">
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                <Button className="btn_Primary" variant="contained" color="primary" onClick={() => this.setState({ shouldOpenSaleRevenueGoalsDialog: true })} size="small" >Add User</Button>
              </Grid>
              <Grid item className="ml-30">
                <Select displayEmpty
                  name="selectedUserList"
                  value={selectedUserList}
                  onChange={this.handleChangeSelectedList}
                  className="w-100x"
                  MenuProps={{ className: 'choose_field_menu' }}
                >
                  <MenuItem value={"all-users"} className="select-menu-item">All Users({goalList.length})</MenuItem>
                  {
                    (teamList.length > 0) && teamList.map((team) => (
                      <MenuItem value={team.id} className="select-menu-item">{team.teamname}</MenuItem>
                    ))
                  }
                </Select>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <Grid item><lable className="text-lable font-size-14" onClick={this.setthisyear}><Button>THIS YEAR</Button></lable></Grid>
              <Grid item className="ml-16">{this.yearFilterList()}</Grid>
            </Grid>
          </Grid>
        </Grid>
        <TableContainer className="simple_table h-vh-auto">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="15%">Name</TableCell>
                <TableCell width="15%">Team</TableCell>
                <TableCell width="15%">Pipeline</TableCell>
                <TableCell align="center" width="15%">{moment(this.state.date).subtract("1", "year").format("YYYY")}</TableCell>
                <TableCell className="text-primary" align="center" width="15%">{this.state.date.format("YYYY")}</TableCell>
                <TableCell align="center" width="15%">{moment(this.state.date).add("1", "year").format("YYYY")}</TableCell>
                <TableCell align="center" width="10%">ACTION</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(goalList && goalList.length > 0) ?
                goalList.map((goaldata, goalkey) => (
                  <TableRow>
                    <TableCell>
                      <Grid container spacing={1}>
                        {/* <Grid item>
                          <Avatar className={"avatar_circle color_"+ goaldata.user.name.charAt(0).toLowerCase(0)}>{goaldata.user.name.charAt(0).toUpperCase()}</Avatar>
                        </Grid> */}
                        <Grid item>
                          <h6>{goaldata.user.name}</h6>
                          <p>{goaldata.user.email}</p>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      {(goaldata.user.teamlist && goaldata.user.teamlist.length > 0) && goaldata.user.teamlist.map((team, key) => (
                        <>{((goaldata.user.teamlist.length - 1) <= key) ? team.team.teamname : team.team.teamname + ", "}</>
                      ))}
                    </TableCell>
                    <TableCell>
                      {goaldata.pipline && goaldata.pipline.map((pipelinedata, pipelinekey) => (
                        <>
                          <InputLabel className="pt-10 pb-5">{pipelinedata.name}</InputLabel>
                        </>
                      ))}
                      <FormControl className="w-100 input_bx">
                        <Select
                          className="pt-5"
                          name="selectedPipeline"
                          value={"0"}
                          onChange={(event, gkey, goal) => this.handlePipelineChange(event, goalkey, goaldata)}
                          MenuProps={{ className: 'choose_field_menu' }}
                        >
                          <MenuItem value="0">Add Pipeline</MenuItem>
                          {pipelineList && pipelineList.map((pipeline) => (
                            <MenuItem value={pipeline.id}>{pipeline.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell align="center">
                      {
                        goaldata.pipline && goaldata.pipline.map((pipe, pipkey) => (
                          goaldata.alldate[pipkey].map((pipelinedatas, alldatakey) => (
                            (pipelinedatas && pipelinedatas.startdate == moment(this.state.previousdate).startOf('years').format('YYYY-MM-DD')) &&
                            <FormControl fullWidth>
                              {/* <InputLabel htmlFor="standard-adornment-amount">Amount</InputLabel> */}
                              <Input
                                id="standard-adornment-amount"
                                value={pipelinedatas.value && pipelinedatas.value}
                                type="number"
                                name={"previousdata"}
                                onChange={(event, gkey, goaldetail, previousdata, allkey) => this.handleChangegoaldata(event, goalkey, goaldata, pipelinedatas, alldatakey)}
                                startAdornment={this.state.goaltype === 1 && <InputAdornment position="start">$</InputAdornment>}
                              />
                            </FormControl>
                          ))
                        ))
                      }
                      <FormControl fullWidth>
                        {/* <InputLabel htmlFor="standard-adornment-amount">Amount</InputLabel> */}
                        <Input
                          id="standard-adornment-amount"
                          type="number"
                          value={"select pipeline"}
                          disabled={true}
                          name={"previousdata"}
                          onChange={(event, gkey, goaldetail, previousdata) => this.handleChangegoaldata(event, goalkey, goaldata)}
                          startAdornment={this.state.goaltype === 1 && <InputAdornment position="start">$</InputAdornment>}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell align="center">
                      {
                        goaldata.pipline && goaldata.pipline.map((pipe, pipkey) => (
                          goaldata.alldate[pipkey].map((pipelinedatas, alldatakey) => (
                            (pipelinedatas && pipelinedatas.startdate == moment(this.state.date).startOf('years').format('YYYY-MM-DD')) &&
                            <FormControl fullWidth>
                              {/* <InputLabel htmlFor="standard-adornment-amount">Amount</InputLabel> */}
                              <Input
                                id="standard-adornment-amount"
                                value={pipelinedatas.value && pipelinedatas.value}
                                type="number"
                                name={"previousdata"}
                                onChange={(event, gkey, goaldetail, previousdata, allkey) => this.handleChangegoaldata(event, goalkey, goaldata, pipelinedatas, alldatakey)}
                                startAdornment={this.state.goaltype === 1 && <InputAdornment position="start">$</InputAdornment>}
                              />
                            </FormControl>
                          ))
                        ))
                      }
                      <FormControl fullWidth>
                        {/* <InputLabel htmlFor="standard-adornment-amount">Amount</InputLabel> */}
                        <Input
                          id="standard-adornment-amount"
                          type="number"
                          value={"select pipeline"}
                          disabled={true}
                          name={"previousdata"}
                          onChange={(event, gkey, goaldetail, previousdata) => this.handleChangegoaldata(event, goalkey, goaldata)}
                          startAdornment={this.state.goaltype === 1 && <InputAdornment position="start">$</InputAdornment>}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell align="center">
                      {
                        goaldata.pipline && goaldata.pipline.map((pipe, pipkey) => (
                          goaldata.alldate[pipkey].map((pipelinedatas, alldatakey) => (
                            (pipelinedatas && pipelinedatas.startdate == moment(this.state.nextdate).startOf('years').format('YYYY-MM-DD')) &&
                            <FormControl fullWidth>
                              {/* <InputLabel htmlFor="standard-adornment-amount">Amount</InputLabel> */}
                              <Input
                                id="standard-adornment-amount"
                                value={pipelinedatas.value && pipelinedatas.value}
                                type="number"
                                name={"previousdata"}
                                onChange={(event, gkey, goaldetail, previousdata, allkey) => this.handleChangegoaldata(event, goalkey, goaldata, pipelinedatas, alldatakey)}
                                startAdornment={this.state.goaltype === 1 && <InputAdornment position="start">$</InputAdornment>}
                              />
                            </FormControl>
                          ))
                        ))
                      }
                      <FormControl fullWidth>
                        {/* <InputLabel htmlFor="standard-adornment-amount">Amount</InputLabel> */}
                        <Input
                          id="standard-adornment-amount"
                          type="number"
                          value={"select pipeline"}
                          disabled={true}
                          name={"previousdata"}
                          onChange={(event, gkey, goaldetail, previousdata) => this.handleChangegoaldata(event, goalkey, goaldata)}
                          startAdornment={this.state.goaltype === 1 && <InputAdornment position="start">$</InputAdornment>}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell align="center">
                      {
                        (goaldata.pipline && goaldata.pipline.length > 0) ?

                          goaldata.pipline.map((pipeline) => (
                            <div className="amount_button">
                              {/* <IconButton className="pt-10" onClick={(deletegoal) => this.handleDeleteGoal(goaldata)}> */}
                              <IconSmall className="mt-10 mb-5" onClick={(deletegoal, pipelinedata) => this.handleDeletePipelineGoal(goaldata, pipeline)}>clear</IconSmall>
                              {/* </IconButton> */}
                            </div>
                          ))
                          :
                          <div className="amount_button cursor-pointer">
                            <IconButton className="pt-10" onClick={(deletegoal) => this.handleDeleteGoal(goaldata)}><IconSmall>delete</IconSmall></IconButton>
                          </div>

                      }
                    </TableCell>
                  </TableRow>
                ))
                :
                <TableRow>
                  <TableCell colSpan="7">
                    <LoadingState
                      icon={"flag"}
                      title="No Goals Added Yet."
                      subtitle="Click ADD USER to get started setting goals"
                    />
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
        {(goalList && goalList.length > 0) &&
          <TableContainer className="simple_table">
            <Table>
              <TableFooter>
                <TableRow>
                  <TableCell width="15%" rowSpan={pipelineList.length + 1}>GOAL TOTAL</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="15%"></TableCell>
                  <TableCell width="15%">
                    {
                      // (goalList && goalList.length > 0) &&
                      // goalList.map((goaldata, goalkey)=>(
                      (pipelineList && pipelineList.length) && pipelineList.map((pipe, pipkey) => (
                        <div>{pipe.name}</div>
                      ))
                      // ))
                    }
                  </TableCell>
                  <TableCell align="center" width="15%">
                    {
                      (pipelineList && pipelineList.length) && pipelineList.map((pipe, pipkey) => (
                        pipelinetotal && pipelinetotal.map((total, totalkey) => (
                          ((total && total[pipe.id]) && total[pipe.id].startdate == moment(this.state.previousdate).startOf('years').format('YYYY-MM-DD')) &&
                          <div>{this.state.goaltype === 1 && "$"}{total[pipe.id].totalValue}</div>
                        ))
                      ))
                    }
                  </TableCell>
                  <TableCell align="center" width="15%">
                    {
                      (pipelineList && pipelineList.length) && pipelineList.map((pipe, pipkey) => (
                        pipelinetotal && pipelinetotal.map((total, totalkey) => (
                          ((total && total[pipe.id]) && total[pipe.id].startdate == moment(this.state.date).startOf('years').format('YYYY-MM-DD')) &&
                          <div>{this.state.goaltype === 1 && "$"}{total[pipe.id].totalValue}</div>
                        ))
                      ))
                    }
                  </TableCell>
                  <TableCell align="center" width="15%">
                    {
                      (pipelineList && pipelineList.length) && pipelineList.map((pipe, pipkey) => (
                        pipelinetotal && pipelinetotal.map((total, totalkey) => (
                          ((total && total[pipe.id]) && total[pipe.id].startdate == moment(this.state.nextdate).startOf('years').format('YYYY-MM-DD')) &&
                          <div>{this.state.goaltype === 1 && "$"}{total[pipe.id].totalValue}</div>
                        ))
                      ))
                    }
                  </TableCell>
                  <TableCell width="10%"></TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        }
        {shouldOpenSaleRevenueGoalsDialog && (
          <GoalsDialog
            handleClose={this.handleGoalDialogClose}
            open={shouldOpenSaleRevenueGoalsDialog}
            handleMinimize={this.handleGoalDialogMinimize}
            handleFull={this.handleGoalDialogFull}
            minimizeScreen={minimizeGoalDialog}
            fullScreen={FullGoalDialog}
            data={this}
          />
        )}
        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={this.state.isDeleteConfirmDialoOpen}
          onClose={this.closegoalRemoveDialoge}
          disableBackdropClick
          aria-labelledby="ar-remove-dialog-title"
        >
          <DialogTitle id="ar-remove-dialog-title">{this.state.deletegoalmessage}</DialogTitle>
          <DialogActions>
            <Button onClick={this.closegoalRemoveDialoge} color="primary">Cancel</Button>
            {
              (deletepipeline && deletepipeline.id) ?
                <Button type="Button" onClick={() => this.confirmDeletePipelineGoal()} className="btn_Primary"> Ok </Button>
                :
                <Button type="Button" onClick={() => this.confirmDeleteGoal()} className="btn_Primary"> Ok </Button>
            }
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,
});

const mapDispatchToProps = dispatch => {
  return {
    saveGoal: (client, request) => dispatch(actionCreators.saveGoal(client, request)),
    deleteGoal: (client, request) => dispatch(actionCreators.deleteGoal(client, request)),

    getPipelines: (client) => dispatch(actionCreators.getPipelines(client)),
    getUserList: (client) => dispatch(actionCreators.getUserList(client)),
    getGoalList: (client, request) => dispatch(actionCreators.getGoalList(client, request)),
    getTeamMember: (client, request) => dispatch(actionCreators.getTeamMember(client, request)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsQuarterlyGoal);