import React, { Component } from "react";
import SettingSidebar from '../../Layout/SettingSidebarMenu';
import { Card, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import TaskAutomationTab from "./TaskAutomationTab";


import * as actions from '../../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';
import moment, { now } from 'moment';

class TaskAutomation extends Component {
    state = {
        workflowCheckbox: true,
        opportunityCheckbox: true,
        companyCheckbox: true,
        personCheckbox: true,
        taskflowList:[],
        userSettings:[],
        deleteid:"",
        // settingObject:[],
        timezoneList:[],
        selectedTimezone:"",

    };

    componentDidMount(){
        this.props.getTaskAutomationList(this.props.apolloClient.client);
        // this.props.getTimezoneList(this.props.apolloClient.client);
    }

    componentWillReceiveProps(props) {
        
        if(props.settingReducer.taskautomationlist && props.settingReducer.taskautomationlist.data){
            this.setState({taskflowList: props.settingReducer.taskautomationlist.data});
        }

        if(props.settingReducer.timezoneList.data && this.state.timezoneList.length == 0) {
            const data = props.settingReducer.timezoneList.data;
            this.setState({
                timezoneList:data,
            })
        }
        
        if (props.settingReducer.automationList && props.settingReducer.automationList.data) {
            this.setState({ workflowList: props.settingReducer.automationList.data })  
        }

        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            const settingData = props.settingReducer.userSettingArray.data;
            // console.log(settingData,'settingDatasettingDatasettingData');
            this.setState({
                userSettings: settingData,
                selectedTimezone:settingData.automationtimezone,
            })
        }
    }


    render() {
        let {taskflowList} = this.state;

        return (
            <React.Fragment>
                <SettingSidebar />
                <div className="lead_content right-16 settings_view">
                    <Card elevation={3} className="h-100">
                        <div className="d-flex justify-content-between mt-16 ml-16 mr-16 mb-12">
                            <h3 className="mb-0">Task automation</h3>
                            <Link to="/settings/add-new-task-automation/"><Button variant="contained" color="primary" size="small" >Add New Task Automation</Button></Link>
                        </div>
                        <TaskAutomationTab automationData={taskflowList} />
                    </Card>
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {
        settingReducer: state.settingReducer,
        apolloClient: state.apolloClient
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getTaskAutomationList: (client) => dispatch(actions.getTaskAutomationList(client)),
        deleteAutomation: (client, request) => dispatch(actions.deleteAutomation(client, request)),
        updateAutomationStatus: (client, request) => dispatch(actions.updateAutomationStatus(client, request)),
        updateAutomationTimezone: (client, request) => dispatch(actions.updateAutomationTimezone(client, request)),
        
        getTimezoneList: (client) => dispatch(actions.getTimezoneList(client)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(TaskAutomation);