import React, { Component } from "react";
import {
    Checkbox, IconButton, MenuItem, Grid,
    TableContainer, Card, Paper, Select,
    TextField, FormControl, InputLabel, Button,
    NativeSelect, Snackbar, Icon, Fade, CircularProgress,
    CardContent, Typography, Table, TableBody, TableCell,
    TableHead, TableRow, Link
} from "@material-ui/core";
import { connect } from "react-redux";
import * as actionCreators from '../../../../store/actions/index';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

class ZendeskIntegrations extends Component {
    state = {
        selection: [],
        integrationId: 1,
        isDataLoading: true,
        rows: [
            { id: 1, name: "onleads", label: "Lead" },
            { id: 2, name: "onopportunities", label: "Opportunities" },
            { id: 3, name: "oncompanies", label: "Companies" },
            { id: 4, name: "onpeople", label: "Contact" },
        ],
        rows_Status: [
            { id: "1", name: "NewChecked", label: "New" },
            { id: "2", name: "OpenChecked", label: "Open" },
            { id: "3", name: "PendingChecked", label: "Pending" },
            { id: "4", name: "HoldChecked", label: "Hold" },
            { id: "5", name: "SolvedChecked", label: "Solved" },
            { id: "6", name: "ClosedChecked", label: "Closed" },
        ],
        data: "",
        onleads: "",
        onopportunities: "",
        oncompanies: "",
        onpeople: "",
        max_num_tickets: "",
        sort_tickets_by: "",
        NewChecked: "",
        OpenChecked: "",
        PendingChecked: "",
        HoldChecked: "",
        SolvedChecked: "",
        ClosedChecked: "",
        ticketstatus: "",
        keyGen: [],
        ticket_valid_status: false,
    };
    componentDidMount() {
        let req = {};
        req.status = true;
        req.integrationid = this.state.integrationId;
        this.props.UpdateIntegrationStatus(this.props.apolloClient.client, req);
        let val = this.state.integrationId;
        this.props.getIntegrationDetail(this.props.apolloClient.client, val);
    }
    componentWillReceiveProps(props) {
        if (props.settingReducer.getintegationDetails.data) {
            if (props.settingReducer.getintegationDetails.data !== this.state.data) {
                const data = props.settingReducer.getintegationDetails.data;
                this.setState({
                    isDataLoading: false,
                    data: data,
                    onleads: data.onleads,
                    oncompanies: data.oncompanies,
                    onopportunities: data.onopportunities,
                    onpeople: data.onpeople,
                    max_num_tickets: data.maxnumberrecords,
                    sort_tickets_by: data.sortby,
                    ticket_status: data.ticketstatus
                });
                const test = props.settingReducer.getintegationDetails.data.ticketstatus;
                if (test && test !== null) {
                    let array = test.split(',');
                    this.setState({ keyGen: array });
                    if (this.includes("1", array)) {
                        this.setState({ NewChecked: true });
                    }
                    if (this.includes("2", array)) {
                        this.setState({ OpenChecked: true });
                    }
                    if (this.includes("3", array)) {
                        this.setState({ PendingChecked: true });
                    }
                    if (this.includes("4", array)) {
                        this.setState({ HoldChecked: true });
                    }
                    if (this.includes("5", array)) {
                        this.setState({ SolvedChecked: true });
                    }
                    if (this.includes("6", array)) {
                        this.setState({ ClosedChecked: true });
                    }
                }

            }
        }
    }

    updateSettings = () => {
        this.setState({ isDataLoading: true });
        let ticket = this.state.keyGen.toString();
        let value1 = '';
        if (ticket && ticket !== null) {
            value1 = this.state.keyGen.toString();
            this.setState({ ticket_valid_status: false });
        } else {
            value1 = '1';
            this.setState({ NewChecked: true, ticket_valid_status: true });
            this.setState({ keyGen: ["1"] });
        }
        let val = {
            integration: {
                integrationid: this.state.integrationId,
                status: true,
                email: this.state.data.email,
                sortby: this.state.sort_tickets_by,
                domain: this.state.data.domain,
                accesstoken: this.state.data.accesstoken,
                refereshtoken: "",
                maxnumberrecords: this.state.max_num_tickets !== "" ? parseFloat(this.state.max_num_tickets) : null,
                oncompanies: this.state.oncompanies,
                onleads: this.state.onleads,
                onopportunities: this.state.onopportunities,
                onpeople: this.state.onpeople,
                onproject: false,
                ticketstatus: value1,
            }
        }
        let data = [];
        data['data'] = val;
        let id = this.state.integrationId;
        let req = { data, id };
        this.props.saveIntegrationSetting(this.props.apolloClient.client, req);
    }
    handleCheckBoxChange = (event) => {
        this.setState({
            [event.target.name]: event.target.type === "checkbox" ?
                event.target.checked
                :
                event.target.value
        }, () => this.updateSettings())
    }

    handleTicketStatusChange(event, value) {
        let evetchanged = this.state.keyGen;
        if (event.target.checked) {
            this.setState({
                [event.target.name]: event.target.checked,
                keyGen: evetchanged.concat([value.id])
            }, () => this.updateSettings())
        } else {
            let status = evetchanged.filter(function (val) { return val !== value.id });
            this.setState({
                keyGen: status, [event.target.name]: event.target.checked
            }, () => this.updateSettings())
        }
    }


    handleDrawerToggle = () => {
        window.location.href = '/settings/integrations';
    }

    includes = (k, main) => {
        for (var i = 0; i < main.length; i++) {
            if (main[i] === k) {
                return true;
            }
        }
        return false;
    }

    render() {
        let { keyGen, ticket_valid_status } = this.state;
        return (
            <React.Fragment>
                {/* <Grid> */}
                <div className="v_scrollI">
                    <div style={{ margin: '10px' }}>
                        <Grid>
                            <IconButton onClick={this.handleDrawerToggle}>
                                <ArrowBackIcon style={{ color: '#2B41B2', width: "15px" }} />
                            </IconButton>
                            <div style={{ margin: '-34px 0px 0px 35px' }}>
                                <Link className="text-small text_link" style={{ color: '#2B41B2' , cursor: 'pointer' }} to='/settings/integrations' >
                                    Back to Integrations
                                </Link>
                            </div>
                        </Grid>
                        <Typography className="p-10" variant="h5" color="inherit" style={{ color: 'black' }}>Zendesk Integrations</Typography>
                        <Typography className="text-hint p-10" variant="p" color="inherit" style={{ color: 'black' }}>
                            Choose where you want to see Zendesk tickets
                        </Typography>
                    </div>
                    <div className="mt-20">
                        <Typography className="text-small-14 font-size-20 p-10" style={{ margin: '10px' }} variant="p" color="inherit">
                            Enable Zendesk Integration for records
                        </Typography>
                        <div style={{ margin: '14px' }} className="mt-20">
                            <Card className="signup-card">
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Record Type</TableCell>
                                                <TableCell>Show on Record</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.rows.map((row) => (
                                                <TableRow >
                                                    <TableCell component="th" scope="row">{row.label}</TableCell>
                                                    <TableCell>
                                                        <div style={{ margin: "0 0px 0px 30px" }}>
                                                            <Checkbox
                                                                checked={this.state[row.name]}
                                                                name={row.name}
                                                                onChange={(event) => this.handleCheckBoxChange(event)}
                                                                color="primary"
                                                            />
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </div>
                    </div>
                    <div className="IntegrationBlock_contents" >
                        <div className="zendesk_Integrations_card">
                            <CardContent>
                                <Grid item xs={5} style={{ padding: '20px' }}>
                                    <FormControl className="w-100 input_bx">
                                        <InputLabel>Choose the maximum number of tickets to show</InputLabel>
                                        <Select
                                            name="max_num_tickets"
                                            value={this.state.max_num_tickets}
                                            onChange={this.handleCheckBoxChange}
                                        >
                                            <MenuItem key={"5"} value={"5"}>5</MenuItem>
                                            <MenuItem key={"10"} value={"10"}>10</MenuItem>
                                            <MenuItem key={"15"} value={"15"}>15</MenuItem>
                                            <MenuItem key={"20"} value={"20"}>20</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <InputLabel className="text-hint p-10" style={{ color: 'black', margin: '10px' }}>
                                    Choose which statuses to show tickets for
                                </InputLabel>
                                {this.state.rows_Status.map((row) => (
                                    <div style={{ margin: '0 0 0 15px' }}>
                                        <div style={{ width: '400px', display: 'flex' }} >
                                            <Checkbox
                                                checked={this.state[row.name]}
                                                name={row.name}
                                                onChange={event => this.handleTicketStatusChange(event, row)}
                                                color="primary"
                                            />
                                            <InputLabel className="text-hint p-10" style={{ color: 'black', margin: '10px' }}>
                                                {row.label}
                                            </InputLabel>
                                        </div>
                                    </div>
                                ))}
                                {ticket_valid_status === true ? <p style={{ color: 'red', margin: '25px' }} >Please Select atleast 1 ticket status</p> : ""}
                                <Grid item xs={5} style={{ padding: '20px' }}>
                                    <FormControl className="w-100 input_bx">
                                        <InputLabel>Choose which field to sort tickets by</InputLabel>
                                        <Select
                                            name="sort_tickets_by"
                                            value={this.state.sort_tickets_by}
                                            onChange={this.handleCheckBoxChange}
                                        >
                                            <MenuItem key={"created_at"} value={"created_at"}>Created Date</MenuItem>
                                            <MenuItem key={"last_modified"} value={"last_modified"}>Last Modified Date</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </CardContent>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        apolloClient: state.apolloClient,
        settingReducer: state.settingReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        saveIntegrationSetting: (client, req) => dispatch(actionCreators.saveIntegrationSetting(client, req)),
        getIntegrationDetail: (client, request) => dispatch(actionCreators.getIntegrationDetail(client, request)),
        UpdateIntegrationStatus: (client, request) => dispatch(actionCreators.UpdateIntegrationStatus(client, request)),
    }
}
export default (connect(mapStateToProps, mapDispatchToProps)(ZendeskIntegrations));
