import React, { Component } from "react";
import { IconButton, Icon, MenuItem, withStyles, Fab, Button, DialogContent, Dialog, DialogActions, DialogTitle, Avatar } from "@material-ui/core";
import DropdownDefault from "../Layout/DropdownDefault";
import ProfileDetails from "./ProfileDetails";
import * as actions from '../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';
import { Redirect, Route } from 'react-router-dom'
import moment from 'moment';
import ConvertLeadDialog from './convertLeadDialog'
import CreateNewTaskDialog from "../Tasks/CreateNewTaskDialog";
import InboxComposeDialog from "../CommonComposeMail/InboxComposeDialog";
import CreatelogDialog from "../CommonLogUI/CreatelogDialog";

const IconButtonWhite = withStyles(theme => ({
  root: {
    // color: theme.palette.getContrastText(purple[500]),
    backgroundColor: "transparent",
    padding: "0 5px 5px 5px",
  }
}))(IconButton);

const IconSmall = withStyles(() => ({
  root: {
    fontSize: "1.3rem",
  }
}))(Icon);
class ProfileLeft extends Component {
  state = {
    shouldOpenCreateLogDialog: false,
    FullCreateLogDialog: false,
    minimizeLogDialog: false,
    isDeleteLogConfirmDialoOpen: false,
    logvaluetype: '',
    logtype: 0,

    firstname: '',
    lastname: '',
    title: '',
    companyName: '',
    contactTypes: [],
    isDeleteConfirmDialoOpen: false,
    lead_id: '',
    redirecttolead: false,
    colorClass: "",
    isfavorite: false,
    star_icon: 'star_border',
    fieldsObj: {},
    fieldsList: [],
    usersListArray: [],
    leadImage: "",
    shouldOpenConvertLeadDialog: false,
    minimizeConvertLeadDialog: false,
    FullConvertLeadDialog: false,
    countryListArray: [],
    systemTagsList: [],
    companyCurrencyData: [],
    userSettings: "",
    shouldOpenCreateTaskDialog: false,
    FullCreateTaskDialog: false,
    minimizeTaskDialog: false,
    userSelectedEmail: "",
    isOpenedClickEmail: false,
    allSettings: "",
    mainUserData: [],
    teamvisible: true,
    crudPermission: {
      isdelete: true,
      isedit: true,
    },
    isConvertable: true
  };
  getUserPermission = () => {
    if (this.props.settingReducer.userPermissionDetails.data) {
      let udata = this.props.settingReducer.userPermissionDetails.data.data
      if (udata.visibilityaccess.length > 0 &&
        udata.teampermissionstatus === true && this.props.authReducer.userRole.id === 3) {
        let leaddt = udata.visibilityaccess.find(function (element) { return element.sourcetype === 1; });
        let contactdt = udata.visibilityaccess.find(function (e) { return e.sourcetype === 2 });
        let compnaydt = udata.visibilityaccess.find(function (e) { return e.sourcetype === 3 });
        let isConvertable = this.state.isConvertable;
        if (contactdt.iscreate && contactdt.isedit && compnaydt.iscreate && compnaydt.isedit) {
          isConvertable = true
        } else {
          isConvertable = false;
        }
        this.setState({
          isConvertable: isConvertable,
          crudPermission: {
            isdelete: leaddt.isdelete,
            isedit: leaddt.isedit,
          }
        })
      }
    }
  }
  componentWillMount() {
    if (this.props.lead_id) {
      this.setState({
        lead_id: this.props.lead_id,
      });
    }

    this.props.getContactType(this.props.apolloClient.client);

    let val = { typeId: 1 }
    let userData = JSON.parse(localStorage.getItem('user_data_session'))
    let userid = { userid: userData.id }
    this.props.getUserSetting(this.props.apolloClient.client, userid);
    // this.props.customFieldList(this.props.apolloClient.client, val);
    this.props.getCountryList(this.props.apolloClient.client);
    this.props.getUserList(this.props.apolloClient.client);
    this.props.getCompanyCurrency(this.props.apolloClient.client);
    this.props.getTagsList(this.props.apolloClient.client);
    this.props.getEmailSetting(this.props.apolloClient.client);

  }
  componentWillReceiveProps(props) {
    this.getUserPermission();
    if (props.settingReducer.emailsettings.data) {
      const mian_data = props.settingReducer.emailsettings.data
      this.setState({
        allSettings: mian_data,
      })
    }
    if (props.settingReducer.userSettingArray.data && this.state.userSettings == "") {
      const settingData = props.settingReducer.userSettingArray.data;
      this.setState({
        userSettings: settingData,
      })
    }
    if (props.settingReducer.companyCurrencyData.data != null && this.state.companyCurrencyData.length == 0) {
      var cData = props.settingReducer.companyCurrencyData.data;
      this.setState({ companyCurrencyData: cData[0] });
    }
    if (props.settingReducer.systemTagsList.data && this.state.systemTagsList.length == 0) {
      const tagsData = props.settingReducer.systemTagsList.data;
      this.setState({ systemTagsList: tagsData })
    }
    if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
      const usersListArray = props.settingReducer.usersListArray.data;
      this.setState({ usersListArray: usersListArray })
    }
    if (props.settingReducer.customFieldList.data && this.state.fieldsList.length == 0) {
      const customFields = props.settingReducer.customFieldList.data;

      this.setState({ fieldsList: customFields })
      var statuskey = this.findWithAttr(customFields, 'id', "3");
      var defaultKeyStatus = this.findWithAttr(customFields[statuskey].options, 'is_default', true);
      this.setState({
        fieldsObj: {
          ...this.state.fieldsObj,
          ["status"]: { fieldid: "3", values: customFields[statuskey].options[defaultKeyStatus] }
        }
      })
    }

    if (props.leadReducer.leadFieldData && props.leadReducer.leadFieldData.data != null) {
      var data = props.leadReducer.leadFieldData.data;
      var mainIntemplate = {};
      data.map((details, index) => {
        if (details.fieldkey == "name") {
          mainIntemplate.fullname = details.leaddetail[0].fieldvalue;
          mainIntemplate.firstname = (details.leaddetail.length > 0 && details.leaddetail[0].values.firstname) ? details.leaddetail[0].values.firstname : "";
          mainIntemplate.lastname = (details.leaddetail.length > 0 && details.leaddetail[0].values.lastname) ? details.leaddetail[0].values.lastname : "";
        }
        if (details.fieldkey == "email" || details.fieldkey == "phones") {
          mainIntemplate[details.fieldkey] = (details.leaddetail.length > 0 && details.leaddetail[0].values.length > 0) ? details.leaddetail[0].values[0].value : null
        }
        if (details.fieldkey == "company" || details.fieldkey == "title" || details.fieldkey == "address") {
          mainIntemplate[details.fieldkey] = (details.leaddetail.length > 0 && details.leaddetail[0].fieldvalue) ?
            details.leaddetail[0].fieldvalue : null
        }
        if (details.fieldkey == "owner") {
          mainIntemplate.owner = (details.leaddetail.length > 0 && details.leaddetail[0].values.length > 0) ? details.leaddetail[0].values[0].name : null
        }
      })

      let star_ic = data[0].leadfavourite ? 'star' : 'star_border';
      this.setState({ star_icon: star_ic, isfavorite: data[0].leadfavourite });

      var titleKey = this.findWithAttr(data, 'id', "13");
      if (titleKey != -1 && data[titleKey].leaddetail.length > 0) {
        this.setState({
          title: data[titleKey].leaddetail[0].fieldvalue ? data[titleKey].leaddetail[0].fieldvalue : "",
        }, () => this.forceUpdate())
      }

      var companyKey = this.findWithAttr(data, 'id', "6");
      // if (companyKey != -1 && data[companyKey].leaddetail.length > 0 && data[companyKey].leaddetail[0].fieldvalue) { // change for update company even it's value is empty
      if (companyKey != -1 && data[companyKey].leaddetail.length > 0) {
        this.setState({
          companyName: data[companyKey].leaddetail[0].fieldvalue ? data[companyKey].leaddetail[0].fieldvalue : "",
        }, () => this.forceUpdate())
      }

      var nameKey = this.findWithAttr(data, 'id', "1");
      if (nameKey != -1 && data[nameKey].leaddetail.length > 0 && data[nameKey].leaddetail[0].values) {
        var names = data[nameKey].leaddetail[0].values;
        let colorClass = (names.firstname) ? names.firstname.toString().charAt(0).toLowerCase() : "";
        this.setState({
          teamvisible: data[nameKey].leaddetail[0].lead.teamvisible ? data[nameKey].leaddetail[0].lead.teamvisible : false,
          firstname: names.firstname,
          lastname: names.lastname ? names.lastname : "",
          colorClass: colorClass,

        }, () => this.forceUpdate())
      }

      if (data[0].leaddetail && data[0].leaddetail.length > 0 && data[0].leaddetail[0].lead) {
        this.setState({
          leadImage: data[0].image,
        })
      }

      //STRAT setting fieldsObj for update data with lead Image
      this.setState({
        fieldsObj: {
          websites: {
            fieldid: "15",//Website Field
            values: [
              { srno: "1", id: null, value: "" },
            ]
          },
          social: {
            fieldid: "10",
            values: [
              { srno: "1", id: null, value: "" },
            ]
          },
          phones: {
            fieldid: "7",
            values: [
              { srno: "1", id: null, value: "" },
            ]
          },
          email: {
            fieldid: "2",
            values: [
              { srno: "1", id: null, value: "" },
            ]
          },
        },
      }, () => {
        let fieldsObj = this.state.fieldsObj;
        this.setState({ leadFieldData: props.leadReducer.leadFieldData.data })
        let country = "";
        const leadDataForEdit = props.leadReducer.leadFieldData.data;

        var promise = new Promise((resolve, reject) => {
          leadDataForEdit.map((v, i) => {
            //if(!v.leaddetail.length){return false}
            if (v.fieldtype.id == '1' && ((v.fieldkey == "websites" || v.fieldkey == "social" || v.fieldkey == "phones" || v.fieldkey == "email") && v.is_default)) {
              if (v.leaddetail.length > 0 && v.leaddetail[0].values.length > 0) {
                if (v.fieldkey == "email") {
                  this.setState({
                    userSelectedEmail: v.leaddetail[0].values[0].value
                  })
                }
                var data = {
                  fieldid: v.id,
                  values: v.leaddetail[0].values
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }

              }
            } else if (v.fieldtype.id == '1' && v.id == '1') {//for first/last name          
              if (v.leaddetail.length > 0) {
                var data = {
                  fieldid: v.id,
                  leadvalue: v.leaddetail[0].values.firstname
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }
                this.setState({
                  lastname: v.leaddetail[0].values.lastname
                })
              }
            }
            else if (v.fieldtype.id == '1' && v.id == '4') {//for owner field        
              if (v.leaddetail.length > 0 && v.leaddetail[0].values.length && v.leaddetail[0].values[0].id) {
                var companyOwnerKey = this.findWithAttr(this.state.usersListArray, 'id', v.leaddetail[0].values[0].id);
                if (companyOwnerKey !== -1) {
                  var ownerData = {
                    fieldid: v.id,
                    values: this.state.usersListArray[companyOwnerKey]
                  }
                  fieldsObj = {
                    ...fieldsObj,
                    [v.fieldkey]: ownerData,
                  }
                }
              }
            } else if (v.fieldtype.id == '1' && v.fieldkey == 'tags') {//for tag input
              var tagArray = []
              if (v.leaddetail.length > 0 && v.leaddetail[0].values.length > 0) {
                v.leaddetail[0].values.map((v, i) => {
                  tagArray.push(v.value);
                })
              }
              var data = {
                fieldid: v.id,
                values: tagArray
              }
              fieldsObj = {
                ...fieldsObj,
                [v.fieldkey]: data,
              }
            } else if (v.fieldtype.id == '1' && v.id == '14') {//For value with currency Field
              if (v.leaddetail.length > 0 && v.leaddetail[0].values.length > 0) {
                var data = {
                  fieldid: v.id,
                  leadvalue: v.leaddetail[0].values[0].value
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }
              }
            } else if (v.fieldtype.id == '1' || v.fieldtype.id == '6' || v.fieldtype.id == '10' || v.fieldtype.id == '7' || v.fieldtype.id == '9') {//other textfield and number input
              if (v.leaddetail.length > 0 && v.leaddetail.length > 0) {
                var data = {
                  fieldid: v.id,
                  leadvalue: v.leaddetail[0].fieldvalue
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }
              }
            }

            if (v.fieldtype.id == '2' && v.id == "8") {//Text aread with Address field
              if (v.leaddetail.length > 0 && v.leaddetail[0].values.length > 0) {
                var data = {
                  fieldid: v.id,
                  leadvalue: v.leaddetail[0].values[0].street
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }

                this.setState({
                  city: v.leaddetail[0].values[0].city,
                  state: v.leaddetail[0].values[0].state,
                  zipcode: v.leaddetail[0].values[0].zipcode,
                })

                if (v.leaddetail[0].values[0].country !== "" && this.state.countryListArray.length > 0) {
                  var key = this.findWithAttr(this.state.countryListArray, 'name', v.leaddetail[0].values[0].country);
                  country = this.state.countryListArray[key];
                }
              }
            } else if (v.fieldtype.id == '2') {//TextArea
              if (v.leaddetail.length > 0) {
                var data = {
                  fieldid: v.id,
                  leadvalue: v.leaddetail[0].fieldvalue
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }
              }
            }

            if (v.fieldtype.id == "3") {//checkboxes
              if (v.leaddetail.length > 0) {
                var data = {
                  fieldid: v.id,
                  leadvalue: v.leaddetail[0].fieldvalue
                }
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: data,
                }
              }
            }
            //if(this.state.fieldsList && this.state.fieldsList.length >0){
            if (v.fieldtype.id === '4') {//dropdown
              //11-source/3-status/5-visibility
              if (v.id === "11" || v.id === "3" || v.id === "5") {
                if (v.leaddetail.length > 0 && v.leaddetail[0].values.length > 0) {
                  var optId = this.findWithAttr(v.options, 'id', v.leaddetail[0].values[0].id);
                  fieldsObj = {
                    ...fieldsObj,
                    [v.fieldkey]: { fieldid: v.id, values: v.options[optId] },
                  }
                }
              } else {
                if (v.leaddetail.length > 0 && v.leaddetail[0].values.length > 0) {
                  var optId = this.findWithAttr(v.options, 'id', v.leaddetail[0].values[0].id);
                  fieldsObj = {
                    ...fieldsObj,
                    [v.fieldkey]: { fieldid: v.id, values: v.options[optId] },
                  }
                }
              }
            }
            if (v.fieldtype.id === '5') {//multi dropdown
              if (v.leaddetail.length > 0 && v.leaddetail[0].values.length > 0) {
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: { fieldid: v.id, values: v.leaddetail[0].values },
                }
              }
            }
            if (v.fieldtype.id === '8') {//datepicker
              if (v.leaddetail.length > 0 && v.leaddetail[0].fieldvalue != "") {
                //var dt = moment.unix(v.leaddetail[0].fieldvalue).format(this.state.userSettings.dateformat).toString()
                var dt = new Date(moment(v.leaddetail[0].fieldvalue).format('YYYY-MM-DD'))
                dt = new Date(dt)
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: { fieldid: v.id, values: dt },
                }
              } else if (v.leaddetail.length > 0 && v.leaddetail[0].fieldvalue == "") {
                fieldsObj = {
                  ...fieldsObj,
                  [v.fieldkey]: { fieldid: v.id, values: null },
                }
              }
            }
            //}
          })

          this.setState({
            mainUserData: mainIntemplate,
            fieldsObj,
            country,
            isUpdate: false,
          }, () => {
            resolve(true)
          })
        });

        promise.then(result => {
          props.leadReducer.leadFieldData.is_loaded = false;
        });
      })
      //END setting fieldsObj for update data with lead Image


    }

    if (props.lead_id !== undefined) {
      this.setState({
        lead_id: props.lead_id,
      }, () => this.forceUpdate());
    }

  }
  // componentWillUnmount() {
  //   this.setState({
  //     userSelectedEmail: ""
  //   })
  // }
  handleTaskDialogClose = () => {
    this.setState({ shouldOpenCreateTaskDialog: false, FullCreateTaskDialog: false, minimizeTaskDialog: false });
  };
  handleTaskDialogMinimize = () => {
    this.setState(oldStateminimizeTaskDialog => ({ minimizeTaskDialog: !oldStateminimizeTaskDialog.minimizeTaskDialog }));
    this.setState({ FullCreateTaskDialog: false })
  };
  handleTaskDialogFull = () => {
    this.setState(oldStateFullCreateTaskDialog => ({ FullCreateTaskDialog: !oldStateFullCreateTaskDialog.FullCreateTaskDialog }));
    this.setState({ minimizeTaskDialog: false })
  };

  //-----------log functions
  handleLogDialogClose = () => {
    this.setState({ shouldOpenCreateLogDialog: false, FullCreateLogDialog: false, minimizeLogDialog: false });
  };

  handleLogDialogMinimize = () => {
    this.setState(oldStateminimizeLogDialog => ({ minimizeLogDialog: !oldStateminimizeLogDialog.minimizeLogDialog }));
    this.setState({ FullCreateLogDialog: false })
  };

  handleLogDialogFull = () => {
    this.setState(oldStateFullCreateLogDialog => ({ FullCreateLogDialog: !oldStateFullCreateLogDialog.FullCreateLogDialog }));
    this.setState({ minimizeLogDialog: false })

  };
  ////----------------------

  handleLeadDialogClose = () => {
    this.setState({ shouldOpenCreateLeadDialog: false });
  };

  handleLeadDialogMinimize = () => {
    this.setState(oldStateminimizeLeadDialog => ({ minimizeConvertLeadDialog: !oldStateminimizeLeadDialog.minimizeConvertLeadDialog }));
    this.setState({ FullCreateLeadDialog: false })
  };

  handleLeadDialogFull = () => {
    this.setState(oldStateFullCreateLeadDialog => ({ FullConvertLeadDialog: !oldStateFullCreateLeadDialog.FullConvertLeadDialog }));
    this.setState({ minimizeLeadDialog: false })
  };
  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  handleDeleteLead = () => {
    this.toggleDeleteDialog()
  }

  toggleDeleteDialog = () => {
    this.setState({ isDeleteConfirmDialoOpen: !this.state.isDeleteConfirmDialoOpen });
  };
  confirmDeleteCustomField = () => {
    let deleteLeadParams = [];

    deleteLeadParams['deleteid'] = { leadid: [parseFloat(this.state.lead_id)] };
    deleteLeadParams['listval'] = {
      filterdata: [],
      list: {
        limit: 10,
        pageno: 1,
        searchtext: "",
        sorttype: "",
        type: "list",
        fieldId: 0,
        columnname: ""
      },
      filterid: 1
    };

    this.toggleDeleteDialog();
    this.props.deleteLead(this.props.apolloClient.client, deleteLeadParams);
    this.setState({ redirecttolead: true });
  };

  handleFavorite = (lead, status) => {
    let setFavoriteParams = [];

    let fav = !status;
    let star_i = fav ? 'star' : 'star_border';
    this.setState({ isfavorite: fav, star_icon: star_i });

    setFavoriteParams['favorite'] = { status: !status, leadid: lead };
    setFavoriteParams['listval'] = {
      filterdata: [],
      list: {
        limit: 10,
        pageno: 1,
        searchtext: "",
        sorttype: "",
        type: "list",
        fieldId: 0,
        columnname: ""
      },
      filterid: 1
    };

    this.props.addFavouriteLead(this.props.apolloClient.client, setFavoriteParams);
  }
  handleLeadProfileImage = event => {
    var leadFile;
    if (event.target.files[0] == undefined) {
      return false
    }

    leadFile = event.target.files[0]


    let logData = [];
    let submitData = JSON.parse(JSON.stringify(this.state.fieldsObj));
    submitData = Object.values(submitData).map((row) => {
      if (row.fieldid == "1") {
        row.names = { firstname: row.leadvalue, lastname: this.state.lastname }
        delete row['leadvalue'];
      }
      if (row.fieldid == "14") {
        row.values = [{ id: this.state.companyCurrencyData.currencyid.toString(), srno: "1", value: row.leadvalue }]
        delete row['leadvalue'];
        if (row.values[0].value == "") {
          row.values = []
        }
      }

      if (row.fieldid == "15" || row.fieldid == "10" || row.fieldid == "7" || row.fieldid == "2") {
        if (row.values.length == 1 && (row.values[0].id == null || row.values[0].value == "")) {
          row.values = [{ "fieldid": row.fieldid, value: [] }]
        }
        if (row.values.length > 1) {
          row.values.map((val, i) => {
            if (val.value == "") {
              row.values.splice(i, 1);
            }
          })
        }

        if (row.fieldid == "7") {
          if (row.values.length === 1) {
            if (row.values[0].fieldid) {
              row.values = []
            } else {
              if (row.values.length > 0) {
                var foundMobile = this.findWithAttr(row.values, 'id', "2");
                if (foundMobile != -1) {
                  var index = 1;
                  row.values.map((val, i) => {
                    if (val.id == "2") {
                      row.values[i].srno = "1"
                    } else {
                      row.values[i].srno = (index + 1).toString()
                    }
                    index++;
                  })
                }
              }
            }
          } else {
            if (row.values.length > 0) {
              var foundMobile = this.findWithAttr(row.values, 'id', "2");
              if (foundMobile != -1) {
                var index = 1;
                row.values.map((val, i) => {
                  if (val.id == "2") {
                    row.values[i].srno = "1"
                  } else {
                    row.values[i].srno = (index + 1).toString()
                  }
                  index++;
                })
              }
            }
          }
        }
        if (row.fieldid == "15" || row.fieldid == "10" || row.fieldid == "2") {//make value blank array if notfill in this field
          if (row.values.length === 1) {
            if (row.values[0].fieldid) {
              row.values = []
            }
          }
        }
      }
      if (row.fieldid === "3" || row.fieldid === "11" || row.fieldid === "5") {
        row.values = [{ srno: "1", id: row.values.id, value: row.values.name }]
      }
      var key = this.findWithAttr(this.state.fieldsList, 'id', row.fieldid);
      if (this.state.fieldsList[key].fieldtype.id == "4" && this.state.fieldsList[key].is_default == false) {
        row.values = [{ srno: "1", id: row.values.id, value: row.values.optionvalue }]
      }
      if (this.state.fieldsList[key].fieldtype.id == "5" && this.state.fieldsList[key].is_default == false) {
        var ar = [];
        if (row.values.length > 0) {
          row.values.map((val, i) => {
            var optName;
            if (val.optionvalue) {
              optName = val.optionvalue
            } else {
              optName = val.value
            }
            ar.push({ srno: (i + 1).toString(), id: val.id, value: optName })
          })
        }
        row.values = ar
      }

      //validating date fiels
      if (this.state.fieldsList[key].fieldtype.id == "8") {//date field
        if (row.values == null) {
          row.leadvalue = "";
          delete row.values;
        } else if (row.values == "Invalid Date") {
          return false
        } else {
          //row.leadvalue = moment(row.values).format("X");
          var dt = moment(row.values).format('YYYY-MM-DD')
          row.leadvalue = dt;
          delete row.values;
        }
      }

      if (row.fieldid == "12") {
        if (row.values.length > 0) {
          var objArr = [];
          row.values.map((val, k) => {
            var foundKey = this.findWithAttr(this.state.systemTagsList, 'tagname', val);
            var tagId = "0";
            if (foundKey !== -1) {
              tagId = this.state.systemTagsList[foundKey].id
            }
            objArr.push({ "srno": (k + 1).toString(), value: val.toLowerCase(), id: tagId, isdeleted: "0" })
          })

          //add tags from props value which is removed   with id deleted id "1"
          var tagsKeyFromProps = this.findWithAttr(this.props.leadReducer.leadFieldData.data, 'id', "12");
          if (this.props.leadReducer.leadFieldData.data[tagsKeyFromProps].leaddetail.length > 0) {
            var oldTagsValues = this.props.leadReducer.leadFieldData.data[tagsKeyFromProps].leaddetail[0].values;
            if (oldTagsValues.length > 0) {
              oldTagsValues.map((v, k) => {
                var checkKey = this.findWithAttr(objArr, 'value', v.value);
                if (checkKey === -1) {
                  v.isdeleted = "1";
                  v.srno = (objArr.length + 1).toString();
                  objArr.push(v);
                }
              })
            }
          }

          row.values = objArr
          if (this.props.leadReducer.leadFieldData.data[tagsKeyFromProps].leaddetail.length > 0) {
            this.props.leadReducer.leadFieldData.data[tagsKeyFromProps].leaddetail[0].values = objArr;
          }
        }
      }

      if (row.fieldid == "8") {//address field
        row.leadvalue = {
          street: row.leadvalue,
          city: this.state.city,
          state: this.state.state,
          zipcode: this.state.zipcode,
          country: (this.state.country && this.state.country.name) ? this.state.country.name : ""
        }
      }
      if (row.fieldid == "4") {//owner value set
        if (row.values) {
          row.values = [{ "srno": "1", id: row.values.id, value: row.values.email, name: row.values.name }];
        } else {
          row.values = [];
        }
        delete row.leadvalue;
      }
      return row;
    })

    //adding  leaddetailid for update object from this.state.leadFieldData
    submitData = submitData.map((row, i) => {
      var key = this.findWithAttr(this.state.leadFieldData, 'id', row.fieldid);
      if (key !== -1) {
        if (this.state.leadFieldData[key].leaddetail.length > 0) {
          row.leaddetailid = this.state.leadFieldData[key].leaddetail[0].id;
        } else {
          row.leaddetailid = ""
        }
      }


      return row
    })

    //filter null and undefined object 
    submitData = submitData.filter(function (element) {
      return element !== undefined;
    });

    //not to submit if any field is false
    var allowToSubmit = true
    submitData.map((row) => {
      if (!row) {
        allowToSubmit = false
      }
    })
    // console.log("submitData", submitData);
    //return
    if (this.state.lead_id && allowToSubmit) {
      let req = {
        LeadData: submitData,
        leadid: this.state.lead_id,
        leadFile: leadFile,
        updatedfieldid: 0,
        teamvisible: this.state.teamvisible,
      }
      this.props.updateLeadDetail(this.props.apolloClient.client, req);
    }
  };

  openConvertLeadDialog = () => {
    this.setState({ shouldOpenConvertLeadDialog: true });
  };

  handleConvertLeadDialogClose = () => {
    this.setState({ shouldOpenConvertLeadDialog: false });
  };

  opeAddNewTaskPopup = () => {
    var data = {
      recordtype: "lead",
      type: "tasks",
      id: parseInt(this.state.lead_id),
    }
    this.setState({
      newTaskData: data,
    }, () => {
      this.setState({
        shouldOpenCreateTaskDialog: true,
        shouldOpenCreateLogDialog: false,
        shouldOpenConvertLeadDialog: false,
      });
    })
  }
  isOpenedClickEmailToggle = () => {
    this.setState({
      isOpenedClickEmail: !this.state.isOpenedClickEmail
    })
  }
  handleSaveAddLog = (data) => {
    let saveLogparams = [];
    saveLogparams['save'] = {
      mentionuser: data.mentionuser,
      mentiondetail: data.mentiondetail,
      propertyname: data.propertyname,
      propertyid: data.propertyid,
      text: data.text,
      logtime: data.logtime,
      logdate: data.logdate,
      oldvalue: "",
      newvalue: "",
      logtype: data.logtype,
      fieldid: data.fieldid,
      leadid: this.props.lead_id
    };
    let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
    saveLogparams['getlog'] = { emailid: emailParam, leadid: parseFloat(this.props.lead_id), logtypeid: [], activitypeid: [] };

    this.props.saveLeadLog(this.props.apolloClient.client, saveLogparams);
    this.setState({ shouldOpenCreateLogDialog: false });

  }

  render() {
    let user_data = JSON.parse(localStorage.getItem('user_data_session'));
    let allowUserReply = user_data.googlestatus === true && this.state.allSettings.setting_one && this.state.allSettings.setting_one === 1 ? true : false

    let { FullCreateTaskDialog, minimizeTaskDialog, shouldOpenCreateTaskDialog, shouldOpenConvertLeadDialog, shouldOpenCreateLogDialog, redirecttolead, colorClass } = this.state;

    return (
      (redirecttolead) ?
        <Redirect to='/lead/leads-list' />
        :
        <aside className="sidenav">
          <div className="sidenav__user">
            <div className="more_vert_menu">
              <DropdownDefault
                menuButton={
                  <IconButton
                    aria-label="More"
                    aria-haspopup="true"
                  >
                    <Icon>more_vert</Icon>
                  </IconButton>
                }
              >
                {/* <MenuItem>Lookup LinkedIn</MenuItem> */}
                {/* <MenuItem>Customize detail field order</MenuItem> */}
                <MenuItem onClick={() => this.fileInput.click()}><Icon className="mr-8 text-muted">camera_alt</Icon>Change profile image</MenuItem>
                {this.state.crudPermission.isdelete &&
                  <MenuItem onClick={this.handleDeleteLead}><Icon className="mr-8 text-muted">delete</Icon>Delete</MenuItem>
                }
              </DropdownDefault>
            </div>
            <div className="username-photo white_space_nowrap">
              <input style={{ display: 'none' }} accept="image/*" type="file" onChange={this.handleLeadProfileImage}
                ref={fileInput => this.fileInput = fileInput}
              />
              {!this.state.leadImage && <Avatar onClick={() => this.fileInput.click()} className={"avatar_circle color_" + colorClass + " custom-avatar-sidebar"}>{colorClass.toUpperCase()}</Avatar>}
              {this.state.leadImage && <Avatar onClick={() => this.fileInput.click()} className="custom-avatar-sidebar" alt="Lead image" src={this.state.leadImage} />}
              {/* <span className="text_ellipsis username">
                {this.state.firstname} {this.state.lastname}
                <IconButtonWhite className="filled_star" size="small" onClick={(lead, status) => this.handleFavorite(this.state.lead_id, this.state.isfavorite)}>
                  <IconSmall>{this.state.star_icon}</IconSmall>
                </IconButtonWhite>
              </span> */}

              <span className="username">
                <h5 className="text_ellipsis" style={{ maxWidth: "175px" }}>
                {this.state.firstname} {this.state.lastname}
                </h5>
                <IconButtonWhite className="filled_star" size="small" onClick={(lead, status) => this.handleFavorite(this.state.lead_id, this.state.isfavorite)}>
                  <IconSmall>{this.state.star_icon}</IconSmall>
                </IconButtonWhite>
              </span>

              <span className="user_designation">{this.state.title} {(this.state.companyName) && 'at ' + this.state.companyName} </span>
              {/* {!this.state.title && !this.state.companyName && <span className="user_designation">&nbsp;</span>} */}
              {!this.state.title && !this.state.companyName && ""}
            </div>

            <div className="user__menu">
              <div className="text-center">
                <Fab
                  size="small"
                  color="primary"
                  className="btn-primary"
                  aria-label="Note"
                  onClick={() => this.setState({ logtype: 5, logvaluetype: 'Note', shouldOpenCreateLogDialog: true, shouldOpenConvertLeadDialog: false, shouldOpenCreateTaskDialog: false })}
                >
                  <Icon className="primary_color">library_books</Icon>
                </Fab>
                <div className="text-gray">Note</div>
              </div>
              {allowUserReply &&
                this.props.leadReducer.leadFieldData.data ?
                <div className="text-center">
                  <Fab
                    size="small"
                    color="primary"
                    className="btn-primary"
                    aria-label="Email"
                    onClick={() => this.isOpenedClickEmailToggle()}>
                    <Icon className="primary_color">email</Icon>
                  </Fab>
                  <div className="text-gray">Email</div>
                </div>
                : ""
              }
              <div className="text-center">
                <Fab
                  size="small"
                  color="primary"
                  className="btn-primary"
                  aria-label="Log"
                  onClick={() => this.setState({ logtype: 0, logvaluetype: 'Phone Call', shouldOpenCreateLogDialog: true, shouldOpenConvertLeadDialog: false, shouldOpenCreateTaskDialog: false })}
                >
                  <Icon className="primary_color">add_circle</Icon>
                </Fab>
                <div className="text-gray">Log</div>
              </div>
              <div className="text-center">
                <Fab
                  size="small"
                  color="primary"
                  className="btn-primary"
                  aria-label="Task"
                  onClick={() => this.opeAddNewTaskPopup()}
                >
                  <Icon className="primary_color">storage</Icon>
                </Fab>
                <div className="text-gray">Task</div>
              </div>
              <div className="text-center">
                <Fab
                  size="small"
                  color="primary"
                  className="btn-primary"
                  aria-label="Meet"
                  onClick={() => this.setState({ logtype: 0, logvaluetype: 'Meeting', shouldOpenCreateLogDialog: true, shouldOpenConvertLeadDialog: false, shouldOpenCreateTaskDialog: false })}
                >
                  <Icon className="primary_color">calendar_today</Icon>
                </Fab>
                <div className="text-gray">Meet</div>
              </div>
            </div>
            <div>
              {this.state.isConvertable &&
                <Button className="btn_Primary w-100" size="small" onClick={() => this.setState({ shouldOpenConvertLeadDialog: true , shouldOpenCreateLogDialog: false, shouldOpenCreateTaskDialog: false })}>Convert Lead</Button>
              }
            </div>
            {shouldOpenConvertLeadDialog && (
              <ConvertLeadDialog
                handleClose={this.handleConvertLeadDialogClose}
                open={shouldOpenConvertLeadDialog}
                handleMinimize={this.handleLeadDialogMinimize}
                handleFull={this.handleLeadDialogFull}
                minimizeScreen={this.state.minimizeConvertLeadDialog}
                fullScreen={this.state.FullConvertLeadDialog}
                states={this.state}
              />
            )}

            <Dialog
              fullWidth={true}
              maxWidth={'xs'}
              open={this.state.isDeleteConfirmDialoOpen}
              onClose={this.toggleDeleteDialog}
              disableBackdropClick

              aria-labelledby="ar-remove-dialog-title"
            >
              <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to remove this Lead ?"}</DialogTitle>
              <DialogActions>
                <Button onClick={this.toggleDeleteDialog} color="primary">
                  Cancel
                                    </Button>
                <Button type="Button" onClick={() => this.confirmDeleteCustomField()} className="btn_Primary">
                  Ok
                                    </Button>

              </DialogActions>
            </Dialog>

          </div>

          {shouldOpenCreateLogDialog && (
            <CreatelogDialog
              handleClose={this.handleLogDialogClose}
              open={shouldOpenCreateLogDialog}
              handleMinimize={this.handleLogDialogMinimize}
              handleFull={this.handleLogDialogFull}
              minimizeScreen={this.state.minimizeLogDialog}
              fullScreen={this.state.FullCreateLogDialog}
              handleSaveAddLog={this.handleSaveAddLog}
              logvaluetype={this.state.logvaluetype}
              logtype={this.state.logtype}
            />
          )}

          {shouldOpenCreateTaskDialog && (
            <CreateNewTaskDialog
              handleClose={this.handleTaskDialogClose}
              open={shouldOpenCreateTaskDialog}
              handleMinimize={this.handleTaskDialogMinimize}
              handleFull={this.handleTaskDialogFull}
              minimizeScreen={minimizeTaskDialog}
              fullScreen={FullCreateTaskDialog}
              allstates={this.state}
              dataFromSidebar={this.state.newTaskData}
            />
          )}
          {this.state.isOpenedClickEmail &&
            <InboxComposeDialog
              handleClose={this.isOpenedClickEmailToggle}
              open={this.state.isOpenedClickEmail}
              userSelectedEmail={this.state.userSelectedEmail}
              mainUserData={this.state.mainUserData}
              LogDetails={{
                leadid: this.state.lead_id,
                emailid: localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '',
                logtypeid: [],
                activitypeid: [],
              }}
              LogComponent="Lead"
            />
          }
          <div className="ml-16 mr-0">
            <ProfileDetails
              lead_id={this.state.lead_id}
              isOpenedClickEmailToggle={this.isOpenedClickEmailToggle}
            />
          </div>
        </aside>
    );
  }
}

const mapStateToProps = state => {
  return {
    leadReducer: state.leadReducer,
    settingReducer: state.settingReducer,
    contactReducer: state.contactReducer,
    apolloClient: state.apolloClient,
    authReducer: state.authReducer
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getContactType: (client) => dispatch(actions.getContactType(client)),
    deleteLead: (client, request) => { dispatch(actions.deleteLead(client, request)) },
    addFavouriteLead: (client, request) => { dispatch(actions.addFavouriteLead(client, request)) },
    //getLeadFilterList: (client, request) => { dispatch(actions.getLeadFilterList(client, request)) },

    customFieldList: (client, request) => { dispatch(actions.customFieldList(client, request)) },
    getUserList: (client) => dispatch(actions.getUserList(client)),
    updateLeadDetail: (client, request) => { dispatch(actions.updateLeadDetail(client, request)) },
    getCountryList: (client) => dispatch(actions.getCountryList(client)),
    getCompanyCurrency: (client) => dispatch(actions.getCompanyCurrency(client)),
    getTagsList: (client) => dispatch(actions.getTagsList(client)),
    getUserSetting: (client, data) => dispatch(actions.getUserSetting(client, data)),
    getEmailSetting: (client) => dispatch(actions.getEmailSetting(client)),
    saveLeadLog: (client, request) => dispatch(actions.saveLeadLog(client, request)),

  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ProfileLeft);
