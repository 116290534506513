import React, { Component } from "react";
import SettingSidebar from '../../../Layout/SettingSidebarMenu';
import { Box, IconButton, Card, Icon, Tab, Tabs, Typography, Button, Checkbox, Chip, Fab, FormControl, Grid, ListItemText, MenuItem, Select, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter } from "@material-ui/core";
import moment from 'moment';
import PropTypes from 'prop-types';
import GoalsDialog from "./GoalsDialog"
import SalesRevenueTab from "./SalesRevenueTab"
import LeadConvertionsTab from "./LeadConvertionsTab"
import WonOpportunitiesTab from "./WonOpportunitiesTab"
import ActivitiesTab from "./ActivitiesTab"


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={2}>{children}</Box>
    </Typography>
  );
}
const names = [
  'All activity',
  'Email',
  'Meeting',
  'Phone call'

];
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}
function a11in1yProps(index) {
  return {
    id: `tabinside1-${index}`,
    'aria-controls': `tabpanelin1-${index}`,
  };
}
function a11in2yProps(index) {
  return {
    id: `tabinside2-${index}`,
    'aria-controls': `tabpanelin2-${index}`,
  };
}
function a11in3yProps(index) {
  return {
    id: `tabinside2-${index}`,
    'aria-controls': `tabpanelin2-${index}`,
  };
}
function a11in4yProps(index) {
  return {
    id: `tabinside2-${index}`,
    'aria-controls': `tabpanelin2-${index}`,
  };
}

class SettingsGoal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userlist: 10,
      addPipeline: 1,
      shouldOpenSaleRevenueGoalsDialog: false,
      FullGoalDialog: false,
      minimizeGoalDialog: false,
      value: 0,
      valueone: 0,
      valuetwo: 0,
      valuethree: 0,
      valuefour: 0,
      personName: ['All activity'],
      date: moment(),
      weeklist: [],
      monthList: [],
      quaterList: [],
      yearList: []
    }
  }

  componentDidMount = () => {
    
  }
 
  handleChangeTab = (event, newValue) => {
    this.setState({ value: newValue, valueone: 0, valuetwo: 0, valuethree: 0, valuefour: 0, date: moment() })
  }

  handleGoalDialogClose = () => {
    this.setState({ shouldOpenSaleRevenueGoalsDialog: false, FullGoalDialog: false, minimizeGoalDialog: false });
  };
  handleGoalDialogMinimize = () => {
    this.setState(oldStateminimizeGoalDialog => ({ minimizeGoalDialog: !oldStateminimizeGoalDialog.minimizeGoalDialog }));
    this.setState({ FullGoalDialog: false })
  };
  handleGoalDialogFull = () => {
    this.setState(oldStateFullGoalDialog => ({ FullGoalDialog: !oldStateFullGoalDialog.FullGoalDialog }));
    this.setState({ minimizeGoalDialog: false })
  };
  
  render() {
    const { userlist, addPipeline, shouldOpenSaleRevenueGoalsDialog, minimizeGoalDialog, FullGoalDialog, value, valueone, valuetwo, valuethree, valuefour } = this.state;
    return (
      <React.Fragment>
        <SettingSidebar />
        <div className="lead_content right-16 settings_view">
          <Card elevation={3} className="h-100">
            <h3 className="ml-16 mt-16 mb-16">Goals</h3>
            <Tabs
              value={value}
              // onChange={(event)=>this.handleChange(event,event.target.value)}
              onChange={this.handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              className="settings_tabs"
              aria-label="scrollable auto tabs"
            >
              <Tab label="SALSE REVENUE"  {...a11yProps(0)} />
              <Tab label="WON OPPORTUNITIES" {...a11yProps(1)} />
              <Tab label="ACTIVITIES"  {...a11yProps(2)} />
            </Tabs>
            <div className="v_scroll position-relative">
              <TabPanel value={value} index={0} className="h-100">
                <p className="text-lable mt-0 mb-16">Sales revenue goals help you track the value of won opportunities for users in your company.</p>
                <SalesRevenueTab />
              </TabPanel>
              <TabPanel value={value} index={1} className="h-100">
                <p className="text-lable mt-0 mb-16">Won opportunity goals help you track the number of won opportunities for users in your company.</p>
                <WonOpportunitiesTab />
              </TabPanel>
              <TabPanel value={value} index={2} className="h-100">
                <p className="text-lable mt-0 mb-16">Set activity goals to track the number of activities completed by team members.</p>
                <ActivitiesTab />
              </TabPanel>
            </div>
            {shouldOpenSaleRevenueGoalsDialog && (
              <GoalsDialog
                handleClose={this.handleGoalDialogClose}
                open={shouldOpenSaleRevenueGoalsDialog}
                handleMinimize={this.handleGoalDialogMinimize}
                handleFull={this.handleGoalDialogFull}
                minimizeScreen={minimizeGoalDialog}
                fullScreen={FullGoalDialog}
              />
            )}
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

export default SettingsGoal;