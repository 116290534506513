import React, { Component } from "react";
import SettingSidebar from '../../../Layout/SettingSidebarMenu';
import { Box, IconButton, Card, Icon, Tab, Tabs, Typography, Button, Checkbox, Chip, Fab, FormControl, Grid, ListItemText, MenuItem, Select, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter } from "@material-ui/core";
import moment from 'moment';
import PropTypes from 'prop-types';
import GoalsDialog from "./GoalsDialog"


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={2}>{children}</Box>
    </Typography>
  );
}
const names = [
  'All activity',
  'Email',
  'Meeting',
  'Phone call'

];
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}
function a11in1yProps(index) {
  return {
    id: `tabinside1-${index}`,
    'aria-controls': `tabpanelin1-${index}`,
  };
}
function a11in2yProps(index) {
  return {
    id: `tabinside2-${index}`,
    'aria-controls': `tabpanelin2-${index}`,
  };
}
function a11in3yProps(index) {
  return {
    id: `tabinside2-${index}`,
    'aria-controls': `tabpanelin2-${index}`,
  };
}
function a11in4yProps(index) {
  return {
    id: `tabinside2-${index}`,
    'aria-controls': `tabpanelin2-${index}`,
  };
}

class SettingsGoal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userlist: 10,
      addPipeline: 1,
      shouldOpenSaleRevenueGoalsDialog: false,
      FullGoalDialog: false,
      minimizeGoalDialog: false,
      value: 0,
      valueone: 0,
      valuetwo: 0,
      valuethree: 0,
      valuefour: 0,
      personName: ['All activity'],
      date: moment(),
      weeklist: [],
      monthList: [],
      quaterList: [],
      yearList: []
    }
  }

  componentDidMount = () => {
    let date = moment().subtract(52, "weeks");
    let weeklist = [];
    var j = 0;

    for (j = 0; j < 104; j++) {
      weeklist.push({ start: moment(date).add(j, "weeks"), end: moment(date).add(j + 1, "weeks") })
    }

    date = moment().subtract(12, "months");
    let monthList = [];

    for (j = 0; j < 24; j++) {
      monthList.push({ start: moment(date).add(j, "months"), end: moment(date).add(j + 1, "months") })
    }

    date = moment().subtract(4, "quarters");
    let quaterList = [];

    for (j = 0; j < 8; j++) {
      quaterList.push({ start: moment(date).add(j, "quarters"), end: moment(date).add(j + 1, "quarters") })
    }
    date = moment().subtract(5, "years");
    let yearList = [];

    for (j = 0; j < 10; j++) {
      yearList.push({ start: moment(date).add(j, "years"), end: moment(date).add(j + 1, "years") })
    }
    this.setState({ weeklist, monthList, quaterList, yearList })
  }
  next = () => {
    let date = moment(this.state.date).add("1", "week")
    this.setState({ date })
  }
  prev = () => {
    let date = moment(this.state.date).subtract("1", "week")
    this.setState({ date })
  }
  nextMonth = () => {
    let date = moment(this.state.date).add("1", "month")
    this.setState({ date })
  }
  prevMonth = () => {
    let date = moment(this.state.date).subtract("1", "month")
    this.setState({ date })
  }
  nextQuater = () => {
    let date = moment(this.state.date).add("1", "quarters")
    this.setState({ date })
  }
  prevQuater = () => {
    let date = moment(this.state.date).subtract("1", "quarters")
    this.setState({ date })
  }
  nextYear = () => {
    let date = moment(this.state.date).add("1", "year")
    this.setState({ date })
  }
  prevYear = () => {
    let date = moment(this.state.date).subtract("1", "year")
    this.setState({ date })
  }
  handleChangeTab = (event, newValue) => {
    this.setState({ value: newValue, valueone: 0, valuetwo: 0, valuethree: 0, valuefour: 0, date: moment() })
  }
  handleThreeChange = (event, newValue) => {
    this.setState({ valuethree: newValue, date: moment() })
  }
  
  handleOnchange = (event) => {
    this.setState({ personName: event.target.value })
  }

  handleDateChange = () => event => {
    this.setState({ date: moment(event.target.value, "DD-MM-YYYY") })
  }
  handleChange = event => {
    event.persist();
    if (event.target.type === 'checkbox') {
      this.setState({ [event.target.name]: event.target.checked });
    }
    else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };
  handleFormSubmit = () => {
    console.log('submited')
  };
  handleGoalDialogClose = () => {
    this.setState({ shouldOpenSaleRevenueGoalsDialog: false, FullGoalDialog: false, minimizeGoalDialog: false });
  };
  handleGoalDialogMinimize = () => {
    this.setState(oldStateminimizeGoalDialog => ({ minimizeGoalDialog: !oldStateminimizeGoalDialog.minimizeGoalDialog }));
    this.setState({ FullGoalDialog: false })
  };
  handleGoalDialogFull = () => {
    this.setState(oldStateFullGoalDialog => ({ FullGoalDialog: !oldStateFullGoalDialog.FullGoalDialog }));
    this.setState({ minimizeGoalDialog: false })
  };
  yearFilterList = () => {
    return (
      <div color="primary" fontSizeSmall >
        <Icon className="text-middle" onClick={this.prevYear} color="primary" fontSizeSmall>keyboard_arrow_left</Icon>
        <Select displayEmpty
          value={this.state.date.format("DD-MM-YYYY")}
          onChange={this.handleDateChange()}
          MenuProps={{ className: 'choose_field_menu' }}
          className="text-middle"
        >
          {this.state.yearList.map(val => {
            return <MenuItem value={val.start.format("DD-MM-YYYY")} className="select-menu-item">{val.start.format("YYYY")}</MenuItem>
          })}
        </Select>
        <Icon className="text-middle" onClick={this.nextYear} color="primary" fontSizeSmall >keyboard_arrow_right</Icon>
      </div>
    )
  }
  weekFilterList = () => {
    return (<div color="primary" fontSizeSmall >
      <Icon className="text-middle" onClick={this.prev} color="primary" fontSizeSmall>keyboard_arrow_left</Icon>
      <Select displayEmpty
        value={this.state.date.format("DD-MM-YYYY")}
        onChange={this.handleDateChange()}
        MenuProps={{ className: 'choose_field_menu' }}
        className="text-middle"
      >
        {this.state.weeklist.map(val => {
          return <MenuItem value={val.start.format("DD-MM-YYYY")} className="select-menu-item">{val.start.format("MMM DD")} - {val.end.format("MMM DD YYYY")}</MenuItem>
        })}
      </Select>
      <Icon className="text-middle" onClick={this.next} color="primary" fontSizeSmall >keyboard_arrow_right</Icon>
    </div>)
  }
  monthFilterList = () => {
    return (<div color="primary" fontSizeSmall >
      <Icon className="text-middle" onClick={this.prevMonth} color="primary" fontSizeSmall>keyboard_arrow_left</Icon>

      <Select displayEmpty
        value={this.state.date.format("DD-MM-YYYY")}
        onChange={this.handleDateChange()}
        inputProps={{ className: "text-primary font-weight-500" }}
        MenuProps={{ className: 'choose_field_menu' }}
        className="text-middle"
      >
        {this.state.monthList.map(val => {
          return <MenuItem value={val.start.format("DD-MM-YYYY")} className="select-menu-item">{val.start.format("MMMM YYYY")}</MenuItem>
        })}
      </Select>
      <Icon className="text-middle" onClick={this.nextMonth} color="primary" fontSizeSmall >keyboard_arrow_right</Icon>
    </div>)
  }
  quaterFilterList = () => {
    return (<div color="primary" fontSizeSmall >
      <Icon className="text-middle" onClick={this.prevQuater} color="primary" fontSizeSmall>keyboard_arrow_left</Icon>

      <Select displayEmpty
        value={this.state.date.format("DD-MM-YYYY")}
        onChange={this.handleDateChange()}
        MenuProps={{ className: 'choose_field_menu' }}
        className="text-middle"
      >
        {this.state.quaterList.map(val => {
          return <MenuItem value={val.start.format("DD-MM-YYYY")} className="select-menu-item">{val.start.format("MMM YY")} - {val.end.format("MMM YYYY")}</MenuItem>
        })}
      </Select>
      <Icon className="text-middle" onClick={this.nextQuater} color="primary" fontSizeSmall >keyboard_arrow_right</Icon>
    </div>)
  }

  render() {
    const { userlist, addPipeline, shouldOpenSaleRevenueGoalsDialog, minimizeGoalDialog, FullGoalDialog, value, valueone, valuetwo, valuethree, valuefour } = this.state;
    return (
      <React.Fragment> 
        <Paper variant="outlined" square className="tabs_paper">
          <Tabs
            value={valuethree}
            onChange={this.handleThreeChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            className="settings_tabs"
            aria-label="scrollable auto tabs"
          >
            <Tab label="MONTHLY GOALS" className="pl-10 pr-10" {...a11in3yProps(0)} />
            <Tab label="QUARTERLY GOALS" className="pl-10 pr-10"  {...a11in3yProps(1)} />
            <Tab label="YEARLY GOALS" className="pl-10 pr-10"  {...a11in3yProps(2)} />
          </Tabs>
          <TabPanel value={valuethree} index={0} className="h-100 setting_goal">
            <Grid container justify="space-between" className="bg-default">
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    <Button variant="contained" className="btn_Primary" color="primary" onClick={() => this.setState({ shouldOpenSaleRevenueGoalsDialog: true })} size="small" >Add user</Button>
                  </Grid>
                  <Grid item className="ml-30">
                    <Select displayEmpty
                      name="userlist"
                      value={userlist}
                      onChange={this.handleChange}
                      className="w-100x"
                      MenuProps={{ className: 'choose_field_menu' }}
                    >
                      <MenuItem value={10} className="select-menu-item">All user</MenuItem>
                      <MenuItem value={20} className="select-menu-item">abc</MenuItem>
                      <MenuItem value={30} className="select-menu-item">def</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item><lable className="text-lable font-size-14">THIS MONTH</lable></Grid>
                  <Grid item className="ml-16">{this.monthFilterList()}</Grid>
                </Grid>
              </Grid>
            </Grid>
            <TableContainer className="simple_table h-vh-auto">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="22.5%">Name</TableCell>
                    <TableCell width="22.5%">Team</TableCell>
                    <TableCell align="right" width="15%">{moment(this.state.date).subtract("1", "month").format("MMM YYYY")}</TableCell>
                    <TableCell className="text-primary" align="right" width="15%">{this.state.date.format("MMM YYYY")}</TableCell>
                    <TableCell align="right" width="15%">{moment(this.state.date).add("1", "months").format("MMM YYYY")}</TableCell>
                    <TableCell align="center" width="10%">ACTION</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Grid container spacing={1}>
                        <Grid item>
                          <Fab className="bg-dark small-size overflow-hidden" size="small">
                            <h5 className="text-primary m-0 font-weight-normal">JS</h5>
                          </Fab>
                        </Grid>
                        <Grid item>
                          <h6>Justin</h6>
                          <p>Justin@gmail.com</p>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>Spartan</TableCell>
                    <TableCell align="right"><div className="amount_button">$4562 <IconButton size="small"><Icon>edit</Icon></IconButton></div></TableCell>
                    <TableCell align="right"><div className="amount_button">$4562 <IconButton size="small"><Icon>edit</Icon></IconButton></div></TableCell>
                    <TableCell align="right"><div className="amount_button">$4562 <IconButton size="small"><Icon>edit</Icon></IconButton></div></TableCell>
                    <TableCell align="center"><IconButton size="small"><Icon>delete</Icon></IconButton></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer className="simple_table">
              <Table>
                <TableFooter>
                  <TableRow>
                    <TableCell width="22.5%">GOAL TOTAL</TableCell>
                    <TableCell width="22.5%"></TableCell>
                    <TableCell align="right" width="15%">$4562</TableCell>
                    <TableCell align="right" width="15%">$4562</TableCell>
                    <TableCell align="right" width="15%">$4562</TableCell>
                    <TableCell width="10%"></TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={valuethree} index={1} className="h-100 setting_goal">
            <Grid container justify="space-between" className="bg-default">
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    <Button variant="contained" className="btn_Primary" color="primary" onClick={() => this.setState({ shouldOpenSaleRevenueGoalsDialog: true })} size="small" >Add user</Button>
                  </Grid>
                  <Grid item className="ml-30">
                    <Select displayEmpty
                      name="userlist"
                      value={userlist}
                      onChange={this.handleChange}
                      className="w-100x"
                      MenuProps={{ className: 'choose_field_menu' }}
                    >
                      <MenuItem value={10} className="select-menu-item">All user</MenuItem>
                      <MenuItem value={20} className="select-menu-item">abc</MenuItem>
                      <MenuItem value={30} className="select-menu-item">def</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item><lable className="text-lable font-size-14">THIS QUARTER</lable></Grid>
                  <Grid item className="ml-16">{this.quaterFilterList()}</Grid>
                </Grid>
              </Grid>
            </Grid>
            <TableContainer className="simple_table h-vh-auto">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="22.5%">Name</TableCell>
                    <TableCell width="22.5%">Team</TableCell>
                    <TableCell align="right" width="15%">{moment(this.state.date).subtract("1", "quarters").format("MMM DD")} - {moment(this.state.date).format("MMM DD YYYY")}</TableCell>
                    <TableCell className="text-primary" align="right" width="15%">{this.state.date.format("MMM DD")} - {moment(this.state.date).add("1", "quarters").format("MMM DD YYYY")}</TableCell>
                    <TableCell align="right" width="15%">{moment(this.state.date).add("1", "quarters").format("MMM DD")} - {moment(this.state.date).add("2", "quarters").format("MMM DD YYYY")}</TableCell>
                    <TableCell align="center" width="10%">ACTION</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Grid container spacing={1}>
                        <Grid item>
                          <Fab className="bg-dark small-size overflow-hidden" size="small">
                            <h5 className="text-primary m-0 font-weight-normal">JS</h5>
                          </Fab>
                        </Grid>
                        <Grid item>
                          <h6>Justin</h6>
                          <p>Justin@gmail.com</p>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>Spartan</TableCell>
                    <TableCell align="right"><div className="amount_button">$4562 <IconButton size="small"><Icon>edit</Icon></IconButton></div></TableCell>
                    <TableCell align="right"><div className="amount_button">$4562 <IconButton size="small"><Icon>edit</Icon></IconButton></div></TableCell>
                    <TableCell align="right"><div className="amount_button">$4562 <IconButton size="small"><Icon>edit</Icon></IconButton></div></TableCell>
                    <TableCell align="center"><IconButton size="small"><Icon>delete</Icon></IconButton></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer className="simple_table">
              <Table>
                <TableFooter>
                  <TableRow>
                    <TableCell width="22.5%">GOAL TOTAL</TableCell>
                    <TableCell width="22.5%"></TableCell>
                    <TableCell align="right" width="15%">$4562</TableCell>
                    <TableCell align="right" width="15%">$4562</TableCell>
                    <TableCell align="right" width="15%">$4562</TableCell>
                    <TableCell width="10%"></TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={valuethree} index={2} className="h-100 setting_goal">
            <Grid container justify="space-between" className="bg-default">
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    <Button variant="contained" className="btn_Primary" color="primary" onClick={() => this.setState({ shouldOpenSaleRevenueGoalsDialog: true })} size="small" >Add user</Button>
                  </Grid>
                  <Grid item className="ml-30">
                    <Select displayEmpty
                      name="userlist"
                      value={userlist}
                      onChange={this.handleChange}
                      className="w-100x"
                      MenuProps={{ className: 'choose_field_menu' }}
                    >
                      <MenuItem value={10} className="select-menu-item">All user</MenuItem>
                      <MenuItem value={20} className="select-menu-item">abc</MenuItem>
                      <MenuItem value={30} className="select-menu-item">def</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item><lable className="text-lable font-size-14">THIS YEAR</lable></Grid>
                  <Grid item className="ml-16">{this.yearFilterList()}</Grid>
                </Grid>
              </Grid>
            </Grid>
            <TableContainer className="simple_table h-vh-auto">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="22.5%">Name</TableCell>
                    <TableCell width="22.5%">Team</TableCell>
                    <TableCell align="right" width="15%">{moment(this.state.date).subtract("1", "year").format("YYYY")}</TableCell>
                    <TableCell className="text-primary" align="right" width="15%">{this.state.date.format("YYYY")}</TableCell>
                    <TableCell align="right" width="15%">{moment(this.state.date).add("1", "year").format("YYYY")}</TableCell>
                    <TableCell align="center" width="10%">ACTION</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Grid container spacing={1}>
                        <Grid item>
                          <Fab className="bg-dark small-size overflow-hidden" size="small">
                            <h5 className="text-primary m-0 font-weight-normal">JS</h5>
                          </Fab>
                        </Grid>
                        <Grid item>
                          <h6>Justin</h6>
                          <p>Justin@gmail.com</p>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>Spartan</TableCell>
                    <TableCell align="right"><div className="amount_button">$4562 <IconButton size="small"><Icon>edit</Icon></IconButton></div></TableCell>
                    <TableCell align="right"><div className="amount_button">$4562 <IconButton size="small"><Icon>edit</Icon></IconButton></div></TableCell>
                    <TableCell align="right"><div className="amount_button">$4562 <IconButton size="small"><Icon>edit</Icon></IconButton></div></TableCell>
                    <TableCell align="center"><IconButton size="small"><Icon>delete</Icon></IconButton></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer className="simple_table">
              <Table>
                <TableFooter>
                  <TableRow>
                    <TableCell width="22.5%">GOAL TOTAL</TableCell>
                    <TableCell width="22.5%"></TableCell>
                    <TableCell align="right" width="15%">$4562</TableCell>
                    <TableCell align="right" width="15%">$4562</TableCell>
                    <TableCell align="right" width="15%">$4562</TableCell>
                    <TableCell width="10%"></TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </TabPanel>
        </Paper> 
        {shouldOpenSaleRevenueGoalsDialog && (
          <GoalsDialog
            handleClose={this.handleGoalDialogClose}
            open={shouldOpenSaleRevenueGoalsDialog}
            handleMinimize={this.handleGoalDialogMinimize}
            handleFull={this.handleGoalDialogFull}
            minimizeScreen={minimizeGoalDialog}
            fullScreen={FullGoalDialog}
          />
        )}
      </React.Fragment>
    );
  }
}

export default SettingsGoal;