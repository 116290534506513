
import React, { Component } from 'react';
import {
  CircularProgress,
  Paper,
  Grid,
  Icon,
  Avatar,
  Typography,
  Drawer,
  Fab,
} from "@material-ui/core";
import * as actions from "../../../store/actions/index";
import SubjectIcon from '@material-ui/icons/Subject';
import EmailIcon from '@material-ui/icons/Email';
import NoteIcon from '@material-ui/icons/Note';
import TodayIcon from '@material-ui/icons/Today';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import TelegramIcon from '@material-ui/icons/Telegram';
import DescriptionIcon from '@material-ui/icons/Description';
import CallIcon from '@material-ui/icons/Call';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import LeadProfileFrame from "../../Leads/LeadProfileFrame";
import ContactProfileFrame from "../../Contacts/ContactProfileFrame";
import CompanyProfileFrame from "../../Company/CompanyProfileFrame";
import OpportunityProfileFrame from "../../Opportunities/OpportunityProfileFrame";
import TaskProfileFrame from "../../Tasks/TaskProfileFrame";
import ProjectProfileFrame from "../../Projects/ProjectProfileFrame";

class AllSearchLogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 'Everything',
      data: [],
      serachText: '',
      isDataLoading: true,
      lead_id: '',
      companyLogs: [],
      IsOpenLeadSideBar: false,
      IsOpenConatctSideBar: false,
      IsOpenCompanySideBar: false,
      IsOpenOppoSideBar: false,
      IsOpenProjectSideBar: false,
      IsOpenTaskSideBar: false,
    };
  }

  componentWillMount() {
    this.setState({ masterSerachData: [] });
    this.props.settingReducer.masterSerach = [];
  }

  componentDidMount() {
    if (this.props.masterSerachData) {
      const data = this.props.masterSerachData;
      // console.log(this.props.masterSerachData, "all serach log");
      this.displayArray(data);
      this.setState({ serachText: this.props.serachText });
    }
  }

  componentWillReceiveProps(props) {
    if (props.masterSerachData) {
      const data = props.masterSerachData;
      if (data !== this.state.data) {
        this.displayArray(data);
      }
    }
    this.setState({ serachText: props.serachText });
  }

  displayArray = (data) => {
    if (data) {
      var NewArray = [];

      data.map((item) => {
        let arrayItem = {};

        if (item.recordtype === "lead") {
          let logdata = [];
          let url = '';
          if (item.__leaddetail__) {
            arrayItem.id = item.id;
            url = "/lead/lead/" + item.id;
            logdata = this.getDetails(item.__leaddetail__);
          } else if (item.__source__) {
            arrayItem.id = item.__source__.id;
            url = "/lead/lead/" + item.__source__.id;
            logdata = this.getLogDetails(item, item.__source__.__leaddetail__ && item.__source__.__leaddetail__);
          }
          arrayItem.data = logdata;
          arrayItem.URLMain = url;
        }
        else if (item.recordtype === "contact") {
          let logdata = [];
          let url = '';
          if (item.__contactdetail__) {
            arrayItem.id = item.id;
            url = "/contact/contact/" + item.id;
            logdata = this.getDetails(item.__contactdetail__);
          } else if (item.__source__) {
            arrayItem.id = item.__source__.id;
            url = "/contact/contact/" + item.__source__.id;
            logdata = this.getLogDetails(item, item.__source__.__contactdetail__ && item.__source__.__contactdetail__);
          }
          arrayItem.data = logdata;
          arrayItem.URLMain = url;
        }
        else if (item.recordtype === "company") {
          let logdata = [];
          let url = '';
          if (item.__companydetail__) {
            arrayItem.id = item.id;
            url = "/company/company/" + item.id;
            logdata = this.getDetails(item.__companydetail__);
          } else if (item.__source__) {
            arrayItem.id = item.__source__.id;
            url = "/company/company/" + item.__source__.id;
            logdata = this.getLogDetails(item, item.__source__.__companydetail__ && item.__source__.__companydetail__);
          }
          arrayItem.data = logdata;
          arrayItem.URLMain = url;
        }
        else if (item.recordtype === "opportunities") {
          let logdata = [];
          let url = '';
          if (item.__opportunitiesdetail__) {
            arrayItem.id = item.id;
            url = "/opportunities/opportunities/" + item.id;
            logdata = this.getDetails(item.__opportunitiesdetail__);
          } else if (item.__source__) {
            arrayItem.id = item.__source__.id;
            url = "/opportunities/opportunities/" + item.__source__.id;
            logdata = this.getLogDetails(item, item.__source__.__opportunitiesdetail__ && item.__source__.__opportunitiesdetail__);
          }
          arrayItem.icon = "business";
          arrayItem.data = logdata;
          arrayItem.URLMain = url;
        }
        else if (item.recordtype === "project") {
          let logdata = [];
          let url = '';
          if (item.__projectdetail__) {
            arrayItem.id = item.id;
            url = "/project/projects/" + item.id;
            logdata = this.getDetails(item.__projectdetail__);
          } else if (item.__source__) {
            arrayItem.id = item.__source__.id;
            url = "/project/projects/" + item.__source__.id;
            logdata = this.getLogDetails(item, item.__source__.__projectdetail__ && item.__source__.__projectdetail__);
          }
          arrayItem.icon = "business_center";
          arrayItem.URLMain = url;
          arrayItem.data = logdata;
        }
        else if (item.recordtype === "task") {
          let logdata = [];
          if (item.__taskdetail__) {
            arrayItem.id = item.id;
            logdata = this.getDetails(item.__taskdetail__);
          } else if (item.__source__) {
            arrayItem.id = item.__source__.id;
            logdata = this.getLogDetails(item, item.__source__.__taskdetail__ && item.__source__.__taskdetail__);
          }
          arrayItem.icon = "list";
          arrayItem.URLMain = "/task/task-list";
          arrayItem.data = logdata;
        }

        arrayItem.recordtype = item.recordtype;
        arrayItem.userImageURL = item.image;
        NewArray.push(arrayItem);
      })
      this.setState({ data: NewArray });
      setTimeout(() => {
        this.setState({ isDataLoading: false });
      }, 4000);
    }
  }

  getDetails = (DetailsData) => {
    let demo = {};
    DetailsData && DetailsData.length > 0 && DetailsData.map((dt, i) => {
      if (dt.__field__ && dt.allvalues.length > 0) {
        if (dt.__field__.fieldkey === "owner" && dt.allvalues[0].name) {
          demo[dt.__field__.fieldkey] = dt.allvalues[0].name
        }
        else if (dt.allvalues[0].value) {
          demo[dt.__field__.fieldkey] = dt.allvalues[0].value
        }
      }
    })
    return demo;
  };

  getLogDetails = (ALlLogData, data) => {
    let demo = {};
    demo['name'] = data[0].fieldvalue;
    demo['text'] = ALlLogData.text;
    demo['propertyname'] = ALlLogData.propertyname;
    return demo;
  };

  detailsUI = (op) => {
    let maindata = op.data;
    const keyword = this.state.serachText;
    const entries = Object.values(maindata);

    let matchcase = entries.filter(word => {
      let e = word.toString()
      return e.toLowerCase().includes(keyword.toLowerCase())
    });

    matchcase.push(maindata.text, maindata.propertyname);

    // console.log('matchcase ::-->' , matchcase);

    const propertyname = matchcase.find(function (element) { return element === maindata.propertyname });
    let ValueofMatchCasees = [
      { Name: 'email', field: matchcase.find(function (element) { return element === maindata.email }), },
      { Name: 'owner', field: matchcase.find(function (element) { return element === maindata.owner }) },
      { Name: 'phones', field: matchcase.find(function (element) { return element === maindata.phones }) },
      { Name: 'description', field: matchcase.find(function (element) { return element === maindata.description }) },
      { Name: 'tags', field: matchcase.find(function (element) { return element === maindata.tags }) },
      { Name: 'websites', field: matchcase.find(function (element) { return element === maindata.websites }) },
      { Name: 'social', field: matchcase.find(function (element) { return element === maindata.social }) },
      { Name: 'activitytype', field: matchcase.find(function (element) { return element === maindata.activitytype }) },
      { Name: 'visibility', field: matchcase.find(function (element) { return element === maindata.visibility }) },
      { Name: 'value', field: matchcase.find(function (element) { return element === maindata.value }) },
      { Name: 'priority', field: matchcase.find(function (element) { return element === maindata.priority }) },
      { Name: 'primarycontact', field: matchcase.find(function (element) { return element === maindata.primarycontact }) },
      { Name: 'pipeline', field: matchcase.find(function (element) { return element === maindata.pipeline }) },
      { Name: 'stage', field: matchcase.find(function (element) { return element === maindata.stage }) },
      { Name: 'source', field: matchcase.find(function (element) { return element === maindata.source }) },
      { Name: 'Email Domain', field: matchcase.find(function (element) { return element === maindata.emaildomain }) },
      { Name: 'address', field: matchcase.find(function (element) { return element === maindata.address }) },
      { Name: 'related To', field: matchcase.find(function (element) { return element === maindata.relatedto }) },
      { Name: 'contact Type', field: matchcase.find(function (element) { return element === maindata.contacttype }) },
      { Name: 'contact Type', field: matchcase.find(function (element) { return element === maindata.contacttype }) },
      { Name: propertyname, field: matchcase.find(function (element) { return element === maindata.text }), icon: 'logs' },
    ];
    let data = ValueofMatchCasees.filter((ele, ind) => ele.field !== undefined && ind === ValueofMatchCasees.findIndex(elem => elem.field === ele.field));
    const ALLData = data.length > 2 ? data.slice(0, 2) : data;

    return (
      ALLData && ALLData.map((item, index) => {

        return (
          <React.Fragment>
            {item.Name && item.field ?
              <Grid className="text_ellipsis d-flex mt-16">
                {item.icon ?
                  <>
                    {item.Name === 'Email' && <EmailIcon style={{ width: '16px' }} />}
                    {item.Name === 'Note' && <NoteIcon style={{ width: '16px' }} />}
                    {item.Name === 'Meeting' && <TodayIcon style={{ width: '16px' }} />}
                    {item.Name === 'WhatsApp Discussion' && <QuestionAnswerIcon style={{ width: '16px' }} />}
                    {item.Name === 'Lunch' && <TelegramIcon style={{ width: '16px' }} />}
                    {item.Name === 'To Do' && <DescriptionIcon style={{ width: '16px' }} />}
                    {item.Name === 'Phone Call' && <CallIcon style={{ width: '16px' }} />}

                  </>
                  : <SubjectIcon style={{ width: '16px' }} />
                }
                <Grid className="ml-16 mt-6" key={index} >
                  <Grid className="font-weight-400 text-gray" >
                    {item.Name.charAt(0).toUpperCase() + item.Name.slice(1)}
                  </Grid>
                  <Grid
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      width: '600px',
                      paddingTop: '4px',
                    }}
                    className="text_ellipsis d-flex mt--4 font-weight-400 text-gray"
                  >
                    <b>{item.field}</b>
                  </Grid>
                </Grid>
              </Grid> : ''}
          </React.Fragment>
        );
      }))
  };

  handleDrawerRight = (Comman_id, type) => {
    // console.log(Comman_id, type, "Comman_id, type");
    if (type === "lead") {
      if (this.state.lead_id && (this.state.lead_id === Comman_id)) { } else {
        this.setState({
          lead_id: Comman_id,
          IsOpenLeadSideBar: true,
        }, () => { this.forceUpdate() })
      }
    }
    else if (type === 'contact') {
      if (this.state.contact_id && (this.state.contact_id === Comman_id)) { } else {
        this.setState({
          contact_id: Comman_id,
          IsOpenConatctSideBar: true,
        }, () => { this.forceUpdate() })
      }
    }
    else if (type === 'company') {
      if (this.state.company_id && (this.state.company_id === Comman_id)) { } else {
        this.setState({
          company_id: Comman_id,
          IsOpenCompanySideBar: true,
        }, () => { this.forceUpdate() })
      }
    }
    else if (type === 'opportunities') {
      if (this.state.opportunities_id && (this.state.opportunities_id === Comman_id)) { } else {
        this.setState({
          opportunities_id: Comman_id,
          IsOpenOppoSideBar: true,
        }, () => { this.forceUpdate() })
      }
    }
    else if (type === 'project') {
      if (this.state.project_id && (this.state.project_id === Comman_id)) { } else {
        this.setState({
          project_id: Comman_id,
          IsOpenProjectSideBar: true,
        }, () => { this.forceUpdate() })
      }
    }
    else if (type === 'task') {
      if (this.state.task_id && (this.state.task_id === Comman_id)) { } else {
        this.setState({
          task_id: Comman_id,
          IsOpenTaskSideBar: true,
        }, () => { this.forceUpdate() })
      }
    }
  };

  closeDrawerRight = (e) => {
    this.setState({
      IsOpenLeadSideBar: false, lead_id: "",
      IsOpenConatctSideBar: false, contact_id: "",
      IsOpenCompanySideBar: false, company_id: "",
      IsOpenOppoSideBar: false, opportunities_id: "",
      IsOpenProjectSideBar: false, project_id: "",
      IsOpenTaskSideBar: false, task_id: "",
    });
  };

  render() {
    const {
      data,
      isDataLoading,
      leadLogs,
      contactLogs,
      companyLogs,
      opportunityLogs,
      taskLogs,
      projectLogs,
      serachText,
      IsOpenLeadSideBar,
      IsOpenConatctSideBar,
      IsOpenCompanySideBar,
      IsOpenOppoSideBar,
      IsOpenTaskSideBar,
      IsOpenProjectSideBar,
    } = this.state;
    return (
      <React.Fragment>
        {isDataLoading === true ?
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{
              minHeight: '10vh',
              backgroundColor: 'rgba(0,0,0,0)',
              margin: '100px'
            }}
          >
            <Grid item xs={3}>
              <CircularProgress disableShrink size={25} />
            </Grid>
          </Grid>
          :
          <>
            {data && data.length > 0 ?
              data.map((item) => (
                <>
                  <Paper
                    square
                    className="p-16 ml-20 gray-on-hover"
                    style={{ width: '900px' }}
                    elevation={0}>
                    <Grid
                      container
                      style={{ cursor: 'pointer' }}
                      spacing={1}
                      onClick={(e) => this.handleDrawerRight(item.id, item.recordtype)}
                      className="m-10">
                      <Grid className="d-flex">
                        <Grid item className="mr-20">
                          {item.icon ?
                            <Fab
                              size="small"
                              className="small-size overflow-hidden custom-avatar"
                              style={{ padding: '20px' }}
                            >
                              <Icon>{item.icon}</Icon>
                            </Fab>
                            :
                            <>
                              {!item.userImageURL &&
                                <Avatar
                                  style={{ cursor: 'pointer' }}
                                  className={`avatar_circle color_${item.data.name}` ?
                                    item.data.name.charAt(0).toUpperCase()
                                    : ""}>
                                  {(item.data.name) ?
                                    item.data.name.charAt(0).toUpperCase()
                                    : ""}
                                </Avatar>}
                              {item.userImageURL &&
                                <Avatar
                                  style={{ cursor: 'pointer' }}
                                  className="avatar_circle color_a"
                                  alt="Lead image"
                                  src={item.userImageURL} />}
                            </>
                          }
                        </Grid>
                        <div className="white_space_nowrap">
                          {item.data &&
                            <>
                              <span className="font-weight-500 company_user_name">
                                <Link to={item.URLMain}>
                                  {item.data.name}
                                </Link>
                              </span>
                              <Grid className="text_ellipsis d-flex" >
                                {item.data.title && item.data.title + '|'}
                                {item.data.comapny && item.data.comapny + '|'}
                                {item.data.status && item.data.status}
                              </Grid>
                              {this.detailsUI(item)}
                            </>
                          }
                        </div>
                      </Grid>
                    </Grid>
                  </Paper></>
              ))
              :
              <Paper
                square
                className="p-16 mb-16 paper-bx gray-on-hover"
                style={{
                  margin: '10px 10px 10px 20px',
                  minWidth: '900px'
                }} >
                <Grid className="d-flex">
                  <Icon style={{ margin: '6px' }}>
                    search
                    </Icon>
                  <Typography
                    className="font-size-16 "
                    variant="h6"
                    color="inherit" >
                    No search results found for "{serachText}"
                  </Typography>
                </Grid>
                <Grid style={{ margin: '20px 0 0 25px' }}>
                  Here are a few suggestions:
                  <ul>
                    <li>Double check your spelling.</li>
                    <li>Try using fewer words.</li>
                    <li>Try using words that are less specific.</li>
                  </ul>
                </Grid>
              </Paper>
            }
          </>
        }
        {this.state.lead_id &&
          <Drawer
            width={"100px"}
            variant="temporary"
            anchor={"right"}
            className="drawer_right"
            open={IsOpenLeadSideBar}
            onClose={this.closeDrawerRight}
            ModalProps={{
              keepMounted: true
            }}
          >
            <LeadProfileFrame
              lead_id={this.state.lead_id}
              leadLogs={leadLogs}
              handleClose={this.closeDrawerRight} />
          </Drawer>
        }
        {this.state.contact_id &&
          <Drawer
            width={"100px"}
            variant="temporary"
            anchor={"right"}
            className="drawer_right"
            open={IsOpenConatctSideBar}
            onClose={this.closeDrawerRight}
            ModalProps={{
              keepMounted: true
            }}
          >
            <ContactProfileFrame contact_id={this.state.contact_id} contactLogs={contactLogs} handleClose={this.closeDrawerRight} />
          </Drawer>
        }
        {this.state.company_id &&
          <Drawer
            width={"100px"}
            variant="temporary"
            anchor={"right"}
            className="drawer_right"
            open={IsOpenCompanySideBar}
            onClose={this.closeDrawerRight}
            ModalProps={{
              keepMounted: true
            }}
          >
            <CompanyProfileFrame company_id={this.state.company_id} companyLogs={companyLogs} handleClose={this.closeDrawerRight} />
          </Drawer>
        }
        {this.state.opportunities_id &&
          <Drawer
            width={"100px"}
            variant="temporary"
            anchor={"right"}
            className="drawer_right"
            open={IsOpenOppoSideBar}
            onClose={this.closeDrawerRight}
            ModalProps={{
              keepMounted: true
            }}
          >
            <OpportunityProfileFrame
              opportunities_id={this.state.opportunities_id}
              opportunityLogs={opportunityLogs}
              phrase={serachText}
              handleClose={this.closeDrawerRight} />
          </Drawer>
        }
        {this.state.task_id &&
          <Drawer
            width={"100px"}
            variant="temporary"
            anchor={"right"}
            className="drawer_right"
            open={IsOpenTaskSideBar}
            onClose={this.closeDrawerRight}
            ModalProps={{
              keepMounted: true
            }}
          >
            <TaskProfileFrame task_id={this.state.task_id} taskLogs={taskLogs} handleClose={this.closeDrawerRight} />
          </Drawer>
        }
        {this.state.project_id &&
          <Drawer
            width={"100px"}
            variant="temporary"
            anchor={"right"}
            className="drawer_right"
            open={IsOpenProjectSideBar}
            onClose={this.closeDrawerRight}
            ModalProps={{
              keepMounted: true
            }}
          >
            <ProjectProfileFrame project_id={this.state.project_id} projectLogs={projectLogs} handleClose={this.closeDrawerRight} />
          </Drawer>
        }

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    masterSearch: (client, req) => { dispatch(actions.masterSearch(client, req)) },
  };
};

export default (connect(mapStateToProps, mapDispatchToProps)(withRouter(AllSearchLogs)));