import React, { Component } from "react";
import {
    IconButton, MenuItem, Grid, Select, FormControl,
    InputLabel, Snackbar, Icon, Fade, CircularProgress, CardContent, Typography,
    withStyles
} from "@material-ui/core";
import { connect } from "react-redux";
import * as actionCreators from '../../../store/actions/index';
import { Link } from "react-router-dom";


const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.2rem",
        fontWeight: 500
    }
}))(Icon);

class SlackIntegrations extends Component {
    state = {
        integrationId: 2,
        lead_one: "No selection",
        opp_status_one: "No selection",
        opp_status_two: "No selection",
        opp_status_three: "No selection",
        opp_warning_one: "No selection",
        opp_warning_two: "No selection",
        opp_warning_three: "No selection",
        opp_update_one: "No selection",
        opp_update_two: "No selection",
        opp_update_three: "No selection",
        opp_update_four: "No selection",
        channelList: [],
        statusUpdateList: [{
            id: 1,
            name: "opp_status_one",
            title: "Choose a channel to get notified when Opportunities are Won"
        }, {
            id: 2,
            name: "opp_status_two",
            title: "Choose a channel to get notified when Opportunities are Lost"
        }, {
            id: 3,
            name: "opp_status_three",
            title: "Choose a channel to get notified when Opportunities are Abandoned"
        }],
        oppWarningsList: [{
            id: 1,
            name: "opp_warning_one",
            title: "Choose a channel to get notified when Opportunity stages move backwards"
        }, {
            id: 2,
            name: "opp_warning_two",
            title: "Choose a channel to get notified when Opportunity close dates get pushed out"
        }, {
            id: 3,
            name: "opp_warning_three",
            title: "Choose a channel to get notified when Opportunity values get decreased"
        }],
        generalUpdateList: [{
            id: 1,
            name: "opp_update_one",
            title: "Choose a channel to get notified when Opportunity is created"
        }, {
            id: 2,
            name: "opp_update_two",
            title: "Choose a channel to get notified when Opportunity stages move forward"
        }, {
            id: 3,
            name: "opp_update_three",
            title: "Choose a channel to get notified when Opportunity close dates get pulled in"
        }, {
            id: 4,
            name: "opp_update_four",
            title: "Choose a channel to get notified when Opportunity values get increased"
        }],
        isDataLoading: true,
    };
    componentDidMount() {
        this.props.getSlackChannelList(this.props.apolloClient.client)
        this.props.getSlackSetting(this.props.apolloClient.client);
    }
    componentWillReceiveProps(props) {
        if (props.settingReducer.slackSettings.data && this.state.allSettings !== props.settingReducer.slackSettings.data) {
            const settigsData = props.settingReducer.slackSettings.data;
            this.setState({
                isDataLoading: false,
                allSettings: settigsData,
                lead_one: settigsData.lead_one,
                opp_status_one: settigsData.opp_status_one,
                opp_status_three: settigsData.opp_status_three,
                opp_status_two: settigsData.opp_status_two,
                opp_update_four: settigsData.opp_update_four,
                opp_update_one: settigsData.opp_update_one,
                opp_update_three: settigsData.opp_update_three,
                opp_update_two: settigsData.opp_update_two,
                opp_warning_one: settigsData.opp_warning_one,
                opp_warning_three: settigsData.opp_warning_three,
                opp_warning_two: settigsData.opp_warning_two,
            })
        }
        if (props.settingReducer.slackChannelList.data && this.state.channelList.length == 0) {
            const data = props.settingReducer.slackChannelList.data;
            this.setState({
                channelList: data
            })
        }
    }
    handleOppStatusUpdates = (event) => {
        event.persist();
        console.log(event.target.name, event.target.value);
        this.setState({
            [event.target.name]: event.target.value
        }, () => this.updateSettings())
    };
    updateSettings = () => {
        this.setState({ isDataLoading: true })
        let req = {
            lead_one: this.state.lead_one,
            opp_status_one: this.state.opp_status_one,
            opp_status_three: this.state.opp_status_three,
            opp_status_two: this.state.opp_status_two,
            opp_update_four: this.state.opp_update_four,
            opp_update_one: this.state.opp_update_one,
            opp_update_three: this.state.opp_update_three,
            opp_update_two: this.state.opp_update_two,
            opp_warning_one: this.state.opp_warning_one,
            opp_warning_three: this.state.opp_warning_three,
            opp_warning_two: this.state.opp_warning_two,
        }
        this.props.saveSlackSetting(this.props.apolloClient.client, req);
    }
    handleDrawerToggle = () => {
        window.location.href = '/settings/integrations';
    }
    render() {
        return (
            <React.Fragment >
                <div className="v_scrollI">
                    <div style={{ margin: '10px' }}>
                        <IconButton size="small" color="primary" onClick={this.handleDrawerToggle}>
                            <IconSmall>arrow_back</IconSmall>
                        </IconButton>
                        <div style={{ margin: '-22px 0 0 35px' }}>
                            <Link className="text-small text_link" style={{ color: '#2B41B2' }} to='/settings/integrations' >
                                Back to Integrations
                            </Link>
                        </div>
                        <Typography className="p-10" variant="h5" color="inherit" style={{ color: 'black' }}>Slack Settings</Typography>
                        <Typography className="text-hint p-10" variant="p" color="inherit" style={{ color: 'black' }}>
                            Choose whether you want notifications for Leads, Opportunities or both. For more information, contact our support team.
                        </Typography>
                    </div>
                    <div className="mt-20">
                        {/* <Typography className="text-small-14 font-size-20 p-10" style={{ margin: '10px' }} variant="p" color="inherit">
                            Lead Updates
                        </Typography>
                    </div>
                    <div className="IntegrationBlock_contents" >
                        <div className="zendesk_Integrations_card">
                            <CardContent>
                                <Grid item style={{ padding: '20px' }}>
                                    <FormControl className="w-100 input_bx">
                                        <InputLabel>Choose a channel to get notified when leads are created, converted or their status and ownership changes.</InputLabel>
                                        <Select
                                            style={{ width: "260px" }}
                                            name="lead_one"
                                            value={this.state.lead_one}
                                            onChange={event => this.handleOppStatusUpdates(event)}
                                        >
                                            {
                                                this.state.channelList.map((channel, i) => (
                                                    <MenuItem value={channel.channel_id} >
                                                        {channel.channel_name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </CardContent>
                        </div>*/}
                    </div>

                    <div className="mt-20">
                        <Typography className="text-small-14 font-size-20 p-10" style={{ margin: '10px' }} variant="p" color="inherit">
                            Opportunity Status Updates
                        </Typography>
                    </div>
                    <div className="IntegrationBlock_contents" >
                        <div className="zendesk_Integrations_card">
                            <CardContent>
                                {this.state.statusUpdateList.map((statusUpdate, i) => (
                                    <Grid item style={{ padding: '20px' }} key={i}>
                                        <FormControl className="w-100 input_bx">
                                            <InputLabel>{statusUpdate.title}</InputLabel>
                                            <Select
                                                style={{ width: "260px" }}
                                                name={statusUpdate.name}
                                                value={this.state[statusUpdate.name]}
                                                onChange={event => this.handleOppStatusUpdates(event)}
                                            >
                                                {
                                                    this.state.channelList.map((channel, i) => (
                                                        <MenuItem value={channel.channel_id} >
                                                            {channel.channel_name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                ))}
                            </CardContent>
                        </div>
                    </div>
                    <div className="mt-20">
                        <Typography className="text-small-14 font-size-20 p-10" style={{ margin: '10px' }} variant="p" color="inherit">
                            Opportunity Warnings
                        </Typography>
                    </div>
                    <div className="IntegrationBlock_contents" >
                        <div className="zendesk_Integrations_card">
                            <CardContent>
                                {this.state.oppWarningsList.map((oppWarning, i) => (
                                    <Grid item style={{ padding: '20px' }} key={i}>
                                        <FormControl className="w-100 input_bx">
                                            <InputLabel>{oppWarning.title}</InputLabel>
                                            <Select
                                                style={{ width: "260px" }}
                                                name={oppWarning.name}
                                                value={this.state[oppWarning.name]}
                                                onChange={event => this.handleOppStatusUpdates(event)}
                                            >
                                                {
                                                    this.state.channelList.map((channel, i) => (
                                                        <MenuItem value={channel.channel_id} >
                                                            {channel.channel_name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                ))}
                            </CardContent>
                        </div>
                    </div>
                    <div className="mt-20">
                        <Typography className="text-small-14 font-size-20 p-10" style={{ margin: '10px' }} variant="p" color="inherit">
                            General Opportunity Updates
                        </Typography>
                    </div>
                    <div className="IntegrationBlock_contents" >
                        <div className="zendesk_Integrations_card">
                            <CardContent>
                                {this.state.generalUpdateList.map((generalupdate, i) => (
                                    <Grid item style={{ padding: '20px' }} key={i}>
                                        <FormControl className="w-100 input_bx">
                                            <InputLabel>{generalupdate.title}</InputLabel>
                                            <Select
                                                style={{ width: "260px" }}
                                                name={generalupdate.name}
                                                value={this.state[generalupdate.name]}
                                                onChange={event => this.handleOppStatusUpdates(event)}
                                            >
                                                {
                                                    this.state.channelList.map((channel, i) => (
                                                        <MenuItem value={channel.channel_id} >
                                                            {channel.channel_name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                ))}
                            </CardContent>
                        </div>
                    </div>
                </div>
                {
                    this.state.isDataLoading &&
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        open={this.state.isDataLoading}
                        TransitionComponent={Fade}
                        message={<><CircularProgress size={20} style={{ color: 'white' }} /><span style={{ paddingLeft: '6px', color: 'white' }}>{" Loading..."}</span></>}
                        variant={'success'}
                    />
                }
            </React.Fragment >
        );
    }
}

const mapStateToProps = state => {
    return {
        apolloClient: state.apolloClient,
        settingReducer: state.settingReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getSlackChannelList: (client) => dispatch(actionCreators.getSlackChannelList(client)),
        getSlackSetting: (client) => dispatch(actionCreators.getSlackSetting(client)),
        saveSlackSetting: (client, request) => dispatch(actionCreators.saveSlackSetting(client, request)),
    }
}
export default (connect(mapStateToProps, mapDispatchToProps)(SlackIntegrations));
