import * as actionTypes from './actionTypes';
import { gql } from "apollo-boost";
import * as dataSet from './dataSet';
import * as actionCreators from './index';

/**
 * TYPE : GET
 * Getting Contact Type
 */
export const getContactType = (client) => {
    return function (dispatch) {
        const query = gql`
                query getContactType
                {
                    getContactType {
                        id
                        name
                        alias
                        order_no
                        is_allowto_delete
                        createdby
                        createdhost
                        createddate
                        lastmodifiedby
                        lastmodifiedhost
                        lastmodifieddate
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache'
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getContactType);
            dispatch(setResponseDetails(actionTypes.GET_CONTACT_TYPE, return_data));
        }).catch((error) => {
            var returnData = {
                'data': {},
                'error': false,
                'notify_msg': ""
            }
            dispatch(setResponseDetails(actionTypes.GET_CONTACT_TYPE, returnData));
        });
    }
}
export const getCompanyRecList = (client) => {
    return function (dispatch) {
        const query = gql`
                query getCompanyRecList
                {
                    getCompanyRecList {
                        data
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache'
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getCompanyRecList);
            dispatch(setResponseDetails(actionTypes.GET_COMPANY_REC_LIST, return_data));
        }).catch((error) => {
            var returnData = {
                'data': {},
                'error': false,
                'notify_msg': ""
            }
            dispatch(setResponseDetails(actionTypes.GET_CONTACT_TYPE, returnData));
        });
    }
}
export const saveContactType = (client, request) => {
    return dispatch => {
        const mutation = gql`
            mutation saveContactType($contacttypename:String!) {
                saveContactType(contacttypename:$contacttypename) {
                    id
                    name
                    alias
                    order_no
                    is_allowto_delete
                    createdby
                    createdhost
                    createddate
                    lastmodifiedby
                    lastmodifiedhost
                    lastmodifieddate
                }
            }
            `;
        client.mutate({
            mutation: mutation,
            variables: request
        })
            .then(response => {
                let returnData = [];

                if (response.data && response.data != undefined) {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': "Contact Type added successfully"
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    dispatch(actionCreators.getContactType(client));
                }

            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const updateContactTypeOrder = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateContactTypeOrder($orderno:[Float!]!, $contacttypeid:[Float!]!) {
                 updateContactTypeOrder(orderno:$orderno,contacttypeid:$contacttypeid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                orderno: request.contactTypeOrder,
                contacttypeid: request.contactTypeIds,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateContactTypeOrder.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateContactTypeOrder.message
                        }
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateContactTypeOrder.message
                        }
                    }
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const updateContactTypeDetail = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateContactTypeDetail($contacttypename: String!,$contacttypeid: Float!) {
                 updateContactTypeDetail(contacttypename:$contacttypename,contacttypeid:$contacttypeid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                contacttypename: request.contacttypename,
                contacttypeid: request.id,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateContactTypeDetail.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateContactTypeDetail.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateContactTypeDetail.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
                dispatch(actionCreators.getContactType(client))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const deleteContactType = (client, request) => {
    return dispatch => {
        const query = gql`
             query deleteContactType($contacttypeid:Float!) {
                 deleteContactType(contacttypeid:$contacttypeid) {
                    status
                    message
                 }
               }
             `;
        client.query({
            query: query,
            variables: {
                contacttypeid: request.contacttypeid,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.deleteContactType.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.deleteContactType.message
                        }
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.deleteContactType.message
                        }
                    }
                }
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData));
                dispatch(actionCreators.getContactType(client))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}


export const saveOpportunitiesDetail = (client, request) => {
    console.log(request, "saveOpportunitiesDetail");
    return dispatch => {
        const mutation = gql`
             mutation saveOpportunitiesDetail($opportunitiesdata:[JSON!]!, $piplineid:Float!, $leadid:Float!,$teamvisible:Boolean!) {
                 saveOpportunitiesDetail(opportunitiesdata:$opportunitiesdata, piplineid:$piplineid, leadid:$leadid,teamvisible:$teamvisible) {
                    status
                    message
                    id
                    opportunities
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                opportunitiesdata: request.opportunitiesdata,
                piplineid: request.piplineid,
                leadid: request.leadid,
                teamvisible: request.teamvisible
            }
        })
            .then(response => {
                var opportunitiesdata = [];
                var insertedOpportunityId = "";
                let returnData = [];
                if (response.data && response.data != undefined) {
                    opportunitiesdata = response.data.saveOpportunitiesDetail.opportunities
                    insertedOpportunityId = response.data.saveOpportunitiesDetail.id
                    if(parseInt(request.leadid) === 0){
                        console.log("condition ni andar ");
                        if (response.data.saveOpportunitiesDetail.status == "true" ) {
                            returnData = {
                                'data': {},
                                'error': false,
                                'notify_msg': response.data.saveOpportunitiesDetail.message
                            }
                            dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                            if (request.isFromTopBar && response.data.saveOpportunitiesDetail.id) {
                                console.log(response.data.saveOpportunitiesDetail.id);
                                let redirectURL = '/opportunities/opportunities/' + response.data.saveOpportunitiesDetail.id;
                                window.location.href = redirectURL;
                            }
                        } else {
                            returnData = {
                                'data': {},
                                'error': true,
                                'notify_msg': response.data.saveOpportunitiesDetail.message
                            }
                            dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                        }
                    }
                }
                var data1 = {
                    filterdata: [],
                    list: { limit: 1000, pageno: 1, searchtext: "", sorttype: "", type: "list", fieldId: 1, columnname: "" },
                    piplineid: parseFloat(localStorage.getItem('selected_pipeline_id')),
                    filterid: 1,
                }
                dispatch(actionCreators.getOpportunitiesFilterList(client, data1))
                dispatch(actionCreators.getPipelines(client))
                dispatch(actionCreators.getCompanyRecList(client))
                dispatch(actionCreators.getContactRecList(client))

                if (request.sidebarParams && request.sidebarParams !== "" && Object.keys(request.sidebarParams).length > 0) {
                    if (request.sidebarParams.recordtype == 'project' && insertedOpportunityId) {
                        var addOpportunityForProject = {
                            opportunitiesdetail: { srno: "1", id: insertedOpportunityId, value: "" },
                            projectid: parseInt(request.sidebarParams.id)
                        }
                        dispatch(actionCreators.addProjectOpportunities(client, addOpportunityForProject))
                    }
                    dispatch(actionCreators.getRecordRelatedData(client, request.sidebarParams))
                }
            }).catch((error) => {
                // let err_data = errorMessageGraphQl(error);
                // dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}



export const updateOpportunitiesDetail = (client, request) => {
    console.log("request", request);
    return dispatch => {
        const mutation = gql`
             mutation updateOpportunitiesDetail($piplineid:Float!, $updatedfieldid:Float!, $opportunitiesdata:[JSON!]!, $opportunitiesid:Float!,$teamvisible:Boolean!) {
                 updateOpportunitiesDetail(piplineid:$piplineid, updatedfieldid:$updatedfieldid, opportunitiesdata:$opportunitiesdata, opportunitiesid:$opportunitiesid,teamvisible:$teamvisible) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                piplineid: request.piplineid,
                opportunitiesdata: request.opportunitiesdata,
                opportunitiesid: request.opportunitiesid,
                updatedfieldid: request.updatedfieldid,
                teamvisible: request.teamvisible
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateOpportunitiesDetail.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateOpportunitiesDetail.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                        //dispatch(actionCreators.getContactLogList(client, request.opportunitiesid))
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateOpportunitiesDetail.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }

                dispatch(actionCreators.getOpportunitiesField(client, request.opportunitiesid))
                let val;
                if (request.listParams) {
                    val = request.listParams
                } else {
                    val = {
                        filterdata: [],
                        list: {
                            limit: 10,
                            pageno: 1,
                            searchtext: "",
                            sorttype: "",
                            type: "list",
                            fieldId: 1,
                            columnname: ''
                        },
                        piplineid: parseFloat(localStorage.getItem('selected_pipeline_id')),
                        filterid: 1
                    }
                }
                dispatch(actionCreators.getOpportunitiesContactList(client, request.opportunitiesid))
                // if (request.sidebarSubmit) {
                dispatch(actionCreators.getOpportunitiesFilterList(client, val))
                // }
                dispatch(actionCreators.getPipelines(client))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}


/**
 * TYPE : GET
 * Getting particular Opportunity Field data
 */
export const getOpportunitiesField = (client, opportunitiesId) => {
    return function (dispatch) {
        const query = gql`
                query getOpportunitiesField($opportunitiesId: Float!) 
                {
                    getOpportunitiesField(opportunitiesId: $opportunitiesId) {
                        id
                        is_default
                        fieldstatus
                        fieldkey
                        fieldtype{
                          id
                          name
                        }
                        fieldicon
                        fieldlabel
                        generatedfieldid
  
                        opportunitiesdetail{
                          id
                          values
                          fieldvalue
                          opportunities{
                                teamvisible
                                interactions
                                inactivedays
                                lastcontacted
                                stageupdatedate
                                dayinstage
                            }
                        }
                        options
                        leadfavourite
                        image
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                opportunitiesId: opportunitiesId
            },
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getOpportunitiesField);
            dispatch(setResponseDetails(actionTypes.OPPORTUNITY_FIELD_DATA, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);

            dispatch(setResponseDetails(actionTypes.OPPORTUNITY_FIELD_DATA, err_data));
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));

            // let err_data = errorMessageGraphQl(error);
            // dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

/**
 * TYPE : GET
 * Getting OPPORTUNITY Filter List
 */
export const getOpportunitiesFilterList = (client, value) => {
    return function (dispatch) {
        const query = gql`
                query getOpportunitiesFilterList($filterdata: [JSON!]!,$list: OpportunitiesListInput!, $piplineid: Float!, $filterid: Float!)                 
                {
                    getOpportunitiesFilterList(filterdata:$filterdata, list: $list, piplineid: $piplineid, filterid: $filterid) {
                        totalrecord
                        totalpage
                        data
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: value,
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getOpportunitiesFilterList);
            dispatch(setResponseDetails(actionTypes.GET_OPPORTUNITY_FILTER_LIST, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            var returnData = {
                'data': { totalrecord: 0, totalpage: 1, data: [] },
                'error': "",
                'notify_msg': "",
                'is_loaded': true,
            }
            // var returnData = {
            //     'data': [],
            //     'error': false,
            //     'notify_msg': ""
            // }
            dispatch(setResponseDetails(actionTypes.GET_OPPORTUNITY_FILTER_LIST, returnData));
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

/**
 * TYPE : GET
 * Delete Opportunity 
 */
export const deleteOpportunities = (client, request) => {
    return dispatch => {
        const query = gql`
            query deleteOpportunities($piplineid: Float, $opportunitiesid: [Float!]!)                 
            {
                deleteOpportunities(piplineid:$piplineid , opportunitiesid:$opportunitiesid) {
                    status
                    message
                }
            }
        `;
        client.query({
            query: query,
            variables: request['deleteid']
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.deleteOpportunities.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.deleteOpportunities.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.deleteOpportunities.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
            var data1 = {
                filterdata: [],
                list: { limit: 1000, pageno: 1, searchtext: "", sorttype: "", type: "list", fieldId: 1, columnname: "" },
                piplineid: parseFloat(localStorage.getItem('selected_pipeline_id')),
                filterid: 1,
            }
            dispatch(actionCreators.getOpportunitiesFilterList(client, data1))
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

/**
 * TYPE : POST
 * Add Opportunity to favorite
 */
export const addFavouriteOpportunities = (client, request) => {
    console.log("request", request);
    return dispatch => {
        const mutation = gql`
             mutation addFavouriteOpportunities($status:Boolean!, $opportunitiesid:Float!) {
                 addFavouriteOpportunities(status:$status,opportunitiesid:$opportunitiesid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: request['favorite']
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.addFavouriteOpportunities.status == "true") {
                        // returnData = {
                        //     'data': {},
                        //     'error': false,
                        //     'notify_msg': response.data.addFavouriteOpportunities.message
                        // }
                        // dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                        dispatch(actionCreators.getOpportunitiesFilterList(client, request['listval']))
                        dispatch(actionCreators.getOpportunitiesField(client, request['favorite'].opportunitiesid))
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.addFavouriteOpportunities.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }

            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}


export const updateOpportunitiesListColumnOrder = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateOpportunitiesListColumnOrder($orderno:[Float!]!, $columnstatus:[Boolean!]!,$fieldid:[Float!]!) {
                 updateOpportunitiesListColumnOrder(orderno:$orderno,columnstatus:$columnstatus,fieldid:$fieldid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: request
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateOpportunitiesListColumnOrder.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateOpportunitiesListColumnOrder.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                        dispatch(actionCreators.getOpportunitiesListColumnOrder(client))
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateOpportunitiesListColumnOrder.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

/**
 * TYPE : GET
 * Getting Contact Column Order
 */
export const getOpportunitiesListColumnOrder = (client) => {

    return function (dispatch) {
        const query = gql`
                query getOpportunitiesListColumnOrder
                {
                    getOpportunitiesListColumnOrder {
                        id
                        field{
                            id
                            fieldkey
                            fieldlabel
                        }
                        userid
                        columnid
                        orderno
                        isactive
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getOpportunitiesListColumnOrder);
            dispatch(setResponseDetails(actionTypes.GET_OPPORTUNITY_LIST_ORDER, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            var returnData = {
                'data': {},
                'error': false,
                'notify_msg': ""
            }
            dispatch(setResponseDetails(actionTypes.GET_CONTACT_LIST_ORDER, returnData));
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}


/**
 * TYPE : GET
 * Getting Company Log List with filter
 */
export const getOpportunitiesActivityLogList = (client, request) => {
    return function (dispatch) {
        const query = gql`
      query getOpportunitiesActivityLogList(
        $opportunitiesid: Float!
        $logtypeid: [Float!]
        $activitypeid: [Float!]
        $filterchange: Boolean
      ) {
        getOpportunitiesActivityLogList(
          opportunitiesid: $opportunitiesid
          logtypeid: $logtypeid
          activitypeid: $activitypeid
          filterchange: $filterchange
        ) {
          status
          message
          pined
          today
          yesterday
          thisweek
          lastweek
          thismonth
          lastmonth
          earlier
        }
      }
    `;
        client
            .query({
                query: query,
                fetchPolicy: "no-cache",
                variables: request,
            })
            .then((response) => {
                let return_data = dataSet.processGQLData(response.data.getOpportunitiesActivityLogList);
                dispatch(setResponseDetails(actionTypes.GET_OPPORTUNITY_SIDEBAR_LOG_LIST, return_data));
            })
            .catch((error) => {
                //let err_data = errorMessageGraphQl(error);
                var returnData = {
                    data: {},
                    error: false,
                    notify_msg: "",
                };
                dispatch(
                    setResponseDetails(actionTypes.GET_OPPORTUNITY_SIDEBAR_LOG_LIST, returnData)
                );
                //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    };
};

/**
 * TYPE : GET
 * Getting Lead Log List with filter
 */
export const getOpportunitiesLogListNew = (client, request) => {
    return function (dispatch) {
        const query = gql`
                query getOpportunitiesLogListNew($opportunitiesid: Float!, $logtypeid:[Float!], $activitypeid:[Float!])                 
                {
                    getOpportunitiesLogListNew(opportunitiesid: $opportunitiesid, logtypeid:$logtypeid, activitypeid:$activitypeid) {
                        logdata
                        status
                        message
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: request,
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getOpportunitiesLogListNew);
            dispatch(setResponseDetails(actionTypes.GET_OPPORTUNITY_LOG_LIST, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            var returnData = {
                'data': {},
                'error': false,
                'notify_msg': ""
            }
            dispatch(setResponseDetails(actionTypes.GET_OPPORTUNITY_LOG_LIST, returnData));
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const saveOpportunitiesLog = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation saveOpportunitiesLog($mentionuser:[Float!],$mentiondetail: String, $propertyid:Float!, $propertyname:String!, $opportunitiesid:Float!, $text:String!, $oldvalue:String!, $newvalue:String!, $logtime:Float!, $logdate:Float!, $logtype:Float!, $fieldid:Float!) {
                 saveOpportunitiesLog(mentionuser:$mentionuser, mentiondetail:$mentiondetail, propertyid:$propertyid, propertyname:$propertyname,opportunitiesid:$opportunitiesid, text:$text, oldvalue:$oldvalue, newvalue:$newvalue, logtype:$logtype, logtime:$logtime, logdate:$logdate, fieldid:$fieldid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: request['save']
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.saveOpportunitiesLog.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.saveOpportunitiesLog.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));

                        dispatch(actionCreators.getOpportunitiesActivityLogList(client, request['getlog']))
                        dispatch(actionCreators.getOpportunitiesLogListNew(client, request['getlog']))
                        dispatch(actionCreators.getOpportunitiesField(client, request['getlog'].opportunitiesid))
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.saveOpportunitiesLog.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}
export const updateOpportunitiesLog = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateOpportunitiesLog( $mentionuser:[Float!], $mentiondetail: String, $text:String!, $logtime:Float!, $logdate:Float!, $logid:Float!) {
                 updateOpportunitiesLog(mentionuser:$mentionuser, mentiondetail:$mentiondetail, text:$text, logtime:$logtime, logdate:$logdate, logid:$logid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: request['update']
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateOpportunitiesLog.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateOpportunitiesLog.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));

                        dispatch(actionCreators.getOpportunitiesLogListNew(client, request['getlog']))
                        dispatch(actionCreators.getOpportunitiesActivityLogList(client, request['getlog']))
                        dispatch(actionCreators.getOpportunitiesField(client, request['getlog'].opportunitiesid))
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateOpportunitiesLog.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}
export const deleteOpportunitiesLogDetail = (client, request) => {

    return dispatch => {
        const query = gql`
            query deleteOpportunitiesLogDetail($logid: Float!)                 
            {
                deleteOpportunitiesLogDetail(logid:$logid) {
                    status
                    message
                }
            }
        `;
        client.query({
            query: query,
            variables: { logid: request.logid },
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.deleteOpportunitiesLogDetail.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.deleteOpportunitiesLogDetail.message
                    }

                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    let getlog = { opportunitiesid: request.opportunitiesid, activitypeid: request.activitypeid, logtypeid: request.logtypeid };
                    dispatch(actionCreators.getOpportunitiesLogListNew(client, getlog));
                    dispatch(actionCreators.getOpportunitiesActivityLogList(client, getlog))
                    dispatch(actionCreators.getOpportunitiesField(client, request.opportunitiesid))
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.deleteOpportunitiesLogDetail.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

/**
 * [addOpportunitiesLogComment saves comments of log]
 */
export const addOpportunitiesLogComment = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation addOpportunitiesLogComment($type:String!,$logid:Float!, $comment:String!,$mentionuser:[Float!], $mentiondetail: String, $opportunitiesid:Float!, ) {
                 addOpportunitiesLogComment(type:$type, logid:$logid, comment:$comment,mentionuser:$mentionuser, mentiondetail:$mentiondetail, opportunitiesid:$opportunitiesid ) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                type: request.type,
                logid: request.logid,
                comment: request.comment,
                mentionuser: request.mentionuser,
                mentiondetail: request.mentiondetail,
                opportunitiesid: request.opportunitiesid
            }
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.addOpportunitiesLogComment.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.addOpportunitiesLogComment.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    let getlog = { opportunitiesid: request.opportunitiesid, activitypeid: request.activitypeid, logtypeid: request.logtypeid };
                    dispatch(actionCreators.getOpportunitiesLogListNew(client, getlog));
                    dispatch(actionCreators.getOpportunitiesActivityLogList(client, getlog))
                    dispatch(actionCreators.getOpportunitiesField(client, request.opportunitiesid))
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.addOpportunitiesLogComment.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}
/**
 * [updateOpportunitiesLogComment saves comments of log]
 */
export const updateOpportunitiesLogComment = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateOpportunitiesLogComment($type:String!,$commentid:Float!, $comment:String!,$mentionuser:[Float!], $mentiondetail: String) {
                 updateOpportunitiesLogComment(type:$type, commentid:$commentid, comment:$comment,mentionuser:$mentionuser, mentiondetail:$mentiondetail) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                type: request.type,
                commentid: request.commentid,
                comment: request.comment,
                mentionuser: request.mentionuser,
                mentiondetail: request.mentiondetail,
            }
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.updateOpportunitiesLogComment.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.updateOpportunitiesLogComment.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));

                    let getlog = { opportunitiesid: request.opportunitiesid, activitypeid: request.activitypeid, logtypeid: request.logtypeid };
                    dispatch(actionCreators.getOpportunitiesLogListNew(client, getlog));
                    dispatch(actionCreators.getOpportunitiesActivityLogList(client, getlog))
                    dispatch(actionCreators.getOpportunitiesField(client, request.opportunitiesid))
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.updateOpportunitiesLogComment.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}
export const deleteOpportunitiesLogComment = (client, request) => {

    return dispatch => {
        const query = gql`
            query deleteOpportunitiesLogComment($commentid: Float!, $type: String!)                 
            {
                deleteOpportunitiesLogComment(commentid:$commentid, type:$type) {
                    status
                    message
                }
            }
        `;
        client.query({
            query: query,
            variables: {
                commentid: request.commentid,
                type: request.type
            },
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.deleteOpportunitiesLogComment.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.deleteOpportunitiesLogComment.message
                    }

                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    let getlog = { opportunitiesid: request.opportunitiesid, activitypeid: request.activitypeid, logtypeid: request.logtypeid };
                    dispatch(actionCreators.getOpportunitiesLogListNew(client, getlog));
                    dispatch(actionCreators.getOpportunitiesActivityLogList(client, getlog))
                    dispatch(actionCreators.getOpportunitiesField(client, request.opportunitiesid))
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.deleteOpportunitiesLogComment.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}





export const getFilterListOpportunity = (client, recordtypeid) => {
    return function (dispatch) {
        const query = gql`
                query getFilterList($recordtypeid: Float!) 
                {
                    getFilterList(recordtypeid: $recordtypeid) {
                        id
                        fitertype
                        userid
                        filtername
                        filterquery
                        filteroption
                        isdefault
                        recordtypeid
                        isselected
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                recordtypeid: recordtypeid
            },
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getFilterList);
            dispatch(setResponseDetails(actionTypes.GET_OPPORTUNITYFILTER_LIST, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const saveFilterDetailOpportunity = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation saveFilterDetail($filterdata:[JSON!]!,$fitertype:String!,$filtername: String!,$recordtypeid: Float!) {
                 saveFilterDetail(filterdata:$filterdata,fitertype:$fitertype,filtername:$filtername,recordtypeid:$recordtypeid) {
                    id
                    fitertype
                    userid
                    filtername
                    filterquery
                    filteroption
                    isdefault
                    recordtypeid
                    isselected
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                filterdata: request.filterdata,
                fitertype: request.fitertype,
                filtername: request.filtername,
                recordtypeid: request.recordtypeid,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.saveFilterDetail.id) {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': "Filter saved successfully"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    }
                }
                dispatch(actionCreators.getFilterListOpportunity(client, 4))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const deleteFilterOpportunity = (client, filterid) => {
    return function (dispatch) {
        const query = gql`
                query deleteFilter($filterid: Float!) 
                {
                    deleteFilter(filterid: $filterid) {
                        status
                        message
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                filterid: filterid
            },
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.deleteFilter.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.deleteFilter.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.deleteFilter.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
            dispatch(actionCreators.getFilterListOpportunity(client, 4))
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const updateFilterDetailOpportunity = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateFilterDetail($filterdata: [JSON!]!, $fitertype:String!,$filtername:String!,$filterid:Float!) {
                 updateFilterDetail(filterdata:$filterdata,fitertype:$fitertype,filtername:$filtername,filterid:$filterid) {
                    id
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                filterdata: request.filterdata,
                fitertype: request.fitertype,
                filtername: request.filtername,
                filterid: request.filterid,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateFilterDetail.id) {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': "Filter updated successfully"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': "Filter couldn't get updated"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
                dispatch(actionCreators.getFilterListOpportunity(client, 4))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const updateFilterDefaultOpportunity = (client, filterid) => {
    return dispatch => {
        const mutation = gql`
             mutation updateFilterDefault($type:String!, $fiterid:Float!,$recordtypeid:Float!) {
                 updateFilterDefault(type:$type,fiterid:$fiterid,recordtypeid:$recordtypeid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                type: "isdefault",
                fiterid: filterid,
                recordtypeid: 4
            },
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateFilterDefault.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateFilterDefault.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateFilterDefault.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
                dispatch(actionCreators.getFilterListOpportunity(client, 4))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const getOpportunitiesContactList = (client, opportunitiesid) => {
    return function (dispatch) {
        const query = gql`
                query getOpportunitiesContactList($opportunitiesid: Float!)                 
                {
                    getOpportunitiesContactList(opportunitiesid: $opportunitiesid) {
                       id
                        contact{
                          id
                          userid
                          interactions
                          lastcontacted
                          inactivedays
                          createdby
                          createdhost
                          createddate
                          lastmodifiedby
                          lastmodifiedhost
                          lastmodifieddate
                          image
                          contactdetail{
                            id
                            field{
                              id
                              fieldkey
                            }
                            values
                            fieldvalue
                            
                            
                          }
                          
                        }
                        createdby
                        createdhost
                        createddate
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: { opportunitiesid: opportunitiesid },
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getOpportunitiesContactList);
            dispatch(setResponseDetails(actionTypes.OPPORTUNITY_CONTACT_LIST, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            var returnData = {
                'data': {},
                'error': false,
                'notify_msg': ""
            }

            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}


export const deleteOpportunitiesContact = (client, req) => {
    return dispatch => {
        const mutation = gql`
             mutation deleteOpportunitiesContact($contactid:Float!, $opportunitiesid:Float!) {
                 deleteOpportunitiesContact(contactid:$contactid,opportunitiesid:$opportunitiesid) {
                    id
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                contactid: req.contactid,
                opportunitiesid: req.opportunitiesid,
            },
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.deleteOpportunitiesContact) {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': "Data updated successfully"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    }
                    // else {
                    //     returnData = {
                    //         'data': {},
                    //         'error': true,
                    //         'notify_msg': "Unable to update data"
                    //     }
                    //     dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    // }
                }
                var reqData = {
                    id: req.opportunitiesid,
                    type: 'contact',
                    recordtype: "opportunities",
                }
                dispatch(actionCreators.getRecordRelatedData(client, reqData))
                var reqData1 = {
                    id: req.contactid,
                    type: "opportunities",
                    recordtype: "contact",
                }
                dispatch(actionCreators.getRecordRelatedData(client, reqData1))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}
export const addNewOpportunitiesContact = (client, req) => {
    return dispatch => {
        const mutation = gql`
             mutation addNewOpportunitiesContact($contactdetail:JSON!, $opportunitiesid:Float!) {
                 addNewOpportunitiesContact(contactdetail:$contactdetail,opportunitiesid:$opportunitiesid) {
                    id
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                contactdetail: req.contactdetail,
                opportunitiesid: req.opportunitiesid,
            },
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.addNewOpportunitiesContact.length > 0) {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': "Data updated successfully"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': "Unable to update data"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
                var reqData = {
                    id: req.opportunitiesid,
                    type: 'contact',
                    recordtype: "opportunities",
                }
                dispatch(actionCreators.getRecordRelatedData(client, reqData))
                var reqData1 = {
                    id: req.contactdetail.id,
                    type: "opportunities",
                    recordtype: "contact",
                }
                dispatch(actionCreators.getRecordRelatedData(client, reqData1))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const getOpportunitiesCompanyList = (client, opportunitiesid) => {
    return function (dispatch) {
        const query = gql`
                query getOpportunitiesCompanyList($opportunitiesid: Float!)                 
                {
                    getOpportunitiesCompanyList(opportunitiesid: $opportunitiesid) {
                       id
                        company{
                          id
                          userid
                          interactions
                          lastcontacted
                          inactivedays
                          createdby
                          createdhost
                          createddate
                          lastmodifiedby
                          lastmodifiedhost
                          lastmodifieddate
                          image
                          companydetail{
                            id
                            field{
                              id
                              fieldkey
                            }
                            values
                            fieldvalue
                            
                            
                          }
                          
                        }
                        createdby
                        createdhost
                        createddate
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: { opportunitiesid: opportunitiesid },
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getOpportunitiesCompanyList);
            dispatch(setResponseDetails(actionTypes.OPPORTUNITY_COMPANY_LIST, return_data));
        }).catch((error) => {
            //let err_data = errorMessageGraphQl(error);
            var returnData = {
                'data': {},
                'error': false,
                'notify_msg': ""
            }

            //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}


export const deleteOpportunitiesCompany = (client, req) => {
    return dispatch => {
        const mutation = gql`
             mutation deleteOpportunitiesCompany($companyid:Float!, $opportunitiesid:Float!) {
                 deleteOpportunitiesCompany(companyid:$companyid,opportunitiesid:$opportunitiesid) {
                    id
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                companyid: req.companyid,
                opportunitiesid: req.opportunitiesid,
            },
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.deleteOpportunitiesCompany) {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': "Data updated successfully"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    }
                    // else {
                    //     returnData = {
                    //         'data': {},
                    //         'error': true,
                    //         'notify_msg': "Unable to update data"
                    //     }
                    //     dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    // }
                }
                var reqData = {
                    id: req.opportunitiesid,
                    type: 'companies',
                    recordtype: "opportunities",
                }
                dispatch(actionCreators.getRecordRelatedData(client, reqData))
                var reqData1 = {
                    id: req.companyid,
                    type: 'opportunities',
                    recordtype: "company",
                }
                dispatch(actionCreators.getRecordRelatedData(client, reqData1))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}
export const addNewOpportunitiesCompany = (client, req) => {
    return dispatch => {
        const mutation = gql`
             mutation addNewOpportunitiesCompany($companydetail:JSON!, $opportunitiesid:Float!) {
                 addNewOpportunitiesCompany(companydetail:$companydetail,opportunitiesid:$opportunitiesid) {
                    id
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                companydetail: req.companydetail,
                opportunitiesid: req.opportunitiesid,
            },
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.addNewOpportunitiesCompany.length > 0) {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': "Data updated successfully"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': "Unable to update data"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
                var reqData = {
                    id: req.opportunitiesid,
                    type: 'companies',
                    recordtype: "opportunities",
                }
                dispatch(actionCreators.getRecordRelatedData(client, reqData))

                var reqData1 = {
                    id: req.companydetail.id,
                    type: 'opportunities',
                    recordtype: "company",
                }
                dispatch(actionCreators.getRecordRelatedData(client, reqData1))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}
export const opportunitiesLost = (client, req) => {
    return dispatch => {
        const mutation = gql`
             mutation opportunitiesLost($changedate:Boolean!, $notes:String!,$lossreasonid:Float!,$opportunitiesid:Float!,$type:String!) {
                 opportunitiesLost(changedate:$changedate,notes:$notes,lossreasonid:$lossreasonid,opportunitiesid:$opportunitiesid,type:$type) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                changedate: req.changedate,
                notes: req.notes,
                lossreasonid: req.lossreasonid,
                opportunitiesid: req.opportunitiesid,
                type: req.type,
            },
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.opportunitiesLost.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.opportunitiesLost.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.opportunitiesLost.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
                dispatch(actionCreators.getOpportunitiesField(client, req.opportunitiesid))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}
export const opportunitiesWin = (client, req) => {
    return dispatch => {
        const mutation = gql`
             mutation opportunitiesWin($changedate:Boolean!, $notes:String!,$contactdata:[JSON!]!,$opportunitiesid:Float!) {
                 opportunitiesWin(changedate:$changedate,notes:$notes,contactdata:$contactdata,opportunitiesid:$opportunitiesid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                changedate: req.changedate,
                notes: req.notes,
                contactdata: req.contactdata,
                opportunitiesid: req.opportunitiesid,
            },
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.opportunitiesWin.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.opportunitiesWin.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.opportunitiesWin.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
                dispatch(actionCreators.getOpportunitiesField(client, req.opportunitiesid))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}
export const updateOpportunitiesStage = (client, req) => {
    return dispatch => {
        const mutation = gql`
             mutation updateOpportunitiesStage($updated_stage_id:Float!, $current_stage_id:Float!,$opportunitiesid:Float!) {
                 updateOpportunitiesStage(updated_stage_id:$updated_stage_id,current_stage_id:$current_stage_id,opportunitiesid:$opportunitiesid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                updated_stage_id: req.updated_stage_id,
                current_stage_id: req.current_stage_id,
                opportunitiesid: req.opportunitiesid,
            },
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateOpportunitiesStage.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateOpportunitiesStage.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                        dispatch(actionCreators.getRecordRelatedData(client, req.getRelatedReq))
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateOpportunitiesStage.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const addOpportunitiesLogEmoji = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation addOpportunitiesLogEmoji($logtype:String!,$logid:Float!,$emoji:String!, $emojidetail:String!) {
                addOpportunitiesLogEmoji(logtype:$logtype,logid:$logid,emoji:$emoji,emojidetail:$emojidetail) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                logtype: request.logtype,
                logid: request.logid,
                emoji: request.emoji,
                emojidetail: request.emojidetail,
            }
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.addOpportunitiesLogEmoji.status == "true") {
                    let getlog = { opportunitiesid: request.opportunitiesid, activitypeid: request.activitypeid, logtypeid: request.logtypeid };
                    dispatch(actionCreators.getOpportunitiesLogListNew(client, getlog));
                    dispatch(actionCreators.getOpportunitiesActivityLogList(client, getlog))
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.addOpportunitiesLogEmoji.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

const setResponseDetails = (newType, data) => {
    return {
        type: newType,
        val: data
    };
}

const notificationAll = (newType, data, status) => {
    return {
        type: newType,
        val: data,
        status: status,
    }
}

const errorMessageGraphQl = (err) => {
    let msg = 'Something Went Wrong!';
    if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        msg = err.graphQLErrors[0].message;
    } else if (err.networkError && err.networkError.result) {
        msg = err.networkError.result.errors[0].message;
    } else if (err.message) {
        msg = err.message;
    }

    return {
        notify_msg: msg,
        error: true
    }
}

export const clearNotification = (value) => {
    return function (dispatch) {
        dispatch(clearNotificationAll(actionTypes.NOTIFICATIONS, {}, false));
    }
}
const clearNotificationAll = (newType, data, status) => {
    return {
        type: actionTypes.NOTIFICATIONS,
        val: '',
        status: '',
    }
}
