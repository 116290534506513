import * as actionTypes from "./actionTypes";
import { gql } from "apollo-boost";
import * as dataSet from "./dataSet";
import * as actionCreators from "./index";

/**
 * TYPE : GET
 * Getting Company Filter List
 */
export const getCompanyRecFilterList = (client, value) => {
  return function (dispatch) {
    const query = gql`
      query getCompanyRecFilterList(
        $filterdata: [JSON!]!
        $list: CompanyListInput!
        $filterid: Float!
      ) {
        getCompanyRecFilterList(
          filterdata: $filterdata
          list: $list
          filterid: $filterid
        ) {
          totalrecord
          totalpage
          data
        }
      }
    `;
    client
      .query({
        query: query,
        fetchPolicy: "no-cache",
        variables: value,
      })
      .then((response) => {
        let return_data = dataSet.processGQLData(
          response.data.getCompanyRecFilterList
        );
        dispatch(
          setResponseDetails(actionTypes.GET_COMAPNY_FILTER_LIST, return_data)
        );
      })
      .catch((error) => {
        //let err_data = errorMessageGraphQl(error);
        var returnData = {
          data: {},
          error: false,
          notify_msg: "",
        };
        dispatch(
          setResponseDetails(actionTypes.GET_COMAPNY_FILTER_LIST, returnData)
        );
        //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
      });
  };
};
export const deleteCompanyRec = (client, request) => {
  return (dispatch) => {
    const query = gql`
      query deleteCompanyRec($companyid: [Float!]!) {
        deleteCompanyRec(companyid: $companyid) {
          status
          message
        }
      }
    `;
    client
      .query({
        query: query,
        variables: request["deleteid"],
      })
      .then((response) => {
        let returnData = [];
        if (response.data && response.data != undefined) {
          if (response.data.deleteCompanyRec.status == "true") {
            returnData = {
              data: {},
              error: false,
              notify_msg: response.data.deleteCompanyRec.message,
            };
            dispatch(
              notificationAll(actionTypes.NOTIFICATIONS, returnData, true)
            );
          } else {
            returnData = {
              data: {},
              error: true,
              notify_msg: response.data.deleteCompanyRec.message,
            };
            dispatch(
              notificationAll(actionTypes.NOTIFICATIONS, returnData, false)
            );
          }
        }
        dispatch(
          actionCreators.getCompanyRecFilterList(client, request["listval"])
        );
      })
      .catch((error) => {
        let err_data = errorMessageGraphQl(error);
        dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
      });
  };
};

export const addFavouriteCompanyRec = (client, request) => {
  return (dispatch) => {
    const mutation = gql`
      mutation addFavouriteCompanyRec($status: Boolean!, $companyid: Float!) {
        addFavouriteCompanyRec(status: $status, companyid: $companyid) {
          status
          message
        }
      }
    `;
    client
      .mutate({
        mutation: mutation,
        variables: request["favorite"],
      })
      .then((response) => {
        let returnData = [];
        if (response.data && response.data != undefined) {
          if (response.data.addFavouriteCompanyRec.status == "true") {
            // returnData = {
            //     'data': {},
            //     'error': false,
            //     'notify_msg': response.data.addFavouriteCompanyRec.message
            // }
            // dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
            dispatch(actionCreators.getCompanyRecFilterList(client, request["listval"]));
            dispatch(actionCreators.getCompanyRecField(client, request["favorite"].companyid));
          } else {
            returnData = {
              data: {},
              error: true,
              notify_msg: response.data.addFavouriteCompanyRec.message,
            };
            dispatch(
              notificationAll(actionTypes.NOTIFICATIONS, returnData, false)
            );
          }
        }
      })
      .catch((error) => {
        let err_data = errorMessageGraphQl(error);
        dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
      });
  };
};
export const updateCompanyRecListColumnOrder = (client, request) => {
  return (dispatch) => {
    const mutation = gql`
      mutation updateCompanyRecListColumnOrder(
        $orderno: [Float!]!
        $columnstatus: [Boolean!]!
        $fieldid: [Float!]!
      ) {
        updateCompanyRecListColumnOrder(
          orderno: $orderno
          columnstatus: $columnstatus
          fieldid: $fieldid
        ) {
          status
          message
        }
      }
    `;
    client
      .mutate({
        mutation: mutation,
        variables: request,
      })
      .then((response) => {
        let returnData = [];
        if (response.data && response.data != undefined) {
          if (response.data.updateCompanyRecListColumnOrder.status == "true") {
            returnData = {
              data: {},
              error: false,
              notify_msg: response.data.updateCompanyRecListColumnOrder.message,
            };
            dispatch(
              notificationAll(actionTypes.NOTIFICATIONS, returnData, true)
            );
            dispatch(actionCreators.getCompanyRecListColumnOrder(client))
          } else {
            returnData = {
              data: {},
              error: true,
              notify_msg: response.data.updateCompanyRecListColumnOrder.message,
            };
            dispatch(
              notificationAll(actionTypes.NOTIFICATIONS, returnData, false)
            );
          }
        }
      })
      .catch((error) => {
        let err_data = errorMessageGraphQl(error);
        dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
      });
  };
};
export const getCompanyRecListColumnOrder = (client) => {
  return function (dispatch) {
    const query = gql`
      query getCompanyRecListColumnOrder {
        getCompanyRecListColumnOrder {
          id
          field {
            id
            fieldkey
            fieldlabel
          }
          userid
          columnid
          orderno
          isactive
        }
      }
    `;
    client
      .query({
        query: query,
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        let return_data = dataSet.processGQLData(
          response.data.getCompanyRecListColumnOrder
        );
        console.log(return_data,'response.dataresponse.data')
        dispatch(
          setResponseDetails(actionTypes.GET_COMPANY_LIST_ORDER, return_data)
        );
      })
      .catch((error) => {
        //let err_data = errorMessageGraphQl(error);
        var returnData = {
          data: {},
          error: false,
          notify_msg: "",
        };
        dispatch(
          setResponseDetails(actionTypes.GET_COMPANY_LIST_ORDER, returnData)
        );
        //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
      });
  };
};

export const getCompanyRecField = (client, companyid) => {
  return function (dispatch) {
    const query = gql`
      query getCompanyRecField($companyid: Float!) {
        getCompanyRecField(companyid: $companyid) {
          id
          is_default
          fieldstatus
          fieldkey
          fieldtype {
            id
            name
          }
          fieldicon
          fieldlabel
          generatedfieldid
          companydetail {
            id
            values
            fieldvalue
            company {
              teamvisible
              interactions
              inactivedays
              lastcontacted
            }
          }
          options
          leadfavourite
          image
        }
      }
    `;
    client
      .query({
        query: query,
        fetchPolicy: "no-cache",
        variables: {
          companyid: companyid,
        },
      })
      .then((response) => {
        let return_data = dataSet.processGQLData(
          response.data.getCompanyRecField
        );
        dispatch(
          setResponseDetails(actionTypes.COMPANY_FIELD_DATA, return_data)
        );
      })
      .catch((error) => {
        let err_data = errorMessageGraphQl(error);

        dispatch(setResponseDetails(actionTypes.COMPANY_FIELD_DATA, err_data));
        dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
      });
  };
};
export const saveCompanyRecDetail = (client, request) => {
  return (dispatch) => {
    const mutation = gql`
      mutation saveCompanyRecDetail($companydata: [JSON!]!,$teamvisible:Boolean!) {
        saveCompanyRecDetail(companydata: $companydata,teamvisible:$teamvisible) {
          status
          message
          id
        }
      }
    `;
    client
      .mutate({
        mutation: mutation,
        variables: {
          companydata: request.companydata,
          teamvisible: request.teamvisible
        },
      })
      .then((response) => {
        let returnData = [];
        let insertedCompanyId = "";
        if (response.data && response.data != undefined) {
          if (response.data.saveCompanyRecDetail.status == "true") {
            insertedCompanyId = response.data.saveCompanyRecDetail.id
            returnData = {
              data: {},
              error: false,
              notify_msg: response.data.saveCompanyRecDetail.message,
            };
            dispatch(
              notificationAll(actionTypes.NOTIFICATIONS, returnData, true)
            );

            if (request.isFromTopBar && response.data.saveCompanyRecDetail.id) {
              console.log(response.data.saveCompanyRecDetail.id);
              let redirectURL = '/company/company/' + response.data.saveCompanyRecDetail.id;
              window.location.href = redirectURL;
            }
            if (request.dataFromSidebar && request.dataFromSidebar.recordtype == "project") {
              var addrelatedCmp = {
                companydetail: { id: parseInt(insertedCompanyId) },
                projectid: parseInt(request.dataFromSidebar.id),
              }
              dispatch(actionCreators.addProjectCompany(client, addrelatedCmp))
            }

          } else {
            returnData = {
              data: {},
              error: true,
              notify_msg: response.data.saveCompanyRecDetail.message,
            };
            dispatch(
              notificationAll(actionTypes.NOTIFICATIONS, returnData, false)
            );
          }
        }
        if (request.listParams && !request.dataFromSidebar) {
          console.log("request", request);
          dispatch(actionCreators.getCompanyRecFilterList(client, request.listParams));
        }

        var checkOpportunityId = localStorage.getItem('opportunitiesid')
        if (checkOpportunityId && insertedCompanyId) {
          var addCompanyForOpportunity = {
            companydetail: { srno: "1", id: insertedCompanyId, value: "" },
            opportunitiesid: parseInt(checkOpportunityId)
          }
          dispatch(actionCreators.addNewOpportunitiesCompany(client, addCompanyForOpportunity))
          localStorage.removeItem('opportunitiesid');
        }

      })
      .catch((error) => {
        let err_data = errorMessageGraphQl(error);
        dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
      });
  };
};

export const updateCompanyRecDetail = (client, request) => {
  return (dispatch) => {
    const mutation = gql`
      mutation updateCompanyRecDetail(
        $updatedfieldid:Float!
        $companydata: [JSON!]!
        $companyid: Float!
        $fileInput: Upload
        $teamvisible:Boolean!
      ) {
        updateCompanyRecDetail(
          updatedfieldid:$updatedfieldid
          companydata: $companydata
          companyid: $companyid
          fileInput: $fileInput
          teamvisible:$teamvisible){
          status
          message
        }
      }
    `;
    client
      .mutate({
        mutation: mutation,
        variables: {
          companydata: request.companydata,
          companyid: request.companyid,
          fileInput: request.companyImage,
          updatedfieldid: request.updatedfieldid,
          teamvisible: request.teamvisible

        },
      })
      .then((response) => {
        let returnData = [];
        if (response.data && response.data != undefined) {
          if (response.data.updateCompanyRecDetail.status == "true") {
            returnData = {
              data: {},
              error: false,
              notify_msg: response.data.updateCompanyRecDetail.message,
            };
            dispatch(
              notificationAll(actionTypes.NOTIFICATIONS, returnData, true)
            );
          } else {
            returnData = {
              data: {},
              error: true,
              notify_msg: response.data.updateCompanyRecDetail.message,
            };
            dispatch(
              notificationAll(actionTypes.NOTIFICATIONS, returnData, false)
            );
          }
        }
        let logparam = { companyid: request.companyid, emailid: "" };
        dispatch(actionCreators.getCompanyRecLogList(client, logparam));
        dispatch(actionCreators.getCompanyRecField(client, request.companyid));

        let val = {
          filterdata: [],
          list: {
            limit: 10,
            pageno: 1,
            searchtext: "",
            sorttype: "",
            type: "list",
            fieldId: 1,
            columnname: "",
          },
          filterid: 1,
        };

        dispatch(actionCreators.getCompanyRecFilterList(client, val));
      })
      .catch((error) => {
        let err_data = errorMessageGraphQl(error);
        dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
      });
  };
};






//-----------------------------log functions ----------------
/**
 * TYPE : GET
 * Getting Company Log List
 */
export const getCompanyRecLogList = (client, request) => {
  return function (dispatch) {
    const query = gql`
      query getCompanyRecLogList($companyid: Float!, $emailid: String!) {
        getCompanyRecLogList(companyid: $companyid, emailid: $emailid) {
          logdata
          status
          message
        }
      }
    `;
    client
      .query({
        query: query,
        fetchPolicy: "no-cache",
        variables: request,
      })
      .then((response) => {
        let return_data = dataSet.processGQLData(
          response.data.getCompanyRecLogList
        );
        dispatch(
          setResponseDetails(actionTypes.GET_COMPANY_LOG_LIST, return_data)
        );
      })
      .catch((error) => {
        //let err_data = errorMessageGraphQl(error);
        var returnData = {
          data: {},
          error: false,
          notify_msg: "",
        };
        dispatch(
          setResponseDetails(actionTypes.GET_COMPANY_LOG_LIST, returnData)
        );
        //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
      });
  };
};

/**
 * TYPE : GET
 * Getting Company Log List with filter
 */
export const getCompanyRecActivityLogList = (client, request) => {
  return function (dispatch) {
    const query = gql`
      query getCompanyRecActivityLogList(
        $companyid: Float!
        $emailid: String!
        $logtypeid: [Float!]
        $activitypeid: [Float!]
        $filterchange: Boolean
      ) {
        getCompanyRecActivityLogList(
          companyid: $companyid
          emailid: $emailid
          logtypeid: $logtypeid
          activitypeid: $activitypeid
          filterchange: $filterchange
        ) {
          status
          message
          pined
          today
          yesterday
          thisweek
          lastweek
          thismonth
          lastmonth
          earlier
        }
      }
    `;
    client
      .query({
        query: query,
        fetchPolicy: "no-cache",
        variables: request,
      })
      .then((response) => {
        let return_data = dataSet.processGQLData(response.data.getCompanyRecActivityLogList);
        dispatch(setResponseDetails(actionTypes.GET_COMPANY_SIDEBAR_LOG_LIST, return_data));
      })
      .catch((error) => {
        //let err_data = errorMessageGraphQl(error);
        var returnData = {
          data: {},
          error: false,
          notify_msg: "",
        };
        dispatch(
          setResponseDetails(actionTypes.GET_COMPANY_SIDEBAR_LOG_LIST, returnData)
        );
        //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
      });
  };
};
/**
 * TYPE : GET
 * Getting Company Log List with filter
 */
export const getCompanyRecLogListNew = (client, request) => {
  return function (dispatch) {
    const query = gql`
      query getCompanyRecLogListNew(
        $companyid: Float!
        $emailid: String!
        $logtypeid: [Float!]
        $activitypeid: [Float!]
      ) {
        getCompanyRecLogListNew(
          companyid: $companyid
          emailid: $emailid
          logtypeid: $logtypeid
          activitypeid: $activitypeid
        ) {
          logdata
          status
          message
        }
      }
    `;
    client
      .query({
        query: query,
        fetchPolicy: "no-cache",
        variables: request,
      })
      .then((response) => {
        let return_data = dataSet.processGQLData(
          response.data.getCompanyRecLogListNew
        );
        dispatch(
          setResponseDetails(actionTypes.GET_COMPANY_LOG_LIST, return_data)
        );
      })
      .catch((error) => {
        //let err_data = errorMessageGraphQl(error);
        var returnData = {
          data: {},
          error: false,
          notify_msg: "",
        };
        dispatch(
          setResponseDetails(actionTypes.GET_COMPANY_LOG_LIST, returnData)
        );
        //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
      });
  };
};

export const saveCompanyRecLog = (client, request) => {
  return (dispatch) => {
    const mutation = gql`
      mutation saveCompanyRecLog(
        $mentionuser:[Float!]
        $mentiondetail: String
        $propertyid: Float!
        $propertyname: String!
        $companyid: Float!
        $text: String!
        $oldvalue: String!
        $newvalue: String!
        $logtime: Float!
        $logdate: Float!
        $logtype: Float!
        $fieldid: Float!
      ) {
        saveCompanyRecLog(
          mentionuser:$mentionuser
          mentiondetail:$mentiondetail
          propertyid: $propertyid
          propertyname: $propertyname
          companyid: $companyid
          text: $text
          oldvalue: $oldvalue
          newvalue: $newvalue
          logtype: $logtype
          logtime: $logtime
          logdate: $logdate
          fieldid: $fieldid
        ) {
          status
          message
        }
      }
    `;
    client
      .mutate({
        mutation: mutation,
        variables: request["save"],
      })
      .then((response) => {
        let returnData = [];
        if (response.data && response.data != undefined) {
          if (response.data.saveCompanyRecLog.status == "true") {
            returnData = {
              data: {},
              error: false,
              notify_msg: response.data.saveCompanyRecLog.message,
            };
            dispatch(
              notificationAll(actionTypes.NOTIFICATIONS, returnData, true)
            );

            dispatch(actionCreators.getCompanyRecLogListNew(client, request["getlog"]));
            dispatch(actionCreators.getCompanyRecActivityLogList(client, request["getlog"]));
            dispatch(actionCreators.getCompanyRecField(client, request["getlog"].companyid));
          } else {
            returnData = {
              data: {},
              error: true,
              notify_msg: response.data.saveCompanyRecLog.message,
            };
            dispatch(
              notificationAll(actionTypes.NOTIFICATIONS, returnData, false)
            );
          }
        }
      })
      .catch((error) => {
        let err_data = errorMessageGraphQl(error);
        dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
      });
  };
};
export const updateCompanyRecLog = (client, request) => {
  return (dispatch) => {
    const mutation = gql`
      mutation updateCompanyRecLog(
        $mentionuser:[Float!]
        $mentiondetail: String
        $text: String!
        $logtime: Float!
        $logdate: Float!
        $logid: Float!
      ) {
        updateCompanyRecLog(
          mentionuser:$mentionuser
          mentiondetail:$mentiondetail
          text: $text
          logtime: $logtime
          logdate: $logdate
          logid: $logid
        ) {
          status
          message
        }
      }
    `;
    client
      .mutate({
        mutation: mutation,
        variables: request["update"],
      })
      .then((response) => {
        let returnData = [];
        if (response.data && response.data != undefined) {
          if (response.data.updateCompanyRecLog.status == "true") {
            returnData = {
              data: {},
              error: false,
              notify_msg: response.data.updateCompanyRecLog.message,
            };

            dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));

            dispatch(actionCreators.getCompanyRecLogListNew(client, request["getlog"]));
            dispatch(actionCreators.getCompanyRecActivityLogList(client, request["getlog"]));
            dispatch(actionCreators.getCompanyRecField(client, request["getlog"].companyid));
          } else {
            returnData = {
              data: {},
              error: true,
              notify_msg: response.data.updateCompanyRecLog.message,
            };
            dispatch(
              notificationAll(actionTypes.NOTIFICATIONS, returnData, false)
            );
          }
        }
      })
      .catch((error) => {
        let err_data = errorMessageGraphQl(error);
        dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
      });
  };
};
export const deleteCompanyRecLogDetail = (client, request) => {
  return (dispatch) => {
    const query = gql`
      query deleteCompanyRecLogDetail($logid: Float!) {
        deleteCompanyRecLogDetail(logid: $logid) {
          status
          message
        }
      }
    `;
    client
      .query({
        query: query,
        variables: { logid: request.logid },
      })
      .then((response) => {
        let returnData = [];
        if (response.data && response.data != undefined) {
          if (response.data.deleteCompanyRecLogDetail.status == "true") {
            returnData = {
              data: {},
              error: false,
              notify_msg: response.data.deleteCompanyRecLogDetail.message,
            };

            dispatch(
              notificationAll(actionTypes.NOTIFICATIONS, returnData, true)
            );
            let logparam = { companyid: request.companyid, emailid: "", logtypeid: request.logtypeid, activitypeid: request.activitypeid };
            dispatch(actionCreators.getCompanyRecLogListNew(client, logparam));
            dispatch(actionCreators.getCompanyRecActivityLogList(client, logparam));

            dispatch(actionCreators.getCompanyRecField(client, request.companyid));
          } else {
            returnData = {
              data: {},
              error: true,
              notify_msg: response.data.deleteCompanyRecLogDetail.message,
            };
            dispatch(
              notificationAll(actionTypes.NOTIFICATIONS, returnData, false)
            );
          }
        }
      })
      .catch((error) => {
        let err_data = errorMessageGraphQl(error);
        dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
      });
  };
};

/**
 * [addCompanyRecLogComment saves comments of log]
 */
export const addCompanyRecLogComment = (client, request) => {
  return (dispatch) => {
    const mutation = gql`
      mutation addCompanyRecLogComment($type:String!, $logid: Float!, $comment: String!, $mentionuser:[Float!],$mentiondetail: String!,$companyid: Float!) {
        addCompanyRecLogComment(type:$type, logid: $logid, comment: $comment, mentionuser:$mentionuser,mentiondetail:$mentiondetail,companyid: $companyid) {
          status
          message
        }
      }
    `;
    client
      .mutate({
        mutation: mutation,
        variables: {
          type: request.type,
          logid: request.logid,
          comment: request.comment,
          mentionuser: request.mentionuser,
          mentiondetail: request.mentiondetail,
          companyid: request.companyid,
        },
      })
      .then((response) => {
        let returnData = [];
        if (response.data && response.data != undefined) {
          if (response.data.addCompanyRecLogComment.status == "true") {
            returnData = {
              data: {},
              error: false,
              notify_msg: response.data.addCompanyRecLogComment.message,
            };
            dispatch(
              notificationAll(actionTypes.NOTIFICATIONS, returnData, true)
            );
            let logparam = { companyid: request.companyid, emailid: "", logtypeid: request.logtypeid, activitypeid: request.activitypeid };
            dispatch(actionCreators.getCompanyRecLogListNew(client, logparam));
            dispatch(actionCreators.getCompanyRecActivityLogList(client, logparam));
            dispatch(actionCreators.getCompanyRecField(client, request.companyid));
          } else {
            returnData = {
              data: {},
              error: true,
              notify_msg: response.data.addCompanyRecLogComment.message,
            };
            dispatch(
              notificationAll(actionTypes.NOTIFICATIONS, returnData, false)
            );
          }
        }
      })
      .catch((error) => {
        let err_data = errorMessageGraphQl(error);
        dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
      });
  };
};
/**
 * [updateCompanyRecLogComment saves comments of log]
 */
export const updateCompanyRecLogComment = (client, request) => {
  return (dispatch) => {
    const mutation = gql`
      mutation updateCompanyRecLogComment($commentid: Float!,$comment: String!,$type:String!,$mentionuser:[Float!],$mentiondetail: String!) {
        updateCompanyRecLogComment(commentid: $commentid, comment: $comment,type:$type, mentionuser:$mentionuser,mentiondetail:$mentiondetail) {
          status
          message
        }
      }
    `;
    client
      .mutate({
        mutation: mutation,
        variables: {
          commentid: request.commentid,
          comment: request.comment,
          type: request.type,
          mentionuser: request.mentionuser,
          mentiondetail: request.mentiondetail,
        },
      })
      .then((response) => {
        let returnData = [];
        if (response.data && response.data != undefined) {
          if (response.data.updateCompanyRecLogComment.status == "true") {
            returnData = {
              data: {},
              error: false,
              notify_msg: response.data.updateCompanyRecLogComment.message,
            };
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
            let logparam = { companyid: request.companyid, emailid: "", logtypeid: request.logtypeid, activitypeid: request.activitypeid };
            dispatch(actionCreators.getCompanyRecLogListNew(client, logparam));
            dispatch(actionCreators.getCompanyRecActivityLogList(client, logparam));
            dispatch(actionCreators.getCompanyRecField(client, request.companyid));
          } else {
            returnData = {
              data: {},
              error: true,
              notify_msg: response.data.updateCompanyRecLogComment.message,
            };
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
          }
        }
      })
      .catch((error) => {
        let err_data = errorMessageGraphQl(error);
        dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
      });
  };
};
export const deleteCompanyRecComment = (client, request) => {
  return (dispatch) => {
    const query = gql`
      query deleteCompanyRecComment($commentid: Float!, $type: String!) {
        deleteCompanyRecComment(commentid: $commentid, type:$type) {
          status
          message
        }
      }
    `;
    client
      .query({
        query: query,
        variables: {
          commentid: request.commentid,
          type: request.type
        },
      })
      .then((response) => {
        let returnData = [];
        if (response.data && response.data != undefined) {
          if (response.data.deleteCompanyRecComment.status == "true") {
            returnData = {
              data: {},
              error: false,
              notify_msg: response.data.deleteCompanyRecComment.message,
            };

            dispatch(
              notificationAll(actionTypes.NOTIFICATIONS, returnData, true)
            );
            let logparam = { companyid: request.companyid, emailid: "", logtypeid: request.logtypeid, activitypeid: request.activitypeid };
            dispatch(actionCreators.getCompanyRecLogListNew(client, logparam));
            dispatch(actionCreators.getCompanyRecActivityLogList(client, logparam));
            dispatch(actionCreators.getCompanyRecField(client, request.companyid));
          } else {
            returnData = {
              data: {},
              error: true,
              notify_msg: response.data.deleteCompanyRecComment.message,
            };
            dispatch(
              notificationAll(actionTypes.NOTIFICATIONS, returnData, false)
            );
          }
        }
      })
      .catch((error) => {
        let err_data = errorMessageGraphQl(error);
        dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
      });
  };
};



//----------------------------------------------------------------------






export const saveFilterDetailCompany = (client, request) => {
  return (dispatch) => {
    const mutation = gql`
      mutation saveFilterDetail(
        $filterdata: [JSON!]!
        $fitertype: String!
        $filtername: String!
        $recordtypeid: Float!
      ) {
        saveFilterDetail(
          filterdata: $filterdata
          fitertype: $fitertype
          filtername: $filtername
          recordtypeid: $recordtypeid
        ) {
          id
          fitertype
          userid
          filtername
          filterquery
          filteroption
          isdefault
          recordtypeid
          isselected
        }
      }
    `;
    client
      .mutate({
        mutation: mutation,
        variables: {
          filterdata: request.filterdata,
          fitertype: request.fitertype,
          filtername: request.filtername,
          recordtypeid: request.recordtypeid,
        },
      })
      .then((response) => {
        let returnData = [];
        if (response.data && response.data != undefined) {
          if (response.data.saveFilterDetail.id) {
            returnData = {
              data: {},
              error: false,
              notify_msg: "Filter saved successfully",
            };
            dispatch(
              notificationAll(actionTypes.NOTIFICATIONS, returnData, true)
            );
          }
        }
        dispatch(actionCreators.getFilterListCompany(client, 3));
      })
      .catch((error) => {
        let err_data = errorMessageGraphQl(error);
        dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
      });
  };
};

export const getFilterListCompany = (client, recordtypeid) => {
  return function (dispatch) {
    const query = gql`
      query getFilterList($recordtypeid: Float!) {
        getFilterList(recordtypeid: $recordtypeid) {
          id
          fitertype
          userid
          filtername
          filterquery
          filteroption
          isdefault
          recordtypeid
          isselected
        }
      }
    `;
    client
      .query({
        query: query,
        fetchPolicy: "no-cache",
        variables: {
          recordtypeid: recordtypeid,
        },
      })
      .then((response) => {
        let return_data = dataSet.processGQLData(response.data.getFilterList);
        dispatch(
          setResponseDetails(actionTypes.GET_COMPANYFILTERS, return_data)
        );
      })
      .catch((error) => {
        //let err_data = errorMessageGraphQl(error);
        //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
      });
  };
};
export const deleteFilterCompany = (client, filterid) => {
  return function (dispatch) {
    const query = gql`
      query deleteFilter($filterid: Float!) {
        deleteFilter(filterid: $filterid) {
          status
          message
        }
      }
    `;
    client
      .query({
        query: query,
        fetchPolicy: "no-cache",
        variables: {
          filterid: filterid,
        },
      })
      .then((response) => {
        let returnData = [];
        if (response.data && response.data != undefined) {
          if (response.data.deleteFilter.status == "true") {
            returnData = {
              data: {},
              error: false,
              notify_msg: response.data.deleteFilter.message,
            };
            dispatch(
              notificationAll(actionTypes.NOTIFICATIONS, returnData, true)
            );
          } else {
            returnData = {
              data: {},
              error: true,
              notify_msg: response.data.deleteFilter.message,
            };
            dispatch(
              notificationAll(actionTypes.NOTIFICATIONS, returnData, false)
            );
          }
        }
        dispatch(actionCreators.getFilterListCompany(client, 3));
      })
      .catch((error) => {
        let err_data = errorMessageGraphQl(error);
        dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
      });
  };
};

export const updateFilterDefaultCompany = (client, filterid) => {
  return (dispatch) => {
    const mutation = gql`
      mutation updateFilterDefault(
        $type: String!
        $fiterid: Float!
        $recordtypeid: Float!
      ) {
        updateFilterDefault(
          type: $type
          fiterid: $fiterid
          recordtypeid: $recordtypeid
        ) {
          status
          message
        }
      }
    `;
    client
      .mutate({
        mutation: mutation,
        variables: {
          type: "isdefault",
          fiterid: filterid,
          recordtypeid: 3,
        },
      })
      .then((response) => {
        let returnData = [];
        if (response.data && response.data != undefined) {
          if (response.data.updateFilterDefault.status == "true") {
            returnData = {
              data: {},
              error: false,
              notify_msg: response.data.updateFilterDefault.message,
            };
            dispatch(
              notificationAll(actionTypes.NOTIFICATIONS, returnData, true)
            );
          } else {
            returnData = {
              data: {},
              error: true,
              notify_msg: response.data.updateFilterDefault.message,
            };
            dispatch(
              notificationAll(actionTypes.NOTIFICATIONS, returnData, false)
            );
          }
        }
        dispatch(actionCreators.getFilterListCompany(client, 3));
      })
      .catch((error) => {
        let err_data = errorMessageGraphQl(error);
        dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
      });
  };
};

export const updateFilterDetailCompany = (client, request) => {
  return (dispatch) => {
    const mutation = gql`
      mutation updateFilterDetail(
        $filterdata: [JSON!]!
        $fitertype: String!
        $filtername: String!
        $filterid: Float!
      ) {
        updateFilterDetail(
          filterdata: $filterdata
          fitertype: $fitertype
          filtername: $filtername
          filterid: $filterid
        ) {
          id
        }
      }
    `;
    client
      .mutate({
        mutation: mutation,
        variables: {
          filterdata: request.filterdata,
          fitertype: request.fitertype,
          filtername: request.filtername,
          filterid: request.filterid,
        },
      })
      .then((response) => {
        let returnData = [];
        if (response.data && response.data != undefined) {
          if (response.data.updateFilterDetail.id) {
            returnData = {
              data: {},
              error: false,
              notify_msg: "Filter updated successfully",
            };
            dispatch(
              notificationAll(actionTypes.NOTIFICATIONS, returnData, true)
            );
          } else {
            returnData = {
              data: {},
              error: true,
              notify_msg: "Filter couldn't get updated",
            };
            dispatch(
              notificationAll(actionTypes.NOTIFICATIONS, returnData, false)
            );
          }
        }
        dispatch(actionCreators.getFilterListCompany(client, 3));
      })
      .catch((error) => {
        let err_data = errorMessageGraphQl(error);
        dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
      });
  };
};


export const addCompanyLogEmoji = (client, request) => {
  return dispatch => {
    const mutation = gql`
           mutation addCompanyLogEmoji($logid:Float!,$emoji:String!,$emojidetail:String!,$logtype:String!) {
              addCompanyLogEmoji(logid:$logid,emoji:$emoji,emojidetail:$emojidetail,logtype:$logtype) {
                  status
                  message
               }
             }
           `;
    client.mutate({
      mutation: mutation,
      variables: {
        logid: request.logid,
        emoji: request.emoji,
        emojidetail: request.emojidetail,
        logtype: request.logtype
      }
    }).then(response => {
      let returnData = [];
      if (response.data && response.data != undefined) {
        if (response.data.addCompanyLogEmoji.status == "true") {
          let logparam = {
            companyid: request.companyid,
            emailid: request.emailid,
            logtypeid: request.logtypeid,
            activitypeid: request.activitypeid
          };
          dispatch(actionCreators.getCompanyRecActivityLogList(client, logparam));
          dispatch(actionCreators.getCompanyRecLogListNew(client, logparam));
        } else {
          returnData = {
            'data': {},
            'error': true,
            'notify_msg': response.data.addCompanyLogEmoji.message
          }
          dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
        }
      }
    }).catch((error) => {
      let err_data = errorMessageGraphQl(error);
      dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
    });
  }
}

const setResponseDetails = (newType, data) => {
  return {
    type: newType,
    val: data,
  };
};

const notificationAll = (newType, data, status) => {
  return {
    type: newType,
    val: data,
    status: status,
  };
};

const errorMessageGraphQl = (err) => {
  let msg = "Something Went Wrong!";
  if (err.graphQLErrors && err.graphQLErrors.length > 0) {
    msg = err.graphQLErrors[0].message;
  } else if (err.networkError && err.networkError.result) {
    msg = err.networkError.result.errors[0].message;
  } else if (err.message) {
    msg = err.message;
  }

  return {
    notify_msg: msg,
    error: true,
  };
};

export const clearNotification = (value) => {
  return function (dispatch) {
    dispatch(clearNotificationAll(actionTypes.NOTIFICATIONS, {}, false));
  };
};
const clearNotificationAll = (newType, data, status) => {
  return {
    type: actionTypes.NOTIFICATIONS,
    val: "",
    status: "",
  };
};
