import React, { Component } from "react";
import {
  Dialog, IconButton, Button, Icon, Tooltip, Grid,
  FormControl, Select, InputLabel, Menu, MenuItem, List,
  ListItem, ListItemText, DialogActions,
  Chip, TextField, withStyles, Fab, Card
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';
import FontAwesome from "react-fontawesome";

import { EditorState, convertToRaw, getPlainText, genKey } from 'draft-js';
import createSingleLinePlugin from 'draft-js-single-line-plugin';
import { MegadraftEditor, editorStateFromRaw, editorStateToJSON, MegadraftPlugin, insertDataBlock } from "megadraft-denistsuman";
import Editor from 'draft-js-plugins-editor';
import createMentionPlugin, { defaultSuggestionsFilter } from 'draft-js-mention-plugin';
import { fromJS } from 'immutable';
import forEach from 'lodash/forEach';
import 'draft-js-mention-plugin/lib/plugin.css';


const IconSmall = withStyles(() => ({
  root: {
    fontSize: "1.3rem",
    color: 'rgba(0, 0, 0, 0.6)'
  }
}))(Icon);
let mentions = [];
class CreatelogDialog extends Component {
  constructor(props) {
    super(props);
    let editorState = EditorState.createEmpty()

    const myContent = {
      "entityMap": {},
      "blocks": [
        {
          "key": genKey(),
          "text": " ",
          "type": "unstyled",
          "depth": 0,
          "inlineStyleRanges": [],
          "entityRanges": [],
          "data": {}
        }
      ]
    };
    // let editorState = editorStateFromRaw(myContent);

    this.state = {
      selectedDate: new Date(),
      logvaluetype: 'Phone Call',
      textvalue: '',
      dateformat: 'MM/DD/YYYY',
      timeZone: 'America/New_York',
      logtype: 0,
      propertyid: 1,
      activities: [],
      textvalue1: '',
      editorState: editorState,
      suggestions: mentions,
    };

    this.mentionPlugin = createMentionPlugin({
      mentions,
      entityMutability: 'IMMUTABLE',
      mentionPrefix: '@',
      supportWhitespace: true
    });

    this.singleLinePlugin = createSingleLinePlugin({
      stripEntities: false
    });
  }

  componentDidMount() {
    this.props.getActivityTypes(this.props.apolloClient.client);
    if (this.props.logvaluetype != '') {
      this.setState({ logvaluetype: this.props.logvaluetype });
    }
  }

  componentWillReceiveProps(props) {
    if (props.settingReducer.usersListArray) {
      let usersList = props.settingReducer.usersListArray;
    }

    if (this.props.settingReducer.usersListArray && this.props.settingReducer.usersListArray.data) {
      let users = [];
      this.props.settingReducer.usersListArray.data.map((user) => {
        users.push({
          id: user.id,
          name: user.name,
        });
      });
      mentions = users;
    }

    if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
      let usersetting = props.settingReducer.userSettingArray.data;

      let format = usersetting.dateformat;
      let timezone = usersetting.timezone;
      this.setState({ dateformat: format, timeZone: timezone })
    }

    if (props.logtype) {
      this.setState({ logtype: props.logtype });
    }
    if (props.settingReducer.masterActivityTypesList && props.settingReducer.masterActivityTypesList.data) {
      this.setState({ activities: props.settingReducer.masterActivityTypesList.data });
    }
  }
  onChange = (editorState) => {
    const content = editorState.getCurrentContent();
    const textvalue = content.getPlainText('\u0001');

    this.setState({
      textvalue1: textvalue,
      editorState: editorState,
    });
  };

  onSearchChange = ({ value }) => {
    this.setState({
      suggestions: defaultSuggestionsFilter(value, mentions),
    });
  };

  onAddMention = (mention) => {
    let that = this;
    setTimeout(() => {
      this.setState({ editorState: EditorState.moveFocusToEnd(that.state.editorState) })
    }, 10);
  }

  focus = () => {
    this.editor.focus();
  };

 
  handlechange = (event) => {

    let key = this.findWithAttr(this.state.activities, 'id', event.target.value);
    let prop_name = this.state.activities[key].name;

    this.setState({ propertyid: event.target.value, logvaluetype: prop_name })
  }
  handleDateChange = date => {
    this.setState({ selectedDate: date });
  };

  handleCancelAddLog = () => {
    let editorState = EditorState.createEmpty();
    this.setState({ textvalue1: '', editorState });  }

  handleSaveAddLog = () => {
    // let date = moment(this.state.selectedDate).format("X");
    const content = this.state.editorState.getCurrentContent();
    const raw = JSON.parse(JSON.stringify(convertToRaw(content)));
    let mention = [];

    Object.entries(raw.entityMap).map((entity) => {
      if (mention.indexOf(entity[1].data.mention.id) == -1) {
        mention.push(parseFloat(entity[1].data.mention.id))
      }
    });

    const textvalue = content.getPlainText('\u0001');

    let date_test = moment(this.state.selectedDate).format('YYYY-MM-DD HH:mm');
    var logdate = moment.tz(date_test, this.state.timeZone).format();
    var date = moment.utc(logdate).format('X');

    let saveLogparams = [];
    saveLogparams['save'] = {
      propertyname: "Note",
      mentionuser: mention,
      propertyid: 0,
      text: textvalue,
      logtime: parseFloat(date),
      logdate: parseFloat(date),
      oldvalue: "",
      newvalue: "",
      logtype: 5,
      fieldid: 0,
      taskid: parseFloat(this.props.task_id)
    };

    saveLogparams['getlog'] = { taskid: parseFloat(this.props.task_id), logtypeid: [] };
    this.props.saveTaskLog(this.props.apolloClient.client, saveLogparams);
    let editorState = EditorState.createEmpty();
    this.setState({ textvalue1: '', editorState });

  }

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }
  toCamelCase = (s) => {
    return s.replace(/([-_][a-z])/ig, ($1) => {
      return $1.toUpperCase()
        .replace('-', '')
        .replace('_', '');
    });
  };
  render() {
    const { MentionSuggestions } = this.mentionPlugin;
    const plugins = [this.singleLinePlugin, this.mentionPlugin];
    const { selectedDate } = this.state
    return (
      <>
        <ValidatorForm noValidate debounceTime={500} onSubmit={this.handleSaveAddLog}>
          <Grid className="p-20" container spacing={2}>
            <Grid item xs={12} className={'crm-editor-mention'} onClick={this.focus}>
              <label style={{ fontSize: '12px' , marginBottom : '4px' }} >Text*</label>
              <Editor
                editorState={this.state.editorState}
                onChange={this.onChange}
                plugins={plugins}
                blockRenderMap={createSingleLinePlugin.blockRenderMap}
                ref={(element) => { this.editor = element; }}
                placeholder={"Add Note Text"}
              />
              <MentionSuggestions
                onSearchChange={this.onSearchChange}
                suggestions={this.state.suggestions}
                onAddMention={this.onAddMention}
                onClose={() => this.setState({ ...this, suggestions: fromJS([]) })}
              />
            </Grid>
            {(this.state.textvalue1 && this.state.textvalue1.length > 0) &&
              <div className="mt-40">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container spacing={3}>
                    <Grid item xs={6} className="pt-0">
                      <InputLabel htmlFor="assignedto" className="font-size-14 font-weight-500">Date</InputLabel>
                      <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        format={this.state.dateformat.replace("DD", "dd").replace("YYYY", "yyyy")}
                        maxDate={new Date()}
                        value={selectedDate}
                        onKeyPress={(e) => e.preventDefault()}
                        onChange={this.handleDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} className="pt-0">
                      <InputLabel htmlFor="assignedto" className="font-size-14 font-weight-500">Time</InputLabel>
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        format="hh:mm a"
                        value={selectedDate}
                        onKeyPress={(e) => e.preventDefault()}
                        onChange={this.handleDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                      />
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
                <div className="pull-right">
                  <Button className="mr-10" color="secondary" onClick={this.handleCancelAddLog}>Cancel</Button>
                  <Button variant="contained" className={"btn_Primary mr-10 text-white"} onClick={this.handleSaveAddLog} type="submit" color="primary">Save</Button>
                </div>
              </div>
            }
          </Grid>

        </ValidatorForm>
      </>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  contactReducer: state.contactReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    saveTaskLog: (client, request) => dispatch(actionCreators.saveTaskLog(client, request)),
    getActivityTypes: (client) => { dispatch(actionCreators.getActivityTypes(client)) },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatelogDialog);
