import React, { Component } from "react";
import { Dialog, IconButton, Button, Icon, Tooltip, Grid, FormControl, Select, InputLabel, Menu, MenuItem, List, ListItem, ListItemText, DialogActions, Chip, TextField } from "@material-ui/core";
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';

import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';
import DropdownDefault from "../Layout/DropdownDefault";
import CommonVisibiltyAdd from "../Layout/CommonVisibiltyAdd"


export const createDynamicFields = (data) => {
  var elements = data.state.fieldsList
  var fieldsArray = [];
  let relatedOptions = data.state.relatedListArray;
  relatedOptions = relatedOptions && relatedOptions.filter(value => Object.keys(value).length !== 0);
  const options = relatedOptions.map((option) => {
    const firstLetter = option.type;
    return {
      firstLetter: firstLetter,
      ...option,
    };
  });

  elements.map((v, i) => {
    if (v.fieldstatus === true) {
      if ((v.fieldtype.id == '1' && v.id == '57')) {
        fieldsArray.push(<><Grid item xs={12}>
          {/* <InputLabel>Name</InputLabel> */}
          <TextValidator
            ref={ref => data[v.fieldkey] = ref}
            onBlur={(event) => data.handleBlur(event, v.fieldkey)}
            type="text"
            id={v.id}
            required={(v.recordcreationdefaultvalue == 2) ? true : false}
            disabled={v.recordcreationdefaultvalue == 3 ? true : false}
            className="w-100 input_bx"
            placeholder={"Add Name"}
            name={v.fieldkey}
            label={"Name"}
            onChange={(event) => data.handleChange({ fieldid: v.id, taskvalue: event.target.value }, v.fieldkey)}
            value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].taskvalue : ""}
            validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
            errorMessages={(v.recordcreationdefaultvalue == 2) ? ["This field is required"] : []}
            InputLabelProps={{ shrink: true, disableAnimation: false, focused: true }}
          />
        </Grid></>
        )
      } else if (v.fieldtype.id == '1' && v.fieldkey == 'tags') { // tags
        var valTag = [];
        if (data.state.fieldsObj[v.fieldkey]) {
          valTag = data.state.fieldsObj[v.fieldkey].values
        }
        fieldsArray.push(<Grid item xs={12}>
          <InputLabel>Tags</InputLabel>
          <Autocomplete
            disableOpenOnFocus
            multiple
            value={valTag}
            filterSelectedOptions
            disabled={v.recordcreationdefaultvalue == 3 ? true : false}
            name={v.fieldkey}
            id="tagInputId1"
            options={data.state.systemTagsList.map(option => option.tagname)}
            freeSolo
            onChange={(event, value) => data.handleChange({ fieldid: v.id, values: value }, v.fieldkey)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip label={option.charAt(0).toUpperCase() + option.slice(1)} {...getTagProps({ index })} />
              ))
            }
            renderInput={params => (
              <TextValidator
                ref={ref => data[v.fieldkey] = ref}
                onBlur={(event) => data.handleBlur(event, v.fieldkey)}
                {...params}
                placeholder="Add Tag"
                name={v.fieldkey}
                value={valTag && valTag.length ? 1 : null}
                className="w-100 input_bx"
                required={(v.recordcreationdefaultvalue == 2) ? true : false}
                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                validators={(v.recordcreationdefaultvalue == 2 && (valTag == null || valTag.length == 0)) ? ["required"] : []}
                errorMessages={(v.recordcreationdefaultvalue == 2 && (valTag == null || valTag.length == 0)) ? ["This field is required"] : []}
                onBlur={() =>
                  setTimeout(() => {
                    document.getElementById("tagInputId1").value = "";
                  }, 10)
                }
                onFocus={() =>
                  setTimeout(() => {
                    document.getElementById("tagInputId1").value = "";
                  }, 0)
                }
              />
            )}
          />
        </Grid>)
      } else if (v.fieldtype.id == '1' && v.id == '61') { // owner
        var nameOwner = [];
        if (data.state.fieldsObj[v.fieldkey]) {
          nameOwner = data.state.fieldsObj[v.fieldkey].values
        }
        fieldsArray.push(<Grid item xs={12}>
          <InputLabel>Owner</InputLabel>
          <Autocomplete
            disableOpenOnFocus
            forcePopupIcon={false}
            name={v.fieldkey}
            className="w-100 input_bx"
            options={data.state.usersListArray}
            getOptionLabel={option => option.name ? option.name : ""}
            value={nameOwner}
            disabled={v.recordcreationdefaultvalue == 3 ? true : false}
            name={v.fieldkey}
            onChange={(event, value) => data.handleChange({ fieldid: v.id, values: value }, v.fieldkey)}
            id={v.id}
            renderInput={params => (
              <TextValidator
                ref={ref => data[v.fieldkey] = ref}
                onBlur={(event) => data.handleBlur(event, v.fieldkey)}
                {...params}
                variant="standard"
                className="w-100 input_bx"
                fullWidth
                name={v.fieldkey}
                value={nameOwner && nameOwner.length ? 1 : null}
                required={(v.recordcreationdefaultvalue == 2) ? true : false}
                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                validators={(v.recordcreationdefaultvalue == 2 && (nameOwner == null || nameOwner.length == 0)) ? ["required"] : []}
                errorMessages={(v.recordcreationdefaultvalue == 2 && (nameOwner == null || nameOwner.length == 0)) ? ["This field is required"] : []}
              />
            )}
          />
        </Grid>)
      } else if (v.fieldtype.id == '1' && v.id == '59') { // related field

        var relatedVal = [];
        if (data.state.fieldsObj[v.fieldkey]) {
          relatedVal = data.state.fieldsObj[v.fieldkey].values
        }
        fieldsArray.push(<Grid item xs={12}>
          <InputLabel>{v.fieldlabel}</InputLabel>
          <Autocomplete
            disableOpenOnFocus
            name={v.fieldkey}
            options={options}
            groupBy={(option) => option.firstLetter.toUpperCase()}
            getOptionLabel={(option) => option.name}
            value={relatedVal}
            disabled={(v.recordcreationdefaultvalue == 3 || data.state.isAddedFromSidebar) ? true : false}
            name={v.fieldkey}
            onChange={(event, value) => data.handleChange({ fieldid: v.id, values: value }, v.fieldkey)}
            id={v.id}
            renderInput={params => (
              <TextValidator
                {...params}
                variant="standard"
                fullWidth
                name={v.fieldkey}
                className="w-100 input_bx"
                placeholder="Add Relation"
                ref={ref => data[v.fieldkey] = ref}
                onBlur={(event) => data.handleBlur(event, v.fieldkey)}
                required={(v.recordcreationdefaultvalue == 2) ? true : false}
                validators={(v.recordcreationdefaultvalue == 2 && (relatedVal == null || relatedVal.length == 0)) ? ["required"] : []}
                errorMessages={(v.recordcreationdefaultvalue == 2 && (relatedVal == null || relatedVal.length == 0)) ? ["This field is required"] : []}
              />
            )}
          />
        </Grid>)
      } else if (v.fieldtype.id == '1') {//TextField with
        fieldsArray.push(<Grid item xs={6}>
          <InputLabel>{v.fieldlabel}</InputLabel>
          <TextValidator
            ref={ref => data[v.fieldkey] = ref}
            onBlur={(event) => data.handleBlur(event, v.fieldkey)}
            type="text"
            required={(v.recordcreationdefaultvalue == 2) ? true : false}
            id={v.id}
            disabled={v.recordcreationdefaultvalue == 3 ? true : false}
            className="w-100 input_bx"
            placeholder={`Add ${v.fieldlabel && v.fieldlabel.charAt(0).toUpperCase() + v.fieldlabel.slice(1)}`}
            onChange={(event) => data.handleChange({ fieldid: v.id, value: event.target.value }, v.fieldkey)}
            name={v.fieldkey}
            value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].value : ""}
            validators={v.recordcreationdefaultvalue == 2 ? ["required"] : []}
            errorMessages={
              v.recordcreationdefaultvalue == 2
                ? ["This field is required"]
                : []
            }
          />
        </Grid>)
      } else if (v.fieldtype.id == '9') {
        fieldsArray.push(<Grid item xs={6}>
          <InputLabel>{v.fieldlabel}</InputLabel>
          <TextValidator
            ref={ref => data[v.fieldkey] = ref}
            onBlur={(event) => data.handleBlur(event, v.fieldkey)}
            type="text"
            required={(v.recordcreationdefaultvalue == 2) ? true : false}
            id={v.id}
            disabled={v.recordcreationdefaultvalue == 3 ? true : false}
            className="w-100 input_bx"
            placeholder={`Add ${v.fieldlabel && v.fieldlabel.charAt(0).toUpperCase() + v.fieldlabel.slice(1)}`}
            onChange={(event) => data.handleChange({ fieldid: v.id, value: event.target.value }, v.fieldkey)}
            name={v.fieldkey}
            value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].value : ""}
            validators={v.recordcreationdefaultvalue == 2 ? ["required", "IsURLValid"] : ["IsURLValid"]}
            errorMessages={
              v.recordcreationdefaultvalue == 2
                ? ["This field is required", "Please enter a valid domain"]
                : ["Please enter a valid domain"]
            }
          />
        </Grid>)
      }
      else if (v.fieldtype.id == '6' || v.fieldtype.id == '10' || v.fieldtype.id == '7') {
        fieldsArray.push(<Grid item xs={6}>
          <InputLabel>{v.fieldlabel}</InputLabel>
          <TextValidator
            type="number"
            required={(v.recordcreationdefaultvalue == 2) ? true : false}
            id={v.id}
            disabled={v.recordcreationdefaultvalue == 3 ? true : false}
            className="w-100 input_bx"
            placeholder={`Add ${v.fieldlabel && v.fieldlabel.charAt(0).toUpperCase() + v.fieldlabel.slice(1)}`}
            onChange={(event) => data.handleChange({ fieldid: v.id, value: event.target.value }, v.fieldkey)}
            name={v.fieldkey}
            value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].value : ""}
            validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
            errorMessages={(v.recordcreationdefaultvalue == 2) ? ["This field is required"] : []}
          />
        </Grid>)
      }

      if (v.fieldtype.id == '2') {
        fieldsArray.push(<Grid item xs={6}>
          <InputLabel>{v.fieldlabel}</InputLabel>
          <TextValidator
            ref={ref => data[v.fieldkey] = ref}
            onBlur={(event) => data.handleBlur(event, v.fieldkey)}
            multiline
            type="text"
            id={v.id}
            required={(v.recordcreationdefaultvalue == 2) ? true : false}
            disabled={v.recordcreationdefaultvalue == 3 ? true : false}
            className="w-100 input_bx"
            placeholder={`Add ${v.fieldlabel && v.fieldlabel.charAt(0).toUpperCase() + v.fieldlabel.slice(1)}`}
            onChange={(event) => data.handleChange({ fieldid: v.id, taskvalue: event.target.value }, v.fieldkey)}
            name={v.fieldkey}
            value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].taskvalue : ""}
            validators={(v.recordcreationdefaultvalue == 2 && (data.state.country == "")) ? ["required"] : []}
            errorMessages={(v.recordcreationdefaultvalue == 2 && (data.state.country == "")) ? ["This field is required"] : []}
          />
        </Grid>)
      }
      if (v.fieldtype.id == '4') {//if dropdown
        if (v.id === "63" || v.id === "65" || v.id === "58") {//65-visibility  63 Priority 58 Activity Type
          //65 -visibility with team start
          if (v.id === "65") {
            fieldsArray.push(
              <CommonVisibiltyAdd data={data} v={v} />
            )
          } else if (v.id === "63") {
            fieldsArray.push(<Grid item xs={6}>
              <InputLabel>{v.fieldlabel}</InputLabel>
              <SelectValidator
                className="w-100 input_bx"
                ref={ref => data[v.fieldkey] = ref}
                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].values : "none"}
                onChange={(event) => data.handleChange({ fieldid: v.id, values: event.target.value }, v.fieldkey)}
                name={v.fieldlabel}
                id={v.id}
                required={(v.recordcreationdefaultvalue == 2) ? true : false}
                validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
                errorMessages={(v.recordcreationdefaultvalue == 2) ? ["This field is required"] : []}
              >
                <MenuItem value="none" style={{display:'none'}} disabled><span className="text-hint">{`Add ${v.fieldlabel && v.fieldlabel.charAt(0).toUpperCase() + v.fieldlabel.slice(1)}`}</span></MenuItem>
                {v.options && v.options.map((item, key) =>
                  <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item}>{item.name}</MenuItem>
                )}
              </SelectValidator>
            </Grid>
            )
          }
          //65-visibility with team end
          else {
            fieldsArray.push(<Grid item xs={v.id == '58' ? 12 : 6}>
              <InputLabel>{v.fieldlabel}</InputLabel>
              <SelectValidator
                className="w-100 input_bx"
                ref={ref => data[v.fieldkey] = ref}
                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].values : ""}
                onChange={(event) => data.handleChange({ fieldid: v.id, values: event.target.value }, v.fieldkey)}
                name={v.fieldlabel}
                id={v.id}
                required={(v.recordcreationdefaultvalue == 2) ? true : false}
                validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
                errorMessages={(v.recordcreationdefaultvalue == 2) ? ["This field is required"] : []}
              >
                {
                  (v.id == "58") ?
                    v.options && v.options.map((item, key) =>
                      (item.is_disable == false) &&
                      <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item}>{item.name}</MenuItem>
                    )
                    :
                    v.options && v.options.map((item, key) =>
                      <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item}>{item.name}</MenuItem>
                    )
                }
              </SelectValidator>
            </Grid>
            )
          }
        } else {
          fieldsArray.push(<Grid item xs={6}>
            <InputLabel>{v.fieldlabel}</InputLabel>
            <SelectValidator
              ref={ref => data[v.fieldkey] = ref}
              className="w-100 input_bx"
              disabled={v.recordcreationdefaultvalue == 3 ? true : false}
              value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].values : "none"}
              onChange={(event) => data.handleChange({ fieldid: v.id, values: event.target.value }, v.fieldkey)}
              name={v.fieldlabel}
              id={v.id}
              required={(v.recordcreationdefaultvalue == 2) ? true : false}
              validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
              errorMessages={(v.recordcreationdefaultvalue == 2) ? ["This field is required"] : []}
            >
              <MenuItem value="none" style={{display:'none'}} disabled><span className="text-hint">{`Add ${v.fieldlabel && v.fieldlabel.charAt(0).toUpperCase() + v.fieldlabel.slice(1)}`}</span></MenuItem>
              {v.options && v.options.map((item, key) =>
                <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item}>{item.optionvalue}</MenuItem>
              )}
            </SelectValidator>
          </Grid >
          )
        }
      }
      if (v.fieldtype.id == '5') {//multidropdown field
        if (data.state.fieldsObj[v.fieldkey]) {
          var valDetail = [];
          valDetail = data.state.fieldsObj[v.fieldkey].values
        }
        console.log('valDetail',valDetail);
        fieldsArray.push(<Grid item xs={6}>
          <InputLabel>{v.fieldlabel}</InputLabel>
          <Autocomplete
            multiple
            disabled={v.recordcreationdefaultvalue == 3 ? true : false}
            name={v.fieldkey}
            onChange={(event, value) => data.handleChange({ fieldid: v.id, values: value }, v.fieldkey)}
            id={v.id}
            options={v.options}
            getOptionLabel={option => option.optionvalue}
            renderInput={params => (
              <TextValidator
                {...params}
                ref={ref => data[v.fieldkey] = ref}
                onBlur={(event) => data.handleBlur(event, v.fieldkey)}
                variant="standard"
                fullWidth
                className="w-100 input_bx"
                placeholder={`Add ${v.fieldlabel && v.fieldlabel.charAt(0).toUpperCase() + v.fieldlabel.slice(1)}`}
                name={v.fieldkey}
                value={valDetail && valDetail.length ? 1 : null}
                required={(v.recordcreationdefaultvalue == 2) ? true : false}
                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                validators={(v.recordcreationdefaultvalue == 2 && (valDetail == null || valDetail.length == 0)) ? ["required"] : []}
                errorMessages={(v.recordcreationdefaultvalue == 2 && (valDetail == null || valDetail.length == 0)) ? ["This field is required"] : []}
              />
            )}
          />
        </Grid>
        )
      }
      if (v.fieldtype.id == '3') {//checkbox field
        fieldsArray.push(<Grid item xs={6}>
          <FormControl className="w-100 input_bx">
            <FormControlLabel
              control={
                <Checkbox
                  name={v.fieldkey}
                  disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                  id={v.id}
                  checked={data.state.checkedB}
                  onChange={(event, value) => data.handleChange({ fieldid: v.id, value: value ? "1" : "0" }, v.fieldkey)}
                  value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].value : ""}
                  color="primary"
                />
              }
              label={<span className="font-size-13">{v.fieldlabel}</span>}
            />
          </FormControl>
        </Grid>
        )
      }
      if (v.fieldtype.id == '8') {//datepicker field
        var max = "";
        var errorMsg = "Please enter a value ";
        var dtFormat = "MM/dd/yyyy";
        var selectedDate = data.state.selectedDate;
        if (data.state.fieldsObj[v.fieldkey]) {
          selectedDate = data.state.fieldsObj[v.fieldkey].values
        }

        var selectedDate1 = data.state.selectedDate1;
        if (data.state.fieldsObj['reminderdate']) {
          selectedDate1 = data.state.fieldsObj['reminderdate'].values
        }

        if (data.state.userSettings != "") {
          dtFormat = data.state.userSettings.dateformat;
          dtFormat = dtFormat.replace("DD", "dd").replace("YYYY", "yyyy");
        }


        if (data.state.repeattype == "days") {
          max = "maxNumber:365";
          errorMsg += " less than or equal to 365.";
        } else if (data.state.repeattype == "weeks") {
          max = "maxNumber:52";
          errorMsg += "less than or equal to 52.";
        } else {
          max = "maxNumber:12";
          errorMsg += "less than or equal to 12.";
        }


        if (v.id == "60") {
          fieldsArray.push(<>
            <Grid item xs={12} style={{ marginTop: '14px' }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container spacing={3}>
                  <Grid item xs={6} className="pt-0">
                    <InputLabel>{v.fieldlabel}</InputLabel>
                    <KeyboardDatePicker
                      // label={v.fieldlabel}
                      format={dtFormat}
                      required={(v.recordcreationdefaultvalue == 2) ? true : false}
                      disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                      placeholder={dtFormat.replace("DD", "dd").replace("YYYY", "yyyy").replace("MM", "mm")}
                      error={(v.recordcreationdefaultvalue == 2 && selectedDate == null || selectedDate == "Invalid Date") ? true : false}
                      value={selectedDate}
                      onChange={(event, value) => data.handleChange({ fieldid: v.id, values: event == "Invalid Date" ? "Invalid Date" : event }, v.fieldkey)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      helperText={(v.recordcreationdefaultvalue == 2 && selectedDate == null) ? "This field is required" : (selectedDate == "Invalid Date") ? "Please enter a valid date." : ""}
                    />
                  </Grid>
                  <Grid item xs={6} className="pt-0">
                    <InputLabel>{"Time"}</InputLabel>
                    <KeyboardTimePicker
                      format="hh:mm a"
                      required={(v.recordcreationdefaultvalue == 2) ? true : false}
                      disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                      // placeholder={dtFormat}
                      error={(v.recordcreationdefaultvalue == 2 && selectedDate == null || selectedDate == "Invalid Date") ? true : false}
                      value={selectedDate}
                      onChange={(event, value) => data.handleChange({ fieldid: v.id, values: event == "Invalid Date" ? "Invalid Date" : event }, v.fieldkey)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      helperText={(v.recordcreationdefaultvalue == 2 && selectedDate == null) ? "This field is required" : (selectedDate == "Invalid Date") ? "Please enter a valid date." : ""}
                    />
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} style={{ padding: '0' }}>
              <DropdownDefault
                menuButton={
                  <p style={{
                    color: '#7467ef',
                    textDecoration: 'underline',
                    cursor: "pointer",
                    marginLeft: '5px',
                    display: 'block',
                  }}
                  >
                    {data.state.selectedRepeatValue != 'never' && <Icon style={{ fontSize: "16px", marginBottom: '-4px' }}>repeat</Icon>}
                    <span style={{ marginLeft: '2px' }}>{data.state.selectedRepeatName}</span>
                  </p>
                }
              >
                <MenuItem onClick={() => data.changeRepeatOption('never', 'Set to repeat', 0)} className="flex flex-middle">Never</MenuItem>
                <MenuItem onClick={() => data.changeRepeatOption('daily', 'Daily', 1)} className="flex flex-middle">Daily</MenuItem>
                <MenuItem onClick={() => data.changeRepeatOption('weekly', 'Weekly', 7)} className="flex flex-middle">Weekly</MenuItem>
                <MenuItem onClick={() => data.changeRepeatOption('monthly', 'Monthly', 30)} className="flex flex-middle">Monthly</MenuItem>
                <MenuItem onClick={() => data.changeRepeatOption('yearly', 'Yearly', 365)} className="flex flex-middle">Yearly</MenuItem>
                <MenuItem onClick={() => data.changeRepeatOption('custom', 'Custom', 0)} className="flex flex-middle">Custom</MenuItem>
              </DropdownDefault>
            </Grid>

            {data.state.selectedRepeatValue == 'custom' && <><Grid item xs={4}>
              <TextValidator
                ref={ref => data[v.fieldkey] = ref}
                onBlur={(event) => data.handleBlur(event, v.fieldkey)}
                type="number"
                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                className="w-100 input_bx"
                label={"Repeat every"}
                onChange={(event) => data.handleNormalFields(event)}
                name={"repeatdays"}
                value={data.state.repeatdays}
                validators={["isNumber", "minNumber:1", max]}
                errorMessages={[
                  "Please enter a valid number format",
                  "Please enter a value greater than 0",
                  errorMsg
                ]
                }
              />
            </Grid>
              <Grid item xs={3}>
                <FormControl className="w-100 input_bx" style={{ marginTop: '14px' }}>
                  <SelectValidator
                    ref={ref => data[v.fieldkey] = ref}
                    value={data.state.repeattype}
                    onChange={(event) => data.handleNormalFields(event)}
                    name={"repeattype"}
                    id={"repeattype"}
                  >
                    <MenuItem className="select-menu-item w-100 input_bx" value={"days"}>Days</MenuItem>
                    <MenuItem className="select-menu-item w-100 input_bx" value={"weeks"}>Weeks</MenuItem>
                    <MenuItem className="select-menu-item w-100 input_bx" value={"months"}>Months</MenuItem>
                  </SelectValidator>
                </FormControl>
              </Grid>
              <Grid item xs={5}>

              </Grid>
            </>
            }
            <Grid item xs={12} style={{ padding: '0' }}>
              <DropdownDefault
                menuButton={
                  <p style={{
                    color: '#7467ef',
                    textDecoration: 'underline',
                    cursor: "pointer",
                    marginLeft: '5px',
                    display: 'block',
                  }}
                  >
                    {data.state.selectedReminderValue != '0' && <Icon style={{ fontSize: "16px", marginBottom: '-4px' }}>notifications</Icon>}
                    <span style={{ marginLeft: '2px' }}>{data.state.selectedReminderName}</span>
                  </p>
                }
              >
                <MenuItem onClick={() => data.changeReminderOption('0', 'Add a reminder', 0)} className="flex flex-middle">No reminder</MenuItem>
                <MenuItem onClick={() => data.changeReminderOption('1', '30 minutes before', 30)} className="flex flex-middle">30 minutes before</MenuItem>
                <MenuItem onClick={() => data.changeReminderOption('2', '1 hour before', 60)} className="flex flex-middle">1 hour before</MenuItem>
                <MenuItem onClick={() => data.changeReminderOption('3', '1 day before', 1440)} className="flex flex-middle">1 day before</MenuItem>
                <MenuItem onClick={() => data.changeReminderOption('4', '1 week before', 10080)} className="flex flex-middle">1 week before</MenuItem>
                <MenuItem onClick={() => data.changeReminderOption('5', 'Custom', 0)} className="flex flex-middle">Custom</MenuItem>
              </DropdownDefault>
            </Grid>
            {data.state.selectedReminderValue == "5" &&
              <Grid item xs={12} style={{ marginTop: '14px' }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container spacing={3}>
                    <Grid item xs={6} className="pt-0">
                      <InputLabel>{"Reminder Date"}</InputLabel>
                      <KeyboardDatePicker
                        // label={"Reminder Date"}
                        format={dtFormat.replace("DD", "dd").replace("YYYY", "yyyy").replace("MM", "mm")}
                        required={(v.recordcreationdefaultvalue == 2) ? true : false}
                        disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                        placeholder={dtFormat}
                        error={(v.recordcreationdefaultvalue == 2 && selectedDate1 == null || selectedDate1 == "Invalid Date") ? true : false}
                        value={selectedDate1}
                        onChange={(event, value) => data.handleChange({ fieldid: '62', values: event == "Invalid Date" ? "Invalid Date" : event }, "reminderdate")}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        helperText={(v.recordcreationdefaultvalue == 2 && selectedDate == null) ? "This field is required" : (selectedDate1 == "Invalid Date") ? "Please enter a valid date." : ""}
                      />
                    </Grid>
                    <Grid item xs={6} className="pt-0">
                      <InputLabel>{"Time"}</InputLabel>
                      <KeyboardTimePicker
                        // label={"Time"}
                        format="hh:mm a"
                        required={(v.recordcreationdefaultvalue == 2) ? true : false}
                        disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                        // placeholder={dtFormat}
                        error={(v.recordcreationdefaultvalue == 2 && selectedDate1 == null || selectedDate1 == "Invalid Date") ? true : false}
                        value={selectedDate1}
                        onChange={(event, value) => data.handleChange({ fieldid: '62', values: event == "Invalid Date" ? "Invalid Date" : event }, "reminderdate")}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        helperText={(v.recordcreationdefaultvalue == 2 && selectedDate1 == null) ? "This field is required" : (selectedDate1 == "Invalid Date") ? "Please enter a valid date." : ""}
                      />
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
            }

          </>)
        } else if (v.id != "62") {
          fieldsArray.push(<Grid item xs={6} >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <FormControl className="w-100 input_bx">
                {/* <InputLabel>{v.fieldlabel}</InputLabel> */}
                <KeyboardDatePicker
                  label={v.fieldlabel}
                  // className="w-100 input_bx"
                  format={dtFormat}
                  required={(v.recordcreationdefaultvalue == 2) ? true : false}
                  disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                  placeholder={dtFormat}
                  error={(v.recordcreationdefaultvalue == 2 && selectedDate == null || selectedDate == "Invalid Date") ? true : false}
                  value={selectedDate}
                  onChange={(event, value) => data.handleChange({ fieldid: v.id, values: event == "Invalid Date" ? "Invalid Date" : event }, v.fieldkey)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  helperText={(v.recordcreationdefaultvalue == 2 && selectedDate == null) ? "This field is required" : (selectedDate == "Invalid Date") ? "Please enter a valid date." : ""}

                />
              </FormControl>
            </MuiPickersUtilsProvider>
          </Grid>
          )
        }
      }


    }
  })

  return fieldsArray;
}