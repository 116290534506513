import React, { Component } from "react";
import {
    Button, withStyles, CardContent, Icon, Snackbar,
    Tooltip, Grid, DialogActions, DialogTitle,
    DialogContent, InputLabel, Dialog, IconButton, Link
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import * as actions from '../../../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';

const TooltipNew = withStyles((theme) => ({
    tooltip: {
        fontSize: 14,
    },
}))(Tooltip);

class TeamActivePerrmission extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            minimizeScreen: false,
            fullScreen: false,

        }
    }
    handleViewEmailDialogMinimize = () => {
        this.setState(oldStateminimizeScreen => ({ minimizeScreen: !oldStateminimizeScreen.minimizeScreen }));
        this.setState({ fullScreen: false })
    };

    handleViewEmailDialogFull = () => {
        this.setState(oldStatefullScreen => ({ fullScreen: !oldStatefullScreen.fullScreen }));
        this.setState({ minimizeScreen: false })
    };
    handleFormSubmit = () => {
        let req = {
            settingstatus: true
        }
        this.props.updateTeamSetting(this.props.apolloClient.client, req)
        this.props.handleClose()
    }

    render() {
        let { minimizeScreen, fullScreen } = this.state;
        let { open, handleClose } = this.props;
        return (
            <Dialog
                onClose={handleClose}
                open={open}
                maxWidth="md"
                fullWidth={true}
                className={`crm-custom-bg-primary custom_dialog ${minimizeScreen && fullScreen ? "alignitemsend-fullscreen" : ""
                    } ${minimizeScreen ? "alignitemsend" : ""} ${fullScreen ? "fullscreen" : ""
                    }`}
                disableBackdropClick

            >
                <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">

                    <h4 className="m-0 text-white">Activate Team Permissions</h4>
                    <div className="d-flex">
                        <TooltipNew title={minimizeScreen ? 'Normal Screen' : 'Minimize Screen'}>
                            <IconButton onClick={this.handleViewEmailDialogMinimize}>
                                <Icon className="text-white">{minimizeScreen ? 'add' : 'remove'}</Icon>
                            </IconButton>
                        </TooltipNew>
                        <TooltipNew title={fullScreen ? 'Exit Full Screen' : 'Full Screen'}>
                            <IconButton onClick={this.handleViewEmailDialogFull}>
                                <Icon className="text-white">{fullScreen ? 'fullscreen_exit' : 'fullscreen'}</Icon>
                            </IconButton>
                        </TooltipNew>
                        <TooltipNew title="Close">
                            <IconButton onClick={handleClose}>
                                <Icon className="text-white">clear</Icon>
                            </IconButton>
                        </TooltipNew>
                    </div>
                </div>
                <div className="dialog-body p-30 ">
                    <Grid container spacing={2}>
                        <span className="pb-8 pt-4">
                            Team Permissions is a way to group users together to manage the visibility of records and control access to certain areas of the app.
                        </span>
                        <span className="font-weight-600 pb-4 pt-4">
                            Important Information about Activating Team Permissions
                                </span>
                        <ul>
                            <li className="pb-4 pt-4">
                                Once Team Permissions is activated, the visibility of records will be set on a Team by Team basis in the ‘Access and Visibility’ section.
                            </li>
                            <li className="pb-4 pt-4">
                                Once Team Permissions is activated, users that don’t belong to a team will have access to all areas of Copper but will only be able to see records owned by themselves.
                                </li>
                            <li className="pb-4 pt-4">
                                Team Permissions is only available on Professional and Business plans. Subscribing to any other plan will disable Team Permissions and revert all records to being publicly visible to everyone.
                                    </li>
                            <li className="pb-4 pt-4">
                                We highly recommend reading our Team Permissions support article before activating this feature.
                            </li>
                        </ul>

                    </Grid>
                </div>

                <DialogActions className="dialog-action">
                    <Button variant="contained"
                        onClick={this.props.handleClose} >
                        Cancel
                         </Button>
                    <Button
                        onClick={this.handleFormSubmit}
                        variant="contained"
                        type="submit"
                        color="primary"
                    > Active            </Button>
                </DialogActions>
            </Dialog >
        )
    }
}
const mapStateToProps = state => {
    return {
        settingReducer: state.settingReducer,
        apolloClient: state.apolloClient
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateTeamSetting: (client, req) => dispatch(actions.updateTeamSetting(client, req)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(TeamActivePerrmission);

