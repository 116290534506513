import * as actionTypes from "./actionTypes";
import { gql } from "apollo-boost";
import * as dataSet from "./dataSet";
import * as actionCreators from "./index";

export const saveGoal = (client, request) => {
  return (dispatch) => {
    const mutation = gql`
        mutation saveGoal($goaldata: GoalInput!) {
          saveGoal(goaldata:$goaldata) {           
            id
            goaltype
            datetype
            user{
              id
              name
            }
            team{
              id
              teamname
            }
            pipline
            previousdate
            currentdate
            nextdate
            alldate
            createdby
            createddate
            lastmodifiedby
            lastmodifieddate
          }
        }
    `;
    client.mutate({
      mutation: mutation,
      variables: {goaldata:request['savegoal'] ? request['savegoal'] : request},
    }).then((response) => {
      let returnData = [];
      if (response.data && response.data != undefined) {
        let return_data = dataSet.processGQLData(response.data.saveGoal);
        if (return_data.data.id) {
          if(request['type'] && (request['type'] == 'Pipeline' || request['type'] == 'Activity') ){
            returnData = {
              'data': {},
              'error': false,
              'notify_msg': request['type'] +" save successfully"
            }

            dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
          
            let goallistparams = {datetype: request['savegoal'].datetype, goaltype: request['savegoal'].goaltype};
            dispatch(actionCreators.getGoalList(client, goallistparams));

          }else if(request['type'] &&( request['type'] == 'Pipeline data' || request['type'] == 'Activity data')){
            returnData = {
              'data': {},
              'error': false,
              'notify_msg': request['type'] + " removed successfully"
            }

            dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
          
            let goallistparams = {datetype: request['savegoal'].datetype, goaltype: request['savegoal'].goaltype};
            dispatch(actionCreators.getGoalList(client, goallistparams));
          }else{
            returnData = {
              'data': {},
              'error': false,
              'notify_msg': "Goal saved successfully"
            }

            dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
          
            let goallistparams = {datetype: request.datetype, goaltype: request.goaltype};
            dispatch(actionCreators.getGoalList(client, goallistparams));

          }
          
          
          //dispatch(setResponseDetails(actionTypes.GET_TEAM_DETIALS, return_data));
        }
      }
    }).catch((error) => {
      let err_data = errorMessageGraphQl(error);
      dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
    });
  };
};


export const getGoalList = (client, request) => {
  return function (dispatch) {
    const query = gql`
      query getGoalList ($datetype: Float!, $goaltype:Float!, $filterdata: [Float!]) {
        getGoalList (datetype: $datetype, goaltype: $goaltype, filterdata: $filterdata) {
          id
          goaltype
          datetype
          user{
            id
            name
            email
            teamlist{
              team{
                id
                teamname
                totalmember
                memberlist{
                  user{
                    id
                    name
                  }
                  team{
                    id
                    teamname
                    totalmember          
                  }
                  teammanager
                }
              }
            }
          }  
          pipline
          activity
          previousdate
          currentdate
          nextdate
          alldate
          createdby
          createddate
          lastmodifiedby
          lastmodifieddate      
        }
      }
    `;
    client.query({
      query: query,
      fetchPolicy: "no-cache",
      variables: request,
    }).then((response) => {
      let return_data = dataSet.processGQLData(response.data.getGoalList);
      if(request.goaltype == 1){
        if(request.datetype == 1){
          dispatch(setResponseDetails(actionTypes.GET_SALES_MONTHLY_GOAL_LIST, return_data));
        }else if(request.datetype == 2){
          dispatch(setResponseDetails(actionTypes.GET_SALES_QUARTER_GOAL_LIST, return_data));
        }else if(request.datetype == 3){
          dispatch(setResponseDetails(actionTypes.GET_SALES_YEAR_GOAL_LIST, return_data));
        }
      }
      if(request.goaltype == 2){
        if(request.datetype == 1){
          dispatch(setResponseDetails(actionTypes.GET_WON_MONTHLY_GOAL_LIST, return_data));
        }else if(request.datetype == 2){
          dispatch(setResponseDetails(actionTypes.GET_WON_QUARTER_GOAL_LIST, return_data));
        }else if(request.datetype == 3){
          dispatch(setResponseDetails(actionTypes.GET_WON_YEAR_GOAL_LIST, return_data));
        }
      }
      if(request.goaltype == 3){
        if(request.datetype == 1){
          dispatch(setResponseDetails(actionTypes.GET_ACTIVITY_MONTHLY_GOAL_LIST, return_data));
        }else if(request.datetype == 2){
          dispatch(setResponseDetails(actionTypes.GET_ACTIVITY_QUARTER_GOAL_LIST, return_data));
        }else if(request.datetype == 3){
          dispatch(setResponseDetails(actionTypes.GET_ACTIVITY_YEAR_GOAL_LIST, return_data));
        }
      }
      
    }).catch((error) => {
      var returnData = {
        data: {},
        error: true,
        notify_msg: "",
      };
      // dispatch(setResponseDetails(actionTypes.NOTIFICATIONS, returnData));
    });
  };
};

export const deleteGoal = (client, request) => {
  return function (dispatch) {
    const query = gql`
      query deleteGoal($goalid: Float!){
        deleteGoal(goalid: $goalid) {
          status
          message       
        }
      }
    `;
    client.query({
      query: query,
      fetchPolicy: "no-cache",
      variables: {
        goalid: request.goalid,
      }
    }).then((response) => {
      let returnData = [];
      if (response.data.deleteGoal.status == "true") {
        returnData = {
          data: {},
          error: false,
          notify_msg: response.data.deleteGoal.message,
        };
        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
        
        let goallistparams = {datetype: request.datetype, goaltype: request.goaltype};
        dispatch(actionCreators.getGoalList(client, goallistparams));

        dispatch(actionCreators.getTeam(client));
      } else {
        returnData = {
          data: {},
          error: true,
          notify_msg: response.data.deleteGoal.message,
        };
        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
      }
    }).catch((error) => {
      var returnData = {
        data: {},
        error: false,
        notify_msg: "",
      };
      dispatch(setResponseDetails(actionTypes.NOTIFICATIONS, returnData));
    });
  };
};


export const userFilter = (client, request) => {
  return function (dispatch) {
    const query = gql`
      query userFilter ($teamid: Float!, $name:String!) {
        userFilter (teamid: $teamid, name: $name) {
          id name email password companyId accesstoken sessiontoken
          activestatus googlestatus passwordtoken emailverified
          forgotpasswordtoken forgotverified systemadmin googlesync
          dataexport dropbox title phoneno address country
          state city pincode companyowner fcmtoken createdhost 
          createdby createddate lastmodifiedby lastmodifiedhost 
          lastmodifieddate lastlogin isFirstCmp
          teamlist{
              teammanager
              user{
                  id
                  name
                  email
              }
              team{
                  id
                  teamname
                  totalmember
                  memberlist{
                      user{
                          id
                          name
                          email
                      }
                      teammanager
                  }
              }
          }     
        }
      }
    `;
    client.query({
      query: query,
      fetchPolicy: "no-cache",
      variables: request,
    }).then((response) => {
      let return_data = dataSet.processGQLData(response.data.userFilter);
      dispatch(setResponseDetails(actionTypes.GET_USER_FILTER_LIST_FOR_GOAL, return_data));
    }).catch((error) => {
      var returnData = {
        data: {},
        error: true,
        notify_msg: "",
      };
      // dispatch(setResponseDetails(actionTypes.NOTIFICATIONS, returnData));
    });
  };
};


export const goalReport = (client, request) => {
  return function (dispatch) {
    const query = gql`
      query goalReport ($datetype: Float!, $goaltype: Float!) {
        goalReport (datetype: $datetype, goaltype: $goaltype) {
          status
          message
          data
        }
      }
    `;
    client.query({
      query: query,
      fetchPolicy: "no-cache",
      variables: request,
    }).then((response) => {
      let return_data = dataSet.processGQLData(response.data.goalReport);
      dispatch(setResponseDetails(actionTypes.GET_GOAL_REPORT, return_data));
    }).catch((error) => {
      var returnData = {
        data: {},
        error: true,
        notify_msg: "",
      };
      // dispatch(setResponseDetails(actionTypes.NOTIFICATIONS, returnData));
    });
  };
};









export const getTeamDetail = (client, request) => {
  return function (dispatch) {
    const query = gql`
      query getTeamDetail($teamid: Float!){
        getTeamDetail(teamid: $teamid) {
          id
          teamname
          totalmember       
        }
      }
    `;
    client.query({
      query: query,
      fetchPolicy: "no-cache",
      variables: {
        teamid: request.teamid,
      }
    }).then((response) => {
      let return_data = dataSet.processGQLData(response.data.getTeamDetail);
      dispatch(setResponseDetails(actionTypes.GET_TEAM_DETIALS, return_data));
    }).catch((error) => {
      var returnData = {
        data: {},
        error: false,
        notify_msg: "",
      };
      dispatch(setResponseDetails(actionTypes.NOTIFICATIONS, returnData));
    });
  };
}
export const saveTeamMember = (client, request) => {
  return (dispatch) => {
    const mutation = gql`
        mutation saveTeamMember($ismanager: Boolean!,$teamid: Float!,$userid: Float!,$memberid:Float!) {
          saveTeamMember(ismanager: $ismanager,teamid:$teamid,userid: $userid,memberid: $memberid) {           
            id
            user{
              id
              name
              email
            }
            team{
              id
              teamname
              totalmember             
            }
            teammanager
          }
        }
    `;
    client.mutate({
      mutation: mutation,
      variables: {
        ismanager: request.ismanager,
        teamid: request.teamid,
        userid: request.userid,
        memberid: request.memberid
      },
    }).then((response) => {
      let returnData = [];
      if (response.data && response.data != undefined) {
        let return_data = dataSet.processGQLData(response.data.saveTeamMember);
        let req = { teamid: request.teamid, }
        dispatch(actionCreators.getTeamMember(client, req))
      }
    }).catch((error) => {
      let err_data = errorMessageGraphQl(error);
      dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
    });
  };
}
export const getTeamMember = (client, request) => {
  return function (dispatch) {
    const query = gql`
      query getTeamMember($teamid: Float!){
        getTeamMember(teamid: $teamid) {
          id
          user{
            id
            name
            email
          } 
          teammanager
         }
        }
    `;
    client.query({
      query: query,
      fetchPolicy: "no-cache",
      variables: {
        teamid: request.teamid,
      }
    }).then((response) => {
      let return_data = dataSet.processGQLData(response.data.getTeamMember);
      dispatch(setResponseDetails(actionTypes.GET_TEAM_MEMBER_LIST, return_data));
    }).catch((error) => {
      var returnData = {
        data: {},
        error: false,
        notify_msg: "",
      };
      dispatch(setResponseDetails(actionTypes.GET_TEAM_MEMBER_LIST, returnData));
    });
  };
}

export const deleteTeamMember = (client, request) => {
  return function (dispatch) {
    const query = gql`
        query deleteTeamMember($memberid: Float!){
          deleteTeamMember(memberid: $memberid) {
            status
            message       
          }
        }
      `;
    client.query({
      query: query,
      fetchPolicy: "no-cache",
      variables: {
        memberid: request.memberid,
      }
    }).then((response) => {

      let returnData = [];
      if (response.data.deleteTeamMember.status == "true") {
        console.log("hh", response)
        returnData = {
          data: {},
          error: false,
          notify_msg: response.data.deleteTeamMember.message,
        };
        let req = {
          teamid: request.teamid
        }
        dispatch(actionCreators.getTeamMember(client, req));
        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));

      }
    }).catch((error) => {

    });
  };
};


const setResponseDetails = (newType, data) => {
  return {
    type: newType,
    val: data,
  };
};

const notificationAll = (newType, data, status) => {
  return {
    type: newType,
    val: data,
    status: status,
  };
};

const errorMessageGraphQl = (err) => {
  let msg = "Something Went Wrong!";
  if (err.graphQLErrors && err.graphQLErrors.length > 0) {
    msg = err.graphQLErrors[0].message;
  } else if (err.networkError && err.networkError.result) {
    msg = err.networkError.result.errors[0].message;
  } else if (err.message) {
    msg = err.message;
  }

  return {
    notify_msg: msg,
    error: true,
  };
};

export const clearNotification = (value) => {
  return function (dispatch) {
    dispatch(clearNotificationAll(actionTypes.NOTIFICATIONS, {}, false));
  };
};
const clearNotificationAll = (newType, data, status) => {
  return {
    type: actionTypes.NOTIFICATIONS,
    val: "",
    status: "",
  };
};
