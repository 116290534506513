import React, { Component } from "react";
import CompanyProfileLeft from "./CompanyProfileLeft";
import CompanyProfileCenter from "./CompanyProfileCenter";
import CompanyProfileRight from "./CompanyProfileRight";

class Company extends Component {
  state = {
    company_id: ""
  }
  componentWillMount() {
    this.setState({
      company_id: parseInt(this.props.match.params.id)
    })
  }
  render() {
    return (
      <React.Fragment>
        <CompanyProfileLeft company_id={this.state.company_id}  />
        <CompanyProfileCenter company_id={this.state.company_id}  />
        <div className="lead_right">
          <CompanyProfileRight company_id={this.state.company_id}/>
        </div>
      </React.Fragment>
    );
  }
}

export default Company;
