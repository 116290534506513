
import React, { Component } from "react";
import { Checkbox } from "@material-ui/core";
import { Table } from 'antd';
import { connect } from "react-redux";
import * as actionCreators from '../../../store/actions/index';

class CommentsNotifications extends Component {
    state = {
        selection: [],
        checkbox_comments_inapp1: '',
        checkbox_comments_email1: '',
        checkbox_comments_mobile1: '',
        checkbox_comments_inapp2: '',
        checkbox_comments_email12: '',
        checkbox_comments_mobile2: '',
    };
    componentDidMount() {
        this.props.getNotificationSetting(this.props.apolloClient.client);
        if (this.props.settingReducer.getnotificationsettingdata) {
            let data = this.props.settingReducer.getnotificationsettingdata.data;
            this.setnotification(data);
            this.setState({ selection: data });
        }
    }
    componentWillReceiveProps(props) {
        if (props.settingReducer.getnotificationsettingdata) {
            let data = props.settingReducer.getnotificationsettingdata.data;
            this.setnotification(data);
            this.setState({ selection: data });
        }
    }
    setnotification = (data) => {
        if (data && data.length > 0) {
            data.map((ndata) => {
                if (ndata) {
                    if (ndata.notification_type === "COMMENTS_01") {
                        this.setState({
                            checkbox_comments_inapp1: ndata.in_app,
                            checkbox_comments_email1: ndata.in_email,
                            checkbox_comments_mobile1: ndata.in_mobile,
                        });
                    }
                    if (ndata.notification_type === "COMMENTS_02") {
                        this.setState({
                            checkbox_comments_inapp2: ndata.in_app,
                            checkbox_comments_email12: ndata.in_email,
                            checkbox_comments_mobile2: ndata.in_mobile,
                        });
                    }
                }
            });
        }
    }

    handleCheckBoxChange = (event, name) => {
        let submitData;

        if (name === 'checkbox_comments_inapp1') {
            submitData = [{
                type: 'COMMENTS_01', in_email: event.target.checked, in_app: this.state.checkbox_comments_email1, in_mobile: this.state.checkbox_comments_mobile1
            }]
        }
        if (name === 'checkbox_comments_inapp2') {
            submitData = [{
                type: 'COMMENTS_02', in_email: event.target.checked, in_app: this.state.checkbox_comments_email12, in_mobile: this.state.checkbox_comments_mobile2
            }]
        }
        let req = { settingdata: submitData };
        this.props.UpdateNotificationSetting(this.props.apolloClient.client, req);
        this.setState({ [name]: event.target.checked });
    };
    render() {
        const comments_rows = [];
        const comments_columns = [

            {
                title: "",
                dataIndex: "name",
                className: "align_left",
                width: 300
            },
            {
                title: "In App",
                dataIndex: "inapp",
                // className: "align_left"
            },
            {
                title: "",
                dataIndex: "email",
                // className: "align_left"
            },
            {
                title: "",
                dataIndex: "mobile",
                // className: "align_left"
            },
            {
                title: "",
                dataIndex: "action",
            }
        ];

        comments_rows.push(
            {
                name: (
                    <div className="ml-10">
                        <label className="crm-custom-field-label font-weight-500">Comments on an activity I created</label>
                    </div>
                ),
                inapp: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_comments_inapp1}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_comments_inapp1')}
                        value="checkbox_comments_inapp1"
                        color="primary"
                    />
                </React.Fragment>),
            },
            {
                name: (
                    <div className="ml-10">
                        <label className="crm-custom-field-label font-weight-500">Reactions on an activity I created</label>
                    </div>
                ),
                inapp: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_comments_inapp2}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_comments_inapp2')}
                        value="checkbox_comments_inapp2"
                        color="primary"
                    />
                </React.Fragment>),
            },
        )
        return (
            <React.Fragment >
                <div className="table_width antd-tables">
                    <Table
                        bordered={false}
                        columns={comments_columns}
                        pagination={false}
                        dataSource={comments_rows}
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        apolloClient: state.apolloClient,
        settingReducer: state.settingReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getNotificationSetting: (client) => dispatch(actionCreators.getNotificationSetting(client)),
        UpdateNotificationSetting: (client, request) => dispatch(actionCreators.UpdateNotificationSetting(client, request)),
    }
}
export default (connect(mapStateToProps, mapDispatchToProps)(CommentsNotifications));

