import React, { Component } from "react";
import { Checkbox } from "@material-ui/core";
import { Table } from 'antd';
import { connect } from "react-redux";
import * as actionCreators from '../../../store/actions/index';

class RemindersNotification extends Component {
    state = {
        selection: [],
        checkbox_reminder_email1: '',
        checkbox_reminder_email2: '',
        checkbox_reminder_inapp1: '',
        checkbox_reminder_inapp2: '',
        checkbox_reminder_mobile1: '',
        checkbox_reminder_mobile2: '',
    };
    componentDidMount() {
        this.props.getNotificationSetting(this.props.apolloClient.client);
    }
    componentWillReceiveProps(props) {
        if (props.settingReducer.getnotificationsettingdata) {
            let data = props.settingReducer.getnotificationsettingdata.data;
            this.setnotification(data);
            this.setState({ selection: data });
        }
    }
    setnotification = (data) => {
        if (data && data.length > 0) {
            data.map((ndata) => {
                if (ndata) {
                    if (ndata.notification_type === "REMINDERS_01") {
                        this.setState({
                            checkbox_reminder_inapp1: ndata.in_app,
                            checkbox_reminder_email1: ndata.in_email,
                            checkbox_reminder_mobile1: ndata.in_mobile,
                        });
                    }
                    if (ndata.notification_type === "REMINDERS_02") {
                        this.setState({
                            checkbox_reminder_inapp2: ndata.in_app,
                            checkbox_reminder_email2: ndata.in_email,
                            checkbox_reminder_mobile2: ndata.in_mobile,
                        });
                    }
                }
            });
        }
    }
    handleCheckBoxChange = (event, name) => {
        let submitData;

        if (name === 'checkbox_reminder_email1') {
            submitData = [{
                type: 'REMINDERS_01', in_email: event.target.checked, in_app: this.state.checkbox_reminder_inapp1, in_mobile: this.state.checkbox_reminder_mobile1
            }]
        }
        if (name === 'checkbox_reminder_email2') {
            submitData = [{
                type: 'REMINDERS_02', in_email: event.target.checked, in_app: this.state.checkbox_reminder_inapp2, in_mobile: this.state.checkbox_reminder_mobile2
            }]
        }
        if (name === 'checkbox_reminder_inapp1') {
            submitData = [{
                type: 'REMINDERS_01', in_app: event.target.checked, in_email: this.state.checkbox_reminder_email1, in_mobile: this.state.checkbox_reminder_mobile1
            }]
        }
        if (name === 'checkbox_reminder_inapp2') {
            submitData = [{
                type: 'REMINDERS_02', in_app: event.target.checked, in_email: this.state.checkbox_reminder_email2, in_mobile: this.state.checkbox_reminder_mobile2
            }]
        }
        if (name === 'checkbox_reminder_mobile1') {
            submitData = [{
                type: 'REMINDERS_01', in_mobile: event.target.checked, in_email: this.state.checkbox_reminder_email1, in_app: this.state.checkbox_reminder_inapp1
            }]
        }
        if (name === 'checkbox_reminder_mobile2') {
            submitData = [{
                type: 'REMINDERS_02', in_mobile: event.target.checked, in_email: this.state.checkbox_reminder_email2, in_app: this.state.checkbox_reminder_inapp2
            }]
        }
        let req = { settingdata: submitData };
        this.props.UpdateNotificationSetting(this.props.apolloClient.client, req);
        this.setState({ [name]: event.target.checked });
    };

    render() {
        const columns = [];
        const rows = [];
        const general_rows = [];

        const general_columns = [

            {
                title: "",
                dataIndex: "name",
                className: "align_left",
                width: 300
            },
            {
                title: "Email",
                dataIndex: "email",
                // className: "align_left"
            },
            {
                title: "In App",
                dataIndex: "inapp",
                // className: "align_left"
            },
            {
                title: "Mobile",
                dataIndex: "mobile",
                // className: "align_left"
            },
            {
                title: "",
                dataIndex: "action",
            }
        ];

        general_rows.push(
            {
                name: (
                    <div className="ml-10">
                        <label className="crm-custom-field-label font-weight-500">Task is due</label>
                    </div>
                ),
                email: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_reminder_email1}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_reminder_email1')}
                        value="checkbox_reminder_email1"
                        color="primary"
                    />
                </React.Fragment>),
                inapp: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_reminder_inapp1}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_reminder_inapp1')}
                        value="checkbox_reminder_inapp1"
                        color="primary"
                    />
                </React.Fragment>),
                mobile: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_reminder_mobile1}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_reminder_mobile1')}
                        value="checkbox_reminder_mobile1"
                        color="primary"
                    />
                </React.Fragment>),
            },
            {
                name: (
                    <div className="ml-10">
                        <label className="crm-custom-field-label font-weight-500">Task reminders</label>
                    </div>
                ),
                email: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_reminder_email2}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_reminder_email2')}
                        value="checkbox_reminder_email2"
                        color="primary"
                    />
                </React.Fragment>),
                inapp: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_reminder_inapp2}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_reminder_inapp2')}
                        value="checkbox_reminder_inapp2"
                        color="primary"
                    />
                </React.Fragment>),
                mobile: (<React.Fragment>
                    <Checkbox
                        checked={this.state.checkbox_reminder_mobile2}
                        onChange={event => this.handleCheckBoxChange(event, 'checkbox_reminder_mobile2')}
                        value="checkbox_reminder_mobile2"
                        color="primary"
                    />
                </React.Fragment>),
            },
        )



        return (
            <React.Fragment>
                <div className="table_width antd-tables">
                    <Table
                        bordered={false}
                        columns={general_columns}
                        pagination={false}
                        dataSource={general_rows}
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        apolloClient: state.apolloClient,
        settingReducer: state.settingReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getNotificationSetting: (client) => dispatch(actionCreators.getNotificationSetting(client)),
        UpdateNotificationSetting: (client, request) => dispatch(actionCreators.UpdateNotificationSetting(client, request)),
    }
}
export default (connect(mapStateToProps, mapDispatchToProps)(RemindersNotification));

