
import React, { Component } from 'react';
import {
  CircularProgress,
  Fab,
  Drawer,
  Paper,
  Grid,
  Icon,
  Avatar,
  Typography,
} from "@material-ui/core";
import * as actions from "../../../store/actions/index";
import { connect } from "react-redux";
import EmailIcon from '@material-ui/icons/Email';
import SubjectIcon from '@material-ui/icons/Subject';
import NoteIcon from '@material-ui/icons/Note';
import TodayIcon from '@material-ui/icons/Today';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import TelegramIcon from '@material-ui/icons/Telegram';
import DescriptionIcon from '@material-ui/icons/Description';
import CallIcon from '@material-ui/icons/Call';
import CompanyProfileFrame from "../../Company/CompanyProfileFrame";
import { Link, withRouter } from "react-router-dom";

class CompaniesSearchLogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 'Everything',
      masterSerachData: [],
      serachText: "",
      companyData: [],
      isDataLoading: true,
      company_id: '',
      drawerOpen: false,
    };
  }

  componentWillMount() {
    this.setState({ masterSerachData: [] });
    this.props.settingReducer.masterSerach = [];
  }

  componentDidMount() {
    if (this.props.masterSerachData) {
      // console.log(this.props.masterSerachData, 'componentDidMount');
      const data = this.props.masterSerachData;
      this.displayArray(data);
      this.setState({ serachText: this.props.serachText });
    }
  }

  componentWillReceiveProps(props) {
    if (props.masterSerachData) {
      const data = props.masterSerachData;
      if (data !== this.state.data) {
        this.displayArray(data);
      }
    }
    this.setState({ serachText: props.serachText });
  }

  displayArray = (data) => {
    if (data) {
      var NewArray = [];
      data.map((item) => {
        let arrayItem = {};
        if (item.recordtype === "company") {
          let logdata = [];
          let url = '';
          if (item.__companydetail__) {
            arrayItem.id = item.id;
            url = "/company/company/" + item.id;
            logdata = this.getDetails(item.__companydetail__);
          } else if (item.__source__) {
            arrayItem.id = item.__source__.id;
            url = "/company/company/" + item.__source__.id;
            logdata = this.getLogDetails(item, item.__source__.__companydetail__ && item.__source__.__companydetail__);
          }
          arrayItem.data = logdata;
          arrayItem.URLMain = url;
          arrayItem.userImageURL = item.image;
          arrayItem.recordtype = item.recordtype;

          NewArray.push(arrayItem);
        }
        this.setState({ companyData: NewArray });
        setTimeout(() => {
          this.setState({ isDataLoading: false });
        }, 5000);
      })
    }
  }

  getLogDetails = (ALlLogData, data) => {
    let demo = {};
    demo['name'] = data[0].fieldvalue;
    demo['text'] = ALlLogData.text;
    demo['propertyname'] = ALlLogData.propertyname;
    return demo;
  };

  getDetails = (DetailsData) => {
    let demo = {};
    DetailsData && DetailsData.length > 0 && DetailsData.map((dt, i) => {
      if (dt.__field__ && dt.allvalues.length > 0) {
        if (dt.__field__.fieldkey === "owner" && dt.allvalues[0].name) {
          demo[dt.__field__.fieldkey] = dt.allvalues[0].name
        }
        else if (dt.allvalues[0].value) {
          demo[dt.__field__.fieldkey] = dt.allvalues[0].value
        }
      }
    })
    return demo;
  };

  detailsUI = (op) => {
    let maindata = op.data;
    const keyword = this.state.serachText;
    const entries = Object.values(maindata);

    let matchcase = entries.filter(word => {
      let e = word.toString()
      return e.toLowerCase().includes(keyword.toLowerCase())
    });

    matchcase.push(maindata.text, maindata.propertyname);
    const propertyname = matchcase.find(function (element) { return element === maindata.propertyname });
    let ValueofMatchCasees = [
      { Name: 'email', field: matchcase.find(function (element) { return element === maindata.email }) },
      { Name: 'owner', field: matchcase.find(function (element) { return element === maindata.owner }) },
      { Name: 'phones', field: matchcase.find(function (element) { return element === maindata.phones }) },
      { Name: 'description', field: matchcase.find(function (element) { return element === maindata.description }) },
      { Name: 'tags', field: matchcase.find(function (element) { return element === maindata.tags }) },
      { Name: 'websites', field: matchcase.find(function (element) { return element === maindata.websites }) },
      { Name: 'social', field: matchcase.find(function (element) { return element === maindata.social }) },
      { Name: 'activitytype', field: matchcase.find(function (element) { return element === maindata.activitytype }) },
      { Name: 'visibility', field: matchcase.find(function (element) { return element === maindata.visibility }) },
      { Name: 'value', field: matchcase.find(function (element) { return element === maindata.value }) },
      { Name: 'priority', field: matchcase.find(function (element) { return element === maindata.priority }) },
      { Name: 'primarycontact', field: matchcase.find(function (element) { return element === maindata.primarycontact }) },
      { Name: 'pipeline', field: matchcase.find(function (element) { return element === maindata.pipeline }) },
      { Name: 'stage', field: matchcase.find(function (element) { return element === maindata.stage }) },
      { Name: 'source', field: matchcase.find(function (element) { return element === maindata.source }) },
      { Name: 'Email Domain', field: matchcase.find(function (element) { return element === maindata.emaildomain }) },
      { Name: 'address', field: matchcase.find(function (element) { return element === maindata.address }) },
      { Name: 'related To', field: matchcase.find(function (element) { return element === maindata.relatedto }) },
      { Name: 'contact Type', field: matchcase.find(function (element) { return element === maindata.contacttype }) },
      { Name: propertyname, field: matchcase.find(function (element) { return element === maindata.text }), icon: 'logs' },
    ];

    let data = ValueofMatchCasees.filter((ele, ind) => ele.field !== undefined && ind === ValueofMatchCasees.findIndex(elem => elem.field === ele.field));
    const ALLData = data.length > 2 ? data.slice(0, 2) : data;

    return (
      ALLData && ALLData.map((item, index) => {
        return (
          <React.Fragment>
            {item.Name && item.field ?
              <Grid className="text_ellipsis d-flex mt-16">
                {item.icon ?
                  <>
                    {item.Name === 'Email' && <EmailIcon style={{ width: '16px' }} />}
                    {item.Name === 'Note' && <NoteIcon style={{ width: '16px' }} />}
                    {item.Name === 'Meeting' && <TodayIcon style={{ width: '16px' }} />}
                    {item.Name === 'WhatsApp Discussion' && <QuestionAnswerIcon style={{ width: '16px' }} />}
                    {item.Name === 'Lunch' && <TelegramIcon style={{ width: '16px' }} />}
                    {item.Name === 'To Do' && <DescriptionIcon style={{ width: '16px' }} />}
                    {item.Name === 'Phone Call' && <CallIcon style={{ width: '16px' }} />}

                  </>
                  : <SubjectIcon style={{ width: '16px' }} />
                }
                <Grid className="ml-16 mt-6" key={index} >
                  <Grid className="font-weight-400 text-gray" >
                    {item.Name.charAt(0).toUpperCase() + item.Name.slice(1)}
                  </Grid>
                  <Grid
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: '600px',
                      paddingTop: '4px',
                    }}
                    className="text_ellipsis d-flex mt--4 font-weight-400 text-gray"
                  >
                    <b>{item.field}</b>
                  </Grid>
                </Grid>
              </Grid> : ''}
          </React.Fragment>
        );
      }))
  };

  handleDrawerRight = (company_id) => {
    if (this.state.company_id && (this.state.company_id === company_id)) { } else {
      this.setState({
        company_id: company_id,
        drawerOpen: true,
      }, () => {
        this.forceUpdate()
      })
    }
  };

  closeDrawerRight = (e) => {
    e.preventDefault()
    this.setState({ drawerOpen: false, company_id: '' });
  };

  render() {
    const { companyData, companyLogs, isDataLoading, drawerOpen, serachText } = this.state;
    console.log('companyData ::', companyData);

    return (
      <React.Fragment>
        {isDataLoading === true ?
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: '10vh', backgroundColor: 'rgba(0,0,0,0)', margin: '100px' }}
          >
            <Grid item xs={3}>
              <CircularProgress size={30} />
            </Grid>
          </Grid>
          :
          <>
            {companyData && companyData.length > 0 ?
              companyData.map((item) => (
                <>
                  <Paper
                    square
                    className="p-16 ml-20 gray-on-hover"
                    elevation={0}
                    style={{ width: '900px' }}
                  >
                    <Grid
                      container
                      style={{ cursor: 'pointer' }}
                      spacing={1}
                      onClick={(e) => this.handleDrawerRight(item.id)}
                      className="m-10 ">
                      <Grid className="d-flex">
                        <Grid item className="mr-20">
                          {item.icon ?
                            <Fab size="small" className="small-size overflow-hidden custom-avatar" style={{ padding: '20px' }}>
                              <Icon className={"m-0 font-weight-normal"} >{item.icon} </Icon>
                            </Fab>
                            :
                            <>
                              {!item.userImageURL &&
                                <Avatar
                                  style={{ cursor: 'pointer' }}
                                  className={"avatar_circle color_" + (item.data.name) ? item.data.name.charAt(0).toUpperCase() : ""}>
                                  {(item.data.name) ? item.data.name.charAt(0).toUpperCase() : ""}
                                </Avatar>}
                              {item.userImageURL &&
                                <Avatar
                                  style={{ cursor: 'pointer' }}
                                  className="avatar_circle color_a"
                                  alt="Lead image"
                                  src={item.userImageURL} />}
                            </>
                          }

                        </Grid>
                        <div className="white_space_nowrap">
                          {item.data &&
                            <>
                              <span className="font-weight-500 company_user_name">
                                <Link to={"/company/company/" + item.id}>
                                  {item.data.name}
                                </Link>
                              </span>
                              <Grid className="text_ellipsis d-flex" >
                                {item.data.title && item.data.title + '|'}
                                {item.data.comapny && item.data.comapny + '|'}
                                {item.data.status && item.data.status}
                              </Grid>
                              {this.detailsUI(item)}
                            </>
                          }
                        </div>
                      </Grid>
                    </Grid>
                  </Paper>
                </>
              ))
              :
              <Paper square className="p-16 mb-16 paper-bx gray-on-hover" style={{ margin: '10px 10px 10px 20px', minWidth: '900px' }} >
                <Grid className="d-flex">
                  <Icon style={{ margin: '6px' }}>search</Icon>
                  <Typography className="font-size-16 " variant="h6" color="inherit" >
                    No search results found for "{serachText}"
                  </Typography>
                </Grid>
                <Grid style={{ margin: '20px 0 0 25px' }}>
                  Here are a few suggestions:
                  <ul>
                    <li>Double check your spelling.</li>
                    <li>Try using fewer words.</li>
                    <li>Try using words that are less specific.</li>
                  </ul>
                </Grid>
              </Paper>
            }
          </>
        }
        {this.state.company_id &&
          <Drawer
            width={"100px"}
            variant="temporary"
            anchor={"right"}
            className="drawer_right"
            open={drawerOpen}
            onClose={this.closeDrawerRight}
            ModalProps={{
              keepMounted: true
            }}
          >
            <CompanyProfileFrame
              company_id={this.state.company_id}
              companyLogs={companyLogs}
              handleClose={this.closeDrawerRight} />
          </Drawer>
        }
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    masterSearch: (client, req) => { dispatch(actions.masterSearch(client, req)) },
  };
};


export default (connect(mapStateToProps, mapDispatchToProps)(withRouter(CompaniesSearchLogs)));
