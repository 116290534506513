import React, { Component } from "react";
import {
    Button, withStyles, CardContent, Icon, Snackbar,
    Tooltip, Grid, DialogActions, DialogTitle,
    Select, MenuItem, InputLabel, Dialog, IconButton, Link
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import * as actionCreators from '../../../../store/actions/index';
import Password from "antd/lib/input/Password";

const TooltipNew = withStyles((theme) => ({
    tooltip: {
        fontSize: 14,
    },
}))(Tooltip);

class AddTeamMember extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: {},
            user_id: "",
            teamManager: false,
            usersListArray: [],
            minimizeScreen: false,
            fullScreen: false
        }
    }
    handleViewEmailDialogMinimize = () => {
        this.setState(oldStateminimizeScreen => ({ minimizeScreen: !oldStateminimizeScreen.minimizeScreen }));
        this.setState({ fullScreen: false })
    };

    handleViewEmailDialogFull = () => {
        this.setState(oldStatefullScreen => ({ fullScreen: !oldStatefullScreen.fullScreen }));
        this.setState({ minimizeScreen: false })
    };
    componentWillMount() {
        this.props.getUserList(this.props.apolloClient.client);
    }
    handleFormSubmit = () => {
        if (this.state.user_id !== "") {
            let req = {
                teamid: parseFloat(this.props.teamid),
                ismanager: this.state.teamManager,
                userid: parseFloat(this.state.user_id),
                memberid: 0
            }
            this.props.saveTeamMember(this.props.apolloClient.client, req)
            this.props.handleClose()
        }
    }
    handleChange = (event, values) => {
        if (values && values.id) {
            this.setState({ name: values, user_id: values.id });
        } else {
            this.setState({ [event.target.name]: event.target.value });
        }
    };

    componentWillReceiveProps(props) {
        if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
            const usersListArray = props.settingReducer.usersListArray.data;
            this.setState({ usersListArray: usersListArray })
        }
        if (props.settingReducer.teamMemberList.data) {
            let teamMemberList = props.settingReducer.teamMemberList.data;
            let usersListArray = props.settingReducer.usersListArray.data;
            let removeAlreadyAdd = [];
            usersListArray.map((e1, i) => {
                if (teamMemberList.filter(e2 => e2.user.id !== e1.id).length > 0) {  // If there's a match
                    removeAlreadyAdd.push(e1)
                }
            })
            this.setState({
                teamMemberList: teamMemberList,
                // usersListArray: removeAlreadyAdd,
                isDataLoading: false
            }, () => this.forceUpdate())
        }
    }

    render() {
        let { teamManager } = this.state;
        let { minimizeScreen, fullScreen } = this.state;

        let { open, handleClose } = this.props;
        return (
            <Dialog
                onClose={handleClose}
                open={open}
                maxWidth="md"
                fullWidth={true}
                className={`crm-custom-bg-primary custom_dialog ${minimizeScreen && fullScreen ? "alignitemsend-fullscreen" : ""
                    } ${minimizeScreen ? "alignitemsend" : ""} ${fullScreen ? "fullscreen" : ""
                    }`}
                disableBackdropClick            >
                <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">
                    <h4 className="m-0 text-white">Add Team Member</h4>
                    <div className="d-flex">
                        <TooltipNew title={minimizeScreen ? 'Normal Screen' : 'Minimize Screen'}>
                            <IconButton onClick={this.handleViewEmailDialogMinimize}>
                                <Icon className="text-white">{minimizeScreen ? 'add' : 'remove'}</Icon>
                            </IconButton>
                        </TooltipNew>
                        <TooltipNew title={fullScreen ? 'Exit Full Screen' : 'Full Screen'}>
                            <IconButton onClick={this.handleViewEmailDialogFull}>
                                <Icon className="text-white">{fullScreen ? 'fullscreen_exit' : 'fullscreen'}</Icon>
                            </IconButton>
                        </TooltipNew>
                        <TooltipNew title="Close">
                            <IconButton onClick={handleClose}>
                                <Icon className="text-white">clear</Icon>
                            </IconButton>
                        </TooltipNew>
                    </div>
                </div>
                <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                    <div className="dialog-body pt-16 pb-16 ">
                        <Grid container spacing={2}>
                            <Grid item sm={6} xs={6}>
                                <InputLabel>Member</InputLabel>
                                <Autocomplete
                                    freeSolo
                                    forcePopupIcon={false}
                                    name={"name"}
                                    options={this.state.usersListArray}
                                    getOptionLabel={(option) => option.name === undefined ? "" : option.name}
                                    value={this.state.name}
                                    onChange={(event, value) => this.handleChange(event, value)}
                                    renderInput={(params) => (
                                        <TextValidator
                                            // ref={ref => this["name"] = ref}
                                            {...params}
                                            variant="standard"
                                            required={true}
                                            type="text"
                                            value={this.state.name}
                                            placeholder="User Name"
                                            className="w-100 input_bx"
                                            validators={["required"]}
                                            errorMessages={["This field is required"]}
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item sm={6} xs={6}>
                                <InputLabel>Role</InputLabel>
                                <Select
                                    className="mt--4"
                                    name="teamManager"
                                    value={teamManager}
                                    fullWidth
                                    onChange={this.handleChange}
                                >
                                    <MenuItem className="select-menu-item" value={false}>Team Member</MenuItem>
                                    <MenuItem className="select-menu-item" value={true}>Team Manager</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </div>
                    <DialogActions className="dialog-action">
                        <Button onClick={() => this.props.handleClose()} variant="contained">Cancel</Button>
                        <Button variant="contained" className="btn_Primary" type="submit" color="primary">Save</Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog >
        )
    }
}
const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
});
const mapDispatchToProps = dispatch => {
    return {
        getUserList: (client) => dispatch(actionCreators.getUserList(client)),
        saveTeamMember: (client, req) => dispatch(actionCreators.saveTeamMember(client, req)),


    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddTeamMember);

