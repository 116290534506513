import React, { Component } from "react";
import { Grid, Button, withStyles, CircularProgress, Container, Backdrop } from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import {Link } from "react-router-dom";
import * as actionCreators from '../../../store/actions/index';

//import { resetPassword } from "../../redux/actions/LoginActions";
const styles = (theme) => ({
  buttonProgress: {
    position: "absolute",
    top: "45%",
    left: "28%",
    marginTop: -12,
    marginLeft: -12
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});
class ForgotPassword extends Component {
  state = {
    email: "",
    loading: false,
    backdrop: false
  };
  componentWillReceiveProps(props) {
    this.setState({ loading: false, backdrop: false })
    console.log(props.forgotPasswordRequests)
    if (props.forgotPasswordRequest.error == true) {

    } else {

    }
  }
  handleChange = event => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  handleFormSubmit = () => {
    this.setState({ loading: true, backdrop: true })
    let send_data = {
      email: this.state.email
    }
    this.props.forgotPasswordRequest(send_data);
  };
  handleBlur = (event,ref) => {
      this[ref].validate(event.target.value);
  }
  render() {
    let { email } = this.state;
    let { classes } = this.props;
    return (
      <Container maxWidth="md" className="h-100vh">
        <Grid container justify="center" alignItems="center" className="h-100">
          <div className="center_bx">
            <Grid container>
              <Grid item sm={6} xs={12}>
                <div className="pl-50 pr-50 py-80">
                  <h3 className="text-center font-weight-bold">Forgot password</h3>
                  <p className="text-center mt-0">Start selling more today.</p>
                  <ValidatorForm instantValidate={false} noValidate onSubmit={this.handleFormSubmit}>
                    <TextValidator
                      className="mb-24 w-100"
                      label="Email"
                      ref={ref => this.emailRef = ref}
                      onBlur={(event)=>this.handleBlur(event,'emailRef')}
                      onChange={this.handleChange}
                      type="email"
                      name="email"
                      value={email}
                      required={true}
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        "This field is required",
                        "email is not valid"
                      ]}
                      autoComplete="off"
                    />
                    <div className="flex flex-middle">
                      <Button variant="contained" color="primary" type="submit" className="button_hover">
                        Reset Password
                      </Button>
                      {this.state.loading && (
                        <Backdrop
                          className={classes.backdrop}
                          open={this.state.backdrop}
                        >
                          <CircularProgress size={60} color="inherit" />
                        </Backdrop>
                      )}
                      <span className="ml-16 mr-16">or</span>
                      <Link to="/sign-in/" className="link link_primary">Sign In</Link>
                    </div>
                  </ValidatorForm>
                </div>
              </Grid>
              <Grid item sm={6} xs={12} className="layers_bg align-items-end d-flex">
                <div>
                <h2>Hello there!</h2>
                <p>Meet the crm that allows you to nurture leads and close deals from your inbox. <br /> Login here and start selling more today.</p>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  forgotPasswordRequests: state.authReducer,
  notification: state.notifications
});
const mapDispatchToProps = dispatch => ({
  forgotPasswordRequest: (email) => dispatch(actionCreators.forgotPasswordRequest(email))
})

export default withStyles(styles, { withTheme: true })
  (
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
      (ForgotPassword)
  );
