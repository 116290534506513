import React, { Component } from "react";
import { Dialog, List, ListItem, DialogTitle, DialogActions, IconButton, Icon, Menu, withStyles, Grid, Fab, Select, MenuItem, TextField, Card, InputLabel, Button, Checkbox, Tooltip } from "@material-ui/core";
import * as actions from '../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import ProfileEmailMenu from "./ProfileEmailMenu";
import InboxCompose from "../CommonComposeMail/InboxCompose";
import FontAwesome from "react-fontawesome";
import _ from 'lodash'
import InboxComposeDialog from "../CommonComposeMail/InboxComposeDialog";
import EmojiTemplate from "../Layout/EmojiTemplate";

import EditCommentMenu from "./EditCommentMenu";
import AddComment from "./AddComment";
import EditComment from "./EditComment";
import ViewHtmlDialog from './ViewHtmlDialog'

import { Link } from "react-router-dom";
import moment from 'moment';

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.3rem",
        color: 'rgba(0, 0, 0, 0.6)'
    }
}))(Icon);

const IconSmaller = withStyles(() => ({
    root: {
        fontSize: "1.0rem",
        color: 'rgba(0, 0, 0, 0.6)'
    }
}))(Icon);

class EmailTypeOne extends Component {
    state = {
        openComment: false,
        Downloadoptions: "",
        showDownloadoptions: false,
    }
    toggleComment = (id) => {
        this.setState({ openComment: !this.state.openComment })
        this.props.thatAll.toggleComment(id)
    }
    handleDownloadClick = (event) => {
        this.setState({ Downloadoptions: event.target, showDownloadoptions: true });
    }
    handleCloseDownload = (event) => {
        this.setState({ Downloadoptions: null, showDownloadoptions: false });
    }
  
    render() {
        const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
        let { Downloadoptions, showDownloadoptions } = this.state;

        let user_data = JSON.parse(localStorage.getItem('user_data_session'));
        let current_logUser = user_data.email;
        let { log, allowUserReply, commentvalue, transferLink, timeZone, commentid, isEditComment, userName, dateformat, logid, emaildata, mainUserData, LogDetails, LogComponent, isOpenedReplayEmailLog, isOpenedEmailIds, thatAll } = this.props;
        return (
            <div className="box_body">
                <div className="lead_pl p-0">
                    <Grid container>
                        <Grid item md={1}>
                            <Icon className="icon_left">mail</Icon>
                        </Grid>
                        <Grid item md={11}>
                            <Grid container justify="space-between">
                                <Grid onClick={(id) => thatAll.toggleEmail(log.logId)}
                                    className="flex-auto cursor_poiter">
                                    <h5 className="mb-0">
                                        <Link className="text_link font-size-16" to={transferLink}>
                                            {userName}
                                        </Link>
                                        {" "}to You
                        {log.emailTo.length > 2 && " and " + ((log.emailTo.length) - 2) + " others"}
                                    </h5>
                                    <p className="mt-4 mb-4 font-size-13 text-hint">{log.email_date_time}</p>
                                </Grid>
                                <Grid>
                                    <div className="action_menu hover_show">
                                        <EmojiTemplate
                                            menuButton={
                                                <IconButton aria-label="Add" size="small">
                                                    <IconSmall>mood</IconSmall>
                                                </IconButton>
                                            }
                                            isright={true}
                                            logs={log}
                                            handleSelect={thatAll.handleSelect}
                                        />
                                        {(log.ispined == true) ?
                                            <IconButton aria-label="Pin" size="small" onClick={(logId, pinned) => thatAll.togglePin(log.logId, log.ispined)} >
                                                <svg viewBox="0 0 24 24" style={{ width: 18 }} fill="var(--primary)" xmlns="http://www.w3.org/2000/svg"><path d="M7 4l2 1.971V10l-4 3v2h6v7h2v-7h6v-2l-4-3V5.971l2-1.97V2H7v2z"></path></svg>
                                            </IconButton>
                                            :
                                            <IconButton aria-label="Pin" size="small" onClick={(logId, pinned) => thatAll.togglePin(log.logId, log.ispined)} >
                                                <svg viewBox="0 0 24 24" style={{ width: 18 }} fill="black" xmlns="http://www.w3.org/2000/svg"><path d="M7 4l2 1.971V10l-4 3v2h6v7h2v-7h6v-2l-4-3V5.971l2-1.97V2H7v2z"></path></svg>
                                            </IconButton>
                                        }

                                        {allowUserReply && log.emaildetails.message_id && log.emaildetails.thread_id &&
                                            <>
                                                <IconButton aria-label="Add" size="small" onClick={() => thatAll.isOpenedClickEmailToggle()}>
                                                    <IconSmall>add_circle</IconSmall>
                                                </IconButton>
                                                <IconButton aria-label="Reply All" size="small" onClick={() => thatAll.openNewMail(log)}>
                                                    <IconSmall>
                                                        {log.emailTo.length > 2 ? 'reply_all' : 'reply'}
                                                    </IconSmall>
                                                </IconButton>
                                            </>
                                        }
                                        <ProfileEmailMenu
                                            logDatas={log}
                                            handleEmailHtmlPopupMain={thatAll.handleEmailHtmlPopupMain}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            {(isOpenedEmailIds.indexOf(log.logId) == -1) ?
                                <div className="mt-0 font-size-14">
                                    <span className="font-weight-500">{log.subject}</span> - {renderHTML(log.snippet)}
                                </div>
                                :
                                <div className="border_bx">
                                    <h6>{log.subject}</h6>
                                    <div dangerouslySetInnerHTML={{ __html: log.body_html }} />
                                </div>
                            }
                            <div className="mt-20">
                                {(log.attachment && log.attachment.length > 0) &&
                                    log.attachment.map((attach) => (
                                        <>
                                            <Button onClick={this.handleDownloadClick} className="crm-email-log-attachment" variant="contained">
                                                <IconButton size="small">
                                                    <IconSmaller>attach_file</IconSmaller>
                                                </IconButton>
                                                {attach.file_name}
                                                <IconButton size="small">
                                                    <IconSmaller>arrow_drop_down</IconSmaller>
                                                </IconButton>
                                            </Button>
                                            <Menu
                                                id="long-menu"
                                                anchorEl={Downloadoptions}
                                                open={showDownloadoptions}
                                                onClose={this.handleCloseDownload}
                                            >
                                                <MenuItem onClick={this.handleCloseDownload}>
                                                    <a href={attach.s3_file_name} target="__blank" className="text-center">
                                                        Download
                                                  </a>
                                                </MenuItem>
                                                <MenuItem onClick={this.handleCloseDownload}>
                                                    <span className="font-size-13 mr-10 mb-5">Relate To Lead</span>
                                                </MenuItem>
                                            </Menu>
                                        </>
                                    ))
                                }
                            </div>
                            <div className="emoji_counter">
                                <li >
                                    <EmojiTemplate
                                        menuButton={
                                            <>
                                                <svg height="16" fill="#7467ef" width="7" viewBox="0 0 7 16" >
                                                    <path d="M4 7V4H3v3H0v1h3v3h1V8h3V7H4z"></path>
                                                </svg>
                                                <IconButton aria-label="Add" size="small" color="primary">
                                                    <IconSmall>mood</IconSmall>
                                                </IconButton>
                                            </>
                                        }
                                        logs={log}
                                        handleSelect={thatAll.handleSelect}
                                    />
                                </li>
                                {
                                    _.map(_.groupBy(log.emojidetail, 'emoji'), (c, emoji) => {
                                        return (
                                            <li onClick={() => thatAll.handleSelect(emoji, log)} >
                                                {emoji}{c.length}
                                            </li>
                                        )
                                    })
                                }
                            </div>
                            {isOpenedReplayEmailLog && logid === log.logId
                                &&
                                <InboxCompose
                                    mainUserData={mainUserData}
                                    emaildata={emaildata}
                                    handleClose={thatAll.toggleReplayEmailLog}
                                    LogDetails={LogDetails}
                                    LogComponent={LogComponent}
                                />
                            }
                            <p className="mb-0 font-size-14">
                                <span className="cursor_poiter" onClick={() => this.toggleComment(log.logId)}>
                                    <Icon className="vert-middle">
                                        {this.state.openComment ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                      Comments ({(log.comments) ? log.comments.length : 0})
                              </span>
                            </p>
                            {this.state.openComment &&
                                <Grid container spacing={1}>
                                    <Grid item className="flex-auto">
                                        <div className="border_bx pr-10">
                                            {(log.comments && log.comments.length > 0) &&
                                                (log.comments).map((cmmt) => (
                                                    <Grid container className="mb-10" spacing={1}>
                                                        <Grid item>
                                                            <Fab className="small-size overflow-hidden mr-8 mt-10" color="secondary" size="small">
                                                                <h5 className={"text-white m-0 font-weight-normal avatar_circle color_" + (cmmt.__commentby__.name).charAt(0)}>{(cmmt.__commentby__.name).charAt(0).toUpperCase()}</h5>
                                                            </Fab>
                                                        </Grid>
                                                        {
                                                            (isEditComment && (commentid == cmmt.id)) ?
                                                                <>
                                                                    <Grid className="mb-10" item md={9}>
                                                                        <ValidatorForm noValidate debounceTime={500} onSubmit={(type) => thatAll.handleSaveEditComment('email')}>
                                                                            <Grid className="mb-10" item>
                                                                                <EditComment
                                                                                    getEditData={thatAll.getEditCommentData}
                                                                                    data={cmmt}
                                                                                />
                                                                            </Grid>
                                                                            {(commentvalue && commentvalue.length > 0) &&
                                                                                <Grid className="mb-10" item>
                                                                                    <Button className="mr-10" color="secondary" onClick={thatAll.handleCancelEditComment}>Cancel</Button>
                                                                                    <Button variant="contained" className={"btn_Primary mr-10 text-white"} type="submit" color="primary">Comment</Button>
                                                                                </Grid>
                                                                            }
                                                                        </ValidatorForm>
                                                                    </Grid>
                                                                </>
                                                                :
                                                                <>
                                                                    <Grid item>
                                                                        <p className="mt-4 mb-4 font-size-13 text-hint">
                                                                            {
                                                                                (cmmt.lastmodifieddate != null)
                                                                                    ?
                                                                                    moment.unix(cmmt.lastmodifieddate).tz(timeZone).format(dateformat) + ", " +
                                                                                    moment.unix(cmmt.lastmodifieddate).tz(timeZone).format("hh:mm A")
                                                                                    :
                                                                                    moment.unix(cmmt.createddate).tz(timeZone).format(dateformat) + ", " +
                                                                                    moment.unix(cmmt.createddate).tz(timeZone).format("hh:mm A")
                                                                            }
                                                                        </p>
                                                                        <p className="mt-4 mb-4 font-size-13">{cmmt.comment}</p>
                                                                    </Grid>
                                                                    {(cmmt.__commentby__.email == current_logUser) &&
                                                                        <EditCommentMenu allData={thatAll} cmt={cmmt} type="email" />
                                                                    }
                                                                </>
                                                        }
                                                    </Grid>
                                                ))
                                            }
                                            {(!isEditComment) &&
                                                <ValidatorForm noValidate debounceTime={500} onSubmit={(type) => thatAll.handleSaveCommentLog('email')}>
                                                    <Grid container spacing={1}>
                                                        <Grid className="mb-10" item >
                                                            <Fab className="small-size overflow-hidden mr-8 mt-10" color="secondary" size="small">
                                                                <h5 className={"text-white m-0 font-weight-normal avatar_circle color_" + log.color}>{log.color.toUpperCase()}</h5>
                                                            </Fab>
                                                        </Grid>
                                                        <Grid className="mb-10" item md={9}>
                                                            <Grid className="mb-10" item>
                                                                <AddComment
                                                                    getdata={thatAll.getdata}
                                                                    commentvalue={commentvalue}
                                                                />
                                                            </Grid>
                                                            {
                                                                (commentvalue && commentvalue.length > 0 && (log.logId == logid)) &&
                                                                <Grid className="mb-10" item>
                                                                    <Button className="mr-10" color="secondary" onClick={thatAll.handleCancelComment}>Cancel</Button>
                                                                    <Button variant="contained" className={"btn_Primary mr-10 text-white"} type="submit" color="primary">Comment</Button>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </ValidatorForm>
                                            }
                                        </div>
                                    </Grid>
                                    <Grid item className="align-self-center">

                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </div>             
            </div >
        );
    }
}

export default EmailTypeOne