import React, { Component } from "react";
import SettingSidebar from '../../../Layout/SettingSidebarMenu';
import { Box, IconButton, Card, Icon, Tab, Tabs, Typography, Button, Checkbox, Chip, Fab, FormControl, Grid, ListItemText, MenuItem, Select, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter } from "@material-ui/core";
import moment from 'moment';
import PropTypes from 'prop-types';
import GoalsDialog from "./GoalsDialog"
import MonthlyActivityGoals from "./MonthlyActivityGoals"
import QuatrterlyActivityGoals from "./QuatrterlyActivityGoals"
import YearlyActivityGoals from "./YearlyActivityGoals"

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from '../../../../store/actions/index';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={2}>{children}</Box>
    </Typography>
  );
}
const names = [
  'All activity',
  'Email',
  'Meeting',
  'Phone call'

];
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}
function a11in1yProps(index) {
  return {
    id: `tabinside1-${index}`,
    'aria-controls': `tabpanelin1-${index}`,
  };
}
function a11in2yProps(index) {
  return {
    id: `tabinside2-${index}`,
    'aria-controls': `tabpanelin2-${index}`,
  };
}
function a11in3yProps(index) {
  return {
    id: `tabinside2-${index}`,
    'aria-controls': `tabpanelin2-${index}`,
  };
}
function a11in4yProps(index) {
  return {
    id: `tabinside2-${index}`,
    'aria-controls': `tabpanelin2-${index}`,
  };
}

class ActivityTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userlist: 10,
      addPipeline: 1,
      shouldOpenSaleRevenueGoalsDialog: false,
      FullGoalDialog: false,
      minimizeGoalDialog: false,
      value: 0,
      valueone: 0,
      valuetwo: 0,
      valuethree: 0,
      valuefour: 0,
      personName: ['All activity'],
      date: moment().startOf('month'),
      previousdate: moment().startOf('month').subtract("1", "month"),
      nextdate: moment().startOf('month').add("1", "months"),
      weeklist: [],
      monthList: [],
      quaterList: [],
      yearList: [],
      usersListArray:[],
      teamList:[], 

      datetype: 1,
      goaltype: 3,
    }
  }

  componentDidMount = () => {
    this.props.getUserList(this.props.apolloClient.client);
    this.props.getTeam(this.props.apolloClient.client);
  }
  componentWillReceiveProps(props){
    
    if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
      const usersListArray = props.settingReducer.usersListArray.data;
      this.setState({ usersListArray: usersListArray })
    }
    
    if (props.settingReducer.teamList && props.settingReducer.teamList.data) {
      const teamList = props.settingReducer.teamList.data;
      this.setState({ teamList: teamList })
    }
  }

  handleFourChange = (event, newValue) => {
    this.setState({ valuefour: newValue, date: moment() })
  }
  
  handleGoalDialogClose = () => {
    this.setState({ shouldOpenSaleRevenueGoalsDialog: false, FullGoalDialog: false, minimizeGoalDialog: false });
  };
  handleGoalDialogMinimize = () => {
    this.setState(oldStateminimizeGoalDialog => ({ minimizeGoalDialog: !oldStateminimizeGoalDialog.minimizeGoalDialog }));
    this.setState({ FullGoalDialog: false })
  };
  handleGoalDialogFull = () => {
    this.setState(oldStateFullGoalDialog => ({ FullGoalDialog: !oldStateFullGoalDialog.FullGoalDialog }));
    this.setState({ minimizeGoalDialog: false })
  };
  

  render() {
    const { userlist, addPipeline, shouldOpenSaleRevenueGoalsDialog, minimizeGoalDialog, FullGoalDialog, value, valueone, valuetwo, valuethree, valuefour } = this.state;
    return (
      <React.Fragment>
        <Paper variant="outlined" square className="tabs_paper">
          <Tabs
            value={valuefour}
            onChange={this.handleFourChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            className="settings_tabs"
            aria-label="scrollable auto tabs"
          >
            <Tab label="MONTHLY GOALS" className="pl-10 pr-10" {...a11in4yProps(0)} />
            <Tab label="QUARTERLY GOALS" className="pl-10 pr-10"  {...a11in4yProps(1)} />
            <Tab label="YEARLY GOALS" className="pl-10 pr-10"  {...a11in4yProps(2)} />
          </Tabs>
          <TabPanel value={valuefour} index={0} className="h-100 setting_goal">
            <MonthlyActivityGoals data={this}  goaltype={3} />
          </TabPanel>
          <TabPanel value={valuefour} index={1} className="h-100 setting_goal">
            <QuatrterlyActivityGoals data={this}  goaltype={3} />
          </TabPanel>
          <TabPanel value={valuefour} index={2} className="h-100 setting_goal">
            <YearlyActivityGoals data={this}  goaltype={3} />
          </TabPanel>
        </Paper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  notification: state.notification,    
});

const mapDispatchToProps = dispatch => {
  return {
      getUserList: (client) => dispatch(actionCreators.getUserList(client)),
      getTeam: (client) => dispatch(actionCreators.getTeam(client)),
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(ActivityTab);
