import React, { Component } from "react";
import {
    Button, withStyles, CardContent, Icon, Snackbar,
    Fade, CircularProgress, DialogActions, DialogTitle,
    DialogContent, Typography, Dialog, IconButton, Link
} from "@material-ui/core";
import { connect } from "react-redux";
import * as actionCreators from '../../../../store/actions/index';
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.5rem",
        color: 'rgba(0, 0, 0, 0.6)'
    }
}))(Icon);

class ZendeskDialog extends Component {
    state = {
        teamname: "",
        token: "",
        email: "",
        Data: [],
    };

    componentWillReceiveProps(props) {
        if (props.settingReducer.zendeskVerify) {
            const obj = props.settingReducer.zendeskVerify;
            if (obj !== this.state.Data) {
                this.setState({
                    Data: obj
                }, () => this.updateSetting())

                setTimeout(() => {
                    this.props.handleClose();
                    window.location.href = '/settings/zendesk/integrations';
                }, 3000);
            }
        }
    }

    handleChange = event => {
        event.persist();
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    updateSetting = () => {
        let status = ['1', '2', '3', '4', '5', '6'];
        let val = {
            integration: {
                integrationid: 1,
                oncompanies: true,
                onleads: true,
                onopportunities: true,
                onpeople: true,
                onproject: false,
                maxnumberrecords: 5,
                sortby: "created_at",
                status: true,
                email: this.state.email,
                domain: this.state.teamname,
                accesstoken: this.state.token,
                refereshtoken: '',
                ticketstatus: status.toString(),
            }
        }
        let data = [];
        data['data'] = val;
        let id = 1;
        let req = { data, id };
        this.props.saveIntegrationSetting(this.props.apolloClient.client, req);

    }

    handleFormSubmit = event => {
        let verify_data = {
            "email": this.state.email,
            "token": this.state.token,
            "subdomain": this.state.teamname,
            "secret_key": "obb+7+z-2j&6tx=^r%(3(@66d-bix#g6u!uf+@(!3_6y(cu%6v"
        }
        let submitData = [{
            'email': this.state.email,
            'token': this.state.token,
            'subdomain': this.state.teamname,
        }]
        let zendeskVerifyreq = {
            data: verify_data,
            getParams: submitData,
        }
        this.props.zendeskVerify(zendeskVerifyreq);
    };

    render() {
        let { teamname, token, email } = this.state;
        let { open, handleClose } = this.props;
        return (
            <Dialog onClose={handleClose} open={open} className="crm_dialog_setting" fullWidth={true} disableBackdropClick 
            // disableEscapeKeyDown
            >
                <ValidatorForm ref="form" noValidate onSubmit={this.handleFormSubmit}>
                    <DialogTitle>
                        <Typography className="p-10" variant="h5" color="inherit">Zendesk Integration</Typography>
                        <div className="clear_icon hover_show">
                            <IconButton aria-label="Delete" size="small" onClick={handleClose}>
                                <IconSmall style={{ fontSize: '18px' }}>clear</IconSmall>
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ padding: '10px' }}>
                            {/* <lable className="Zend_Integrations_card font-weight-600 font-size-18 text-small">Zendesk Team Name</lable> */}
                            <div style={{ width: '100%', display: 'flex' }}>
                                <div style={{ width: '83%' }}>
                                    <TextValidator
                                        fullWidth
                                        type="text"
                                        id="teamname"
                                        name="teamname"
                                        required={true}
                                        value={teamname}
                                        autoComplete='off'
                                        className="w-100 input_bx"
                                        onChange={this.handleChange}
                                        label={'Zendesk Team Name'}
                                        placeholder="e.g. mycompanyname"
                                        validators={["required"]}
                                        errorMessages={["This field is required"]}
                                        InputLabelProps={{shrink: true, disableAnimation: false, focused: true }}
                                    />
                                </div>
                                <div style={{ width: '10%', marginTop: '28px' }} >
                                    <b className="text-small">.zendesk.com</b>
                                </div>
                            </div>
                            <div style={{ marginTop: '10px' }} >
                                {/* <lable className="Zend_Integrations_card font-weight-600 font-size-18 text-small">Zendesk API Token</lable> */}
                                <TextValidator
                                    required={true}
                                    type="text"
                                    id="token"
                                    className="w-100 input_bx"
                                    onChange={this.handleChange}
                                    name="token"
                                    value={token}
                                    fullWidth
                                    placeholder="e.g. DNWRCsTC235Jpe49GocxDZxDWizt9jnYURh5yBXq"
                                    validators={["required"]}
                                    errorMessages={["This field is required"]}
                                    autoComplete='off'
                                    label={'Zendesk API Token'}
                                    InputLabelProps={{shrink: true, disableAnimation: false, focused: true }}
                                />
                            </div>
                            <div style={{ marginTop: '10px' }} >
                                {/* <lable className="Zend_Integrations_card font-weight-600 font-size-18 text-small">Email address of the user who created the API token in Zendesk</lable> */}
                                <TextValidator
                                    required={true}
                                    type="email"
                                    id="email"
                                    className="w-100 input_bx"
                                    onChange={this.handleChange}
                                    name="email"
                                    value={email}
                                    fullWidth
                                    placeholder="e.g. yourname@idrive.com"
                                    validators={["required", "isEmail"]}
                                    errorMessages={[
                                        "This field is required",
                                        "email is not valid"
                                    ]}
                                    autoComplete='off'
                                    label={'Email address of the user who created the API token in Zendesk'}
                                    InputLabelProps={{shrink: true, disableAnimation: false, focused: true }}
                                />
                            </div>
                        </div>
                        <div className="Zend_Integrations_card font-weight-600 font-size-18 text-small" style={{ margin: "10px" }}>
                            Learn how to generate your Zendesk API token here :
                           <span style={{ color: '#2B41B2' }}>
                                <a target="_blank" href="https://support.zendesk.com/hc/en-us/articles/226022787-Generating-a-new-API-token-">Generating a new API token</a>
                            </span>
                        </div>
                        <div className="Zend_Integrations_card font-weight-600 font-size-18 text-small" style={{ margin: "10px" }}>
                            Need help? Contact our     
                            <span className="company_user_name" style={{ color: '#2B41B2' }}>
                                <a target="_blank" href="http://idrivecrm.com/">
                                    {` support`}
                                </a>    
                            </span>
                        </div>
                    </DialogContent>
                    <DialogActions className="dialog-action">
                        <Button variant="contained" onClick={handleClose}>Cancel</Button>
                        <Button variant="contained" color="primary" type="submit">Enable</Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog >
        );
    }
}

const mapStateToProps = state => {
    return {
        apolloClient: state.apolloClient,
        settingReducer: state.settingReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        zendeskVerify: (response) => dispatch(actionCreators.zendeskVerify(response)),
        saveIntegrationSetting: (client, req) => dispatch(actionCreators.saveIntegrationSetting(client, req)),
    }
}
export default (connect(mapStateToProps, mapDispatchToProps)(ZendeskDialog));
