import React, { Component } from "react";
import { Button, Card, Icon, DialogTitle, DialogActions, IconButton, withStyles, Dialog, Snackbar, Fade, CircularProgress, DialogContent } from "@material-ui/core";
import { default as MaterialGrid } from '@material-ui/core/Grid';
import { Link } from "react-router-dom";
import AddTeam from "./AddTeam"
import {
    Grid,
    TableHeaderRow,
    Toolbar,
    VirtualTable,
    TableSelection,
    SearchPanel,
    Table,
    TableFixedColumns,
} from '@devexpress/dx-react-grid-material-ui';
import {
    SortingState,
    SearchState,
    IntegratedSorting,
    SelectionState,
    IntegratedSelection,
} from '@devexpress/dx-react-grid';
import * as actions from '../../../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';
import TeamActivePerrmission from "./TeamActivePerrmission"

function EmptyCell(props) {
    return (
        <td colSpan={4} className="">
            {!props.loading &&
                <div className="">
                    <div className="pt-20  font-size-20 font-weight-500 text-center text-hint">No Team created yet</div>
                </div>
            }
        </td>
    );
}

class TeamList extends Component {
    state = {
        selection: [],
        isDeleteConfirmDialogOpen: false,
        shouldOpenDialog: false,
        teamList: [],
        deleteId: "",
        isDataLoading: true,
        isActivePermissions: true,
        isOpenDisableDialog: false
    };
    componentWillMount() {

        this.props.getTeamSetting(this.props.apolloClient.client)
        this.props.getTeam(this.props.apolloClient.client)
    }

    componentWillReceiveProps(props) {
        if (props.settingReducer.teamPermissionStatus.data) {
            let data = props.settingReducer.teamPermissionStatus.data;
            if (this.state.isActivePermissions !== data.ispermission) {
                this.setState({
                    isActivePermissions: data.ispermission
                })
            }
        }
        if (props.settingReducer.teamList.data !== this.state.teamList) {
            const teamList = props.settingReducer.teamList.data;
            this.setState({ teamList: teamList, isDataLoading: false })
        } else {
            setTimeout(() => {
                this.setState({
                    isDataLoading: false,
                })
            }, 1500);
        }
    }
    setSelection = (val) => {
        this.setState({ selection: val })
    }
    handleDialogClose = () => {
        this.setState({ shouldOpenDialog: false });
    };
    toggleDeleteDialog = () => {
        this.setState({ isDeleteConfirmDialogOpen: !this.state.isDeleteConfirmDialogOpen });
    };
    confirmDeleteCustomField = () => {
        let deleteTeamParams = { teamid: parseFloat(this.state.deleteId) };
        this.setState({
            deleteId: "", isDataLoading: true,
        });
        this.props.deleteTeam(this.props.apolloClient.client, deleteTeamParams)
        this.toggleDeleteDialog()
    };
    handleDeleteTeam = (id) => {
        this.toggleDeleteDialog()
        this.setState({ deleteId: id })
    }
    handleDialogActiveClose = () => {
        this.setState({ openActiveDialog: false })
    }
    toggleDisbleDialog = () => {
        this.setState({ isOpenDisableDialog: !this.state.isOpenDisableDialog })
    }
    onDisableConform = () => {
        let req = {
            settingstatus: false
        }
        this.toggleDisbleDialog()
        this.props.updateTeamSetting(this.props.apolloClient.client, req)
    }
    render() {
        let { isActivePermissions } = this.state;
        const tableColumnExtensions = [{}];
        const columns = [
            {
                name: "teamname",
                title: "Team Name"
            },
            {
                name: "totalmember",
                title: "Team Members"
            },
            {
                name: "actions",
                title: "Actions"
            }
        ];

        let rows = [];
        if (this.state.teamList) {
            this.state.teamList.map((val) => {
                rows.push(
                    {
                        teamname: val.teamname,
                        totalmember: val.totalmember !== null ? val.totalmember : "-",
                        actions: (<>
                            <Link to={"/settings/team-detail/" + val.id}>
                                <IconButton>
                                    <Icon>visibility</Icon>
                                </IconButton>
                            </Link>
                            {this.props.authReducer.userRole.id !== 3 &&
                                <IconButton onClick={(id) => this.handleDeleteTeam(val.id)} aria-label="delete">
                                    <Icon>delete</Icon>
                                </IconButton>
                            }
                        </>)
                    })
            })
        }
        const toolbarTemplate = params => {
            return (
                <div className="d-flex mt-16 mb-8 ml-16 mr-16">

                    {params.children}
                    {this.state.selection.length ?
                        <div className="d-inline-block ml-8">
                            <Button variant="contained" color="secondary" size="small">
                                Delete
                        </Button>
                        </div>
                        :
                        ''
                    }

                    <div className="d-inline-block ml-8">
                        {this.props.authReducer.userRole.id !== 3 &&
                            <Button variant="contained" className="btn_Primary" color="primary" onClick={() => this.setState({ shouldOpenDialog: true })} size="small" style={{ minWidth: 95 }}>
                                Create New Team
                        </Button>
                        }
                    </div>

                </div>
            );
        };
        return (
            <Card elevation={3} className="h-100">
                <MaterialGrid className="mt-16 mb-8" justify="space-between" container alignItems="center">
                    <MaterialGrid item>
                        <h3 className="ml-16">Manage Teams </h3>
                        <div className="font-size-13 font-weight-400 mb-20 ml-16 text-hint">
                            Manage the visibility of records and control access to certain areas of the application for specific users
                        </div>
                    </MaterialGrid>
                    <MaterialGrid item className="mr-10">
                        {
                            this.state.teamList &&
                            this.state.teamList.length > 0 &&
                            this.props.authReducer.userRole.id !== 3 &&
                            <>
                                {
                                    isActivePermissions !== true ?
                                        <Button variant="contained"
                                            color="primary"
                                            onClick={() => this.setState({ openActiveDialog: true })}
                                            size="small"
                                            style={{ minWidth: 95 }}
                                            className="btn_Primary"
                                            >
                                            Active Team Permissions
                                        </Button>
                                        : <Button variant="contained"
                                            className="btn_Primary"
                                            onClick={() => this.setState({ isOpenDisableDialog: true })}
                                            size="small" style={{ minWidth: 95 }}>
                                            Disable Team Permissions
                                </Button>
                                }
                            </>
                        }
                    </MaterialGrid>
                </MaterialGrid>
                <div className="leads_list_table">
                    <Grid rows={rows} columns={columns}>
                        <SelectionState
                            selection={this.state.selection}
                            onSelectionChange={this.setSelection}
                        />
                        <IntegratedSelection />
                        <VirtualTable />
                        {/* <SearchState defaultValue="" /> */}

                        <SortingState />
                        <IntegratedSorting />
                        <Table
                            columnExtensions={tableColumnExtensions}
                            noDataCellComponent={() => <EmptyCell loading={this.state.isDataLoading} />}
                        />
                        <TableSelection showSelectAll highlightRow />

                        <TableHeaderRow showSortingControls />

                        <TableFixedColumns />
                        <Toolbar rootComponent={toolbarTemplate} />
                        {/* <SearchPanel /> */}
                    </Grid>
                </div>
                {this.state.isDataLoading &&
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        open={this.state.isDataLoading}
                        TransitionComponent={Fade}
                        message={<><CircularProgress size={20} style={{ color: 'white' }} /><span style={{ paddingLeft: '6px', color: 'white' }}>{" Loading..."}</span></>}
                        variant={'success'}
                    />
                }
                {this.state.shouldOpenDialog && (
                    <AddTeam
                        handleClose={this.handleDialogClose}
                        open={this.state.shouldOpenDialog}
                    />
                )}
                {this.state.isDeleteConfirmDialogOpen &&
                    <Dialog
                        fullWidth={true}
                        maxWidth={'xs'}
                        open={this.state.isDeleteConfirmDialogOpen}
                        onClose={this.toggleDeleteDialog}
                        disableBackdropClick
                        // disableEscapeKeyDown
                        aria-labelledby="ar-remove-dialog-title"
                    >
                        <DialogTitle id="ar-remove-dialog-title">{"Are you sure to remove the selected Team?"}</DialogTitle>
                        <DialogActions>
                            <Button onClick={this.toggleDeleteDialog} color="primary">
                                Cancel
                                </Button>
                            <Button type="Button" onClick={this.confirmDeleteCustomField} className="btn_Primary">
                                Ok
                                </Button>
                        </DialogActions>
                    </Dialog>
                }
                {this.state.openActiveDialog && (
                    <TeamActivePerrmission
                        handleClose={this.handleDialogActiveClose}
                        open={this.state.openActiveDialog}
                    />
                )}
                {this.state.isOpenDisableDialog &&
                    <Dialog
                        fullWidth={true}
                        maxWidth={'xs'}
                        open={this.state.isOpenDisableDialog}
                        onClose={this.toggleDisbleDialog}
                        disableBackdropClick
                        // disableEscapeKeyDown
                        aria-labelledby="ar-remove-dialog-title"
                    >
                        <DialogTitle id="ar-remove-dialog-title">
                            {"Are you sure you want to disable Team Permissions?"}</DialogTitle>
                        <DialogContent>
                            Disabling Team Permissions will cause all records in your system to be public to all users. Additionally, all entities and reports will be visible to all users.

                          </DialogContent>
                        <DialogActions>
                            <Button onClick={this.toggleDisbleDialog} color="primary">
                                Cancel
                                </Button>
                            <Button type="Button" onClick={this.onDisableConform} className="btn_Primary">
                                Disable
                                </Button>
                        </DialogActions>
                    </Dialog>
                }
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        settingReducer: state.settingReducer,
        apolloClient: state.apolloClient,
        authReducer: state.authReducer,
        notification: state.notification,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        clearNotification: () => dispatch(actions.clearNotification()),
        updateTeamSetting: (client, req) => dispatch(actions.updateTeamSetting(client, req)),
        getTeamSetting: (client) => dispatch(actions.getTeamSetting(client)),
        getTeam: (client) => dispatch(actions.getTeam(client)),
        deleteTeam: (client, req) => dispatch(actions.deleteTeam(client, req)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(TeamList);

