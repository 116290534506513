import React, { Component } from "react";
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { Link, withRouter } from 'react-router-dom'

import { connect } from "react-redux";
import * as actionCreators from "../../store/actions/index"
class SettingSidebarMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            "Personal Preferences": true,
            // "Manage Your Data": true,
            "Account Settings": true,
            "Customize": true,
            "Team": true,
            "Automation": true,
            "Item4": true,
            "Integrations": true,
            "Data Management": true,
            "Team" : true,
            "Billing": true,
            "data": [
                {
                    id: 2,
                    "name": "Account Settings",
                    "class": 'first_level',
                    visible: true,
                    "children": [
                        {
                            id: "sub21",
                            "name": "Company & Invite New Users",
                            "class": 'second_level',
                            "url": "/settings/user-management/",
                            visible: true,
                        },
                        {
                            id: "sub22",
                            "name": "Currency",
                            "class": 'second_level',
                            "url": "/settings/currency/",
                            visible: true,
                        }
                    ]
                },
                {
                    id: 3,
                    "name": "Personal Preferences",
                    "class": 'first_level',
                    visible: true,
                    "children": [
                        {
                            id: "sub31",
                            "name": "My Preferences",
                            "class": 'second_level',
                            "url": "/settings/my-preferences/",
                            visible: true,
                        },
                        // {
                        //     id: "sub32",
                        //     "name": "Email Settings & Templates",
                        //     "class": 'second_level',
                        //     "url": "/settings/email-settings/",
                        //     visible: true,
                        // },
                        // {
                        //     id: "sub33",
                        //     "name": "Basic Information",
                        //     "class": 'second_level',
                        //     "url": "#",
                        //     visible: true,
                        // },
                        {
                            id: "sub34",
                            "name": "Notifications",
                            "class": 'second_level',
                            "url": "/settings/notifications/",
                            visible: true,
                        },
                    ]
                },
                {
                    id: 1,
                    "name": "Data Management",
                    "class": 'first_level',
                    visible: true,
                    "children": [
                        {
                            id: "sub11",
                            "name": "Import Data",
                            "class": 'second_level',
                            "url": "/settings/import-data/",
                            visible: true,
                        },
                        {
                            id: "sub12",
                            "name": "Export Data",
                            "class": 'second_level',
                            "url": "/settings/export-data/",
                            visible: true,
                        },
                    ]
                },
                {
                    id: 4,
                    "name": "Team",
                    "class": 'first_level',
                    visible: true,
                    "children": [
                        {
                            id: "sub41",
                            "name": "Set Goals",
                            "class": 'second_level',
                            "url": "/settings/goals/",
                            visible: true,
                        },
                        {
                            id: "sub42",
                            "name": "Visibility & Permissions",
                            "class": 'second_level',
                            "url": "/settings/teams-permissions/",
                            visible: true,
                        }
                    ]
                },
                {
                    id: 5,
                    "name": "Customize",
                    "class": 'first_level',
                    visible: true,
                    "children": [
                        {
                            id: "sub51",
                            "name": "Dashboard",
                            "class": 'second_level',
                            "url": "#",
                            visible: true,
                        },
                        {
                            id: "sub52",
                            "name": "Fields on Records",
                            "class": 'second_level',
                            "url": "/settings/settings",
                            visible: true,
                        },
                        {
                            id: "sub53",
                            "name": "Related Section",
                            "class": 'second_level',
                            "url": "/settings/relatedsettings",
                            visible: true,
                        },
                        {
                            id: "sub54",
                            "name": "Activity Types",
                            "class": 'second_level',
                            "url": "/settings/activity-types",
                            visible: true,
                        },
                        {
                            id: "sub55",
                            "name": "Tags",
                            "class": 'second_level',
                            "url": "/settings/tag-management",
                            visible: true,
                        },
                        {
                            id: "sub56",
                            "name": "Project Stages",
                            "class": 'second_level',
                            "url": "/settings/project-stage",
                            visible: true,
                        },
                        {
                            id: "sub57",
                            "name": "Leads Statuses",
                            "class": 'second_level',
                            "url": "/settings/lead-statuses",
                            visible: true,
                            // "children": [
                            //     {
                            //         "name": "Lead Status",
                            //         "class": 'third_level',
                            //         "url": "/settings/lead-statuses",
                            //         visible: true,
                            //     }
                            // ],
                        },
                        {
                            id: "sub58",
                            "name": "Contact Types",
                            "class": 'second_level',
                            visible: true,
                            "url": "/settings/contact-types",
                            // "children": [
                            //     {
                            //         "name": "Contact Type",
                            //         "class": 'third_level',
                            //         "url": "/settings/contact-types",
                            //         visible: true,
                            //     }
                            // ],
                        },
                        {
                            id: "sub59",
                            "name": "Pipeline Stages",
                            "class": 'second_level',
                            "url": "/settings/pipeline-stages",
                            visible: true,
                            // "children": [
                            //     {
                            //         "name": "Pipeline Stages",
                            //         "class": 'third_level',
                            //         "url": "/settings/pipeline-stages",
                            //         visible: true,
                            //     },
                            //     {
                            //         "name": "Sources",
                            //         "class": 'third_level',
                            //         "url": "/settings/sources",
                            //         visible: true,
                            //     },
                            //     {
                            //         "name": "Loss Reasons",
                            //         "class": 'third_level',
                            //         "url": "/settings/loss-reason",
                            //         visible: true,
                            //     },
                            // ],
                        },
                        {
                            id: "sub72",
                            "name": "Sources",
                            "class": 'second_level',
                            "url": "/settings/sources",
                            visible: true,
                        },
                        {
                            id: "sub73",
                            "name": "Loss Reasons",
                            "class": 'second_level',
                            "url": "/settings/loss-reason",
                            visible: true,
                        },
                        {
                             id: "sub32",
                             "name": "Email Settings & Templates",
                             "class": 'second_level',
                             "url": "/settings/email-settings/",
                              visible: true,
                        },
                    ]
                },
                {
                    id: 6,
                    "name": "Automation",
                    "class": 'first_level',
                    visible: true,
                    "children": [
                        {
                            id: "sub61",
                            "name": "Workflow Automation",
                            "class": 'second_level',
                            "url": "/settings/workflow-automation/",
                            visible: true,
                        },
                        {
                            id: "sub62",
                            "name": "Task Automation",
                            "class": 'second_level',
                            "url": "/settings/task-automation/",
                            visible: true,
                        }
                    ]
                },
                {
                    id: 7,
                    "name": "Integrations",
                    "class": 'first_level',
                    visible: true,
                    "children": [
                        {
                            id: "sub71",
                            "name": "Integrations",
                            "class": 'second_level',
                            "url": "/settings/integrations",
                            visible: true,
                        },
                    ]
                },
                {
                    id: 8,
                    "name": "Billing",
                    "class": 'first_level',
                    visible: true,
                    "children": [
                        {
                            id: "sub71",
                            "name": "Billing",
                            "class": 'second_level',
                            "url": "/settings/billings",
                            visible: true,
                        },
                    ]
                }]
        }
    }

    // this method sets the current state of a menu item i.e whether it is in expanded or collapsed or a collapsed state
    handleClick(item) {
        this.setState(prevState => (
            { [item]: !prevState[item] }
        ))
    }
    // if the menu item doesn't have any child, this method simply returns a clickable menu item that redirects to any location and if there is no child this method uses recursion to go until the last level of children and then returns the item by the first condition.
    handler(children, isChild) {
        let sessionData = JSON.parse(localStorage.getItem('user_data_session'));
        const userRole = this.props.authReducer.userRole.id
        // console.log("userRole",userRole);
        const { state } = this
        return children.map((subOption) => {
            if (sessionData.dataexport !== 1) {
                var hiddenMenuId = ["sub12"];
                if (hiddenMenuId.indexOf(subOption.id) > -1) {
                    subOption.visible = false
                }
            }
            if (userRole !== 0 && userRole === 3) {
                // console.log("in condition");
                var hiddenMenuId = ["sub22", "sub41", "sub51", "sub52", "sub53", "sub54", "sub55", "sub56", "sub57", "sub58", "sub59", "sub72", "sub73", 6, 7, 8];
                if (hiddenMenuId.indexOf(subOption.id) > -1) {
                    subOption.visible = false
                }
            }
            if (!subOption.children && subOption.visible) {
                return (
                    <div key={subOption.name}>
                        <Link to={subOption.url}
                            className={window.location.pathname === subOption.url ? "text_link" : ""}
                        >
                            <ListItem
                                button
                                alignItems="flex-start"
                                key={subOption.name}>
                                <ListItemText
                                    className={subOption.class}
                                    inset={isChild ? true : false}
                                    disableTypography={isChild ? true : false}
                                    primary={subOption.name}
                                />
                            </ListItem>
                        </Link>
                    </div>
                )
            }
            if (subOption.visible) {
                return (
                    <div key={subOption.name}>
                        <ListItem
                            button
                            className={state[subOption.name] ? 'active' : ''}
                            onClick={() => this.handleClick(subOption.name)}>
                            <ListItemText
                                className={subOption.class}
                                primary={subOption.name} />
                            {state[subOption.name] ?
                                <ExpandLess /> :
                                <ExpandMore />
                            }
                        </ListItem>
                        <Collapse
                            in={state[subOption.name]}
                            timeout="auto"
                            unmountOnExit
                        >
                            {this.handler(subOption.children, true)}
                        </Collapse>
                    </div>
                )
            }
        })
    }
    render() {
        return (
            <React.Fragment>
                <aside className="sidenav collepse_menu">
                    <List className="side_menu">
                        {this.handler(this.state.data)}
                    </List>
                </aside>
            </React.Fragment>
        );
    }
}

// export default withRouter(TopNavbar);
const mapStateToProps = state => ({
    authReducer: state.authReducer
});
const mapDispatchToProps = dispatch => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SettingSidebarMenu)); 
