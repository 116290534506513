import React, { Component } from "react";
import { Dialog, List, ListItem, DialogTitle, DialogActions, IconButton, Icon, Menu, withStyles, Grid, Fab, Select, MenuItem, TextField, Card, InputLabel, Button, Checkbox, Tooltip } from "@material-ui/core";
import * as actions from '../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import FontAwesome from "react-fontawesome";
// import FiltersMenu from "./FiltersMenu";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';
// const ITEM_HEIGHT = 48;
import _ from 'lodash'
import AddComment from "../CommonLogUI/AddComment";
import EditComment from "../CommonLogUI/EditComment";
import forEach from 'lodash/forEach';
import EmailTypeOne from "../CommonLogUI/EmailTypeOne";
import EmailTypeTwo from "../CommonLogUI/EmailTypeTwo";
import EmailTypeFour from "../CommonLogUI/EmailTypeFour";
import SystemLog from "../CommonLogUI/SystemLog"
import 'draft-js-mention-plugin/lib/plugin.css';
import NoteLog from "../CommonLogUI/NoteLog";
import CallLogs from "../CommonLogUI/CallLogs";
import RingCentralCallLogs from "../CommonLogUI/RingCentralCallLogs";
import FilterLogoCommon from "../CommonLogUI/FilterLogoCommon";
import CreatelogDialog from "../CommonLogUI/CreatelogDialog";
import InboxComposeDialog from "../CommonComposeMail/InboxComposeDialog";
import ViewHtmlDialog from "../CommonLogUI/ViewHtmlDialog";

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.3rem",
        color: 'rgba(0, 0, 0, 0.6)'
    }
}))(Icon);

const IconSmaller = withStyles(() => ({
    root: {
        fontSize: "1.0rem",
        color: 'rgba(0, 0, 0, 0.6)'
    }
}))(Icon);

const TooltipNew = withStyles((theme) => ({
    tooltip: {
        fontSize: 14,
    },
}))(Tooltip);

class FiltersMenu extends Component {
    state = {
        commentid: '',
        Commentoptions: '',
        showCommentoptions: false,
        activities: [],
        typeArray: [],
        activityArray: [],
        activityflag: true,
    };

    componentDidMount() {

        let typeArray1 = this.state.typeArray;
        let activityArray1 = this.state.activityArray;

        typeArray1 = [1, 2, 3, 4, 6, 7, 15, 20, 21, 5, 100];

        this.setState({ typeArray: typeArray1 });
        this.props.thisData.setState({ logtypeid: typeArray1 });
    }
    componentWillReceiveProps(props) {

        if (props.proVar && props.proVar.length > 0) {
            this.setState({ activities: props.proVar });
            if (this.state.activityflag) {
                let activityArray1 = this.state.activityArray;
                let activities = props.proVar;

                if (activities && activities.length > 0) {
                    activities.map((activity) => {
                        if (activityArray1.indexOf(parseFloat(activity.id)) == -1) {
                            activityArray1.push(parseFloat(activity.id));
                        }
                    })
                    this.setState({ activityArray: activityArray1, activityflag: false });
                    props.thisData.setState({ activitypeid: activityArray1 });
                }
            }
        }
    }

    handleClick = (event) => {
        this.setState({ Commentoptions: event.target, showCommentoptions: true });
    }

    handleCloseOptions = (event) => {
        this.setState({ Commentoptions: null, showCommentoptions: false });
    }

    handleMenuClick = (e, value, type) => {
        let typeArray1 = this.state.typeArray;
        let activityArray1 = this.state.activityArray;
        var promise = new Promise((resolve, reject) => {
            if (e.target.checked == true) {
                if (type == 0) {

                    if (activityArray1.indexOf(value) == -1)
                        activityArray1.push(value);

                } else if (type == 1000) {

                    typeArray1 = [1, 2, 3, 4, 6, 7, 15, 20, 21, 5, 100];

                    let activities = this.state.activities;

                    if (activities && activities.length > 0) {
                        activities.map((activity) => {
                            if (activityArray1.indexOf(parseFloat(activity.id)) == -1) {
                                activityArray1.push(parseFloat(activity.id));
                            }
                        })
                    }

                } else if (type == 1001) {

                    let pusharr = [1, 2, 3, 4, 6, 7, 15, 20, 21];
                    pusharr.map((arr) => {
                        if (typeArray1.indexOf(arr) == -1) {
                            typeArray1.push(arr);
                        }
                    })

                }
                else {
                    if (typeArray1.indexOf(type) == -1)
                        typeArray1.push(type);

                    if (activityArray1.indexOf(3) == -1)
                        activityArray1.push(3);
                }
            }
            if (e.target.checked == false) {
                if (type == 0) {

                    let key1 = activityArray1.indexOf(value);
                    activityArray1.splice(key1, 1);

                } else if (type == 1000) {

                    typeArray1 = [];
                    activityArray1 = [];

                } else if (type == 1001) {

                    let pullarr = [1, 2, 3, 4, 6, 7, 15, 20, 21];
                    pullarr.map((arr) => {
                        if (typeArray1.indexOf(arr) != -1) {
                            let ind = typeArray1.indexOf(arr);
                            typeArray1.splice(ind, 1);
                        }
                    })

                } else {
                    let key = typeArray1.indexOf(type);
                    typeArray1.splice(key, 1);

                    let key1 = activityArray1.indexOf(3);
                    activityArray1.splice(key1, 1);
                }
            }
            resolve(1)
        })

        promise.then(result => {
            this.setState({ typeArray: typeArray1, activityArray: activityArray1 });
            this.props.thisData.setState({ logtypeid: typeArray1, activitypeid: activityArray1 });
            this.props.thisData.handlefiltermain(typeArray1, activityArray1);
        });
    }

    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }

    render() {
        let { activities, activityArray, typeArray } = this.state;
        let def_typearr = [1, 2, 3, 4, 5, 100];
        let flag_all = true;

        if (activities && activities.length > 0) {
            activities.map((act) => {
                if (activityArray.indexOf(parseFloat(act.id)) == -1)
                    flag_all = false;
            })
        } else {
            flag_all = false;
        }

        def_typearr.map((def) => {
            if (typeArray.indexOf(def) == -1)
                flag_all = false;
        })

        return (
            <>
                <Grid container justify="space-between">
                    <Grid item xs={10}></Grid>
                    <Grid item xs={2}>
                        <List className="p-0 pull-right mr-20 log_filter_list">
                            <ListItem
                                aria-label="More"
                                aria-owns={this.state.showCommentoptions ? "filters_menu" : undefined}
                                aria-haspopup="true"
                                onClick={this.handleClick}
                                className="font-size-14 cursor_poiter  pr-0"
                            >
                                Filters <Icon>arrow_drop_down</Icon>
                            </ListItem>
                            <Menu
                                className=""
                                id="long-menu"
                                anchorEl={this.state.Commentoptions}
                                open={this.state.showCommentoptions}
                                onClose={this.handleCloseOptions}
                                PaperProps={{
                                    style: {
                                        width: 200
                                    }
                                }}
                            >
                                <MenuItem className="filter_items">
                                    <Icon className="mr-16 ">storage</Icon><TooltipNew title='All Activities'>
                                        <div className="text_ellipsis " style={{ maxWidth: '72px', fontSize: '14px', margin: '3px 0px 0px 0px', fontWeight: '400' }}>All Activities</div></TooltipNew>
                                    <Checkbox color="primary"
                                        disabled={false}
                                        className={"crm_log_checkbox_filter checkB_color"}
                                        name={'allActivities'}
                                        id={'filterCheck'}
                                        checked={flag_all}
                                        onChange={(event, value) => this.handleMenuClick(event, 1000, 1000)}
                                        value={this.state.filterCheck}
                                        color="primary"
                                    />
                                </MenuItem>
                                {(activities && activities.length > 0) && activities.map((activity) => (
                                    parseFloat(activity.id) !== 3 && (
                                        <MenuItem className="filter_items">
                                            {activity.icon && activity.icon === "fa-paper-plane" && <FontAwesome className={activity.icon + " p-5 crm_activitytype_icon"} style={{ fontSize: '20px', padding: '5px 18px 5px 2px' }} />}
                                            {activity.icon && activity.icon !== "fa-paper-plane" && <FontAwesome className={activity.icon + " mr-16"} style={{ fontSize: '15px', padding: '5px' }} />}
                                            <TooltipNew title={activity.name}>
                                                <div className="text_ellipsis " style={{ maxWidth: '72px', fontSize: '14px', margin: '3px 0px 0px 0px', fontWeight: '400' }}>{activity.name}</div></TooltipNew>
                                            <Checkbox color="primary"
                                                className={"crm_log_checkbox_filter checkB_color"}
                                                disabled={false}
                                                name={activity.name}
                                                id={'filterCheck'}
                                                checked={(activityArray.indexOf(parseFloat(activity.id)) != -1) ? true : false}
                                                onChange={(event, value) => this.handleMenuClick(event, parseFloat(activity.id), 0)}
                                                value={this.state.filterCheck}
                                                color="primary"
                                            />
                                        </MenuItem>)
                                ))}
                                <MenuItem className="filter_items">
                                    <FontAwesome className={"fa-credit-card p-5 crm_activitytype_icon"} style={{ fontSize: '17px', padding: '5px 18px 5px 5px' }} />
                                    <TooltipNew title='Emails'>
                                        <div className="text_ellipsis " style={{ maxWidth: '72px', fontSize: '14px', margin: '3px 0px 0px 0px', fontWeight: '400' }}>Emails</div></TooltipNew>
                                    <Checkbox color="primary"
                                        disabled={false}
                                        className={"crm_log_checkbox_filter checkB_color"}
                                        name={'email'}
                                        id={'filterCheck'}
                                        checked={(typeArray.indexOf(100) != -1) ? true : false}
                                        onChange={(event, value) => this.handleMenuClick(event, 100, 100)}
                                        value={this.state.filterCheck}
                                        color="primary"
                                    />
                                </MenuItem>
                                <MenuItem className="filter_items">
                                    <Icon className="mr-16">subject</Icon><TooltipNew title='Notes'>
                                        <div className="text_ellipsis " style={{ maxWidth: '72px', fontSize: '14px', margin: '3px 0px 0px 0px', fontWeight: '400' }}>Notes</div></TooltipNew>
                                    <Checkbox color="primary"
                                        disabled={false}
                                        className={"crm_log_checkbox_filter checkB_color"}
                                        name={"note"}
                                        id={'filterCheck'}
                                        checked={(typeArray.indexOf(5) != -1) ? true : false}
                                        onChange={(event, value) => this.handleMenuClick(event, 5, 5)}
                                        value={this.state.filterCheck}
                                        color="primary"
                                    />
                                </MenuItem>
                                <MenuItem className="filter_items">
                                    <Icon className="mr-16 ">storage</Icon> <TooltipNew title='System Updates'>
                                        <div className="text_ellipsis " style={{ maxWidth: '72px', fontSize: '14px', margin: '3px 0px 0px 0px', fontWeight: '400' }}>System Updates</div></TooltipNew>
                                    <Checkbox color="primary"
                                        disabled={false}
                                        className={"crm_log_checkbox_filter checkB_color"}
                                        name={'sysupdate'}
                                        id={'filterCheck'}
                                        checked={(typeArray.indexOf(1) != -1) ? true : false}
                                        onChange={(event, value) => this.handleMenuClick(event, 1001, 1001)}
                                        value={this.state.filterCheck}
                                        color="primary"
                                    />
                                </MenuItem>
                            </Menu>
                        </List>
                    </Grid>
                </Grid>
            </>
        )
    }
}

class CompanyLogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewHtmlData: '',
            shouldOpenViewEmailDialog: false,
            FullViewEmailDialog: false,
            minimizeViewEmailDialog: false,

            selectedDate: new Date(),
            isOpenedEmailIds: [],
            isOpenedReplayEmailLog: false,
            allLogs: [],
            logvaluetype: 'Phone Call',
            logtype: '',
            textvalue: '',
            dateformat: 'MM/DD/YYYY',
            timeZone: 'America/New_York',
            shouldOpenCreateLogDialog: false,
            FullCreateCompanytDialog: false,
            minimizeCompanyDialog: false,
            isDeleteLogConfirmDialoOpen: false,
            isEditLog: false,
            isEditComment: false,
            logid: '',
            commentid: '',
            Commentoptions: '',
            allSettings: "",
            showCommentoptions: false,
            isOpenedCommentIds: [],
            logtypeid: [],
            activitypeid: [],
            activities: [],
            userid: "",
            showSelector: false,
            userName: "",
            isSendNewMail: false,
            emaildata: [],
        };

    }

    componentDidMount() {
        let val = { typeId: 3 }
        this.props.customFieldList(this.props.apolloClient.client, val);
        this.props.getActivityTypes(this.props.apolloClient.client);
        this.props.getEmailSetting(this.props.apolloClient.client);
        let userData = JSON.parse(localStorage.getItem('user_data_session'))
        this.setState({
            userid: userData.id
        })
    }
    componentWillReceiveProps(props) {

        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            let usersetting = props.settingReducer.userSettingArray.data;

            let format = usersetting.dateformat;
            let timezone = usersetting.timezone;
            this.setState({ dateformat: format, timeZone: timezone })
        }
        if (props.settingReducer.masterActivityTypesList) {
            this.setState({ activities: props.settingReducer.masterActivityTypesList.data });
        }

        if (props.settingReducer.emailsettings.data) {
            const mian_data = props.settingReducer.emailsettings.data
            this.setState({
                allSettings: mian_data,
            })
        }
        if (props.companyReducer.companyFieldData.data && props.companyReducer.companyFieldData.data.length > 0 && props.companyReducer.companyFieldData.is_loaded) {
            var data = props.companyReducer.companyFieldData.data;
            var mainIntemplate = {};
            data.map((details, index) => {
                if (details.fieldkey == "name") {
                    mainIntemplate.fullname = details.companydetail[0].fieldvalue;
                    mainIntemplate.firstname = (details.companydetail.length > 0 && details.companydetail[0].values.firstname) ? details.companydetail[0].values.firstname : "";
                    mainIntemplate.lastname = (details.companydetail.length > 0 && details.companydetail[0].values.lastname) ? details.companydetail[0].values.lastname : "";
                    this.setState({ userName: details.companydetail.length > 0 ? details.companydetail[0].fieldvalue : null })
                }
                if (details.fieldkey == "company" || details.fieldkey == "contacttype" || details.fieldkey == "email" || details.fieldkey == "phones") {
                    mainIntemplate[details.fieldkey] = (details.companydetail.length > 0 && details.companydetail[0].values.length > 0) ? details.companydetail[0].values[0].value : null
                }
                if (details.fieldkey == "title" || details.fieldkey == "address") {
                    mainIntemplate[details.fieldkey] = (details.companydetail.length > 0 && details.companydetail[0].fieldvalue) ? details.companydetail[0].fieldvalue : null
                }
                if (details.fieldkey == "owner") {
                    mainIntemplate.owner = (details.companydetail.length > 0 && details.companydetail[0].values.length > 0) ? details.companydetail[0].values[0].name : null
                }
                if (details.fieldkey == "email") {
                    this.setState({
                        userSelectedEmail: (details.companydetail.length > 0 && details.companydetail[0].values.length > 0) ? details.companydetail[0].values[0].value : null
                    })
                }
            })
            this.setState({
                mainUserData: mainIntemplate
            })
        }
        if (props.companyReducer.companyLogList && props.companyReducer.companyLogList.data && props.companyReducer.companyLogList.data.logdata && props.companyReducer.companyLogList.data.logdata.length > 0) {
            let logged_in_user_email = JSON.parse(localStorage.getItem("user_data_session")).email;
            let logData = [];
            let results = [];
            let smallData = props.companyReducer.companyLogList.data.logdata[props.companyReducer.companyLogList.data.logdata.length - 1]
            // let resultList = this.getMonths(smallData.createddate, props.companyReducer.companyLogList.data.logdata[0].createddate);
            let source = '';
            if (props.companyReducer.companyLogList.data) {
                let fieldSource = props.companyReducer.companyFieldData.data;
                var sourcekey = this.findWithAttr(fieldSource, 'fieldkey', 'name');

                if (fieldSource[sourcekey] && fieldSource[sourcekey].companydetail && fieldSource[sourcekey].companydetail[0].fieldvalue)
                    source = fieldSource[sourcekey].companydetail[0].fieldvalue;
            }
            let resultList = [];
            props.companyReducer.companyLogList.data.logdata.map((data) => {
                // resultList.push({
                //     str: moment.unix(data.createddate).tz(this.state.timeZone).format('MMM YYYY')
                // })
                if (data.logtype == 0 || data.logtype == 5) {
                    resultList.push({
                        str: moment.unix(data.logdate).tz(this.state.timeZone).format('MMM YYYY')
                    })
                } else {
                    resultList.push({
                        str: moment.unix(data.createddate).tz(this.state.timeZone).format('MMM YYYY')
                    })
                }
            })
            results['Pinned'] = [];
            let results_pined_ids = [];
            resultList.map((mont) => {
                results[mont.str] = [];


                props.companyReducer.companyLogList.data.logdata.map((data) => {

                    let pinned = data.ispined;
                    if (data.email_type) {
                        if (mont.str == moment.unix(data.createddate).tz(this.state.timeZone).format('MMM YYYY')) {
                            let log_user = JSON.parse(localStorage.getItem('user_data_session'));
                            let color_class = (log_user.name) ? log_user.name.charAt(0) : "";

                            if (data.email_type == 1) {
                                let date = moment(data.details.email_date_time).format("X");
                                logData.push(
                                    {
                                        logId: data.id,
                                        emojidetail: data.emojidetail && data.emojidetail !== null ? JSON.parse(data.emojidetail) : [],
                                        emailtype: 1,
                                        name: data.name,
                                        ispined: pinned,
                                        email: data.email,
                                        color: color_class,
                                        comments: data.__commentlist__,
                                        attachment: data.attachment,
                                        emaildetails: data.details,
                                        emailTo: data.emailTo,
                                        snippet: data.details.snippet,
                                        subject: data.details.subject,
                                        body_html: data.details.body_html,
                                        email_date_time: moment.unix(data.createddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " + moment.unix(data.createddate).tz(this.state.timeZone).format('hh:mm A'),
                                    }
                                );
                            }
                            else if (data.email_type == 2) {
                                logData.push(
                                    {
                                        logId: data.id,
                                        emojidetail: data.emojidetail && data.emojidetail !== null ? JSON.parse(data.emojidetail) : [],
                                        emailtype: 2,
                                        ispined: pinned,
                                        name: data.name,
                                        email: data.email,
                                        color: color_class,
                                        comments: data.__commentlist__,
                                        attachment: data.attachment,
                                        emaildetails: data.details,
                                        emailTo: data.emailTo,
                                        snippet: data.details.snippet,
                                        subject: data.details.subject,
                                        body_html: data.details.body_html,
                                        email_date_time: moment.unix(data.createddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " + moment.unix(data.createddate).tz(this.state.timeZone).format('hh:mm A'),
                                    }
                                );
                            }
                            else if (data.email_type == 4) {
                                logData.push(
                                    {
                                        logId: data.id,
                                        emojidetail: data.emojidetail && data.emojidetail !== null ? JSON.parse(data.emojidetail) : [],
                                        emailtype: 4,
                                        ispined: pinned,
                                        name: data.name,
                                        email: data.email,
                                        color: color_class,
                                        comments: data.__commentlist__,
                                        attachment: data.attachment,
                                        emaildetails: data.details,
                                        emailTo: data.emailTo,
                                        snippet: data.details.snippet,
                                        subject: data.details.subject,
                                        body_html: data.details.body_html,
                                        email_date_time: moment.unix(data.createddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " + moment.unix(data.createddate).tz(this.state.timeZone).format('hh:mm A'),
                                    }
                                );
                            }
                        }
                    }
                    else {
                        let template = data.template;
                        let color_class = (data.__user__.name) ? data.__user__.name.charAt(0) : "";
                        let text = (data.text) ? data.text : "";
                        let loguser = (data.__user__.email == logged_in_user_email) ? 'You' : data.__user__.name;
                        let template1 = (data.template) ? template.replace("{{user}}", loguser) : "";
                        let new_template = "";
                        let create_date = data.createddate;
                        let updateddate = data.updateddate;
                        if (mont.str == moment.unix(data.createddate).tz(this.state.timeZone).format('MMM YYYY') && data.logtype != 0 && data.logtype != 5) {

                            if (data.logtype == 1) // create time
                            {
                                new_template = template1.replace("{{source_type}}", "company");
                            }
                            // else if (data.logtype == 0) // Phone call
                            // {
                            //     updateddate = data.logdate;
                            //     new_template = template1.replace("{{property_name}}", '"' + data.propertyname + '"');
                            // }
                            else if (data.logtype == 1000) {// RingCentral Call
                                updateddate = data.logdate;
                                new_template = "Ring Central Calls";
                            }
                            else if (data.logtype == 2) // name change
                            {
                                let old_template = template1.replace("{{source_type}}", "company");
                                new_template = old_template.replace("{{source name=new_value}}", '"' + data.newvalue + '"');
                            }
                            else if (data.logtype == 3 && data.__assignuser__) // owner change
                            {
                                let logAssignUser = (data.__assignuser__.email == logged_in_user_email) ? 'You' : data.__assignuser__.name;
                                new_template = template1.replace("{{target}}", '"' + logAssignUser + '"');
                            }
                            else if (data.logtype == 4) // for status
                            {
                                let old_template = template1.replace("{{old_value}}", data.oldvalue);
                                new_template = old_template.replace("{{new_value}}", data.newvalue);
                            }
                            // else if (data.logtype == 5) // for note
                            // {
                            //     updateddate = data.logdate;
                            //     new_template = template1.replace("{{property_name}}", '"' + data.propertyname + '"');
                            // }
                            else if (data.logtype == 6) // when assigned a contact
                            {
                                if (template1.indexOf("{{opportunities}}") != -1) {
                                    let template2 = template1.replace("{{source_type}}", 'Opportunities');
                                    data.__opportunities__ && data.__opportunities__.__opportunitiesdetail__.map((oppolog) => {
                                        if (oppolog.__field__ && oppolog.__field__.id == "40") {
                                            new_template = template2.replace("{{opportunities}}", '"' + oppolog.fieldvalue.trim() + '"');
                                        }
                                    });
                                }
                                if (template1.indexOf("{{people}}") != -1) {
                                    let template2 = template1.replace("{{source_type}}", 'Contact');
                                    data.__people__ && data.__people__.__contactdetail__.map((poplelog) => {
                                        if (poplelog.__field__ && poplelog.__field__.id == "16") {
                                            new_template = template2.replace("{{people}}", '"' + poplelog.fieldvalue.trim() + '"');
                                        }
                                    });
                                }

                            }
                            else if (data.logtype == 7) // when assigned a company
                            {
                                let old_template = "";
                                if (template1.indexOf("{{opportunities}}") != -1) {
                                    let template2 = template1.replace("{{source_type}}", 'Opportunities');
                                    data.__opportunities__ && data.__opportunities__.__opportunitiesdetail__.map((oppolog) => {
                                        if (oppolog.__field__ && oppolog.__field__.id == "40") {
                                            old_template = template2.replace("{{opportunities}}", '"' + oppolog.fieldvalue.trim() + '"');
                                        }
                                    });
                                }

                                if (template1.indexOf("{{people}}") != -1) {
                                    let template2 = template1.replace("{{source_type}}", 'Contact');
                                    data.__people__ && data.__people__.__contactdetail__.map((companylog) => {
                                        if (companylog.__field__ && companylog.__field__.id == "16") {
                                            old_template = template2.replace("{{people}}", '"' + companylog.fieldvalue.trim() + '"');
                                        }
                                    });
                                }

                                if (data.__assignuser__) {
                                    let logAssignUser = (data.__assignuser__.email == logged_in_user_email) ? 'You' : data.__assignuser__.name;
                                    new_template = old_template.replace("{{target}}", '"' + logAssignUser + '"');
                                }

                            }
                            else if (data.logtype == 10) // contacttype change 
                            {

                                let old_template = template1.replace("{{actor}}", loguser);
                                let old_template1 = old_template.replace("{{source_type}}", 'contact');
                                let old_template2 = '';
                                data.__people__ && data.__people__.__contactdetail__.map((companylog) => {
                                    if (companylog.__field__ && companylog.__field__.id == "16") {
                                        old_template2 = old_template1.replace("{{source}}", companylog.fieldvalue);
                                    }
                                });
                                let old_template3 = old_template2.replace("{{property_name}}", data.propertyname);
                                let old_template4 = old_template3.replace("{{old_value}}", data.oldvalue);
                                new_template = old_template4.replace("{{new_value}}", data.newvalue);
                            }
                            else if (data.logtype == 11) // opportunity stage change
                            {
                                // {{actor}} updated the Stage for the Opportunity "{{opportunities}}" related to "{{primary_association}}" from {{old_value}} to {{new_value}}

                                let old_template = template1.replace("{{actor}}", loguser);
                                // let old_template1 = old_template.replace("{{source_type}}", 'contact');
                                // let old_template2 = '';
                                // data.__people__ && data.__people__.__contactdetail__.map((companylog) => {
                                //     if (companylog.__field__ && companylog.__field__.id == "16") {
                                //         old_template2 = old_template1.replace("{{source}}", companylog.fieldvalue);
                                //     }
                                // });
                                // let old_template3 = old_template2.replace("{{property_name}}", data.propertyname);
                                // let old_template4 = old_template3.replace("{{old_value}}", data.oldvalue);
                                // new_template = old_template4.replace("{{new_value}}", data.newvalue);
                            }
                            else if (data.logtype == 12) // opportunity stage change
                            {
                                // {{actor}} updated the Status for the Opportunity "{{opportunities}}" related to "{{primary_association}}" from {{old_value}} to {{new_value}}

                                let old_template = template1.replace("{{actor}}", loguser);
                                // let old_template1 = old_template.replace("{{source_type}}", 'contact');
                                // let old_template2 = '';
                                // data.__people__ && data.__people__.__contactdetail__.map((companylog) => {
                                //     if (companylog.__field__ && companylog.__field__.id == "16") {
                                //         old_template2 = old_template1.replace("{{source}}", companylog.fieldvalue);
                                //     }
                                // });
                                // let old_template3 = old_template2.replace("{{property_name}}", data.propertyname);
                                // let old_template4 = old_template3.replace("{{old_value}}", data.oldvalue);
                                // new_template = old_template4.replace("{{new_value}}", data.newvalue);
                            }
                            else if (data.logtype == 15) // for note
                            {
                                let old_template = template1.replace("{{old_value}}", data.oldvalue);
                                let old_template1 = old_template.replace("{{new_value}}", data.newvalue);
                                new_template = old_template1.replace("{{property_name}}", '"' + data.propertyname + '"');
                            }
                            else if (data.logtype == 20) // for note
                            {
                                if (data.__task__ != null) {
                                    let old_template = template1.replace("{{task}}", data.__task__.__taskdetail__[0].fieldvalue);
                                    new_template = old_template.replace("{{source}}", source);
                                }
                            }
                            else if (data.logtype == 21) // for note
                            {
                                if (data.__task__ != null) {
                                    let old_template = template1.replace("{{task}}", data.__task__.__taskdetail__[0].fieldvalue);
                                    let old_template1 = old_template.replace("{{source}}", source);
                                    new_template = old_template1.replace("{{target}}", loguser);
                                }
                            }

                            if (new_template != "") {
                                logData.push(
                                    {
                                        logId: data.id,
                                        ispined: pinned,
                                        emojidetail: data.emojidetail && data.emojidetail !== null ? JSON.parse(data.emojidetail) : [],
                                        mentionid: data.mentionid,
                                        mentiondetail: data.mentiondetail ? data.mentiondetail : "",
                                        creaetedDate: moment.unix(create_date).tz(this.state.timeZone).format(this.state.dateformat),
                                        emailtype: 0,
                                        comments: data.__commentlist__,
                                        activitytype: data.__activitytype__,
                                        user: data.__user__.name,
                                        useremail: data.__user__.email,
                                        propertyType: data.propertyname,
                                        color: color_class,
                                        template: new_template,
                                        text: text,
                                        logtype: data.logtype,
                                        time: (updateddate != null)
                                            ?
                                            moment.unix(updateddate).tz(this.state.timeZone).format('hh:mm A')
                                            :
                                            moment.unix(create_date).tz(this.state.timeZone).format('hh:mm A'),
                                        date: (updateddate != null)
                                            ?
                                            moment.unix(updateddate).tz(this.state.timeZone).format(this.state.dateformat)
                                            :
                                            moment.unix(create_date).tz(this.state.timeZone).format(this.state.dateformat)
                                    }
                                );
                            }
                        } else if (mont.str == moment.unix(data.logdate).tz(this.state.timeZone).format('MMM YYYY') && (data.logtype == 0 || data.logtype == 5)) {
                            if (data.logtype == 0) // Phone call
                            {
                                updateddate = data.logdate;
                                new_template = template1.replace("{{property_name}}", '"' + data.propertyname + '"');
                            } else if (data.logtype == 5) // for note
                            {
                                updateddate = data.logdate;
                                new_template = template1.replace("{{property_name}}", '"' + data.propertyname + '"');
                            }

                            if (new_template != "") {
                                logData.push(
                                    {
                                        logId: data.id,
                                        ispined: pinned,
                                        emojidetail: data.emojidetail && data.emojidetail !== null ? JSON.parse(data.emojidetail) : [],
                                        mentionid: data.mentionid,
                                        mentiondetail: data.mentiondetail ? data.mentiondetail : "",
                                        creaetedDate: moment.unix(create_date).tz(this.state.timeZone).format(this.state.dateformat),
                                        emailtype: 0,
                                        comments: data.__commentlist__,
                                        activitytype: data.__activitytype__,
                                        user: data.__user__.name,
                                        useremail: data.__user__.email,
                                        propertyType: data.propertyname,
                                        color: color_class,
                                        template: new_template,
                                        text: text,
                                        logtype: data.logtype,
                                        time: (updateddate != null)
                                            ?
                                            moment.unix(updateddate).tz(this.state.timeZone).format('hh:mm A')
                                            :
                                            moment.unix(create_date).tz(this.state.timeZone).format('hh:mm A'),
                                        date: (updateddate != null)
                                            ?
                                            moment.unix(updateddate).tz(this.state.timeZone).format(this.state.dateformat)
                                            :
                                            moment.unix(create_date).tz(this.state.timeZone).format(this.state.dateformat)
                                    }
                                );
                            }
                        }

                    }

                    if (logData.length > 0) {
                        if (logData[0].ispined && logData[0].ispined == true) {
                            if (results_pined_ids.indexOf(logData[0].logId) == -1) {
                                results['Pinned'].push(logData);
                                results_pined_ids.push(logData[0].logId);
                            }
                        } else {
                            results[mont.str].push(logData);
                        }
                        logData = []
                    }
                    // }
                });
            });
            let temp_arr1 = [];
            Object.keys(results).forEach(function (key) {
                let temp_arr2 = [];
                if (key === 'Pinned') {
                    temp_arr2['key'] = 'Pinned';
                    temp_arr2['log'] = results['Pinned'];
                    temp_arr1.unshift(temp_arr2);
                } else if (key !== 'Pinned') {
                    temp_arr2['key'] = key;
                    temp_arr2['log'] = results[key];
                    temp_arr1.push(temp_arr2);
                }
            })
            this.setState({ allLogs: temp_arr1, company_id: props.company_id });
        }
        else if (props.companyReducer.companyLogList && props.companyReducer.companyLogList.data && props.companyReducer.companyLogList.data.logdata && props.companyReducer.companyLogList.data.logdata.length == 0) {
            this.setState({ allLogs: [], company_id: props.company_id });
        }
    }

    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array && array.length; i += 1) {
            if (array && array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    };

    handleCompanyDialogClose = () => {
        this.setState({ shouldOpenCreateLogDialog: false, FullCreateCompanytDialog: false, minimizeCompanyDialog: false });
    };
    handleCompanyDialogMinimize = () => {
        this.setState(oldStateminimizeCompanyDialog => ({ minimizeCompanyDialog: !oldStateminimizeCompanyDialog.minimizeCompanyDialog }));
        this.setState({ FullCreateCompanytDialog: false })
    };
    handleCompanyDialogFull = () => {
        this.setState(oldStateFullCreateCompanytDialog => ({ FullCreateCompanytDialog: !oldStateFullCreateCompanytDialog.FullCreateCompanytDialog }));
        this.setState({ minimizeCompanyDialog: false })
    };


    handleViewEmailDialogClose = () => {
        this.setState({ shouldOpenViewEmailDialog: false, FullViewEmailDialog: false, minimizeViewEmailDialog: false });
    };

    handleViewEmailDialogMinimize = () => {
        this.setState(oldStateminimizeViewEmailDialog => ({ minimizeViewEmailDialog: !oldStateminimizeViewEmailDialog.minimizeViewEmailDialog }));
        this.setState({ FullViewEmailDialog: false })
    };
    handleViewEmailDialogFull = () => {
        this.setState(oldStateFullViewEmailDialog => ({ FullViewEmailDialog: !oldStateFullViewEmailDialog.FullViewEmailDialog }));
        this.setState({ minimizeViewEmailDialog: false })
    };


    handlechange = (event, logid) => {
        this.setState({ [event.target.name]: event.target.value, logid: logid })
    }
    handleDateChange = date => {
        this.setState({ selectedDate: date });
    };
    handleCancelAddLog = () => {
        this.setState({ textvalue: '' })
    }

    toggleReplayEmailLog = () => {
        this.setState({
            isOpenedReplayEmailLog: !this.state.isOpenedReplayEmailLog
        });
    }
    openNewMail = (log) => {
        this.setState({ isOpenedReplayEmailLog: true, logid: log.logId, emaildata: log });
    }


    handleSaveEditLog = (data) => {
        let mention = [];
        Object.entries(data.raw.entityMap).map((entity) => {
            if (mention.indexOf(entity[1].data.mention.id) == -1) {
                mention.push(parseFloat(entity[1].data.mention.id))
            }
        });
        // let date = moment(this.state.selectedDate).format("X");

        let date_test = moment(this.state.selectedDate).format('YYYY-MM-DD HH:mm');
        console.log(date_test, 'inserted');

        var logdate = moment.tz(date_test, this.state.timeZone).format();
        console.log(logdate, this.state.timeZone);

        var date = moment.utc(logdate).format('X');
        console.log(date, 'utc');

        console.log(moment.unix(date).tz(this.state.timeZone).format('YYYY-MM-DD hh:mm A'), 'conv');

        // let textvalue = this.state.textvalue;
        let saveLogparams = [];

        saveLogparams['update'] = {
            text: data.textvalue,
            mentionuser: mention,
            mentiondetail: JSON.stringify(data.raw),
            logtime: parseFloat(date),
            logdate: parseFloat(date),
            logid: parseFloat(this.state.logid),
            // propertyid: parseFloat(0)
        };

        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        saveLogparams['getlog'] = { emailid: emailParam, companyid: parseFloat(this.props.company_id), logtypeid: this.state.logtypeid, activitypeid: this.state.activitypeid };

        this.props.updateCompanyRecLog(this.props.apolloClient.client, saveLogparams);
        this.setState({ isEditLog: false })
    }


    //------delete log
    handleDeleteLog = (logid) => {
        this.setState({ logid: logid });
        this.toggleDeleteDialog()
    };

    toggleDeleteDialog = () => {
        this.setState({ isDeleteLogConfirmDialoOpen: !this.state.isDeleteLogConfirmDialoOpen });
    };

    confirmDeleteLog = () => {
        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        let deletelogParams = {
            logid: parseFloat(this.state.logid),
            companyid: parseFloat(this.state.company_id),
            emailid: emailParam,
            logtypeid: this.state.logtypeid,
            activitypeid: this.state.activitypeid
        };
        this.props.deleteCompanyRecLogDetail(this.props.apolloClient.client, deletelogParams);
        this.toggleDeleteDialog();
    };

    //email menu functions
    handleEmailHtmlPopupMain = (logdata) => {
        this.setState({ shouldOpenViewEmailDialog: true, viewHtmlData: logdata });
    }

    //- comments-----------------
    toggleComment = (logid) => {
        let isOpenedCommentIds = this.state.isOpenedCommentIds;

        if (isOpenedCommentIds.indexOf(logid) == -1) {
            isOpenedCommentIds.push(logid);
        }
        else {
            let index = isOpenedCommentIds.indexOf(logid);
            isOpenedCommentIds.splice(index, 1);
        }
        this.setState(({ commentvalue: "", logid: logid, isOpenedCommentIds: isOpenedCommentIds }));
    }

    handleCancelComment = () => {
        this.setState({ commentvalue: '' });
    };

    handleSaveCommentLog = (type) => {
        const textvalue = this.state.commentState.textvalue
        const raw = this.state.commentState.raw
        let mention = [];
        Object.entries(raw.entityMap).map((entity) => {
            if (mention.indexOf(entity[1].data.mention.id) == -1) {
                mention.push(parseFloat(entity[1].data.mention.id))
            }
        });
        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        let commentParams = {
            type: type,
            comment: textvalue,
            logid: parseFloat(this.state.logid),
            companyid: parseFloat(this.state.company_id),
            emailid: emailParam,
            logtypeid: this.state.logtypeid,
            activitypeid: this.state.activitypeid,
            mentionuser: mention,
            mentiondetail: "",

        };
        console.log(commentParams, "commentParams");
        this.props.addCompanyRecLogComment(this.props.apolloClient.client, commentParams);
        this.setState({ commentvalue: '' });
    };

    //-edit comment
    handleSaveEditComment = (type) => {
        const textvalue = this.state.commentState.textvalue
        const raw = this.state.commentState.raw
        let mention = [];
        Object.entries(raw.entityMap).map((entity) => {
            if (mention.indexOf(entity[1].data.mention.id) == -1) {
                mention.push(parseFloat(entity[1].data.mention.id))
            }
        });
        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        let commentParams = {
            type: type,
            commentid: parseFloat(this.state.commentid),
            comment: this.state.commentvalue,
            companyid: parseFloat(this.state.company_id),
            emailid: emailParam,
            logtypeid: this.state.logtypeid,
            activitypeid: this.state.activitypeid,
            mentionuser: mention,
            mentiondetail: "",
        };
        console.log(commentParams, "commentParams");
        this.props.updateCompanyRecLogComment(this.props.apolloClient.client, commentParams);
        this.setState({ commentvalue: '', isEditComment: false, commentid: '' });

    }

    handleEditCommentMain = (id, comment) => {
        this.setState({ isEditComment: true, commentvalue: comment, commentid: id });
    }

    handleCancelEditComment = () => {
        this.setState({ isEditComment: false, commentvalue: '' })
    }

    //-delete comment
    deleteComment = (cmt_id, type) => {
        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        let commentParams = {
            commentid: cmt_id,
            emailid: emailParam,
            companyid: parseFloat(this.state.company_id),
            logtypeid: this.state.logtypeid,
            activitypeid: this.state.activitypeid,
            type: type
        };
        this.props.deleteCompanyRecComment(this.props.apolloClient.client, commentParams);
    }
    //--------------comments functions-----------

    //---- email functions
    toggleEmail = (id) => {
        let isOpenedEmailIds = this.state.isOpenedEmailIds;

        if (isOpenedEmailIds.indexOf(id) == -1) {
            isOpenedEmailIds.push(id);
        }
        else {
            let index = isOpenedEmailIds.indexOf(id);
            isOpenedEmailIds.splice(index, 1);
        }

        this.setState(({ logid: id, isOpenedEmailIds: isOpenedEmailIds }));
    }

    toggleReplayEmail = () => {
        this.setState(oldStateReplayEmail => ({ isOpenedReplayEmail: !oldStateReplayEmail.isOpenedReplayEmail }));
    }

    componentWillUnmount() {
        localStorage.removeItem('emailLogEmail');
    }
    //------------

    // -- get all months between two dates
    getMonths = (startDate, endDate) => {
        var resultList = [];
        var date = moment.unix(startDate).tz(this.state.timeZone)._d;
        var endDate = moment.unix(endDate).tz(this.state.timeZone)._d;

        var monthNameList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        while (date.getMonth() <= endDate.getMonth() + 1) {
            var stringDate = monthNameList[date.getMonth()] + " " + date.getFullYear();

            //get first and last day of month
            var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

            resultList.push({
                str: stringDate,
            });
            date.setMonth(date.getMonth() + 1);
        }
        resultList.reverse();
        return resultList;
    };

    handlefiltermain = (types, activities) => {
        if (types.length == 0 && activities.length == 0) {
            this.setState({ allLogs: [] });
        } else {
            let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
            this.setState({ logtypeid: types, activitypeid: activities });

            let getfilterLogParam = { companyid: this.state.company_id, emailid: emailParam, logtypeid: types, activitypeid: activities }

            this.props.getCompanyRecLogListNew(this.props.apolloClient.client, getfilterLogParam);
        }
    }

    togglePin = (logId, pinned) => {
        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';

        let pinParams = {
            ispined: !pinned,
            logid: parseFloat(logId),
            recordid: parseFloat(this.state.company_id),
            recordtypeid: 3,
            emailid: emailParam,
            logtypeid: this.state.logtypeid,
            activitypeid: this.state.activitypeid
        }
        this.props.addLogPined(this.props.apolloClient.client, pinParams);
    };

    handleAdd = (log) => this.setState({ showSelector: !this.state.showSelector, logid: log.logId })

    handleSelect = (emoji, data) => {
        const index = data.emojidetail.findIndex(obj => obj.emoji === emoji && obj.by === this.state.userid)
        var main_data = data.emojidetail;
        if (index > -1) {
            main_data = [...main_data.slice(0, index), ...main_data.slice(index + 1)]
        } else {
            main_data = [...main_data, { emoji, by: this.state.userid }]
        }
        this.setState({ showSelector: false })
        console.log("new... ", main_data)
        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        let req = {
            logid: data.emailtype !== 0 ? parseFloat(data.emaildetails.id) : parseFloat(data.logId),
            emoji: emoji,
            emojidetail: JSON.stringify(main_data),
            companyid: parseFloat(this.state.company_id),
            activitypeid: this.state.activitypeid,
            emailid: emailParam,
            logtypeid: this.state.logtypeid,
            logtype: data.emailtype !== 0 ? "email" : "log"
        }
        this.props.addCompanyLogEmoji(this.props.apolloClient.client, req)
    };

    getdata = (data) => {
        console.log("data ....", data)
        this.setState({ commentvalue: data.textvalue, commentState: data })
    };

    getEditCommentData = (data) => {
        console.log("data edit ....", data)
        this.setState({ commentvalue: data.textvalue, commentState: data })
    };

    saveId = (id, date) => {
        this.setState({ logid: id, selectedDate: date })
    };

    AddNewLog = (log) => {
        this.setState({
            logtype: log.logtype,
            logvaluetype: log.propertyType,
            shouldOpenCreateLogDialog: true
        })
    };

    handleSaveAddLog = (data) => {
        let saveLogparams = [];
        saveLogparams['save'] = {
            mentionuser: data.mentionuser,
            mentiondetail: data.mentiondetail,
            propertyname: data.propertyname,
            propertyid: data.propertyid,
            text: data.text,
            logtime: data.logtime,
            logdate: data.logdate,
            oldvalue: "",
            newvalue: "",
            logtype: data.logtype,
            fieldid: data.fieldid,
            companyid: this.props.company_id
        };
        saveLogparams['getlog'] = { companyid: this.props.company_id, emailid: '', logtypeid: [], activitypeid: [] }
        this.props.saveCompanyRecLog(this.props.apolloClient.client, saveLogparams);
        this.setState({ shouldOpenCreateLogDialog: false })
    };

    isOpenedClickEmailToggle = () => {
        this.setState({
            isSendNewMail: !this.state.isSendNewMail
        })
    };


    render() {
        const { isOpenedReplayEmailLog, commentvalue, allLogs, logvaluetype, isOpenedComment, textvalue, selectedDate, shouldOpenCreateLogDialog, minimizeCompanyDialog, FullCreateCompanytDialog } = this.state
        let user_data = JSON.parse(localStorage.getItem('user_data_session'));
        let current_logUser = user_data.email;
        let loghtml = [];
        let logheader = [];
        let logbody = [];
        let allowUserReply = user_data.googlestatus === true && this.state.allSettings.setting_one && this.state.allSettings.setting_one === 1 ? true : false

        allLogs.map((logdata) => {
            logheader = [];
            logbody = [];
            logheader.push(
                <FilterLogoCommon headerKey={logdata.key} />
            )

            logdata['log'].map((log) => {
                log = log[0];
                if (log.emailtype != 0) {
                    if (log.emailtype == 1) {
                        logbody.push(
                            <EmailTypeOne
                                allowUserReply={allowUserReply}
                                commentvalue={commentvalue}
                                log={log}
                                logid={this.state.logid}
                                userName={this.state.userName}
                                company_id={this.state.company_id}
                                isOpenedEmailIds={this.state.isOpenedEmailIds}
                                showSelector={this.state.showSelector}
                                dateformat={this.state.dateformat}
                                timeZone={this.state.timeZone}
                                commentid={this.state.commentid}
                                isOpenedCommentIds={this.state.isOpenedCommentIds}
                                isEditComment={this.state.isEditComment}
                                thatAll={this}
                                transferLink={"/company/company/" + this.state.company_id}
                                mainUserData={this.state.mainUserData}
                                isOpenedReplayEmailLog={isOpenedReplayEmailLog}
                                LogDetails={{
                                    companyid: this.state.company_id,
                                    emailid: localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '',
                                    logtypeid: this.state.logtypeid,
                                    activitypeid: this.state.activitypeid,
                                }}
                                emaildata={this.state.emaildata}
                                LogComponent="Comapny"
                            />
                        )
                    }
                    else if (log.emailtype == 2) {
                        logbody.push(
                            <EmailTypeTwo
                                allowUserReply={allowUserReply}
                                commentvalue={commentvalue}
                                log={log}
                                logid={this.state.logid}
                                userName={this.state.userName}
                                company_id={this.state.company_id}
                                isOpenedEmailIds={this.state.isOpenedEmailIds}
                                showSelector={this.state.showSelector}
                                dateformat={this.state.dateformat}
                                timeZone={this.state.timeZone}
                                commentid={this.state.commentid}
                                isOpenedCommentIds={this.state.isOpenedCommentIds}
                                isEditComment={this.state.isEditComment}
                                thatAll={this}
                                transferLink={"/company/company/" + this.state.company_id}
                                isOpenedReplayEmailLog={isOpenedReplayEmailLog}
                                mainUserData={this.state.mainUserData}
                                LogDetails={{
                                    companyid: this.state.company_id,
                                    emailid: localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '',
                                    logtypeid: this.state.logtypeid,
                                    activitypeid: this.state.activitypeid,
                                }}
                                emaildata={this.state.emaildata}
                                LogComponent="Company"
                            />
                        )
                    }
                    else if (log.emailtype == 4) {
                        logbody.push(
                            <EmailTypeFour
                                allowUserReply={allowUserReply}
                                commentvalue={commentvalue}
                                log={log}
                                logid={this.state.logid}
                                userName={this.state.userName}
                                company_id={this.state.company_id}
                                isOpenedEmailIds={this.state.isOpenedEmailIds}
                                showSelector={this.state.showSelector}
                                dateformat={this.state.dateformat}
                                timeZone={this.state.timeZone}
                                commentid={this.state.commentid}
                                isOpenedCommentIds={this.state.isOpenedCommentIds}
                                isEditComment={this.state.isEditComment}
                                thatAll={this}
                                transferLink={"/company/company/" + this.state.company_id}
                                isOpenedReplayEmailLog={isOpenedReplayEmailLog}
                                mainUserData={this.state.mainUserData}
                                LogDetails={{
                                    companyid: this.state.company_id,
                                    emailid: localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '',
                                    logtypeid: this.state.logtypeid,
                                    activitypeid: this.state.activitypeid,
                                }}
                                emaildata={this.state.emaildata}
                                LogComponent="Company"
                            />
                        )
                    }
                } else {
                    if (log.logtype == 0) {
                        logbody.push(
                            <CallLogs
                                thatAll={this}
                                log={log}
                                commentvalue={this.state.commentvalue}
                                selectedDate={this.state.selectedDate}
                                logid={this.state.logid}
                                dateformat={this.state.dateformat}
                                showSelector={this.state.showSelector}
                                isOpenedCommentIds={this.state.isOpenedCommentIds}
                                isEditComment={this.state.isEditComment}
                                commentid={this.state.commentid}
                                timeZone={this.state.timeZone}
                            />)
                    } else if (log.logtype == 1000) {
                        logbody.push(
                            <RingCentralCallLogs
                                thatAll={this}
                                log={log}
                                commentvalue={this.state.commentvalue}
                                selectedDate={this.state.selectedDate}
                                logid={this.state.logid}
                                dateformat={this.state.dateformat}
                                showSelector={this.state.showSelector}
                                isOpenedCommentIds={this.state.isOpenedCommentIds}
                                isEditComment={this.state.isEditComment}
                                commentid={this.state.commentid}
                                timeZone={this.state.timeZone}
                            />)
                    } else if (log.logtype == 5) {
                        logbody.push(
                            <NoteLog
                                thatAll={this}
                                log={log}
                                commentvalue={this.state.commentvalue}
                                selectedDate={this.state.selectedDate}
                                logid={this.state.logid}
                                dateformat={this.state.dateformat}
                                showSelector={this.state.showSelector}
                                isOpenedCommentIds={this.state.isOpenedCommentIds}
                                isEditComment={this.state.isEditComment}
                                commentid={this.state.commentid}
                                timeZone={this.state.timeZone}
                            />
                        )
                    }
                    else {
                        logbody.push(
                            <SystemLog log={log} />
                        )
                    }
                }

            })
            if (logbody && logbody.length > 0) {
                loghtml.push(logheader);
                loghtml.push(logbody);
            }
        })


        return (
            <>
                <FiltersMenu proVar={this.state.activities} thisData={this} />
                <div>
                    {loghtml.map((month_log) => (
                        <div className="lead_upcoming">
                            <div className="box_portlet">
                                {month_log}
                            </div>
                        </div>
                    ))}
                </div>

                {shouldOpenCreateLogDialog && (
                    <CreatelogDialog
                        handleClose={this.handleCompanyDialogClose}
                        open={shouldOpenCreateLogDialog}
                        handleMinimize={this.handleCompanyDialogMinimize}
                        handleFull={this.handleCompanyDialogFull}
                        minimizeScreen={this.state.minimizeCompanyDialog}
                        fullScreen={this.state.FullCreateCompanyDialog}
                        handleSaveAddLog={this.handleSaveAddLog}
                        logvaluetype={this.state.logvaluetype}
                        propertyid={this.state.propertyid}
                        logtype={this.state.logtype}
                    />
                )}
                <Dialog
                    fullWidth={true}
                    maxWidth={'xs'}
                    open={this.state.isDeleteLogConfirmDialoOpen}
                    onClose={this.toggleDeleteDialog}
                    disableBackdropClick

                    aria-labelledby="ar-remove-dialog-title"
                >
                    <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to remove selected log ?"}</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.toggleDeleteDialog} color="primary">
                            Cancel
                        </Button>
                        <Button type="Button" onClick={() => this.confirmDeleteLog()} className="btn_Primary">
                            Ok
                        </Button>

                    </DialogActions>
                </Dialog>
                {this.state.shouldOpenViewEmailDialog && (
                    <ViewHtmlDialog
                        handleClose={this.handleViewEmailDialogClose}
                        open={this.state.shouldOpenViewEmailDialog}
                        logDatas={this.state.viewHtmlData}
                    />
                )}
                {this.state.isSendNewMail &&
                    <InboxComposeDialog
                        handleClose={this.isOpenedClickEmailToggle}
                        open={this.state.isSendNewMail}
                        userSelectedEmail={this.state.userSelectedEmail}
                        mainUserData={this.state.mainUserData}
                        LogDetails={{
                            companyid: parseFloat(this.state.company_id),
                            emailid: localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '',
                            logtypeid: this.state.logtypeid,
                            activitypeid: this.state.activitypeid,
                        }}
                        LogComponent="Company"
                    />
                }
            </>
        );
    }
}

//export default CompanyLogs;

const mapStateToProps = state => {
    return {
        companyReducer: state.companyReducer,
        apolloClient: state.apolloClient,
        settingReducer: state.settingReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
        addLogPined: (client, request) => dispatch(actions.addLogPined(client, request)),
        getCompanyRecLogListNew: (client, request) => dispatch(actions.getCompanyRecLogListNew(client, request)),
        getActivityTypes: (client) => { dispatch(actions.getActivityTypes(client)) },
        saveCompanyRecLog: (client, request) => dispatch(actions.saveCompanyRecLog(client, request)),
        deleteCompanyRecLogDetail: (client, request) => dispatch(actions.deleteCompanyRecLogDetail(client, request)),
        updateCompanyRecLog: (client, request) => dispatch(actions.updateCompanyRecLog(client, request)),
        addCompanyRecLogComment: (client, request) => dispatch(actions.addCompanyRecLogComment(client, request)),
        updateCompanyRecLogComment: (client, request) => dispatch(actions.updateCompanyRecLogComment(client, request)),
        deleteCompanyRecComment: (client, request) => dispatch(actions.deleteCompanyRecComment(client, request)),
        addCompanyLogEmoji: (client, request) => dispatch(actions.addCompanyLogEmoji(client, request)),
        getEmailSetting: (client) => dispatch(actions.getEmailSetting(client)),
        customFieldList: (client, request) => { dispatch(actions.customFieldList(client, request)) },
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(CompanyLogs);
