import React, { Component } from "react";
import { IconButton, Icon, Grid, MenuItem, withStyles, Fab, Button, DialogContent, Dialog, DialogActions, DialogTitle, Avatar, Card } from "@material-ui/core";

import { connect } from "react-redux";
import { compose } from 'redux';
import MyOpenTasks from "./MyOpenTasks"
import PipelineSummary from "../SalesTab/PipelineSummary"
import SalesPerformance from "../SalesTab/SalesPerformance"
import LatestActivity from "../ActivityTab/LatestActivity"
import CommanDateFilter from "../CommanDateFilter"

class SummaryDashboard extends Component {
  state = {
  };
  render() {
    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CommanDateFilter />
          </Grid>
          <Grid md={12} item>
            <PipelineSummary />
          </Grid>
          <Grid item xs={6}>
            <MyOpenTasks />
          </Grid>
          <Grid md={6} item>
            <LatestActivity />
          </Grid>

          <Grid item xs={12}>
            <SalesPerformance />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};
const mapDispatchToProps = dispatch => {
  return {

  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(SummaryDashboard);