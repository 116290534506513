import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {
  Grid,
  Card,
  withStyles,
  CircularProgress,
  Button
} from "@material-ui/core";

import * as actionCreators from '../../../store/actions/index';

const styles = theme => ({
  wrapper: {
    psition: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: 'white',
    textAlign: 'center',
  },
});
const centerDiv = {
  textAlign: 'center',
  backgroundColor: '#ede8e8',
  width: '100%'
}
const buttonProgress = {
  position: "absolute",
  top: "50%",
  left: "50%",
  marginTop: -12,
  marginLeft: -12
}



class MultipleCompany extends Component {

  //console.log("fsdfsdfds",this)
  state = {
    loading: false,
  };

  componentWillMount() {
    let sessionData = JSON.parse(localStorage.getItem('user_data_session'))
    this.setState({ sessionData: sessionData })

  }
  //const 
  setDatavalue = () => {
    let sessionData = JSON.parse(localStorage.getItem('user_data_session'))
    sessionData.companylist.forEach((ele, idx) => {
      //console.log(ele,"=============",idx)
      return (
        <button>helelele</button>
      )
    });
  }

  MultipleCompanyId = (id) => {
    let data = JSON.parse(localStorage.getItem('user_data_session'))
    var req = {
      cmpId: id,
      email: data.email,
    }
    this.props.checkMultiCompany(req)
  }

  componentWillReceiveProps() {
    this.setState({ loading: false })
  }

  logoutUser = () => {
    localStorage.removeItem('user_data_session')
    localStorage.removeItem('selected_contact_filter_id')
    localStorage.removeItem('selected_lead_filter_id')
    localStorage.removeItem('selected_company_filter_id')
    localStorage.clear();
    window.location.href = '/sign-in';
  }

  render() {
    let classes = this.props;
    return (
      <>
        <div className="flex flex-end">
          <div style={{ margin: '20px 60px 30px 40px' }}>
            <Button color="primary" onClick={() => this.logoutUser()}>Log out</Button>
          </div>
        </div>
        <div className="flex flex-center w-100 h-100vh">
          <div className="p-8">
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              style={{ margin: '100px  0 0 0 ' }}
            >
              <img alt="imag_logo" src={"/assets/images/headerlogo1.png"} className="crm-logo position-relative" style={{ width: '150px', height: '48px' }} />
            </Grid>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              style={{ margin: '20px  0 0 0 ' }}
            >
              <Card className="signup-card position-relative" style={{ boxShadow: '0 30px 60px 0 rgba(0,0,0,.2)', borderRadius: '0px', maxWidth: '230px' }}   >
                <Grid container>
                  <Grid style={centerDiv} className={classes.centerdiv}>
                    <p className="m-10 font-size-14 font-weight-500">Please Select One Company</p>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className="p-20 h-100 position-relative">
                      <div>
                        <div className={classes.root}>
                          <List component="nav" aria-label="">
                            {this.state.sessionData.companylist.map((ele, idx) => {
                              return (
                                <div>
                                  <ListItem
                                    button
                                    onClick={() => this.MultipleCompanyId(ele.id)}
                                  >
                                    {ele.displayname}
                                  </ListItem>
                                </div>
                              )
                            })}
                          </List>
                        </div>
                        {this.state.loading && (
                          <CircularProgress style={buttonProgress} size={50} color="inherit" />
                        )}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  emailData: state.authReducer.userData
});
const mapDispatchToProps = (dispatch) => ({
  resendEmail: (email) => dispatch(actionCreators.resendEmail(email)),
  checkMultiCompany: (data) => dispatch(actionCreators.checkMultiCompany(data))
})
export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(withRouter(MultipleCompany)));
