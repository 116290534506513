import React from 'react';
import PropTypes from 'prop-types';
import RelatedSettingContact from "./RelatedSettingContact";
import RelatedSettingCompanies from "./RelatedSettingCompanies";
import RelatedSettingOpportunities from "./RelatedSettingOpportunities";
import RelatedSettingProjects from "./RelatedSettingProjects";
// import RelatedSettingTask from "./RelatedSettingTask";
import RelatedSettingLead from "./RelatedSettingLead";
import CreationRule from "./CreationRule";
import { Tabs, Tab, Box, Typography, Icon, Switch, FormControlLabel, Card, IconButton, withStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.5rem",
    }
}))(Icon);
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={2}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}


export default function RelatedSettingTab() {
    const data = [
        {
            drag: <Link className="drag-handle" to="#"><Icon>drag_handle</Icon></Link>,
            key: "1",
            name: "jay",
            fieldType: "Text",
            creationRule: <CreationRule />,
            editRule: <CreationRule />,
            action: <IconButton aria-label="edit" size="small">
                <IconSmall>edit</IconSmall>
            </IconButton>,
            active: <FormControlLabel
                value="cardview"
                control={<Switch color="primary" checked />}
                labelPlacement="start"
            />
        },
        {
            drag: <Link className="drag-handle" to="#"><Icon>drag_handle</Icon></Link>,
            key: "1",
            name: "harul",
            fieldType: "Text",
            creationRule: <CreationRule />,
            editRule: <CreationRule />,
            action: <IconButton aria-label="edit" size="small">
                <IconSmall>edit</IconSmall>
            </IconButton>,
            active: <FormControlLabel
                value="cardview"
                control={<Switch color="primary" checked />}
                labelPlacement="start"
            />
        }
    ]
    const [value, setValue] = React.useState(0);

    function handleChange(event, newValue) {
        setValue(newValue);
    }

    return (
        <Card elevation={3} className="h-100">
            <h3 className="ml-16 mt-16 mb-16">Related Settings</h3>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                className="settings_tabs"
                aria-label="scrollable auto tabs"
            >
                <Tab label="Leads" {...a11yProps(0)} />
                <Tab label="Contacts" {...a11yProps(1)} />
                <Tab label="Companies" {...a11yProps(2)} />
                <Tab label="Opportunities" {...a11yProps(3)} />
                <Tab label="Projects" {...a11yProps(4)} />
                {/* <Tab className="ml-auto" label="Other custom field" {...a11yProps(6)} /> */}
            </Tabs>
            <div className="v_scroll position-relative">
                <TabPanel value={value} index={0} className="h-100"><RelatedSettingLead tabdata={data} /></TabPanel>
                <TabPanel value={value} index={1} className="h-100"><RelatedSettingContact /></TabPanel>
                <TabPanel value={value} index={2} className="h-100"><RelatedSettingCompanies /></TabPanel>
                <TabPanel value={value} index={3} className="h-100"><RelatedSettingOpportunities /></TabPanel>
                <TabPanel value={value} index={4} className="h-100"><RelatedSettingProjects /></TabPanel>
                {/* <TabPanel value={value} index={5} className="h-100"><RelatedSettingTask /></TabPanel> */}
            </div>
        </Card>
    );
}
