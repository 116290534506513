import React, { Component } from "react";
import SettingSidebar from '../../Layout/SettingSidebarMenu';
import PropTypes from 'prop-types';
import { Card, FormControl, Switch, Tabs, Dialog, DialogTitle, DialogActions, Tab, Typography, Box, Icon, withStyles, Grid, ButtonGroup, Button, Select, MenuItem, Menu, List, ListItem, ListItemText, IconButton, Input, InputAdornment } from "@material-ui/core";
import { WorkflowDynamicFields } from './WorkflowDynamicFields';
import { WorkflowRequiredFields } from './WorkflowRequiredFields';
// import SearchDropdown from "./SearchDropdown";
import DeleteIcon from '@material-ui/icons/Delete';

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import {
    ValidatorForm,
    TextValidator,
    SelectValidator,
} from "react-material-ui-form-validator";
import moment from 'moment';
import Autocomplete from "@material-ui/lab/Autocomplete";

import * as actions from '../../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';

// draft js imports
import { MegadraftEditor, editorStateFromRaw, editorStateToJSON, MegadraftPlugin, insertDataBlock } from "megadraft-denistsuman";
import { convertFromHTML, EditorState, ContentState, Modifier, Entity, genKey, ContentBlock } from 'draft-js';
import LinkInput from "megadraft-denistsuman/lib/entity_inputs/LinkInput";
import icons from "megadraft-denistsuman/lib/icons"
import 'megadraft-denistsuman/dist/css/megadraft.css'
import { stateToHTML } from 'draft-js-export-html';
import { Map } from 'immutable';
import clearFormatting from 'draft-js-clear-formatting';
let allPipelines = [];

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={2}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    classes: PropTypes.object.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
const styles = theme => ({
    bigIndicator: {
        display: 'none',
    },
});

class CustomSidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false,
            selecttemplate: "none",
            placeholder: "none",
            Template_list: [],
            current_template: [],
            message_edit: '',
            user_placeholder: [],
            conditionfields: [],
            alldraftFieldData: this.props.thisData.state.alldraftFieldData,
            actionindex: this.props.actionindex,
            fieldindex: this.props.fieldindex,
        };
    }

    componentWillReceiveProps(props) {
        if (props.thisData.state.defaultActionFields) {
            this.setState({ conditionfields: props.thisData.state.defaultActionFields[props.thisData.state.TabValue + 1] })
        }
        if (props.thisData.state.alldraftFieldData) {
            this.setState({ alldraftFieldData: props.thisData.state.alldraftFieldData })
        }
        if (props.actionindex)
            this.setState({ actionindex: props.actionindex })

        if (props.fieldindex)
            this.setState({ fieldindex: props.fieldindex })

        if (props.thisData.props.settingReducer.emailtemplatelist.data) {
            let email_list = props.thisData.props.settingReducer.emailtemplatelist.data;
            this.setState({ Template_list: email_list })
        }
    }

    handleSelectPlaceholder = (event) => {

        // console.log(event.target.value,'event.target.value');
        let selectedfield = event.target.value;
        let value = "{{" + selectedfield.generatedfieldid + "}}";
        let type = selectedfield.generatedfieldid;
        let editortype = this.props.type;
        // console.log(this.state.alldraftFieldData,'this.state.alldraftFieldData');
        let editortokenState = {};

        if (editortype == 'firstname') {
            editortokenState = this.state.alldraftFieldData[this.state.actionindex][this.state.fieldindex].names.firstname;
        } else if (editortype == 'lastname') {
            editortokenState = this.state.alldraftFieldData[this.state.actionindex][this.state.fieldindex].names.lastname;
        } else {
            editortokenState = this.state.alldraftFieldData[this.state.actionindex][this.state.fieldindex].fieldvalue;
        }



        let currenttokenContent = editortokenState.getCurrentContent();
        let tokenSelection = editortokenState.getSelection();
        let entityKey = Entity.create('UNSTYLED', 'MUTABLE', " ");
        let textWithEntity = Modifier.insertText(currenttokenContent, tokenSelection, " ", null, entityKey);
        let newtokenitorstate = EditorState.push(editortokenState, textWithEntity, 'insert-characters');

        currenttokenContent = newtokenitorstate.getCurrentContent();
        tokenSelection = newtokenitorstate.getSelection();
        let entityKey2 = Entity.create('LINK', 'MUTABLE', { url: '#' + type, value });
        let textWithEntity2 = Modifier.insertText(currenttokenContent, tokenSelection, value, null, entityKey2);
        newtokenitorstate = EditorState.push(newtokenitorstate, textWithEntity2, 'insert-characters');

        currenttokenContent = newtokenitorstate.getCurrentContent();
        tokenSelection = newtokenitorstate.getSelection();
        let entityKey1 = Entity.create('UNSTYLED', 'MUTABLE', " ");
        let textWithEntity1 = Modifier.insertText(currenttokenContent, tokenSelection, " ", null, entityKey1);
        newtokenitorstate = EditorState.push(newtokenitorstate, textWithEntity1, 'insert-characters');

        if (editortype == 'firstname') {
            this.props.thisData.handleFirstNameChangeToken(newtokenitorstate, selectedfield);
        } else if (editortype == 'lastname') {
            this.props.thisData.handleLastNameChangeToken(newtokenitorstate, selectedfield);
        } else {
            this.props.thisData.handleChangeToken(newtokenitorstate, selectedfield);
        }

    }

    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] == value) {
                return i;
            }
        }
        return -1;
    }


    render() {
        let { Template_list, conditionfields } = this.state;
        //   console.log(conditionfields,'conditionfields');

        return (
            <>
                <div className="cursor-pointer" style={{ height: "18px " }}>
                    <Grid
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Select
                            name="placeholder"
                            id="placeholder"
                            label="Token"
                            className="font-size-13 "
                            value={this.state.placeholder}
                            disableUnderline
                            inputProps={{ className: "pb-0 pt-0" }}
                            onChange={this.handleSelectPlaceholder}
                        >
                            <MenuItem value="none">Token</MenuItem>
                            {conditionfields.map((val, i) => (
                                <MenuItem className="wrapper-rec" key={i} value={val}>
                                    <span className="font-weight-500 font-size-14 ml-5 mr-5 crm-rec-hashplaceholder" >
                                        {val.fieldlabel} </span>
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </div>

            </>
        );
    }
}


class AddWorkAutomation extends Component {
    constructor(props) {
        super(props);

        const myToken = {
            "entityMap": {},
            "blocks": [
                {
                    "key": "0_0_" + genKey(),
                    "text": "",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                }
            ]
        };

        let tokenEditorState = editorStateFromRaw(myToken);


        this.state = {
            name: '',
            TabValue: 0,
            selectedEvent: 1,
            selctedButton: 1,
            search: '',
            chooseField: '1',
            // chooseOperator: '1',
            selectOptions: '1',
            filterConitions: [{
                title: "1"
            }],
            AddAction: [{
                title: "Action 1"
            }],
            actionType: [1],
            actionModule: [1],
            actionFields: [],
            defaultActionFields: [],
            actionotherFields: [],
            allactionmodulesArr: [{ id: 1, name: 'Lead' }, { id: 2, name: 'Contact' }, { id: 3, name: 'Company' }, { id: 4, name: 'Opportunity' }, { id: 5, name: 'Project' }, { id: 6, name: 'Task' }],
            updateActionmodule: [[{ id: 1, name: 'Lead' }, { id: 2, name: 'Contact' }, { id: 3, name: 'Company' }, { id: 4, name: 'Opportunity' }, { id: 5, name: 'Project' }, { id: 6, name: 'Task' }]],
            actionName: '',
            selectPipeline: '1',
            selectPerson: '',
            AddMoreFields: [],
            otherOptions: '1',
            contactTokenDropdown: null,
            contactTokenSelected: 0,
            Tokenoptions: [],
            customFieldsList: [],
            conditionfields: [],
            allconditionfields: [],
            chooseOperator: [],
            actiontagfields: [],
            conditionOperators: [
                [{ id: 1, fieldlabel: 'Equals', fieldkey: 'equals', value: "" },
                { id: 2, fieldlabel: 'Not Equals', fieldkey: 'not-equals', value: "NOT" },
                { id: 3, fieldlabel: 'Contains', fieldkey: 'contains', value: 'like' },]
            ],
            fieldsObject: [],
            companyOptions: [],
            contactOptions: [],
            // conditionValues:[],
            selectedConditionField: [],
            submitFieldData: [],
            IscancelWorkflowAutomation: false,
            token: tokenEditorState,
            alldraftFieldData: [],
            usersListArray: []
        };
    }

    tokenIdGenerator = (actionindex, fieldindex, requiredtotal, field) => {
        // console.log(actionindex, fieldindex, requiredtotal, field,'skdhdf');

        let detaftData = this.state.alldraftFieldData;

        if (field == undefined)
            field = this.state.defaultActionFields[this.state.actionModule[actionindex]][0];

        if (actionindex == undefined)
            actionindex = 0

        if (fieldindex == undefined)
            fieldindex = 0

        if (requiredtotal == undefined)
            requiredtotal = 0

        if (detaftData[actionindex] == undefined)
            detaftData[actionindex] = [];

        if (detaftData[actionindex][fieldindex + requiredtotal] == undefined)
            detaftData[actionindex][fieldindex + requiredtotal] = [];

        const myToken = {
            "entityMap": {},
            "blocks": [
                {
                    "key": actionindex + "_" + (fieldindex + requiredtotal) + "_" + field.id + "_" + genKey(),
                    "text": "",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                }
            ]
        };


        if (parseFloat(field.id) == 1 || parseFloat(field.id) == 16) {
            detaftData[actionindex][fieldindex + requiredtotal].names = { firstname: editorStateFromRaw(myToken), lastname: editorStateFromRaw(myToken) };

            return detaftData[actionindex][fieldindex + requiredtotal].names;
        } else {
            detaftData[actionindex][fieldindex + requiredtotal].fieldvalue = editorStateFromRaw(myToken);

            return detaftData[actionindex][fieldindex + requiredtotal].fieldvalue;
        }
        this.setState({ alldraftFieldData: detaftData });
    }

    getCustomTokenSidebar = (actionindex, fieldindex, type) => {
        return <CustomSidebar actionindex={actionindex} fieldindex={fieldindex} type={type} thisData={this} editorType="token" />
    }

    handleFirstNameChangeToken = (token) => {

        let alldraftFieldData = this.state.alldraftFieldData;
        let submitFieldData = this.state.submitFieldData;

        let jsondata = JSON.parse(editorStateToJSON(token));
        let keydata = jsondata.blocks[0].key.split("_");

        const contentToken = token.getCurrentContent();
        // let tokenhtml = stateToHTML(contentToken);
        let plaintext = contentToken.getPlainText('\u0001');

        let actionindex = parseFloat(keydata[0]);
        let fieldindex = parseFloat(keydata[1]);
        let fieldid = parseFloat(keydata[2]);

        let fieldsArr = this.state.actionFields[actionindex];
        let fieldkeyfound = this.findWithAttr(fieldsArr, 'id', fieldid);
        if (fieldkeyfound != -1) {
            let field = fieldsArr[fieldkeyfound];

            alldraftFieldData[actionindex][fieldindex].names.firstname = token;
            submitFieldData.alldraftFieldData = alldraftFieldData;

            this.checkForDefinedFields(actionindex, field, fieldindex, 0);

            if (submitFieldData.actiondetail[actionindex].details[fieldindex].names == undefined)
                submitFieldData.actiondetail[actionindex].details[fieldindex].names = {}

            submitFieldData.actiondetail[actionindex].details[fieldindex].names.firstname = plaintext;
        }

        this.setState({ alldraftFieldData, submitFieldData });
    }

    handleLastNameChangeToken = (token) => {
        let alldraftFieldData = this.state.alldraftFieldData;
        let submitFieldData = this.state.submitFieldData;

        let jsondata = JSON.parse(editorStateToJSON(token));
        let keydata = jsondata.blocks[0].key.split("_");

        const contentToken = token.getCurrentContent();
        // let tokenhtml = stateToHTML(contentToken);
        let plaintext = contentToken.getPlainText('\u0001');

        let actionindex = parseFloat(keydata[0]);
        let fieldindex = parseFloat(keydata[1]);
        let fieldid = parseFloat(keydata[2]);

        let fieldsArr = this.state.actionFields[actionindex];
        let fieldkeyfound = this.findWithAttr(fieldsArr, 'id', fieldid);
        if (fieldkeyfound != -1) {
            let field = fieldsArr[fieldkeyfound];

            alldraftFieldData[actionindex][fieldindex].names.lastname = token;
            submitFieldData.alldraftFieldData = alldraftFieldData;

            this.checkForDefinedFields(actionindex, field, fieldindex, 0);

            if (submitFieldData.actiondetail[actionindex].details[fieldindex].names == undefined)
                submitFieldData.actiondetail[actionindex].details[fieldindex].names = {}

            submitFieldData.actiondetail[actionindex].details[fieldindex].names.lastname = plaintext;
        }

        this.setState({ alldraftFieldData, submitFieldData });
    }

    handleChangeToken = (token) => {
        let alldraftFieldData = this.state.alldraftFieldData;
        let submitFieldData = this.state.submitFieldData;

        let jsondata = JSON.parse(editorStateToJSON(token));
        let keydata = jsondata.blocks[0].key.split("_");

        const contentToken = token.getCurrentContent();
        // let tokenhtml = stateToHTML(contentToken);
        let plaintext = contentToken.getPlainText('\u0001');

        let actionindex = parseFloat(keydata[0]);
        let fieldindex = parseFloat(keydata[1]);
        let fieldid = parseFloat(keydata[2]);

        let fieldsArr = this.state.actionFields[actionindex];
        let fieldkeyfound = this.findWithAttr(fieldsArr, 'id', fieldid);
        if (fieldkeyfound != -1) {
            let field = fieldsArr[fieldkeyfound];

            alldraftFieldData[actionindex][fieldindex].fieldvalue = token;
            submitFieldData.alldraftFieldData = alldraftFieldData;

            this.checkForDefinedFields(actionindex, field, fieldindex, 0);
            submitFieldData.actiondetail[actionindex].details[fieldindex].fieldvalue = plaintext;
        }

        this.setState({ alldraftFieldData, submitFieldData });
    }

    componentWillMount() {

        if (this.props.match.params.id) {
            // this.setState({automation_id: parseFloat(this.props.match.params.id)});
            let params_automation = { automationid: parseFloat(this.props.match.params.id) }
            this.props.getAutomationDetail(this.props.apolloClient.client, params_automation);


        }


        let params_lead = { typeId: 1 }
        this.props.customFieldList(this.props.apolloClient.client, params_lead);

        let params_contact = { typeId: 2 }
        this.props.customFieldList(this.props.apolloClient.client, params_contact);

        let params_company = { typeId: 3 }
        this.props.customFieldList(this.props.apolloClient.client, params_company);

        let params_opp = { typeId: 4 }
        this.props.customFieldList(this.props.apolloClient.client, params_opp);

        let params_project = { typeId: 5 }
        this.props.customFieldList(this.props.apolloClient.client, params_project);

        let params_task = { typeId: 6 }
        this.props.customFieldList(this.props.apolloClient.client, params_task);

        this.props.getUserList(this.props.apolloClient.client);

        this.props.getCompanyRecList(this.props.apolloClient.client);
    }

    componentWillReceiveProps(props) {
        let actionFields = this.state.defaultActionFields;
        let triggerfileds = this.state.allconditionfields;

        // console.log(props.settingReducer,'props.settingReducerprops.settingReducerprops.settingReducer');
        if (props.settingReducer.companyRecList && props.settingReducer.companyRecList.data && props.settingReducer.companyRecList.data.data) {
            let companyOptions = this.state.companyOptions;
            props.settingReducer.companyRecList.data.data.map((company) => {
                let companykey = this.findWithAttr(companyOptions, 'id', company.company_id);

                if (companykey == -1)
                    companyOptions.push({ id: company.company_id, name: company.name });
            })
            this.setState({ companyOptions });
        }

        if (props.settingReducer.contactRecList && props.settingReducer.contactRecList.data && props.settingReducer.contactRecList.data.data) {
            let contactOptions = this.state.contactOptions;
            props.settingReducer.contactRecList.data.data.map((contact) => {
                let contactkey = this.findWithAttr(contactOptions, 'id', contact.contact_id);

                if (contactkey == -1)
                    contactOptions.push({ id: contact.contact_id, name: contact.name });
            })
            this.setState({ contactOptions });
        }

        if (props.settingReducer.usersListArray && props.settingReducer.usersListArray.data) {
            const usersListArray = props.settingReducer.usersListArray.data;

            this.setState({ usersListArray: usersListArray })
        }

        if (props.settingReducer.userSettingArray.data) {
            const settingData = props.settingReducer.userSettingArray.data;
            this.setState({
                userSettings: settingData,
            })
        }

        if (props.settingReducer.customFieldList && props.settingReducer.customFieldList.data) {
            if (this.state.TabValue == 0) {
                let customfields = this.getDropdownField(props.settingReducer.customFieldList.data);
                customfields = this.addConditionOptions(customfields);
                // this.setState({ customFieldsList: props.settingReducer.customFieldList.data })
                this.setState({ customFieldsList: props.settingReducer.customFieldList.data, conditionfields: customfields, actiontagfields: customfields }, () => {
                    if (props.match.params.id && (this.state.automation_id != props.match.params.id) && props.settingReducer.automationDetail && props.settingReducer.automationDetail.data) {
                        let automationDetail = props.settingReducer.automationDetail.data;
                        this.setEditAutomationData(automationDetail);
                    }
                });
            }

            let customfields2 = this.getDropdownField(props.settingReducer.customFieldList.data);
            customfields2 = this.addConditionOptions(customfields2);
            triggerfileds[0] = customfields2;
            // if (this.state.TabValue == 0){
            //     this.setState({ conditionfields: customfields2, actiontagfields: customfields2});
            // }

            let customfields1 = this.getDropdownField(props.settingReducer.customFieldList.data, 'action');
            actionFields[1] = customfields1;
            if (this.state.actionFields.length == 0) {
                let actfields = this.state.actionFields;
                actfields[0] = customfields1;
                this.setState({ actionFields: actfields });
            }
            this.setState({ defaultActionFields: actionFields, allconditionfields: triggerfileds });
        }

        if (props.settingReducer.contactCustomFieldList && props.settingReducer.contactCustomFieldList.data) {
            if (this.state.TabValue == 1) {
                let customfields = this.getDropdownField(props.settingReducer.contactCustomFieldList.data);
                customfields = this.addConditionOptions(customfields);
                // this.setState({ customFieldsList: props.settingReducer.customFieldList.data })
                this.setState({ customFieldsList: props.settingReducer.contactCustomFieldList.data, conditionfields: customfields, actiontagfields: customfields }, () => {
                    if (props.match.params.id && (this.state.automation_id != props.match.params.id) && props.settingReducer.automationDetail && props.settingReducer.automationDetail.data) {
                        let automationDetail = props.settingReducer.automationDetail.data;
                        this.setEditAutomationData(automationDetail);
                    }
                });
            }


            let customfields2 = this.getDropdownField(props.settingReducer.contactCustomFieldList.data);
            customfields2 = this.addConditionOptions(customfields2);
            triggerfileds[1] = customfields2;
            this.setState({ allconditionfields: triggerfileds });


            let customfields1 = this.getDropdownField(props.settingReducer.contactCustomFieldList.data, 'action');
            actionFields[2] = customfields1;
            this.setState({ defaultActionFields: actionFields })

        }

        if (props.settingReducer.companyCustomFieldList && props.settingReducer.companyCustomFieldList.data) {
            if (this.state.TabValue == 2) {
                let customfields = this.getDropdownField(props.settingReducer.companyCustomFieldList.data);
                customfields = this.addConditionOptions(customfields);
                // this.setState({ customFieldsList: props.settingReducer.customFieldList.data })
                this.setState({ customFieldsList: props.settingReducer.companyCustomFieldList.data, conditionfields: customfields, actiontagfields: customfields }, () => {
                    if (props.match.params.id && (this.state.automation_id != props.match.params.id) && props.settingReducer.automationDetail && props.settingReducer.automationDetail.data) {
                        let automationDetail = props.settingReducer.automationDetail.data;
                        this.setEditAutomationData(automationDetail);
                    }
                });
            }

            let customfields2 = this.getDropdownField(props.settingReducer.companyCustomFieldList.data);
            customfields2 = this.addConditionOptions(customfields2);
            triggerfileds[2] = customfields2;
            this.setState({ allconditionfields: triggerfileds });

            let customfields1 = this.getDropdownField(props.settingReducer.companyCustomFieldList.data, 'action');
            actionFields[3] = customfields1;
            this.setState({ defaultActionFields: actionFields })

        }


        if (props.settingReducer.opportunitiesCustomFieldList && props.settingReducer.opportunitiesCustomFieldList.data) {
            if (this.state.TabValue == 3) {
                let customfields = this.getDropdownField(props.settingReducer.opportunitiesCustomFieldList.data);
                customfields = this.addConditionOptions(customfields);
                // this.setState({ customFieldsList: props.settingReducer.customFieldList.data })
                this.setState({ customFieldsList: props.settingReducer.opportunitiesCustomFieldList.data, conditionfields: customfields, actiontagfields: customfields }, () => {
                    if (props.match.params.id && (this.state.automation_id != props.match.params.id) && props.settingReducer.automationDetail && props.settingReducer.automationDetail.data) {
                        let automationDetail = props.settingReducer.automationDetail.data;
                        this.setEditAutomationData(automationDetail);
                    }
                });
            }

            let customfields2 = this.getDropdownField(props.settingReducer.opportunitiesCustomFieldList.data);
            customfields2 = this.addConditionOptions(customfields2);
            triggerfileds[3] = customfields2;
            this.setState({ allconditionfields: triggerfileds });

            let pipelinekey = this.findWithAttr(customfields2, 'fieldkey', 'pipeline')
            if (pipelinekey != -1) {
                allPipelines = customfields2[pipelinekey].options
            }

            let customfields1 = this.getDropdownField(props.settingReducer.opportunitiesCustomFieldList.data, 'action');
            actionFields[4] = customfields1;
            this.setState({ defaultActionFields: actionFields })

        }

        if (props.settingReducer.projectCustomFieldList && props.settingReducer.projectCustomFieldList.data) {
            if (this.state.TabValue == 4) {
                let customfields = this.getDropdownField(props.settingReducer.projectCustomFieldList.data);
                customfields = this.addConditionOptions(customfields);
                // this.setState({ customFieldsList: props.settingReducer.customFieldList.data })
                this.setState({ customFieldsList: props.settingReducer.projectCustomFieldList.data, conditionfields: customfields, actiontagfields: customfields }, () => {
                    if (props.match.params.id && (this.state.automation_id != props.match.params.id) && props.settingReducer.automationDetail && props.settingReducer.automationDetail.data) {
                        let automationDetail = props.settingReducer.automationDetail.data;
                        this.setEditAutomationData(automationDetail);
                    }
                });
            }

            let customfields2 = this.getDropdownField(props.settingReducer.projectCustomFieldList.data);
            customfields2 = this.addConditionOptions(customfields2);
            triggerfileds[4] = customfields2;
            this.setState({ allconditionfields: triggerfileds });

            let customfields1 = this.getDropdownField(props.settingReducer.projectCustomFieldList.data, 'action');
            actionFields[5] = customfields1;
            this.setState({ defaultActionFields: actionFields })
        }

        if (props.settingReducer.taskCustomFieldList && props.settingReducer.taskCustomFieldList.data) {
            if (this.state.TabValue == 5) {
                let customfields = this.getDropdownField(props.settingReducer.taskCustomFieldList.data);
                customfields = this.addConditionOptions(customfields);
                // this.setState({ customFieldsList: props.settingReducer.customFieldList.data })
                this.setState({ customFieldsList: props.settingReducer.taskCustomFieldList.data, conditionfields: customfields, actiontagfields: customfields }, () => {
                    if (props.match.params.id && (this.state.automation_id != props.match.params.id) && props.settingReducer.automationDetail && props.settingReducer.automationDetail.data) {
                        let automationDetail = props.settingReducer.automationDetail.data;
                        this.setEditAutomationData(automationDetail);
                    }
                });
            }

            let customfields2 = this.getDropdownField(props.settingReducer.taskCustomFieldList.data);
            customfields2 = this.addConditionOptions(customfields2);
            triggerfileds[5] = customfields2;
            this.setState({ allconditionfields: triggerfileds });

            let customfields1 = this.getDropdownField(props.settingReducer.taskCustomFieldList.data, 'action');
            actionFields[6] = customfields1;
            this.setState({ defaultActionFields: actionFields })
        }
    }

    setEditAutomationData = (automationDetail) => {



        let filterConitions = [];
        let AddAction = [];
        let updateActionmodule = [];
        let AddMoreFields = [];
        let chooseOperator = [];

        let submitFieldData = this.state.submitFieldData;
        let actionFields = this.state.actionFields;
        let conditionfields = this.state.conditionfields;
        let allconditionfields = this.state.allconditionfields;
        let defaultActionFields = this.state.defaultActionFields;
        let selectedConditionField = this.state.selectedConditionField;
        let allactionmodulesArr = this.state.allactionmodulesArr;
        let alldraftFieldData = this.state.alldraftFieldData;

        let draftFieldData = JSON.parse(automationDetail.alldraftfielddata);

        let draftsEditarr = [];


        draftFieldData && draftFieldData.map((draftdata, key) => {
            draftdata.details && draftdata.details.map((draftfieldsdata, fieldkey) => {
                if (draftfieldsdata && draftfieldsdata.names) {
                    if (draftfieldsdata.names.firstname) {
                        let keydata = draftfieldsdata.names.firstname.blocks[0].key.split("_");
                        let actionindex = parseFloat(keydata[0]);
                        let fieldindex = parseFloat(keydata[1]);
                        let fieldid = parseFloat(keydata[2]);

                        if (draftsEditarr[actionindex] == undefined)
                            draftsEditarr[actionindex] = [];

                        if (draftsEditarr[actionindex][fieldindex] == undefined)
                            draftsEditarr[actionindex][fieldindex] = [];


                        if (draftsEditarr[actionindex][fieldindex].names == undefined)
                            draftsEditarr[actionindex][fieldindex].names = {};

                        draftsEditarr[actionindex][fieldindex].names.firstname = editorStateFromRaw(draftfieldsdata.names.firstname);
                    }

                    if (draftfieldsdata.names.lastname) {
                        let keydata = draftfieldsdata.names.lastname.blocks[0].key.split("_");
                        let actionindex = parseFloat(keydata[0]);
                        let fieldindex = parseFloat(keydata[1]);
                        let fieldid = parseFloat(keydata[2]);


                        if (draftsEditarr[actionindex] == undefined)
                            draftsEditarr[actionindex] = [];

                        if (draftsEditarr[actionindex][fieldindex] == undefined)
                            draftsEditarr[actionindex][fieldindex] = [];


                        if (draftsEditarr[actionindex][fieldindex].names == undefined)
                            draftsEditarr[actionindex][fieldindex].names = {};

                        draftsEditarr[actionindex][fieldindex].names.lastname = editorStateFromRaw(draftfieldsdata.names.lastname);
                    }
                }
                if (draftfieldsdata && draftfieldsdata.fieldvalue) {
                    console.log(draftfieldsdata.fieldvalue, 'draftfieldsdata');
                    let keydata = draftfieldsdata.fieldvalue.blocks[0].key.split("_");
                    let actionindex = parseFloat(keydata[0]);
                    let fieldindex = parseFloat(keydata[1]);
                    let fieldid = parseFloat(keydata[2]);


                    if (draftsEditarr[actionindex] == undefined)
                        draftsEditarr[actionindex] = [];

                    if (draftsEditarr[actionindex][fieldindex] == undefined)
                        draftsEditarr[actionindex][fieldindex] = [];


                    if (draftsEditarr[actionindex][fieldindex].fieldvalue == undefined)
                        draftsEditarr[actionindex][fieldindex].fieldvalue = {};

                    draftsEditarr[actionindex][fieldindex].fieldvalue = editorStateFromRaw(draftfieldsdata.fieldvalue);
                }

            })
        })


        submitFieldData.alldraftFieldData = draftsEditarr;

        let TabValue = automationDetail.triggerrecordid - 1;
        let selectedEvent = automationDetail.triggertype;
        let workflowname = automationDetail.name;

        if (allconditionfields[TabValue]) {
            conditionfields = allconditionfields[TabValue];


            let selctedButton = (automationDetail.filtercondition == "AND") ? 1 : (automationDetail.filtercondition == "OR") ? 2 : "";
            let actionType = this.state.actionType;
            let actionModule = this.state.actionModule;
            let conditionOperators = this.state.conditionOperators;

            automationDetail.triggercondition.map((submitdata, index) => {
                submitFieldData.push(submitdata);
                filterConitions.push({ title: index + 1 });
                chooseOperator[index] = submitdata.conditionkey;
                
                let selectedkey = -1;
                if(submitdata.fieldid != 0){
                    selectedkey = this.findWithAttr(conditionfields, 'id', submitdata.fieldid);
                }else{
                    selectedkey = this.findWithAttr(conditionfields, 'fieldkey', submitdata.fieldkey);
                }
                
                if (selectedkey != -1) {
                    selectedConditionField[index] = conditionfields[selectedkey];
                    conditionOperators[index] = conditionfields[selectedkey].conditionOptions;
                }
            });

            automationDetail.actiondetail.map((actiondata, key) => {
                actionType[key] = actiondata.type;
                actionModule[key] = actiondata.recordid;
                actionFields[key] = defaultActionFields[actiondata.recordid];
                AddAction[key] = { title: "Action " + (key + 1) };

                updateActionmodule[key] = [];
                if (actiondata.type == 2) {
                    let moduleKey = this.findWithAttr(allactionmodulesArr, 'id', TabValue + 1);
                    if (moduleKey != -1) {
                        updateActionmodule[key].push(allactionmodulesArr[moduleKey]);
                    }
                } else {
                    allactionmodulesArr.map((modulesname) => {
                        if (actiondata.type == 1) {
                            updateActionmodule[key].push(modulesname)
                        }
                    });
                }
                AddMoreFields[key] = [];
                actiondata.details && actiondata.details.map((detail, dynamickey) => {
                    if (detail && actionFields[key] && detail.fieldid) {
                        let fieldkey = this.findWithAttr(actionFields[key], 'id', detail.fieldid);
                        if (fieldkey != -1) {
                            if (actionFields[key][fieldkey].recordcreationdefaultvalue != 2) {
                                AddMoreFields[key].push({ title: dynamickey });
                            }
                        }
                    }

                })
            });

            submitFieldData.actiondetail = automationDetail.actiondetail;
            this.setState({ automation_id: parseFloat(this.props.match.params.id), alldraftFieldData: draftsEditarr, chooseOperator, AddMoreFields, updateActionmodule, AddAction, actionFields, conditionfields, selectedConditionField, filterConitions, selctedButton, workflowname, TabValue, selectedEvent, submitFieldData, actionType, actionModule })
        }
    }

    addConditionOptions = (customfields) => {
        let conditionOptions = [];

        customfields.map((field, key) => {

            if (parseFloat(field.fieldtype.id) == 1 || parseFloat(field.fieldtype.id) == 2) {
                if (field.fieldkey == 'value') {
                    conditionOptions = [
                        { id: 1, fieldlabel: 'Equals', fieldkey: 'equals', value: "" },
                        { id: 4, fieldlabel: 'Greater', fieldkey: 'greater', value: '>' },
                        { id: 5, fieldlabel: 'Less', fieldkey: 'less', value: "<" }
                    ];
                    customfields[key].conditionOptions = conditionOptions;
                } else if (field.fieldkey == 'owner') {
                    conditionOptions = [
                        { id: 1, fieldlabel: 'Equals', fieldkey: 'equals', value: "" },
                        { id: 2, fieldlabel: 'Not Equals', fieldkey: 'not-equals', value: "NOT" },
                    ];

                    customfields[key].conditionOptions = conditionOptions;
                } else {
                    conditionOptions = [
                        { id: 1, fieldlabel: 'Equals', fieldkey: 'equals', value: "" },
                        { id: 2, fieldlabel: 'Not Equals', fieldkey: 'not-equals', value: "NOT" },
                        { id: 3, fieldlabel: 'Contains', fieldkey: 'contains', value: 'like' },
                    ];
                    customfields[key].conditionOptions = conditionOptions;
                }
            } else if (parseFloat(field.fieldtype.id) == 4 || parseFloat(field.fieldtype.id) == 5) {
                if (field.fieldkey == 'source') {
                    conditionOptions = [
                        { id: 1, fieldlabel: 'Equals', fieldkey: 'equals', value: "" },
                        { id: 2, fieldlabel: 'Not Equals', fieldkey: 'not-equals', value: "NOT" },
                    ];
                    customfields[key].conditionOptions = conditionOptions;
                } else {
                    conditionOptions = [
                        { id: 1, fieldlabel: 'Equals', fieldkey: 'equals', value: "" },
                        { id: 2, fieldlabel: 'Not Equals', fieldkey: 'not-equals', value: "NOT" },
                    ];
                    customfields[key].conditionOptions = conditionOptions;
                }
            } else if (parseFloat(field.fieldtype.id) == 6 || parseFloat(field.fieldtype.id) == 7) {
                conditionOptions = [
                    { id: 1, fieldlabel: 'Equals', fieldkey: 'equals', value: "" },
                    { id: 4, fieldlabel: 'Greater', fieldkey: 'greater', value: '>' },
                    { id: 5, fieldlabel: 'Less', fieldkey: 'less', value: "<" }
                ];

                customfields[key].conditionOptions = conditionOptions;

            } else if (parseFloat(field.fieldtype.id) == 8) {
                conditionOptions = [
                    { id: 1, fieldlabel: 'Equals', fieldkey: 'equals', value: "" },
                    { id: 7, fieldlabel: 'Before', fieldkey: 'before', value: "<" },
                    { id: 8, fieldlabel: 'After', fieldkey: 'after', value: ">" }
                ];
                customfields[key].conditionOptions = conditionOptions;
            } else {
                conditionOptions = [
                    { id: 1, fieldlabel: 'Equals', fieldkey: 'equals', value: "" },
                    { id: 2, fieldlabel: 'Not Equals', fieldkey: 'not-equals', value: "NOT" },
                    { id: 3, fieldlabel: 'Contains', fieldkey: 'contains', value: 'like' },
                ];
                customfields[key].conditionOptions = conditionOptions;
            }
        })

        let chooseOperator = this.state.chooseOperator;
        if (chooseOperator.length == 0) {
            if (this.state.selectedConditionField.length > 0 && this.state.selectedConditionField[0] && this.state.selectedConditionField[0].conditionOptions) {
                chooseOperator[0] = this.state.selectedConditionField[0].conditionOptions[0];
                this.setState({ chooseOperator });
            }
        }
        return customfields;
    }

    getDropdownField = (customFieldsList, type = null) => {

        let newCustomfields = [];
        if (customFieldsList.length > 0) {
            customFieldsList.map((field, key) => {
                if (parseFloat(field.fieldtype.id) == 1 || parseFloat(field.fieldtype.id) == 2) { // textbox // text area
                    if (field.fieldkey == "websites" || field.fieldkey == "social" || field.fieldkey == "phones" || field.fieldkey == "email" || field.fieldkey == "address" || field.fieldkey == "relatedto") {
                    } else if (field.fieldkey == "primarycontact") {
                        field.options = this.state.contactOptions;
                        newCustomfields.push(field);
                    } else if (field.fieldkey == "company") {
                        field.options = this.state.companyOptions;
                        newCustomfields.push(field);
                    } else {
                        newCustomfields.push(field);
                    }
                }

                if (parseFloat(field.fieldtype.id) == 4 || parseFloat(field.fieldtype.id) == 5) { // dropdown
                    if (field.fieldkey == "visibility") {
                    } else if (field.fieldkey == "stage" && field.id == "47") {
                        if (allPipelines.length > 0) {

                            let new_options = [];
                            field.options && field.options.map((option) => {
                                let pipelinekey = this.findWithAttr(allPipelines, 'id', option.pipline_id)
                                if (pipelinekey != -1)
                                    option.pipeline_name = allPipelines[pipelinekey].name;

                                new_options.push(option);
                            })
                            field.options = new_options;
                        }
                        newCustomfields.push(field);
                    } else {
                        if (field.fieldkey == "company") {
                            field.options = this.state.companyOptions;
                            newCustomfields.push(field);
                        } else {
                            newCustomfields.push(field);
                        }
                    }
                }

                if (parseFloat(field.fieldtype.id) == 3 || parseFloat(field.fieldtype.id) == 6 || parseFloat(field.fieldtype.id) == 7 || parseFloat(field.fieldtype.id) == 10 || parseFloat(field.fieldtype.id) == 8) { // checkbox //number //percentage //date
                    newCustomfields.push(field);
                }
            })
            if (type == null) {
                newCustomfields.push({ id: 0, fieldlabel: 'Created At', columnname: 'createddate', fieldkey: 'createddate', fieldtype: { fieldicon: "", id: "8", name: "Date", __typename: "Fieldtype" }, options: null });
                newCustomfields.push({ id: 0, fieldlabel: 'Updated At', columnname: 'lastmodifieddate', fieldkey: 'lastmodifieddate', fieldtype: { fieldicon: "", id: "8", name: "Date", __typename: "Fieldtype" }, options: null });
                newCustomfields.push({ id: 1003, fieldlabel: 'Last Contacted', columnname: 'lastcontacted', fieldkey: 'lastcontacted', fieldtype: { fieldicon: "", id: "8", name: "Date", __typename: "Fieldtype" }, options: null });
                newCustomfields.push({ id: 1004, fieldlabel: 'Interactions', columnname: 'interactions', fieldkey: 'interactions', fieldtype: { fieldicon: "", id: "6", name: "Number", __typename: "Fieldtype" }, options: null });
                newCustomfields.push({ id: 1005, fieldlabel: 'Inactive Days', columnname: 'inactivedays', fieldkey: 'inactivedays', fieldtype: { fieldicon: "", id: "6", name: "Number", __typename: "Fieldtype" }, options: null });

                if (this.state.TabValue == 0) {
                    newCustomfields.push({ id: 1001, fieldlabel: 'Status Update Date', columnname: 'statusupdatedate', fieldkey: 'statusupdatedate', fieldtype: { fieldicon: "", id: "8", name: "Date", __typename: "Fieldtype" }, options: null });
                    newCustomfields.push({ id: 1002, fieldlabel: 'Status Duration', columnname: 'statusduration', fieldkey: 'statusduration', fieldtype: { fieldicon: "", id: "6", name: "Number", __typename: "Fieldtype" }, options: null });
                }

                // let selectedConditionField = this.state.selectedConditionField;
                // if (selectedConditionField.length == 0) {
                //     selectedConditionField[0] = newCustomfields[0];
                //     this.setState({ selectedConditionField: selectedConditionField })
                // }
            }
        }

        return newCustomfields;
    }

    handleChange = event => {
        event.persist();
        if (event.target.type === 'checkbox') {
            this.setState({ [event.target.name]: event.target.checked });
        }
        else {
            this.setState({ [event.target.name]: event.target.value });
        }
    };

    handleConditionValueChange = (event, field, index) => {

        let submitFieldData = this.state.submitFieldData;
        if (submitFieldData[index] != undefined) {
            submitFieldData[index] = { fieldid: field.id, fieldkey: event.target.name, values: event.target.value };
        } else {
            submitFieldData[index] = [];
            submitFieldData[index] = { fieldid: field.id, fieldkey: event.target.name, values: event.target.value };
        }
        if (submitFieldData[index].condition == undefined && this.state.chooseOperator.length > 0) {
            submitFieldData[index].condition = this.state.chooseOperator[index].value;
            submitFieldData[index].conditionkey = this.state.chooseOperator[index];
        }

        this.setState({ submitFieldData })
    }

    handleOwnerChange = (values, fieldkey, field, index) => {
        console.log(values, 'values');
        let submitFieldData = this.state.submitFieldData;
        if (submitFieldData[index] != undefined) {
            submitFieldData[index] = { fieldid: field.id, fieldkey: fieldkey, values: values ? values.email : "", fieldvalues: values ? values : "" };
        } else {
            submitFieldData[index] = [];
            submitFieldData[index] = { fieldid: field.id, fieldkey: fieldkey, values: values ? values.email : "", fieldvalues: values ? values : "" }
        }
        if (submitFieldData[index].condition == undefined && this.state.chooseOperator.length > 0) {
            submitFieldData[index].condition = this.state.chooseOperator[index].value;
            submitFieldData[index].conditionkey = this.state.chooseOperator[index];
        }

        console.log(submitFieldData, 'submitFieldData');
        this.setState({ submitFieldData })
    }

    handleDropChange = (event, field, index) => {
        let submitFieldData = this.state.submitFieldData;
        if (submitFieldData[index] != undefined) {
            submitFieldData[index] = { fieldid: field.id, fieldkey: event.target.name, values: event.target.value };
        } else {
            submitFieldData[index] = [];
            submitFieldData[index] = { fieldid: field.id, fieldkey: event.target.name, values: event.target.value };
        }
        if (submitFieldData[index].condition == undefined && this.state.chooseOperator.length > 0) {
            submitFieldData[index].condition = this.state.chooseOperator[index].value;
            submitFieldData[index].conditionkey = this.state.chooseOperator[index];
        }

        this.setState({ submitFieldData })
    }

    handleNumberChange = (event, field, index) => {

        let submitFieldData = this.state.submitFieldData;
        if (submitFieldData[index] != undefined) {
            submitFieldData[index] = { fieldid: field.id, fieldkey: event.target.name, values: event.target.value };
        } else {
            submitFieldData[index] = [];
            submitFieldData[index] = { fieldid: field.id, fieldkey: event.target.name, values: event.target.value };
        }
        if (submitFieldData[index].condition == undefined && this.state.chooseOperator.length > 0) {
            submitFieldData[index].condition = this.state.chooseOperator[index].value;
            submitFieldData[index].conditionkey = this.state.chooseOperator[index];
        }

        this.setState({ submitFieldData })
    }

    handleDateChange = (event, field, fieldkey, index) => {

        let submitFieldData = this.state.submitFieldData;
        if (submitFieldData[index] != undefined) {
            submitFieldData[index] = { fieldid: field.id, fieldkey: fieldkey, values: moment(event).format("YYYY-MM-DD") };
        } else {
            submitFieldData[index] = [];
            submitFieldData[index] = { fieldid: field.id, fieldkey: fieldkey, values: moment(event).format("YYYY-MM-DD") };
        }
        if (submitFieldData[index].condition == undefined && this.state.chooseOperator.length > 0) {
            submitFieldData[index].condition = this.state.chooseOperator[index].value;
            submitFieldData[index].conditionkey = this.state.chooseOperator[index];
        }

        this.setState({ submitFieldData })
    }

    handleOperatorChange = (event, options, index) => {
        let optionkey = this.findWithAttr(options[index], 'fieldkey', event.target.value)
        let chooseOperator = this.state.chooseOperator;
        let submitFieldData = this.state.submitFieldData;

        if (submitFieldData[index] == undefined)
            submitFieldData[index] = [];

        submitFieldData[index].condition = options[index][optionkey].value;
        submitFieldData[index].conditionkey = options[index][optionkey];

        chooseOperator[index] = options[index][optionkey];


        this.setState({ chooseOperator, submitFieldData })
    }

    selectConditionField = (event, conditionIndex) => {
        let submitFieldData = this.state.submitFieldData;
        let conditionfields = this.state.conditionfields;

        let condkey = this.findWithAttr(conditionfields, 'fieldkey', event.target.value)
        let conditionfield = []
        if (condkey != -1)
            conditionfield = conditionfields[condkey];

        let fieldcondition = this.state.selectedConditionField;

        let operatorcondition = this.state.conditionOperators;
        fieldcondition[conditionIndex] = conditionfield;
        operatorcondition[conditionIndex] = conditionfield.conditionOptions;

        if (submitFieldData[conditionIndex])
            submitFieldData.splice(conditionIndex, 1);

        this.setState({ selectedConditionField: fieldcondition, conditionOperators: operatorcondition, submitFieldData });

    }
    //--------------------
    handleButton = (e) => {
        this.setState({ selctedButton: parseInt(e.currentTarget.dataset.id) });
    }

    handleFormSubmit = () => {

        let filtercondition = (this.state.selctedButton == 1) ? "AND" : (this.state.selctedButton == 2) ? "OR" : '';
        let submitFieldData = this.state.submitFieldData;
        let triggercondition = [];
        let saveData = [];
        submitFieldData.map((submitdata) => {
            triggercondition.push(submitdata);
        })

        let workflowname = this.state.workflowname;
        let triggerrecordid = this.state.actionType;

        let draftsdata = [];


        submitFieldData.alldraftFieldData && submitFieldData.alldraftFieldData.map((draftactiondata, actionkey) => {
            draftactiondata && draftactiondata.map((draftfielddata, actionfieldkey) => {
                if (draftsdata[actionkey] == undefined)
                    draftsdata[actionkey] = {};

                if (draftsdata[actionkey].details == undefined)
                    draftsdata[actionkey].details = [];

                if (draftsdata[actionkey].details[actionfieldkey] == undefined)
                    draftsdata[actionkey].details[actionfieldkey] = {};

                if (draftfielddata.fieldvalue) {
                    draftsdata[actionkey].details[actionfieldkey].fieldvalue = JSON.parse(editorStateToJSON(draftfielddata.fieldvalue));
                }
                if (draftfielddata.names) {
                    if (draftsdata[actionkey].details[actionfieldkey].names == undefined)
                        draftsdata[actionkey].details[actionfieldkey].names = {}

                    if (draftfielddata.names.firstname) {
                        draftsdata[actionkey].details[actionfieldkey].names.firstname = JSON.parse(editorStateToJSON(draftfielddata.names.firstname));
                    }
                    if (draftfielddata.names.lastname) {
                        draftsdata[actionkey].details[actionfieldkey].names.lastname = JSON.parse(editorStateToJSON(draftfielddata.names.lastname));
                    }
                }
            })
        });

        saveData = {
            id: this.state.automation_id,
            name: workflowname,
            triggerrecordid: this.state.TabValue + 1,
            triggertype: this.state.selectedEvent,
            triggercondition: triggercondition,
            filtercondition: filtercondition,
            actiondetail: submitFieldData.actiondetail,
            alldraftfielddata: JSON.stringify(draftsdata),
            status: true,
        };

        this.props.saveAutomation(this.props.apolloClient.client, saveData);
        this.props.history.push('/settings/workflow-automation/');
    };

    handleAddOption = () => {
        let options = this.state.filterConitions;
        let conditionOperators = this.state.conditionOperators;
        let selectedConditionField = this.state.selectedConditionField;
        let conditionfields = this.state.conditionfields;

        selectedConditionField[options.length] = conditionfields[0];

        conditionOperators[options.length] = [
            { id: 1, fieldlabel: 'Equals', fieldkey: 'equals', value: "" },
            { id: 2, fieldlabel: 'Not Equals', fieldkey: 'not-equals', value: "NOT" },
            { id: 3, fieldlabel: 'Contains', fieldkey: 'contains', value: 'like' },
        ];


        let chooseOperator = this.state.chooseOperator;
        if (chooseOperator[options.length] == undefined) {
            chooseOperator[options.length] = conditionOperators[options.length][0];
            this.setState({ chooseOperator: chooseOperator });
        }

        options.push({ title: options.length + 1 })
        this.setState({ filterConitions: options, conditionOperators: conditionOperators, selectedConditionField: selectedConditionField })
    }

    deleteOption = (index) => {
        let dataResetValue = [];
        let filterConitions = this.state.filterConitions;
        let selectedConditionField = this.state.selectedConditionField;
        let conditionOperators = this.state.conditionOperators;
        let submitFieldData = this.state.submitFieldData;

        this.state.filterConitions.splice(index, 1);
        selectedConditionField.splice(index, 1);
        submitFieldData.splice(index, 1);
        conditionOperators.splice(index, 1);

        const arr = [...Array(filterConitions.length + 1).keys()].slice(1);
        arr.map((i) => { dataResetValue.push({ title: i }) })
        this.setState({ filterConitions: dataResetValue, selectedConditionField: selectedConditionField, conditionOperators: conditionOperators, submitFieldData: submitFieldData })
    }

    handleCheck = (e) => {
        this.setState({ selectedEvent: parseInt(e.currentTarget.dataset.id) });
    }

    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] == value) {
                return i;
            }
        }
        return -1;
    }

    findWithAttrAction = (array, attr, value) => {

        for (var i = 0; i < array.length; i += 1) {
            if (array[i] && array[i][attr] == value) {
                return i;
            }
        }
        return -1;
    }

    // action change field functions 
    handleAddMoreField = (event, actionindex, requiredTotal) => {

        let morefields = this.state.AddMoreFields;
        let submitFieldData = this.state.submitFieldData;

        if (morefields[actionindex] == undefined)
            morefields[actionindex] = [];

        let newfieldid = morefields[actionindex].length;
        morefields[actionindex].push({ title: morefields[actionindex].length + 1 })

        this.setState({ AddMoreFields: morefields, submitFieldData })
    }

    deleteMoreFields = (event, actionindex, requiredTotal, indexoffield) => {
        let morefields = this.state.AddMoreFields;
        let submitFieldData = this.state.submitFieldData;

        if (submitFieldData.actiondetail && submitFieldData.actiondetail[actionindex])
            submitFieldData.actiondetail[actionindex].details.splice(indexoffield + requiredTotal, 1);

        morefields[actionindex].splice(indexoffield, 1);
        this.setState({ AddMoreFields: morefields, submitFieldData })
    }

    handleAddAction = () => {
        let addaction = this.state.AddAction;
        let actionType = this.state.actionType;
        let actionModule = this.state.actionModule;
        let submitFieldData = this.state.submitFieldData;
        let allactionmodulesArr = this.state.allactionmodulesArr;
        let updateActionmodule = this.state.updateActionmodule;
        let actionFields = this.state.actionFields;
        let defaultActionFields = this.state.defaultActionFields;

        actionFields[addaction.length] = defaultActionFields[1];

        updateActionmodule[addaction.length] = [];

        allactionmodulesArr.map((module) => {
            updateActionmodule[addaction.length].push(module);
        })

        actionType[addaction.length] = 1;
        actionModule[addaction.length] = 1;

        if (submitFieldData.actiondetail == undefined)
            submitFieldData.actiondetail = [];

        submitFieldData.actiondetail[addaction.length] = [];
        submitFieldData.actiondetail[addaction.length] = {
            type: 1,
            recordid: 1,
            details: []
        };


        addaction.push({ title: "Action " + (addaction.length + 1) })


        this.setState({ AddAction: addaction, actionType, actionModule, submitFieldData, updateActionmodule, actionFields })
    }

    deleteAction = (index) => {

        let addaction = this.state.AddAction;
        let actionType = this.state.actionType;
        let actionModule = this.state.actionModule;
        let submitFieldData = this.state.submitFieldData;
        let updateActionmodule = this.state.updateActionmodule;
        let actionFields = this.state.actionFields;

        addaction.splice(index, 1);
        actionType.splice(index, 1);
        actionModule.splice(index, 1);
        updateActionmodule.splice(index, 1);
        submitFieldData.actiondetail.splice(index, 1)
        actionFields.splice(index, 1);

        this.setState({ AddAction: addaction, actionType, actionModule, submitFieldData, actionFields })
    }

    handleClickActionToken = event => {
        this.setState({ contactTokenDropdown: event.currentTarget });
    };

    handleClosecontactToken = () => {
        this.setState({ contactTokenDropdown: null });
    };

    handleMenuItemcontactToken = (event, index) => {
        this.setState({ contactTokenDropdown: null, contactTokenSelected: index });
    };

    handleChangeTab = (event, newValue) => {

        let allactionmodulesArr = this.state.allactionmodulesArr;
        let submitFieldData = this.state.submitFieldData;
        let actionModule = this.state.actionModule;
        let actionType = this.state.actionType;
        let updateActionmodule = this.state.updateActionmodule;
        submitFieldData = [];

        this.state.AddAction.map((item, index) => {

            if (actionType[index] == 2) {

                let moduleKey = this.findWithAttr(allactionmodulesArr, 'id', newValue + 1);
                if (moduleKey != -1) {
                    updateActionmodule[index] = [];
                    updateActionmodule[index].push(allactionmodulesArr[moduleKey]);
                }
                actionModule[index] = newValue + 1;

                this.setState({ updateActionmodule: updateActionmodule });
            }
            // if(submitFieldData[index] == undefined)


            if (submitFieldData.actiondetail == undefined)
                submitFieldData.actiondetail = [];

            if (submitFieldData.actiondetail[index] == undefined)
                submitFieldData.actiondetail[index] = [];

            submitFieldData.actiondetail[index] = {
                type: actionType[index],
                recordid: actionModule[index] ? actionModule[index] : 1,
                details: []
                // detail: submitFieldData.actiondetail[index].detail ? submitFieldData.actiondetail[index].detail : []
            };

            this.setState({ submitFieldData: submitFieldData });
        });


        this.setState({ TabValue: newValue, customFieldsList: [], conditionfields: this.state.allconditionfields[newValue], actiontagfields: this.state.allconditionfields[newValue] });
        // let params_customfield = { typeId: newValue + 1 }


        let selectedConditionField = this.state.selectedConditionField;
        let chooseOperator = [];
        selectedConditionField[0] = this.state.allconditionfields[newValue][0];
        chooseOperator[0] = selectedConditionField[0].conditionOptions[0]


        this.setState({ selectedConditionField: selectedConditionField, filterConitions: [{ title: "1" }], chooseOperator: chooseOperator });
        // this.props.customFieldList(this.props.apolloClient.client, params_customfield);
    }

    handleChangeActionType = (event, index) => {
        let actionModule = this.state.actionModule;

        if (event.target.value == 2) {
            let allactionmodulesArr = this.state.allactionmodulesArr;
            let updateActionmodule = this.state.updateActionmodule;

            let moduleKey = this.findWithAttr(allactionmodulesArr, 'id', this.state.TabValue + 1);
            if (moduleKey != -1) {
                updateActionmodule[index] = [];
                updateActionmodule[index].push(allactionmodulesArr[moduleKey]);
            }
            actionModule[index] = this.state.TabValue + 1;
            this.setState({ actionModule: actionModule, updateActionmodule: updateActionmodule });
        } else {
            let allactionmodulesArr = this.state.allactionmodulesArr
            let updateActionmodule = this.state.updateActionmodule;
            updateActionmodule[index] = [];

            allactionmodulesArr.map((module) => {
                updateActionmodule[index].push(module);
            })
            this.setState({ updateActionmodule: updateActionmodule })
        }

        let actionType = this.state.actionType;
        actionType[index] = event.target.value;

        let submitFieldData = this.state.submitFieldData;
        if (submitFieldData.actiondetail == undefined)
            submitFieldData.actiondetail = [];

        if (submitFieldData.actiondetail[index] == undefined)
            submitFieldData.actiondetail[index] = [];

        submitFieldData.actiondetail[index] = {
            type: actionType[index],
            recordid: actionModule[index] ? actionModule[index] : 1,
            details: []
            // detail: submitFieldData.actiondetail[index].details ? submitFieldData.actiondetail[index].details : []
        };

        this.setState({ actionType: actionType, submitFieldData: submitFieldData });
    }

    handleChangeActionModule = (event, index) => {
        let actionType = this.state.actionType;
        let actionModule = this.state.actionModule;
        let actionFields = this.state.actionFields;
        let defaultActionFields = this.state.defaultActionFields;
        let alldraftFieldData = this.state.alldraftFieldData;

        actionFields[index] = defaultActionFields[parseFloat(event.target.value)]
        actionModule[index] = event.target.value;

        let submitFieldData = this.state.submitFieldData;
        if (submitFieldData.actiondetail == undefined)
            submitFieldData.actiondetail = [];

        if (submitFieldData.actiondetail[index] == undefined)
            submitFieldData.actiondetail[index] = [];

        submitFieldData.actiondetail[index] = {
            type: submitFieldData.actiondetail[index].type ? submitFieldData.actiondetail[index].type : 1,
            recordid: actionModule[index] ? actionModule[index] : 1,
            details: []
            // details: submitFieldData.actiondetail[index].details ? submitFieldData.actiondetail[index].details : []
        };
        alldraftFieldData[index] = [];

        this.setState({ actionModule: actionModule, submitFieldData: submitFieldData, actionFields });
        this.tokenIdGenerator(index);
    }

    selectActionField = (event, actionindex, requiredTotal, indexoffield) => {

        let submitFieldData = this.state.submitFieldData;
        let actionFields = this.state.actionFields[actionindex];
        let fieldkey = this.findWithAttr(actionFields, 'id', event.target.value);

        if (fieldkey != -1) {
            if (submitFieldData.actiondetail == undefined)
                submitFieldData.actiondetail = [];

            if (submitFieldData.actiondetail[actionindex] == undefined)
                submitFieldData.actiondetail[actionindex] = {};

            if (submitFieldData.actiondetail[actionindex].type == undefined)
                submitFieldData.actiondetail[actionindex].type = 1;

            if (submitFieldData.actiondetail[actionindex].recordid == undefined)
                submitFieldData.actiondetail[actionindex].recordid = 1;


            if (submitFieldData.actiondetail[actionindex].details == undefined)
                submitFieldData.actiondetail[actionindex].details = [];

            if (submitFieldData.actiondetail[actionindex].details[indexoffield + requiredTotal] == undefined)
                submitFieldData.actiondetail[actionindex].details[indexoffield + requiredTotal] = {};

            submitFieldData.actiondetail[actionindex].details[indexoffield + requiredTotal].fieldid = actionFields[fieldkey].id;

            this.setState({ submitFieldData })
        }
    }

    checkForDefinedFields = (index, field, fieldindex, requiredTotal) => {
        let submitFieldData = this.state.submitFieldData;

        if (submitFieldData.actiondetail == undefined)
            submitFieldData.actiondetail = [];

        if (submitFieldData.actiondetail[index] == undefined)
            submitFieldData.actiondetail[index] = {};

        if (submitFieldData.actiondetail[index].type == undefined)
            submitFieldData.actiondetail[index].type = 1;

        if (submitFieldData.actiondetail[index].recordid == undefined)
            submitFieldData.actiondetail[index].recordid = 1;

        if (submitFieldData.actiondetail[index].details == undefined)
            submitFieldData.actiondetail[index].details = [];

        if (submitFieldData.actiondetail[index].details[fieldindex + requiredTotal] == undefined)
            submitFieldData.actiondetail[index].details[fieldindex + requiredTotal] = {};

        if (submitFieldData.actiondetail[index].details[fieldindex + requiredTotal].fieldid == undefined)
            submitFieldData.actiondetail[index].details[fieldindex + requiredTotal].fieldid = field.id;

        this.setState({ submitFieldData })
    }

    // required fields change

    ActionOwnerChange = (event, value, field, index, indexoffield, requiredTotal) => {
        if (requiredTotal == undefined)
            requiredTotal = 0

        let submitFieldData = this.state.submitFieldData;
        this.checkForDefinedFields(index, field, indexoffield, requiredTotal);

        submitFieldData.actiondetail[index].details[indexoffield + requiredTotal].values = value;
        this.setState({ submitFieldData })
    }

    ActionDropChange = (event, field, index, indexoffield, requiredTotal) => {
        if (requiredTotal == undefined)
            requiredTotal = 0

        let submitFieldData = this.state.submitFieldData;
        this.checkForDefinedFields(index, field, indexoffield, requiredTotal);
        let fieldkey = this.findWithAttr(field.options, 'id', event.target.value);

        submitFieldData.actiondetail[index].details[indexoffield + requiredTotal].values = field.options[fieldkey];
        this.setState({ submitFieldData })
    }

    ActionDateChange = (event, field, index, indexoffield, requiredTotal) => {
        if (requiredTotal == undefined)
            requiredTotal = 0

        let submitFieldData = this.state.submitFieldData;
        this.checkForDefinedFields(index, field, indexoffield, requiredTotal);
        submitFieldData.actiondetail[index].details[indexoffield + requiredTotal].fieldvalue = moment(event).format("YYYY-MM-DD");
        this.setState({ submitFieldData });
    }

    actionConditionValueChange = (event, field, index, indexoffield, requiredTotal) => {
        if (requiredTotal == undefined)
            requiredTotal = 0

        let submitFieldData = this.state.submitFieldData;
        this.checkForDefinedFields(index, field, indexoffield, requiredTotal);

        if (event.target.name == "firstname" || event.target.name == "lastname") {
            if (submitFieldData.actiondetail[index].details[indexoffield + requiredTotal].names == undefined)
                submitFieldData.actiondetail[index].details[indexoffield + requiredTotal].names = {};

            submitFieldData.actiondetail[index].details[indexoffield + requiredTotal].names[event.target.name] = event.target.value;
        } else {
            if (field.fieldtype.id == 1) {
                if (field.fieldkey == 'name') {
                    submitFieldData.actiondetail[index].details[indexoffield + requiredTotal].fieldvalue = event.target.value;
                } else if (field.fieldkey == 'value' || field.fieldkey == 'winpercentage') {
                    submitFieldData.actiondetail[index].details[indexoffield + requiredTotal].fieldvalue = parseFloat(event.target.value);
                }
            }
            else if (field.fieldtype.id == 6 || field.fieldtype.id == 7 || field.fieldtype.id == 10) {
                submitFieldData.actiondetail[index].details[indexoffield + requiredTotal].fieldvalue = parseFloat(event.target.value);
            }
            else {
                submitFieldData.actiondetail[index].details[indexoffield + requiredTotal].fieldvalue = event.target.value;
            }
        }

        this.setState({ submitFieldData });
    }

    ActionTokenChange = (event, value, field, index, indexoffield) => {
        let submitFieldData = this.state.submitFieldData;
        let actiontagfields = this.state.actiontagfields;
        this.checkForDefinedFields(index, field, indexoffield, 0);

        submitFieldData.actiondetail[index].details[indexoffield].token = [];
        submitFieldData.actiondetail[index].details[indexoffield].tokentag = value;

        value && value.map((key) => {
            let fieldkey = this.findWithAttr(actiontagfields, 'fieldkey', key);

            if (fieldkey != -1)
                submitFieldData.actiondetail[index].details[indexoffield].token.push({ fieldid: actiontagfields[fieldkey].id, istoken: 1 });
        });
    }

    // dynamic fields change
    actionDynamicValueChange = (event, field, index, indexoffield, requiredTotal) => {
        let submitFieldData = this.state.submitFieldData;
        this.checkForDefinedFields(index, field, indexoffield, requiredTotal);

        submitFieldData.actiondetail[index].details[indexoffield + requiredTotal].fieldvalue = event.target.value;

        this.setState({ submitFieldData })
    }

    actionDynamicNumberChange = (event, field, index, indexoffield, requiredTotal) => {
        let submitFieldData = this.state.submitFieldData;
        this.checkForDefinedFields(index, field, indexoffield, requiredTotal);

        submitFieldData.actiondetail[index].details[indexoffield + requiredTotal].fieldvalue = parseFloat(event.target.value);

        this.setState({ submitFieldData })
    }

    ActionDynamicOwnerChange = (event, value, field, index, indexoffield, requiredTotal) => {
        let submitFieldData = this.state.submitFieldData;
        this.checkForDefinedFields(index, field, indexoffield, requiredTotal);

        submitFieldData.actiondetail[index].details[indexoffield + requiredTotal].values = value;
        this.setState({ submitFieldData })
    }

    ActionDynamicDropChange = (event, field, index, indexoffield, requiredTotal) => {
        let submitFieldData = this.state.submitFieldData;
        this.checkForDefinedFields(index, field, indexoffield, requiredTotal);
        let fieldkey = this.findWithAttr(field.options, 'id', event.target.value);

        submitFieldData.actiondetail[index].details[indexoffield + requiredTotal].values = field.options[fieldkey];
        this.setState({ submitFieldData })
    }

    ActionDynamicDateChange = (event, field, index, indexoffield, requiredTotal) => {
        let submitFieldData = this.state.submitFieldData;
        this.checkForDefinedFields(index, field, indexoffield, requiredTotal);
        submitFieldData.actiondetail[index].details[indexoffield + requiredTotal].fieldvalue = moment(event).format("YYYY-MM-DD");
        this.setState({ submitFieldData });
    }

    ActionDynamicTokenChange = (event, value, field, index, indexoffield, requiredTotal) => {
        let submitFieldData = this.state.submitFieldData;
        let actiontagfields = this.state.actiontagfields;
        this.checkForDefinedFields(index, field, indexoffield, requiredTotal);

        submitFieldData.actiondetail[index].details[indexoffield + requiredTotal].token = [];
        submitFieldData.actiondetail[index].details[indexoffield + requiredTotal].tokentag = value;

        value && value.map((key) => {
            let fieldkey = this.findWithAttr(actiontagfields, 'fieldkey', key);

            if (fieldkey != -1)
                submitFieldData.actiondetail[index].details[indexoffield + requiredTotal].token.push({ fieldid: actiontagfields[fieldkey].id, istoken: 1 });
        });
    }

    toggleCancelWorkflowDialog = () => {
        this.setState({ IscancelWorkflowAutomation: !this.state.IscancelWorkflowAutomation });
    };

    confirmCancelWorkflow = () => {
        this.props.history.push('/settings/workflow-automation/');
    };

    handleStatusChange = (event, id) => {
        // console.log(event.target.checked,'event.target.checked');
        let updateStatusParams = { id: parseFloat(id), status: event.target.checked };
        this.props.updateAutomationStatus(this.props.apolloClient.client, updateStatusParams);
    }

    render() {

        const { classes } = this.props;
        let { IscancelWorkflowAutomation, actiontagfields, defaultActionFields, actionFields, allactionmodulesArr, updateActionmodule, submitFieldData, selectedConditionField, conditionOperators, conditionfields, customFieldsList, name, TabValue, selectedEvent, selctedButton, chooseField, search, chooseOperator, selectOptions, actionType, actionModule, actionName, selectPipeline, selectPerson, otherOptions, contactTokenDropdown, contactTokenSelected, Tokenoptions } = this.state;
        let conditions = [];
        let conditionValues = [];
        let actionTypes = [];
        let thisdata = this;
        // console.log(submitFieldData, chooseOperator, selectedConditionField, conditionfields, 'submitFieldData');

        this.state.filterConitions.map((item, index) => {
            let field = [];

            if (selectedConditionField && selectedConditionField[index] && selectedConditionField[index].fieldtype) {
                field = selectedConditionField[index];
            } else {
                field = conditionfields[0];
            }

            if (field != undefined) {
                let indexOfCondition = index;
                // let selectedfieldkey = this.findWithAttr(conditionfields, 'id', field.id);

                if (conditionValues[indexOfCondition] == undefined) {

                    conditionValues[indexOfCondition] = [];
                    if (parseFloat(field.fieldtype.id) == 1 || parseFloat(field.fieldtype.id) == 2) {
                        if (field.fieldkey == 'owner') {
                            let fieldtemp = field;
                            let fieldVal = -1;
                            if (submitFieldData[field.fieldkey] && submitFieldData[field.fieldkey].length > 0) {
                                fieldVal = submitFieldData[field.fieldkey][index]
                            }
                            var nameOwner = [];
                            if (submitFieldData[indexOfCondition]) {
                                nameOwner = submitFieldData[indexOfCondition].fieldvalues
                            }
                            // console.log("nameOwner", nameOwner)

                            conditionValues[indexOfCondition].push(

                                <Grid item xs={12}>
                                    <FormControl className="w-100 input_bx">
                                        <Autocomplete
                                            disableOpenOnFocus
                                            forcePopupIcon={false}
                                            name={fieldtemp.fieldkey}
                                            options={this.state.usersListArray}
                                            getOptionLabel={option => option.name}
                                            value={nameOwner}
                                            name={fieldtemp.fieldkey}
                                            onChange={(event, value, field, index) => this.handleOwnerChange(value, fieldtemp.fieldkey, fieldtemp, indexOfCondition)}
                                            id={field.id}
                                            renderInput={params => (
                                                <TextValidator
                                                    {...params}
                                                    variant="standard"
                                                    label={"Owner"}
                                                    fullWidth
                                                    ref={ref => field = ref}
                                                    name={fieldtemp.fieldkey}
                                                    value={nameOwner ? nameOwner.email ? nameOwner.email : "" : []}
                                                    required={true}
                                                    validators={["required"]}
                                                    errorMessages={["This field is required"]}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            )
                        } else if (field.fieldkey == "primarycontact" && field.options && field.options.length > 0) {
                            let fieldtemp = field;

                            conditionValues[indexOfCondition].push(

                                <Grid item xs={12}>
                                    <FormControl className="w-100 input_bx">
                                        <SelectValidator
                                            ref={ref => field = ref}
                                            label={field.title}
                                            value={(submitFieldData && submitFieldData[indexOfCondition]) ? submitFieldData[indexOfCondition].values : ''}
                                            onChange={(event, field, index) => this.handleDropChange(event, fieldtemp, indexOfCondition)}
                                            name={field.fieldkey}
                                            id={field.id}
                                            required={true}
                                            validators={["required"]}
                                            errorMessages={["This field is required"]}
                                        >

                                            {field.options && field.options.map((item, key) =>
                                                <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item.name}>{item.name}</MenuItem>
                                            )}

                                        </SelectValidator>
                                    </FormControl>
                                </Grid>
                            )
                        } else if (field.fieldkey == "company" && this.state.TabValue == 3 && field.options && field.options.length > 0) {
                            let fieldtemp = field;

                            conditionValues[indexOfCondition].push(

                                <Grid item xs={12}>
                                    <FormControl className="w-100 input_bx">
                                        <SelectValidator
                                            ref={ref => field = ref}
                                            label={field.title}
                                            value={(submitFieldData && submitFieldData[indexOfCondition]) ? submitFieldData[indexOfCondition].values : ''}
                                            onChange={(event, field, index) => this.handleDropChange(event, fieldtemp, indexOfCondition)}
                                            name={field.fieldkey}
                                            id={field.id}
                                            required={true}
                                            validators={["required"]}
                                            errorMessages={["This field is required"]}
                                        >
                                            {field.options && field.options.map((item, key) =>
                                                <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item.name}>{item.name}</MenuItem>
                                            )}
                                        </SelectValidator>
                                    </FormControl>
                                </Grid>
                            )
                        } else if (field.fieldkey == 'value' || field.fieldkey == 'winpercentage') { // value // winpercentage

                            let fieldtemp = field;

                            conditionValues[indexOfCondition].push(
                                <Grid item xs={12}>
                                    <TextValidator
                                        ref={ref => field = ref}
                                        type="number"
                                        required={true}
                                        id={field.id}
                                        className="w-100 input_bx"
                                        label={field.fieldlabel}
                                        onChange={(event, field, index) => this.handleNumberChange(event, fieldtemp, indexOfCondition)}
                                        name={field.fieldkey}
                                        value={(submitFieldData && submitFieldData[indexOfCondition]) ? submitFieldData[indexOfCondition].values : ''}
                                        validators={["required"]}
                                        errorMessages={["This field is required"]}
                                    />
                                </Grid>
                            )
                        } else {
                            let fieldtemp = field;

                            conditionValues[indexOfCondition].push(
                                <Grid item xs={12}>
                                    <TextValidator
                                        ref={ref => field = ref}
                                        type="text"
                                        id={field.id}
                                        placeholder={"Add " + field.fieldkey.charAt(0).toUpperCase() + field.fieldkey.slice(1)}
                                        className="w-100 input_bx"
                                        onChange={(event, field, index) => this.handleConditionValueChange(event, fieldtemp, indexOfCondition)}
                                        name={field.fieldkey}
                                        value={(submitFieldData && submitFieldData[indexOfCondition]) ? submitFieldData[indexOfCondition].values : ''}
                                        validators={["required"]}
                                        errorMessages={["This field is required"]}
                                    />
                                </Grid>
                            )
                        }

                    } else if (parseFloat(field.fieldtype.id) == 8) { // Date
                        let fieldtemp = field;
                        var dtFormat = "MM/dd/yyyy";
                        let fieldVal = -1;
                        var selectedDate = null;
                        if (submitFieldData && submitFieldData[index]) {
                            selectedDate = submitFieldData[index].values;
                        }

                        if (this.state.userSettings && this.state.userSettings.dateformat) {
                            dtFormat = this.state.userSettings.dateformat;
                            dtFormat = dtFormat.replace("DD", "dd").replace("YYYY", "yyyy");
                        }
                        conditionValues[indexOfCondition].push(
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <FormControl className="w-100 input_bx">
                                    <KeyboardDatePicker
                                        label={field.fieldlabel}
                                        format={dtFormat}
                                        required={true}
                                        placeholder={dtFormat}
                                        error={(selectedDate == null || selectedDate == "Invalid Date") ? true : false}
                                        value={selectedDate}
                                        onChange={(event, field, fieldkey, index) => this.handleDateChange(event == "Invalid Date" ? "Invalid Date" : event, fieldtemp, fieldtemp.fieldkey, indexOfCondition)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        helperText={selectedDate == null ? "This field is required" : (selectedDate == "Invalid Date") ? "Please enter a valid date." : ""}
                                    />
                                </FormControl>
                            </MuiPickersUtilsProvider>
                        )
                    } else if (parseFloat(field.fieldtype.id) == 4 || parseFloat(field.fieldtype.id) == 5) { // drop-down
                        if (field.fieldkey == 'company' || field.fieldkey == 'status' || field.fieldkey == 'source' || field.fieldkey == 'contacttype' || field.fieldkey == 'pipeline' || field.fieldkey == 'stage' || field.fieldkey == 'priority' || field.fieldkey == 'lossreason' || field.fieldkey == 'activitytype') {
                            let fieldtemp = field;

                            conditionValues[indexOfCondition].push(

                                <Grid item xs={12}>
                                    <FormControl className="w-100 input_bx">
                                        <SelectValidator
                                            ref={ref => field = ref}
                                            label={field.title}
                                            value={(submitFieldData && submitFieldData[indexOfCondition]) ? submitFieldData[indexOfCondition].values : ''}
                                            onChange={(event, field, index) => this.handleDropChange(event, fieldtemp, indexOfCondition)}
                                            name={field.fieldkey}
                                            id={field.id}
                                            required={true}
                                            validators={["required"]}
                                            errorMessages={["This field is required"]}
                                        >

                                            {field.options && field.options.map((item, key) =>
                                                <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item.name}>{item.name}</MenuItem>
                                            )}

                                        </SelectValidator>
                                    </FormControl>
                                </Grid>
                            )
                        } else {
                            let fieldtemp = field;

                            conditionValues[indexOfCondition].push(
                                <Grid item xs={12}>
                                    <FormControl className="w-100 input_bx">
                                        <SelectValidator
                                            ref={ref => field = ref}
                                            label={field.title}
                                            value={(submitFieldData && submitFieldData[indexOfCondition]) ? submitFieldData[indexOfCondition].values : ''}
                                            onChange={(event, field, index) => this.handleDropChange(event, fieldtemp, indexOfCondition)}
                                            name={field.fieldkey}
                                            id={field.id}
                                            required={true}
                                            validators={["required"]}
                                            errorMessages={["This field is required"]}
                                        >

                                            {field.options && field.options.map((item, key) =>
                                                <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item.optionvalue}>{item.optionvalue}</MenuItem>
                                            )}

                                        </SelectValidator>
                                    </FormControl>
                                </Grid>
                            )
                        }

                    } else if (parseFloat(field.fieldtype.id) == 6 || parseFloat(field.fieldtype.id) == 7) { // number

                        let fieldtemp = field;

                        conditionValues[indexOfCondition].push(

                            <Grid item xs={12}>
                                <TextValidator
                                    ref={ref => field = ref}
                                    type="number"
                                    required={true}
                                    id={field.id}
                                    className="w-100 input_bx"
                                    label={field.fieldlabel}
                                    onChange={(event, field, index) => this.handleNumberChange(event, fieldtemp, indexOfCondition)}
                                    name={field.fieldkey}
                                    value={(submitFieldData && submitFieldData[indexOfCondition]) ? submitFieldData[indexOfCondition].values : ''}
                                    validators={["required"]}
                                    errorMessages={["This field is required"]}
                                />
                            </Grid>
                        )
                    }
                }

                let fieldtemp = field;
                conditions.push(
                    <li key={index}>
                        <span className="filter_index">{item.title}</span>
                        <div className="flex-fixed">
                            {/* <SearchDropdown data={this} dropdownData={conditionfields} defaultValue={selectedfieldkey} dropdownType={'conditionfields'} conditionIndex={index} /> */}
                            <FormControl className="w-100 input_bx pr-16">
                                <Select
                                    name="selectedConditionField"
                                    value={(selectedConditionField[index]) ? selectedConditionField[index].fieldkey : "-"}
                                    onChange={(event, index) => this.selectConditionField(event, indexOfCondition)}
                                    MenuProps={{ className: 'choose_field_menu' }}
                                    className="crm-worflow-condition-comparator"
                                >
                                    <MenuItem className="select-menu-item" value={"-"}>-Select Field-</MenuItem>
                                    {(conditionfields && conditionfields) && conditionfields.map((option) => (
                                        <MenuItem className="select-menu-item" value={option.fieldkey}>{option.fieldlabel}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="flex-fixed">
                            <FormControl className="w-100 input_bx pr-16">
                                <Select
                                    name="chooseOperator"
                                    value={(chooseOperator[index]) ? chooseOperator[index].fieldkey : "equals"}
                                    onChange={(event, options, index) => this.handleOperatorChange(event, conditionOperators, indexOfCondition)}
                                    MenuProps={{ className: 'choose_field_menu' }}
                                    className="crm-worflow-condition-comparator"
                                >
                                    {(conditionOperators && conditionOperators[index] && conditionOperators[index].length > 0) && conditionOperators[index].map((option) => (
                                        <MenuItem className="select-menu-item" value={option.fieldkey}>{option.fieldlabel}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="flex-fixed crm-worflow-condition-valuefield">
                            {conditionValues[index]}
                        </div>
                        {this.state.filterConitions.length > 1 &&
                            <IconButton size="small" onClick={() => this.deleteOption(index)}>
                                <Icon>delete</Icon>
                            </IconButton>
                        }
                    </li>
                )
            }
        });

        {
            this.state.AddAction.map((item, index) => {

                let actionindex = index;
                let actionrequiredFields = [];
                actionrequiredFields[actionindex] = [];

                var otherfieldsArray = [];
                let defaultotherfields = [];
                otherfieldsArray[actionindex] = [];
                // otherfieldsArray[actionindex]['selected'] = [];
                let selectedFields = [];
                selectedFields[actionindex] = [];

                this.state.actionFields[actionindex] && this.state.actionFields[actionindex].map((field, i) => {
                    if (field.recordcreationdefaultvalue && field.recordcreationdefaultvalue == 2) {
                        actionrequiredFields[actionindex].push(field);
                    }
                    else {
                        if (submitFieldData.actiondetail && submitFieldData.actiondetail[actionindex] && submitFieldData.actiondetail[actionindex].details) {
                            let fieldkey = this.findWithAttrAction(submitFieldData.actiondetail[actionindex].details, 'fieldid', field.id);
                            if (fieldkey == -1) {
                                defaultotherfields.push(field);
                            } else {
                                selectedFields[actionindex][fieldkey] = field;
                            }
                        }
                        otherfieldsArray[actionindex].push(field);
                    }
                });

                // {(this.state.AddMoreFields && this.state.AddMoreFields[actionindex]) && this.state.AddMoreFields[actionindex].map((item, morefieldindex)=>{
                //     if(selectedFields[actionindex] && selectedFields[actionindex][morefieldindex+actionrequiredFields.length]){
                //         otherfieldsArray[actionindex][morefieldindex+actionrequiredFields.length] = defaultotherfields;
                //         let key = otherfieldsArray[actionindex][morefieldindex+actionrequiredFields.length].push(selectedFields[actionindex][morefieldindex+actionrequiredFields.length]);

                //         defaultotherfields.splice(key, 1);

                //     }else{
                //         otherfieldsArray[actionindex][morefieldindex+actionrequiredFields.length] = defaultotherfields;
                //     }
                // })}

                // this.state.actionFields[actionindex] && this.state.actionFields[actionindex].map((field, i) => {
                //     if(submitFieldData.actiondetail && submitFieldData.actiondetail[actionindex] && submitFieldData.actiondetail[actionindex].details){
                //       let selectedFieldsid = submitFieldData.actiondetail[actionindex].details;

                //       selectedFieldsid.map((selected, fieldindex)=>{
                //         if(selected.fieldid != undefined && selected.fieldid ==  field.id){
                //             if(otherfieldsArray[fieldindex] == undefined)
                //                 otherfieldsArray[fieldindex] = [];
                //             otherfieldsArray[fieldindex].push(field);
                //         }
                //       });
                //     }
                // });

                let addmoreFieldButton = <Button size="small" color="primary" className="font-weight-semi mt-16 ml--10" onClick={(event, index, requiredTotal) => this.handleAddMoreField(event, actionindex, actionrequiredFields.length)}><Icon>add</Icon> Add More fields</Button>;
                actionTypes.push(
                    <Grid item sm={6} xs={12} key={index}>
                        <div className="box_portlet mb-16">
                            <div className="box_header">
                                <div className="box_label"><h3 className="box_title">{item.title}</h3></div>
                                {this.state.AddAction.length > 1 &&
                                    <IconButton size="small" onClick={() => this.deleteAction(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                }
                            </div>
                            <div className="box_body">
                                <Grid container spacing={2}>
                                    <Grid item xs={11}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <Select
                                                    name="actionType"
                                                    value={(submitFieldData.actiondetail && submitFieldData.actiondetail[actionindex]) ?
                                                        submitFieldData.actiondetail[actionindex].type
                                                        :
                                                        1
                                                    }
                                                    className="w-100"
                                                    onChange={(event, index) => this.handleChangeActionType(event, actionindex)}
                                                >
                                                    <MenuItem className="select-menu-item" value={1}>Create</MenuItem>
                                                    <MenuItem className="select-menu-item" value={2}>Update</MenuItem>
                                                </Select>
                                            </Grid>
                                            {
                                                <Grid item xs={8}>
                                                    <Select
                                                        name="actionModule"
                                                        value={(submitFieldData.actiondetail && submitFieldData.actiondetail[actionindex]) ?
                                                            submitFieldData.actiondetail[actionindex].recordid
                                                            :
                                                            1
                                                        }
                                                        className="w-100"
                                                        onChange={(event, index) => this.handleChangeActionModule(event, actionindex)}
                                                    >
                                                        {(this.state.updateActionmodule && this.state.updateActionmodule[actionindex]) && this.state.updateActionmodule[actionindex].map((module) => (
                                                            <MenuItem className="select-menu-item" value={module.id}>{module.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </Grid>
                                            }

                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} alignItems="center" className="mt-20">
                                    {/* {(actionType[index] == 1) && WorkflowRequiredFields(thisdata, actionindex, actionrequiredFields[actionindex], actiontagfields)} */}
                                    {WorkflowRequiredFields(thisdata, actionindex, actionrequiredFields[actionindex], actiontagfields)}
                                </Grid>

                                {(this.state.AddMoreFields && this.state.AddMoreFields[actionindex]) && this.state.AddMoreFields[actionindex].map((item, morefieldindex) => (
                                    WorkflowDynamicFields(thisdata, morefieldindex, actionindex, otherfieldsArray, actionrequiredFields[actionindex], selectedFields, actiontagfields)
                                ))}
                                {addmoreFieldButton}
                            </div>
                        </div>
                    </Grid>
                );
            })
        }

        return (
            <React.Fragment>
                <SettingSidebar />
                <div className="lead_content right-16 settings_view">
                    <Card elevation={3} className="h-100">
                        <div className="d-flex justify-content-between m-16">
                            <h3>Setup your workflow</h3>
                        </div>
                        <div className="pl-16 pr-16 v_scroll">
                            <ValidatorForm ref="form" instantValidate={false} noValidate onSubmit={this.handleFormSubmit}>
                                <FormControl className="w-100 input_bx mb-16">
                                    {/* <label className="text-lable">Name</label> */}
                                    <TextValidator
                                        className="w-100 input_bx"
                                        placeholder="Add Name"
                                        onChange={this.handleChange}
                                        type="text"
                                        name="workflowname"
                                        required={true}
                                        label={'Name'}
                                        value={this.state.workflowname}
                                        validators={["required"]}
                                        errorMessages={["This field is required"]}
                                        InputLabelProps={{ shrink: true, disableAnimation: false, focused: true }}
                                    />
                                </FormControl>
                                <div className="box_portlet mb-16">
                                    <div className="box_header">
                                        <div className="box_label"><h3 className="box_title">Trigger</h3></div>
                                    </div>
                                    <div className="box_body p-0">
                                        <Grid container className="select_trigger">
                                            <Grid item className="trigger_modules"><label className="text-lable">Select Modules</label></Grid>
                                            <Grid item className="trigger_event flex-auto"><label className="text-lable">Select event</label></Grid>
                                        </Grid>
                                        <div className="d-flex workflow_trigger">
                                            <Tabs
                                                orientation="vertical"
                                                variant="scrollable"
                                                value={TabValue}
                                                onChange={this.handleChangeTab}
                                                aria-label="Vertical tabs"
                                                classes={{ indicator: classes.bigIndicator }}
                                            >
                                                <Tab className="trigger_button" label={<React.Fragment><Icon>supervised_user_circle</Icon> Lead <Icon className="tabicon_active">check</Icon></React.Fragment>} {...a11yProps(0)} />
                                                <Tab className="trigger_button" label={<React.Fragment><Icon>person</Icon> Contact <Icon className="tabicon_active">check</Icon></React.Fragment>}{...a11yProps(1)} />
                                                <Tab className="trigger_button" label={<React.Fragment><Icon>business</Icon> Company <Icon className="tabicon_active">check</Icon></React.Fragment>} {...a11yProps(2)} />
                                                <Tab className="trigger_button" label={<React.Fragment><Icon>attach_money</Icon> Opportunity <Icon className="tabicon_active">check</Icon></React.Fragment>} {...a11yProps(3)} />
                                                <Tab className="trigger_button" label={<React.Fragment><Icon>business_center</Icon> Project <Icon className="tabicon_active">check</Icon></React.Fragment>} {...a11yProps(4)} />
                                                <Tab className="trigger_button" label={<React.Fragment><Icon>list</Icon> Task <Icon className="tabicon_active">check</Icon></React.Fragment>} {...a11yProps(5)} />
                                            </Tabs>
                                            <TabPanel value={TabValue} className="trigger_panel flex-auto" index={0}>
                                                <ul>
                                                    <li onClick={this.handleCheck} data-id="1" className={selectedEvent === 1 ? 'trigger_active' : ""}>
                                                        <h6>When lead created</h6>
                                                        <p>IDrive CRM looks for when a Lead is created</p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="2" className={selectedEvent === 2 ? 'trigger_active' : ""}>
                                                        <h6>Any time a lead Updated or Created</h6>
                                                        <p>IDrive CRM looks for any changes on a Lead</p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="3" className={selectedEvent === 3 ? 'trigger_active' : ""}>
                                                        <h6>When a specific field is updated on a Lead</h6>
                                                        <p>IDrive CRM looks for changes to fields used in the trigger</p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="4" className={selectedEvent === 4 ? 'trigger_active' : ""}>
                                                        <h6>Check Once a day</h6>
                                                        <p>IDrive CRM looks for Leads that match the trigger conditions</p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                </ul>
                                            </TabPanel>
                                            <TabPanel value={TabValue} index={1} className="trigger_panel flex-auto">
                                                <ul>
                                                    <li onClick={this.handleCheck} data-id="1" className={selectedEvent === 1 ? 'trigger_active' : ""}>
                                                        <h6>When contact created</h6>
                                                        <p>IDrive CRM looks for when a contact is created</p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="2" className={selectedEvent === 2 ? 'trigger_active' : ""}>
                                                        <h6>Any time a contact Updated or Created</h6>
                                                        <p>IDrive CRM looks for any changes on a contact</p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="3" className={selectedEvent === 3 ? 'trigger_active' : ""}>
                                                        <h6>When a specific field is updated on a contact</h6>
                                                        <p>IDrive CRM looks for changes to fields used in the trigger</p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="4" className={selectedEvent === 4 ? 'trigger_active' : ""}>
                                                        <h6>Check Once a day</h6>
                                                        <p>IDrive CRM looks for contacts that match the trigger conditions</p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                </ul>
                                            </TabPanel>
                                            <TabPanel value={TabValue} index={2} className="trigger_panel flex-auto">
                                                <ul>
                                                    <li onClick={this.handleCheck} data-id="1" className={selectedEvent === 1 ? 'trigger_active' : ""}>
                                                        <h6>When company created</h6>
                                                        <p>IDrive CRM looks for when a contact is created</p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="2" className={selectedEvent === 2 ? 'trigger_active' : ""}>
                                                        <h6>Any time a company updated</h6>
                                                        <p>IDrive CRM looks for any changes on a company</p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="3" className={selectedEvent === 3 ? 'trigger_active' : ""}>
                                                        <h6>When a specific field is updated on a company</h6>
                                                        <p>IDrive CRM looks for changes to fields used in the trigger</p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="4" className={selectedEvent === 4 ? 'trigger_active' : ""}>
                                                        <h6>Check Once a day</h6>
                                                        <p>IDrive CRM looks for companies that match the trigger conditions</p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                </ul>
                                            </TabPanel>
                                            <TabPanel value={TabValue} index={3} className="trigger_panel flex-auto">
                                                <ul>
                                                    <li onClick={this.handleCheck} data-id="1" className={selectedEvent === 1 ? 'trigger_active' : ""}>
                                                        <h6>When opportunity created</h6>
                                                        <p>IDrive CRM looks for when a opportunity is created</p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="2" className={selectedEvent === 2 ? 'trigger_active' : ""}>
                                                        <h6>Any time a opportunity updated</h6>
                                                        <p>IDrive CRM looks for any changes on a opportunity</p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="3" className={selectedEvent === 3 ? 'trigger_active' : ""}>
                                                        <h6>When a specific field is updated on a opportunity</h6>
                                                        <p>IDrive CRM looks for changes to fields used in the trigger</p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="4" className={selectedEvent === 4 ? 'trigger_active' : ""}>
                                                        <h6>Check Once a day</h6>
                                                        <p>IDrive CRM looks for opportunities that match the trigger conditions</p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                </ul>
                                            </TabPanel>
                                            <TabPanel value={TabValue} index={4} className="trigger_panel flex-auto">
                                                <ul>
                                                    <li onClick={this.handleCheck} data-id="1" className={selectedEvent === 1 ? 'trigger_active' : ""}>
                                                        <h6>When project created</h6>
                                                        <p>IDrive CRM looks for when a project is created</p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="2" className={selectedEvent === 2 ? 'trigger_active' : ""}>
                                                        <h6>Any time a project updated</h6>
                                                        <p>IDrive CRM looks for any changes on a project</p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="3" className={selectedEvent === 3 ? 'trigger_active' : ""}>
                                                        <h6>When a specific field is updated on a project</h6>
                                                        <p>IDrive CRM looks for changes to fields used in the trigger</p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                </ul>
                                            </TabPanel>
                                            <TabPanel value={TabValue} index={5} className="trigger_panel flex-auto">
                                                <ul>
                                                    <li onClick={this.handleCheck} data-id="1" className={selectedEvent === 1 ? 'trigger_active' : ""}>
                                                        <h6>When task created</h6>
                                                        <p>IDrive CRM looks for when a task is created</p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="2" className={selectedEvent === 2 ? 'trigger_active' : ""}>
                                                        <h6>Any time a task updated</h6>
                                                        <p>IDrive CRM looks for any changes on a task</p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                    <li onClick={this.handleCheck} data-id="3" className={selectedEvent === 3 ? 'trigger_active' : ""}>
                                                        <h6>When a specific field is updated on a task</h6>
                                                        <p>IDrive CRM looks for changes to fields used in the trigger</p>
                                                        <Icon className="panelicon_active">check</Icon>
                                                    </li>
                                                </ul>
                                            </TabPanel>
                                        </div>
                                    </div>
                                </div>
                                <div className="box_portlet mb-16">
                                    <div className="box_header">
                                        <div className="box_label"><h3 className="box_title">Add filter conditions for trigger</h3></div>
                                    </div>
                                    <div className="box_body">
                                        <ul className="filter_conitions">
                                            {conditions}
                                        </ul>
                                        <Button size="small" variant="contained" color="primary" onClick={this.handleAddOption}><Icon>add</Icon> Add new conditions</Button>
                                        <div className="d-flex justify-content-center align-items-center filter_conditions">
                                            <label className="conditions-lable pr-16">Select filter conditions</label>
                                            <ButtonGroup size="small" aria-label="small outlined button group">
                                                <Button onClick={this.handleButton} data-id="1" className={selctedButton === 1 && 'button_active'}>AND</Button>
                                                <Button onClick={this.handleButton} data-id="2" className={selctedButton === 2 && 'button_active'}>OR</Button>
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                </div>
                                <Grid container spacing={2}>
                                    {actionTypes}
                                </Grid>
                                <Button size="small" variant="contained" color="primary" onClick={this.handleAddAction}><Icon>add</Icon> Add action</Button>
                                <div className="d-flex justify-content-end mt-16 mb-40">
                                    <Grid container spacing={2}>
                                        <Grid item md={10}>
                                            {/* <Switch
                                                // checked={automation.isactive}
                                                name="workflowCheckbox"
                                                onChange={(event, id)=>this.handleStatusChange(event, this.stateautomationid)}
                                                value="workflowCheckbox"
                                                color="primary"
                                            />
                                            Workflow   */}
                                        </Grid>
                                        <Grid item md={2}>
                                            <Button variant="contained" className="mr-8" onClick={this.toggleCancelWorkflowDialog}>Cancel</Button>
                                            <Button variant="contained" type="submit" color="primary">Save</Button>
                                        </Grid>
                                    </Grid>

                                </div>
                            </ValidatorForm>
                        </div>
                    </Card>
                </div>
                <Dialog
                    fullWidth={true}
                    maxWidth={'xs'}
                    open={IscancelWorkflowAutomation}
                    onClose={this.toggleCancelWorkflowDialog}
                    disableBackdropClick
                    // disableEscapeKeyDown
                    aria-labelledby="ar-remove-dialog-title"
                >
                    <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to leave this Workflow Automation rule?"}</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.toggleCancelWorkflowDialog} color="primary">
                            Cancel
                        </Button>
                        <Button type="Button" onClick={() => this.confirmCancelWorkflow()} className="btn_Primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>

            </React.Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {
        settingReducer: state.settingReducer,
        apolloClient: state.apolloClient
    };
};

const mapDispatchToProps = dispatch => {
    return {
        customFieldList: (client, request) => dispatch(actions.customFieldList(client, request)),
        saveAutomation: (client, request) => dispatch(actions.saveAutomation(client, request)),
        getAutomationDetail: (client, request) => dispatch(actions.getAutomationDetail(client, request)),
        updateAutomationStatus: (client, request) => dispatch(actions.updateAutomationStatus(client, request)),
        getCompanyRecList: (client) => dispatch(actions.getCompanyRecList(client)),

        getUserList: (client) => dispatch(actions.getUserList(client)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withStyles(styles)(AddWorkAutomation));
// export default ;
