import React, { Component } from "react";
import { Dialog, List, ListItem, DialogTitle, DialogActions, IconButton, Icon, Menu, withStyles, Grid, Fab, Select, MenuItem, TextField, Card, InputLabel, Button, Checkbox, Tooltip } from "@material-ui/core";
import * as actions from '../../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import InboxCompose from "../CommonComposeMail/InboxCompose";
import InboxComposeDialog from "../CommonComposeMail/InboxComposeDialog";
import { Link } from "react-router-dom";
import ViewHtmlDialog from "../CommonLogUI/ViewHtmlDialog";
import FontAwesome from "react-fontawesome";
import _ from 'lodash'
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import SystemLog from "../CommonLogUI/SystemLog"
import DateFnsUtils from "@date-io/date-fns";
import EmailTypeOne from "../CommonLogUI/EmailTypeOne";
import EmailTypeTwo from "../CommonLogUI/EmailTypeTwo";
import EmailTypeFour from "../CommonLogUI/EmailTypeFour";
import AddComment from "../CommonLogUI/AddComment";
import EditComment from "../CommonLogUI/EditComment";
import NoteLog from "../CommonLogUI/NoteLog";
import CallLogs from "../CommonLogUI/CallLogs";
import RingCentralCallLogs from "../CommonLogUI/RingCentralCallLogs";
import CreatelogDialog from "../CommonLogUI/CreatelogDialog";

import moment, { now } from 'moment';
import FilterLogoCommon from "../CommonLogUI/FilterLogoCommon";
const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.3rem",
        color: 'rgba(0, 0, 0, 0.6)'
    }
}))(Icon);

const IconSmaller = withStyles(() => ({
    root: {
        fontSize: "1.0rem",
        color: 'rgba(0, 0, 0, 0.6)'
    }
}))(Icon);



class LeadLogs extends Component {
    state = {
        viewHtmlData: '',
        shouldOpenViewEmailDialog: false,
        FullViewEmailDialog: false,
        minimizeViewEmailDialog: false,
        selectedDate: new Date(),
        isOpenedEmailIds: [],
        isOpenedReplayEmaillog: false,
        allLogs: [],
        logvaluetype: 'Phone Call',
        logtype: '',
        textvalue: '',
        dateformat: 'MM/DD/YYYY',
        timeZone: 'America/New_York',
        shouldOpenCreateLogDialog: false,
        FullCreateLeadDialog: false,
        minimizeLeadDialog: false,
        isDeleteLogConfirmDialoOpen: false,
        isEditLog: false,
        isEditComment: false,
        logid: '',
        commentid: '',
        Commentoptions: '',
        showCommentoptions: false,
        isOpenedCommentIds: [],
        logtypeid: [],
        activitypeid: [],
        activities: [],
        userid: "",
        showSelector: false,
        reactions: ["👍", "🔥", "😍", "🎉", "👏", "💯", "😎", "😂", "😭", "⛳️", "🙂", "🎯", "🍾", "😢", "❤️"],
        allSettings: "",
        emaildata: [],
        mainUserData: [],
        userSelectedEmail: "",
        isSendNewMail: false,
        userName: ""
    };

    componentDidMount() {
        this.props.getEmailSetting(this.props.apolloClient.client);
        let val = { typeId: 1 }
        this.props.customFieldList(this.props.apolloClient.client, val);
        this.props.getActivityTypes(this.props.apolloClient.client);
        let userData = JSON.parse(localStorage.getItem('user_data_session'))
        this.setState({
            userid: userData.id
        })
    }
    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }
    componentWillReceiveProps(props) {
        if (props.leadReducer.leadFieldData.data) {
            var fielddata = props.leadReducer.leadFieldData.data;
            var mainIntemplate = {};
            fielddata.map((details, index) => {
                if (details.fieldkey == "name") {
                    mainIntemplate.fullname = details.leaddetail[0].fieldvalue;
                    mainIntemplate.firstname = (details.leaddetail.length > 0 && details.leaddetail[0].values.firstname) ? details.leaddetail[0].values.firstname : "";
                    mainIntemplate.lastname = (details.leaddetail.length > 0 && details.leaddetail[0].values.lastname) ? details.leaddetail[0].values.lastname : "";
                    this.setState({ userName: details.leaddetail[0].fieldvalue })
                }
                if (details.fieldkey == "email" || details.fieldkey == "phones") {
                    mainIntemplate[details.fieldkey] = (details.leaddetail.length > 0 && details.leaddetail[0].values.length > 0) ? details.leaddetail[0].values[0].value : null
                }
                if (details.fieldkey == "company" || details.fieldkey == "title" || details.fieldkey == "address") {
                    mainIntemplate[details.fieldkey] = (details.leaddetail.length > 0 && details.leaddetail[0].fieldvalue) ? details.leaddetail[0].fieldvalue : null
                }
                if (details.fieldkey == "owner") {
                    mainIntemplate.owner = (details.leaddetail.length > 0 && details.leaddetail[0].values.length > 0) ? details.leaddetail[0].values[0].name : null
                }
                if (details.fieldkey == "email") {
                    this.setState({
                        userSelectedEmail: (details.leaddetail.length > 0 && details.leaddetail[0].values.length > 0) ? details.leaddetail[0].values[0].value : null
                    })
                }
            })
            this.setState({
                mainUserData: mainIntemplate
            })
        }
        if (props.settingReducer.emailsettings.data) {
            const mian_data = props.settingReducer.emailsettings.data
            this.setState({
                allSettings: mian_data,
            })
        }

        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            let usersetting = props.settingReducer.userSettingArray.data;

            let format = usersetting.dateformat;
            let timezone = usersetting.timezone;
            this.setState({ dateformat: format, timeZone: timezone })
        }
        if (props.settingReducer.masterActivityTypesList) {
            this.setState({ activities: props.settingReducer.masterActivityTypesList.data });
        }
        if (props.leadReducer.leadLogList && props.leadReducer.leadLogList.data && props.leadReducer.leadLogList.data.logdata && props.leadReducer.leadLogList.data.logdata.length > 0) {
            let logged_in_user_email = JSON.parse(localStorage.getItem("user_data_session")).email;
            let logData = [];
            let results = [];
            let smallData = props.leadReducer.leadLogList.data.logdata[props.leadReducer.leadLogList.data.logdata.length - 1]
            // let resultList = this.getMonths(smallData.createddate, props.leadReducer.leadLogList.data.logdata[0].createddate);
            let source = '';
            if (props.leadReducer.leadFieldData.data) {
                let fieldSource = props.leadReducer.leadFieldData.data;
                var sourcekey = this.findWithAttr(fieldSource, 'fieldkey', 'name');

                if (fieldSource[sourcekey].leaddetail && fieldSource[sourcekey].leaddetail[0].fieldvalue)
                    source = fieldSource[sourcekey].leaddetail[0].fieldvalue;
            }
            let resultList = [];
            props.leadReducer.leadLogList.data.logdata.map((data) => {
                if (data.logtype == 0) {
                    resultList.push({
                        str: moment.unix(data.logdate).tz(this.state.timeZone).format('MMM YYYY')
                    })
                } else {
                    resultList.push({
                        str: moment.unix(data.createddate).tz(this.state.timeZone).format('MMM YYYY')
                    })
                }
            })

            results['Pinned'] = [];
            let results_pined_ids = [];
            resultList.map((mont) => {
                results[mont.str] = [];
                props.leadReducer.leadLogList.data.logdata.map((data) => {
                        let pinned = data.ispined;
                        if (data.email_type) {
                            if (mont.str == moment.unix(data.createddate).tz(this.state.timeZone).format('MMM YYYY')) {
                                let log_user = JSON.parse(localStorage.getItem('user_data_session'));
                                let color_class = (log_user.name) ? log_user.name.charAt(0) : "";
    
                                if (data.email_type == 1) {
                                    let date = moment(data.details.email_date_time).format("X");
                                    logData.push(
                                        {
                                            logId: data.id,
                                            emojidetail: data.emojidetail && data.emojidetail !== null ? JSON.parse(data.emojidetail) : [],
                                            ispined: pinned,
                                            emailtype: 1,
                                            name: data.name,
                                            email: data.email,
                                            color: color_class,
                                            comments: data.__commentlist__,
                                            attachment: data.attachment,
                                            emaildetails: data.details,
                                            emailTo: data.emailTo,
                                            snippet: data.details.snippet,
                                            subject: data.details.subject,
                                            body_html: data.details.body_html,
                                            email_date_time: moment.unix(data.createddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " + moment.unix(data.createddate).tz(this.state.timeZone).format('hh:mm a'),
                                        }
                                    );
                                }
                                else if (data.email_type == 2) {
                                    logData.push(
                                        {
                                            logId: data.id,
                                            emojidetail: data.emojidetail && data.emojidetail !== null ? JSON.parse(data.emojidetail) : [],
                                            ispined: pinned,
                                            emailtype: 2,
                                            name: data.name,
                                            email: data.email,
                                            color: color_class,
                                            comments: data.__commentlist__,
                                            attachment: data.attachment,
                                            emaildetails: data.details,
                                            emailTo: data.emailTo,
                                            snippet: data.details.snippet,
                                            subject: data.details.subject,
                                            body_html: data.details.body_html,
                                            email_date_time: moment.unix(data.createddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " + moment.unix(data.createddate).tz(this.state.timeZone).format('hh:mm a'),
                                        }
                                    );
                                }
                                else if (data.email_type == 4) {
                                    logData.push(
                                        {
                                            logId: data.id,
                                            emojidetail: data.emojidetail && data.emojidetail !== null ? JSON.parse(data.emojidetail) : [],
                                            emailtype: 4,
                                            ispined: pinned,
                                            name: data.name,
                                            email: data.email,
                                            color: color_class,
                                            comments: data.__commentlist__,
                                            attachment: data.attachment,
                                            emaildetails: data.details,
                                            emailTo: data.emailTo,
                                            snippet: data.details.snippet,
                                            subject: data.details.subject,
                                            body_html: data.details.body_html,
                                            email_date_time: moment.unix(data.createddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " + moment.unix(data.createddate).tz(this.state.timeZone).format('hh:mm A'),
                                        }
                                    );
                                }
                            }
                        }
                        else {
                            if (!data.email_type && data.propertyname && data.propertyname === "Email") {
                                if (mont.str == moment.unix(data.logdate).tz(this.state.timeZone).format('MMM YYYY') && data.logtype == 0 ) {
                                    let pinned = data.ispined;
                                    let template = data.template;
                                    let color_class = (data.__user__.name) ? data.__user__.name.charAt(0) : "";
                                    let text = (data.text) ? data.text : "";
                                    let loguser = (data.__user__.email == logged_in_user_email) ? 'You' : data.__user__.name;
                                    let template1 = (data.template) ? template.replace("{{user}}", loguser) : "";
    
                                    let new_template = "";
    
                                    let create_date = data.createddate;
                                    let updateddate = data.updateddate;
    
                                    if (data.logtype == 0) // Email log
                                    {
                                        updateddate = data.logdate;
                                        new_template = template1.replace("{{property_name}}", '"' + data.propertyname + '"');
                                    }
    
                                    if (new_template != "") {
                                        logData.push({
                                            logId: data.id,
                                            mentionid: data.mentionid,
                                            mentiondetail: data.mentiondetail ? data.mentiondetail : "",
                                            emojidetail: data.emojidetail && data.emojidetail !== null ? JSON.parse(data.emojidetail) : [],
                                            creaetedDate: moment.unix(create_date).tz(this.state.timeZone).format(this.state.dateformat),
                                            emailtype: 0,
                                            comments: data.__commentlist__,
                                            activitytype: data.__activitytype__,
                                            user: data.__user__.name,
                                            useremail: data.__user__.email,
                                            propertyType: data.propertyname,
                                            color: color_class,
                                            template: new_template,
                                            ispined: pinned,
                                            text: text,
                                            logtype: data.logtype,
                                            time: (updateddate != null)
                                                ?
                                                moment.unix(updateddate).tz(this.state.timeZone).format('hh:mm a')
                                                :
                                                moment.unix(create_date).tz(this.state.timeZone).format('hh:mm a'),
                                            date: (updateddate != null)
                                                ?
                                                moment.unix(updateddate).tz(this.state.timeZone).format(this.state.dateformat)
                                                :
                                                moment.unix(create_date).tz(this.state.timeZone).format(this.state.dateformat)
                                        });
                                    }
                                }
                            }
                        }


                        if (logData.length > 0) {
                            if (logData[0].ispined && logData[0].ispined == true) {
                                if (results_pined_ids.indexOf(logData[0].logId) == -1) {
                                    results['Pinned'].push(logData);
                                    results_pined_ids.push(logData[0].logId);
                                }
                            } else {
                                results[mont.str].push(logData);
                            }
                            logData = []
                        }

                    
                });
            });
            let temp_arr1 = [];
            Object.keys(results).forEach(function (key) {
                let temp_arr2 = [];
                if (key === 'Pinned') {
                    temp_arr2['key'] = 'Pinned';
                    temp_arr2['log'] = results['Pinned'];
                    if (results['Pinned'] && results['Pinned'].length > 0) {
                        temp_arr1.push(temp_arr2);
                    }
                } else if (key !== 'Pinned') {
                    temp_arr2['key'] = key;
                    temp_arr2['log'] = results[key];
                    if (results[key] && results[key].length > 0) {
                        temp_arr1.push(temp_arr2);
                    }
                }
            })

            // this.setState({ LogResult: results, lead_id: props.lead_id});
            this.setState({ allLogs: temp_arr1, lead_id: props.lead_id });
        }
        else if (props.leadReducer.leadLogList && props.leadReducer.leadLogList.data && props.leadReducer.leadLogList.data.logdata && props.leadReducer.leadLogList.data.logdata.length == 0) {
            this.setState({ allLogs: [], lead_id: props.lead_id });
        }
    }
    togglePin = (logId, pinned) => {
        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';

        let pinParams = {
            ispined: !pinned,
            logid: parseFloat(logId),
            recordid: parseFloat(this.state.lead_id),
            recordtypeid: 1,
            emailid: emailParam,
            logtypeid: this.state.logtypeid,
            activitypeid: this.state.activitypeid
        }

        this.props.addLogPined(this.props.apolloClient.client, pinParams);
    }
    handleLeadDialogClose = () => {
        this.setState({ shouldOpenCreateLogDialog: false, FullCreateLeadDialog: false, minimizeLeadDialog: false });
    };
    handleLeadDialogMinimize = () => {
        this.setState(oldStateminimizeLeadDialog => ({ minimizeLeadDialog: !oldStateminimizeLeadDialog.minimizeLeadDialog }));
        this.setState({ FullCreateLeadDialog: false })
    };
    handleLeadDialogFull = () => {
        this.setState(oldStateFullCreateLeadDialog => ({ FullCreateLeadDialog: !oldStateFullCreateLeadDialog.FullCreateLeadDialog }));
        this.setState({ minimizeLeadDialog: false })
    };


    handleViewEmailDialogClose = () => {
        this.setState({ shouldOpenViewEmailDialog: false, FullViewEmailDialog: false, minimizeViewEmailDialog: false });
    };
    handlechange = (event, logid) => {
        this.setState({ [event.target.name]: event.target.value, logid: logid })
    }
    handleDateChange = date => {
        this.setState({ selectedDate: date });
    };
    handleCancelAddLog = () => {
        this.setState({ textvalue: '' })
    }
    handleSaveEditLog = () => {
        let date_test = moment(this.state.selectedDate).format('YYYY-MM-DD HH:mm');
        console.log(date_test, 'inserted');

        var logdate = moment.tz(date_test, this.state.timeZone).format();
        console.log(logdate, this.state.timeZone);

        var date = moment.utc(logdate).format('X');
        console.log(date, 'utc');

        console.log(moment.unix(date).tz(this.state.timeZone).format('YYYY-MM-DD hh:mm a'), 'conv');


        let textvalue = this.state.textvalue;
        let saveLogparams = [];

        saveLogparams['update'] = {
            text: textvalue,
            logtime: parseFloat(date),
            logdate: parseFloat(date),
            logid: parseFloat(this.state.logid),
        };

        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        saveLogparams['getlog'] = { emailid: emailParam, leadid: parseFloat(this.props.lead_id), logtypeid: this.state.logtypeid, activitypeid: this.state.activitypeid };

        this.props.updateLeadLog(this.props.apolloClient.client, saveLogparams);
        this.setState({ isEditLog: false })
    }
    handleEditLog = (id, date, time, text) => {
        let date1 = new Date(date + ',' + time);
        this.setState({ isEditLog: true, textvalue: text, selectedDate: date1, logid: id });
    }
    handleCancelEdit = () => {
        this.setState({ isEditLog: false })
    }

    //------delete log
    handleDeleteLog = (logid) => {
        this.setState({ logid: logid });
        this.toggleDeleteDialog()
    }
    toggleDeleteDialog = () => {
        this.setState({ isDeleteLogConfirmDialoOpen: !this.state.isDeleteLogConfirmDialoOpen });
    };
    confirmDeleteLog = () => {
        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        let deletelogParams = { logid: parseFloat(this.state.logid), leadid: parseFloat(this.state.lead_id), emailid: emailParam, logtypeid: this.state.logtypeid, activitypeid: this.state.activitypeid };
        this.props.deleteLeadLogDetail(this.props.apolloClient.client, deletelogParams);
        this.toggleDeleteDialog()
    };

    //email menu functions
    handleEmailHtmlPopupMain = (logdata) => {
        this.setState({ shouldOpenViewEmailDialog: true, viewHtmlData: logdata });
    }

    //- comments-----------------
    toggleComment = (logid) => {
        let isOpenedCommentIds = this.state.isOpenedCommentIds;

        if (isOpenedCommentIds.indexOf(logid) == -1) {
            isOpenedCommentIds.push(logid);
        }
        else {
            let index = isOpenedCommentIds.indexOf(logid);
            isOpenedCommentIds.splice(index, 1);
        }

        this.setState(({ commentvalue: "", logid: logid, isOpenedCommentIds: isOpenedCommentIds }));
        // this.setState(({ isOpenedComment: !this.state.isOpenedComment, logid: logid }));
    }

    handleCancelComment = () => {
        this.setState({ commentvalue: '' });
    }

    handleSaveCommentLog = (type) => {
        const textvalue = this.state.commentState.textvalue
        const raw = this.state.commentState.raw
        let mention = [];
        Object.entries(raw.entityMap).map((entity) => {
            if (mention.indexOf(entity[1].data.mention.id) == -1) {
                mention.push(parseFloat(entity[1].data.mention.id))
            }
        });

        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        let commentParams = {
            type: type,
            comment: this.state.commentvalue,
            logid: parseFloat(this.state.logid),
            leadid: parseFloat(this.state.lead_id),
            emailid: emailParam, logtypeid: this.state.logtypeid,
            activitypeid: this.state.activitypeid,
            mentionuser: mention,
            mentiondetail: "",
        };
        this.props.addLeadLogComment(this.props.apolloClient.client, commentParams);
        this.setState({ commentvalue: '' });
    }

    //-edit comment
    handleSaveEditComment = (type) => {
        const textvalue = this.state.commentState.textvalue
        const raw = this.state.commentState.raw
        let mention = [];
        Object.entries(raw.entityMap).map((entity) => {
            if (mention.indexOf(entity[1].data.mention.id) == -1) {
                mention.push(parseFloat(entity[1].data.mention.id))
            }
        });

        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        let commentParams = {
            type: type,
            commentid: parseFloat(this.state.commentid),
            comment: this.state.commentvalue,
            leadid: parseFloat(this.state.lead_id),
            emailid: emailParam,
            logtypeid: this.state.logtypeid,
            activitypeid: this.state.activitypeid,
            mentionuser: mention,
            mentiondetail: "",
        };
        this.setState({ commentvalue: '', isEditComment: false, commentid: '' });
        this.props.updateLeadLogComment(this.props.apolloClient.client, commentParams);
    }

    handleEditCommentMain = (id, comment) => {
        this.setState({ isEditComment: true, commentvalue: comment, commentid: id });
    }

    handleCancelEditComment = () => {
        this.setState({ isEditComment: false, commentvalue: '' })
    }

    //-delete comment
    deleteComment = (cmt_id, type) => {
        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        let commentParams = { commentid: cmt_id, type: type, emailid: emailParam, leadid: parseFloat(this.state.lead_id), logtypeid: this.state.logtypeid, activitypeid: this.state.activitypeid };
        this.props.deleteLeadLogComment(this.props.apolloClient.client, commentParams);
    }
    //--------------comments functions-----------

    //---- email functions
    toggleEmail = (id) => {
        let isOpenedEmailIds = this.state.isOpenedEmailIds;

        if (isOpenedEmailIds.indexOf(id) == -1) {
            isOpenedEmailIds.push(id);
        }
        else {
            let index = isOpenedEmailIds.indexOf(id);
            isOpenedEmailIds.splice(index, 1);
        }

        this.setState(({ logid: id, isOpenedEmailIds: isOpenedEmailIds }));
    }

    toggleReplayEmailLog = () => {
        this.setState({
            isOpenedReplayEmailLog: !this.state.isOpenedReplayEmailLog
        });
    }

    componentWillUnmount() {
        localStorage.removeItem('emailLogEmail');
    }
    //------------

    // -- get all months between two dates
    getMonths = (startDate, endDate) => {
        var resultList = [];
        var date = moment.unix(startDate).tz(this.state.timeZone)._d;
        var endDate = moment.unix(endDate).tz(this.state.timeZone)._d;
        var monthNameList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        if (date.getMonth() < endDate.getMonth()) {
            while (date.getMonth() <= endDate.getMonth() + 1) {
                var stringDate = monthNameList[date.getMonth()] + " " + date.getFullYear();

                //get first and last day of month
                // var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                // var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

                resultList.push({
                    str: stringDate,
                });
                date.setMonth(date.getMonth() + 1);
            }
            resultList.reverse();
        } else if (date.getMonth() == endDate.getMonth()) {
            var stringDate = monthNameList[date.getMonth()] + " " + date.getFullYear();
            resultList.push({
                str: stringDate,
            });
        }

        return resultList;
    };

    handlefiltermain = (types, activities) => {
        this.setState({ logtypeid: types, activitypeid: activities }, () => this.forceUpdate());

        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        let getfilterLogParam = { leadid: this.state.lead_id, emailid: emailParam, logtypeid: types, activitypeid: activities }
        this.props.getLeadLogListNew(this.props.apolloClient.client, getfilterLogParam);
    }
    handleAdd = (log) => this.setState({ showSelector: !this.state.showSelector, logid: log.logId })

    handleSelect = (emoji, data) => {
        const index = data.emojidetail.findIndex(obj => obj.emoji === emoji && obj.by === this.state.userid)
        var main_data = data.emojidetail;
        if (index > -1) {
            main_data = [...main_data.slice(0, index), ...main_data.slice(index + 1)]
        } else {
            main_data = [...main_data, { emoji, by: this.state.userid }]
        }
        this.setState({ showSelector: false })

        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';

        let req = {
            logid: data.emailtype !== 0 ? parseFloat(data.emaildetails.id) : parseFloat(data.logId),
            emoji: emoji,
            emojidetail: JSON.stringify(main_data),
            leadid: parseFloat(this.state.lead_id),
            activitypeid: this.state.activitypeid,
            emailid: emailParam,
            logtypeid: this.state.logtypeid,
            logtype: data.emailtype !== 0 ? "email" : "log"
        }
        this.props.addLeadLogEmoji(this.props.apolloClient.client, req)
    }
    isOpenedClickEmailToggle = () => {
        this.setState({
            isSendNewMail: !this.state.isSendNewMail
        })
    }
    openNewMail = (log) => {
        this.setState({ isOpenedReplayEmailLog: true, logid: log.logId, emaildata: log });
    }
    getdata = (data) => {
        console.log("data ....", data)
        this.setState({ commentvalue: data.textvalue, commentState: data })
    }
    getEditCommentData = (data) => {
        console.log("data edit ....", data)
        this.setState({ commentvalue: data.textvalue, commentState: data })
    }
    saveId = (id, date) => {
        this.setState({ logid: id, selectedDate: date })
    }
    AddNewLog = (log) => {
        this.setState({
            logtype: log.logtype,
            logvaluetype: log.propertyType,
            shouldOpenCreateLogDialog: true
        })
    }
    handleSaveAddLog = (data) => {
        let saveLogparams = [];
        saveLogparams['save'] = {
            mentionuser: data.mentionuser,
            mentiondetail: data.mentiondetail,
            propertyname: data.propertyname,
            propertyid: data.propertyid,
            text: data.text,
            logtime: data.logtime,
            logdate: data.logdate,
            oldvalue: "",
            newvalue: "",
            logtype: data.logtype,
            fieldid: data.fieldid,
            leadid: this.props.lead_id
        };
        let emailParam = localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '';
        saveLogparams['getlog'] = { emailid: emailParam, leadid: parseFloat(this.props.lead_id), logtypeid: [], activitypeid: [] };

        this.props.saveLeadLog(this.props.apolloClient.client, saveLogparams);
        this.setState({ shouldOpenCreateLogDialog: false });

    }

    render() {
        const { isOpenedReplayEmail, isOpenedReplayEmailLog, commentvalue, allLogs, logvaluetype, isOpenedComment, textvalue, selectedDate, shouldOpenCreateLogDialog, minimizeLeadDialog, FullCreateLeadDialog } = this.state
        let user_data = JSON.parse(localStorage.getItem('user_data_session'));
        let current_logUser = user_data.email;
        let loghtml = [];
        let logheader = [];
        let logbody = [];
        let allowUserReply = user_data.googlestatus === true && this.state.allSettings.setting_one && this.state.allSettings.setting_one === 1 ? true : false

        allLogs.map((logdata) => {
            logheader = [];
            logbody = [];
            logheader.push(
                <FilterLogoCommon headerKey={logdata.key} />
            )

            logdata['log'].map((log) => {
                log = log[0];
                if (log.emailtype != 0) {
                    if (log.emailtype == 1) {
                        logbody.push(
                            <EmailTypeOne
                                allowUserReply={allowUserReply}
                                commentvalue={commentvalue}
                                log={log}
                                logid={this.state.logid}
                                userName={this.state.userName}
                                lead_id={this.state.lead_id}
                                isOpenedEmailIds={this.state.isOpenedEmailIds}
                                showSelector={this.state.showSelector}
                                dateformat={this.state.dateformat}
                                timeZone={this.state.timeZone}
                                commentid={this.state.commentid}
                                isOpenedCommentIds={this.state.isOpenedCommentIds}
                                isEditComment={this.state.isEditComment}
                                isOpenedReplayEmailLog={isOpenedReplayEmailLog}
                                thatAll={this}
                                transferLink={"/lead/lead/" + this.state.lead_id}
                                mainUserData={this.state.mainUserData}
                                emaildata={this.state.emaildata}
                                LogDetails={{
                                    leadid: this.state.lead_id,
                                    emailid: localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '',
                                    logtypeid: this.state.logtypeid,
                                    activitypeid: this.state.activitypeid,
                                }}
                                LogComponent="Lead"
                            />
                        )
                    }
                    else if (log.emailtype == 2) {
                        logbody.push(
                            <EmailTypeTwo
                                allowUserReply={allowUserReply}
                                commentvalue={commentvalue}
                                log={log}
                                logid={this.state.logid}
                                userName={this.state.userName}
                                lead_id={this.state.lead_id}
                                isOpenedEmailIds={this.state.isOpenedEmailIds}
                                showSelector={this.state.showSelector}
                                dateformat={this.state.dateformat}
                                timeZone={this.state.timeZone}
                                commentid={this.state.commentid}
                                isOpenedCommentIds={this.state.isOpenedCommentIds}
                                isEditComment={this.state.isEditComment}
                                isOpenedReplayEmailLog={isOpenedReplayEmailLog}
                                thatAll={this}
                                transferLink={"/lead/lead/" + this.state.lead_id}
                                mainUserData={this.state.mainUserData}
                                emaildata={this.state.emaildata}
                                LogDetails={{
                                    leadid: this.state.lead_id,
                                    emailid: localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '',
                                    logtypeid: this.state.logtypeid,
                                    activitypeid: this.state.activitypeid,
                                }}
                                LogComponent="Lead"
                            />
                        )
                    }
                    else if (log.emailtype == 4) {
                        logbody.push(
                            <EmailTypeFour
                                allowUserReply={allowUserReply}
                                commentvalue={commentvalue}
                                log={log}
                                logid={this.state.logid}
                                userName={this.state.userName}
                                lead_id={this.state.lead_id}
                                isOpenedEmailIds={this.state.isOpenedEmailIds}
                                showSelector={this.state.showSelector}
                                dateformat={this.state.dateformat}
                                timeZone={this.state.timeZone}
                                commentid={this.state.commentid}
                                isOpenedReplayEmailLog={isOpenedReplayEmailLog}
                                isOpenedCommentIds={this.state.isOpenedCommentIds}
                                isEditComment={this.state.isEditComment}
                                thatAll={this}
                                transferLink={"/lead/lead/" + this.state.lead_id}
                                mainUserData={this.state.mainUserData}
                                emaildata={this.state.emaildata}
                                LogDetails={{
                                    leadid: this.state.lead_id,
                                    emailid: localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '',
                                    logtypeid: this.state.logtypeid,
                                    activitypeid: this.state.activitypeid,
                                }}
                                LogComponent="Lead"
                            />
                        )
                    }
                } else {
                    if (log.logtype == 0) {
                        logbody.push(
                            <CallLogs
                                thatAll={this}
                                log={log}
                                commentvalue={this.state.commentvalue}
                                selectedDate={this.state.selectedDate}
                                logid={this.state.logid}
                                dateformat={this.state.dateformat}
                                showSelector={this.state.showSelector}
                                isOpenedCommentIds={this.state.isOpenedCommentIds}
                                isEditComment={this.state.isEditComment}
                                commentid={this.state.commentid}
                                timeZone={this.state.timeZone}
                            />)
                    } else if (log.logtype == 1000) {
                        logbody.push(
                            <RingCentralCallLogs
                                thatAll={this}
                                log={log}
                                commentvalue={this.state.commentvalue}
                                selectedDate={this.state.selectedDate}
                                logid={this.state.logid}
                                dateformat={this.state.dateformat}
                                showSelector={this.state.showSelector}
                                isOpenedCommentIds={this.state.isOpenedCommentIds}
                                isEditComment={this.state.isEditComment}
                                commentid={this.state.commentid}
                                timeZone={this.state.timeZone}
                            />)
                    }
                    else if (log.logtype == 5) {
                        logbody.push(
                            <NoteLog
                                thatAll={this}
                                log={log}
                                commentvalue={this.state.commentvalue}
                                selectedDate={this.state.selectedDate}
                                logid={this.state.logid}
                                dateformat={this.state.dateformat}
                                showSelector={this.state.showSelector}
                                isOpenedCommentIds={this.state.isOpenedCommentIds}
                                isEditComment={this.state.isEditComment}
                                commentid={this.state.commentid}
                                timeZone={this.state.timeZone}
                            />
                        )
                    }
                    else {
                        logbody.push(
                            <SystemLog log={log} />
                        )
                    }
                }
            })
            if (logbody && logbody.length > 0) {
                loghtml.push(logheader);
                loghtml.push(logbody);
            }
        })


        return (
            <>
                {/* <FiltersMenu proVar={this.state.activities} thisData={this} /> */}
                {allLogs && allLogs.length > 0 ?
                    <div>
                        {loghtml.map((month_log) => (
                            <div className="lead_upcoming">
                                <div className="box_portlet">
                                    {month_log}
                                </div>
                            </div>
                        ))}
                    </div>
                    :
                    <div className="isLogTabEmpty">
                        <div className="tab_empty_crm">
                            <div className="notabIcon">
                                <Icon style={{ fontSize : '50px' , margin : '10px 10px 32px 10px' }}>mail</Icon>
                            </div>
                            <div className="notabTitle">No Email Logged Yet</div>
                        </div>
                    </div>
                }

                {shouldOpenCreateLogDialog && (
                    <CreatelogDialog
                        handleClose={this.handleLeadDialogClose}
                        open={shouldOpenCreateLogDialog}
                        handleMinimize={this.handleLeadDialogMinimize}
                        handleFull={this.handleLeadDialogFull}
                        minimizeScreen={minimizeLeadDialog}
                        fullScreen={FullCreateLeadDialog}
                        handleSaveAddLog={this.handleSaveAddLog}
                        logvaluetype={this.state.logvaluetype}
                        logtype={this.state.logtype}
                    />
                )}
                <Dialog
                    fullWidth={true}
                    maxWidth={'xs'}
                    open={this.state.isDeleteLogConfirmDialoOpen}
                    onClose={this.toggleDeleteDialog}
                    disableBackdropClick

                    aria-labelledby="ar-remove-dialog-title"
                >
                    <DialogTitle id="ar-remove-dialog-title">{"Are you sure you want to remove selected log ?"}</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.toggleDeleteDialog} color="primary">
                            Cancel
                        </Button>
                        <Button type="Button" onClick={() => this.confirmDeleteLog()} className="btn_Primary">
                            Ok
                        </Button>

                    </DialogActions>
                </Dialog>
                {this.state.shouldOpenViewEmailDialog && (
                    <ViewHtmlDialog
                        handleClose={this.handleViewEmailDialogClose}
                        open={this.state.shouldOpenViewEmailDialog}
                        logDatas={this.state.viewHtmlData}
                    />
                )}
                {this.state.isSendNewMail &&
                    <InboxComposeDialog
                        handleClose={this.isOpenedClickEmailToggle}
                        open={this.state.isSendNewMail}
                        userSelectedEmail={this.state.userSelectedEmail}
                        mainUserData={this.state.mainUserData}
                        LogDetails={{
                            leadid: this.state.lead_id,
                            emailid: localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '',
                            logtypeid: this.state.logtypeid,
                            activitypeid: this.state.activitypeid,
                        }}
                        LogComponent="Lead"
                    />
                }
            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        leadReducer: state.leadReducer,
        apolloClient: state.apolloClient,
        settingReducer: state.settingReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
        addLogPined: (client, request) => dispatch(actions.addLogPined(client, request)),

        getLeadLogListNew: (client, request) => dispatch(actions.getLeadLogListNew(client, request)),
        // getLeadLogList: (client, request) => dispatch(actions.getLeadLogList(client, request)),
        getActivityTypes: (client) => { dispatch(actions.getActivityTypes(client)) },
        saveLeadLog: (client, request) => dispatch(actions.saveLeadLog(client, request)),
        deleteLeadLogDetail: (client, request) => dispatch(actions.deleteLeadLogDetail(client, request)),
        updateLeadLog: (client, request) => dispatch(actions.updateLeadLog(client, request)),
        addLeadLogComment: (client, request) => dispatch(actions.addLeadLogComment(client, request)),
        updateLeadLogComment: (client, request) => dispatch(actions.updateLeadLogComment(client, request)),
        deleteLeadLogComment: (client, request) => dispatch(actions.deleteLeadLogComment(client, request)),
        addLeadLogEmoji: (client, request) => dispatch(actions.addLeadLogEmoji(client, request)),
        getEmailSetting: (client) => dispatch(actions.getEmailSetting(client)),
        customFieldList: (client, request) => { dispatch(actions.customFieldList(client, request)) },

    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(LeadLogs);