import React, { Component } from "react";
import { Grid, IconButton, Icon, Card, Fab, Badge, Paper, Box, CircularProgress, Tooltip, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import { NavLink, withRouter } from "react-router-dom";
import moment from 'moment';

const IconSmall = withStyles(() => ({
  root: {
    fontSize: "1.5rem",
    color: 'rgba(0, 0, 0, 0.6)'
  }
}))(Icon);

const TooltipNew = withStyles((theme) => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);
class Xero extends Component {
  state = {
    isOpenedXero: false,
    dateformat: 'MM/DD/YYYY',
    timeZone: 'America/New_York',
    hasMore: true,
    totalCount: 0,
    isDataLoading: true,
    invoiceList: [],
    items: [],
    page_size: 0,
  };
  componentWillUnmount() {
    this.props.settingReducer.relatedDataXero = []
  }

  componentWillMount() {
    if (this.props.recordId) {
      this.setState({
        recordId: this.props.recordId,
      });
      var req = {
        id: this.props.recordId,
        type: this.props.recordtype === "opportunities" ? "opportunity" : this.props.recordtype,
        page: 1,
      }
      this.props.getXeroInvoice(req);
    }
  }
  componentWillReceiveProps(props) {
    if (props.recordId != this.state.recordId) {//if(props.recordId !== undefined && this.state.oppoFlag){
      this.setState({
        recordId: this.props.recordId,
      });
      var req = {
        id: this.props.recordId,
        type: this.props.recordtype === "opportunities" ? "opportunity" : this.props.recordtype,
        page: 1,
      }
      this.props.getXeroInvoice(req);
    }
    if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
      let usersetting = props.settingReducer.userSettingArray.data;
      let format = usersetting.dateformat;
      let timezone = usersetting.timezone;
      this.setState({ dateformat: format, timeZone: timezone })
    }
    if (props.settingReducer.relatedDataXero.data) {
      if (props.settingReducer.relatedDataXero.data.data !== this.state.invoiceList) {
        const AllData = props.settingReducer.relatedDataXero.data;
        const totalRecord = AllData.count;
        const items = (AllData.data.slice(0, AllData.page_size))
        this.setState({
          hasMore: totalRecord > AllData.page_size ? true : false,
          totalCount: totalRecord,
          invoiceList: AllData.data,
          isDataLoading: false,
          page_size: AllData.page_size,
          items: items
        })
      }
      if (props.settingReducer.relatedDataXero.data.data.length > 0) {
        this.setState({ isOpenedXero: true });
      }else{
        this.setState({ isOpenedXero: false });
      }
    } else {
      this.setState({
        isDataLoading: false
      });
    }
  }

  fetchMoreData = (e) => {
    e.preventDefault();
    console.log("dataa  fetch nmore")
    if (this.state.items.length >= this.state.totalCount) {
      this.setState({ hasMore: false });
      return;
    }
    this.setState({ isDataLoading: true });
    var data = [...this.state.invoiceList]
    var maindata = data.slice(this.state.items.length, this.state.items.length + this.state.page_size)
    const data_new = this.state.items.concat(maindata)
    this.setState({

      hasMore: data_new.length === this.state.totalCount ? false : true,
    });
    setTimeout(() => {
      this.setState({
        items: data_new,
        isDataLoading: false
      })
    }, 1000);
  };
  toggleXero = () => {
    this.setState({ isOpenedXero: !this.state.isOpenedXero });
  }
  render() {
    // https://developer.xero.com/documentation/api/invoices
    const { isOpenedXero, invoiceList, items } = this.state;
    let displayInvoiceList = []
    if (items.length > 0) {
      items.map((invoice, i) => {
        let invoiceLink = "https://go.xero.com/AccountsReceivable/View.aspx?InvoiceID=" + invoice.doc_number;
        let status = ""
        let color = '';
        let invoice_Date = "-"

        if (invoice.balance > 0 && moment(invoice.due_at).isBefore(moment())) {
          color = "#B50814"
          status = "OVERDUE"
        } else {
          if (invoice.status === "DRAFT") {
            color = "#546e7a"
            status = "DRAFT"
          } else if (invoice.status === "PAID") {
            color = "#08ad6c"
            status = "PAID"
          } else if (invoice.status === "AUTHORISED") {
            color = "#08ad6c"
            status = "OPEN"
          } else {
            color = "#546e7a"
            status = invoice.status
          }
        }
        if (invoice.due_at) {
          invoice_Date = moment(invoice.due_at, 'YYYY-MM-DD').format(this.state.dateformat)
        }
        displayInvoiceList.push(
          <div className="box_portlet mt-10 crm-calendar-event" key={i}>
            <div className="box_body px-10 py-20">
              <div className="lead_pl" style={{ paddingLeft: '44px' }}>
                <div className="icon_left">
                  <Fab
                    size="small"
                    aria-label="Task"
                    className={`wrap_icons_task_sidebar vert-middle bg-grey_sidebar`}
                  >
                    <img src="/assets/images/intregation_logo/xero-logo-white-punchout.png" style={{ width: "82%" }} />
                  </Fab>
                </div>
                <Grid container justify="space-between">
                  <h6 className="m-0 font-weight-600 font-size-13">Invoice #{invoice.id}</h6>
                  <Box className="text-white hover_hide" style={{ padding: "0 4px", background: `${color}`, fontSize: "11px", height: "15px" }}>{status}</Box>
                  <Box className="Toggle_hover_hide" style={{ padding: "0 4px", height: "15px" }}>
                    <a href={invoiceLink} target="_blank">
                      <TooltipNew title="Open in Xero" placement="top" arrow>
                        <IconSmall style={{ fontSize: '22px' }}>launch</IconSmall>
                      </TooltipNew>
                    </a>
                  </Box>
                </Grid>
                <span className="capitalize font-weight-500 font-size-13">{invoice.customer_name}</span>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item><span className="font-size-13 font-weight-500">Due:</span></Grid>
                  <Grid item><span className="font-size-13 font-weight-500">{invoice_Date}</span></Grid>
                </Grid>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item><span className="font-size-13 font-weight-500">Total Due:</span></Grid>
                  <Grid item><span className="font-size-13 font-weight-500">{invoice.currency}{" "}{invoice.total}</span></Grid>
                </Grid>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item><span className="font-size-13 font-weight-500">Balance Due:</span></Grid>
                  <Grid item><span className="font-size-13 font-weight-500">{invoice.currency}{" "}{invoice.balance}</span></Grid>
                </Grid>
              </div>
            </div>
          </div>
        )
      })
    }
    return (
      <Card elevation={3} className="p-16 mt-16" style={{ overflow: 'initial' }}>
        <Grid container justify="space-between">
          <h5 className="flex-auto cursor_poiter mb-0 pt-4"
            onClick={this.toggleXero}>
            Xero ({this.state.totalCount})
            <Icon className="vert-middle">
              {isOpenedXero ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
            </Icon>
          </h5>
        </Grid>
        {
          isOpenedXero &&
          <>
            {
              (invoiceList.length == 0) ?
                <label className="text-hint">
                  <IconButton className="mr-8" component="span">
                    <Icon>description</Icon>
                  </IconButton>No Invoices</label>
                :
                <>
                  {displayInvoiceList}
                  {
                    this.state.isDataLoading ?
                      <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                        style={{ minHeight: '10vh', backgroundColor: 'rgba(0,0,0,0)' }}
                      >
                        <Grid item xs={3}>
                          <CircularProgress disableShrink size={25} />
                        </Grid>
                      </Grid>
                      :
                      this.state.hasMore &&
                      <Grid container item justify="center" className="mt-10">
                        <a href="" className="text-small text_link"
                          onClick={this.fetchMoreData}
                        >
                          SHOW {this.state.totalCount - items.length} MORE INVOICES
                          <Icon style={{ margin: "-8px 0px" }}>expand_more</Icon>
                        </a>
                      </Grid>
                  }
                </>
            }

          </>
        }
      </Card >
    );
  }
}

const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
});

const mapDispatchToProps = dispatch => {
  return {
    getXeroInvoice: (req) => { dispatch(actionCreators.getXeroInvoice(req)) },
  }
}
export default (connect(mapStateToProps, mapDispatchToProps)(withRouter(Xero)));