import React, { Component } from "react";
import { Dialog, IconButton, Button, Icon, Tooltip, Grid, FormControl, Select, InputLabel, Menu, MenuItem, List, ListItem, ListItemText, DialogActions, Chip, TextField } from "@material-ui/core";
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';

import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';
import CommonVisibiltyAdd from "../Layout/CommonVisibiltyAdd"

export const createDynamicFields = (data) => {
  var elements = data.state.fieldsList
  var fieldsArray = [];



  elements.map((v, i) => {
    if (v.fieldstatus === true) {
      if (v.fieldtype.id == '1' && ((v.fieldkey == "websites" || v.fieldkey == "social" || v.fieldkey == "phones") && v.is_default)) {
        {
          let tempFieldArray = []
          data.state.fieldsObj[v.fieldkey].values && data.state.fieldsObj[v.fieldkey].values.map((row, index) => {
            if (!row.id) {
              data.handleAddMoreField("id", v.fieldkey, index, v.options[0].id)
            }
            tempFieldArray.push(
              <div className={row.srno > 1 && "mt-10"}>
                <Select
                  name={v.fieldkey}
                  id={v.id}
                  value={row.id}
                  disableUnderline
                  onChange={(event) => data.handleAddMoreField("id", v.fieldkey, index, event.target.value)}
                  SelectDisplayProps={{ className: "multiple-text-dropdown" }}
                >
                  {v.options && v.options.map((item, key) =>
                    <MenuItem key={item.id} value={item.id}>{item.optionvalue}</MenuItem>
                  )}
                </Select>
                <span style={{ color: '#0000008a' }}>{(v.recordcreationdefaultvalue == 2) ? "*" : ""}</span>
                <TextValidator
                  style={{ marginTop: "-6px" }}
                  ref={ref => data[v.fieldkey] = ref}
                  onBlur={(event) => data.handleBlur(event, v.fieldkey)}
                  type="text"
                  id={v.id}
                  // placeholder={"Add " + v.fieldkey}
                  placeholder={`Add ${v.fieldkey && v.fieldkey.charAt(0).toUpperCase() + v.fieldkey.slice(1)}`}
                  className="w-100 input_bx"
                  onChange={(event) => data.handleAddMoreField("value", v.fieldkey, index, event.target.value)}
                  name={v.fieldkey}
                  value={row.value}
                  disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                  validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
                  errorMessages={(v.recordcreationdefaultvalue == 2) ? ["This field is required"] : []}
                />
                {v.fieldkey != "email" && row.value && index == (data.state.fieldsObj[v.fieldkey].values.length - 1) &&
                  <span className="add-more-field" type="button" onClick={() => { data.addNewField(v.fieldkey, v.options[0]) }}>Add More</span>
                }
              </div>
            )
          })
          fieldsArray.push(
            <Grid item xs={6}>
              {tempFieldArray}
            </Grid>
          )
        }

      } else if (v.fieldtype.id == '1' && v.fieldkey == 'emaildomain') {
        fieldsArray.push(<><Grid item xs={6}>
          <InputLabel>Email Domain</InputLabel>
          <TextValidator
            ref={ref => data[v.fieldkey] = ref}
            onBlur={(event) => data.handleBlur(event, v.fieldkey)}
            type="text"
            id={v.id}
            required={(v.recordcreationdefaultvalue == 2) ? true : false}
            disabled={v.recordcreationdefaultvalue == 3 ? true : false}
            className="w-100 input_bx"
            placeholder={"Add Email Domain"}
            onChange={(event) => data.handleChange({ fieldid: v.id, companyvalue: event.target.value }, v.fieldkey)}
            name={v.fieldkey}
            value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].companyvalue : ""}
            validators={(v.recordcreationdefaultvalue == 2) ? ["required", "emaildomain"] : ["emaildomain"]}
            errorMessages={(v.recordcreationdefaultvalue == 2) ? ["This field is required", "Please enter a valid email domain"] : ["Please enter a valid email domain"]}
          />
        </Grid></>
        )
      } else if (v.fieldtype.id == '1' && v.id == '29') {
        fieldsArray.push(<><Grid item xs={6}>
          {/* <InputLabel>Name</InputLabel> */}
          <TextValidator
            ref={ref => data[v.fieldkey] = ref}
            onBlur={(event) => data.handleBlur(event, v.fieldkey)}
            type="text"
            id={v.id}
            required={(v.recordcreationdefaultvalue == 2) ? true : false}
            label={'Name'}
            disabled={v.recordcreationdefaultvalue == 3 ? true : false}
            className="w-100 input_bx"
            // placeholder={"Add " + v.fieldlabel}
            placeholder={`Add ${v.fieldlabel && v.fieldlabel.charAt(0).toUpperCase() + v.fieldlabel.slice(1)}`}
            onChange={(event) => data.handleChange({ fieldid: v.id, companyvalue: event.target.value }, v.fieldkey)}
            name={v.fieldkey}
            value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].companyvalue : ""}
            validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
            errorMessages={(v.recordcreationdefaultvalue == 2) ? ["This field is required"] : []}
            InputLabelProps={{
              shrink: true,
              disableAnimation: false,
              focused: true
            }}
          />
        </Grid></>
        )
      } else if (v.fieldtype.id == '1' && v.fieldkey == 'tags') { // tags
        var valTag = [];
        if (data.state.fieldsObj[v.fieldkey]) {
          valTag = data.state.fieldsObj[v.fieldkey].values
        }
        fieldsArray.push(<Grid item xs={6}>
          <InputLabel>Tags</InputLabel>
          <Autocomplete
            disableOpenOnFocus
            multiple
            value={valTag}
            filterSelectedOptions
            disabled={v.recordcreationdefaultvalue == 3 ? true : false}
            name={v.fieldkey}
            id="tagInputId1"
            options={data.state.systemTagsList.map(option => option.tagname)}
            freeSolo
            onChange={(event, value) => data.handleChange({ fieldid: v.id, values: value }, v.fieldkey)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip label={option.charAt(0).toUpperCase() + option.slice(1)} {...getTagProps({ index })} />
              ))
            }
            renderInput={params => (
              <TextValidator
                ref={ref => data[v.fieldkey] = ref}
                onBlur={(event) => data.handleBlur(event, v.fieldkey)}
                {...params}
                // label="Tags"
                placeholder="Add Tag"
                name={v.fieldkey}
                value={valTag && valTag.length ? 1 : null}
                className="w-100 input_bx"
                required={(v.recordcreationdefaultvalue == 2) ? true : false}
                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                validators={(v.recordcreationdefaultvalue == 2 && (valTag == null || valTag.length == 0)) ? ["required"] : []}
                errorMessages={(v.recordcreationdefaultvalue == 2 && (valTag == null || valTag.length == 0)) ? ["This field is required"] : []}
                onBlur={() =>
                  setTimeout(() => {
                    document.getElementById("tagInputId1").value = "";
                  }, 10)
                }
                onFocus={() =>
                  setTimeout(() => {
                    document.getElementById("tagInputId1").value = "";
                  }, 0)
                }
              />
            )}
          />
        </Grid>)
      } else if (v.fieldtype.id == '1' && v.id == '31') { // owner
        var nameOwner = [];
        if (data.state.fieldsObj[v.fieldkey]) {
          nameOwner = data.state.fieldsObj[v.fieldkey].values
        }
        fieldsArray.push(<Grid item xs={6}>
          {/* <FormControl className="w-100 input_bx"> */}
          <InputLabel>Owner</InputLabel>
          <Autocomplete
            disableOpenOnFocus
            forcePopupIcon={false}
            name={v.fieldkey}
            options={data.state.usersListArray}
            getOptionLabel={option => option.name ? option.name : ""}
            value={nameOwner}
            disabled={v.recordcreationdefaultvalue == 3 ? true : false}
            name={v.fieldkey}
            onChange={(event, value) => data.handleChange({ fieldid: v.id, values: value }, v.fieldkey)}
            id={v.id}
            renderInput={params => (
              <TextValidator
                ref={ref => data[v.fieldkey] = ref}
                onBlur={(event) => data.handleBlur(event, v.fieldkey)}
                {...params}
                variant="standard"
                // label={"Owner"}
                placeholder="Add Owner"
                className="w-100 input_bx"
                fullWidth
                name={v.fieldkey}
                value={nameOwner && nameOwner.length ? 1 : null}
                required={(v.recordcreationdefaultvalue == 2) ? true : false}
                validators={(v.recordcreationdefaultvalue == 2 && (nameOwner == null || nameOwner.length == 0)) ? ["required"] : []}
                errorMessages={(v.recordcreationdefaultvalue == 2 && (nameOwner == null || nameOwner.length == 0)) ? ["This field is required"] : []}
              />
            )}
          />
          {/* </FormControl> */}
        </Grid>)
      } else if (v.fieldtype.id == '1') {//TextField with
        fieldsArray.push(<Grid item xs={6}>
          <InputLabel>{v.fieldlabel}</InputLabel>
          <TextValidator
            ref={ref => data[v.fieldkey] = ref}
            onBlur={(event) => data.handleBlur(event, v.fieldkey)}
            type="text"
            required={(v.recordcreationdefaultvalue == 2) ? true : false}
            id={v.id}
            disabled={v.recordcreationdefaultvalue == 3 ? true : false}
            className="w-100 input_bx"
            placeholder={`Add ${v.fieldlabel && v.fieldlabel.charAt(0).toUpperCase() + v.fieldlabel.slice(1)}`}
            onChange={(event) => data.handleChange({ fieldid: v.id, companyvalue: event.target.value }, v.fieldkey)}
            name={v.fieldkey}
            value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].companyvalue : ""}
            validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
            errorMessages={(v.recordcreationdefaultvalue == 2) ? ["This field is required"] : []}
          />
        </Grid>)
      } else if (v.fieldtype.id == '9') {//TextField with
        fieldsArray.push(<Grid item xs={6}>
          <InputLabel>{v.fieldlabel}</InputLabel>
          <TextValidator
            ref={ref => data[v.fieldkey] = ref}
            onBlur={(event) => data.handleBlur(event, v.fieldkey)}
            type="text"
            required={(v.recordcreationdefaultvalue == 2) ? true : false}
            id={v.id}
            disabled={v.recordcreationdefaultvalue == 3 ? true : false}
            className="w-100 input_bx"
            placeholder={`Add ${v.fieldlabel && v.fieldlabel.charAt(0).toUpperCase() + v.fieldlabel.slice(1)}`}
            onChange={(event) => data.handleChange({ fieldid: v.id, companyvalue: event.target.value }, v.fieldkey)}
            name={v.fieldkey}
            value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].companyvalue : ""}
            validators={v.recordcreationdefaultvalue == 2 ? ["required", "IsURLValid"] : ["IsURLValid"]}
            errorMessages={
              v.recordcreationdefaultvalue == 2
                ? ["This field is required", "Please enter a valid domain"]
                : ["Please enter a valid domain"]
            }
          />
        </Grid>)
      }
      else if (v.fieldtype.id == '6' || v.fieldtype.id == '10' || v.fieldtype.id == '7') {
        fieldsArray.push(<Grid item xs={6}>
          <InputLabel>{v.fieldlabel}</InputLabel>
          <TextValidator
            ref={ref => data[v.fieldkey] = ref}
            onBlur={(event) => data.handleBlur(event, v.fieldkey)}
            type="number"
            required={(v.recordcreationdefaultvalue == 2) ? true : false}
            id={v.id}
            disabled={v.recordcreationdefaultvalue == 3 ? true : false}
            className="w-100 input_bx"
            placeholder={`Add ${v.fieldlabel && v.fieldlabel.charAt(0).toUpperCase() + v.fieldlabel.slice(1)}`}
            onChange={(event) => data.handleChange({ fieldid: v.id, companyvalue: event.target.value }, v.fieldkey)}
            name={v.fieldkey}
            value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].companyvalue : ""}
            validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
            errorMessages={(v.recordcreationdefaultvalue == 2) ? ["This field is required"] : []}
          />
        </Grid>)
      }

      if (v.fieldtype.id == '2' && v.id == "36") {//Address Field
        var countryValidation = true;
        if (data.state.country != "" && data.state.country != null) {
          countryValidation = false;
        }
        fieldsArray.push(
          <>
            <Grid item xs={12}>
              <InputLabel>{"Address"}</InputLabel>
              <TextValidator
                ref={ref => data[v.fieldkey] = ref}
                onBlur={(event) => data.handleBlur(event, v.fieldkey)}
                multiline
                type="text"
                id={v.id}
                required={(v.recordcreationdefaultvalue == 2) ? true : false}
                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                className="w-100 input_bx"
                placeholder="Street"
                onChange={(event) => data.handleNormalFields(event)}
                name={'street'}
                value={data.state.street}
                validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
                errorMessages={(v.recordcreationdefaultvalue == 2) ? ["This field is required"] : []}
              />
            </Grid>
            <Grid item xs={4}>
              <TextValidator
                ref={ref => data['city'] = ref}
                onBlur={(event) => data.handleBlur(event, 'city')}
                type="text"
                id={"city"}
                required={(v.recordcreationdefaultvalue == 2) ? true : false}
                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                className="w-100 input_bx"
                placeholder={"City"}
                onChange={(event) => data.handleNormalFields(event)}
                name={"city"}
                value={data.state.city}
                validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
                errorMessages={(v.recordcreationdefaultvalue == 2) ? ["This field is required"] : []}
              />
            </Grid>
            <Grid item xs={4}>
              <TextValidator
                ref={ref => data['addressState'] = ref}
                onBlur={(event) => data.handleBlur(event, 'addressState')}
                type="text"
                required={(v.recordcreationdefaultvalue == 2) ? true : false}
                id={"state"}
                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                className="w-100 input_bx"
                placeholder={"State"}
                onChange={(event) => data.handleNormalFields(event)}
                name={"addressState"}
                value={data.state.addressState}
                validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
                errorMessages={(v.recordcreationdefaultvalue == 2) ? ["This field is required"] : []}
              />
            </Grid>
            <Grid item xs={4}>
              <TextValidator
                ref={ref => data['zipcode'] = ref}
                onBlur={(event) => data.handleBlur(event, 'zipcode')}
                required={(v.recordcreationdefaultvalue == 2) ? true : false}
                type="text"
                id={"zipcode"}
                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                className="w-100 input_bx"
                placeholder={"Zip code"}
                onChange={(event) => data.handleNormalFields(event)}
                name={"zipcode"}
                value={data.state.zipcode}
                validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
                errorMessages={(v.recordcreationdefaultvalue == 2) ? ["This field is required"] : []}
              />
            </Grid>
            <Grid item xs={12}>
              {/* <FormControl className="w-100 input_bx"> */}
              <Autocomplete
                disableOpenOnFocus
                forcePopupIcon={false}
                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                name={"country"}
                onChange={data.handleCountryChange}
                id={"country"}
                options={data.state.countryListArray}
                getOptionLabel={option => option.name ? option.name : ""}
                renderInput={params => (
                  <TextValidator
                    {...params}
                    className="w-100 input_bx"
                    ref={ref => data['country'] = ref}
                    onBlur={(event) => data.handleBlur(event, 'country')}
                    variant="standard"
                    placeholder={"Country"}
                    fullWidth
                    name={v.fieldkey}
                    value={countryValidation ? null : 1}
                    required={(v.recordcreationdefaultvalue == 2) ? true : false}
                    validators={(v.recordcreationdefaultvalue == 2 && countryValidation) ? ["required"] : []}
                    errorMessages={(v.recordcreationdefaultvalue == 2 && countryValidation) ? ["This field is required"] : []}
                  />
                )}
              />
              {/* </FormControl> */}
            </Grid>
          </>
        )
      } else if (v.fieldtype.id == '2') {
        fieldsArray.push(<Grid item xs={6}>
          <InputLabel>{v.fieldlabel}</InputLabel>
          <TextValidator
            ref={ref => data[v.fieldkey] = ref}
            onBlur={(event) => data.handleBlur(event, v.fieldkey)}
            multiline
            type="text"
            id={v.id}
            required={(v.recordcreationdefaultvalue == 2) ? true : false}
            disabled={v.recordcreationdefaultvalue == 3 ? true : false}
            className="w-100 input_bx"
            placeholder={`Add ${v.fieldlabel && v.fieldlabel.charAt(0).toUpperCase() + v.fieldlabel.slice(1)}`}
            onChange={(event) => data.handleChange({ fieldid: v.id, companyvalue: event.target.value }, v.fieldkey)}
            name={v.fieldkey}
            value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].companyvalue : ""}
            validators={(v.recordcreationdefaultvalue == 2 && (data.state.country == "")) ? ["required"] : []}
            errorMessages={(v.recordcreationdefaultvalue == 2 && (data.state.country == "")) ? ["This field is required"] : []}
          />
        </Grid>)
      }

      if (v.fieldtype.id == '4') {//if dropdown
        if (v.id === "38" || v.id === "33") {//38-visibility  33 contact type
          //38 -visibility with team start
          if (v.id === "38") {
            fieldsArray.push(
              <CommonVisibiltyAdd data={data} v={v} />
            )
          }
          //38-visibility with team end
          else {
            fieldsArray.push(<Grid item xs={6}>
              {/* <FormControl className="w-100 input_bx"> */}
              <InputLabel>{v.fieldlabel}</InputLabel>
              <SelectValidator
                ref={ref => data[v.fieldkey] = ref}
                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].values : ""}
                onChange={(event) => data.handleChange({ fieldid: v.id, values: event.target.value }, v.fieldkey)}
                name={v.fieldlabel}
                id={v.id}
                className="w-100 input_bx"
                required={(v.recordcreationdefaultvalue == 2) ? true : false}
                // label={v.fieldlabel}
                validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
                errorMessages={(v.recordcreationdefaultvalue == 2) ? ["This field is required"] : []}
              >
                {v.options && v.options.map((item, key) =>
                  <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item}>{item.name}</MenuItem>
                )}
                {v.options && v.id == "33" &&
                  <MenuItem className="select-menu-item item-with-link" value={5}><Link to="/settings/contact-types">Customize Contact Types</Link></MenuItem>
                }
              </SelectValidator>
              {/* </FormControl> */}
            </Grid >
            )
          }
        } else {
          fieldsArray.push(<Grid item xs={6}>
            {/* <FormControl className="w-100 input_bx"> */}
            <InputLabel>{v.fieldlabel}</InputLabel>
            <SelectValidator
              ref={ref => data[v.fieldkey] = ref}
              className="w-100 input_bx"
              // label={v.fieldlabel}
              disabled={v.recordcreationdefaultvalue == 3 ? true : false}
              value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].values : "none"}
              onChange={(event) => data.handleChange({ fieldid: v.id, values: event.target.value }, v.fieldkey)}
              name={v.fieldlabel}
              id={v.id}
              required={(v.recordcreationdefaultvalue == 2) ? true : false}
              validators={(v.recordcreationdefaultvalue == 2) ? ["required"] : []}
              errorMessages={(v.recordcreationdefaultvalue == 2) ? ["This field is required"] : []}
            >
              <MenuItem value="none" disabled style={{ display: 'none' }}><span className="text-hint">{`Add ${v.fieldlabel && v.fieldlabel.charAt(0).toUpperCase() + v.fieldlabel.slice(1)}`}</span></MenuItem>
              {v.options && v.options.map((item, key) =>
                <MenuItem className="select-menu-item w-100 input_bx" key={item.id} value={item}>{item.optionvalue}</MenuItem>
              )}

            </SelectValidator>
            {/* </FormControl> */}
          </Grid >
          )
        }
      }
      if (v.fieldtype.id == '5') {//multidropdown field
        if (data.state.fieldsObj[v.fieldkey]) {
          var valDetail = [];
          valDetail = data.state.fieldsObj[v.fieldkey].values
        }
        fieldsArray.push(<Grid item xs={6}>
          {/* <FormControl className="w-100 input_bx"> */}
          <InputLabel>{v.fieldlabel}</InputLabel>
          <Autocomplete
            multiple
            disabled={v.recordcreationdefaultvalue == 3 ? true : false}
            name={v.fieldkey}
            onChange={(event, value) => data.handleChange({ fieldid: v.id, values: value }, v.fieldkey)}
            id={v.id}
            options={v.options}
            getOptionLabel={option => option.optionvalue ? option.optionvalue : ""}
            renderInput={params => (
              <TextValidator
                ref={ref => data[v.fieldkey] = ref}
                onBlur={(event) => data.handleBlur(event, v.fieldkey)}
                {...params}
                variant="standard"
                fullWidth
                className="w-100 input_bx"
                name={v.fieldkey}
                placeholder={`Add ${v.fieldlabel && v.fieldlabel.charAt(0).toUpperCase() + v.fieldlabel.slice(1)}`}
                value={valDetail && valDetail.length ? 1 : null}
                required={(v.recordcreationdefaultvalue == 2) ? true : false}
                validators={(v.recordcreationdefaultvalue == 2 && (valDetail == null || valDetail.length == 0)) ? ["required"] : []}
                errorMessages={(v.recordcreationdefaultvalue == 2 && (valDetail == null || valDetail.length == 0)) ? ["This field is required"] : []}
              />
            )}
          />
          {/* </FormControl> */}
        </Grid >
        )
      }
      if (v.fieldtype.id == '3') {//checkbox field
        fieldsArray.push(<Grid item xs={6}>
          <FormControl className="w-100 input_bx">
            <FormControlLabel
              control={
                <Checkbox
                  name={v.fieldkey}
                  disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                  id={v.id}
                  checked={data.state.checkedB}
                  onChange={(event, value) => data.handleChange({ fieldid: v.id, companyvalue: value ? "1" : "0" }, v.fieldkey)}
                  value={data.state.fieldsObj[v.fieldkey] ? data.state.fieldsObj[v.fieldkey].companyvalue : ""}
                  color="primary"
                />
              }
              label={<span className="font-size-13">{v.fieldlabel}</span>}
            />
          </FormControl>
        </Grid >
        )
      }
      if (v.fieldtype.id == '8') {//datepicker field
        var dtFormat = "MM/dd/yyyy";
        var selectedDate = data.state.selectedDate;
        if (data.state.fieldsObj[v.fieldkey]) {
          selectedDate = data.state.fieldsObj[v.fieldkey].values
        }

        if (data.state.userSettings != "") {
          dtFormat = data.state.userSettings.dateformat;
          dtFormat = dtFormat.replace("DD", "dd").replace("YYYY", "yyyy");
        }
        fieldsArray.push(<Grid item xs={6} >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <FormControl className="w-100 input_bx">
              <KeyboardDatePicker
                label={v.fieldlabel}
                format={dtFormat}
                required={(v.recordcreationdefaultvalue == 2) ? true : false}
                disabled={v.recordcreationdefaultvalue == 3 ? true : false}
                placeholder={dtFormat}
                error={(v.recordcreationdefaultvalue == 2 && selectedDate == null || selectedDate == "Invalid Date") ? true : false}
                value={selectedDate}
                onChange={(event, value) => data.handleChange({ fieldid: v.id, values: event == "Invalid Date" ? "Invalid Date" : event }, v.fieldkey)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                helperText={(v.recordcreationdefaultvalue == 2 && selectedDate == null) ? "This field is required" : (selectedDate == "Invalid Date") ? "Please enter a valid date." : ""}

              />
            </FormControl>
          </MuiPickersUtilsProvider>
        </Grid >
        )
      }


    }
  })

  return fieldsArray;
}