export const navigations = [
  {
    id: 0,
    name: "Dashboard",
    path: "",
    icon: "palette",
    isaccess: true,

  }, {
    id: 1,
    name: "Leads",
    path: "/lead/leads-list",
    icon: "supervised_user_circle",
    isaccess: true,
    sourcetype: 1
  }, {
    id: 2,
    name: "Contacts",
    path: "/contact/contact-list",
    icon: "group",
    isaccess: true,
    sourcetype: 2
  }, {
    id: 3,
    name: "Companies",
    path: "/company/company-list",
    icon: "supervised_user_circle",
    isaccess: true,
    sourcetype: 3
  }, {
    id: 4,
    name: "Opportunities",
    path: "/opportunities/opportunities-card",
    icon: "business",
    isaccess: true,
    sourcetype: 4
  }, {
    id: 5,
    name: "Projects",
    path: "/project/projects-card",
    icon: "business_center",
    isaccess: true,
    sourcetype: 5
  }, {
    id: 6,
    name: "Tasks",
    path: "/task/task-list",
    icon: "list",
    isaccess: true,
    sourcetype: 6
  }, {
    id: 7,
    name: "My Tracked Emails",
    path: "/tracked-emails-list",
    icon: "mail",
    isaccess: true,
    sourcetype: 7,
  }, {
    id: 8,
    name: "Reports",
    path: "/reports/report-default/",
    icon: "flag",
    isaccess: true,
    sourcetype: 8,
  }
];
