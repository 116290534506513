import React, { Component } from "react";
import { Dialog, IconButton, withStyles, Button, Icon, Tooltip, Grid, FormControl, Select, InputLabel, MenuItem, DialogActions, Snackbar, CircularProgress, Fade } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { connect } from "react-redux";
import * as actionCreators from '../../../store/actions/index';
import Autocomplete from "@material-ui/lab/Autocomplete";
const TooltipNew = withStyles((theme) => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);

class CreateNewUser extends Component {
  state = {
    value: '',
    source: '',
    name: "",
    email: "",
    system_admin: false,
    google_sync: true,
    data_export: true,
    dropbox: true,
    teamManager: false,
    userDataEdit: 0,
    isDisabled: false,
    selctedTeamList: [{}],
    teamList: [],
    minimizeScreen: false,
    fullScreen: false,
    title: "",
    isDataLoading: true,
    companyowner: false,
    alredyAdded: [],
  };

  handleViewEmailDialogMinimize = () => {
    this.setState(oldStateminimizeScreen => ({ minimizeScreen: !oldStateminimizeScreen.minimizeScreen }));
    this.setState({ fullScreen: false })
  };

  handleViewEmailDialogFull = () => {
    this.setState(oldStatefullScreen => ({ fullScreen: !oldStatefullScreen.fullScreen }));
    this.setState({ minimizeScreen: false })
  };
  componentDidMount() {
    this.props.getTeam(this.props.apolloClient.client)
    ValidatorForm.addValidationRule('nameTrim', (value) => {
      if (!value || !value.trim()) {
        return false;
      }
      return true;
    });
  }

  handleChange = (event, source) => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  handleCheckBoxChange = (event, name) => {
    event.persist();
    this.setState({ [name]: event.target.checked });
  };

  handleBlur = (event, ref) => {
    console.log("this[ref]", this[ref]);
    this[ref].validate(event.target.value);
  }

  componentWillMount() {
    this.setState({ isDisabled: false })
    if (this.props.userDataEdit) {
      console.log(this.props.userDataEdit)
      let userDataEdit = this.props.userDataEdit;
      let data = [];
      let alredyAdded = [];
      if (userDataEdit.teamlist.length > 0) {
        userDataEdit.teamlist.map((v, i) => {
          alredyAdded.push(v.team.id)
          data.push({ id: v.team.id, ismanager: v.teammanager, teamname: v.team.teamname })
        });
      }
      this.setState({
        email: userDataEdit.email,
        name: userDataEdit.name,
        title: userDataEdit.title,
        companyowner: userDataEdit.companyowner,
        data_export: userDataEdit.dataexport === "1" ? true : false,
        dropbox: userDataEdit.dropbox === "1" ? true : false,
        google_sync: userDataEdit.googlesync === "1" ? true : false,
        system_admin: userDataEdit.systemadmin === "1" ? true : false,
        selctedTeamList: data,
        isDisabled: true,
        alredyAdded: alredyAdded
      })
    }
  }
  componentWillReceiveProps(props) {
    if (props.settingReducer.teamList.data && this.state.teamList !== props.settingReducer.teamList.data) {
      const teamList = props.settingReducer.teamList.data;
      let data = [];
      teamList.map((v, i) => {
        data.push({ id: v.id, teamname: v.teamname })
      });
      this.setState({ teamList: data, isDataLoading: false })
    } else {
      setTimeout(() => {
        this.setState({
          isDataLoading: false
        })
      }, 1500);
    }
  }
  handleTeamChange = (index, value) => {
    console.log("edd", index, value)
    let data = this.state.selctedTeamList
    if (value !== null) {
      data[index].id = value.id;
      data[index].teamname = value.teamname
      data[index].ismanager = data[index].ismanager ? data[index].ismanager : false
    }
    this.setState({ selctedTeamList: data })
  }
  handleRoleChange = (e, index) => {
    let data = this.state.selctedTeamList
    if (e.target.value !== null) {
      data[index].ismanager = e.target.value;
    }
    this.setState({ selctedTeamList: data })
  }
  handleDeleteRow = (index) => {
    let data = this.state.selctedTeamList
    data.splice(index, 1);
    this.setState({ selctedTeamList: data })
  }
  addNewTeam = () => {
    let data = this.state.selctedTeamList
    this.setState({ selctedTeamList: [...data, {}] })
  }
  handleFormSubmit = () => {
    let error = [];
    if (!this.state.name || !this.state.name.trim()) {
      error.push("name")
    }
    if (!this.state.email || !this.state.email.trim()) {
      error.push("email")
    }
    if (error.length > 0) {
      this.setState({
        error
      })
      return error;
    }
    let session_data = JSON.parse(localStorage.getItem('user_data_session'));
    let googlesync = 0;
    let systemadmin = 0;
    let dataexport = 0;
    let dropbox = 0;
    if (this.state.system_admin == true) {
      systemadmin = 1;
    }
    if (this.state.google_sync == true) {
      googlesync = 1;
    }
    if (this.state.data_export == true) {
      dataexport = 1;
    }
    if (this.state.dropbox == true) {
      dropbox = 1;
    }
    let teamdata = this.state.selctedTeamList.length > 0 ? this.state.selctedTeamList.filter(el => Object.keys(el).length) : []
    let data = [];
    let deleteteam = [];
    if (teamdata.length > 0) {
      teamdata.map((v, i) => {
        data.push({ teamid: v.id, ismanager: v.ismanager })
      })
    }
    if (this.state.alredyAdded.length > 0) {
      this.state.alredyAdded.map((v, i) => {
        if (teamdata.some(item => item.id == v)) {
        } else {
          deleteteam.push(v)
        }
      })
    } else {
      deleteteam = []
    }
    const req = ({
      name: this.state.name,
      email: this.state.email,
      googlesync: googlesync,
      systemadmin: systemadmin,
      title: this.state.title,
      dataexport: dataexport,
      dropbox: dropbox,
      companyid: session_data.companyId,
      teamdata: data,
      deleteteam: deleteteam,
    });
    if (this.props.userDataEdit) {
      let edit_user_id = ({ userid: this.props.userDataEdit.id });
      let newstate = Object.assign(req, edit_user_id);
      this.props.saveEditUser(newstate);
      this.props.fetchUserList();
    } else {
      this.props.settingAddUser(req);
      this.props.fetchUserList();
    }
    this.props.handleClose();
  };

  render() {
    let { name, email, title, } = this.state;
    let { minimizeScreen, fullScreen } = this.state;
    let { open, handleClose } = this.props;
    console.log(this.state.selctedTeamList, this.state.teamList)
    let addnewteam = [];
    if (this.state.teamList.length > 0) {
      if (this.state.selctedTeamList.length < this.state.teamList.length) {
        addnewteam.push(
          <Grid container spacing={4} className="p-16" justify="flex-end">
            <span type="button" className="cursor-pointer text_link" onClick={this.addNewTeam}>Add a Team </span>
          </Grid>
        )
      }
    }
    let mainTeamSelectionRow = []
    this.state.selctedTeamList.map((team, i) => {
      mainTeamSelectionRow.push(
        <Grid container
          direction="row"
          justify="flex-start"
          alignItems="center" spacing={2} key={i} >
          <Grid item xs={6}>
            <InputLabel>Team </InputLabel>
            <Autocomplete
              key={i}
              forcePopupIcon={false}
              name={"team" + i}
              disableClearable
              options={this.state.teamList}
              getOptionLabel={(option) => option.teamname}
              value={team}
              onChange={(event, value) =>
                this.handleTeamChange(i, value)}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  variant="standard"
                  className="w-100 input_bx"
                  fullWidth
                  placeholder="Add Team "
                  name={"team" + i}
                  value={team.teamname && team.teamname.length > 0 ? team.teamname : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={5}>
            <InputLabel>Team Role</InputLabel>
            {team.teamname && team.teamname !== null ?
              <Select
                className="mt--4"
                name={"team" + i}
                value={team.ismanager}
                fullWidth
                onChange={(event, value) =>
                  this.handleRoleChange(event, i)
                }
              >
                <MenuItem className="select-menu-item" value={false}>Team Member</MenuItem>
                <MenuItem className="select-menu-item" value={true}>Team Manager</MenuItem>
              </Select>
              : <div className="font-size-13 pt-10 text-muted">Select a Role</div>
            }
          </Grid>
          <Grid item xs={1} className="pt-30 text-center " onClick={() => this.handleDeleteRow(i)} >
            <Icon className="font-size-16">close</Icon>
          </Grid>
        </Grid>
      )
    })

    return (
      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth="md"
        fullWidth={true}
        className={`crm-custom-bg-primary custom_dialog ${minimizeScreen && fullScreen ? "alignitemsend-fullscreen" : ""
          } ${minimizeScreen ? "alignitemsend" : ""} ${fullScreen ? "fullscreen" : ""
          } `}
        disableBackdropClick
      >
        <div className="d-flex justify-content-between align-items-center pl-16 pr-4 py-4 bg-primary">
          <h4 className="m-0 text-white">
            {this.props.userDataEdit ?
              "Edit " + name :
              "Invite a New User"}
          </h4>
          <div className="d-flex">
            <TooltipNew title={minimizeScreen ? 'Normal Screen' : 'Minimize Screen'}>
              <IconButton onClick={this.handleViewEmailDialogMinimize}>
                <Icon className="text-white">{minimizeScreen ? 'add' : 'remove'}</Icon>
              </IconButton>
            </TooltipNew>
            <TooltipNew title={fullScreen ? 'Exit Full Screen' : 'Full Screen'}>
              <IconButton onClick={this.handleViewEmailDialogFull}>
                <Icon className="text-white">{fullScreen ? 'fullscreen_exit' : 'fullscreen'}</Icon>
              </IconButton>
            </TooltipNew>
            <TooltipNew title="Close">
              <IconButton onClick={handleClose}>
                <Icon className="text-white">clear</Icon>
              </IconButton>
            </TooltipNew>
          </div>
        </div>
        <ValidatorForm ref="form" instantValidate={false} onSubmit={this.handleFormSubmit} noValidate>
          <div className="dialog-body pt-16 pb-16 ">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {/* <InputLabel>Name *</InputLabel> */}
                <TextValidator
                  className="w-100 input_bx"
                  required={true}
                  label={'Name'}
                  ref={ref => this['name'] = ref}
                  onBlur={(event) => this.handleBlur(event, 'name')}
                  onChange={this.handleChange}
                  type="text"
                  placeholder="Add Name"
                  name="name"
                  value={name}
                  validators={["required", "nameTrim"]}
                  errorMessages={["This field is required", "This field is required"]}
                  autoComplete='off'
                  InputLabelProps={{ shrink: true, disableAnimation: false, focused: true }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {/* <InputLabel>Email *</InputLabel> */}
                <TextValidator
                  ref={ref => this['email'] = ref}
                  onBlur={(event) => this.handleBlur(event, 'email')}
                  required={true}
                  label={'Email'}
                  className="w-100 input_bx"
                  onChange={this.handleChange}
                  type="email"
                  name="email"
                  placeholder="Add Email"
                  value={email}
                  validators={["required", 'isEmail']}
                  errorMessages={["This field is required", "email is not valid"]}
                  autoComplete='off'
                  disabled={this.state.isDisabled}
                  InputLabelProps={{ shrink: true, disableAnimation: false, focused: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel>Title</InputLabel>
                <TextValidator
                  ref={ref => this['title'] = ref}
                  onBlur={(event) => this.handleBlur(event, 'title')}
                  required={true}
                  className="w-100 input_bx"
                  onChange={this.handleChange}
                  type="text"
                  name="title"
                  placeholder="Add Title"
                  value={title}
                />
              </Grid>
            </Grid>
            {this.props.authReducer.userRole.id !== 3 &&
              <>
                {mainTeamSelectionRow}
                {addnewteam}
                <Grid container spacing={4} className="pt-16">
                  <Grid item xs={12}>
                    Note: Users not added to a Team will have access to all entities,reports and dashboards and will only be able to see records owned by themselves
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <InputLabel className="font-size-20">Privileges</InputLabel>
                    <FormControlLabel
                      className="font-size-14"
                      control={
                        <Checkbox
                          checked={this.state.companyowner === "true" ? true : this.state.system_admin}
                          disabled={this.state.companyowner === "true" ? true : false}
                          onChange={event => this.handleCheckBoxChange(event, 'system_admin')}
                          value="system_admin"
                          color="primary"
                          // className="checkB_color"
                        />
                      }
                      label="System Admin"
                    />
                    <FormControlLabel
                      className="font-size-14"
                      control={
                        <Checkbox
                          checked={this.state.google_sync}
                          onChange={event => this.handleCheckBoxChange(event, 'google_sync')}
                          value="google_sync"
                          color="primary"
                        />
                      }
                      label="Google Sync"
                    />
                    <FormControlLabel
                      className="font-size-14"
                      control={
                        <Checkbox
                          checked={this.state.data_export}
                          onChange={event => this.handleCheckBoxChange(event, 'data_export')}
                          value="data_export"
                          color="primary"
                        />
                      }
                      label="Data Export"
                    />
                    <FormControlLabel
                      className="font-size-14"
                      control={
                        <Checkbox
                          checked={this.state.dropbox}
                          onChange={event => this.handleCheckBoxChange(event, 'dropbox')}
                          value=""
                          color="primary"
                        />
                      }
                      label="Drop Box"
                    />
                  </Grid>
                </Grid>
              </>
            }
          </div>
          <DialogActions className="dialog-action">
            <Button onClick={() => this.props.handleClose()} variant="contained">Cancel</Button>
            <Button variant="contained" type="submit" color="primary" className="btn_Primary">Save</Button>
          </DialogActions>
        </ValidatorForm>
        {
          this.state.isDataLoading &&
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={this.state.isDataLoading}
            TransitionComponent={Fade}
            message={<><CircularProgress size={20} style={{ color: 'white' }} /><span style={{ paddingLeft: '6px', color: 'white' }}>{" Loading..."}</span></>}
            variant={'success'}
          />
        }
      </Dialog >
    );
  }
}
const mapStateToProps = state => ({
  settingReducer: state.settingReducer,
  apolloClient: state.apolloClient,
  authReducer: state.authReducer
});
const mapDispatchToProps = dispatch => {
  return {
    getTeam: (client) => dispatch(actionCreators.getTeam(client)),

    settingAddUser: (data) => dispatch(actionCreators.settingAddUser(data)),
    getUserById: (id) => dispatch(actionCreators.getUserById(id)),
    saveEditUser: (data) => dispatch(actionCreators.saveEditUser(data))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateNewUser);