import React from "react";
import { IconButton, Icon, Menu, MenuItem } from "@material-ui/core";

function ProfileEmailMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    
    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }
    function handleEmailHtmlPopup(){
        handleClose();
        props.handleEmailHtmlPopupMain(props.logDatas)
    }
    return (
        <React.Fragment>
            <IconButton size="small"
                aria-label="More"
                aria-owns={open ? "long-menu" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                className="hover_show"
            >
                <Icon>more_vert</Icon>
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className="choose_field_menu"
            >
                <MenuItem className="filter_items" onClick={handleEmailHtmlPopup}>
                    <Icon className="mr-16 text-muted">launch</Icon> View HTML Version
                </MenuItem>
                <MenuItem onClick={handleClose} className="filter_items">
                    <Icon className="mr-16 text-muted">delete</Icon> Delete Email
                </MenuItem>
                <MenuItem onClick={handleClose} className="filter_items">
                    <Icon className="mr-16 text-error">lock</Icon>
                    Make email public
                </MenuItem>
            </Menu>
            
        </React.Fragment>
    );
}

export default ProfileEmailMenu;
